/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Row } from './row';

/**
 * 
 * @export
 * @interface Data
 */
export interface Data {
    /**
     * 
     * @type {number}
     * @memberof Data
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Data
     */
    'type'?: DataTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Data
     */
    'columns'?: Array<string>;
    /**
     * 
     * @type {Array<Row>}
     * @memberof Data
     */
    'rows'?: Array<Row>;
    /**
     * 
     * @type {number}
     * @memberof Data
     */
    'numRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof Data
     */
    'numCols'?: number;
}

export const DataTypeEnum = {
    InputEntity: 'INPUT_ENTITY',
    InputEntitySegment: 'INPUT_ENTITY_SEGMENT',
    OutputEntity: 'OUTPUT_ENTITY'
} as const;

export type DataTypeEnum = typeof DataTypeEnum[keyof typeof DataTypeEnum];


