import React from "react";
import shallow from "zustand/shallow";

import { AccessControlContext } from "./AccessControlContext";
import { useGetRole } from "src/hooks/useGetRole";
import useAuthStore from "src/stores/auth.store";

export const AccessControlContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { isBusinessUser, isAdmin, hasDemoRole } = useGetRole();
  const [userAdmin] = useAuthStore((state) => [state.hasPrivileges], shallow);
  const value = {
    canAddStandardRecipe: !isBusinessUser,
    canAddArtifacts: !isBusinessUser,
    canAddModels: !isBusinessUser,
    canRunProject: !isBusinessUser,
    canViewScenarios: !isBusinessUser,
    hideProjectSettingGlobalVar: isBusinessUser,
    canAccessRecipeTimeout: !isBusinessUser,
    canViewCodeRecipeConditions: !isBusinessUser,
    canViewStandardRecipeConditions: !isBusinessUser,
    canViewSegments: !isBusinessUser,
    canAccessTenantSettings: isAdmin || hasDemoRole,
    canAddUsers: userAdmin || isBusinessUser,
    canEditUsers: userAdmin || isBusinessUser
  };
  return <AccessControlContext.Provider value={value}>{children}</AccessControlContext.Provider>;
};
