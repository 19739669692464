import React from "react";

interface ITextProps {
  value?: string | JSX.Element;
  style?: React.CSSProperties;
  dataTestId?: string;
}

const Text: React.FC<ITextProps> = ({ value, style, dataTestId }) => {
  if (!value) {
    return null;
  }

  return (
    <span
      style={{ position: "relative", whiteSpace: "pre-wrap", ...style }}
      data-testid={dataTestId}>
      {value}
    </span>
  );
};

export default Text;
