import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { UseGetProjectsQueryKeys } from "./useGetProjects";
import { handleResponse, postAPIWithRethrow } from "src/utils/apiService";
import { SUCCESSFULLY_COPIED_MSG } from "pages/Projects/CopyProject/utils/CopyDownloadProject.constants";

export interface IProjectRunData {
  name: string;
  projectId: string;
  modelEntityId: string;
}

export interface ICopyPayload {
  name: string;
  projectId: string;
  tenantId: string;
  dataAppIds: string[];
  projectRunIds: string[];
  envId: string;
  predictionServices: string[];
}

const useCopyProject = (): UseMutationResult<unknown, unknown, ICopyPayload, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body) => {
      const data = await postAPIWithRethrow(`/v2/projects/copy?async=false`, body);
      return data;
    },
    onSuccess: () => {
      handleResponse({ successMessage: SUCCESSFULLY_COPIED_MSG });
      queryClient.invalidateQueries([UseGetProjectsQueryKeys.Projects]);
    }
  });
};

export default useCopyProject;
