import React from "react";

// Packages
import { includes } from "lodash";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { ScenarioUpdatePromptDetails } from "../utils/Scenario.constants";

type Props = {
  onUpdate: () => void;
  isScenarioUpdating?: boolean;
  resetUpdateScenario: () => void;
};

const UpdateScenario = (props: Props) => {
  const { onUpdate, isScenarioUpdating, resetUpdateScenario } = props || {};

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={ScenarioUpdatePromptDetails.title}
      content={[ScenarioUpdatePromptDetails.messageLine1, ScenarioUpdatePromptDetails.messageLine2]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetUpdateScenario();
        }
      }}
      onSubmit={onUpdate}
      submitLabel={ScenarioUpdatePromptDetails.submitLabel}
      isCancelDisabled={isScenarioUpdating}
      isSubmitDisabled={isScenarioUpdating}
      isSubmitting={isScenarioUpdating}
      hideCloseIcon
    />
  );
};

export default UpdateScenario;
