import React from "react";

import GeneratingAboutContentIcon from "src/icons/GeneratingAboutContentIcon";
import { useStyles } from "./NoAboutContent";

const GeneratingAboutContent: React.FC = () => {
  const { flexContainer } = useStyles();

  return (
    <div className={flexContainer}>
      <div>
        <GeneratingAboutContentIcon />
        <div>About Page Content is getting Generated</div>
        <span>It might take few minutes, please give us some time to update the content here</span>
      </div>
    </div>
  );
};

export default GeneratingAboutContent;
