import React from "react";

// Packages
import { generatePath, Link } from "react-router-dom";

// MUI
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// Icons
import InfoOutlined from "@material-ui/icons/InfoOutlined";

// Utils
import { RenderText } from "src/utils";
import { WebPaths } from "src/routing/routes";

// Constants
import { JobsHelperText } from "../../utils/Jobs.constants";

type Props = {
  project: $TSFixMe;
  jobData: $TSFixMe;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, jobData } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={generatePath(`${WebPaths.Dag}/canvas`, { projectId: project?.id })}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    !!project?.id && (
      <Link key="jobs" to={generatePath(WebPaths.JobRoutes, { projectId: project?.id })}>
        <RenderText>Jobs</RenderText>
      </Link>
    )
  );

  breadcrumbs.push(
    !!project?.id && !!jobData?.id ? (
      <Link
        key="job-name"
        to={generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
          projectId: project?.id,
          jobId: jobData?.id
        })}>
        <RenderText>{jobData?.name || "Job"}</RenderText>
      </Link>
    ) : (
      <RenderText key="job-name" color="textSecondary">
        {jobData?.name || "Job"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    <RenderText key="info" color="textSecondary">
      Run History{" "}
      <Tooltip title={JobsHelperText.JobRunsInfo}>
        <Typography component="span" color="textSecondary" style={{ cursor: "help" }}>
          <InfoOutlined fontSize="small" />
        </Typography>
      </Tooltip>
    </RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
