import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, makeStyles } from "@material-ui/core";

import ChartsHeader from "./ChartsHeader";
import DeleteNodeModal from "../../ViewData/DeleteNodeModal/DeleteNodeModal";
import Spinner from "../../../components/Spinner";
import { getNodes } from "../../../store/store.selectors";
import { useCanvasStore } from "../../../store/store";
import { useGetCanvas, useGetJob, useGetJobRun } from "src/hooks/api";
import { ChartList } from "./ChartList";
import { useGetStreamlitToken } from "src/hooks/api/common/useGetStreamlitToken";
import { useSetAIGuideMessagesCount } from "../common/hooks/useSetAIGuideMessagesCount";

const useStyles = makeStyles(() => ({
  header: {
    alignItems: "center",
    borderBottom: "1px solid #D3D9DC",
    maxHeight: 85
  },
  container: {
    background: "#ffffff"
  },
  minWidth: {
    flexGrow: 0
  },
  icon: {
    marginRight: 8
  }
}));

const Charts = () => {
  const classes = useStyles();
  const { projectId, chartId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();

  const { isLoading: isFetchingStreamlitToken, data: token } = useGetStreamlitToken({
    enabled: true
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const nodes = useCanvasStore(getNodes);
  const setNodes = useCanvasStore((state) => state.setNodes);
  const setEdges = useCanvasStore((state) => state.setEdges);
  const selectedNode = nodes?.find(({ id }: { id: string }) => id === chartId);
  const chartList = eval(selectedNode?.viewData) || [];
  useSetAIGuideMessagesCount({ projectId: projectId! });

  const [charts, setCharts] = useState(chartList);
  const [chartName, setChartName] = useState<string>(selectedNode?.displayName || "");

  const { data: jobData } = useGetJob({ projectId: projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });

  const handleChartNameChange = (value: string) => {
    setChartName(value);
    const updatedNodes = nodes?.map((node: any) =>
      node.id === chartId ? { ...node, displayName: value } : node
    );
    setNodes(updatedNodes);
  };

  const { data: canvas, isFetching } = useGetCanvas({
    projectId,
    scenarioId,
    jobId,
    jobRunId,
    enabled: !chartList || chartList?.length === 0,
    options: {
      keepPreviousData: true,
      refetchOnMount: true
    }
  });

  useEffect(() => {
    if (!isFetching && canvas) {
      setNodes(canvas?.nodes);
      setEdges(canvas?.edges);
      const selectedNode = canvas?.nodes?.find(({ id }: { id: string }) => id === chartId);
      const chartList = eval(selectedNode?.viewData) || [];
      setChartName(selectedNode?.displayName);
      setCharts(chartList);
    }
  }, [canvas, isFetching]);

  return isFetching || isFetchingStreamlitToken || !token || charts?.length === 0 ? (
    <Spinner />
  ) : (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      // New UX change
      // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
      style={{ background: "#FFFFFF", minHeight: "calc(100vh - 94px)" }}>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
        }}
        nodeId={chartId}
        nodeName={chartName}
        nodeType="chart"
      />
      <Grid container item className={`${classes.container} ${classes.header}`}>
        <ChartsHeader
          handleChangeName={handleChartNameChange}
          chartName={chartName}
          jobData={jobData}
          jobRunData={jobRunData}
        />
      </Grid>
      <ChartList charts={charts} token={token} hideAIGuide={!!jobId || !!jobRunId} />
    </Grid>
  );
};

export default Charts;
