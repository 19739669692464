import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const TestIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7413 13.9655C13.6247 13.7313 9.68058 5.81472 9.46886 5.38972V0.9375H10.0314C10.2902 0.9375 10.5001 0.727625 10.5001 0.46875C10.5001 0.209875 10.2902 0 10.0314 0C8.77151 0 5.40967 0 3.96886 0C3.70998 0 3.50011 0.209875 3.50011 0.46875C3.50011 0.727625 3.70998 0.9375 3.96886 0.9375H4.53136V5.38969C-1.82077 18.1398 1.2658 11.9445 0.259233 13.9648C-0.208767 14.9009 0.472764 16 1.51701 16H12.4832C13.5309 16 14.2073 14.8976 13.7413 13.9655ZM5.41967 5.70903C5.45201 5.64409 5.46886 5.57253 5.46886 5.5V0.9375H8.53136V5.5C8.53136 5.57253 8.5482 5.64409 8.58055 5.70903L10.1111 8.78125H8.3917C8.19558 8.20063 7.64608 7.78125 7.00011 7.78125C6.35414 7.78125 5.80464 8.20063 5.60851 8.78125H3.88911L5.41967 5.70903ZM7.53136 9.25C7.53136 9.54294 7.29305 9.78125 7.00011 9.78125C6.70717 9.78125 6.46886 9.54294 6.46886 9.25C6.46886 8.95706 6.70717 8.71875 7.00011 8.71875C7.29305 8.71875 7.53136 8.95706 7.53136 9.25ZM12.4832 15.0625H1.51701C1.16851 15.0625 0.941827 14.696 1.09805 14.3835L3.42205 9.71875H5.60851C5.80464 10.2994 6.35414 10.7188 7.00011 10.7188C7.64608 10.7188 8.19558 10.2994 8.3917 9.71875H10.5782L12.9025 14.3841C13.0584 14.6959 12.8315 15.0625 12.4832 15.0625Z"
        fill={color}
      />
      <path
        d="M5 11.0312C4.19012 11.0312 3.53125 11.6901 3.53125 12.5C3.53125 13.3099 4.19012 13.9688 5 13.9688C5.80988 13.9688 6.46875 13.3099 6.46875 12.5C6.46875 11.6901 5.80988 11.0312 5 11.0312ZM5 13.0312C4.70706 13.0312 4.46875 12.7929 4.46875 12.5C4.46875 12.2071 4.70706 11.9688 5 11.9688C5.29294 11.9688 5.53125 12.2071 5.53125 12.5C5.53125 12.7929 5.29294 13.0312 5 13.0312Z"
        fill={color}
      />
      <path
        d="M8.95987 11.4084C8.90128 11.1274 8.62084 10.9733 8.36425 11.0515C8.10556 11.134 7.9799 11.3954 8.05175 11.6362C8.13912 11.9043 8.4034 12.0176 8.63643 11.9484C8.88334 11.8677 9.0025 11.6362 8.95987 11.4084Z"
        fill={color}
      />
      <path
        d="M10.4598 12.4082C10.4019 12.1306 10.1267 11.973 9.86386 12.0513C9.59552 12.1388 9.48236 12.4036 9.55168 12.636C9.63799 12.9009 9.89989 13.0184 10.1364 12.9482C10.3879 12.8659 10.5018 12.6326 10.4598 12.4082Z"
        fill={color}
      />
      <path
        d="M8.45977 13.4084C8.40123 13.1278 8.1212 12.9732 7.86414 13.0515C7.60236 13.135 7.4808 13.3988 7.55164 13.6362C7.63908 13.9046 7.90326 14.0176 8.13633 13.9484C8.38336 13.8676 8.50236 13.636 8.45977 13.4084Z"
        fill={color}
      />
    </svg>
  );
};

export default TestIcon;
