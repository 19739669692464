import React, { useRef, useState } from "react";
import _ from "lodash";
import { Close } from "@material-ui/icons";
import { IconButton, makeStyles } from "@material-ui/core";

import OverflowTooltip from "components/OverflowTooltip";
import UploadFile from "./UploadFile";
import { formatBytes } from "src/utils/format-bytes";

export interface IFile extends File {
  fileType: string;
  file: File;
  id: string;
}

interface IProps {
  files: IFile[];
  name: string;
  getFileType: (file: IFile) => string;
  isUploadOpen: boolean;
  msgHeight: number;
  onChange: (finished: boolean) => void;
  onRemoveFile: (newFiles: IFile[]) => void;
  onError: (error: any, finished: boolean, allCanceled: boolean) => void;
  onClose: () => void;
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "10px",
    maxHeight: ({ msgHeight }) => `calc(100vh - ${295 + msgHeight}px)`
  },
  filesContainer: {
    width: "100%",
    overflow: "auto",
    maxHeight: ({ msgHeight }: { msgHeight: number }) => `calc(100vh - ${285 + msgHeight}px)`
  },
  file: {
    display: "flex",
    boxShadow:
      "inset 0px 1px 0px rgba(211, 217, 220, 0.5), inset 0px -1px 0px rgba(211, 217, 220, 0.5)",
    color: "#415F80",
    padding: "12px 14px",
    fontSize: "12px",
    "& > div": {
      width: 130,
      lineHeight: "19px",
      marginRight: "10px"
    },
    "& > div:first-child": {
      fontSize: "14px",
      color: "#123553"
    }
  },
  fileCloseIcon: {
    padding: 0,
    alignItems: "flex-start",
    height: "fit-content"
  }
});

const BATCH_SIZE = 20;

const UploadFileList: React.FC<IProps> = (props) => {
  const {
    files,
    name,
    msgHeight,
    getFileType,
    isUploadOpen,
    onError,
    onChange,
    onRemoveFile,
    onClose
  } = props;

  const classes = useStyles({ msgHeight });
  const successCount = useRef<number>(0);
  const failedCount = useRef<number>(0);
  const [batchCount, setBatchCount] = useState(0);

  const handleFinish = () => {
    const finished = failedCount.current + successCount.current === _.size(files) - 1;
    onChange(finished);

    if (finished) {
      setBatchCount(0);
      successCount.current = 0;
    } else {
      successCount.current += 1;
      setBatchCount((old) => old + 1);
    }
  };

  const handleDelete = () => {
    const finished = failedCount.current + successCount.current === _.size(files) - 1;
    if (finished) {
      onClose();
      setBatchCount(0);
    }
  };

  const handleError = (error: any) => {
    const finished = failedCount.current + successCount.current === _.size(files) - 1;
    const allCanceled = failedCount.current === _.size(files) - 1;
    onError(error, finished, allCanceled);

    if (finished && !allCanceled) {
      failedCount.current = 0;
      setBatchCount(0);
    } else {
      failedCount.current += 1;
      setBatchCount((old) => old + 1);
    }
  };

  const handleRemoveFile = (id: string) => {
    const newFiles = _.filter(files, (file) => file.id !== id);
    onRemoveFile(newFiles);
  };

  const shouldStartUpload = (index: number) => {
    return index < batchCount + BATCH_SIZE && index >= batchCount;
  };

  if (isUploadOpen) {
    return (
      <div className={classes.root}>
        {_.map(files, (file, index) => (
          <UploadFile
            key={`${file.name}_${index}`}
            shouldStartUpload={shouldStartUpload(index)}
            file={file}
            name={name}
            getFileType={getFileType}
            onError={handleError}
            onFinishUpload={handleFinish}
            onDelete={handleDelete}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={classes.filesContainer}>
      {_.map(files, (file: IFile, index: number) => (
        <div className={classes.file} key={`file-${index}`}>
          <div>
            <OverflowTooltip style={{ whiteSpace: "nowrap" }} value={file?.name} />
          </div>
          <div>{`Size: ${file.size ? formatBytes(file.size) : "0 KB"}`}</div>
          <div>{`Type: ${getFileType(file)}`}</div>
          <IconButton
            className={classes.fileCloseIcon}
            aria-label="remove file"
            onClick={() => handleRemoveFile(file?.id)}>
            <Close fontSize="small" />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default UploadFileList;
