/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { DFSRunConfigGroupDto } from './dfsrun-config-group-dto';
// May contain unused imports in some cases
// @ts-ignore
import type { ProjectRunDto } from './project-run-dto';

/**
 * 
 * @export
 * @interface EnvUsageDto
 */
export interface EnvUsageDto {
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'recipeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'recipeName'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'recipeCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'recipeUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'recipeCreator'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'recipeUpdater'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'projectName'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'projectCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'projectUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'projectCreator'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'projectUpdater'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'pdId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'pdName'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'pdCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'pdUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'pdCreator'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'pdUpdater'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'runningJobCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'runningPredictionJobCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvUsageDto
     */
    'runningRecipeCount'?: number;
    /**
     * 
     * @type {Array<ProjectRunDto>}
     * @memberof EnvUsageDto
     */
    'projectRunList'?: Array<ProjectRunDto>;
    /**
     * 
     * @type {Array<DFSRunConfigGroupDto>}
     * @memberof EnvUsageDto
     */
    'recipeList'?: Array<DFSRunConfigGroupDto>;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'pdModelName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'pdModelVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvUsageDto
     */
    'type'?: EnvUsageDtoTypeEnum;
}

export const EnvUsageDtoTypeEnum = {
    Recipe: 'RECIPE',
    Project: 'PROJECT',
    PredictionService: 'PREDICTION_SERVICE'
} as const;

export type EnvUsageDtoTypeEnum = typeof EnvUsageDtoTypeEnum[keyof typeof EnvUsageDtoTypeEnum];


