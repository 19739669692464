import React from "react";

import Tooltip from "@material-ui/core/Tooltip";

type Props = {
  title: string;
  children: React.ReactNode;
};

const NodeActionWrapper = (props: Props) => {
  const { title = "", children } = props || {};

  return (
    <Tooltip title={title}>
      <span>{children}</span>
    </Tooltip>
  );
};

export default NodeActionWrapper;
