import { useRef } from "react";

import { delay, join, slice } from "lodash";

import { leadingTextInPath } from "src/utils/formatText";

export const invalidFileTypes = [
  "c",
  "cgi",
  "pl",
  "class",
  "cpp",
  "cs",
  "h",
  "java",
  "php",
  "py",
  "sh",
  "swift",
  "vb",
  "asp",
  "aspx",
  "cer",
  "cfm",
  "css",
  "htm",
  "html",
  "js",
  "jsp",
  "part",
  "rss",
  "xhtml",
  "apk",
  "bat",
  "bin",
  "com",
  "exe",
  "gadget",
  "jar",
  "msi",
  "wsf"
];

export function isUnique(arrayOfValues: $TSFixMe) {
  return new Set(arrayOfValues).size !== arrayOfValues.length;
}

export function addEllipsis(label = "") {
  if (label?.length > 25) {
    return label.substring(0, 11) + "..." + label.substring(label.length - 11);
  }

  return label;
}

export function handleClickClosure({
  shouldBlockClickHandlerTrigger,
  isCtrlPressed,
  handleSingleClick,
  handleDoubleClick,
  handleMultiSelection,
  shouldClearEntities = true
}: $TSFixMe) {
  const clickCountRef = useRef(0);

  return () => {
    if (isCtrlPressed) {
      handleMultiSelection();
      return;
    } else {
      shouldClearEntities && sessionStorage.setItem("selectedEntities", "");
    }

    if (shouldBlockClickHandlerTrigger) {
      return;
    }

    clickCountRef.current++;

    if (clickCountRef.current === 1) {
      delay(() => {
        if (clickCountRef.current === 1) {
          clickCountRef.current = 0;
          handleSingleClick();
          return;
        }
      }, 500);
    } else if (clickCountRef.current === 2) {
      clickCountRef.current = 0;
      handleDoubleClick();
      return;
    } else {
      return;
    }
  };
}

export const getDocsUrl = (): string => {
  const href = window.location.href;
  switch (true) {
    case /test.dev/.test(href):
      return "https://docs-test.dev.rapidcanvas.net";
    case /qa.dev/.test(href):
      return "https://docs-qa.dev.rapidcanvas.net";
    case /staging.dev/.test(href):
      return "https://docs.dev.rapidcanvas.net";
    default:
      return "https://docs.rapidcanvas.ai";
  }
};

// $FixMe: Specific to Recipe. Keeping it for a demo. It can be changed later.
export const recipeModelProblemTypesDocsUrl = (): string =>
  "https://docs.dev.rapidcanvas.net/Problemtypes.html";

export const validateFiles = (files: FileList, validFileExtensions: Array<string>) => {
  return files
    ? Object.values(files).every((file: File) =>
        validFileExtensions.includes(leadingTextInPath({ text: file.name }))
      )
    : true;
};

export const areFileTypesValid = (
  files: FileList,
  invalidFileExtensions: Array<string> = invalidFileTypes
) => {
  return files
    ? !Object.values(files).some((file: File) =>
        invalidFileExtensions.includes(leadingTextInPath({ text: file.name }))
      )
    : true;
};

export const getDataAppDomain = (): string => {
  return window.location.hostname;
};

// $FixMe: Scope to be refactored.
export const createString = (array: string[]): string => {
  if (array.length === 1) return array[0];

  const firstPart = join(slice(array, 0, -1), ", ");
  const lastPart = array[array.length - 1];

  return `${firstPart} and ${lastPart}`;
};
