export const DATA_TYPES = [
  "DOUBLE",
  "LONG",
  "TIMESTAMP",
  "BOOLEAN",
  "STRING",
  "SET",
  "LIST",
  "MAP",
  "BLOB",
  "EMPTY",
  "ANY"
].sort();

export const dataTypes = {
  ID: "ID",
  DOUBLE: "DOUBLE",
  LONG: "LONG",
  TIMESTAMP: "TIMESTAMP",
  BOOLEAN: "BOOLEAN",
  STRING: "STRING",
  SET: "SET",
  LIST: "LIST",
  MAP: "MAP",
  BLOB: "BLOB",
  EMPTY: "EMPTY",
  ANY: "ANY"
};

const totalRowCnt = { key: "totalRowCnt", name: "Total Row Count" };
const totalDistinct = { key: "t_uniques", name: "Total Distinct" };
const cardinalityFactor = { key: "cardinalityFactor", name: "Cardinality Factor" };
const sparseFactor = { key: "sparseFactor", name: "Sparse Factor" };
const validCnt = { key: "validCnt", name: "Valid Count" };
const nullCnt = { key: "nullCnt", name: "Null Count" };
const invalidOntologyCnt = { key: "invalidOntologyCnt", name: "Invalid Ontology Count" };
const minWordCnt = { key: "minWordCnt", name: "Min Word Count" };
const meanWordCnt = { key: "meanWordCnt", name: "Mean Word Count" };
const maxWordCnt = { key: "maxWordCnt", name: "Max Word Count" };
const minLength = { key: "minLength", name: "Min Length" };
const meanLength = { key: "minLength", name: "Mean Length" };
const maxLength = { key: "maxLength", name: "Max Length" };
const min_str = { key: "min_str", name: "Min String" };
const max_str = { key: "max_str", name: "Max String" };
const stdDev = { key: "stdDev", name: "stdDev" };
const stdDevLength = { key: "stdDevLength", name: "stdDev Length" };
const min_numeric = { key: "min_numeric", name: "Min" };
const max_numeric = { key: "max_numeric", name: "Max" };
const mean = { key: "mean", name: "Mean" };
const negativeCnt = { key: "negativeCnt", name: "Negative Count" };
const zeroCnt = { key: "zeroCnt", name: "Zero Count" };
const oneCnt = { key: "oneCnt", name: "One Count" };
const skewness = { key: "skewness", name: "Skewness" };
const mostFrequent = { key: "mostFrequent", name: "Most Frequent" };
const zeroPercentage = { key: "zeroPercentage", name: "Zero %" };
const onePercentage = { key: "onePercentage", name: "One %" };
const median = { key: "median", name: "Median" };

const STATS = [
  totalRowCnt,
  totalDistinct,
  cardinalityFactor,
  sparseFactor,
  validCnt,
  nullCnt,
  invalidOntologyCnt,
  minWordCnt,
  meanWordCnt,
  maxWordCnt,
  minLength,
  meanLength,
  maxLength,
  min_str,
  max_str,
  stdDev,
  stdDevLength,
  mostFrequent
];

const NUMBER_STATS = [
  totalRowCnt,
  totalDistinct,
  cardinalityFactor,
  sparseFactor,
  validCnt,
  nullCnt,
  invalidOntologyCnt,
  min_numeric,
  mean,
  max_numeric,
  negativeCnt,
  zeroCnt,
  oneCnt,
  skewness,
  stdDev,
  mostFrequent,
  median
];

const BOOLEAN_STATS = [
  totalRowCnt,
  totalDistinct,
  cardinalityFactor,
  sparseFactor,
  validCnt,
  nullCnt,
  invalidOntologyCnt,
  zeroCnt,
  oneCnt,
  zeroPercentage,
  onePercentage
];

export const STATS_ITEMS_BY_DATA_TYPES = {
  [dataTypes.ANY]: STATS,
  [dataTypes.ID]: STATS,
  [dataTypes.STRING]: STATS,
  [dataTypes.BLOB]: STATS,
  [dataTypes.EMPTY]: STATS,
  [dataTypes.LIST]: STATS,
  [dataTypes.MAP]: STATS,
  [dataTypes.SET]: STATS,
  [dataTypes.TIMESTAMP]: [
    totalRowCnt,
    totalDistinct,
    cardinalityFactor,
    sparseFactor,
    validCnt,
    nullCnt,
    invalidOntologyCnt,
    min_str,
    max_str
  ],
  [dataTypes.DOUBLE]: NUMBER_STATS,
  [dataTypes.LONG]: NUMBER_STATS,
  [dataTypes.BOOLEAN]: BOOLEAN_STATS
};

export const ONTOLOGY = [
  "none",
  "ip",
  "id",
  "user_id",
  "device_id",
  "order_id",
  "product_id",
  "label",
  "label_binary",
  "is_fraud",
  "name",
  "first_name",
  "last_name",
  "full_name",
  "email",
  "phone",
  "dateOfBirth",
  "age",
  "ssn",
  "time_first_seen",
  "time_last_seen",
  "time_since",
  "address",
  "address_line1",
  "address_line2",
  "city",
  "zipcode",
  "country",
  "state",
  "latitude",
  "longitude",
  "latlong",
  "currency_code",
  "amount_usd",
  "ticker_symbol",
  "company_name",
  "filename",
  "url",
  "comments",
  "product_review",
  "twitter_post"
].sort();

export const CONCEPT = [
  "NONE",
  "EMPTY",
  "CONSTANT",
  "OTHER",

  "KEY",
  "ID",

  "BINARY",
  "CATEGORICAL_SMALL",
  "CATEGORICAL_LARGE",

  "ORDINAL_SMALL",
  "ORDINAL_LARGE",

  "TIME",
  "TIME_WINDOW",

  "TEXT_SMALL",
  "TEXT_LARGE",

  "NUMERIC_LONG",
  "NUMERIC_DOUBLE",

  "NON_NUMERIC_LONG",
  "NON_NUMERIC_DOUBLE"
].sort();

export const DATE_FORMATS = [
  "yyyy-MM-dd HH:mm:ss.SSS",
  "yyyyMMdd",
  "dd-MM-yyyy",
  "yyyy-MM-dd",
  "MM/dd/yyyy",
  "yyyy/MM/dd",
  "dd MMM yyyy",
  "dd MMMM yyyy",
  "yyyyMMddHHmm",
  "yyyyMMdd HHmm",
  "dd-MM-yyyy HH:mm",
  "yyyy-MM-dd HH:mm",
  "MM/dd/yyyy HH:mm",
  "yyyy/MM/dd HH:mm",
  "dd MMM yyyy HH:mm",
  "dd MMMM yyyy HH:mm",
  "yyyyMMddHHmmss",
  "yyyyMMdd HHmmss",
  "dd-MM-yyyy HH:mm:ss",
  "yyyy-MM-dd HH:mm:ss",
  "MM/dd/yyyy HH:mm:ss",
  "yyyy/MM/dd HH:mm:ss",
  "dd MMM yyyy HH:mm:ss",
  "dd MMMM yyyy HH:mm:ss"
];

export const DEFAULT_VISIBLE_COLUMNS = {
  entityFeatures: [
    "Name & Type",
    "Data Type",
    "Concept",
    "Ontology",
    "RefEntity",
    "Primary Key",
    "Timestamp?"
  ],
  transforms: ["Enabled", "Entity", "Output", "Transform"]
};

export const ALL_COLUMNS = {
  entityFeatures: [
    ...DEFAULT_VISIBLE_COLUMNS.entityFeatures,
    "Collection",
    "Sparse",
    "Lineage",
    "Source",
    "Secure?",
    "Target?",
    "Timestamp Format"
  ],
  transforms: [...DEFAULT_VISIBLE_COLUMNS.transforms]
};

export const STEPS_FEATURE = [
  { name: "Transform Data", route: "entities" },
  { name: "Run Pipeline", route: "pipeline-settings" },
  { name: "Validate Output", route: "pipeline-output" },
  { name: "Build Model", route: "ml" }
];

export const CUSTOM_TRANSFORMS = {
  DELETE_NULLS: "filter_null",
  ONE_HOT_ENCODING: "one_hot_encoding"
};

export const tenantAvatarPalettes = [
  {
    backgroundColor: "#123553",
    color: "#fff"
  },
  {
    backgroundColor: "#415F80",
    color: "#fff"
  },
  {
    backgroundColor: "#ECEFF2",
    color: "#000"
  },
  {
    backgroundColor: "#f1957d",
    color: "#fff"
  },
  {
    backgroundColor: "#828282",
    color: "#fff"
  }
];

export const DISABLED_RECIPE_LOGS = "To check logs, please save and run the recipe";

export enum RecipeStatuses {
  UnBuilt = "UNBUILT",
  Running = "RUNNING",
  Success = "SUCCESS",
  Error = "ERROR"
}
export const READONLY_ENTITY = "This action is only supported in default scenario.";
