import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

export const enum UseGetRolesQueryKeys {
  Roles = "roles"
}

export interface IRole {
  name: string;
  id: string;
  created: number;
  creator: string;
  updater: string;
  updated: number;
  standard: boolean;
  permissions: string[];
}

export const useGetRoles = (options?: UseQueryOptions): UseQueryResult<IRole[] | any> =>
  useQuery({
    queryKey: [UseGetRolesQueryKeys.Roles],
    queryFn: async () => await getAPIWithRethrow("/v2/role/all"),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    enabled: false,
    ...options
  });
