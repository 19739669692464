/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Context
 */
export interface Context {
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'datasetContext'?: ContextDatasetContextEnum;
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'artifactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Context
     */
    'dataappChatGroupId'?: string;
}

export const ContextDatasetContextEnum = {
    Dataset: 'DATASET',
    RapidModelRecipe: 'RAPID_MODEL_RECIPE'
} as const;

export type ContextDatasetContextEnum = typeof ContextDatasetContextEnum[keyof typeof ContextDatasetContextEnum];


