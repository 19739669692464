import React, { useState, useMemo } from "react";
import _ from "lodash";
import { Box, Grid, Button, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

// Icons
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { MagicGenerateIcon } from "src/icons/NewUX/MagicGenerateIcon";

import DeleteGeneratedContentModal from "./DeleteGeneratedContentModal";
import GeneratingAboutContent from "./GeneratingAboutContent";
import MarkdownDisplay from "src/components/MarkdownDisplay";
import NoAboutContent from "./NoAboutContent";
import UpdateGeneratedContentModal from "./UpdateGeneratedContentModal";
import useGenerateAboutContent from "../hooks/useGenerateAboutContent";
import useUpdateProject from "hooks/api/projects/useUpdateProject";
import { Spinner } from "src/components";
import { handleResponse } from "src/utils/apiService";
import { useGetProject } from "hooks/api";
import { UseGetProjectQueryKeys } from "src/hooks/api/projects/useGetProject";
import useAuthStore from "src/stores/auth.store";
import { useProjectsStore } from "src/store/store";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import { AskAiA11yBackdrop } from "src/components/custom";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

const useStyles = makeStyles({
  pageContainer: {
    // For Project Sub-Navbar
    marginLeft: 50,
    backgroundColor: "#f5f5f5",
    padding: 16,
    position: "relative"
  },
  markdownContainer: {
    background: "#FFF",
    padding: "16px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px"
  }
});

const About: React.FC = () => {
  const { projectId } = useParams<$TSFixMe>();

  const askAiEnabled = useAuthStore((state) => state.askAiEnabled);

  // Project context
  const { project } = useProjectContext() || {};

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [askAiA11yChecked, setAskAiA11yChecked] = useState(false);

  const classes = useStyles();
  const queryClient = useQueryClient();
  const generateContent = useGenerateAboutContent();
  const updateProject = useUpdateProject();
  const [generatingAboutProjects, setGeneratingAboutProjects] = useProjectsStore((state) => [
    state.generatingAboutProjects,
    state.setGeneratingAboutProjects
  ]);

  const { data, isLoading } = useGetProject({
    projectId,
    cacheTime: Infinity,
    refetchOnMount: true
  });

  const isAlreadyGenerating = useMemo(() => {
    return _.includes(generatingAboutProjects, projectId);
  }, [generatingAboutProjects, projectId]);

  const handleGenerate = (isUpdated: boolean) => {
    setGeneratingAboutProjects(projectId ? _.uniq([...generatingAboutProjects, projectId]) : []);
    generateContent.mutate(
      {
        projectId,
        type: "PROJECT",
        isUpdated,
        invalidateAboutPage: true,
        projectName: data?.name
      },
      {
        onSuccess: () => {
          setOpenUpdateModal(false);
        }
      }
    );
  };

  const handleDelete = () => {
    if (data) {
      updateProject.mutate(
        // @ts-ignore
        {
          ...data,
          markdown: ""
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([UseGetProjectQueryKeys.Project]);
            handleResponse({
              successMessage: `About Page content for the project ${data?.name} has been deleted successfully`
            });
            handleCancel();
          }
        }
      );
    }
  };

  const handleCancel = () => {
    setOpenDeleteModal(false);
  };

  const handleUpdateCancel = () => {
    setOpenUpdateModal(false);
  };

  const handleClick = () => {
    if (data?.markdown) {
      setOpenUpdateModal(true);
    } else {
      handleGenerate(false);
    }
  };

  const handleDeleteOpen = () => {
    setOpenDeleteModal(true);
  };

  return (
    <NewThemeWrapper>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: <SubTopNavBarBreadcrumbs project={project} />
        }}
        subTopNavBarRightSection={{
          moreOptions: [
            {
              label: "Delete",
              icon: <TrashIcon viewBox="0 0 20 20" />,
              action: handleDeleteOpen,
              isDisabled: !data?.markdown
            }
          ],
          ...(!isLoading
            ? {
                component: (
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      setAskAiA11yChecked(() => true);
                      !!askAiEnabled && handleClick();
                    }}
                    disabled={generateContent.isLoading || (isAlreadyGenerating && !data?.markdown)}
                    startIcon={<MagicGenerateIcon />}>
                    {data?.markdown ? "Update Content" : "Generate Content"}
                  </Button>
                )
              }
            : {})
        }}
      />

      <Grid item xs={12} className={classes.pageContainer}>
        {askAiA11yChecked && !askAiEnabled && <AskAiA11yBackdrop />}

        {isLoading ? (
          <Spinner />
        ) : (
          <Box className={classes.markdownContainer}>
            {data?.markdown ? (
              <MarkdownDisplay string={data?.markdown} />
            ) : generateContent.isLoading || isAlreadyGenerating ? (
              <GeneratingAboutContent />
            ) : (
              <NoAboutContent
                onGenerate={() => {
                  setAskAiA11yChecked(() => true);
                  !!askAiEnabled && handleGenerate(false);
                }}
              />
            )}
          </Box>
        )}
      </Grid>
      <DeleteGeneratedContentModal
        open={openDeleteModal}
        onCancel={handleCancel}
        loading={updateProject.isLoading}
        onSubmit={handleDelete}
      />
      <UpdateGeneratedContentModal
        open={openUpdateModal}
        onCancel={handleUpdateCancel}
        loading={generateContent.isLoading}
        onSubmit={() => handleGenerate(true)}
      />
    </NewThemeWrapper>
  );
};

export default About;
