import React from "react";

export const DataAnalysisIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14855_118196)">
        <path
          d="M3.79964 11.6657H12.6229C13.9981 11.6657 14.7219 10.9419 14.7219 9.56667V0.808594H1.69336V9.56667C1.7006 10.9419 2.42441 11.6657 3.79964 11.6657Z"
          stroke="#292D32"
          strokeWidth="1.08572"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.976562 0.808594H15.4527"
          stroke="#292D32"
          strokeWidth="1.08572"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.31836 15.285L8.21361 13.8374V11.666"
          stroke="#292D32"
          strokeWidth="1.08572"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1101 15.2855L8.21484 13.8379"
          stroke="#292D32"
          strokeWidth="1.08572"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.95703 7.32379L7.23705 5.42019C7.418 5.26819 7.65685 5.31159 7.7799 5.51424L8.64845 6.96189C8.7715 7.16454 9.01035 7.20074 9.1913 7.05599L11.4713 5.15234"
          stroke="#292D32"
          strokeWidth="1.08572"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14855_118196">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
