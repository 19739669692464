import { MoreOptions, OverflowTooltip } from "src/components";
import { useDeleteAIGuideThread } from "src/hooks/api/aiguide/useDeleteAIGuideThread";
import { handleResponse } from "src/utils/apiService";
import { CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { useAIGuideStore } from "src/store/store";

import _ from "lodash";
import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";

type StyleProps = {
  isActive: boolean;
  isHovered: boolean;
  isDeleting: boolean;
};
const useStyles = makeStyles<undefined, StyleProps>({
  chatName: {
    padding: "0px 16px 12px 16px"
  },
  chatRow: {
    whiteSpace: "nowrap",
    justifyContent: "space-between",
    background: ({ isActive, isHovered }) => (isActive || isHovered ? "#F2F2FF" : "#fff"),
    border: ({ isActive, isHovered }) =>
      isActive ? `1px solid #4646B5` : isHovered ? "1px solid #F2F2FF" : "1px solid #fff",
    color: ({ isActive }) => (isActive ? "#4646B5" : "#515151"),
    boxShadow: "none",
    borderRadius: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    fontWeight: 400,
    minHeight: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center"
  },
  chatText: {
    fontWeight: 400
  },
  chatNames: {
    gap: "16px"
  },
  delete: {
    fontSize: "14px"
  },
  deleteTitle: {
    color: "#515151",
    height: "18px",
    margin: "4px 0px",
    fontSize: "12px"
  },
  chatLabelWrap: {
    maxWidth: "calc(100% - 36px)",
    flex: 1
  },
  moreOptions: {
    flex: 0
  },
  deleteTxt: {
    color: "#515151",
    height: "18px",
    margin: "4px 0px",
    fontSize: "12px"
  },
  deleteRow: {
    gap: ({ isDeleting }) => (isDeleting ? "12px" : "0px")
  }
});

const AIGuideChat = ({
  isActive,
  chatName,
  onChatChange,
  isLoading,
  threadId,
  onDeleteSuccess,
  projectId
}: {
  isLoading: boolean;
  chatName: string;
  isActive: boolean;
  onChatChange: () => void;
  threadId: string;
  onDeleteSuccess: (threadId: string) => void;
  projectId: string;
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const deleteAIGuideThread = useDeleteAIGuideThread();
  const isDeleting = deleteAIGuideThread.isLoading;
  const classes = useStyles({ isActive, isHovered, isDeleting });

  const generatingState = useAIGuideStore((state) => state.generatingState);
  const generateState = _.get(generatingState, threadId!);
  const isGenerating: boolean = generateState?.isGenerating || generateState?.isDisabled || false;

  const onDeleteChat = () => {
    deleteAIGuideThread.mutate(
      { threadId, projectId },
      {
        onSuccess: () => {
          onDeleteSuccess(threadId);
          handleResponse({ successMessage: `Chat with name- ${chatName} deleted successfully.` });
        }
      }
    );
  };
  const moreOptions = [
    {
      id: "delete-chat",
      label: (
        <Grid container direction="row" className={classes.deleteRow}>
          <Typography className={classes.deleteTxt}>Delete</Typography>
          {isDeleting && <CircularProgress size="14" />}
        </Grid>
      ),
      minWidth: "24px !important",
      click: () => onDeleteChat(),
      icon: <DeleteOutline style={{ fontSize: "14px" }} />,
      tooltip: isGenerating ? "Query run in progress" : "",
      disabled: isDeleting || isGenerating,
      dataTestId: "delete-ai-guide-chat"
    }
  ];

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      onClick={() => onChatChange()}
      className={classes.chatNames}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Grid container direction="row" wrap="nowrap">
        <Grid container direction="row" wrap="nowrap" className={classes.chatName}>
          <Grid container direction="row" wrap="nowrap" className={classes.chatRow}>
            <Grid item className={classes.chatLabelWrap}>
              <Grid container direction="column" wrap="nowrap">
                <Typography variant="subtitle2" className={classes.chatText}>
                  {isLoading ? (
                    <Skeleton width="100%" />
                  ) : (
                    <OverflowTooltip value={chatName} testId="ai-guide-chat-name" />
                  )}
                </Typography>
              </Grid>
            </Grid>
            {isHovered && (
              <Grid item>
                <MoreOptions
                  testId="ai-chat-more-options-btn"
                  options={moreOptions}
                  menuProps={{
                    MenuListProps: {
                      disableListWrap: true,
                      disablePadding: true
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(AIGuideChat);
