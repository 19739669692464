import { useEffect } from "react";

import {
  Criteria,
  FileUploadStatuses,
  DatasetKeys,
  OntologyDatasetStatuses
} from "../utils/Dataset.constants";

import useStoreSelectors from "./useStoreSelectors";
import useHelpers from "./useHelpers";
import useFilesObserver from "./useFilesObserver";
import { useUploadContext } from "../contexts/Upload/useUploadContext";
import useDatasetsObserver from "./useDatasetsObserver";

const useFooterActionsObserver = () => {
  const { isUploadDisabled, ontologyDatasetIndex } = useUploadContext();

  const {
    isFilesSelected,
    isFilesUploadProcessing,
    isFilesWithSignedUrl,
    isFilesUploadDone,
    isFilesUploadFulfilled
  } = useFilesObserver();

  const { isOntologyDatasetsUpdated } = useDatasetsObserver();

  const { source, isDataSourcesFilesUpload, isUploadControlProcessing } = useHelpers();

  // Stores - STARTS >>
  const {
    datasetCriterionStore,
    datasetFilesStore,
    datasetSelectedDataSourceFilesStore,
    datasetDatasetsStore,
    datasetIsDeletingEntitiesStore,
    datasetIsOntologyStore,
    setDatasetIsOntologyStore,
    datasetIsFetchingOntologyDataStore,
    datasetFooterActionsStore,
    setDatasetFooterActionsStore,
    datasetIsResettingStore,
    datasetIsFetchingOntologySchemaData
  } = useStoreSelectors();
  // << ENDS - Stores

  // Observable facets - STARTS >>
  // Files upload >>
  useEffect(() => {
    if (!datasetIsOntologyStore && !isDataSourcesFilesUpload && !source?.isSql) {
      const thisDatasetFooterActionsStore = datasetFooterActionsStore;

      // Upload >>
      thisDatasetFooterActionsStore.upload.isHidden = true;
      thisDatasetFooterActionsStore.upload.isDisabled = true;
      // << Upload

      // Next >>
      thisDatasetFooterActionsStore.next.isHidden = true;
      thisDatasetFooterActionsStore.next.isDisabled = true;
      // << Next

      if (isFilesSelected) {
        let thisIsFilesUploadDone: boolean = false;
        // Enabling Next button in the below cases:
        // Case #1: If criterion is not segregate + not all csv files + all files upload done
        // Case #2: If criterion is not segregate + all csv files + all files are with signed url status
        // Case #3: If criterion is segregate + (if csv file then file upload done | if not csv file then file is with signed url status)

        if (datasetCriterionStore?.value === Criteria.Segregate) {
          let uploadedFilesCounter: number = 0;

          datasetFilesStore.forEach((eachFile: $TSFixMe) => {
            // if (schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase())) {
            if ([FileUploadStatuses.SignedUrl].includes(eachFile?.status)) {
              uploadedFilesCounter++;
            }
          });

          thisIsFilesUploadDone = uploadedFilesCounter === datasetFilesStore?.length;
        } else {
          thisIsFilesUploadDone = isFilesWithSignedUrl;
        }

        if (thisIsFilesUploadDone) {
          // thisDatasetFooterActionsStore.upload.isHidden = true;
          // thisDatasetFooterActionsStore.upload.isDisabled = true;

          // thisDatasetFooterActionsStore.next.isHidden = false;
          // thisDatasetFooterActionsStore.next.isDisabled = false;

          setDatasetIsOntologyStore(true);
        } else {
          thisDatasetFooterActionsStore.upload.isHidden = false;
          thisDatasetFooterActionsStore.upload.isDisabled = !!isUploadDisabled;

          thisDatasetFooterActionsStore.cancel.isDisabled = isFilesUploadProcessing;
        }
      }

      setDatasetFooterActionsStore({ ...thisDatasetFooterActionsStore });
    }
  }, [
    datasetIsOntologyStore,
    isDataSourcesFilesUpload,
    source,
    isFilesSelected,
    datasetCriterionStore,
    datasetFilesStore,
    isFilesUploadProcessing,
    isFilesWithSignedUrl,
    isFilesUploadDone,
    isUploadDisabled
  ]);
  // << Files upload

  // Data sources files upload >>
  useEffect(() => {
    if (!datasetIsOntologyStore && isDataSourcesFilesUpload && !source?.isSql) {
      const thisDatasetFooterActionsStore = datasetFooterActionsStore;

      // Data sources previous >>
      thisDatasetFooterActionsStore.dataSourcesPrevious.isHidden = true;
      thisDatasetFooterActionsStore.dataSourcesPrevious.isDisabled = true;
      // << Data sources previous

      // Next >>
      thisDatasetFooterActionsStore.upload.isHidden = true;
      thisDatasetFooterActionsStore.upload.isDisabled = true;
      // << Next

      // Next >>
      thisDatasetFooterActionsStore.next.isHidden = true;
      thisDatasetFooterActionsStore.next.isDisabled = true;
      // << Next

      if (isFilesSelected) {
        thisDatasetFooterActionsStore.dataSourcesNext.isHidden = true;
        thisDatasetFooterActionsStore.dataSourcesNext.isDisabled = true;

        if (isFilesUploadFulfilled) {
          // thisDatasetFooterActionsStore.dataSourcesPrevious.isHidden = true;
          // thisDatasetFooterActionsStore.dataSourcesPrevious.isDisabled = true;

          // thisDatasetFooterActionsStore.upload.isHidden = true;
          // thisDatasetFooterActionsStore.upload.isDisabled = true;

          // thisDatasetFooterActionsStore.next.isHidden = false;
          // thisDatasetFooterActionsStore.next.isDisabled = false;

          setDatasetIsOntologyStore(true);
        } else {
          thisDatasetFooterActionsStore.dataSourcesPrevious.isHidden = false;
          thisDatasetFooterActionsStore.dataSourcesPrevious.isDisabled = isUploadControlProcessing;

          thisDatasetFooterActionsStore.upload.isHidden = false;
          thisDatasetFooterActionsStore.upload.isDisabled = !!isUploadDisabled;
        }
      } else {
        const isDatasetSelectedDataSourceFilesStore =
          (datasetSelectedDataSourceFilesStore || [])?.length > 0;

        thisDatasetFooterActionsStore.dataSourcesPrevious.isHidden = true;
        thisDatasetFooterActionsStore.dataSourcesPrevious.isDisabled = true;

        thisDatasetFooterActionsStore.dataSourcesNext.isHidden =
          !isDatasetSelectedDataSourceFilesStore;
        thisDatasetFooterActionsStore.dataSourcesNext.isDisabled =
          !isDatasetSelectedDataSourceFilesStore;
      }

      setDatasetFooterActionsStore({ ...thisDatasetFooterActionsStore });
    }
  }, [
    datasetIsOntologyStore,
    isDataSourcesFilesUpload,
    source,
    isFilesSelected,
    isFilesUploadDone,
    isFilesUploadFulfilled,
    isUploadControlProcessing,
    isUploadDisabled,
    datasetSelectedDataSourceFilesStore
  ]);
  // << Data sources files upload

  // Ontology >>
  useEffect(() => {
    if (datasetIsOntologyStore && !source?.isSql) {
      const thisDatasetFooterActionsStore = datasetFooterActionsStore;

      // Next >>
      thisDatasetFooterActionsStore.next.isHidden = true;
      thisDatasetFooterActionsStore.next.isDisabled = true;
      // << Next

      // Checking disability of next or create dataset >>
      const datasetDatasetsStoreLength = (datasetDatasetsStore || [])?.length;
      const datasetOntologyConfig =
        datasetDatasetsStore?.[ontologyDatasetIndex]?.[DatasetKeys.OntologyConfig];

      const isOntologyNextOrDoneDisabled =
        datasetIsFetchingOntologyDataStore ||
        [OntologyDatasetStatuses.Updating].includes(datasetOntologyConfig?.[DatasetKeys.Status]);
      // << Checking disability of next or create dataset

      const isDone =
        datasetDatasetsStoreLength === ontologyDatasetIndex + 1 && isOntologyDatasetsUpdated;

      const isNavigation =
        datasetCriterionStore?.value === Criteria.Segregate && datasetDatasetsStoreLength > 1;

      if (isNavigation) {
        // Previous >>
        thisDatasetFooterActionsStore.ontologyPrevious.isHidden =
          // isDone || ontologyDatasetIndex === 0;
          ontologyDatasetIndex === 0;
        thisDatasetFooterActionsStore.ontologyPrevious.isDisabled =
          // isDone ||
          ontologyDatasetIndex === 0 || isOntologyNextOrDoneDisabled;
        // << Previous

        // Next >>
        thisDatasetFooterActionsStore.ontologyNext.isHidden =
          datasetDatasetsStoreLength === ontologyDatasetIndex + 1;
        thisDatasetFooterActionsStore.ontologyNext.isDisabled =
          datasetDatasetsStoreLength === ontologyDatasetIndex + 1 || isOntologyNextOrDoneDisabled;
        // << Next

        // Ontology done >>
        thisDatasetFooterActionsStore.ontologyClose.isHidden =
          // isDone || datasetDatasetsStoreLength > ontologyDatasetIndex + 1;
          datasetDatasetsStoreLength > ontologyDatasetIndex + 1;
        thisDatasetFooterActionsStore.ontologyClose.isDisabled =
          // isDone ||
          datasetIsResettingStore ||
          datasetDatasetsStoreLength > ontologyDatasetIndex + 1 ||
          isOntologyNextOrDoneDisabled;
        // << Ontology done
      } else {
        // Ontology done >>
        // thisDatasetFooterActionsStore.ontologyClose.isHidden = isDone;
        thisDatasetFooterActionsStore.ontologyClose.isHidden = false;
        thisDatasetFooterActionsStore.ontologyClose.isDisabled =
          // isDone || isOntologyNextOrDoneDisabled;
          datasetIsResettingStore || isOntologyNextOrDoneDisabled;
        // << Ontology done
      }

      // Cancel >>
      thisDatasetFooterActionsStore.cancel.isHidden = isDone;
      thisDatasetFooterActionsStore.cancel.isDisabled =
        isFilesUploadProcessing || isDone || datasetIsDeletingEntitiesStore;
      // << Cancel

      setDatasetFooterActionsStore({ ...thisDatasetFooterActionsStore });
    }
  }, [
    datasetIsOntologyStore,
    source,
    datasetDatasetsStore,
    ontologyDatasetIndex,
    datasetCriterionStore,
    datasetIsFetchingOntologyDataStore,
    isOntologyDatasetsUpdated,
    datasetIsDeletingEntitiesStore,
    isFilesUploadProcessing,
    datasetIsResettingStore
  ]);
  // << Ontology

  // SQL data sources files upload >>
  useEffect(() => {
    if (source?.isSql) {
      const thisDatasetFooterActionsStore = datasetFooterActionsStore;

      const dataset: $TSFixMe = datasetDatasetsStore?.[0];
      const datasetOntologyConfig = dataset?.[DatasetKeys.OntologyConfig];

      if (datasetIsOntologyStore) {
        thisDatasetFooterActionsStore.next.isHidden = true;
        thisDatasetFooterActionsStore.next.isDisabled = true;

        thisDatasetFooterActionsStore.cancel.isHidden = isOntologyDatasetsUpdated;
        thisDatasetFooterActionsStore.cancel.isDisabled = isOntologyDatasetsUpdated;

        // Ontology done >>
        const isOntologyCloseDisabled =
          datasetIsFetchingOntologyDataStore ||
          datasetIsFetchingOntologySchemaData ||
          datasetOntologyConfig?.[DatasetKeys.Status] === OntologyDatasetStatuses.Updating;

        thisDatasetFooterActionsStore.ontologyClose.isHidden = false;
        thisDatasetFooterActionsStore.ontologyClose.isDisabled =
          // isOntologyDatasetsUpdated || isOntologyCloseDisabled;
          isOntologyCloseDisabled;
        // << Ontology done
      } else {
        thisDatasetFooterActionsStore.ontologyClose.isHidden = true;
        thisDatasetFooterActionsStore.ontologyClose.isDisabled = true;

        // Next >>
        let isNextDisabled = false;
        isNextDisabled = isNextDisabled || !dataset?.id;
        isNextDisabled = isNextDisabled || dataset?.[DatasetKeys.IsDirty];
        isNextDisabled = isNextDisabled || !dataset?.[DatasetKeys.IsValid];
        isNextDisabled = isNextDisabled || datasetOntologyConfig?.[DatasetKeys.IsSqlFieldDirty];
        isNextDisabled = isNextDisabled || !datasetOntologyConfig?.[DatasetKeys.IsSqlConfigValid];
        isNextDisabled =
          isNextDisabled ||
          datasetOntologyConfig?.[DatasetKeys.Status] === OntologyDatasetStatuses.Deleting;

        isNextDisabled = isNextDisabled || datasetIsFetchingOntologyDataStore;

        thisDatasetFooterActionsStore.next.isHidden = false;
        thisDatasetFooterActionsStore.next.isDisabled = isNextDisabled;
        // << Next
      }

      setDatasetFooterActionsStore({ ...thisDatasetFooterActionsStore });
    }
  }, [
    source,
    datasetDatasetsStore,
    datasetIsOntologyStore,
    isOntologyDatasetsUpdated,
    datasetIsFetchingOntologyDataStore,
    datasetIsFetchingOntologySchemaData
  ]);
  // << SQL data sources files upload
  // << ENDS - Observable facets

  return {};
};

export default useFooterActionsObserver;
