import useTenantsStore from "src/stores/tenant-management.store";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { patchAPIWithRethrow } from "src/utils/apiService";

type IVariables = {
  userId: string;
  userType: "USER" | "INVITED";
  roleId: string;
};

export const useUpdateUserMutation = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  const [toggleTenantsRefresh] = useTenantsStore((state) => [state.toggleTenantsRefresh]);
  return useMutation({
    mutationFn: async (body) => {
      const updatedUser = await patchAPIWithRethrow("/tenants/current/user", body);
      updatedUser && toggleTenantsRefresh();
      return updatedUser;
    }
  });
};
