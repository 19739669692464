import React from "react";

export const ScenarioSettings = ({
  width = 24,
  height = 24,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2H11.5H10C7.19974 2 5.79961 2 4.73005 2.54497C3.78924 3.02433 3.02433 3.78924 2.54497 4.73005C2 5.79961 2 7.19974 2 10V14C2 16.8003 2 18.2004 2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455C5.79961 22 7.19974 22 10 22H14C16.8003 22 18.2004 22 19.27 21.455C20.2108 20.9757 20.9757 20.2108 21.455 19.27C22 18.2004 22 16.8003 22 14V12.5V12M17 4C16.75 5.75 18.25 7.25 20 7M11 13L11.5701 10.7198C11.7301 10.0797 11.8101 9.7596 11.9388 9.46119C12.053 9.19626 12.1956 8.94448 12.364 8.71022C12.5536 8.44636 12.7869 8.21308 13.2535 7.74651L17.5 3.5C17.5797 3.42035 17.6195 3.38052 17.6547 3.34847C18.4176 2.65508 19.5824 2.65508 20.3453 3.34847C20.3805 3.38052 20.4203 3.42035 20.5 3.5V3.5C20.5797 3.57965 20.6195 3.61948 20.6515 3.65474C21.3449 4.41756 21.3449 5.58244 20.6515 6.34526C20.6195 6.38052 20.5797 6.42035 20.5 6.5L16.2535 10.7465C15.7869 11.2131 15.5536 11.4464 15.2898 11.636C15.0555 11.8044 14.8037 11.947 14.5388 12.0612C14.2404 12.1899 13.9203 12.2699 13.2802 12.4299L11 13Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
