import { Grid, Typography } from "@material-ui/core";
import { lowerCase } from "lodash";
import React from "react";
import { PublicScreenLabels } from "src/constants";
import { useStyles } from "./SignIn.styles";

const BoldText = ({ text }: { text: string }) => (
  <Typography variant="inherit" style={{ fontWeight: 500 }}>
    {text}
  </Typography>
);

export const WelcomeMessage = ({ isSignUpFlow }: { isSignUpFlow: boolean }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.messageContainer}>
      <Typography
        variant={isSignUpFlow ? "subtitle1" : "h6"}
        component="div"
        align="center"
        style={{ fontWeight: 400 }}
        data-testid="signInMessageLine1">
        {isSignUpFlow ? (
          <>
            To join the tenant, {lowerCase(PublicScreenLabels.SignIn)} to{" "}
            <BoldText text="RapidCanvas" />
          </>
        ) : (
          <>
            Welcome to <BoldText text="RapidCanvas" />
          </>
        )}
      </Typography>
    </Grid>
  );
};
