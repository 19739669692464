import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";

import { AskAIOutputWrapContainer } from "./AskAIOutputWrapContainer";
import { AskAICodeEditor } from "src/pages/Projects/common/AskAICodeEditor";
import { marked } from "marked";

type Props = {
  editorValue: string;
  toggleExpanded?: () => void;
  buttons?: $TSFixMe;
  background?: string;
  title?: string;
  errDetails?: {
    lineNo: number;
    lineOfCode: string;
    errorExplanation?: string;
  } | null;
  errorDescription?: string | null;
};

export const AskAICodeContainer = ({
  editorValue,
  toggleExpanded,
  buttons,
  background,
  title = "Python",
  errDetails,
  errorDescription
}: Props) => {
  const [htmlContent, setHtmlContent] = React.useState<string>("");

  useEffect(() => {
    const fetchHtmlContent = async () => {
      const htmlString = await marked.parse(title);
      setHtmlContent(htmlString);
    };

    fetchHtmlContent();
  }, [title]);

  return (
    <Grid container test-id="ask-ai-modal-code-container">
      <AskAIOutputWrapContainer
        name={htmlContent ? <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> : title}
        buttons={buttons}
        onClick={() => toggleExpanded?.()}
        background={background}>
        <AskAICodeEditor
          editorValue={editorValue}
          errDetails={errDetails}
          errorDescription={errorDescription}
        />
      </AskAIOutputWrapContainer>
    </Grid>
  );
};
