// Packages
import { generatePath, useLocation, useNavigate } from "react-router-dom";

// Stores
import { useDrawerStore } from "src/store/store";
import { sideComponentSetter } from "src/store/store.selectors";

// Components
import { ModelModalV2 } from "src/components/Canvas/CanvasDrawerItems/ModelModalV2";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";

// Types
import { NodeData } from "src/types";
import { WebPaths } from "src/routing/routes";

type Props = {
  data: NodeData;
  disabledMessage: string;
};

const useActions = (props: Props) => {
  const { data, disabledMessage } = props || {};

  const location = useLocation();
  const navigate = useNavigate();

  // Stores - STARTS >>
  const setSideDrawerStore = useDrawerStore(sideComponentSetter);
  // << ENDS - Stores

  const onClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!data?.isJobCanvasPath) {
        return;
      }
    }

    const drawerData = {
      ...data,
      scenarioId: data?.scenarioId
    };

    setSideDrawerStore({
      sideComponent: ModelModalV2,
      sideComponentProps: { noHeader: true, data: drawerData }
    });
  };

  const onDoubleClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage) {
      return;
    }

    if (!data?.name) {
      return;
    }

    let path = generatePath(WebPaths.ModelDetails, {
      modelName: data?.name
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId"]) ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobModels}`, {
        projectId: data?.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        modelName: data?.name
      });
    }

    !!path && navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  return { onClick, onDoubleClick };
};

export default useActions;
