import React from "react";
import { Badge, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";

import { AiGuideIcon } from "src/icons/NewUX";
import { Spinner } from "src/components";
import { useThreadsStore } from "src/store/store";

interface IProps {
  onClick: () => void;
  isLoading: boolean;
  projectId: string;
}

const useStyles = makeStyles({
  spinner: {
    border: "2px solid #ff653d",
    borderRadius: "50%"
  }
});
const AIGuideBtn: React.FC<IProps> = (props) => {
  const { projectId, isLoading, onClick } = props;
  const classes = useStyles();
  const { checkIfProjectHasMessages } = useThreadsStore();

  const hasMessages = checkIfProjectHasMessages(projectId);
  return (
    <Tooltip title="RapidCanvas AI Guide" placement="top">
      <IconButton
        aria-label="ai-guide"
        test-id="ai-guide-btn"
        size="small"
        style={{ padding: 0 }}
        onClick={() => onClick()}>
        <Badge
          variant="dot"
          color="error"
          invisible={!hasMessages}
          overlap="circular"
          badgeContent="">
          {isLoading ? (
            <Spinner size={20} padding="10px" className={classes.spinner} />
          ) : (
            <Typography color="primary">
              <AiGuideIcon />
            </Typography>
          )}
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default AIGuideBtn;
