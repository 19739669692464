import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  makeStyles,
  Grid
} from "@material-ui/core";
import { generatePath, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import { TrashIcon } from "src/icons/NewUX/TrashIcon";

import { NodeTypes, NodeTypesIconMapping } from "src/pages/private/ProjectsModule/utils";
import { RenderText } from "src/utils";

import DeleteNodeModal from "../../../../../pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import DrawerAbout from "../DrawerAbout";
import DrawerAccordion from "../DrawerAccordion";
import useEntityDetails, { QUERY_KEY_ENTITY_DETAILS } from "hooks/api/entities/useEntityDetails";
import { Spinner } from "../../../..";
import { dateFormat } from "../../../../../utils/dateFormat";
import { handleResponse } from "../../../../../utils/apiService";
import { getNodes, sideComponentSetter } from "../../../../../store/store.selectors";
import { updateEntityWithRethrow } from "src/api";
import { useCanvasStore, useDrawerStore } from "../../../../../store/store";
import { useStyles } from "../styling";
import { validateNameField } from "src/utils/formFieldUtils";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import ChartReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/Chart/ChartReadonly";
import { toastWrapper } from "src/utils/toastWrapper";
import { WebPaths } from "src/routing/routes";
import { EyeIcon } from "src/icons/NewUX";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";

const useNodesListStyles = makeStyles({
  chartsLisContainer: { width: "100%", overflowY: "auto" },
  container: { paddingLeft: 0, paddingRight: 0 },
  icon: { minWidth: 30 },
  text: { wordBreak: "break-word" }
});

// const FONT_SIZE = 14;

export const ChartModal = ({ data }: $TSFixMe) => {
  const classes = useStyles();
  const nodesListStyles = useNodesListStyles();
  const { isJobCanvas, itemId, projectId, label, scenarioId, isCustomScenario } = data || {};
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);

  const { data: entityDetails, isLoading } = useEntityDetails(
    itemId,
    data?.scenarioId,
    data?.jobProps?.jobRunId,
    {
      enabled: !!data.itemId,
      cacheTime: Infinity,
      refetchOnMount: true
    }
  );

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const setReloadTrigger = useCanvasStore((state) => state.setReloadTrigger);

  const nodes = useCanvasStore(getNodes);

  const currentNode = useMemo(
    () => nodes?.find((node: $TSFixMe) => node.id === itemId),
    [itemId, nodes]
  );

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const updateChartName = async (name: string) => {
    const { isValid, error } = validateNameField({
      fieldName: name.trim(),
      fieldNameLabel: `chart name`,
      maxFieldLength: 64
    });
    if (!isValid && error) {
      handleResponse({ errorMessage: error });
      setShouldResetName(Date.now());
      return;
    }

    try {
      const response = await updateEntityWithRethrow({
        displayName: name,
        name: name,
        id: data.itemId
      });
      if (response && !response.msg) {
        setReloadTrigger();
        toastWrapper({
          type: "success",
          content: "Chart Name updated successfully."
        });
        queryClient.invalidateQueries([QUERY_KEY_ENTITY_DETAILS]);
      } else {
        setShouldResetName(Date.now());
      }
    } catch (error: $TSFixMe) {
      console.error(error);
      setShouldResetName(Date.now());
    }
  };

  const getNavigatePath = () => {
    if (!!data?.jobProps) {
      return generatePath(`${WebPaths.JobRoutes}${WebPaths.JobChart}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        chartId: data.itemId
      });
    } else {
      return generatePath(WebPaths.Charts, {
        projectId,
        scenarioId: scenarioId || data?.scenarioId,
        chartId: itemId
      });
    }
  };

  const handleDashboardClick = React.useCallback(() => {
    navigate(getNavigatePath());
  }, [history, itemId, projectId, scenarioId, data?.scenarioId, data?.jobProps]);

  const onClose = () =>
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

  return (
    <NewThemeWrapper>
      <div className={classes.drawerContent}>
        <DeleteNodeModal
          open={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          nodeId={itemId}
          nodeName={label}
          nodeType="chart"
          onAfterSubmit={() => setSideComponent({ sideComponent: null, sideComponentProps: null })}
        />

        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <>
                <ChartReadonly data={data} />

                {!!data?.isJobCanvas || isCustomScenario ? (
                  <RenderText
                    color="textSecondary"
                    isOverflowTooltip
                    linkTo={getNavigatePath()}
                    data-testid="sidebar-chart-name">
                    {currentNode?.displayName}
                  </RenderText>
                ) : (
                  <EditName
                    key="edit-name"
                    fieldNameLabel="chart name"
                    inputValue={currentNode?.displayName}
                    onSave={updateChartName}
                    isResetName={shouldResetName}
                    containerProps={{
                      style: {
                        maxWidth: 200
                        // width: ((currentNode?.displayName || "")?.length + 1) * (FONT_SIZE - 4) + 16
                      }
                    }}
                  />
                )}
              </>
            )
          }}
        />

        <div className={classes.content} style={{ marginTop: 44 }}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Card className={classes.cardMargin}>
                <CardContent>
                  <Grid container direction="row" wrap="nowrap">
                    <Grid
                      container
                      item
                      md={11}
                      xs={10}
                      className={classes.datasetItemGap}
                      wrap="nowrap">
                      {data?.status === "BUILT" && (
                        <Button
                          variant="contained"
                          startIcon={<EyeIcon viewBox="0 0 20 20" />}
                          color="primary"
                          size="small"
                          onClick={handleDashboardClick}>
                          Preview
                        </Button>
                      )}
                    </Grid>
                    {!data?.isJobCanvas && !isCustomScenario && (
                      <Grid container item md={1} xs={2} className="moreIcon">
                        <MoreOptions
                          options={[
                            {
                              label: "Delete",
                              icon: <TrashIcon viewBox="0 0 20 22" />,
                              action: onDelete
                            }
                          ]}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
              {entityDetails?.markdown && (
                <Card className={classes.cardMargin}>
                  <DrawerAbout
                    markdownString={entityDetails?.markdown}
                    projectId={projectId}
                    elementId={itemId}
                    hiddenUpdateContentAction={!!isJobCanvas}
                  />
                </Card>
              )}
              <Card className={classes.cardMargin}>
                <CardContent>
                  <div className={classes.detailsWrapper}>
                    <div className={classes.detailItem}>
                      <p className={classes.detailName}>Source:</p>
                      <p>
                        {entityDetails?.parentTransform
                          ? entityDetails.parentTransform
                          : "No Parent found"}
                      </p>
                    </div>
                    <div className={classes.detailItem}>
                      <p className={classes.detailName}>Created:</p>
                      <p>{dateFormat(entityDetails?.created)}</p>
                    </div>
                    <div className={classes.detailItem}>
                      <p className={classes.detailName}>Last modification:</p>
                      <p>{dateFormat(entityDetails?.updated)}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className={classes.cardMargin}>
                <DrawerAccordion
                  title="List of Charts on the Dashboard"
                  content={
                    <List dense className={nodesListStyles.chartsLisContainer}>
                      {data?.viewData?.map(({ name }: $TSFixMe) => {
                        return (
                          <ListItem key={uuidv4()} className={nodesListStyles.container}>
                            <ListItemIcon className={nodesListStyles.icon}>
                              {NodeTypesIconMapping[NodeTypes.Chart]}
                            </ListItemIcon>
                            <ListItemText primary={name} className={nodesListStyles.text} />
                          </ListItem>
                        );
                      })}
                    </List>
                  }
                />
              </Card>
            </>
          )}
        </div>
      </div>
    </NewThemeWrapper>
  );
};
