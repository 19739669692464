import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import _ from "lodash";

import {
  useAutoGenerateCodeRecipeMutationV2,
  useCreateNewThreadMutation,
  useGetAIGuideMessages,
  useGetAIGuideThreads,
  useGetCodeHistory,
  useSaveAIGuideChatMutation
} from "src/hooks/api";
import { AskAIChatResponseType, OUTPUT_TYPE } from "./CodeRecipeContextProvider";
import { getAPIWithRethrow } from "src/utils/apiService";
import { extractAskAINewFlowInputDatasetIds } from "../CodeRecipeTabContainer/AskAIContainer/askai.helper";

import { AskAIResponse, Entity } from "src/types";
import { AIChatRequestDtoUseCaseEnum } from "openapi/Models/aichat-request-dto";
import { ThreadTargetInputInputEntityTypeEnum } from "openapi/Models/thread-target-input";
import {
  AIChatResponseDtoOutputTypeEnum,
  AIChatResponseDtoUseCaseEnum
} from "openapi/Models/aichat-response-dto";
import {
  ThreadResponseDto,
  ThreadResponseDtoFlowTypeEnum,
  ThreadResponseDtoTargetTypeEnum
} from "openapi/Models/thread-response-dto";

type IProps = {
  generateQueryLimit: number | undefined;
  newAskAIFlow: boolean;
  recipeId: string | undefined;
  inputDatasets: Entity[];
  responses: Array<AskAIChatResponseType>;
  setResponses: React.Dispatch<React.SetStateAction<AskAIChatResponseType[]>>;
  inputNames: string[];
};

export const useCodeRecipeApis = ({
  generateQueryLimit,
  newAskAIFlow,
  recipeId,
  inputDatasets,
  responses,
  setResponses,
  inputNames
}: IProps) => {
  const { projectId, scenarioId } = useParams<$TSFixMe>();
  const autoGenerateCodeRecipeMutation = useAutoGenerateCodeRecipeMutationV2();
  const createNewThreadMutation = useCreateNewThreadMutation();
  const saveAIGuideChatMutation = useSaveAIGuideChatMutation(projectId!);
  const autoGenerateCodeRecipeMutationV2 = useAutoGenerateCodeRecipeMutationV2();
  const isPromptSuggestionGenerationStopped = useRef<boolean>(false);
  const controllerRef = useRef<AbortController | null>(null);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);

  const {
    data: threads,
    isLoading: isGetThreadsLoading,
    isError: isGetThreadsError
  } = useGetAIGuideThreads({
    projectId: projectId!,
    enabled: newAskAIFlow ? !!recipeId : false
  });

  const recipeThread = threads?.find(
    (thread) =>
      thread.targetType === ThreadResponseDtoTargetTypeEnum.Recipe && thread.entityId === recipeId
  );

  const { isFetching: isFetchingCodeHistory } = useGetCodeHistory({
    recipeId,
    enabled: responses?.length === 0 && !newAskAIFlow,
    onSuccess: (response: Array<AskAIResponse>) => {
      setResponses(
        response.map((response: AskAIResponse) =>
          response.promptSuggestions ? { ...response, isExpanded: false } : response
        )
      );
    }
  });

  const { isInitialLoading: isFetchingMessages, data: messages } = useGetAIGuideMessages({
    threadId: recipeThread?.threadId,
    options: {
      enabled: !!recipeThread?.threadId && !!newAskAIFlow
    }
  });

  useEffect(() => {
    messages &&
      setResponses(
        messages.map((response) =>
          response.useCase === AIChatResponseDtoUseCaseEnum.PromptSuggestionsUseCase
            ? { ...response, isExpanded: false }
            : response
        )
      );
  }, [messages]);

  const commonProps = {
    threadsApiInfo: {
      threads,
      recipeThread,
      isGetThreadsError,
      isGetThreadsLoading
    },
    isFetchingChats: isFetchingCodeHistory || isFetchingMessages
  };

  const onAutoGenerateCodeNewFlow = React.useCallback(
    ({ payload, onSuccess, onError }: $TSFixMe) => {
      const createChat = (thread: ThreadResponseDto) => {
        const newController = new AbortController();
        controllerRef.current = newController;
        const {
          entityIds,
          intermediateDatasets,
          inputNames,
          userInput,
          outputType,
          useCase = AIChatRequestDtoUseCaseEnum.OneShotUseCase
        } = payload;
        const { inputIds } = extractAskAINewFlowInputDatasetIds({
          recipeThread: thread,
          intermediateDatasets,
          entityIds
        });

        saveAIGuideChatMutation.mutate(
          {
            threadId: thread.threadId!,
            request: {
              query: userInput,
              useCase: useCase,
              outputType,
              inputIds,
              controller: newController,
              sampleRows: generateQueryLimit
            },
            inputNames
          },
          {
            onSuccess,
            onError
          }
        );
      };
      if (!recipeThread) {
        const { userInput, outputType } = payload;
        const request = {
          flowType: ThreadResponseDtoFlowTypeEnum.Recipe,
          context: {
            projectId,
            scenarioId
          },
          target: {
            targetId: recipeId,
            targetType: ThreadResponseDtoTargetTypeEnum.Recipe
          },
          inputEntities: inputDatasets?.map((dataset) => ({
            entityId: dataset.id,
            entityName: dataset.name,
            inputEntityType: ThreadTargetInputInputEntityTypeEnum.Entity
          })),
          inputNames,
          request: {
            query: userInput,
            outputType
          }
        };
        createNewThreadMutation.mutate(request, {
          onSuccess: (newThread) => {
            createChat(newThread);
          },
          onError
        });
        return;
      }
      recipeThread && createChat(recipeThread);
    },
    [autoGenerateCodeRecipeMutation, projectId]
  );

  const onAutoGenerateCode = React.useCallback(
    ({ payload, onSuccess, onError }: $TSFixMe) => {
      autoGenerateCodeRecipeMutation.mutate(
        { ...payload, projectId, shouldThrowError: false, sampleRows: generateQueryLimit },
        {
          onSuccess,
          onError
        }
      );
    },
    [autoGenerateCodeRecipeMutation, projectId]
  );

  const fetchSuggestions = ({ payload }: { payload: any }) => {
    const { intermediateDatasets, datasetIds, inputNames } = payload;
    const selectedDatasetIds = inputDatasets
      ?.filter((ds) => inputNames.includes(ds.displayName || ds.name))
      ?.map((ds) => ds.id);

    autoGenerateCodeRecipeMutationV2.mutate(
      {
        recipeId,
        projectId,
        userInput: payload.userInput || "prompt suggestions",
        entityIds: datasetIds || selectedDatasetIds,
        outputType: OUTPUT_TYPE.PROMPT_SUGGESTIONS,
        intermediateDatasets,
        shouldThrowError: true,
        inputNames
      },
      {
        onSuccess: (response) => {
          if (isPromptSuggestionGenerationStopped.current || !response) {
            isPromptSuggestionGenerationStopped.current = false;
            return;
          }

          setResponses((responses: any) => [
            ...responses,
            {
              ...response,
              ...response?.history,
              isExpanded: true,
              queryInputs: inputNames.map((inputName: string) => ({ name: inputName }))
            }
          ]);
        },
        onError: async (error: any) => {
          if (isPromptSuggestionGenerationStopped.current) {
            isPromptSuggestionGenerationStopped.current = false;
            return;
          }

          const commonResponsesParams = {
            queryInputs: inputNames.map((name: string) => ({ name })),
            outputType: OUTPUT_TYPE.PROMPT_SUGGESTIONS,
            outputStatus: "FAILED",
            userInput: "Prompt Suggestions"
          };
          const errorMsg = error.response?.data?.msg;
          if (errorMsg?.includes("codeHistoryId=")) {
            const match = errorMsg?.match(/codeHistoryId=(.*)/);
            const codeHistoryId = match ? match[1] : null;
            const response = await getAPIWithRethrow(
              `/v2/dfs-run-config-groups/${recipeId}/code-history/${codeHistoryId}`
            );
            if (!response?.erroneousCode) {
              setResponses((responses: any) => [
                ...responses,
                {
                  execErr: response.execErr.replace(/codeHistoryId=.*$/, ""),
                  ...commonResponsesParams
                }
              ]);
              return;
            }
            setResponses((responses: any) => [
              ...responses,
              {
                execErr: response.execErr.replace(/codeHistoryId=.*$/, ""),
                erroneousCode: response.erroneousCode,
                errDetails: response.errDetails,
                ...commonResponsesParams
              }
            ]);
          } else {
            setResponses((responses: any) => [
              ...responses,
              {
                execErr: errorMsg,
                ...commonResponsesParams
              }
            ]);
          }
        }
      }
    );
  };

  const onFetchSuggestionsNewFlow = ({ payload }: { payload: any }) => {
    setIsFetchingSuggestions(true);
    const onSettled = () => setIsFetchingSuggestions(false);
    onAutoGenerateCodeNewFlow({
      payload: {
        ...payload,
        useCase: AIChatRequestDtoUseCaseEnum.PromptSuggestionsUseCase,
        outputType: AIChatResponseDtoOutputTypeEnum.Text,
        userInput: payload.userInput || "Prompt Suggestions"
      },
      onSuccess: onSettled,
      onError: onSettled
    });
  };

  if (newAskAIFlow) {
    const createThreadVariables = createNewThreadMutation?.variables as any;
    const saveChatVariables = saveAIGuideChatMutation.variables;
    const queryInputNames =
      saveChatVariables?.inputNames || createThreadVariables?.inputNames || [];
    const queryUserInput =
      saveChatVariables?.request?.query || createThreadVariables?.request?.query || "";
    const queryOutputType =
      saveChatVariables?.request?.outputType || createThreadVariables?.request?.outputType || "";
    return {
      ...commonProps,
      autoGenerateApiInfo: {
        autoGenerateQueryInputNames: queryInputNames,
        autoGenerateQueryUserInput: queryUserInput,
        autoGenerateOutputType: queryOutputType,
        autoGenerateQueryReset: () => {
          controllerRef?.current?.abort();
          saveAIGuideChatMutation.reset();
          createNewThreadMutation.reset();
        },
        isAutoGenerateInProgress:
          saveAIGuideChatMutation.isLoading || createNewThreadMutation.isLoading,
        isAutoGeneratedSuccess:
          saveAIGuideChatMutation.isSuccess || createNewThreadMutation.isSuccess
      },
      onAutoGenerateCode: onAutoGenerateCodeNewFlow,
      fetchSuggestions: onFetchSuggestionsNewFlow,
      fetchSuggestionsApiInfo: {
        isFetchingSuggestions,
        fetchingSuggestionsQueryReset: () => {
          controllerRef?.current?.abort();
          saveAIGuideChatMutation.reset();
          createNewThreadMutation.reset();
          isPromptSuggestionGenerationStopped.current = true;
        },
        fetchSuggestionsQueryInputNames: queryInputNames,
        fetchSuggestionsUserInput: queryUserInput
      }
    };
  }

  return {
    ...commonProps,
    autoGenerateApiInfo: {
      autoGenerateQueryInputNames:
        autoGenerateCodeRecipeMutation.variables?.inputNames ||
        autoGenerateCodeRecipeMutationV2.variables?.inputNames ||
        [],
      autoGenerateQueryUserInput:
        autoGenerateCodeRecipeMutation.variables?.userInput ||
        autoGenerateCodeRecipeMutationV2.variables?.userInput ||
        "",
      autoGenerateQueryReset: autoGenerateCodeRecipeMutation.reset,
      isAutoGenerateInProgress: autoGenerateCodeRecipeMutation.isLoading,
      isAutoGeneratedSuccess: autoGenerateCodeRecipeMutation.isSuccess
    },
    onAutoGenerateCode,
    fetchSuggestions,
    fetchSuggestionsApiInfo: {
      isFetchingSuggestions: autoGenerateCodeRecipeMutationV2.isLoading,
      fetchingSuggestionsQueryReset: () => {
        autoGenerateCodeRecipeMutationV2.reset();
        isPromptSuggestionGenerationStopped.current = true;
      },
      fetchSuggestionsQueryInputNames: autoGenerateCodeRecipeMutationV2.variables?.inputNames || [],
      fetchSuggestionsUserInput: autoGenerateCodeRecipeMutationV2.variables?.userInput || ""
    }
  };
};
