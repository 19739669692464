import React from "react";
import _ from "lodash";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import JsonEditor from "components/editor/JsonEditor";
import { INCORRECT_PRETTIFY_JSON_MSG } from "../utils/PredictionService.constants";
import { handleResponse } from "src/utils/apiService";

interface IProps {
  jsonString: string;
  onChange: (jsonString: string) => void;
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "16px 0"
  },
  editor: {
    width: "100% !important",
    borderRadius: "6px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    height: "300px !important",

    "& .ace_print-margin": {
      visibility: "hidden !important"
    }
  },
  childDiv: {
    display: "flex",
    gap: "6px",

    "& button": {
      padding: "5px"
    },

    "& svg": {
      width: "16px",
      height: "16px"
    }
  }
});

const EditJson: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const handlePrettify = () => {
    try {
      props.onChange(JSON.stringify(JSON.parse(props.jsonString), null, 2));
    } catch {
      handleResponse({
        errorMessage: INCORRECT_PRETTIFY_JSON_MSG
      });
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.childDiv}>
        <div>or JSON Editor</div>
        {!_.isEmpty(props.jsonString) && (
          <Tooltip title="Prettify the input JSON">
            <IconButton onClick={handlePrettify}>
              <ShuffleIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <JsonEditor
        placeholder="Copy paste your JSON here"
        jsonString={props.jsonString}
        className={classes.editor}
        onChange={props.onChange}
      />
    </div>
  );
};

export default EditJson;
