import { useMutation, UseMutationResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";

interface IPayload {
  segmentId?: string;
}

const useDeleteSegment = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ segmentId }: IPayload) => {
      if (!segmentId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(async () => await api.SegmentControllerApi.delete1(segmentId));
    }
  });

export default useDeleteSegment;
