// Packages
import { get } from "lodash";
import { useNavigate } from "react-router-dom";

// Stores
import { useDrawerStore } from "src/store/store";
import { sideComponentSetter } from "src/store/store.selectors";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";

// Components
import { TransformGroupModal } from "src/components/Canvas/CanvasDrawerItems";

// Contexts
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";
import { RecipeTypes, RecipeTypesPathMapping } from "pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";

type Props = {
  data: NodeData;
  disabledMessage: string;
};

const useActions = (props: Props) => {
  const { data, disabledMessage } = props || {};

  const navigate = useNavigate();

  // Contexts
  const { canAccessRecipeTimeout } = useAccessControlContext();

  // Stores - STARTS >>
  const setSideDrawerStore = useDrawerStore(sideComponentSetter);
  // << ENDS - Stores

  const onClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage) {
      return;
    }

    if (!!data?.jobProps) {
      if (!data?.isJobCanvasPath) {
        return;
      }
    }

    const drawerData = {
      ...data,
      ...{
        scenarioId: data?.scenarioId,
        status: data?.status,
        isJobCanvas: !!data?.isJobCanvas,
        jobProps: data?.jobProps
      }
    };

    setSideDrawerStore({
      sideComponent: TransformGroupModal,
      sideComponentProps: {
        noHeader: true,
        data: drawerData,
        canAccessRecipeTimeout,
        handleCloseAction: () => sessionStorage.removeItem("configGroupId")
      }
    });
  };

  const onDoubleClick = () => {
    setSideDrawerStore({
      sideComponent: null,
      sideComponentProps: null
    });

    if (!!disabledMessage) {
      return;
    }

    if (!areAllKeysPresentAndNotNil(data, ["projectId", "scenarioId", "id"])) {
      return;
    }

    const recipeType = get(RecipeTypesPathMapping, data?.recipeType || RecipeTypes.Template);
    let path = `/projects/${data?.projectId}/scenario/${data?.scenarioId}/${recipeType}/${data?.id}`;

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = `/projects/${data?.projectId}`;
      path = `${path}/jobs/${data?.jobProps?.jobId}`;
      path = `${path}/scenario/${data?.scenarioId}`;
      path = `${path}/job-runs/${data?.jobProps?.jobRunId}`;
      path = `${path}/recipes/${recipeType}/${data?.id}`;
    }

    !!path && navigate(path);
  };

  return { onClick, onDoubleClick };
};

export default useActions;
