import makeStyles from "@material-ui/core/styles/makeStyles";

type StyleProps = {
  backgroundColor?: string;
  nodeExpanded: boolean;
};

const useStyles = makeStyles((theme) => ({
  base: {
    backgroundColor: "transparent !important",
    borderColor: "transparent !important"
  },
  connectable: {
    backgroundColor: ({ backgroundColor }: StyleProps) => `${backgroundColor} !important`,
    "&.expandableHandle": {
      width: `${theme.spacing(2)}px !important`,
      height: `${theme.spacing(2)}px !important`,
      right: `-${theme.spacing(1)}px !important`,
      backgroundColor: `${theme.palette.warning.main} !important`,
      borderColor: ({ backgroundColor }: StyleProps) => `${backgroundColor} !important`,
      cursor: "pointer !important",
      textAlign: "center",
      "& .text": {
        position: "absolute"
      },
      "&.expanded": {
        "& .text": {
          marginTop: -theme.spacing(0.75),
          marginLeft: -theme.spacing(0.375)
        }
      },
      "&.collapsed": {
        "& .text": {
          marginTop: -theme.spacing(0.5),
          marginLeft: -theme.spacing(0.5),
          fontSize: "small"
        }
      }
    }
  }
}));

export default useStyles;
