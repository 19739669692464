import { useEffect, useState } from "react";

// Packages
import { useParams } from "react-router-dom";
import { find, size } from "lodash";

// Open API
import { ScenarioDto } from "openapi/Models";

// Hooks
import { useGetScenario } from "src/hooks/api";

// Stores
import { useScenariosStore } from "src/store/store";

const useIsDefaultScenario = () => {
  const { scenarioId } = useParams();

  // Stores - STARTS >>
  const scenariosStore = useScenariosStore((state) => state.scenarios);
  // << ENDS - Stores

  // States - STARTS >>
  const [isDefaultScenario, setIsDefaultScenario] = useState(false);
  // << ENDS - States

  const { refetch: refetchScenario } = useGetScenario({
    scenarioId,
    onSuccess: (data) => setIsDefaultScenario(() => data?.name === "DEFAULT")
  });

  useEffect(() => {
    if (size(scenariosStore) > 0) {
      const filteredScenario = find(
        scenariosStore,
        (scenario: ScenarioDto) => scenario?.id === scenarioId
      );

      setIsDefaultScenario(filteredScenario?.name === "DEFAULT");
    } else {
      refetchScenario();
    }
  }, [scenariosStore]);

  return { isDefaultScenario };
};

export default useIsDefaultScenario;
