import React from "react";

export const RocketLaunch = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M3.5 9.96733L6.1475 12.6273C6.1475 12.6273 14.3975 9.12733 13.955 2.05483C13.955 2.05483 7.41 1.06983 3.5 9.96733Z"
        stroke="white"
        strokeWidth="0.75"
      />
      <path
        d="M11.1726 2.27246C11.2784 2.91087 11.5828 3.49964 12.0426 3.95496C12.5145 4.43106 13.129 4.74005 13.7926 4.83496"
        stroke="white"
        strokeWidth="0.75"
      />
      <path
        d="M9.86494 7.68984C10.721 7.68984 11.4149 6.99588 11.4149 6.13984C11.4149 5.2838 10.721 4.58984 9.86494 4.58984C9.0089 4.58984 8.31494 5.2838 8.31494 6.13984C8.31494 6.99588 9.0089 7.68984 9.86494 7.68984Z"
        stroke="white"
        strokeWidth="0.75"
      />
      <path
        d="M3.72248 9.45518L2.39748 9.62518C2.38585 9.62647 2.37409 9.62472 2.36335 9.6201C2.3526 9.61548 2.34324 9.60815 2.33618 9.59882C2.32913 9.5895 2.32461 9.5785 2.32309 9.5669C2.32156 9.5553 2.32307 9.54351 2.32748 9.53268L3.30998 7.28268C3.35225 7.18613 3.41694 7.10106 3.49868 7.03454C3.58042 6.96801 3.67686 6.92195 3.77998 6.90018L5.42748 6.55518"
        stroke="white"
        strokeWidth="0.75"
      />
      <path
        d="M6.63751 12.35L6.46501 13.675C6.46372 13.6866 6.46547 13.6984 6.47009 13.7091C6.47471 13.7199 6.48204 13.7293 6.49137 13.7363C6.50069 13.7434 6.51169 13.7479 6.52329 13.7494C6.53488 13.7509 6.54668 13.7494 6.55751 13.745L8.80751 12.765C8.90389 12.7218 8.98875 12.6565 9.0552 12.5745C9.12165 12.4924 9.16784 12.3958 9.19001 12.2925L9.53501 10.645"
        stroke="white"
        strokeWidth="0.75"
      />
      <path
        d="M5.55236 12.0325C4.95986 13.885 2.02736 13.9775 2.02736 13.9775C2.02736 13.9775 1.99986 11.1275 3.93986 10.4175"
        stroke="white"
        strokeWidth="0.75"
      />
    </svg>
  );
};
