import {
  deleteAPIWithRethrow,
  getAPIWithRethrow
  // postAPIWithRethrow,
  // putAPIWithRethrow
} from "../utils/apiService";

export const getArtifacts = async ({ scenarioId, jobRunId }: $TSFixMe = {}) =>
  await getAPIWithRethrow(
    `/v2/artifacts?${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }`
  );

export const getArtifactByName = async (name?: string, scenarioId = "", jobRunId = "") =>
  await getAPIWithRethrow(
    `/v2/artifacts?name=${name}${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }`
  );

export const getArtifactSignedUrl = async (path: string) =>
  await getAPIWithRethrow(`/v2/artifacts/signed-url`, { params: { path } });

export const downloadArtifactSignedUrl = async (path: string) =>
  await getAPIWithRethrow(`/v2/artifacts/download-signed-url`, { params: { path } });

export const deleteArtifact = async (name: string) =>
  // @ts-expect-error
  await deleteAPIWithRethrow(`/v2/artifacts/${name}`);

export const getModels = async ({ scenarioId, jobRunId }: $TSFixMe = {}) =>
  await getAPIWithRethrow(
    `/v2/prediction-services/models?${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
      !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
    }`
  );

export const getModelsByName = async (name: string) =>
  await getAPIWithRethrow(`/v2/prediction-services/model-details/${name}`);

export const downloadModelSignedUrl = async (name: string, path: string) =>
  await getAPIWithRethrow(
    `/v2/prediction-services/models/download-signed-url/${name}?path=${path}`
  );

export const deleteModal = async (name: string) =>
  // @ts-expect-error
  await deleteAPIWithRethrow(`/v2/prediction-services/model/${name}`);
