import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { UserResolveAuthRequestDto } from "openapi/Models";

export const useResolveSSOAuthMutation = () => {
  return useMutation({
    mutationFn: (request: UserResolveAuthRequestDto) => {
      return axios.post("/api/sso/resolve/auth", request);
    }
  });
};
