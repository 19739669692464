import React from "react";

export const ProfileIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 6} ${height + 6}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 15C14.7 15 17.8 16.29 18 17V18H6V17.01C6.2 16.29 9.3 15 12 15ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13Z"
      fill={color}
    />
  </svg>
);
