import { Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import JsonEditor from "src/components/editor/JsonEditor";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

interface IProps {
  jsonString?: string;
  title: string;
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "16px 0"
  },
  editor: {
    width: "100% !important",
    borderRadius: "6px",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    height: "300px !important",

    "& .ace_print-margin": {
      visibility: "hidden !important"
    }
  },
  resultContainer: {
    gap: "5px"
  }
});

const PrettyJsonEditor: React.FC<IProps> = ({ jsonString, title }) => {
  const [value, setValue] = useState(jsonString);
  const classes = useStyles();
  const [isCopied, setIsCopied] = useState(false);

  const showPretty = useMemo(() => {
    try {
      JSON.stringify(JSON.parse(jsonString ?? ""), null, 2);
      return true;
    } catch {
      return false;
    }
  }, [jsonString]);

  const handlePrettify = () => {
    setValue(JSON.stringify(JSON.parse(jsonString ?? ""), null, 2));
  };

  const handleCopy = () => {
    navigator?.clipboard?.writeText(value ?? "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 5000);
  };

  return (
    <Grid direction="column">
      <Grid container>
        <Typography>{title}</Typography>
        <Grid style={{ marginLeft: "auto" }}>
          {showPretty && (
            <Tooltip title="Prettify">
              <IconButton onClick={handlePrettify}>
                <ShuffleIcon style={{ width: "16px", height: "16px" }} />
              </IconButton>
            </Tooltip>
          )}
          {value && (
            <Tooltip title={isCopied ? "Copied" : `Copies ${title}`}>
              <IconButton size="small" onClick={handleCopy}>
                <FileCopyOutlinedIcon style={{ width: "16px", height: "16px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <JsonEditor className={classes.editor} jsonString={value ?? ""} readOnly />
    </Grid>
  );
};

export default PrettyJsonEditor;
