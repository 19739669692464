import { useEffect, useRef, useState } from "react";

// Packages
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { get, includes, map, omit, some, uniq } from "lodash";

// Utils
import { toastWrapper } from "src/utils/toastWrapper";

// Open API
import { Node, ProjectCanvasDto } from "openapi/Models";

// APIs
import { getProjectCanvas } from "src/api";

const enum DagPollingConfig {
  PollDuration = 60000 // 1 min.
}

const enum UseQueryKeys {
  ValidateCanvas = "data-connectors-data-upload-validate-canvas",
  PollCanvas = "data-connectors-data-upload-poll-canvas"
}

type Props = {
  projectId?: string;
  scenarioId?: string;
};

const useDagPolling = (props: Props) => {
  const { projectId, scenarioId } = props || {};

  const location = useLocation();
  const navigate = useNavigate();

  // States - STARTS >>
  const [isPoll, setIsPoll] = useState(false);
  // << ENDS - States

  const pollTimeoutRef = useRef<NodeJS.Timeout | string | number | undefined>(undefined);

  // Utils - STARTS >>
  const hasUnBuiltDataset = (thisCanvasData: ProjectCanvasDto) => {
    if (location?.state?.action !== "done") {
      return;
    }

    const inputDatasets: string[] = uniq(
      map(get(thisCanvasData, "edges") || [], (node) => node?.source)
    );

    const outputDatasets: string[] = uniq(
      map(get(thisCanvasData, "edges") || [], (node) => node?.target)
    );

    return some(
      get(thisCanvasData, "nodes") || [],
      (node: Node | any) =>
        node?.status === "UNBUILT" &&
        node?.type === "ENTITY" &&
        ((!includes(inputDatasets, node?.id) && !includes(outputDatasets, node?.id)) ||
          includes(inputDatasets, node?.id))
    );
  };

  const stopPolling = () => {
    setIsPoll(() => false);
    clearTimeout(pollTimeoutRef.current);
  };
  // << ENDS - Utils

  // Query hooks - STARTS >>
  const {
    isSuccess: isCanvasFetched,
    data: canvasData,
    refetch: refetchCanvas
  } = useQuery({
    queryKey: [UseQueryKeys.ValidateCanvas],
    queryFn: () =>
      getProjectCanvas({
        projectId,
        scenarioId
      }),
    select: (data) => (typeof data === "object" ? data : {}),
    cacheTime: 0,
    enabled: false
  });

  useQuery({
    queryKey: [UseQueryKeys.PollCanvas],
    queryFn: () =>
      getProjectCanvas({
        projectId,
        scenarioId
      }),
    select: (data) => (typeof data === "object" ? data : {}),
    onSuccess: (data) => {
      if (!hasUnBuiltDataset(data)) {
        stopPolling();
      }
    },
    refetchInterval: isPoll ? 2000 : false,
    cacheTime: 0,
    enabled: !!isPoll
  });
  // << ENDS - Query hooks

  useEffect(() => {
    if (isCanvasFetched) {
      if (hasUnBuiltDataset(canvasData)) {
        toastWrapper({
          type: "info",
          content:
            "Processing of the dataset is in progress, and it may take some time for the status to transition from UNBUILT to BUILT."
        });

        setIsPoll(() => true);

        pollTimeoutRef.current = setTimeout(() => {
          stopPolling();
        }, DagPollingConfig.PollDuration);

        navigate(
          { pathname: location?.pathname },
          { state: omit(location?.state || {}, "action") || {} }
        );
      }
    }
  }, [isCanvasFetched]);

  const onDatasetCreated = () => {
    refetchCanvas();
  };

  useEffect(
    () => () => {
      stopPolling();

      pollTimeoutRef.current = undefined;
    },
    []
  );

  return { onDatasetCreated };
};

export default useDagPolling;
