import dayjs from "dayjs";

export const dateFormat = (
  date: $TSFixMe,
  isEpochMilliSec = true,
  dateFormat = "YYYY-MM-DD HH:mm:ss"
) => {
  if (!isEpochMilliSec) {
    if (date && date !== 0) {
      // Mechanism to detect if timestamp is already in seconds
      return date < 631152000000
        ? dayjs.unix(date).format(dateFormat)
        : dayjs.unix(date / 1000).format(dateFormat);
    }
    return "N/A";
  }
  if (typeof date === "string") {
    return date?.length !== 0 ? dayjs(date).format(dateFormat) : "N/A";
  }
  return date && date !== 0 ? dayjs.unix(date / 1000).format(dateFormat) : "N/A";
};

export const dateDiffFromNow = (date1: number, isEpochMilliSec = true, unit?: $TSFixMe) => {
  const dayjs1 = date1 ? (isEpochMilliSec ? dayjs.unix(date1 / 1000) : dayjs.unix(date1)) : null;
  if (!dayjs1) {
    return null;
  }
  return dayjs(dayjs1).diff(Date.now(), unit);
};

export const timestampsDiff = (fromTimestamp: number, toTimestamp: number) => {
  let delta = Math.abs(toTimestamp - fromTimestamp) / 1000; // delta

  // Uncommented config properties will be considered.
  // Add a config if require.
  const config: $TSFixMe = {
    // year: 31536000,
    // month: 2592000,
    // week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  };

  const diffMap: $TSFixMe = {}; // result
  Object.keys(config)?.forEach((key: $TSFixMe) => {
    diffMap[key] = Math.floor(delta / config[key]);
    delta -= diffMap[key] * config[key];
  });

  // Example: { year: 0, month: 0, week: 1, day: 2, hour: 34, minute: 56, second: 7 };
  return diffMap;
};
