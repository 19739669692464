import React, { ReactNode } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import CancelIcon from "@material-ui/icons/Cancel";
import { Chip } from "@material-ui/core";

import { capitalize } from "src/utils/capitalize";

import {
  JobsStatuses,
  jobStatusesNames,
  JobRunStatuses,
  JobsStatusColors,
  JobRunStatusColors,
  jobRunStatusesNames
} from "./Jobs.constants";

export const getJobStatusTemplate = (status: string, renderChip?: boolean) => {
  const label = jobStatusesNames[status] || capitalize(status);

  switch (status) {
    case JobsStatuses.Started:
    case JobsStatuses.Active:
    case JobsStatuses.Success:
      if (renderChip) {
        return (
          <Chip
            size="small"
            label={label}
            style={{ backgroundColor: JobsStatusColors.Success, color: "#fff" }}
          />
        );
      }

      return <span style={{ color: JobsStatusColors.Success }}>{label}</span>;

    case JobsStatuses.Inactive:
    case JobsStatuses.Failure:
    case JobsStatuses.Failed:
    case JobsStatuses.TimedOut:
      if (renderChip) {
        return (
          <Chip size="small" label={label} style={{ backgroundColor: "#F5C726", color: "#000" }} />
        );
      }

      return <span style={{ color: JobsStatusColors.Fail }}>{label}</span>;

    default:
      if (renderChip) {
        return (
          <Chip
            size="small"
            label={label}
            style={{ backgroundColor: JobRunStatusColors.Fail, color: "#fff" }}
          />
        );
      }

      return <span>{label}</span>;
  }
};

export const getJobRunStatusTemplate = (status: string) => {
  let template: ReactNode = <></>;

  if (!!status) {
    switch (status) {
      case JobRunStatuses.SuccessWithWarn:
      case JobRunStatuses.Success:
        template = (
          <>
            <span style={{ color: JobRunStatusColors.Success }}>
              {jobRunStatusesNames[status] || capitalize(status)}
            </span>
          </>
        );
        break;

      case JobRunStatuses.TimedOut:
      case JobRunStatuses.RecipeTimedOut:
      case JobRunStatuses.Failure:
        template = (
          <>
            <span style={{ color: JobRunStatusColors.Fail }}>
              {jobRunStatusesNames[status] || capitalize(status)}
            </span>
          </>
        );
        break;

      default:
        template = (
          <>
            <span>{jobRunStatusesNames[status] || capitalize(status)}</span>
          </>
        );
        break;
    }
  }

  return template;
};

export const getJobRunStatusBadge = (status: string) => {
  let template: ReactNode = null;

  if (!!status) {
    switch (status) {
      case JobRunStatuses.Created:
      case JobRunStatuses.Started:
      case JobRunStatuses.EntityLoading:
      case JobRunStatuses.Running:
        template = (
          <RotateLeftIcon
            fontSize="small"
            style={{ cursor: "pointer", color: JobRunStatusColors.Info }}
          />
        );
        break;

      case JobRunStatuses.SuccessWithWarn:
      case JobRunStatuses.Success:
        template = (
          <CheckCircleIcon
            fontSize="small"
            style={{ cursor: "pointer", color: JobRunStatusColors.Success }}
          />
        );
        break;

      case JobRunStatuses.TimedOut:
      case JobRunStatuses.RecipeTimedOut:
      case JobRunStatuses.Failure:
        template = (
          <CancelIcon
            fontSize="small"
            style={{ cursor: "pointer", color: JobRunStatusColors.Fail }}
          />
        );
        break;

      default:
        template = null;
        break;
    }
  }

  return !!template ? (
    !!jobRunStatusesNames[status] && (
      <Tooltip title={jobRunStatusesNames[status]}>
        <span>{template}</span>
      </Tooltip>
    )
  ) : (
    <></>
  );
};
