/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InputEntityDto
 */
export interface InputEntityDto {
    /**
     * 
     * @type {string}
     * @memberof InputEntityDto
     */
    'inputEntityType'?: InputEntityDtoInputEntityTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof InputEntityDto
     */
    'ragFiles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InputEntityDto
     */
    'entityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InputEntityDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InputEntityDto
     */
    'filePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof InputEntityDto
     */
    'schemaPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof InputEntityDto
     */
    'dataSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InputEntityDto
     */
    'tableName'?: string;
}

export const InputEntityDtoInputEntityTypeEnum = {
    Entity: 'ENTITY',
    Adhoc: 'ADHOC',
    ExternalDatasource: 'EXTERNAL_DATASOURCE',
    RagFiles: 'RAG_FILES',
    IntermediateEntity: 'INTERMEDIATE_ENTITY',
    PredictionService: 'PREDICTION_SERVICE',
    Chart: 'CHART',
    Artifact: 'ARTIFACT'
} as const;

export type InputEntityDtoInputEntityTypeEnum = typeof InputEntityDtoInputEntityTypeEnum[keyof typeof InputEntityDtoInputEntityTypeEnum];


