import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams, useSearchParams } from "react-router-dom";

// MUI
import Button from "@material-ui/core/Button";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import { RunIcon } from "src/icons/NewUX/RunIcon";
import { TimeoutIcon } from "src/icons/NewUX/TimeoutIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { AddRunIcon } from "src/icons/NewUX";

import { useDrawerStore, useCanvasStore } from "src/store/store";
import { sideComponentSetter } from "src/store/store.selectors";
import { handleResponse } from "src/utils/apiService";
import { isRecipeRunInQueue as isRecipeRunInQueueHelper } from "src/pages/private/ProjectsModule/utils";
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { RecipeConditions } from "src/pages/Projects/common/RecipeConditions/RecipeConditions";

import { StandardRecipeHeaderProps } from "./StandardRecipeHeader.type";
import { TimeoutTextField } from "../../common/TimeoutTextField/TimeoutTextField";
import { useValidateRecipeName } from "../../common/useValidateRecipeName";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { DISABLED_RECIPE_LOGS, READONLY_ENTITY } from "src/constants";
import RecipeLogsDrawer from "../../common/ShowLogsModal/RecipeLogsDrawer";
import { useAccessControlContext } from "src/routing/PrivateRoute/accessControlContext/useAccessControlContext";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";

// Context
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import useStopRecipe from "src/pages/private/ProjectsModule/pages/Dag/hooks/useStopRecipe";
import StopCircleOutlined from "src/icons/StopCircleOutlined";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { toastWrapper } from "src/utils/toastWrapper";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_RECIPE } from "src/hooks/api/transforms/useGetRecipe";

export const StandardRecipeHeader = ({
  recipeName,
  recipeDisplayName,
  handleSaveName,
  onRunRecipe,
  scenarioData,
  isDefaultScenario,
  recipe,
  isEmptyRecipe,
  isRunDisabled,
  isRunInProgress,
  conditions,
  setConditions,
  invalidTransformInputsHelpText,
  recipeRunInProgressHelpText,
  emptyRecipeHelperText,
  handleUpdateRecipeRunTimeout,
  jobData,
  jobRunName,
  isAddingRecipeToQueue
}: StandardRecipeHeaderProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  // Project context
  const { project } = useProjectContext() || {};

  const { projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();
  const [shouldResetName, setShouldResetName] = useState<number | boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRecipeUpdateInProgress, setIsRecipeUpdateInProgress] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openLogs, setOpenLogs] = useState(false);
  const isOpenLogs = searchParams.get(openLogsModal);
  const stopRecipe = useStopRecipe();
  const setReloadTriggerWithDelay = useCanvasStore((state) => state.setReloadTriggerWithDelay);
  const [stopRecipeModalOpen, setStopRecipeModalOpen] = useState(false);

  const { validateName } = useValidateRecipeName();
  const { canAccessRecipeTimeout, canViewStandardRecipeConditions } = useAccessControlContext();

  const setSideComponent = useDrawerStore(sideComponentSetter);
  const isRecipesRunningAcrossScenariosStore = useCanvasStore(
    (state) => state.isRecipesRunningAcrossScenarios
  );
  const isPendingRecipeRunsInQueueStore = useCanvasStore(
    (state) => state.isPendingRecipeRunsInQueue
  );
  const pendingRecipeRunsInQueueStore = useCanvasStore((state) => state.pendingRecipeRunsInQueue);

  const handleLogsClick = () => {
    setOpenLogs(true);
  };

  useEffect(() => {
    if (isOpenLogs) {
      handleLogsClick();
    }
  }, [isOpenLogs]);

  const openConditionsSideBar = () => {
    const onClose = () => {
      setSideComponent({
        sideComponent: null,
        sideComponentProps: null
      });
    };
    setSideComponent({
      sideComponent: RecipeConditions,
      sideComponentProps: {
        title: "Conditions",
        projectId,
        scenarioId,
        recipe,
        isJobPath,
        jobId,
        jobRunId,
        conditions,
        setConditions,
        onClose,
        isDefaultScenario
      }
    });
  };

  const stopRunningRecipe = () => {
    if (recipe?.id) {
      stopRecipe.mutate(
        { groupId: recipe?.id, scenarioId },
        {
          onSuccess: () => {
            setStopRecipeModalOpen(false);
            toastWrapper({ type: "info", content: "Recipe stop action is completed" });
            setReloadTriggerWithDelay();
            queryClient.invalidateQueries([QUERY_KEY_RECIPE]);
          }
        }
      );
    }
  };
  const handleCancel = () => {
    setStopRecipeModalOpen(false);
  };

  const updateTransformName = React.useCallback(
    async (name: string) => {
      const cleanedName = name?.trim();
      const { error, nameExist } = validateName(cleanedName);

      if (error) {
        handleResponse({ errorMessage: error });
        setShouldResetName(Date.now());
      } else if (nameExist) {
        handleResponse({ errorMessage: "Recipe name already exists" });
        setShouldResetName(Date.now());
      } else {
        await handleSaveName({
          updatedName: cleanedName,
          onError: () => setShouldResetName(Date.now())
        });
      }
    },
    [handleSaveName, validateName]
  );

  const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [projectId, scenarioId]);

  const onUpdateRecipeRunTimeout = (timeout: number) => {
    handleUpdateRecipeRunTimeout({
      timeout,
      onError: () => setIsRecipeUpdateInProgress(false),
      onSuccess: () => setIsRecipeUpdateInProgress(false)
    });
  };

  const isRecipeRunInQueue = useMemo(
    () =>
      isRecipeRunInQueueHelper({
        pendingRecipeRunsInQueue: pendingRecipeRunsInQueueStore,
        recipeId: recipe?.id,
        scenarioId: scenarioId
      }),
    [pendingRecipeRunsInQueueStore, recipe?.id, scenarioId]
  );

  const isRunActionDisabled = useMemo(
    () =>
      (isEmptyRecipe && !recipe?.id) ||
      !recipe?.id ||
      isRunInProgress ||
      isRunDisabled ||
      isRecipeRunInQueue ||
      isAddingRecipeToQueue,
    [
      isEmptyRecipe,
      recipe?.id,
      isRunInProgress,
      isRunDisabled,
      isRecipeRunInQueue,
      isAddingRecipeToQueue
    ]
  );

  const isConditionsAction = useMemo(() => {
    if (!canViewStandardRecipeConditions) {
      return false;
    }

    if (!isJobPath && isEmptyRecipe && !recipe?.id) {
      return false;
    }

    return true;
  }, [isJobPath, canViewStandardRecipeConditions, isEmptyRecipe, recipe?.id]);

  const isRunAction = useMemo(() => {
    if (!!isJobPath) {
      return false;
    } else {
      if (isEmptyRecipe && !recipe?.id) {
        return false;
      }
    }

    return true;
  }, [isJobPath, isEmptyRecipe, recipe?.id]);

  const isLogsAction = useMemo(() => {
    if (!isJobPath && isEmptyRecipe && !recipe?.id) {
      return false;
    }

    return true;
  }, [isJobPath, isEmptyRecipe, recipe?.id]);

  const handleCloseLogs = () => {
    setOpenLogs(false);
    setSearchParams({});
  };

  const isAddRecipeToQueue = useMemo(
    () =>
      !isRunInProgress &&
      (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore),
    [isRunInProgress, isRecipesRunningAcrossScenariosStore, isPendingRecipeRunsInQueueStore]
  );

  return (
    <>
      <DeleteNodeModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={handleBack}
        nodeId={recipe?.id}
        nodeName={recipe?.displayName}
        nodeType="group"
        deleteNote={
          <div>
            <span>Note: </span>
            {psMessage}
          </div>
        }
      />
      {!!stopRecipeModalOpen && (
        <NewThemeWrapper>
          <Modal
            open
            variant={ModalVariants.Delete}
            title={
              <Grid direction="column" container>
                <Typography>Stop Recipe Run</Typography>
                <span style={{ fontSize: "12px", fontStyle: "italic", color: "grey" }}>
                  This process may take some time to get completed.
                </span>
              </Grid>
            }
            content={[
              "This action updates the recipe status to Unbuilt, shuts down the related project environment, and restarts it. This may cause any other recipes depending on this environment to fail.",
              <>
                <b>Note:</b> If recipe execution gets completed before associated environment
                restart then the status of the recipe will be based on the run result.
              </>,
              "Are you sure you want to proceed with this?"
            ]}
            submitLabel="Yes, Proceed"
            onClose={handleCancel}
            isSubmitting={stopRecipe.isLoading}
            onSubmit={stopRunningRecipe}
          />
        </NewThemeWrapper>
      )}
      {openLogs && (
        <RecipeLogsDrawer
          name={recipe?.displayName ?? ""}
          open={openLogs}
          projectId={projectId}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          groupId={recipe?.id}
          isJobPath={isJobPath}
          onClose={handleCloseLogs}
        />
      )}
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: (
            <SubTopNavBarBreadcrumbs
              project={project}
              isJobPath={isJobPath}
              jobData={jobData}
              jobRunId={jobRunId}
              jobRunName={jobRunName}
              isDefaultScenario={isDefaultScenario}
              scenarioName={scenarioData?.name}
              recipeName={recipeDisplayName || recipeName}
              updateRecipeName={updateTransformName}
              shouldResetRecipeName={shouldResetName}
            />
          )
        }}
        subTopNavBarRightSection={{
          ...(!isJobPath && !!recipe?.id
            ? {
                moreOptions: [
                  {
                    label: "Delete",
                    icon: <TrashIcon viewBox="0 0 20 20" />,
                    action: () => (recipe?.id ? handleOpenDeleteModal() : handleBack()),
                    isDisabled: !isDefaultScenario,
                    ...(!isDefaultScenario && {
                      tooltip: READONLY_ENTITY
                    })
                  },
                  ...(canAccessRecipeTimeout
                    ? [
                        {
                          label: `Timeout ${recipe?.timeout || 0}hr`,
                          icon: <TimeoutIcon viewBox="0 0 20 22" />,
                          nestedComponent: (
                            <TimeoutTextField
                              value={recipe?.timeout || ""}
                              minValue={1}
                              onChange={onUpdateRecipeRunTimeout}
                              disabled={isRecipeUpdateInProgress}
                            />
                          )
                        }
                      ]
                    : [])
                ]
              }
            : {}),
          component: (
            <>
              {!!isConditionsAction && (
                <Button
                  size="small"
                  color="primary"
                  onClick={openConditionsSideBar}
                  disabled={isEmptyRecipe && !recipe?.id}>
                  Conditions
                </Button>
              )}

              {!!isRunAction && (
                <Tooltip
                  title={
                    emptyRecipeHelperText ||
                    invalidTransformInputsHelpText ||
                    recipeRunInProgressHelpText ||
                    (isRecipeRunInQueue
                      ? "Recipe is already in queue!"
                      : isAddingRecipeToQueue
                        ? "Recipe is being added to queue"
                        : isAddRecipeToQueue
                          ? "Add to Queue"
                          : "Run Recipe")
                  }>
                  <span>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={onRunRecipe}
                      disabled={isRunActionDisabled}
                      style={{ textTransform: "none" }}
                      startIcon={
                        isAddingRecipeToQueue || isRunInProgress ? (
                          <CircularProgress size={18} />
                        ) : isAddRecipeToQueue ? (
                          <AddRunIcon />
                        ) : (
                          <RunIcon />
                        )
                      }>
                      {isAddRecipeToQueue ? "Add to Queue" : "Run"}
                    </Button>
                  </span>
                </Tooltip>
              )}
              {!isJobPath && isRunInProgress && (
                <Tooltip
                  title={
                    stopRecipe.isLoading ? "Stop Recipe Run is in Progress..." : "Stop Recipe Run"
                  }>
                  <span>
                    <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={() => setStopRecipeModalOpen(true)}
                      style={{ textTransform: "none" }}
                      disabled={stopRecipe.isLoading}
                      startIcon={
                        stopRecipe.isLoading ? (
                          <CircularProgress size={18} />
                        ) : (
                          <StopCircleOutlined />
                        )
                      }>
                      Stop
                    </Button>
                  </span>
                </Tooltip>
              )}

              {!!isLogsAction && (
                <Tooltip title={!recipe?.id ? DISABLED_RECIPE_LOGS : ""}>
                  <span>
                    <Button
                      size="small"
                      color="primary"
                      onClick={handleLogsClick}
                      disabled={!recipe?.id}>
                      <LogsNewIconV2
                        width="22"
                        height="22"
                        // New UX change
                        // The static value will be replaced with new theme at platform-level.
                        color={!recipe?.id ? "#4646b550" : "#4646b5"}
                      />
                    </Button>
                  </span>
                </Tooltip>
              )}
            </>
          )
        }}
      />
    </>
  );
};
