import { useEffect } from "react";

// Packages
import { useForm } from "react-hook-form";
import { delay, isEmpty, isNil, map } from "lodash";

// Open API
import { GlobalVariableDto, ProjectDto } from "openapi/Models";

// Types
import { ProjectSettingsFormFieldsType } from "../ProjectSettings.types";

// Constants
import {
  defaultValues,
  defaultVariables,
  ProjectSettingsFormFields
} from "../utils/ProjectSettings.constants";

type Props = {
  isBusinessUser?: boolean;
  project?: ProjectDto;
  variables?: GlobalVariableDto[];
};

const useProjectSettingsForm = (props: Props) => {
  const { project, isBusinessUser, variables } = props || {};

  const formMethods = useForm<
    ProjectSettingsFormFieldsType<typeof isBusinessUser extends true ? false : true>
  >({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues
  });

  const { reset, getValues } = formMethods || {};

  useEffect(() => {
    if (!isNil(variables) && !isEmpty(project)) {
      const image = project?.image?.split(";base64,")?.pop() || project?.image;

      // Calling reset to mark as initial values
      reset({
        ...getValues(),
        [ProjectSettingsFormFields.Name]: project?.name || project?.display_name || "",
        [ProjectSettingsFormFields.Description]: project?.description ?? "",
        [ProjectSettingsFormFields.Image]: image ? `data:image/jpeg;base64,${image}` : "",
        [ProjectSettingsFormFields.EnvironmentType]: project?.envId ?? "",
        [ProjectSettingsFormFields.AskAiSystemMessage]: project?.askAIContext ?? ""
      });
    }
  }, [project]);

  const resetOnlyVariables = () => {
    // Delay is to avoid some sync issues.
    delay(() => {
      // Calling reset to mark as initial values
      reset({
        ...getValues(),
        [ProjectSettingsFormFields.Variables]: isEmpty(variables)
          ? defaultVariables
          : map(variables, (variable: GlobalVariableDto) => ({
              key: variable?.name || "",
              value: variable?.value || ""
            }))
      });
    }, 0);
  };

  useEffect(() => {
    !isBusinessUser && !isNil(variables) && !isEmpty(variables) && resetOnlyVariables();
  }, [isBusinessUser, variables]);

  return { formMethods, resetOnlyVariables };
};

export default useProjectSettingsForm;
