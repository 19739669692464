import React, { useMemo } from "react";

// Packages
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import shallow from "zustand/shallow";
import clsx from "clsx";
import { find, flatten, includes, map, slice, split } from "lodash";

import {
  Paper,
  Popover,
  ImageList,
  ImageListItem,
  Box,
  Typography,
  useTheme
} from "@material-ui/core";

// Utils
import Analytics from "src/utils/analytics";
import { openNotebookTab } from "src/utils/operateNotebookService";

// Stores
import useAuthStore from "src/stores/auth.store";

// Components
import Divider from "src/components/Divider/Divider";

// Constants
import { EVENTS } from "src/constants/events";
import { MenuItemTypes, NotebookTypes } from "src/constants";
import { IItem } from "../../utils/NavBars.constants";

import { useStyles } from "./MainMenu.styles";

type MainMenuProps = {
  mainMenuButtonAnchorEl: HTMLButtonElement | null;
  onMainMenuClose: $TSFixMeFunction;
  menuItems: IItem[][];
  notebookMenuItems: IItem[];
};

type MainMenuWrapperProps = {
  mainMenuButtonAnchorEl: HTMLButtonElement | null;
  onMainMenuClose: $TSFixMeFunction;
  children?: React.ReactNode;
};

const MainMenuWrapper = (props: MainMenuWrapperProps) => {
  const { mainMenuButtonAnchorEl, onMainMenuClose, children } = props || {};

  const theme = useTheme();

  const mainMenuWrapperStyles = useMemo(
    () => ({
      width: 375,
      border: `5px solid ${theme.palette.common.white}`,
      borderRadius: 10,
      backgroundImage:
        "linear-gradient(0deg, rgba(209,209,255,1) -25%, rgba(255,255,255,1) 50%, rgba(255,226,210,1) 125%)"
    }),
    [theme]
  );

  return (
    <Popover
      open
      anchorEl={mainMenuButtonAnchorEl}
      onClose={onMainMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      PaperProps={{
        style: mainMenuWrapperStyles
      }}>
      {children}
    </Popover>
  );
};

const MainMenu = (props: MainMenuProps) => {
  const { mainMenuButtonAnchorEl, onMainMenuClose, menuItems, notebookMenuItems } = props || {};

  const theme = useTheme();
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const [
    notebookType,
    isLoggingNotebooksTypeUserOut,
    isLoggingNotebooksTypeTenantOut,
    setIsLoggingNotebooksTypeUserOut,
    setIsLoggingNotebooksTypeTenantOut
  ] = useAuthStore(
    (state) => [
      state.notebookType,
      state.isLoggingNotebooksTypeUserOut,
      state.isLoggingNotebooksTypeTenantOut,
      state.setIsLoggingNotebooksTypeUserOut,
      state.setIsLoggingNotebooksTypeTenantOut
    ],
    shallow
  );

  const mainMenuItem = useMemo(() => !!menuItems[0][0]?.isMainMenu && menuItems[0][0], [menuItems]);

  const slicedMenuItems = useMemo(
    () => [
      // Exempting main-menu-item.
      ...slice(menuItems, 1, -1),
      // Combining rest of the menu-items and notebook menu-items.
      flatten([...slice(menuItems, -1), ...notebookMenuItems])
    ],
    [menuItems, notebookMenuItems]
  );

  const isDisabledMenuItem = (type?: string) => {
    if (type === MenuItemTypes.Notebook) {
      return !!isDisabledNotebookMenuItem;
    }

    return false;
  };

  // $FixMe: Scope to be refactored.
  const { activeKey } = useMemo(() => {
    const activeItem = find(flatten(menuItems), (item) => {
      const webPath = split(location.pathname, "/")[1];
      return webPath === split(item?.path, "/")[1] || includes(item?.activePaths, webPath);
    });

    return activeItem
      ? {
          activeKey: activeItem.label
        }
      : { activeKey: null };
  }, [location.pathname, menuItems]);

  const isDisabledNotebookMenuItem = useMemo(() => {
    return (
      (notebookType === NotebookTypes.User.toLowerCase() && isLoggingNotebooksTypeUserOut) ||
      (notebookType === NotebookTypes.Tenant.toLowerCase() && isLoggingNotebooksTypeTenantOut)
    );
  }, [notebookType, isLoggingNotebooksTypeUserOut, isLoggingNotebooksTypeTenantOut]);

  const onMenuItemClick = (item: IItem) => {
    Analytics.track({
      category: EVENTS.menuItemClick.category,
      action: EVENTS.menuItemClick.action,
      label: item?.label
    });

    if (item?.type === MenuItemTypes.Notebook) {
      openNotebookTab({
        path: item?.path,
        setIsLoggingNotebooksTypeUserOut,
        setIsLoggingNotebooksTypeTenantOut
      });
    } else {
      navigate(item?.path);
    }

    // Closing main-menu drop-down on clicking a menu-item.
    onMainMenuClose();
  };

  return (
    <MainMenuWrapper
      mainMenuButtonAnchorEl={mainMenuButtonAnchorEl}
      onMainMenuClose={onMainMenuClose}>
      {!!mainMenuItem && (
        <>
          <Paper elevation={0} className={classes.root}>
            <ImageList cols={1} data-testid="mainMenuList">
              <ImageListItem
                onClick={() => onMenuItemClick(mainMenuItem)}
                className={classes.mainMenuItemWrapper}
                data-testid="mainMenuItem">
                <Paper
                  className={clsx(classes.baseMenuItem, classes.mainMenuItem, {
                    ["active"]: activeKey === mainMenuItem?.label
                  })}
                  {...(activeKey === mainMenuItem?.label ? {} : { elevation: 0 })}>
                  <Box data-testid="mainMenuItemIcon">
                    {mainMenuItem?.menuIcon?.({
                      width: 22,
                      height: 22,
                      color: theme.palette.secondary.main,
                      viewBox: "0 0 16 16"
                    })}
                  </Box>
                  <Typography variant="body2" color="textSecondary" data-testid="mainMenuItemLabel">
                    {mainMenuItem?.label}
                  </Typography>
                </Paper>
              </ImageListItem>
            </ImageList>
          </Paper>
          <Divider height={1.5} color={theme.palette.common.white} />
        </>
      )}
      <Paper elevation={0} className={classes.root}>
        {map(slicedMenuItems, (value, slicedMenuItemIndex) => (
          <ImageList key={uuidv4()} rowHeight={100} data-testid={`menuList-${slicedMenuItemIndex}`}>
            {map(value, (item, index) => (
              <ImageListItem
                key={uuidv4()}
                onClick={() => !isDisabledMenuItem(item?.type) && onMenuItemClick(item)}
                className={classes.menuItemWrapper}
                data-testid={`menuItem-${index}`}>
                <Paper
                  elevation={0}
                  className={clsx(classes.baseMenuItem, classes.menuItem, {
                    ["active"]: activeKey === item?.label,
                    ["disabled"]: isDisabledMenuItem(item?.type)
                  })}>
                  <Box data-testid={`menuItemIcon-${index}`}>
                    {item?.menuIcon?.({
                      width: 20,
                      height: 20,
                      viewBox: "0 0 18 18",
                      color: theme.palette.secondary.main
                    })}
                  </Box>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    data-testid={`menuItemLabel-${index}`}>
                    {item?.label}
                  </Typography>
                </Paper>
              </ImageListItem>
            ))}
          </ImageList>
        ))}
      </Paper>
    </MainMenuWrapper>
  );
};

export default MainMenu;
