/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";
import { getEntitiesByProjectIdWithRethrow } from "src/api";
import { Entities } from "src/types";

export const useGetInputEntities = ({
  id,
  scenarioId,
  jobRunId,
  onSuccess,
  datasetId
}: {
  id?: string;
  scenarioId?: string;
  jobRunId?: string;
  onSuccess?: (data: Entities) => void;
  datasetId?: string;
}) => {
  return useQuery<Entities>({
    queryKey: ["project", id, "inputEntities"],
    queryFn: () => {
      if (!id) {
        throw "id is absent";
      }

      return getEntitiesByProjectIdWithRethrow(id, scenarioId, jobRunId, datasetId);
    },
    onSuccess,
    cacheTime: 0,
    staleTime: 0
  });
};
