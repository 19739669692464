import React, { MutableRefObject } from "react";

type ContextTypes = {
  historyStack: MutableRefObject<string[]>;

  isNavBars: boolean;
  isFetchingProjects: boolean;
};

// @ts-ignore
export const PrivateRouteContext = React.createContext<ContextTypes>();
