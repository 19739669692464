import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import _, { includes } from "lodash";
import { HelpOutline, InfoOutlined } from "@material-ui/icons";
import { useLocation, useParams } from "react-router";

import { recipeModelProblemTypesDocsUrl } from "src/helpers/helpers";
import { Autocomplete, OverflowTooltip, Select, Switch } from "src/components";
import { useAutoMLRecipeContext } from "../../AutoMLRecipeContext/useAutoMLRecipeContext";
import { useGetAllowedDatasets } from "src/pages/Projects/common/useGetAllowedDatasets";
import { Entities, EntityTypeEnum } from "src/types";
import TargetColumnSelect, { NO_TARGET_COL_TEXT } from "./TargetColumnSelect";
import { MachineLearningTask } from "../../hooks/useGetAutoMLProblemTypes";
import { AiGuideMiniIcon } from "src/icons/NewUX";
import AIGuideDialog from "src/pages/Projects/AIGuide/common/AIGuideDialog";
import {
  ThreadResponseDtoDatasetContextEnum,
  ThreadResponseDtoTargetTypeEnum
} from "openapi/Models/thread-response-dto";

type Props = {
  allEntities: Entities | undefined;
  isDefaultScenario: boolean;
  targetCol: string;
};

const useStyles = makeStyles({
  problemTypeInput: {
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "16px",
    paddingTop: "16px"
  },
  inputAdornment: {
    marginRight: "16px"
  },
  isExperimentalBox: {
    gap: "8px",
    alignItems: "center",
    paddingTop: "12px",
    width: "auto"
  },
  problemTypeHelperText: {
    paddingBottom: "4px"
  },
  formItemContainer: {
    display: "flex",
    width: "100%",
    gap: 5,
    alignItems: "center"
  },
  formItem: {
    flex: "1"
  },
  helpTextContainer: {
    width: "20px"
  },
  aiGuide: {
    opacity: ({ isAiGuideDisabled }: any) => (isAiGuideDisabled ? 0.5 : 1)
  }
});
const AutoMLRecipeBaseInputs = ({ allEntities, isDefaultScenario, targetCol }: Props) => {
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const { projectId, scenarioId } = useParams<$TSFixMe>();
  const {
    inputDatasets,
    recipe,
    setInputDatasets,
    problemType,
    setProblemType,
    allProblemTypes,
    isFetchingProblemTypes,
    isExperimental,
    setIsExperimental,
    setActualProblemType,
    handleInputChange,
    suggestedTemplateName,
    setSelectedTransform,
    setValues,
    isNumericTarget,
    allColumns,
    numericColumns,
    columnsLoading
  } = useAutoMLRecipeContext();
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);

  const classes = useStyles({ isAiGuideDisabled: !inputDatasets?.[0]?.id });

  const { allowedInputEntities, isFetching } = useGetAllowedDatasets({
    allEntities,
    recipeId: recipe?.id
  });

  const onlyNumericColumns = useMemo(
    () => includes([MachineLearningTask.TIMESERIES_FORECASTING], problemType),
    [problemType]
  );

  const targetColOptions = useMemo(() => {
    const filteredColumns = onlyNumericColumns ? numericColumns : allColumns;
    return (
      filteredColumns?.map((column: any) => ({
        label: <OverflowTooltip value={column.name} title={column.name} />,
        value: column.name,
        category: "Select Target Column"
      })) || []
    );
  }, [onlyNumericColumns, numericColumns, allColumns]);

  const allowedInputDatasets = useMemo(
    () =>
      allowedInputEntities?.filter(
        (dataset: $TSFixMe) =>
          dataset.entityMeta?.entityViewType?.toLowerCase() !==
            EntityTypeEnum.CHART.toLowerCase() &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !==
            EntityTypeEnum.ARTIFACT.toLowerCase() &&
          dataset.entityMeta?.entityViewType?.toLowerCase() !== EntityTypeEnum.MODEL.toLowerCase()
      ),
    [allowedInputEntities]
  );
  const {
    binaryClassificationTemplate,
    multiClassClassificationTemplate,
    binaryExpermentalTemplate
  } = useMemo(() => {
    const binaryClassificationTemplate = _.get(
      _.find(allProblemTypes, { value: MachineLearningTask.BINARY_CLASSIFICATION }),
      "templateName"
    );
    const multiClassClassificationTemplate = _.get(
      _.find(allProblemTypes, { value: MachineLearningTask.MULTICLASS_CLASSIFICATION }),
      "templateName"
    );
    const binaryExpermentalTemplate = _.get(
      _.find(allProblemTypes, { value: MachineLearningTask.BINARY_CLASSIFICATION_GPT }),
      "templateName"
    );
    return {
      binaryClassificationTemplate,
      multiClassClassificationTemplate,
      binaryExpermentalTemplate
    };
  }, [allProblemTypes]);

  const hasNoTargetColumn = useMemo(() => {
    return (
      targetCol === NO_TARGET_COL_TEXT ||
      (!targetCol &&
        _.filter(
          allProblemTypes,
          (task) =>
            task.value === MachineLearningTask.ANOMALY_DETECTION ||
            task.value === MachineLearningTask.CLUSTERING
        )
          .map((task) => task.templateName)
          .includes(problemType))
    );
  }, [allProblemTypes, targetCol, problemType]);

  const filteredProblemTypes = useMemo(() => {
    const nonExperimentalProblemTypes = allProblemTypes?.filter(
      (curr: any) => curr.value !== MachineLearningTask.BINARY_CLASSIFICATION_GPT
    );
    if (hasNoTargetColumn) {
      return nonExperimentalProblemTypes?.filter(
        (curr: any) =>
          curr.value === MachineLearningTask.ANOMALY_DETECTION ||
          curr.value === MachineLearningTask.CLUSTERING
      );
    }
    if (targetCol && !isNumericTarget) {
      return nonExperimentalProblemTypes?.filter(
        (curr: any) => curr.value !== MachineLearningTask.TIMESERIES_FORECASTING
      );
    }

    return nonExperimentalProblemTypes;
  }, [allProblemTypes, isNumericTarget, hasNoTargetColumn, targetCol]);

  const autoMLProblemTypeValues = filteredProblemTypes?.map((type: any) => ({
    label: type.value,
    value: type.templateName
  }));

  const navigateToDocsPage = () => {
    window.open(recipeModelProblemTypesDocsUrl(), "_blank");
  };

  const handleIsExperimentalUpdate = (event: any) => {
    setIsExperimental(event.target.checked);
  };

  useEffect(() => {
    if (problemType === binaryClassificationTemplate) {
      setActualProblemType(
        isExperimental ? binaryExpermentalTemplate : binaryClassificationTemplate
      );
    } else {
      problemType && setActualProblemType(problemType);
    }
  }, [isExperimental, problemType]);

  const handleDatasetChange = (__: any, datasetId: string | null) => {
    const updatedDataset = allowedInputDatasets?.find((entity) => entity.id === datasetId);
    setActualProblemType("");
    setProblemType("");
    setIsExperimental(false);
    setSelectedTransform({});
    setValues({});
    setInputDatasets(updatedDataset ? [updatedDataset] : []);
  };

  const isAutoSelected =
    suggestedTemplateName &&
    targetCol &&
    targetCol !== NO_TARGET_COL_TEXT &&
    suggestedTemplateName === problemType;

  const openAIGuideDialog = () => {
    setShowAIGuideDialog(true);
  };

  return (
    <>
      <Grid className={classes.formItemContainer}>
        <Grid item className={classes.formItem}>
          <Autocomplete
            label="Dataset"
            loading={isFetching}
            required
            isSorted
            value={inputDatasets?.[0]?.id}
            disabled={isJobPath || !isDefaultScenario}
            size="small"
            onChange={handleDatasetChange}
            options={
              _.map(allowedInputDatasets, (dataset) => ({
                label: dataset.displayName || dataset.name,
                value: dataset.id
              })) ?? []
            }
          />
        </Grid>
        <Grid item className={classes.helpTextContainer}>
          <Tooltip arrow title="Minimum 50 rows should be present in data for reliable processing.">
            <InfoOutlined color="disabled" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid className={classes.formItemContainer}>
        <Grid item className={classes.formItem}>
          <Grid container direction="row" className={classes.problemTypeInput}>
            <TargetColumnSelect
              variant="outlined"
              margin="dense"
              label="Target Column"
              disabled={isJobPath || !inputDatasets?.[0]?.id}
              required
              value={hasNoTargetColumn ? NO_TARGET_COL_TEXT : targetCol}
              values={targetColOptions || []}
              columnsLoading={columnsLoading}
              onChange={(value: $TSFixMe) => {
                handleInputChange({ target: { value: value, name: "targetCol" } });
              }}
              endAdornment={
                !isJobPath &&
                isDefaultScenario && (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    <Grid className={classes.aiGuide}>
                      <IconButton
                        onClick={() => openAIGuideDialog()}
                        size="small"
                        disabled={!inputDatasets?.[0]?.id}>
                        <AiGuideMiniIcon width={32} height={20} viewBox="10 -2 38 38" />
                      </IconButton>
                    </Grid>
                  </InputAdornment>
                )
              }
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item className={classes.helpTextContainer}>
          {onlyNumericColumns && (
            <Tooltip arrow title="Only numeric columns are supported for this problem type.">
              <InfoOutlined color="disabled" fontSize="small" />
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid container direction="column" wrap="nowrap">
        <Grid className={classes.formItemContainer}>
          <Grid item className={classes.formItem}>
            <Grid container direction="row" className={classes.problemTypeInput}>
              <Select
                variant="outlined"
                margin="dense"
                isSorted
                isLoading={isFetchingProblemTypes}
                label={`Problem Type${isAutoSelected ? "(Auto Selected)" : ""}`}
                disabled={isJobPath || isFetchingProblemTypes || !isDefaultScenario}
                required
                endAdornment={
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    <IconButton onClick={navigateToDocsPage} size="small">
                      <HelpOutline fontSize="small" style={{ color: "#4646b5" }} />
                    </IconButton>
                  </InputAdornment>
                }
                value={problemType}
                values={autoMLProblemTypeValues || []}
                onChange={(event: $TSFixMe) => setProblemType(event.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item className={classes.helpTextContainer}>
            {problemType === multiClassClassificationTemplate && (
              <Tooltip arrow title="Data with max 25 classes is supported">
                <InfoOutlined color="disabled" fontSize="small" />
              </Tooltip>
            )}
          </Grid>
        </Grid>

        {isAutoSelected && (
          <Grid
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            className={classes.problemTypeHelperText}>
            <InfoOutlined style={{ color: "#9e9e9e", fontSize: "16px", marginRight: "4px" }} />
            <FormHelperText>Auto-Selected. Can be changed</FormHelperText>
          </Grid>
        )}
      </Grid>

      {problemType === binaryClassificationTemplate && (
        <Grid container item wrap="nowrap" direction="row">
          <Grid container wrap="nowrap" direction="row" className={classes.isExperimentalBox}>
            <Switch checked={isExperimental} onChange={handleIsExperimentalUpdate} />
            <Typography>Dynamic features</Typography>
          </Grid>
          <Box pl="12px" pt="9px">
            <Tooltip
              arrow
              title="Dynamic features generates custom features on the fly based on the business context and data">
              <InfoOutlined style={{ color: "#9e9e9e", fontSize: "16px" }} />
            </Tooltip>
          </Box>
        </Grid>
      )}
      <AIGuideDialog
        open={showAIGuideDialog}
        onClose={() => {
          setShowAIGuideDialog(false);
        }}
        projectId={projectId!}
        scenarioId={scenarioId!}
        datasetId={inputDatasets?.[0]?.id!}
        targetType={ThreadResponseDtoTargetTypeEnum.Dataset}
        datasetContext={ThreadResponseDtoDatasetContextEnum.RapidModelRecipe}
      />
    </>
  );
};

export default React.memo(AutoMLRecipeBaseInputs);
