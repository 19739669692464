import React from "react";

const Loading = () => (
  <svg viewBox="0 0 241 215" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m88.004 155.78c32.991 0 59.735-26.744 59.735-59.734 0-32.991-26.744-59.735-59.735-59.735-32.991 0-59.735 26.744-59.735 59.735 0 32.99 26.744 59.734 59.735 59.734z"
      fill="#F2F2F2"
    />
    <path
      d="m135.76 173.1c-28.55 2e-3 -53.391-21.296-57.061-50.346l0.7014-0.088c3.6244 28.69 28.162 49.726 56.36 49.728 2.396-1e-3 4.789-0.152 7.166-0.453 31.099-3.928 53.204-32.426 49.275-63.525l0.702-0.089c3.977 31.486-18.402 60.338-49.889 64.316-2.406 0.304-4.829 0.457-7.255 0.457z"
      fill="#123553"
    />
    <path
      d="m137.29 184.92h-11.241c-0.24 1e-3 -0.477-0.053-0.694-0.157-0.216-0.105-0.406-0.257-0.556-0.445-0.147-0.183-0.251-0.397-0.304-0.627-0.053-0.229-0.053-0.467 0-0.697 1.066-4.707 1.003-9.601-0.184-14.28-0.081-0.323-0.058-0.663 0.066-0.973 0.123-0.309 0.342-0.571 0.623-0.749 3.889-2.409 8.124-2.334 12.947 0.23 0.219 0.116 0.409 0.28 0.554 0.481s0.242 0.432 0.283 0.677c0.799 5.023 0.827 10.14 0.083 15.172-0.053 0.38-0.242 0.728-0.532 0.98-0.29 0.251-0.661 0.389-1.045 0.388z"
      fill="#f1957d"
    />
    <path
      d="m86.037 124.83h-11.241c-0.2402 0-0.4774-0.054-0.6938-0.158-0.2163-0.104-0.4063-0.256-0.5556-0.444-0.1475-0.183-0.2516-0.398-0.3044-0.627s-0.053-0.468-6e-4 -0.697c1.0707-4.724 1.0034-9.635-0.1962-14.328-0.0759-0.309-0.0575-0.634 0.0527-0.932s0.3072-0.557 0.5657-0.743c5.7803-4.157 10.332-4.036 13.53 0.359 0.1442 0.201 0.2399 0.432 0.2798 0.675 0.8544 5.137 0.9017 10.375 0.1401 15.526-0.0529 0.381-0.2418 0.729-0.5317 0.98-0.2898 0.252-0.661 0.39-1.0449 0.389z"
      fill="#ccc"
    />
    <path
      d="m132.04 174.37h-3.867c-0.214-1e-3 -0.418-0.086-0.569-0.237s-0.236-0.356-0.236-0.57c0-0.213 0.085-0.418 0.236-0.569s0.355-0.236 0.569-0.237h3.867c0.214 0 0.419 0.085 0.57 0.236 0.151 0.152 0.236 0.357 0.236 0.57 0 0.214-0.085 0.419-0.236 0.571-0.151 0.151-0.356 0.236-0.57 0.236z"
      fill="#fff"
    />
    <path
      d="m136.76 177.56h-8.595c-0.214-1e-3 -0.418-0.086-0.569-0.237s-0.236-0.356-0.236-0.57c0-0.213 0.085-0.418 0.236-0.569s0.355-0.237 0.569-0.237h8.595c0.214 0 0.418 0.086 0.569 0.237s0.236 0.356 0.236 0.569c0 0.214-0.085 0.419-0.236 0.57s-0.355 0.236-0.569 0.237z"
      fill="#fff"
    />
    <path
      d="m82.552 113.57h-3.867c-0.2136 0-0.4182-0.085-0.5691-0.236-0.1508-0.151-0.2355-0.356-0.2355-0.57 0-0.213 0.0847-0.418 0.2355-0.569 0.1509-0.151 0.3555-0.237 0.5691-0.237h3.867c0.2136 0 0.4182 0.086 0.5691 0.237 0.1508 0.151 0.2355 0.356 0.2355 0.569 0 0.214-0.0847 0.419-0.2355 0.57-0.1509 0.151-0.3555 0.236-0.5691 0.236z"
      fill="#fff"
    />
    <path
      d="m82.552 116.4h-3.867c-0.2136 0-0.4182-0.085-0.5691-0.237-0.1508-0.151-0.2355-0.356-0.2355-0.569 0-0.214 0.0847-0.419 0.2355-0.57 0.1509-0.151 0.3555-0.236 0.5691-0.236h3.867c0.2136 0 0.4182 0.085 0.5691 0.236 0.1508 0.151 0.2355 0.356 0.2355 0.57 0 0.213-0.0847 0.418-0.2355 0.569-0.1509 0.152-0.3555 0.237-0.5691 0.237z"
      fill="#fff"
    />
    <path
      d="m82.552 119.23h-3.867c-0.2136-1e-3 -0.4182-0.086-0.5691-0.237-0.1508-0.151-0.2355-0.356-0.2355-0.569 0-0.214 0.0847-0.419 0.2355-0.57 0.1509-0.151 0.3555-0.236 0.5691-0.237h3.867c0.2136 1e-3 0.4182 0.086 0.5691 0.237 0.1508 0.151 0.2355 0.356 0.2355 0.57 0 0.213-0.0847 0.418-0.2355 0.569-0.1509 0.151-0.3555 0.236-0.5691 0.237z"
      fill="#fff"
    />
    <path
      d="m237.33 138.11h-35.934c-0.964-1e-3 -1.887-0.384-2.568-1.065s-1.064-1.604-1.065-2.567v-22.132c1e-3 -0.963 0.384-1.887 1.065-2.568s1.604-1.064 2.568-1.065h35.934c0.963 1e-3 1.886 0.384 2.567 1.065s1.065 1.605 1.066 2.568v22.132c-1e-3 0.963-0.385 1.886-1.066 2.567s-1.604 1.064-2.567 1.065z"
      fill="#E6E6E6"
    />
    <path
      d="m223.06 135.26h-19.131c-0.902-1e-3 -1.767-0.36-2.405-0.998s-0.996-1.502-0.998-2.404v-16.892c2e-3 -0.902 0.36-1.767 0.998-2.405 0.638-0.637 1.503-0.996 2.405-0.997h30.882c0.902 1e-3 1.767 0.36 2.405 0.997 0.637 0.638 0.996 1.503 0.997 2.405v5.14c-4e-3 4.018-1.602 7.87-4.443 10.71-2.841 2.841-6.693 4.439-10.71 4.444z"
      fill="#fff"
    />
    <path
      d="m231.73 120.82h-24.722c-0.213 0-0.418-0.085-0.57-0.237-0.151-0.151-0.236-0.356-0.236-0.57s0.085-0.419 0.236-0.57c0.152-0.151 0.357-0.236 0.57-0.236h24.722c0.214 0 0.419 0.085 0.57 0.236s0.236 0.356 0.236 0.57-0.085 0.419-0.236 0.57c-0.151 0.152-0.356 0.237-0.57 0.237z"
      fill="#f1957d"
    />
    <path
      d="m210.87 124h-3.867c-0.213 0-0.418-0.085-0.569-0.236s-0.235-0.356-0.235-0.57c0-0.213 0.084-0.418 0.235-0.569 0.151-0.152 0.356-0.237 0.569-0.237h3.867c0.214 0 0.419 0.085 0.569 0.237 0.151 0.151 0.236 0.356 0.236 0.569 0 0.214-0.085 0.419-0.236 0.57-0.15 0.151-0.355 0.236-0.569 0.236z"
      fill="#f1957d"
    />
    <path
      d="m217.72 127.19h-10.717c-0.213 0-0.418-0.085-0.57-0.236-0.151-0.151-0.236-0.356-0.236-0.57s0.085-0.419 0.236-0.57c0.152-0.151 0.357-0.236 0.57-0.236h10.717c0.213 0 0.418 0.085 0.57 0.236 0.151 0.151 0.236 0.356 0.236 0.57s-0.085 0.419-0.236 0.57c-0.152 0.151-0.357 0.236-0.57 0.236z"
      fill="#f1957d"
    />
    <path
      d="m196.51 115.88c4.198 0 7.602-3.403 7.602-7.602 0-4.198-3.404-7.601-7.602-7.601s-7.601 3.403-7.601 7.601c0 4.199 3.403 7.602 7.601 7.602z"
      fill="#123553"
    />
    <path
      d="m110.46 50.448c0.503-2.5543 1.505-4.9844 2.947-7.1516 1.442-2.1673 3.297-4.0292 5.459-5.4795 4.367-2.929 9.718-4.0036 14.876-2.9873 2.554 0.5032 4.985 1.5046 7.152 2.947s4.029 3.2975 5.479 5.4595c1.451 2.162 2.461 4.5884 2.973 7.1408 0.513 2.5524 0.518 5.1808 0.015 7.735l-0.694-0.1366c0.98-4.9745-0.056-10.135-2.881-14.345-2.824-4.2106-7.206-7.1266-12.18-8.1067-4.975-0.98-10.135 0.0562-14.346 2.8807-4.21 2.8245-7.126 7.206-8.106 12.18l-0.694-0.1366z"
      fill="#123553"
    />
    <path
      d="m117.99 62.955l-11.232 0.4652c-0.24 0.0104-0.479-0.0336-0.699-0.1288-0.221-0.0952-0.417-0.239-0.574-0.4208-0.155-0.1771-0.268-0.387-0.33-0.6139s-0.072-0.4649-0.029-0.6963c0.861-4.6999 0.61-9.5364-0.734-14.122-0.104-0.3507-0.083-0.7265 0.06-1.0634 0.142-0.3369 0.397-0.614 0.721-0.7841 4.6-2.3901 8.911-2.5113 12.811-0.3606 0.204 0.111 0.382 0.2653 0.521 0.4521 0.138 0.1868 0.235 0.4016 0.282 0.6295 1.015 5.0035 1.258 10.133 0.722 15.21-0.038 0.382-0.212 0.7376-0.491 1.001s-0.644 0.4167-1.028 0.4315z"
      fill="#f1957d"
    />
    <path
      d="m117.52 55.603l-8.588 0.3557c-0.106 0.0045-0.212-0.012-0.311-0.0484-0.1-0.0363-0.191-0.092-0.269-0.1637s-0.141-0.1581-0.186-0.2542c-0.044-0.0961-0.069-0.2001-0.074-0.3059-4e-3 -0.1059 0.012-0.2115 0.049-0.311 0.036-0.0995 0.092-0.1908 0.164-0.2687 0.071-0.0779 0.158-0.1409 0.254-0.1854s0.2-0.0696 0.306-0.0739l8.588-0.3557c0.106-0.0044 0.211 0.0121 0.311 0.0486 0.099 0.0364 0.19 0.0921 0.268 0.1638s0.141 0.158 0.186 0.2541c0.044 0.096 0.07 0.1999 0.074 0.3057s-0.012 0.2114-0.049 0.3109c-0.036 0.0994-0.092 0.1906-0.163 0.2685-0.072 0.0779-0.158 0.141-0.255 0.1855-0.096 0.0445-0.2 0.0697-0.305 0.0741z"
      fill="#fff"
    />
    <path
      d="m117.4 52.778l-8.588 0.3557c-0.214 0.0089-0.422-0.0675-0.58-0.2123-0.157-0.1448-0.25-0.3462-0.259-0.5599s0.067-0.4221 0.212-0.5794 0.346-0.2507 0.56-0.2595l8.588-0.3557c0.214-0.0089 0.422 0.0675 0.579 0.2123 0.158 0.1448 0.251 0.3462 0.26 0.5599 9e-3 0.2136-0.068 0.4221-0.212 0.5794-0.145 0.1573-0.347 0.2507-0.56 0.2595z"
      fill="#fff"
    />
    <path
      d="m191.5 59.397h-35.935c-0.963-0.0011-1.886-0.3841-2.567-1.0651s-1.064-1.6043-1.065-2.5673v-22.132c1e-3 -0.963 0.384-1.8863 1.065-2.5673s1.604-1.064 2.567-1.0651h35.935c0.963 0.0011 1.886 0.3841 2.567 1.0651s1.064 1.6043 1.065 2.5673v22.132c-1e-3 0.963-0.384 1.8863-1.065 2.5673s-1.604 1.064-2.567 1.0651z"
      fill="#E6E6E6"
    />
    <path
      d="m177.22 56.546h-19.131c-0.902-9e-4 -1.767-0.3597-2.404-0.9974-0.638-0.6378-0.997-1.5025-0.998-2.4044v-16.892c1e-3 -0.902 0.36-1.7667 0.998-2.4045 0.637-0.6377 1.502-0.9964 2.404-0.9974h30.883c0.902 1e-3 1.767 0.3597 2.404 0.9975 0.638 0.6377 0.997 1.5024 0.998 2.4044v5.1405c-5e-3 4.0175-1.603 7.8692-4.444 10.71-2.84 2.8408-6.692 4.4387-10.71 4.4432z"
      fill="#fff"
    />
    <path
      d="m185.89 42.1h-24.721c-0.214 0-0.419-0.0849-0.57-0.2361s-0.236-0.3563-0.236-0.5701c0-0.2139 0.085-0.4189 0.236-0.5701s0.356-0.2362 0.57-0.2362h24.721c0.214 0 0.419 0.085 0.57 0.2362 0.152 0.1512 0.237 0.3562 0.237 0.5701 0 0.2138-0.085 0.4189-0.237 0.5701-0.151 0.1512-0.356 0.2361-0.57 0.2361z"
      fill="#f1957d"
    />
    <path
      d="m165.03 45.286h-3.867c-0.214-5e-4 -0.418-0.0856-0.569-0.2368-0.151-0.1511-0.236-0.356-0.236-0.5695 0-0.2136 0.085-0.4184 0.236-0.5695 0.151-0.1512 0.355-0.2363 0.569-0.2368h3.867c0.214 5e-4 0.418 0.0856 0.569 0.2368 0.151 0.1511 0.236 0.3559 0.236 0.5695 0 0.2135-0.085 0.4184-0.236 0.5695-0.151 0.1512-0.355 0.2363-0.569 0.2368z"
      fill="#f1957d"
    />
    <path
      d="m171.88 48.472h-10.716c-0.214 0-0.419-0.0849-0.57-0.2361s-0.236-0.3563-0.236-0.5702c0-0.2138 0.085-0.4189 0.236-0.5701s0.356-0.2361 0.57-0.2361h10.716c0.214 4e-4 0.418 0.0856 0.569 0.2367 0.151 0.1512 0.236 0.356 0.236 0.5695 0 0.2136-0.085 0.4184-0.236 0.5696-0.151 0.1511-0.355 0.2363-0.569 0.2367z"
      fill="#f1957d"
    />
    <path
      d="m156.2 63.229c2.216-3.5655 1.123-8.2527-2.443-10.469-3.565-2.2164-8.253-1.1227-10.469 2.4428s-1.123 8.2527 2.443 10.469c3.565 2.2164 8.253 1.1227 10.469-2.4428z"
      fill="#123553"
    />
    <path
      d="m48.48 118.52c-3.1188-8.245-2.8346-17.39 0.7899-25.426 3.6246-8.0351 10.293-14.301 18.537-17.42s17.391-2.8346 25.426 0.7899c8.0352 3.6246 14.301 10.293 17.42 18.537l-0.661 0.2503c-1.512-3.9955-3.795-7.6542-6.721-10.767-2.925-3.1129-6.4349-5.6192-10.329-7.3758-3.8939-1.7565-8.0957-2.7288-12.366-2.8615-4.2697-0.1327-8.5238 0.577-12.519 2.0883-3.9955 1.5114-7.6542 3.795-10.767 6.7203-3.113 2.9254-5.6192 6.4352-7.3758 10.329-1.7565 3.894-2.7288 8.0959-2.8615 12.366-0.1327 4.269 0.577 8.523 2.0883 12.519l-0.6613 0.25z"
      fill="#123553"
    />
    <path
      d="m77.554 85.597h-11.241c-0.2402 5e-4 -0.4773-0.0534-0.6937-0.1576s-0.4063-0.2561-0.5556-0.4442c-0.1475-0.1833-0.2516-0.3977-0.3044-0.627-0.0529-0.2293-0.053-0.4675-6e-4 -0.6969 1.0642-4.6968 1.004-9.5786-0.1757-14.248-0.0836-0.3304-0.058-0.6791 0.0731-0.9937s0.3606-0.5783 0.6542-0.7515c4.072-2.3704 8.4206-2.298 12.924 0.2155 0.2133 0.1163 0.3971 0.2797 0.5376 0.4779 0.1404 0.1981 0.2338 0.4257 0.2729 0.6654 0.8016 5.0299 0.8306 10.153 0.0858 15.191-0.0529 0.3801-0.2418 0.7282-0.5317 0.9798s-0.6611 0.3897-1.0449 0.3886z"
      fill="#f1957d"
    />
    <path
      d="m72.302 74.693h-3.867c-0.2136-5e-4 -0.4182-0.0856-0.5691-0.2368-0.1508-0.1511-0.2355-0.356-0.2355-0.5695 0-0.2136 0.0847-0.4184 0.2355-0.5696 0.1509-0.1511 0.3555-0.2363 0.5691-0.2367h3.867c0.2136 4e-4 0.4182 0.0856 0.569 0.2367 0.1509 0.1512 0.2356 0.356 0.2356 0.5696 0 0.2135-0.0847 0.4184-0.2356 0.5695-0.1508 0.1512-0.3554 0.2363-0.569 0.2368z"
      fill="#fff"
    />
    <path
      d="m77.03 77.879h-8.5953c-0.2138 0-0.4189-0.0849-0.5701-0.2361s-0.2362-0.3563-0.2362-0.5702c0-0.2138 0.085-0.4189 0.2362-0.5701s0.3563-0.2361 0.5701-0.2361h8.5953c0.2136 4e-4 0.4182 0.0856 0.569 0.2367 0.1509 0.1512 0.2356 0.356 0.2356 0.5695 0 0.2136-0.0847 0.4184-0.2356 0.5696-0.1508 0.1511-0.3554 0.2363-0.569 0.2367z"
      fill="#fff"
    />
    <path
      d="m40.567 118.07h-35.935c-0.96305-1e-3 -1.8863-0.384-2.5673-1.065-0.68098-0.681-1.064-1.605-1.0651-2.568v-22.132c0.00109-0.963 0.38413-1.8863 1.0651-2.5673 0.68097-0.681 1.6043-1.064 2.5673-1.0651h35.935c0.9631 0.0011 1.8864 0.3841 2.5674 1.0651s1.064 1.6043 1.0651 2.5673v22.132c-0.0011 0.963-0.3841 1.887-1.0651 2.568s-1.6043 1.064-2.5674 1.065z"
      fill="#E6E6E6"
    />
    <path
      d="m26.29 115.22h-19.131c-0.90195-1e-3 -1.7667-0.36-2.4045-0.998-0.63779-0.637-0.99656-1.502-0.9976-2.404v-16.892c0.00104-0.9019 0.35981-1.7666 0.9976-2.4044 0.63779-0.6377 1.5025-0.9964 2.4045-0.9974h30.883c0.9019 1e-3 1.7666 0.3597 2.4044 0.9975 0.6378 0.6377 0.9965 1.5024 0.9975 2.4043v5.1409c-0.0046 4.017-1.6026 7.869-4.4434 10.71-2.8408 2.84-6.6925 4.438-10.71 4.443z"
      fill="#fff"
    />
    <path
      d="m34.961 100.78h-24.721c-0.2139 0-0.41895-0.085-0.57015-0.236s-0.23614-0.357-0.23614-0.5703c0-0.2139 0.08494-0.4189 0.23614-0.5701s0.35625-0.2362 0.57015-0.2362h24.721c0.2138 0 0.4189 0.085 0.5701 0.2362s0.2361 0.3562 0.2361 0.5701c0 0.2133-0.0849 0.4193-0.2361 0.5703s-0.3563 0.236-0.5701 0.236z"
      fill="#f1957d"
    />
    <path
      d="m14.106 103.96h-3.867c-0.2136-1e-3 -0.41823-0.086-0.56907-0.237-0.15085-0.151-0.23556-0.356-0.23556-0.57 0-0.213 0.08471-0.418 0.23556-0.569 0.15084-0.151 0.35547-0.236 0.56907-0.237h3.867c0.2136 1e-3 0.4182 0.086 0.569 0.237 0.1509 0.151 0.2356 0.356 0.2356 0.569 0 0.214-0.0847 0.419-0.2356 0.57-0.1508 0.151-0.3554 0.236-0.569 0.237z"
      fill="#f1957d"
    />
    <path
      d="m20.955 107.15h-10.716c-0.2136-1e-3 -0.41823-0.086-0.56907-0.237-0.15085-0.151-0.23556-0.356-0.23556-0.57 0-0.213 0.08471-0.418 0.23556-0.569 0.15084-0.151 0.35547-0.236 0.56907-0.237h10.716c0.2139 0 0.4189 0.085 0.5702 0.236 0.1512 0.151 0.2361 0.356 0.2361 0.57s-0.0849 0.419-0.2361 0.57c-0.1513 0.152-0.3563 0.237-0.5702 0.237z"
      fill="#f1957d"
    />
    <path
      d="m54.756 121.2c2.2164-3.565 1.1227-8.252-2.4428-10.469-3.5655-2.216-8.2526-1.123-10.469 2.443-2.2164 3.565-1.1227 8.253 2.4428 10.469s8.2527 1.123 10.469-2.443z"
      fill="#123553"
    />
    <path
      d="m116.08 106.8h-11.241c-0.24 1e-3 -0.477-0.053-0.694-0.157-0.216-0.105-0.406-0.256-0.555-0.445-0.148-0.183-0.252-0.397-0.305-0.627-0.053-0.229-0.053-0.467 0-0.696 1.062-4.689 1.004-9.5619-0.17-14.224-0.083-0.3351-0.055-0.6881 0.081-1.0057 0.135-0.3175 0.37-0.5822 0.67-0.7539 5.461-3.1259 9.785-3.0396 13.219 0.2639 0.241 0.2357 0.4 0.5425 0.452 0.8752 0.835 5.0968 0.875 10.292 0.12 15.401-0.053 0.38-0.242 0.728-0.532 0.98-0.29 0.251-0.661 0.389-1.045 0.388z"
      fill="#415F80"
    />
    <path
      d="m111.54 96.254h-3.867c-0.214-5e-4 -0.418-0.0856-0.569-0.2368s-0.236-0.356-0.236-0.5695c0-0.2136 0.085-0.4184 0.236-0.5696 0.151-0.1511 0.355-0.2363 0.569-0.2367h3.867c0.213 4e-4 0.418 0.0856 0.569 0.2367 0.151 0.1512 0.235 0.356 0.235 0.5696 0 0.2135-0.084 0.4183-0.235 0.5695s-0.356 0.2363-0.569 0.2368z"
      fill="#fff"
    />
    <path
      d="m116.26 99.44h-8.595c-0.214 0-0.419-0.0849-0.57-0.2361s-0.236-0.3563-0.236-0.5702c0-0.2138 0.085-0.4189 0.236-0.5701s0.356-0.2362 0.57-0.2362h8.595c0.214 5e-4 0.419 0.0856 0.569 0.2368 0.151 0.1512 0.236 0.356 0.236 0.5695 0 0.2136-0.085 0.4184-0.236 0.5696-0.15 0.1511-0.355 0.2363-0.569 0.2367z"
      fill="#fff"
    />
  </svg>
);

export default Loading;
