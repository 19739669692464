import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React from "react";
import _ from "lodash";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";

import SelectDataAppCard from "./SelectDataAppCard";
import { CreateDataAppRequestDtoDataAppTypeEnum } from "openapi/Models/create-data-app-request-dto";
import { askAITypesInfoList } from "src/pages/DataApps/utils/DataApps.constants";

interface IProps {
  disabledAddDataAppActionMessage: string;
  disabledAskAICreateActionMessage: string;
  onTypeChange: (type: CreateDataAppRequestDtoDataAppTypeEnum) => void;
  onCancel: () => void;
}

const SelectDataAppType: React.FC<IProps> = (props) => {
  const {
    disabledAddDataAppActionMessage,
    disabledAskAICreateActionMessage,
    onTypeChange,
    onCancel
  } = props;

  return (
    <div style={{ maxWidth: "900px", margin: "auto" }}>
      <div style={{ display: "flex", gap: "8px", marginBottom: "16px" }}>
        <IconButton style={{ padding: 0 }} onClick={onCancel} data-testid="backIcon">
          <ArrowBackIcon />
        </IconButton>
        <Typography component="h3" style={{ color: "#515151" }} data-testid="selectConfigText">
          Select Configuration
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs="auto">
          <SelectDataAppCard
            disabledMsg={disabledAddDataAppActionMessage}
            title="Model DataApp"
            description="A model dataapp helps in sharing model metrics and interactive model usage capabilities to other users"
            configList={[
              "Uses model from the Canvas",
              "Contains model performance metrics",
              "What-If analysis",
              "Prediction Service",
              "AskAI on prediction data",
              "Save additional charts"
            ]}
            onClick={() => onTypeChange(CreateDataAppRequestDtoDataAppTypeEnum.RapidModel)}
          />
        </Grid>
        <Grid item xs="auto">
          <SelectDataAppCard
            disabledMsg={disabledAskAICreateActionMessage}
            title="Custom DataApp"
            description="A custom dataapp allows users to create a customised and configurable AskAI application which can be shared with other users"
            configList={[
              "Configurable AskAI application",
              <>
                <div>Input supports</div>
                <ul>
                  {_.map(askAITypesInfoList, ({ title, tooltip }, index) => (
                    <li key={index}>
                      <span>{title}</span>{" "}
                      <Tooltip title={tooltip}>
                        <InfoOutlinedIcon
                          style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                        />
                      </Tooltip>
                    </li>
                  ))}
                </ul>
              </>
            ]}
            onClick={() => onTypeChange(CreateDataAppRequestDtoDataAppTypeEnum.Askai)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SelectDataAppType;
