import React from "react";
import _ from "lodash";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";

interface IProps {
  value: number;
  disabled: boolean;
  onChange: (value: number) => void;
}

const PredictPeriod: React.FC<IProps> = (props) => {
  const { value, disabled, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const input = _.toNumber(e.target.value);

    if (!input || (/^\d+$/.test(e.target.value) && input > 0)) {
      onChange(input);
    }
  };

  return (
    <FormControl variant="outlined" disabled={disabled} fullWidth margin="dense" required={true}>
      <InputLabel test-id="predict-period">Future Predict Period</InputLabel>
      <OutlinedInput
        margin="dense"
        fullWidth
        disabled={disabled}
        label="Future Predict Period *"
        id={`parameters-input-future-predict-period`}
        type="number"
        value={value || ""}
        onChange={handleChange}
        inputProps={{
          type: "number",
          min: 1,
          "test-id": "selectedTransformParametersField"
        }}
      />
    </FormControl>
  );
};

export default PredictPeriod;
