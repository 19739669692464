// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Utils
import api from "utils/AxiosClient";

// Open API
import { EnvDto } from "openapi/Models";

export const enum UseGetEnvironmentsQueryKeys {
  Environments = "environments"
}

interface IEnvironmentsProps extends UseQueryOptions<unknown, unknown, EnvDto[]> {}

const useGetEnvironments = (props: IEnvironmentsProps): UseQueryResult<EnvDto[]> => {
  const { ...options } = props;

  return useQuery({
    queryKey: [UseGetEnvironmentsQueryKeys.Environments],
    queryFn: async () => await api.fetchResponse(async () => await api.EnvControllerApi.findEnvs()),
    select: (data) => (isArray(data) ? data : []),
    cacheTime: 0,
    ...options
  });
};

export default useGetEnvironments;
