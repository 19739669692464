import React, { useMemo, useState } from "react";
import _ from "lodash";

import AIGuideContainer from "../common/AIGuideContainer";
import ComponentNotFound from "src/components/Errors/ComponentNotFound";
import ReactException from "src/components/Errors/ReactException";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { ThreadResponseDto } from "openapi/Models/thread-response-dto";
import { useAIGuideContext } from "../common/useAIGuideContext";
import { useAIGuideStore } from "src/store/store";
import { useGetEntityFeaturesV2, useGetInputEntity } from "src/hooks/api";
import AIGuideInputDataset from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIInputDatasets/AIGuideInputDataset";

type IProps = {
  suggestions: string[];
  thread: ThreadResponseDto;
  messages: AIChatResponseDto[];
};

const AIGuide = ({ suggestions, messages, thread }: IProps) => {
  const {
    projectId,
    scenarioId,
    datasetId: datasetIdInContext,
    targetType: targetTypeInContext
  } = useAIGuideContext();

  const { threadId, targetType: targetTypeInThread, entityId } = thread;
  const targetId = entityId!;

  const datasetId = targetId || datasetIdInContext;
  const [query, setQuery] = useState("");

  const {
    data: entities,
    isFetching,
    isError,
    refetch
  } = useGetInputEntity({
    id: projectId!,
    scenarioId,
    datasetId
  });

  const targetType: any = targetTypeInThread || targetTypeInContext;
  const {
    isFetching: isEntitiesLoading,
    data: entityFeatures,
    isError: isEntityDataFetchError
  } = useGetEntityFeaturesV2({
    datasetsIds: datasetId ? [datasetId] : [],
    scenarioId,
    projectId
  });

  const [generatingState] = useAIGuideStore((state) => [state.generatingState]);
  const generateState = _.get(generatingState, threadId!);
  const dataset = entities?.[0];
  const entityData = useMemo(
    () => entityFeatures?.find((feature) => feature.id === datasetId),
    [entityFeatures, datasetId]
  );

  const columns = useMemo(
    () => entityData?.schema?.map((entity: any) => entity.name) || [],
    [entityData?.schema]
  );

  if (isError || entities?.[0]?.status === "UNBUILT" || isEntityDataFetchError) {
    return <ReactException onClick={refetch} subtitle1="Error in fetching dataset" />;
  }

  if (!scenarioId || !projectId) {
    return <ComponentNotFound />;
  }

  return (
    <AIGuideContainer
      query={query}
      projectId={projectId!}
      thread={thread}
      isLoading={isFetching || isEntitiesLoading || !dataset || !entityData}
      setQuery={setQuery}
      generateState={generateState}
      suggestions={suggestions}
      threadId={threadId!}
      targetType={targetType}
      messages={messages}
      columns={columns}>
      <AIGuideInputDataset
        name={dataset?.displayName || dataset?.name || ""}
        datasetId={dataset?.id!}
        entityData={entityData}
      />
    </AIGuideContainer>
  );
};

export default React.memo(AIGuide);
