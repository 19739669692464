/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FeatureMeta
 */
export interface FeatureMeta {
    /**
     * 
     * @type {boolean}
     * @memberof FeatureMeta
     */
    'secureFlag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureMeta
     */
    'idFlag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureMeta
     */
    'tsFlag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureMeta
     */
    'targetFlag'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'tsFormat'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'dataType'?: FeatureMetaDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'ontology'?: FeatureMetaOntologyEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'concept'?: FeatureMetaConceptEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'cardinalityType'?: FeatureMetaCardinalityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'collectionType'?: FeatureMetaCollectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'eventTimeTickGranularity'?: FeatureMetaEventTimeTickGranularityEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'sparseType'?: FeatureMetaSparseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'lineageType'?: FeatureMetaLineageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'timeWindow'?: FeatureMetaTimeWindowEnum;
    /**
     * 
     * @type {string}
     * @memberof FeatureMeta
     */
    'sourceLineage'?: FeatureMetaSourceLineageEnum;
}

export const FeatureMetaDataTypeEnum = {
    Long: 'LONG',
    Float: 'FLOAT',
    String: 'STRING',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Any: 'ANY',
    Double: 'DOUBLE',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type FeatureMetaDataTypeEnum = typeof FeatureMetaDataTypeEnum[keyof typeof FeatureMetaDataTypeEnum];
export const FeatureMetaOntologyEnum = {
    None: 'none',
    Ip: 'ip',
    Id: 'id',
    UserId: 'user_id',
    DeviceId: 'device_id',
    OrderId: 'order_id',
    ProductId: 'product_id',
    Label: 'label',
    LabelBinary: 'label_binary',
    Name: 'name',
    FirstName: 'first_name',
    LastName: 'last_name',
    FullName: 'full_name',
    Email: 'email',
    Phone: 'phone',
    DateOfBirth: 'dateOfBirth',
    Age: 'age',
    Ssn: 'ssn',
    TimeFirstSeen: 'time_first_seen',
    TimeLastSeen: 'time_last_seen',
    TimeSince: 'time_since',
    Year: 'year',
    Quarter: 'quarter',
    Month: 'month',
    Address: 'address',
    AddressLine1: 'address_line1',
    AddressLine2: 'address_line2',
    City: 'city',
    Zipcode: 'zipcode',
    Country: 'country',
    State: 'state',
    Latitude: 'latitude',
    Longitude: 'longitude',
    Latlong: 'latlong',
    CurrencyCode: 'currency_code',
    AmountUsd: 'amount_usd',
    TickerSymbol: 'ticker_symbol',
    CompanyName: 'company_name',
    IspProvider: 'isp_provider',
    Filename: 'filename',
    Url: 'url',
    BlsNaicsCode: 'bls_naics_code',
    BlsNaicsTitle: 'bls_naics_title',
    BlsOccCode: 'bls_occ_code',
    BlsOccTitle: 'bls_occ_title',
    BlsCbsaCode: 'bls_cbsa_code',
    BlsCbsaTitle: 'bls_cbsa_title',
    BlsOccGroup: 'bls_occ_group',
    BlsIndustryGroup: 'bls_industry_group',
    BlsOwnCode: 'bls_own_code',
    Comments: 'comments',
    ProductReview: 'product_review',
    TwitterPost: 'twitter_post'
} as const;

export type FeatureMetaOntologyEnum = typeof FeatureMetaOntologyEnum[keyof typeof FeatureMetaOntologyEnum];
export const FeatureMetaConceptEnum = {
    None: 'NONE',
    Empty: 'EMPTY',
    Constant: 'CONSTANT',
    Key: 'KEY',
    Id: 'ID',
    Binary: 'BINARY',
    CategoricalSmall: 'CATEGORICAL_SMALL',
    CategoricalLarge: 'CATEGORICAL_LARGE',
    OrdinalSmall: 'ORDINAL_SMALL',
    OrdinalLarge: 'ORDINAL_LARGE',
    Time: 'TIME',
    TimeWindow: 'TIME_WINDOW',
    TextSmall: 'TEXT_SMALL',
    TextLarge: 'TEXT_LARGE',
    NumericLong: 'NUMERIC_LONG',
    NumericDouble: 'NUMERIC_DOUBLE',
    NonNumericLong: 'NON_NUMERIC_LONG',
    NonNumericDouble: 'NON_NUMERIC_DOUBLE',
    Other: 'OTHER'
} as const;

export type FeatureMetaConceptEnum = typeof FeatureMetaConceptEnum[keyof typeof FeatureMetaConceptEnum];
export const FeatureMetaCardinalityTypeEnum = {
    None: 'NONE',
    Single: 'SINGLE',
    Binary: 'BINARY',
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE'
} as const;

export type FeatureMetaCardinalityTypeEnum = typeof FeatureMetaCardinalityTypeEnum[keyof typeof FeatureMetaCardinalityTypeEnum];
export const FeatureMetaCollectionTypeEnum = {
    None: 'NONE',
    Primitive: 'PRIMITIVE',
    Set: 'SET',
    List: 'LIST',
    Map: 'MAP'
} as const;

export type FeatureMetaCollectionTypeEnum = typeof FeatureMetaCollectionTypeEnum[keyof typeof FeatureMetaCollectionTypeEnum];
export const FeatureMetaEventTimeTickGranularityEnum = {
    None: 'NONE',
    Adhoc: 'ADHOC',
    Ms1: 'MS_1',
    Second1: 'SECOND_1',
    Seconds30: 'SECONDS_30',
    Minute1: 'MINUTE_1',
    Minute5: 'MINUTE_5',
    Hour1: 'HOUR_1',
    Hour2: 'HOUR_2',
    Hour4: 'HOUR_4',
    Hour12: 'HOUR_12',
    Day1: 'DAY_1',
    Day15: 'DAY_15',
    Week1: 'WEEK_1',
    Month1: 'MONTH_1',
    Quarter1: 'QUARTER_1',
    Year1: 'YEAR_1'
} as const;

export type FeatureMetaEventTimeTickGranularityEnum = typeof FeatureMetaEventTimeTickGranularityEnum[keyof typeof FeatureMetaEventTimeTickGranularityEnum];
export const FeatureMetaSparseTypeEnum = {
    None: 'NONE',
    Empty: 'EMPTY',
    Sparse01: 'SPARSE_01',
    Sparse05: 'SPARSE_05',
    Sparse0590: 'SPARSE_05_90',
    Sparse90: 'SPARSE_90',
    Sparse95: 'SPARSE_95',
    Sparse99: 'SPARSE_99',
    Sparse100: 'SPARSE_100'
} as const;

export type FeatureMetaSparseTypeEnum = typeof FeatureMetaSparseTypeEnum[keyof typeof FeatureMetaSparseTypeEnum];
export const FeatureMetaLineageTypeEnum = {
    None: 'NONE',
    Raw: 'RAW',
    ScalarDereived: 'SCALAR_DEREIVED',
    AggDerived: 'AGG_DERIVED',
    Deprecated: 'DEPRECATED'
} as const;

export type FeatureMetaLineageTypeEnum = typeof FeatureMetaLineageTypeEnum[keyof typeof FeatureMetaLineageTypeEnum];
export const FeatureMetaTimeWindowEnum = {
    None: 'NONE',
    Ms1: 'MS_1',
    Second1: 'SECOND_1',
    Seconds30: 'SECONDS_30',
    Minute1: 'MINUTE_1',
    Minute5: 'MINUTE_5',
    Hour1: 'HOUR_1',
    Hour2: 'HOUR_2',
    Hour4: 'HOUR_4',
    Hour12: 'HOUR_12',
    Day1: 'DAY_1',
    Day15: 'DAY_15',
    Week1: 'WEEK_1',
    Month1: 'MONTH_1',
    Quarter1: 'QUARTER_1',
    Year1: 'YEAR_1'
} as const;

export type FeatureMetaTimeWindowEnum = typeof FeatureMetaTimeWindowEnum[keyof typeof FeatureMetaTimeWindowEnum];
export const FeatureMetaSourceLineageEnum = {
    None: 'NONE',
    CsvFile: 'CSV_FILE',
    OfflineBatch: 'OFFLINE_BATCH',
    Ingress: 'INGRESS',
    Signal: 'SIGNAL',
    Agg: 'AGG'
} as const;

export type FeatureMetaSourceLineageEnum = typeof FeatureMetaSourceLineageEnum[keyof typeof FeatureMetaSourceLineageEnum];


