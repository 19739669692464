import React from "react";
import _ from "lodash";
import { Grid, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import { dateFormat } from "src/utils/dateFormat";
import { CardTitle } from "src/components";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { TileCard } from "src/components/Card/TileCard";
import {
  getJobRunStatusBadge,
  getJobStatusTemplate
} from "../private/ProjectsModule/pages/Jobs/utils/Jobs.helpers";
import { useJobsListHelper } from "./useJobsListHelper";
import {
  JobDeletePromptDetails,
  JobRunStatuses
} from "../private/ProjectsModule/pages/Jobs/utils/Jobs.constants";
import { JobIcon } from "src/icons/NewUX/JobIcon";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import {
  lastRunEntryId,
  openLogsModal
} from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { RecipeTypesPathMapping } from "../private/ProjectsModule/utils/Projects.constants";

const useStyles = makeStyles({
  gridItem: {
    width: "80%"
  },
  jobIcon: {
    marginRight: "12px"
  }
});

interface IFailedRecipeObj {
  failedRecipeId?: string;
  recipePath?: string;
}

export const JobsList = ({ data }: any) => {
  const classes = useStyles();

  const {
    dto: { id: jobId, projectId, name: jobName, status },
    lastRunEntry: { created, updated },
    lastRunEntries
  } = data;
  const {
    projectName,
    cardOptions,
    cancelDeleteJob,
    confirmDeleteJob,
    showConfirmScreen,
    isDeleting
  } = useJobsListHelper({ data });

  const navigateTo = (entry: any) => {
    switch (entry?.status) {
      case JobRunStatuses.Failure:
      case JobRunStatuses.RecipeTimedOut: {
        const failedRecipeObject: IFailedRecipeObj = {};

        _.forEach(entry?.recipeRunInfo, (val, key) => {
          if (val?.status === "FAILURE") {
            const type = _.get(entry, ["allRecipeDetails", key, "recipeType"]);
            const recipePath = type ? _.get(RecipeTypesPathMapping, type) : "";
            _.setWith(
              failedRecipeObject,
              "failedRecipeId",
              _.get(entry?.recipeNameToId, key),
              Object
            );
            _.setWith(failedRecipeObject, "recipePath", recipePath, Object);
          }
        });

        if (failedRecipeObject?.recipePath && failedRecipeObject?.failedRecipeId) {
          return `/projects/${projectId}/jobs/${jobId}/scenario/${entry?.scenarioId}/job-runs/${entry?.id}/recipes/${failedRecipeObject.recipePath}/${failedRecipeObject.failedRecipeId}?${openLogsModal}=true`;
        }
        return `/projects/${projectId}/jobs/${jobId}/job-runs/${entry?.id}/job-canvas`;
      }
      case JobRunStatuses.Running:
      case JobRunStatuses.TimedOut: {
        return `/projects/${projectId}/jobs/${jobId}/job-runs?${lastRunEntryId}=${entry?.id}&${openLogsModal}=true`;
      }
      default: {
        return `/projects/${projectId}/jobs/${jobId}/job-runs/${entry?.id}/job-canvas`;
      }
    }
  };

  return (
    <>
      {showConfirmScreen && (
        <NewThemeWrapper>
          <Modal
            open={true}
            variant={ModalVariants.Delete}
            title="Delete Job"
            content={[JobDeletePromptDetails.messageLine1, JobDeletePromptDetails.messageLine2]}
            onClose={cancelDeleteJob}
            onSubmit={confirmDeleteJob}
            isCancelDisabled={isDeleting}
            isSubmitDisabled={isDeleting}
            isSubmitting={isDeleting}
          />
        </NewThemeWrapper>
      )}
      <TileCard moreOptions={cardOptions}>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            container
            item
            direction="row"
            xs={4}
            wrap="nowrap"
            alignItems="center"
            style={{ gap: "12px" }}>
            <Grid data-testid="jobIcon" item style={{ flexShrink: 0 }}>
              <JobIcon />
            </Grid>
            <Grid item container direction="column">
              <Grid item className={classes.gridItem}>
                <CardTitle
                  variant="body1"
                  dataTestId="jobName"
                  title={jobName}
                  highlightOnHover
                  link={`projects/${projectId}/jobs/${jobId}`}
                />
                <Tooltip title={dateFormat(updated || created)}>
                  <Typography data-testid="lastRunOn" color="textSecondary" variant="body2">
                    Last Run On: {dateFormat(updated || created)}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={4}>
            <Grid item className={classes.gridItem}>
              <Typography
                data-testid="jobProjects"
                component="div"
                color="textSecondary"
                variant="body2">
                Project
              </Typography>
              <CardTitle
                dataTestId="jobProjectName"
                highlightOnHover
                variant="body1"
                title={projectName}
                link={`projects/${projectId}`}
              />
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2}>
            <Grid item className={classes.gridItem}>
              <Typography
                data-testid="jobStatus"
                component="div"
                color="textSecondary"
                variant="body2">
                Job Status
              </Typography>
              {getJobStatusTemplate(status, true)}
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={2}>
            <Typography
              data-testid="jobLastFiveRunStatus"
              component="span"
              color="textSecondary"
              variant="body2">
              Last 5 Run Status
            </Typography>
            {lastRunEntries?.length > 0 && (
              <Grid container>
                {lastRunEntries
                  ?.slice(0, 5)
                  ?.sort(
                    (a: $TSFixMe, b: $TSFixMe) =>
                      (a.updated || a.created) - (b.updated || b.created)
                  )
                  ?.map((entry: $TSFixMe, index: number) => (
                    <Link
                      key={`lastRunStatus_${jobName}_${index}`}
                      id={`lastRunStatus_${jobName}_${index}`}
                      to={navigateTo(entry)}>
                      {getJobRunStatusBadge(entry?.status)}
                    </Link>
                  ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </TileCard>
    </>
  );
};
