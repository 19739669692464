import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { PredictLogDto } from "openapi/Models";
import api from "src/utils/AxiosClient";

export const CACHE_QUERY_KEY_FETCH_PREDICTION_LOG_DETAILS = "fetch-prediction-log-details";

const useGetLogDetails = (
  id: string,
  runId: string,
  options?: UseQueryOptions<PredictLogDto, any>
): UseQueryResult<PredictLogDto, any> => {
  return useQuery<PredictLogDto, any>({
    queryKey: [CACHE_QUERY_KEY_FETCH_PREDICTION_LOG_DETAILS, id, runId],
    queryFn: async () => {
      const data = await api.fetchResponse(
        async () => await api.PredictionServiceControllerApi.expandLogs(id, runId)
      );

      return data;
    },
    ...options
  });
};

export default useGetLogDetails;
