import React, { useMemo } from "react";
import _ from "lodash";
import { TextField, TextFieldProps } from "@material-ui/core";

type IProps = TextFieldProps & {
  minChar?: number;
  maxChar?: number;
};
const TextFieldWithValidation: React.FC<IProps> = (props) => {
  const { value, minChar = 0, maxChar = 255, ...restProps } = props;

  const { error, helperText } = useMemo(() => {
    if (!value || typeof value !== "string") {
      return { error: false, helperText: "" };
    }

    const size = _.size(_.trim(value));
    const isError = size > maxChar || (size < minChar && size !== 0);

    return {
      error: isError,
      helperText: isError ? `Maximum ${maxChar} characters allowed` : ""
    };
  }, [value]);

  return <TextField value={value} error={error} helperText={helperText} {...restProps} />;
};

export default TextFieldWithValidation;
