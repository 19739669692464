import React from "react";

// Packages
import { get } from "lodash";
import clsx from "clsx";

// MUI
import Tooltip from "@material-ui/core/Tooltip";

// Hooks
import useAttributes from "./useAttributes";

// Constants
import { RecipeTypesNameMapping } from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";

// Styles
import useStyles from "./RecipeReadonly.styles";
import useNodeStyle from "../../../hooks/useNodeStyle";

type Props = {
  data: NodeData;
};

const RecipeReadonly = (props: Props) => {
  const { data } = props || {};

  const { classes, statusKey } = useNodeStyle({
    classes: useStyles({ status: data?.status }),
    status: data?.status
  });

  // Keeping hooks at the bottom so as to pass the above defined props to it.
  // Hooks - STARTS >>
  const { icon } = useAttributes({ data });
  // << ENDS - Hooks

  return (
    <Tooltip title={get(RecipeTypesNameMapping, data?.recipeType)}>
      <div className={clsx(classes.root, classes[statusKey])}>
        <div className={classes.container}>
          <div className={classes.icon}>{icon}</div>
        </div>
      </div>
    </Tooltip>
  );
};

export default RecipeReadonly;
