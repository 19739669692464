import { makeStyles, Theme } from "@material-ui/core/styles";

interface IProps {
  isDeleteModal: boolean;
  background?: string;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  title: {
    minHeight: theme.spacing(7),
    borderBottom: ({ isDeleteModal }) => (isDeleteModal ? "1px solid #e5e5e5" : undefined),
    padding: ({ isDeleteModal }) =>
      isDeleteModal ? `${theme.spacing(1.5)}px ${theme.spacing(3)}px` : undefined
  },
  titleText: {
    fontWeight: 400
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2)
  },
  dialogueContentBackground: {
    backgroundColor: ({ background }) => background || "#fff"
  },
  isDeleteModal: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5)
  },

  actions: {
    borderTop: ({ isDeleteModal }) => (isDeleteModal ? "1px solid #e5e5e5" : undefined),
    minHeight: theme.spacing(7),
    padding: ({ isDeleteModal }) =>
      isDeleteModal ? `${theme.spacing(1)}px ${theme.spacing(3)}px` : undefined
  }
}));

export default useStyles;
