/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";
import { getEntitiesByProjectIdWithRethrow } from "src/api";
import { Entities } from "src/types";

export const useGetInputEntity = ({
  id,
  scenarioId,
  datasetId
}: {
  id?: string;
  scenarioId?: string;
  datasetId?: string;
}) => {
  return useQuery<Entities>({
    queryKey: ["project", id, "inputEntity", datasetId],
    queryFn: () => {
      if (!datasetId) {
        throw "datasetId is absent";
      }

      return getEntitiesByProjectIdWithRethrow(id, scenarioId, undefined, datasetId);
    },
    cacheTime: 0,
    staleTime: 0
  });
};
