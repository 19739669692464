import React, { useCallback, useEffect, useState } from "react";

// Packages
import { Route, Routes, generatePath, useNavigate, useParams } from "react-router-dom";

// Utils
import { WebPaths } from "src/routing/routes";
import { getErrorMessage, handleErrorWithRedirectToLogs } from "src/utils/apiService";
import { toastWrapper } from "src/utils/toastWrapper";

// APIs
import { publishJobWithRethrow } from "src/api";

// Hooks
import useDagPolling from "./hooks/useDagPolling";

// Pages
import CanvasFlowHeader from "src/pages/Projects/CanvasFlow/CanvasFlowHeader";

// Components
import { Spinner } from "src/components";
import { DagFlow } from "./components";
import { useSetAIGuideMessagesCount } from "src/pages/Projects/common/hooks/useSetAIGuideMessagesCount";

// Contexts
import DagContextProvider from "./context/DagContextProvider";
import { useDagContext } from "./context/useDagContext";

const DagPage = () => {
  const navigate = useNavigate();

  const { projectId } = useParams() || {};

  const { scenarioId, project, isFromDatasetPage, jobsData, setIsFromArtifactsModelModal } =
    useDagContext() || {};

  useSetAIGuideMessagesCount({ projectId: projectId! });

  // To poll canvas data upon closing data-upload modal, if there are UnBuilt datasets in the canvas.
  const { onDatasetCreated } = useDagPolling({
    projectId,
    scenarioId
  });

  const [recipeRunsPopoverAnchorEl, setRecipeRunsPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const onAddDatasetAction = useCallback(() => {
    if (projectId) {
      navigate(generatePath(WebPaths.Dataset, { projectId, scenarioId }));
    }
  }, [scenarioId, projectId]);

  // $FixMe: Scope to be refactored.
  // The below publish-jobs flow can go into CanvasHeader.
  // Publish to job flow - STARTS >>
  const [showPublishJobsModal, setShowPublishJobsModal] = useState(false);
  const [publishingJobIds, setPublishingJobIds] = useState([]);
  const [isPublishingJobs, setIsPublishingJobs] = useState(false);

  const publishJobs = async () => {
    try {
      setIsPublishingJobs(() => true);

      await Promise.all(
        publishingJobIds?.map(
          async (eachJobId: string) => await publishJobWithRethrow(eachJobId, false)
        )
      );

      toastWrapper({
        type: "success",
        content: `Project Canvas is republished to selected ${publishingJobIds?.length} Job${
          publishingJobIds?.length > 1 ? `s` : ``
        }!`
      });

      setShowPublishJobsModal(() => false);
      setPublishingJobIds(() => []);
    } catch (error) {
      handleErrorWithRedirectToLogs(getErrorMessage(error));
    } finally {
      setIsPublishingJobs(() => false);
    }
  };
  // << ENDS - Publish to job flow

  const onRecipeRunsPopoverAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRecipeRunsPopoverAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    !!isFromDatasetPage && onDatasetCreated();
  }, [isFromDatasetPage]);

  return (
    <>
      <CanvasFlowHeader
        project={project}
        onAddDatasetAction={onAddDatasetAction}
        onRecipeRunsPopoverAction={onRecipeRunsPopoverAction}
        setIsFromArtifactsModelModal={setIsFromArtifactsModelModal}
        publishJobsProps={{
          jobsData,
          showPublishJobsModal,
          setShowPublishJobsModal,
          isPublishingJobs,
          scenarioId,
          publishingJobIds,
          setPublishingJobIds,
          publishJobs
        }}
      />

      <DagFlow
        onAddDatasetAction={onAddDatasetAction}
        recipeRunsPopoverAnchorEl={recipeRunsPopoverAnchorEl}
        setRecipeRunsPopoverAnchorEl={setRecipeRunsPopoverAnchorEl}
      />
    </>
  );
};

const DagRoutes = () => {
  const { isFetchingProjectScenarios, scenarioId } = useDagContext() || {};

  return !scenarioId || isFetchingProjectScenarios ? (
    <Spinner />
  ) : (
    <Routes>
      <Route path={WebPaths.Canvas} element={<DagPage />} />
    </Routes>
  );
};

const DagWrapper = () => {
  return (
    <DagContextProvider>
      <DagRoutes />
    </DagContextProvider>
  );
};

export default DagWrapper;
