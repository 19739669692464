import Card from "@material-ui/core/Card";
import React from "react";

import { Grid, Divider, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import OverflowTooltip from "components/OverflowTooltip";
// import Text from "components/Widget/Text";
import infoCardsStyles from "components/InfoCards/InfoCards.module.scss";
import styles from "./DataSourceTile.module.scss";
import { MoreOptions } from "src/components/MoreOptions/MoreOptions";
import { dataSourcesTypes, DataSourcesHelperText } from "./utils/DataSources.constants";
import { dateFormat } from "src/utils/dateFormat";

import DataConnectorStatus from "./DataSource/DataConnectorStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    padding: theme.spacing(2),
    "& .moreIcon": {
      display: "none"
    },
    "&:hover .moreIcon": {
      display: "block"
    }
  },
  titleText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "90%",
    display: "block",
    overflow: "hidden",
    color: "#003656"
  }
}));

const defaultOptions = [
  {
    label: "Create",
    click: () => {}
  },
  {
    label: "Edit",
    click: () => {}
  },
  {
    label: "Delete",
    click: () => {}
  }
];

export const DataSourceTile = ({ data, options = defaultOptions, onManualSync }: $TSFixMe) => {
  const { id, title, link, source, sourceLink, lastUpdate, updatedBy, metadata, status } = data;

  const classes = useStyles();

  const getDataSourceType = (): $TSFixMe => {
    return (
      dataSourcesTypes?.find(
        (eachDataSourceType: $TSFixMe) => eachDataSourceType?.name === source
      ) || {}
    );
  };

  const cardTitle = (
    <Typography component="span" variant="h6" className={classes.titleText}>
      <OverflowTooltip style={{ maxWidth: 300, whiteSpace: "nowrap" }} value={title} />
    </Typography>
  );

  return (
    <Card className={classes.root}>
      <Grid container alignItems="center" justifyContent="space-around">
        <Grid item className={styles.cardImg} data-testid="connectorImg">
          {sourceLink ? (
            <img src={sourceLink} alt={title} width={90} height={90} />
          ) : (
            getDataSourceType()?.image
          )}
        </Grid>
        <Grid container justifyContent="space-between" className={styles.infoCardTitle}>
          <Grid container>
            <Grid item md={11} xs={10}>
              <Link data-testid="connectorTitle" to={`${link}/${id}`} className={styles.title}>
                {cardTitle}
              </Link>
            </Grid>
            <Grid item md={1} xs={2} className="moreIcon">
              <MoreOptions options={options} />
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" className={styles.dataContainer}>
            <Grid item xs={9} className={styles.details}>
              <DataConnectorStatus id={id} status={status} onManualSync={onManualSync} />
              {source && (
                <Typography
                  data-testid="connectorSource"
                  component="span"
                  color="textSecondary"
                  variant="body2">
                  <div>
                    {DataSourcesHelperText.Source}: {source}
                  </div>
                </Typography>
              )}
              <Tooltip title={dateFormat(lastUpdate)}>
                <Typography
                  data-testid="connectorUpdatedOn"
                  component="span"
                  color="textSecondary"
                  variant="body2">
                  <div>
                    {DataSourcesHelperText.UpdatedOn}: {dateFormat(lastUpdate)}
                  </div>
                </Typography>
              </Tooltip>
              {updatedBy && (
                <div className={infoCardsStyles.text}>
                  <div className={infoCardsStyles.userMenuItemIndicator}>
                    {updatedBy?.charAt(0)?.toUpperCase()}
                  </div>
                  <OverflowTooltip
                    style={{ whiteSpace: "nowrap" }}
                    value={
                      <Typography
                        data-testid="connectorUpdatedBy"
                        color="textSecondary"
                        variant="caption">
                        {updatedBy}
                      </Typography>
                    }
                  />
                </div>
              )}
            </Grid>
            <div className={styles.metadataContainer}>
              <Divider orientation="vertical" />
              <div className={styles.metadata}>
                {Object.keys(metadata)?.map((extraData, i) => {
                  const isOdd = (i + 1) % 2 !== 0;
                  const typographyStyle = isOdd ? "primary" : "secondary";
                  const numberStyle = isOdd ? styles.extraDataOdd : styles.extraDataEven;
                  return (
                    <div key={extraData} className={styles.metaDataContainer}>
                      <Typography
                        component={"span"}
                        className={numberStyle}
                        color={typographyStyle}
                        variant="h5">
                        {metadata[extraData]}
                      </Typography>{" "}
                      <Typography component={"span"} color="textPrimary" variant="caption">
                        {extraData}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

DataSourceTile.defaultProps = {
  buttonProps: null
};
