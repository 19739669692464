import Alert, { Color } from "@material-ui/lab/Alert";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  Grid,
  Theme,
  Typography,
  makeStyles,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Paper,
  Button,
  Tooltip,
  IconButton,
  Checkbox,
  TextField
} from "@material-ui/core";
import { Close, InfoOutlined } from "@material-ui/icons";
import { includes } from "lodash";

import Drawer from "components/Drawer/CustomDrawer";
import useDataAppStatus from "hooks/api/dataapps/useDataAppStatus";
import { CpuIcon } from "src/icons/CpuIcon";
import { CustomLinearProgressBar, Spinner, HorseShoeProgressBar } from "src/components";
import { DataAppAppTypeEnum, DataAppType } from "../DataApps.type";
import { EnvironmentsTypes } from "src/constants/environments.constants";
import { MemoryIcon } from "src/icons/MemoryIcon";
import { UpdateDataAppEnvironmentWarningDialog } from "../DataAppDashboard/UpdateDataAppEnvironmentWarningDialog";
import { capitalize } from "src/utils/capitalize";
import {
  useGetDataAppResourceUsage,
  useGetEnvironmentTypes,
  useSaveDataAppEnvMutation
} from "src/hooks/api";
import { DataApp, useDataAppStore } from "src/store/store";
import { DataAppDtoLaunchStatusEnum } from "openapi/Models/data-app-dto";
const drawerWidth = "40%";

interface StyleProps {
  disabled?: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  drawerPaper: {
    width: drawerWidth,
    overflowX: "hidden"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: "14px"
  },
  titleString: {
    fontSize: "18px"
  },
  dataAppConfigContainer: {
    padding: "20px"
  },
  memoryUsageWrap: {
    height: "95%",
    "& .title": {
      marginTop: "15%",
      color: "#4646b5",
      columnGap: 5
    },
    "& .percentage": { marginTop: "auto" }
  },
  linearProgressContainer: {
    border: "none",
    backgroundColor: "#f7f7f7",
    marginBottom: 20,
    padding: "20px 20%"
  },
  memUsageContainer: {
    border: "none",
    backgroundColor: "#f7f7f7",
    marginBottom: 20,
    padding: 32,
    cursor: ({ disabled }) => (disabled ? "not-allowed" : "default")
  },
  btnList: {
    height: "70px",
    backgroundColor: "#F5F5F5",
    padding: "20px",
    alignItems: "center",
    gap: "20px"
  },
  container: {
    maxHeight: "calc(100vh - 135px)",
    overflowX: "hidden",
    overflowY: "auto"
  },
  envTypeSpan: {
    color: "rgba(0, 0, 0, 0.6)",
    display: "inline-block",
    fontSize: "12px",
    lineHeight: "24px"
  },
  envSelectItem: {
    flexWrap: "nowrap",
    gap: "8px"
  },
  flex: { display: "flex", alignItems: "center", gap: "6px" },
  selectRoot: {
    padding: "14px"
  },
  envConfig: {
    flexWrap: "nowrap",
    gap: "16px",
    marginBottom: "12px"
  },
  textField: {
    width: "100%"
  },
  timeoutTextField: {
    padding: "0px 0px 20px 0px",
    flexDirection: "column",
    flexWrap: "nowrap",
    gap: "10px"
  },
  warning: {
    fontSize: "12px"
  },
  evergreen: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    margin: "6px 0px 12px 0px"
  },
  alert: {
    marginBottom: 10
  }
}));

const MIN_INACTIVITY = 1;
const MAX_INACTIVITY = 262800;

export interface IStatusInfo {
  status: keyof typeof DataAppDtoLaunchStatusEnum;
  text: string;
  alertType: Color;
}
interface IProps {
  open: boolean;
  dataApp: DataAppType;
  onClose: () => void;
}

const DataAppConfigDrawer: React.FC<IProps> = (props) => {
  const { open, dataApp, onClose } = props;
  const { envType, ttlInHrs, memInMbs, id } = dataApp;
  const [initialRecord, setInitialRecord] = useState<DataApp>({
    id,
    checked: ttlInHrs === 0,
    loading: false,
    ttlInHrs,
    envType
  });

  const [updatingDataApps, setUpdatingDataApps] = useDataAppStore((state) => [
    state.updatingDataApps,
    state.setUpdatingDataApps
  ]);

  useEffect(() => {
    const alreadyExist = _.find(updatingDataApps, { id });
    if (!alreadyExist) {
      setUpdatingDataApps([...updatingDataApps, initialRecord]);
    } else if (!alreadyExist.loading) {
      setUpdatingDataApps(
        _.map(updatingDataApps, (dataApp) => (dataApp.id === id ? initialRecord : dataApp))
      );
    } else {
      setInitialRecord(alreadyExist);
    }
  }, []);

  const dataAppConfig = useMemo(() => {
    return _.find(updatingDataApps, { id }) ?? initialRecord;
  }, [updatingDataApps, dataApp]);

  const { currentStatusInfo, status, setStatus, refetch } = useDataAppStatus(id);

  const { data: resourceUsage } = useGetDataAppResourceUsage({
    dataAppId: dataApp?.id,
    enabled:
      open &&
      includes([DataAppDtoLaunchStatusEnum.Running, DataAppDtoLaunchStatusEnum.Launching], status)
  });
  const { isLoading, data } = useGetEnvironmentTypes();

  const saveDataAppEnvMutation = useSaveDataAppEnvMutation();
  const classes = useStyles({ disabled: !resourceUsage });

  const environmentsTypes = useMemo(() => {
    return Array.isArray(data)
      ? data.filter((currEnvType) => currEnvType.name !== EnvironmentsTypes.Custom)
      : [];
  }, [data]);

  const disabled = useMemo(() => {
    return (
      saveDataAppEnvMutation.isLoading ||
      dataAppConfig.loading ||
      (dataAppConfig.envType === initialRecord.envType &&
        dataAppConfig.ttlInHrs === initialRecord.ttlInHrs)
    );
  }, [dataAppConfig, initialRecord, saveDataAppEnvMutation.isLoading]);

  const isError = useMemo(() => {
    if (dataAppConfig.checked || dataAppConfig.ttlInHrs === null) {
      return false;
    }

    return dataAppConfig.ttlInHrs < MIN_INACTIVITY || dataAppConfig.ttlInHrs > MAX_INACTIVITY;
  }, [dataAppConfig]);

  const [showEnvUpdateDialog, setShowEnvUpdateDialog] = React.useState<boolean>(false);

  const callApis = (envChanged: boolean) => {
    saveDataAppEnvMutation.mutate(
      {
        dataAppId: id,
        dataApp: { ...dataApp, ttlInHrs: dataAppConfig.checked ? 0 : dataAppConfig.ttlInHrs },
        TTLChanged: initialRecord.ttlInHrs !== dataAppConfig.ttlInHrs,
        envType: dataAppConfig.envType,
        envChanged,
        setStatus,
        refetch
      },
      {
        onSuccess: () => {
          setInitialRecord(dataAppConfig);
        }
      }
    );
  };
  const handleSaveDataAppConfig = () => {
    callApis(true);
  };

  const handleSaveBtnClick = () => {
    if (initialRecord.envType === dataAppConfig.envType) {
      callApis(false);
    } else {
      setShowEnvUpdateDialog(true);
    }
  };

  const memPercentage = resourceUsage
    ? parseInt(resourceUsage.memoryPercentage?.split("%")?.[0] || "0")
    : 0;
  const memInGbs = memInMbs / 1024;

  const backgroundColor = useMemo(() => {
    switch (currentStatusInfo?.alertType) {
      case "info":
        return "rgb(229, 246, 253)";
      case "success":
        return "rgb(237, 247, 237)";
      case "error":
        return "rgb(253, 237, 237)";
      default:
        return "";
    }
  }, [currentStatusInfo]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const newDataApps = _.map(updatingDataApps, (dataApp) => {
      return dataApp.id === id
        ? { ...dataApp, checked: isChecked, ttlInHrs: isChecked ? 0 : null }
        : dataApp;
    });
    setUpdatingDataApps(newDataApps);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newDataApps = _.map(updatingDataApps, (dataApp) => {
      return dataApp.id === id
        ? {
            ...dataApp,
            ttlInHrs:
              e.target.value === "" || e.target.value === null ? null : _.toNumber(e.target.value)
          }
        : dataApp;
    });
    setUpdatingDataApps(newDataApps);
  };

  const handleEnvChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const newDataApps = _.map(updatingDataApps, (dataApp) => {
      return dataApp.id === id
        ? { ...dataApp, envType: event.target.value as keyof typeof EnvironmentsTypes }
        : dataApp;
    });
    setUpdatingDataApps(newDataApps);
  };

  const title = (
    <div className={classes.title}>
      <div className={classes.titleString}>Configuration & Consumption</div>
      <div className={classes.flex}>
        <div style={{ padding: "8px", backgroundColor, borderRadius: "6px", fontWeight: 400 }}>
          {currentStatusInfo?.text}
        </div>
        <IconButton onClick={onClose} color="primary">
          <Close />
        </IconButton>
      </div>
    </div>
  );

  const footer = (
    <Grid container direction="row-reverse" className={classes.btnList}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveBtnClick}
        disabled={disabled || isError}
        test-id="dataapp-config-save-btn">
        {dataAppConfig.loading || saveDataAppEnvMutation.isLoading ? (
          <Spinner size={24} noPadding />
        ) : (
          "Save"
        )}
      </Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={onClose}
        test-id="dataapp-config-cancel-btn">
        Cancel
      </Button>
    </Grid>
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      test-id="dataapp-config-drawer"
      hideCloseButton
      open={open}
      width="40%"
      title={title}
      classes={{
        paper: classes.drawerPaper
      }}
      footer={footer}
      onClose={onClose}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Grid
          container
          className={classes.container}
          direction="column"
          justifyContent="space-between">
          <Grid container direction="column" className={classes.dataAppConfigContainer}>
            {dataApp.appTemplateType !== DataAppAppTypeEnum.REACTJS && (
              <Grid container direction="column" className={classes.envConfig}>
                <FormControl variant="outlined">
                  <InputLabel shrink htmlFor="environmentId">
                    Change Environment
                  </InputLabel>
                  <Select
                    label="Change Environment"
                    required
                    classes={{
                      select: classes.selectRoot
                    }}
                    name="environmentId"
                    value={dataAppConfig.envType}
                    onChange={handleEnvChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}>
                    {environmentsTypes?.map((env: $TSFixMe) => {
                      const displayName = capitalize(env.name.toLowerCase().split("_").join(" "));
                      return (
                        <MenuItem key={env.name} value={env.name}>
                          <Grid
                            container
                            direction="row"
                            className={classes.envSelectItem}
                            alignItems="center">
                            <Grid component="span" color="primary">
                              {displayName}
                            </Grid>
                            <Grid component="span" className={classes.envTypeSpan}>
                              {`Core: ${env.cores} | Memory: ${(
                                env.memInMbs / 1024
                              ).toFixed()} GB | Disk Space: ${env.diskInGbs} GB`}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid container direction="column" className={classes.timeoutTextField}>
              <div className={classes.flex}>
                <TextField
                  inputProps={{ type: "number", step: 1 }}
                  value={
                    dataAppConfig.ttlInHrs === null || dataAppConfig.checked
                      ? ""
                      : dataAppConfig.ttlInHrs
                  }
                  disabled={dataAppConfig.checked}
                  label="Shutdown DataApp if Inactive for"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={handleChange}
                />
                <Typography className={classes.subHeading}>hr</Typography>
              </div>
              {isError && (
                <Alert className={classes.alert} variant="outlined" severity="error">
                  {`The inactivity shutdown period should be min ${MIN_INACTIVITY} and at most ${MAX_INACTIVITY}`}
                </Alert>
              )}
              <div className={classes.evergreen}>
                <Checkbox
                  checked={dataAppConfig.checked}
                  style={{ padding: 0 }}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span>Evergreen</span>
                <Tooltip
                  placement="right-start"
                  title="Enabling this option will always keep this DataApp up and running">
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </div>
            </Grid>
            <Paper variant="outlined" square className={classes.memUsageContainer}>
              <HorseShoeProgressBar percentage={memPercentage} disabled={!resourceUsage} isNewTheme>
                <Grid
                  container
                  direction="row"
                  alignContent="space-around"
                  className={classes.memoryUsageWrap}>
                  <Grid container justifyContent="center" className="title">
                    <MemoryIcon color="#4646b5" />
                    <Typography variant="body2">Memory</Typography>
                  </Grid>
                  <Grid container justifyContent="center" direction="column">
                    <Typography variant="h6" color="primary" align="center">
                      {(memInGbs * memPercentage * 0.01).toFixed(2)} GB
                    </Typography>
                    <Typography variant="caption" color="primary" align="center">
                      of {memInGbs} GB used
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="center" className="percentage">
                    <Typography variant="body2" color="primary">
                      {memPercentage?.toFixed(2)}% used
                    </Typography>
                  </Grid>
                </Grid>
              </HorseShoeProgressBar>
            </Paper>
            <Paper variant="outlined" square className={classes.linearProgressContainer}>
              <CustomLinearProgressBar
                isNewTheme
                disabled={!resourceUsage}
                percentage={resourceUsage?.cpuPercentage || "0"}
                title="CPU Usage"
                icon={<CpuIcon color="#4646b5" />}
              />
            </Paper>
            {!resourceUsage && (
              <div>
                <InfoOutlined fontSize="small" />{" "}
                <span className={classes.warning}>
                  Consumption is shown for launching and running DataApps
                </span>
              </div>
            )}
          </Grid>
          {showEnvUpdateDialog && (
            <UpdateDataAppEnvironmentWarningDialog
              isDeleting={saveDataAppEnvMutation?.isLoading}
              handleClose={() => setShowEnvUpdateDialog(false)}
              handleSubmit={() => {
                handleSaveDataAppConfig();
                setShowEnvUpdateDialog(false);
              }}
            />
          )}
        </Grid>
      )}
    </Drawer>
  );
};

export default DataAppConfigDrawer;
