import React from "react";

export const Clear = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1031 11.8375L11.275 7.0625H11.5547C11.7797 7.0625 11.9609 6.88125 11.9609 6.65625V3.65625C11.9609 3.43125 11.7797 3.25 11.5547 3.25H7.71094V0.40625C7.71094 0.18125 7.52969 0 7.30469 0H4.80469C4.57969 0 4.39844 0.18125 4.39844 0.40625V3.25H0.55469C0.32969 3.25 0.14844 3.43125 0.14844 3.65625V6.65625C0.14844 6.88125 0.32969 7.0625 0.55469 7.0625H0.83438L0.00625002 11.8375C0.00157002 11.8609 0 11.8844 0 11.9062C0 12.1312 0.18125 12.3125 0.40625 12.3125H11.7031C11.7266 12.3125 11.75 12.3109 11.7719 12.3062C11.9938 12.2687 12.1422 12.0578 12.1031 11.8375ZM1.24219 4.34375H5.49219V1.09375H6.61719V4.34375H10.8672V5.96875H1.24219V4.34375ZM8.55469 11.2188V8.7812C8.55469 8.7125 8.49849 8.6562 8.42969 8.6562H7.67969C7.61094 8.6562 7.55469 8.7125 7.55469 8.7812V11.2188H4.55469V8.7812C4.55469 8.7125 4.49844 8.6562 4.42969 8.6562H3.67969C3.61094 8.6562 3.55469 8.7125 3.55469 8.7812V11.2188H1.22344L1.92813 7.15625H10.1797L10.8844 11.2188H8.55469Z"
      fill="currentColor"
    />
  </svg>
);
