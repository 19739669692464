/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import {
  Grid,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  ListItemIcon,
  Box,
  Chip,
  Divider,
  makeStyles,
  Badge
} from "@material-ui/core";
import { FilterIcon } from "src/icons/FilterIcon";
import { UnCheckedIcon } from "src/icons/UnCheckedIcon";
import { CheckedIcon } from "src/icons/CheckedIcon";
import EventBus from "src/utils/EventBus";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";

type Value = {
  label: string | number;
  value: string;
  selected: boolean;
  tag?: string;
  disabled?: boolean;
};

const useStyles = makeStyles({
  icon: {
    minWidth: "32px"
  },
  chip: {
    padding: "0px 4px"
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    //minWidth: "294px",
    gap: "84px"
  },
  menuFooter: {
    paddingTop: "8px",
    display: "flex",
    justifyContent: "flex-end"
  }
});

type Props = {
  icon?: React.ReactNode;
  values: Value[];
  allLabel?: string;
  onFilter: (values: Array<string>) => void;
  testId?: string;
  isFiltered: boolean;
};

export const FilterMenu = ({
  icon = <FilterIcon />,
  values,
  allLabel = "ALL",
  onFilter,
  testId,
  isFiltered,
  ...restProps
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedValues, setSelectedValues] = React.useState<Array<string>>([]);
  const isAllDisabled = !values.find((value) => !value.disabled);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const allSelected = React.useMemo(
    () => selectedValues.length === values.length && !isAllDisabled,
    [isAllDisabled, selectedValues.length, values.length]
  );

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.OpenFilterDropdownDataApp, () => {
      setAnchorEl(buttonRef.current);
    });

    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.OpenFilterDropdownDataApp);
    };
  }, []);

  useEffect(() => {
    setSelectedValues(values.filter((value) => value.selected).map((value) => value.value));
  }, [values]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSelectItem = (value: string, selected: boolean) => {
    if (selected) {
      setSelectedValues((selectedValues) =>
        selectedValues.filter((selectedValue) => selectedValue !== value)
      );
    } else {
      setSelectedValues((selectedValues) => [...selectedValues, value]);
    }
  };

  const toggleSelectAll = () => {
    setSelectedValues(allSelected ? [] : values.map((value) => value.value));
  };

  const onApply = () => {
    onFilter(selectedValues);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} ref={buttonRef} test-id={testId}>
        <Badge color="error" variant="dot" overlap="rectangular" invisible={!isFiltered}>
          {icon}
        </Badge>
      </IconButton>
      <Menu
        {...restProps}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        TransitionProps={{
          onExited: () => {
            setSelectedValues(values.filter((value) => value.selected).map((value) => value.value));
          }
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          value="ALL"
          onClick={toggleSelectAll}
          className={classes.menuItem}
          disabled={isAllDisabled}>
          <Box display="flex" flexDirection="row" flex={1} alignItems="center">
            <ListItemIcon className={classes.icon} test-id="filterMenuAllIcon">
              {allSelected ? <CheckedIcon /> : <UnCheckedIcon />}
            </ListItemIcon>
            <Typography component="span" variant="body2" test-id="filterMenuAllLabel">
              {allLabel}
            </Typography>
          </Box>
        </MenuItem>
        {values.map(({ label, value, tag, disabled }) => {
          const selected = selectedValues.includes(value) && !disabled;
          return (
            <MenuItem
              key={value}
              id={value}
              value={value}
              disabled={disabled}
              onClick={() => toggleSelectItem(value, selected)}
              className={classes.menuItem}>
              <Box display="flex" flexDirection="row" flex={1} alignItems="center">
                <ListItemIcon className={classes.icon} test-id="filterMenuIcon">
                  {selected ? <CheckedIcon /> : <UnCheckedIcon />}
                </ListItemIcon>
                <Typography component="span" variant="body2" test-id="filterMenuLabel">
                  {label}
                </Typography>
              </Box>
              {tag && (
                <Grid item>
                  <Chip className={classes.chip} label={tag} size="small" test-id="filterMenuTag" />
                </Grid>
              )}
            </MenuItem>
          );
        })}
        <Box px="16px" pt="8px">
          <Divider light />
          <Box className={classes.menuFooter}>
            <Button
              variant="outlined"
              onClick={onApply}
              disabled={isAllDisabled}
              test-id="filterMenuApplyBtn">
              APPLY
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
