import React, { useMemo, useCallback, useState } from "react";

// // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useTable, usePagination, useSortBy } from "react-table";

import { deleteAPIWithRethrow } from "../../../utils/apiService";

import styles from "../VariablesList.module.scss";

import { Menu, MenuItem, Grid, IconButton } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { useDrawerStore, useVariablesStore } from "../../../store/store";
import { sideComponentSetter } from "../../../store/store.selectors";
import Pagination from "../../../components/Table/Pagination";
import VariablesForm from "./VariablesForm";
import { toastWrapper } from "src/utils/toastWrapper";

const PAGE_SIZE = 10;
const PADDING_SIDE_SIZE = 20;

const ShimmerTdCell = ({ style }: $TSFixMe) => {
  return <td style={style} className={styles.shimmerTdCell}></td>;
};

const HeaderCell = ({ column }: $TSFixMe) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((e: $TSFixMe) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleAZSort = useCallback(() => {
    column.toggleSortBy(false, false);
    setAnchorEl(null);
  }, [column]);

  const handleZASort = useCallback(() => {
    column.toggleSortBy(true, false);
    setAnchorEl(null);
  }, [column]);

  const handleClose = useCallback(() => setAnchorEl(null), []);

  return (
    <span className={styles.headerCellContainer}>
      <span>{column.Header}</span>
      <button onClick={handleClick} className={styles.tableHeaderOptionsButton}>
        <MoreVertIcon />
      </button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleAZSort}>Sort A -{">"} Z</MenuItem>
        <MenuItem onClick={handleZASort}>Sort Z -{">"} A</MenuItem>
      </Menu>
    </span>
  );
};

const ActionsCell = ({ cell }: $TSFixMe) => {
  const triggerVariablesReload = useVariablesStore((state) => state.triggerVariablesReload);
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const originalRow = useMemo(() => {
    return cell.row.original;
  }, [cell]);

  const onDeleteVariable = useCallback(async () => {
    try {
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      await deleteAPIWithRethrow(`/v2/variables/${originalRow.id}`);
      triggerVariablesReload();
    } catch (e) {
      toastWrapper({
        type: "error",
        content: "Variable removal failed"
      });
    }
  }, [originalRow, triggerVariablesReload]);

  const onEditVariable = useCallback(() => {
    setSideComponent({
      sideComponent: () => (
        <VariablesForm projectId={originalRow.projectId} selectedVariable={originalRow} />
      ),
      sideComponentProps: {
        title: "Edit Variable",
        anchor: "left",
        drawerPanelClassName: styles.drawerPanel
      }
    });
  }, [originalRow, setSideComponent]);

  return (
    <Grid container className={styles.actionButtonsContainer}>
      <IconButton
        disabled={originalRow.default}
        onClick={onEditVariable}
        style={{ padding: 0 }}
        aria-label="Edit scenario">
        <EditIcon />
      </IconButton>
      <IconButton
        disabled={originalRow.default}
        onClick={onDeleteVariable}
        style={{ padding: 0 }}
        aria-label="Delete scenario">
        <DeleteOutline />
      </IconButton>
    </Grid>
  );
};

export const VariablesTable = ({ data, isLoading, projectId }: $TSFixMe) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Variable Name",
        id: "name",
        accessor: "name"
      },
      {
        Header: "Data Type",
        id: "type",
        accessor: "type"
      },
      {
        Header: "Value",
        id: "value",
        accessor: "value"
      },
      {
        Header: "Used In",
        id: "usedIn",
        accessor: "asignees",
        Cell: ({ cell }: $TSFixMe) => cell.value?.join(", ") || []
      },
      {
        Header: "Actions",
        id: "actions",
        accessor: "actions",
        Cell: ({ cell }: $TSFixMe) => <ActionsCell cell={cell} projectId={projectId} />
      }
    ];
  }, [projectId]);

  const tableData = useMemo(() => {
    if (isLoading) {
      const initDataArray: $TSFixMe = [];
      initDataArray.length = 10;
      initDataArray.fill({});
      return initDataArray.map(() =>
        columns.reduce((acc, curr) => {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          acc[curr.accessor] = "";
          return acc;
        }, {})
      );
    }
    return data;
  }, [data, columns, isLoading]);

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: PAGE_SIZE
      },
      autoResetPage: false
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    state: { pageIndex }
  } = tableInstance;

  return (
    <>
      <table className={styles.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: $TSFixMe) => {
            const { key: groupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr className={styles.theadRow} key={groupKey} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column: $TSFixMe, index: $TSFixMe) => {
                  const key = column.accessor + index;

                  return (
                    <th
                      style={{
                        minWidth: `max(calc(${65 / 4}vw - ${Math.floor(
                          (PADDING_SIDE_SIZE * 2) / 4
                        )}px - ${3 / 4}px), 100px)`
                      }}
                      className={styles.theadCell}
                      key={key}
                      {...column.getHeaderProps()}>
                      <HeaderCell column={column} />
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: $TSFixMe) => {
            prepareRow(row);
            const { key: rowKey, ...restRowProps } = row.getRowProps();

            return (
              <tr className={styles.tbodyRow} key={rowKey} {...restRowProps}>
                {row.cells.map((cell: $TSFixMe) => {
                  const { key: cellKey, ...restCellProps } = cell.getCellProps();

                  if (isLoading) {
                    return (
                      <ShimmerTdCell
                        style={{
                          minWidth: `max(calc(${65 / 4}vw - ${Math.floor(
                            (PADDING_SIDE_SIZE * 2) / 4
                          )}px - ${3 / 4}px), 100px)`
                        }}
                        key={cellKey}
                      />
                    );
                  }

                  return (
                    <td
                      style={{
                        minWidth: `max(calc(${65 / 4}vw - ${Math.floor(
                          (PADDING_SIDE_SIZE * 2) / 4
                        )}px - ${3 / 4}px), 100px)`
                      }}
                      className={styles.tbodyCell}
                      key={cellKey}
                      {...restCellProps}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!page.length && <div className={styles.emptyTableMessage}>No records</div>}
      {rows.length > PAGE_SIZE && (
        <Pagination
          pageOptions={pageOptions}
          pageCount={pageCount}
          actualPage={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
        />
      )}
    </>
  );
};
