import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { getArtifacts } from "src/api";
import { Artifact, ArtifactsResponse } from "src/types";

type ArtifactsProps = {
  scenarioId?: string;
  jobRunId?: string;
};

export const QUERY_KEY_ARTIFACTS = "artifacts";

export const useGetArtifacts = (
  props: ArtifactsProps = {},
  options?: UseQueryOptions<Artifact[]>
) => {
  const { scenarioId, jobRunId } = props;

  return useQuery<Artifact[]>({
    queryKey: [QUERY_KEY_ARTIFACTS],
    queryFn: async () => {
      const response: ArtifactsResponse = await getArtifacts({ scenarioId, jobRunId });

      return response.artifacts;
    },
    ...options
  });
};
