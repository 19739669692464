import React from "react";

import IconButton from "@material-ui/core/IconButton";

import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";

import { useDagFlowContext } from "../DagFlow/context/useDagFlowContext";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: `${theme.spacing(1.5)}px !important`,
    border: "1px solid white",
    backgroundColor: `${theme.palette.warning.main} !important`,
    "& svg": {
      fontSize: "x-small",
      color: "black !important"
    }
  },
  borderLeft: {
    borderLeft: "1px solid #ffffff4d"
  }
}));

type Props = {
  nodeId: string;
  disabled?: boolean;
};

const ExpandCollapseIconButton = (props: Props) => {
  const { nodeId, disabled } = props || {};

  const { nodesExpanded, updateNodesExpanded } = useDagFlowContext();

  const classes = useStyles();

  return (
    <Box px={1} className={classes.borderLeft}>
      {nodesExpanded[nodeId] ?? true ? (
        <IconButton
          size="small"
          disabled={disabled}
          onClick={() => updateNodesExpanded(nodeId)}
          className={classes.root}>
          <RemoveRoundedIcon />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          disabled={disabled}
          onClick={() => updateNodesExpanded(nodeId)}
          className={classes.root}>
          <AddRoundedIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default ExpandCollapseIconButton;
