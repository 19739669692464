/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import { UserTenantType } from "src/types/Tenant.type";
import { logNotebooksOut } from "src/utils/operateNotebookService";
import { postAPIWithRethrow } from "src/utils/apiService";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";
import { NotebookTypes } from "src/constants";

type Props = {
  onSuccess?: (data: UserTenantType) => void;
};

export const useTenantSwitchMutation = ({ onSuccess }: Props = {}) => {
  const { setAuthResponse } = useAuthSetAuthResponse();

  return useMutation({
    mutationFn: ({ tenantId }: { tenantId: string }) => {
      return postAPIWithRethrow(`/switch`, { tenantId }).then(async (data: UserTenantType) => {
        // $FixMe: This needs to be centralized.
        if (data.notebookType !== NotebookTypes.Tenant) {
          await logNotebooksOut();
        }
        setAuthResponse({
          data,
          rememberMe: true
        });
        await onSuccess?.(data);
        return data;
      });
    }
  });
};
