import FlashOnIcon from "@material-ui/icons/FlashOn";
import React, { useMemo } from "react";
import _ from "lodash";
import { Clear } from "@material-ui/icons";
import { makeStyles, Grid, Box, IconButton, Tooltip } from "@material-ui/core";
import { useLocation } from "react-router";

import Chart from "../../Charts/Chart";
import ChartFrame from "src/pages/Projects/Charts/ChartFrame";
import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";
import { Tabs, Tab, TabPanel } from "src/components";
import { useApiConnectorRecipeContext } from "../ApiConnectorRecipeContext/useApiConnectorRecipeContext";
import PlotlyCharts from "components/PlotlyCharts";
import { CodeRecipeCodeEditor } from "../../AddCodeRecipe/CodeRecipeTabContainer/CodeRecipeCodeEditor/CodeRecipeCodeEditor";
import { TestRecipeDropdown } from "../../AddCodeRecipe/CodeRecipeTabContainer/TestRecipeDropdown/TestRecipeDropdown";
import SyntaxButton from "./SyntaxButton";
import { READONLY_ENTITY } from "src/constants";
import { SaveButton } from "./SaveButton";
import useSaveCode from "src/hooks/useSaveCode";

const useStyles = makeStyles({
  header: {
    flexWrap: "nowrap",
    borderBottom: "1px solid rgba(53,96,121,0.26)",
    padding: "8px 16px",
    justifyContent: "space-between"
  },
  tabRoot: {
    minHeight: "44px",
    flex: 1,
    borderRadius: "4px"
  },
  tabDetails: {
    padding: "16px",
    height: "100%"
  },
  codeEditorWrap: {
    border: "1px solid #C5C5C5",
    borderRadius: "4px",
    height: "100%",

    " & .react-monaco-editor-container": {
      overflow: "hidden",

      "& .monaco-editor": {
        width: "100% !important"
      }
    }
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px 16px",
    gap: "16px"
  },
  tabHeader: {
    flexWrap: "nowrap",
    gap: "10px",
    display: "flex !important",
    alignItems: "center"
  },
  testBtn: {
    width: "64px"
  },
  viewDataContentContainer: {
    padding: "16px",
    height: "calc(100vh - 159px)",
    overflowY: "auto"
  },
  inputTabWrap: {
    flexWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  chartOutput: {
    borderRadius: "4px",
    background: "#FFF",
    border: "1px solid #D1D1D1"
  },
  chartName: {
    margin: "4px 12px 0px 12px",
    color: "#133553",
    borderBottom: "1px solid #D1D1D1"
  },
  editor: {
    overflow: "hidden"
  }
});

const ApiConnectorRecipeTabContainer = ({ isDefaultScenario }: { isDefaultScenario: boolean }) => {
  const classes = useStyles();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const [value, setValue] = React.useState<string>("codeEditor");
  const [insertedCode, setInsertedCode] = React.useState<string | null>(null);

  const {
    isTestDisabled,
    isTestInProgress,
    testTooltip,
    previewTabs,
    editorValue,
    codeErrorDetails,
    setPreviewTabs,
    handleTest,
    setEditorValue,
    isSaveDisabled,
    saveToolTip,
    isSaveInProgress,
    handleSave,
    isAutoSaving
  } = useApiConnectorRecipeContext();

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (previewTabs.length) {
      const lastPreviewTab = previewTabs[previewTabs.length - 1];
      setValue(lastPreviewTab.id);
    }
  }, [previewTabs]);

  useSaveCode({
    handleSave,
    isSaveDisabled: isSaveDisabled || !!isJobPath,
    isTestInProgress
  });

  return (
    <>
      <Grid container direction="row" className={classes.header}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            test-id="code-recipe-code-editor-tab"
            key="codeEditor"
            value="codeEditor"
            label="Code"
          />
          {previewTabs.length
            ? previewTabs?.map((item: $TSFixMe) => (
                <Tab
                  key={item.id}
                  value={item.id}
                  icon={<FlashOnIcon color="secondary" style={{ fontSize: "16px" }} />}
                  label={item.name}
                  clearIcon={
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setPreviewTabs((previewTabs: any) =>
                          previewTabs.filter((tab: any) => tab.id !== item.id)
                        );
                        setValue("codeEditor");
                      }}>
                      <Clear style={{ fontSize: 14 }} />
                    </IconButton>
                  }
                />
              ))
            : null}
        </Tabs>

        {!isJobPath && (
          <Grid className={classes.headerButtons}>
            <Box display="flex" flexDirection="row" flexWrap="nowrap" gridGap="16px">
              <SaveButton
                onClick={handleSave}
                tooltip={isDefaultScenario ? saveToolTip || "" : READONLY_ENTITY}
                disabled={isSaveDisabled || !isDefaultScenario}
                loading={isSaveInProgress}
                isAutoSaving={isAutoSaving}
              />
              <Tooltip title={testTooltip || ""}>
                <span>
                  <TestRecipeDropdown
                    hideMenu
                    onTest={handleTest}
                    isTestDisabled={isTestDisabled}
                    isTestInProgress={isTestInProgress}
                  />
                </span>
              </Tooltip>
              {value === "codeEditor" && <SyntaxButton />}
            </Box>
          </Grid>
        )}
      </Grid>
      <TabPanel
        key="codeEditor"
        value={value}
        index="codeEditor"
        boxProps={{ height: "100%" }}
        style={{ height: "calc(100% - 65px)" }}>
        <Grid className={classes.tabDetails}>
          <Grid className={classes.codeEditorWrap}>
            <Box height="calc(100% - 2px)" mt="1px" mb="4px">
              <CodeRecipeCodeEditor
                disabled={!isDefaultScenario || !!isJobPath}
                editorValue={editorValue}
                setEditorValue={setEditorValue}
                insertedCode={insertedCode}
                resetInsertedCode={() => setInsertedCode(null)}
                errDetails={codeErrorDetails}
              />
            </Box>
          </Grid>
        </Grid>
      </TabPanel>
      {previewTabs?.map((item: $TSFixMe) => {
        if (item.type === "DATASET") {
          return (
            <TabPanel value={value} index={item.id} key={item.id}>
              <div id="viewDataContainer" className={classes.viewDataContentContainer}>
                <ViewDataData
                  isLoadingData={false}
                  hideCount={false}
                  responseData={item.data || { columns: [], rows: [] }}
                  entityFeatures={[]}
                  maxHeight="calc(-200px + 100vh)"
                />
              </div>
            </TabPanel>
          );
        } else {
          const chartJson =
            item.chartJson && typeof item.chartJson === "string"
              ? { ...JSON.parse(item.chartJson) }
              : null;
          const cleanedChartJson = chartJson
            ? { ...chartJson, layout: { ...chartJson.layout, title: null } }
            : {};
          return (
            <TabPanel key={item.id} value={value} index={item.id}>
              <Box p="16px" width="100%">
                <Grid container direction="column" className={classes.chartOutput}>
                  {item.chartJson && typeof item.chartJson === "string" ? (
                    <Grid container direction="column" spacing={1}>
                      <Grid item className={classes.chartName}>
                        {item.name}
                      </Grid>
                      <Grid item>
                        <PlotlyCharts {...cleanedChartJson} />
                      </Grid>
                    </Grid>
                  ) : item.chartType ? (
                    <Chart data={item} expanded />
                  ) : (
                    <ChartFrame data={item} size={12} />
                  )}
                </Grid>
              </Box>
            </TabPanel>
          );
        }
      })}
    </>
  );
};

export default React.memo(ApiConnectorRecipeTabContainer);
