import React from "react";
import { makeStyles, Container } from "@material-ui/core";

import TopNavBarNotifications from "./TopNavBarNotifications/TopNavBarNotifications";
import TopNavBarHelpMenu from "./TopNavBarHelpMenu";
import { TopNavBarTenantMenu } from "./TopNavBarTenantMenu";
import { TopNavBarUserMenu } from "./TopNavBarUserMenu";

const useStyles = makeStyles({
  root: {
    display: "inline-flex !important",
    justifyContent: "end",
    alignItems: "center",
    columnGap: "1rem",
    paddingRight: 12
  }
});

const TopNavBarRightSection = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <TopNavBarNotifications />
      <TopNavBarHelpMenu />
      <TopNavBarTenantMenu />
      <TopNavBarUserMenu />
    </Container>
  );
};

export default TopNavBarRightSection;
