import React from "react";

export const RapidModelRecipeIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor",
  opacity = 1
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <path d="M13 9H19L8 22L11 12H5L10 2H17L13 9Z" fill={color} />
    </g>
  </svg>
);
