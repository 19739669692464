import React, { useMemo, useState } from "react";
import ReportOutlinedIcon from "@material-ui/icons/ReportOutlined";
import _ from "lodash";
import { Grid, Tab, Tabs, Tooltip, Button, makeStyles } from "@material-ui/core";
import { Launch } from "@material-ui/icons";
import { Link } from "react-router-dom";

import CollapsibleCard from "./CollapsibleCard";
import Description, { IData } from "./Description";
import EdaAlerts from "./EdaAlerts";
import EdaCorrelations from "./EdaCorrelations";
import { EDA_COMPONENTS, MAPPING } from "../utils/Eda.constants";
import { Section } from "pages/ViewData/ViewDataRoutes";
import { TabPanel } from "src/components";
import { ADVANCE_ANALYSIS_URL } from "../AdvanceAnalysis";

export interface IEdaCardProps {
  edaJson: IEdaJson;
  url?: string;
}

interface IProps extends IEdaCardProps {
  isTruncated: boolean;
}

interface IVal {
  label: string;
  renderFn?: (value: any) => any;
}

const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  title: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& button": {
      textTransform: "none"
    }
  },
  extra: {
    display: "flex",
    gap: "25px",
    alignItems: "center"
  },
  overviewTabs: {
    boxShadow: "none"
  },
  selected: {
    backgroundColor: "#E7E7FF"
  }
});

const EdaOverview: React.FC<IProps> = (props) => {
  const { edaJson, url, isTruncated } = props;

  const [tab, setTab] = useState(0);
  const { wrapper, title, extra, overviewTabs, selected } = useStyles();

  const { overviewData, variableTypesData } = useMemo(() => {
    const overview: IData[] = [];
    const types: IData[] = [];

    _.forEach(MAPPING.overview.statistics, ({ label, renderFn }: IVal, key) => {
      if (_.has(edaJson.table, key)) {
        const val = _.get(edaJson.table, key);
        overview.push({
          key: label,
          value: _.isFunction(renderFn) ? renderFn(val) : val
        });
      }
    });

    _.forEach(edaJson.table.types, (val, key) => {
      types.push({
        key: key,
        value: val
      });
    });

    return {
      overviewData: overview,
      variableTypesData: types
    };
  }, [edaJson.table]);

  const handleChange = (__: React.ChangeEvent<{}>, value: number) => {
    setTab(value);
  };

  return (
    <CollapsibleCard
      title={
        <div className={title}>
          <Tabs
            value={tab}
            variant="scrollable"
            className={overviewTabs}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}>
            <Tab key={0} label="Overview" classes={{ selected }} />
            {_.has(edaJson, "correlations.auto") && (
              <Tab key={1} label="Correlation" classes={{ selected }} />
            )}
            {!_.isEmpty(edaJson.alerts) && (
              <Tab
                key={2}
                label={<span>Alerts ({_.size(edaJson.alerts)})</span>}
                classes={{ selected }}
              />
            )}
          </Tabs>
          <div className={extra}>
            {isTruncated && (
              <Tooltip title="This analysis report has been generated using a subset of the larger dataset, encompassing 50 columns and 100k rows, due to the dataset's substantial size.">
                <ReportOutlinedIcon color="error" />
              </Tooltip>
            )}
            {url && (
              <Link
                to={`${_.replace(
                  window.location.href,
                  Section.analysis,
                  Section.advanceAnalysis
                )}?${ADVANCE_ANALYSIS_URL}=${encodeURIComponent(url)}`}
                target="_blank">
                <Button size="small" endIcon={<Launch />} color="primary" variant="outlined">
                  Advanced Analysis
                </Button>
              </Link>
            )}
          </div>
        </div>
      }
      label={EDA_COMPONENTS.overview.title}
      defaultExpanded>
      <div className={wrapper}>
        <TabPanel index={0} value={tab}>
          <Grid container spacing={3} style={{ width: "100%" }}>
            {overviewData.length > 0 && (
              <Grid item xs={12} sm={12} md={6}>
                <Description title="Dataset statistics" data={overviewData} />
              </Grid>
            )}
            {variableTypesData.length > 0 && (
              <Grid item xs={12} sm={12} md={3}>
                <Description title="Variable types" data={variableTypesData} />
              </Grid>
            )}
          </Grid>
        </TabPanel>
        {_.has(edaJson, "correlations.auto") && (
          <TabPanel index={1} value={tab}>
            <EdaCorrelations edaJson={edaJson} />
          </TabPanel>
        )}
        {!_.isEmpty(edaJson.alerts) && (
          <TabPanel index={_.has(edaJson, "correlations.auto") ? 2 : 1} value={tab}>
            <EdaAlerts alerts={edaJson.alerts} />
          </TabPanel>
        )}
      </div>
    </CollapsibleCard>
  );
};

export default EdaOverview;
