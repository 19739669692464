import React, { useState } from "react";
import _ from "lodash";
import { FileCopyOutlined } from "@material-ui/icons";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";

interface IProps {
  tableData: TableData[];
}

type TableData = Record<string, string | number | boolean>;

const useStyles = makeStyles((theme) => ({
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: theme.spacing(0.5)
  }
}));

const formatTableData = (tableData: TableData[]): string => {
  if (_.isEmpty(tableData)) return "";
  const filtered = ["NaN", "nan"];
  const headers = _.keys(tableData[0]).join("\t");

  const rows = _.map(tableData, (row) =>
    _.map(_.values(row), (value) =>
      _.includes(filtered, _.toString(value)) ? "" : _.toString(value)
    ).join("\t")
  ).join("\n");

  return `${headers}\n${rows}`;
};

const CopyDataButton: React.FC<IProps> = (props) => {
  const { tableData } = props;

  const [isCopied, setIsCopied] = useState(false);
  const classes = useStyles();

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    const copiedString = formatTableData(tableData);
    try {
      await navigator.clipboard.writeText(copiedString);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 5000);
    } catch (err) {
      toast.error("Error in copying the data");
      setIsCopied(false);
    }
  };

  return (
    <Tooltip title={isCopied ? "Data copied successfully" : "Copy table data"}>
      <IconButton
        className={classes.queryBtn}
        size="small"
        data-testid={`ask-ai-copy-data-btn`}
        onClick={handleClick}>
        <FileCopyOutlined style={{ width: "16px", height: "16px", color: "black" }} />
      </IconButton>
    </Tooltip>
  );
};

export default CopyDataButton;
