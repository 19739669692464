import React from "react";
import { Alert } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { startCase } from "lodash";
import { PublicScreenLabels } from "src/constants";
import { useStyles } from "./SignIn.styles";

interface SignInErrorProps {
  isVisible?: boolean;
  error?: string | undefined;
}

export const SignInError: React.FC<SignInErrorProps> = ({ isVisible, error }) => {
  const classes = useStyles();
  if (!isVisible) return null;

  return (
    <Alert variant="outlined" severity="error" className={classes.error}>
      <Typography variant="caption">
        {error ||
          `${startCase(PublicScreenLabels.Email)} or ${startCase(PublicScreenLabels.Password)} is invalid!`}
      </Typography>
    </Alert>
  );
};
