import { UseMutationResult, useMutation } from "@tanstack/react-query";

import { postAPIWithRethrow } from "src/utils/apiService";

export enum SignedUrlObjectTypeEnum {
  DATAAPP = "DATAAPP",
  APP_TEMPLATE_STREAMLIT = "APP_TEMPLATE_STREAMLIT",
  DATAAPP_STREAMLIT = "DATAAPP_STREAMLIT",
  DATAAPP_LAUNCH_LOGS = "DATAAPP_LAUNCH_LOGS",
  DATAAPP_ARTIFACTS = "DATAAPP_ARTIFACTS",
  FUNC_TEMPLATE = "FUNC_TEMPLATE",
  DEMO_PROJECT = "DEMO_PROJECT",
  GENERAL = "GENERAL"
}

type IPayload = {
  fileName: string;
  filePath: string;
  signedUrlObjectType: SignedUrlObjectTypeEnum;
};

type IResponse = {
  signedUrl: string;
  responseEntity: string;
  headers: { string: string };
  status: "SKIPPED" | "SUCCESS" | "FAILURE";
};
export const useGetSignedUrlMutation = (): UseMutationResult<
  IResponse,
  unknown,
  IPayload,
  unknown
> => {
  return useMutation({
    mutationFn: (payload) => {
      return postAPIWithRethrow(`/signed-url/generate-file-download-url`, payload);
    }
  });
};
