/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import type { Message } from './message';
// May contain unused imports in some cases
// @ts-ignore
import type { UninterpretedOption } from './uninterpreted-option';
// May contain unused imports in some cases
// @ts-ignore
import type { UninterpretedOptionOrBuilder } from './uninterpreted-option-or-builder';
// May contain unused imports in some cases
// @ts-ignore
import type { UnknownFieldSet } from './unknown-field-set';

/**
 * 
 * @export
 * @interface FieldOptionsOrBuilder
 */
export interface FieldOptionsOrBuilder {
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptionsOrBuilder
     */
    'deprecated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptionsOrBuilder
     */
    'packed'?: boolean;
    /**
     * 
     * @type {Array<UninterpretedOption>}
     * @memberof FieldOptionsOrBuilder
     */
    'uninterpretedOptionList'?: Array<UninterpretedOption>;
    /**
     * 
     * @type {number}
     * @memberof FieldOptionsOrBuilder
     */
    'uninterpretedOptionCount'?: number;
    /**
     * 
     * @type {Array<UninterpretedOptionOrBuilder>}
     * @memberof FieldOptionsOrBuilder
     */
    'uninterpretedOptionOrBuilderList'?: Array<UninterpretedOptionOrBuilder>;
    /**
     * 
     * @type {string}
     * @memberof FieldOptionsOrBuilder
     */
    'ctype'?: FieldOptionsOrBuilderCtypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FieldOptionsOrBuilder
     */
    'jstype'?: FieldOptionsOrBuilderJstypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptionsOrBuilder
     */
    'lazy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptionsOrBuilder
     */
    'weak'?: boolean;
    /**
     * 
     * @type {Message}
     * @memberof FieldOptionsOrBuilder
     */
    'defaultInstanceForType'?: Message;
    /**
     * 
     * @type {UnknownFieldSet}
     * @memberof FieldOptionsOrBuilder
     */
    'unknownFields'?: UnknownFieldSet;
    /**
     * 
     * @type {string}
     * @memberof FieldOptionsOrBuilder
     */
    'initializationErrorString'?: string;
    /**
     * 
     * @type {Descriptor}
     * @memberof FieldOptionsOrBuilder
     */
    'descriptorForType'?: Descriptor;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FieldOptionsOrBuilder
     */
    'allFields'?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof FieldOptionsOrBuilder
     */
    'initialized'?: boolean;
}

export const FieldOptionsOrBuilderCtypeEnum = {
    String: 'STRING',
    Cord: 'CORD',
    StringPiece: 'STRING_PIECE'
} as const;

export type FieldOptionsOrBuilderCtypeEnum = typeof FieldOptionsOrBuilderCtypeEnum[keyof typeof FieldOptionsOrBuilderCtypeEnum];
export const FieldOptionsOrBuilderJstypeEnum = {
    Normal: 'JS_NORMAL',
    String: 'JS_STRING',
    Number: 'JS_NUMBER'
} as const;

export type FieldOptionsOrBuilderJstypeEnum = typeof FieldOptionsOrBuilderJstypeEnum[keyof typeof FieldOptionsOrBuilderJstypeEnum];


