import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  drawer: {
    width: "40%",
    flexShrink: 0,
    "& .MuiAppBar-root": {
      width: "40%",
      backgroundColor: "white",
      boxShadow: "0px 1px 2px 0px #00000040"
    }
  },
  drawerPaper: {
    width: "40%",
    backgroundColor: "#f5f5f5"
  },
  container: {
    height: "80%",
    overflowY: "auto",
    "& div[class^='MuiFormControl-root']": {
      width: "100%"
    },
    "& div[class^='MuiSelect-root']": {
      paddingTop: 9.5,
      paddingBottom: 9.5
    }
  }
}));

export { useStyles };
