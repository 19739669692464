import React from "react";

const NoConnectorIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11950_24483)">
        <path
          d="M22.5927 16.143C22.1046 15.6548 21.3131 15.6548 20.825 16.143L20.4644 16.5036L20.1037 16.143C19.6156 15.6548 18.8241 15.6548 18.336 16.143C17.8479 16.6312 17.8478 17.4226 18.336 17.9107L18.6966 18.2714L18.336 18.632C17.8478 19.1202 17.8478 19.9116 18.336 20.3997C18.5801 20.6438 18.8999 20.7659 19.2199 20.7659C19.5398 20.7659 19.8597 20.6438 20.1037 20.3997L20.4644 20.0391L20.825 20.3997C21.0691 20.6438 21.3889 20.7659 21.7089 20.7659C22.0288 20.7659 22.3487 20.6438 22.5927 20.3997C23.0809 19.9116 23.0809 19.1201 22.5927 18.632L22.2321 18.2714L22.5927 17.9107C23.0809 17.4226 23.0809 16.6311 22.5927 16.143Z"
          fill="#7C7C7C"
        />
        <path
          d="M9.40728 20.3997C9.65134 20.6438 9.97122 20.7659 10.2912 20.7659C10.6111 20.7659 10.931 20.6438 11.175 20.3997L11.5357 20.0391L11.8963 20.3997C12.1403 20.6438 12.4602 20.7659 12.7802 20.7659C13.1001 20.7659 13.42 20.6438 13.664 20.3997C14.1522 19.9116 14.1522 19.1201 13.664 18.632L13.3034 18.2714L13.664 17.9107C14.1522 17.4226 14.1522 16.6311 13.664 16.143C13.1758 15.6549 12.3844 15.6548 11.8963 16.143L11.5357 16.5036L11.175 16.143C10.6868 15.6548 9.8954 15.6548 9.40728 16.143C8.91915 16.6312 8.91909 17.4226 9.40728 17.9107L9.7679 18.2714L9.40728 18.632C8.91909 19.1201 8.91909 19.9116 9.40728 20.3997Z"
          fill="#7C7C7C"
        />
        <path
          d="M14.425 25.3655C15.2935 24.497 16.7065 24.497 17.5749 25.3655C17.819 25.6095 18.1388 25.7316 18.4588 25.7316C18.7787 25.7316 19.0986 25.6095 19.3427 25.3655C19.8308 24.8773 19.8308 24.0858 19.3427 23.5977C17.4995 21.7545 14.5003 21.7545 12.6572 23.5977C12.169 24.0859 12.169 24.8773 12.6572 25.3655C13.1453 25.8536 13.9368 25.8537 14.425 25.3655Z"
          fill="#7C7C7C"
        />
        <path
          d="M27 0.557617H5C2.243 0.557617 0 2.80062 0 5.55762V26.4428C0 29.1998 2.243 31.4428 5 31.4428H27C29.7471 31.4428 31.9899 29.2079 31.9997 26.4608C32.0021 25.7705 31.4445 25.2088 30.7541 25.2064H30.7496C30.0613 25.2064 29.5021 25.7631 29.4996 26.4519C29.4947 27.8254 28.3734 28.9429 26.9999 28.9429H5C3.6215 28.9429 2.5 27.8214 2.5 26.4429V13.0122H29.5V20.2064C29.5 20.8967 30.0596 21.4564 30.75 21.4564C31.4404 21.4564 32 20.8967 32 20.2064V5.55762C32 2.80062 29.757 0.557617 27 0.557617ZM2.5 10.5122V5.55762C2.5 4.17912 3.6215 3.05762 5 3.05762H27C28.3785 3.05762 29.5 4.17912 29.5 5.55762V10.5122H2.5Z"
          fill="#7C7C7C"
        />
        <path
          d="M6.76955 5.53516H6.12036C5.42999 5.53516 4.87036 6.09478 4.87036 6.78516C4.87036 7.47553 5.42999 8.03516 6.12036 8.03516H6.76955C7.45992 8.03516 8.01955 7.47553 8.01955 6.78516C8.01955 6.09478 7.45992 5.53516 6.76955 5.53516Z"
          fill="#7C7C7C"
        />
        <path
          d="M11.8991 5.53516H11.2499C10.5595 5.53516 9.99988 6.09478 9.99988 6.78516C9.99988 7.47553 10.5595 8.03516 11.2499 8.03516H11.8991C12.5894 8.03516 13.1491 7.47553 13.1491 6.78516C13.1491 6.09478 12.5894 5.53516 11.8991 5.53516Z"
          fill="#7C7C7C"
        />
        <path
          d="M17.0286 5.53516H16.3794C15.689 5.53516 15.1294 6.09478 15.1294 6.78516C15.1294 7.47553 15.689 8.03516 16.3794 8.03516H17.0286C17.719 8.03516 18.2786 7.47553 18.2786 6.78516C18.2786 6.09478 17.719 5.53516 17.0286 5.53516Z"
          fill="#7C7C7C"
        />
      </g>
      <defs>
        <clipPath id="clip0_11950_24483">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoConnectorIcon;
