import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { Grid, Paper, Avatar, Tooltip, CircularProgress, IconButton } from "@material-ui/core";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import EditJobConnector from "./EditJobConnector";
import ViewJobDataConnector from "./ViewJobDataConnector";
import SelectJobConnectorType from "./SelectJobConnectorType";
import { toastWrapper } from "src/utils/toastWrapper";
import {
  useCreateJobDestination,
  useUpdateJobDestination,
  useDeleteJobDestination
} from "src/hooks/api";
import { Selection } from "src/components";
import { JobsHelperText } from "../../utils/Jobs.constants";
import { useStyles } from "./JobDestination.styles";
import { IDestination } from "../JobDestinations/JobDestinations";
import { DataConnectorNames } from "pages/DataSources/utils/DataSources.constants";
import {
  CONNECTOR_KEYS,
  MAX_LONG_LENGTH
} from "../../../OutputDataset/utils/OutputDataset.constants";
import { isInvalidConnectionInputs } from "../../../OutputDataset/utils/OutputDataset.helpers";

interface IProps {
  jobData: any;
  datasetsData: any[];
  dataSourcesData: any[];
  destinations: IDestination[];
  setDestinations: (destinations: IDestination[]) => void;
  destination: IDestination;
  index: number;
  isAllDestinationsValid: boolean;
  setIsSaved: (val: () => false) => void;
  isDestinationSaving: boolean;
  setIsDestinationSaving: (val: () => boolean) => void;
  deletingDestinationId: string;
  setDeletingDestinationId: (deletingDestinationId: string) => void;
  refetchDestinations: () => void;
}

const JobDestination: React.FC<IProps> = (props) => {
  const {
    jobData,
    datasetsData,
    dataSourcesData,
    destinations,
    setDestinations,
    destination,
    index,
    isAllDestinationsValid,
    setIsSaved,
    isDestinationSaving,
    setIsDestinationSaving,
    deletingDestinationId,
    setDeletingDestinationId,
    refetchDestinations
  } = props || {};

  const classes = useStyles();

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isJobDestinationSaving,
    isSuccess: isJobDestinationSaved,
    data: savedJobDestinationData,
    mutateAsync: saveJobDestinationMutation,
    reset: resetSaveJobDestinationMutation
  } = useCreateJobDestination();

  const {
    isLoading: isJobDestinationUpdating,
    isSuccess: isJobDestinationUpdated,
    data: updatedJobDestinationData,
    mutateAsync: updateJobDestinationMutation,
    reset: resetUpdateJobDestinationMutation
  } = useUpdateJobDestination();

  useEffect(() => {
    setIsDestinationSaving(() => isJobDestinationSaving || isJobDestinationUpdating);
  }, [isJobDestinationSaving, isJobDestinationUpdating]);

  const { mutateAsync: deleteJobDestinationMutation, reset: resetDeleteJobDestinationMutation } =
    useDeleteJobDestination();
  // << ENDS - Query hooks

  const getDataset = (datasetId: string = "") => {
    return (datasetsData || [])?.find((eachDataset: any) => eachDataset?.id === datasetId) || {};
  };

  const editDestination = () => {
    const thisDestinations: IDestination[] = [...destinations]?.map(
      (eachDestination: IDestination) => {
        if (Object.keys(eachDestination || {})?.length > 0) {
          eachDestination["isEditing"] = false;
        }

        return eachDestination;
      }
    );

    thisDestinations[index]["isSubmitted"] = false;
    thisDestinations[index]["isValid"] = false;

    thisDestinations[index]["selectedDatasets"] = [
      getDataset(thisDestinations[index]["datasetId"]) || {}
    ];
    thisDestinations[index]["datasetId"] = "";
    thisDestinations[index]["searchedDatasets"] = [];

    thisDestinations[index]["isEditing"] = true;

    setDestinations([...thisDestinations]);
  };

  useEffect(() => {
    if (isJobDestinationSaved || isJobDestinationUpdated) {
      const destinationData = savedJobDestinationData || updatedJobDestinationData;

      if (destinationData?.id) {
        const thisDestinations = [...destinations];
        thisDestinations[index]["isSubmitted"] = true;
        thisDestinations[index]["isValid"] = true;
        thisDestinations[index]["id"] = destinationData?.id || "";
        thisDestinations[index]["datasetId"] =
          destinationData?.entityId || destination?.selectedDatasets[0]?.id;
        thisDestinations[index]["dataSourceId"] =
          destinationData?.dataSourceId || destination?.enteredDataSourceId;
        thisDestinations[index]["options"] = destinationData?.options;
        thisDestinations[index]["enteredOptions"] = destinationData?.options;
        thisDestinations[index]["isEditing"] = false;

        setDestinations(thisDestinations);
      }
    }
  }, [isJobDestinationSaved, isJobDestinationUpdated]);

  const saveDestination = async () => {
    if (!jobData?.id) {
      return;
    }

    let payload = {
      projectRunId: jobData?.id,
      entityId: destination?.selectedDatasets[0]?.id,
      dataSourceId: destination?.enteredDataSourceId,
      options: destination.enteredOptions
    };

    if (destination?.id) {
      resetUpdateJobDestinationMutation();

      payload = {
        ...payload,
        ...{ id: destination?.id }
      };

      await updateJobDestinationMutation(payload, {
        onSuccess: () => {
          refetchDestinations();
          toastWrapper({ type: "success", content: JobsHelperText.DestinationUpdated });
        },
        onError: () => {
          toastWrapper({ type: "error", content: JobsHelperText.DestinationSaveFailed });
        }
      });
    } else {
      resetSaveJobDestinationMutation();

      await saveJobDestinationMutation(payload, {
        onSuccess: () => {
          refetchDestinations();
          toastWrapper({ type: "success", content: JobsHelperText.DestinationSaved });
        },
        onError: () => {
          toastWrapper({ type: "error", content: JobsHelperText.DestinationSaveFailed });
        }
      });
    }
  };

  const filterDestination = () => {
    setDestinations(
      destinations?.filter((_: IDestination, thisIndex: number) => thisIndex !== index) || []
    );
  };

  const deleteDestination = async () => {
    if (!jobData?.id) {
      setIsSaved(() => false);
    }

    if (!destination?.id) {
      filterDestination();
      return;
    }

    resetDeleteJobDestinationMutation();

    setDeletingDestinationId(destination?.id);
    await deleteJobDestinationMutation(destination?.id, {
      onSuccess: () => {
        refetchDestinations();
        filterDestination();

        toastWrapper({ type: "success", content: JobsHelperText.DestinationDeleted });
      },
      onError: () => {
        toastWrapper({ type: "error", content: JobsHelperText.DestinationDeleteFailed });
      },
      onSettled: () => {
        setDeletingDestinationId("");
      }
    });
  };

  const disableSave = useMemo(() => {
    if (_.size(destination.selectedDatasets) === 0 || !destination?.enteredDataSourceId) {
      return true;
    }
    const values = destination.enteredOptions;
    switch (destination.selectedDataSourceType) {
      case DataConnectorNames.AZURE_BLOB:
      case DataConnectorNames.GCP_STORAGE:
      case DataConnectorNames.S3_STORAGE:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.fileCategory.destinationFolder.key)
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.fileCategory.destinationFileName.key)
          )
        );

      case DataConnectorNames.MYSQL:
      case DataConnectorNames.REDSHIFT:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.sqlRedshiftCategory.tableName.key),
            CONNECTOR_KEYS.sqlRedshiftCategory.tableName.regex
          ) || isInvalidConnectionInputs(_.get(values, CONNECTOR_KEYS.sqlRedshiftCategory.type.key))
        );

      case DataConnectorNames.MONGO:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.mongoCateogry.collection.key),
            CONNECTOR_KEYS.mongoCateogry.collection.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.mongoCateogry.database.key),
            CONNECTOR_KEYS.mongoCateogry.database.regex
          )
        );

      case DataConnectorNames.SNOWFLAKE:
        return (
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.databaseName.key),
            CONNECTOR_KEYS.snowFlakeCategory.databaseName.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.schema.key),
            CONNECTOR_KEYS.snowFlakeCategory.schema.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.tableName.key),
            CONNECTOR_KEYS.snowFlakeCategory.tableName.regex
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.warehouse.key),
            undefined,
            MAX_LONG_LENGTH
          ) ||
          isInvalidConnectionInputs(
            _.get(values, CONNECTOR_KEYS.snowFlakeCategory.role.key),
            undefined,
            undefined,
            true
          ) ||
          isInvalidConnectionInputs(_.get(values, CONNECTOR_KEYS.snowFlakeCategory.type.key))
        );

      default:
        return false;
    }
  }, [
    destination?.selectedDatasets,
    destination?.enteredDataSourceId,
    destination?.enteredOptions,
    destination.selectedDataSourceType
  ]);

  const onSearchDatasets = (value: string) => {
    const thisDestinations = [...destinations];

    if (!value) {
      thisDestinations[index]["searchedDatasets"] = datasetsData;
      return;
    }

    const searchedDatasets = datasetsData?.filter((eachDataset: $TSFixMe) => {
      const regex = new RegExp(value, "gi");
      return eachDataset?.name.match(regex);
    });

    thisDestinations[index]["searchedDatasets"] = searchedDatasets;

    setDestinations(thisDestinations);
  };

  const onDatasetSelection = (e: $TSFixMe) => {
    if (!jobData?.id) {
      setIsSaved(() => false);
    }

    const thisDestinations = [...destinations];

    if (e?.target?.checked) {
      thisDestinations[index]["selectedDatasets"] = [getDataset(e?.target?.value) || {}];
    }

    setDestinations(thisDestinations);
  };

  const handleConnectorChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    dataSourceType: string
  ) => {
    if (!jobData?.id) {
      setIsSaved(() => false);
    }

    const thisDestinations = [...destinations];

    const value = e?.target?.value as unknown as string;

    thisDestinations[index]["enteredDataSourceId"] = value;
    thisDestinations[index]["selectedDataSourceType"] = dataSourceType;
    thisDestinations[index]["enteredOptions"] = {};
    setDestinations(thisDestinations);
  };

  const saveInfo = useMemo(() => {
    return !disableSave
      ? destination?.id
        ? JobsHelperText.Update
        : JobsHelperText.Save
      : `Please add all the mandatory information before ${
          destination?.id ? `updating` : `saving`
        } the destination details.`;
  }, [destination?.id, disableSave]);

  const handleOptionsChange = (options: Record<string, string>) => {
    const thisDestinations = [...destinations];
    thisDestinations[index]["enteredOptions"] = options;
    setDestinations(thisDestinations);
  };

  return (
    <Paper key={`destination_${index}`} component="fieldset" className={classes.destination}>
      <legend className="destinationTitle">{`Destination #${index + 1}`}</legend>
      <Grid container style={{ marginBottom: 15 }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", columnGap: 10 }}>
          {!destination?.isEditing && (
            <Tooltip
              title={
                !isAllDestinationsValid
                  ? "Please save unsaved changes to proceed with this action."
                  : JobsHelperText.Edit
              }>
              <span>
                <IconButton
                  size="small"
                  disabled={!isAllDestinationsValid}
                  onClick={() => editDestination()}>
                  <CreateOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {destination?.isEditing &&
            (isDestinationSaving ? (
              <Avatar sizes="small" className="loading">
                <CircularProgress size={16} />
              </Avatar>
            ) : (
              <Tooltip title={saveInfo}>
                <span>
                  <IconButton size="small" disabled={disableSave} onClick={() => saveDestination()}>
                    <SaveOutlinedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ))}
          {!!destination?.id && deletingDestinationId === destination?.id ? (
            <Avatar sizes="small" className="loading">
              <CircularProgress size={16} />
            </Avatar>
          ) : (
            <Tooltip title={JobsHelperText.Delete}>
              <span>
                <IconButton size="small" onClick={() => deleteDestination()}>
                  <DeleteOutlineIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </div>
      </Grid>
      {destination?.isEditing ? (
        <Grid container alignItems="center">
          <Grid item style={{ width: "47%" }}>
            <div
              className={
                destination?.isSubmitted && (destination?.selectedDatasets || [])?.length === 0
                  ? classes.multiSelectionError
                  : undefined
              }>
              <Selection
                isMultiSelection={false}
                data={datasetsData}
                onValueSearch={(searchedValue: string) => onSearchDatasets(searchedValue)}
                onValueSelection={(e) => onDatasetSelection(e)}
                searchedValues={destination?.searchedDatasets || []}
                selectedValues={destination?.selectedDatasets || []}
                selectedValuesInSet={
                  (destinations || [])?.map(
                    (eachDestination: IDestination) => eachDestination?.datasetId
                  ) || []
                }
                searchPlaceholder="Search dataset"
                isLabelWordBreak
              />
            </div>
          </Grid>
          <Grid item style={{ width: "6%" }}>
            <ArrowForwardIcon />
          </Grid>
          <Grid item style={{ width: "47%" }}>
            <SelectJobConnectorType
              connectors={dataSourcesData}
              destination={destination}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                classes: { paper: classes.selectDropdownContainer }
              }}
              onConnectorChange={handleConnectorChange}
            />
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <EditJobConnector
                destination={destination}
                index={index}
                onOptionsChange={handleOptionsChange}
              />
            </div>
          </Grid>
        </Grid>
      ) : (
        <ViewJobDataConnector
          destination={destination}
          datasetName={getDataset(destination?.datasetId)?.name ?? ""}
        />
      )}
    </Paper>
  );
};

export default JobDestination;
