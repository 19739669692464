import React, { useMemo } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from "@material-ui/core";
import { UseQueryResult } from "@tanstack/react-query";
import { map, get, isEmpty, find } from "lodash";
import { useLocation } from "react-router-dom";

import Text from "components/Widget/Text";
import { Model } from "hooks/api/projects/useGetAllProjectModels";

interface IProps extends Omit<SelectProps, "onChange"> {
  projectId: string;
  allModelResponse: UseQueryResult<Model[]>;
  numOfDatasets?: number;
  onChange: (id: string, name: string, targetCol: string, isTimeSeriesRecipeModel: boolean) => void;
}

export const MODELS = "models";

const SelectModel: React.FC<IProps> = (props) => {
  const { projectId, value, numOfDatasets, allModelResponse, onChange, ...rest } = props;
  const { state } = useLocation();

  const { data } = allModelResponse;
  const options = useMemo(() => {
    const models = data ?? get(state, MODELS) ?? [];

    return map(models, ({ id, displayName }) => (
      <MenuItem id={id} key={id} value={id}>
        <Text value={displayName} />
      </MenuItem>
    ));
  }, [state, data]);

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const record = find(data ?? get(state, MODELS), { id: e.target.value });
    onChange(
      e.target.value as string,
      record?.name,
      record?.targetCol,
      record?.isTimeSeriesParentRecipe
    );
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel test-id="model-label">Model *</InputLabel>
      <Select
        id="model-id"
        label="Model *"
        test-id="model-id"
        name="Model"
        style={{ height: "40px" }}
        value={isEmpty(options) ? "" : value}
        required
        onChange={handleChange}
        {...rest}>
        {options}
      </Select>
      {!!numOfDatasets && (
        <FormHelperText
          style={{
            margin: "6px auto",
            fontStyle: "italic"
          }}>{`This model needs ${numOfDatasets} dataset(s) to be selected`}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectModel;
