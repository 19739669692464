import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
  opacity?: number;
};

const AutoLayoutIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  color = "currentColor",
  opacity = 1
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill="none">
    <g opacity={opacity}>
      <rect x="6.35" y="2.35" width="3.3" height="3.3" rx="0.65" stroke={color} strokeWidth="0.7" />
      <rect
        x="0.35"
        y="10.35"
        width="3.3"
        height="3.3"
        rx="0.65"
        stroke={color}
        strokeWidth="0.7"
      />
      <rect
        x="6.35"
        y="10.35"
        width="3.3"
        height="3.3"
        rx="0.65"
        stroke={color}
        strokeWidth="0.7"
      />
      <rect
        x="12.35"
        y="10.35"
        width="3.3"
        height="3.3"
        rx="0.65"
        stroke={color}
        strokeWidth="0.7"
      />
      <path d="M2 9V7.5H14V9" stroke={color} strokeWidth="0.7" strokeLinecap="round" />
      <path d="M8 6V8.5" stroke={color} strokeWidth="0.7" strokeLinecap="round" />
    </g>
  </svg>
);

export default AutoLayoutIcon;
