import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "utils/AxiosClient";
import { AIChatRequestDto } from "openapi/Models/aichat-request-dto";
import { useAIGuideStore } from "src/store/store";
import { AI_GUIDE_MESSAGES_KEY } from "./useGetAIGuideMessages";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";

type IProps = { request: AIChatRequestDto; messageId: string };

export const useRetryAIGuideChatMutation = (threadId: string) => {
  const [setGeneratingState, setIsGenerated] = useAIGuideStore((state) => [
    state.setGeneratingState,
    state.setIsGenerated
  ]);
  const queryClient = useQueryClient();
  return useMutation<any, unknown, IProps, AIChatRequestDto>({
    mutationFn: async ({ messageId, request }: IProps) => {
      const { query } = request;
      const currTime = Date.now();
      const currTimestamp = new Date(currTime).toISOString();
      setGeneratingState(threadId, query!, currTimestamp, false, true);
      const response = await api.fetchResponse(() => api.AiControllerApi.chat1(threadId, request));
      await api.fetchResponse(() => api.AiControllerApi.deleteMessage2(threadId, messageId));
      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, threadId],
        (prevData: AIChatResponseDto[] | undefined) => {
          const filteredMsgs = (prevData || [])?.filter(
            (response) => response.askAIMessageId !== messageId
          );
          return [...filteredMsgs, response];
        }
      );
      return response;
    },
    onSuccess: () => {
      setIsGenerated(threadId);
    },
    onError: () => {
      setIsGenerated(threadId);
    }
  });
};
