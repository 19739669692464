import React from "react";
import clsx from "clsx";

import { Dialog, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import SimpleModalTitle from "./SimpleModalTitle";

import styles from "./SimpleModal.module.scss";

const SimpleModal = ({ children, className, open, onClose, title, hideCloseButton }: $TSFixMe) => {
  return (
    <Dialog className={clsx(className, styles.simpleModal)} open={open} onClose={onClose}>
      {!hideCloseButton && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="primary"
          style={{
            position: "absolute",
            right: 8,
            top: 8
          }}>
          <Close />
        </IconButton>
      )}
      {title && <SimpleModalTitle>{title}</SimpleModalTitle>}
      {children}
    </Dialog>
  );
};

export default SimpleModal;
