import React, { useEffect, useState, useMemo } from "react";

import {
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import Text from "src/components/Widget/Text";
import { useEnvironmentsStore } from "../../../../../store/store";
import { useForm } from "../../../../../utils/useForm";
import { Field, CodeEditor } from "../../../../../components";
import {
  EnvironmentType,
  EnvironmentsTypes
} from "../../../../../constants/environments.constants";
import { createEnvironment } from "../../../../../api";
import styles from "../../Environments.module.scss";
import { Environment } from "../../Environments";
import { validateNameField } from "src/utils/formFieldUtils";
import { toastWrapper } from "src/utils/toastWrapper";
import EnvironmentTypeConfig from "../EnvironmentTypeConfig";
import { Modal } from "src/components/custom";
import Space from "src/pages/common/Space";
import { InfoOutlined } from "@material-ui/icons";
import ConfirmCloseWindow from "./ConfirmCloseWindow";
import { compact, find, get, isEmpty, map, size } from "lodash";
import _ from "lodash";
import { EnvironmentsHelperText, PackagesInfoLines } from "../../utils/Environments.constants";

const useStyles = makeStyles((theme) => ({
  dropdown: {
    maxHeight: 200
  },
  diskSpaceInfo: {
    margin: 0,
    padding: 0,
    border: "none",
    "& [class^='MuiAlert-icon']": {
      marginRight: theme.spacing(1)
    },
    "& [class^='MuiAlert-message']": {
      paddingTop: theme.spacing(0.75)
    }
  }
}));

type Props = {
  open?: boolean;
  isEnvironmentsTypesFetching?: boolean;
  environmentsTypes?: $TSFixMe[];
  refetch: () => void;
  onClose?: Function;
  watchEnvironment?: $TSFixMeFunction;
};

const initialFormValues = {
  name: "",
  description: "",
  envType: "",
  code: "",
  cores: "",
  memory: "",
  diskSpace: ""
};

const errors = {
  nameExist: "The environment name already exists"
};

export const CreateEnvironment = ({
  open,
  isEnvironmentsTypesFetching,
  environmentsTypes,
  refetch,
  onClose = () => null,
  watchEnvironment
}: Props) => {
  const classes = useStyles();

  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  const { values, setValues, handleInputChange, resetForm } = useForm(initialFormValues);
  const [showNameError, setShowNameError] = useState<[boolean, string]>([false, ""]);
  const [typeOption, setTypeOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customConfigErrors, setCustomConfigErrors] = useState(["", "", ""]);

  const environmentList = useEnvironmentsStore((state) => state.environmentList);

  const isDirty = useEnvironmentsStore((state) => state.isDirty);
  const toggleDirty = useEnvironmentsStore((state) => state.toggleDirty);

  const handleCloseModal = () => {
    toggleDirty(false);
    setShowConfirmScreen(false);
    onClose();
  };

  const handleCancelClose = () => {
    setShowConfirmScreen(false);
  };

  const customEnvLimit = useMemo(
    () => find(environmentsTypes, (item: any) => item.name === EnvironmentsTypes.Custom),
    [environmentsTypes]
  );

  const validateCustomConfig = (
    fieldIndex: number,
    fieldName: string,
    value: string,
    limit: number
  ) => {
    let isValid = true;
    let errorMessage = "";

    if (value === "") {
      errorMessage = `The ${fieldName} cannot be blank.`;
      isValid = false;
    } else {
      const numericValue = _.toNumber(value);

      if (!Number.isInteger(numericValue)) {
        errorMessage = `The ${fieldName} must be an integer.`;
        isValid = false;
      } else if (numericValue <= 0 || numericValue > limit) {
        errorMessage = `The  ${fieldName} needs to be between 1 and ${limit}.`;
        isValid = false;
      }
    }

    setCustomConfigErrors((prev) => {
      prev[fieldIndex] = errorMessage;
      return [...prev];
    });

    return isValid;
  };

  const validFields = () => {
    let isValid = true;
    const { isValid: isNameValid, error } = validateNameField({
      fieldName: (values as $TSFixMe)?.name,
      fieldNameLabel: `environment name`
    });
    if (!isNameValid && error) {
      setShowNameError([true, error]);
      isValid = false;
    } else if (nameExist()) {
      setShowNameError([true, errors.nameExist]);
      isValid = false;
    } else {
      setShowNameError([false, ""]);
    }
    if ((values as $TSFixMe)?.envType === EnvironmentsTypes.Custom) {
      const validCores = validateCustomConfig(
        0,
        "Cores",
        (values as $TSFixMe)?.cores,
        get(customEnvLimit, "cores")
      );

      const validMemory = validateCustomConfig(
        1,
        "Memory",
        (values as $TSFixMe)?.memory,
        get(customEnvLimit, "memInGbs")
      );
      const validDiskSpace = validateCustomConfig(
        2,
        " Disk Space",
        (values as $TSFixMe)?.diskSpace,
        get(customEnvLimit, "diskInGbs")
      );

      isValid = validCores && validMemory && validDiskSpace;
    }
    return isValid;
  };

  useEffect(() => {
    showNameError[0] && setShowNameError([false, ""]);
  }, [open]); // eslint-disable-line

  const nameExist = () => {
    return environmentList.some((env) => {
      return (env as Environment).name.toLowerCase() === (values as $TSFixMe).name.toLowerCase();
    });
  };

  const handleOnClose = () => {
    if ((environmentsTypes || [])?.length === 0) {
      return;
    }

    resetForm();
    onClose();
  };

  const handleSubmit = async () => {
    try {
      let valuesToSend: $TSFixMe;
      const typeSelected = (environmentsTypes || [])?.find(
        (type: EnvironmentType) => type.name === (values as $TSFixMe).envType
      );
      if (validFields()) {
        valuesToSend = {
          name: (values as $TSFixMe).name.trim(),
          description: (values as $TSFixMe).description?.trim(),
          requirements: (values as $TSFixMe).code,
          envType: (values as $TSFixMe).envType
        };

        if ((values as $TSFixMe)?.envType === EnvironmentsTypes.Custom) {
          valuesToSend.cores = (values as $TSFixMe)?.cores;
          valuesToSend.memInMbs = (values as $TSFixMe)?.memory * 1024;
          valuesToSend.diskInGbs = (values as $TSFixMe)?.diskSpace;
        } else {
          valuesToSend.cores = typeSelected?.cores;
          valuesToSend.memInMbs = typeSelected?.memInMbs;
          valuesToSend.diskInGbs = typeSelected?.diskInGbs;
        }

        setIsLoading(true);

        const createdEnvironment = await createEnvironment(valuesToSend);

        toastWrapper({
          type: "success",
          content: "Environment created successfully!"
        });

        await refetch();

        toggleDirty(false);
        onClose();

        //@ts-ignore
        watchEnvironment(createdEnvironment);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event: $TSFixMe, id?: string) => {
    if (id === "name") {
      const { isValid: isNameValid, error } = validateNameField({
        fieldName: event.target.value,
        fieldNameLabel: `environment name`
      });
      if (!isNameValid && error) {
        setShowNameError([true, error]);
      } else {
        setShowNameError([false, ""]);
      }
    }
    if (id === "cores") {
      validateCustomConfig(0, "Cores", event.target.value, get(customEnvLimit, "cores"));
    }
    if (id === "memory") {
      validateCustomConfig(1, "Memory", event.target.value, get(customEnvLimit, "memInGbs"));
    }
    if (id === "diskSpace") {
      validateCustomConfig(2, " Disk Space", event.target.value, get(customEnvLimit, "diskInGbs"));
    }

    if (id === "envType" && event?.target?.value !== EnvironmentsTypes.Custom) {
      setTimeout(() => {
        setValues(() => ({
          ...values,
          envType: event?.target?.value,
          cores: "",
          memory: "",
          diskSpace: ""
        }));
      }, 10);
    }

    handleInputChange(event);
    toggleDirty(true);
  };

  const handleChangeCodeEditor = (name: string, value: $TSFixMe) => {
    const newValue = { target: { name, value } };
    handleInputChange(newValue);
    toggleDirty(true);
  };

  const handleCloseAttempt = () => {
    if (isDirty) {
      return setShowConfirmScreen(true);
    }

    handleOnClose();
  };

  const disabledCreateActionMessage = useMemo(() => {
    if (!!isEnvironmentsTypesFetching) {
      return "Please wait. Fetching environment types.";
    }

    let isInvalidFields = false;
    isInvalidFields = isInvalidFields || !(values as $TSFixMe)?.name?.trim();
    isInvalidFields = isInvalidFields || !typeOption;

    if ((values as $TSFixMe)?.envType === EnvironmentsTypes.Custom) {
      isInvalidFields = isInvalidFields || !(values as $TSFixMe)?.cores;
      isInvalidFields = isInvalidFields || !(values as $TSFixMe)?.memory;
      isInvalidFields = isInvalidFields || !(values as $TSFixMe)?.diskSpace;
    }

    if (!!isInvalidFields) {
      return "Please enter all mandatory fields to proceed with this action";
    }

    return "";
  }, [isEnvironmentsTypesFetching, values, typeOption]);

  return (
    <>
      {showConfirmScreen && isDirty && (
        <ConfirmCloseWindow onConfirm={handleCloseModal} onCancel={handleCancelClose} />
      )}

      <Modal
        open
        size="md"
        title="Create a new environment"
        onClose={handleCloseAttempt}
        onSubmit={handleSubmit}
        submitLabel="Create environment"
        isSubmitting={isLoading}
        isSubmitDisabled={
          !!disabledCreateActionMessage || showNameError[0] || !isEmpty(compact(customConfigErrors))
        }
        submitActionInfo={disabledCreateActionMessage}
        submitActionTooltipProps={{
          placement: "top-end"
        }}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container className={styles.formSection}>
              <Grid item xs={12}>
                <Field
                  id="name"
                  label="Environment Name"
                  value={(values as $TSFixMe).name}
                  onChange={(event: $TSFixMe) => {
                    handleChange(event, "name");
                  }}
                  error={showNameError[0]}
                  helperText={showNameError[0] && showNameError[1]}
                  required
                  variant="outlined"
                />
                <Typography variant="caption" color="textSecondary" data-testid="envNameInfo">
                  The name shown in the list of environments.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  id="description"
                  label="Environment Description"
                  onChange={(event: $TSFixMe) => {
                    handleChange(event);
                  }}
                  multiline
                  minRows={4}
                  maxRows={4}
                  value={(values as $TSFixMe).description}
                  variant="outlined"
                />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  data-testid="envDescriptionInfo">
                  Provide additional information about this environment.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  id="envType"
                  required
                  select
                  label="Environment Type"
                  onChange={(event: $TSFixMe) => {
                    handleChange(event, "envType");
                  }}
                  minRows={4}
                  maxRows={4}
                  value={(values as $TSFixMe).envType}
                  variant="outlined"
                  SelectProps={{ MenuProps: { classes: { paper: `${classes.dropdown} ` } } }}>
                  {(environmentsTypes || [])?.map((typeOption: $TSFixMe) => {
                    return (
                      <MenuItem
                        key={typeOption.name}
                        value={typeOption.name}
                        onClick={() => setTypeOption(typeOption)}>
                        <Text value={typeOption.name} />
                      </MenuItem>
                    );
                  })}
                </Field>
                {(values as $TSFixMe).envType !== EnvironmentsTypes.Custom && typeOption ? (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    data-testid="envDiskSpaceInfo">
                    <EnvironmentTypeConfig
                      cores={(typeOption as EnvironmentType).cores}
                      memInMbs={(typeOption as EnvironmentType).memInMbs}
                      diskInGbs={(typeOption as EnvironmentType).diskInGbs}
                    />
                  </Typography>
                ) : null}
              </Grid>
              {(values as $TSFixMe).envType === EnvironmentsTypes.Custom && (
                <>
                  <Grid container justifyContent="space-between">
                    <Grid item style={{ width: "47.5%" }}>
                      <Field
                        id="cores"
                        label="Cores"
                        value={(values as $TSFixMe).cores}
                        onChange={(event: $TSFixMe) => {
                          handleChange(event, "cores");
                        }}
                        error={!!customConfigErrors[0]}
                        helperText={customConfigErrors[0]}
                        type="number"
                        required
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item style={{ width: "47.5%" }}>
                      <Field
                        id="memory"
                        label="Memory"
                        value={(values as $TSFixMe).memory}
                        onChange={(event: $TSFixMe) => {
                          handleChange(event, "memory");
                        }}
                        InputProps={{
                          // limit: getCoreSet({ maxValue: get(customEnvLimit, "memInGbs") }),
                          endAdornment: <InputAdornment position="end">GB</InputAdornment>
                        }}
                        error={!!customConfigErrors[1]}
                        helperText={customConfigErrors[1]}
                        type="number"
                        required
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      id="diskSpace"
                      label="Disk Space"
                      value={(values as $TSFixMe).diskSpace}
                      onChange={(event: $TSFixMe) => {
                        handleChange(event, "diskSpace");
                      }}
                      InputProps={{
                        // limit: getCoreSet({ maxValue: get(customEnvLimit, "diskInGbs") }),
                        endAdornment: <InputAdornment position="end">GB</InputAdornment>
                      }}
                      error={!!customConfigErrors[2]}
                      helperText={customConfigErrors[2]}
                      type="number"
                      required
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Alert
                variant="outlined"
                severity="info"
                className={classes.diskSpaceInfo}
                icon={<InfoOutlinedIcon fontSize="small" color="action" />}>
                <Typography variant="caption" color="textSecondary" data-testid="envDiskSpaceInfo">
                  {EnvironmentsHelperText.DiskSpaceInfo}
                </Typography>
              </Alert>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ maxHeight: 280, gap: 18, paddingLeft: 20 }}>
            <Space>
              <Typography>Packages</Typography>
              <Tooltip
                arrow
                title={map(PackagesInfoLines, (line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < size(PackagesInfoLines) - 1 && <br />}
                  </React.Fragment>
                ))}>
                <InfoOutlined style={{ color: "#9e9e9e", fontSize: "16px" }} />
              </Tooltip>
            </Space>
            <Paper
              elevation={0}
              style={{
                border: "2px solid black",
                margin: 0,
                width: "100%",
                height: "100%"
              }}>
              <CodeEditor
                name="code"
                height="100%"
                value={(values as $TSFixMe).code}
                //@ts-expect-error
                width="100%"
                scriptType="SYSTEM"
                onChange={handleChangeCodeEditor}
                hideTestCode
              />
            </Paper>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
