import React from "react";
import _ from "lodash";
import { ClickAwayListener, Popper } from "@material-ui/core";
import { FixedSizeList as List } from "react-window";

import ColumnRow, { IColumnRowProps } from "./ColumnRow";

interface IProps {
  anchorElColumn: HTMLTextAreaElement | null;
  filteredSuggestions: string[];
  highlightedIndex: number | null;
  menuItemRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
  onSuggestionClick: (suggestion: string) => void;
  onClose: () => void;
}

const InputSuggestions: React.FC<IProps> = (props) => {
  const {
    anchorElColumn,
    highlightedIndex,
    menuItemRefs,
    filteredSuggestions,
    onClose,
    onSuggestionClick
  } = props;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        open={Boolean(anchorElColumn)}
        anchorEl={anchorElColumn}
        placement="top-start"
        style={{
          background: "white",
          maxHeight: "300px",
          overflowX: "auto",
          width: 225,
          zIndex: 100000,
          borderRadius: 12,
          boxShadow:
            "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
        }}>
        <List
          height={Math.min(300, 38 * filteredSuggestions.length + 1)}
          itemCount={filteredSuggestions.length}
          itemSize={38}
          itemData={
            {
              columns: filteredSuggestions,
              highlightedIndex,
              menuItemRefs,
              onSuggestionClick
            } as IColumnRowProps
          }
          width="100%">
          {ColumnRow}
        </List>
      </Popper>
    </ClickAwayListener>
  );
};

export default React.memo(InputSuggestions);
