import React, { useEffect, useRef, useState } from "react";
import _, { isString, map, toUpper } from "lodash";
import {
  TableContainer,
  TableHead,
  TableBody,
  Paper,
  Grid,
  Typography,
  Checkbox,
  Box
} from "@material-ui/core";
import {
  useSortBy,
  useTable,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useFilters,
  useResizeColumns,
  useColumnOrder
} from "react-table";
import { useVirtual } from "react-virtual";
import clsx from "clsx";

import OverflowTooltip from "../OverflowTooltip";
import Pagination from "./Pagination";
import { DefaultTableCell } from "./Cells/DefaultTableCell/DefaultTableCell";
import { StyledTableRow, StyledMaUTable, useStyles } from "./styling";
import { TableHeader } from "./TableHeader/TableHeader";
import { TableSettingsMenu } from "./TableSettingsMenu/TableSettingsMenu";

type Props = {
  columns: {
    id: string;
    accessor?: string | Function;
  }[];
  data: $TSFixMe[];
  secondaryData?: $TSFixMe[];
  onSort?: $TSFixMeFunction;
  size?: string;
  children?: $TSFixMe;
  noHeaders?: boolean;
  selectedRow?: number;
  flatStyle?: boolean;
  rowsPerPage?: number;
  emptyTableMessage?: string;
  sourceColumns?: $TSFixMe[];
  newColumns?: $TSFixMe[];
  customHeader?: boolean;
  isCellSortEnabled?: boolean;
  orderByDefault?: string;
  medium?: boolean;
  actionHeader?: {
    title: string;
    actions: React.ReactNode;
  };
  skipPageReset?: boolean;
  updateMyData?: $TSFixMeFunction;
  rowToBeEdited?: any;
  captionStyles?: $TSFixMe;
  filters?: any;
  isTheadSticky?: boolean;
  sortInverted?: boolean;
  isSelectable?: boolean;
  onSelectedRowsChange?: $TSFixMeFunction;
  onSelectedColumnChange?: (columnNames: string[]) => void;
  selectedRowIds?: $TSFixMe;
  getRowId?: $TSFixMeFunction;
  globalFilter?: $TSFixMe;
  shouldDisableInitialSelectedRows?: boolean;
  isLoading?: boolean;
  showPagination?: boolean;
  hideSettings?: boolean;
  inheritHeight?: boolean;
  hideCount?: boolean;
  fixedLayout?: boolean;
  absolutePagination?: boolean;
  unsorted?: boolean;
  showSample?: boolean;
  columnOptionsCustomRender?: React.ReactNode;
  maxHeight?: string;
  onRowClicked?: (rowData: any) => void;
  hiddenColumns?: string[];
};

export const Table = ({
  columns,
  data,
  secondaryData,
  size: initialSize,
  absolutePagination,
  children,
  noHeaders,
  selectedRow,
  hideCount,
  flatStyle,
  isTheadSticky = false,
  rowsPerPage = 50,
  showSample,
  emptyTableMessage = "",
  sourceColumns = [],
  newColumns = [],
  customHeader,
  medium = true,
  orderByDefault = "Created", // Has to match column id
  actionHeader,
  updateMyData,
  skipPageReset,
  rowToBeEdited,
  captionStyles = {},
  sortInverted,
  filters,
  isSelectable = false,
  selectedRowIds,
  getRowId,
  onSelectedRowsChange,
  onSelectedColumnChange,
  globalFilter,
  shouldDisableInitialSelectedRows = false,
  isLoading,
  showPagination = false,
  hideSettings = false,
  inheritHeight = false,
  fixedLayout = true,
  unsorted = false,
  maxHeight,
  columnOptionsCustomRender,
  onRowClicked,
  hiddenColumns
}: Props) => {
  const size = initialSize ?? (medium ? "medium" : "small");
  const classes = useStyles({ size, fixedLayout });

  const tableContainerRef = useRef(null);
  const [orderBy, setOrderBy] = useState(orderByDefault);
  const [highlightedRowIndex, setHighlightedRowIndex] = useState<number | void>();

  const columnsIdList: string[] = React.useMemo(
    () => columns.map((column) => column.id),
    [columns]
  );

  const selectProps = React.useMemo(
    () =>
      isSelectable
        ? [
            useRowSelect,
            (hooks: $TSFixMe) => {
              hooks?.visibleColumns?.push((columns: $TSFixMe) => [
                {
                  id: "selection",
                  width: 55,
                  minWidth: 55,
                  isSortable: false,
                  Header: ({ getToggleAllPageRowsSelectedProps }: $TSFixMe) => (
                    <Checkbox size={size} {...getToggleAllPageRowsSelectedProps()} />
                  ),
                  Cell: ({ row }: $TSFixMe) => {
                    const rowIds = Object.keys(selectedRowIds);
                    const isDisabled = shouldDisableInitialSelectedRows && rowIds?.includes(row.id);
                    return (
                      // To overwrite textOverflow introduced in body cell
                      <Box style={{ textOverflow: "clip" }}>
                        <Checkbox
                          disabled={isDisabled}
                          color="primary"
                          size={size}
                          {...row.getToggleRowSelectedProps()}
                        />
                      </Box>
                    );
                  }
                },
                ...columns
              ]);
            }
          ]
        : [],
    [isSelectable, size]
  );

  const tableColumns = React.useMemo(() => {
    if (isLoading) {
      const initialColArray: $TSFixMe = [];
      initialColArray.length = 7;
      initialColArray.fill({});
      return initialColArray.map((__: {}, index: number) => {
        return {
          id: index,
          accessor: `init_col_${index}`
        };
      });
    }
    return columns;
  }, [columns, isLoading]);

  const tableData = React.useMemo(() => {
    if (isLoading) {
      const placeholderArray: any[] = [];
      placeholderArray.length = 10;
      placeholderArray.fill({});
      return placeholderArray.map(() =>
        columns.reduce((acc, curr) => {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          acc[curr.accessor] = "";
          return acc;
        }, {})
      );
    }
    // 500 is maximum rows allowed
    return data?.slice(0, 500);
  }, [isLoading, data, columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    visibleColumns,
    setHiddenColumns,
    setGlobalFilter,
    setAllFilters,
    setColumnOrder,
    state: { pageIndex, selectedRowIds: selectedRowIdsState, columnOrder }
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      autoResetPage: !skipPageReset || false,
      updateMyData,
      autoResetSelectedRows: false,
      getRowId,
      sortTypes: {
        alphanumeric: (row1: $TSFixMe, row2: $TSFixMe, columnName: string) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];

          // Check if both are numbers (string or actual number)
          const isNumberRowOne = !isNaN(parseFloat(rowOneColumn)) && isFinite(rowOneColumn);
          const isNumberRowTwo = !isNaN(parseFloat(rowTwoColumn)) && isFinite(rowTwoColumn);

          if (isNumberRowOne && isNumberRowTwo) {
            // Compare as numbers
            return parseFloat(rowOneColumn) - parseFloat(rowTwoColumn);
          }

          // Check if both are strings
          if (!!isString(rowOneColumn) && !!isString(rowTwoColumn)) {
            return toUpper(rowOneColumn) > toUpper(rowTwoColumn) ? 1 : -1;
          }

          // Fallback to string comparison if they are not numbers
          return rowOneColumn > rowTwoColumn ? 1 : -1;
        }
      },
      initialState: {
        pageSize: showPagination ? rowsPerPage : 500,
        columnOrder: [...columnsIdList],
        hiddenColumns: hiddenColumns || [],
        sortBy: unsorted ? [] : [{ id: orderBy, desc: true }],
        ...(filters ? { filters } : {}),
        ...(selectedRowIds ? { selectedRowIds } : {}),
        ...(globalFilter ? { globalFilter } : {})
      },
      autoResetHiddenColumns: false,
      autoResetSortBy: false
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useColumnOrder,
    ...selectProps
  );

  const handleSort = (value: $TSFixMe) => {
    setOrderBy(value);
    rowVirtualizer.scrollToIndex(0);
  };

  useEffect(() => {
    setColumnOrder(columnsIdList);
  }, [columnsIdList]);

  useEffect(() => {
    isSelectable && onSelectedRowsChange?.(selectedRowIdsState);
  }, [isSelectable, selectedRowIdsState]);

  useEffect(() => {
    setGlobalFilter?.(globalFilter && globalFilter !== "" ? globalFilter : undefined);
  }, [globalFilter]);

  useEffect(() => {
    filters && setAllFilters(filters);
  }, [filters, setAllFilters]);

  const handleCellMenuClick = (e: any, rowIndex: number) => {
    e.stopPropagation();
    if (e?.target?.id === "cellMenuIcon" || e?.target?.parentNode?.id === "cellMenuIcon") {
      setHighlightedRowIndex(rowIndex);
    } else {
      setHighlightedRowIndex();
    }
  };

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: page?.length,
    overscan: 10
  });

  const paddingTop =
    rowVirtualizer?.virtualItems?.length > 0 ? rowVirtualizer?.virtualItems?.[0]?.start || 0 : 0;
  const paddingBottom =
    rowVirtualizer?.virtualItems.length > 0
      ? rowVirtualizer?.totalSize -
        (rowVirtualizer?.virtualItems?.[rowVirtualizer?.virtualItems.length - 1]?.end || 0)
      : 0;

  return (
    <div className={clsx(inheritHeight && classes.inheritHeightContainer)}>
      {!hideCount && (
        <Typography
          className={
            classes.recordCount
          }>{`${showSample ? "Sample" : "Total"} ${_.size(data) ?? 0} records`}</Typography>
      )}
      <TableContainer
        component={Paper}
        className={clsx(
          classes.relativeContainer,
          flatStyle ? classes.tableFlatContainer : classes.tableContainer,
          inheritHeight && classes.inheritHeightContainer
        )}>
        {!isLoading &&
          !hideSettings &&
          !actionHeader &&
          !noHeaders &&
          columnOrder?.length !== 0 && (
            <TableSettingsMenu
              originalColumns={map(tableColumns, "id")}
              visibleColumns={map(visibleColumns, "id")}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
              onColumnChange={onSelectedColumnChange}
            />
          )}
        <Grid
          ref={tableContainerRef}
          className={classes.tableDivContainer}
          style={{
            maxHeight: maxHeight ? maxHeight : inheritHeight ? "inherit" : "100%",
            maxWidth: "100vw"
          }}>
          <StyledMaUTable
            style={{ borderSpacing: "revert" }}
            stickyHeader
            {...getTableProps()}
            data-testid="oldCustomTable">
            {children && <caption style={captionStyles}>{children}</caption>}
            {actionHeader && (
              <Grid className={classes.actionHeader}>
                <Grid container direction="row" alignItems="center">
                  <Typography
                    component="div"
                    variant={size === "medium" ? "h6" : "body1"}
                    className={classes.maxWidth}>
                    {actionHeader.title}
                  </Typography>
                  <Grid>{actionHeader.actions}</Grid>
                </Grid>
              </Grid>
            )}
            {!noHeaders && (
              <TableHead>
                {headerGroups?.map((headerGroup: $TSFixMe) => {
                  const { key: groupKey, ...getHeaderGroupProps } =
                    headerGroup?.getHeaderGroupProps();
                  return (
                    <StyledTableRow key={groupKey} {...getHeaderGroupProps}>
                      {/* First element of tableData should always be visible */}
                      <TableHeader
                        key={headerGroup?.headers?.[0]?.id}
                        index={0}
                        size={size}
                        column={headerGroup?.headers?.[0]}
                        orderBy={orderBy}
                        headerSortActive={handleSort}
                        className={clsx("headerCell", {
                          customHeader: customHeader,
                          showHeaderPadding: sourceColumns?.length > 0,
                          sourceColumn: sourceColumns?.includes(0),
                          newColumn: newColumns?.includes(0)
                        })}
                        sortInverted={sortInverted}
                        isLoading={isLoading}
                        columnOptionsCustomRender={columnOptionsCustomRender}
                        updateMyData={updateMyData}
                      />
                      {headerGroup?.headers?.map((column: $TSFixMe, index: number) => {
                        if (index === 0) {
                          return;
                        }
                        const customClassName = clsx("headerCell", {
                          headerSticky: isTheadSticky,
                          customHeader: customHeader,
                          showHeaderPadding: sourceColumns?.length > 0,
                          sourceColumn: sourceColumns?.includes(index),
                          newColumn: newColumns?.includes(index)
                        });
                        return (
                          <TableHeader
                            key={column.id}
                            index={index}
                            size={size}
                            column={column}
                            orderBy={orderBy}
                            headerSortActive={handleSort}
                            className={customClassName}
                            sortInverted={sortInverted}
                            isLoading={isLoading}
                            columnOptionsCustomRender={columnOptionsCustomRender}
                            updateMyData={updateMyData}
                          />
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableHead>
            )}
            <TableBody {...getTableBodyProps()}>
              {visibleColumns?.length > 0 &&
                paddingTop > 0 &&
                rowVirtualizer?.virtualItems?.length !== rows?.length && (
                  <tr>
                    <td
                      colSpan={visibleColumns?.length}
                      className={clsx(
                        classes.shimmerTdCell,
                        classes.paddingCell,
                        classes.paddingTopCell
                      )}
                      style={{ height: `${paddingTop}px` }}>
                      Loading more rows...
                    </td>
                  </tr>
                )}
              {rowVirtualizer?.virtualItems?.map((virtualRow: $TSFixMe, rowIndex: number) => {
                const row = page?.[virtualRow.index];
                prepareRow(row);
                const { key: rowKey, ...rowProps } = row?.getRowProps() || {};
                return (
                  <StyledTableRow
                    key={rowKey}
                    {...rowProps}
                    selected={rowIndex === selectedRow}
                    onClick={(e) => {
                      if (onRowClicked) {
                        onRowClicked(row);
                      }
                      handleCellMenuClick(e, rowIndex);
                    }}
                    {...(highlightedRowIndex === rowIndex && { className: classes.highlightRow })}>
                    {/* First element of tableData should always be visible */}
                    {isLoading ? (
                      <td
                        className={classes.shimmerTdCell}
                        key={row?.cells?.[0]?.getCellProps()?.key}
                      />
                    ) : null}
                    {row?.cells?.map((cell: $TSFixMe, index: number) => {
                      const { key: cellKey, ...getCellProps } =
                        cell?.getCellProps({
                          style: {
                            minWidth: cell?.column?.minWidth,
                            width: cell?.column?.width
                          }
                        }) || {};

                      if (isLoading) {
                        return <td className={classes.shimmerTdCell} key={cellKey}></td>;
                      }
                      if (index === 0) {
                        return (
                          <DefaultTableCell
                            //@ts-expect-error
                            key={cellKey}
                            cell={row?.cells?.[0]}
                            className={clsx("bodyCell", {
                              sourceColumn: sourceColumns?.includes(0),
                              newColumn: newColumns?.includes(0)
                            })}
                            rowToBeEdited={rowToBeEdited}
                            updateMyData={updateMyData}
                            size={size}
                            secondaryData={secondaryData}
                            {...row?.cells?.[0]?.getCellProps()}>
                            {row?.cells?.[0]?.column?.id === "selection" ? (
                              row?.cells?.[0].render("Cell")
                            ) : //@ts-expect-error
                            row?.cells?.[0]?.column?.isTooltip ?? true ? (
                              <OverflowTooltip value={row?.cells?.[0]?.render("Cell")} />
                            ) : (
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}>
                                {row?.cells?.[0]?.render("Cell")}
                              </div>
                            )}
                          </DefaultTableCell>
                        );
                      }
                      const className = clsx("bodyCell", {
                        sourceColumn: sourceColumns?.includes(index),
                        newColumn: newColumns?.includes(index),
                        [classes.shimmerTdCell]:
                          // Below condition only applies to ViewDataDataTable data, since it's specially parsed
                          cell?.column?.name === cell?.column?.id &&
                          cell?.row?.original?.[cell?.column?.name] === undefined
                      });
                      const isTooltip = cell?.column?.isTooltip ?? true;
                      const shouldSkipOverflow = cell?.column?.id === "selection";
                      return (
                        <DefaultTableCell
                          key={cellKey}
                          cell={cell}
                          className={className}
                          rowToBeEdited={rowToBeEdited}
                          updateMyData={updateMyData}
                          size={size}
                          secondaryData={secondaryData}
                          {...getCellProps}>
                          {shouldSkipOverflow ? (
                            cell.render("Cell")
                          ) : isTooltip ? (
                            <OverflowTooltip value={cell?.render("Cell")} />
                          ) : (
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}>
                              {cell?.render("Cell")}
                            </div>
                          )}
                        </DefaultTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
              {visibleColumns?.length > 0 &&
                paddingBottom > 0 &&
                rowVirtualizer?.virtualItems?.length !== rows?.length && (
                  <tr>
                    <td
                      colSpan={visibleColumns?.length}
                      className={clsx(
                        classes.shimmerTdCell,
                        classes.paddingCell,
                        classes.paddingBottomCell
                      )}
                      style={{ height: `${paddingBottom}px` }}>
                      Loading more rows...
                    </td>
                  </tr>
                )}
            </TableBody>
          </StyledMaUTable>
        </Grid>
        {showPagination
          ? page?.length === 0
          : rows?.length === 0 &&
            emptyTableMessage && <span className={classes.emptyMessage}>{emptyTableMessage}</span>}
        {visibleColumns?.length === 0 && (
          <span className={classes.emptyMessage}>No columns selected</span>
        )}
        {showPagination && visibleColumns?.length > 0 && rows?.length > rowsPerPage && (
          <Pagination
            pageOptions={pageOptions}
            pageCount={pageCount}
            actualPage={pageIndex}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            previousPage={previousPage}
            gotoPage={gotoPage}
            absolutePosition={absolutePagination}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default React.memo(Table);
