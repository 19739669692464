import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import { includes } from "lodash";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { ExpandLess, ExpandMore, InfoOutlined, Search as SearchIcon } from "@material-ui/icons";

import { useAutoMLRecipeContext } from "../../AutoMLRecipeContext/useAutoMLRecipeContext";
import { MachineLearningTask } from "../../hooks/useGetAutoMLProblemTypes";
import { VirtualizedAutocompleteWithChips } from "src/components/Autocomplete/VirtualizedAutocompleteWithChips";

import _ from "lodash";
import { Spinner } from "src/components";

const useStyles = makeStyles({
  title: {
    fontSize: "14px",
    fontWeight: 400
  },
  freezeColBox: {
    padding: "4px 0px",
    gap: "4px"
  },
  tooltip: {
    paddingLeft: "8px",
    display: "flex",
    paddingTop: "2px"
  },
  advancedInputsContainer: {
    padding: "0px 16px 16px 16px",
    flexWrap: "nowrap",
    overflow: "auto",
    maxHeight: "100%",
    overflowX: "hidden",
    zIndex: 1000
  },
  header: {
    padding: "0px 16px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    background: "#d9d9d933"
  },
  collapsableHeader: {
    cursor: "pointer",
    justifyContent: "space-between"
  },
  helperText: {
    margin: "4px 0px"
  }
});

interface IProps {
  freezeCol: string;
  handleInputChange: any;
}

export const AutoMLAdvancedInputs: React.FC<PropsWithChildren<IProps>> = ({
  freezeCol,
  handleInputChange,
  children
}) => {
  const classes = useStyles();
  const { allColumns, numericColumns, problemType, columnsLoading } = useAutoMLRecipeContext();

  const curValue = freezeCol ? freezeCol?.split(",") : [];
  const [isExpanded, setIsExpanded] = useState(curValue?.length !== 0);

  const handleToggle = () => {
    setIsExpanded((expanded) => !expanded);
  };

  const onlyNumericColumns = useMemo(
    () =>
      includes(
        [MachineLearningTask.CLUSTERING, MachineLearningTask.ANOMALY_DETECTION],
        problemType
      ),
    [problemType]
  );

  const filteredColumns = onlyNumericColumns ? numericColumns : allColumns;
  const columnOptions = useMemo(
    () =>
      filteredColumns?.map((column: any) => ({
        label: column.name,
        value: column.name
      })) || [],
    [filteredColumns]
  );

  const curOption = useMemo(
    () => columnOptions?.filter((opt: any) => curValue?.includes(opt.label)) || [],
    [columnOptions, curValue]
  );

  return (
    <Grid container direction="column" wrap="nowrap">
      <Box className={clsx(classes.header, classes.collapsableHeader)} onClick={handleToggle}>
        <Typography variant="subtitle1">Advanced Options</Typography>
        <IconButton size="small">{isExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </Box>

      {isExpanded && (
        <Grid container direction="column" className={classes.advancedInputsContainer}>
          <Grid container direction="column" className={classes.freezeColBox}>
            {children ? (
              children
            ) : (
              <>
                <Grid container direction="row" wrap="nowrap">
                  <Typography className={classes.title}>Freeze Columns</Typography>
                  <Grid className={classes.tooltip}>
                    <Tooltip
                      arrow
                      title={`Freeze Columns ensures that these columns are not manipulated or dropped as part of the model building process and are used as is. Select columns which you know are definitely influential for a given model and should always be considered.`}>
                      <InfoOutlined style={{ color: "#9e9e9e", fontSize: "16px" }} />
                    </Tooltip>
                  </Grid>
                </Grid>
                <VirtualizedAutocompleteWithChips
                  options={columnOptions}
                  loading={columnsLoading}
                  loadingText={<Spinner padding={5} size={24} />}
                  value={curOption}
                  onChange={(values: $TSFixMe) => {
                    handleInputChange({
                      target: {
                        value: values?.map((value: any) => value.value).join(","),
                        name: "freeze_col"
                      }
                    });
                  }}
                  disableCloseOnSelect
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    classes: {
                      helperText: classes.helperText
                    },
                    type: "search"
                  }}
                  placeholder={`Search by column name.`}
                  disabled={columnOptions?.length === 0}
                  fullWidth
                  textFieldProps={{
                    helperText: onlyNumericColumns
                      ? `Only numeric columns are allowed for this problem type.`
                      : null
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
