import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Model, ModelsResponse } from "src/types";
import { getModels } from "src/api";

type ModelsProps = {
  scenarioId?: string;
  jobRunId?: string;
};

export const QUERY_KEY_MODELS = "models";
export const useGetModels = (props: ModelsProps = {}, options?: UseQueryOptions<Model[]>) => {
  const { scenarioId, jobRunId } = props;

  return useQuery({
    queryKey: [QUERY_KEY_MODELS],
    queryFn: async () => {
      const response: ModelsResponse = await getModels({ scenarioId, jobRunId });
      return response.models;
    },
    ...options
  });
};
