/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PredictLogDto
 */
export interface PredictLogDto {
    /**
     * 
     * @type {string}
     * @memberof PredictLogDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PredictLogDto
     */
    'startTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof PredictLogDto
     */
    'endTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof PredictLogDto
     */
    'status'?: PredictLogDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PredictLogDto
     */
    'request'?: string;
    /**
     * 
     * @type {string}
     * @memberof PredictLogDto
     */
    'response'?: string;
    /**
     * 
     * @type {string}
     * @memberof PredictLogDto
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof PredictLogDto
     */
    'version'?: PredictLogDtoVersionEnum;
}

export const PredictLogDtoStatusEnum = {
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type PredictLogDtoStatusEnum = typeof PredictLogDtoStatusEnum[keyof typeof PredictLogDtoStatusEnum];
export const PredictLogDtoVersionEnum = {
    V1: 'V1'
} as const;

export type PredictLogDtoVersionEnum = typeof PredictLogDtoVersionEnum[keyof typeof PredictLogDtoVersionEnum];


