import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { formTableCells, type InputColumn } from "src/components/Table/TableUtils";
import { Table } from "src/components";

const useStyles = makeStyles({
  licenseQuotas: {
    backgroundColor: "#ffffff",
    padding: "16px",
    gap: "16px",
    borderRadius: "4px"
  }
});

export const LicenseQuotas = ({ metadata }: { metadata: $TSFixMe }) => {
  const classes = useStyles();
  const quotas = metadata
    ? Object.keys(metadata).reduce(
        (acc: Array<{ component: string; quota: boolean }>, metadataKey) => {
          const metadataValue: boolean = metadata[metadataKey];
          return typeof metadataValue === "number" || typeof metadataValue === "bigint"
            ? [...acc, { component: metadataKey, quota: metadataValue }]
            : acc;
        },
        []
      )
    : [];

  const inputColumns: InputColumn[] = [
    {
      id: "Component",
      accessor: "component",
      Header: "Component",
      type: "string"
    },
    {
      id: "Quota",
      accessor: "quota",
      Header: "Quota",
      type: "string",
      fontWeight: 500
    }
  ];

  const columns = formTableCells({ inputColumns, size: "medium" });

  return quotas?.length && quotas?.length !== 0 ? (
    <Grid container direction="column" className={classes.licenseQuotas}>
      <Grid item>
        <Typography variant="h6" color="textPrimary">
          License Quotas
        </Typography>
      </Grid>
      <Grid item>
        <Table size="medium" columns={columns} data={quotas} />
      </Grid>
    </Grid>
  ) : (
    <React.Fragment />
  );
};
