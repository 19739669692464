import Papa from "papaparse";
import { get, find, startsWith, forOwn } from "lodash";

interface IOutput {
  path?: string;
  name?: string;
}

const getPredictionJobOutputpathAndName = (lastRunEntry: any): IOutput => {
  const output = find(
    get(lastRunEntry, "outEntityInfo"),
    ({ name }) => startsWith(name, "prediction_output_") || startsWith(name, "OutputDataset_")
  );

  return { name: output?.name, path: output?.path };
};

const getFailedRecipeName = (detailsResponse: any) => {
  let recipeName = "";
  const recipeRunInfo = get(detailsResponse, ["lastRunEntry", "recipeRunInfo"]);
  forOwn(recipeRunInfo, ({ status }, key) => {
    if (status === "FAILURE") {
      recipeName = key;
      return false;
    }
  });

  return recipeName;
};

const csvtoJson = (uploadedFile: string | File): Promise<string[][]> => {
  return new Promise((resolve, reject) => {
    Papa.parse(uploadedFile, {
      skipEmptyLines: true,
      preview: 500,
      complete: (results: any) => {
        resolve(results.data);
      },
      error: (err: any) => {
        reject(err);
      }
    });
  });
};

const getNumberSuffix = (number: number): string => {
  const suffixes: string[] = ["th", "st", "nd", "rd"];
  const remainder10: number = number % 10;
  const remainder100: number = number % 100;

  if (remainder10 === 1 && remainder100 !== 11) {
    return number + suffixes[1];
  } else if (remainder10 === 2 && remainder100 !== 12) {
    return number + suffixes[2];
  } else if (remainder10 === 3 && remainder100 !== 13) {
    return number + suffixes[3];
  } else {
    return number + suffixes[0];
  }
};

export { getPredictionJobOutputpathAndName, csvtoJson, getNumberSuffix, getFailedRecipeName };
