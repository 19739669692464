const TRACKING_INFO = {
  test: "Ry00NzNWUDJQM1BX",
  trial: "Ry1OS1pCQjIyUVMz",
  staging: "Ry1UMkxGNTFQNkVX"
};

const EVENTS = {
  menuItemClick: {
    action: "Navigation",
    category: "Sidebar Navigation"
  },
  internalNavigation: {
    action: "Navigation",
    category: "Internal navigation"
  },
  login: {
    action: "Button Click",
    category: "Login"
  },
  logout: {
    action: "Button Click",
    category: "Logout"
  },
  navigateToUserProfile: {
    action: "Button Click",
    category: "navigate to user profile"
  },
  navigateToServicesAgreement: {
    action: "Button Click",
    category: "navigate to services agreement"
  },
  tenantSwitch: {
    action: "Button Click",
    category: "Tenant switch"
  },
  navigateToTenantPage: {
    action: "Button Click",
    category: "Navigate To tenant page"
  },
  createTenant: {
    action: "Button Click",
    category: "Create new tenant"
  }
};
export { TRACKING_INFO, EVENTS };
