import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { ProjectAssociatesDetailDto } from "openapi/Models";
import api from "src/utils/AxiosClient";

const QUERY_KEY_PROJECT_DETAILS = "query-key-project-details";

export interface Record {
  id: string;
  name: string;
  displayName: string;
  predictionService: string;
}

const useProjectDetails = (
  id?: string,
  options?: UseQueryOptions<ProjectAssociatesDetailDto>
): UseQueryResult<ProjectAssociatesDetailDto> => {
  return useQuery({
    queryKey: [QUERY_KEY_PROJECT_DETAILS, id],
    queryFn: async () => {
      const data = await api.fetchResponse(async () => {
        if (!id) {
          throw "projectId is required!!";
        }
        return await api.ProjectsControllerV2Api.getProjectAssociates(id);
      });

      return data;
    },
    ...options,
    refetchOnMount: true
  });
};

export default useProjectDetails;
export { QUERY_KEY_PROJECT_DETAILS };
