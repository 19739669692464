import React from "react";
import _ from "lodash";
import { Grid, List, ListItem } from "@material-ui/core";

import { IVideo, videos } from "./VideoList";
import NewModal from "src/components/Modal/NewModal";
import OverflowTooltip from "src/components/OverflowTooltip";

interface IProps {
  video: IVideo;
  open: boolean;
  onClose: () => void;
  onSelectVideo: (video: IVideo) => void;
}

const VideoModal: React.FC<IProps> = (props) => {
  const { video, open, onClose, onSelectVideo } = props;

  return (
    <NewModal
      header={
        <Grid container>
          <Grid item xs={8}>
            {video.title}
          </Grid>
          <Grid item xs={4} style={{ paddingLeft: "16px" }}>
            Recommended
          </Grid>
        </Grid>
      }
      width={1000}
      background="#fff"
      modelBg="linear-gradient(36.71deg, #E5E5FF -1.63%, #FFE2D2 116.16%)"
      hideFooter
      open={open}
      onClose={onClose}>
      <Grid container>
        <Grid item xs={8} style={{ paddingRight: "16px", borderRight: "1px solid #CCCCCC" }}>
          <iframe
            style={{ border: 0 }}
            width="100%"
            height="500"
            src={video.link}
            title={video.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item xs={4}>
          <List
            style={{
              background: "white",
              marginLeft: "16px",
              maxHeight: "500px",
              padding: 0,
              overflow: "auto",
              borderRadius: "10px"
            }}>
            {_.map(videos, (video, index) => (
              <ListItem
                button
                style={{
                  borderBottom: index !== videos.length - 1 ? "1px solid #CCCCCC" : "none",
                  fontSize: 14,
                  padding: "8px 4px",
                  margin: "0px 8px",
                  width: "auto"
                }}
                key={index}
                onClick={() => onSelectVideo(video)}>
                <OverflowTooltip style={{ whiteSpace: "nowrap" }} value={video.title} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </NewModal>
  );
};

export default VideoModal;
