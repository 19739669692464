/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { EntityMetaDto } from './entity-meta-dto';

/**
 * 
 * @export
 * @interface UpdateEntityDto
 */
export interface UpdateEntityDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateEntityDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {EntityMetaDto}
     * @memberof UpdateEntityDto
     */
    'entityMeta'?: EntityMetaDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'dataSourceId'?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateEntityDto
     */
    'dataSourceOptions'?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'syncDataSourceId'?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateEntityDto
     */
    'syncDataSourceOptions'?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'status'?: UpdateEntityDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEntityDto
     */
    'image'?: string;
}

export const UpdateEntityDtoStatusEnum = {
    Pending: 'PENDING',
    Built: 'BUILT',
    Empty: 'EMPTY',
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Success: 'SUCCESS',
    Error: 'ERROR',
    Unbuilt: 'UNBUILT',
    Skipped: 'SKIPPED',
    Building: 'BUILDING'
} as const;

export type UpdateEntityDtoStatusEnum = typeof UpdateEntityDtoStatusEnum[keyof typeof UpdateEntityDtoStatusEnum];


