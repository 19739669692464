/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TenantUserRoleUpdateDto
 */
export interface TenantUserRoleUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof TenantUserRoleUpdateDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TenantUserRoleUpdateDto
     */
    'userType': TenantUserRoleUpdateDtoUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TenantUserRoleUpdateDto
     */
    'roleId': string;
}

export const TenantUserRoleUpdateDtoUserTypeEnum = {
    User: 'USER',
    Invited: 'INVITED'
} as const;

export type TenantUserRoleUpdateDtoUserTypeEnum = typeof TenantUserRoleUpdateDtoUserTypeEnum[keyof typeof TenantUserRoleUpdateDtoUserTypeEnum];


