import { Grid, InputAdornment, makeStyles, Popover, TextField } from "@material-ui/core";
import { filter, includes, isEmpty, map, toLower } from "lodash";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { OverflowTooltip, Spinner, Table } from "src/components";
import useArtifactByName from "src/hooks/api/artifacts/useArtifactByName";
import SearchIcon from "@material-ui/icons/Search";

interface IProps {
  anchorEl: HTMLDivElement | null;
  name: string;
  setAnchorEl: Dispatch<SetStateAction<HTMLDivElement | null>>;
}

const tableColumns = [
  {
    id: "File Name",
    accessor: "name",
    Header: "File Name"
  },
  {
    id: "Type",
    accessor: "type",
    Header: "Type"
  }
];

const useStyles = makeStyles({
  entity: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "4px 6px",
    width: "inherit",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D1D1D1"
  },
  entityText: {
    fontWeight: 400,
    fontSize: "14px",
    width: "174px"
  },
  tableWrap: {
    padding: "1px",
    overflow: "auto",
    maxWidth: "500px"
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  header: {
    padding: "10px 26px",
    borderBottom: "0.5px solid #D1D1D1",
    fontWeight: 600,
    maxWidth: "350px"
  },
  search: {
    color: "#7c7c7c",
    minHeight: "34px",
    height: "100%",
    margin: "0px",
    width: "100%",
    borderTop: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "& fieldset": {
      border: "none"
    },
    "& div[class*='MuiInputBase-root']": {
      height: "34px",
      width: "100%",
      color: "#7c7c7c"
    },
    "& input[class*='MuiInputBase-input']": {
      height: "34px",
      fontSize: "14px",
      width: "100%"
    }
  }
});

const ArtifactPopover: React.FC<IProps> = ({ anchorEl, setAnchorEl, name }) => {
  const classes = useStyles();
  const artifactDetails = useArtifactByName(name);
  const [searchValue, setSearchValue] = useState("");

  const artifactNames = useMemo(() => {
    if (artifactDetails?.data) {
      return map(
        filter(artifactDetails.data.fileObjects, (item) =>
          includes(toLower(item.path), toLower(searchValue))
        ),
        (item) => ({ name: item.path, type: item.type })
      );
    } else {
      return [];
    }
  }, [artifactDetails?.data, searchValue]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      onClose={() => setAnchorEl(null)}>
      <div className={classes.header}>
        <OverflowTooltip value={name} />
      </div>

      <TextField
        onChange={(e) => handleSearch(e.target.value)}
        InputProps={{
          onKeyDown: (e) => e.stopPropagation(),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
          type: "search"
        }}
        placeholder="Search by file name"
        className={classes.search}
        id="column-search-input"
        name="column-search-input"
        variant="outlined"
      />
      {artifactDetails.isLoading && <Spinner />}
      {!artifactDetails.isLoading &&
        (isEmpty(artifactNames) ? (
          <div
            style={{ justifyContent: "center", fontSize: "14px", color: "grey", padding: "20px" }}>
            {!!searchValue ? "No matching result found" : "No files present in this Artifact"}
          </div>
        ) : (
          <Grid container className={classes.tableWrap}>
            <Table
              data={artifactNames}
              columns={tableColumns}
              hideCount
              hideSettings
              size="small"
              maxHeight="calc(100vh - 130px)"
              unsorted
            />
          </Grid>
        ))}
    </Popover>
  );
};

export default ArtifactPopover;
