/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { BaseTransform } from './base-transform';
// May contain unused imports in some cases
// @ts-ignore
import type { DFSInput } from './dfsinput';

/**
 * 
 * @export
 * @interface PipelineTemplateDto
 */
export interface PipelineTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof PipelineTemplateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineTemplateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineTemplateDto
     */
    'type'?: PipelineTemplateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineTemplateDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineTemplateDto
     */
    'noteBookName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineTemplateDto
     */
    'noteBookSubPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineTemplateDto
     */
    'noteBookRemotePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineTemplateDto
     */
    'requirements'?: string;
    /**
     * 
     * @type {Array<DFSInput>}
     * @memberof PipelineTemplateDto
     */
    'inputs'?: Array<DFSInput>;
    /**
     * 
     * @type {Array<BaseTransform>}
     * @memberof PipelineTemplateDto
     */
    'baseTransforms'?: Array<BaseTransform>;
}

export const PipelineTemplateDtoTypeEnum = {
    Notebook: 'NOTEBOOK',
    PythonFunc: 'PYTHON_FUNC',
    Code: 'CODE'
} as const;

export type PipelineTemplateDtoTypeEnum = typeof PipelineTemplateDtoTypeEnum[keyof typeof PipelineTemplateDtoTypeEnum];


