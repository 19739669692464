import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import launchImage from "src/assets/images/launch.png";

const useStyles = makeStyles({
  dataAppViewWrap: {
    flexWrap: "nowrap",
    alignItems: "center",
    paddingTop: "10%",
    gap: "24px"
  },
  dataAppLaunchingImage: {
    height: "180px",
    width: "180px"
  }
});

export const DataAppLaunchingView = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.dataAppViewWrap}>
      <Grid component="img" src={launchImage} className={classes.dataAppLaunchingImage} />
      <Typography variant="h5" color="primary" test-id="dataAppLaunchingTitle">
        DataApp is launching
      </Typography>
      <Typography color="primary" test-id="dataAppLaunchingSubTitle">
        This might take a few minutes, Please wait...
      </Typography>
    </Grid>
  );
};
