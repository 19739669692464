import React from "react";
import { Grid, Card as MuiCard, CardProps as MuiCardProps, makeStyles } from "@material-ui/core";
import { CardProvider } from "./CardContext";
import { MoreOptions, IOption } from "../MoreOptions/MoreOptions";
import { useNavigate } from "react-router";

export interface CardProps extends MuiCardProps {
  children: React.ReactNode;
  moreOptions?: IOption[];
  link?: string;
}

const useStyles = makeStyles({
  card: {
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "12px",
    background: ({ isHovered }: any) => (isHovered ? "#F4F4FF" : "#fff"),
    border: ({ isHovered }: any) => (isHovered ? "1px solid #4646B5" : "1px solid #fff"),
    minHeight: "120px",
    padding: "16px",
    position: "relative",
    cursor: ({ link }: any) => (link ? "pointer" : "default"),
    height: "100%"
  },
  moreOptions: {
    position: "absolute",
    background: "#fff",
    top: "32px",
    right: "32px",
    width: "25px",
    height: "28px",
    borderRadius: "4px",
    zIndex: 1
  }
});

export const Card = ({ children, link, moreOptions, ...restProps }: CardProps) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const navigate = useNavigate();
  const classes = useStyles({ isHovered, link });
  const onLinkClick = () => {
    link && navigate(link);
  };
  return (
    <CardProvider isHovered={isHovered} {...restProps}>
      <MuiCard
        onClick={link ? onLinkClick : undefined}
        className={classes.card}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {moreOptions && isHovered && (
          <Grid className={classes.moreOptions}>
            <MoreOptions
              options={moreOptions}
              btnProps={{
                style: {
                  color: "#4646B5"
                }
              }}
            />
          </Grid>
        )}
        {children}
      </MuiCard>
    </CardProvider>
  );
};
