import { WebPaths } from "src/routing/routes";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import checkIconImgSrc from "src/assets/images/check-icon.svg";

const useStyles = makeStyles({
  runSuccessImg: {
    width: "60px",
    height: "60px"
  },
  successView: {
    flexWrap: "nowrap",
    gap: "16px",
    alignItems: "center"
  },
  btns: {
    justifyContent: "center",
    gap: "8px"
  }
});

export const AutoMLRecipeRunSuccessView = ({ onBack }: { onBack: () => void }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { projectId, scenarioId } = useParams<$TSFixMe>();

  const openCanvas = () => {
    if (projectId && scenarioId) {
      navigate(
        generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, {
          projectId,
          scenarioId
        })
      );
    }
  };

  return (
    <Grid container direction="column" className={classes.successView}>
      <Grid component="img" src={checkIconImgSrc} className={classes.runSuccessImg} />
      <Typography>Project Run is successful</Typography>
      <Grid container direction="row" className={classes.btns}>
        <Button size="small" startIcon={<ArrowBackOutlined />} onClick={() => onBack()}>
          Go back to Dataset View
        </Button>
        <Button size="small" onClick={openCanvas}>
          Open Canvas
        </Button>
      </Grid>
    </Grid>
  );
};
