import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { postAPI } from "src/utils/apiService";

export const QUERY_KEY_DATA_SOURCE_REFRESHED_STATUS = "query-key-data-source-refreshed-status";

const useGetRefreshedDataConnectorStatus = (
  dataSourceIds: string[],
  options?: UseQueryOptions<any, any>
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_DATA_SOURCE_REFRESHED_STATUS, dataSourceIds],
    queryFn: async () => {
      const secretResponse = await postAPI(`/v2/data-source/refresh`, { dataSourceIds });

      return secretResponse;
    },
    ...options
  });
};

export default useGetRefreshedDataConnectorStatus;
