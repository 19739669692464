import { forEach } from "lodash";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEY_EDA } from "./useGetEntityEda";
import { QUERY_KEY_EDA_CHARTS } from "./useGetEdaCharts";
import { QUERY_KEY_ENTITIES } from "./useEntities";
import { QUERY_KEY_ENTITY_AND_STATS } from "../projects/useEntityDataAndStats";
import { QUERY_KEY_ENTITY_DETAILS } from "./useEntityDetails";
import { handleResponse, postAPIWithRethrow } from "src/utils/apiService";
import { UseGetProjectCanvasQueryKeys } from "../projects/useGetProjectCanvas";

interface IVariables {
  id?: string;
  name: string;
}

const useReloadDatasets = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }) => {
      if (!id) {
        throw "Entity id is absent";
      }

      const results = await postAPIWithRethrow(`/v2/entities/${id}/reload`, {});

      return results;
    },
    onSuccess: (__, { name }) => {
      handleResponse({ successMessage: `Dataset ${name} reloaded successfully` });
      forEach(
        [
          QUERY_KEY_ENTITIES,
          QUERY_KEY_ENTITY_DETAILS,
          QUERY_KEY_ENTITY_AND_STATS,
          QUERY_KEY_EDA,
          QUERY_KEY_EDA_CHARTS,
          UseGetProjectCanvasQueryKeys.ProjectCanvas
        ],
        (query) => queryClient.invalidateQueries([query])
      );
    }
  });
};

export default useReloadDatasets;
