import React from "react";
import clsx from "clsx";
import { Button, ButtonProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "capitalize",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "3px 15px",
    fontWeight: 400,

    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`
    }
  }
}));

interface IProps extends ButtonProps {
  isCustomTheming?: boolean;
  component?: string;
}

const DefaultButton: React.FC<IProps> = (props) => {
  const { isCustomTheming = true, className, ...restProps } = props;
  const styles = useStyles();

  return (
    // New UX change
    // Can be removed soon.
    <Button className={clsx(className, isCustomTheming ? styles.button : null)} {...restProps} />
  );
};

export default DefaultButton;
