import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

// MUI
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Icons
import { EyeIcon } from "src/icons/NewUX";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";

// Components
import DeleteNodeModal from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import NodeActionWrapper from "../NodeActionWrapper";

// Types
import { NodeData } from "src/types";

type Props = {
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  children?: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& button": {
      minWidth: theme.spacing(4),
      "& svg": {
        color: theme.palette.common.white
      }
    },
    "& .borderLeft": {
      borderLeft: "1px solid #ffffff4d",
      borderRadius: "initial"
    }
  }
}));

const ContextMenu = (props: Props) => {
  const { closeContextMenu, isDefaultScenario, data, children } = props || {};

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onView = () => {
    if (!data?.name) {
      return;
    }

    let path = generatePath(WebPaths.ArtifactsDetails, { artifactName: data?.name });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        artifactName: data?.name
      });
    }

    !!path && navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    items.push(
      <NodeActionWrapper key="view" title="View">
        <IconButton size="small" onClick={onView}>
          <EyeIcon viewBox="0 0 20 20" />
        </IconButton>
      </NodeActionWrapper>
    );

    if (!data?.isJobCanvas && isDefaultScenario) {
      items.push(
        <NodeActionWrapper key="delete" title="Delete">
          <IconButton size="small" className="borderLeft" onClick={onDelete}>
            <TrashIcon viewBox="0 0 20 22" />
          </IconButton>
        </NodeActionWrapper>
      );
    }

    return items;
  }, [data?.isJobCanvas, data?.isJobCanvasPath, isDefaultScenario]);

  return (
    <>
      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="artifact"
          deleteNote="Deleting this might impact associated DataApps (if any)"
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
