import React from "react";

// Packages
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import MuiLink from "@material-ui/core/Link";
import { generatePath } from "react-router";
// Utils
import { RenderText } from "src/utils";

// Types
import { IEntity } from "src/hooks/api/entities/useEntities";
import { useNavigate } from "react-router";
import { WebPaths } from "src/routing/routes";

type Props = {
  scenarioId?: string;
  project: $TSFixMe;
  dataset?: IEntity;
  navigateBack: $TSFixMeFunction;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { scenarioId, project, dataset, navigateBack } = props || {};
  const navigate = useNavigate();

  const navigatBackWithRedirect = (path: string) => {
    navigateBack().then(() => {
      navigate(path);
    });
  };

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <MuiLink
        key="projectName"
        onClick={() => navigatBackWithRedirect(`/projects/${project?.id}/canvas`)}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </MuiLink>
    ) : (
      <RenderText key="projects" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  if (!!dataset?.id) {
    if (!!project?.id && !!scenarioId) {
      breadcrumbs.push(
        <MuiLink
          key="link"
          onClick={() =>
            navigatBackWithRedirect(
              generatePath(WebPaths.ViewData, {
                projectId: project?.id,
                entityId: dataset?.id,
                scenarioId,
                section: "data"
              })
            )
          }
          style={{ cursor: "pointer" }}>
          <RenderText>{dataset?.name || "Dataset"}</RenderText>
        </MuiLink>
      );
    }

    breadcrumbs.push(
      <RenderText key="dataset" color="textSecondary">
        Append File
      </RenderText>
    );
  } else {
    breadcrumbs.push(
      <RenderText key="dataset" color="textSecondary">
        Create New Dataset
      </RenderText>
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
