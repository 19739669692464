import { putAPIWithRethrow } from "src/utils/apiService";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  connectorId: string;
  syncFrequency: number;
}

const useSetSynchFrequency = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ connectorId, syncFrequency }) => {
      const data = await putAPIWithRethrow(`/v2/data-source/sync-frequency`, {
        connectorId,
        syncFrequency
      });

      return { data };
    }
  });
};

export default useSetSynchFrequency;
