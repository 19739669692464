import { get, has } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { NodeArtifactTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const useStyles = makeStyles({
  root: {
    width: 28,
    height: 28,
    borderRadius: 28 / 3,
    backgroundColor: ({ status }: StyleProps) =>
      has(NodeArtifactTheme, status)
        ? get(NodeArtifactTheme, [status, "backgroundColor"])
        : NodeArtifactTheme.backgroundColor,
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeArtifactTheme, status)
        ? get(NodeArtifactTheme, [status, "borderColor"])
        : NodeArtifactTheme.borderColor,
    color: ({ status }: StyleProps) =>
      has(NodeArtifactTheme, status)
        ? get(NodeArtifactTheme, [status, "iconColor"])
        : NodeArtifactTheme.iconColor,
    "&:hover": {
      cursor: "help"
    }
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "80%",
      height: "80%"
    }
  }
});

export default useStyles;
