import React from "react";

export const TemplatesIcon = () => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 1.5V3.80003C11 4.92013 11 5.48019 11.218 5.90801C11.4097 6.28433 11.7157 6.5903 12.092 6.78204C12.5198 7.00003 13.0799 7.00003 14.2 7.00003H16.5M8 10L6 12L8 14M10 12L12 14L10 16M17 9.31371V13C17 15.8003 17 17.2004 16.455 18.27C15.9757 19.2108 15.2108 19.9757 14.27 20.455C13.2004 21 11.8003 21 9 21V21C6.19974 21 4.79961 21 3.73005 20.455C2.78924 19.9757 2.02433 19.2108 1.54497 18.27C1 17.2004 1 15.8003 1 13V8.77817C1 6.18697 1 4.89136 1.46859 3.88663C1.96536 2.82147 2.82147 1.96536 3.88663 1.46859C4.89136 1 6.18697 1 8.77818 1V1C9.91046 1 10.4766 1 11.0113 1.11855C11.5806 1.24479 12.1235 1.46965 12.6153 1.78296C13.0772 2.07721 13.4775 2.47753 14.2782 3.27817L14.6569 3.65685C15.5216 4.52161 15.954 4.95399 16.2632 5.45858C16.5373 5.90594 16.7394 6.39366 16.8618 6.90384C17 7.47928 17 8.09076 17 9.31371Z"
      stroke="#FFFFFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
