import {
  getAPI,
  getAPIWithRethrow,
  postAPI,
  postAPIWithRethrow,
  putAPI,
  putAPIWithRethrow,
  deleteAPI,
  deleteAPIWithRethrow
} from "../utils/apiService";

// Data connectors get APIs - STARTS >>
export const getDataConnectors = async () => await getAPI("/v2/data-source/third-party-sources");
// << ENDS - Data connectors get APIs

// Data source APIs - STARTS >>
// Get >>
export const getDataSources = async () => await getAPI("/v2/data-source");

export const getDataSourcesWithRethrow = async () => await getAPIWithRethrow("/v2/data-source");

export const getDataSource = async ({ id }: $TSFixMe = {}) => await getAPI(`/v2/data-source/${id}`);

export const syncDataSource = async (id: string) =>
  await postAPIWithRethrow(`/v2/data-source/${id}/sync`, { id });

export const getDataSourceWithRethrow = async ({ id }: $TSFixMe = {}) =>
  await getAPIWithRethrow(`/v2/data-source/${id}`);
// << Get

// Create >>
export const createDataSource = async ({ payload }: $TSFixMe = {}) =>
  await postAPI("/v2/data-source", payload);

export const createDataSourceWithRethrow = async ({ payload }: $TSFixMe = {}) =>
  await postAPIWithRethrow("/v2/data-source", payload);
// << Create

// Update >>
export const updateDataSource = async ({ payload = {} }: $TSFixMe = {}) =>
  await putAPI(`/v2/data-source`, payload);

export const updateDataSourceWithRethrow = async ({ payload = {} }: $TSFixMe = {}) =>
  await putAPIWithRethrow(`/v2/data-source`, payload);
// << Update

// Delete >>
export const deleteDataSource = async ({ id, payload = {} }: $TSFixMe = {}) =>
  await deleteAPI(`/v2/data-source?id=${id}`, payload);

export const deleteDataSourceWithRethrow = async ({ id, payload = {} }: $TSFixMe = {}) =>
  await deleteAPIWithRethrow(`/v2/data-source?id=${id}`, payload);
// << Delete
// << ENDS - Data source APIs

export const getDataSourcesSchema = async () => await getAPI("/v2/data-source/schema");

export const getDataSourceFiles = async ({ payload }: $TSFixMe = {}) =>
  await postAPI("/v2/data-source/list-files", payload, false);

export const getDataSourceUsageWithRethrow = async (id: string) =>
  await getAPIWithRethrow(`/v2/data-source/${id}/usage`);

export const getSqlDataSourceSampleDataWithRethrow = async ({ payload }: $TSFixMe = {}) =>
  await postAPIWithRethrow("/v2/data-source/sample-data", payload);
