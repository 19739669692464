import { postAPI, putAPI } from "src/utils/apiService";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { map } from "lodash";

interface IVariable {
  add: Record<string, any>[];
  update: Record<string, any>[];
}

const useAddAndUpdateSecret = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async (payload) => {
      const data = await Promise.all([
        ...map(payload.add, (item) => postAPI("/secret", item)),
        ...map(payload.update, (item) => putAPI(`/secret/${item.id}`, item))
      ]);

      return { data };
    }
  });
};

export default useAddAndUpdateSecret;
