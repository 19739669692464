import React from "react";

export const ClearIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 6.66666V9.99999L12.0833 12.0833"
        stroke="#4646B5"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.61488 5.72551L2.98988 5.72865C2.9916 6.07137 3.26901 6.34877 3.61173 6.3505L3.61488 5.72551ZM5.73303 6.36116C6.0782 6.36289 6.35943 6.08448 6.36116 5.73931C6.36289 5.39413 6.08448 5.11291 5.73931 5.11117L5.73303 6.36116ZM4.22921 3.60107C4.22747 3.2559 3.94625 2.97748 3.60107 2.97922C3.2559 2.98096 2.97748 3.26218 2.97922 3.60735L4.22921 3.60107ZM3.125 10C3.125 9.65483 2.84517 9.375 2.5 9.375C2.15483 9.375 1.875 9.65483 1.875 10H3.125ZM14.0629 17.0377C14.3617 16.8648 14.4638 16.4825 14.291 16.1837C14.1182 15.8849 13.7358 15.7827 13.4371 15.9557L14.0629 17.0377ZM15.9557 13.4371C15.7827 13.7358 15.8849 14.1182 16.1837 14.291C16.4825 14.4638 16.8648 14.3617 17.0377 14.0629L15.9557 13.4371ZM4.28361 4.22605C4.03832 4.46891 4.03634 4.86463 4.27921 5.10992C4.52207 5.35522 4.91778 5.35718 5.16307 5.11432L4.28361 4.22605ZM15.7186 4.28155C12.5351 1.09805 7.39068 1.06582 4.22825 4.22825L5.11213 5.11213C7.77805 2.44621 12.131 2.4617 14.8348 5.16543L15.7186 4.28155ZM4.22825 4.22825L3.17293 5.28357L4.05682 6.16745L5.11213 5.11213L4.22825 4.22825ZM3.61173 6.3505L5.73303 6.36116L5.73931 5.11117L3.61802 5.10052L3.61173 6.3505ZM4.23987 5.72237L4.22921 3.60107L2.97922 3.60735L2.98988 5.72865L4.23987 5.72237ZM10 3.125C13.7969 3.125 16.875 6.20304 16.875 10H18.125C18.125 5.51268 14.4873 1.875 10 1.875V3.125ZM10 16.875C6.20304 16.875 3.125 13.7969 3.125 10H1.875C1.875 14.4873 5.51268 18.125 10 18.125V16.875ZM13.4371 15.9557C12.4265 16.5402 11.2533 16.875 10 16.875V18.125C11.4789 18.125 12.8672 17.7293 14.0629 17.0377L13.4371 15.9557ZM16.875 10C16.875 11.2533 16.5402 12.4265 15.9557 13.4371L17.0377 14.0629C17.7293 12.8672 18.125 11.4789 18.125 10H16.875ZM5.16307 5.11432C6.40589 3.88382 8.11372 3.125 10 3.125V1.875C7.77118 1.875 5.75094 2.77326 4.28361 4.22605L5.16307 5.11432Z"
        fill="#4646B5"
      />
    </svg>
  );
};
