import React, { useState } from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";

import { useGetAIGuideThreads } from "src/hooks/api";
import { Spinner } from "src/components";
import { useAIGuideContext } from "./useAIGuideContext";
import TopNavBar from "src/layout/NavBars/components/TopNavBar/TopNavBar";
import { ThreadResponseDto } from "openapi/Models/thread-response-dto";
import { AIGuideThreadsWrap } from "./AIGuideThreadsWrap";

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: "absolute",
    top: 94,
    overflow: "auto"
  }
}));

const AIGuideThreads = () => {
  const { projectId } = useAIGuideContext();
  const classes = useStyles();
  const [threads, setThreads] = useState<ThreadResponseDto[] | undefined>(undefined);

  const { isLoading } = useGetAIGuideThreads({
    projectId: projectId!,
    shouldCheckEnvRelaunch: true,
    shouldSortAndFilter: true,
    enabled: true,
    onSuccess: (response) => {
      setThreads(response);
    }
  });

  return isLoading || !threads ? (
    <Spinner />
  ) : (
    <Grid container direction="column" wrap="nowrap">
      <TopNavBar background="rgb(0 0 0 / 30%)" />
      <Container component="main" maxWidth={false} disableGutters className={classes.mainContainer}>
        <AIGuideThreadsWrap threads={threads} setThreads={setThreads} />
      </Container>
    </Grid>
  );
};

export default AIGuideThreads;
