import React, { useEffect, useRef, useState } from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { Criteria, DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";

import FileControl from "./FileControl/FileControl";
import DatasetControl from "./DatasetControl";

import useHelpers from "../../hooks/useHelpers";
import { useUploadContext } from "../../contexts/Upload/useUploadContext";
import { includes } from "lodash";
import LocalFilesSelection from "../LocalFilesSelection/LocalFilesSelection";
import SegregateUploadControl from "./FileControl/SegregateUploadControl";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  uploadControlRow: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2)
    },
    "& .fileControlContainer": { position: "relative", width: "42.5%" },
    "& .datasetControlContainer": { width: "42.5%" }
  }
}));

const UploadControl = () => {
  const { reUpload, deleteFile } = useUploadContext();

  const { isDataSourcesFilesUpload } = useHelpers();

  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>

  const {
    datasetIsSelectionSectionOpenStore,
    datasetCriterionStore,
    datasetFilesStore,
    datasetDatasetsStore
  } = useStoreSelectors();
  // << ENDS - Stores

  // States - STARTS >>
  const [datasetFieldsRefs, setDatasetFieldsRefs] = useState<$TSFixMe>([]);
  const [datasetFormControlsRefs, setDatasetFormControlsRefs] = useState<$TSFixMe>([]);

  const [connectorLineRerenderTimer, setConnectorLineRerenderTimer] = useState<$TSFixMe>(
    new Date().getTime()
  );
  // << ENDS - Stores

  // Refs - STARTS >>
  const fileControlContainerRef = useRef<$TSFixMe>();
  const datasetControlContainerRef = useRef<$TSFixMe>(null);

  // << ENDS - Refs

  // Update datasets refs - STARTS >>
  const updateDatasetFormControlRef = (thisRef: $TSFixMe, thisIndex = 0) => {
    const thisDatasetFormControlsRefs = datasetFormControlsRefs;
    thisDatasetFormControlsRefs[thisIndex] = thisRef;

    setDatasetFormControlsRefs(() => thisDatasetFormControlsRefs);

    // To refresh connectorLine in FileControl component
    setConnectorLineRerenderTimer(() => new Date().getTime());
  };

  const updateDatasetFieldRef = (thisRef: $TSFixMe, thisIndex = 0) => {
    const thisDatasetFieldsRefs = datasetFieldsRefs;
    thisDatasetFieldsRefs[thisIndex] = thisRef;

    setDatasetFieldsRefs(() => thisDatasetFieldsRefs);
  };
  // << ENDS - Update datasets refs

  useEffect(() => {
    // To refresh connectorLine in FileControl component
    setConnectorLineRerenderTimer(() => new Date().getTime());
  }, [
    datasetIsSelectionSectionOpenStore,

    datasetFilesStore,

    fileControlContainerRef.current,
    datasetControlContainerRef.current
  ]);

  // *Important: Changing structure of elements starting from root to the down to its descendants WILL AFFECT connectorLine in FileControl component!
  return (
    <>
      <Grid container className={classes.root}>
        {[Criteria.SingleFileUpload, Criteria.Segregate].includes(datasetCriterionStore?.value) ? (
          (datasetFilesStore || [])?.map((eachFile: $TSFixMe, index: number) => (
            <SegregateUploadControl
              index={index}
              key={index}
              reUpload={reUpload}
              deleteFile={deleteFile}
              eachFile={eachFile}
              datasetFieldRef={datasetFormControlsRefs[index]}
              isDataSourcesFilesUpload={isDataSourcesFilesUpload}
              datasetFormControlsRefs={datasetFormControlsRefs}
              uploadControlRow={classes.uploadControlRow}
              datasetName={datasetDatasetsStore[index]?.name}
              connectorLineRerenderTimer={connectorLineRerenderTimer}
              updateDatasetFieldRef={(thisRef: $TSFixMe) => updateDatasetFieldRef(thisRef, index)}
              updateDatasetFormControlRef={(thisRef: $TSFixMe) =>
                updateDatasetFormControlRef(thisRef, index)
              }
            />
          ))
        ) : (
          <>
            <Grid container justifyContent="space-between" className={classes.uploadControlRow}>
              <Grid container className="fileControlContainer" ref={fileControlContainerRef}>
                {(datasetFilesStore || [])?.map((eachFile: $TSFixMe, index: number) => (
                  <FileControl
                    key={`fileControlRow_${index}`}
                    index={index}
                    fileControlContainerRef={fileControlContainerRef.current}
                    datasetControlContainerRef={datasetControlContainerRef.current}
                    datasetFormControlRef={datasetFormControlsRefs[0]}
                    datasetFieldRef={datasetFieldsRefs[0]}
                    connectorLineRerenderTimer={connectorLineRerenderTimer}
                    file={eachFile}
                    reUpload={() => reUpload(eachFile, index)}
                    deleteFile={() => deleteFile(eachFile, index)}
                    isLastFileControlContainer={index === (datasetFilesStore || [])?.length - 1}
                  />
                ))}
              </Grid>
              <Grid container className="datasetControlContainer" ref={datasetControlContainerRef}>
                <DatasetControl
                  index={0}
                  datasetName={datasetDatasetsStore[0]?.name || DatasetHelperText.Unknown}
                  updateDatasetFormControlRef={(thisRef: $TSFixMe) =>
                    updateDatasetFormControlRef(thisRef)
                  }
                  updateDatasetFieldRef={(thisRef: $TSFixMe) => updateDatasetFieldRef(thisRef)}
                />
              </Grid>
            </Grid>
          </>
        )}
        {!isDataSourcesFilesUpload &&
          includes([Criteria.Merge, Criteria.Segregate], datasetCriterionStore.value) && (
            <LocalFilesSelection />
          )}
      </Grid>
    </>
  );
};

export default UploadControl;
