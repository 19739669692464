import * as React from "react";

import { ListItemAvatar, ListItemText, ListItem, Avatar } from "@material-ui/core";
import styles from "../entity-modal.module.scss";

export default function TransformItem({ Icon, title, subTitle, isActive, handleClick }: $TSFixMe) {
  return (
    <ListItem onClick={handleClick} className={styles.transformIcons}>
      <ListItemAvatar className={isActive && styles.transformIconsActive}>
        <Avatar>
          <Icon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={subTitle} />
    </ListItem>
  );
}
