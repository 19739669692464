import { getEnvironmentLogs } from "src/api/environments";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

const QUERY_KEY_ENV_LOGS = "query-env-env-logs";

const useEnvironmentLogs = (
  envId: string,
  mode: "live" | "offline",
  options?: UseQueryOptions<string[]>
): UseQueryResult<string[], any> => {
  return useQuery<string[]>({
    queryKey: [QUERY_KEY_ENV_LOGS, envId, mode],
    queryFn: async () => {
      const response = await getEnvironmentLogs({
        id: envId,
        mode
      });

      return response?.logs;
    },
    ...options
  });
};

export default useEnvironmentLogs;
export { QUERY_KEY_ENV_LOGS };
