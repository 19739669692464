import React from "react";
import { Box, makeStyles } from "@material-ui/core";

import { AddArtifactTable } from "./AddArtifactTable";
import { Artifact } from "src/types/Artifact.type";
import { Modal } from "src/components/custom";
import { SearchField, Spinner } from "src/components";
import { handleResponse } from "src/utils/apiService";
import { useGetArtifacts } from "hooks/api/artifacts/useGetArtifacts";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

type Props = {
  open: boolean;
  selectedArtifacts?: Artifact[];
  shouldDisableInitialSelectedRows?: boolean;
  onAddArtifacts: (artifactNames: Array<string>) => void;
  onClose: () => void;
};

// $FixMe: Scope to be refactored.
// The below styles are added only to support New UX. It shall be removed soon.
const useStyles = makeStyles(() => ({
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
}));

export const AddArtifactsModal: React.FC<Props> = (props) => {
  const {
    open,
    onAddArtifacts,
    onClose,
    selectedArtifacts = [],
    shouldDisableInitialSelectedRows
  } = props;

  const classes = useStyles();

  const [selectedArtifactNames, setSelectedArtifactNames] = React.useState<Array<any>>([]);
  const [searchValue, setSearchValue] = React.useState("");

  const { data, isLoading } = useGetArtifacts(
    {},
    {
      refetchOnMount: true,
      onError: (error: any) => {
        handleResponse({
          errorMessage: error.response?.data?.msg || error.message || "Error in fetching Artifacts"
        });
      },
      enabled: open
    }
  );

  const handleSubmit = () => {
    onAddArtifacts(selectedArtifactNames);
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearchValue(event.target.value);
  };

  const selectedRowIds = React.useMemo(() => {
    const artifactNames = selectedArtifacts.map((artifact) => artifact.name);
    return data?.reduce((acc, artifact: Artifact) => {
      return artifactNames.includes(artifact.name) ? { ...acc, [artifact.name]: true } : acc;
    }, {});
  }, [data, selectedArtifacts]);

  const onSelectedRowsChange = (artifactNames: $TSFixMe) => {
    setSelectedArtifactNames(artifactNames);
  };

  return (
    <NewThemeWrapper>
      {open ? (
        <Modal
          open={open}
          size="lg"
          title="Add Artifact"
          submitLabel="Add Artifact"
          isSubmitDisabled={selectedArtifactNames.length === 0}
          onClose={onClose}
          onSubmit={handleSubmit}>
          {isLoading || !data ? (
            <Spinner />
          ) : (
            <>
              <Box pb="16px">
                <SearchField
                  placeholder="Search Artifacts"
                  value={searchValue}
                  onChange={handleSearchValueChange}
                  className={classes.searchField}
                />
              </Box>
              <AddArtifactTable
                data={data}
                globalFilter={searchValue}
                onSelectedRowsChange={onSelectedRowsChange}
                selectedRowIds={selectedRowIds}
                shouldDisableInitialSelectedRows={shouldDisableInitialSelectedRows}
              />
            </>
          )}
        </Modal>
      ) : (
        <></>
      )}
    </NewThemeWrapper>
  );
};
