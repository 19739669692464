import Papa from "papaparse";
import axios from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { SignedUrlObjectTypeEnum } from "src/hooks/api/common/useGetSignedUrlMutation";

export const QUERY_KEY_FETCH_CHARTS = "query-key-fetch-charts";

interface IVariables {
  name: string;
  dataFilePath: string | undefined;
  chartType: string;
  shouldFetchData?: boolean;
}

const useFetchChartData = (
  { name, dataFilePath, chartType }: IVariables,
  options?: UseQueryOptions<any, any>
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_FETCH_CHARTS, name, dataFilePath, chartType],
    queryFn: async () => {
      if (!dataFilePath) {
        return null;
      }

      const payload = {
        fileName: name,
        filePath: dataFilePath,
        signedUrlObjectType: SignedUrlObjectTypeEnum.GENERAL
      };

      const response = await api.fetchResponse(
        async () => await api.SignedUrlControllerApi.downloadFileUrl(payload)
      );

      const { data: fileData } = await axios.get(response.signedUrl as string, {
        headers: response.headers
      });

      if (chartType === "IMAGE") {
        const res = await fetch(response.signedUrl as string);
        const blob = await res.blob();

        return URL.createObjectURL(blob);
      }
      if (chartType === "TABLE") {
        return new Promise((resolve, reject) => {
          Papa.parse(fileData, {
            header: true,
            skipEmptyLines: true,
            complete(results: any) {
              resolve(results.data);
            },
            error(err) {
              reject(err);
            }
          });
        });
      }
      if (chartType === "JSON") {
        const MAX_SIZE_LIMIT = 4 * 1024 * 1024; // 4 MB
        const jsonSizeInBytes = new Blob([JSON.stringify(fileData)]).size;
        if (jsonSizeInBytes > MAX_SIZE_LIMIT) {
          throw "The chart size is quite large. We suggest reducing the number of data points being plotted for better performance";
        }

        return { ...fileData, layout: { ...fileData.layout, title: null } };
      } else {
        return fileData;
      }
    },
    ...options
  });
};

export default useFetchChartData;
