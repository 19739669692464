import React from "react";
import clsx from "clsx";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Handle, Position } from "react-flow-renderer";

import BallotIcon from "@material-ui/icons/Ballot";

import { EventModal } from "../CanvasDrawerItems";
// import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
// import DoneAllIcon from "@material-ui/icons/DoneAll";
// import WarningIcon from "@material-ui/icons/Warning";

// import { EntityModalV2 } from "../CanvasDrawerItems";
import { addEllipsis, handleClickClosure } from "../../../helpers/helpers";

import { useDrawerStore } from "../../../store/store";
import { sideComponentSetter } from "../../../store/store.selectors";

import styles from "./EntityBlock.module.scss";
import { WebPaths } from "src/routing/routes";

const EventBlockV2 = ({ data }: $TSFixMe) => {
  const { scenarioId } = useParams<$TSFixMe>();
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const navigate = useNavigate();

  const handleSingleClick = () => {
    const dataToSet = {
      ...data,
      scenarioId
    };
    setSideComponent({
      sideComponent: EventModal,
      sideComponentProps: { title: data.label, data: dataToSet }
    });
  };

  const handleDoubleClick = () => {
    let path = generatePath(WebPaths.ViewData, {
      projectId: data.projectId,
      scenarioId: scenarioId || data?.scenarioId,
      entityId: data.itemId,
      section: "data"
    });

    navigate(path);
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <div
        role="button"
        tabIndex={0}
        className={clsx(styles.entityBlockContainer)}
        onClick={handleClickClosure({
          shouldBlockClickHandlerTrigger: data.shouldDisableBlockInteraction,
          handleDoubleClick,
          handleSingleClick
        })}
        onKeyPress={handleClickClosure({
          shouldBlockClickHandlerTrigger: data.shouldDisableBlockInteraction,
          handleDoubleClick,
          handleSingleClick
        })}>
        <div className={styles.relativeContainer}>
          <div className={styles.iconContainer}>
            <BallotIcon />
          </div>
          <div className={styles.label}>{addEllipsis(data.label)}</div>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default EventBlockV2;
