import React from "react";

// Packages
import clsx from "clsx";

// MUI
import Tooltip from "@material-ui/core/Tooltip";

// Icons
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

// Constants
import { NodeTypeNames } from "src/pages/private/ProjectsModule/utils";

// Types
import { NodeData } from "src/types";

// Styles
import useStyles from "./ArtifactReadonly.styles";
import useNodeStyle from "../../../hooks/useNodeStyle";

type Props = {
  data: NodeData;
};

const ArtifactReadonly = (props: Props) => {
  const { data } = props || {};

  const { classes, statusKey } = useNodeStyle({
    classes: useStyles({ status: data?.status }),
    status: data?.status
  });

  return (
    <Tooltip title={NodeTypeNames.Artifact}>
      <div className={clsx(classes.root, classes[statusKey])}>
        <div className={classes.container}>
          <div className={classes.icon}>
            <AccountBalanceIcon />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default ArtifactReadonly;
