import React, { useMemo } from "react";

import { useLocation, Link } from "react-router-dom";
import { isEmpty } from "lodash";

import {
  Container,
  Divider,
  IconButton,
  Avatar,
  Typography,
  useTheme,
  makeStyles
} from "@material-ui/core";

import { MainMenuIcon } from "src/icons/NewUX/MainMenuIcon";
import { RcLogoIcon } from "src/icons/NewUX/RcLogoIcon";
import { WebPaths } from "src/routing/routes";

import { capitalizeFirstLetter } from "src/utils/capitalize";
import { useGetRole } from "src/hooks/useGetRole";

import { VIEW_DATA, getMenus } from "./helpers/TopNavBar.helpers";

import useMainMenu from "./useMainMenu";
import MainMenu from "../MainMenu/MainMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex !important",
    alignItems: "center",

    "& .divider": {
      marginTop: 9.5,
      marginBottom: 9.5,
      backgroundColor: theme.palette.text.primary,
      opacity: 0.3
    },
    "& .menuIcon": {
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

const TopNavBarLeftSection = () => {
  const theme = useTheme();

  const classes = useStyles();

  const location = useLocation();

  const { isRoleYieldsDataAppView, checkIsRoleYieldsDataAppView } = useGetRole();

  // $FixMe: Scope to be refactored.
  const [section, sectionId] = location.pathname.split("/").filter((part: string) => Boolean(part));

  // $FixMe: Scope to be refactored.
  const menu = useMemo(
    () =>
      getMenus({
        section:
          section === VIEW_DATA
            ? "projects"
            : section || (isRoleYieldsDataAppView ? "dataapps-ui" : undefined),
        sectionId
      }) || {},
    [section, sectionId, isRoleYieldsDataAppView]
  );
  // Main-menu - STARTS >>
  const { mainMenuButtonAnchorEl, onMainMenuOpen, onMainMenuClose, menuItems, notebookMenuItems } =
    useMainMenu({
      checkIsRoleYieldsDataAppView
    });
  // << ENDS - Main-menu

  const getMenuIcon = (menu: $TSFixMe) => (
    <Avatar style={{ marginRight: "12px" }} variant="rounded" className="menuIcon">
      {menu?.getMenuIcon?.({
        width: 14,
        height: 14,
        viewBox: "0 0 16 16",
        color: theme.palette.secondary.main,
        ...menu?.iconProps
      })}
    </Avatar>
  );

  const getMenuLabel = (menu: $TSFixMe) => (
    <Typography variant="body2" color="textPrimary">
      {capitalizeFirstLetter(menu?.sectionName)}
    </Typography>
  );

  return (
    <>
      {/* Main-menu mounts here */}
      {Boolean(mainMenuButtonAnchorEl) && (
        <MainMenu
          mainMenuButtonAnchorEl={mainMenuButtonAnchorEl}
          onMainMenuClose={onMainMenuClose}
          menuItems={menuItems}
          notebookMenuItems={notebookMenuItems}
        />
      )}

      {/* Need to apply prop disableGutters. */}
      <Container className={classes.root}>
        <Link to={WebPaths.Dashboard} data-testid="rcLogoLink">
          <RcLogoIcon />
        </Link>
        <Divider orientation="vertical" style={{ marginLeft: 12 }} flexItem className="divider" />
        <IconButton
          edge="start"
          style={{ marginLeft: 0 }}
          color="inherit"
          onClick={onMainMenuOpen}
          data-testid="mainMenuAction">
          <MainMenuIcon color={theme.palette.text.primary} opacity={0.5} />
        </IconButton>
        <Divider orientation="vertical" style={{ marginRight: 12 }} flexItem className="divider" />
        {/* Disable prop for react-router Link is not available. Hence, opted conditional rendering. */}
        {!isEmpty(menu) &&
          (!!menu?.backButtonPath ? (
            <>
              <Link to={menu?.backButtonPath || WebPaths.Dashboard} data-testid="mainMenuIconLink">
                {getMenuIcon(menu)}
              </Link>
              <Link to={menu?.backButtonPath || WebPaths.Dashboard} data-testid="mainMenuLabelLink">
                {getMenuLabel(menu)}
              </Link>
            </>
          ) : (
            <>
              {getMenuIcon(menu)}
              {getMenuLabel(menu)}
            </>
          ))}
      </Container>
    </>
  );
};

export default TopNavBarLeftSection;
