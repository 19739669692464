import { isEqual, sortBy, uniqWith, some } from "lodash";

export const compareArrays = (previous: $TSFixMe, current: $TSFixMe, key?: string) => {
  const previousSet = new Set(previous?.map((item: $TSFixMe) => (key ? item[key] : item)));
  const currentSet = new Set(current?.map((item: $TSFixMe) => (key ? item[key] : item)));

  const added = current?.filter((item: $TSFixMe) => !previousSet.has(key ? item[key] : item));
  const removed = previous?.filter((item: $TSFixMe) => !currentSet.has(key ? item[key] : item));

  return { added, removed };
};

export const areEqual = (previous: $TSFixMe, current: $TSFixMe, key: string = "id") => {
  return isEqual(sortBy(previous, key), sortBy(current, key));
};

export const areArraysEqual = (arr1: Array<string>, arr2: Array<string>) => {
  const sortedArr1 = sortBy(arr1);
  const sortedArr2 = sortBy(arr2);

  return isEqual(sortedArr1, sortedArr2);
};

export const hasDuplicateItems = (list: Array<any>) => {
  return list?.length > 0 && list?.length !== uniqWith(list, isEqual)?.length;
};

export const hasInvalidValueLength = (list: Array<any>) => {
  return some(list, ({ value }) => value?.value?.length > 255);
};
