import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import _ from "lodash";

import { DataAppType } from "pages/DataApps/DataApps.type";
import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_DATA_APPS = "query-key-data-apps";

const useGetDataApps = (
  options?: UseQueryOptions<DataAppType[]>
): UseQueryResult<DataAppType[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_DATA_APPS],
    queryFn: async () => {
      const response = await getAPIWithRethrow("/dataapps");

      const dataWithAppTemplates = _.filter(response, (dataApp) => !!dataApp.appTemplateId);

      return dataWithAppTemplates;
    },
    refetchOnMount: true,
    ...options
  });
};

export default useGetDataApps;
export { QUERY_KEY_DATA_APPS };
