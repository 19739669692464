import _ from "lodash";
import { useQueryClient, useMutation } from "@tanstack/react-query";

import { NotificationResponse } from "src/types/Notifications.types";
import { QUERY_KEY_NOTIFICATIONS } from "./useGetNotifications";
import { deleteNotificationWithRethrow } from "src/api";

type DeleteNotificationProps = { notificationIds: string[] };

export const useDeleteNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ notificationIds }: DeleteNotificationProps) => {
      return deleteNotificationWithRethrow(notificationIds);
    },
    onSuccess: (__, { notificationIds }) => {
      queryClient.setQueryData([QUERY_KEY_NOTIFICATIONS], (prevData?: NotificationResponse[]) => {
        return _.filter(prevData, ({ id }) => !_.includes(notificationIds, id));
      });
    }
  });
};
