import React from "react";

import Text from "src/components/Widget/Text";
import { Artifact } from "src/types/Artifact.type";
import { Table } from "src/components";
import { dateFormat } from "src/utils/dateFormat";
import { formTableCells, InputColumn } from "src/components/Table/TableUtils";

const TABLE_SIZE = "small";

export const AddArtifactTable = ({
  data,
  globalFilter,
  selectedRowIds,
  onSelectedRowsChange,
  shouldDisableInitialSelectedRows
}: {
  data: Artifact[];
  selectedRowIds: $TSFixMe;
  globalFilter?: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onSelectedRowsChange: (rows: Array<any>) => void;
  shouldDisableInitialSelectedRows?: boolean;
}) => {
  const artifactColumns = React.useMemo(() => {
    const columns: InputColumn[] = [
      {
        id: "Artifact Name",
        Header: "Artifact Name",
        accessor: "name",
        Cell: ({ value }: $TSFixMe) => <Text value={value} />
      },
      {
        accessor: (row: $TSFixMe) => dateFormat(row["created"], false, "YYYY-MM-DD"),
        id: "Date Created",
        Header: "Date Created"
      },
      {
        id: "Source",
        accessor: (row: $TSFixMe) => {
          const value = row["producer"];
          return value?.projectName || "Manual addition";
        },
        Header: "Source"
      },
      {
        id: "Source Recipe",
        accessor: "producer.recipeName",
        Header: "Source Recipe"
      }
    ];
    return formTableCells({ inputColumns: columns, size: TABLE_SIZE, isFilterable: true });
  }, []);

  const handleSelectedRowsChange = (rows: any) => {
    onSelectedRowsChange(Object.keys(rows));
  };
  return (
    <Table
      data={data}
      size={TABLE_SIZE}
      columns={artifactColumns}
      isCellSortEnabled
      isSelectable
      orderByDefault="Date Created"
      sortInverted
      maxHeight="calc(100vh - 337px)"
      onSelectedRowsChange={handleSelectedRowsChange}
      globalFilter={globalFilter}
      selectedRowIds={selectedRowIds}
      getRowId={(row: $TSFixMe) => {
        return row.name;
      }}
      emptyTableMessage="No Artifacts"
      shouldDisableInitialSelectedRows={shouldDisableInitialSelectedRows}
      skipPageReset
    />
  );
};
