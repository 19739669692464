import React, { useEffect, useRef } from "react";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Header from "./components/Header/Header";

import SelectionSection from "./components/SelectionSection/SelectionSection";
import DatasetContextProvider from "./contexts/Dataset/DatasetContextProvider";
import SourceContextProvider from "./contexts/Source/SourceContextProvider";
import ContentSection from "./components/ContentSection/ContentSection";
import clsx from "clsx";
import useStyles from "./Dataset.styles";
import UploadContextProvider from "./contexts/Upload/UploadContextProvider";
import useFooterActionsObserver from "./hooks/useFooterActionsObserver";
import useStoreSelectors from "./hooks/useStoreSelectors";
import { useCancelApis } from "src/utils/useCancelApis";

const Dataset = () => {
  const { datasetIsSelectionSectionOpenStore, datasetIsResettingStore } = useStoreSelectors();

  const classes = useStyles();

  useFooterActionsObserver();

  return (
    <>
      <Header datasetIsResettingStore={datasetIsResettingStore} />

      <Box className={classes.root}>
        <Grid container className={classes.container}>
          <Grid
            item
            {...(!!datasetIsSelectionSectionOpenStore
              ? {
                  xs: 6,
                  md: 4,
                  lg: 3,
                  xl: 2
                }
              : {})}
            className={clsx("leftContainer", {
              closed: !datasetIsSelectionSectionOpenStore
            })}>
            <SelectionSection />
          </Grid>
          <Grid
            item
            {...(!!datasetIsSelectionSectionOpenStore
              ? {
                  xs: 6,
                  md: 8,
                  lg: 9,
                  xl: 10
                }
              : {})}
            className={clsx("rightContainer", {
              closed: !datasetIsSelectionSectionOpenStore
            })}>
            <ContentSection />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const DatasetWrapper = () => {
  const { newCancelApiToken, cancelApi } = useCancelApis();
  const cancelApiTokenRef = useRef<$TSFixMe>(null);

  useEffect(() => {
    if (!cancelApiTokenRef.current) {
      cancelApiTokenRef.current = newCancelApiToken();
    }
  }, []);

  return (
    <DatasetContextProvider cancelApi={cancelApi}>
      <SourceContextProvider>
        <UploadContextProvider cancelApiTokenRef={cancelApiTokenRef}>
          <Dataset />
        </UploadContextProvider>
      </SourceContextProvider>
    </DatasetContextProvider>
  );
};

export default DatasetWrapper;
