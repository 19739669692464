/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import {
  saveTransformByIdWithRethrow,
  updateTransformByIdWithRethrow,
  addTransformGroupWithRethrow,
  getTransformGroupWithRethrow
} from "src/api";
import { Recipe, EntityTypeEnum } from "src/types";

export const useSaveTransformMutation = () => {
  return useMutation({
    mutationFn: async ({
      recipe,
      transformData,
      recipeName,
      projectId,
      selectedArtifacts,
      selectedModels,
      selectedInputDatasets,
      conditions
    }: {
      recipe: Recipe | null;
      transformData: $TSFixMe;
      recipeName: string;
      projectId: string;
      selectedArtifacts?: any;
      selectedModels?: any;
      selectedInputDatasets?: any;
      conditions: $TSFixMe;
    }) => {
      const updatedDatasets =
        recipe?.parents?.filter(
          (parent: $TSFixMe) =>
            parent.type !== EntityTypeEnum.ENTITY &&
            parent.type !== EntityTypeEnum.ARTIFACT &&
            parent.type !== EntityTypeEnum.MODEL
        ) || [];
      const newEntities =
        selectedInputDatasets?.map((item: $TSFixMe) => ({
          id: item.id,
          type: EntityTypeEnum.ENTITY
        })) || [];
      const newArtifacts =
        selectedArtifacts?.map((artifact: $TSFixMe) => ({
          name: artifact.name,
          type: EntityTypeEnum.ARTIFACT
        })) || [];
      const newModels =
        selectedModels?.map((model: $TSFixMe) => ({
          name: model.name,
          type: EntityTypeEnum.MODEL
        })) || [];
      const updatedParents = [...updatedDatasets, ...newEntities, ...newArtifacts, ...newModels];
      const updatedPayload = {
        ...recipe,
        projectId,
        name: recipe?.name || recipeName,
        displayName: recipe?.displayName || recipe?.name || recipeName,
        parents: updatedParents || recipe?.parents,
        condition: {
          expression: conditions
        }
      };
      const createdGroup = await (recipe?.id
        ? getTransformGroupWithRethrow(recipe?.id)
        : addTransformGroupWithRethrow(updatedPayload, projectId));

      const transformGroupResponse = Array.isArray(createdGroup) ? createdGroup[0] : createdGroup;
      const currentConfigIds =
        transformGroupResponse?.runConfigs?.map((config: $TSFixMe) => config.id) || [];

      const isEditing = currentConfigIds?.includes(transformData?.id);
      const transformPayload = {
        ...transformData,
        variables: transformData.inputValues,
        groupId: transformGroupResponse?.id,
        ...(isEditing ? { configId: transformData.id } : {})
      };
      try {
        const transformResponse = await (isEditing
          ? await updateTransformByIdWithRethrow(transformPayload)
          : await saveTransformByIdWithRethrow(transformPayload));

        return {
          transformGroupResponse: {
            ...transformGroupResponse,
            runConfigs: isEditing
              ? transformGroupResponse?.runConfigs?.map((config: any) =>
                  config.id === transformResponse.id ? transformResponse : config
                )
              : [...transformGroupResponse?.runConfigs, transformResponse]
          },
          transformResponse
        };
      } catch (error) {
        return { transformGroupResponse, transformResponse: null };
      }
    },
    cacheTime: 0
  });
};
