import React from "react";

// Packages
import { includes } from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

// Hooks
import useDeleteSegment from "src/hooks/api/projects/useDeleteSegment";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { DeleteSegmentPromptDetails, SegmentHelperText } from "../utils/Segment.constants";

type Props = {
  segmentId: string;
  resetDeleteSegment: () => void;
  onDelete: () => void;
};

const DeleteSegment = (props: Props) => {
  const { segmentId, resetDeleteSegment, onDelete } = props || {};

  const {
    isLoading: isSegmentDeleting,
    mutateAsync: deleteSegmentMutation,
    reset: resetDeleteSegmentMutation
  } = useDeleteSegment();

  const deleteSegment = async () => {
    resetDeleteSegmentMutation();

    await deleteSegmentMutation(
      { segmentId },
      {
        onSuccess: () => {
          toastWrapper({ type: ToastTypes.Success, content: SegmentHelperText.SegmentDeleted });
          onDelete();
        },
        onError: () => resetDeleteSegment()
      }
    );
  };

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={DeleteSegmentPromptDetails.title}
      content={[DeleteSegmentPromptDetails.messageLine1, DeleteSegmentPromptDetails.messageLine2]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetDeleteSegment();
        }
      }}
      onSubmit={deleteSegment}
      isCancelDisabled={isSegmentDeleting}
      isSubmitDisabled={isSegmentDeleting}
      isSubmitting={isSegmentDeleting}
      hideCloseIcon
    />
  );
};

export default DeleteSegment;
