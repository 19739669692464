import React, { createContext, useContext } from "react";
import {
  ThreadResponseDtoDatasetContextEnum,
  ThreadResponseDtoTargetTypeEnum
} from "openapi/Models/thread-response-dto";

export type AIGuideProps = {
  projectId: string;
  scenarioId: string;
  modelId?: string;
  chartId?: string;
  diamondId?: string;
  datasetId?: string;
  chartName?: string;
  targetType: ThreadResponseDtoTargetTypeEnum;
  datasetContext?: ThreadResponseDtoDatasetContextEnum;
};

export interface AIGuideModalProps extends AIGuideProps {
  onClose: () => void;
}

interface AIGuideContextProviderProps extends AIGuideModalProps {
  children: React.ReactNode;
}

//@ts-expect-error
const AIGuideContext = createContext<AIGuideModalProps>();

const useAIGuideContext = () => useContext(AIGuideContext);

const AIGuideContextProvider = ({ children, ...restProps }: AIGuideContextProviderProps) => {
  return (
    <AIGuideContext.Provider
      value={{
        ...restProps
      }}>
      {children}
    </AIGuideContext.Provider>
  );
};

export { AIGuideContext, useAIGuideContext, AIGuideContextProvider };
