import React, { useMemo, useState } from "react";

// Packages
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Theme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Icons
import { EyeIcon } from "src/icons/NewUX";
import PredictionServiceIcon from "src/icons/NewUX/PredictionServiceIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { WebPaths } from "src/routing/routes";

// Components
import DeleteNodeModal, { psMessage } from "src/pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import usePredictionServiceByModel from "src/hooks/usePredictionServiceByModel";
import { TABS } from "pages/Library/ArtifactsAndModels/Models/ModelDetails";
import NodeActionWrapper from "../NodeActionWrapper";
import { AIGuideMiniButton } from "src/components/Buttons/AIGuideMiniButton";

// Constants
import { newTheme as theme } from "src/styles";

// Types
import { NodeData } from "src/types";

type Props = {
  closeContextMenu: () => void;
  isDefaultScenario: boolean;
  data: NodeData;
  children?: React.ReactNode;
  openAIGuideDialog: () => void;
};

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    "& button": {
      minWidth: theme.spacing(4),
      "& svg": {
        color: theme.palette.common.white
      }
    },
    "& .borderLeft": {
      borderLeft: "1px solid #ffffff4d",
      borderRadius: "initial"
    }
  }
}));

const ContextMenu = (props: Props) => {
  const { closeContextMenu, isDefaultScenario, data, children, openAIGuideDialog } = props || {};

  const location = useLocation();
  const navigate = useNavigate();

  const classes = useStyles();

  const isBuilt = useMemo(() => ["BUILT"].includes(data?.status), [data?.status]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { isLoading, data: predictionService } = usePredictionServiceByModel(
    data?.name,
    data?.scenarioId,
    data?.jobProps?.jobRunId
  );

  const onView = () => {
    if (!data?.name) {
      return;
    }

    let path = generatePath(WebPaths.ModelDetails, {
      modelName: data?.name
    });

    if (!!data?.jobProps) {
      if (
        !data?.isJobCanvasPath ||
        !areAllKeysPresentAndNotNil(data?.jobProps, ["jobId", "jobRunId"])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobModels}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        modelName: data?.name
      });
    }

    !!path && navigate({ pathname: path }, { state: { prevPath: location.pathname } });
  };

  const onPredictionServiceView = () => {
    if (!data?.name) {
      return;
    }

    navigate(
      generatePath(WebPaths.ModelDetails, {
        modelName: data?.name
      }),
      {
        state: {
          prevPath: location.pathname,
          tab: TABS.predictionService.value,
          predictionServiceDetails: predictionService?.predictionServiceDetails
        }
      }
    );
  };

  const navigateToAIGuide = () => {
    openAIGuideDialog();
    closeContextMenu();
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const buttonComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath) {
        return [];
      }
    }

    items.push(
      <NodeActionWrapper key="view" title="View">
        <IconButton size="small" onClick={onView}>
          <EyeIcon viewBox="0 0 20 20" />
        </IconButton>
      </NodeActionWrapper>
    );

    if (!data?.isJobCanvas && !!isDefaultScenario) {
      items.push(
        <ButtonGroup key="buttonGroup" variant="text" size="small" className="borderLeft">
          {!!isLoading ? (
            <IconButton size="small" disabled>
              <CircularProgress size={theme.spacing(1.75)} />
            </IconButton>
          ) : (
            <NodeActionWrapper title="Prediction Service">
              <IconButton size="small" onClick={onPredictionServiceView}>
                <PredictionServiceIcon />
              </IconButton>
            </NodeActionWrapper>
          )}
        </ButtonGroup>
      );

      if (!data?.isJobCanvas && !!isDefaultScenario && isBuilt) {
        items.push(
          <NodeActionWrapper title="AI Guide">
            <Box className="borderLeft">
              <AIGuideMiniButton
                width={24}
                height={14}
                viewBox="4 -2 48 39"
                badgeStyleProps={{
                  marginTop: -2,
                  marginRight: 4,
                  marginLeft: 4
                }}
                badgeDotStyleProps={{
                  marginTop: 4,
                  marginRight: 0
                }}
                onClick={navigateToAIGuide}
                targetId={data?.itemId!}
                projectId={data?.projectId!}
              />
            </Box>
          </NodeActionWrapper>
        );
      }

      items.push(
        <NodeActionWrapper key="delete" title="Delete">
          <IconButton size="small" className="borderLeft" onClick={onDelete}>
            <TrashIcon viewBox="0 0 20 22" />
          </IconButton>
        </NodeActionWrapper>
      );
    }

    return items;
  }, [data?.isJobCanvas, data?.isJobCanvasPath, isDefaultScenario, isLoading]);

  return (
    <>
      {!!isDeleteModalOpen && (
        <DeleteNodeModal
          open
          nodeId={data?.id}
          nodeName={data?.label}
          nodeType="model"
          deleteNote={
            <div>
              <span>Note: Deleting this might impact associated DataApps (if any). </span>
              {psMessage}
            </div>
          }
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          onAfterSubmit={closeContextMenu}
        />
      )}

      {(React.isValidElement(children) || !isEmpty(buttonComponents)) && (
        <ButtonGroup variant="text" size="small" className={classes.root}>
          {!isEmpty(buttonComponents) && buttonComponents}
          {children}
        </ButtonGroup>
      )}
    </>
  );
};

export default ContextMenu;
