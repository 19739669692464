import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  Typography,
  makeStyles
} from "@material-ui/core";
import { filter, size } from "lodash";

import Analytics from "src/utils/analytics";
import ReleaseNotesDrawer from "./ReleaseNotes/ReleaseNotesDrawer";
import VersionIcon from "src/icons/NewUX/VersionIcon";
import VersionList from "./VersionList";
import VideoLibraryIcon from "src/icons/NewUX/VideoLibraryIcon";
import VideoList, { IVideo } from "./VideoList";
import VideoModal from "./VideoModal";
import { EVENTS } from "src/constants/events";
import { ReleaseNotesConfig } from "src/constants";
import { ReleaseNotesIcon } from "src/icons/NewUX";
import { SupportIcon } from "src/icons/NewUX/SupportIcon";
import { UserGuideIcon } from "src/icons/NewUX/UserGuideIcon";
import { docsToRouteMapping } from "src/routing/docsToRouteMapping";
import { getDocsUrl } from "src/helpers/helpers";
import { matchPath } from "react-router-dom";
import { useGetReleaseNotes } from "src/hooks/api/releaseNotes";

export function getDocsUrlForRoute(url: string) {
  for (let route of docsToRouteMapping) {
    const match = matchPath({ path: route.path, end: true }, url);
    if (match) {
      return `${getDocsUrl()}${route.url}`;
    }
  }
  return getDocsUrl();
}

interface IProps {
  menuType: IMenuType;
}

const useStyles = makeStyles<Theme, IProps>(() => ({
  menuPaper: {
    borderRadius: "20px",
    maxWidth: "410px",
    background: "linear-gradient(34.82deg, #DCDCFF -1.9%, #FFE0CE 107.96%)",
    boxShadow: "0px 4px 4px 0px #3A3A3A40",
    border: "1px solid #FFFFFF",
    width: ({ menuType }) =>
      menuType === "video" ? "380px" : menuType === "version" ? "320px" : "250px"
  },
  subTitle: {
    fontSize: "12px",
    marginBottom: "10px"
  },
  wrap: {
    background: "#fff",
    border: "1px solid #C7C7C7",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "20px",
    width: "100%",
    padding: "10px 0px"
  },
  videos: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "6px"
  }
}));

type IMenuType = "video" | "version" | null;

const TopNavBarHelpMenu: React.FC = () => {
  const [menuType, setMenuType] = useState<IMenuType>(null);

  const classes = useStyles({ menuType });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<IVideo | null>(null);
  const [isReleaseNotes, setIsReleaseNotes] = useState(false);

  // Query hooks - STARTS >>
  // Queries
  const { data: releaseNotes } = useGetReleaseNotes();
  // << ENDS - Query hooks

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeMenuType = (type: IMenuType) => {
    setMenuType(type);
  };

  const handleSelectVideo = (video: IVideo) => {
    setSelectedVideo(video);
    handleClose();
  };

  const handleUserGuide = useCallback(() => {
    Analytics.track({
      category: EVENTS.menuItemClick.category,
      action: EVENTS.menuItemClick.action,
      label: "User Guide"
    });

    window.open(getDocsUrlForRoute(window.location.pathname) ?? getDocsUrl(), "_blank");
  }, [window.location]);

  const handleSupport = () => {
    Analytics.track({
      category: EVENTS.menuItemClick.category,
      action: EVENTS.menuItemClick.action,
      label: "Support"
    });

    window.open("https://rapidcanvas.atlassian.net/servicedesk/customer/portals", "_blank");
  };

  const handleExited = () => {
    setMenuType(null);
  };

  const isReleaseNotesAction = useMemo(
    () => size(filter(releaseNotes, ["name", ReleaseNotesConfig.CurrentReleaseName])) > 0,
    [releaseNotes]
  );

  return (
    <>
      {isReleaseNotes && <ReleaseNotesDrawer onClose={() => setIsReleaseNotes(() => false)} />}

      <IconButton data-testid="topNavBarHelpButton" onClick={handleClick}>
        <Typography color="textPrimary" style={{ opacity: 0.5 }}>
          <HelpOutlineIcon fontSize="small" />
        </Typography>
      </IconButton>
      <Menu
        classes={{
          paper: classes.menuPaper
        }}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        TransitionProps={{
          onExited: handleExited
        }}
        transformOrigin={{ horizontal: menuType === "video" ? "right" : "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={handleClose}>
        <Box p="20px">
          <Typography className={classes.subTitle} test-id="tenant-header">
            HELP
          </Typography>
          <div className={classes.wrap}>
            {menuType === "video" ? (
              <VideoList onClose={() => setMenuType(null)} onSelectVideo={handleSelectVideo} />
            ) : menuType === "version" ? (
              <VersionList onClose={() => setMenuType(null)} />
            ) : (
              <div>
                <MenuItem
                  style={{ color: "#515151" }}
                  data-testid="help-menu-videos"
                  onClick={() => handleChangeMenuType("video")}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <div className={classes.videos}>
                      <ListItemIcon style={{ minWidth: 0 }}>
                        <VideoLibraryIcon />
                      </ListItemIcon>
                      <Typography variant="body2">How to Videos</Typography>
                    </div>
                    <ChevronRightIcon fontSize="small" />
                  </Grid>
                </MenuItem>
                <MenuItem
                  style={{ color: "#515151" }}
                  data-testid="user-guide-btn"
                  onClick={handleUserGuide}>
                  <div className={classes.videos}>
                    <ListItemIcon style={{ minWidth: 0 }}>
                      <UserGuideIcon width={20} height={20} />
                    </ListItemIcon>
                    <Typography variant="body2">User Guide</Typography>
                  </div>
                </MenuItem>
                <MenuItem
                  style={{ color: "#515151" }}
                  data-testid="support-btn"
                  onClick={handleSupport}>
                  <div className={classes.videos}>
                    <ListItemIcon style={{ minWidth: 0 }}>
                      <SupportIcon width={18} height={18} />
                    </ListItemIcon>
                    <Typography variant="body2">Support</Typography>
                  </div>
                </MenuItem>
                {isReleaseNotesAction && (
                  <MenuItem
                    style={{ color: "#515151" }}
                    data-testid="release-notes-btn"
                    onClick={() => {
                      setIsReleaseNotes(() => true);
                      handleClose();
                    }}>
                    <div className={classes.videos}>
                      <ListItemIcon style={{ minWidth: 0 }}>
                        <ReleaseNotesIcon />
                      </ListItemIcon>
                      <Typography variant="body2">Latest Release Notes</Typography>
                    </div>
                  </MenuItem>
                )}
                <MenuItem
                  style={{ color: "#515151" }}
                  data-testid="help-menu-versions"
                  onClick={() => handleChangeMenuType("version")}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <div className={classes.videos}>
                      <ListItemIcon style={{ minWidth: 0 }}>
                        <VersionIcon />
                      </ListItemIcon>
                      <Typography variant="body2">Version</Typography>
                    </div>
                    <ChevronRightIcon fontSize="small" />
                  </Grid>
                </MenuItem>
              </div>
            )}
          </div>
        </Box>
      </Menu>
      {selectedVideo && (
        <VideoModal
          open={!!selectedVideo}
          video={selectedVideo}
          onSelectVideo={setSelectedVideo}
          onClose={() => setSelectedVideo(null)}
        />
      )}
    </>
  );
};

export default TopNavBarHelpMenu;
