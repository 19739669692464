import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

type Props = {
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
};

export const RevertChangesConfirmModal = ({ loading, handleClose, handleSubmit, open }: Props) => {
  return (
    <Modal
      open={open}
      variant={ModalVariants.Delete}
      title="Revert"
      content={["Do you want to revert all your changes?"]}
      cancelLabel="No"
      submitLabel="Yes"
      isSubmitting={loading}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};
