/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface GetVerticalsResponseDto
 */
export interface GetVerticalsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetVerticalsResponseDto
     */
    'name'?: GetVerticalsResponseDtoNameEnum;
    /**
     * 
     * @type {string}
     * @memberof GetVerticalsResponseDto
     */
    'value'?: string;
}

export const GetVerticalsResponseDtoNameEnum = {
    RetailAndEcommerce: 'RETAIL_AND_ECOMMERCE',
    FinanceAndBanking: 'FINANCE_AND_BANKING',
    ManufacturingAndIndustry: 'MANUFACTURING_AND_INDUSTRY',
    EnergyAndUtilities: 'ENERGY_AND_UTILITIES',
    TransportationAndLogistics: 'TRANSPORTATION_AND_LOGISTICS',
    HealthcareAndLifeSciences: 'HEALTHCARE_AND_LIFE_SCIENCES',
    EntertainmentAndMedia: 'ENTERTAINMENT_AND_MEDIA',
    AgricultureAndFoodTechnology: 'AGRICULTURE_AND_FOOD_TECHNOLOGY',
    RealEstateAndUrbanPlanning: 'REAL_ESTATE_AND_URBAN_PLANNING',
    EducationAndLearning: 'EDUCATION_AND_LEARNING',
    Others: 'OTHERS'
} as const;

export type GetVerticalsResponseDtoNameEnum = typeof GetVerticalsResponseDtoNameEnum[keyof typeof GetVerticalsResponseDtoNameEnum];


