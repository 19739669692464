// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Open API
import api from "utils/AxiosClient";
import { EntityDto } from "openapi/Models";

export const enum UseGetDatasetsQueryKeys {
  Datasets = "datasets"
}

interface IDatasetsProps extends UseQueryOptions<unknown, unknown, EntityDto[]> {
  projectId?: string;
  scenarioId?: string;
  jobRunId?: string;
  options?: UseQueryOptions<EntityDto>;
}

const useGetDatasets = (props: IDatasetsProps): UseQueryResult<EntityDto[]> => {
  const { projectId, scenarioId, jobRunId, ...options } = props;

  return useQuery({
    queryKey: [UseGetDatasetsQueryKeys.Datasets, projectId, jobRunId],
    queryFn: async () => {
      if (!projectId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.EntityControllerApi.findEntities("", "", projectId, scenarioId, jobRunId)
      );
    },
    select: (data) => (isArray(data) ? data : []),
    enabled: !!projectId,
    ...options
  });
};

export default useGetDatasets;
