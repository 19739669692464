import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { Environment } from "pages/private/Environments/Environments";
import { putAPIWithRethrow } from "src/utils/apiService";

const useUpdateEnvironment = (): UseMutationResult<unknown, unknown, Environment, unknown> => {
  return useMutation({
    mutationFn: async (body) => {
      const { id } = body;
      return await putAPIWithRethrow(`/v2/envs/${id}?async=true`, body);
    }
  });
};

export default useUpdateEnvironment;
