import React, { useMemo } from "react";
import _ from "lodash";
import RemoveMarkdown from "remove-markdown";
import { Box, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";

import AIGuideTextRow from "./AIGuideTextRow";
import { TextIconV2 } from "src/icons/NewUX/TextIconV2";

import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { AskAICodeEditor } from "../../common/AskAICodeEditor";
import { AskAICodeContainer } from "../../AddCodeRecipe/CodeRecipeTabContainer/AskAIContainer/AskAIResponseContainer/AskAICodeContainer";
import { ExpandLess } from "@material-ui/icons";
import { EntityIconV3 } from "src/icons/NewUX/EntityIconV3";
import { ModelIcon } from "src/icons/NewUX/ModelIcon";
import { ChartIconV3 } from "src/icons/NewUX/ChartIconV3";

const useStyles = makeStyles({
  textIcon: {
    height: "32px",
    width: "32px"
  },
  collapsedText: {
    "& > *": {
      marginBottom: "0px !important"
    }
  },
  title: {
    textWrap: "wrap !important",
    wordBreak: "break-word",
    fontWeight: 400,
    color: "#515151",
    fontSize: "14px"
  },
  codeRow: {
    padding: "0px 32px 0px 12px",
    width: "inherit",
    gap: "20px",
    flexWrap: "nowrap"
  },
  entityIcon: {
    height: "32px",
    width: "24px !important",
    minWidth: "24px"
  },
  modelIcon: {
    width: "24px",
    minWidth: "24px",
    background: "#fff",
    borderRadius: "50%",
    height: "24px",
    flexWrap: "nowrap",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  chartIcon: {
    height: "32px",
    width: "24px !important"
  }
});

export interface IError {
  errMessage?: string;
  errorType?: string;
  explanation?: string;
  lineInCode?: string;
  lineNo?: number;
  originalErrMessage?: string;
}

const GENERIC_ERROR_RESPONSE = "AI model generated invalid response. Please try again.";
const AIGuideTextResponse = ({
  message,
  isExpanded,
  setIsExpanded,
  shouldShowCode,
  isOutputDataset,
  isOutputChart,
  isOutputModel,
  explainedCode
}: {
  message: AIChatResponseDto;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  hasMarkDown?: boolean;
  rightContainer?: React.ReactNode;
  shouldShowCode: boolean;
  isOutputDataset?: boolean;
  isOutputChart?: boolean;
  isOutputModel?: boolean;
  explainedCode?: string;
}) => {
  const classes = useStyles();

  const { outputEntityList, outputEntityResponseList, sampleRows, code } = message;
  const error = message.error as IError | null;

  const getErrorPreference = (e: IError) => {
    if (!e) {
      return {
        collapsedText: GENERIC_ERROR_RESPONSE,
        expandedText: GENERIC_ERROR_RESPONSE
      };
    }

    if (_.includes(["LLM_DOWN", "LLM_GENERATED_INVALID_TAGS_RESPONSE"], e.errorType)) {
      return {
        collapsedText: e.errMessage ?? e.originalErrMessage ?? GENERIC_ERROR_RESPONSE,
        expandedText: e.errMessage ?? e.originalErrMessage ?? GENERIC_ERROR_RESPONSE
      };
    }

    if (e.explanation) {
      return {
        collapsedText: e.explanation,
        expandedText: e.explanation
      };
    }

    if (e.errMessage) {
      return {
        collapsedText: e.errMessage,
        expandedText: _.join([e.errMessage, e.originalErrMessage], "\n- **More Details:** ")
      };
    }

    if (e.originalErrMessage) {
      return {
        collapsedText: e.originalErrMessage,
        expandedText: e.originalErrMessage
      };
    }

    return {
      collapsedText: GENERIC_ERROR_RESPONSE,
      expandedText: GENERIC_ERROR_RESPONSE
    };
  };

  const { collapsedText, isError, expandedText } = useMemo(() => {
    const outputText = _.join(
      _.map(outputEntityResponseList || outputEntityList, "textAnswer"),
      "<br/>"
    );
    const isAnyErrorMsgExists =
      !!error?.explanation || !!error?.errMessage || !!error?.originalErrMessage;

    const isError = isAnyErrorMsgExists || !outputText;

    if (isError || error) {
      const { collapsedText, expandedText } = getErrorPreference(error!);

      return {
        isError,
        collapsedText: _.split(collapsedText, "\n")?.[0],
        expandedText
      };
    } else {
      const firstResponse =
        (_.first(outputEntityResponseList) || _.first(outputEntityList))?.textAnswer ?? "Answer";

      return {
        collapsedText: RemoveMarkdown(_.split(firstResponse, "\n")?.[0], {
          stripListLeaders: true,
          listUnicodeChar: "",
          gfm: true
        }),
        isError: false,
        expandedText: outputText ?? "Answer"
      };
    }
  }, [error, outputEntityResponseList, outputEntityList]);

  const errorMsg = useMemo(
    () => (isError ? getErrorPreference(error!).expandedText : null),
    [isError]
  );

  const icon = useMemo(
    () =>
      isOutputDataset ? (
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          wrap="nowrap"
          className={classes.entityIcon}>
          <EntityIconV3 />
        </Grid>
      ) : isOutputModel ? (
        <Grid
          test-id="ask-ai-modal-rc-icon-container"
          container
          alignItems="center"
          justifyContent="center"
          className={classes.modelIcon}>
          <ModelIcon width={14} height={14} viewBox="-3 -3 24 24" color="#7C7C7C" />
        </Grid>
      ) : isOutputChart ? (
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          wrap="nowrap"
          className={classes.chartIcon}>
          <ChartIconV3 />
        </Grid>
      ) : (
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          wrap="nowrap"
          className={classes.textIcon}>
          <TextIconV2 />
        </Grid>
      ),
    [isOutputChart, isOutputDataset, isOutputModel]
  );
  return (
    <Grid container direction="column" wrap="nowrap">
      {!isExpanded ? (
        <Grid className={classes.collapsedText}>
          <AIGuideTextRow
            userInput={collapsedText}
            color={isError ? "#FFDBDD" : "#fff"}
            hoverBgColor={isError ? "#FFDBDD" : "#fff"}
            textColor={isError ? "#515151" : "#4646B5"}
            isError={isError}
            onExpand={() => setIsExpanded(true)}
            canCopy={false}
            noPadding
            disableMarkdownPadding
            isMarkDown
            icon={icon}
          />
        </Grid>
      ) : shouldShowCode && code && errorMsg ? (
        <Grid container direction="row" className={classes.codeRow}>
          {icon}
          <Box width="calc(100% - 160px)">
            <AskAICodeContainer
              title={errorMsg || "Python"}
              errDetails={error ? ({ ...error, lineOfCode: error?.lineInCode } as any) : {}}
              editorValue={explainedCode ?? code}
              toggleExpanded={() => setIsExpanded(false)}
              buttons={[
                {
                  component: (
                    <IconButton size="small" data-testid="ai-text-collapse-btn">
                      <ExpandLess fontSize="small" />
                    </IconButton>
                  )
                }
              ]}
              background={"#FFDBDD"}
            />
          </Box>
        </Grid>
      ) : (
        <Grid container direction="column" wrap="nowrap">
          <AIGuideTextRow
            width="calc(100% - 134px)"
            color={isError ? "#FFDBDD" : "#fff"}
            hoverBgColor="#4646b5"
            userInput={expandedText}
            isError={isError}
            textColor="#003656"
            copyTitle="Copy Answer"
            canCopy={!isError}
            children={
              shouldShowCode && code && errorMsg ? (
                <Grid container direction="column" wrap="nowrap">
                  <Typography data-testid="ai-text-error-msg" className={classes.title}>
                    {errorMsg}
                  </Typography>
                  <AskAICodeEditor
                    noMargin
                    editorValue={explainedCode ?? code}
                    errDetails={
                      error ? ({ ...error, lineOfCode: (error as any)?.lineInCode } as any) : {}
                    }
                  />
                </Grid>
              ) : undefined
            }
            isMarkDown
            noPadding
            onCollapse={() => setIsExpanded(false)}
            icon={icon}
            sampleRows={sampleRows}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(AIGuideTextResponse);
