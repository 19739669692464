import React from "react";

export const PredictionServiceIcon: React.FC = () => {
  return (
    <svg
      width="109"
      height="109"
      viewBox="0 0 109 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <path
          d="M58.7919 29.3789C64.2662 29.3789 68.7041 24.9411 68.7041 19.4667C68.7041 13.9924 64.2662 9.55453 58.7919 9.55453C53.3175 9.55453 48.8797 13.9924 48.8797 19.4667C48.8797 24.9411 53.3175 29.3789 58.7919 29.3789Z"
          stroke="black"
          strokeWidth="5.10938"
        />
        <path
          d="M91.3386 63.7309C96.813 63.7309 101.251 59.2931 101.251 53.8187C101.251 48.3444 96.813 43.9066 91.3386 43.9066C85.8643 43.9066 81.4265 48.3444 81.4265 53.8187C81.4265 59.2931 85.8643 63.7309 91.3386 63.7309Z"
          stroke="black"
          strokeWidth="5.10938"
        />
        <path
          d="M58.7919 96.0392C64.2662 96.0392 68.7041 91.6014 68.7041 86.127C68.7041 80.6527 64.2662 76.2148 58.7919 76.2148C53.3175 76.2148 48.8797 80.6527 48.8797 86.127C48.8797 91.6014 53.3175 96.0392 58.7919 96.0392Z"
          stroke="black"
          strokeWidth="5.10938"
        />
        <path
          d="M25.8193 81.4945C31.2937 81.4945 35.7315 77.0567 35.7315 71.5823C35.7315 66.108 31.2937 61.6702 25.8193 61.6702C20.345 61.6702 15.9071 66.108 15.9071 71.5823C15.9071 77.0567 20.345 81.4945 25.8193 81.4945Z"
          stroke="black"
          strokeWidth="5.10938"
        />
        <path
          d="M25.8193 42.7314C31.2937 42.7314 35.7315 38.2936 35.7315 32.8192C35.7315 27.3449 31.2937 22.907 25.8193 22.907C20.345 22.907 15.9071 27.3449 15.9071 32.8192C15.9071 38.2936 20.345 42.7314 25.8193 42.7314Z"
          stroke="black"
          strokeWidth="5.10938"
        />
        <path
          d="M58.7749 57.8552C63.1958 57.8552 66.7796 54.2713 66.7796 49.8505C66.7796 45.4296 63.1958 41.8458 58.7749 41.8458C54.354 41.8458 50.7702 45.4296 50.7702 49.8505C50.7702 54.2713 54.354 57.8552 58.7749 57.8552Z"
          stroke="black"
          strokeWidth="5.10938"
        />
        <path d="M34.3521 27.7609L49.2204 22.0214" stroke="black" strokeWidth="5.10938" />
        <path d="M65.7406 26.5517L84.2706 46.87" stroke="black" strokeWidth="5.10938" />
        <path d="M85.2756 61.6531L65.8599 79.1783" stroke="black" strokeWidth="5.10938" />
        <path d="M31.2694 41.0964L52.6436 78.3608" stroke="black" strokeWidth="5.10938" />
        <path d="M34.5905 76.1978L48.8797 82.823" stroke="black" strokeWidth="5.10938" />
        <path d="M29.5321 62.3855L53.427 27.795" stroke="black" strokeWidth="5.10938" />
        <path d="M34.9482 36.7023L51.6729 46.1547" stroke="black" strokeWidth="5.10938" />
        <path d="M66.7967 50.7531L81.4265 51.8602" stroke="black" strokeWidth="5.10938" />
        <path d="M58.7749 57.8722L58.7919 76.1978" stroke="black" strokeWidth="5.10938" />
      </g>
    </svg>
  );
};
