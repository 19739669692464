import React, { useMemo } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { UseTableRowProps } from "react-table";
import { makeStyles } from "@material-ui/core";

import Text from "src/components/Widget/Text";
import { Delete } from "../../../../icons/Delete";
import { LinkCellWithMenu } from "../../../../components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { SplashIcon } from "src/icons/NewUX";
import { SplashSection, Table } from "../../../../components";
import { dateFormat } from "src/utils/dateFormat";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "calc(100vh - 64px - 254px)",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.grey[700]
  },
  gridIcon: {
    height: 215,
    width: 215,
    marginRight: 140
  }
}));

export const getSourceVal = (value: any) => {
  return value
    ? `${value?.recipeName} ${value?.projectName ? `(${value?.projectName})` : ""}`
    : "Manual addition";
};

const ArtifactsTable = ({ data, onDelete, openCreationModal, children }: $TSFixMe) => {
  const { gridContainer, gridIcon } = useStyles();

  const sourceSortType = useMemo(
    () => (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
      const valA = getSourceVal(_.get(rowA.original, "producer"));
      const valB = getSourceVal(_.get(rowB.original, "producer"));

      if (_.lowerCase(valA) > _.lowerCase(valB)) {
        return 1;
      }
      if (_.lowerCase(valA) < _.lowerCase(valB)) {
        return -1;
      }

      return 0;
    },
    []
  );

  const columns = [
    {
      id: "Name",
      accessor: "name",
      Header: "Name",
      isSortable: true,
      isTooltip: false,
      Cell: ({ row }: $TSFixMe) =>
        !row?.original?.producer ? (
          <LinkCellWithMenu
            url={`/artifacts-and-models/artifact/${row.original.name}`}
            linkText={<Text value={row.original.name} />}
            menuButtons={[
              {
                label: "DELETE",
                icon: <Delete />,
                action: () => onDelete(row?.original?.name)
              }
            ]}
          />
        ) : (
          <Link className="name-link" to={`/artifacts-and-models/artifact/${row.original.name}`}>
            {row.original.name}
          </Link>
        )
    },
    {
      id: "Updated on",
      accessor: "updated",
      Header: "Updated on",
      isSortable: true,
      Cell: ({ value }: $TSFixMe) => <span>{dateFormat(value, false)}</span>
    },
    {
      id: "Source",
      accessor: "producer",
      Header: "Source",
      isSortable: true,
      sortType: sourceSortType,
      Cell: ({ value }: $TSFixMe) => getSourceVal(value)
    }
  ];

  return (
    <>
      {data.length ? (
        <Table
          data={data}
          size="small"
          columns={columns}
          isCellSortEnabled
          orderByDefault="Updated on"
          sortInverted
          skipPageReset
          maxHeight="calc(100vh - 248px)"
          isTheadSticky
        />
      ) : (
        children ?? (
          <NewThemeWrapper>
            <SplashSection
              gridContainerStyle={gridContainer}
              gridIconStyle={gridIcon}
              button={{
                color: "primary",
                onClick: openCreationModal,
                label: "+ Create Artifact"
              }}
              titleSection={{
                title: "Get Started with a New Artifact",
                subtitleLines: [
                  "Welcome to the artifacts page.",
                  "Create a new one by clicking on the button below."
                ]
              }}
              icon={<SplashIcon />}
            />
          </NewThemeWrapper>
        )
      )}
    </>
  );
};

export default ArtifactsTable;
