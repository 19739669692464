import React from "react";

export const TagOutline = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g opacity="0.5">
        <path
          d="M10.705 5.79L6.205 1.29C6.01751 1.10404 5.76407 0.99979 5.5 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V5.5C0.999896 5.63196 1.02591 5.76263 1.07654 5.88448C1.12717 6.00634 1.20142 6.11697 1.295 6.21L5.795 10.71C5.98249 10.896 6.23593 11.0002 6.5 11C6.76479 10.9989 7.01833 10.8928 7.205 10.705L10.705 7.205C10.8928 7.01833 10.9989 6.76479 11 6.5C11.0001 6.36804 10.9741 6.23737 10.9235 6.11552C10.8728 5.99366 10.7986 5.88303 10.705 5.79ZM6.5 10L2 5.5V2H5.5L10 6.5M3.25 2.5C3.39834 2.5 3.54334 2.54399 3.66668 2.6264C3.79001 2.70881 3.88614 2.82594 3.94291 2.96299C3.99968 3.10003 4.01453 3.25083 3.98559 3.39632C3.95665 3.5418 3.88522 3.67544 3.78033 3.78033C3.67544 3.88522 3.5418 3.95665 3.39632 3.98559C3.25083 4.01453 3.10003 3.99968 2.96299 3.94291C2.82594 3.88614 2.70881 3.79001 2.6264 3.66668C2.54399 3.54334 2.5 3.39834 2.5 3.25C2.5 3.05109 2.57902 2.86032 2.71967 2.71967C2.86032 2.57902 3.05109 2.5 3.25 2.5Z"
          fill="black"
        />
      </g>
    </svg>
  );
};
