export enum EnvironmentsConfig {
  ExemptingWidth = 0.15, // 15%
  CardWidth = 375,
  CardHeight = 370
}

export enum SplashSectionHelperText {
  Title = "Get Started with a New Environment",
  SubLine1 = "Welcome to the Environments page.",
  SubLine2 = "Start a new one by clicking on the button below."
}

export enum EnvironmentDeleteMessage {
  messageLine1 = "Do you really want to delete this environment?",
  messageLine2 = "If you confirm you will delete the environment completely"
}

export enum ConfirmCleanCustomPackagesPromptDetails {
  title = "Confirm Clean Custom Packages",

  messageLine1 = "This action removes custom libraries installed manually or through recipes in this environment and restarts it.",
  messageLine2 = "Are you sure?"
}

export enum EnvironmentsHelperText {
  NewEnvironment = "New Environment",
  NameInfo = "The name of the tenant-level default environment is non-editable",
  DeleteInfo = "Cannot delete a launching environment",
  UsageInfo = "Resource usage data can be accessed when the environment is in a running state",

  DiskSpaceInfo = "Disk space cannot be modified once the environment is created.",

  Save = "Save"
}

export enum EnvironmentHelperText {
  ClearingCacheInfo = "Please wait. Clearing cache.",
  CleaningCustomPackagesInfo = "Please wait. Cleaning custom packages.",
  CleanActionsDisabledInfo = "This action is enabled when the environment is in running state. Please run the environment to enable this action."
}

export const PackagesInfoLines = [
  "To install the latest available version of a specific library, simply state the library's name.",
  "To install a specific version of a library, use the format library_name == version, as shown in the example numpy == 1.19.5."
];
