import React, { useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { useNavigate, useParams, useLocation } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import shallow from "zustand/shallow";
import { isEqual, keyBy, mapValues } from "lodash";
import { Box, Grid, IconButton, makeStyles } from "@material-ui/core";

import { Spinner } from "src/components";
import { useQuery } from "src/hooks";
import {
  useGetDfsTemplates,
  useGetEntityFeatures,
  useGetInputEntities,
  useGetScenario,
  useSaveStandardRecipeMutation,
  useGetTransformGroups,
  useTestTransformGroupV2Mutation,
  useDeleteTransformMutation,
  useSaveTransformMutation
} from "src/hooks/api";
import { useGetJob, useGetJobRun } from "src/hooks/api";
import { useAddRecipeToQueue, UseGetRecipeRunsQueueQueryKeys } from "src/hooks/api/recipes";
import { AddEntitiesDialog } from "./AddEntitiesDialog/AddEntitiesDialog";
import { StandardRecipeHeader } from "./StandardRecipeHeader/StandardRecipeHeader";
import { StandardRecipeInputContainer } from "./StandardRecipeInputContainer/StandardRecipeInputContainer";
import { handleResponse } from "src/utils/apiService";
import { areArraysEqual } from "src/utils/arrayUtils";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";

import { EmptyRecipeContainer } from "./EmptyRecipeContainer/EmptyRecipeContainer";

import {
  ModelMini,
  Entities,
  Entity,
  ArtifactMini,
  Recipe,
  Scenario,
  EntityFeaturesResponse,
  EntityTypeEnum
} from "src/types";
import { StandardRecipeInputsView } from "./StandardRecipeInputContainer/StandardRecipeInputsView";

import { ExpandIcon } from "src/icons/ExpandIcon";
import { useRecipeRun } from "../common/useRecipeRun";
import { useTransformErrorUtils } from "../common/hooks/useTransformErrorUtils";
import { useCanvasStore, useScenariosStore } from "src/store/store";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { RecipeStatuses } from "src/constants";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { OUTPUT_TYPE } from "../AddCodeRecipe/CodeRecipeContext/CodeRecipeContextProvider";

const useStyles = makeStyles({
  noDatasetsContainerWrap: {
    width: "100%",
    height: "calc(100% - 44px)",
    flexWrap: "nowrap",
    paddingTop: "5%",
    alignItems: "center",
    backgroundColor: "#ffffff"
  },
  noDatasetsSvgWrap: {
    position: "relative",
    textAlign: "center"
  },
  addDatasetsText: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    marginBottom: "16px"
  },
  recipeContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    backgroundColor: "#f5f5f5"
  },
  standardRecipeBodyContainer: {
    flex: 1,
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    flexWrap: "nowrap",
    overflow: "hidden"
  },
  gridContainer: {
    height: "100%",
    gap: "18px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    maxWidth: "20%",
    flexBasis: "20%"
  },
  leftContainer: {
    border: "1px solid #BDBDBD",
    borderRadius: "12px",
    height: "fit-content",
    background: "#FFFFFF"
  },
  inputViewContainer: {
    border: "1px solid #BDBDBD",
    borderRadius: "12px",
    background: "#FFFFFF",
    height: "fit-content",
    maxHeight: "100%"
  },
  inputContainer: {
    borderLeft: "1px solid #CECECE",
    maxWidth: ({ isJobPath, isExpanded }: { isJobPath?: boolean; isExpanded: boolean }) =>
      !!isJobPath ? "100%" : isExpanded ? "80%" : "calc(100% - 46px)",
    flexBasis: ({ isJobPath, isExpanded }: { isJobPath?: boolean; isExpanded: boolean }) =>
      !!isJobPath ? "100%" : isExpanded ? "80%" : "calc(100% - 46px)",
    minWidth: 0
  }
});

export const StandardRecipeContainer = ({
  recipe: initialRecipe,
  entityIds,
  artifactNames,
  modelNames
}: $TSFixMe) => {
  const navigate = useNavigate();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const queryParameters = useQuery();
  const queryClient = useQueryClient();

  // Stores - STARTS >>
  const [isRecipesRunningAcrossScenariosStore, isPendingRecipeRunsInQueueStore] = useCanvasStore(
    (state: $TSFixMe) => [state.isRecipesRunningAcrossScenarios, state.isPendingRecipeRunsInQueue],
    shallow
  );
  // << ENDS - Stores

  const [isExpanded, setIsExpanded] = React.useState(true);
  const classes = useStyles({ isJobPath, isExpanded });
  const { projectId, scenarioId, jobId, jobRunId } = useParams<$TSFixMe>();

  const [recipe, setRecipe] = useState<Recipe | null>(initialRecipe || null);
  const [recipeName, setRecipeName] = useState<string>(initialRecipe?.name || "");
  const [recipeDisplayName, setRecipeDisplayName] = useState<string>(
    initialRecipe?.displayName || initialRecipe?.name || ""
  );
  const [scenarioData, setScenarioData] = useState<null | Scenario>(null);
  const [showAddEntitiesDialog, setShowAddEntitiesDialog] = useState<boolean>(false);
  const [entityFeaturesMap, setEntityFeaturesMap] = useState<{
    [id: string]: EntityFeaturesResponse;
  } | null>(null);
  const [selectedInputDatasets, setSelectedInputDatasets] = useState<Array<Entity>>([]);
  const [selectedArtifacts, setSelectedArtifacts] = useState<Array<ArtifactMini>>([]);
  const [selectedModels, setSelectedModels] = useState<Array<ModelMini>>([]);
  const [appliedTransforms, setAppliedTransforms] = useState<Array<$TSFixMe>>([]);
  const [isSelectedEntitiesUpdateInProgess, setIsSelectedEntitiesUpdateInProgess] =
    useState<boolean>(false);

  const [conditions, setConditions] = useState(initialRecipe?.condition?.expression);
  const [previewTabs, setPreviewTabs] = useState<$TSFixMe>([]);

  const saveRecipeMutation = useSaveStandardRecipeMutation();

  const saveAndTestRecipeMutation = useSaveStandardRecipeMutation();
  const testTransformGroupMutation = useTestTransformGroupV2Mutation();
  const saveTransformMutation = useSaveTransformMutation();
  const deleteTransformMutation = useDeleteTransformMutation();
  const { isRunInProgress, onRecipeRun } = useRecipeRun(
    recipe?.status === RecipeStatuses.Running,
    recipe?.id
  );
  const { renderTestTransformErrors } = useTransformErrorUtils();

  const { isLoading, data: allEntities } = useGetInputEntities({
    id: projectId,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (allEntities: Entities) => {
      const selectedEntitiesStr = sessionStorage.getItem("selectedEntities");
      const selectedArtifactsStr = sessionStorage.getItem("selectedArtifacts");
      const selectedModelsStr = sessionStorage.getItem("selectedModels");
      const selectedEntities = selectedEntitiesStr ? JSON.parse(selectedEntitiesStr) : null;
      const selectedArtifacts = selectedArtifactsStr ? JSON.parse(selectedArtifactsStr) : null;
      const selectedModels = selectedModelsStr ? JSON.parse(selectedModelsStr) : null;
      const filteredInputDatasets = allEntities.filter(
        (entity: $TSFixMe) =>
          selectedEntities?.includes(entity.id) ||
          entityIds?.includes(entity.id) ||
          queryParameters?.get("entity") === entity.id
      );
      const selectedArtifactsObj: Array<ArtifactMini> =
        allEntities
          .filter(
            (entity: $TSFixMe) =>
              selectedArtifacts?.includes?.(entity.name) || artifactNames?.includes?.(entity.name)
          )
          ?.map((entity: $TSFixMe) => ({
            name: entity.name,
            type: "ARTIFACT"
          })) || [];
      const selectedModelsObj: Array<ModelMini> =
        allEntities
          .filter(
            (entity: $TSFixMe) =>
              selectedModels?.includes(entity.name) || modelNames?.includes(entity.name)
          )
          ?.map((entity: $TSFixMe) => ({
            name: entity.name,
            type: "MODEL"
          })) || [];
      sessionStorage.setItem("selectedEntities", "");
      setSelectedInputDatasets(filteredInputDatasets);
      setSelectedArtifacts(selectedArtifactsObj);
      setSelectedModels(selectedModelsObj);
    }
  });

  const {
    isLoading: isAddingRecipeToQueue,
    mutateAsync: addRecipeToQueueMutation,
    reset: resetAddRecipeToQueueMutation
  } = useAddRecipeToQueue();

  const { data: jobData } = useGetJob({ projectId, jobId });
  const { data: jobRunData } = useGetJobRun({ jobRunId, isApiWithRethrow: false });

  const scenarios = useScenariosStore((state) => state.scenarios);

  const isEmptyRecipe = useMemo(
    () =>
      selectedInputDatasets?.length === 0 &&
      selectedArtifacts?.length === 0 &&
      selectedModels?.length === 0,
    [selectedInputDatasets?.length, selectedArtifacts?.length, selectedModels?.length]
  );

  const { isFetching: isFetchingRecipes } = useGetTransformGroups({
    projectId,
    enabled: isLoading ? false : !recipeName,
    onSuccess: (groups: Array<Recipe>) => {
      const entityName = allEntities?.find(
        (item: $TSFixMe) => item.id === queryParameters?.get("entity")
      )?.name;
      const allRecipeNames = groups.reduce((acc: Array<string>, item) => {
        return [...acc, item.name?.toLowerCase(), item.displayName?.toLowerCase()];
      }, []);
      let updatedRecipeName = entityName ? `${entityName} Recipe` : `Recipe`;
      if (allRecipeNames?.length) {
        let i = 2;
        while (allRecipeNames?.includes((updatedRecipeName || "")?.toLowerCase())) {
          updatedRecipeName = entityName ? `${entityName} Recipe ${i}` : `Recipe ${i}`;
          i++;
        }
      }
      setRecipeName(updatedRecipeName);
    }
  });

  const { isFetching: isGetEntityFeaturesLoading } = useGetEntityFeatures({
    datasetsToFetch: selectedInputDatasets,
    ...(!!isJobPath ? { scenarioId, jobRunId } : {}),
    onSuccess: (entityFeatures: EntityFeaturesResponse) => {
      const newFeatures = selectedInputDatasets?.reduce(
        (acc, dataset, index) => ({
          ...acc,
          [dataset?.name]: entityFeatures[index]
        }),
        {}
      );
      setEntityFeaturesMap(newFeatures);
    }
  });

  const { isLoading: isFetchingTemplates, data: dfsTemplates } = useGetDfsTemplates({
    projectId: projectId!
  });

  const { isLoading: isScenarioLoading } = useGetScenario({
    scenarioId,
    onSuccess: (data: Scenario) => {
      setScenarioData(data);
    }
  });

  React.useEffect(() => {
    if (recipe?.id && recipe?.runConfigs && dfsTemplates) {
      const appliedTransformsData = recipe?.runConfigs?.map((item: $TSFixMe) => {
        const templateData = dfsTemplates?.find(
          (template: $TSFixMe) => template.id === item.templateId
        );
        return {
          ...templateData,
          name: item.displayName || item.name,
          displayName: item.displayName || item.name,
          id: item.id,
          templateId: item.templateId,
          inputValues: item.variables || {}
        };
      });
      appliedTransformsData && setAppliedTransforms(appliedTransformsData);
    }
  }, [dfsTemplates, recipe?.id, recipe?.runConfigs]);

  const onUpdateRecipe = useCallback(
    (updatedRecipe: Recipe) => {
      !recipe &&
        navigate(`/projects/${projectId}/scenario/${scenarioId}/add-recipe/${updatedRecipe?.id}`, {
          replace: true
        });
      setRecipeName(updatedRecipe?.name);
      setRecipe(updatedRecipe);
      setConditions(updatedRecipe?.condition?.expression);
      setRecipeDisplayName(updatedRecipe?.displayName || updatedRecipe?.name);
    },
    [projectId, recipe, scenarioId]
  );

  const handleUpdateRecipe = useCallback(
    ({
      payload,
      successMessage,
      onError,
      onSuccess
    }: {
      payload: $TSFixMe;
      successMessage?: string;
      onError?: $TSFixMeFunction;
      onSuccess?: $TSFixMeFunction;
    }) => {
      saveRecipeMutation.mutate(
        {
          recipeName,
          recipe,
          selectedInputDatasets,
          selectedArtifacts,
          selectedModels,
          projectId: projectId!,
          conditions,
          payload
        },
        {
          onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
            onSuccess?.();

            onUpdateRecipe(transformGroupResponse);
            handleResponse({
              successMessage: successMessage || `Transform saved successfully.`
            });
          },
          onError
        }
      );
    },
    [
      conditions,
      projectId,
      onUpdateRecipe,
      recipe,
      recipeName,
      saveRecipeMutation,
      selectedArtifacts,
      selectedInputDatasets,
      selectedModels
    ]
  );

  const handleUpdateSelectedEntities = useCallback(
    async ({
      updatedInputDatasets = selectedInputDatasets,
      updatedArtifacts = selectedArtifacts,
      updatedModels = selectedModels
    }: $TSFixMe) => {
      setIsSelectedEntitiesUpdateInProgess(true);

      const updatedDatasets =
        recipe?.parents?.filter(
          (parent: $TSFixMe) =>
            parent.type !== EntityTypeEnum.ENTITY &&
            parent.type !== EntityTypeEnum.ARTIFACT &&
            parent.type !== EntityTypeEnum.MODEL
        ) || [];
      const newEntities =
        updatedInputDatasets?.map((item: $TSFixMe) => ({
          id: item.id,
          type: EntityTypeEnum.ENTITY
        })) || [];
      const newArtifacts =
        updatedArtifacts?.map((artifact: $TSFixMe) => ({
          name: artifact.name,
          type: EntityTypeEnum.ARTIFACT
        })) || [];
      const newModels =
        updatedModels?.map((model: $TSFixMe) => ({
          name: model.name,
          type: EntityTypeEnum.MODEL
        })) || [];
      const updatedParents = [...updatedDatasets, ...newEntities, ...newArtifacts, ...newModels];
      handleUpdateRecipe({
        payload: { parents: updatedParents },
        successMessage: "Entitites updated successfully.",
        onSuccess: () => {
          setSelectedInputDatasets(updatedInputDatasets);
          setSelectedArtifacts(updatedArtifacts);
          setSelectedModels(updatedModels);
          setIsSelectedEntitiesUpdateInProgess(false);
        }
      });
    },
    [handleUpdateRecipe, recipe?.parents, selectedArtifacts, selectedInputDatasets, selectedModels]
  );

  const handleSaveConditions = (updatedConditions: $TSFixMe) => {
    handleUpdateRecipe({
      payload: {
        condition: {
          expression: updatedConditions
        }
      },
      successMessage: "Conditions updated successfully."
    });
  };

  const handleSaveName = ({ updatedName, onError }: { updatedName: string; onError: $TSFixMe }) => {
    handleUpdateRecipe({
      payload: {
        name: updatedName,
        displayName: updatedName
      },
      successMessage: "Name updated successfully.",
      onError
    });
  };

  const handleUpdateRecipeRunTimeout = ({
    onSuccess,
    onError,
    timeout
  }: {
    timeout: number;
    onError?: () => void;
    onSuccess?: () => void;
  }) => {
    handleUpdateRecipe({
      payload: { timeout },
      onSuccess,
      onError,
      successMessage: "Recipe Run Timeout updated successfully."
    });
  };

  const handleTest = () => {
    recipe &&
      testTransformGroupMutation.mutate(
        {
          recipeId: recipe?.id,
          projectId,
          testTransformConfig: recipe?.runConfigs?.map((response: any) => ({
            templateId: response.templateId,
            name: response.name,
            variables: response.variables
          }))
        },
        {
          onSuccess: (response: $TSFixMe) => {
            const outputDatasets = response?.dataMap
              ? Object.keys(response?.dataMap).map((item) => ({
                  name: item,
                  data: response?.dataMap?.[item],
                  type: OUTPUT_TYPE.DATASET
                }))
              : [];
            const outputCharts =
              response?.entityViewData.map((item: $TSFixMe) => ({
                ...item,
                type: "CHART"
              })) || [];

            setPreviewTabs([...outputDatasets, ...outputCharts]);
            handleResponse({ successMessage: `Test successful` });
          },
          onError: (error: $TSFixMe) => {
            renderTestTransformErrors(error);
          }
        }
      );
  };

  const addRecipeToQueue = async () => {
    await resetAddRecipeToQueueMutation();
    await addRecipeToQueueMutation(
      { scenarioId, id: recipe?.id },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue]);

          toastWrapper({
            type: ToastTypes.Info,
            content: `Recipe ${recipeDisplayName || recipe?.name} added to queue successfully!`
          });
        },
        onError: () => {
          toastWrapper({
            type: ToastTypes.Error,
            content: `Error while adding Recipe ${recipeDisplayName || recipe?.name} to queue!`
          });
        }
      }
    );
  };

  const handleRun = async () => {
    if (!scenarioId || !recipe?.id) {
      return;
    }

    !!projectId && checkEnvRelaunch(projectId);

    if (!!isRecipesRunningAcrossScenariosStore || !!isPendingRecipeRunsInQueueStore) {
      addRecipeToQueue();
    } else {
      onRecipeRun({ recipe });
    }
  };

  const handleAppliedTransformChange = ({
    transformData,
    onSettled,
    onSuccess
  }: {
    transformData: $TSFixMe;
    onSettled?: () => void;
    onSuccess?: $TSFixMeFunction;
  }) => {
    saveTransformMutation.mutate(
      {
        recipe,
        transformData,
        recipeName,
        selectedInputDatasets,
        selectedArtifacts,
        selectedModels,
        projectId: projectId!,
        conditions
      },
      {
        onSuccess: ({
          transformGroupResponse,
          transformResponse
        }: {
          transformGroupResponse: Recipe;
          transformResponse: $TSFixMe;
        }) => {
          onUpdateRecipe(transformGroupResponse);
          transformResponse && onSuccess?.({ transformResponse });
          transformResponse &&
            handleResponse({
              successMessage: `Transform saved successfully.`
            });
        },
        onSettled: () => {
          onSettled?.();
        }
      }
    );
  };

  const handleAppliedTransformDelete = useCallback(
    ({
      transformId,
      transformName,
      onSuccess
    }: {
      transformId: string;
      transformName: string;
      onSuccess: () => void;
    }) => {
      recipe?.id &&
        deleteTransformMutation.mutate(
          { transformId, recipeId: recipe?.id },
          {
            onSuccess: ({ transformGroupResponse }: { transformGroupResponse: Recipe }) => {
              onUpdateRecipe(transformGroupResponse);
              onSuccess?.();
              handleResponse({
                successMessage: `Transform - ${transformName} removed from the recipe.`
              });
            }
          }
        );
    },
    [deleteTransformMutation, onUpdateRecipe, recipe?.id]
  );

  const allDatasetNames = selectedInputDatasets?.map(
    (dataset) => dataset.name || dataset.displayName
  );

  const areAllTransformInputsValid = appliedTransforms?.every((appliedTransform) =>
    appliedTransform?.inputs
      ?.filter((input: any) => input?.display?.input_dataset || input?.metadata?.input_dataset)
      ?.every((input: any) =>
        allDatasetNames?.includes(appliedTransform?.inputValues?.[input?.name])
      )
  );

  const isRecipeUnChanged = useMemo(() => {
    if (!recipe?.id) {
      return false;
    }
    const recipeTransformIds = recipe?.runConfigs?.map((config) => config.id);
    const currentTransformIds = appliedTransforms?.map((currTransform) => currTransform.id);
    const recipeTemplateVariables = mapValues(keyBy(recipe?.runConfigs, "id"), "variables");
    const currentTemplateVariables = mapValues(keyBy(appliedTransforms, "id"), "inputValues");

    const areTransformsSame =
      areArraysEqual(recipeTransformIds, currentTransformIds) &&
      isEqual(recipeTemplateVariables, currentTemplateVariables);
    if (!areTransformsSame) {
      return false;
    }

    const recipeDatasetIds = recipe?.parents
      ?.filter((parent) => parent.type === EntityTypeEnum.ENTITY)
      ?.map((entity) => entity.id);
    const recipeArtifactNames = recipe?.parents
      ?.filter((parent) => parent.type === EntityTypeEnum.ARTIFACT)
      ?.map((entity) => entity.name);
    const recipeModelNames = recipe?.parents
      ?.filter((parent) => parent.type === EntityTypeEnum.MODEL)
      ?.map((entity) => entity.name);

    const selectedDatasetIds = selectedInputDatasets.map((ds) => ds.id);
    const selectedArtifactNames = selectedArtifacts.map((artifact) => artifact.name);
    const selectedModelNames = selectedModels.map((model) => model.name);

    const areEntitiesSame =
      areArraysEqual(recipeDatasetIds, selectedDatasetIds) &&
      areArraysEqual(recipeArtifactNames, selectedArtifactNames) &&
      areArraysEqual(recipeModelNames, selectedModelNames);

    const areConditionsSame = isEqual(conditions, recipe?.condition?.expression);
    return areEntitiesSame && areTransformsSame && areConditionsSame;
  }, [
    appliedTransforms,
    conditions,
    recipe?.condition?.expression,
    recipe?.id,
    recipe?.parents,
    recipe?.runConfigs,
    selectedArtifacts,
    selectedInputDatasets,
    selectedModels
  ]);

  const removeInputDataset = async (inputDsId: string) => {
    const updatedInputDatasets = selectedInputDatasets.filter(
      (inputDs) => inputDs.id !== inputDsId
    );

    await handleUpdateSelectedEntities({ updatedInputDatasets });
  };

  const removeArtifact = async (artifactName: string) => {
    const updatedArtifacts = selectedArtifacts.filter((artifact) => artifact.name !== artifactName);
    await handleUpdateSelectedEntities({ updatedArtifacts });
  };

  const removeModel = async (modelName: string) => {
    const updatedModels = selectedModels.filter((model) => model.name !== modelName);
    await handleUpdateSelectedEntities({ updatedModels });
  };

  const isTestInProgress = React.useMemo(
    () => saveAndTestRecipeMutation.isLoading || testTransformGroupMutation.isLoading,
    [saveAndTestRecipeMutation.isLoading, testTransformGroupMutation.isLoading]
  );

  const isSaveInProgress = React.useMemo(
    () => saveRecipeMutation.isLoading,
    [saveRecipeMutation.isLoading]
  );

  const invalidTransformInputsHelpText = !areAllTransformInputsValid
    ? "Invalid Input Datasets provided for transformations"
    : "";

  const recipeRunInProgressHelpText = !!isRunInProgress ? "Recipe run is in progress" : "";

  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(scenarioData, scenarios, scenarioId),
    [scenarioData, scenarios, scenarioId]
  );

  return (
    <>
      <NewThemeWrapper>
        {isJobPath ? (
          isLoading || isFetchingRecipes || isScenarioLoading ? (
            <Spinner />
          ) : (
            <Grid className={classes.recipeContainer}>
              <StandardRecipeHeader
                recipeName={recipeName}
                recipeDisplayName={recipeDisplayName}
                scenarioData={scenarioData}
                isDefaultScenario={isDefaultScenario}
                recipe={recipe}
                isEmptyRecipe={isEmptyRecipe}
                emptyRecipeHelperText={
                  isEmptyRecipe || appliedTransforms?.length === 0
                    ? "Add entities & transformations to enable run"
                    : ""
                }
                invalidTransformInputsHelpText={invalidTransformInputsHelpText}
                recipeRunInProgressHelpText={recipeRunInProgressHelpText}
                isRunDisabled={
                  isSaveInProgress ||
                  isTestInProgress ||
                  !isRecipeUnChanged ||
                  !areAllTransformInputsValid ||
                  appliedTransforms?.length === 0 ||
                  !recipe?.id
                }
                onRunRecipe={handleRun}
                isRunInProgress={isRunInProgress}
                conditions={conditions}
                handleSaveName={handleSaveName}
                setConditions={handleSaveConditions}
                handleUpdateRecipeRunTimeout={handleUpdateRecipeRunTimeout}
                jobData={jobData}
                jobRunName={jobRunData?.entryDto?.runId}
              />
              <Grid container direction="row" className={classes.standardRecipeBodyContainer}>
                {isExpanded ? (
                  <Grid item className={classes.gridContainer}>
                    <Grid className={clsx([classes.inputViewContainer, classes.leftContainer])}>
                      <StandardRecipeInputsView
                        recipeId={recipe?.id}
                        inputDatasets={selectedInputDatasets}
                        selectedArtifacts={selectedArtifacts}
                        selectedModels={selectedModels}
                        entityFeaturesMap={entityFeaturesMap}
                        onDeleteDataset={removeInputDataset}
                        openAddEntitiesDialog={() => setShowAddEntitiesDialog(true)}
                        onCollapse={() => setIsExpanded(false)}
                        isDefaultScenario={isDefaultScenario}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item className={classes.leftContainer}>
                    <IconButton onClick={() => setIsExpanded(true)} size="small">
                      <ExpandIcon />
                    </IconButton>
                  </Grid>
                )}
                <Grid item className={classes.inputContainer}>
                  {isGetEntityFeaturesLoading ? (
                    <Spinner />
                  ) : (
                    <Box height="100%" boxShadow={3}>
                      <StandardRecipeInputContainer
                        recipe={recipe}
                        appliedTransforms={appliedTransforms}
                        selectedInputDatasets={selectedInputDatasets}
                        selectedArtifacts={selectedArtifacts}
                        selectedModels={selectedModels}
                        previewTabs={previewTabs}
                        setPreviewTabs={setPreviewTabs}
                        entityFeaturesMap={entityFeaturesMap}
                        isGetEntityFeaturesLoading={isGetEntityFeaturesLoading}
                        dfsTemplates={dfsTemplates}
                        isFetchingTemplates={isFetchingTemplates}
                        onAppliedTransformChange={handleAppliedTransformChange}
                        onDeleteAppliedTransform={handleAppliedTransformDelete}
                        removeInputDataset={removeInputDataset}
                        removeArtifact={removeArtifact}
                        removeModel={removeModel}
                        handleTest={handleTest}
                        isTestInProgress={isTestInProgress}
                        isEmptyRecipe={isEmptyRecipe}
                        isTestDisabled={
                          isSaveInProgress ||
                          appliedTransforms?.length === 0 ||
                          isRunInProgress ||
                          !areAllTransformInputsValid ||
                          !recipe?.id
                        }
                        emptyRecipeHelperText={
                          isEmptyRecipe || appliedTransforms?.length === 0
                            ? "Add entities & transformations to enable test"
                            : ""
                        }
                        isDefaultScenario={isDefaultScenario}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )
        ) : (
          <Grid className={classes.recipeContainer}>
            {isLoading || isFetchingRecipes || isScenarioLoading ? (
              <Spinner />
            ) : (
              <Grid className={classes.recipeContainer}>
                <StandardRecipeHeader
                  recipeName={recipeName}
                  recipeDisplayName={recipeDisplayName}
                  scenarioData={scenarioData}
                  isDefaultScenario={isDefaultScenario}
                  recipe={recipe}
                  isEmptyRecipe={isEmptyRecipe}
                  emptyRecipeHelperText={
                    isEmptyRecipe || appliedTransforms?.length === 0
                      ? "Add entities & transformations to enable run"
                      : ""
                  }
                  invalidTransformInputsHelpText={invalidTransformInputsHelpText}
                  recipeRunInProgressHelpText={recipeRunInProgressHelpText}
                  isRunDisabled={
                    isSaveInProgress ||
                    isTestInProgress ||
                    !isRecipeUnChanged ||
                    !areAllTransformInputsValid ||
                    appliedTransforms?.length === 0 ||
                    !recipe?.id
                  }
                  onRunRecipe={handleRun}
                  isRunInProgress={isRunInProgress}
                  conditions={conditions}
                  handleSaveName={handleSaveName}
                  setConditions={handleSaveConditions}
                  handleUpdateRecipeRunTimeout={handleUpdateRecipeRunTimeout}
                  isAddingRecipeToQueue={isAddingRecipeToQueue}
                />
                {isEmptyRecipe && !recipe?.id ? (
                  <EmptyRecipeContainer
                    isDefaultScenario={isDefaultScenario}
                    openAddEntitiesDialog={() => setShowAddEntitiesDialog(true)}
                  />
                ) : (
                  <Grid container direction="row" className={classes.standardRecipeBodyContainer}>
                    {isExpanded ? (
                      <Grid item className={classes.gridContainer}>
                        <Grid className={clsx([classes.inputViewContainer, classes.leftContainer])}>
                          <StandardRecipeInputsView
                            recipeId={recipe?.id}
                            inputDatasets={selectedInputDatasets}
                            selectedArtifacts={selectedArtifacts}
                            selectedModels={selectedModels}
                            entityFeaturesMap={entityFeaturesMap}
                            onDeleteDataset={removeInputDataset}
                            openAddEntitiesDialog={() => setShowAddEntitiesDialog(true)}
                            onCollapse={() => setIsExpanded(false)}
                            isDefaultScenario={isDefaultScenario}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item className={classes.leftContainer}>
                        <Box p="16px 8px">
                          <IconButton onClick={() => setIsExpanded(true)} size="small">
                            <ExpandIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    )}
                    <Grid item className={classes.inputContainer}>
                      {isGetEntityFeaturesLoading ? (
                        <Spinner />
                      ) : (
                        <Box height="100%" boxShadow={3}>
                          <StandardRecipeInputContainer
                            recipe={recipe}
                            appliedTransforms={appliedTransforms}
                            selectedInputDatasets={selectedInputDatasets}
                            selectedArtifacts={selectedArtifacts}
                            selectedModels={selectedModels}
                            previewTabs={previewTabs}
                            setPreviewTabs={setPreviewTabs}
                            entityFeaturesMap={entityFeaturesMap}
                            isGetEntityFeaturesLoading={isGetEntityFeaturesLoading}
                            dfsTemplates={dfsTemplates}
                            isFetchingTemplates={isFetchingTemplates}
                            onAppliedTransformChange={handleAppliedTransformChange}
                            onDeleteAppliedTransform={handleAppliedTransformDelete}
                            removeInputDataset={removeInputDataset}
                            removeArtifact={removeArtifact}
                            removeModel={removeModel}
                            handleTest={handleTest}
                            isTestInProgress={isTestInProgress}
                            isEmptyRecipe={isEmptyRecipe}
                            isTestDisabled={
                              isSaveInProgress ||
                              appliedTransforms?.length === 0 ||
                              isRunInProgress ||
                              !areAllTransformInputsValid ||
                              !recipe?.id
                            }
                            emptyRecipeHelperText={
                              isEmptyRecipe || appliedTransforms?.length === 0
                                ? "Add entities & transformations to enable test"
                                : ""
                            }
                            isDefaultScenario={isDefaultScenario}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </NewThemeWrapper>
      {showAddEntitiesDialog && (
        <AddEntitiesDialog
          recipeId={recipe?.id}
          selectedArtifacts={selectedArtifacts}
          selectedInputDatasets={selectedInputDatasets}
          selectedModels={selectedModels}
          allEntities={allEntities || []}
          onClose={() => {
            setShowAddEntitiesDialog(false);
          }}
          showArtifacts
          showModels
          onSubmit={handleUpdateSelectedEntities}
          isSubmitInProgress={isSelectedEntitiesUpdateInProgess}
        />
      )}
    </>
  );
};

export default StandardRecipeContainer;
