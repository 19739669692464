import { Dispatch, SetStateAction, useCallback, useState } from "react";

// Packages
import { Node as ReactFlowNode, Edge as ReactFlowEdge, XYPosition } from "react-flow-renderer";
import { assign, filter, find, map, size } from "lodash";

// Utils
import { toastWrapper } from "src/utils/toastWrapper";

// APIs
import { putAPIWithRethrow } from "src/utils/apiService";

// Contexts
import { useDagFlowContext } from "../context/useDagFlowContext";

// Types
import { NodeType } from "../../../Dag.types";

type Props = {
  projectId?: string;
  nodes: ReactFlowNode[];
  edges: ReactFlowEdge[];
  setNodes: Dispatch<SetStateAction<ReactFlowNode[]>>;
};

function useControlNodeDrag(props: Props) {
  const { projectId, nodes, setNodes, edges } = props || {};

  const { initialNodes, setInitialNodes, initialEdges, setNodeActions } = useDagFlowContext();

  const [beforeDragPositions, setBeforeDragPositions] = useState<XYPosition | null>(null);

  const setNodeDragActionInContext = useCallback((nodeDrag = false) => {
    setNodeActions((prev) => ({
      ...prev,
      nodeDrag
    }));
  }, []);

  const resetNode = useCallback(
    (node: ReactFlowNode) => {
      setNodes((thisNodes) =>
        map(thisNodes, (thisNode: ReactFlowNode) => {
          if (thisNode?.id === node?.id && !!beforeDragPositions) {
            return assign({}, thisNode, { position: beforeDragPositions });
          }

          return thisNode;
        })
      );

      setNodeDragActionInContext();
    },
    [nodes, beforeDragPositions]
  );

  const isBlockColliding = useCallback(
    (node: ReactFlowNode) => {
      const collidingBlocks = filter(initialNodes, (thisNode: ReactFlowNode) => {
        return (
          thisNode?.position?.x === node?.position?.x &&
          thisNode?.position?.y === node?.position?.y &&
          thisNode?.id !== node?.id
        );
      });

      if (size(collidingBlocks) > 0) {
        toastWrapper({ type: "error", content: "Blocks cannot collide!" });
        resetNode(node);

        return true;
      }

      return;
    },
    [initialNodes, beforeDragPositions]
  );

  const isBlockSurpassingParentOnLeft = useCallback(
    (node: ReactFlowNode) => {
      const parentEdges = filter(initialEdges, (thisEdge) => thisEdge?.target === node?.id);

      const parentBlocks = map(parentEdges, (thisEdge) =>
        find(initialNodes, (thisNode) => thisNode?.id === thisEdge?.source)
      );

      const parentBlocksMaxXPosition = Math.max(
        // @ts-ignore
        ...(parentBlocks || [])?.map((block: ReactFlowNode) => block?.position?.x)
      );

      if (node?.position?.x <= parentBlocksMaxXPosition) {
        toastWrapper({
          type: "error",
          content: "Block cannot be moved on the left side of the parent block!"
        });

        resetNode(node);

        return true;
      }

      return;
    },
    [initialNodes, initialEdges, beforeDragPositions]
  );

  const isBlockSurpassingChildOnRight = useCallback(
    (node: ReactFlowNode) => {
      const childEdges = filter(initialEdges, (thisEdge) => thisEdge?.source === node?.id);

      const childBlocks = map(childEdges, (thisEdge) =>
        find(initialNodes, (thisNode) => thisNode?.id === thisEdge?.target)
      );

      const childBlocksMinXPosition = Math.min(
        // @ts-ignore
        ...(childBlocks || [])?.map((block: ReactFlowNode) => block?.position?.x)
      );

      if (node?.position?.x >= childBlocksMinXPosition) {
        toastWrapper({
          type: "error",
          content: "Block cannot be moved on the right side of the child block!"
        });

        resetNode(node);

        return true;
      }

      return;
    },
    [initialNodes, initialEdges, beforeDragPositions]
  );

  const errorBlockDrag = useCallback(
    (node: ReactFlowNode) => {
      toastWrapper({ type: "error", content: "Blocks cannot collide!" });
      resetNode(node);
    },
    [beforeDragPositions]
  );

  const onNodeDragStart = useCallback((node: ReactFlowNode) => {
    setBeforeDragPositions(node?.position);
  }, []);

  const onNodeDragStop = useCallback(
    async (_: React.MouseEvent, node: ReactFlowNode) => {
      setNodeDragActionInContext(true);

      if (isBlockColliding(node)) {
        return;
      }

      if (isBlockSurpassingParentOnLeft(node)) {
        return;
      }

      if (isBlockSurpassingChildOnRight(node)) {
        return;
      }

      try {
        await putAPIWithRethrow(
          `/v2/project-canvas/${projectId}`,
          nodes
            ?.filter((thisNode) => !!thisNode?.id)
            ?.map((thisNode) => ({
              id: thisNode?.id,
              positionX: thisNode?.position.x / 180,
              positionY: thisNode?.position.y / 180
            }))
        );

        setInitialNodes(() =>
          map(initialNodes, (thisNode: NodeType) => {
            if (thisNode?.id === node?.id) {
              return assign({}, thisNode, {
                position: node?.position
              });
            }

            return { ...thisNode };
          })
        );

        setNodeDragActionInContext();
      } catch (e) {
        errorBlockDrag(node);
        return;
      }
    },
    [projectId, initialNodes, initialEdges, nodes, edges, beforeDragPositions]
  );

  return { onNodeDragStart, onNodeDragStop };
}

export default useControlNodeDrag;
