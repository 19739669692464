/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";
import { AskAIResponse } from "src/types";

import { getAPI } from "src/utils/apiService";

export const useGetCodeHistory = ({
  recipeId,
  onSuccess,
  enabled
}: {
  enabled: boolean;
  recipeId: string | undefined;
  onSuccess: (history: AskAIResponse[]) => void;
}) => {
  return useQuery<AskAIResponse[]>({
    queryKey: ["recipe", recipeId, "codeHistory"],
    queryFn: () => {
      return getAPI(`/v2/dfs-run-config-groups/${recipeId}/code-history`);
    },
    enabled: !!recipeId && enabled,
    cacheTime: 0,
    onSuccess
  });
};
