import { useMutation } from "@tanstack/react-query";
import { saveCodeRecipeFn, SaveRecipeProps } from "./useSaveCodeRecipeMutation";

export const useAutoSaveCodeRecipeMutation = () => {
  return useMutation({
    mutationFn: async (props: SaveRecipeProps) => {
      const response = await saveCodeRecipeFn(props);
      return response;
    }
  });
};
