import { useQueryClient, useMutation } from "@tanstack/react-query";

import { QUERY_KEY_NOTIFICATIONS } from "./useGetNotifications";
import { updateNotificationWithRethrow } from "src/api";

type UpdateNotificationProps = { notificationId: string };

export const useUpdateNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ notificationId }: UpdateNotificationProps) => {
      return updateNotificationWithRethrow(notificationId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY_NOTIFICATIONS]);
    },
    onError: () => {
      queryClient.invalidateQueries([QUERY_KEY_NOTIFICATIONS]);
    }
  });
};
