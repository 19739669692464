import React, { useMemo, useRef } from "react";

import { Grid } from "@material-ui/core";

import { DatasetHelperText } from "../../../utils/Dataset.constants";

import FileControl from "./FileControl";
import DatasetControl from "../DatasetControl";

interface IProps {
  index: number;
  uploadControlRow: any;
  datasetFormControlsRefs: any;
  connectorLineRerenderTimer: number;
  eachFile: any;
  datasetFieldRef: any;
  isDataSourcesFilesUpload: boolean;
  datasetName: string;
  updateDatasetFormControlRef: any;
  reUpload: any;
  deleteFile: any;
  updateDatasetFieldRef: any;
}

const SegregateUploadControl: React.FC<IProps> = (props) => {
  const {
    index,
    uploadControlRow,
    eachFile,
    datasetFormControlsRefs,
    connectorLineRerenderTimer,
    datasetFieldRef,
    isDataSourcesFilesUpload,
    datasetName,
    updateDatasetFieldRef,
    reUpload,
    deleteFile,
    updateDatasetFormControlRef
  } = props;
  const fileControlContainerRef = useRef<$TSFixMe>();
  const datasetControlContainerRef = useRef<$TSFixMe>(null);

  const connectorLineRerenderTimerComputed = useMemo(() => {
    return new Date().getTime();
  }, [
    fileControlContainerRef.current,
    datasetControlContainerRef.current,
    connectorLineRerenderTimer
  ]);

  return (
    <Grid
      key={`uploadControlRow_${index}`}
      container
      justifyContent="space-between"
      className={uploadControlRow}>
      <Grid container className="fileControlContainer" ref={fileControlContainerRef}>
        <FileControl
          index={index}
          fileControlContainerRef={fileControlContainerRef.current}
          datasetControlContainerRef={datasetControlContainerRef.current}
          datasetFormControlRef={datasetFormControlsRefs[index]}
          datasetFieldRef={datasetFieldRef}
          connectorLineRerenderTimer={connectorLineRerenderTimerComputed}
          file={eachFile}
          isDataSourcesFilesUpload={isDataSourcesFilesUpload}
          reUpload={() => reUpload(eachFile, index)}
          deleteFile={() => deleteFile(eachFile, index)}
        />
      </Grid>
      <Grid container className="datasetControlContainer" ref={datasetControlContainerRef}>
        <DatasetControl
          index={index}
          datasetName={datasetName || DatasetHelperText.Unknown}
          updateDatasetFormControlRef={updateDatasetFormControlRef}
          updateDatasetFieldRef={updateDatasetFieldRef}
        />
      </Grid>
    </Grid>
  );
};

export default SegregateUploadControl;
