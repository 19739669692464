import React, { useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core";

import { Tabs, Tab, TabPanel } from "src/components";

import Files from "./Files";
import TabPanelWrapper from "../TabPanelWrapper";

import {
  DataSourceTabKeys,
  dataSourceTabKeyNames,
  dataSourceTabs,
  DataSourcesHelperText
} from "../../utils/DataSources.constants";

const useStyles = makeStyles((theme) => ({
  tabPanelContainer: {
    // 94 (SubTopNavBar height)
    // + 24 (Page padding)
    // + 48 (Tabs height)
    // + 24 (File section margin-top)
    // + 8 (File section padding-top)
    // + n (buffer)
    height: ({ isBreadcrumbs }: { isBreadcrumbs: boolean }) =>
      `calc(100vh - ${isBreadcrumbs ? 212 + 44 : 214}px)`,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: 12
  }
}));

const ObjectBased = (props: $TSFixMe) => {
  const {
    getFiles,
    isFilesLoading,
    files,
    breadcrumbs,
    setBreadcrumbs,
    isObjectContentDisabled,
    isDataSourceUsageLoading,
    usageSourceDatasets,
    usageDestinationDatasets,
    usageSourceDatasetsInJobs,
    usageDestinationDatasetsInJobs
  } = props || {};

  const classes = useStyles({ isBreadcrumbs: breadcrumbs?.length > 0 });

  const [tabValue, setTabValue] = useState(0);

  const onTabChange = (newValue: $TSFixMe) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={onTabChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable">
        <Tab value={0} label={dataSourceTabKeyNames[DataSourceTabKeys.Files]} />
        {dataSourceTabs.map((eachTab: $TSFixMe, index: number) => (
          <Tab key={uuidv4()} value={index + 1} label={eachTab.displayName} />
        ))}
      </Tabs>
      <div className={classes.tabPanelContainer}>
        <TabPanel value={tabValue} index={0}>
          <Files
            getFiles={getFiles}
            isFilesLoading={isFilesLoading}
            files={files}
            breadcrumbs={breadcrumbs}
            setBreadcrumbs={setBreadcrumbs}
            isObjectContentDisabled={isObjectContentDisabled}
          />
        </TabPanel>
        <TabPanelWrapper
          tab={DataSourceTabKeys.Datasets}
          value={tabValue}
          index={1}
          isDataSourceUsageLoading={isDataSourceUsageLoading}
          usageSourceDatasets={usageSourceDatasets}
          usageDestinationDatasets={usageDestinationDatasets}
          emptyDataMessage={DataSourcesHelperText.NoDatasetFound}
        />
        <TabPanelWrapper
          tab={DataSourceTabKeys.Jobs}
          value={tabValue}
          index={2}
          isDataSourceUsageLoading={isDataSourceUsageLoading}
          usageSourceDatasets={usageSourceDatasetsInJobs}
          usageDestinationDatasets={usageDestinationDatasetsInJobs}
          emptyDataMessage={DataSourcesHelperText.NoJobFound}
        />
      </div>
    </>
  );
};

export default ObjectBased;
