import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import React, { useCallback, useState } from "react";
import clsx from "clsx";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import VersionIcon from "src/icons/NewUX/VersionIcon";
import shallow from "zustand/shallow";
import useAuthStore from "src/stores/auth.store";
import { OverflowTooltip } from "src/components";

interface IProps {
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  videos: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "6px"
  },
  menuItem: {
    borderBottom: "1px solid #cccccc",
    color: "#003656",
    fontSize: "0.875rem",

    "&:hover": {
      background: "rgba(230, 245, 255, .6)"
    },

    "&:last-of-type": {
      borderBottom: "none !important"
    },
    width: "inherit"
  }
}));

const VersionList: React.FC<IProps> = (props) => {
  const { onClose } = props;
  const [isCopied, setIsCopied] = useState(false);
  const [rcClientVersion, rcReleaseVersion] = useAuthStore(
    useCallback((state) => [state.rcClientVersion, state.rcReleaseVersion], []),
    shallow
  );

  const classes = useStyles();

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `pip install --extra-index-url=https://us-central1-python.pkg.dev/rapidcanvas-361003/pypi/simple utils==${rcClientVersion}`
    );
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 5000);
  };

  return (
    <Box m="0px 10px">
      <MenuItem
        style={{ paddingLeft: 0, paddingRight: 0 }}
        data-testid="help-menu-videos"
        onClick={onClose}>
        <Grid container direction="row" alignItems="center">
          <ChevronLeftIcon fontSize="small" />
          <div className={classes.videos}>
            <ListItemIcon style={{ minWidth: 0 }}>
              <VersionIcon />
            </ListItemIcon>
            <Typography variant="body2">Version</Typography>
          </div>
        </Grid>
      </MenuItem>
      <MenuItem className={clsx([classes.menuItem])}>
        <OverflowTooltip value={`Platform Version : ${rcReleaseVersion ?? "unknown"}`} />
      </MenuItem>
      <MenuItem className={clsx([classes.menuItem])}>
        <OverflowTooltip value={`Python SDK Version : ${rcClientVersion ?? "unknown"}`} />
        <Tooltip
          title={
            isCopied
              ? "Copied"
              : "Copies the command to install latest python client in local notebook(Requires pip v3.0 or higher)"
          }>
          <IconButton size="small" onClick={handleCopy}>
            <FileCopyOutlinedIcon style={{ width: "16px", height: "16px" }} />
          </IconButton>
        </Tooltip>
      </MenuItem>
    </Box>
  );
};

export default VersionList;
