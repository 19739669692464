import React from "react";

// MUI
import Grid from "@material-ui/core/Grid";

import PublicRoute from "./PublicRoute";

const PublicRouteWrapper = () => {
  return (
    <Grid
      component="main"
      container
      direction="column"
      wrap="nowrap"
      style={{ minHeight: "100vh" }}>
      <PublicRoute />
    </Grid>
  );
};

export default PublicRouteWrapper;
