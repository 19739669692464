import React from "react";

import { Button, makeStyles } from "@material-ui/core";

import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

import { DataSourcesNavigationTypes, DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import useFilesObserver from "../../hooks/useFilesObserver";
import FilesUploadProgress from "../../components/FilesUploadProgress";
import { useUploadContext } from "../../contexts/Upload/useUploadContext";
import useFilesSession from "../../hooks/useFilesSession";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 150
  },
  buttonMarginLeft: {
    extend: "button",
    marginLeft: theme.spacing(1)
  }
}));

const ConnectorFilesActions = () => {
  const classes = useStyles();

  // Stores - STARTS >>
  const { setDatasetIsOntologyStore, datasetFooterActionsStore } = useStoreSelectors();
  // << ENDS - Stores

  const { setDatasetFilesSession } = useFilesSession();
  const { isFilesUploading } = useFilesObserver();

  const { setDataSourceFilesInStore, setFilesTimer } = useUploadContext();

  const dataSourcesFilesNavigation = (navigationTypes: string) => {
    if (navigationTypes === DataSourcesNavigationTypes.Next) {
      setDataSourceFilesInStore();
    } else if (navigationTypes === DataSourcesNavigationTypes.Previous) {
      setDatasetFilesSession([]);
      setFilesTimer(() => new Date().getTime());
    }
  };

  const onNext = () => {
    setDatasetIsOntologyStore(true);
  };

  return (
    <>
      {!datasetFooterActionsStore.dataSourcesPrevious.isHidden && (
        <Button
          id="datasetActionDataSourcesPrevious"
          variant="outlined"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.dataSourcesPrevious.isDisabled}
          onClick={() => dataSourcesFilesNavigation(DataSourcesNavigationTypes.Previous)}>
          <ChevronLeftRoundedIcon />
        </Button>
      )}
      {!datasetFooterActionsStore.upload.isHidden && (
        <Button
          id="datasetActionUpload"
          variant="contained"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.upload.isDisabled}
          // Uploading one file at-a-time in ontology screen.
          onClick={() => onNext()}
          startIcon={<FilesUploadProgress />}>
          {isFilesUploading ? DatasetHelperText.Uploading : DatasetHelperText.Upload}
        </Button>
      )}
      {!datasetFooterActionsStore.dataSourcesNext.isHidden && (
        <Button
          id="datasetActionDataSourcesNext"
          variant="outlined"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.dataSourcesNext.isDisabled}
          onClick={() => dataSourcesFilesNavigation(DataSourcesNavigationTypes.Next)}
          endIcon={<ChevronRightRoundedIcon />}>
          {DatasetHelperText.Next}
        </Button>
      )}
    </>
  );
};

export default ConnectorFilesActions;
