import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Grid, Box, makeStyles } from "@material-ui/core";
import _, { get, some, toUpper } from "lodash";

import {
  getDataSources,
  createDataSourceWithRethrow,
  updateDataSourceWithRethrow,
  deleteDataSourceWithRethrow,
  getDataSourcesSchema,
  getDataSourceFiles,
  getDataSourceUsageWithRethrow
} from "src/api/dataSources";
import useGetDataConnector from "hooks/api/dataSources/useGetDataConnector";
import useGetSynchedData from "src/hooks/api/dataSources/useGetSynchedData";

import { toastWrapper } from "src/utils/toastWrapper";

import { useDataSourcesStore } from "src/store/store";
import {
  dataSourcesGetter,
  dataSourcesSetter,
  dataSourceTypeGetter,
  dataSourceTypeSetter
} from "src/store/store.selectors";

import { Spinner } from "src/components";

import {
  DataSourcesHttpStatuses,
  DataSourceUsageDatasetTypes,
  dataSourcesTypes,
  DataSourceDeletePromptDetails,
  DataSourcesHelperText
} from "../utils/DataSources.constants";

import DataSourceHeader from "./DataSourceHeader";
import DataSourceConfiguration from "./DataSourceConfiguration";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { getFivetranConnectorStatus } from "src/utils/fivetran";

import ObjectBased from "./ObjectBased/ObjectBased";
import SqlBased from "./SqlBased/SqlBased";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import UpdateDataConnectorWarningModal from "./UpdateDataConnectorWarningModal";
import { FivetranStatus } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";
import { WebPaths } from "src/routing/routes";
import useGetRefreshedDataConnectorStatus from "src/hooks/api/dataSources/useGetRefreshedDataConnectorStatus";

const defaultDataSourceValues = {
  name: { dataType: "STRING", name: "name", required: true }
};

const getFormValues = (data?: $TSFixMe) =>
  data ? { ...defaultDataSourceValues, ...data } : { ...defaultDataSourceValues };

const useStyles = makeStyles(() => ({
  root: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    minHeight: "calc(100vh - 94px)"
  },
  dataSourceContainer: { padding: 24, width: "calc(100vw)" },
  tab: {
    padding: "0 !important",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    "&.Mui-selected": {
      backgroundColor: "rgba(63, 81, 181, 0.08)"
    }
  }
}));

const DataSource = () => {
  const { id } = useParams<$TSFixMe>();

  const classes = useStyles();

  const navigate = useNavigate();

  // Stores - STARTS >>
  const dataSourcesStore = useDataSourcesStore(dataSourcesGetter);
  const setDataSourcesStore = useDataSourcesStore(dataSourcesSetter);
  const dataSourceTypeStore = useDataSourcesStore(dataSourceTypeGetter);
  const setDataSourceTypeStore = useDataSourcesStore(dataSourceTypeSetter);
  const [newConnectorToSync, setNewConnectorToSync] = useDataSourcesStore((state) => [
    state.newConnectorToSync,
    state.setNewConnectorToSync
  ]);

  // << ENDS - Stores

  // States - STARTS >>
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [dataSourceDetails, setDataSourceDetails] = useState<$TSFixMe>({});
  const [refetchTimedout, setRefetchTimedout] = useState(false);
  const [status, setStatus] = useState<FivetranStatus | null>(null);

  const [values, setValues] = useState<$TSFixMe>(defaultDataSourceValues);

  const [isFilesLoading, setIsFilesLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<$TSFixMe>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<$TSFixMe>([]);

  const [isDataSourceUsageLoading, setIsDataSourceUsageLoading] = useState<boolean>(false);

  const [usageSourceDatasets, setUsageSourceDatasets] = useState<$TSFixMe>([]);
  const [usageDestinationDatasets, setUsageDestinationDatasets] = useState<$TSFixMe>([]);

  const [usageSourceDatasetsInJobs, setUsageSourceDatasetsInJobs] = useState<$TSFixMe>([]);
  const [usageDestinationDatasetsInJobs, setUsageDestinationDatasetsInJobs] = useState<$TSFixMe>(
    []
  );

  const [isDestOrJobDest, setIsDestOrJobDest] = useState(false);
  const [open, setOpen] = useState(false);

  const [isNameDirty, setIsNameDirty] = useState<$TSFixMe>(false);
  const [isDataSourceConfigDirty, setIsDataSourceConfigDirty] = useState<$TSFixMe>(false);

  const [nameError, setNameError] = useState<string | undefined>();

  const [isSaving, setIsSaving] = useState<$TSFixMe>(false);

  const [isTesting, setIsTesting] = useState<boolean>(false);
  const [isTestSucceed, setIsTestSucceed] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const [dataSourcesSchema, setDataSourcesSchema] = useState<$TSFixMe>([]);

  const [showConfirmScreen, setShowConfirmScreen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  // << ENDS - States

  const refreshedStatusResult = useGetRefreshedDataConnectorStatus(id ? [id] : [], {
    refetchInterval: status === FivetranStatus.syncing && !refetchTimedout ? 30000 : false,
    enabled: status === FivetranStatus.syncing && !!id
  });

  const synchDataResult = useGetSynchedData(
    get(dataSourceDetails, ["options", "connectorId"]),
    undefined,
    undefined,
    {
      refetchOnMount: true,
      refetchInterval: status === FivetranStatus.syncing && !refetchTimedout ? 15000 : false,
      enabled: !!get(dataSourceDetails, ["options", "connectorId"])
    }
  );

  const navigateBack = ({ reloadConnectors = false } = {}) => {
    if (!!reloadConnectors) {
      navigate(WebPaths.DataConnectors, { state: { reloadConnectors: true } });
    } else {
      navigate(WebPaths.DataConnectors);
    }
  };

  useEffect(() => {
    const refetchTimeOut = setTimeout(() => {
      setRefetchTimedout(true);
    }, 3600000);

    return () => {
      clearTimeout(refetchTimeOut);
    };
  }, []);

  useEffect(() => {
    if (newConnectorToSync) {
      const isSameConnector = dataSourceDetails?.id === newConnectorToSync?.id;
      if (isSameConnector) {
        const currentTime = Date.now();
        if (newConnectorToSync.created > currentTime) {
          setNewConnectorToSync(null);
        } else {
          const timeToWait = newConnectorToSync.created + 120000 - currentTime;
          setTimeout(() => {
            setNewConnectorToSync(null);
          }, timeToWait);
        }
      }
    }
  }, [newConnectorToSync, dataSourceDetails?.id]);

  const formForm = async (thisSourceDetails: $TSFixMe) => {
    const foundDataSourceType = dataSourcesSchema?.find(
      (eachDataSourceType: $TSFixMe) =>
        eachDataSourceType?.type === thisSourceDetails?.dataSourceType
    );

    if ((foundDataSourceType?.dataSourceFields || [])?.length > 0) {
      const allFields = [...[{ name: "name" }], ...foundDataSourceType?.dataSourceFields];

      const formFormFields: $TSFixMe = getFormValues();
      allFields?.forEach((field: $TSFixMe) => {
        let formField = field;

        if (thisSourceDetails[field?.name]) {
          formField.value = thisSourceDetails[field?.name];
        }

        if (thisSourceDetails?.options?.[field?.name]) {
          formField.value = thisSourceDetails?.options?.[field?.name];
        }

        formFormFields[field?.name] = { ...formFormFields[field?.name], ...formField };
      });

      if (Object.keys(formFormFields)?.length > 0) {
        setValues(() => getFormValues(formFormFields));
      }
    } else {
      navigateBack();
    }
  };

  // Pre-fetching required data - STARTS >>
  useEffect(() => {
    const _ = async () => {
      setIsFetching(() => true);

      const dataSourcesSchemaResponse = await getDataSourcesSchema();
      setDataSourcesSchema(() => dataSourcesSchemaResponse);

      setIsFetching(() => false);
    };

    _();
  }, []);

  useEffect(() => {
    const _ = async () => {
      setIsFetching(() => true);

      const dataSourcesResponse = await getDataSources();
      setDataSourcesStore(dataSourcesResponse);

      setIsFetching(() => false);
    };

    dataSourcesStore?.length === 0 && _();
  }, []);

  useEffect(() => {
    const isSameConnector = dataSourceDetails?.id === newConnectorToSync?.id;
    const refreshedData = _.first(refreshedStatusResult.data) as any;
    if (refreshedData) {
      setStatus(
        getFivetranConnectorStatus(
          refreshedData.thirdPartyDataSourceStatus,
          isSameConnector,
          newConnectorToSync?.created
        )
      );
    } else {
      setStatus(
        getFivetranConnectorStatus(
          dataSourceDetails?.thirdPartyDataSourceStatus,
          isSameConnector,
          newConnectorToSync?.created
        )
      );
    }
  }, [
    dataSourceDetails?.thirdPartyDataSourceStatus,
    newConnectorToSync,
    _.get(_.first(refreshedStatusResult.data), "thirdPartyDataSourceStatus")
  ]);

  useGetDataConnector(id, {
    refetchOnMount: true,
    onSuccess: (result) => {
      setDataSourceDetails(result);
      setIsSaved(() => true);
      setDataSourceTypeStore("");
    }
  });

  useEffect(() => {
    if (!id && !dataSourceTypeStore) {
      navigateBack();
    }
  }, [id, dataSourceTypeStore]);

  useEffect(() => {
    if ((dataSourcesSchema || [])?.length > 0) {
      if (Object.keys(dataSourceDetails || {})?.length > 0) {
        formForm(dataSourceDetails);
      } else if (!id && dataSourceTypeStore) {
        formForm({ dataSourceType: dataSourceTypeStore });
      }
    }
  }, [dataSourcesSchema, id, dataSourceDetails, dataSourceTypeStore]);

  const dataSourceTypeMetaData = useMemo((): $TSFixMe => {
    if (dataSourceDetails?.dataSourceType === thirdPartyTypeName) {
      return {
        name: thirdPartyTypeName,
        displayName: toUpper(dataSourceDetails?.tpConnectorType),
        image: dataSourceDetails?.iconUrl,
        connectCardUrl: dataSourceDetails?.options?.connectCardUrl,
        isSql: true
      };
    }
    return (
      dataSourcesTypes.find(
        (eachDataSourceType: $TSFixMe) =>
          eachDataSourceType?.name === (dataSourceDetails?.dataSourceType || dataSourceTypeStore)
      ) || {}
    );
  }, [dataSourceDetails, dataSourceTypeStore]);

  const testToaster = ({ isSucceed = false, message = "" }) => {
    if (isSucceed) {
      toastWrapper({
        type: "success",
        content:
          message || !(dataSourceTypeMetaData || {})?.isSql
            ? DataSourcesHelperText.ConnectionSuccessObjectBased
            : DataSourcesHelperText.ConnectionSuccess
      });
    } else {
      toastWrapper({
        type: "error",
        content: message || DataSourcesHelperText.ConnectionFailed
      });
    }
  };

  const getFiles = async ({ filePath = "/", onLoad = false } = {}) => {
    if (!(dataSourceTypeMetaData || {})?.isSql) {
      setIsFilesLoading(() => true);
      try {
        const filesResponse = await getDataSourceFiles({
          payload: {
            id: id || dataSourceDetails?.id,
            filePath
          }
        });

        if (filesResponse?.status === DataSourcesHttpStatuses.Success) {
          setFiles(() => filesResponse?.files);
        } else {
          // Specific error message from Back-end
          // onLoad &&
          //   testToaster({
          //     isSucceed: false,
          //     message: Object.keys(filesResponse?.messages[0] || {})?.[0]
          //   });

          // Generic error message from Front-end
          onLoad &&
            testToaster({
              isSucceed: false
            });
        }
      } catch (error: $TSFixMe) {
        testToaster({
          isSucceed: false
        });
      }
      setIsFilesLoading(() => false);
    }
  };

  useEffect(() => {
    id && Object.keys(dataSourceDetails || {})?.length > 0 && getFiles({ onLoad: true });
  }, [id, dataSourceDetails, dataSourceTypeMetaData]);

  useEffect(() => {
    const _ = async () => {
      setIsDataSourceUsageLoading(() => true);

      try {
        const dataSourceUsageResponse = await getDataSourceUsageWithRethrow(id!);

        if (Array.isArray(dataSourceUsageResponse)) {
          const shouldThrowWarning = some(dataSourceUsageResponse, ({ type }) =>
            [
              DataSourceUsageDatasetTypes.Destination,
              DataSourceUsageDatasetTypes.JobDestination
            ].includes(type)
          );
          if (shouldThrowWarning) {
            setIsDestOrJobDest(true);
          }
          const thisUsageSourceDatasets: $TSFixMe = [];
          const thisUsageDestinationDatasets: $TSFixMe = [];

          const thisUsageSourceDatasetsInJobs: $TSFixMe = [];
          const thisUsageDestinationDatasetsInJobs: $TSFixMe = [];

          // $FixMe: Sorting works on column's id and the same id is displayed in Table Settings.
          // So, adding displayable key names so that sorting and name in Table Settings both works.
          // It should be removed in future.
          dataSourceUsageResponse?.forEach((eachEntry: $TSFixMe) => {
            const usageMap = {
              projectId: eachEntry?.projectId,
              projectName: eachEntry?.projectName,

              defaultScenarioId: eachEntry?.defaultScenarioId,

              entityId: eachEntry?.entityId,
              entityName: eachEntry?.entityName,
              entityCreated: eachEntry?.entityCreated,

              "Dataset Created on": eachEntry?.entityCreated
            };

            if (
              [
                DataSourceUsageDatasetTypes.Source,
                DataSourceUsageDatasetTypes.Destination
              ].includes(eachEntry?.type)
            ) {
              if (eachEntry?.type === DataSourceUsageDatasetTypes.Source) {
                thisUsageSourceDatasets.push(usageMap);
              }

              if (eachEntry?.type === DataSourceUsageDatasetTypes.Destination) {
                thisUsageDestinationDatasets.push(usageMap);
              }
            }

            if (!!eachEntry.jobId) {
              if (
                [
                  DataSourceUsageDatasetTypes.JobSource,
                  DataSourceUsageDatasetTypes.JobDestination
                ].includes(eachEntry?.type)
              ) {
                const jobMap = {
                  ...usageMap,
                  ...{
                    jobId: eachEntry?.jobId,
                    jobName: eachEntry?.jobName,
                    jobCreated: eachEntry?.jobCreated,

                    "Job Created on": eachEntry?.jobCreated
                  }
                };

                if (eachEntry?.type === DataSourceUsageDatasetTypes.JobSource) {
                  thisUsageSourceDatasetsInJobs.push(jobMap);
                }

                if (eachEntry?.type === DataSourceUsageDatasetTypes.JobDestination) {
                  thisUsageDestinationDatasetsInJobs.push(jobMap);
                }
              }
            }
          });

          setUsageSourceDatasets(() => thisUsageSourceDatasets);
          setUsageDestinationDatasets(() => thisUsageDestinationDatasets);

          setUsageSourceDatasetsInJobs(() => thisUsageSourceDatasetsInJobs);
          setUsageDestinationDatasetsInJobs(() => thisUsageDestinationDatasetsInJobs);
        }
      } catch (error: $TSFixMe) {
        console.error(error);
      } finally {
        setIsDataSourceUsageLoading(() => false);
      }
    };

    id && _();
  }, [id]);
  // << ENDS - Pre-fetching required data

  // Test data-source - STARTS >>
  const getPayloadConfigDataSource = () => {
    let isValid = true;

    const requestJson: $TSFixMe = {
      type: dataSourceDetails?.dataSourceType || dataSourceTypeStore,
      ...(dataSourceDetails?.dataSourceType === thirdPartyTypeName
        ? {
            iconUrl: dataSourceDetails?.iconUrl,
            tpConnectorType: dataSourceDetails?.tpConnectorType
          }
        : {}),
      options: {}
    };

    Object.keys(values)?.forEach((field: $TSFixMe) => {
      const thisValue: $TSFixMe = (values?.[field]?.value || "")?.trim();

      if (values?.[field]?.name === "name") {
        requestJson[field] = thisValue;
      } else {
        requestJson.options[field] = thisValue;
      }
    });

    return { isValid, requestJson };
  };

  const test = async () => {
    setIsTesting(() => true);

    if (!(dataSourceTypeMetaData || {})?.isSql) {
      setIsFilesLoading(() => true);
      setFiles(() => []);
      setBreadcrumbs(() => []);
    }

    const { isValid, requestJson } = getPayloadConfigDataSource() || {};

    if (isValid) {
      if (requestJson?.type && Object.keys(requestJson?.options)?.length > 0) {
        try {
          const testResponse = await getDataSourceFiles({
            payload: {
              type: requestJson?.type,
              options: requestJson?.options,
              filePath: "/",
              ...((id || !!dataSourceDetails?.id) && { id: id || dataSourceDetails?.id })
            }
          });

          const isTestSucceedResponse = testResponse?.status === DataSourcesHttpStatuses.Success;

          if (isTestSucceedResponse) {
            setIsDataSourceConfigDirty(() => false);
            setIsTestSucceed(() => true);

            !(dataSourceTypeMetaData || {})?.isSql && setFiles(() => testResponse?.files || []);
            testToaster({ isSucceed: true });
          } else {
            // Specific error message from Back-end
            // testToaster({
            //   isSucceed: false,
            //   message: Object.keys(testResponse?.messages[0] || {})?.[0]
            // });

            // Generic error message from Front-end
            testToaster({
              isSucceed: false
            });
          }
        } catch (error: $TSFixMe) {
          testToaster({
            isSucceed: false
          });
        }
      }
    }

    !(dataSourceTypeMetaData || {})?.isSql && setIsFilesLoading(() => false);

    setIsTesting(() => false);
  };
  // << ENDS - Test data-source

  // Save data-source - STARTS >>
  const checkIsDuplicate = (name: string) => {
    if (dataSourcesStore?.length === 0) return false;

    return dataSourcesStore?.some((dataSource: $TSFixMe) => {
      return (
        dataSource?.id !== id && dataSource?.name?.toLowerCase() === name?.toLowerCase()?.trim()
      );
    });
  };

  const saveDataSource = async () => {
    setIsSaving(() => true);

    const { isValid, requestJson } = getPayloadConfigDataSource() || {};
    const thisRequestJson: $TSFixMe = requestJson || {};

    if (isValid) {
      if (id || dataSourceDetails?.id) {
        thisRequestJson.id = id || dataSourceDetails?.id;

        try {
          await updateDataSourceWithRethrow({ payload: thisRequestJson });

          toastWrapper({
            type: "success",
            content: DataSourcesHelperText.DataSourceUpdateSucceed
          });

          setIsSaved(() => true);
          setOpen(false);
          setIsNameDirty(() => false);
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          const dataSourceDetailsResponse = await createDataSourceWithRethrow({
            payload: thisRequestJson
          });
          setDataSourceDetails(() => dataSourceDetailsResponse);

          toastWrapper({
            type: "success",
            content: DataSourcesHelperText.DataSourceCreateSucceed
          });

          setIsSaved(() => true);
          setIsNameDirty(() => false);
        } catch (e) {
          console.error(e);
        }
      }
    }

    setIsSaving(() => false);
  };
  // << ENDS - Save data-source

  // Delete data source - STARTS >>
  const promptConfirmDeleteDataSource = () => {
    setShowConfirmScreen(() => true);
  };

  const cancelDeleteDataSource = () => {
    setShowConfirmScreen(() => false);
  };

  const confirmDeleteDataSource = () => {
    deleteDataSource();
  };

  const deleteDataSource = () => {
    const _ = async () => {
      if (id || dataSourceDetails?.id) {
        setIsDeleting(() => true);

        try {
          await deleteDataSourceWithRethrow({ id: id || dataSourceDetails?.id });

          toastWrapper({
            type: "success",
            content: DataSourcesHelperText.DataSourceDeleteSucceed
          });

          navigateBack({ reloadConnectors: true });
        } catch (e: $TSFixMe) {
          console.error(e);
        } finally {
          setIsDeleting(() => false);
          setShowConfirmScreen(() => false);
        }
      }
    };

    _();
  };
  // << ENDS - Delete data source

  // Actions observers - STARTS >>
  const isInvalidConfig = () => {
    const { isValid } = getPayloadConfigDataSource() || {};

    return !isValid;
  };

  const isObjectContentDisabled = useMemo(() => {
    let isDisabled = true;

    if (id || dataSourceDetails?.id) {
      if (isSaved) {
        isDisabled = false;
      }
    }

    return isDisabled;
  }, [id, dataSourceDetails?.id, isSaved]);

  const isSqlContentDisabled = useMemo(() => {
    let isDisabled = true;

    if (id || dataSourceDetails?.id) {
      if (isSaved) {
        isDisabled = false;
      } else {
        if (isNameDirty && !isDataSourceConfigDirty) {
          isDisabled = !isSaved;
        }
      }
    }

    return isDisabled;
  }, [id, dataSourceDetails?.id, isNameDirty, isDataSourceConfigDirty, isTestSucceed, isSaved]);

  const isTestDisabled = useMemo(() => {
    let isDisabled = true;

    if (!(isInvalidConfig() || isTesting || isSaving)) {
      if (id || dataSourceDetails?.id) {
        if (isDataSourceConfigDirty) {
          isDisabled = false;
        }
      } else {
        if (!isTestSucceed) {
          isDisabled = !isDataSourceConfigDirty;
        }
      }
    }

    return isDisabled;
  }, [
    id,
    dataSourceDetails,
    isInvalidConfig,
    isDataSourceConfigDirty,
    isTesting,
    isSaving,
    isTestSucceed
  ]);

  const isSaveDisabled = useMemo(() => {
    let isDisabled = true;
    const isInvalid = isInvalidConfig();

    if (!(isInvalid || nameError || isTesting || isSaving)) {
      if (isTestSucceed) {
        return false;
      }

      if (id || dataSourceDetails?.id) {
        if (isSaved) {
          isDisabled = true;
        } else {
          if (isNameDirty && !isDataSourceConfigDirty) {
            isDisabled = false;
          } else {
            isDisabled = !isTestSucceed;
          }
        }
      } else {
        if (isTestSucceed) {
          isDisabled = false;
        }
      }
    }

    return isDisabled;
  }, [
    id,
    dataSourceDetails,
    isInvalidConfig,
    isNameDirty,
    isDataSourceConfigDirty,
    nameError,
    isTesting,
    isSaving,
    isSaved,
    isTestSucceed
  ]);

  const isDeleteDisabled = useMemo(() => {
    let isDisabled = true;

    if (!(isTesting || isSaving)) {
      if (id || dataSourceDetails?.id) {
        isDisabled = false;
      }
    }

    return isDisabled;
  }, [id, dataSourceDetails, isTesting, isSaving]);
  // << ENDS - Actions observers

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (isDestOrJobDest) {
      setOpen(true);
    } else {
      await saveDataSource();
    }
  };

  const handleManualSync = () => {
    setNewConnectorToSync({ id, created: Date.now() });
    setDataSourceDetails({
      ...dataSourceDetails,
      thirdPartyDataSourceStatus: {
        ...dataSourceDetails.thirdPartyDataSourceStatus,
        historicalSync: true,
        syncState: FivetranStatus.syncing
      }
    });
  };

  return (
    <>
      {showConfirmScreen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Delete Connector"
          content={[DataSourceDeletePromptDetails.title, DataSourceDeletePromptDetails.message]}
          isSubmitting={isDeleting}
          onClose={cancelDeleteDataSource}
          onSubmit={confirmDeleteDataSource}
        />
      )}

      <Grid container direction="column" alignItems="stretch" className={classes.root}>
        {isFetching || (id && Object.keys(dataSourceDetails).length === 0) ? (
          <Spinner />
        ) : (
          <>
            <DataSourceHeader
              dataSourceTypeMetaData={dataSourceTypeMetaData}
              isFetching={isFetching}
              status={status}
              id={id}
              name={_.get(values, ["name", "value"])}
              dataSourceDetails={dataSourceDetails}
              test={test}
              isTestSucceed={isTestSucceed}
              isTesting={isTesting}
              isSaving={isSaving}
              isSaved={isSaved}
              checkIsDuplicate={checkIsDuplicate}
              isNameDirty={isNameDirty}
              isTestDisabled={isTestDisabled}
              isSaveDisabled={isSaveDisabled}
              isDeleteDisabled={isDeleteDisabled}
              saveDataSource={handleSave}
              deleteDataSource={promptConfirmDeleteDataSource}
              onManualSync={handleManualSync}
              syncData={synchDataResult.data}
              setValues={setValues}
              setDataSourceDetails={setDataSourceDetails}
            />
            <Grid item xs={12} className={`${classes.dataSourceContainer} container-height`}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <DataSourceConfiguration
                    dataSourceTypeMetaData={dataSourceTypeMetaData}
                    values={values}
                    setValues={setValues}
                    setIsNameDirty={setIsNameDirty}
                    setIsDataSourceConfigDirty={setIsDataSourceConfigDirty}
                    nameError={nameError}
                    setNameError={setNameError}
                    checkIsDuplicate={checkIsDuplicate}
                    setIsTestSucceed={setIsTestSucceed}
                    isSaving={isSaving}
                    setIsSaved={setIsSaved}
                  />
                </Grid>
                {(dataSourceTypeMetaData || {})?.isSql ? (
                  <Grid item xs={9}>
                    <Box sx={{ width: "100%" }}>
                      <SqlBased
                        status={status}
                        id={id}
                        isFetching={isFetching}
                        dataSourcesSchema={dataSourcesSchema}
                        dataSourceTypeStore={dataSourceTypeStore}
                        dataSourceDetails={dataSourceDetails}
                        isSqlContentDisabled={isSqlContentDisabled}
                        isDataSourceUsageLoading={isDataSourceUsageLoading}
                        usageSourceDatasets={usageSourceDatasets}
                        usageDestinationDatasets={usageDestinationDatasets}
                        usageSourceDatasetsInJobs={usageSourceDatasetsInJobs}
                        usageDestinationDatasetsInJobs={usageDestinationDatasetsInJobs}
                        syncData={synchDataResult.data}
                      />
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={9}>
                    <ObjectBased
                      getFiles={getFiles}
                      isFilesLoading={isFilesLoading}
                      files={files}
                      breadcrumbs={breadcrumbs}
                      setBreadcrumbs={setBreadcrumbs}
                      isObjectContentDisabled={isObjectContentDisabled}
                      isDataSourceUsageLoading={isDataSourceUsageLoading}
                      usageSourceDatasets={usageSourceDatasets}
                      usageDestinationDatasets={usageDestinationDatasets}
                      usageSourceDatasetsInJobs={usageSourceDatasetsInJobs}
                      usageDestinationDatasetsInJobs={usageDestinationDatasetsInJobs}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <UpdateDataConnectorWarningModal
        open={open}
        loading={isSaving}
        onCancel={handleCancel}
        onSubmit={saveDataSource}
      />
    </>
  );
};

export default DataSource;
