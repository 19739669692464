import { useCallback, useEffect, useState } from "react";

// Packages
import { Node as ReactFlowNode } from "react-flow-renderer";
import { filter, keys, map, pick, size } from "lodash";

// Utils
import { toastWrapper } from "src/utils/toastWrapper";

// Hooks
import { useSaveExpandableNodes } from "src/hooks/api/entities/useUpdateEntities";

// APIs
import { putAPIWithRethrow } from "src/utils/apiService";

// Contexts
import { useDagFlowContext } from "../context/useDagFlowContext";

type Props = {
  projectId?: string;
};

const useSaveNodes = (props: Props) => {
  const { projectId } = props || {};

  const { isJobPath, nodesExpanded } = useDagFlowContext();

  const [prevExpandableNodes, setPrevExpandableNodes] = useState<{ [key: string]: boolean }>({});

  // Query hooks - STARTS >>
  // Mutations
  const { mutateAsync: saveExpandableNodesMutation, reset: resetSaveExpandableNodesMutation } =
    useSaveExpandableNodes({
      onSuccess: () => {
        setPrevExpandableNodes(() => ({ ...nodesExpanded }));
      }
    });
  // << ENDS - Query hooks

  const saveNodes = useCallback(
    async ({
      payloadNodes,
      onSettled,
      onSuccess
    }: {
      payloadNodes: ReactFlowNode[];
      onSettled?: () => void;
      onSuccess?: () => void;
    }) => {
      try {
        await putAPIWithRethrow(
          `/v2/project-canvas/${projectId}`,
          payloadNodes
            ?.filter((node) => !!node.id)
            ?.map((node) => ({
              id: node.id,
              positionX: node.position.x / 180,
              positionY: node.position.y / 180
            }))
        );

        onSuccess?.();
      } catch (e) {
        toastWrapper({
          type: "error",
          content: "Canvas element position update failed"
        });
      } finally {
        onSettled?.();
      }
    },
    [projectId]
  );

  useEffect(() => {
    let changedExpandableNodes = {};

    if (size(prevExpandableNodes) === 0) {
      setPrevExpandableNodes(() => ({ ...nodesExpanded }));
    } else {
      // Find keys in nodesExpanded that have different values compared to prevExpandableNodes
      const uncommonKeys = filter(
        keys(nodesExpanded),
        (key) => nodesExpanded[key] !== prevExpandableNodes[key]
      );

      changedExpandableNodes = pick(nodesExpanded, uncommonKeys);
    }

    const _ = async () => {
      // Convert the key-value paris to the array format
      const payload = map(changedExpandableNodes, (expanded, id) => ({
        id,
        collapsed: !expanded
      }));

      await resetSaveExpandableNodesMutation();
      await saveExpandableNodesMutation({ projectId, payload });
    };

    !isJobPath && size(changedExpandableNodes) > 0 && _();
  }, [isJobPath, nodesExpanded]);

  return { saveNodes };
};

export default useSaveNodes;
