import { DataappAskAIConfigInputTypeEnum } from "openapi/Models";

export enum DataAppsConfig {
  ExemptingWidth = 0.15, // 15%
  CardWidth = 575,
  CardHeight = 328
}

export enum DataAppsSplashSection {
  title = "Get Started with a New DataApp",
  subTitleLine1 = "Welcome to the DataApp page.",
  subTitleLine2 = "Start a new one by clicking on the button below.",
  actionLabel = "+ New DataApp"
}

export const STOPPING_DATAAPP_MSG = "Please wait while the DataApp stops";
export const LAUNCHING_DATAAPP_MSG = "Please wait while the dataapp is launching";
export const DELETING_DATAAPP_MSG = "Dataapp deletion is already in progress";

export const askAITypesInfoList = [
  {
    title: "Project Canvas Datasets",
    tooltip:
      "This option supports using AskAI on all the datasets available on the project canvas along with user uploaded datasets",
    label: "Project Canvas Datasets",
    value: DataappAskAIConfigInputTypeEnum.ProjectCanvas
  },
  {
    title: "Custom Uploaded Datasets",
    tooltip: "This option supports using AskAI on user uploaded datasets",
    label: "Custom Uploaded Datasets",
    value: DataappAskAIConfigInputTypeEnum.Dataset
  },
  {
    title: "SQL DB as Inputs",
    tooltip:
      "This option supports using AskAI on MySQL based database. The connection needs to be created under Connectors menu via main navigation",
    label: "SQL Sources",
    value: DataappAskAIConfigInputTypeEnum.SqlDb
  },
  {
    title: "Documents and PDFs",
    tooltip:
      "This option supports using AskAI on user uploaded Document, PDF, Text and Markdown files",
    label: "Documents and PDFs",
    value: DataappAskAIConfigInputTypeEnum.RagFiles
  },
  {
    title: "Prediction Service",
    tooltip: "This option supports using AskAI on Prediction Service",
    label: "Prediction Service",
    value: DataappAskAIConfigInputTypeEnum.PredictionService
  }
];
