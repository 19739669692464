import { useEffect } from "react";

const useSaveCode = ({
  handleSave,
  isSaveDisabled,
  isTestInProgress
}: {
  handleSave: () => void;
  isSaveDisabled: boolean;
  isTestInProgress: boolean;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        if (isSaveDisabled || isTestInProgress) {
          event.preventDefault();
        } else {
          event.preventDefault();
          handleSave();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSave]);
};

export default useSaveCode;
