import React from "react";

type ContextTypes = {
  areDataSourceFilesFetching: boolean;
  sources: $TSFixMe;
  dataSourceFiles: $TSFixMe;
  onSourceOrCriterionChange: $TSFixMeFunction;
  getDataSourceFiles: $TSFixMeFunction;
};

// @ts-ignore
export const SourceContext = React.createContext<ContextTypes>();
