import React, { useMemo, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { keyBy, get, filter } from "lodash";
import { generatePath, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import DeleteProjectRunModal from "./DeletePredictionRunModal";
import DownloadPredictions from "./DownloadPredictions";
import {
  IProjectRun,
  QUERY_KEY_ALL_PROJECT_RUNS
} from "src/hooks/api/projects/useGetAllProjectRuns";
import { JobRunStatuses } from "../../Jobs/utils/Jobs.constants";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";
import { MODELS } from "./SelectModel";
import { Model } from "hooks/api/projects/useGetAllModels";
import { Table } from "components/Table/Table";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import { dateFormat } from "utils/dateFormat";
import { getFailedRecipeName } from "../utils/PredictionJob.helpers";
import { getJobRunStatusTemplate } from "../../Jobs/utils/Jobs.helpers";
import { useStyles } from "../../Jobs/components/JobsTable/JobsTable.styles";
import { WebPaths } from "src/routing/routes";

interface IProps {
  data: IProjectRun[];
  projectId: string;
  models?: Model[];
  onLogsOpen: ({
    runId,
    runEntryId,
    recipeId,
    recipeName,
    errorMsg
  }: {
    runId: string;
    runEntryId: string;
    recipeId: string;
    recipeName?: string;
    errorMsg: string;
    jobName: string;
  }) => void;
}

export interface IDeleteRecord {
  id: string;
  name: string;
}

const PredictionRunsTable: React.FC<IProps> = (props) => {
  const { data, projectId, models, onLogsOpen } = props;

  const [deleteRecord, setDeleteRecord] = useState<IDeleteRecord | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const classes = useStyles();

  const handleDelete = (id: string, name: string) => {
    setDeleteRecord({ id, name });
  };

  const handleSuccess = (id: string) => {
    queryClient.setQueryData(
      [QUERY_KEY_ALL_PROJECT_RUNS, projectId],
      filter(data, ({ dto }) => dto.id !== id)
    );
    setDeleteRecord(null);
  };

  const handleLogsClick = ({
    runId,
    runEntryId,
    recipeId,
    recipeName,
    jobName,
    errorMsg
  }: {
    runId: string;
    runEntryId: string;
    recipeId: string;
    recipeName?: string;
    errorMsg: string;
    jobName: string;
  }) => {
    onLogsOpen({ runId, runEntryId, recipeId, recipeName, errorMsg, jobName });
  };

  const columns = useMemo(() => {
    const keyByModels = keyBy(models, "id");

    return [
      {
        id: "Name",
        accessor: "dto.name",
        Header: "Name",
        isSortable: true,
        Cell: ({ row }: { row: { original: IProjectRun } }) => {
          const { dto } = row.original;
          const { name, id } = dto;
          return (
            <LinkCellWithMenu
              linkText={name}
              menuButtons={[
                {
                  label: "Delete",
                  icon: <TrashIcon viewBox="0 0 20 20" />,
                  action: () => handleDelete(dto.id, dto.name)
                }
              ]}
              onClick={() => {
                navigate(
                  generatePath(WebPaths.EditPredictionJob, {
                    projectId,
                    projectRunId: id
                  }),
                  {
                    state: { [MODELS]: models }
                  }
                );
              }}
            />
          );
        }
      },
      {
        id: "Triggered By",
        accessor: "dto.updater",
        Header: "Triggered By",
        isSortable: true,
        Cell: ({ row }: { row: { original: IProjectRun } }) => {
          const { updater } = row.original.dto || {};
          return <span>{updater}</span>;
        }
      },
      {
        id: "Updated at",
        accessor: "dto.updated",
        Header: "Updated at",
        isSortable: true,
        Cell: ({ row }: { row: { original: IProjectRun } }) => (
          <span>{dateFormat(row.original.dto.updated)}</span>
        )
      },
      {
        id: "Input Model",
        accessor: "dto.modelEntityId",
        Header: "Input Model",
        isSortable: true,
        Cell: ({ row }: { row: { original: IProjectRun } }) => (
          <span>{get(keyByModels, [row.original.dto.modelEntityId, "displayName"]) ?? "-"}</span>
        )
      },
      {
        id: "Status",
        accessor: "lastRunEntry.status",
        Header: "Status",
        isSortable: true,
        Cell: ({ row }: { row: { original: IProjectRun } }) => {
          const lastRunEntry = row.original.lastRunEntry;
          if (!lastRunEntry) {
            return getJobRunStatusTemplate(JobRunStatuses.Created);
          }
          return (
            <>
              {lastRunEntry?.status
                ? getJobRunStatusTemplate(lastRunEntry?.status ?? JobRunStatuses.Failure)
                : ""}
            </>
          );
        }
      },
      {
        id: "Predictions",
        accessor: "updater",
        Header: "Predictions",
        isSortable: false,
        Cell: ({ row }: { row: { original: IProjectRun } }) => (
          <DownloadPredictions
            id={row.original.lastRunEntry?.id}
            style={{ background: "transparent" }}
            disabled={
              !row.original.lastRunEntry?.id ||
              (row.original.lastRunEntry?.status !== JobRunStatuses.Success &&
                row.original.lastRunEntry?.status !== JobRunStatuses.SuccessWithWarn)
            }
          />
        )
      },
      {
        id: "Log",
        accessor: "lastRunLog",
        Header: "Log",
        isSortable: false,
        Cell: ({ row }: { row: { original: IProjectRun } }) => {
          const { id, status } = row.original.lastRunEntry || {};
          const recipeName = getFailedRecipeName({ lastRunEntry: row.original.lastRunEntry });
          const recipeId: string = recipeName
            ? get(row.original.lastRunEntry, ["recipeNameToId", recipeName])
            : "";

          const errorMsg = get(row.original.lastRunEntry, "error");

          return !!id ? (
            [JobRunStatuses.Created].includes(status) ? (
              <Tooltip title="Kindly wait for the run to start before checking the logs.">
                <span className={classes.linkDisabled}>View</span>
              </Tooltip>
            ) : (
              <button
                className={classes.link}
                onClick={() =>
                  handleLogsClick({
                    runId: row.original?.dto?.id,
                    runEntryId: id,
                    recipeId,
                    recipeName,
                    errorMsg: recipeId ? "" : errorMsg,
                    jobName: row.original?.dto?.name
                  })
                }>
                View
              </button>
            )
          ) : (
            "N/A"
          );
        }
      }
    ];
  }, [models, projectId]);

  return (
    <>
      <Table
        data={data}
        size="medium"
        columns={columns}
        isCellSortEnabled
        orderByDefault="Updated at"
        maxHeight="calc(100vh - 134px)"
      />
      {deleteRecord && (
        <DeleteProjectRunModal
          name={deleteRecord.name}
          id={deleteRecord.id}
          open={!!deleteRecord}
          onCancel={() => setDeleteRecord(null)}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default PredictionRunsTable;
