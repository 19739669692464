import React from "react";

export const TimeoutIcon = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.75 7.00001V12"
        stroke="#7C7C7C"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.75 1H12.75"
        stroke="#7C7C7C"
        strokeWidth="1.8"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 7C1.65 8.46 1 10.28 1 12.25C1 17.08 4.92 21 9.75 21C14.58 21 18.5 17.08 18.5 12.25C18.5 7.42 14.58 3.5 9.75 3.5C8.49 3.5 7.3 3.76 6.22 4.24"
        stroke="#7C7C7C"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
