import React, { PropsWithChildren } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    boxShadow: "0px 4px 4px 0px #00000040",
    background: "#FFFFFF",
    padding: "16px 0px",
    overflow: "auto",
    display: "flex",
    justifyContent: "center"
  }
});

const EdaChartCard: React.FC<PropsWithChildren<{}>> = (props) => {
  const { root } = useStyles();

  return <div className={root}>{props.children}</div>;
};

export default EdaChartCard;
