import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

import { Autocomplete, OverflowTooltip } from "src/components";
import useStyles from "./SegmentConditions.styles";

import { SegmentHelperText } from "../../utils/Segment.constants";

interface Option {
  label: string;
  value: string;
}

export const FieldController = ({
  control,
  name,
  disabled,
  options,
  testId,
  groupIndex,
  ruleIndex,
  loading
}: {
  groupIndex: number;
  ruleIndex: number;
  testId: string;
  disabled: boolean;
  name: string;
  control: any;
  loading: boolean;
  options: Option[];
}) => {
  const classes = useStyles();

  const inputLabelProps = {
    classes: { root: classes.inputLabel, shrink: classes.inputLabelShrink }
  };
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field, fieldState }) => {
        const { error } = fieldState;

        return (
          <Autocomplete
            {...field}
            loading={loading}
            virtualized
            size="small"
            className={classes.textField}
            classes={{ listbox: classes.listBox }}
            disableClearable
            getOptionLabel={(option: any) => option?.label || ""}
            value={field?.value}
            disabled={disabled}
            onChange={(_, option) => field.onChange(option)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${SegmentHelperText.Field} *`}
                InputLabelProps={inputLabelProps}
                variant="outlined"
                error={!!error}
                data-testid={`segmentConditionGroup${groupIndex}Rule${ruleIndex}InputField`}
              />
            )}
            renderOption={(option) => <OverflowTooltip title={option.label} value={option.label} />}
            options={options}
            data-testid={testId}
          />
        );
      }}
    />
  );
};

export default React.memo(FieldController);
