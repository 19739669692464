import { Grid, makeStyles } from "@material-ui/core";
import React, { PropsWithChildren } from "react";

interface IProps {
  size?: "small" | "medium" | "large";
  justifyContent?: "center" | "left" | "right";
}

const useStyles = makeStyles({
  space: (props: IProps) => ({
    rowGap: props.size === "small" ? "8px" : props.size === "medium" ? "12px" : "16px",
    columnGap: props.size === "small" ? "8px" : props.size === "medium" ? "12px" : "16px",
    justifyContent:
      props.justifyContent === "left"
        ? "flex-start"
        : props.justifyContent === "center"
          ? "center"
          : "flex-end"
  })
});

const Space: React.FC<PropsWithChildren<IProps>> = ({
  size = "small",
  children,
  justifyContent = "left"
}) => {
  const classes = useStyles({ size, justifyContent });
  return (
    <Grid container alignItems="center" className={classes.space}>
      {children}
    </Grid>
  );
};

export default Space;
