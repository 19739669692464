/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { PipelineTestResultPerTransformMsgsInnerValueInner } from './pipeline-test-result-per-transform-msgs-inner-value-inner';
// May contain unused imports in some cases
// @ts-ignore
import type { Source } from './source';

/**
 * 
 * @export
 * @interface ThirdPartySourcesListResult
 */
export interface ThirdPartySourcesListResult {
    /**
     * 
     * @type {string}
     * @memberof ThirdPartySourcesListResult
     */
    'status'?: ThirdPartySourcesListResultStatusEnum;
    /**
     * 
     * @type {Array<PipelineTestResultPerTransformMsgsInnerValueInner>}
     * @memberof ThirdPartySourcesListResult
     */
    'messages'?: Array<PipelineTestResultPerTransformMsgsInnerValueInner>;
    /**
     * 
     * @type {string}
     * @memberof ThirdPartySourcesListResult
     */
    'responseEntity'?: string;
    /**
     * 
     * @type {Array<Source>}
     * @memberof ThirdPartySourcesListResult
     */
    'sources'?: Array<Source>;
}

export const ThirdPartySourcesListResultStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type ThirdPartySourcesListResultStatusEnum = typeof ThirdPartySourcesListResultStatusEnum[keyof typeof ThirdPartySourcesListResultStatusEnum];


