/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PipelineStep
 */
export interface PipelineStep {
    /**
     * 
     * @type {string}
     * @memberof PipelineStep
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineStep
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineStep
     */
    'type'?: PipelineStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineStep
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineStep
     */
    'recipeType'?: PipelineStepRecipeTypeEnum;
}

export const PipelineStepTypeEnum = {
    Entity: 'ENTITY',
    IntermediateEntity: 'INTERMEDIATE_ENTITY',
    Dfsgroup: 'DFSGROUP',
    Chart: 'CHART',
    Model: 'MODEL',
    Artifact: 'ARTIFACT'
} as const;

export type PipelineStepTypeEnum = typeof PipelineStepTypeEnum[keyof typeof PipelineStepTypeEnum];
export const PipelineStepRecipeTypeEnum = {
    Standard: 'STANDARD',
    Code: 'CODE',
    AutoMl: 'AUTO_ML',
    ApiConnector: 'API_CONNECTOR'
} as const;

export type PipelineStepRecipeTypeEnum = typeof PipelineStepRecipeTypeEnum[keyof typeof PipelineStepRecipeTypeEnum];


