import React from "react";

export const CollapseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24" width="24">
      <path
        d="M18.4102 16.59L17.0002 18L11.0002 12L17.0002 6L18.4102 7.41L13.8302 12L18.4102 16.59ZM12.4102 16.59L11.0002 18L5.00016 12L11.0002 6L12.4102 7.41L7.83016 12L12.4102 16.59Z"
        fill="black"
      />
    </svg>
  );
};
