import Plotly, { PlotParams } from "react-plotly.js";
import React from "react";

const PlotlyCharts: React.FC<PlotParams> = (props) => {
  return (
    <Plotly
      {...props}
      layout={{
        ...props.layout,
        margin: {
          ...props.layout?.margin,
          t: 35,
          l: props.layout?.margin?.l ?? 160
        }
      }}
      config={{ displaylogo: false }}
    />
  );
};

export default PlotlyCharts;
