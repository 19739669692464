import React, { useMemo, Suspense, lazy, useEffect, useState } from "react";
import _ from "lodash";
import shallow from "zustand/shallow";
import { Routes, Navigate, Route, useLocation } from "react-router-dom";

const AboutProject = lazy(
  () => import("pages/private/ProjectsModule/pages/About/components/About")
);
const AccessDenied = lazy(() => import("components/Errors/AccessDenied"));
const AnalyticsWrapper = lazy(() => import("../AnalyticsWrapper"));
const AppLoadingScreen = lazy(() => import("components/Screens/AppLoadingScreen"));
const ComponentNotFound = lazy(() => import("components/Errors/ComponentNotFound"));
const DataAppRunLogs = lazy(() => import("pages/DataApps/DataAppLogs/DataAppRunLogs"));
const EnvironmentLogs = lazy(
  () => import("pages/private/Environments/components/EnvironmentLogs/EnvironmentLogs")
);
const ProjectRoutesBox = lazy(() => import("./ProjectRoutesBox"));
const Projects = lazy(() => import("pages/Projects/Projects"));
const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const UserDetails = lazy(() => import("pages/User/UserDetails"));
const TenantManagement = lazy(() => import("pages/TenantManagement/TenantManagement"));

const Dataset = lazy(() => import("pages/private/ProjectsModule/pages/Dataset/Dataset"));
const Segment = lazy(
  () => import("pages/private/ProjectsModule/pages/ViewDataset/Segment/Segment")
);
const StandardRecipeDataContainer = lazy(
  () => import("pages/Projects/AddStandardRecipe/StandardRecipeDataContainer")
);
const PredictionJob = lazy(
  () => import("pages/private/ProjectsModule/pages/PredictionJob/PredictionJob")
);
const ViewDataRoutes = lazy(() => import("pages/ViewData/ViewDataRoutes"));
const Charts = lazy(() => import("pages/Projects/Charts/Charts"));
const Scenarios = lazy(() => import("pages/private/ProjectsModule/pages/Scenarios/Scenarios"));
const Scenario = lazy(() => import("pages/private/ProjectsModule/pages/Scenario/Scenario"));
const UsageAndPolicy = lazy(() => import("pages/UsageAndPolicy/UsageAndPolicy"));
const VariablesList = lazy(() => import("pages/Variables/VariablesList"));
const Environments = lazy(() => import("pages/private/Environments/Environments"));
const Environment = lazy(() => import("pages/private/Environments/components/Environment"));
const RecipeRunLogs = lazy(() => import("pages/Projects/RecipeRunLogs/RecipeRunLogs"));
const DataSources = lazy(() => import("pages/DataSources/DataSources"));
const DataSource = lazy(() => import("pages/DataSources/DataSource/DataSource"));
const Dag = lazy(() => import("pages/private/ProjectsModule/pages/Dag/Dag"));
const JobRoutes = lazy(() => import("pages/private/ProjectsModule/pages/Jobs/JobRoutes"));
const ArtifactsAndModels = lazy(
  () => import("pages/Library/ArtifactsAndModels/ArtifactsAndModels")
);
const ArtifactDetails = lazy(
  () => import("pages/Library/ArtifactsAndModels/Artifacts/ArtifactDetails")
);
const ModelDetails = lazy(() => import("pages/Library/ArtifactsAndModels/Models/ModelDetails"));

const PlatformDataApps = lazy(
  () => import("pages/DataApps/DataAppsDashboard/DataApps/PlatformDataApps")
);
const DataApp = lazy(() => import("pages/DataApps/DataAppDashboard/DataApp"));
const DataAppDeepLink = lazy(() => import("pages/DataApps/DataAppDeepLink/DataAppDeepLink"));
const CodeRecipeDataContainer = lazy(
  () => import("pages/Projects/AddCodeRecipe/CodeRecipeDataContainer")
);
const AutoMLRecipeDataContainer = lazy(
  () => import("pages/Projects/AddAutoMLRecipe/AutoMLRecipeDataContainer")
);
const ApiConnectorRecipeDataContainer = lazy(
  () => import("pages/Projects/AddApiConnectorRecipe/ApiConnectorRecipeDataContainer")
);
const PredictionJobRun = lazy(
  () => import("pages/private/ProjectsModule/pages/PredictionJob/PredictionJobRun")
);
const PredictionJobLogsPage = lazy(
  () =>
    import(
      "pages/private/ProjectsModule/pages/PredictionJob/components/PredictionJobLogs/PredictionJobLogsPage"
    )
);
const ProjectDataApps = lazy(
  () => import("pages/DataApps/DataAppsDashboard/DataApps/ProjectDataApps")
);
const ReleaseNotes = lazy(
  () => import("layout/NavBars/components/TopNavBar/ReleaseNotes/ReleaseNotes")
);

import useAuthStore from "stores/auth.store";
import { RequestState } from "src/types/RequestState";
import { Roles } from "src/types";
import { Spinner } from "src/components";
import { useAuthAwaitStreamlit } from "src/hooks/useAuthAwaitStreamlit";
import { useGetRole } from "src/hooks/useGetRole";
import { useAuthNotebookRedirect } from "src/hooks/useAuthNotebookRedirect";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { WebPaths } from "../routes";
import EventBus from "src/utils/EventBus";
import RecordNotFound from "src/components/Errors/RecordNotFound";
import { EVENTBUS_EVENTS } from "src/constants/eventbus.constants";
import AutoMLNotebookContainer from "src/pages/Projects/AddAutoMLRecipe/AutoMLNotebookContainer";

const privateConfig = [
  {
    key: "usage-and-policy",
    path: WebPaths.UsageAndPolicy,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <UsageAndPolicy />
  },
  {
    key: "environments",
    path: WebPaths.Environments,
    allowedRoles: [Roles.Admin.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <Environments />
      </NewThemeWrapper>
    )
  },
  {
    key: "environment-config",
    path: WebPaths.EnvironmentConfig,
    allowedRoles: [Roles.Admin.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <Environment />
      </NewThemeWrapper>
    )
  },
  {
    key: "environment-logs",
    path: WebPaths.EnvironmentLogs,
    allowedRoles: [Roles.Admin.name, Roles.User.name, Roles["Business User"].name],
    Component: <EnvironmentLogs />
  },
  {
    key: "variables-list",
    path: WebPaths.VariablesList,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name],
    Component: <VariablesList />
  },
  {
    key: "user-profile",
    path: WebPaths.UserProfile,
    allowedRoles: [
      Roles.Admin.name,
      Roles.Demo.name,
      Roles.User.name,
      Roles.DataAppView.name,
      Roles["Business User"].name
    ],
    Component: (
      <NewThemeWrapper>
        <UserDetails />
      </NewThemeWrapper>
    )
  },
  {
    key: "tenant-management",
    path: WebPaths.TenantManagement,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <TenantManagement />
      </NewThemeWrapper>
    )
  },
  {
    key: "data-connectors",
    path: WebPaths.DataConnectors,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <DataSources />
      </NewThemeWrapper>
    )
  },
  {
    key: "create-data-connector",
    path: WebPaths.CreateDataConnector,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <DataSource />
      </NewThemeWrapper>
    )
  },
  {
    key: "existing-data-connector",
    path: WebPaths.DataConnector,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <DataSource />
      </NewThemeWrapper>
    )
  },
  {
    key: "artifacts-and-models",
    path: WebPaths.ArtifactsAndModels,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <ArtifactsAndModels />
      </NewThemeWrapper>
    )
  },
  {
    key: "artifacts-details",
    path: WebPaths.ArtifactsDetails,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <ArtifactDetails />
      </NewThemeWrapper>
    )
  },
  {
    key: "model-details",
    path: WebPaths.ModelDetails,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <ModelDetails />
  },
  {
    key: "data-app-dashboard",
    path: WebPaths.DataAppDashboard,
    allowedRoles: [
      Roles.Admin.name,
      Roles.Demo.name,
      Roles.User.name,
      Roles.DataAppView.name,
      Roles["Business User"].name
    ],
    Component: (
      <NewThemeWrapper>
        <DataApp />
      </NewThemeWrapper>
    )
  },
  {
    key: "data-app-deeplink",
    path: WebPaths.DataAppDeeplink,
    allowedRoles: [
      Roles.Admin.name,
      Roles.Demo.name,
      Roles.User.name,
      Roles.DataAppView.name,
      Roles["Business User"].name
    ],
    Component: <DataAppDeepLink />
  },
  {
    key: "data-app-run-logs",
    path: WebPaths.DataAppLogs,
    allowedRoles: [
      Roles.Admin.name,
      Roles.Demo.name,
      Roles.User.name,
      Roles.DataAppView.name,
      Roles["Business User"].name
    ],
    Component: (
      <NewThemeWrapper>
        <DataAppRunLogs />
      </NewThemeWrapper>
    )
  },
  {
    key: "projects",
    path: WebPaths.Projects,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <Projects />
  },
  {
    key: "dataapps",
    path: WebPaths.Dataapps,
    allowedRoles: [
      Roles.Admin.name,
      Roles.Demo.name,
      Roles.User.name,
      Roles.DataAppView.name,
      Roles["Business User"].name
    ],
    Component: (
      <NewThemeWrapper>
        <PlatformDataApps />
      </NewThemeWrapper>
    )
  },
  {
    key: "release-notes",
    path: WebPaths.ReleaseNotes,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <ReleaseNotes />
  }
];

const projectConfig = [
  // Job-routes - STARTS >>
  // Categorizing the job-routes into 3.
  // 1 - Routes for the screens - jobs, entity-details, and artifacts & models.
  // They have param variable for project-id as "projectId".
  {
    key: "jobRoutes1",
    path: `${WebPaths.JobRoutes}/*`,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <JobRoutes />
      </NewThemeWrapper>
    )
  },
  // << ENDS - Job-routes
  {
    key: "about",
    path: WebPaths.About,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <AboutProject />
  },
  {
    key: "dataset",
    path: WebPaths.Dataset,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <Dataset />
      </NewThemeWrapper>
    )
  },
  {
    key: "append-dataset",
    path: WebPaths.AppendDataset,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <Dataset />
      </NewThemeWrapper>
    )
  },
  {
    key: "standard-recipe-data-container",
    path: WebPaths.StandardRecipeDataContainer,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <StandardRecipeDataContainer />
  },
  {
    key: "standard-recipe-notebook-container",
    path: WebPaths.StandardRecipeNotebookContainer,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <AutoMLNotebookContainer recipeType="STANDARD" />
  },
  {
    key: "code-recipe-container",
    path: WebPaths.CodeRecipeContainer,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <CodeRecipeDataContainer />
  },
  {
    key: "code-recipe-notebook-container",
    path: WebPaths.CodeNotebookContainer,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <AutoMLNotebookContainer recipeType="CODE" />
  },
  {
    key: "auto-ml-recipe-container",
    path: WebPaths.AutoMLRecipeContainer,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <AutoMLRecipeDataContainer />
  },
  {
    key: "auto-ml-notebook-container",
    path: WebPaths.AutoMLNotebookContainer,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <AutoMLNotebookContainer recipeType="AUTO-ML" />
  },
  {
    key: "api-connector-recipe-container",
    path: `${WebPaths.APIConnectorRecipeContainer}/:groupId?`,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <ApiConnectorRecipeDataContainer />
  },
  {
    key: "recipe-run-logs",
    path: WebPaths.RecipeRunLogs,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <RecipeRunLogs />
  },
  {
    key: "prediction-job",
    path: WebPaths.PredictionJob,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <PredictionJob />
  },
  {
    key: "edit-prediction-job",
    path: WebPaths.EditPredictionJob,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <PredictionJobRun />
  },
  {
    key: "prediction-logs",
    path: WebPaths.PredictionLogs,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <PredictionJobLogsPage />
  },
  {
    key: "charts",
    path: WebPaths.Charts,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <Charts />
  },
  {
    key: "dag",
    path: `${WebPaths.Dag}/*`,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: <Dag />
  },
  {
    key: "scenarios",
    path: WebPaths.Scenarios,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name],
    Component: (
      <NewThemeWrapper>
        <Scenarios />
      </NewThemeWrapper>
    )
  },
  {
    key: "scenario",
    path: WebPaths.Scenario,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name],
    Component: (
      <NewThemeWrapper>
        <Scenario />
      </NewThemeWrapper>
    )
  },
  {
    key: "new-scenario",
    path: WebPaths.NewScenario,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name],
    Component: (
      <NewThemeWrapper>
        <Scenario />
      </NewThemeWrapper>
    )
  },
  {
    key: "view-data-routes",
    path: WebPaths.ViewData,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <ViewDataRoutes />
      </NewThemeWrapper>
    )
  },
  {
    key: "segment",
    path: WebPaths.Segment,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <Segment />
      </NewThemeWrapper>
    )
  },
  {
    key: "project-app-dashboard",
    path: WebPaths.ProjectDashboard,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <ProjectDataApps />
      </NewThemeWrapper>
    )
  },
  {
    key: "project-data-app",
    path: WebPaths.ProjectDataApp,
    allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name, Roles["Business User"].name],
    Component: (
      <NewThemeWrapper>
        <DataApp />
      </NewThemeWrapper>
    )
  }
];

const login = `${WebPaths.AuthPath}${WebPaths.SignIn}`;

const PrivateRoute = () => {
  const [isUserLoggedIn, token, roleName] = useAuthStore(
    (state) => [state.isUserLoggedIn, state.token, state.roleName],
    shallow
  );

  const location = useLocation();
  const { shouldRedirect, redirectToNotebook } = useAuthNotebookRedirect();
  /* If URL has includeStreamlit Auth Params, fetch streamlitToken*/
  const { shouldFetchStreamlitToken, requestState } = useAuthAwaitStreamlit();
  const [noRecord, setNoRecord] = useState(false);

  const { checkIsRoleYieldsDataAppView } = useGetRole();

  useEffect(() => {
    if (noRecord) {
      setNoRecord(false);
    }
  }, [location]);

  useEffect(() => {
    EventBus.subscribe(EVENTBUS_EVENTS.RecordNotFound, () => {
      setNoRecord(true);
    });

    return () => {
      EventBus.unsubscribe(EVENTBUS_EVENTS.RecordNotFound);
    };
  });

  const getChildComponent = (
    allowedRoles: string[],
    roleName: any,
    Component: JSX.Element,
    path: string
  ) => {
    let isNavigable = allowedRoles.includes(roleName);

    if (!isNavigable) {
      isNavigable =
        allowedRoles.includes(Roles.DataAppView.name) && checkIsRoleYieldsDataAppView(roleName);
    }

    return isNavigable ? (
      <AnalyticsWrapper path={path}>{Component}</AnalyticsWrapper>
    ) : (
      <AccessDenied />
    );
  };

  const routes = useMemo(() => {
    const privateRoutes = _.map(privateConfig, ({ key, path, Component, allowedRoles }) => (
      <Route
        key={key}
        path={path}
        element={getChildComponent(allowedRoles, roleName, Component, path)}
      />
    ));
    const projectRoutes = _.map(projectConfig, ({ key, path, Component, allowedRoles }) => (
      <Route
        key={key}
        path={path}
        element={
          <ProjectRoutesBox>
            {getChildComponent(allowedRoles, roleName, Component, path)}
          </ProjectRoutesBox>
        }
      />
    ));
    const defaultRoute = (
      <Route
        key="/"
        path={WebPaths.Dashboard}
        element={getChildComponent(
          [
            Roles.Admin.name,
            Roles.Demo.name,
            Roles.User.name,
            Roles.DataAppView.name,
            Roles["Business User"].name
          ],
          roleName,
          checkIsRoleYieldsDataAppView(roleName) ? (
            <NewThemeWrapper>
              <PlatformDataApps />
            </NewThemeWrapper>
          ) : (
            <Dashboard />
          ),
          "/"
        )}
      />
    );

    return _.concat(privateRoutes, projectRoutes, defaultRoute);
  }, [roleName]);

  if (!isUserLoggedIn) {
    return <Navigate to={{ pathname: login }} />;
  }

  if (
    shouldFetchStreamlitToken &&
    (requestState === RequestState.Idle || requestState === RequestState.Loading)
  ) {
    return <AppLoadingScreen requestPending />;
  }

  if (shouldFetchStreamlitToken && requestState === RequestState.Failure) {
    return <Navigate to={{ pathname: login, search: location.search }} replace />;
  }

  if (shouldRedirect && token) {
    redirectToNotebook(token);
  }

  if (noRecord) {
    return <RecordNotFound setNoRecord={setNoRecord} />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {routes}
        <Route path="*" element={<ComponentNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default PrivateRoute;
