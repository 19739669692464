import { v4 as uuidv4 } from "uuid";
import { uniqBy, sumBy } from "lodash";

import { validateFiles } from "src/helpers/helpers";

import {
  Criteria,
  validFileTypes,
  maximumNoOfFilesAllowed,
  maximumTotalFilesSizeAllowedInGb,
  DatasetConfig,
  DatasetHelperText
} from "./Dataset.constants";

export const getFormattedFileName = (fileName: $TSFixMe) => {
  if (!fileName) {
    return DatasetHelperText.Unknown;
  }

  const suffix = `_${uuidv4()?.substring(0, 5)}`;
  const updatedFileName = `${fileName}${suffix}`;

  if (updatedFileName?.length > DatasetConfig.acceptedDatasetNameLength) {
    return `${fileName.slice(
      0,
      DatasetConfig.acceptedDatasetNameLength - suffix?.length
    )}${suffix}`;
  }

  return fileName;
};

export const invalidFilesUploadingMessage = (files: $TSFixMe, criterion?: Criteria) => {
  const isSingleFileUpload = criterion === Criteria.SingleFileUpload;
  const isMergeOrAppendFileUpload = !!criterion
    ? [Criteria.Merge, Criteria.Append].includes(criterion)
    : false;

  const areAllFileTypesValid = validateFiles(files || [], validFileTypes) || false;

  if (!areAllFileTypesValid) {
    return `File type is invalid. Supported file formats are ${validFileTypes.join(", ")}.`;
  }

  if (isMergeOrAppendFileUpload) {
    const uniqueFileTypes = uniqBy(files || [], "type");

    if (uniqueFileTypes?.length > 1) {
      return `Multiple file-types are not allowed in this mode!`;
    }
  }

  if (isSingleFileUpload && (files || [])?.length > 1) {
    return "Multiple files are not allowed!";
  }

  if ((files || [])?.length > maximumNoOfFilesAllowed) {
    return `Maximum ${maximumNoOfFilesAllowed} files are allowed!`;
  }

  const uploadingFilesTotalSize = sumBy(Object.values(files) || [], "size");

  // Math.pow(1024, 3) = 1 GB
  if (uploadingFilesTotalSize > maximumTotalFilesSizeAllowedInGb * Math.pow(1024, 3)) {
    if (isSingleFileUpload) {
      return `Maximum size of the file should not exceed ${maximumTotalFilesSizeAllowedInGb} GB!`;
    } else {
      return `Maximum size of all files together should not exceed ${maximumTotalFilesSizeAllowedInGb} GB!`;
    }
  }

  return "";
};
