/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DataappAskAIConfig
 */
export interface DataappAskAIConfig {
    /**
     * 
     * @type {string}
     * @memberof DataappAskAIConfig
     */
    'systemMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataappAskAIConfig
     */
    'outputType'?: DataappAskAIConfigOutputTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DataappAskAIConfig
     */
    'historicalMessagesLength'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataappAskAIConfig
     */
    'llmType'?: DataappAskAIConfigLlmTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataappAskAIConfig
     */
    'useCase'?: DataappAskAIConfigUseCaseEnum;
    /**
     * 
     * @type {number}
     * @memberof DataappAskAIConfig
     */
    'temperature'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataappAskAIConfig
     */
    'dataSharingStrategy'?: DataappAskAIConfigDataSharingStrategyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DataappAskAIConfig
     */
    'enableCache'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataappAskAIConfig
     */
    'inputType'?: DataappAskAIConfigInputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataappAskAIConfig
     */
    'customEnvId'?: string;
}

export const DataappAskAIConfigOutputTypeEnum = {
    Text: 'TEXT',
    Chart: 'CHART',
    Image: 'IMAGE',
    Dataset: 'DATASET',
    TextNoCode: 'TEXT_NO_CODE',
    Function: 'FUNCTION',
    Unrecognized: 'UNRECOGNIZED',
    AutoInfer: 'AUTO_INFER',
    Model: 'MODEL'
} as const;

export type DataappAskAIConfigOutputTypeEnum = typeof DataappAskAIConfigOutputTypeEnum[keyof typeof DataappAskAIConfigOutputTypeEnum];
export const DataappAskAIConfigLlmTypeEnum = {
    OpenaiGpt4Turbo: 'OPENAI_GPT_4_TURBO',
    OpenaiGpt4O: 'OPENAI_GPT_4_O',
    AzureOpenaiGpt4O: 'AZURE_OPENAI_GPT_4_O',
    AnthropicClaude35Sonnet: 'ANTHROPIC_CLAUDE_3_5_SONNET'
} as const;

export type DataappAskAIConfigLlmTypeEnum = typeof DataappAskAIConfigLlmTypeEnum[keyof typeof DataappAskAIConfigLlmTypeEnum];
export const DataappAskAIConfigUseCaseEnum = {
    ConversationUseCase: 'CONVERSATION_USE_CASE',
    ProblemTypeUseCase: 'PROBLEM_TYPE_USE_CASE',
    TargetColumnUseCase: 'TARGET_COLUMN_USE_CASE',
    OneShotUseCase: 'ONE_SHOT_USE_CASE',
    PromptSuggestionsUseCase: 'PROMPT_SUGGESTIONS_USE_CASE',
    RagUseCase: 'RAG_USE_CASE',
    ExplainCodeUseCase: 'EXPLAIN_CODE_USE_CASE',
    PredictionServiceUseCase: 'PREDICTION_SERVICE_USE_CASE',
    ChartEditUseCase: 'CHART_EDIT_USE_CASE'
} as const;

export type DataappAskAIConfigUseCaseEnum = typeof DataappAskAIConfigUseCaseEnum[keyof typeof DataappAskAIConfigUseCaseEnum];
export const DataappAskAIConfigDataSharingStrategyEnum = {
    SampleData: 'SAMPLE_DATA',
    OnlyMetadata: 'ONLY_METADATA'
} as const;

export type DataappAskAIConfigDataSharingStrategyEnum = typeof DataappAskAIConfigDataSharingStrategyEnum[keyof typeof DataappAskAIConfigDataSharingStrategyEnum];
export const DataappAskAIConfigInputTypeEnum = {
    ProjectCanvas: 'PROJECT_CANVAS',
    Dataset: 'DATASET',
    SqlDb: 'SQL_DB',
    RagFiles: 'RAG_FILES',
    PredictionService: 'PREDICTION_SERVICE'
} as const;

export type DataappAskAIConfigInputTypeEnum = typeof DataappAskAIConfigInputTypeEnum[keyof typeof DataappAskAIConfigInputTypeEnum];


