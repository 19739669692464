import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const SnippetGeneratorIcon = ({
  width = 18,
  height = 18,
  viewBox = "0 0 18 18",
  fill = "none",
  color = "#396083"
}: Props) => (
  <svg
    width={width}
    height={height}
    style={{ marginTop: "2px" }}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12322_14521)">
      <path
        d="M14.3851 3.26231L11.2601 0.137313C11.1722 0.049375 11.053 0 10.9287 0H2.80371C2.54484 0 2.33496 0.209875 2.33496 0.46875V15.5312C2.33496 15.7901 2.54484 16 2.80371 16H14.0537C14.3126 16 14.5225 15.7901 14.5225 15.5312V3.59375C14.5225 3.46941 14.4731 3.35019 14.3851 3.26231ZM11.3975 1.60041L12.9221 3.125H11.3975V1.60041ZM13.585 15.0625H3.27246V0.9375H10.46V3.59375C10.46 3.85262 10.6698 4.0625 10.9287 4.0625H13.585V15.0625Z"
        fill={color}
      />
      <path
        d="M4.67871 5.3125H5.20265C5.2534 5.52638 5.33774 5.72734 5.4503 5.90925L5.07949 6.28006C4.89643 6.46313 4.89643 6.75991 5.07949 6.94297C5.26255 7.12603 5.55934 7.12603 5.7424 6.94297L6.11321 6.57216C6.29512 6.68472 6.49605 6.76906 6.70996 6.81981V7.34375C6.70996 7.60262 6.91984 7.8125 7.17871 7.8125C7.43759 7.8125 7.64746 7.60262 7.64746 7.34375V6.81981C7.86134 6.76906 8.0623 6.68472 8.24421 6.57216L8.61502 6.94297C8.79809 7.12603 9.09487 7.12603 9.27793 6.94297C9.46099 6.75991 9.46099 6.46313 9.27793 6.28006L8.90712 5.90925C9.01968 5.72734 9.10402 5.52641 9.15477 5.3125H9.67871C9.93759 5.3125 10.1475 5.10262 10.1475 4.84375C10.1475 4.58488 9.93759 4.375 9.67871 4.375H9.15477C9.10402 4.16112 9.01968 3.96016 8.90712 3.77825L9.27793 3.40744C9.46099 3.22438 9.46099 2.92759 9.27793 2.74453C9.09487 2.56147 8.79809 2.56147 8.61502 2.74453L8.24421 3.11534C8.0623 3.00278 7.86137 2.91844 7.64746 2.86769V2.34375C7.64746 2.08487 7.43759 1.875 7.17871 1.875C6.91984 1.875 6.70996 2.08487 6.70996 2.34375V2.86769C6.49609 2.91844 6.29512 3.00278 6.11321 3.11534L5.7424 2.74453C5.55934 2.56147 5.26255 2.56147 5.07949 2.74453C4.89643 2.92759 4.89643 3.22438 5.07949 3.40744L5.4503 3.77825C5.33774 3.96016 5.2534 4.16109 5.20265 4.375H4.67871C4.41984 4.375 4.20996 4.58488 4.20996 4.84375C4.20996 5.10262 4.41984 5.3125 4.67871 5.3125ZM7.17871 3.75C7.7818 3.75 8.27246 4.24066 8.27246 4.84375C8.27246 5.44684 7.7818 5.9375 7.17871 5.9375C6.57562 5.9375 6.08496 5.44684 6.08496 4.84375C6.08496 4.24066 6.57562 3.75 7.17871 3.75Z"
        fill={color}
      />
      <path
        d="M4.20996 9.84375C4.20996 10.1026 4.41984 10.3125 4.67871 10.3125H12.1787C12.4376 10.3125 12.6475 10.1026 12.6475 9.84375C12.6475 9.58487 12.4376 9.375 12.1787 9.375H4.67871C4.41984 9.375 4.20996 9.58487 4.20996 9.84375Z"
        fill={color}
      />
      <path
        d="M12.1787 11.25H4.67871C4.41984 11.25 4.20996 11.4599 4.20996 11.7188C4.20996 11.9776 4.41984 12.1875 4.67871 12.1875H12.1787C12.4376 12.1875 12.6475 11.9776 12.6475 11.7188C12.6475 11.4599 12.4376 11.25 12.1787 11.25Z"
        fill={color}
      />
      <path
        d="M12.1787 13.125H4.67871C4.41984 13.125 4.20996 13.3349 4.20996 13.5938C4.20996 13.8526 4.41984 14.0625 4.67871 14.0625H12.1787C12.4376 14.0625 12.6475 13.8526 12.6475 13.5938C12.6475 13.3349 12.4376 13.125 12.1787 13.125Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_12322_14521">
        <rect width="16" height="16" fill="white" transform="translate(0.428711)" />
      </clipPath>
    </defs>
  </svg>
);

export default SnippetGeneratorIcon;
