import React, { useRef, ChangeEvent, useState, useEffect, useMemo } from "react";
import {
  makeStyles,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  Chip
} from "@material-ui/core";
import { SendSharp } from "@material-ui/icons";

import QuerySpinner from "./QuerySpinner";
import { getAPIWithRethrow } from "src/utils/apiService";
import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";
import { OUTPUT_TYPE } from "../../../CodeRecipeContext/CodeRecipeContextProvider";
import _, {
  isEmpty,
  includes,
  map,
  filter,
  toLower,
  flatten,
  keyBy,
  mapValues,
  pick,
  values,
  uniq
} from "lodash";
import useMultipleEntityFeatures from "src/hooks/api/projects/useMultipleEntityFeatures";
import { ModalWarningDialog } from "./ModalWarningDialog";
import { extractAskAIDatasets } from "../askai.helper";
import {
  AIChatRequestDtoOutputTypeEnum,
  AIChatRequestDtoUseCaseEnum,
  AIChatResponseDto
} from "openapi/Models";
import { useQueryClient } from "@tanstack/react-query";
import { AI_GUIDE_MESSAGES_KEY } from "src/hooks/api";
import ColumnSuggestionsPopper from "./ColumnSuggestionsPopper";
import SelectOutputType from "./SelectOutputType";

const useStyles = makeStyles({
  send: {
    color: ({ disabled }: { disabled: boolean }) => (disabled ? "rgba(0, 0, 0, 0.6)" : "#4646b5")
  },
  input: {
    height: "100%",
    borderRadius: "0px 24px 24px 0px",
    background: "#fff",
    "&:hover $notchedOutline": {
      borderLeftWidth: "1px !important"
    },
    "&$focused $notchedOutline": {
      borderLeftWidth: "1px !important"
    }
  },
  notchedOutline: {
    borderLeftWidth: "0px"
  },
  root: {
    height: "100%",
    minHeight: "51px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: "0px"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: "1px !important"
    }
  }
});

export const STOPPED_QUERY_ID = "stopped_query";

export const GenerateCodeQueryInput = ({
  userInput,
  disabled,
  isFetchingSuggestions,
  onFetchPromptSuggestions,
  onUserInputChange,
  onReset,
  autoGenerateOutputType
}: {
  disabled: boolean;
  userInput: string;
  onUserInputChange: (userInput: string) => void;
  isFetchingSuggestions: boolean;
  onFetchPromptSuggestions: (response: { payload: any }) => void;
  onReset: () => void;
  autoGenerateOutputType: string;
}) => {
  const {
    recipeId,
    responses,
    inputNames,
    isAutoGenerateInProgress,
    pinnedNames,
    setInputNames,
    reset,
    outputType,
    inputDatasets,
    setOutputType,
    setResponses,
    onAutoGenerateCode,
    newAskAIFlow,
    threadId,
    autoGenerateQueryUserInput,
    isRetryInProgress
  } = useCodeRecipeContext();

  const textFieldRef = useRef<HTMLTextAreaElement>(null);
  const isStopped = useRef<boolean>(false);
  const queryClient = useQueryClient();

  const [anchorEl, setAnchorEl] = useState<HTMLTextAreaElement | null>(null);
  const [anchorElColumn, setAnchorElColumn] = useState<HTMLTextAreaElement | null>(null);
  const [filteredSuggestions, setFilteredSuggestion] = useState<string[]>([]);
  const [showModalWarningDialog, setShowModalWarningDialog] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(0);
  const [atIndex, setAtIndex] = useState<number | null>(null);
  const menuItemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const isOutputTypePrompt = outputType === OUTPUT_TYPE.PROMPT_SUGGESTIONS;
  const askAIModalGenerationWarning = localStorage.getItem("askAIModalGenerationWarning");

  const isGenerateBtnInvalid = useMemo(
    () =>
      inputNames?.length === 0 ||
      !recipeId ||
      (!userInput?.trimEnd() && !isOutputTypePrompt) ||
      !outputType?.trim(),
    [inputNames, recipeId, userInput, isOutputTypePrompt, outputType]
  );

  const isGenerateBtnDisabled = useMemo(
    () => isGenerateBtnInvalid || isAutoGenerateInProgress || disabled || isRetryInProgress,
    [isGenerateBtnInvalid, isAutoGenerateInProgress, disabled, isRetryInProgress]
  );

  const intermediateDatasetColumns = useMemo(() => {
    if (newAskAIFlow) {
      return mapValues(
        keyBy(
          flatten(
            filter(responses, (item) => item.outputType === "DATASET")?.map(
              (entity: any) => entity.outputEntityResponseList || entity.outputEntityList
            )
          ),
          "name"
        ),
        (value) => value?.data?.columns || value?.datasetCols
      );
    }
    return mapValues(
      keyBy(
        flatten(
          map(
            filter(responses, (item) => item.outputType === "DATASET"),
            "queryOutputs"
          )
        ),
        "name"
      ),
      "entityCols"
    );
  }, [responses]);

  const entityIds = useMemo(
    () =>
      map(
        filter(inputDatasets, (entity) => inputNames.includes(entity.displayName || entity.name)),
        "id"
      ),
    [inputDatasets, inputNames]
  );

  const classes = useStyles({ disabled: isGenerateBtnDisabled });
  const result = useMultipleEntityFeatures(undefined, entityIds);

  const avaliableColumns = useMemo(() => {
    const columns = flatten(values(pick(intermediateDatasetColumns, inputNames)));
    if (result.data) {
      return uniq([...result.data, ...columns]);
    } else {
      return columns;
    }
  }, [result.data, intermediateDatasetColumns, inputNames]);

  const autoGenerateCode = () => {
    setOutputType(OUTPUT_TYPE.AUTO_INFER);

    const { intermediateDatasets, entityIds } = extractAskAIDatasets({
      inputDatasets,
      inputNames,
      responses
    });

    const payload = {
      userInput: userInput?.trim(),
      entityIds,
      outputType,
      recipeId,
      intermediateDatasets,
      inputNames
    };

    if (isOutputTypePrompt) {
      onUserInputChange("");
      onFetchPromptSuggestions({ payload });
      return;
    }

    const onSuccess = (response: $TSFixMe) => {
      if (isStopped.current || !response) {
        isStopped.current = false;
        return;
      }
      if (newAskAIFlow) {
        const outputName = response.outputEntityResponseList?.[0]?.name;
        if (isEmpty(pinnedNames) && response.outputType === OUTPUT_TYPE.DATASET && outputName) {
          setInputNames([outputName]);
        }
        return;
      }
      const output = response?.history?.outputType ?? outputType;
      const updatedOutputNames =
        output === OUTPUT_TYPE.CHART
          ? response?.pipelineTestResult?.entityViewData?.map((entity: any) => entity.name)
          : Object.keys(response?.pipelineTestResult?.dataMap);
      setResponses((responses: any) => [
        ...responses,
        {
          ...response,
          ...response?.history,
          userInput: userInput?.trim(),
          queryInputs: inputNames.map((name) => ({ name })),
          outputType: output,
          outputNames: updatedOutputNames,
          isExpanded: true,
          answer: response?.pipelineTestResult?.answer
        }
      ]);
      if (
        isEmpty(pinnedNames) &&
        output === OUTPUT_TYPE.DATASET &&
        response.history?.queryOutputs?.[0]?.name
      ) {
        setInputNames([response.history.queryOutputs[0].name]);
      }
    };

    const onError = async (error: any) => {
      if (isStopped.current || !error) {
        isStopped.current = false;
        return;
      }
      if (newAskAIFlow) {
        return;
      }

      const commonResponsesParams = {
        userInput: userInput?.trim(),
        queryInputs: inputNames.map((name) => ({ name })),
        outputType,
        outputStatus: "FAILED"
      };
      const errorMsg = error.response?.data?.msg || error.response?.data; /* 
      const errorStatus = error.response.status;
      if (errorStatus >= 500 && errorStatus < 600) {
        setResponses([
          ...responses,
          {
            execErr: "Oops, something went wrong. please try again",
            ...commonResponsesParams,
            userInput: query?.trimEnd()
          }
        ]);
        return;
      } */
      if (errorMsg?.includes("codeHistoryId=")) {
        const match = errorMsg?.match(/codeHistoryId=(.*)/);
        const codeHistoryId = match ? match[1] : null;
        const response = await getAPIWithRethrow(
          `/v2/dfs-run-config-groups/${recipeId}/code-history/${codeHistoryId}`
        );
        if (!response?.erroneousCode) {
          setResponses((responses: any) => [
            ...responses,
            {
              execErr: response.execErr.replace(/codeHistoryId=.*$/, ""),
              ...commonResponsesParams
            }
          ]);
          return;
        }
        setResponses((responses: any) => [
          ...responses,
          {
            execErr: response.execErr.replace(/codeHistoryId=.*$/, ""),
            erroneousCode: response.erroneousCode,
            errDetails: response.errDetails,
            ...commonResponsesParams
          }
        ]);
      } else {
        setResponses((responses: any) => [
          ...responses,
          {
            execErr: errorMsg,
            ...commonResponsesParams
          }
        ]);
      }
    };
    onUserInputChange("");
    onAutoGenerateCode({ payload, onSuccess, onError });
  };

  const handleStop = () => {
    isStopped.current = true;
    const baseResponse = {
      threadId: threadId!,
      query: autoGenerateQueryUserInput,
      askAIMessageId: STOPPED_QUERY_ID,
      isExpanded: true,
      outputType: autoGenerateOutputType ?? AIChatRequestDtoOutputTypeEnum.Text,
      error: {
        explanation: "Query run stopped",
        errorType: "errorType"
      } as any,
      targetInputs: inputNames.map((name) => ({ entityName: name }))
    } as AIChatResponseDto;

    if (isFetchingSuggestions) {
      onReset();

      setResponses((responses: any) => {
        if (newAskAIFlow) {
          const updatedResponses = [
            ...responses,
            {
              ...baseResponse,
              useCase: AIChatRequestDtoUseCaseEnum.PromptSuggestionsUseCase
            }
          ];

          queryClient.setQueryData([AI_GUIDE_MESSAGES_KEY, threadId], updatedResponses);

          return updatedResponses;
        }
        return [
          ...responses,
          {
            execErr: "Prompt suggestions generation stopped",
            userInput: autoGenerateQueryUserInput,
            queryInputs: inputNames.map((name) => ({ name })),
            outputType,
            hideDelete: true,
            historyId: STOPPED_QUERY_ID,
            outputStatus: "FAILED"
          }
        ];
      });
      return;
    }

    reset();
    setResponses((responses: any) => {
      if (newAskAIFlow) {
        const updatedResponses = [
          ...responses,
          {
            ...baseResponse,
            useCase: AIChatRequestDtoUseCaseEnum.ConversationUseCase
          }
        ];
        queryClient.setQueryData([AI_GUIDE_MESSAGES_KEY, threadId], updatedResponses);
        return updatedResponses;
      }
      return [
        ...responses,
        {
          execErr: "Query run stopped",
          userInput: autoGenerateQueryUserInput,
          queryInputs: inputNames.map((name) => ({ name })),
          outputType,
          hideDelete: true,
          historyId: STOPPED_QUERY_ID,
          outputStatus: "FAILED"
        }
      ];
    });
  };

  useEffect(() => {
    if (atIndex !== null) {
      const query = userInput.slice(atIndex + 1, textFieldRef.current?.selectionStart);
      if (query.length >= 0) {
        const newSuggestion = filter(
          avaliableColumns,
          (item) => query.length === 0 || includes(toLower(item), toLower(query))
        );
        setFilteredSuggestion(_.uniq(newSuggestion));
        if (!isEmpty(newSuggestion) && textFieldRef.current) {
          setAnchorElColumn(textFieldRef.current);
        } else {
          setAnchorElColumn(null);
        }
      } else {
        setAnchorElColumn(null);
      }
    } else {
      setAnchorElColumn(null);
    }
  }, [userInput, atIndex]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (val: OUTPUT_TYPE) => {
    setOutputType(val);
    setAnchorEl(null);
    if (userInput.endsWith("/")) {
      onUserInputChange(val === OUTPUT_TYPE.PROMPT_SUGGESTIONS ? "" : userInput.slice(0, -1));
    }
  };

  const handleModalClick = () => {
    setAnchorEl(null);
    onUserInputChange(userInput.slice(0, -1));
    if (!askAIModalGenerationWarning) {
      setShowModalWarningDialog(true);
      localStorage.setItem("askAIModalGenerationWarning", "true");
      return;
    } else {
      handleClick(OUTPUT_TYPE.MODEL);
    }
  };

  useEffect(() => {
    if (anchorElColumn) {
      textFieldRef.current?.focus();
    } else {
      setHighlightedIndex(0);
    }
  }, [anchorElColumn]);

  const handleSuggestionClick = (suggestion: string) => {
    if (atIndex === null || isEmpty(suggestion)) return;

    const beforeMention = userInput.slice(0, atIndex);
    const afterMention = userInput.slice(textFieldRef.current?.selectionStart);
    const newValue = `${beforeMention}${suggestion} ${afterMention}`;

    onUserInputChange(newValue);
    setAnchorElColumn(null);
    setAtIndex(null);
    textFieldRef.current?.focus();
    const newCursorPosition = beforeMention.length + suggestion.length + 1;
    setTimeout(() => {
      textFieldRef.current?.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const val = event.target.value;
    onUserInputChange(val);

    if (val && val.endsWith("/")) {
      setAnchorEl(event.currentTarget);
      setAtIndex(null);
    } else if (val) {
      const cursorPosition = event.target.selectionStart;
      if (cursorPosition) {
        const lastAtSymbolIndex = val.lastIndexOf("@", cursorPosition - 1);
        setAtIndex(lastAtSymbolIndex !== -1 ? lastAtSymbolIndex : null);
      }
    } else {
      setAtIndex(null);
    }
  };

  const handleDelete = () => {
    setOutputType(OUTPUT_TYPE.AUTO_INFER);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (anchorElColumn) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === null || prevIndex === filteredSuggestions.length - 1
            ? prevIndex
            : prevIndex + 1
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex === null || prevIndex === 0 ? prevIndex : prevIndex - 1
        );
      } else if (event.key === "Enter" && highlightedIndex !== null) {
        event.preventDefault();
        handleSuggestionClick(filteredSuggestions[highlightedIndex]);
      }
    } else if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
      if (isOutputTypePrompt) {
        return;
      }
      // For Command + Enter
      event.preventDefault();
      onUserInputChange(userInput + "\n");
    } else if (isGenerateBtnDisabled || isAutoGenerateInProgress) {
      return;
    } else if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      setAtIndex(null);
      setAnchorElColumn(null);
      autoGenerateCode();
    }
  };
  useEffect(() => {
    if (highlightedIndex !== null && menuItemRefs.current[highlightedIndex]) {
      menuItemRefs.current[highlightedIndex]!.scrollIntoView({
        behavior: "smooth",
        block: "nearest"
      });
    }
  }, [highlightedIndex]);

  return (
    <>
      <TextField
        size="small"
        fullWidth
        value={userInput}
        className={classes.root}
        variant="outlined"
        placeholder={
          isOutputTypePrompt
            ? "Generate prompt suggestions by pressing Enter or clicking the send button"
            : "Enter your query here. Use / for options"
        }
        test-id="ask-ai-modal-text-field"
        inputProps={{
          "test-id": { inputTestId: "ask-ai-modal-input" }
        }}
        multiline
        minRows={1}
        maxRows={3}
        inputRef={textFieldRef}
        onKeyDown={handleKeyDown}
        InputProps={{
          className: classes.input,
          classes: {
            notchedOutline: classes.notchedOutline
          },
          startAdornment:
            outputType !== OUTPUT_TYPE.AUTO_INFER ? (
              <InputAdornment position="start">
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={outputType}
                  onDelete={handleDelete}
                />
              </InputAdornment>
            ) : undefined,
          endAdornment:
            isAutoGenerateInProgress || isFetchingSuggestions ? (
              <QuerySpinner onStop={handleStop} />
            ) : (
              <Tooltip
                arrow
                title={
                  isGenerateBtnInvalid
                    ? "Add Inputs and Query to enable"
                    : isOutputTypePrompt
                      ? "Click on send to get prompt suggestions"
                      : isFetchingSuggestions
                        ? "Please wait until prompt suggestions are generated"
                        : isRetryInProgress
                          ? "Please wait until retry query is finished"
                          : ""
                }>
                {/* div has to enclose button to display tooltip when disabled */}
                <div>
                  <IconButton
                    color="primary"
                    size="small"
                    test-id="ask-ai-modal-generate-code-btn"
                    disabled={isGenerateBtnDisabled}
                    onClick={autoGenerateCode}>
                    <SendSharp className={classes.send} fontSize="small" style={{ opacity: 1 }} />
                  </IconButton>
                </div>
              </Tooltip>
            )
        }}
        disabled={isAutoGenerateInProgress || isRetryInProgress}
        onChange={handleChange}
      />
      {showModalWarningDialog && (
        <ModalWarningDialog
          handleSubmit={() => {
            setShowModalWarningDialog(false);
            handleClick(OUTPUT_TYPE.MODEL);
          }}
          onClose={() => setShowModalWarningDialog(false)}
        />
      )}
      <SelectOutputType
        anchorEl={anchorEl}
        onModelClick={handleModalClick}
        onClose={handleClose}
        onClick={handleClick}
      />
      <ColumnSuggestionsPopper
        highlightedIndex={highlightedIndex}
        menuItemRefs={menuItemRefs}
        anchorElColumn={anchorElColumn}
        filteredSuggestions={filteredSuggestions}
        onClose={() => setAnchorElColumn(null)}
        onSuggestionClick={handleSuggestionClick}
      />
    </>
  );
};
