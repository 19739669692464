import { createTheme } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";

export const newTheme = createTheme({
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff"
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#4646b5"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#ff5c00"
    },
    error: {
      main: "#da1d27"
    },
    warning: {
      main: "#f5c726"
    },
    info: {
      main: "#008fe4"
    },
    success: {
      main: "#5fcb84"
    },
    text: {
      primary: "#003656",
      secondary: "#515151"
    },
    background: {
      default: "#f4f4f4"
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // Ex: Shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  },
  overrides: {
    MuiTableCell: {
      body: {
        color: "#212529"
      }
    },
    MuiButton: {
      root: {
        textTransform: "capitalize"
      },
      containedPrimary: {
        backgroundColor: "#4646b5"
      },
      outlined: {
        backgroundColor: "#fff"
      },
      outlinedPrimary: {
        minWidth: "auto"
      },
      text: {
        minWidth: "auto",
        backgroundColor: "#fff",
        borderColor: "#fff",
        paddingLeft: 10,
        paddingRight: 10,
        "&.Mui-disabled": {
          borderColor: "#fff"
        }
      }
    },
    MuiToolbar: {
      dense: {
        minHeight: 44
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: "#bdbdbd" // palette.gray.400
      },
      rounded: {
        borderRadius: 8
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "14px"
      }
    },
    // Dialog
    MuiDialog: {
      paper: {
        borderRadius: 8
      }
    },
    MuiDialogActions: {
      root: {
        padding: "16px 24px"
      }
    },
    MuiInputLabel: {
      root: {
        "&.MuiInputLabel-outlined, &.MuiInputLabel-shrink": {
          width: "calc(133% - 32px) !important" // Account width for shrinked label size(0.75x and ellipsis)
        }
      },
      outlined: {
        overflow: "hidden",
        width: "calc(100% - 24px)",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        top: "0px",
        left: "0px"
      }
    },
    MuiStepConnector: {
      lineVertical: {
        borderLeftStyle: "dashed",
        marginTop: "2px"
      },
      line: {
        borderLeftStyle: "dashed",
        marginTop: "2px"
      }
    },
    MuiStepContent: {
      root: {
        borderLeft: "1px dashed #bdbdbd"
      }
    },
    MuiStepper: {
      root: {
        padding: "6px"
      }
    },
    MuiStepLabel: {
      label: {
        fontWeight: 400,
        marginBottom: "4px"
      },
      active: {
        fontWeight: 400
      }
    },
    MuiMenu: {
      list: {
        paddingTop: "0px"
      }
    }
  }
});

export const oldTheme = createTheme({
  palette: {
    primary: {
      main: "#003656",
      light: "#415F80",
      [100]: "#ECEFF2"
    },
    secondary: {
      main: "#f1957d",
      contrastText: "#FFFFFF"
    },
    background: {
      paper: "#FFFFFF",
      // default: "#F5F7F9"
      // New UX change
      default: "#f3f4f5"
    },
    grey: {
      ...grey,
      [400]: "#828282",
      [600]: "#4F4F4F"
    },
    // @ts-expect-error TS(2322) FIXME: Type '{ primary: { main: string; light: string; 10... Remove this comment to see the full error message
    danger: {
      main: "#DA5957",
      dark: "#c8211e",
      light: "#F5F0F1",
      contrast: "#E31B0C"
    }
  },
  typography: {
    h4: {
      fontWeight: 500
    }
  },
  props: {
    MuiButton: {
      disableElevation: true
    }
  },
  overrides: {
    MuiButton: {
      root: {
        whiteSpace: "nowrap"
      },
      containedPrimary: {
        color: "#FFFFFF",
        backgroundColor: "#008fe4",
        fontWeight: 400,
        "&:hover": {
          backgroundColor: "rgb(0, 100, 159)"
        }
      },
      containedSecondary: {
        color: "#FFFFFF"
      },
      sizeLarge: {
        padding: "12px 22px"
      }
    },
    MuiFormControlLabel: {
      label: {
        color: "#123553"
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "14px"
      }
    },
    MuiInputLabel: {
      root: {
        "&.MuiInputLabel-outlined, &.MuiInputLabel-shrink": {
          width: "calc(133% - 32px) !important" // Account width for shrinked label size(0.75x and ellipsis)
        }
      },
      outlined: {
        overflow: "hidden",
        width: "calc(100% - 24px)",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        top: "0px",
        left: "0px"
      }
    },
    MuiTabs: {
      root: {
        minHeight: 44,
        background: "#FFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.05), 0px 1px 1px 0px rgba(0,0,0,0.05), 0px 1px 3px 0px rgba(0,0,0,0.05)"
      },
      indicator: {
        backgroundColor: "#008FE4"
      }
    },
    MuiTab: {
      root: {
        textTransform: "capitalize",
        minHeight: 44,
        "&$selected": {
          color: "#008FE4 !important",
          background: "#E5F4FF"
        }
      }
    },
    MuiMenu: {
      list: {
        paddingTop: "0px"
      }
    }
  }
});
