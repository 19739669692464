/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { ByteString } from './byte-string';
// May contain unused imports in some cases
// @ts-ignore
import type { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import type { FieldProperties } from './field-properties';
// May contain unused imports in some cases
// @ts-ignore
import type { FieldPropertiesOrBuilder } from './field-properties-or-builder';
// May contain unused imports in some cases
// @ts-ignore
import type { UnknownFieldSet } from './unknown-field-set';

/**
 * 
 * @export
 * @interface FieldSchema
 */
export interface FieldSchema {
    /**
     * 
     * @type {UnknownFieldSet}
     * @memberof FieldSchema
     */
    'unknownFields'?: UnknownFieldSet;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'fieldName'?: string;
    /**
     * 
     * @type {number}
     * @memberof FieldSchema
     */
    'serializedSize'?: number;
    /**
     * 
     * @type {object}
     * @memberof FieldSchema
     */
    'parserForType'?: object;
    /**
     * 
     * @type {FieldSchema}
     * @memberof FieldSchema
     */
    'defaultInstanceForType'?: FieldSchema;
    /**
     * 
     * @type {number}
     * @memberof FieldSchema
     */
    'rank'?: number;
    /**
     * 
     * @type {FieldProperties}
     * @memberof FieldSchema
     */
    'fieldProperties'?: FieldProperties;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'pandasDataType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'rcDataType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'datetimeFormat'?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'pandasDataTypeOriginal'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldSchema
     */
    'fieldNameBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldSchema
     */
    'pandasDataTypeBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldSchema
     */
    'rcDataTypeBytes'?: ByteString;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldSchema
     */
    'datetimeFormatBytes'?: ByteString;
    /**
     * 
     * @type {FieldPropertiesOrBuilder}
     * @memberof FieldSchema
     */
    'fieldPropertiesOrBuilder'?: FieldPropertiesOrBuilder;
    /**
     * 
     * @type {number}
     * @memberof FieldSchema
     */
    'rcDataTypeEnumValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'rcDataTypeEnum'?: FieldSchemaRcDataTypeEnumEnum;
    /**
     * 
     * @type {ByteString}
     * @memberof FieldSchema
     */
    'pandasDataTypeOriginalBytes'?: ByteString;
    /**
     * 
     * @type {number}
     * @memberof FieldSchema
     */
    'rcDataTypeOriginalValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'rcDataTypeOriginal'?: FieldSchemaRcDataTypeOriginalEnum;
    /**
     * 
     * @type {number}
     * @memberof FieldSchema
     */
    'rcOntlogyOriginalValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'rcOntlogyOriginal'?: FieldSchemaRcOntlogyOriginalEnum;
    /**
     * 
     * @type {number}
     * @memberof FieldSchema
     */
    'rcOntologyValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'rcOntology'?: FieldSchemaRcOntologyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FieldSchema
     */
    'initialized'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FieldSchema
     */
    'initializationErrorString'?: string;
    /**
     * 
     * @type {Descriptor}
     * @memberof FieldSchema
     */
    'descriptorForType'?: Descriptor;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FieldSchema
     */
    'allFields'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof FieldSchema
     */
    'memoizedSerializedSize'?: number;
}

export const FieldSchemaRcDataTypeEnumEnum = {
    Long: 'LONG',
    Float: 'FLOAT',
    String: 'STRING',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Any: 'ANY',
    Double: 'DOUBLE',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type FieldSchemaRcDataTypeEnumEnum = typeof FieldSchemaRcDataTypeEnumEnum[keyof typeof FieldSchemaRcDataTypeEnumEnum];
export const FieldSchemaRcDataTypeOriginalEnum = {
    Long: 'LONG',
    Float: 'FLOAT',
    String: 'STRING',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Any: 'ANY',
    Double: 'DOUBLE',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type FieldSchemaRcDataTypeOriginalEnum = typeof FieldSchemaRcDataTypeOriginalEnum[keyof typeof FieldSchemaRcDataTypeOriginalEnum];
export const FieldSchemaRcOntlogyOriginalEnum = {
    Unknown: 'UNKNOWN',
    Address: 'ADDRESS',
    Ban: 'BAN',
    CreditCard: 'CREDIT_CARD',
    EmailAddress: 'EMAIL_ADDRESS',
    Uuid: 'UUID',
    HashOrKey: 'HASH_OR_KEY',
    Ipv4: 'IPV4',
    Ipv6: 'IPV6',
    MacAddress: 'MAC_ADDRESS',
    Person: 'PERSON',
    PhoneNumber: 'PHONE_NUMBER',
    Ssn: 'SSN',
    Url: 'URL',
    UsState: 'US_STATE',
    DriversLicense: 'DRIVERS_LICENSE',
    Date: 'DATE',
    Time: 'TIME',
    Datetime: 'DATETIME',
    Integer: 'INTEGER',
    Decimal: 'DECIMAL',
    Quantity: 'QUANTITY',
    Ordinal: 'ORDINAL',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type FieldSchemaRcOntlogyOriginalEnum = typeof FieldSchemaRcOntlogyOriginalEnum[keyof typeof FieldSchemaRcOntlogyOriginalEnum];
export const FieldSchemaRcOntologyEnum = {
    Unknown: 'UNKNOWN',
    Address: 'ADDRESS',
    Ban: 'BAN',
    CreditCard: 'CREDIT_CARD',
    EmailAddress: 'EMAIL_ADDRESS',
    Uuid: 'UUID',
    HashOrKey: 'HASH_OR_KEY',
    Ipv4: 'IPV4',
    Ipv6: 'IPV6',
    MacAddress: 'MAC_ADDRESS',
    Person: 'PERSON',
    PhoneNumber: 'PHONE_NUMBER',
    Ssn: 'SSN',
    Url: 'URL',
    UsState: 'US_STATE',
    DriversLicense: 'DRIVERS_LICENSE',
    Date: 'DATE',
    Time: 'TIME',
    Datetime: 'DATETIME',
    Integer: 'INTEGER',
    Decimal: 'DECIMAL',
    Quantity: 'QUANTITY',
    Ordinal: 'ORDINAL',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type FieldSchemaRcOntologyEnum = typeof FieldSchemaRcOntologyEnum[keyof typeof FieldSchemaRcOntologyEnum];


