import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import React, { useState } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

import useAuthStore from "src/stores/auth.store";

import GradientButton from "components/Buttons/GradientButton";
import MagicGenerateIcon from "src/icons/MagicGenerateIcon";

import { AskAiA11yBackdrop } from "src/components/custom";

const useStyles = makeStyles({
  flexContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    alignItems: "center",
    marginTop: "40px",

    "& button": {
      textTransform: "capitalize",
      backgroundColor: "rgba(0, 143, 228, 1)",
      color: "#fff !important",

      "&:hover": {
        backgroundColor: "rgba(0, 143, 228, 1)",
        color: "white !important"
      }
    }
  },

  span: {
    fontSize: 12,
    color: "#7c7c7c",

    "& svg": {
      width: 16,
      height: 16
    }
  },

  subText: { fontSize: 12, fontWeight: 400, color: "#7c7c7c" },

  button: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "center"
  }
});

interface IProps {
  isLoading: boolean;
  prevLength: number;
  onGenerate: (limit: number) => void;
}

const EdaAdditionalDefault: React.FC<IProps> = (props) => {
  const { flexContainer, subText, button, span } = useStyles({ disabled: props.isLoading });

  const askAiEnabled = useAuthStore((state) => state.askAiEnabled);

  const [askAiA11yChecked, setAskAiA11yChecked] = useState(false);

  return (
    <div className={flexContainer}>
      {askAiA11yChecked && !askAiEnabled && (
        <AskAiA11yBackdrop size="small" styleProps={{ minHeight: 200 }} />
      )}

      <div className={subText}>
        Include additional visualizations to augment the analysis and provide deeper insights
      </div>
      <div className={button}>
        <GradientButton
          variant="contained"
          disabled={props.isLoading}
          startIcon={
            props.isLoading ? (
              <CircularProgress size={16} style={{ color: "#fff" }} />
            ) : (
              <MagicGenerateIcon />
            )
          }
          onClick={() => {
            setAskAiA11yChecked(() => true);
            !!askAiEnabled && props.onGenerate(10 - props.prevLength);
          }}>
          {props.isLoading ? "Generating Additional Charts" : "Generate More Charts"}
        </GradientButton>
        <span className={span} style={{ fontStyle: "italic" }}>
          <ErrorOutlineIcon /> This action might take time
        </span>
        {props.isLoading && (
          <span className={span}>
            Feel free to navigate to other pages and we shall update you once additional charts are
            ready.
          </span>
        )}
      </div>
    </div>
  );
};

export default EdaAdditionalDefault;
