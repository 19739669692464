import React from "react";
import { AmazonS3Image } from "src/pages/DataSources/assets/images/AmazonS3Image";
import { AzureImage } from "src/pages/DataSources/assets/images/AzureImage";
import { GcpImage } from "src/pages/DataSources/assets/images/GcpImage";
import { MongoDbImage } from "src/pages/DataSources/assets/images/MongoDbImage";
import { MySqlImage } from "src/pages/DataSources/assets/images/MySqlImage";
// import { RedisImage } from "src/pages/DataSources/assets/images/RedisImage";
import { RedshiftImage } from "src/pages/DataSources/assets/images/RedshiftImage";
import { SnowflakeImage } from "src/pages/DataSources/assets/images/SnowflakeImage";

const DATA_TYPES_ICONS = {
  CHARTS: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.28948 22.4612C1.91511 21.1019 1.91511 18.8981 3.28948 17.5388L17.5095 3.47505C18.8839 2.11579 21.1122 2.11579 22.4865 3.47505L36.7066 17.5388C38.081 18.8981 38.081 21.1019 36.7066 22.4612L22.4865 36.525C21.1122 37.8842 18.8839 37.8842 17.5095 36.525L3.28948 22.4612Z"
        fill="white"
      />
      <path
        d="M26.031 15.0277L24.6133 13.6255L18.2383 19.9304L19.6561 21.3326L26.031 15.0277ZM30.2944 13.6255L19.6561 24.1469L15.4531 20L14.0353 21.4022L19.6561 26.9612L31.7222 15.0277L30.2944 13.6255ZM8.34412 21.4022L13.9649 26.9612L15.3827 25.559L9.77194 20L8.34412 21.4022Z"
        fill="#396083"
      />
      <path
        d="M3.28948 22.4612C1.91511 21.1019 1.91511 18.8981 3.28948 17.5388L17.5095 3.47505C18.8839 2.11579 21.1122 2.11579 22.4865 3.47505L36.7066 17.5388C38.081 18.8981 38.081 21.1019 36.7066 22.4612L22.4865 36.525C21.1122 37.8842 18.8839 37.8842 17.5095 36.525L3.28948 22.4612Z"
        stroke="#003656"
      />
    </svg>
  ),
  DATASET: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V28C31.5 29.933 29.933 31.5 28 31.5H4C2.067 31.5 0.5 29.933 0.5 28V4Z"
        fill="#396083"
      />
      <path
        d="M22 10.9998L20.59 9.58984L14.25 15.9298L15.66 17.3398L22 10.9998ZM26.24 9.58984L15.66 20.1698L11.48 15.9998L10.07 17.4098L15.66 22.9998L27.66 10.9998L26.24 9.58984ZM4.41003 17.4098L10 22.9998L11.41 21.5898L5.83003 15.9998L4.41003 17.4098Z"
        fill="white"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V28C31.5 29.933 29.933 31.5 28 31.5H4C2.067 31.5 0.5 29.933 0.5 28V4Z"
        stroke="#003656"
      />
    </svg>
  ),
  GROUP: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V28C31.5 29.933 29.933 31.5 28 31.5H4C2.067 31.5 0.5 29.933 0.5 28V4Z"
        fill="white"
      />
      <path
        d="M10 13V13C10 12.0681 10 11.6022 10.1522 11.2346C10.3552 10.7446 10.7446 10.3552 11.2346 10.1522C11.6022 10 12.0681 10 13 10H18.8C19.9201 10 20.4802 10 20.908 10.218C21.2843 10.4097 21.5903 10.7157 21.782 11.092C22 11.5198 22 12.0799 22 13.2V19C22 19.9319 22 20.3978 21.8478 20.7654C21.6448 21.2554 21.2554 21.6448 20.7654 21.8478C20.3978 22 19.9319 22 19 22V22M14 9V9C14 8.06812 14 7.60218 14.1522 7.23463C14.3552 6.74458 14.7446 6.35523 15.2346 6.15224C15.6022 6 16.0681 6 17 6H22.8C23.9201 6 24.4802 6 24.908 6.21799C25.2843 6.40973 25.5903 6.71569 25.782 7.09202C26 7.51984 26 8.0799 26 9.2V15C26 15.9319 26 16.3978 25.8478 16.7654C25.6448 17.2554 25.2554 17.6448 24.7654 17.8478C24.3978 18 23.9319 18 23 18V18M9.2 26H14.8C15.9201 26 16.4802 26 16.908 25.782C17.2843 25.5903 17.5903 25.2843 17.782 24.908C18 24.4802 18 23.9201 18 22.8V17.2C18 16.0799 18 15.5198 17.782 15.092C17.5903 14.7157 17.2843 14.4097 16.908 14.218C16.4802 14 15.9201 14 14.8 14H9.2C8.0799 14 7.51984 14 7.09202 14.218C6.71569 14.4097 6.40973 14.7157 6.21799 15.092C6 15.5198 6 16.0799 6 17.2V22.8C6 23.9201 6 24.4802 6.21799 24.908C6.40973 25.2843 6.71569 25.5903 7.09202 25.782C7.51984 26 8.07989 26 9.2 26Z"
        stroke="#396083"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V28C31.5 29.933 29.933 31.5 28 31.5H4C2.067 31.5 0.5 29.933 0.5 28V4Z"
        stroke="#12B62C"
      />
    </svg>
  ),
  RECIPE: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#f1957d" />
      <path
        d="M22 10.9998L20.59 9.58984L14.25 15.9298L15.66 17.3398L22 10.9998ZM26.24 9.58984L15.66 20.1698L11.48 15.9998L10.07 17.4098L15.66 22.9998L27.66 10.9998L26.24 9.58984ZM4.41003 17.4098L10 22.9998L11.41 21.5898L5.83003 15.9998L4.41003 17.4098Z"
        fill="white"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#003656" />
    </svg>
  ),
  ARTIFACT: (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21" fill="none">
      <path
        d="M2 9V16H5V9H2ZM8 9V16H11V9H8ZM0 21H19V18H0V21ZM14 9V16H17V9H14ZM9.5 0L0 5V7H19V5L9.5 0Z"
        fill="#003656"
      />
    </svg>
  ),
  MODEL: (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M16 2H14V0H4V2H2C0.9 2 0 2.9 0 4V5C0 7.55 1.92 9.63 4.39 9.94C5.02 11.44 6.37 12.57 8 12.9V16H4V18H14V16H10V12.9C11.63 12.57 12.98 11.44 13.61 9.94C16.08 9.63 18 7.55 18 5V4C18 2.9 17.1 2 16 2ZM4 7.82C2.84 7.4 2 6.3 2 5V4H4V7.82ZM16 5C16 6.3 15.16 7.4 14 7.82V4H16V5Z"
        fill="#003656"
      />
    </svg>
  ),
  GENERIC: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V28C31.5 29.933 29.933 31.5 28 31.5H4C2.067 31.5 0.5 29.933 0.5 28V4C0.5 2.067 2.067 0.5 4 0.5Z"
        fill="white"
      />
      <g clipPath="url(#clip0_6084_91525)">
        <path
          d="M25 8H7C6.45 8 6 8.45 6 9V23C6 23.55 6.45 24 7 24H25C25.55 24 26 23.55 26 23V9C26 8.45 25.55 8 25 8ZM12 22H8V10H12V22ZM18 22H14V10H18V22ZM24 22H20V10H24V22Z"
          fill="#123553"
        />
      </g>
      <path
        d="M4 0.5H28C29.933 0.5 31.5 2.067 31.5 4V28C31.5 29.933 29.933 31.5 28 31.5H4C2.067 31.5 0.5 29.933 0.5 28V4C0.5 2.067 2.067 0.5 4 0.5Z"
        stroke="#003656"
      />
      <defs>
        <clipPath id="clip0_6084_91525">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  ),
  S3_STORAGE: <AmazonS3Image height="20px" />,
  AZURE_BLOB: <AzureImage height="20px" />,
  GCP_STORAGE: <GcpImage height="20px" />,
  MONGO: <MongoDbImage height="20px" />,
  MYSQL: <MySqlImage height="20px" />,
  // Deferring the below types for time-being.
  // REDIS_STORAGE: <RedisImage height="20px" />,
  REDSHIFT: <RedshiftImage height="20px" />,
  SNOWFLAKE: <SnowflakeImage height="20px" />
};

export default DATA_TYPES_ICONS;
