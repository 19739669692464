import { useRef, useEffect, useCallback } from "react";
import axios from "axios";

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing Axios calls that result in a state update on success / fail.
 * This function sets up the appropriate useEffect to handle the canceling.
 *
 * @returns {newCancelApiToken: function, isCancelApi: function}
 * newCancelApiToken - used to generate the cancel token sent in the Axios request.
 * isCancelApi - used to check if error returned in response is a cancel token error.
 */
export const useCancelApis = () => {
  const axiosSource = useRef<$TSFixMe>(null);

  const newCancelApiToken = useCallback(() => {
    axiosSource.current = axios.CancelToken.source();
    return axiosSource.current.token;
  }, []);

  const cancelApi = () => {
    if (axiosSource.current) axiosSource.current.cancel();
  };

  useEffect(
    () => () => {
      if (axiosSource.current) axiosSource.current.cancel();
    },
    []
  );

  return { newCancelApiToken, cancelApi, isCancelApi: axios.isCancel };
};
