/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DeleteDataConversationsRequest
 */
export interface DeleteDataConversationsRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteDataConversationsRequest
     */
    'context'?: DeleteDataConversationsRequestContextEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteDataConversationsRequest
     */
    'historyIds'?: Array<string>;
}

export const DeleteDataConversationsRequestContextEnum = {
    Recipe: 'RECIPE',
    Dataapp: 'DATAAPP'
} as const;

export type DeleteDataConversationsRequestContextEnum = typeof DeleteDataConversationsRequestContextEnum[keyof typeof DeleteDataConversationsRequestContextEnum];


