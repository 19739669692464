import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const UnPinnedIcon: React.FC<IProps> = (props) => {
  const {
    width = 13,
    height = 13,
    viewBox = "0 0 12 12",
    fill = "none",
    color = "#0F0F0F"
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56101 0.935169C7.87876 0.252895 6.73906 0.382929 6.22801 1.20136L4.80542 3.47963C4.80542 3.47963 4.80467 3.48089 4.80201 3.4828C4.79875 3.48513 4.79179 3.48916 4.78011 3.49245C4.7546 3.49963 4.72128 3.49908 4.69151 3.48805C4.20597 3.30805 3.37252 3.11031 2.50705 3.43631C2.04077 3.61194 1.80809 4.01616 1.78273 4.42627C1.75854 4.81756 1.91657 5.2198 2.20723 5.51045L3.97498 7.2782L0.634972 10.6182C0.43971 10.8135 0.43971 11.1301 0.634972 11.3254C0.830232 11.5206 1.14682 11.5206 1.34208 11.3254L4.68209 7.9853L6.44986 9.7531C6.74051 10.0438 7.14276 10.2018 7.53406 10.1776C7.94416 10.1523 8.34841 9.91955 8.52401 9.4533C8.85001 8.5878 8.65226 7.75435 8.47231 7.2688C8.46126 7.23905 8.46071 7.20575 8.46786 7.1802C8.47116 7.16855 8.47521 7.1616 8.47756 7.1583C8.47946 7.15565 8.48066 7.15495 8.48066 7.15495L10.759 5.7323C11.5774 5.22125 11.7075 4.08159 11.0252 3.39932L8.56101 0.935169ZM7.07626 1.73101C7.24661 1.4582 7.62651 1.41485 7.85391 1.64228L10.3181 4.10642C10.5455 4.33385 10.5022 4.71374 10.2293 4.88408L7.95106 6.3067C7.47871 6.60165 7.37356 7.1819 7.53466 7.6164C7.67756 8.0019 7.79156 8.5609 7.58821 9.1008C7.57431 9.13765 7.55886 9.15095 7.54936 9.1575C7.53726 9.16585 7.51341 9.17695 7.47236 9.1795C7.38361 9.185 7.25686 9.14585 7.15696 9.046L2.91434 4.80335C2.81447 4.70348 2.77534 4.57672 2.78083 4.48797C2.78336 4.4469 2.79449 4.42308 2.80285 4.41096C2.80938 4.40148 2.82269 4.386 2.85954 4.37212C3.39945 4.16876 3.95841 4.28278 4.34392 4.42569C4.77845 4.58678 5.35866 4.48164 5.65366 4.00928L7.07626 1.73101Z"
        fill={color}
      />
    </svg>
  );
};

export default UnPinnedIcon;
