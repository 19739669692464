import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Box,
  IconButton,
  Tooltip,
  Divider,
  Button,
  CircularProgress
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import WarningIcon from "@material-ui/icons/Warning";

import { capitalize } from "src/utils/capitalize";
import { dateDiffFromNow, dateFormat } from "src/utils/dateFormat";
import { LicenseDetailsType } from "src/types/License.type";

const useStyles = makeStyles({
  licenseDetails: {
    backgroundColor: "#ffffff",
    padding: "16px",
    gap: "16px",
    borderRadius: "4px"
  },
  tenantDetailKey: {
    opacity: "50%",
    color: "#000000",
    fontSize: "14px"
  },
  tenantLicenseKey: {
    flexWrap: "nowrap",
    alignItems: "center"
  }
});

export const LicenseDetails = ({
  licenseDetails,
  onSync,
  isSyncInProgress,
  onUpdate
}: {
  licenseDetails: LicenseDetailsType;
  onSync: () => void;
  isSyncInProgress: boolean;
  onUpdate: () => void;
}) => {
  const classes = useStyles();
  var durationTillExpiry = dateDiffFromNow(licenseDetails?.expiryTime, true, "days");
  return (
    <Grid container direction="column" className={classes.licenseDetails}>
      <Grid item>
        <Typography variant="h6" color="textPrimary">
          License
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Typography className={classes.tenantDetailKey}>Status</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textPrimary">
            {capitalize(licenseDetails.status)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container alignItems="flex-end">
        <Grid item xs={4}>
          <Typography className={classes.tenantDetailKey}>Key</Typography>
        </Grid>
        <Grid item container direction="row" className={classes.tenantLicenseKey} xs={8}>
          <Typography variant="body2" color="textPrimary">
            {licenseDetails.maskedLicense}
          </Typography>
          <Box pl="8px">
            <Tooltip
              title={isSyncInProgress ? "License Sync is in progress" : "Sync"}
              arrow
              placement="top">
              <IconButton color="primary" size="small" onClick={onSync} disabled={isSyncInProgress}>
                {isSyncInProgress ? (
                  <CircularProgress size="16px" />
                ) : (
                  <SyncIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Grid item container alignItems="flex-end">
        <Grid item xs={4}>
          <Typography className={classes.tenantDetailKey}>Expiration Date</Typography>
        </Grid>
        <Grid item container direction="row" alignItems="flex-end" xs={8}>
          <Typography variant="body2" color="textPrimary">
            {dateFormat(licenseDetails.expiryTime)}
          </Typography>
          {durationTillExpiry && durationTillExpiry > 0 && durationTillExpiry <= 30 && (
            <Box pl="16px">
              <Tooltip
                title={`Your license will expire in ${durationTillExpiry} day(s). Please contact support to extend usage.`}>
                <WarningIcon fontSize="small" style={{ color: "#db2d02" }} />
              </Tooltip>
            </Box>
          )}
        </Grid>
      </Grid>
      <Box pt="8px">
        <Divider />
      </Box>
      <Grid item>
        <Button variant="outlined" color="primary" onClick={onUpdate}>
          Update License Key
        </Button>
      </Grid>
    </Grid>
  );
};
