import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const ExpandAllIcon: React.FC<IProps> = (props) => {
  const {
    width = 16,
    height = 16,
    viewBox = "0 0 16 16",
    fill = "none",
    color = "currentColor"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2357 12C10.8195 12 11.2945 11.525 11.2945 10.9412L11.2945 1.05914C11.2945 0.474694 10.8195 0.000350821 10.2357 0.00035077L2.82419 0.000350122L2.82418 12L10.2357 12ZM7.24187 8.36875L6.74274 7.86962L8.25788 6.35448L4.16875 6.35448L4.16875 5.6486L8.25789 5.6486L6.74275 4.13346L7.24188 3.63432L9.6091 6.00154L7.24187 8.36875ZM1.41211 0L2.11797 6.17085e-08L2.11797 11.9997L1.41211 11.9996L1.41211 0Z"
        fill={color}
      />
    </svg>
  );
};

export default ExpandAllIcon;
