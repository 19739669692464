/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

export enum MachineLearningTask {
  BINARY_CLASSIFICATION = "Binary Classification",
  REGRESSION = "Regression",
  MULTICLASS_CLASSIFICATION = "MultiClass Classification",
  TIMESERIES_FORECASTING = "Timeseries Forecasting",
  ANOMALY_DETECTION = "Anomaly Detection",
  CLUSTERING = "Clustering",
  BINARY_CLASSIFICATION_GPT = "Binary Experimental"
}

type AutoMLProblemType = {
  name: keyof typeof MachineLearningTask;
  value: string;
  templateName: string;
};

export const useGetAutoMLProblemTypes = () => {
  return useQuery<Array<AutoMLProblemType>>({
    queryKey: ["auto-ml-problem-types"],
    queryFn: () => {
      return getAPIWithRethrow(`/v2/dfs-templates/auto-ml-problem-types`);
    }
  });
};
