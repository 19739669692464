import * as React from "react";

import TransformItemsList from "./components/transform-items-list";

import styles from "./entity-modal.module.scss";

export default function TransformModal({ history, pipeline }: $TSFixMe) {
  return (
    <div className={styles.transformModal}>
      <TransformItemsList history={history} pipeline={pipeline} />;
    </div>
  );
}
