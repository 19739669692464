import React, { useCallback } from "react";
import _, { toLower } from "lodash";
import { Grid, makeStyles, Tooltip, Box, Typography, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

// Icons
import { Edit } from "@material-ui/icons";
import { ConfigIcon } from "src/icons/ConfigIcon";
import { LogsNewIconV2 } from "src/icons/LogsNewIconV2";
import { Delete } from "src/icons/Delete";

import {
  environmentsTypes as environmentsTypesConstant,
  EnvironmentStatuses
} from "src/constants/environments.constants";
import envIcon from "src/assets/images/environment-medium.svg";
import { MoreOptions } from "src/components/MoreOptions/MoreOptions";
import { Environment } from "../../Environments";
import { StatusBar } from "../StatusBar/StatusBar";
import styles from "./EnvironmentCards.module.scss";
import Text from "src/components/Widget/Text";
import infoCardsStyles from "src/components/InfoCards/InfoCards.module.scss";
import { OverflowTooltip } from "src/components";
import { dateFormat } from "src/utils/dateFormat";
import { environmentDetails } from "../../utils/environments.helpers";
import { EnvironmentsConfig, EnvironmentsHelperText } from "../../utils/Environments.constants";

const useStyles = makeStyles({
  link: {
    display: "inline-block",
    color: "#003656",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
});

const NO_PROJECTS = "No projects";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px rgba(0,0,0,0.14), 0px 1px 5px rgba(0,0,0,0.12)"
  }
}))(Tooltip);

type Props = {
  environmentsTypes: $TSFixMe[];
  list: Environment[];
  onDeleteEnv: Function;
  onEditEnv: Function;
  onRelaunchEnv: Function;
  stoppingEnvironments: $TSFixMe;
  onStopEnv: Function;
  logs: $TSFixMe;
  usage: $TSFixMe;
};

export const EnvironmentCards = ({
  environmentsTypes,
  list = [],
  onDeleteEnv,
  onEditEnv,
  onRelaunchEnv,
  stoppingEnvironments,
  onStopEnv,
  logs,
  usage
}: Props) => {
  const { link } = useStyles();

  const isLaunching = useCallback(
    (env: $TSFixMe) => env?.launchStatus === EnvironmentStatuses.Launching,
    []
  );

  const cardOptions = (env: $TSFixMe) => [
    {
      label: "Edit",
      icon: (
        <Typography color="textSecondary">
          <Edit />
        </Typography>
      ),
      click: () => onEditEnv(env?.id)
    },
    {
      label: "Configs",
      icon: <ConfigIcon />,
      click: () => usage(env)
    },
    {
      label: "Logs",
      icon: <LogsNewIconV2 fill="#003656" width="24" height="24" />,
      click: () => logs(env)
    },
    {
      label: "Delete",
      icon: (
        <Typography color="textSecondary">
          <Delete />
        </Typography>
      ),
      click: () => onDeleteEnv(env),
      disabled: isLaunching(env),
      tooltip: isLaunching(env) ? EnvironmentsHelperText.DeleteInfo : ""
    }
  ];

  const availableWindowSize = window.innerWidth * (1 - EnvironmentsConfig.ExemptingWidth);
  const statusOrder = ["launching", "success", "shutdown", "failed"];
  return (
    <Grid container justifyContent="flex-start" style={{ gap: 24, width: availableWindowSize }}>
      {list
        ?.sort((a, b) => b.updated - a.updated)
        .sort((a, b) => {
          return (
            statusOrder.indexOf(toLower(a.launchStatus)) -
            statusOrder.indexOf(toLower(b.launchStatus))
          );
        })
        .map((env: Environment) => {
          const projects =
            env.projects.length > 0
              ? _.map(env.projectDtos, (project, i) => (
                  <span key={project.id}>
                    {i > 0 && <span>{", "}</span>}
                    <Link className={link} to={`/projects/${project.id}`}>
                      <Text value={project.name} />
                    </Link>
                  </span>
                ))
              : NO_PROJECTS;

          return (
            <div className={styles.card} key={env.id}>
              <div className={styles.cardContent}>
                <div className={styles.cardHeader}>
                  <Box width="100%" display="flex" className={styles.cardTitleWrap}>
                    <img alt="environment-icon" src={envIcon} />
                    <Link className={styles.cardTitleLink} to={`/environments/${env.id}`}>
                      <Typography variant="h6" color="textPrimary" className={styles.cardTitleText}>
                        <OverflowTooltip
                          tooltipProps={{
                            placement: "bottom-start",
                            PopperProps: { style: { marginTop: -12 } }
                          }}
                          value={
                            <Text
                              value={env.name}
                              dataTestId="environmentTitle"
                              style={{ whiteSpace: "pre" }}
                            />
                          }
                        />
                      </Typography>
                    </Link>
                  </Box>

                  <div className={styles.moreOption}>
                    <MoreOptions options={cardOptions(env)} />
                  </div>
                </div>
                <div className={styles.cardDescription}>
                  <OverflowTooltip style={{ whiteSpace: "nowrap" }} value={env.description} />
                </div>
                <div className={styles.cardItem}>
                  <div>Environment Type</div>
                  <div>
                    {
                      environmentsTypesConstant[
                        environmentDetails({ environmentsTypes, env })?.name || ""
                      ]
                    }
                  </div>
                </div>
                <div className={styles.cardItem}>
                  <div>Projects</div>
                  {projects === NO_PROJECTS ? (
                    NO_PROJECTS
                  ) : (
                    <LightTooltip
                      placement="bottom-start"
                      interactive
                      PopperProps={{ style: { marginTop: -12 } }}
                      title={projects}>
                      <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                        {projects}
                      </Box>
                    </LightTooltip>
                  )}
                </div>
                <div className={styles.cardItem}>
                  <div>Updated on</div>
                  <div>{dateFormat(env.updated)}</div>
                </div>
                {env?.updater && (
                  <div className={infoCardsStyles.text}>
                    <div className={infoCardsStyles.userMenuItemIndicator}>
                      {env?.updater?.charAt(0)?.toUpperCase()}
                    </div>
                    <OverflowTooltip
                      style={{ whiteSpace: "nowrap" }}
                      value={
                        <Typography color="textSecondary" variant="caption">
                          {env?.updater}
                        </Typography>
                      }
                    />
                  </div>
                )}
              </div>
              <StatusBar
                env={env}
                onRelaunchEnv={onRelaunchEnv}
                stoppingEnvironments={stoppingEnvironments}
                onStopEnv={onStopEnv}
              />
            </div>
          );
        })}
    </Grid>
  );
};
