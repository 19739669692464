/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateAppTemplateBuildStatus
 */
export interface UpdateAppTemplateBuildStatus {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppTemplateBuildStatus
     */
    'dataappId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppTemplateBuildStatus
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppTemplateBuildStatus
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppTemplateBuildStatus
     */
    'buildStatus'?: UpdateAppTemplateBuildStatusBuildStatusEnum;
}

export const UpdateAppTemplateBuildStatusBuildStatusEnum = {
    Unbuilt: 'UNBUILT',
    Requested: 'REQUESTED',
    Building: 'BUILDING',
    Failure: 'FAILURE',
    Built: 'BUILT'
} as const;

export type UpdateAppTemplateBuildStatusBuildStatusEnum = typeof UpdateAppTemplateBuildStatusBuildStatusEnum[keyof typeof UpdateAppTemplateBuildStatusBuildStatusEnum];


