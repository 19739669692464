import ReactGA from "react-ga4";
import _ from "lodash";
import { GaOptions, InitOptions } from "react-ga4/types/ga4";
import { RouteProps } from "react-router";

interface IParams {
  category: string;
  action: string;
  label: string;
}

interface IOptions {
  nonce?: string;
  testMode?: boolean;
  gtagUrl?: string;
  gaOptions?: GaOptions | any;
  gtagOptions?: any;
}

interface ISetParam {
  userId: string;
  tenantId: string;
}

class Analytics {
  tenantId!: string;
  userId!: string;

  constructor() {
    const tenantId = localStorage.getItem("tenantId");
    if (tenantId) {
      this.tenantId = tenantId;
    }
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const userId = JSON.parse(atob(_.split(token, ".")[1]))?.id;
        this.userId = userId;
      } catch {
        return;
      }
    }
  }

  initialize = (params: InitOptions[] | string, options?: IOptions) => {
    ReactGA.initialize(params, options);
  };

  set = (params: ISetParam) => {
    const { userId, tenantId } = params;
    this.tenantId = tenantId;
    this.userId = userId;

    ReactGA.set(params);
  };

  trackNavigation = (url: RouteProps["path"], title?: string) => {
    ReactGA.send({
      hitType: "pageview",
      page: url,
      title: title ?? document.title,
      clientId: this.tenantId,
      userId: this.userId
    });
  };

  track = (params: IParams) => {
    ReactGA.event(params);
  };
}

const analytics = new Analytics();

export default analytics;
