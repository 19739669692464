import React from "react";

import { isNumber } from "lodash";

import { Divider as MuiDivider, useTheme, makeStyles } from "@material-ui/core";

type Props = {
  height?: number | string;
  color?: string;
};

type StyleProps = {
  height?: number | string;
  color?: string;
};

const useStyles = makeStyles(() => ({
  divider: {
    height: (props: StyleProps) =>
      `${isNumber(props?.height) ? `${props?.height}px` : props?.height}`,
    backgroundColor: (props: StyleProps) => props?.color
  }
}));

const Divider = (props: Props) => {
  const theme = useTheme();

  const { height = 1, color = theme.palette.common.black } = props || {};

  const classes = useStyles({ height, color });

  return <MuiDivider className={classes.divider} />;
};

export default Divider;
