import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

interface IProps {
  id: string;
  open: boolean;
  loading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const DeletePredictionServiceModal: React.FC<IProps> = (props) => {
  const { open, loading, onCancel, onSubmit } = props;

  return open ? (
    <Modal
      open={open}
      variant={ModalVariants.Delete}
      title="Delete Prediction Service"
      content={[
        "Deleting the prediction service removes it permanently.",
        "Note: Deleting this might impact associated dataapps(if any).",
        "Are you sure you want to delete it?"
      ]}
      isSubmitting={loading}
      onClose={onCancel}
      onSubmit={onSubmit}
    />
  ) : (
    <></>
  );
};

export default DeletePredictionServiceModal;
