import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "40%",
    flexShrink: 0,
    "& .MuiAppBar-root": {
      width: "40%",
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 1px 2px 0px #00000040"
    }
  },
  drawerPaper: {
    width: "40%",
    backgroundColor: "#f5f5f5"
  },
  inputField: {
    backgroundColor: theme.palette.common.white
  },
  inputLabel: {
    opacity: 0.625
  },
  inputLabelShrink: {
    shrink: true,
    opacity: 1
  }
}));

export default useStyles;
