import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import StopIcon from "@material-ui/icons/Stop";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    width: "24px",
    height: "24px"
  },
  fabProgress: {
    color: "orange",
    position: "absolute",
    top: 1,
    left: -2
  }
});

interface IProps {
  onStop: () => void;
}

const QuerySpinner: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <Tooltip title="Stop query">
      <div
        className={classes.wrapper}
        tabIndex={0}
        aria-hidden="true"
        role="button"
        data-testid="ai-generate-stop-btn"
        onClick={props.onStop}>
        <Fab
          style={{
            background: "transparent",
            boxShadow: "none",
            width: "22px",
            height: "22px"
          }}>
          <StopIcon color="error" style={{ marginBottom: "8px" }} fontSize="small" />
        </Fab>
        <CircularProgress size={26} className={classes.fabProgress} />
      </div>
    </Tooltip>
  );
};

export default React.memo(QuerySpinner);
