import React, { useEffect, useState, useMemo } from "react";
import Alert from "@material-ui/lab/Alert";
import { InfoOutlined } from "@material-ui/icons";
import {
  Grid,
  Paper,
  Drawer,
  Box,
  TextField,
  Typography,
  CircularProgress,
  makeStyles,
  Button,
  Theme,
  Checkbox,
  Tooltip
} from "@material-ui/core";
import { includes, isEmpty } from "lodash";

import CpuUsage from "./CpuUsage";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import useGetEnvironmentResourceUsage from "hooks/api/environments/useGetEnvironmentResourceUsage";
import useUpdateEnvironment from "hooks/api/environments/useUpdateEnvironment";
import { Environment } from "../../Environments";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { HorseShoeProgressBar } from "src/components";
import { MemoryIcon } from "src/icons/MemoryIcon";
import { StatusBar } from "../StatusBar/StatusBar";
import { toastWrapper } from "src/utils/toastWrapper";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { RenderText } from "src/utils";

const drawerWidth = "40%";
const MIN_INACTIVITY = 1;
const MAX_INACTIVITY = 24;

interface IProps {
  isOpen: boolean;
  environment: Environment;
  stoppingEnvironments: string[];
  onClose: () => void;
  onUpdate: () => void;
}

interface IStyleProps {
  disabled: boolean;
  dialSize: number;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiAppBar-root": {
      width: drawerWidth
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  container: {
    border: "none",
    backgroundColor: "#f7f7f7",
    marginBottom: 20,
    padding: 20,
    cursor: ({ disabled }) => (disabled ? "not-allowed" : "default"),
    opacity: ({ disabled }) => (disabled ? 0.5 : 1)
  },
  cpuUsageContainer: {
    border: "none",
    backgroundColor: "#f7f7f7",
    marginBottom: 20,
    padding: "20px 25%",
    opacity: ({ disabled }) => (disabled ? 0.5 : 1)
  },
  memoryDetailsContainer: {
    position: "absolute",
    width: ({ dialSize }) => `${dialSize}px`,
    height: ({ dialSize }) => `${dialSize - 10}px`,
    "& .title": {
      marginTop: "15%",
      color: "#008FE4",
      columnGap: 5
    },
    "& .percentage": { marginTop: "auto" }
  },
  warning: {
    fontSize: "12px"
  },
  timeoutContainer: {
    flexWrap: "nowrap",
    alignItems: "flex-end",
    gap: "8px",
    padding: "10px 0px"
  },
  alert: {
    marginBottom: 10
  },
  btnList: {
    height: "70px",
    backgroundColor: "#F5F5F5",
    padding: "20px",
    alignItems: "center",
    gap: "20px"
  },
  boxContainer: {
    height: "80%",
    marginTop: 44,
    overflowY: "auto"
  },
  footerContainer: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    justifyContent: "flex-end",
    columnGap: theme.spacing(1),
    width: "100%",
    background: "#f5f5f5",
    padding: "10px 20px",
    zIndex: 1,
    backgroundColor: "#d9d9d9"
  },
  evergreen: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    margin: "6px 0px 12px 0px"
  }
}));

const EVERGREEN_TYPE = "EVERGREEN" as const;
const INACTIVITY_TIME_PERIOD = "INACTIVITY_TIME_PERIOD" as const;

const EnvironmentConfigDrawer: React.FC<IProps> = (props) => {
  const { isOpen, environment, stoppingEnvironments, onUpdate, onClose } = props;

  const isRunning = useMemo(
    () =>
      includes(
        [EnvironmentStatuses.Active, EnvironmentStatuses.Launching],
        environment.launchStatus
      ),
    [environment]
  );

  const { data, isLoading } = useGetEnvironmentResourceUsage(environment?.id, {
    enabled: isOpen && isRunning,
    refetchInterval: 5000
  });

  const classes = useStyles({ dialSize: 180, disabled: !isRunning });
  const updateEnv = useUpdateEnvironment();

  // States - STARTS >>
  const [inactivityTime, setInactivityTime] = useState<string | number>(
    environment?.shutdownStrategy?.inactivityInHours ?? 0
  );
  const [disabled, setDisabled] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [checked, setChecked] = useState(true);
  // << ENDS - States

  useEffect(() => {
    const checked = environment?.shutdownStrategy?.type === EVERGREEN_TYPE;
    setChecked(checked);
    setInactivityTime(checked ? "" : environment?.shutdownStrategy?.inactivityInHours);
    setDisabled(true);
  }, [environment]);

  const isError = useMemo(() => {
    if (checked || isEmpty(inactivityTime)) {
      return false;
    }

    const val = Number(inactivityTime);
    return val < MIN_INACTIVITY || val > MAX_INACTIVITY;
  }, [inactivityTime, checked]);

  const formattedPercentage = useMemo(
    () => (data?.memoryPercentage ? Number(parseFloat("" + data?.memoryPercentage).toFixed(2)) : 0),
    [data?.memoryPercentage]
  );

  const formattedMemoryInGbs = useMemo(
    () => (environment?.memInMbs ? parseInt(environment.memInMbs as unknown as string) / 1024 : 0),
    [environment?.memInMbs]
  );

  const handleSubmit = () => {
    const payload = {
      ...environment,
      shutdownStrategy: {
        type: checked ? EVERGREEN_TYPE : INACTIVITY_TIME_PERIOD,
        inactivityInHours: checked ? (undefined as unknown as number) : Number(inactivityTime)
      }
    };
    updateEnv.mutate(payload, {
      onSuccess: () => {
        toastWrapper({
          type: "success",
          content: `The inactivity shutdown period for ${environment?.name} is updated successfully.`
        });
        onUpdate();
        setDisabled(true);
        setOpenConfirmModal(false);
      }
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setInactivityTime(2);
    setDisabled(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInactivityTime(e.target.value);
    setDisabled(false);
  };

  const footer = (
    <footer className={classes.footerContainer}>
      <Button variant="outlined" color="primary" size="small" onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setOpenConfirmModal(true)}
        disabled={disabled || isError || !(checked || inactivityTime)}>
        Save
      </Button>
    </footer>
  );

  return (
    <>
      <Modal
        open={openConfirmModal}
        variant={ModalVariants.Delete}
        title="Save Configuration"
        content={[
          "Modifications to the shutdown time for the running environment will take effect upon relaunching the environment."
        ]}
        isSubmitting={updateEnv.isLoading}
        submitLabel="Yes"
        onClose={() => setOpenConfirmModal(false)}
        onSubmit={handleSubmit}
      />
      <Drawer
        open={isOpen}
        anchor="right"
        variant="temporary"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={onClose}>
        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <RenderText color="textSecondary" isOverflowTooltip>
                Configuration & Consumption
              </RenderText>
            )
          }}
          subTopNavBarRightSection={{
            component: <StatusBar env={environment} stoppingEnvironments={stoppingEnvironments} />
          }}
        />
        <Box p={2} className={classes.boxContainer}>
          <Grid container direction="row" className={classes.timeoutContainer}>
            <TextField
              inputProps={{ type: "number", step: 1 }}
              value={inactivityTime}
              disabled={checked}
              label="Shutdown Environment if Inactive for"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleChange}
            />
            <Typography className={classes.subHeading}>hr</Typography>
          </Grid>
          {isError && (
            <Alert className={classes.alert} variant="outlined" severity="error">
              {`The inactivity shutdown period should be min ${MIN_INACTIVITY} and at most ${MAX_INACTIVITY}`}
            </Alert>
          )}
          <div className={classes.evergreen}>
            <Checkbox
              checked={checked}
              style={{ padding: 0 }}
              onChange={handleCheckboxChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span>Evergreen</span>
            <Tooltip title="Enabling this option will always keep this environment up and running">
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </div>
          <Paper variant="outlined" square className={classes.container}>
            <HorseShoeProgressBar
              isNewTheme
              percentage={formattedPercentage || 0}
              disabled={!formattedPercentage}>
              {isRunning && isLoading ? (
                <CircularProgress size={24} style={{ margin: "auto" }} />
              ) : (
                <Grid
                  container
                  direction="row"
                  alignContent="space-around"
                  className={classes.memoryDetailsContainer}>
                  <Grid container justifyContent="center" className="title">
                    <MemoryIcon color="#4646b5" />
                    <Typography variant="body2" color="primary">
                      Memory
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="center" direction="column">
                    <Typography variant="h6" color="textPrimary" align="center">
                      {(formattedMemoryInGbs * formattedPercentage * 0.01).toFixed(2)} GB
                    </Typography>
                    <Typography variant="caption" color="textPrimary" align="center">
                      of {formattedMemoryInGbs} GB used
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="center" className="percentage">
                    <Typography variant="body2" color="textPrimary">
                      {formattedPercentage.toFixed(2)}% used
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </HorseShoeProgressBar>
          </Paper>
          <Paper variant="outlined" square className={classes.cpuUsageContainer}>
            <CpuUsage percentage={data?.cpuPercentage || 0} disabled={!data?.cpuPercentage} />
          </Paper>
          {!data?.cpuPercentage && !isLoading && (
            <div>
              <InfoOutlined fontSize="small" />{" "}
              <span className={classes.warning}>
                Consumption is shown for launching and running Environments
              </span>
            </div>
          )}
        </Box>
        {footer}
      </Drawer>
    </>
  );
};

export default EnvironmentConfigDrawer;
