import AceEditor, { IAceEditorProps } from "react-ace";
import React from "react";

interface IProps extends IAceEditorProps {
  jsonString: string;
  onChange?: (jsonString: string) => void;
}

const JsonEditor: React.FC<IProps> = (props) => {
  const { jsonString, onChange, ...restProps } = props;

  const handleChange = (newValue: string) => {
    onChange?.(newValue);
  };

  return (
    <AceEditor
      mode="json"
      name="jsonEditor"
      value={jsonString}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        showLineNumbers: true,
        tabSize: 2,
        wrap: true
      }}
      onChange={handleChange}
      {...restProps}
    />
  );
};

export default JsonEditor;
