import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { NotificationResponse } from "src/types/Notifications.types";
import { getNotifications } from "src/api";

export const QUERY_KEY_NOTIFICATIONS = "query-key-notifications";

const useGetNotifications = (
  options?: UseQueryOptions<NotificationResponse[]>
): UseQueryResult<NotificationResponse[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_NOTIFICATIONS],
    queryFn: async () => {
      const data = await getNotifications({ groupMatching: true });
      return data;
    },
    select: (data: NotificationResponse[]) => (Array.isArray(data) ? data : []),
    cacheTime: Infinity,
    refetchOnMount: true,
    ...options
  });
};

export default useGetNotifications;
