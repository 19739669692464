import { useRef } from "react";
import { filter } from "lodash";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import api from "src/utils/AxiosClient";
import { DataAppDtoLaunchStatusEnum } from "openapi/Models/data-app-dto";
import { QUERY_KEY_DATAAPPS_ACROSS_TENANTS } from "./useGetDataAppAcrossTenants";
import { QUERY_KEY_DATA_APPS } from "./useGetDataApps";
import { handleResponse } from "src/utils/apiService";
import { invalidateDataAppQueries } from "./useLaunchDataAppMutation";
import { QUERY_KEY_PROJECT_DETAILS } from "../projects/useProjectDetails";

interface IVariables {
  dataAppId: string;
  name: string;
}

export const useDeleteDataAppMutation = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  const fetchingRef = useRef<boolean>(false);
  const { dataAppName } = useParams();

  return useMutation({
    mutationFn: async ({ dataAppId }) => {
      invalidateDataAppQueries(
        queryClient,
        dataAppId,
        DataAppDtoLaunchStatusEnum.Deleting,
        dataAppName
      );
      const data = await api.fetchResponse(
        async () => await api.DataAppControllerApi.delete8(dataAppId)
      );

      await new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
          if (!fetchingRef.current) {
            fetchingRef.current = true;
            try {
              await api.DataAppControllerApi.getDataAppsById(dataAppId);
              fetchingRef.current = false;
            } catch (e: any) {
              if (e?.response?.status === 404) {
                resolve("Successfully deleted");
              } else {
                reject("Error in deleting the dataapp");
              }
              clearInterval(interval);
            }
          }
        }, 3000);
      });

      return data;
    },
    onSuccess: (__, { dataAppId, name }) => {
      queryClient.setQueryData([QUERY_KEY_DATA_APPS], (prevData: any) => {
        return filter(prevData, (app) => app.id !== dataAppId);
      });
      queryClient.invalidateQueries([QUERY_KEY_DATAAPPS_ACROSS_TENANTS]);
      queryClient.invalidateQueries([QUERY_KEY_PROJECT_DETAILS]);
      handleResponse({ successMessage: `DataApp ${name} deleted successfully.` });
    }
  });
};
