import React from "react";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import AccessDeniedIcon from "src/icons/AccessDeniedIcon";
import Result from "./Result";

interface IProps {
  subtitle1?: string;
  extra?: React.JSX.Element;
}

const AccessDenied: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Result
      icon={<AccessDeniedIcon />}
      heading="Access Denied!"
      subtitle1={props.subtitle1 ?? "Sorry, you don't have permission to view this page."}
      subtitle2="If you believe you've received this message in error, please check with your admin"
      extra={
        props.extra ?? (
          <Button variant="contained" onClick={handleGoBack}>
            Go Back
          </Button>
        )
      }
    />
  );
};

export default AccessDenied;
