import React, { useRef, PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  hiddenInput: {
    display: "none"
  }
}));

interface Props {
  id?: string;
  name?: string;
  accept?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileInputField: React.FC<PropsWithChildren<Props>> = (props) => {
  const { id = "fileInput", name = "fileInput", accept, disabled, children, onChange } = props;
  const classes = useStyles();

  const fileRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);

    // Below code resets the input type file. It's needed to accept every files selection. Without the below code, the subsequent selections are omitted.
    // @ts-ignore
    fileRef.current.value = "";
  };

  return (
    <>
      <input
        id={id}
        name={name}
        accept={accept}
        type="file"
        multiple
        disabled={disabled}
        ref={fileRef}
        onChange={handleChange}
        className={classes.hiddenInput}
      />
      {children}
    </>
  );
};

export default FileInputField;
