import { useCallback } from "react";

// Packages
import shallow from "zustand/shallow";

// Utils
import { toastWrapper } from "src/utils/toastWrapper";

// APIs
import { putAPIWithRethrow } from "src/utils/apiService";

// Open API
import { DFSRunConfigDto, Node } from "openapi/Models";

// Stores
import { useCanvasStore } from "src/store/store";

// Contexts
import { useDagFlowContext } from "../context/useDagFlowContext";

const useConnect = () => {
  const { setReloadTriggerStore } = useDagFlowContext();

  const [nodesStore] = useCanvasStore((state) => [state.nodes], shallow);

  const onConnect = useCallback(
    async ({ source, target }: { source: string; target: string }) => {
      const sourceNode: Node | any = nodesStore.find((node: Node) => node.id === source);
      const targetNode: Node | any = nodesStore.find((node: Node) => node.id === target);

      const sourceNodeType = sourceNode?.type;
      const targetNodeType = targetNode?.type;

      if (sourceNodeType === "chart" || targetNodeType === "chart") {
        toastWrapper({
          type: "error",
          content: "Chart blocks cannot be connected with other types of blocks"
        });
        return;
      }

      if (sourceNodeType === "entity" && targetNodeType === "entity") {
        toastWrapper({
          type: "error",
          content: "Two datasets cannot be connected"
        });
        return;
      }

      if (sourceNodeType === "dfsgroup" && targetNodeType === "dfsgroup") {
        toastWrapper({
          type: "error",
          content: "Two recipes cannot be connected"
        });
        return;
      }

      const dfsGroup = [sourceNode, targetNode].find((node) => node.type === "dfsgroup");
      const dataset = [sourceNode, targetNode].find((node) => node.type === "entity");

      try {
        const [[dfsGroupFromBE], [datasetFromBE]] = await Promise.all([
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          getAPIWithRethrow(`/v2/dfs-run-config-groups?id=${dfsGroup.id}`),
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          getAPIWithRethrow(`/v2/entities?id=${dataset.id}`)
        ]);

        let parents = [...dfsGroupFromBE.parents];
        let children = [...dfsGroupFromBE.children];

        const objectToPush = {
          id: datasetFromBE.id,
          name: datasetFromBE.name,
          type: "ENTITY",
          displayName: datasetFromBE.displayName,
          viewData: dataset?.data?.viewData
        };

        if (dataset?.data?.isSourceNode) {
          parents.push(objectToPush);
        }

        if (dataset?.data?.isSourceNode) {
          children.push(objectToPush);
        }

        // @ts-ignore
        const __ = await putAPIWithRethrow(`/v2/dfs-run-config-groups/${dfsGroupFromBE.id}`, {
          id: dfsGroupFromBE.id,
          displayName: dfsGroupFromBE.displayName,
          runConfigs: dfsGroupFromBE.runConfigs.map((runConfig: DFSRunConfigDto) => runConfig.id),
          parents,
          children
        });

        // const edgeType = "smart";
        // const markerEnd = { type: "arrowclosed" };
        // let edgesToSet = null;

        // setEdges((edges) => {
        //   edgesToSet = addEdge(
        //     { id: Date.now(), source, target, type: edgeType, markerEnd },
        //     edges
        //   );
        //   return edgesToSet;
        // });

        // setCanvasEdges(edgesToSet);
        setReloadTriggerStore();
      } catch (e) {
        toastWrapper({
          type: "error",
          content: "Edge creation failed"
        });
      }
    },
    [nodesStore, setReloadTriggerStore]
  );

  return { onConnect };
};

export default useConnect;
