import React, { useMemo, useState } from "react";

// Packages
import { useLocation, useNavigate } from "react-router-dom";

// MUI
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

// Icons
import { SettingsIcon } from "src/icons/NewUX/SettingsIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { RenderText } from "src/utils";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import DeleteProject from "./DeleteProject";

// Constants
import { ProjectSettingsHelperText } from "../utils/ProjectSettings.constants";
import { PathRexExps } from "src/constants";

type Props = {
  projectId: string;
  projectName?: string;
  isSaving: boolean;
  onClose: () => void;
  onDelete?: () => void;
};

const Header = (props: Props) => {
  const { projectId, projectName, isSaving, onClose, onDelete } = props || {};

  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();

  const [showDeleteConfirmScreen, setShowDeleteConfirmScreen] = useState(false);

  const promptConfirmDeleteProject = () => {
    setShowDeleteConfirmScreen(() => true);
  };

  const resetDeleteProject = () => {
    setShowDeleteConfirmScreen(() => false);
  };

  const disabledDeleteActionMessage = useMemo(() => {
    if (!!isSaving) {
      return "Please wait. The save action is in progress.";
    }

    return "";
  }, [isSaving]);

  const handleOnDelete = () => {
    if (PathRexExps.canvas.test(location.pathname)) {
      navigate(-1);
      return;
    }

    onDelete?.();
  };

  return (
    <>
      {!!showDeleteConfirmScreen && (
        <DeleteProject
          projectId={projectId}
          projectName={projectName}
          resetDeleteProject={resetDeleteProject}
          onDelete={handleOnDelete}
        />
      )}

      <SubTopNavBarWrapper
        variant="drawer"
        onDrawerClose={() => !isSaving && onClose()}
        subTopNavBarLeftSection={{
          component: (
            <Grid container alignItems="center" style={{ columnGap: theme.spacing(1) }}>
              <SettingsIcon data-testid="projectSettingsIcon" />
              <RenderText
                color="textSecondary"
                isOverflowTooltip
                data-testid="projectSettingsTitle">
                {ProjectSettingsHelperText.Title}
              </RenderText>
            </Grid>
          )
        }}
        subTopNavBarRightSection={{
          moreOptions: [
            {
              label: ProjectSettingsHelperText.Delete,
              icon: <TrashIcon viewBox="0 0 20 20" data-testid="projectSettingsDeleteIcon" />,
              disabled: !!disabledDeleteActionMessage,
              tooltip: disabledDeleteActionMessage,
              action: () => !disabledDeleteActionMessage && promptConfirmDeleteProject()
            }
          ]
        }}
      />
    </>
  );
};

export default Header;
