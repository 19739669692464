import { useQuery } from "@tanstack/react-query";
import { isArray, sortBy } from "lodash";

import { getAPIWithRethrow } from "src/utils/apiService";

export const useGetGlobalVariables = ({
  projectId,
  scenarioId,
  jobId,
  jobRunId
}: {
  projectId: string | null;
  scenarioId?: string;
  jobId?: string;
  jobRunId?: string;
}) => {
  return useQuery({
    queryKey: ["project", projectId, "variables"],
    queryFn: async () => {
      return getAPIWithRethrow(
        `/v2/variables?projectId=${projectId}${
          !!scenarioId ? `&scenarioId=${scenarioId}` : ``
        }${!!jobId ? `&projectRunId=${jobId}` : ``}${
          !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
        }`
      );
    },
    select: (data) => (isArray(data) ? sortBy(data, "name") : []),
    enabled: !!projectId,
    cacheTime: 0,
    refetchOnMount: true
  });
};
