import React from "react";

interface IProps {
  stroke?: string;
}

export const DownloadRound: React.FC<IProps> = (props) => {
  const { stroke = "black" } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g id="Group 99245787">
        <path
          id="Vector"
          d="M12.7 6.41797C15.7 6.6763 16.925 8.21797 16.925 11.593V11.7013C16.925 15.4263 15.4333 16.918 11.7083 16.918H6.28332C2.55832 16.918 1.06665 15.4263 1.06665 11.7013V11.593C1.06665 8.24297 2.27498 6.7013 5.22498 6.4263M8.99998 0.667969V11.4013"
          stroke={stroke}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M11.7916 9.54297L8.99992 12.3346L6.20825 9.54297"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
