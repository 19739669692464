import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useSearchParams } from "react-router-dom";

import ComponentNotFound from "components/Errors/ComponentNotFound";
import ReactException from "components/Errors/ReactException";
import useAdvanceAnalysis from "hooks/api/entities/useAdvanceAnalysis";
import { Spinner } from "src/components";

export const ADVANCE_ANALYSIS_URL = "url";

interface IProps {
  title?: string;
}
const AdvanceAnalysis: React.FC<IProps> = (props) => {
  const { title } = props;
  const [searchParams] = useSearchParams();
  const url = searchParams.get(ADVANCE_ANALYSIS_URL);

  const { data, isLoading, isError, refetch } = useAdvanceAnalysis(url);

  useEffect(() => {
    if (title) {
      document.title = title;
    }

    return () => {
      document.title = "RapidCanvas.ai";
    };
  }, [title]);

  if (!url) {
    return <ComponentNotFound />;
  }

  if (isError) {
    return <ReactException onClick={refetch} />;
  }

  const sanitizeIframeContent = (data: string) => {
    const preventTopNavigationScript = `
      <script>
        window.top = window.self; // Prevent navigation to the top window
        window.parent = window.self; // Prevent iframe content from targeting parent window
        document.addEventListener('click', (e) => {
          const target = e.target;
          if (target.tagName === 'A' && target.getAttribute('target') === '_top') {
            e.preventDefault(); // Prevent top-level navigation links
          }
        });
      </script>
    `;

    return data + preventTopNavigationScript;
  };

  return (
    <Grid style={{ height: "calc(100vh - 140px)" }} container justifyContent="center">
      {isLoading ? (
        <Spinner />
      ) : (
        <iframe
          title={title}
          srcDoc={sanitizeIframeContent(data)}
          sandbox="allow-same-origin allow-scripts"
          style={{ width: "100%", border: "none" }}
        />
      )}
    </Grid>
  );
};

export default AdvanceAnalysis;
