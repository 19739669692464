import React from "react";
import { Grid, Card as MuiCard, CardProps as MuiCardProps, makeStyles } from "@material-ui/core";
import { CardProvider } from "./CardContext";
import { MoreOptions, IOption } from "../MoreOptions/MoreOptions";

export interface CardProps extends MuiCardProps {
  children: React.ReactNode;
  moreOptions?: IOption[];
}

const useStyles = makeStyles({
  card: {
    background: ({ isHovered }: any) => (isHovered ? "#F4F4FF" : "#fff"),
    position: "relative",
    width: "100%",
    padding: "8px 16px",
    boxShadow: "none"
  },
  moreOptions: {
    position: "absolute",
    background: "#fff",
    top: "16px",
    right: "32px",
    width: "24px",
    height: "30px",
    borderRadius: "4px",
    zIndex: 1
  }
});

export const TileCard = ({ children, moreOptions, ...restProps }: CardProps) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const classes = useStyles({ isHovered });
  return (
    <CardProvider isHovered={isHovered} {...restProps}>
      <MuiCard
        className={classes.card}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {moreOptions && isHovered && (
          <Grid className={classes.moreOptions}>
            <MoreOptions
              options={moreOptions}
              btnProps={{
                style: {
                  color: "#4646B5"
                }
              }}
            />
          </Grid>
        )}
        {children}
      </MuiCard>
    </CardProvider>
  );
};
