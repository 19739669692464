import React from "react";
import { DialogTitle, Grid, GridSize, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    // @ts-expect-error TS(2339) FIXME: Property 'large' does not exist on type '{}'.
    minHeight: ({ large }) => (large ? 92 : 60),
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    top: -8,
    maxWidth: "calc(100% - 70px)"
  },
  title: {
    padding: 0,
    // @ts-expect-error TS(2339) FIXME: Property 'isSubtitle' does not exist on type '{}'.
    alignItems: ({ isSubtitle }) => isSubtitle && "center",
    display: "flex",
    "& > .MuiTypography-h6": {
      fontWeight: 400,
      // @ts-expect-error TS(2339) FIXME: Property 'isSubtitle' does not exist on type '{}'.
      fontSize: ({ isSubtitle }) => (isSubtitle ? "1rem" : "1.4rem"),
      color: theme.palette.primary.main,
      "& > svg": {
        marginRight: 14
      },
      "& > span": {
        verticalAlign: "middle"
      }
    }
  }
}));

type Props = {
  title: string | React.ReactNode;
  isSubtitle?: boolean;
  children?: React.ReactNode;
  noMargin?: boolean;
  xs?: GridSize;
  titleIcon?: React.JSX.Element;
  large?: boolean;
};

const ModalTitle = ({
  title,
  isSubtitle,
  children,
  titleIcon,
  noMargin,
  xs = 12,
  large
}: Props) => {
  const classes = useStyles({ isSubtitle, noMargin, large });
  return (
    <Grid container item xs={12} className={classes.container}>
      <Grid item xs={xs}>
        <DialogTitle id="form-dialog-title" className={classes.title}>
          {titleIcon}
          <span>{title}</span>
        </DialogTitle>
      </Grid>
      {children}
    </Grid>
  );
};

export default ModalTitle;
