import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CircularProgress, Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import EdaChartsWrapper from "./components/EdaChartsWrapper";
import EdaOverview from "./components/EdaOverview";
import errorSrc from "src/assets/images/astranaut-floating-error.png";
import loadingSrc from "src/assets/images/astronaut-waiting.png";
import { QUERY_KEY_EDA_CHARTS } from "hooks/api/entities/useGetEdaCharts";
import { useGetEntityEda } from "hooks/api/entities/useGetEntityEda";
import { useProjectsStore } from "src/store/store";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

const useStyles = makeStyles({
  edaImageWrap: {
    flexWrap: "nowrap",
    alignItems: "center",
    paddingTop: "10%",
    gap: "16px"
  },
  edaLoadingScreen: {
    height: "200px",
    width: "200px"
  },
  edaError: {
    height: "160px",
    width: "160px"
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const Eda: React.FC = () => {
  const queryClient = useQueryClient();
  const [triggerOnError, setTriggerOnError] = useState(false);
  const { entityId, scenarioId, jobRunId, projectId } = useParams();
  const classes = useStyles();

  const [modifiedDatasetId, setModifiedDatasetId] = useProjectsStore((state) => [
    state.modifiedDatasetId,
    state.setModifiedDatasetId
  ]);

  const response = useGetEntityEda({
    entityId,
    scenarioId,
    jobRunId,
    async: true,
    triggerOnError
  });

  const { isLoading, data, isError, isFetching, refetch } = response;

  useEffect(() => {
    if (modifiedDatasetId === entityId && data) {
      refetch();
      queryClient.invalidateQueries([QUERY_KEY_EDA_CHARTS]);
      setModifiedDatasetId("");
    }
  }, [modifiedDatasetId]);

  const handleClick = () => {
    if (triggerOnError) {
      refetch();
    } else {
      setTriggerOnError(true);
    }
  };

  if (isLoading || isFetching) {
    return (
      <Grid container direction="column" className={classes.edaImageWrap}>
        <Grid component="img" src={loadingSrc} className={classes.edaLoadingScreen} />
        <Typography>Data Analysis in Progress. Please Wait...</Typography>
      </Grid>
    );
  }

  if (isError || !data?.url) {
    return (
      <Grid container direction="column" className={classes.edaImageWrap}>
        <Grid component="img" src={errorSrc} className={classes.edaLoadingScreen} />
        <Typography>Data Analysis Unsuccessful</Typography>
        <Button color="primary" onClick={handleClick}>
          {isFetching ? <CircularProgress size="24px" /> : "Regenerate"}
        </Button>
      </Grid>
    );
  }

  if (!data?.edaJson) {
    return (
      <div>
        <iframe
          src={data.url}
          title="Data Analysis Tab"
          style={{
            height: "calc(100vh - 172px)",
            width: "100%",
            background: "white",
            border: "none",
            boxShadow: "none"
          }}
        />
      </div>
    );
  }

  return (
    <>
      {_.has(data.edaJson, "table") && (
        <NewThemeWrapper>
          <EdaOverview edaJson={data.edaJson} url={data?.url} isTruncated={data?.isTruncated} />
        </NewThemeWrapper>
      )}
      {!jobRunId && (
        <EdaChartsWrapper
          itemId={entityId}
          scenarioId={scenarioId}
          jobRunId={jobRunId}
          projectId={projectId}
        />
      )}
    </>
  );
};

export default Eda;
