export enum TableConfig {
  MaxColumnsCount = 300
}

export enum TableColumnSortingDirections {
  Asc = "asc",
  Desc = "desc"
}

export enum TableSettingsColumnsDirection {
  Default = "default",
  Asc = "asc",
  Desc = "desc"
}
