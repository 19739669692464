import React, { useEffect, useState } from "react";
import MonacoEditor from "react-monaco-editor";
import { useGetRecipe } from "src/hooks/api";
import * as monaco from "monaco-editor";

interface IProps {
  recipeId: string;
  scenarioId?: string;
}

const ReadOnlyCodeRecipe: React.FC<IProps> = ({ recipeId, scenarioId }) => {
  const { data } = useGetRecipe({
    recipeId,
    scenarioId,
    options: {
      cacheTime: Infinity
    }
  });

  const [code, setCode] = useState<string>();

  useEffect(() => {
    if (data) {
      setCode(data.templates?.map((template: any) => template.code)?.join("\r\n\n"));
    }
  }, [data]);

  useEffect(() => {
    monaco.editor.defineTheme("custom-theme", {
      base: "vs",
      inherit: true,
      rules: [],
      colors: {
        "editor.background": "#FFFFEE"
      }
    });
    monaco.editor.setTheme("custom-theme");
  }, []);

  return (
    <MonacoEditor
      key={`code-recipe-code-editor-${recipeId}`}
      height="100%"
      value={code}
      width="100%"
      language="python"
      theme="custom-theme"
      options={{
        readOnly: true,
        minimap: { enabled: false },
        renderLineHighlight: "none",
        scrollbar: {
          vertical: "hidden",
          horizontal: "hidden"
        },
        overviewRulerLanes: 0,
        padding: {
          top: 16
        }
      }}
    />
  );
};

export default ReadOnlyCodeRecipe;
