import React from "react";
import { makeStyles, TextField, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { useStyles as useSubtopBarStyles } from "./styling";

const FONT_SIZE = 14;

export const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 12,
    // @ts-expect-error
    maxWidth: ({ inputWidth }) => inputWidth
  },
  inputClass: {
    flex: "1 0 auto",
    "& div:not(.Mui-focused):not(:hover) fieldset": {
      border: "none"
    },
    "& input": {
      fontSize: FONT_SIZE,
      padding: "8px"
    }
  }
}));

type OwnProps = {
  placeholder?: string;
  inputValue?: string;
  onSave: $TSFixMeFunction;
  onChange: $TSFixMeFunction;
  isLoading?: boolean;
  infoTooltip?: string;
};

// @ts-expect-error TS(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof EditName.defaultProps;

export default function EditName({
  placeholder,
  inputValue,
  onSave,
  onChange,
  isLoading,
  infoTooltip,
  ...restProps
}: Props) {
  const classes = useStyles({ inputWidth: (inputValue?.length + 1) * (FONT_SIZE - 4) + 16 });
  const { infoTooltipStyles } = useSubtopBarStyles();

  const handleChange = (e: $TSFixMe) => {
    const { value } = e.target;
    onChange(value);
  };
  const handleSubmit = () => {
    onSave();
  };

  const handleKeyPress = (e: $TSFixMe) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={classes.container}>
      <TextField
        size="small"
        className={classes.inputClass}
        onBlur={handleSubmit}
        onChange={handleChange}
        placeholder={placeholder}
        value={inputValue}
        variant="outlined"
        onKeyPress={handleKeyPress}
        disabled={isLoading}
        {...restProps}
      />
      {infoTooltip && (
        <Tooltip title={infoTooltip}>
          <InfoOutlined className={infoTooltipStyles} />
        </Tooltip>
      )}
    </div>
  );
}

EditName.defaultProps = {
  placeholder: "",
  inputValue: "",
  isLoading: false
};
