/* eslint-disable no-unused-vars */
import React from "react";
import { useForm } from "react-hook-form";
import {
  makeStyles,
  Grid,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  Typography,
  Tooltip
} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import InfoIcon from "@material-ui/icons/Info";

import { useLicenseTestMutation } from "src/hooks/api";
import { Modal } from "src/components/custom";

const useStyles = makeStyles({
  updateLicenseForm: {
    gap: "4px",
    paddingTop: "8px"
  },
  licenseRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "16px"
  },
  successHelperText: {
    color: "#1B9654"
  },
  errorHelperText: {
    color: "#E16546",
    paddingRight: "6px"
  }
});

type FormValues = {
  licenseKey: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onUpdate: (licenseKey: string) => void;
  isSubmitInProgress: boolean;
};

export const UpdateLicenseModal = ({ open, onClose, onUpdate, isSubmitInProgress }: Props) => {
  const classes = useStyles();
  const licenseTestMutation = useLicenseTestMutation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<FormValues>();
  const licenseKey = watch("licenseKey");
  const onSubmit = (data: FormValues) => {
    onUpdate(data.licenseKey);
  };

  const clearLicenseKey = () => {
    setValue("licenseKey", "");
    licenseTestMutation.reset();
  };

  const onLicenseTest = (licenseKey: string) => {
    licenseTestMutation.mutate({ licenseKey });
  };

  const errorMsg =
    (licenseTestMutation.error as $TSFixMe)?.response?.data?.msg ||
    (licenseTestMutation.error as $TSFixMe)?.message;
  return (
    <Modal
      open={open}
      size="md"
      title="Update License"
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      isSubmitDisabled={
        isSubmitInProgress || !licenseTestMutation.isSuccess || licenseTestMutation.isLoading
      }
      isSubmitting={isSubmitInProgress}
      submitLabel="Update">
      <Grid container direction="column" className={classes.updateLicenseForm}>
        <Grid className={classes.licenseRow}>
          <Box flex={1}>
            <TextField
              required
              fullWidth
              size="small"
              {...register("licenseKey", {
                required: "The License Key cannot be blank.",
                setValueAs: (val: string) => val?.trim(),
                onChange: () => licenseTestMutation.reset()
              })}
              label="License Key"
              variant="outlined"
              error={!!errors.licenseKey}
              disabled={licenseTestMutation.isLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={clearLicenseKey} disabled={!licenseKey}>
                      <BackspaceIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText={errors.licenseKey && <>{errors.licenseKey?.message}</>}
            />
          </Box>
          <Box width="120px">
            <Button
              fullWidth
              variant="outlined"
              disabled={!licenseKey}
              onClick={() => onLicenseTest(licenseKey)}>
              Test
            </Button>
          </Box>
        </Grid>
        {licenseTestMutation.isSuccess && (
          <Typography variant="subtitle2" className={classes.successHelperText}>
            License Key Test Successful
          </Typography>
        )}
        {licenseTestMutation.isError && (
          <Grid container direction="row" alignItems="center">
            <Typography variant="subtitle2" className={classes.errorHelperText}>
              Invalid License key
            </Typography>
            {(licenseTestMutation.isError as $TSFixMe) && (
              <Tooltip title={errorMsg}>
                <InfoIcon style={{ color: "#E16546", fontSize: 16 }} />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};
