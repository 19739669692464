import { createContext } from "react";

type MyContextData = {
  canAddStandardRecipe: boolean;
  canAddArtifacts: boolean;
  canAddModels: boolean;
  canRunProject: boolean;
  canViewScenarios: boolean;
  hideProjectSettingGlobalVar: boolean;
  canAccessRecipeTimeout: boolean;
  canViewCodeRecipeConditions: boolean;
  canViewStandardRecipeConditions: boolean;
  canViewSegments: boolean;
  canAccessTenantSettings: boolean;
  canAddUsers: boolean;
  canEditUsers: boolean;
};
//@ts-expect-error
export const AccessControlContext = createContext<MyContextData>();
