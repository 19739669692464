import { getMemorySize, getPercentage } from "./Eda.helpers";

const EDA_COMPONENTS = {
  overview: {
    title: "Overview",
    hideKey: "table"
  },
  correlations: {
    title: "Correlations",
    hideKey: "correlations.auto"
  }
};

const MAPPING = {
  overview: {
    statistics: {
      n_var: {
        label: "Number of variables"
      },
      n: {
        label: "Number of observations"
      },
      n_cells_missing: {
        label: "Missing cells"
      },
      p_cells_missing: {
        label: "Missing cells (%)",
        renderFn: (value: number) => getPercentage(value)
      },
      n_duplicates: {
        label: "Duplicate rows"
      },
      p_duplicates: {
        label: "Duplicate rows (%)",
        renderFn: (value: number) => getPercentage(value)
      },
      memory_size: {
        label: "Total size in memory",
        renderFn: (value: number) => getMemorySize(value)
      },
      record_size: {
        label: "Average record size in memory",
        renderFn: (value: number) => getMemorySize(value)
      }
    }
  }
};

const ALERTS = {
  Constant: {
    tagging: "Constant",
    match: ["has constant value", "constant", "has a constant value"],
    backgroundColor: "#f0ad4e",
    recommendation:
      "Investigate the data source or drop the constant column as it doesn't contribute to variability."
  },
  Zeros: {
    tagging: "Zeros",
    match: ["zeros"],
    backgroundColor: "#f0ad4e",
    recommendation: "Examine the nature of the zeros and consider their impact on the analysis."
  },
  "High Correlation": {
    tagging: "High Correlation",
    match: ["highly overall correlated"],
    backgroundColor: "#777",
    recommendation:
      "Assess the correlated variables, and if necessary, apply dimensionality reduction techniques."
  },
  "High Cardinality": {
    tagging: "High Cardinality",
    match: ["high cardinality"],
    backgroundColor: "#777",
    recommendation:
      "Evaluate the impact of high cardinality on the model. Consider dimensionality reduction or encoding techniques."
  },
  Imbalance: {
    tagging: "Imbalance",
    match: ["highly imbalanced"],
    backgroundColor: "#777",
    recommendation:
      "Address class imbalance using techniques such as oversampling, undersampling, or synthetic data generation."
  },
  Skewed: {
    tagging: "Skewed",
    match: ["skewed"],
    backgroundColor: "#5bc0de",
    recommendation:
      "Check the skewness's impact on the model. Apply transformations if needed for normalisation."
  },
  "Missing Values": {
    tagging: "Missing Values",
    match: ["missing values"],
    backgroundColor: "#5bc0de",
    recommendation:
      "Impute or handle missing values appropriately based on the data distribution and analysis requirements."
  },
  "Infinite Values": {
    tagging: "Infinite Values",
    match: ["infinite", "infinite values"],
    backgroundColor: "#5bc0de",
    recommendation:
      "Investigate the source of infinite values and address them through proper data cleaning."
  },
  "Unique Values": {
    tagging: "Unique Values",
    match: ["has unique values", "unique"],
    backgroundColor: "#337ab7",
    recommendation:
      "Verify if the uniqueness is expected; if not, investigate and handle accordingly."
  },
  Seasonal: {
    tagging: "Seasonal",
    match: ["seasonal"],
    backgroundColor: "#5bc0de",
    recommendation:
      "Examine the seasonality pattern and incorporate it into the analysis if relevant."
  },
  "Non Stationary": {
    tagging: "Non Stationary",
    match: ["stationary"],
    backgroundColor: "#777",
    recommendation:
      "Apply techniques like differencing to make the time series stationary if needed."
  },
  Date: {
    tagging: "Date",
    match: ["date"],
    backgroundColor: "#777",
    recommendation:
      "Confirm the column's datatype, and if it contains dates, use appropriate date-based analysis."
  },
  Uniform: {
    tagging: "Uniform",
    match: ["uniformly distributed", "uniform"],
    backgroundColor: "#337ab7",
    recommendation:
      "Evaluate the impact of a uniform distribution on the analysis. Consider transformations if needed."
  },
  "Constant Length": {
    tagging: "Constant Length",
    match: [],
    backgroundColor: "#f0ad4e",
    recommendation:
      "Verify if constant length is expected. Otherwise, investigate and address accordingly."
  },
  Rejected: {
    tagging: "Rejected",
    match: ["rejected", "reject"],
    backgroundColor: "#f0ad4e",
    recommendation: "Reevaluate the variable's inclusion in the analysis or address mixed types."
  },
  Unsupported: {
    tagging: "Unsupported",
    match: ["unsupported"],
    backgroundColor: "#337ab7",
    recommendation: "Check and convert unsupported data types or formats to compatible ones."
  },
  Duplicates: {
    tagging: "Duplicates",
    match: ["duplicate", "duplicates"],
    backgroundColor: "#337ab7",
    recommendation: "Investigate and handle duplicate records to ensure data integrity."
  },
  Empty: {
    tagging: "Empty",
    match: ["has empty", "empty"],
    backgroundColor: "#337ab7",
    recommendation:
      "Ensure data is available for analysis. Investigate and acquire data if it's missing."
  }
};

const TAGS = [
  "High Correlation",
  "Imbalance",
  "High Cardinality",
  "Missing Values",
  "Uniform",
  "Zeros",
  "Empty",
  "Duplicates",
  "Unsupported",
  "Rejected",
  "Constant Length",
  "Constant",
  "Skewed",
  "Non Stationary",
  "Seasonal",
  "Unique Values",
  "Infinite Values",
  "Date"
];

export { EDA_COMPONENTS, MAPPING, ALERTS, TAGS };
