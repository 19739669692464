import React from "react";

import { Button, makeStyles } from "@material-ui/core";

import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

import { OntologyNavigationTypes, DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import { useUploadContext } from "../../contexts/Upload/useUploadContext";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 150
  },
  buttonMarginLeft: {
    extend: "button",
    marginLeft: theme.spacing(1)
  }
}));

type Props = {
  ontologyClose: React.ReactNode;
};

const OntologyActions = (props: Props) => {
  const { ontologyClose } = props || {};

  const classes = useStyles();

  const { ontologyNavigation } = useUploadContext();

  // Stores - STARTS >>
  const { datasetFooterActionsStore } = useStoreSelectors();
  // << ENDS - Stores

  return (
    <>
      {!datasetFooterActionsStore.ontologyPrevious.isHidden && (
        <Button
          id="datasetActionOntologyPrevious"
          variant="outlined"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.ontologyPrevious.isDisabled}
          onClick={() => ontologyNavigation(OntologyNavigationTypes.Previous)}>
          <ChevronLeftRoundedIcon />
        </Button>
      )}
      {/* Hidden cancel-action since 16-Jan-2024 release. */}
      {/* {cancel} */}
      {!datasetFooterActionsStore.ontologyNext.isHidden && (
        <Button
          id="datasetActionOntologyNext"
          variant="contained"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.ontologyNext.isDisabled}
          onClick={() => ontologyNavigation()}
          endIcon={<ChevronRightRoundedIcon />}>
          {DatasetHelperText.Next}
        </Button>
      )}
      {ontologyClose}
    </>
  );
};

export default OntologyActions;
