import React from "react";

export const ArtifactsAndModelsIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 2} ${height + 2}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6853 7.44095L13.1158 6.15628L15.6853 4.87148C16.1449 4.64174 16.1454 3.98354 15.6853 3.75343L8.31025 0.0659183C8.13422 -0.0219728 7.92719 -0.0219728 7.75116 0.0659183L0.376131 3.75343C-0.0834661 3.98329 -0.0839543 4.6415 0.376131 4.87148L2.9456 6.15628L0.376131 7.44095C-0.0834661 7.67081 -0.0839543 8.32889 0.376131 8.559L2.9456 9.84379L0.376131 11.1285C-0.0834661 11.3583 -0.0839543 12.0164 0.376131 12.2465L7.75116 15.934C7.92682 16.0218 8.13385 16.0222 8.31025 15.934L15.6853 12.2465C16.1449 12.0167 16.1454 11.3586 15.6853 11.1285L13.1158 9.84379L15.6853 8.559C16.1449 8.32926 16.1454 7.67105 15.6853 7.44095ZM8.03071 1.32374L14.0081 4.31252L8.03071 7.30118L2.05326 4.31252L8.03071 1.32374ZM14.0081 11.6876L8.03071 14.6762L2.05326 11.6876L4.34319 10.5425L7.75116 12.2465C7.92695 12.3343 8.13385 12.3346 8.31025 12.2465L11.7182 10.5425L14.0081 11.6876ZM8.03071 10.9887L2.05326 8.00004L4.34319 6.85501L7.75116 8.559C7.92695 8.64677 8.13385 8.64713 8.31025 8.559L11.7182 6.85501L14.0081 8.00004L8.03071 10.9887Z"
      fill={color}
    />
  </svg>
);
