import React from "react";

// MUI
import { ThemeProvider } from "@material-ui/core/styles";

// Styles
import { newTheme } from "src/styles";

type Props = {
  children: React.ReactNode;
};

const NewThemeWrapper = ({ children }: Props) => {
  return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>;
};

export default NewThemeWrapper;
