export enum ProjectSettingsFormFields {
  Name = "name",
  Description = "description",
  Image = "image",
  EnvironmentType = "environmentType",
  Variables = "variables",
  AskAiSystemMessage = "askAiSystemMessage"
}

export const ProjectSettingsFormFieldsNameMapping = {
  [ProjectSettingsFormFields.Name]: "Project Name",
  [ProjectSettingsFormFields.Description]: "Project Description",
  [ProjectSettingsFormFields.Image]: "Project Image",
  [ProjectSettingsFormFields.EnvironmentType]: "Environment Type",
  [ProjectSettingsFormFields.Variables]: "Global Variables",
  [ProjectSettingsFormFields.AskAiSystemMessage]: "AskAi System Message"
};

export const defaultVariables = [
  {
    key: "",
    value: ""
  }
];

export const defaultValues = {
  [ProjectSettingsFormFields.Name]: "",
  [ProjectSettingsFormFields.Description]: "",
  [ProjectSettingsFormFields.Image]: "",
  [ProjectSettingsFormFields.EnvironmentType]: "",
  [ProjectSettingsFormFields.Variables]: defaultVariables
};

export enum ProjectSettingsCloseWindowPromptDetails {
  title = "Confirm",

  messageLine1 = "If you leave you will lose any unsaved changes.",
  messageLine2 = "Do you really want to close this window?",

  cancelLabel = "No",
  submitLabel = "Yes"
}

export enum ProjectSettingsHelperText {
  Title = "Project Settings",

  Additional = "Additional",

  Variables = "Global Variables",
  AddVariable = "Add Variable",
  VariableFieldName = "Global Variable Name",
  Key = "Variable",
  Value = "Key Value",
  Unknown = "Unknown",

  AskAiSystemMessagePlaceholder = "System message updated here will be used by all AskAI queries across this project",

  Cancel = "Cancel",
  Save = "Save",
  Delete = "Delete Project",

  ProjectSaved = "Settings saved successfully!",
  ProjectDeleted = "Project deleted successfully!"
}
