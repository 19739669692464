import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5)
  },
  wrapper: {
    maxHeight: "calc(100vh - 715px)",
    overflowY: "auto"
  },
  variableContainer: {
    position: "relative",
    columnGap: theme.spacing(2),
    width: "auto"
  },
  textField: {
    width: 250
  },
  inputLabel: {
    opacity: 0.625
  },
  inputLabelShrink: {
    opacity: 1
  }
}));

export default useStyles;
