import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import TenantDetails from "./TenantDetails/TenantDetails";
import TenantUsersManagement from "./TenantUsersManagement/TenantUsersManagement";
import { Tenant } from "src/types";
import { IUserTenant } from "src/hooks/api";

const useStyles = makeStyles(() => ({
  tenantSettingsWrap: {
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

type IProps = {
  tenantDetails: Tenant;
  currentTenant: IUserTenant | undefined;
};

export const TenantSettings = ({ tenantDetails, currentTenant }: IProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.tenantSettingsWrap}>
      <TenantDetails
        name={tenantDetails.name}
        description={tenantDetails.description}
        userCount={currentTenant?.numberOfUsers || 0}
        projectCount={currentTenant?.numberOfProjects || 0}
      />
      <TenantUsersManagement users={tenantDetails.tenants} />
    </Grid>
  );
};
