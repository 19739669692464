const getPercentage = (value: number) => {
  return Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);
};

const getMemorySize = (value: number) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    style: "unit",
    unit: "byte",
    unitDisplay: "narrow"
  }).format(value / 1.024);
};

const getNumberUptoNDecimal = (value: number, decimals: number) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value);
};

export { getPercentage, getMemorySize, getNumberUptoNDecimal };
