import React from "react";
import { FormControl, FormLabel, Switch, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import { dataAppConfigFields } from "./CreateDataAppForm";

interface IProps {
  readOnly?: boolean;
  value: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EnableResponseCache: React.FC<IProps> = (props) => {
  return (
    <FormControl component="fieldset" disabled={props.readOnly}>
      <FormLabel component="legend">
        Enable Response Caching{" "}
        <Tooltip title="Caches the responses and returns the same response for identical queries asked multiple times">
          <InfoOutlined style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }} />
        </Tooltip>
      </FormLabel>
      <Switch
        checked={props.value}
        name={dataAppConfigFields.enableCache}
        color="primary"
        onChange={props.onChange}
        data-testid="dataAppConfigEnableCache"
      />
    </FormControl>
  );
};

export default EnableResponseCache;
