/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import { Grid, SvgIcon, Paper, Typography, makeStyles } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  paper: {
    width: "100%",
    marginBottom: 8,
    height: "100%",
    display: "flex",
    backgroundColor: "#f5f7f9",
    border: "1px dashed #425e7f",
    boxShadow: "none",
    "& input:hover": {
      cursor: "pointer"
    }
  },
  uploadComponent: {
    marginBottom: 0,
    marginRight: 8,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    height: "100%"
  },
  label: {
    fontSize: ".8rem",
    color: "#113554",
    marginTop: 10,
    padding: "0 24px"
  },
  error: {
    fontSize: ".8rem",
    color: "red",
    marginTop: 10
  }
}));

type Props = {
  value?: {
    name?: string;
  };
  onChange: $TSFixMeFunction;
  id: string;
  required?: boolean;
  disabled?: boolean;
  types?: string[];
  validateFiles?: (files: FileList) => boolean;
};

const validFileTypes = ["csv", "xls", "xlsx", "parquet"];

const validFormatString = () => {
  let formatFileTypes = validFileTypes?.map((type: string) =>
    ["xls", "xlsx"].includes(type) ? `.${type}` : type
  );
  const last = formatFileTypes?.pop();
  return formatFileTypes?.length === 0 ? last : [formatFileTypes?.join(", "), last]?.join(" and ");
};

const UploadFileInput = ({
  value = {},
  required,
  disabled,
  types,
  onChange,
  id = "file",
  validateFiles
}: Props) => {
  const [errorMessage, setErrorMessage] = useState<$TSFixMe>();
  const classes = useStyles();

  const fileRef = useRef(null);

  const handleChange = (e: $TSFixMe) => {
    const areAllFileTypesValid = validateFiles
      ? validateFiles?.(e.target.files)
      : [...e.target?.files]?.every((eachFile: $TSFixMe) => types?.includes(eachFile?.type)) ||
        false;

    if (areAllFileTypesValid) {
      setErrorMessage(null);
      onChange(e);
    } else {
      setErrorMessage(
        `File type is invalid. <br />Supported file formats are ${validFormatString()}.`
      );
    }

    // Below code resets the input type file. It's needed to accept every files selection. Without the below code, the subsequent selections are omitted.
    // @ts-ignore
    fileRef.current.value = "";
  };

  const cleanErrorMessage = () => errorMessage && setErrorMessage(null);

  return (
    <Paper className={classes.paper} style={{ opacity: disabled ? 0.5 : 1 }}>
      <Grid container alignItems="center" style={{ display: "flex", justifyContent: "center" }}>
        <input
          style={{ opacity: 0, height: 120, position: "absolute" }}
          id={id}
          multiple
          name="file"
          type="file"
          onChange={handleChange}
          onClick={cleanErrorMessage}
          required={required}
          ref={fileRef}
          disabled={disabled}
        />
        <label htmlFor={id} className={classes.uploadComponent}>
          <SvgIcon fontSize="large" style={{ color: "#425e7f" }}>
            <CloudUpload />
          </SvgIcon>
          {errorMessage ? (
            <Typography
              className={classes.error}
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          ) : (
            <Typography className={classes.label}>
              {value?.name || "Drag and drop or click to upload a file from local"}
            </Typography>
          )}
        </label>
      </Grid>
    </Paper>
  );
};

export default UploadFileInput;
