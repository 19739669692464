import { useMutation } from "@tanstack/react-query";

import api from "utils/AxiosClient";

import { AddInputsToThreadRequestDto } from "openapi/Models/add-inputs-to-thread-request-dto";

export const useAddAIThreadInputsMutation = () => {
  return useMutation({
    mutationFn: async ({ request }: { request: AddInputsToThreadRequestDto }) => {
      return await api.fetchResponse(() =>
        api.AiControllerApi.addInputsToThread(request.threadId!, request)
      );
    }
  });
};
