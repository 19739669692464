import React, { useMemo } from "react";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { includes, without } from "lodash";

import PinnedIcon from "src/icons/NewUX/PinnedIcon";
import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";
import UnPinnedIcon from "src/icons/NewUX/UnPinnedIcon";
import { MAX_INPUT_COUNT } from "../GenerateCodeBar/GenerateCodeInputsDropdown";

export const useStyles = makeStyles({
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "6px"
  }
});

interface IProps {
  name: string;
}

const PinnedDatasets: React.FC<IProps> = (props) => {
  const { name } = props;

  const classes = useStyles();
  const { pinnedNames, setPinnedNames, setInputNames } = useCodeRecipeContext();

  const pinned = useMemo(() => includes(pinnedNames, name), [pinnedNames, name]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const datasets = pinned ? without(pinnedNames, name) : [...pinnedNames, name];
    const updated =
      datasets?.length <= MAX_INPUT_COUNT ? datasets : datasets.slice(1, MAX_INPUT_COUNT + 1);

    setPinnedNames(updated);
    setInputNames(updated);
  };

  return (
    <Tooltip title="Pinning a dataset will run queries on pinned datasets instead of intermediate datasets">
      <div>
        <IconButton className={classes.queryBtn} test-id="ask-ai-pin" onClick={handleClick}>
          {pinned ? <PinnedIcon /> : <UnPinnedIcon color="#0f0f0f" />}
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default PinnedDatasets;
