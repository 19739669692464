import { cloneDeep, filter, forEach, includes, map } from "lodash";

import { ConditionExpression, SegmentDto } from "openapi/Models";

import { SegmentGroup } from "src/types";
import { DatasetFeaturesDataTypeMapping, Segment } from "../Segment.type";

import { SegmentFormFields, SegmentHelperText } from "./Segment.constants";

export const composeSegmentName = (segments?: SegmentDto[]) => {
  const segmentNames = map(
    filter(segments, (segment) => includes(segment?.name, SegmentHelperText.UntitledSegment)),
    (segment) => segment?.name
  );

  let name = `${SegmentHelperText.UntitledSegment}`;
  let i = 2;

  while (includes(segmentNames, name)) {
    name = `${SegmentHelperText.UntitledSegment} ${i}`;
    i++;
  }

  return name;
};

export const getRulesFromExpression = (expression?: SegmentGroup): SegmentGroup[] => {
  const rules: SegmentGroup[] = [];

  const processGroup = (group: any) => {
    const { groupElements = [] } = group;

    // Filter out only the items from the group elements
    const items = filter(groupElements, (element) => element.type === "item");

    // Add the current group with only item elements to the rules array
    rules.push({
      ...group,
      groupElements: items
    });

    // Recursively process nested groups
    forEach(groupElements, (element) => {
      if (element.type === "group") {
        processGroup(element);
      }
    });
  };

  // Start processing from the root expression group
  if (!!expression) {
    processGroup(cloneDeep(expression));
  }

  return rules;
};

const filterGroup = (
  group: any,
  datasetFeaturesDataTypeMapping?: DatasetFeaturesDataTypeMapping
): any => {
  const newGroupElements = group.groupElements.map((rule: any) => {
    if (includes(["IsNull", "IsNotNull"], rule.operator)) {
      const clonedRule = { ...rule };
      delete clonedRule.value.value;
      return clonedRule;
    }

    return {
      ...rule,
      value: {
        ...rule.value,
        dataType: datasetFeaturesDataTypeMapping?.[rule?.field?.name] || "ANY"
      }
    };
  });

  return { ...group, groupElements: newGroupElements };
};

export const payloadConditions = (
  values: Segment,
  datasetFeaturesDataTypeMapping?: DatasetFeaturesDataTypeMapping
): ConditionExpression => {
  const groups = [...(values?.[SegmentFormFields.Groups] || [])];

  return groups.reduceRight((expression, ruleGroup, index) => {
    const filteredRuleGroup = filterGroup(ruleGroup, datasetFeaturesDataTypeMapping);

    if (index === groups.length - 1) {
      return filteredRuleGroup;
    }

    return {
      ...filteredRuleGroup,
      groupElements: [...filteredRuleGroup.groupElements, expression]
    };
  }, {} as ConditionExpression);
};
