import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient
} from "@tanstack/react-query";

import { EXPORT_CONNECTOR_WITH_OUTPUT_ENTITY_SUCCESS_MSG } from "pages/private/ProjectsModule/pages/OutputDataset/utils/OutputDataset.constants";
import { QUERY_KEY_ENTITIES } from "./useEntities";
import { handleResponse, putAPIWithRethrow } from "src/utils/apiService";
import { saveExpandableNodesWithRethrow, updateEntityWithRethrow } from "src/api/projects";

interface IVariables {
  id: string;
  syncDataSourceId?: string | null;
  syncDataSourceOptions?: Record<string, string> | null;
  displayName?: string;
  showSuccessMsg?: boolean;
}

const useUpdateEntities = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, syncDataSourceId, displayName, syncDataSourceOptions }) => {
      const results = await putAPIWithRethrow("/v2/entities", {
        id,
        syncDataSourceId,
        syncDataSourceOptions,
        displayName
      });

      return results;
    },
    onSuccess: (_, variables) => {
      variables.showSuccessMsg &&
        handleResponse({ successMessage: EXPORT_CONNECTOR_WITH_OUTPUT_ENTITY_SUCCESS_MSG });
      queryClient.invalidateQueries([QUERY_KEY_ENTITIES]);
    }
  });
};

export const useUpdateEntity = ({ ...options }: UseMutationOptions<$TSFixMe, Error, $TSFixMe>) => {
  return useMutation({
    mutationFn: async (payload) => await updateEntityWithRethrow(payload),
    ...options
  });
};

export const useSaveExpandableNodes = ({
  ...options
}: UseMutationOptions<$TSFixMe, Error, $TSFixMe>): UseMutationResult => {
  return useMutation({
    mutationFn: async (data: $TSFixMe) =>
      await saveExpandableNodesWithRethrow({ projectId: data?.projectId, payload: data?.payload }),
    ...options
  });
};

export default useUpdateEntities;
