import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

type Props = {
  handleClose: () => void;
  handleSubmit: () => void;
  isSubmitLoading?: boolean;
};

export const PublishJobModal = ({ handleClose, handleSubmit, isSubmitLoading }: Props) => (
  <Modal
    open={true}
    variant={ModalVariants.Delete}
    title="Republish Canvas to Job"
    content={["This will republish the latest Project Canvas to this Job!", "Are you sure?"]}
    cancelLabel="No"
    submitLabel="Yes, Republish"
    isSubmitting={isSubmitLoading}
    onClose={handleClose}
    onSubmit={handleSubmit}
  />
);
