import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useGetCanvas } from "src/hooks/api";
import { Entities } from "src/types";
export const useGetAllowedDatasets = ({
  allEntities,
  recipeId
}: {
  allEntities: Entities | undefined;
  recipeId: string | undefined;
}) => {
  const { projectId, scenarioId, jobRunId } = useParams<$TSFixMe>();
  const {
    isFetching,
    isFetched,
    data: canvas
  } = useGetCanvas({
    projectId,
    scenarioId,
    jobRunId,
    enabled: !!recipeId
  });

  const allAllowedEntities = React.useMemo(
    () => allEntities?.filter((entity) => entity.status === "BUILT"),
    [allEntities]
  );

  const allowedInputEntities = useMemo(() => {
    const getAllowedEntities = (recipeId: string) => {
      const edgeSourceDestinationMap = canvas?.edges?.reduce((acc: $TSFixMe, edge: $TSFixMe) => {
        const { source, target } = edge;
        if (!acc[source]) {
          return { ...acc, [source]: [target] };
        }
        const currentTargets = acc[source];
        return { ...acc, [source]: [...currentTargets, target] };
      }, {});
      const getChildNodes = (() => {
        const cache: { [key: string]: $TSFixMe } = {};

        return (recipeId: string, edgeSourceDestinationMap: $TSFixMe) => {
          if (cache[recipeId]) {
            return cache[recipeId];
          }

          if (
            !edgeSourceDestinationMap?.[recipeId] ||
            edgeSourceDestinationMap?.[recipeId]?.length === 0
          ) {
            cache[recipeId] = [recipeId];
            return cache[recipeId];
          }

          const targets = edgeSourceDestinationMap?.[recipeId];
          const childNodes = targets?.flatMap((target: string) =>
            getChildNodes(target, edgeSourceDestinationMap)
          );

          cache[recipeId] = [...childNodes, recipeId];
          return cache[recipeId];
        };
      })();
      const childNodes = getChildNodes(recipeId, edgeSourceDestinationMap);
      return allAllowedEntities?.filter((entity) => !childNodes.includes(entity.id)) || [];
    };
    return recipeId && isFetched ? getAllowedEntities(recipeId) : allAllowedEntities || [];
  }, [canvas?.edges, allAllowedEntities, isFetched, recipeId]);

  return { allowedInputEntities, isFetching };
};
