import React from "react";

// MUI
import { Grid, Paper } from "@material-ui/core";

// Icons
import rapidCanvasLogo from "src/assets/images/rc-logo-dark-text.svg";

// Styles
import { useStyles } from "./ResetPassword.styles";

type WrapperProps = {
  children?: React.ReactNode;
};

const Wrapper = (props: WrapperProps) => {
  const { children } = props || {};

  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.page}>
        <Paper variant="outlined" className={classes.root}>
          <Grid container direction="column" className={classes.container}>
            <Grid item className={classes.rapidCanvasLogoContainer}>
              <img
                src={rapidCanvasLogo}
                width={250}
                alt="RapidCanvas logo"
                data-testid="signInRapidCanvasLogo"
              />
            </Grid>
            {children}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default Wrapper;
