interface ValidateFieldResponse {
  isValid: boolean;
  error?: string;
}
export const validateNameField = ({
  fieldName,
  fieldNameLabel,
  maxFieldLength = 64
}: {
  fieldName: string;
  fieldNameLabel: string;
  maxFieldLength?: number;
}): ValidateFieldResponse => {
  if (!fieldName) {
    return {
      isValid: false,
      error: `The ${fieldNameLabel} cannot be blank.`
    };
  }
  const cleanedFieldName = fieldName.trim();
  if (cleanedFieldName?.length < 3 || cleanedFieldName?.length > maxFieldLength) {
    return {
      isValid: false,
      error: `The ${fieldNameLabel} needs to be between 3 and ${maxFieldLength} characters.`
    };
  }
  if (
    // Spaces, underscores and dashes are allowed. No other special characters
    !/^(?:[a-zA-Z0-9 _-]*)?$/.test(cleanedFieldName) ||
    // Should not have only special characters
    /^(?:[_ -]*)?$/.test(cleanedFieldName) ||
    // Should not start with special characters
    /^(?:[_-]*)?$/.test(cleanedFieldName.substring(0, 1))
  ) {
    return {
      isValid: false,
      error: `The ${fieldNameLabel} contains invalid characters. It needs to start with alphanumerics and can only contain alphanumerics, spaces, underscores and dashes.`
    };
  }
  return { isValid: true };
};
