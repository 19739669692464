import React from "react";
import { List } from "@material-ui/core";

import NotificationItem from "./NotificationItem";
import { Notification } from "src/types/Notifications.types";
import { useStyles } from "./TopNavBarNotifications.style";

interface IProps {
  notifications: Notification[];
}

const NotificationsList: React.FC<IProps> = (props) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {props.notifications.map((eachNotification: Notification, index: number) => (
        <NotificationItem key={index} notification={eachNotification} />
      ))}
    </List>
  );
};

export default NotificationsList;
