import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";

const QUERY_KEY_ADVANCE_ANALYSIS = "query-key-advance-analysis";

const useAdvanceAnalysis = (
  url: string | null,
  options?: UseQueryOptions<string>
): UseQueryResult<string> => {
  return useQuery({
    queryKey: [QUERY_KEY_ADVANCE_ANALYSIS, url],
    queryFn: async () => {
      if (!url) {
        throw "url is absent";
      }

      const response = await axios.get(url);

      return response.data;
    },
    enabled: !!url,
    refetchOnMount: true,
    ...options
  });
};

export default useAdvanceAnalysis;
export { QUERY_KEY_ADVANCE_ANALYSIS };
