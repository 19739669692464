import React from "react";
import shallow from "zustand/shallow";
import { useNavigate, useLocation } from "react-router-dom";

import useAuthStore from "src/stores/auth.store";
import useQuery from "src/hooks/useQuery";
import { RequestState } from "src/types/RequestState";
import { fetchUpdatedStreamlitURL } from "src/utils/urlUtils";
import { getAPIWithRethrow, postAPIWithRethrow } from "src/utils/apiService";
import { loginPath } from "src/routing/routes";

export const useAuthAwaitStreamlit = ({ fetchOnLoad } = { fetchOnLoad: true }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = useQuery();
  const includeStreamlitAuth = queryParameters.get("includeStreamlitAuth");
  const redirectUrl = queryParameters.get("redirectUrl");
  const tenantId = queryParameters.get("tenantId");
  const [requestState, setRequestState] = React.useState<RequestState>(RequestState.Idle);
  const shouldFetchStreamlitToken = redirectUrl && includeStreamlitAuth;
  const [token, logout] = useAuthStore((state) => [state.token, state.logout], shallow);

  const fetchAllTokens = React.useCallback(async () => {
    const handleRequestFailure = () => {
      setRequestState(RequestState.Failure);
      logout({});

      navigate({ pathname: loginPath, search: location.search });
    };
    const fetchStreamLitToken = async (newToken?: string) => {
      setRequestState(RequestState.Loading);
      const headers = newToken ? { Authorization: `Bearer ${newToken}` } : {};
      try {
        const streamlitToken = await getAPIWithRethrow(`/streamlit/access-token`, { headers });
        const updatedUrl = fetchUpdatedStreamlitURL(redirectUrl, streamlitToken, newToken || token);
        // Redirect to streamlit app on success
        window.location.replace(updatedUrl);
      } catch (error) {
        handleRequestFailure();
        return Promise.reject(error);
      }
    };
    const fetchTenantAndStreamlitTokens = async () => {
      setRequestState(RequestState.Loading);
      try {
        const data = await postAPIWithRethrow("/switch", { tenantId });
        fetchStreamLitToken(data.token);
      } catch (error) {
        handleRequestFailure();
        return Promise.reject(error);
      }
    };
    if (!shouldFetchStreamlitToken) {
      return;
    }
    return tenantId ? fetchTenantAndStreamlitTokens() : fetchStreamLitToken();
  }, [redirectUrl, shouldFetchStreamlitToken, tenantId, token]);

  React.useEffect(() => {
    /* If tenantId in params, fetchTenantToken and use the new tenant token to fetch streamlit token
    Otherwise, Just use existing tenant in session to fetch streamlit token */
    fetchOnLoad && fetchAllTokens();
  }, [fetchOnLoad]);

  return {
    shouldFetchStreamlitToken,
    requestState,
    fetchAllTokens
  };
};
