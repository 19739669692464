import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: `0.5px solid ${theme.palette.divider}`,
    padding: 0,
    "&:hover": {
      "& .reSizer": {
        opacity: 1
      }
    },
    "& .headerContainer": {
      columnGap: theme.spacing(1),
      padding: `${theme.spacing(0.75)}px ${theme.spacing(3)}px ${theme.spacing(0.75)}px ${theme.spacing(2)}px`
    },
    "& .schemaContainer": {
      padding: `${theme.spacing(0.75)}px ${theme.spacing(3)}px ${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
      borderTop: "1px solid #e0e0e0",

      fontSize: "small",
      fontStyle: "italic",
      fontWeight: "bold",
      color: "#7c7c7c"
    },
    "& .sortable": {
      cursor: "pointer"
    },
    "& .reSizer": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "50%",
      width: theme.spacing(0.5),
      background: "#e0e0e0",
      cursor: "col-resize",
      userSelect: "none",
      touchAction: "none",
      opacity: 0
    },
    "& .isResizing": {
      background: theme.palette.primary.main,
      opacity: 1
    }
  }
}));

export default useStyles;
