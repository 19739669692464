/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import { postAPIWithRethrow } from "src/utils/apiService";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";

type TestTransformConfig = {
  name: string;
  templateId: string | null;
};

export const useTestTransformGroupV2Mutation = () => {
  return useMutation({
    mutationFn: async ({
      recipeId,
      projectId,
      testTransformConfig,
      sampleRows
    }: {
      projectId: string | undefined;
      recipeId: string | null | undefined;
      testTransformConfig: TestTransformConfig[];
      sampleRows?: number;
    }) => {
      projectId && checkEnvRelaunch(projectId);
      return await postAPIWithRethrow(
        `/v2/dfs-run-config-groups/testv2/${recipeId}${
          sampleRows ? `?sampleRows=${sampleRows}` : ""
        }`,
        testTransformConfig,
        {},
        false
      );
    },
    cacheTime: 0
  });
};
