import { MarkerType } from "react-flow-renderer";
import { includes, isEmpty, lowerCase, remove } from "lodash";

import {
  getSessionStorageItem,
  setSessionStorageItem
} from "src/services/SessionStorage/SessionStorage.service";

import { NodeTypes, NodeTypesSessionKeyMapping } from "./Projects.constants";

import { NodeData } from "src/types";
import { EdgeType } from "../pages/Dag/Dag.types";

// @ts-ignore
export const edgeConfig: EdgeType = {
  type: "smoothstep",
  markerEnd: {
    type: MarkerType.Arrow, // 'arrow' or 'arrowclosed'
    color: "#c7c7c7", // arrow fill color
    width: 10,
    height: 10,
    orient: "auto", // defines rotation
    strokeWidth: 2
  },
  style: { stroke: "#c7c7c7", strokeWidth: 2, transition: "all ease 0.25s" }
};

export const resetNodesSelectionStore = () => {
  setSessionStorageItem({
    key: NodeTypesSessionKeyMapping[NodeTypes.Dataset],
    data: []
  });

  setSessionStorageItem({
    key: NodeTypesSessionKeyMapping[NodeTypes.Artifact],
    data: []
  });

  setSessionStorageItem({
    key: NodeTypesSessionKeyMapping[NodeTypes.Model],
    data: []
  });
};

const thisUpdateNodeSelectionStore = ({ key, value }: { key: string; value: string }) => {
  let selectedNodes: string[] =
    getSessionStorageItem({
      key
    }) || [];

  if (includes(selectedNodes, value)) {
    remove(selectedNodes, (id: string) => value === id);
  } else {
    if (isEmpty(selectedNodes)) {
      selectedNodes = [value];
    } else {
      selectedNodes.push(value);
    }
  }

  setSessionStorageItem({
    key,
    data: selectedNodes
  });
};

export const updateNodeSelectionStore = (nodeData?: NodeData) => {
  switch (nodeData?.type) {
    case lowerCase(NodeTypes.Dataset):
      thisUpdateNodeSelectionStore({
        key: NodeTypesSessionKeyMapping[NodeTypes.Dataset],
        value: nodeData?.id
      });
      break;

    case lowerCase(NodeTypes.Artifact):
      thisUpdateNodeSelectionStore({
        key: NodeTypesSessionKeyMapping[NodeTypes.Artifact],
        value: nodeData?.name
      });
      break;

    case lowerCase(NodeTypes.Model):
      thisUpdateNodeSelectionStore({
        key: NodeTypesSessionKeyMapping[NodeTypes.Model],
        value: nodeData?.name
      });
      break;
  }
};

export const isNodeSelected = (nodeData: $TSFixMe) => {
  let isSelected = false;
  let selectedNodes;

  switch (nodeData?.type) {
    case lowerCase(NodeTypes.Dataset):
      selectedNodes = getSessionStorageItem({ key: NodeTypesSessionKeyMapping[NodeTypes.Dataset] });
      isSelected = includes(selectedNodes || [], nodeData?.id);
      break;

    case lowerCase(NodeTypes.Artifact):
      selectedNodes = getSessionStorageItem({
        key: NodeTypesSessionKeyMapping[NodeTypes.Artifact]
      });
      isSelected = includes(selectedNodes || [], nodeData?.name);
      break;

    case lowerCase(NodeTypes.Model):
      selectedNodes = getSessionStorageItem({ key: NodeTypesSessionKeyMapping[NodeTypes.Model] });
      isSelected = includes(selectedNodes || [], nodeData?.name);
      break;
  }

  return isSelected;
};
