import { useEffect } from "react";
import { useGetAIGuideThreads } from "src/hooks/api";
import { useThreadsStore } from "src/store/store";

export const useSetAIGuideMessagesCount = ({ projectId }: { projectId: string }) => {
  const { setProjectTargetMapFromThreads } = useThreadsStore();
  const { data: threads } = useGetAIGuideThreads({
    projectId: projectId!,
    shouldSortAndFilter: true
  });

  useEffect(() => {
    threads && setProjectTargetMapFromThreads(threads);
  }, [threads]);
};
