import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  radialWrapper: {
    position: "absolute",
    width: ({ dialSize }: any) => dialSize,
    height: ({ dialSize }: any) => dialSize,
    // left: ({ dialSize }: any) => `calc(50% - ${dialSize}px)`,
    transform: "rotateZ(45deg)",
    "&::after": {
      content: '""',
      background: "#f7f7f7",
      borderRadius: "50%",
      position: "absolute" as any,
      width: ({ dialSize, dialLineWidth }: any) => dialSize - dialLineWidth * 2,
      height: ({ dialSize, dialLineWidth }: any) => dialSize - dialLineWidth * 2,
      top: ({ dialLineWidth }: any) => dialLineWidth,
      left: ({ dialLineWidth }: any) => dialLineWidth
    }
  },
  radialSection: {
    position: "absolute",
    top: 0,
    bottom: 0,
    overflow: "hidden",
    width: ({ dialSize }: any) => `${dialSize / 2}px`
  },
  radialLeftSection: {
    left: 0
  },
  radialRightSection: {
    left: ({ dialSize }: any) => `${dialSize / 2}px`
  },
  marker: {
    background: ({ isNewTheme }) => (isNewTheme ? "#d5d5f2" : "#baddf2"),
    borderRadius: "50%",
    height: ({ dialLineWidth }: any) => dialLineWidth,
    width: ({ dialLineWidth }: any) => dialLineWidth,
    position: "absolute",
    top: 0,
    left: ({ dialLineWidth }: any) => `calc(50% - ${dialLineWidth / 2}px)`,
    transformOrigin: ({ dialSize }: any) => `50% ${dialSize / 2}px`,
    transform: "rotateZ(-180deg)"
  },
  markerEnd: {
    transform: "rotateZ(90deg)",
    transition: "all 1s linear"
  },
  wedge: {
    background: ({ isNewTheme }) => (isNewTheme ? "#d5d5f2" : "#baddf2"),
    height: ({ dialSize }: any) => `${dialSize}px`,
    width: ({ dialSize }: any) => `${dialSize / 2}px`,
    transition: "all 1s linear"
  },
  rightSectionWedge: {
    borderRadius: ({ dialSize }: any) => `0 ${dialSize}px ${dialSize}px 0`,
    left: 0,
    transformOrigin: "0 50%",
    transform: "rotateZ(-90deg)"
  },
  leftSectionWedge: {
    borderRadius: ({ dialSize }: any) => `${dialSize}px 0 0 ${dialSize}px`,
    transformOrigin: ({ dialSize }: any) => `${dialSize / 2}px ${dialSize / 2}px`,
    transform: "rotateZ(0deg)"
  },
  progressBarMarkerEnd: {
    transform: "rotateZ(-45deg)"
  },
  progressBarBckgd: {
    background: ({ isNewTheme, disabled }) => (disabled ? "" : isNewTheme ? "#4646b5" : "#008FE4")
  },
  progressBarLeftSectionWedge: {
    transform: "rotateZ(-45deg)"
  },
  progressBarRightSectionWedge: {
    transform: "rotateZ(-181deg)"
  },
  body: {
    margin: "20px"
  },
  wrap: ({ disabled }) => ({
    position: "relative",
    display: "flex",
    height: 165,
    width: "100%",
    background: "#f7f7f7",
    cursor: ({ disabled }) => (disabled ? "not-allowed" : "default"),

    "& .MuiTypography-root": {
      color: disabled ? "#cac8c8" : ""
    }
  }),
  innerContainerWrap: {
    height: "100%",
    flexWrap: "nowrap",
    width: ({ dialSize }: any) => `${dialSize}px`,
    position: "absolute"
  },
  innerContainer: {
    position: "absolute",
    width: ({ dialSize }: any) => `${dialSize - 10}px`,
    height: ({ dialSize }: any) => `${dialSize - 10}px`
  }
}));

export const HorseShoeProgressBar = ({
  percentage,
  children,
  disabled,
  isNewTheme
}: {
  percentage: number;
  children: React.ReactNode;
  disabled?: boolean;
  isNewTheme?: boolean;
}) => {
  const classes = useStyles({ dialSize: 180, dialLineWidth: 12, disabled, isNewTheme });

  const markerEndRef = React.useRef(null);
  const rightSectionWedgeRef = React.useRef(null);
  const leftSectionWedgeRef = React.useRef(null);
  const [oldValue, setOldValue] = React.useState(50);

  function changeTransitionDuration(element: any, duration: any) {
    element.style.transitionDuration = duration + "ms";
  }

  // Function to change transform rotateZ
  function changeTransformRotateZ(element: any, deg: any) {
    element.style.transform = "rotateZ(" + deg + "deg)";
  }

  React.useEffect(() => {
    // Function to change progress dial
    function changeProgressDial(value: number) {
      const leftSection = leftSectionWedgeRef.current;
      const rightSection = rightSectionWedgeRef.current;

      const markerEnd = markerEndRef.current;

      var rightAnimationDuration = 0;
      var leftAnimationDuration = 0;

      if (value <= 66) {
        // Ensure the right side is hidden
        if (oldValue > 66) {
          rightAnimationDuration = (oldValue - 67) * 10;

          changeTransitionDuration(rightSection, rightAnimationDuration);
          changeTransitionDuration(markerEnd, rightAnimationDuration);

          changeTransformRotateZ(rightSection, -181);
          changeTransformRotateZ(markerEnd, 0);
        }

        var leftDeg = -Math.abs(180 - Math.round(2.7 * value));
        var threshold = oldValue > 66 ? 67 : oldValue;

        setTimeout(function () {
          leftAnimationDuration = Math.abs(threshold - value) * 10;

          changeTransitionDuration(leftSection, leftAnimationDuration);
          changeTransitionDuration(markerEnd, leftAnimationDuration);

          changeTransformRotateZ(leftSection, leftDeg);
          changeTransformRotateZ(markerEnd, leftDeg);
        }, rightAnimationDuration);
      } else {
        // See if LHS needs to change
        if (oldValue <= 66) {
          leftAnimationDuration = (67 - oldValue) * 10;

          changeTransitionDuration(leftSection, leftAnimationDuration);
          changeTransitionDuration(markerEnd, leftAnimationDuration);

          changeTransformRotateZ(leftSection, 0);
          changeTransformRotateZ(markerEnd, 0);
        }

        var rightDeg = -90 - (100 - value) * 2.7;
        var thresholdNew = oldValue < 67 ? 67 : oldValue;

        setTimeout(function () {
          rightAnimationDuration = Math.abs(thresholdNew - value) * 10;

          changeTransitionDuration(rightSection, rightAnimationDuration);
          changeTransitionDuration(markerEnd, rightAnimationDuration);

          changeTransformRotateZ(rightSection, rightDeg);
          changeTransformRotateZ(markerEnd, 180 + rightDeg);
        }, leftAnimationDuration);
      }

      setOldValue(value);
    }

    // Call changeProgressDial when the percentage prop changes
    changeProgressDial(percentage);
  }, [percentage, oldValue]);

  return (
    <Grid container className={classes.wrap} justifyContent="center">
      <Grid className={classes.radialWrapper}>
        <Grid className={clsx(classes.radialSection, classes.radialRightSection)}>
          <Grid className={clsx(classes.wedge, classes.rightSectionWedge)}></Grid>
        </Grid>
        <Grid className={clsx(classes.radialSection, classes.radialLeftSection)}>
          <Grid className={clsx(classes.wedge, classes.leftSectionWedge)}></Grid>
        </Grid>
        <Grid className={classes.marker}></Grid>
        <Grid className={clsx(classes.marker, classes.markerEnd)}></Grid>
      </Grid>
      <Grid className={classes.radialWrapper}>
        <Grid className={clsx(classes.radialSection, classes.radialRightSection)}>
          <Grid
            ref={rightSectionWedgeRef}
            className={clsx(
              classes.wedge,
              classes.progressBarBckgd,
              classes.rightSectionWedge,

              classes.progressBarRightSectionWedge
            )}></Grid>
        </Grid>
        <Grid className={clsx(classes.radialSection, classes.radialLeftSection)}>
          <Grid
            ref={leftSectionWedgeRef}
            className={clsx(
              classes.wedge,
              classes.progressBarBckgd,
              classes.leftSectionWedge,
              classes.progressBarLeftSectionWedge
            )}></Grid>
        </Grid>
        <Grid className={clsx(classes.marker, classes.progressBarBckgd)}></Grid>
        <Grid
          ref={markerEndRef}
          className={clsx(
            classes.marker,
            classes.markerEnd,
            classes.progressBarBckgd,
            classes.progressBarMarkerEnd
          )}></Grid>
      </Grid>
      <Grid container className={classes.innerContainerWrap} alignItems="center">
        {children}
      </Grid>
    </Grid>
  );
};

export default HorseShoeProgressBar;
