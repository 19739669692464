import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { PS_DELETE_SUCCESSFUL_MESSAGE } from "../pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.constants";
import { QUERY_KEY_PREDICTION_SERVICE_BY_MODEL } from "./usePredictionServiceByModel";
import { deleteAPIWithRethrow, handleResponse } from "src/utils/apiService";
import { QUERY_KEY_MODELS } from "src/hooks/api";

interface IVariables {
  id: string;
}

const useDeletePredictionService = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }) => {
      const data = await deleteAPIWithRethrow(`/v2/prediction-services/${id}`, { id });
      return data;
    },
    onSuccess: async () => {
      handleResponse({ successMessage: PS_DELETE_SUCCESSFUL_MESSAGE });
      await queryClient.invalidateQueries([QUERY_KEY_PREDICTION_SERVICE_BY_MODEL]);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_MODELS], refetchType: "all" });
    }
  });
};

export default useDeletePredictionService;
