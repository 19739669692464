import React, { Dispatch, SetStateAction } from "react";

import { keys, map, startCase } from "lodash";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

import { Field } from "src/components";

import { SchemaField } from "./Source.types";

type Props = {
  isJobPath: boolean;
  isDefaultScenario: boolean;
  values: { [key: string]: SchemaField };
  setValues: Dispatch<SetStateAction<{ [key: string]: SchemaField }>>;
  isProcessing: boolean;
  resetRunQueryMutation: () => void;
};

const SqlConfiguration = (props: Props) => {
  const { isJobPath, values, setValues, isProcessing, resetRunQueryMutation, isDefaultScenario } =
    props || {};

  const theme = useTheme();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetRunQueryMutation();

    const { name, value } = event.target;

    values[name].value = value;

    setValues({
      ...values
    });
  };

  const getErrorText = (field: string) => {
    const isRequired = values?.[field]?.required && !values?.[field]?.value;

    if (isRequired) {
      return "Required!";
    }

    return "";
  };

  return (
    <Paper elevation={0} style={{ borderRadius: 12 }}>
      <Box p={2} pb={0}>
        <Grid
          container
          component="form"
          id="sqlConfigurationForm"
          name="sqlConfigurationForm"
          autoComplete="sqlConfigurationForm-off"
          style={{ columnGap: theme.spacing(2) }}>
          {map(keys(values), (field: string, index: number) => (
            <Box mb={2}>
              <Field
                key={`index_${index}`}
                id={values?.[field]?.name || `sqlConfigurationFieldId_${index}`}
                label={startCase(values?.[field]?.name || "")}
                variant="outlined"
                // @ts-ignore
                size="small"
                // @ts-ignore
                value={values?.[field]?.value || ""}
                required={values?.[field]?.required || false}
                error={!!getErrorText(field)}
                helperText={getErrorText(field)}
                onChange={onChange}
                autoComplete={`sqlConfigurationFieldId_${index}-off`}
                disabled={!!isJobPath || !isDefaultScenario || isProcessing}
              />
            </Box>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};

export default SqlConfiguration;
