import React from "react";

export const useUpdateEffect = (effect: $TSFixMe, deps: $TSFixMe) => {
  const isFirstMount = React.useRef(true);

  React.useEffect(() => {
    if (!isFirstMount.current) effect();
    else isFirstMount.current = false;
  }, deps);
};
