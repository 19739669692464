import React from "react";

export const RecipeIcon = ({ width = 25, height = 25, fill = "none" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 7.8125C5 6.2592 6.2592 5 7.8125 5H18.4375C19.9908 5 21.25 6.2592 21.25 7.8125V17.5H25.625V21.5625C25.625 23.8061 23.8061 25.625 21.5625 25.625H7.8125C6.2592 25.625 5 24.3657 5 22.8125V7.8125ZM21.25 23.75H21.5625C22.7706 23.75 23.75 22.7706 23.75 21.5625V19.375H21.25V23.75ZM9.6875 10C9.16974 10 8.75 10.4197 8.75 10.9375C8.75 11.4553 9.16974 11.875 9.6875 11.875H16.5625C17.0802 11.875 17.5 11.4553 17.5 10.9375C17.5 10.4197 17.0802 10 16.5625 10H9.6875ZM9.6875 14.375C9.16974 14.375 8.75 14.7948 8.75 15.3125C8.75 15.8302 9.16974 16.25 9.6875 16.25H16.5625C17.0802 16.25 17.5 15.8302 17.5 15.3125C17.5 14.7948 17.0802 14.375 16.5625 14.375H9.6875ZM9.6875 18.75C9.16974 18.75 8.75 19.1698 8.75 19.6875C8.75 20.2052 9.16974 20.625 9.6875 20.625H12.8125C13.3302 20.625 13.75 20.2052 13.75 19.6875C13.75 19.1698 13.3302 18.75 12.8125 18.75H9.6875Z"
        fill="currentColor"
      />
    </svg>
  );
};
