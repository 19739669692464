/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Data } from './data';
// May contain unused imports in some cases
// @ts-ignore
import type { PipelineTestResultPerTransformMsgsInnerValueInner } from './pipeline-test-result-per-transform-msgs-inner-value-inner';

/**
 * 
 * @export
 * @interface SampleDSDataResult
 */
export interface SampleDSDataResult {
    /**
     * 
     * @type {string}
     * @memberof SampleDSDataResult
     */
    'status'?: SampleDSDataResultStatusEnum;
    /**
     * 
     * @type {Array<PipelineTestResultPerTransformMsgsInnerValueInner>}
     * @memberof SampleDSDataResult
     */
    'messages'?: Array<PipelineTestResultPerTransformMsgsInnerValueInner>;
    /**
     * 
     * @type {string}
     * @memberof SampleDSDataResult
     */
    'responseEntity'?: string;
    /**
     * 
     * @type {Data}
     * @memberof SampleDSDataResult
     */
    'data'?: Data;
}

export const SampleDSDataResultStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type SampleDSDataResultStatusEnum = typeof SampleDSDataResultStatusEnum[keyof typeof SampleDSDataResultStatusEnum];


