import React from "react";

export const ConfigIcon = ({ color = "#7C7C7C" } = {}) => (
  <svg width="20" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3333 4.41667H13.3333M4.99996 4.41667H1.66663M18.3333 13.5833H15M6.66663 13.5833H1.66663M8.33329 7.33333C8.71631 7.33333 9.09559 7.25789 9.44945 7.11132C9.80332 6.96474 10.1248 6.7499 10.3957 6.47906C10.6665 6.20822 10.8814 5.88669 11.0279 5.53283C11.1745 5.17896 11.25 4.79969 11.25 4.41667C11.25 4.03364 11.1745 3.65437 11.0279 3.30051C10.8814 2.94664 10.6665 2.62511 10.3957 2.35427C10.1248 2.08343 9.80332 1.86859 9.44945 1.72202C9.09559 1.57544 8.71631 1.5 8.33329 1.5C7.55974 1.5 6.81788 1.80729 6.2709 2.35427C5.72392 2.90125 5.41663 3.64312 5.41663 4.41667C5.41663 5.19021 5.72392 5.93208 6.2709 6.47906C6.81788 7.02604 7.55974 7.33333 8.33329 7.33333ZM11.6666 16.5C12.4402 16.5 13.182 16.1927 13.729 15.6457C14.276 15.0987 14.5833 14.3569 14.5833 13.5833C14.5833 12.8098 14.276 12.0679 13.729 11.5209C13.182 10.974 12.4402 10.6667 11.6666 10.6667C10.8931 10.6667 10.1512 10.974 9.60423 11.5209C9.05725 12.0679 8.74996 12.8098 8.74996 13.5833C8.74996 14.3569 9.05725 15.0987 9.60423 15.6457C10.1512 16.1927 10.8931 16.5 11.6666 16.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
