import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

interface IProps {
  loading: boolean;
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const UpdateGeneratedContentModal: React.FC<IProps> = (props) => {
  const { open, loading, onCancel, onSubmit } = props;

  return open ? (
    <Modal
      open={open}
      variant={ModalVariants.Delete}
      title="Update Content"
      content={[
        "This will trigger About page generation using Generative AI and update the existing content",
        "Are you sure?"
      ]}
      submitLabel="Update"
      isSubmitting={loading}
      onClose={onCancel}
      onSubmit={onSubmit}
    />
  ) : (
    <></>
  );
};

export default UpdateGeneratedContentModal;
