import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

interface IProps {
  loading: boolean;
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const DeleteGeneratedContentModal: React.FC<IProps> = (props) => {
  const { open, loading, onCancel, onSubmit } = props;

  return open ? (
    <Modal
      open={open}
      variant={ModalVariants.Delete}
      title="Delete About Page Content"
      content={["This will delete About page content", "Are you sure?"]}
      isSubmitting={loading}
      onClose={onCancel}
      onSubmit={onSubmit}
    />
  ) : (
    <></>
  );
};

export default DeleteGeneratedContentModal;
