/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LicenseDto
 */
export interface LicenseDto {
    /**
     * 
     * @type {string}
     * @memberof LicenseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDto
     */
    'scope'?: LicenseDtoScopeEnum;
    /**
     * 
     * @type {string}
     * @memberof LicenseDto
     */
    'status'?: LicenseDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof LicenseDto
     */
    'expiryTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicenseDto
     */
    'tenantId'?: string;
}

export const LicenseDtoScopeEnum = {
    System: 'SYSTEM',
    Tenant: 'TENANT'
} as const;

export type LicenseDtoScopeEnum = typeof LicenseDtoScopeEnum[keyof typeof LicenseDtoScopeEnum];
export const LicenseDtoStatusEnum = {
    Active: 'ACTIVE',
    Expired: 'EXPIRED',
    Expiring: 'EXPIRING',
    Suspended: 'SUSPENDED',
    Revoked: 'REVOKED',
    Banned: 'BANNED',
    Inactive: 'INACTIVE'
} as const;

export type LicenseDtoStatusEnum = typeof LicenseDtoStatusEnum[keyof typeof LicenseDtoStatusEnum];


