import React from "react";

const ReactExceptionIcon: React.FC = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11950_25356)">
        <path
          d="M89.0832 27.2416L75.2664 13.4248C74.252 12.4104 72.9031 11.8516 71.4684 11.8516H45.8516C45.0426 11.8516 44.3867 12.5074 44.3867 13.3164C44.3867 14.1254 45.0426 14.7812 45.8516 14.7812H71.4684C71.5199 14.7812 71.5705 14.7855 71.6215 14.7887V27.4684C71.6215 29.3529 73.1549 30.8863 75.0395 30.8863H87.7191C87.7223 30.9373 87.7266 30.9881 87.7266 31.0395V68.5418C87.7266 68.8143 87.5482 68.9367 87.442 68.9855C87.3357 69.0344 87.1268 69.0896 86.9201 68.9121L79.8668 62.8496C79.2531 62.3223 78.3285 62.3922 77.8012 63.0057C77.2738 63.6191 77.3436 64.5439 77.9572 65.0713L85.0105 71.1338C85.6486 71.682 86.4314 71.9654 87.2281 71.9654C87.7117 71.9654 88.2006 71.8609 88.6645 71.6479C89.8932 71.0838 90.6562 69.8936 90.6562 68.5416V31.0395C90.6562 29.6049 90.0977 28.2561 89.0832 27.2416ZM74.5512 27.4684V16.8527L85.6551 27.9566H75.0395C74.7703 27.9566 74.5512 27.7377 74.5512 27.4684Z"
          fill="#7C7C7C"
        />
        <path
          d="M24.9412 35.043C24.1322 35.043 23.4764 35.6988 23.4764 36.5078V60.3902C23.4764 61.4445 23.9516 62.4234 24.7801 63.0758L36.0104 71.9166C36.6629 72.4303 37.4531 72.684 38.242 72.684C39.1406 72.684 40.0371 72.3547 40.725 71.7055L50.3166 62.6553C50.5729 62.4133 50.9791 62.4082 51.2416 62.6432L56.4703 67.3238C57.9301 68.6303 60.2006 68.524 61.5317 67.0865L69.4018 58.5863C69.6529 58.3152 70.069 58.2916 70.349 58.5322L73.3072 61.0748C73.9209 61.6021 74.8457 61.5322 75.3731 60.9187C75.9004 60.3053 75.8305 59.3805 75.217 58.8531L72.2588 56.3103C70.7779 55.0377 68.5789 55.1631 67.2524 56.5957L59.382 65.0959C59.1301 65.368 58.7006 65.3881 58.4246 65.1408L53.1959 60.4604C51.8084 59.2182 49.6606 59.2463 48.3061 60.5244L38.7145 69.5746C38.4701 69.8055 38.0865 69.8227 37.8225 69.6146L26.5922 60.7738C26.4738 60.6805 26.4059 60.5406 26.4059 60.39V36.5078C26.4061 35.6988 25.7504 35.043 24.9412 35.043Z"
          fill="#7C7C7C"
        />
        <path
          d="M89.4664 80.1148L72.799 65.7892C71.3183 64.5167 69.1191 64.6419 67.7926 66.0745L59.8312 74.6732C59.5793 74.945 59.1498 74.9652 58.8738 74.7179L53.318 69.7445C51.9305 68.5023 49.7826 68.5304 48.4281 69.8085L39.1183 78.5927C38.874 78.8234 38.4904 78.8404 38.2266 78.6327L29.0088 71.3763C27.9715 70.5597 26.5894 70.4107 25.4019 70.9872C24.2142 71.5638 23.4767 72.7419 23.4767 74.062V95.6056C23.4767 98.0288 25.448 100 27.8713 100H86.2617C88.685 100 90.6562 98.0288 90.6562 95.6056V82.7068C90.6562 81.7097 90.2226 80.765 89.4664 80.1148ZM87.7266 95.6056C87.7266 96.4134 87.0695 97.0704 86.2617 97.0704H27.8709C27.0631 97.0704 26.406 96.4134 26.406 95.6056V74.0617C26.406 73.7956 26.5783 73.6722 26.681 73.6224C26.7328 73.5974 26.8101 73.5697 26.901 73.5697C26.9904 73.5697 27.0926 73.5962 27.1963 73.6779L36.4139 80.9343C37.8096 82.0329 39.8365 81.9425 41.1287 80.7232L50.4385 71.939C50.6947 71.697 51.1012 71.6919 51.3635 71.9269L56.9193 76.9003C58.3791 78.2068 60.6496 78.1007 61.9807 76.663L69.942 68.0644C70.193 67.7933 70.6092 67.7697 70.8892 68.0105L87.5566 82.3361C87.6646 82.429 87.7266 82.564 87.7266 82.7064V95.6056Z"
          fill="#7C7C7C"
        />
        <path
          d="M40.5389 15.5977C40.5389 11.1781 38.6522 6.94805 35.3627 3.99238C34.7609 3.45156 33.8348 3.50117 33.2939 4.10293C32.7531 4.70469 32.8027 5.63086 33.4045 6.17148C36.0766 8.57246 37.6092 12.0082 37.6092 15.5977C37.6092 22.5828 31.9264 28.2656 24.9412 28.2656C17.9561 28.2656 12.2732 22.5828 12.2732 15.5977C12.2732 8.6125 17.9562 2.92969 24.9412 2.92969C25.9311 2.92969 26.9164 3.04395 27.8697 3.26953C28.657 3.45547 29.4463 2.96836 29.6324 2.18125C29.8186 1.39395 29.3312 0.604687 28.5441 0.418555C27.3701 0.14082 26.158 0 24.9412 0C16.3406 0 9.34375 6.99707 9.34375 15.5977C9.34375 24.1982 16.3408 31.1953 24.9414 31.1953C33.5418 31.1953 40.5389 24.1982 40.5389 15.5977Z"
          fill="#7C7C7C"
        />
        <path
          d="M28.3065 10.1605L24.9412 13.5257L21.576 10.1605C21.0039 9.58843 20.0764 9.58843 19.5043 10.1605C18.9322 10.7326 18.9322 11.6601 19.5043 12.232L22.8695 15.5972L19.5043 18.9625C18.9322 19.5345 18.9322 20.4621 19.5043 21.0339C19.7904 21.3201 20.1652 21.463 20.5402 21.463C20.9151 21.463 21.2901 21.3201 21.576 21.0339L24.9412 17.6687L28.3065 21.0339C28.5924 21.3199 28.9674 21.463 29.3422 21.463C29.717 21.463 30.092 21.3201 30.3781 21.0339C30.9502 20.4619 30.9502 19.5343 30.3781 18.9625L27.0129 15.5972L30.3781 12.232C30.9502 11.6599 30.9502 10.7324 30.3781 10.1605C29.8061 9.58843 28.8787 9.58862 28.3065 10.1605Z"
          fill="#7C7C7C"
        />
        <path
          d="M44.4385 34.9258H69.6945C70.5035 34.9258 71.1594 34.2699 71.1594 33.4609C71.1594 32.652 70.5035 31.9961 69.6945 31.9961H44.4385C43.6295 31.9961 42.9736 32.652 42.9736 33.4609C42.9736 34.2699 43.6295 34.9258 44.4385 34.9258Z"
          fill="#7C7C7C"
        />
        <path
          d="M44.4385 42.5508H69.6945C70.5035 42.5508 71.1594 41.8949 71.1594 41.0859C71.1594 40.277 70.5035 39.6211 69.6945 39.6211H44.4385C43.6295 39.6211 42.9736 40.277 42.9736 41.0859C42.9736 41.8949 43.6295 42.5508 44.4385 42.5508Z"
          fill="#7C7C7C"
        />
        <path
          d="M44.4385 50.1758H69.6945C70.5035 50.1758 71.1594 49.5199 71.1594 48.7109C71.1594 47.902 70.5035 47.2461 69.6945 47.2461H44.4385C43.6295 47.2461 42.9736 47.902 42.9736 48.7109C42.9736 49.5199 43.6295 50.1758 44.4385 50.1758Z"
          fill="#7C7C7C"
        />
        <path
          d="M69.6943 84.4424H44.4385C43.6295 84.4424 42.9736 85.0982 42.9736 85.9072C42.9736 86.7162 43.6295 87.3721 44.4385 87.3721H69.6945C70.5035 87.3721 71.1594 86.7162 71.1594 85.9072C71.1594 85.0982 70.5033 84.4424 69.6943 84.4424Z"
          fill="#7C7C7C"
        />
      </g>
      <defs>
        <clipPath id="clip0_11950_25356">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReactExceptionIcon;
