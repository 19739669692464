import React from "react";
import { Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import ChatBox, { ChatBoxProps } from "src/pages/Projects/AIGuide/common/ChatBox";

interface IProps {
  name: string;
  isExpandDisabled?: boolean;
  icon?: React.JSX.Element;
  onExpandToggle: () => void;
  isAddedToRecipe?: boolean;
  chatBoxProps?: Partial<ChatBoxProps>;
}

const useStyles = makeStyles({
  collapsedBox: {
    cursor: "pointer",
    padding: "0px",
    gap: "16px"
  },
  collapsedTitle: {
    color: "#4646B5",
    fontWeight: 400
  }
});

const AskAICollapsed: React.FC<IProps> = (props) => {
  const { name, icon, isExpandDisabled, onExpandToggle, chatBoxProps } = props;
  const classes = useStyles();

  return (
    <ChatBox {...chatBoxProps}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={isExpandDisabled ? undefined : onExpandToggle}
        className={classes.collapsedBox}>
        <Typography
          variant="subtitle2"
          className={classes.collapsedTitle}
          data-testid="ai-chatbox-title">
          {name}
        </Typography>
        {icon ? (
          icon
        ) : (
          <IconButton size="small" style={{ padding: "0px" }}>
            <ExpandMore fontSize="small" data-testid="expandMoreBtn" />
          </IconButton>
        )}
      </Grid>
    </ChatBox>
  );
};

export default AskAICollapsed;
