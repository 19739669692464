import { makeStyles } from "@material-ui/core/styles";

import backgroundTopImg from "src/assets/images/background-top.svg";
import backgroundBottomImg from "src/assets/images/background-bottom.svg";

const useStyles = makeStyles({
  containerBackground: {
    backgroundImage: `url(${backgroundTopImg}), url(${backgroundBottomImg})`,
    backgroundSize: "100% auto, 100% auto",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: "right top, right bottom"
  },
  headerContainer: {
    position: "sticky",
    top: 64,
    margin: "0 10%",
    zIndex: 1
  },
  root: {
    display: "flex",
    marginTop: 64,
    flexDirection: "column",
    padding: "0 10% 32px 10%",
    width: "100%",
    // New UX change
    // The value 96px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 46px).
    height: "calc(100vh - 228px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  title: {
    color: "#003656",
    fontSize: "32px",
    lineHeight: "37.5px",
    paddingBottom: "4px"
  },
  topActions: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    marginLeft: "auto"
  }
});

export default useStyles;
