import { deleteAPI } from "src/utils/apiService";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  id: string;
}

const useDeleteSecret = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ id }) => {
      const data = await deleteAPI(`/secret/${id}`, {});

      return { data };
    }
  });
};

export default useDeleteSecret;
