import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";

const useStyles = makeStyles({
  viewDataContentContainer: {
    overflow: "auto",
    height: ({ height }: { height?: string }) => height || "calc(100vh - 166px)",
    borderRadius: "0px 0px 4px 4px"
  }
});

const RecipeInputDatasetTable = ({
  isLoading,
  entityFeatures,
  currentEntityData,
  maxHeight,
  height,
  ...rest
}: {
  isLoading: boolean;
  entityFeatures: $TSFixMe;
  currentEntityData: $TSFixMe;
  maxHeight?: string;
  height?: string;
  onColumnChange?: any;
  hideCount?: boolean;
  showSample?: boolean;
}) => {
  const classes = useStyles({ height: height || maxHeight });
  return (
    <Grid
      id="viewDataContainer"
      className={classes.viewDataContentContainer}
      data-testid="recipeInputDatasetTable">
      <ViewDataData
        {...rest}
        isLoadingData={isLoading}
        responseData={currentEntityData || { columns: [], rows: [] }}
        entityFeatures={entityFeatures}
        maxHeight={maxHeight}
      />
    </Grid>
  );
};

export default React.memo(RecipeInputDatasetTable);
