import React from "react";

// Packages
import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";

// MUI
import { ThemeProvider } from "@material-ui/core/styles";

import { useToastsStore } from "src/store/store";
import { toastContentGetter } from "src/store/store.selectors";

// Components
import ReactException from "src/components/Errors/ReactException";
import { ErrorMessageModal } from "./pages/common/ErrorMessageModal";

// Styles
import { oldTheme } from "src/styles";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./AuthProvider";

type Props = {
  children: React.ReactNode;
};

// This utility will be used in the content-section (with nav-bars) of private-routes and body-section of public routes.
export const SentryProvider = ({ children }: Props) => {
  return (
    <Sentry.ErrorBoundary
      onError={(e) => console.error(e)}
      fallback={({ resetError }) => <ReactException onClick={resetError} />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

const AppProviders = ({ children }: Props) => {
  // Stores - STARTS >>
  const toastContentStore = useToastsStore(toastContentGetter);
  // << ENDS - Stores

  return (
    <ThemeProvider theme={oldTheme}>
      <AuthProvider>
        {/* Global components - STARTS >> */}
        <ToastContainer
          closeOnClick
          // position "bottom-center" allows us to have the correct slide in animation
          position="bottom-center"
          newestOnTop={false}
          hideProgressBar
          className="Toastify__toast-container-custom"
          bodyClassName="Toastify__toast-body-custom"
        />
        {/* << ENDS - Global components */}
        {!!toastContentStore && <ErrorMessageModal />}
        {children}
      </AuthProvider>
    </ThemeProvider>
  );
};

export default AppProviders;
