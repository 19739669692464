import { useMemo } from "react";

import useStoreSelectors from "./useStoreSelectors";
import { DatasetKeys, OntologyDatasetStatuses } from "../utils/Dataset.constants";

const useDatasetsObserver = () => {
  // Stores - STARTS >>
  const { datasetDatasetsStore } = useStoreSelectors();
  // << ENDS - Stores

  // Observable facets - STARTS >>
  const isExistingDatasetNames = useMemo(
    () =>
      (Array.isArray(datasetDatasetsStore) ? datasetDatasetsStore : [])?.some(
        (eachDataset: $TSFixMe) => eachDataset?.[DatasetKeys.IsExisting]
      ) || false,
    [datasetDatasetsStore]
  );

  const isInvalidDatasetNames = useMemo(
    () =>
      (Array.isArray(datasetDatasetsStore) ? datasetDatasetsStore : [])?.some(
        (eachDataset: $TSFixMe) =>
          eachDataset?.[DatasetKeys.IsValid] !== undefined
            ? !eachDataset?.[DatasetKeys.IsValid]
            : false
      ) || false,
    [datasetDatasetsStore]
  );

  const isEmptyDatasetNames = useMemo(
    () =>
      (Array.isArray(datasetDatasetsStore) ? datasetDatasetsStore : [])?.some(
        (eachDataset: $TSFixMe) => (eachDataset?.[DatasetKeys.Name] || "")?.trim()?.length === 0
      ) || false,
    [datasetDatasetsStore]
  );

  const isOntologyDatasetsUpdated = useMemo(
    () =>
      (Array.isArray(datasetDatasetsStore) ? datasetDatasetsStore : [])?.every(
        (eachDataset: $TSFixMe) =>
          eachDataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
          OntologyDatasetStatuses.Updated
      ) || false,
    [datasetDatasetsStore]
  );
  // << ENDS - Observable facets

  return {
    isExistingDatasetNames,
    isInvalidDatasetNames,
    isEmptyDatasetNames,
    isOntologyDatasetsUpdated
  };
};

export default useDatasetsObserver;
