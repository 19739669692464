import queryString from "query-string";

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const fetchUpdatedStreamlitURL = (redirectUrl: any, streamlitToken: any, token: any) => {
  const cleanedUrl = checkAndFetchRelativeUrl(redirectUrl);
  const [pathname, searchParams] = cleanedUrl.split("?");
  const updatedParams = updatedSearchParams(searchParams, { streamlitToken, token });
  return `${pathname}?${updatedParams}`;
};

export const cleanLocationParams = (location: any) => {
  const parsedParams = queryString.parse(location.search);
  if (!parsedParams?.includeStreamlitAuth) return location;
  if (parsedParams?.includeStreamlitAuth) {
    delete parsedParams.includeStreamlitAuth;
  }
  if (parsedParams?.redirectUrl) {
    delete parsedParams.redirectUrl;
  }
  if (parsedParams?.tenantId) {
    delete parsedParams.tenantId;
  }
  return { pathname: location.pathname, search: queryString.stringify(parsedParams) };
};

export const fetchRedirectUrlPostSignIn = (location: any, token: string) => {
  const parsedParams = queryString.parse(location.search);
  const redirectUrl = parsedParams?.redirectUrl || "";
  const includeAuth = Boolean(parsedParams?.includeAuth);

  // Ignore multiple params & invalid URL formats
  if (!redirectUrl || typeof redirectUrl !== "string") {
    const updatedParams = updatedSearchParams(parsedParams, { token }, includeAuth);
    return { pathname: "/", search: `?${updatedParams}` };
  }
  const isNotebookRedirect = redirectUrl?.includes("/notebooks");

  const cleanedUrl = checkAndFetchRelativeUrl(redirectUrl);
  const [pathname, searchParams] = cleanedUrl.split("?");
  const updatedParams = updatedSearchParams(searchParams, { token }, includeAuth);

  if (isNotebookRedirect && includeAuth) {
    const domain = window.location.origin;
    window.location.replace(
      `${domain}${pathname.startsWith("/") ? pathname : `/${pathname}`}?${updatedParams}`
    );
  }
  return { pathname, search: `?${updatedParams}` };
};

export const checkAndFetchRelativeUrl = (url: string): string => {
  var regex = new RegExp(URL_REGEX);
  // If absolute URL, redirect to dashboard path
  return url.match(regex) ? "/" : decodeURIComponent(url);
};

export const updatedSearchParams = (
  params: any = {},
  extraParams: any,
  shouldUpdate = true
): string => {
  if (params["invitation_details"]) {
    delete params["invitation_details"];
  }
  if (!shouldUpdate) {
    return typeof params === "string" ? params : queryString.stringify(params);
  }
  const redirectParams = queryString.parse(params);
  const redirectParamsWithToken = { ...redirectParams, ...extraParams };
  return queryString.stringify(redirectParamsWithToken);
};
