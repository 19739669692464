import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";

interface IPayload {
  projectId?: string;
  scenarioId?: string;
}

const useRunProject = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ projectId, scenarioId }: IPayload) => {
      if (!projectId || !scenarioId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectsControllerV2Api.runProject1(projectId, scenarioId)
      );
    }
  });

export default useRunProject;
