import { init as sentryInit, close as closeSentry } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export enum SentryConfig {
  ForbiddenUserNameKeyword = "automation"
}

export function initSentry(enabled = true) {
  const enableSentryIntegration = Boolean(process.env.ENABLE_SENTRY);
  const environment = process.env.ENVIRONMENT || window?.location?.hostname?.split(".")?.[0];

  if (enableSentryIntegration) {
    sentryInit({
      dsn: process.env.SENTRY_DATA_SOURCE_NAME,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      enabled,
      ...(!!environment ? { environment } : {})
    });
  }
}

export function disableSentry() {
  closeSentry() // Sentry is disabled!
    .catch((error) => {
      console.error("Failed to disable Sentry:", error);
    });
}
