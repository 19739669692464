/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Msg } from './msg';

/**
 * 
 * @export
 * @interface ScenarioRunStepResult
 */
export interface ScenarioRunStepResult {
    /**
     * 
     * @type {string}
     * @memberof ScenarioRunStepResult
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioRunStepResult
     */
    'type'?: ScenarioRunStepResultTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ScenarioRunStepResult
     */
    'status'?: ScenarioRunStepResultStatusEnum;
    /**
     * 
     * @type {Array<Msg>}
     * @memberof ScenarioRunStepResult
     */
    'msgList'?: Array<Msg>;
    /**
     * 
     * @type {number}
     * @memberof ScenarioRunStepResult
     */
    'transformRuntime'?: number;
}

export const ScenarioRunStepResultTypeEnum = {
    Transform: 'TRANSFORM',
    Recipe: 'RECIPE'
} as const;

export type ScenarioRunStepResultTypeEnum = typeof ScenarioRunStepResultTypeEnum[keyof typeof ScenarioRunStepResultTypeEnum];
export const ScenarioRunStepResultStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type ScenarioRunStepResultStatusEnum = typeof ScenarioRunStepResultStatusEnum[keyof typeof ScenarioRunStepResultStatusEnum];


