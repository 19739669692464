import { map } from "lodash";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";

const QUERY_KEY_MODEL_PARENT_CANVAS = "query-key-model-parent-canvas";

export interface Node {
  id: string;
  stepId: string;
  deeplinkId: string;
  name: string;
  groupName: string;
  displayName: string;
  type: string;
  column: number;
  row: number;
  status: string;
  root: boolean;
}

export interface IParentNodeDetails {
  id: string;
  name: string;
  displayName: string;
  type: string;
  root: boolean;
}

const useGetModelParentCanvas = (
  projectId?: string,
  entityId?: string,
  options?: UseQueryOptions<IParentNodeDetails[]>
): UseQueryResult<IParentNodeDetails[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_MODEL_PARENT_CANVAS, projectId, entityId],
    queryFn: async () => {
      if (!projectId || !entityId) {
        throw "error";
      }

      const data = await api.fetchResponse(
        async () =>
          await api.ProjectsControllerV2Api.getAllModelsWithInputDataSets1(projectId, entityId)
      );

      return map(data.nodes as any, ({ id, name, displayName, type, root }) => ({
        id,
        name,
        displayName,
        type,
        root
      }));
    },
    ...options,
    enabled: !!projectId && !!entityId,
    refetchOnMount: true
  });
};

export default useGetModelParentCanvas;
export { QUERY_KEY_MODEL_PARENT_CANVAS };
