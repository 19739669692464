import React, { useState } from "react";

// MUI
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { ArrowDownIcon } from "src/icons/NewUX/ArrowDownIcon";

// Utils
import { isDrawerVariant } from "./SubTopNavBar.helpers";

// Types
import { MoreOption, StyleProps, SubTopNavBarRightSectionProps } from "./SubTopNavBar.types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "auto",
    marginLeft: "auto",
    columnGap: 10,
    paddingRight: (props: StyleProps) =>
      isDrawerVariant(props?.variant) ? theme.spacing(2) : theme.spacing(3)
  },
  moreAction: {
    minHeight: 28,
    padding: theme.spacing(2)
  },
  menuItemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(1)
  },
  loader: { color: theme.palette.text.secondary }
}));

const SubTopNavBarRightSection = (props: SubTopNavBarRightSectionProps) => {
  const { variant, onDrawerClose, component, moreOptions, moreActionWidth } = props || {};

  const theme = useTheme();
  const classes = useStyles({ variant });

  const [nestedComponent, setNestedComponent] = useState<React.ReactNode | null>(null);

  // Drop-down - STARTS >>
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
    setNestedComponent(null);
  };
  // ENDS - Drop-down

  return (
    <Grid container wrap="nowrap" alignItems="center" className={classes.root}>
      {component}

      {!!moreOptions && (
        <>
          {isDrawerVariant(variant) ? (
            <IconButton
              color="primary"
              size="small"
              onClick={onOpen}
              data-testid="subTopNavBarMoreAction">
              <MoreVertRoundedIcon opacity={0.5} />
            </IconButton>
          ) : (
            <Button
              color="primary"
              className={classes.moreAction}
              onClick={onOpen}
              data-testid="subTopNavBarMoreAction">
              <ArrowDownIcon color={theme.palette.text.primary} opacity={0.3} />
            </Button>
          )}

          {Boolean(anchorEl) && (
            <Menu
              open
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              onClose={onClose}
              PaperProps={{
                style: {
                  marginTop: 6,
                  width: moreActionWidth ?? 225,
                  borderRadius: theme.spacing(1.5)
                }
              }}>
              {Boolean(nestedComponent) && (
                <MenuItem onClick={() => setNestedComponent(null)}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <ChevronLeftIcon />
                  </ListItemIcon>
                </MenuItem>
              )}

              {Boolean(nestedComponent) && nestedComponent}

              {!Boolean(nestedComponent) &&
                moreOptions?.map((moreOption: MoreOption, index: number) => (
                  <Tooltip key={index} title={!!moreOption?.tooltip ? moreOption?.tooltip : ""}>
                    <span>
                      <MenuItem
                        disabled={!!moreOption?.isDisabled || !!moreOption?.isLoading}
                        onClick={() => {
                          if (!!moreOption?.nestedComponent) {
                            setNestedComponent(moreOption?.nestedComponent);
                          } else {
                            moreOption?.action();
                            !!moreOption?.isCloseMoreAction && onClose();
                          }
                        }}
                        data-testid={moreOption?.testId || "subTopNavBarMoreOptionMenuItem"}>
                        <ListItemIcon className={classes.menuItemIcon}>
                          {moreOption?.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={moreOption?.label}
                          primaryTypographyProps={{ variant: "body2" }}
                        />
                        {!!moreOption?.isLoading && (
                          <ListItemSecondaryAction>
                            <CircularProgress
                              size={18}
                              color="inherit"
                              className={classes.loader}
                            />
                          </ListItemSecondaryAction>
                        )}
                        {!!moreOption?.nestedComponent && (
                          <ListItemSecondaryAction>
                            <ChevronRightIcon fontSize="small" />
                          </ListItemSecondaryAction>
                        )}
                      </MenuItem>
                    </span>
                  </Tooltip>
                ))}
            </Menu>
          )}
        </>
      )}

      {isDrawerVariant(variant) && (
        <IconButton size="small" color="primary" onClick={onDrawerClose}>
          <CloseRoundedIcon opacity={0.5} />
        </IconButton>
      )}
    </Grid>
  );
};

export default SubTopNavBarRightSection;
