import React, { useMemo } from "react";
import _ from "lodash";

import EdaCharts, { EDA_CHART_LIMIT } from "./EdaCharts";
import EdaChartsDefault from "./EdaChartsDefault";
import useGenerateEdaCharts from "hooks/api/entities/useGenerateEdaCharts";
import useGetEdaCharts from "hooks/api/entities/useGetEdaCharts";
import { Spinner } from "src/components";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { useProjectsStore } from "src/store/store";

interface IProps {
  itemId?: string;
  scenarioId?: string;
  jobRunId?: string;
  projectId?: string;
}

const EdaChartsWrapper: React.FC<IProps> = (props) => {
  const { itemId, scenarioId, jobRunId, projectId } = props;

  const [generatingEdaChartsEntities, setGeneratingEdaChartsEntities] = useProjectsStore(
    (state) => [state.generatingEdaChartsEntities, state.setGeneratingEdaChartsEntities]
  );

  const isAlreadyGenerating = useMemo(
    () => _.includes(generatingEdaChartsEntities, itemId),
    [generatingEdaChartsEntities, itemId]
  );

  const { data, isLoading } = useGetEdaCharts(itemId, scenarioId, jobRunId);

  const generateEdaCharts = useGenerateEdaCharts();

  const handleGenerate = (limit: number) => {
    checkEnvRelaunch(projectId);
    setGeneratingEdaChartsEntities(itemId ? _.uniq([...generatingEdaChartsEntities, itemId]) : []);
    generateEdaCharts.mutate({
      noOfCharts: limit,
      itemId,
      async: true,
      scenarioId,
      projectRunEntryId: jobRunId,
      triggerOnError: true,
      generateAdditionalCharts: limit > EDA_CHART_LIMIT
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (data && !_.isEmpty(data)) {
    return (
      <EdaCharts
        data={data}
        isLoading={generateEdaCharts.isLoading || isAlreadyGenerating}
        onGenerate={handleGenerate}
      />
    );
  }

  return (
    <EdaChartsDefault
      isLoading={generateEdaCharts.isLoading || isAlreadyGenerating}
      onGenerate={handleGenerate}
    />
  );
};

export default EdaChartsWrapper;
