import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from "@tanstack/react-query";
import { omit } from "lodash";

import { EntityDataRequestDto } from "openapi/Models";

import api from "src/utils/AxiosClient";
import { EntityDataDto } from "openapi/Models/entity-data-dto";

const QUERY_KEY_ENTITY_DATA = "query-key-entity-data";

const useGetEntityData = (
  entityId: string,
  scenarioId?: string,
  jobRunId?: string,
  options?: UseQueryOptions<EntityDataDto>
): UseQueryResult<EntityDataDto> => {
  return useQuery({
    queryKey: [QUERY_KEY_ENTITY_DATA, entityId, scenarioId, jobRunId],
    queryFn: async () => {
      const response = await api.fetchResponse(
        async () =>
          await api.EntityControllerApi.getEntityData(entityId, {
            scenarioId,
            projectRunEntryId: jobRunId,
            rowsStart: 0,
            rowsEnd: 100
          })
      );
      return response;
    },
    ...options
  });
};

interface GetDatasetsPostPayload extends EntityDataRequestDto {
  datasetId: string;
}

export const useGetDatasetDataPost = (): UseMutationResult<
  EntityDataDto,
  unknown,
  GetDatasetsPostPayload,
  unknown
> =>
  useMutation({
    mutationFn: async (payload: GetDatasetsPostPayload) =>
      await api.fetchResponse(
        async () =>
          await api.EntityControllerApi.getEntityData(
            payload?.datasetId,
            omit(payload, "datasetId")
          )
      )
  });

export default useGetEntityData;

export { QUERY_KEY_ENTITY_DATA };
