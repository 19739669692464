import React from "react";

export const NotebookIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 2} ${height + 2}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5312 1.90625H0.46875C0.209839 1.90625 0 2.11609 0 2.375V13.625C0 13.8839 0.209839 14.0938 0.46875 14.0938H15.5312C15.7902 14.0938 16 13.8839 16 13.625V2.375C16 2.11609 15.7902 1.90625 15.5312 1.90625ZM15.0625 3.78125H3.78125V2.84375H15.0625V3.78125ZM2.84375 2.84375V3.78125H0.9375V2.84375H2.84375ZM0.9375 13.1562V4.71875H15.0625V13.1562H0.9375Z"
      fill={color}
    />
    <path
      d="M11.1439 6.73108C10.9609 6.54797 10.6641 6.54797 10.4811 6.73108C10.298 6.91418 10.298 7.21094 10.4811 7.39392L12.0245 8.9375L10.4811 10.4811C10.298 10.6642 10.298 10.9609 10.4811 11.1439C10.6641 11.327 10.9609 11.327 11.1439 11.1439L13.0189 9.26892C13.202 9.08594 13.202 8.78918 13.0189 8.60608L11.1439 6.73108Z"
      fill={color}
    />
    <path
      d="M5.51892 6.73108C5.33594 6.54797 5.03906 6.54797 4.85608 6.73108L2.98108 8.60608C2.79797 8.78918 2.79797 9.08594 2.98108 9.26892L4.85608 11.1439C5.03906 11.327 5.33582 11.327 5.51892 11.1439C5.70203 10.9609 5.70203 10.6642 5.51892 10.4811L3.97546 8.9375L5.51892 7.39392C5.70203 7.21094 5.70203 6.91406 5.51892 6.73108Z"
      fill={color}
    />
    <path
      d="M9.08584 5.6804C8.84023 5.59861 8.57485 5.7313 8.49294 5.97691L6.61794 11.6019C6.53604 11.8475 6.66885 12.1129 6.91445 12.1948C7.16006 12.2767 7.42544 12.1439 7.50735 11.8983L9.38235 6.27329C9.46426 6.02769 9.33145 5.76231 9.08584 5.6804Z"
      fill={color}
    />
  </svg>
);
