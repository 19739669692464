import React from "react";

// Utils
import { toastWrapper } from "src/utils/toastWrapper";

// Hooks
import useDeleteSegment from "src/hooks/api/projects/useDeleteSegment";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { SegmentDeletePromptDetails, SegmentsHelperText } from "../Segments.constants";

type Props = {
  deletingSegmentId: string;
  resetDeleteSegment: () => void;
  refetch: () => void;
};

const DeleteSegment = (props: Props) => {
  const { deletingSegmentId, resetDeleteSegment, refetch } = props || {};

  const {
    isLoading: isSegmentDeleting,
    mutateAsync: deleteSegmentMutation,
    reset: resetDeleteSegmentMutation
  } = useDeleteSegment();

  const deleteSegment = async () => {
    resetDeleteSegmentMutation();

    await deleteSegmentMutation(
      { segmentId: deletingSegmentId },
      {
        onSuccess: async () => {
          toastWrapper({ type: "success", content: SegmentsHelperText.SegmentDeleted });
          await refetch();
        },
        onSettled: () => {
          resetDeleteSegment();
        }
      }
    );
  };

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={SegmentDeletePromptDetails.title}
      content={[SegmentDeletePromptDetails.messageLine1, SegmentDeletePromptDetails.messageLine2]}
      onClose={resetDeleteSegment}
      onSubmit={deleteSegment}
      isCancelDisabled={isSegmentDeleting}
      isSubmitDisabled={isSegmentDeleting}
      isSubmitting={isSegmentDeleting}
    />
  );
};

export default DeleteSegment;
