export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.addEventListener("message", (e) => {
    const { entityId, scenarioId, authToken, domain } = e.data;

    const headers = { Authorization: `Bearer ${authToken}` };

    fetch(`${domain}/api/v2/entities/${entityId}/eda?async=true`, {
      method: "POST",
      headers: {
        ...headers,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        scenarioId,
        includeHtml: false
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((result) => {
        postMessage({ entityId, entityUrl: result.url });
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
