import React from "react";
import {
  ArtifactMini,
  Entities,
  EntityFeaturesResponse,
  ModelMini,
  Recipe,
  Scenario
} from "src/types";
import { AskAIChatResponseType, OUTPUT_TYPE } from "./CodeRecipeContextProvider";

type MyContextData = {
  jobData?: $TSFixMe;
  jobRunData?: $TSFixMe;
  recipe: Recipe | null;
  inputDatasets: Entities;
  scenarioData: $TSFixMe;
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  setEntityFeaturesMap: (features: { [id: string]: EntityFeaturesResponse } | null) => void;
  allEntities: Entities | undefined;
  allInputEntities: Entities | undefined;
  handleInputDatasetsChange: (
    entities: Entities,
    artifacts?: ArtifactMini[],
    models?: ModelMini[]
  ) => void;
  handleUpdateRecipe: (props: {
    payload: $TSFixMe;
    onError?: () => void;
    onSuccess?: (recipe: Recipe) => void;
  }) => void;
  recipeId: string | undefined;
  isSaveDisabled: boolean;
  isRunDisabled: boolean;
  isAddRecipeToQueue: boolean;
  isAddingRecipeToQueue: boolean;
  isTestDisabled: boolean;
  saveToolTip: string | undefined;
  runTooltip: string | undefined;
  testTooltip: string | undefined;
  isRunInProgress: boolean;
  isTestInProgress: boolean;
  isSaveInProgress: boolean;
  handleSave: () => void;
  handleAsyncSave: () => Promise<void>;
  handleRun: () => void;
  handleTest: (sampleRows?: number) => void;
  onAddCodeToRecipe: (payload: {
    code: string;
    codeHistoryId: string;
    onSuccess: (transformId: string) => void;
  }) => void;
  onRemoveCodeFromRecipe: (payload: { transformId: string; onSuccess: () => void }) => void;
  onUpdateRecipe: (recipe: Recipe) => void;
  isAskAiRecipeUpdateInProgress: boolean;
  previewTabs: $TSFixMe;
  setPreviewTabs: $TSFixMeFunction;
  setScenarioData: (scenario: Scenario) => void;
  editorValue: string;
  setEditorValue: (editorValue: string) => void;
  setInputDatasets: (inputDatasets: Entities) => void;
  setAllEntities: (entities: Entities) => void;
  responses: Array<AskAIChatResponseType>;
  setResponses: $TSFixMeFunction;
  onAutoGenerateCode: $TSFixMeFunction;
  isAutoGenerateInProgress: boolean;
  inputNames: string[];
  pinnedNames: string[];
  outputType: OUTPUT_TYPE;
  setInputNames: (names: string[]) => void;
  setPinnedNames: (pinnedNames: string[]) => void;
  setOutputType: (outputType: OUTPUT_TYPE) => void;
  isAutoGeneratedSuccess: boolean;
  isSelectedEntitiesUpdateInProgess: boolean;
  autoGenerateQueryInputNames: string[];
  autoGenerateQueryUserInput: string;
  setGenerateQueryLimit: (limit: number | undefined) => void;
  generateQueryLimit: number | undefined;
  codeErrorDetails: {
    lineNo: number;
    line: string;
  } | null;
  reset: () => void;
  isQueryAssociatedWithRecipe: (identifier: string) => boolean;
  isDeletableRecipe: boolean;
  allDatasets: any[];
  allRecipeDatasets: any[];
  allRecipeCharts: any[];
  allRecipeModels: any[];
  isGetThreadsLoading: boolean;
  newAskAIFlow: boolean;
  isFetchingChats: boolean;
  threadId: string | undefined;
  fetchSuggestionsApiInfo: {
    isFetchingSuggestions: boolean;
    fetchingSuggestionsQueryReset: () => void;
    fetchSuggestionsQueryInputNames: string[];
    fetchSuggestionsUserInput: string;
  };
  fetchSuggestions: (response: { payload: any }) => void;
  isRetryInProgress: boolean;
  setIsRetryInProgress: (isRetryInProgress: boolean) => void;
  autoGenerateOutputType: string;
  selectedArtifacts: Array<ArtifactMini>;
  selectedModels: Array<ModelMini>;
  setSelectedArtifacts: (artifacts: ArtifactMini[]) => void;
  setSelectedModels: (models: ModelMini[]) => void;
  setSelectedCodeRecipe: (selectedCodeRecipe: Record<string, string>[]) => void;
  selectedCodeRecipe: Record<string, string>[];
  setCurrentSelectedCodeRecipe: (selectedCodeRecipe?: string) => void;
  currentSelectedCodeRecipe?: string;
  isAutoSaving: boolean;
};

//@ts-expect-error
export const CodeRecipeContext = React.createContext<MyContextData>();
