import { RecipeRunDataStatusEnum } from "openapi/Models/recipe-run-data";

export enum QueuedRecipeRunsConfig {
  MaxNoOfRecipeRunsInClient = 10,
  MaxNoOfRecipeRunsInScroll = 15
}

export const statusNameMap: { [key: string]: string } = {
  [RecipeRunDataStatusEnum.Unbuilt]: "Unbuilt",
  [RecipeRunDataStatusEnum.InQueue]: "In Queue",
  [RecipeRunDataStatusEnum.Running]: "Running",
  [RecipeRunDataStatusEnum.Success]: "Success",
  [RecipeRunDataStatusEnum.Error]: "Error"
};

export const QueuedRecipeTheme = {
  backgroundColor: "#e6e6ff", // Light Purple color
  [RecipeRunDataStatusEnum.Unbuilt]: {
    backgroundColor: "#b6b7b950" // Light Ash color
  },
  [RecipeRunDataStatusEnum.InQueue]: {
    backgroundColor: "#e6e6ff" // Light Purple color
  },
  [RecipeRunDataStatusEnum.Running]: {
    backgroundColor: "#ffedae" // Light Yellow color
  },
  [RecipeRunDataStatusEnum.Success]: {
    backgroundColor: "#cdffcf" // Light Green color–
  },
  [RecipeRunDataStatusEnum.Error]: {
    backgroundColor: "#ffdfdf" // Light Red color
  }
};

export enum RecipeRunsHelperText {
  RecipeQueue = "Recipe Queue",
  CurrentScenario = "Current Scenario",
  ClearAll = "Clear all",
  Unknown = "Unknown",
  NoDataFoundMessage = "Queue is empty. No Recipes added to the queue yet.",
  More = "More"
}
