import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

import { Carousel } from "src/components";
import dataCleaningImgSrc from "src/assets/images/data-cleaning.svg";
import featureEngSrc from "src/assets/images/feature-engineering.svg";
import modalTrainingSrc from "src/assets/images/modal-training.svg";
import modalEvaluationSrc from "src/assets/images/modal-evaluation.svg";

import { AutoMLRecipeRunningImage } from "./AutoMLRecipeRunningImage";

const useStyles = makeStyles((theme) => ({
  recipeRunningContainer: {
    width: "100%",
    height: "100%",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    background: "#F8FAFB",
    boxShadow: theme.shadows[3],
    borderRadius: "6px",
    border: "1px solid #C8C8C8"
  },
  almostThereImg: {
    width: "100px",
    height: "100px"
  }
}));

export const AutoMLRecipeRunningView = () => {
  const classes = useStyles();
  const images = [
    {
      imgSrc: dataCleaningImgSrc,
      title: "1. Data Cleaning & Pre-processing",
      description: `The Data Cleaning Step is a critical phase in the machine learning pipeline designed to enhance the quality of your dataset. This process identifies and rectifies inconsistencies, missing values, and outliers in your data. By ensuring that your dataset is accurate and uniform, the Data Cleaning Step not only improves the reliability of your model's predictions but also optimises its training process. A clean dataset is foundational for effective machine learning, and this step paves the way for robust model performance.`
    },
    {
      imgSrc: featureEngSrc,
      title: "2. Feature Engineering",
      description: `The Feature Engineering Step is a pivotal stage in the machine learning workflow that focuses on enhancing the dataset for optimal model performance. Through this process, existing features are transformed, combined, or even expanded upon to create new ones that can provide more significant insights for predictive modeling. By tailoring the dataset to better highlight underlying patterns and relationships, Feature Engineering not only maximizes the model's predictive power but also often leads to faster training and more interpretable results.`
    },
    {
      imgSrc: modalTrainingSrc,
      title: "3. Model Training and Selection",
      description: `The Model Training and Selection Step is the heart of the machine learning process where algorithms learn from the prepared dataset. In this phase, various algorithms are trained using the data, allowing them to understand and capture the underlying patterns. Post-training, these models are evaluated based on specific performance metrics. The best-performing model, which offers a balance between accuracy and generalisation, is then selected. This comprehensive approach ensures that the chosen model not only fits the data well but also performs effectively on unseen data, ensuring reliable predictions.`
    },
    {
      imgSrc: modalEvaluationSrc,
      title: "4. Model Evaluation",
      description: `The Model Evaluation Step rigorously assesses the performance of the trained machine learning model using various metrics and test datasets. This step ensures that the model's predictions align closely with actual outcomes and checks its robustness against overfitting. By comparing results across different metrics such as accuracy, precision, recall, and more, the Model Evaluation provides a comprehensive understanding of the model's strengths and areas for improvement. It's a critical phase that validates the model's reliability and readiness for deployment in real-world scenarios.`
    }
  ];
  const children = [
    images.map((image, index) => <AutoMLRecipeRunningImage key={index} {...image} />)
  ];
  return (
    <Grid container direction="column" className={classes.recipeRunningContainer}>
      <Carousel autoPlay interval={3000} showThumbs={false} showStatus={false} infiniteLoop>
        {/* @ts-expect-error */}
        {React.Children.toArray(children)}
      </Carousel>
    </Grid>
  );
};
