import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Papa from "papaparse";
import React, { useMemo, useState } from "react";
import { filter, findIndex, forEach, isEmpty, map, setWith } from "lodash";
import { makeStyles } from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import ViewDataData from "pages/ViewData/ViewDataData/ViewDataData";
import ViewDataSchema from "pages/ViewData/ViewDataSchema/ViewDataSchema";
import useEntityDataAndStats from "hooks/api/projects/useEntityDataAndStats";
import { Tabs, Tab, Spinner } from "src/components";

interface IProps {
  open: boolean;
  projectId: string;
  entityId: string;
  entityName: string;
  targetCol: string;
  onClose: () => void;
}

enum TABS {
  SampleData = "Sample Data",
  Schema = "Schema"
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    color: "#003656",
    alignItems: "center",
    marginBottom: "20px",
    fontSize: "12px",
    justifyContent: "space-between",
    fontStyle: "italic",

    "& .MuiTabs-flexContainer": {
      borderRadius: "20px",
      border: `1px solid ${theme.palette.primary.dark}`
    }
  }
}));

const SampleDataModal: React.FC<IProps> = (props) => {
  const { entityId, entityName, open, targetCol, projectId, onClose } = props;

  const [tab, setTab] = useState<TABS>(TABS.SampleData);

  const { wrapper } = useStyles();
  const { data, isLoading } = useEntityDataAndStats({
    projectId,
    entityId,

    enabled: open,
    refetchOnMount: true
  });

  const tableData = useMemo(() => {
    if (!data?.viewData) {
      return {
        columns: [],
        rows: []
      };
    }
    const targetColumnIndex = findIndex(data?.viewData?.columns, (col) => col === targetCol);
    if (targetColumnIndex === undefined) {
      return data?.viewData;
    }

    return {
      columns: filter(data?.viewData?.columns, (item) => item !== targetCol),
      rows: map(data?.viewData?.rows, ({ cells }: { cells: string[] }) => {
        const resultArray: string[] = [];
        forEach(cells, (val, index) => {
          if (index !== targetColumnIndex) {
            resultArray.push(val);
          }
        });
        return {
          cells: resultArray
        };
      })
    };
  }, [data?.viewData, targetCol]);

  const viewDataEntityFeatures = useMemo(
    () => filter(data?.entityFeatures, ({ name }) => name !== targetCol),
    [data?.entityFeatures, targetCol]
  );

  const downloadFile = (data: string, fileName: string) => {
    const blob = new Blob([data], { type: "text/csv" });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    a.click();
  };

  const handleDownload = () => {
    const modifiedData = map(tableData.rows, ({ cells }) => {
      const output = {};
      forEach(cells, (cell, index) => setWith(output, [tableData.columns[index]], cell, Object));
      return output;
    });

    const csvData = Papa.unparse(modifiedData, {
      delimiter: ",",
      header: true
    });
    downloadFile(csvData, `sample_${entityName}.csv`);
  };

  const getChildComponent = () => {
    switch (tab) {
      case TABS.SampleData:
      default:
        return (
          <ViewDataData
            hideCount={false}
            isLoadingData={isLoading}
            responseData={tableData}
            entityFeatures={[]}
            maxHeight="calc(100vh - 279px)"
          />
        );

      case TABS.Schema:
        return (
          <ViewDataSchema
            isLoading={isLoading}
            entityFeatures={viewDataEntityFeatures}
            maxHeight="calc(100vh - 279px)"
          />
        );
    }
  };

  return (
    <NewModal
      header={`Sample Input Data for ${entityName} dataset`}
      open={open}
      data-testid="predict-job-dataset-sample-dataset-modal"
      width={980}
      background="#fff"
      submitDisabled={isEmpty(tableData?.rows)}
      keepMounted={false}
      cancelButtonLabel="Close"
      submitButtonLabel="Download as CSV"
      onClose={onClose}
      onFinish={handleDownload}>
      {isLoading ? (
        <Spinner padding={100} />
      ) : (
        <>
          <div className={wrapper}>
            <span>
              <InfoOutlinedIcon fontSize="small" /> You can download and use this format for your
              new Dataset
            </span>
            <Tabs value={tab} onChange={setTab}>
              <Tab label={TABS.SampleData} value={TABS.SampleData} />
              <Tab label={TABS.Schema} value={TABS.Schema} />
            </Tabs>
          </div>
          <div id="pr-viewDataContainer">{getChildComponent()}</div>
        </>
      )}
    </NewModal>
  );
};

export default SampleDataModal;
