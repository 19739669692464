import React from "react";
import useAuthStore from "src/stores/auth.store";
import EmailPasswordSignIn from "./EmailPasswordSignIn";
import SSOSupportedSignInFlow from "./SSOSupportedSignInFlow";

const SignInFlow = () => {
  const [isNonSSOFlow] = useAuthStore((state) => [state.isNonSSOFlow]);
  if (isNonSSOFlow) {
    return <EmailPasswordSignIn />;
  }
  return <SSOSupportedSignInFlow />;
};

export default SignInFlow;
