// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Utils
import api from "utils/AxiosClient";

// Open API
import { ProjectDto } from "openapi/Models";

export const enum UseGetProjectQueryKeys {
  Project = "project"
}

interface IProjectProps extends UseQueryOptions<unknown, unknown, ProjectDto> {
  projectId?: string;
  includeJobCount?: boolean;
}

const useGetProject = (props: IProjectProps): UseQueryResult<ProjectDto> => {
  const { projectId, includeJobCount = false, ...options } = props;

  return useQuery({
    queryKey: [UseGetProjectQueryKeys.Project, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "Invalid projectId!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectControllerApi.findProjectById(projectId, includeJobCount)
      );
    },
    select: (data) => (isArray(data) ? data?.[0] : []),
    enabled: !!projectId,
    ...options
  });
};

export default useGetProject;
