import React from "react";

export const CpuIcon = ({ width = 20, height = 20, color = "#008FE4" }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83331 8.33331C5.83331 7.1548 5.83331 6.56555 6.19943 6.19943C6.56555 5.83331 7.1548 5.83331 8.33331 5.83331H11.6666C12.8451 5.83331 13.4344 5.83331 13.8006 6.19943C14.1666 6.56555 14.1666 7.1548 14.1666 8.33331V11.6666C14.1666 12.8451 14.1666 13.4344 13.8006 13.8006C13.4344 14.1666 12.8451 14.1666 11.6666 14.1666H8.33331C7.1548 14.1666 6.56555 14.1666 6.19943 13.8006C5.83331 13.4344 5.83331 12.8451 5.83331 11.6666V8.33331Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <path
        d="M10.3572 8.33331L9.16669 9.99998H10.8334L9.64285 11.6666"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33331 9.99998C3.33331 6.85728 3.33331 5.28594 4.30962 4.30962C5.28594 3.33331 6.85728 3.33331 9.99998 3.33331C13.1426 3.33331 14.7141 3.33331 15.6903 4.30962C16.6666 5.28594 16.6666 6.85728 16.6666 9.99998C16.6666 13.1426 16.6666 14.7141 15.6903 15.6903C14.7141 16.6666 13.1426 16.6666 9.99998 16.6666C6.85728 16.6666 5.28594 16.6666 4.30962 15.6903C3.33331 14.7141 3.33331 13.1426 3.33331 9.99998Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <path d="M3.33335 10H1.66669" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M18.3334 10H16.6667" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M3.33335 7.5H1.66669" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M18.3334 7.5H16.6667" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M3.33335 12.5H1.66669" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M18.3334 12.5H16.6667" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M10 16.6667V18.3334" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M10 1.66669V3.33335" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M7.5 16.6667V18.3334" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M7.5 1.66669V3.33335" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M12.5 16.6667V18.3334" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
      <path d="M12.5 1.66669V3.33335" stroke={color} strokeWidth="1.25" strokeLinecap="round" />
    </svg>
  </>
);
