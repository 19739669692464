import CachedIcon from "@material-ui/icons/Cached";
import React, { useMemo, useState } from "react";
import { Box, Button, Card, CardContent, CircularProgress, Divider } from "@material-ui/core";
import { generatePath, useNavigate } from "react-router-dom";
import { ExportIcon } from "src/icons/NewUX/ExportIcon";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";
import AddMenu from "src/pages/ViewData/AddMenu";

import { RenderText } from "src/utils";

import { useScenariosStore, useDrawerStore } from "../../../store/store";
import { sideComponentSetter } from "../../../store/store.selectors";

import { downloadEntity } from "src/api/projects";

import { EntityDetailItem } from "./components";
import DeleteNodeModal, {
  psMessage
} from "../../../pages/ViewData/DeleteNodeModal/DeleteNodeModal";
import { Spinner } from "../..";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { toastWrapper } from "src/utils/toastWrapper";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";

import DatasetReadonly from "src/pages/private/ProjectsModule/pages/Dag/components/Nodes/Dataset/DatasetReadonly";
import DestinationDetails from "pages/private/ProjectsModule/pages/OutputDataset/components/DestinationDetails";
import DrawerAbout from "./components/DrawerAbout";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import ReloadDatasetModal from "pages/ViewData/ViewDataData/ReloadDatasetModal";
import SourceDetails from "pages/private/ProjectsModule/pages/OutputDataset/components/SourceDetails";
import useEntities from "hooks/api/entities/useEntities";
import useEntityDetails from "hooks/api/entities/useEntityDetails";
import { checkIfDefaultScenario } from "src/pages/private/ProjectsModule/utils";
import { thirdPartyTypeName } from "pages/DataSources/utils/DataSources.constants";
import { useStyles as useDrawerStyles } from "./components/styling";
import Modal, { ModalVariants } from "components/custom/Modal/Modal";
import { WebPaths } from "src/routing/routes";
import _, { capitalize } from "lodash";
import { EyeIcon } from "src/icons/NewUX";
import AIGuideDialog from "src/pages/Projects/AIGuide/common/AIGuideDialog";
import MoreOptions from "src/layout/NavBars/components/SubTopNavBar/MoreOptions";
import {
  ThreadResponseDtoDatasetContextEnum,
  ThreadResponseDtoTargetTypeEnum
} from "openapi/Models/thread-response-dto";
import { AIGuideMiniButton } from "src/components/Buttons/AIGuideMiniButton";
// import RecipeIcons from "./RecipeIcons";

const EntityModalV2 = ({ data, canAddStandardRecipe }: $TSFixMe) => {
  const { cardMargin, drawerContent, content, datasetItemGap, flex } = useDrawerStyles();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState<$TSFixMe>(false);
  const [isRawDownloading, setIsRawDownloading] = React.useState<$TSFixMe>(false);
  const [open, setOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const navigate = useNavigate();
  const [scenarios] = useScenariosStore((state) => [state.scenarios]);
  const setSideComponent = useDrawerStore(sideComponentSetter);
  const isDefaultScenario = useMemo(
    () => checkIfDefaultScenario(null, scenarios, data?.scenarioId),
    [data?.scenarioId, scenarios]
  );

  const {
    data: entity,
    isLoading,
    refetch
  } = useEntities({
    id: data.itemId,
    scenarioId: data?.scenarioId,
    ...(!!data?.isJobCanvas ? { jobRunId: data?.jobProps?.jobRunId } : {}),
    options: { cacheTime: Infinity, refetchOnMount: true }
  });

  const { data: entityDetails } = useEntityDetails(
    data.itemId,
    data.scenarioId,
    data.jobProps?.jobRunId,
    {
      enabled: !!data.itemId,
      cacheTime: Infinity,
      refetchOnMount: true
    }
  );
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);

  const downloadCSV = async (raw: boolean = true) => {
    if (raw) {
      setIsRawDownloading(() => true);
      if (!data?.itemId) {
        return;
      }

      data?.projectId && checkEnvRelaunch(data?.projectId);

      try {
        let jobPayload: $TSFixMe = {};
        if (!!data?.isJobCanvas) {
          jobPayload = !!data?.jobProps?.jobRunId
            ? { projectRunEntryId: data?.jobProps?.jobRunId }
            : {};
        }

        const downloadEntityResponse: $TSFixMe = await downloadEntity({
          scenarioId: data?.scenarioId || "",
          entityId: data.itemId || "",
          downloadRaw: true,
          ...jobPayload
        });

        !!downloadEntityResponse &&
          window.open(downloadEntityResponse.replace(": //", "://"), "_blank");
      } catch (error: $TSFixMe) {
        toastWrapper({
          type: "error",
          content: "No file connected to this dataset!"
        });
      }

      setIsRawDownloading(() => false);
    } else {
      setIsDownloading(() => true);

      if (!data?.itemId) {
        return;
      }

      data?.projectId && checkEnvRelaunch(data?.projectId);

      try {
        let jobPayload: $TSFixMe = {};
        if (!!data?.isJobCanvas) {
          jobPayload = !!data?.jobProps?.jobRunId
            ? { projectRunEntryId: data?.jobProps?.jobRunId }
            : {};
        }

        const downloadEntityResponse: $TSFixMe = await downloadEntity({
          scenarioId: data?.scenarioId || "",
          entityId: data.itemId || "",
          downloadRaw: false,
          ...jobPayload
        });

        !!downloadEntityResponse &&
          window.open(downloadEntityResponse.replace(": //", "://"), "_blank");
      } catch (error: $TSFixMe) {
        toastWrapper({
          type: "error",
          content: "No file connected to this dataset!"
        });
      }

      setIsDownloading(() => false);
    }
  };

  const onAddDatasetAction = () => {
    const path = generatePath(WebPaths.AppendDataset, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId,
      datasetId: data.itemId
    });

    navigate(path);
  };

  const handleAddTransformClick = () => {
    const url = _.replace(
      WebPaths.StandardRecipeDataContainer,
      "/:groupId?",
      `?entity=${data.itemId}`
    );
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleAddCodeRecipeClick = () => {
    const url = _.replace(WebPaths.CodeRecipeContainer, "/:groupId?", `?entity=${data.itemId}`);
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleAddAutoMLRecipeClick = () => {
    const url = _.replace(WebPaths.AutoMLRecipeContainer, "/:groupId?", `?entity=${data.itemId}`);
    const path = generatePath(url, {
      projectId: data.projectId,
      scenarioId: data?.scenarioId
    });

    navigate(path);
  };

  const handleAddApiConnectorRecipe = () => {
    const url = `${WebPaths.APIConnectorRecipeContainer}?entity=${data.itemId}`;
    navigate(
      generatePath(url, {
        projectId: data.projectId,
        scenarioId: data?.scenarioId
      })
    );
  };

  const getNavigatePath = (goToSource?: boolean) => {
    if (!!data?.jobProps) {
      return generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        entityId: data.itemId,
        section: goToSource ? "source" : "data"
      });
    } else {
      return generatePath(WebPaths.ViewData, {
        projectId: data.projectId,
        scenarioId: data?.scenarioId,
        entityId: data.itemId,
        section: goToSource ? "source" : "data"
      });
    }
  };

  const goToViewData = (goToSource?: boolean) => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

    navigate(getNavigatePath(goToSource));
  };

  const onDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const isUnbuilt = useMemo(() => {
    return !entity?.status || !["BUILT"].includes(entity?.status);
  }, [entity?.status]);

  const isInHouse = useMemo(() => {
    return !!entity?.dataSourceType && entity?.dataSourceType !== thirdPartyTypeName;
  }, [entity?.dataSourceType]);

  const onClose = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const handleReload = () => {
    setOpen(true);
  };

  const handleSuccess = () => {
    setOpen(false);
  };

  const handleOpenAddFileModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const navigateToAIGuide = () => {
    setShowAIGuideDialog(true);
  };

  return (
    <NewThemeWrapper>
      <Box className={drawerContent}>
        {entity && (
          <DeleteNodeModal
            open={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            deleteNote={
              <div>
                <span>Note: </span>
                {psMessage}
              </div>
            }
            nodeId={data.itemId}
            nodeName={data.label}
            nodeType="entity"
            onAfterSubmit={onClose}
          />
        )}
        <ReloadDatasetModal
          id={data.itemId}
          name={data?.label}
          projectId={data.projectId}
          isFiveTran={entity?.dataSourceType === thirdPartyTypeName}
          open={open}
          onCancel={() => setOpen(false)}
          onSuccess={handleSuccess}
        />
        <AIGuideDialog
          open={showAIGuideDialog}
          onClose={() => {
            setShowAIGuideDialog(false);
          }}
          projectId={data?.projectId!}
          scenarioId={data?.scenarioId!}
          datasetId={data.itemId!}
          targetType={ThreadResponseDtoTargetTypeEnum.Dataset}
          datasetContext={ThreadResponseDtoDatasetContextEnum.Dataset}
        />
        {openConfirmModal && (
          <Modal
            open
            variant={ModalVariants.Delete}
            title="Add File"
            content={[
              "If you make any changes to the dataset, it will render the current runs of the associated recipe(s) with this input dataset invalid, marking them as UNBUILT. To implement the changes, it is necessary to rerun the linked recipe(s). Also the associated segment(if any) will be deleted, and any custom scenarios using it will default to the entire dataset instead of the segment.",
              "Are you sure you want to proceed with this?"
            ]}
            submitLabel="Yes, Proceed"
            onClose={handleCancel}
            onSubmit={onAddDatasetAction}
          />
        )}
        <SubTopNavBarWrapper
          variant="drawer"
          onDrawerClose={onClose}
          subTopNavBarLeftSection={{
            component: (
              <>
                <DatasetReadonly data={data} />
                <RenderText
                  color="textSecondary"
                  isOverflowTooltip
                  linkTo={getNavigatePath()}
                  data-testid="sidebar-entity-name">
                  {data?.label}
                </RenderText>
              </>
            )
          }}
        />
        <div className={content} style={{ marginTop: 44 }}>
          <Card className={cardMargin}>
            <CardContent>
              <div className={flex}>
                <div style={{ display: "flex", gap: "10px" }} className={datasetItemGap}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => goToViewData()}
                    data-testid="sidebar-entity-preview-icon"
                    startIcon={<EyeIcon viewBox="0 0 20 20" />}>
                    Preview
                  </Button>
                  {data?.isJobCanvas &&
                    !isUnbuilt &&
                    !!entityDetails?.rows &&
                    (entityDetails.fileExtension === "csv" ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        data-testid="sidebar-entity-download-csv-icon"
                        onClick={() => downloadCSV(true)}
                        disabled={isRawDownloading}
                        startIcon={
                          isRawDownloading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <ExportIcon viewBox="0 0 22 24" />
                          )
                        }>
                        Export
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          data-testid="sidebar-entity-download-csv-icon"
                          onClick={() => downloadCSV(false)}
                          disabled={isDownloading}
                          startIcon={
                            isDownloading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <ExportIcon viewBox="0 0 22 24" />
                            )
                          }>
                          Export as Csv
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          data-testid="sidebar-entity-download-raw-icon"
                          onClick={() => downloadCSV(true)}
                          disabled={isRawDownloading}
                          startIcon={
                            isRawDownloading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <ExportIcon viewBox="0 0 22 24" />
                            )
                          }>
                          {`Export as ${capitalize(entityDetails.fileExtension) ?? "Parquet"}`}
                        </Button>
                      </>
                    ))}
                  {!!isDefaultScenario && !isUnbuilt && !data?.isJobCanvas && (
                    <AIGuideMiniButton
                      width={36}
                      height={29.75}
                      viewBox="3 -6 48 48"
                      badgeStyleProps={{
                        marginTop: 2,
                        marginRight: -12,
                        marginLeft: 4,
                        width: 44
                      }}
                      onClick={navigateToAIGuide}
                      targetId={data?.itemId!}
                      projectId={data?.projectId!}
                    />
                  )}
                  {!data?.isJobCanvas && isDefaultScenario && (
                    <AddMenu
                      // Datasets
                      isAddDatasetHidden={!isUnbuilt || data?.isOutputDataset || isInHouse}
                      addDataset={handleOpenAddFileModal}
                      // Recipes
                      isUnbuilt={isUnbuilt}
                      addTemplateRecipe={handleAddTransformClick}
                      addAiAssistedRecipe={handleAddCodeRecipeClick}
                      addRapidModelRecipe={handleAddAutoMLRecipeClick}
                      onAddApiConnectorRecipe={handleAddApiConnectorRecipe}
                      canAddStandardRecipe={canAddStandardRecipe}
                      menuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center"
                        }
                      }}
                    />
                  )}
                  {/* <Divider orientation="vertical" />
                  {!data?.isJobCanvas && isDefaultScenario && (
                    <RecipeIcons
                      isUnbuilt={isUnbuilt}
                      onAddTemplateRecipe={handleAddTransformClick}
                      onAddAiAssistedRecipe={handleAddCodeRecipeClick}
                      onAddRapidModelRecipe={handleAddAutoMLRecipeClick}
                      onAddApiConnectorRecipe={handleAddApiConnectorRecipe}
                      canAddStandardRecipe={canAddStandardRecipe}
                    />
                  )} */}
                </div>
                {!data?.isJobCanvas && (
                  <div style={{ display: "flex", gap: "10px" }} className="moreIcon">
                    <Divider orientation="vertical" />

                    <MoreOptions
                      options={[
                        ...(!isUnbuilt && !!entityDetails?.rows
                          ? entityDetails.fileExtension === "csv"
                            ? [
                                {
                                  label: "Export",
                                  icon: <ExportIcon viewBox="0 0 22 24" />,
                                  action: () => downloadCSV(true),
                                  isDisabled: isRawDownloading,
                                  isLoading: isRawDownloading,
                                  testId: "sidebar-entity-export-icon"
                                }
                              ]
                            : [
                                {
                                  label: "Export as Csv",
                                  icon: <ExportIcon viewBox="0 0 22 24" />,
                                  action: () => downloadCSV(false),
                                  isDisabled: isDownloading,
                                  isLoading: isDownloading,
                                  testId: "sidebar-entity-export-icon"
                                },
                                {
                                  label: `Export as ${capitalize(entityDetails.fileExtension) ?? "Parquet"}`,
                                  icon: <ExportIcon viewBox="0 0 22 24" />,
                                  action: () => downloadCSV(true),
                                  isDisabled: isRawDownloading,
                                  isLoading: isRawDownloading,
                                  testId: "sidebar-entity-raw-export-icon"
                                }
                              ]
                          : []),
                        ...(isDefaultScenario
                          ? [
                              {
                                label: "Delete",
                                icon: <TrashIcon viewBox="0 0 20 22" />,
                                action: onDelete,
                                testId: "sidebar-entity-delete-icon"
                              }
                            ]
                          : []),
                        ...(!!data.entityDSDetails &&
                        data?.entityDSDetails?.purpose === "IMPORT" &&
                        isDefaultScenario
                          ? [
                              {
                                label: "Reload",
                                icon: <CachedIcon style={{ width: "16px", height: "16px" }} />,
                                tooltip:
                                  entity?.dataSourceType === thirdPartyTypeName
                                    ? "Synchronizes the current dataset with latest data in corresponding data connector"
                                    : "Synchronizes the current dataset with remote storage and retrieves the latest data",
                                action: handleReload,
                                testId: "sidebar-entity-reload-icon"
                              }
                            ]
                          : [])
                      ]}
                    />
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
          {entity?.markdown && (
            <Card className={cardMargin}>
              <DrawerAbout
                markdownString={entity?.markdown}
                projectId={data.projectId}
                elementId={data.itemId}
                hiddenUpdateContentAction={!!data?.isJobCanvas}
                type="ENTITY"
                onSuccess={refetch}
              />
            </Card>
          )}
          <Card className={cardMargin}>
            <CardContent>
              {entityDetails ? (
                <EntityDetailItem
                  entityDetails={entityDetails}
                  navigateToSource={() => goToViewData(true)}
                  isSourceFileImport={
                    // Dataset should be a root-node and shouldn't have been imported by a data-connector.
                    data?.isRootNode && !entityDetails?.dataSourceId && !data?.entityDSDetails?.id
                  }
                />
              ) : (
                <Spinner />
              )}
            </CardContent>
          </Card>
          {!data?.jobProps && data?.isOutputDataset ? (
            <>
              {entity && (
                <DestinationDetails
                  entity={entity}
                  scenarioId={data?.scenarioId}
                  loading={isLoading}
                  disableExport={isUnbuilt}
                />
              )}
            </>
          ) : (
            !!data?.entityDSDetails?.id &&
            data?.entityDSDetails?.purpose === "IMPORT" && (
              <SourceDetails setSideComponent={setSideComponent} data={data} />
            )
          )}
        </div>
      </Box>
    </NewThemeWrapper>
  );
};

export default EntityModalV2;
