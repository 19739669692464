import React from "react";
import { Typography } from "@material-ui/core";

import { Modal } from "src/components/custom";

interface IProps {
  open: boolean;
  disabled: boolean;
  onClose: () => void;
  onReset: () => void;
}

const PasswordResetModal = (props: IProps) => {
  const { open, disabled, onClose, onReset } = props;

  return (
    <Modal
      open={open}
      title="Password reset"
      submitLabel="Send"
      isSubmitDisabled={disabled}
      onClose={onClose}
      onSubmit={onReset}>
      <Typography variant="body2" gutterBottom>
        We will send a reset link to your e-mail. Please use it to change your password.
      </Typography>
      <Typography variant="body2">
        If you don't see the mail, please check the SPAM folder on your mailbox. Let us know if you
        experience any other problems
      </Typography>
    </Modal>
  );
};

export default PasswordResetModal;
