import React from "react";

import { Header, flexRender } from "@tanstack/react-table";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";

import { OverflowTooltip } from "src/components";

import { TData } from "../../Table.types";

type Props = {
  header: Header<TData, string>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    columnGap: theme.spacing(1)
  }
}));

const HeaderName = (props: Props) => {
  const { header } = props || {};

  const classes = useStyles();

  const sortColumn = () => {
    header?.column?.toggleSorting();
  };

  return (
    <Grid container wrap="nowrap" className={classes.root} onClick={sortColumn}>
      <OverflowTooltip
        style={{ maxWidth: 250, whiteSpace: "nowrap" }}
        value={flexRender(header?.column?.columnDef?.header, header?.getContext())}
      />

      {{
        asc: <ArrowDropUpRoundedIcon />,
        desc: <ArrowDropDownRoundedIcon />
      }[header?.column?.getIsSorted() as string] ?? null}
    </Grid>
  );
};

export default HeaderName;
