import { get, has } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { NodeArtifactTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const useStyles = makeStyles({
  root: {
    width: 40,
    height: 40,
    borderRadius: 40 / 3,
    backgroundColor: ({ status }: StyleProps) =>
      has(NodeArtifactTheme, status)
        ? get(NodeArtifactTheme, [status, "backgroundColor"])
        : NodeArtifactTheme.backgroundColor,
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeArtifactTheme, status)
        ? get(NodeArtifactTheme, [status, "borderColor"])
        : NodeArtifactTheme.borderColor,
    color: ({ status }: StyleProps) =>
      has(NodeArtifactTheme, status)
        ? get(NodeArtifactTheme, [status, "iconColor"])
        : NodeArtifactTheme.iconColor,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: ({ status }: StyleProps) =>
        has(NodeArtifactTheme, status)
          ? get(NodeArtifactTheme, [status, "onHover", "backgroundColor"])
          : NodeArtifactTheme.onHover.backgroundColor,
      border: "1px solid",
      borderColor: ({ status }: StyleProps) =>
        has(NodeArtifactTheme, status)
          ? get(NodeArtifactTheme, [status, "onHover", "borderColor"])
          : NodeArtifactTheme.onHover.borderColor,
      color: ({ status }: StyleProps) =>
        has(NodeArtifactTheme, status)
          ? get(NodeArtifactTheme, [status, "onHover", "iconColor"])
          : NodeArtifactTheme.onHover.iconColor
    },
    "&.highlighted": {
      boxShadow: `0px 0px 10px 5px ${NodeArtifactTheme.highlightColor}`
    }
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.active": {
      boxShadow: "0px 0px 48px 0px #003656"
    }
  },
  label: {
    top: 2,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 100,
    color: "#000",
    whiteSpace: "nowrap",
    "&.active": {
      fontWeight: "bold"
    }
  }
});

export default useStyles;
