import { useMutation, UseMutationResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";

interface IPayload {
  scenarioId?: string;
  id?: string;
}

const useAddRecipeToQueue = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ scenarioId, id }: IPayload) => {
      if (!scenarioId || !id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.DfsRunConfigGroupControllerV2Api.addGroupToQueue(id, scenarioId)
      );
    }
  });

export default useAddRecipeToQueue;
