import _ from "lodash";

import { DEFAULT_PYTHON_CLASS } from "./PredictionService.constants";

const getUniqueEndpoint = (name: string) => {
  return `${window.location.origin}/api/v2/predict/${name}`;
};

const getCurlCommand = (name: string, token: string) => {
  return `curl -XPOST -H 'Authorization: Bearer ${token}' -H "Content-type: application/json" --data-raw '<PUT YOUR DATA HERE>' '${getUniqueEndpoint(
    name
  )}'`;
};

const getFormattedPythonCode = (pythonCode: string, name: string, oldName?: string) => {
  const pythonClass = oldName ?? DEFAULT_PYTHON_CLASS;
  if (_.includes(pythonCode, pythonClass)) {
    return _.replace(pythonCode, pythonClass, name);
  }
  return pythonCode;
};

export { getCurlCommand, getFormattedPythonCode, getUniqueEndpoint };
