import React from "react";

export const MainMenuIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor",
  opacity = 1
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M1.92018 0.143555C0.888455 0.143555 0.0491943 0.982873 0.0491943 2.01454C0.0491943 3.0462 0.888107 3.88546 1.92018 3.88546C2.95225 3.88546 3.79116 3.0462 3.79116 2.01454C3.79116 0.982873 2.95184 0.143555 1.92018 0.143555Z"
          fill={color}
        />
        <path
          d="M7.9756 0.143555C6.94393 0.143555 6.10461 0.982873 6.10461 2.01454C6.10461 3.0462 6.94393 3.88546 7.9756 3.88546C9.00732 3.88546 9.84676 3.0462 9.84676 2.01454C9.84676 0.982873 9.00767 0.143555 7.9756 0.143555Z"
          fill={color}
        />
        <path
          d="M14.129 3.88552C15.1605 3.88552 16 3.04626 16 2.0146C16 0.982931 15.1612 0.143555 14.129 0.143555C13.0969 0.143555 12.2581 0.982873 12.2581 2.01454C12.2581 3.0462 13.0975 3.88552 14.129 3.88552Z"
          fill={color}
        />
        <path
          d="M1.87133 9.88076C2.903 9.88076 3.74249 9.04167 3.74249 8.00983C3.74249 6.97793 2.903 6.13867 1.87133 6.13867C0.839666 6.13867 0 6.97799 0 8.00983C0 9.04167 0.839666 9.88076 1.87133 9.88076Z"
          fill={color}
        />
        <path
          d="M7.92677 9.88076C8.95826 9.88076 9.79775 9.04167 9.79775 8.00983C9.79775 6.97793 8.95896 6.13867 7.92677 6.13867C6.89476 6.13867 6.05579 6.97793 6.05579 8.00983C6.05579 9.04167 6.8951 9.88076 7.92677 9.88076Z"
          fill={color}
        />
        <path
          d="M14.0807 9.88076C15.1126 9.88076 15.9516 9.04167 15.9516 8.00983C15.9516 6.97793 15.1126 6.13867 14.0807 6.13867C13.0488 6.13867 12.209 6.97793 12.209 8.00983C12.209 9.04167 13.0485 9.88076 14.0807 9.88076Z"
          fill={color}
        />
        <path
          d="M1.91418 12.1152C0.882513 12.1152 0.0428467 12.9543 0.0428467 13.9862C0.0428467 15.0177 0.882513 15.8571 1.91418 15.8571C2.94584 15.8571 3.7851 15.0181 3.7851 13.9862C3.7851 12.9543 2.94584 12.1152 1.91418 12.1152Z"
          fill={color}
        />
        <path
          d="M7.96984 12.1152C6.93812 12.1152 6.09851 12.9543 6.09851 13.9862C6.09851 15.0177 6.93783 15.8571 7.96984 15.8571C9.00133 15.8571 9.84077 15.0181 9.84077 13.9862C9.84077 12.9543 9.00133 12.1152 7.96984 12.1152Z"
          fill={color}
        />
        <path
          d="M14.1231 12.1152C13.0916 12.1152 12.2521 12.9543 12.2521 13.9862C12.2521 15.0177 13.0916 15.8571 14.1231 15.8571C15.155 15.8571 15.994 15.0181 15.994 13.9862C15.994 12.9543 15.1546 12.1152 14.1231 12.1152Z"
          fill={color}
        />
      </g>
    </svg>
  </>
);
