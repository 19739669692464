/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OutputEntity
 */
export interface OutputEntity {
    /**
     * 
     * @type {string}
     * @memberof OutputEntity
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputEntity
     */
    'outputType'?: OutputEntityOutputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OutputEntity
     */
    'sampleDatasetFilePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputEntity
     */
    'fullDatasetFilePath'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputEntity
     */
    'datasetNumRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputEntity
     */
    'datasetNumCols'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputEntity
     */
    'datasetCols'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OutputEntity
     */
    'datasetMetaString'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputEntity
     */
    'plotlyChartFilePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputEntity
     */
    'textAnswer'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputEntity
     */
    'codeName'?: string;
}

export const OutputEntityOutputTypeEnum = {
    Text: 'TEXT',
    Chart: 'CHART',
    Image: 'IMAGE',
    Dataset: 'DATASET',
    TextNoCode: 'TEXT_NO_CODE',
    Function: 'FUNCTION',
    Unrecognized: 'UNRECOGNIZED',
    AutoInfer: 'AUTO_INFER',
    Model: 'MODEL'
} as const;

export type OutputEntityOutputTypeEnum = typeof OutputEntityOutputTypeEnum[keyof typeof OutputEntityOutputTypeEnum];


