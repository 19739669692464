/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { ByteString } from './byte-string';
// May contain unused imports in some cases
// @ts-ignore
import type { Descriptor } from './descriptor';
// May contain unused imports in some cases
// @ts-ignore
import type { Message } from './message';
// May contain unused imports in some cases
// @ts-ignore
import type { UninterpretedOption } from './uninterpreted-option';
// May contain unused imports in some cases
// @ts-ignore
import type { UninterpretedOptionOrBuilder } from './uninterpreted-option-or-builder';
// May contain unused imports in some cases
// @ts-ignore
import type { UnknownFieldSet } from './unknown-field-set';

/**
 * 
 * @export
 * @interface FileOptionsOrBuilder
 */
export interface FileOptionsOrBuilder {
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'deprecated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'javaStringCheckUtf8'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'javaMultipleFiles'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     * @deprecated
     */
    'javaGenerateEqualsAndHash'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'optimizeFor'?: FileOptionsOrBuilderOptimizeForEnum;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'goPackage'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'goPackageBytes'?: ByteString;
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'ccGenericServices'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'javaGenericServices'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'pyGenericServices'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'phpGenericServices'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'ccEnableArenas'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'objcClassPrefix'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'objcClassPrefixBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'csharpNamespace'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'csharpNamespaceBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'swiftPrefix'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'swiftPrefixBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'phpClassPrefix'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'phpClassPrefixBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'phpNamespace'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'phpNamespaceBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'phpMetadataNamespace'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'phpMetadataNamespaceBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'rubyPackage'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'rubyPackageBytes'?: ByteString;
    /**
     * 
     * @type {Array<UninterpretedOption>}
     * @memberof FileOptionsOrBuilder
     */
    'uninterpretedOptionList'?: Array<UninterpretedOption>;
    /**
     * 
     * @type {number}
     * @memberof FileOptionsOrBuilder
     */
    'uninterpretedOptionCount'?: number;
    /**
     * 
     * @type {Array<UninterpretedOptionOrBuilder>}
     * @memberof FileOptionsOrBuilder
     */
    'uninterpretedOptionOrBuilderList'?: Array<UninterpretedOptionOrBuilder>;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'javaPackage'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'javaPackageBytes'?: ByteString;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'javaOuterClassname'?: string;
    /**
     * 
     * @type {ByteString}
     * @memberof FileOptionsOrBuilder
     */
    'javaOuterClassnameBytes'?: ByteString;
    /**
     * 
     * @type {Message}
     * @memberof FileOptionsOrBuilder
     */
    'defaultInstanceForType'?: Message;
    /**
     * 
     * @type {UnknownFieldSet}
     * @memberof FileOptionsOrBuilder
     */
    'unknownFields'?: UnknownFieldSet;
    /**
     * 
     * @type {string}
     * @memberof FileOptionsOrBuilder
     */
    'initializationErrorString'?: string;
    /**
     * 
     * @type {Descriptor}
     * @memberof FileOptionsOrBuilder
     */
    'descriptorForType'?: Descriptor;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof FileOptionsOrBuilder
     */
    'allFields'?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof FileOptionsOrBuilder
     */
    'initialized'?: boolean;
}

export const FileOptionsOrBuilderOptimizeForEnum = {
    Speed: 'SPEED',
    CodeSize: 'CODE_SIZE',
    LiteRuntime: 'LITE_RUNTIME'
} as const;

export type FileOptionsOrBuilderOptimizeForEnum = typeof FileOptionsOrBuilderOptimizeForEnum[keyof typeof FileOptionsOrBuilderOptimizeForEnum];


