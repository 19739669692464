import React from "react";

export const useSaveEntityStorageHelper = ({ data, sessionKey }: $TSFixMe) => {
  const [, setIsEntitySelected] = React.useState(false);

  const getSelectedEntities = () => {
    let selectedEntities = sessionStorage.getItem(sessionKey);
    selectedEntities = !selectedEntities ? [] : JSON.parse(selectedEntities);
    return selectedEntities;
  };

  const isEntitySelected = () => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    return getSelectedEntities().includes(data?.name);
  };

  const handleMultiSelection = () => {
    let selectedEntities = getSelectedEntities();

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    if (selectedEntities.includes(data?.name)) {
      selectedEntities = (selectedEntities as $TSFixMe).filter(
        (entity: $TSFixMe) => entity !== data?.name
      );
      setIsEntitySelected(false);
    } else {
      (selectedEntities as $TSFixMe).push(data?.name);
      setIsEntitySelected(true);
    }

    sessionStorage.setItem(sessionKey, JSON.stringify(selectedEntities));
  };

  return { isEntitySelected, handleMultiSelection };
};
