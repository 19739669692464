import { ScenarioRunStepDtoStatusEnum } from "openapi/Models";

export const scenarioStatusesNames: $TSFixMe = {
  [ScenarioRunStepDtoStatusEnum.Pending]: "Pending",
  [ScenarioRunStepDtoStatusEnum.Built]: "Built",
  [ScenarioRunStepDtoStatusEnum.Empty]: "Empty",
  [ScenarioRunStepDtoStatusEnum.Running]: "Running",
  [ScenarioRunStepDtoStatusEnum.Stopped]: "Stopped",
  [ScenarioRunStepDtoStatusEnum.Success]: "Success",
  [ScenarioRunStepDtoStatusEnum.Error]: "Error",
  [ScenarioRunStepDtoStatusEnum.Unbuilt]: "Unbuilt",
  [ScenarioRunStepDtoStatusEnum.Skipped]: "Skipped",
  [ScenarioRunStepDtoStatusEnum.Building]: "Building"
};

export enum ScenarioStatusColors {
  Info = "#e0e0e0",
  Warn = "#f5c726",
  Error = "#e56169",
  Success = "#83c784"
}

export enum ScenarioDeletePromptDetails {
  title = "Delete Scenario",

  messageLine1 = "Do you really want to delete this scenario?",
  messageLine2 = "If you confirm you will delete the scenario completely!"
}

export enum ScenariosHelperText {
  DefaultScenarioRestrictedEditInfo = "Default scenario cannot be edited!",
  DefaultScenarioRestrictedDeleteInfo = "Default scenario cannot be deleted!",

  ScenarioDeleted = "Scenario deleted successfully!",
  ScenarioDeleteFailed = "Scenario deletion failed!",

  Edit = "Edit",
  Run = "Run",
  DisplayOnDag = "Display on Canvas",
  Delete = "Delete"
}
