import React from "react";

export const DatasetIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor",
  opacity = 1
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <path
        d="M9.99996 0C4.8971 0 0.89917 2.18657 0.89917 4.97865V15.0188C0.89917 17.8108 4.8971 19.9974 9.99996 19.9974C15.1028 19.9974 19.1007 17.8108 19.1007 15.0188V4.97865C19.0982 2.18657 15.1028 0 9.99996 0ZM2.45176 9.92625C2.45176 9.52516 2.62772 9.12408 2.97447 8.73075C3.08574 8.60655 3.14526 8.45387 3.16337 8.3012C4.81947 9.32592 7.24669 9.96248 9.99996 9.96248C12.7273 9.96248 15.139 9.33627 16.7926 8.32967C16.8236 8.45646 16.8805 8.57549 16.9737 8.679C17.3541 9.09044 17.5456 9.50964 17.5456 9.92884C17.5456 11.5487 14.4456 13.3549 9.99737 13.3549C5.54919 13.3549 2.45176 11.5461 2.45176 9.92625ZM9.99996 1.55259C14.4481 1.55259 17.5482 3.35878 17.5482 4.97865C17.5482 6.59853 14.4481 8.4073 9.99996 8.4073C5.55178 8.4073 2.45176 6.60111 2.45176 4.97865C2.45176 3.35619 5.55178 1.55259 9.99996 1.55259ZM9.99996 18.4474C5.55178 18.4474 2.45176 16.6412 2.45176 15.0188C2.45176 14.6177 2.62772 14.2166 2.97447 13.8233C3.11938 13.6577 3.18148 13.4481 3.16596 13.2462C4.82206 14.2683 7.24928 14.9049 9.99996 14.9049C12.717 14.9049 15.1183 14.2839 16.7719 13.285C16.7822 13.4584 16.8469 13.6318 16.9737 13.7689C17.3541 14.1804 17.5456 14.5996 17.5456 15.0188C17.5456 16.6412 14.4481 18.4474 9.99996 18.4474Z"
        fill={color}
      />
    </g>
  </svg>
);
