import React, { useEffect, useMemo } from "react";
import { Grid, Button, CircularProgress, Tooltip, Typography, makeStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import MonacoEditor from "react-monaco-editor";

import { getSqlDataSourceSampleDataWithRethrow } from "src/api/dataSources";

import { toastWrapper } from "src/utils/toastWrapper";
import { capitalize } from "src/utils/capitalize";

import { Field } from "src/components";

import SampleData from "./SampleData";

import { DataSourcesHttpStatuses, DataSourcesHelperText } from "../../utils/DataSources.constants";
import DataFetchingContainer from "src/pages/private/ProjectsModule/pages/Dataset/components/DataFetchingContainer";

const useStyles = makeStyles(() => ({
  root: {
    rowGap: 20
  },
  editorQueryFieldsContainer: {
    "& > .MuiGrid-root": {
      width: "100%",
      rowGap: 20
    },
    "& .title": {
      marginBottom: "0.5rem"
    },
    "& .queryFieldInfo": {
      marginLeft: 10
    }
  },
  sqlConfigurationForm: {
    display: "flex",
    alignItems: (styleProps: $TSFixMe) => styleProps.rootAlignItems,
    columnGap: 20
  }
}));

const Data = (props: $TSFixMe) => {
  const {
    id,
    isFetching,
    dataSourceTypeStore,
    dataSourceDetails,
    isSqlContentDisabled,
    values,
    setValues,
    editorQueryFields,
    setEditorQueryFields,
    sampleData,
    setSampleData,
    isFetchingSampleData,
    setIsFetchingSampleData,
    isRanQuery,
    setIsRanQuery,
    isFormFieldsDirty,
    setIsFormFieldsDirty
  } = props || {};

  const classes = useStyles({});

  const editorQueryFieldLabel = (fieldKey: string) => {
    if (!editorQueryFields[fieldKey]?.name) {
      return DataSourcesHelperText.Unknown;
    }

    let thisEditorQueryFieldLabel = capitalize(editorQueryFields[fieldKey]?.name);

    if (editorQueryFields[fieldKey]?.required) {
      thisEditorQueryFieldLabel = `${thisEditorQueryFieldLabel} *`;
    }

    return thisEditorQueryFieldLabel;
  };

  const getErrorText = (field: $TSFixMe) => {
    if (!isRanQuery) {
      return "";
    }

    const isRequired = values?.[field]?.required && !values?.[field]?.value;

    if (isRequired) {
      return DataSourcesHelperText.Required;
    }

    return "";
  };

  const onChange = (event: $TSFixMe) => {
    const { name, value } = event.target;

    values[name].value = value;

    setValues({
      ...values
    });

    setIsFormFieldsDirty(() => true);
  };

  const onEditorQueryFieldChange = (value: $TSFixMe, fieldKey: string) => {
    const thisSqlEditorValue: $TSFixMe = editorQueryFields;
    thisSqlEditorValue[fieldKey].value = value;
    setEditorQueryFields(() => ({ ...thisSqlEditorValue }));

    setIsFormFieldsDirty(() => true);
  };

  const getPayloadConfig = () => {
    let isValid = true;

    const requestJson: $TSFixMe = {
      id: id || dataSourceDetails?.id,
      type: dataSourceDetails?.dataSourceType,
      options: {}
    };

    if (Object.keys(editorQueryFields)?.length > 0) {
      Object.keys(editorQueryFields)?.forEach((fieldKey: $TSFixMe) => {
        const thisValue: $TSFixMe = (editorQueryFields?.[fieldKey]?.value || "")?.trim();

        if (editorQueryFields?.[fieldKey]?.required && !thisValue) {
          isValid = false;
          return;
        } else {
          if (thisValue) {
            requestJson.options[editorQueryFields?.[fieldKey]?.name] = thisValue;
          }
        }
      });
    }

    if (Object.keys(values)?.length > 0) {
      Object.keys(values)?.forEach((fieldKey: $TSFixMe) => {
        const thisValue: $TSFixMe = (values?.[fieldKey]?.value || "")?.trim();

        if (values?.[fieldKey]?.required && !thisValue) {
          isValid = false;
          return;
        } else {
          if (thisValue) {
            requestJson.options[values?.[fieldKey]?.name] = thisValue;
          }
        }
      });
    }

    return { isValid, requestJson };
  };

  const runQuery = async () => {
    setIsRanQuery(() => true);

    const { isValid, requestJson } = getPayloadConfig() || {};

    if (isValid && Object.keys(requestJson.options || {})?.length > 0) {
      const thisRequestJson: $TSFixMe = {
        id: id || dataSourceDetails?.id,
        type: dataSourceTypeStore || dataSourceDetails?.dataSourceType,
        dsOptions: {
          ...requestJson.options
        }
      };

      try {
        setIsFetchingSampleData(() => true);

        const sampleDataResponse = await getSqlDataSourceSampleDataWithRethrow({
          payload: thisRequestJson
        });

        if (sampleDataResponse?.status === DataSourcesHttpStatuses.Success) {
          if (Object.keys(sampleDataResponse?.data || {})?.length > 0) {
            setSampleData(() => sampleDataResponse?.data);
            setIsFormFieldsDirty(() => false);
          }
        } else {
          const errorMessage =
            Object.keys(sampleDataResponse?.messages?.[0] || {})?.[0] ||
            DataSourcesHelperText.SomethingWentWrong;

          toastWrapper({
            type: "error",
            content: errorMessage
          });

          setIsRanQuery(() => false);
        }
      } catch (error: $TSFixMe) {
        console.error(error);

        setIsRanQuery(() => false);
      } finally {
        setIsFetchingSampleData(() => false);
      }
    }
  };

  const isInvalidConfig = useMemo(() => {
    const { isValid } = getPayloadConfig() || {};

    return !isValid;
  }, [id, dataSourceDetails?.id, dataSourceDetails?.dataSourceType, editorQueryFields, values]);

  useEffect(() => {
    if (!isSqlContentDisabled) {
      if (!isInvalidConfig) {
        setIsFormFieldsDirty(() => true);
      }
    }
  }, [isSqlContentDisabled]);

  const isRunQueryDisabled = useMemo(() => {
    let isDisabled = true;

    if (!isSqlContentDisabled && !isFetchingSampleData) {
      if (isInvalidConfig) {
        isDisabled = true;
      } else {
        isDisabled = !isFormFieldsDirty;
      }
    }

    return isDisabled;
  }, [isSqlContentDisabled, isInvalidConfig, isFormFieldsDirty, isFetchingSampleData]);

  return (
    <>
      {isFetching ? (
        <CircularProgress size={23} color="primary" />
      ) : (
        <>
          <Grid container direction="row" className={classes.root}>
            {Object.keys(values || [])?.length > 0 && (
              <Grid item>
                <form
                  id="sqlConfigurationForm"
                  name="sqlConfigurationForm"
                  autoComplete="sqlConfigurationForm-off"
                  className={classes.sqlConfigurationForm}>
                  {Object.keys(values).map((field: $TSFixMe, index: number) => {
                    return (
                      <Tooltip
                        key={index}
                        id={`sqlConfigurationFieldDisabledTooltip_${index}`}
                        title={
                          isSqlContentDisabled
                            ? DataSourcesHelperText.SqlConfigurationFieldDisabledInfo
                            : ""
                        }
                        arrow>
                        <div>
                          <Field
                            key={`sqlConfigurationFieldKey_${index}`}
                            id={values?.[field]?.name || `sqlConfigurationFieldId_${index}`}
                            label={capitalize(values?.[field]?.name || "")}
                            variant="outlined"
                            // @ts-ignore
                            size="small"
                            // @ts-ignore
                            value={values?.[field]?.value || ""}
                            required={values?.[field]?.required || false}
                            error={!!getErrorText(field)}
                            helperText={getErrorText(field)}
                            onChange={onChange}
                            disabled={isSqlContentDisabled}
                            autoComplete={`sqlConfigurationFieldId_${index}-off`}
                          />
                        </div>
                      </Tooltip>
                    );
                  })}
                </form>
              </Grid>
            )}
            {Object.keys(editorQueryFields)?.length > 0 && (
              <>
                <Grid container direction="row" className={classes.editorQueryFieldsContainer}>
                  {(Object.keys(editorQueryFields) || [])?.map((fieldKey: string) => {
                    return (
                      <Grid key={fieldKey} item style={{ width: "100%" }}>
                        <Typography
                          id="sqlConfigurationEditorQueryFieldLabel"
                          variant="body2"
                          color="textSecondary"
                          className="title">
                          {editorQueryFieldLabel(fieldKey)}
                          {!isSqlContentDisabled &&
                            editorQueryFields[fieldKey]?.name?.trim()?.toLowerCase() ===
                              "query" && (
                              <Tooltip
                                id="sqlConfigurationEditorQueryFieldInfoTooltip"
                                title={
                                  DataSourcesHelperText.SqlConfigurationEditorQueryFieldInfo || ""
                                }
                                arrow>
                                <InfoOutlinedIcon className="queryFieldInfo" fontSize="small" />
                              </Tooltip>
                            )}
                        </Typography>
                        <Tooltip
                          id="sqlConfigurationEditorQueryFieldDisabledTooltip"
                          title={
                            isSqlContentDisabled
                              ? DataSourcesHelperText.SqlConfigurationFieldDisabledInfo
                              : ""
                          }
                          placement="top"
                          arrow>
                          <div id="sqlConfigurationEditorQueryField">
                            <MonacoEditor
                              language="sql"
                              height="200px"
                              width="100%"
                              theme="vs-dark"
                              value={editorQueryFields[fieldKey]?.value}
                              onChange={(event: $TSFixMe) =>
                                onEditorQueryFieldChange(event, fieldKey)
                              }
                              options={{
                                readOnly: isSqlContentDisabled,
                                fontSize: 12,
                                minimap: { enabled: false },
                                renderLineHighlight: "none",
                                lineNumbers: "off",
                                scrollBeyondLastLine: false,
                                padding: {
                                  top: 12
                                }
                              }}
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}

            {(Object.keys(values || [])?.length > 0 ||
              Object.keys(editorQueryFields)?.length > 0) && (
              <Grid container justifyContent="flex-end">
                <Tooltip
                  id="sqlConfigurationRunQueryActionDisabledTooltip"
                  title={
                    isSqlContentDisabled
                      ? DataSourcesHelperText.SqlConfigurationActionDisabledInfo
                      : ""
                  }
                  arrow>
                  <div>
                    <Button
                      id="sqlConfigurationRunQuery"
                      variant="contained"
                      color="primary"
                      disabled={isRunQueryDisabled}
                      onClick={() => runQuery()}>
                      {DataSourcesHelperText.RunQuery}
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            )}

            {isRanQuery && (
              <>
                {isFetchingSampleData ? (
                  <DataFetchingContainer />
                ) : (sampleData?.columns || [])?.length === 0 ||
                  (sampleData?.rows || [])?.length === 0 ? (
                  <span id="noSampleDataFound">{DataSourcesHelperText.NoSampleDataFound}</span>
                ) : (
                  <Grid item style={{ width: "100%" }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Typography id="sampleDataTitle" variant="subtitle2">
                          {DataSourcesHelperText.SampleData}
                        </Typography>
                      </Grid>
                    </Grid>
                    <SampleData sampleData={sampleData} />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Data;
