// Packages
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { isArray } from "lodash";

// Utils
import api from "utils/AxiosClient";

export const enum UseGetEnvironmentTypesQueryKeys {
  EnvironmentTypes = "environmentTypes"
}

interface IEnvironmentTypesProps extends UseQueryOptions<unknown, unknown, any[]> {}

const useGetEnvironmentTypes = (props?: IEnvironmentTypesProps): UseQueryResult<any[]> => {
  const { ...options } = props;

  return useQuery({
    queryKey: [UseGetEnvironmentTypesQueryKeys.EnvironmentTypes],
    queryFn: async () =>
      await api.fetchResponse(async () => await api.EnvControllerApi.getAllEnvTypes()),
    select: (data) => (isArray(data) ? data : []),
    ...options
  });
};

export default useGetEnvironmentTypes;
