import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { PipelineStep } from "openapi/Models/pipeline-step";

import api from "utils/AxiosClient";

const QUERY_KEY_DELETE_CANDIDATES = "query-key-delete-candidates";

const useGetDeleteCandidates = (
  stepId: string,
  stepType: string,
  nodeType: string,
  options?: UseQueryOptions<PipelineStep[]>
): UseQueryResult<PipelineStep[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_DELETE_CANDIDATES, stepId, stepType, nodeType],
    queryFn: async () => {
      const deleteAssociatedPDs = ["group", "model", "entity"].includes(nodeType);

      const data = await api.fetchResponse(
        async () =>
          await api.ProjectsControllerV2Api.getDeleteCandidates(
            stepId,
            stepType,
            deleteAssociatedPDs,
            deleteAssociatedPDs
          )
      );
      return data;
    },
    ...options,
    refetchOnMount: true
  });
};

export default useGetDeleteCandidates;
export { QUERY_KEY_DELETE_CANDIDATES };
