import React from "react";

import { MenuItem, Switch } from "@material-ui/core";
import styles from "./MenuList.module.scss";
import ColumnFilterMenu from "../../pages/ViewData/ColumnFilterMenu/ColumnFilterMenu";

type OwnProps = {
  list?: $TSFixMe[];
  hasListSearch?: boolean;
  hasSelectAll?: boolean;
  isSelectAllChecked?: boolean;
  hasToggle?: boolean;
  isToggleActive?: boolean;
  handleSelectAll?: $TSFixMeFunction;
  handleMenuItem?: $TSFixMeFunction;
  handleMenuItemClose?: $TSFixMeFunction;
  handleCheckboxChange?: $TSFixMeFunction;
  handleToggle?: $TSFixMeFunction;
  toggleLabel: string;
  checkedValidation?: $TSFixMeFunction;
  listItemStyles?: $TSFixMe;
};

// @ts-expect-error TS(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof MenuList.defaultProps;

// @ts-expect-error TS(7022) FIXME: 'MenuList' implicitly has type 'any' because it do... Remove this comment to see the full error message
const MenuList = ({
  list,
  handleColumnChange,
  handleSearch,
  visibleColumns,
  handleToggle,
  hasToggle,
  isToggleActive,
  toggleLabel
}: Props) => {
  return (
    <div>
      <ColumnFilterMenu
        data={list}
        onSearch={handleSearch}
        onFormControlChange={handleColumnChange}
        visibleColumns={visibleColumns}
        classNameContainer={styles.menuColumnFilterContainer}
        classNameList={styles.menuColumnFilterList}
        size="small"
      />
      {hasToggle && (
        <MenuItem onClick={() => handleToggle(!isToggleActive)}>
          <Switch checked={isToggleActive} inputProps={{ "aria-label": `toggle` }} size="small" />
          <span style={{ fontSize: "0.875rem" }}>{toggleLabel}</span>
        </MenuItem>
      )}
    </div>
  );
};

MenuList.defaultProps = {
  list: [],
  hasListSearch: false,
  hasSelectAll: false,
  isSelectAllChecked: false,
  hasToggle: false,
  handleSelectAll: () => {},
  handleMenuItem: () => {},
  handleMenuItemClose: () => {},
  handleCheckboxChange: () => {},
  checkedValidation: () => {},
  listItemStyles: {}
};

export default MenuList;
