import React from "react";

export const PlusFilledIcon = ({ disabled }: { disabled?: boolean }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: disabled ? 0.5 : "default" }}>
      <g clipPath="url(#clip0_18283_2710)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4 9.8C15.4 9.02681 14.7732 8.4 14 8.4C13.2268 8.4 12.6 9.02681 12.6 9.8V12.6H9.8C9.02681 12.6 8.4 13.2268 8.4 14C8.4 14.7732 9.02681 15.4 9.8 15.4H12.6V18.2C12.6 18.9732 13.2268 19.6 14 19.6C14.7732 19.6 15.4 18.9732 15.4 18.2V15.4H18.2C18.9732 15.4 19.6 14.7732 19.6 14C19.6 13.2268 18.9732 12.6 18.2 12.6H15.4V9.8ZM7.3501 0.542948C9.16829 0.13888 11.374 0 14 0C16.626 0 18.8317 0.13888 20.6499 0.542948C22.484 0.950558 24.0083 1.6464 25.181 2.81901C26.3536 3.99162 27.0494 5.51603 27.4571 7.3501C27.8611 9.16829 28 11.374 28 14C28 16.626 27.8611 18.8317 27.4571 20.6499C27.0494 22.484 26.3536 24.0083 25.181 25.181C24.0083 26.3536 22.484 27.0494 20.6499 27.4571C18.8317 27.8611 16.626 28 14 28C11.374 28 9.16829 27.8611 7.3501 27.4571C5.51603 27.0494 3.99162 26.3536 2.81901 25.181C1.6464 24.0083 0.950558 22.484 0.542948 20.6499C0.13888 18.8317 0 16.626 0 14C0 11.374 0.13888 9.16829 0.542948 7.3501C0.950558 5.51603 1.6464 3.99162 2.81901 2.81901C3.99162 1.6464 5.51603 0.950558 7.3501 0.542948Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_18283_2710">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
