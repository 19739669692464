import React from "react";

export const CardsViewIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.58846 0.421875H1.68408C0.986443 0.421875 0.420898 0.98742 0.420898 1.68506V5.58944C0.420898 6.28707 0.986443 6.85261 1.68408 6.85261H5.58846C6.28609 6.85261 6.85164 6.28707 6.85164 5.58944V1.68506C6.85164 0.98742 6.28609 0.421875 5.58846 0.421875Z"
      stroke={color}
      strokeWidth="0.842121"
    />
    <path
      d="M5.58846 9.14844H1.68408C0.986443 9.14844 0.420898 9.71398 0.420898 10.4116V14.316C0.420898 15.0136 0.986443 15.5792 1.68408 15.5792H5.58846C6.28609 15.5792 6.85164 15.0136 6.85164 14.316V10.4116C6.85164 9.71398 6.28609 9.14844 5.58846 9.14844Z"
      stroke={color}
      strokeWidth="0.842121"
    />
    <path
      d="M15.0435 0.421875H11.1392C10.4415 0.421875 9.87598 0.98742 9.87598 1.68506V5.58944C9.87598 6.28707 10.4415 6.85261 11.1392 6.85261H15.0435C15.7412 6.85261 16.3067 6.28707 16.3067 5.58944V1.68506C16.3067 0.98742 15.7412 0.421875 15.0435 0.421875Z"
      stroke={color}
      strokeWidth="0.842121"
    />
    <path
      d="M15.0435 9.14844H11.1392C10.4415 9.14844 9.87598 9.71398 9.87598 10.4116V14.316C9.87598 15.0136 10.4415 15.5792 11.1392 15.5792H15.0435C15.7412 15.5792 16.3067 15.0136 16.3067 14.316V10.4116C16.3067 9.71398 15.7412 9.14844 15.0435 9.14844Z"
      stroke={color}
      strokeWidth="0.842121"
    />
  </svg>
);
