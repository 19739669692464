import React from "react";
import { InputAdornment, makeStyles, TextFieldProps } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { Field } from "../";

const useStyles = makeStyles(() => ({
  search: {
    backgroundColor: "white",
    borderRadius: 4,
    "& .MuiFilledInput-input": {
      padding: "9px 12px 9px 12px",
      fontSize: 12,
      maxHeight: 24,
      "&::placeholder": {
        color: "rgba(0, 0, 0, 1)"
      }
    },
    "& .MuiFilledInput-adornedStart": {
      paddingRight: 0,
      borderColor: "#e0e0e0 !important"
    },
    "& .MuiInputAdornment-root path": {
      stroke: "rgba(0, 0, 0, 0.54)"
    }
  }
}));

const SearchField = ({
  id = "subTopbarSearch",
  className,
  InputProps,
  ...rest
}: TextFieldProps) => {
  const classes = useStyles();

  return (
    <Field
      id={id}
      hiddenLabel
      className={`${classes.search}${className ? ` ${className}` : ""}`}
      placeholder="Search..."
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        type: "search",
        ...InputProps
      }}
      {...rest}
    />
  );
};

export default SearchField;
