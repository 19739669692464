import React from "react";

export const ChartIconV3 = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.8" y="3.2" width="19.2" height="19.2" rx="9.6" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0441 8.4366C15.2885 8.6118 14.3347 9.5932 13.6483 10.6306L13.2334 11.2578L11.3867 9.56L12.2552 8.62L13.017 9.3205C13.8207 8.31 15.0067 7.3553 16.9166 7.16484L17.0441 8.4366Z"
        fill="#FF6550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3617 11.5791C17.9969 10.4647 16.6276 10.486 15.4077 10.7354L14.67 10.8862L14.568 8.38328L15.8475 8.33128L15.8896 9.36384C17.1735 9.21608 18.688 9.37752 20.1725 10.5898L19.3617 11.5791Z"
        fill="#FF6550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.025 10.1641C10.2007 11.9152 11.1847 12.8666 12.2246 13.5511L12.8534 13.965L11.1512 15.807L10.2091 14.9407L10.9113 14.1808C9.89821 13.3792 8.94095 12.1962 8.75 10.2913L10.025 10.1641Z"
        fill="#FF6550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1739 7.84895C12.0569 9.2106 12.0848 10.5564 12.3285 11.794L12.4794 12.53L9.9709 12.6319L9.91875 11.3552L10.9539 11.3132C10.8056 10.0322 10.9673 8.5216 12.1824 7.0397L13.1739 7.84895Z"
        fill="#FF6550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.757 17.1648C13.5127 16.9896 14.4665 16.0082 15.1529 14.9708L15.5678 14.3436L17.4144 16.0414L16.5459 16.9813L15.7841 16.2808C14.9804 17.2913 13.7944 18.246 11.8845 18.4365L11.757 17.1648Z"
        fill="#FF6550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43859 14.0243C11.0036 15.1383 12.3718 15.1169 13.3938 14.8676L14.1316 14.7168L14.2338 17.2189L12.9537 17.2709L12.9116 16.2383C11.6271 16.3834 10.112 16.221 8.62741 15.0087L9.43859 14.0243Z"
        fill="#FF6550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7768 17.8359C18.6013 15.0847 17.6171 14.1313 16.5772 13.2493L15.9445 12.6345L17.6506 9.79296L18.5925 10.6593L17.8904 11.4192C18.906 12.2208 19.8633 13.4038 20.054 15.3087L18.7768 17.8359Z"
        fill="#FF6550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6274 17.7512C16.7444 16.3895 16.7165 15.0436 16.4728 13.806L16.3219 13.07L18.8304 12.9681L18.8826 14.2448L17.8474 14.2868C17.9958 15.5678 17.8341 17.0784 16.6189 18.5603L15.6274 17.7512Z"
        fill="#FF6550"
      />
      <g filter="url(#filter0_d_20123_8423)">
        <rect x="3.2" y="1.6" width="25.6" height="25.6" rx="12.8" fill="#FF5C00" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5255 8.58208C17.1847 8.81552 15.913 9.66966 14.9978 10.7073L14.4444 11.3435L11.9823 10.0798L12.9625 9.02684L13.764 9.76063C14.8705 8.4132 16.3928 7.14032 19.3555 6.88632L19.5255 8.58208Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6155 12.7721C20.7959 11.2862 18.9762 11.3147 17.3436 11.6471L16.36 11.8481L16.224 8.51098L17.93 8.4416L17.9862 9.81832C19.698 9.62146 21.7173 9.83667 23.6968 11.4531L22.6155 12.7721Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1667 10.8854C10.401 13.22 11.713 14.4887 13.0995 15.4014L13.8979 15.972L11.6684 18.4092L10.4121 17.2541L11.3484 16.241C10.0083 15.172 8.72139 13.3589 8.46667 10.8549L10.1667 10.8854Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3652 7.80972C13.2855 9.36848 13.3217 10.8351 13.6385 12.2586L13.8393 13.2399L10.091 13.3759L10.025 11.4175L11.4049 11.3715C11.2065 9.70154 11.4231 7.5947 13.0432 5.63366L14.3652 7.80972Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4761 20.2197C14.8169 19.9863 16.0886 19.1322 17.0038 18.0945L17.5572 17.4583L20.0193 18.722L19.0391 19.775L18.2376 19.0412C17.1311 20.3887 15.6088 21.6616 12.646 21.9156L12.4761 20.2197Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38475 16.0322C11.2043 17.5181 13.024 17.4896 14.6566 17.1571L15.64 16.9561L15.776 20.2932L14.0701 20.3626L14.0139 18.9858C12.302 19.1827 10.2827 18.9675 8.30328 17.351L9.38475 16.0322Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8357 17.9145C21.6001 15.58 20.2881 14.3113 18.9017 13.3986L18.1032 12.828L20.3327 10.3907L21.5889 11.5449L20.6526 12.558C21.9927 13.627 23.2796 15.4401 23.5343 17.9441L21.8357 17.9145Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4365 21.001C18.5162 19.4423 18.48 17.9757 18.1632 16.5522L17.9624 15.5709L21.7107 15.4349L21.7767 17.3933L20.3968 17.4393C20.5952 19.1092 20.3786 21.216 18.7585 23.177L17.4365 21.001Z"
        fill="white"
      />
      <g filter="url(#filter1_d_20123_8423)">
        <rect x="3.2" y="1.6" width="25.6" height="25.6" rx="12.8" fill="white" />
        <g opacity="0.4">
          <path
            d="M19.436 18H12.5667V10.8H13.2536V17.2H13.9404V13.6H15.3144V17.2H16.0013V12H17.3753V17.2H18.0622V15.2H19.436V18Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_20123_8423"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.6" />
          <feGaussianBlur stdDeviation="1.6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20123_8423" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20123_8423"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_20123_8423"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.6" />
          <feGaussianBlur stdDeviation="1.6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.36138 0 0 0 0 0.36138 0 0 0 0 0.36138 0 0 0 0.2 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20123_8423" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20123_8423"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
