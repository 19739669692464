import { OutlinedTextFieldProps, TextField } from "@material-ui/core";
import React from "react";
import { EmailIdError } from "./EmailIdError";
import { startCase } from "lodash";
import { PublicScreenLabels } from "src/constants";

interface EmailInputProps extends Partial<OutlinedTextFieldProps> {
  error?: any;
}

export const EmailInput = (props: EmailInputProps) => {
  const { disabled, error, ...restProps } = props;
  return (
    <TextField
      id="signInEmail"
      data-testid="signInEmail"
      label={startCase(PublicScreenLabels.Email)}
      variant="outlined"
      size="small"
      color="primary"
      fullWidth
      autoFocus
      autoComplete="off"
      disabled={disabled}
      {...restProps}
      error={!!error}
      helperText={<EmailIdError type={error?.type} />}
    />
  );
};
