/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface GenerateCodeResponse
 */
export interface GenerateCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeResponse
     */
    'groupId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateCodeResponse
     */
    'passed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeResponse
     */
    'prompt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeResponse
     */
    'codeHistoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeResponse
     */
    'errorType'?: GenerateCodeResponseErrorTypeEnum;
}

export const GenerateCodeResponseErrorTypeEnum = {
    AiDown: 'AI_DOWN',
    AiCodeNotGenerated: 'AI_CODE_NOT_GENERATED',
    UnknownError: 'UNKNOWN_ERROR',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type GenerateCodeResponseErrorTypeEnum = typeof GenerateCodeResponseErrorTypeEnum[keyof typeof GenerateCodeResponseErrorTypeEnum];


