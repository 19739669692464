import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    "&.stickyHeader": {
      position: "sticky",
      top: 0,
      zIndex: 1
    }
  },
  tableRow: {
    display: "flex"
  }
}));

export default useStyles;
