/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { ScenarioRunStepResult } from './scenario-run-step-result';

/**
 * 
 * @export
 * @interface DFSConfigGroupDetailsDto
 */
export interface DFSConfigGroupDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof DFSConfigGroupDetailsDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof DFSConfigGroupDetailsDto
     */
    'updated'?: number;
    /**
     * 
     * @type {number}
     * @memberof DFSConfigGroupDetailsDto
     */
    'built'?: number;
    /**
     * 
     * @type {string}
     * @memberof DFSConfigGroupDetailsDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSConfigGroupDetailsDto
     */
    'status'?: DFSConfigGroupDetailsDtoStatusEnum;
    /**
     * 
     * @type {Array<ScenarioRunStepResult>}
     * @memberof DFSConfigGroupDetailsDto
     */
    'results'?: Array<ScenarioRunStepResult>;
    /**
     * 
     * @type {string}
     * @memberof DFSConfigGroupDetailsDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSConfigGroupDetailsDto
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof DFSConfigGroupDetailsDto
     */
    'image'?: string;
}

export const DFSConfigGroupDetailsDtoStatusEnum = {
    Pending: 'PENDING',
    Built: 'BUILT',
    Empty: 'EMPTY',
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Success: 'SUCCESS',
    Error: 'ERROR',
    Unbuilt: 'UNBUILT',
    Skipped: 'SKIPPED',
    Building: 'BUILDING'
} as const;

export type DFSConfigGroupDetailsDtoStatusEnum = typeof DFSConfigGroupDetailsDtoStatusEnum[keyof typeof DFSConfigGroupDetailsDtoStatusEnum];


