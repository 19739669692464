import { has, get } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { NodeRecipeTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const useStyles = makeStyles({
  root: {
    width: 28,
    height: 28,
    borderRadius: "50%",
    backgroundColor: ({ status }: StyleProps) =>
      has(NodeRecipeTheme, status)
        ? get(NodeRecipeTheme, [status, "backgroundColor"])
        : NodeRecipeTheme.backgroundColor,
    border: "1px solid",
    borderColor: ({ status }: StyleProps) =>
      has(NodeRecipeTheme, status)
        ? get(NodeRecipeTheme, [status, "borderColor"])
        : NodeRecipeTheme.borderColor,
    color: ({ status }: StyleProps) =>
      has(NodeRecipeTheme, status)
        ? get(NodeRecipeTheme, [status, "iconColor"])
        : NodeRecipeTheme.iconColor,
    "&:hover": {
      cursor: "help"
    }
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "80%",
      height: "80%"
    }
  }
});

export default useStyles;
