import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  link: {
    color: "#003656",
    background: "none",
    border: "none",
    "& h6": {
      fontWeight: "initial"
    },
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  },
  linkDisabled: {
    color: "#003656",
    opacity: 0.5,
    cursor: "not-allowed"
  }
}));

export { useStyles };
