import React from "react";

import Text from "src/components/Widget/Text";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { useDeleteDataAppMutation } from "src/hooks/api/dataapps";

type Props = {
  open: boolean;
  dataAppId: string;
  dataAppName: string;
  onClose: () => void;
  onDeleteSuccess?: () => void;
};

export const DeleteDataAppModal = ({
  open,
  dataAppId,
  onDeleteSuccess,
  dataAppName,
  onClose
}: Props) => {
  const deleteDataAppMutation = useDeleteDataAppMutation();

  const handleDelete = () => {
    onClose();
    deleteDataAppMutation.mutate(
      { dataAppId, name: dataAppName },
      {
        onSuccess: () => {
          onDeleteSuccess?.();
        }
      }
    );
  };

  return open ? (
    <Modal
      open={open}
      variant={ModalVariants.Delete}
      title="Delete DataApp"
      content={[
        <>
          DataApp <b>&ldquo;{<Text value={dataAppName} />}&rdquo;</b>
        </>,
        "Are you sure you want to delete?"
      ]}
      isSubmitting={deleteDataAppMutation.isLoading}
      onClose={onClose}
      onSubmit={handleDelete}
    />
  ) : (
    <></>
  );
};
