import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const SampleSyntaxIcon: React.FC<IProps> = (props) => {
  const {
    width = 20,
    height = 20,
    viewBox = "0 0 20 20",
    fill = "none",
    color = "#4646B5"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16333_289)">
        <path
          d="M0.996322 0.0292873C0.547103 0.126944 0.154525 0.511709 0.0510094 0.957022C0.0236657 1.0703 0.0197594 2.07226 0.0197594 9.99999C0.0197594 19.8965 0.00804067 19.0234 0.152572 19.3164C0.258041 19.5312 0.480697 19.7519 0.703353 19.8613C1.00609 20.0098 0.703353 20 4.97874 20C7.95726 20 8.83226 19.9941 8.89476 19.9766C9.00413 19.9433 9.13109 19.8125 9.16038 19.7031C9.21117 19.5176 9.13304 19.3203 8.96898 19.2246L8.87718 19.1699L4.99632 19.1601L1.11742 19.1504L1.03148 19.0918C0.986556 19.0586 0.926009 18.9922 0.898666 18.9453L0.849838 18.8574V9.99999V1.14257L0.892806 1.06444C0.945541 0.966787 1.00609 0.908194 1.09788 0.869131C1.16234 0.84374 1.76195 0.839834 6.80101 0.839834C12.0901 0.841787 12.4358 0.84374 12.5041 0.87499C12.5881 0.9121 15.7444 4.05272 15.7991 4.15038C15.8264 4.20312 15.8323 4.36327 15.8401 5.70312L15.8498 7.19726L15.9045 7.28905C16.0002 7.45312 16.1955 7.53124 16.3811 7.47851C16.4963 7.44726 16.6291 7.3203 16.6584 7.21483C16.6721 7.16405 16.6799 6.65819 16.6799 5.68749C16.6799 4.44726 16.676 4.21483 16.6487 4.08788C16.6116 3.90233 16.5471 3.75976 16.4377 3.60546C16.3108 3.42968 13.1623 0.298819 13.0178 0.205069C12.9553 0.164053 12.8264 0.101553 12.7346 0.070303L12.5686 0.00975609L6.84593 0.00584984C2.00218 0.00389671 1.10374 0.00780296 0.996322 0.0292873Z"
          fill={color}
        />
        <path
          d="M6.4668 4.18164C5.77734 4.26954 5.18945 4.81446 5.05078 5.49219C5.00586 5.70899 5.00586 6.79102 5.05078 7.00782C5.24414 7.95313 6.24023 8.54297 7.16211 8.25977C7.74023 8.08204 8.18945 7.5918 8.30859 7.00782C8.35352 6.79102 8.35352 5.70899 8.30859 5.49219C8.22852 5.10352 7.97656 4.7168 7.64648 4.47461C7.44727 4.33008 7.14453 4.21485 6.87109 4.17969C6.69141 4.15821 6.64648 4.15821 6.4668 4.18164ZM6.91211 5.03711C7.21484 5.13086 7.42773 5.35743 7.49023 5.65625C7.53125 5.84571 7.5293 6.65625 7.49023 6.84375C7.40039 7.26758 6.97461 7.5586 6.55078 7.48633C6.20117 7.42579 5.92578 7.15625 5.86133 6.8086C5.83398 6.65821 5.83398 5.8418 5.86133 5.69141C5.95117 5.20118 6.44727 4.89258 6.91211 5.03711Z"
          fill={color}
        />
        <path
          d="M2.73442 4.22261C2.68559 4.24996 2.62114 4.30855 2.58794 4.35347L2.52934 4.43746L2.52348 6.21675C2.51762 8.21089 2.50981 8.10347 2.65825 8.22847C2.78325 8.33589 2.91801 8.36324 3.0684 8.3105C3.17387 8.27339 3.2559 8.19722 3.30278 8.09566C3.33794 8.02144 3.33989 7.92964 3.33989 6.248V4.47847L3.29497 4.39253C3.22465 4.25972 3.11333 4.19136 2.95317 4.18355C2.8438 4.17769 2.80864 4.18355 2.73442 4.22261Z"
          fill={color}
        />
        <path
          d="M10.2344 4.22261C10.1856 4.24996 10.1211 4.30855 10.0879 4.35347L10.0293 4.43746L10.0235 6.21675C10.0176 8.21089 10.0098 8.10347 10.1582 8.22847C10.2832 8.33589 10.418 8.36324 10.5684 8.3105C10.6739 8.27339 10.7559 8.19722 10.8028 8.09566C10.8379 8.02144 10.8399 7.92964 10.8399 6.248V4.47847L10.795 4.39253C10.7247 4.25972 10.6133 4.19136 10.4532 4.18355C10.3438 4.17769 10.3086 4.18355 10.2344 4.22261Z"
          fill={color}
        />
        <path
          d="M12.7344 4.22261C12.6856 4.24996 12.6211 4.30855 12.5879 4.35347L12.5293 4.43746L12.5235 6.21675C12.5176 8.21089 12.5098 8.10347 12.6582 8.22847C12.7832 8.33589 12.918 8.36324 13.0684 8.3105C13.1739 8.27339 13.2559 8.19722 13.3028 8.09566C13.3379 8.02144 13.3399 7.92964 13.3399 6.248V4.47847L13.295 4.39253C13.2247 4.25972 13.1133 4.19136 12.9532 4.18355C12.8438 4.17769 12.8086 4.18355 12.7344 4.22261Z"
          fill={color}
        />
        <path
          d="M13.9456 9.20703C12.2054 9.42773 10.6975 10.4492 9.84793 11.9824C9.13504 13.2656 8.9866 14.8496 9.44559 16.2598C10.0335 18.0644 11.5315 19.4375 13.3772 19.8633C14.1409 20.0391 14.9729 20.0449 15.7425 19.8789C17.2698 19.5508 18.5745 18.5762 19.3518 17.1777C19.6292 16.6777 19.8304 16.0977 19.9436 15.459C19.9866 15.2285 19.9905 15.1484 19.9905 14.5898C19.9905 14.0371 19.9866 13.9492 19.9456 13.7207C19.7835 12.791 19.4241 11.9766 18.8479 11.2305C18.6585 10.9844 18.1643 10.4941 17.9202 10.3105C17.1761 9.74413 16.4026 9.40429 15.5042 9.24609C15.2386 9.19921 15.1468 9.19335 14.678 9.18749C14.3206 9.18359 14.0843 9.18945 13.9456 9.20703ZM15.2249 10.0469C16.5393 10.2383 17.6839 10.9551 18.4065 12.041C19.428 13.5762 19.4358 15.5684 18.4261 17.1016C18.1878 17.4629 17.7991 17.8926 17.471 18.1562C17.1057 18.4473 16.6018 18.7344 16.1721 18.8926C15.9182 18.9863 15.469 19.0957 15.1858 19.1309C14.7366 19.1894 14.0862 19.1641 13.6487 19.0723C12.012 18.7266 10.6565 17.4687 10.1975 15.8691C10.0589 15.3848 10.01 14.9805 10.0257 14.4355C10.0452 13.7383 10.178 13.2012 10.4866 12.5684C11.18 11.1406 12.5823 10.1758 14.1858 10.0195C14.4221 9.99609 14.9788 10.0117 15.2249 10.0469Z"
          fill={color}
        />
        <path
          d="M14.3535 11.4062C13.8145 11.4706 13.2793 11.7949 12.9688 12.246C12.7559 12.5585 12.6172 12.9863 12.6172 13.3378C12.6172 13.5312 12.7031 13.6699 12.8672 13.7441C12.9863 13.8007 13.1113 13.7988 13.2246 13.7402C13.375 13.6621 13.4336 13.5527 13.457 13.3105C13.4863 12.9882 13.5918 12.7578 13.8008 12.5527C14.1523 12.207 14.6875 12.1288 15.125 12.3593C15.7891 12.7089 15.9453 13.5898 15.4375 14.1406C15.2246 14.3691 14.9883 14.4824 14.6621 14.5117C14.416 14.5331 14.3164 14.5859 14.2344 14.7441L14.1797 14.8476V15.3496C14.1797 15.8476 14.1797 15.8515 14.2285 15.9511C14.3145 16.123 14.5 16.2109 14.6953 16.1679C14.7695 16.1523 14.8125 16.1269 14.8828 16.0566C14.998 15.9413 15.0195 15.8593 15.0195 15.5429V15.3007L15.1543 15.2675C15.3477 15.2167 15.6484 15.0605 15.832 14.914C16.625 14.2792 16.8086 13.1367 16.252 12.2929C15.8301 11.6562 15.1094 11.3183 14.3535 11.4062Z"
          fill="#4646B5"
        />
        <path
          d="M14.4513 17.0371C14.1232 17.1289 13.9298 17.4297 13.9884 17.7539C14.0236 17.9512 14.1427 18.1094 14.3283 18.2031C14.4103 18.2441 14.4513 18.252 14.5998 18.252C14.799 18.252 14.8927 18.2168 15.0255 18.0879C15.2013 17.9238 15.2619 17.6465 15.1759 17.418C15.0705 17.1387 14.7306 16.959 14.4513 17.0371Z"
          fill="#4646B5"
        />
        <path
          d="M3.8772 10.0273C3.2522 10.1523 2.76001 10.5996 2.57642 11.2109C2.5354 11.3476 2.53149 11.4023 2.52368 12.0215C2.51587 12.7539 2.52368 12.8281 2.63501 13.1152C2.83228 13.6289 3.33032 14.0371 3.8772 14.1386C4.0354 14.1679 4.35962 14.164 4.51196 14.1328C4.92798 14.0449 5.30689 13.7949 5.54126 13.4531C5.66626 13.2734 5.70532 13.1933 5.77368 12.9883L5.83032 12.8222V12.0996C5.83032 11.2812 5.82837 11.2617 5.67603 10.9472C5.46704 10.5117 5.04907 10.166 4.59009 10.0488C4.41431 10.0058 4.04907 9.99411 3.8772 10.0273ZM4.49829 10.8965C4.73853 10.9902 4.95728 11.2656 5.00024 11.5332C5.02564 11.6875 5.02564 12.5039 5.00024 12.6386C4.93189 12.9922 4.6604 13.2597 4.30103 13.3222C3.96509 13.3828 3.58423 13.1797 3.43579 12.8633C3.35571 12.6894 3.34009 12.5703 3.34009 12.0801C3.34009 11.6015 3.35571 11.4726 3.42603 11.3164C3.47876 11.2011 3.63892 11.0195 3.74439 10.9531C3.94165 10.832 4.26782 10.8086 4.49829 10.8965Z"
          fill="#4646B5"
        />
        <path
          d="M7.79688 10.0254C7.69922 10.0625 7.59766 10.1523 7.55664 10.2422C7.52148 10.3184 7.51953 10.4043 7.51953 12.0918V13.8613L7.56445 13.9473C7.58984 13.9941 7.64844 14.0586 7.69336 14.0918C7.76953 14.1445 7.79102 14.1504 7.92969 14.1504C8.06836 14.1504 8.08984 14.1445 8.16602 14.0918C8.21094 14.0586 8.26953 13.9941 8.29492 13.9473L8.33984 13.8613V12.0918C8.33984 10.4121 8.33789 10.3184 8.30273 10.2422C8.21875 10.0566 7.98633 9.95703 7.79688 10.0254Z"
          fill="#4646B5"
        />
      </g>
      <defs>
        <clipPath id="clip0_16333_289">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SampleSyntaxIcon;
