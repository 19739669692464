import React from "react";
import { Button, Grid, Typography, makeStyles, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";
import noDatasetsSvg from "src/assets/images/no-datasets.svg";
import { READONLY_ENTITY } from "src/constants";

const useStyles = makeStyles({
  noDatasetsContainerWrap: {
    width: "100%",
    height: "100%",
    flexWrap: "nowrap",
    paddingTop: "5%",
    alignItems: "center",
    backgroundColor: "#ffffff"
  },
  noDatasetsSvgWrap: {
    position: "relative",
    textAlign: "center"
  },
  addDatasetsText: {
    position: "absolute",
    bottom: "0",
    left: "0",
    right: "0",
    marginBottom: "16px"
  }
});

export const EmptyRecipeContainer = ({
  openAddEntitiesDialog,
  isDefaultScenario
}: {
  openAddEntitiesDialog: () => void;
  isDefaultScenario: boolean;
}) => {
  const classes = useStyles();
  const { barMainButton } = useSubtopBarStyles();
  return (
    <Grid
      container
      direction="column"
      className={`${classes.noDatasetsContainerWrap} container-height`}>
      <Grid container direction="column" className={classes.noDatasetsSvgWrap}>
        <Grid component="img" test-id="emptyRecipePageImage" src={noDatasetsSvg} />
        <Typography className={classes.addDatasetsText} test-id="emptyRecipePageText">
          Add Entities to choose transformations
        </Typography>
      </Grid>
      <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
        <div>
          <Button
            color="primary"
            variant="contained"
            className={barMainButton}
            startIcon={<AddIcon fontSize="small" />}
            test-id="emptyRecipePageAddEntitiesBtn"
            onClick={openAddEntitiesDialog}
            disabled={!isDefaultScenario}>
            Add Entities
          </Button>
        </div>
      </Tooltip>
    </Grid>
  );
};
