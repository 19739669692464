import React, { useEffect, useState, useMemo } from "react";

// Packages
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { capitalize, isEmpty, lowerCase, map, startCase } from "lodash";

// MUI
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  CircularProgress,
  Typography
} from "@material-ui/core";

// Icons
import { Alert } from "@material-ui/lab";

// Utils
import { PatternRexExps } from "src/utils";

// Components
import PasswordInput from "src/components/PasswordInput";
import Wrapper from "./Wrapper";

// Constants
import { PublicScreenLabels } from "src/constants";

// Styles
import { useStyles } from "./ResetPassword.styles";
import { loginPath } from "src/routing/routes";

const ResetPassword = () => {
  const classes = useStyles();
  // $FixMe: Scope to be refactored.
  // Old code - STARTS >>
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [resetLinkConsentError, setResetLinkConsentError] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({ defaultValues: { password: "", password_repeat: "" } });
  const formPassword = watch("password");
  const navigate = useNavigate();
  const search = useLocation().search;
  const details = new URLSearchParams(search).get("details");

  const handleResetPasswordClick = async (data: $TSFixMe) => {
    setIsResettingPassword(() => true);
    try {
      await axios.put("/api/password", {
        email: email,
        password: data.password,
        passwordResetRequestDetails: details
      });
      navigate(loginPath);
    } catch (err: $TSFixMe) {
      const errorMessage = err.response.data.msg || err.message;
      setError(errorMessage);
    } finally {
      setIsResettingPassword(() => false);
    }
  };

  useEffect(() => {
    async function callToAction(details: $TSFixMe) {
      try {
        const serverResponse = await axios.get(
          `/api/password/reset-link/consent?details=${details}`
        );
        setEmail(serverResponse.data.email);
      } catch (err: $TSFixMe) {
        setResetLinkConsentError(err?.response?.data?.msg || err?.message);
      }
    }

    if (details) {
      callToAction(details);
    }
  }, [details]);
  // << ENDS - Old code

  const passwordError = useMemo(() => {
    const errorsList = [];

    if (errors?.password?.type === "required") {
      errorsList.push(`${startCase(PublicScreenLabels.Password)} is required!`);
    }

    if (errors?.password?.type === "pattern") {
      errorsList.push(
        `${startCase(
          PublicScreenLabels.Password
        )} should be at least 9 characters long, contain at least one lowercase, one uppercase, one digit and one special character.`
      );
    }

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`passwordError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ marginTop: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [errors?.password?.type]);

  const confirmPasswordError = useMemo(() => {
    const errorsList = [];

    if (errors?.password_repeat?.type === "validate" && !!errors?.password_repeat?.message) {
      errorsList.push(errors?.password_repeat?.message);
    }

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`confirmPasswordError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ marginTop: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [errors?.password_repeat?.type, errors?.password_repeat?.message]);

  const resetPasswordError = useMemo(() => {
    const errorsList: $TSFixMe[] = [];

    (!!error || !!resetLinkConsentError) && errorsList.push(error || resetLinkConsentError);

    if (!isEmpty(errorsList)) {
      return (
        <List dense disablePadding>
          {map(errorsList, (error: string | $TSFixMe, index: number) => (
            <ListItem key={`resetPasswordError-${index}`} disableGutters>
              <ListItemText primary={error} disableTypography style={{ margin: 0 }} />
            </ListItem>
          ))}
        </List>
      );
    }

    return "";
  }, [error, resetLinkConsentError]);

  return (
    <Wrapper>
      <Grid item className={classes.titleContainer}>
        <Typography variant="h5" component="div" align="center" data-testid="resetPasswordTitle">
          Reset your {lowerCase(PublicScreenLabels.Password)}
        </Typography>
      </Grid>

      <Grid
        container
        component="form"
        justifyContent="center"
        className={classes.formContainer}
        onSubmit={handleSubmit(handleResetPasswordClick)}>
        <Typography variant="body2" data-testid="resetPasswordMessageLine1">
          To reset password, enter a new one below
        </Typography>

        <TextField
          id="resetPasswordEmail"
          data-testid="resetPasswordEmail"
          label={startCase(PublicScreenLabels.Email)}
          variant="outlined"
          size="small"
          color="primary"
          value={email}
          fullWidth
          disabled
        />
        <PasswordInput
          // @ts-ignore
          id="resetPasswordPassword"
          data-testid="resetPasswordPassword"
          variant="outlined"
          size="small"
          color="primary"
          fullWidth
          // eslint-disable-next-line
          autoFocus
          autoComplete="off"
          disabled={!!isResettingPassword || !!resetLinkConsentError}
          {...register("password", {
            required: true,
            pattern: PatternRexExps.Password
          })}
          {...(!!passwordError
            ? {
                error: true,
                helperText: passwordError
              }
            : {})}
        />
        <PasswordInput
          // @ts-ignore
          id="resetPasswordConfirmPassword"
          data-testid="resetPasswordConfirmPassword"
          variant="outlined"
          size="small"
          color="primary"
          fullWidth
          autoComplete="off"
          label={capitalize(PublicScreenLabels.ConfirmPassword)}
          disabled={!!isResettingPassword || !!resetLinkConsentError}
          {...register("password_repeat", {
            validate: (value) => {
              return value === formPassword || "The passwords do not match";
            }
          })}
          {...(!!confirmPasswordError
            ? {
                error: true,
                helperText: confirmPasswordError
              }
            : {})}
        />

        <Button
          id="resetPasswordAction"
          data-testid="resetPasswordAction"
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          fullWidth
          className={classes.action}
          disabled={!!isResettingPassword || !!error || !!resetLinkConsentError}>
          {!!isResettingPassword ? (
            <>
              <CircularProgress size={16} style={{ marginRight: 5 }} />
              Resetting password...
            </>
          ) : (
            `Reset ${startCase(PublicScreenLabels.Password)}`
          )}
        </Button>

        {!!resetPasswordError && (
          <Alert
            variant="outlined"
            severity="error"
            className={classes.errorContainer}
            data-testid="resetPasswordErrorContainer">
            <Typography variant="caption">{resetPasswordError}</Typography>
          </Alert>
        )}
      </Grid>
    </Wrapper>
  );
};

export default ResetPassword;
