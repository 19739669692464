import React from "react";

export const CloudUploadIcon = ({ width = 100, height = 100, fill = "white" }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 101 101"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2" clipPath="url(#clip0_3763_116764)">
        <path
          d="M81.6998 27.6731C77.3591 19.9261 68.8156 15.4824 59.6537 16.9454C56.0574 9.39603 48.5335 4.60205 40.0728 4.60205C28.0976 4.60205 18.3542 14.3449 18.3542 26.3222C18.3542 26.8166 18.3737 27.3261 18.4181 27.8567C8.08243 30.212 0.5 39.5438 0.5 50.291C0.5 62.9912 10.8319 73.3215 23.5321 73.3215H41.7372V69.5179H23.5321C12.9289 69.5179 4.30584 60.8931 4.30584 50.2916C4.30584 40.8022 11.3901 32.629 20.7886 31.2841C21.2927 31.2126 21.7459 30.9413 22.048 30.5304C22.3485 30.1216 22.4785 29.6077 22.3967 29.1064C22.2359 28.0754 22.1547 27.1669 22.1547 26.3239C22.1547 16.443 30.1962 8.40518 40.0733 8.40518C47.4928 8.40518 54.0367 12.8775 56.7516 19.7972C57.0927 20.6749 58.0245 21.1693 58.9487 20.9559C67.3006 19.0241 75.1483 23.0584 78.7679 30.2515C79.043 30.8076 79.5817 31.1915 80.1979 31.2786C89.6017 32.6176 96.7017 40.7913 96.7017 50.29C96.7017 60.8915 88.0727 69.5162 77.4695 69.5162H53.2321V73.3199H77.4679C90.1697 73.3199 100.5 62.9896 100.5 50.2894C100.5 39.2379 92.4833 29.6846 81.6998 27.6731Z"
          fill="#010002"
        />
        <path
          d="M59.2937 49.3393C60.055 48.5796 60.055 47.3511 59.2937 46.5914L48.4404 35.7365C48.0608 35.3585 47.5665 35.1707 47.0683 35.1707L47.0337 35.1766C47.0239 35.1766 47.0142 35.1707 47.0044 35.1707C46.5004 35.1707 46.0028 35.3661 45.6324 35.7365L34.7791 46.592C34.0194 47.3516 34.0194 48.5802 34.7791 49.3398C35.5387 50.1011 36.7673 50.1011 37.5269 49.3398L45.059 41.8072V94.4505C45.059 95.528 45.927 96.3981 47.0044 96.3981C48.076 96.3981 48.9482 95.528 48.9482 94.4505V41.7395L56.5464 49.3393C57.3033 50.1011 58.534 50.1011 59.2937 49.3393Z"
          fill="#010002"
        />
      </g>
      <defs>
        <clipPath id="clip0_3763_116764">
          <rect width="100" height="100" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  </>
);
