/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { ConversationNameChanges } from './conversation-name-changes';

/**
 * 
 * @export
 * @interface UpdateDataConversationRequest
 */
export interface UpdateDataConversationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDataConversationRequest
     */
    'mode'?: UpdateDataConversationRequestModeEnum;
    /**
     * 
     * @type {ConversationNameChanges}
     * @memberof UpdateDataConversationRequest
     */
    'conversationNameChanges'?: ConversationNameChanges;
    /**
     * 
     * @type {string}
     * @memberof UpdateDataConversationRequest
     */
    'historyId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateDataConversationRequest
     */
    'outputNameChanges'?: { [key: string]: string; };
}

export const UpdateDataConversationRequestModeEnum = {
    Recipe: 'RECIPE',
    Dataapp: 'DATAAPP'
} as const;

export type UpdateDataConversationRequestModeEnum = typeof UpdateDataConversationRequestModeEnum[keyof typeof UpdateDataConversationRequestModeEnum];


