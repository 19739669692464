/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AutoMlProblemTypesResponse } from '../Models';
// @ts-ignore
import type { CreateDFSTemplateDto } from '../Models';
// @ts-ignore
import type { DFSProblemTypeDto } from '../Models';
// @ts-ignore
import type { DFSTemplateDto } from '../Models';
// @ts-ignore
import type { DFSTemplateInputDto } from '../Models';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { UpdateDFSTemplateDto } from '../Models';
/**
 * DfsTemplateControllerV2Api - axios parameter creator
 * @export
 */
export const DfsTemplateControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoMlProblemTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/dfs-templates/auto-ml-problem-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDFSTemplateDto} createDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate: async (createDFSTemplateDto: CreateDFSTemplateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDFSTemplateDto' is not null or undefined
            assertParamExists('createTemplate', 'createDFSTemplateDto', createDFSTemplateDto)
            const localVarPath = `/v2/dfs-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDFSTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTemplate', 'id', id)
            const localVarPath = `/v2/dfs-templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [targetColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedAutoMlProblemType: async (id?: string, targetColumn?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/dfs-templates/get-problem-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (targetColumn !== undefined) {
                localVarQueryParameter['targetColumn'] = targetColumn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate: async (templateId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getTemplate', 'templateId', templateId)
            const localVarPath = `/v2/dfs-templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {GetTemplatesSourceEnum} [source] 
         * @param {GetTemplatesStatusEnum} [status] 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates: async (name?: string, projectId?: string, source?: GetTemplatesSourceEnum, status?: GetTemplatesStatusEnum, tags?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/dfs-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateTemplate: async (templateId: string, updateDFSTemplateDto: UpdateDFSTemplateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('partiallyUpdateTemplate', 'templateId', templateId)
            // verify required parameter 'updateDFSTemplateDto' is not null or undefined
            assertParamExists('partiallyUpdateTemplate', 'updateDFSTemplateDto', updateDFSTemplateDto)
            const localVarPath = `/v2/dfs-templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDFSTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (updateDFSTemplateDto: UpdateDFSTemplateDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDFSTemplateDto' is not null or undefined
            assertParamExists('updateTemplate', 'updateDFSTemplateDto', updateDFSTemplateDto)
            const localVarPath = `/v2/dfs-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDFSTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {Array<DFSTemplateInputDto>} dFSTemplateInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateInputs: async (templateId: string, dFSTemplateInputDto: Array<DFSTemplateInputDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateTemplateInputs', 'templateId', templateId)
            // verify required parameter 'dFSTemplateInputDto' is not null or undefined
            assertParamExists('updateTemplateInputs', 'dFSTemplateInputDto', dFSTemplateInputDto)
            const localVarPath = `/v2/dfs-templates/{templateId}/update-inputs`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dFSTemplateInputDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateMarkdown: async (templateId: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateTemplateMarkdown', 'templateId', templateId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTemplateMarkdown', 'body', body)
            const localVarPath = `/v2/dfs-templates/{templateId}/update-markdown`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DfsTemplateControllerV2Api - functional programming interface
 * @export
 */
export const DfsTemplateControllerV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DfsTemplateControllerV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoMlProblemTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AutoMlProblemTypesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoMlProblemTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.autoMlProblemTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateDFSTemplateDto} createDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplate(createDFSTemplateDto: CreateDFSTemplateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(createDFSTemplateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.createTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.deleteTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [targetColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedAutoMlProblemType(id?: string, targetColumn?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSProblemTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedAutoMlProblemType(id, targetColumn, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.getRelatedAutoMlProblemType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplate(templateId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplate(templateId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.getTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {GetTemplatesSourceEnum} [source] 
         * @param {GetTemplatesStatusEnum} [status] 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplates(name?: string, projectId?: string, source?: GetTemplatesSourceEnum, status?: GetTemplatesStatusEnum, tags?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DFSTemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplates(name, projectId, source, status, tags, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.getTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partiallyUpdateTemplate(templateId: string, updateDFSTemplateDto: UpdateDFSTemplateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partiallyUpdateTemplate(templateId, updateDFSTemplateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.partiallyUpdateTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(updateDFSTemplateDto: UpdateDFSTemplateDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(updateDFSTemplateDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.updateTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {Array<DFSTemplateInputDto>} dFSTemplateInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplateInputs(templateId: string, dFSTemplateInputDto: Array<DFSTemplateInputDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplateInputs(templateId, dFSTemplateInputDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.updateTemplateInputs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplateMarkdown(templateId: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplateMarkdown(templateId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsTemplateControllerV2Api.updateTemplateMarkdown']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DfsTemplateControllerV2Api - factory interface
 * @export
 */
export const DfsTemplateControllerV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DfsTemplateControllerV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoMlProblemTypes(options?: any): AxiosPromise<Array<AutoMlProblemTypesResponse>> {
            return localVarFp.autoMlProblemTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateDFSTemplateDto} createDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(createDFSTemplateDto: CreateDFSTemplateDto, options?: any): AxiosPromise<DFSTemplateDto> {
            return localVarFp.createTemplate(createDFSTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [targetColumn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedAutoMlProblemType(id?: string, targetColumn?: string, options?: any): AxiosPromise<DFSProblemTypeDto> {
            return localVarFp.getRelatedAutoMlProblemType(id, targetColumn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(templateId: string, options?: any): AxiosPromise<DFSTemplateDto> {
            return localVarFp.getTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [projectId] 
         * @param {GetTemplatesSourceEnum} [source] 
         * @param {GetTemplatesStatusEnum} [status] 
         * @param {string} [tags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(name?: string, projectId?: string, source?: GetTemplatesSourceEnum, status?: GetTemplatesStatusEnum, tags?: string, options?: any): AxiosPromise<Array<DFSTemplateDto>> {
            return localVarFp.getTemplates(name, projectId, source, status, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partiallyUpdateTemplate(templateId: string, updateDFSTemplateDto: UpdateDFSTemplateDto, options?: any): AxiosPromise<DFSTemplateDto> {
            return localVarFp.partiallyUpdateTemplate(templateId, updateDFSTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(updateDFSTemplateDto: UpdateDFSTemplateDto, options?: any): AxiosPromise<DFSTemplateDto> {
            return localVarFp.updateTemplate(updateDFSTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {Array<DFSTemplateInputDto>} dFSTemplateInputDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateInputs(templateId: string, dFSTemplateInputDto: Array<DFSTemplateInputDto>, options?: any): AxiosPromise<DFSTemplateDto> {
            return localVarFp.updateTemplateInputs(templateId, dFSTemplateInputDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplateMarkdown(templateId: string, body: string, options?: any): AxiosPromise<DFSTemplateDto> {
            return localVarFp.updateTemplateMarkdown(templateId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DfsTemplateControllerV2Api - interface
 * @export
 * @interface DfsTemplateControllerV2Api
 */
export interface DfsTemplateControllerV2ApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    autoMlProblemTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<AutoMlProblemTypesResponse>>;

    /**
     * 
     * @param {CreateDFSTemplateDto} createDFSTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    createTemplate(createDFSTemplateDto: CreateDFSTemplateDto, options?: RawAxiosRequestConfig): AxiosPromise<DFSTemplateDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    deleteTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [targetColumn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    getRelatedAutoMlProblemType(id?: string, targetColumn?: string, options?: RawAxiosRequestConfig): AxiosPromise<DFSProblemTypeDto>;

    /**
     * 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    getTemplate(templateId: string, options?: RawAxiosRequestConfig): AxiosPromise<DFSTemplateDto>;

    /**
     * 
     * @param {string} [name] 
     * @param {string} [projectId] 
     * @param {GetTemplatesSourceEnum} [source] 
     * @param {GetTemplatesStatusEnum} [status] 
     * @param {string} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    getTemplates(name?: string, projectId?: string, source?: GetTemplatesSourceEnum, status?: GetTemplatesStatusEnum, tags?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DFSTemplateDto>>;

    /**
     * 
     * @param {string} templateId 
     * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    partiallyUpdateTemplate(templateId: string, updateDFSTemplateDto: UpdateDFSTemplateDto, options?: RawAxiosRequestConfig): AxiosPromise<DFSTemplateDto>;

    /**
     * 
     * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    updateTemplate(updateDFSTemplateDto: UpdateDFSTemplateDto, options?: RawAxiosRequestConfig): AxiosPromise<DFSTemplateDto>;

    /**
     * 
     * @param {string} templateId 
     * @param {Array<DFSTemplateInputDto>} dFSTemplateInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    updateTemplateInputs(templateId: string, dFSTemplateInputDto: Array<DFSTemplateInputDto>, options?: RawAxiosRequestConfig): AxiosPromise<DFSTemplateDto>;

    /**
     * 
     * @param {string} templateId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2ApiInterface
     */
    updateTemplateMarkdown(templateId: string, body: string, options?: RawAxiosRequestConfig): AxiosPromise<DFSTemplateDto>;

}

/**
 * DfsTemplateControllerV2Api - object-oriented interface
 * @export
 * @class DfsTemplateControllerV2Api
 * @extends {BaseAPI}
 */
export class DfsTemplateControllerV2Api extends BaseAPI implements DfsTemplateControllerV2ApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public autoMlProblemTypes(options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).autoMlProblemTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateDFSTemplateDto} createDFSTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public createTemplate(createDFSTemplateDto: CreateDFSTemplateDto, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).createTemplate(createDFSTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public deleteTemplate(id: string, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).deleteTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [targetColumn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public getRelatedAutoMlProblemType(id?: string, targetColumn?: string, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).getRelatedAutoMlProblemType(id, targetColumn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public getTemplate(templateId: string, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).getTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {string} [projectId] 
     * @param {GetTemplatesSourceEnum} [source] 
     * @param {GetTemplatesStatusEnum} [status] 
     * @param {string} [tags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public getTemplates(name?: string, projectId?: string, source?: GetTemplatesSourceEnum, status?: GetTemplatesStatusEnum, tags?: string, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).getTemplates(name, projectId, source, status, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public partiallyUpdateTemplate(templateId: string, updateDFSTemplateDto: UpdateDFSTemplateDto, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).partiallyUpdateTemplate(templateId, updateDFSTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDFSTemplateDto} updateDFSTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public updateTemplate(updateDFSTemplateDto: UpdateDFSTemplateDto, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).updateTemplate(updateDFSTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {Array<DFSTemplateInputDto>} dFSTemplateInputDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public updateTemplateInputs(templateId: string, dFSTemplateInputDto: Array<DFSTemplateInputDto>, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).updateTemplateInputs(templateId, dFSTemplateInputDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTemplateControllerV2Api
     */
    public updateTemplateMarkdown(templateId: string, body: string, options?: RawAxiosRequestConfig) {
        return DfsTemplateControllerV2ApiFp(this.configuration).updateTemplateMarkdown(templateId, body, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetTemplatesSourceEnum = {
    System: 'SYSTEM',
    Project: 'PROJECT',
    Tenant: 'TENANT',
    Custom: 'CUSTOM'
} as const;
export type GetTemplatesSourceEnum = typeof GetTemplatesSourceEnum[keyof typeof GetTemplatesSourceEnum];
/**
 * @export
 */
export const GetTemplatesStatusEnum = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;
export type GetTemplatesStatusEnum = typeof GetTemplatesStatusEnum[keyof typeof GetTemplatesStatusEnum];
