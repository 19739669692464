import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_ENV_RESOURCE_USAGE = "query-env-resource-usage";

const useGetEnvironmentResourceUsage = (
  envId: string,
  options?: UseQueryOptions<any>
): UseQueryResult<any> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_ENV_RESOURCE_USAGE, envId],
    queryFn: async () => {
      return await getAPIWithRethrow(`/v2/envs/${envId}/resource-usage`);
    },
    ...options
  });
};

export default useGetEnvironmentResourceUsage;
export { QUERY_KEY_ENV_RESOURCE_USAGE };
