import shallow from "zustand/shallow";

import { postAPIWithRethrow } from "./apiService";

import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";

import useAuthStore from "../stores/auth.store";

const useSwitchTenant = () => {
  const [tenantId] = useAuthStore((state: $TSFixMe) => [state.tenantId], shallow);
  const { setAuthResponse } = useAuthSetAuthResponse();

  const switchTenant = async (tenantId: $TSFixMe) => {
    try {
      // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
      const tenantSwitchResponse = await postAPIWithRethrow("/switch", { tenantId });
      setAuthResponse({ data: tenantSwitchResponse });

      // history.push(`/`);
    } catch (error) {
      console.error({ error });
    } finally {
      window.location.href = "/";
    }
  };

  window.addEventListener("storage", (event) => {
    if (event.storageArea === localStorage && event.key === "tenantId") {
      const thisTenantId = event.newValue;
      try {
        if (!!tenantId && !!thisTenantId && tenantId !== thisTenantId) {
          switchTenant(thisTenantId);
        }
      } catch (e) {
        console.error(e);
      }
    }
  });
};

export default useSwitchTenant;
