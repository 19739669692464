import React from "react";

export const DeleteNew = ({
  size = "medium",
  disabled
}: {
  disabled?: boolean;
  size?: "medium" | "small" | "tiny";
}) => {
  if (size === "tiny") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14 3.98732C11.78 3.76732 9.54667 3.65398 7.32 3.65398C6 3.65398 4.68 3.72065 3.36 3.85398L2 3.98732M5.66667 3.31398L5.81333 2.44065C5.92 1.80732 6 1.33398 7.12667 1.33398H8.87333C10 1.33398 10.0867 1.83398 10.1867 2.44732L10.3333 3.31398M12.5667 6.09398L12.1333 12.8073C12.06 13.854 12 14.6673 10.14 14.6673H5.86C4 14.6673 3.94 13.854 3.86667 12.8073L3.43333 6.09398M6.88667 11.0007H9.10667M6.33333 8.33398H9.66667"
          stroke="#515151"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg
      width={size === "small" ? "16" : "18"}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: disabled ? 0.5 : "default" }}>
      <path
        d="M19 4.98C15.67 4.65 12.32 4.48 8.98 4.48C7 4.48 5.02 4.58 3.04 4.78L1 4.98M6.5 3.97L6.72 2.66C6.88 1.71 7 1 8.69 1H11.31C13 1 13.13 1.75 13.28 2.67L13.5 3.97M16.85 8.14L16.2 18.21C16.09 19.78 16 21 13.21 21H6.79C4 21 3.91 19.78 3.8 18.21L3.15 8.14M8.33 15.5H11.66M7.5 11.5H12.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
