import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { handleResponse, postAPIWithRethrow } from "src/utils/apiService";

interface IVariables {
  projectId: string;
  name: string;
  path: string;
}

const useAddPredictionOutputToCanvas = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  return useMutation({
    mutationFn: async ({ projectId, name, path }) => {
      const createEntityResponse = await postAPIWithRethrow("/v2/entities", {
        projectId,
        name,
        entityMeta: { entityOntology: "USER", entityType: "EVENT" }
      });

      const id = createEntityResponse.id;

      if (id) {
        return await postAPIWithRethrow(
          `/v2/entities/${id}/process-uploaded-file?fileName=${name}&existingFilePath=${path}`,
          {}
        );
      }
    },
    onSuccess: () => {
      handleResponse({ successMessage: "Output successfully added to canvas" });
    }
  });
};

export default useAddPredictionOutputToCanvas;
