import React from "react";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => {
    const decodedString = search ? decodeURIComponent(search) : "";
    return new URLSearchParams(decodedString);
  }, [search]);
};

export default useQuery;
