import React, { Dispatch, SetStateAction } from "react";

// Packages
import { Column } from "@tanstack/react-table";

// MUI
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Icons
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";

// Constants
import { TableColumnSortingDirections } from "../../utils/Table.constants";

// Components
import SearchField from "./SearchField";
// import Selection from "./Selection";

// Contexts
import { useTableContext } from "../../context/useTableContext";

type Props = {
  column: Column<any, unknown>;
  anchorMoreOptionsEl: SVGElement | null;
  setMoreOptionsAnchorEl: Dispatch<SetStateAction<SVGElement | null>>;
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: 300,
    borderRadius: theme.spacing(1.5)
  },
  menuItem: {
    columnGap: theme.spacing(1),
    fontSize: "small"
  },
  menuItemSelection: {
    flexDirection: "column"
  }
}));

const MoreOptions = (props: Props) => {
  const { column, anchorMoreOptionsEl, setMoreOptionsAnchorEl } = props || {};

  const classes = useStyles();

  const { hideColumn } = useTableContext();

  const onClose = () => {
    setMoreOptionsAnchorEl(() => null);
  };

  const onHide = () => {
    hideColumn(column?.columnDef?.header);
    onClose();
  };

  const onSort = (direction: string) => {
    column?.toggleSorting(direction === TableColumnSortingDirections.Desc);

    onClose();
  };

  return (
    <Menu
      open
      anchorEl={anchorMoreOptionsEl}
      keepMounted
      onClose={onClose}
      PopoverClasses={{ paper: classes.container }}>
      <MenuItem disableRipple className={classes.menuItem} onClick={onHide}>
        <VisibilityOffOutlinedIcon fontSize="small" />
        Hide
      </MenuItem>
      <Divider />
      <MenuItem
        disableRipple
        className={classes.menuItem}
        onClick={() => onSort(TableColumnSortingDirections.Asc)}>
        <ArrowDropUpRoundedIcon fontSize="small" />
        Sort Ascending
      </MenuItem>
      <Divider />
      <MenuItem
        disableRipple
        className={classes.menuItem}
        onClick={() => onSort(TableColumnSortingDirections.Desc)}>
        <ArrowDropDownRoundedIcon fontSize="small" />
        Sort Descending
      </MenuItem>
      <Divider />
      <MenuItem
        disableRipple
        className={classes.menuItem}
        onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => event?.stopPropagation()}>
        <SearchField column={column} />
      </MenuItem>
      {/* Hiding excel-like column filter for time-being, as it needs to be customized. */}
      {/* <Divider />
      <MenuItem
        className={classes.menuItemSelection}
        disableRipple
        disableGutters
        onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => event?.stopPropagation()}>
        <Selection column={column} />
      </MenuItem> */}
    </Menu>
  );
};

export default MoreOptions;
