import React from "react";

import {
  ArtifactsAndModelsIcon,
  ConnectorsIcon,
  DashboardIcon,
  DataappsIcon,
  EnvironmentsIcon,
  ProjectsIcon
} from "src/icons/NewUX";

import { Roles } from "src/types";

export interface IItem {
  path: string;
  label: string;
  icon: React.JSX.Element;
  menuIcon?: $TSFixMeFunction;
  isMainMenu?: boolean | undefined;
  allowedRoles?: string[];
  activePaths?: string[];
  type?: string;
}

export interface IProjectMenuItem {
  pathName: string;
  path: string;
  label: string;
  icon: (active?: boolean) => React.JSX.Element;
  allowedRoles: string[];
}

export enum ProjectMeuItemLabels {
  Canvas = "Canvas",
  Scenarios = "Scenarios",
  PredictionJob = "Prediction Job",
  Jobs = "Jobs",
  Settings = "Settings",
  DataApps = "DataApps",
  About = "About"
}

const NAVBAR_SECTION = [
  [
    {
      path: "/",
      label: "Dashboard",
      menuIcon: DashboardIcon,
      isMainMenu: true,
      allowedRoles: [
        Roles.Admin.name,
        Roles.Demo.name,
        Roles.User.name,
        Roles["Business User"].name
      ]
    }
  ],
  [
    {
      path: "/projects",
      label: "Projects",
      menuIcon: ProjectsIcon,
      activePaths: ["view-data", "recipe-run-logs", "variables", "scenarios"],
      allowedRoles: [
        Roles.Admin.name,
        Roles.Demo.name,
        Roles.User.name,
        Roles["Business User"].name
      ]
    },
    {
      path: "/dataapps-ui",
      label: "DataApps",
      menuIcon: DataappsIcon,
      allowedRoles: [
        Roles.Admin.name,
        Roles.Demo.name,
        Roles.User.name,
        Roles.DataAppView.name,
        Roles["Business User"].name
      ]
    }
  ],
  [
    {
      path: "/data-connectors",
      label: "Connectors",
      menuIcon: ConnectorsIcon,
      allowedRoles: [
        Roles.Admin.name,
        Roles.Demo.name,
        Roles.User.name,
        Roles["Business User"].name
      ]
    },
    {
      path: "/artifacts-and-models/artifacts",
      label: "Artifacts & Models",
      menuIcon: ArtifactsAndModelsIcon,
      allowedRoles: [Roles.Admin.name, Roles.Demo.name, Roles.User.name]
    },
    {
      path: "/environments",
      label: "Environments",
      menuIcon: EnvironmentsIcon,
      allowedRoles: [Roles.Admin.name, Roles.User.name]
    }
  ]
];

export { NAVBAR_SECTION };
