import React from "react";

export const SegmentsIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14855_118177)">
        <path
          d="M11.333 6.66732H12.6663C13.9997 6.66732 14.6663 6.00065 14.6663 4.66732V3.33398C14.6663 2.00065 13.9997 1.33398 12.6663 1.33398H11.333C9.99967 1.33398 9.33301 2.00065 9.33301 3.33398V4.66732C9.33301 6.00065 9.99967 6.66732 11.333 6.66732Z"
          stroke="#292D32"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33301 14.6673H4.66634C5.99967 14.6673 6.66634 14.0007 6.66634 12.6673V11.334C6.66634 10.0007 5.99967 9.33398 4.66634 9.33398H3.33301C1.99967 9.33398 1.33301 10.0007 1.33301 11.334V12.6673C1.33301 14.0007 1.99967 14.6673 3.33301 14.6673Z"
          stroke="#292D32"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.99967 6.66732C5.47243 6.66732 6.66634 5.47341 6.66634 4.00065C6.66634 2.52789 5.47243 1.33398 3.99967 1.33398C2.52691 1.33398 1.33301 2.52789 1.33301 4.00065C1.33301 5.47341 2.52691 6.66732 3.99967 6.66732Z"
          stroke="#292D32"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9997 14.6673C13.4724 14.6673 14.6663 13.4734 14.6663 12.0007C14.6663 10.5279 13.4724 9.33398 11.9997 9.33398C10.5269 9.33398 9.33301 10.5279 9.33301 12.0007C9.33301 13.4734 10.5269 14.6673 11.9997 14.6673Z"
          stroke="#292D32"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14855_118177">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
