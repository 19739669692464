import React from "react";
import { makeStyles, Button } from "@material-ui/core";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import RocketIcon from "src/icons/NewUX/RocketIcon";

interface IProps {
  onClick: () => void;
}

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    marginBottom: "64px",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    minHeight: "calc(100vh - 225px)"
  },
  buttonWrapper: {
    marginBottom: "10px"
  }
});

const NoProjectFound: React.FC<IProps> = (props) => {
  const { wrapper, buttonWrapper } = useStyles();

  return (
    <NewThemeWrapper>
      <div className={wrapper}>
        <div>
          <RocketIcon />
        </div>
        <div>
          <div className={buttonWrapper}>Add a new project and start your journey</div>
          <Button variant="outlined" color="primary" onClick={props.onClick}>
            Add Project
          </Button>
        </div>
      </div>
    </NewThemeWrapper>
  );
};

export default NoProjectFound;
