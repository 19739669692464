/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";

import { getEntityFeatures } from "src/api";
import { Entity, EntityFeaturesResponse } from "src/types";

export const useGetEntityFeatures = ({
  datasetsToFetch,
  scenarioId,
  jobRunId,
  onSuccess
}: {
  datasetsToFetch: Array<Entity> | [];
  scenarioId?: string;
  jobRunId?: string;
  onSuccess?: (entityFeatures: EntityFeaturesResponse) => void;
}) => {
  return useQuery<EntityFeaturesResponse>({
    queryKey: ["entityFeatures", datasetsToFetch?.map((data) => data.id)],
    queryFn: () => {
      return Promise.all(
        datasetsToFetch?.map((dataset) => getEntityFeatures(dataset?.id, scenarioId, jobRunId))
      );
    },
    enabled: !!datasetsToFetch?.length,
    onSuccess
  });
};
