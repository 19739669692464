import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import useReloadDatasets from "hooks/api/entities/useReloadDatasets";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";

interface IProps {
  id?: string;
  name: string;
  open: boolean;
  isFiveTran: boolean;
  projectId?: string;
  onSuccess: () => void;
  onCancel: () => void;
}

const ReloadDatasetModal: React.FC<IProps> = (props) => {
  const { id, projectId, name, isFiveTran, open, onCancel, onSuccess } = props;

  const reloadDatasets = useReloadDatasets();

  const handleSubmit = () => {
    checkEnvRelaunch(projectId);
    if (id) {
      reloadDatasets.mutate({ id, name }, { onSuccess });
    }
  };

  return (
    <Modal
      open={open}
      variant={ModalVariants.Delete}
      title="Reload Dataset"
      content={[
        "If you proceed with this action, it will render the current runs of the associated recipe(s) with this input dataset invalid, marking them as UNBUILT. To implement the changes, it is necessary to rerun the linked and subsequent recipe(s)",
        "Are you sure you want to proceed with this?",
        isFiveTran ? (
          <span key="note" style={{ color: "grey", fontStyle: "italic" }}>
            <b>Note:</b> This action will overwrite the current dataset with latest data and schema
            in corresponding data connector. Ensure that the schema in the data connector matches
            that of the dataset; otherwise, linked recipes may encounter failures. Also the
            associated segment(if any) will be deleted, and any custom scenarios using it will
            default to the entire dataset instead of the segment.
          </span>
        ) : (
          <span key="note" style={{ color: "grey", fontStyle: "italic" }}>
            <b>Note:</b> This action will overwrite the current dataset with latest data and schema
            from remote storage. Ensure that the schema in the remote storage matches that of the
            dataset; otherwise, linked recipes may encounter failures. Also the associated
            segment(if any) will be deleted, and any custom scenarios using it will default to the
            entire dataset instead of the segment.
          </span>
        )
      ]}
      isSubmitting={reloadDatasets.isLoading}
      submitLabel="Reload"
      onClose={onCancel}
      onSubmit={handleSubmit}
    />
  );
};

export default ReloadDatasetModal;
