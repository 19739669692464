import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  container: {
    padding: "20px 20px 0 20px"
  },
  entitiesListContainer: {
    "& .MuiIconButton-label": {
      "& .MuiGrid-root": {
        padding: 0
      }
    }
  },
  datasetsContainer: {
    fontSize: "14px",
    display: "flex",
    gap: "16px",
    "& > div": {
      width: "100%",
      "& > ul": {
        paddingTop: 0
      }
    }
  },
  listIcon: {
    width: "16px",
    height: "16px",
    color: "#003656",
    minWidth: "24px",
    "& svg": {
      width: "16px",
      height: "16px"
    }
  },
  link: {
    color: "#003656",
    cursor: "pointer",
    "&:hover": {
      color: "#003656",
      textDecoration: "underline"
    }
  }
}));

export { useStyles };
