import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { AI_GUIDE_THREADS_KEY } from "./useGetAIGuideThreads";

import { CreateAIThreadRequestDto } from "openapi/Models/create-aithread-request-dto";

export const useCreateNewThreadMutation = (shouldDispatchEvent: boolean = true) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: CreateAIThreadRequestDto) => {
      return await api.fetchResponse(
        () => api.AiControllerApi.createThread(request),
        shouldDispatchEvent
      );
    },
    onSuccess: (response, variables) => {
      const projectId = variables?.context?.projectId as string;
      queryClient.setQueryData(["projects", projectId, AI_GUIDE_THREADS_KEY], (prevData: any) => [
        response,
        ...prevData
      ]);
    }
  });
};
