/* eslint-disable no-unused-vars */
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

export const useGetCanvas = ({
  projectId,
  scenarioId,
  jobRunId,
  jobId,
  enabled,
  cacheTime = 0,
  options = {}
}: {
  projectId?: string | null;
  scenarioId?: string | null;
  jobRunId?: string;
  jobId?: string;
  enabled?: boolean;
  cacheTime?: number;
  options?: UseQueryOptions<any>;
}) => {
  return useQuery({
    queryKey: ["project", projectId, "scenario", scenarioId],
    queryFn: () => {
      if (!projectId || !scenarioId) {
        throw "projectId or scenarioId absent";
      }
      return getAPIWithRethrow(
        `/v2/project-canvas/${projectId}?chartsEnabled=true&scenarioId=${scenarioId}${
          !!jobId ? `&projectRunId=${jobId}` : ""
        }${!!jobRunId ? `&projectRunEntryId=${jobRunId}` : ""}`
      );
    },
    ...options,
    enabled,
    cacheTime
  });
};
