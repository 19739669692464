/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { PipelineTestResultPerTransformMsgsInnerValueInner } from './pipeline-test-result-per-transform-msgs-inner-value-inner';

/**
 * 
 * @export
 * @interface SignedUrlResult
 */
export interface SignedUrlResult {
    /**
     * 
     * @type {string}
     * @memberof SignedUrlResult
     */
    'status'?: SignedUrlResultStatusEnum;
    /**
     * 
     * @type {Array<PipelineTestResultPerTransformMsgsInnerValueInner>}
     * @memberof SignedUrlResult
     */
    'messages'?: Array<PipelineTestResultPerTransformMsgsInnerValueInner>;
    /**
     * 
     * @type {string}
     * @memberof SignedUrlResult
     */
    'responseEntity'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignedUrlResult
     */
    'signedUrl'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SignedUrlResult
     */
    'headers'?: { [key: string]: string; };
}

export const SignedUrlResultStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type SignedUrlResultStatusEnum = typeof SignedUrlResultStatusEnum[keyof typeof SignedUrlResultStatusEnum];


