import React from "react";

const NewInputEntity = () => (
  <svg width="241" height="215" viewBox="0 0 241 215" xmlns="http://www.w3.org/2000/svg">
    <path d="M186.548 165.624H42.8447V57.9701H186.548V165.624Z" fill="white" />
    <path
      d="M186.548 165.624H42.8447V57.9701H186.548V165.624ZM44.3262 59.4516V164.143H185.067V59.4516H44.3262Z"
      fill="#E6E6E6"
    />
    <path
      d="M114.652 139.362C129.764 139.362 142.014 127.111 142.014 111.999C142.014 96.8872 129.764 84.6366 114.652 84.6366C99.5399 84.6366 87.2893 96.8872 87.2893 111.999C87.2893 127.111 99.5399 139.362 114.652 139.362Z"
      fill="#f1957d"
    />
    <path
      d="M126.014 109.701H116.711V100.398C116.711 99.8175 116.48 99.2607 116.07 98.8502C115.659 98.4396 115.102 98.209 114.522 98.209C113.941 98.209 113.384 98.4396 112.974 98.8502C112.563 99.2607 112.333 99.8175 112.333 100.398V109.701H103.029C102.449 109.701 101.892 109.932 101.482 110.343C101.071 110.753 100.84 111.31 100.84 111.89C100.84 112.471 101.071 113.028 101.482 113.438C101.892 113.849 102.449 114.08 103.029 114.08H112.333V123.383C112.333 123.963 112.563 124.52 112.974 124.931C113.384 125.341 113.941 125.572 114.522 125.572C115.102 125.572 115.659 125.341 116.07 124.931C116.48 124.52 116.711 123.963 116.711 123.383V114.08H126.014C126.595 114.08 127.152 113.849 127.562 113.438C127.973 113.028 128.203 112.471 128.203 111.89C128.203 111.31 127.973 110.753 127.562 110.343C127.152 109.932 126.595 109.701 126.014 109.701Z"
      fill="white"
    />
    <path d="M54.6983 57.5207H1V17.0001H54.6983V57.5207Z" fill="white" />
    <path
      d="M54.6983 57.5207H1V17.0001H54.6983V57.5207ZM1.98765 56.5331H53.7106V17.9878H1.98765V56.5331Z"
      fill="#E6E6E6"
    />
    <path d="M47.6154 28.0363H8.08252V30.6718H47.6154V28.0363Z" fill="#E4E4E4" />
    <path d="M47.6154 35.9423H8.08252V38.5778H47.6154V35.9423Z" fill="#E4E4E4" />
    <path d="M47.6154 43.849H8.08252V46.4846H47.6154V43.849Z" fill="#E4E4E4" />
    <path d="M85.102 81.2244H25.1975V36.0206H85.102V81.2244Z" fill="white" />
    <path
      d="M85.102 81.2244H25.1975V36.0206H85.102V81.2244ZM26.2993 80.1226H84.0002V37.1224H26.2993V80.1226Z"
      fill="#E6E6E6"
    />
    <path d="M77.2005 48.3322H33.0986V51.2723H77.2005V48.3322Z" fill="#f1957d" />
    <path d="M77.2005 57.1522H33.0986V60.0924H77.2005V57.1522Z" fill="#f1957d" />
    <path d="M77.2005 65.9725H33.0986V68.9127H77.2005V65.9725Z" fill="#f1957d" />
    <path d="M240.564 199.562H149.148V130.58H240.564V199.562Z" fill="white" />
    <path
      d="M240.564 199.562H149.148V130.58H240.564V199.562ZM150.83 197.881H238.882V132.262H150.83V197.881Z"
      fill="#E6E6E6"
    />
    <path d="M228.506 149.368H161.205V153.855H228.506V149.368Z" fill="#f1957d" />
    <path d="M228.506 162.828H161.205V167.314H228.506V162.828Z" fill="#f1957d" />
    <path d="M228.506 176.288H161.205V180.774H228.506V176.288Z" fill="#f1957d" />
  </svg>
);

export default NewInputEntity;
