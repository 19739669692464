import React, { useEffect, useMemo } from "react";
import _ from "lodash";

import { InputDatasetTable } from "./InputDataset";
import useFetchChartData from "../../Charts/useFetchChartData";
import { OutputEntity, OutputEntityDto } from "openapi/Models";
import { Spinner } from "src/components";

interface IProps {
  outputEntity: OutputEntity | undefined;
  outputEntityResponse: OutputEntityDto | undefined;
  onSuccess?: (data: any) => void;
}

const AIGuideDataset: React.FC<IProps> = (props) => {
  const { outputEntity, outputEntityResponse, onSuccess } = props;

  const dataset = outputEntity || outputEntityResponse;
  const datasetName = dataset?.name || "";

  useEffect(() => {
    if (outputEntityResponse?.data) {
      const data = outputEntityResponse?.data;
      onSuccess?.(data?.rows?.map(({ cells }: any) => _.zipObject(data?.columns ?? [], cells)));
    }
  }, []);

  const { data: fetchedChartData, isLoading } = useFetchChartData(
    {
      name: datasetName,
      dataFilePath: outputEntity?.sampleDatasetFilePath,
      chartType: "TABLE"
    },
    { enabled: !outputEntityResponse?.data, onSuccess }
  );

  const chartData = outputEntityResponse?.data || fetchedChartData;

  const entityData = useMemo(() => {
    const countObj = {
      numRows: (dataset as any)?.datasetNumRows,
      numColumns: (dataset as any)?.datasetNumCols
    };
    if (outputEntityResponse?.data) {
      return {
        ...outputEntityResponse,
        numRows: chartData?.numRows,
        numColumns: chartData?.numCols
      };
    }
    const columns = chartData?.[0] ? Object.keys(chartData?.[0]) : [];
    return chartData
      ? {
          ...countObj,
          data: {
            columns,
            rows: _.map(chartData, (data) => ({ cells: Object.values(data) }))
          }
        }
      : {};
  }, [dataset, chartData, outputEntityResponse]);

  return (
    <>
      {isLoading && !outputEntityResponse?.data ? (
        <Spinner />
      ) : (
        <InputDatasetTable entityData={entityData as any} />
      )}
    </>
  );
};

export default React.memo(AIGuideDataset);
