import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    height: ({ height }: $TSFixMe) => height,
    rowGap: 10
  }
}));
const OntologyLoaderWrapper = ({ height = 400, children }: $TSFixMe) => {
  const classes = useStyles({ height });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}>
      {children}
    </Grid>
  );
};

export default OntologyLoaderWrapper;
