import React from "react";

const CurvedLinesIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="6.5" width="3" height="3" rx="1" stroke={color} />
    <rect x="11.5" y="12.5" width="3" height="3" rx="1" stroke={color} />
    <path
      d="M4 8H5.26666C6.92352 8 8.26667 9.34315 8.26667 11V11C8.26667 12.6569 9.60981 14 11.2667 14H12"
      stroke={color}
    />
    <path
      d="M4 8H5.26666C6.92352 8 8.26667 6.65685 8.26667 5V5C8.26667 3.34315 9.60981 2 11.2667 2H12"
      stroke={color}
    />
    <rect x="11.5" y="0.5" width="3" height="3" rx="1" stroke={color} />
  </svg>
);

export default CurvedLinesIcon;
