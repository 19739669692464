import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { AuthenticationResponseDto } from "openapi/Models";

const VALIDATE_SSO_TOKEN = "validateSsoToken";

export const useValidateSSOToken = (
  options?: UseQueryOptions<AxiosResponse<AuthenticationResponseDto, any>>
) => {
  return useQuery({
    queryKey: [VALIDATE_SSO_TOKEN],
    queryFn: async () => {
      const response = await axios.get("/api/sso/token/validation");
      return response;
    },
    ...options
  });
};
