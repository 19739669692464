/* eslint-disable no-unused-vars */
import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { getScenarioById } from "src/api";
import { Scenario } from "src/types";

export const useGetScenario = ({
  scenarioId,
  options,
  onSuccess,
  onError
}: {
  scenarioId?: string;
  onSuccess?: (data: Scenario) => void;
  onError?: (e: any) => void;
  options?: UseQueryOptions<Scenario>;
}) => {
  return useQuery<Scenario>({
    queryKey: ["scenario", scenarioId],
    queryFn: async () => {
      if (!scenarioId) {
        throw "scenarioId is absent";
      }
      return await getScenarioById(scenarioId);
    },
    cacheTime: 0,
    onSuccess,
    onError,
    enabled: !!scenarioId,
    ...options
  });
};
