import React from "react";
import { Button, Grid, Typography, makeStyles } from "@material-ui/core";

import Text from "components/Widget/Text";
import sleepingPanda from "src/assets/images/sleepingPanda.png";
import { RocketLaunch } from "src/icons/RocketLaunch";
import { Spinner } from "src/components";
import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";

const useStyles = makeStyles({
  dataAppViewWrap: {
    flexWrap: "nowrap",
    alignItems: "center",
    paddingTop: "10%"
  },
  dataAppNotRunningImage: {
    height: "180px",
    width: "180px"
  },
  inactiveMessage: {
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "22px 0px"
  },
  spinner: {
    width: "100px",
    "& div": {
      display: "contents"
    }
  }
});

export const DataAppNotRunningView = ({
  dataAppName,
  isDeleting,
  isLaunching,
  stoppedBy,
  onLaunch
}: {
  dataAppName: string;
  onLaunch: () => void;
  isLaunching?: boolean;
  stoppedBy: string | undefined;
  isDeleting: boolean;
}) => {
  const classes = useStyles();
  const { barMainButton } = useSubtopBarStyles();
  return (
    <Grid container direction="column" className={classes.dataAppViewWrap}>
      <Grid component="img" src={sleepingPanda} className={classes.dataAppNotRunningImage} />
      <Typography variant="h5" color="primary" test-id="dataAppNotRunningText">
        Oops! Your DataApp is not running
      </Typography>
      {!isDeleting && (
        <>
          <Grid
            container
            direction="column"
            className={classes.inactiveMessage}
            test-id="dataAppNotRunningSubText">
            <Typography color="primary">
              Your{" "}
              <b>
                <Text value={dataAppName} />
              </b>
              {stoppedBy === "CleanupJob"
                ? " has been shut down due to inactivity time set for the dataapp"
                : ` has been manually shut down by ${stoppedBy}`}
            </Typography>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={barMainButton}
            test-id="dataAppLaunchBtn"
            onClick={onLaunch}
            disabled={isLaunching}
            startIcon={!isLaunching && <RocketLaunch />}>
            {isLaunching ? <Spinner size={22} noPadding className={classes.spinner} /> : "RELAUNCH"}
          </Button>
        </>
      )}
    </Grid>
  );
};
