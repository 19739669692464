import React from "react";

import { Breadcrumbs, Button, Tooltip, makeStyles } from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { capitalize } from "src/utils/capitalize";
import { ellipses, trimLeadingSlash } from "src/utils/formatText";

import { Table, Spinner } from "src/components";

import { DataSourcesHelperText } from "../../utils/DataSources.constants";

const useStyles = makeStyles((theme) => ({
  breadcrumb: { marginBottom: "0.5rem" },
  breadcrumbPath: { fontSize: "0.875rem", fontWeight: 500 },
  breadcrumbLink: {
    display: "flex",
    minWidth: 0,
    padding: "6px 8px",
    textTransform: "none"
  },
  breadcrumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  filePath: { padding: "6px 8px", "& .icon": { marginRight: 4 } },
  disabled: {
    opacity: 0.5
  }
}));

const Files = (props: $TSFixMe) => {
  const { getFiles, isFilesLoading, files, breadcrumbs, setBreadcrumbs, isObjectContentDisabled } =
    props || {};

  const classes = useStyles();

  const columns = [
    {
      id: "Name",
      accessor: "path",
      Header: "Name",
      isSortable: true,
      Cell: ({ row }: $TSFixMe) => {
        const { path, type } = row?.original;

        return type === "directory" ? (
          <Button
            color="secondary"
            className={classes.breadcrumbLink}
            onClick={async () => {
              await getFiles({ filePath: path });

              const newPaths = breadcrumbs?.length === 0 ? ["/", path] : [...breadcrumbs, path];
              setBreadcrumbs(newPaths);
            }}
            disabled={isObjectContentDisabled}>
            <FolderOpenIcon className={classes.breadcrumbIcon} />
            {path ?? null}
          </Button>
        ) : (
          <div className={classes.filePath}>
            {isObjectContentDisabled ? (
              <span className={classes.disabled}>
                <DescriptionOutlinedIcon fontSize="small" className="icon" />
                {path ?? null}
              </span>
            ) : (
              <>
                <DescriptionOutlinedIcon fontSize="small" className="icon" />
                {path ?? null}
              </>
            )}
          </div>
        );
      }
    },
    {
      id: "Type",
      accessor: "type",
      Header: "Type",
      isSortable: true,
      Cell: (row: $TSFixMe) =>
        row?.value ? (
          isObjectContentDisabled ? (
            <span className={classes.disabled}>{capitalize(row?.value)}</span>
          ) : (
            capitalize(row?.value)
          )
        ) : null
    }
  ];

  const updateBreadcrumbs = (index: $TSFixMe) => {
    breadcrumbs?.splice(index + 1);
    setBreadcrumbs(breadcrumbs);
  };

  return (
    <>
      {isFilesLoading ? (
        <Spinner />
      ) : (
        <>
          {breadcrumbs?.length > 1 && (
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
              {breadcrumbs.map((breadcrumb: $TSFixMe, breadcrumbIndex: $TSFixMe) => {
                const formattedBreadcrumbText = trimLeadingSlash({ text: breadcrumb });

                return breadcrumbIndex === breadcrumbs?.length - 1 ? (
                  <Tooltip
                    key={`breadcrumb_${breadcrumbIndex}`}
                    title={formattedBreadcrumbText?.length > 20 ? formattedBreadcrumbText : ""}>
                    <span className={classes.breadcrumbPath}>
                      {ellipses({
                        text: formattedBreadcrumbText,
                        length: 20
                      })}
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    key={`breadcrumb_${breadcrumbIndex}`}
                    title={formattedBreadcrumbText?.length > 20 ? formattedBreadcrumbText : ""}>
                    <Button
                      key={`breadcrumb_${breadcrumbIndex}`}
                      color="secondary"
                      className={classes.breadcrumbLink}
                      onClick={() => {
                        updateBreadcrumbs(breadcrumbIndex);
                        getFiles({ filePath: breadcrumb });
                      }}
                      disabled={isObjectContentDisabled}>
                      <FolderOpenIcon
                        className={classes.breadcrumbIcon}
                        style={
                          !formattedBreadcrumbText
                            ? {
                                marginRight: 0
                              }
                            : {}
                        }
                      />
                      {ellipses({
                        text: formattedBreadcrumbText,
                        length: 20
                      })}
                    </Button>
                  </Tooltip>
                );
              })}
            </Breadcrumbs>
          )}

          <Table
            data={files}
            size="small"
            columns={columns}
            isCellSortEnabled
            orderByDefault="Name"
            isTheadSticky
            emptyTableMessage={DataSourcesHelperText.NoFileFound}
            maxHeight={`calc(100vh - ${breadcrumbs?.length > 1 ? 230 + 44 : 230}px)`}
          />
        </>
      )}
    </>
  );
};

export default Files;
