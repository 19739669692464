import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { ChangeTemplateResponseDto } from "openapi/Models";

import api from "src/utils/AxiosClient";

export const CACHE_QUERY_KEY_FETCH_AUTO_ML_RECIPE_NOTEBOOK_URL =
  "fetch-auto-ml-recipe-notebook-url";

const useAutoMLNotebookUrl = (
  runConfigId?: string,
  scenarioId?: string,
  options?: UseQueryOptions<ChangeTemplateResponseDto, Error>
): UseQueryResult<ChangeTemplateResponseDto, Error> => {
  return useQuery<ChangeTemplateResponseDto, Error>({
    queryKey: [CACHE_QUERY_KEY_FETCH_AUTO_ML_RECIPE_NOTEBOOK_URL, runConfigId, scenarioId],
    queryFn: async () => {
      if (!runConfigId) {
        throw "run config id is required";
      }

      const data = await api.fetchResponse(
        async () => await api.DfsRunConfigControllerV2Api.editTemplate(runConfigId, { scenarioId })
      );

      return data;
    },
    ...options
  });
};

export default useAutoMLNotebookUrl;
