/* eslint-disable no-unused-vars */
enum LicenseStatusType {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  EXPIRING = "EXPIRING",
  SUSPENDED = "SUSPENDED",
  REVOKED = "REVOKED",
  BANNED = "BANNED",
  INACTIVE = "INACTIVE"
}

export type LicenseType = {
  id: string;
  description?: string;
  scope: "SYSTEM" | "TENANT";
  status: keyof typeof LicenseStatusType;
  expiryTime: number;
  tenantId: string;
};

export type LicensesType = Array<LicenseType>;

export type LicenseDetailsType = {
  valid: boolean;
  metadata: $TSFixMe;
  status: keyof typeof LicenseStatusType;
  expiryTime: number;
  maskedLicense: string;
};
