import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const RocketIcon: React.FC<Props> = ({
  width = 78,
  height = 77,
  viewBox = "0 0 78 77",
  fill = "none",
  color = "#FF5C00"
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M77.9441 2.39045C77.8947 1.2404 76.9976 0.306535 75.8504 0.211168L75.779 0.205226C68.0061 -0.435684 60.297 0.426277 52.8661 2.76689C45.2716 5.1593 38.333 8.97322 32.243 14.1029C31.1865 14.9929 30.1675 15.9174 29.1814 16.8704C26.7614 15.4146 23.9362 14.7231 21.0864 14.9143C17.7321 15.1393 14.5742 16.5737 12.1947 18.9531L0.669323 30.4787C-0.223108 31.3711 -0.223108 32.8179 0.669323 33.7104L2.02412 35.0652C5.74237 38.7834 10.6253 40.8634 15.7733 41.0005L14.911 46.6335C14.8009 47.3524 15.0398 48.0808 15.5541 48.5951L27.9544 60.9953C28.3862 61.427 28.9687 61.6647 29.57 61.6647C29.6849 61.6647 29.8007 61.656 29.9158 61.6383L35.5506 60.7758C35.6881 65.9238 37.7669 70.8079 41.4841 74.5253L42.839 75.8802C43.2852 76.3264 43.8701 76.5495 44.4548 76.5495C45.0395 76.5495 45.6245 76.3264 46.0705 75.8801L57.5958 64.3546C62.0311 59.9193 62.9149 53.1904 59.9443 47.8261C61.106 46.6902 62.2271 45.5068 63.3009 44.2713C68.3254 38.4898 72.1504 31.8908 74.6697 24.6576C77.1688 17.4835 78.2702 9.99164 77.9441 2.39045ZM73.4284 4.63219C73.4612 9.71529 72.7987 14.7343 71.4545 19.6169L57.9383 6.10078C63.0095 4.87929 68.1982 4.386 73.4284 4.63219ZM5.51965 32.0916L15.4263 22.1849C18.2107 19.4005 22.4339 18.7209 25.9013 20.2998C22.5494 24.0994 19.6962 28.3122 17.3798 32.8961L17.019 33.61C16.9102 33.8252 16.8362 34.0565 16.7998 34.2949L16.4723 36.4349C12.398 36.4713 8.5033 34.9418 5.51965 32.0916ZM19.6038 46.1815L20.5636 39.9115C20.6399 39.8069 20.7089 39.6969 20.7668 39.5805L36.9831 55.7967C36.8749 55.8494 36.7722 55.9116 36.674 55.9803L30.368 56.9455L19.6038 46.1815ZM54.3644 61.1231L44.458 71.0297C41.6085 68.047 40.0784 64.1509 40.1147 60.0771L42.2546 59.7496C42.493 59.7132 42.7242 59.6391 42.9394 59.5304L45.1057 58.4358C49.175 56.3796 52.9543 53.8944 56.4148 51.013C57.8118 54.422 57.0765 58.4109 54.3644 61.1231ZM43.0445 54.3566L42.3547 54.7052L21.8523 34.2027C28.3933 21.7485 39.6256 12.122 52.931 7.55658L69.8679 24.4933C64.9664 37.4991 55.4872 48.0691 43.0445 54.3566Z"
      fill={color}
    />
    <path
      d="M40.5478 24.0377C36.9962 27.5893 36.9962 33.3683 40.5478 36.9201C42.3235 38.6958 44.6563 39.5836 46.9889 39.5836C49.3214 39.5836 51.6542 38.6958 53.43 36.9199C56.9816 33.3683 56.9816 27.5893 53.43 24.0377C49.8784 20.4861 44.0992 20.4861 40.5478 24.0377ZM50.1983 33.6882C48.4287 35.4579 45.5491 35.4579 43.7794 33.6882C42.0098 31.9185 42.0098 29.039 43.7794 27.2692C44.6644 26.3844 45.8265 25.942 46.9889 25.942C48.1513 25.942 49.3135 26.3844 50.1983 27.2692C51.9679 29.0392 51.9679 31.9186 50.1983 33.6882Z"
      fill={color}
    />
    <path
      d="M18.7568 58.4227C17.8645 57.5304 16.4176 57.5303 15.5251 58.4227L8.17225 65.7757C7.27982 66.6681 7.27982 68.1149 8.17225 69.0074C8.61847 69.4536 9.20332 69.6766 9.78801 69.6766C10.3727 69.6766 10.9577 69.4536 11.4038 69.0072L18.7566 61.6542C19.6494 60.7621 19.6494 59.3151 18.7568 58.4227Z"
      fill={color}
    />
    <path
      d="M12.21 51.872C11.3177 50.9795 9.87074 50.9795 8.97831 51.872L1.62529 59.225C0.732856 60.1174 0.732856 61.5642 1.62529 62.4567C2.0715 62.9029 2.65635 63.1259 3.24104 63.1259C3.82574 63.1259 4.41074 62.9029 4.8568 62.4567L12.2098 55.1036C13.1024 54.2112 13.1024 52.7644 12.21 51.872Z"
      fill={color}
    />
    <path
      d="M22.0718 64.9663L14.7187 72.3193C13.8263 73.2118 13.8263 74.6586 14.7187 75.551C15.165 75.9972 15.7498 76.2204 16.3345 76.2204C16.9192 76.2204 17.5042 75.9974 17.9503 75.551L25.3033 68.198C26.1957 67.3056 26.1957 65.8587 25.3033 64.9663C24.411 64.0737 22.9642 64.0737 22.0718 64.9663Z"
      fill={color}
    />
  </svg>
);

export default RocketIcon;
