import { useMutation } from "@tanstack/react-query";

import { getDataSourceFilesWithRethrow } from "src/api/projects";

export const enum UseGetConnectorFilesQueryKeys {
  ConnectorFiles = "connector-files"
}

// $FixMe: Scope to be refactored.
// Need to cache the below query.
export const useGetConnectorFiles = () => {
  return useMutation({
    mutationKey: [UseGetConnectorFilesQueryKeys.ConnectorFiles],
    mutationFn: async (payload: $TSFixMe) => await getDataSourceFilesWithRethrow({ data: payload })
  });
};
