/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ChangeTemplateResponseDto } from '../Models';
// @ts-ignore
import type { CreateDFSRunConfigDto } from '../Models';
// @ts-ignore
import type { DFSRunConfigDto } from '../Models';
// @ts-ignore
import type { EditTemplateRequestDto } from '../Models';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { PublishTemplateResponseDto } from '../Models';
// @ts-ignore
import type { UpdateDFSRunConfigDto } from '../Models';
/**
 * DfsRunConfigControllerV2Api - axios parameter creator
 * @export
 */
export const DfsRunConfigControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDFSRunConfigDto} createDFSRunConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRunConfig1: async (createDFSRunConfigDto: CreateDFSRunConfigDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDFSRunConfigDto' is not null or undefined
            assertParamExists('createRunConfig1', 'createDFSRunConfigDto', createDFSRunConfigDto)
            const localVarPath = `/v2/dfs-run-configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDFSRunConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRunConfig: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRunConfig', 'id', id)
            const localVarPath = `/v2/dfs-run-configs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {EditTemplateRequestDto} editTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTemplate: async (id: string, editTemplateRequestDto: EditTemplateRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editTemplate', 'id', id)
            // verify required parameter 'editTemplateRequestDto' is not null or undefined
            assertParamExists('editTemplate', 'editTemplateRequestDto', editTemplateRequestDto)
            const localVarPath = `/v2/dfs-run-configs/{id}/edit-template`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editTemplateRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRunConfigs: async (id?: string, name?: string, groupId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/dfs-run-configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishTemplate', 'id', id)
            const localVarPath = `/v2/dfs-run-configs/{id}/publish-template`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetTemplate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resetTemplate', 'id', id)
            const localVarPath = `/v2/dfs-run-configs/{id}/reset-template`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDFSRunConfigDto} updateDFSRunConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRunConfig1: async (updateDFSRunConfigDto: UpdateDFSRunConfigDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDFSRunConfigDto' is not null or undefined
            assertParamExists('updateRunConfig1', 'updateDFSRunConfigDto', updateDFSRunConfigDto)
            const localVarPath = `/v2/dfs-run-configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDFSRunConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DfsRunConfigControllerV2Api - functional programming interface
 * @export
 */
export const DfsRunConfigControllerV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DfsRunConfigControllerV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDFSRunConfigDto} createDFSRunConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRunConfig1(createDFSRunConfigDto: CreateDFSRunConfigDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSRunConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRunConfig1(createDFSRunConfigDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsRunConfigControllerV2Api.createRunConfig1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRunConfig(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRunConfig(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsRunConfigControllerV2Api.deleteRunConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {EditTemplateRequestDto} editTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editTemplate(id: string, editTemplateRequestDto: EditTemplateRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editTemplate(id, editTemplateRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsRunConfigControllerV2Api.editTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRunConfigs(id?: string, name?: string, groupId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DFSRunConfigDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRunConfigs(id, name, groupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsRunConfigControllerV2Api.getRunConfigs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublishTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishTemplate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsRunConfigControllerV2Api.publishTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetTemplate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeTemplateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetTemplate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsRunConfigControllerV2Api.resetTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDFSRunConfigDto} updateDFSRunConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRunConfig1(updateDFSRunConfigDto: UpdateDFSRunConfigDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DFSRunConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRunConfig1(updateDFSRunConfigDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DfsRunConfigControllerV2Api.updateRunConfig1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DfsRunConfigControllerV2Api - factory interface
 * @export
 */
export const DfsRunConfigControllerV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DfsRunConfigControllerV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDFSRunConfigDto} createDFSRunConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRunConfig1(createDFSRunConfigDto: CreateDFSRunConfigDto, options?: any): AxiosPromise<DFSRunConfigDto> {
            return localVarFp.createRunConfig1(createDFSRunConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRunConfig(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRunConfig(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {EditTemplateRequestDto} editTemplateRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editTemplate(id: string, editTemplateRequestDto: EditTemplateRequestDto, options?: any): AxiosPromise<ChangeTemplateResponseDto> {
            return localVarFp.editTemplate(id, editTemplateRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRunConfigs(id?: string, name?: string, groupId?: string, options?: any): AxiosPromise<Array<DFSRunConfigDto>> {
            return localVarFp.getRunConfigs(id, name, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishTemplate(id: string, options?: any): AxiosPromise<PublishTemplateResponseDto> {
            return localVarFp.publishTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetTemplate(id: string, options?: any): AxiosPromise<ChangeTemplateResponseDto> {
            return localVarFp.resetTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDFSRunConfigDto} updateDFSRunConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRunConfig1(updateDFSRunConfigDto: UpdateDFSRunConfigDto, options?: any): AxiosPromise<DFSRunConfigDto> {
            return localVarFp.updateRunConfig1(updateDFSRunConfigDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DfsRunConfigControllerV2Api - interface
 * @export
 * @interface DfsRunConfigControllerV2Api
 */
export interface DfsRunConfigControllerV2ApiInterface {
    /**
     * 
     * @param {CreateDFSRunConfigDto} createDFSRunConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2ApiInterface
     */
    createRunConfig1(createDFSRunConfigDto: CreateDFSRunConfigDto, options?: RawAxiosRequestConfig): AxiosPromise<DFSRunConfigDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2ApiInterface
     */
    deleteRunConfig(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {EditTemplateRequestDto} editTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2ApiInterface
     */
    editTemplate(id: string, editTemplateRequestDto: EditTemplateRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ChangeTemplateResponseDto>;

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2ApiInterface
     */
    getRunConfigs(id?: string, name?: string, groupId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<DFSRunConfigDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2ApiInterface
     */
    publishTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<PublishTemplateResponseDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2ApiInterface
     */
    resetTemplate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ChangeTemplateResponseDto>;

    /**
     * 
     * @param {UpdateDFSRunConfigDto} updateDFSRunConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2ApiInterface
     */
    updateRunConfig1(updateDFSRunConfigDto: UpdateDFSRunConfigDto, options?: RawAxiosRequestConfig): AxiosPromise<DFSRunConfigDto>;

}

/**
 * DfsRunConfigControllerV2Api - object-oriented interface
 * @export
 * @class DfsRunConfigControllerV2Api
 * @extends {BaseAPI}
 */
export class DfsRunConfigControllerV2Api extends BaseAPI implements DfsRunConfigControllerV2ApiInterface {
    /**
     * 
     * @param {CreateDFSRunConfigDto} createDFSRunConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2Api
     */
    public createRunConfig1(createDFSRunConfigDto: CreateDFSRunConfigDto, options?: RawAxiosRequestConfig) {
        return DfsRunConfigControllerV2ApiFp(this.configuration).createRunConfig1(createDFSRunConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2Api
     */
    public deleteRunConfig(id: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigControllerV2ApiFp(this.configuration).deleteRunConfig(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {EditTemplateRequestDto} editTemplateRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2Api
     */
    public editTemplate(id: string, editTemplateRequestDto: EditTemplateRequestDto, options?: RawAxiosRequestConfig) {
        return DfsRunConfigControllerV2ApiFp(this.configuration).editTemplate(id, editTemplateRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2Api
     */
    public getRunConfigs(id?: string, name?: string, groupId?: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigControllerV2ApiFp(this.configuration).getRunConfigs(id, name, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2Api
     */
    public publishTemplate(id: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigControllerV2ApiFp(this.configuration).publishTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2Api
     */
    public resetTemplate(id: string, options?: RawAxiosRequestConfig) {
        return DfsRunConfigControllerV2ApiFp(this.configuration).resetTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDFSRunConfigDto} updateDFSRunConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsRunConfigControllerV2Api
     */
    public updateRunConfig1(updateDFSRunConfigDto: UpdateDFSRunConfigDto, options?: RawAxiosRequestConfig) {
        return DfsRunConfigControllerV2ApiFp(this.configuration).updateRunConfig1(updateDFSRunConfigDto, options).then((request) => request(this.axios, this.basePath));
    }
}

