import React, { useCallback } from "react";
import { useForm } from "react-hook-form";

import { Button, Checkbox, Divider, TextField, MenuItem } from "@material-ui/core";
import { useDrawerStore, useVariablesStore } from "../../../store/store";
import { sideComponentSetter } from "../../../store/store.selectors";
import { createVariable, updateVariable } from "../../../api";

import styles from "./VariablesForm.module.scss";
import { capitalize } from "lodash";

const types = ["STRING", "INTEGER", "FLOAT", "BOOLEAN"];

const VariablesForm = ({ projectId, selectedVariable = {} }: $TSFixMe) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: selectedVariable.name || "",
      type: capitalize(selectedVariable.type) || "",
      value: selectedVariable.value || ""
    }
  });
  const triggerVariablesReload = useVariablesStore((state) => state.triggerVariablesReload);
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const onSubmit = async ({ name, type, value }: $TSFixMe) => {
    const { id } = selectedVariable;
    const body = {
      ...(id && { id }),
      name,
      type,
      value,
      projectId
    };
    const response = await (id ? updateVariable(body, id) : createVariable(body));
    triggerVariablesReload();
    response && onCloseDrawer();
  };

  const onCloseDrawer = useCallback(() => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  }, [setSideComponent]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.container}>
        <div className={styles.fieldLabel}>Name</div>
        <TextField
          className={styles.field}
          {...register("name", { required: true })}
          variant="outlined"
          error={Boolean(errors.name)}
          helperText={errors.name && "Variable name is required"}
          size="small"
        />
        <div className={styles.fieldLabel}>Data Type</div>
        <TextField
          className={styles.field}
          select
          fullWidth
          variant="outlined"
          size="small"
          defaultValue={capitalize(selectedVariable.type)}
          inputProps={{ ...register("type", { required: true }) }}
          error={Boolean(errors.type)}
          helperText={errors.type && "Data type is required"}>
          {types.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <div className={styles.fieldLabel}>Value</div>
        <TextField
          className={styles.field}
          {...register("value", { required: true })}
          variant="outlined"
          error={Boolean(errors.value)}
          helperText={errors.value && "Value is required"}
          size="small"
        />
      </div>
      <Divider light />
      <div className={`${styles.container} ${styles.checkboxContainer}`}>
        <Checkbox />
        <div>Make it visible only around the project</div>
      </div>
      <div className={styles.buttonGroup}>
        <Button onClick={onCloseDrawer} color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {selectedVariable.id ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
};

export default VariablesForm;
