import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";

interface IVariables {
  stepId: string;
  stepType: string;
  nodeType: string;
}

const useDeleteCandidates = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ stepId, stepType, nodeType }) => {
      const deleteAssociatedPDs = ["group", "model", "entity"].includes(nodeType);
      const data = await api.fetchResponse(
        async () =>
          await api.ProjectsControllerV2Api.deleteStep(
            stepId,
            stepType,
            deleteAssociatedPDs,
            deleteAssociatedPDs
          )
      );
      return data;
    }
  });
};

export default useDeleteCandidates;
