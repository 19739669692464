/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";

import { saveTransformByIdWithRethrow, updateTransformByIdWithRethrow } from "src/api";
import { Recipe } from "src/types";

export const useSaveRunConfigsMutation = () => {
  return useMutation({
    mutationFn: async ({ recipe, transformData }: { recipe: Recipe; transformData: $TSFixMe }) => {
      const currentConfigIds = recipe?.runConfigs?.map((config: $TSFixMe) => config.id) || [];

      const isEditing = currentConfigIds?.includes(transformData?.id);
      let cleanedVariables = _.omitBy(transformData.inputValues, _.isUndefined);

      const transformPayload = {
        ...transformData,
        variables: cleanedVariables,
        groupId: recipe?.id,
        ...(isEditing ? { configId: transformData.id } : {})
      };
      const transformResponse = await (isEditing
        ? await updateTransformByIdWithRethrow(transformPayload)
        : await saveTransformByIdWithRethrow(transformPayload));

      return {
        transformGroupResponse: {
          ...recipe,
          runConfigs: isEditing
            ? recipe?.runConfigs?.map((config: any) =>
                config.id === transformResponse.id ? transformResponse : config
              )
            : [...(recipe?.runConfigs || []), transformResponse]
        },
        transformResponse
      };
    },
    cacheTime: 0
  });
};
