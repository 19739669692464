import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";
import { Artifact } from "src/types";

const QUERY_KEY_ARTIFACT_BY_NAME = "query-key-artifact-by-name";

const useArtifactByName = (
  name: string,
  scenarioId?: string,
  jobRunId?: string,
  options?: UseQueryOptions<Artifact>
): UseQueryResult<Artifact> => {
  return useQuery({
    queryKey: [QUERY_KEY_ARTIFACT_BY_NAME, name, scenarioId, jobRunId],
    queryFn: async () => {
      const data = await getAPIWithRethrow(
        `/v2/artifacts?name=${name}${!!scenarioId ? `&scenarioId=${scenarioId}` : ``}${
          !!jobRunId ? `&projectRunEntryId=${jobRunId}` : ``
        }`
      );

      return data.artifacts[0];
    },
    ...options
  });
};

export default useArtifactByName;
export { QUERY_KEY_ARTIFACT_BY_NAME };
