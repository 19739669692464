/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InputIdDto
 */
export interface InputIdDto {
    /**
     * 
     * @type {string}
     * @memberof InputIdDto
     */
    'type'?: InputIdDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InputIdDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InputIdDto
     */
    'name'?: string;
}

export const InputIdDtoTypeEnum = {
    InputId: 'INPUT_ID',
    AskaiMessageId: 'ASKAI_MESSAGE_ID'
} as const;

export type InputIdDtoTypeEnum = typeof InputIdDtoTypeEnum[keyof typeof InputIdDtoTypeEnum];


