import { Tooltip } from "@material-ui/core";
import React from "react";

interface IProps {
  label: string;
  children: React.ReactElement<any, any>;
}

const NodeCaptionOverflowTooltip: React.FC<IProps> = ({ label, children }) => {
  return <Tooltip title={label.length > 25 ? label : ""}>{children}</Tooltip>;
};

export default NodeCaptionOverflowTooltip;
