import { Grid } from "@material-ui/core";
import React from "react";
import "./UsageAndPolicy.css";

const UsageAndPolicy = () => {
  return (
    <Grid container>
      <section id="usageAndPolicyContainer">
        <h1
          className="header1"
          style={{
            paddingTop: "2pt",
            paddingLeft: "15pt",
            textIndent: "0pt",
            textAlign: "center"
          }}>
          RAPIDCANVAS CLOUD TECHNOLOGY ACCESS AGREEMENT
        </h1>
        <br />
        <p
          className="parragraph"
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
          <b>Last updated: October 7, 2022</b>
        </p>
        <br />
        <p
          className="parragraph"
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
          <b>
            By indicating your acceptance of this Agreement or accessing or using the Cloud Service,
            you are agreeing to be bound by the terms and conditions of this Agreement. Each party
            expressly agrees that this Agreement is legally binding upon it.
          </b>
        </p>
        <br />
        <p
          className="parragraph"
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
          This RapidCanvas Technology Access Agreement (“<b>Agreement</b>”) is entered into between
          RapidCanvas, Inc., with a principal place of business at 10900 RESEARCH BLVD STE160C PMB
          30, Austin, TX 78759 (“<b>RapidCanvas</b>”), and the entity or person placing an Order for
          or accessing the Software (“<b>Customer</b>” or “<b>you</b>”), to provide the terms and
          conditions under which Customer may access and use Rapid Canvas’s machine
          learning/artificial intelligence (ML/AI) software platform. If you are accessing or using
          the Cloud Service on behalf of your company, you represent that you are authorized to
          accept this Agreement on behalf of your company, and all references to “you” reference
          your company. The “Effective Date” of this Agreement is the date which is the earlier of
          (a) Customer’s initial access to the Cloud Service through any online provisioning,
          registration or order process or (b) the subscription start date of the first Order.
        </p>
        <br />
        <p
          className="parragraph"
          style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
          The parties agree as follows:
        </p>
        <br />
        <ol id="l1">
          <li className="listItem" data-list-text="1.">
            <h2
              className="header2"
              style={{ paddingLeft: "15pt", textIndent: " -10pt", textAlign: "justify" }}>
              DEFINITIONS
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l2">
              <li className="listItem" data-list-text="1.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  “Affiliate”{" "}
                  <span className="parragraph">
                    means, with respect to each party, an entity that controls, is controlled by, or
                    is under common control with such party, where “control” means the beneficial
                    ownership of more than fifty percent (50%) of the voting power or other equity
                    interest with voting rights in an entity.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.2">
                <p
                  className="parragraph"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  “<b>Confidential Information</b>” means any non-public, confidential, or
                  proprietary information of the disclosing party that is clearly marked
                  confidential or reasonably should be assumed to be confidential given the nature
                  of the information or the circumstances of disclosure.
                </p>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.3">
                <h2
                  className="header2"
                  style={{ paddingLeft: "22pt", textIndent: "-16pt", textAlign: "left" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark0">“Customer Data” </a>
                  <span className="parragraph">
                    means the data or information submitted by Customer or Customer’s Users for
                    processing by the Software.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.4">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark1">“Customer Materials” </a>
                  <span className="parragraph">
                    means the software applications, products, system, network or infrastructure
                    that are owned by Customer or licensed from a third party by Customer and used
                    by Customer in connection with the Product.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.5">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark2">“Documentation” </a>
                  <span className="parragraph">
                    means the user manuals and technical documentation regarding use of the Software
                    that are made available by RapidCanvas, as may be revised by RapidCanvas from
                    time to time.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.6">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                  “Order Schedule”{" "}
                  <span className="parragraph">
                    means the written order confirmation issued by RapidCanvas that specifies
                    Customer’s purchase of the Service and Permitted Use, and may include other
                    access and use details for the Service.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.7">
                <p
                  className="parragraph"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  <b>“Output” </b>means final output obtained by Customer as a result of processing
                  of Customer Data by the Software. For the avoidance of doubt, however, “Output”
                  does <u>not</u> include (a) any portion of the Software, (b) the enhancements,
                  modifications and derivative works made of the Customer Data through processing by
                  the Software (“<b>Enhancements</b>”), as well as the intermediate output as a
                  result of such Enhancements (“<b>Intermediate Output</b>”), or (c) the form or
                  format in which the Output may be displayed after processing by the Software.
                </p>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.8">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                  “Permitted Use”{" "}
                  <span className="parragraph">
                    means the scope of use of the Product that is paid for by Customer and permitted
                    by RapidCanvas, as provided in the applicable Order Schedule.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.9">
                <h2
                  className="header2"
                  style={{ paddingLeft: "20pt", textIndent: "-15pt", textAlign: "left" }}>
                  “Product”{" "}
                  <span className="parragraph">
                    means either the Software or the Service, as applicable.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.10">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                  “RapidCanvas Cloud”{" "}
                  <span className="parragraph">
                    means the system, network or infrastructure that are owned by RapidCanvas or
                    licensed from a third party by RapidCanvas and used by RapidCanvas to provide
                    the Service.
                  </span>
                </h2>
              </li>
              <li className="listItem" data-list-text="1.11">
                <h2
                  className="header2"
                  style={{
                    paddingTop: "2pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}>
                  “RapidCanvas Materials”{" "}
                  <span className="parragraph">
                    means the algorithms, process, software and other technology as well as the
                    products, system, network and infrastructure that are owned by RapidCanvas or
                    licensed from a third party by RapidCanvas and used by RapidCanvas in connection
                    with the Product, including without limitation (i) the Product, (ii) all
                    RapidCanvas proprietary materials, any written or printed summaries, analyses or
                    reports generated in connection with use of the Product, including written
                    reports that are created for Customer in the course of using the Product, and
                    (iii) the Enhancements, Intermediate Outputs and any other data generated by the
                    Software (but, for clarification, excluding Customer Data and Output).
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.12">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  “Service
                  <span className="parragraph">
                    ” means RapidCanvas’ provision to Customer of remote access to and use of the
                    applicable Software as authorized under the applicable Order Schedule and this
                    Agreement, including any applicable support and maintenance services and
                    Documentation.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.13">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  “Software”{" "}
                  <span className="parragraph">
                    means the applicable RapidCanvas ML/AI software product made available by
                    RapidCanvas to Customer as provided in the applicable Order Schedule, including
                    any Updates provided by RapidCanvas to Customer.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.14">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  “Subscription Term”{" "}
                  <span className="parragraph">
                    means the term of Customer’s authorized access and use of the Product, as in the
                    applicable Order Schedule.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.15">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark3">“Updates” </a>
                  <span className="parragraph">
                    means any and all bug fixes, work arounds, updates and other revisions of the
                    Software provided by RapidCanvas to Customer.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.16">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark4">“Usage Data</a>
                  <span className="parragraph">
                    ” means any diagnostic and usage-related information from the use, performance
                    and operation of the Product.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="1.17">
                <h2
                  className="header2"
                  style={{ paddingLeft: "25pt", textIndent: "-20pt", textAlign: "justify" }}>
                  “User
                  <span className="parragraph">
                    ” means Customer’s and its permitted Affiliates’ employees, contractors, and
                    similar personnel authorized by Customer or its Affiliates to access and use the
                    Service on such entity’s behalf.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="2.">
            <h2
              className="header2"
              style={{ paddingLeft: "15pt", textIndent: " -10pt", textAlign: "justify" }}>
              SUBSCRIPTIONS; RESTRICTIONS
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l3">
              <li className="listItem" data-list-text="2.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark5">Order Schedules</a>
                  <span className="parragraph">
                    . Customer may order Product(s) from RapidCanvas pursuant to one or more Order
                    Schedule(s). All Order Schedule(s) that are executed by both parties are
                    expressly made a part of this Agreement as though fully set forth herein.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="2.2">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark6">Right to Access and Use</a>
                  <span className="parragraph">
                    . During the applicable Subscription Term, and subject to the terms and
                    conditions of this Agreement, including but not limited to Customer’s payment of
                    all applicable fees:
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <ol id="l4">
                  <li className="listItem" data-list-text="2.2.1">
                    <p
                      className="parragraph"
                      style={{ paddingLeft: "59pt", textIndent: "-36pt", textAlign: "left" }}>
                      If, pursuant to the applicable Order Schedule, Software is to be provided by
                      RapidCanvas, RapidCanvas grants Customer a non-exclusive, non-transferable
                      license to (a) install, deploy and use the Software (in object code form) in
                      the Customer Cloud, and (b) make copies of the Software for backup/archival
                      and/or non-production test purposes (provided that all copies retain all
                      notices of proprietary rights included in the original);
                    </p>
                    <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </li>
                  <li className="listItem" data-list-text="2.2.2">
                    <p
                      className="parragraph"
                      style={{ paddingLeft: "59pt", textIndent: "-36pt", textAlign: "left" }}>
                      If, pursuant to the applicable Order Schedule, the Service is to be provided
                      by RapidCanvas, RapidCanvas will make available to Customer, and Customer may
                      access, the Service in accordance with this Agreement;
                    </p>
                    <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                    <p
                      className="parragraph"
                      style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                      provided that, in the case of either Section 2.2.1 or 2.2.2, such rights may
                      be exercised only within the scope of the Permitted Use set forth in the
                      applicable Order Schedule and in any event solely for Customer’s internal
                      business purposes. The foregoing license and rights includes the right to use
                      the Enhancements and Intermediate Output made available by RapidCanvas solely
                      as part of Customer’s Permitted Use of the Product.
                    </p>
                    <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </li>
                </ol>
              </li>
              <li className="listItem" data-list-text="2.3">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark7">Restrictions. </a>
                  <span className="parragraph">
                    Except as expressly permitted in this Agreement, Customer will not (and will not
                    permit an Affiliate, User, or third party to): (a) sublicense, resell, rent,
                    lease, distribute or otherwise transfer rights to, or usage of, all or any
                    portion of the Product, to anyone else, including without limitation making the
                    Product available on a timesharing, service bureau, or other similar basis; (b)
                    modify, adapt, translate, create derivative works of, reverse engineer,
                    decompile, disassemble, or otherwise attempt to derive the source code of, any
                    part of the Product; (c) remove, modify, or obscure any proprietary rights
                    notices contained in or included with the Product; (d) attempt to gain
                    unauthorized access to the Product, or to disrupt, degrade,
                  </span>
                </h2>
                <p
                  className="parragraph"
                  style={{
                    paddingTop: "2pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}>
                  impair, or violate the integrity, security, or performance of the Product or the
                  RapidCanvas Cloud; (e) access or use any Product in order to build a competitive
                  product or service; (f) publicly disseminate Product performance information or
                  analysis (including without limitation benchmarks) except with RapidCanvas’s prior
                  written consent; (g) use the Product to store or transmit any viruses or other
                  code designed to permit unauthorized access, to disable, erase or otherwise harm
                  software, hardware or data, or to perform any other harmful actions; (h) take any
                  action that imposes or may impose an unreasonable or disproportionately large load
                  on the RapidCanvas Cloud, as determined by RapidCanvas in its sole discretion; (i)
                  disable or circumvent any monitoring, billing or other technological security
                  mechanism in the Product; or (j) access or use the Product in a manner that
                  violates applicable law or regulation (including any export regulations) or
                  infringes or violates any third party rights.
                </p>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="2.4">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Third Party Software.{" "}
                  <span className="parragraph">
                    The Product may include, or the Software may be distributed alongside, certain
                    third party software (&quot;
                  </span>
                  Third Party Software
                  <span className="parragraph">
                    &quot;) that is provided under separate license terms (the &quot;
                  </span>
                  Third Party Terms
                  <span className="parragraph">
                    &quot;), as set forth in more detail via RapidCanvas’s Third Party Software
                    Notice file, which is available to Customer upon request. Use of such Third
                    Party Software in conjunction with the Product in a manner consistent with this
                    Agreement is permitted; however, broader rights may be granted under the
                    applicable Third Party Terms, and nothing in this Agreement is intended to
                    impose further restrictions on use of the Third Party Software. Notwithstanding
                    any other provision of this Agreement, RapidCanvas makes no warranties and will
                    have no obligations or liabilities with respect to any Third Party Software.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="2.5">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Customer Responsibilities.{" "}
                  <span className="parragraph">
                    Customer is responsible for Customer’s and Users’ access and use of the Service,
                    including all activity occurring under Customer’s and User’s accounts, and will
                    use reasonable measures to protect the account information and access
                    credentials (including passwords and devices or information used for
                    multi-factor authentication purposes) used by Customer and Users to access the
                    Product. Customer will promptly notify RapidCanvas of any unauthorized account
                    use or other suspected security breach, or unauthorized use, copying, or
                    distribution of the Product. If Customer installs the Software in the Customer
                    Cloud at a facility owned or operated by anyone other than Customer, Customer
                    will ensure that such third party is bound by an agreement containing terms that
                    are no less restrictive or protective of RapidCanvas’s rights than the
                    applicable terms of this Agreement, including those set forth in Section 2.3 (
                  </span>
                  Restrictions<span className="parragraph">) and in Section 4 (</span>
                  Confidentiality
                  <span className="parragraph">
                    ), and in any event Customer remains responsible for compliance with this
                    Agreement. Unless otherwise expressly agreed to by RapidCanvas and Customer
                    under separate terms and conditions for professional services, Customer shall be
                    solely responsible for the interoperability and configuration of the Product
                    with Customer Materials. RapidCanvas has no responsibility for Customer’s use
                    of, or inability to use, the Customer Materials with the Product.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="2.6">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark8">Customer Data</a>
                  <span className="parragraph">
                    . Customer is solely responsible for all Customer Data, including but not
                    limited to its accuracy, quality, and legality. Customer represents and warrants
                    that it: (a) has the legal rights to provide and use Customer Data in connection
                    with the Product; and (b) will comply with all applicable laws and regulations
                    relating to the collection, processing and use of Customer Data pursuant to this
                    Agreement. Customer is responsible for taking and maintaining appropriate steps
                    to protect the confidentiality, integrity, and security of Customer Data,
                    including but not limited to: (i) controlling access that Customer provides to
                    Users; and (ii) backing up Customer Data. Customer grants RapidCanvas a
                    non-exclusive, worldwide, royalty-free license to access and use the Customer
                    Data to perform its obligations (including to provide the Service), including to
                    create Enhancements and Intermediate Outputs, and otherwise exercise its rights
                    under this Agreement.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="2.7">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Third Party Services.{" "}
                  <span className="parragraph">
                    The Product may enable or require Customer to access or use third parties’
                    websites, platforms, content, products, services, or information (
                  </span>
                  “Third Party Services”
                  <span className="parragraph">
                    ). Third Party Services are not part of the Product, and RapidCanvas does not
                    control and is not responsible for the Third Party Services. Customer is solely
                    responsible for: (a) obtaining and complying with any terms of access and use of
                    the Third Party Services, including any separate fees or charges imposed by the
                    provider of the Third Party Services; and (b) configuring the Third Party
                    Services appropriately. RapidCanvas disclaims all responsibility and liability
                    arising from or related to Customer’s access or use of the Third Party Services,
                    including any impact on Product capabilities as a result of Customer’s use of,
                    or reliance upon, the Third Party Services.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="2.8">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Support
                  <span className="parragraph">
                    . During the Subscription Term, RapidCanvas will provide the technical support
                    described at rapidcanvas.ai/support. From time to time, RapidCanvas performs
                    scheduled maintenance to update the servers, software, and other technology that
                    are used to provide the Service and will use commercially reasonable efforts to
                    provide prior notice of such scheduled maintenance. Customer acknowledges that,
                    in certain situations, RapidCanvas may need to perform emergency maintenance of
                    the Service without providing prior notice.
                  </span>
                </h2>
              </li>
              <li className="listItem" data-list-text="2.9">
                <h2
                  className="header2"
                  style={{
                    paddingTop: "2pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}>
                  Modifications.{" "}
                  <span className="parragraph">
                    RapidCanvas reserves the right to modify the Product at its discretion,
                    including without limitation modifications, additions or deletions of features,
                    functionalities, and content available through the Service. RapidCanvas will use
                    commercially reasonable efforts to notify Customer of any material changes which
                    may adversely affect Customer’s ability to access or use the Product.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="2.10">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Additional Service Terms
                  <span className="parragraph">
                    . Subject to the terms and conditions of the Agreement, RapidCanvas will use
                    commercially reasonable efforts to make the Service available in accordance with
                    RapidCanvas’s Service Level Agreement available at rapidcanvas.ai/SLA.
                    RAPIDCANVAS DOES NOT GUARANTEE THAT CUSTOMER’S USE OF THE SERVICE WILL BE
                    ERROR-FREE OR UNINTERRUPTED. Customer acknowledges and agrees that RapidCanvas
                    may interrupt the Service as RapidCanvas deems necessary in order to maintain,
                    repair, restructure or make adjustments to the Service. RapidCanvas will use
                    commercially reasonable efforts to perform Service maintenance during
                    non-business hours and to provide Customer prior notice of any prolonged service
                    interruptions. RapidCanvas may immediately suspend Customer’s or User’s access
                    and use of the Service if:
                  </span>
                </h2>
                <p
                  className="parragraph"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  (a) RapidCanvas believes there is a significant threat to the functionality,
                  security, or integrity of the Service to RapidCanvas, Customer or to other
                  customers; (b) Customer accesses or uses the Service in violation of Section 2.3
                  (Restrictions); (c) Customer fails to pay the fee for access and use of the
                  Service to RapidCanvas in accordance with this Agreement. If reasonably
                  practicable and lawfully permitted, RapidCanvas will provide Customer with advance
                  notice of any such suspension. RapidCanvas will use reasonable efforts to
                  re-establish the subscription promptly after it determines that the issue causing
                  the suspension has been resolved. Any subscription suspension under this Section
                  shall not excuse Customer’s payment obligations under this Agreement.
                  Notwithstanding any other provision of this Agreement, Customer acknowledges that
                  RapidCanvas uses a third party provider (currently Google Cloud Platform (GCP))
                  for data center services with respect to the Service (“<b>Host Provider</b>”), and
                  Customer agrees that the terms of this Agreement, including without limitation
                  those relating to security, insurance requirements, business continuity, disaster
                  recovery, service level commitments and the like do not apply to the Host
                  Provider, except and to the extent the Host Provider has otherwise agreed to any
                  such terms.
                </p>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="2.11">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Certain Rights and Obligations.{" "}
                  <span className="parragraph">
                    During the Subscription Term, Customer will provide RapidCanvas with data and
                    reports regarding Customer’s usage of the Product, upon RapidCanvas’ reasonable
                    request. In addition, RapidCanvas may audit Customer’s use of the Product from
                    time to time upon fifteen (15) days prior written notice to ensure Customer’s
                    compliance with the terms of this Agreement, and Customer will provide
                    reasonable cooperation with respect to such audit.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="3.">
            <h2
              className="header2"
              style={{ paddingLeft: "15pt", textIndent: " -10pt", textAlign: "justify" }}>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
              <a name="bookmark9">PAYMENT; TAXES</a>
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l5">
              <li className="listItem" data-list-text="3.1">
                <h2
                  className="header2"
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "109%",
                    textAlign: "justify"
                  }}>
                  Fees and Payment
                  <span className="parragraph">
                    . Customer will pay RapidCanvas the subscription and fees for access and use of
                    the Product within thirty (30) days of the invoice date in U.S. dollars and via
                    the payment method specified on the invoice, unless otherwise noted in the Order
                    Schedule.
                  </span>
                </h2>
              </li>
              <li className="listItem" data-list-text="3.2">
                <h2
                  className="header2"
                  style={{
                    paddingTop: "7pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "109%",
                    textAlign: "justify"
                  }}>
                  Taxes.{" "}
                  <span className="parragraph">
                    All fees are exclusive of value added tax and any other federal, state,
                    municipal, or other governmental taxes, duties, licenses, fees, excises, or
                    tariffs, and Customer is responsible for paying any taxes assessed based on
                    Customer’s purchases under this Agreement.
                  </span>
                </h2>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="4.">
            <h2
              className="header2"
              style={{
                paddingTop: "7pt",
                paddingLeft: "15pt",
                textIndent: " -10pt",
                textAlign: "justify"
              }}>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
              <a name="bookmark10">CONFIDENTIALITY</a>
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l6">
              <li className="listItem" data-list-text="4.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark11">Confidentiality</a>
                  <span className="parragraph">
                    . Each party acknowledges that it and its Affiliates (
                  </span>
                  “Disclosing Party”
                  <span className="parragraph">
                    ) may have access to Confidential Information of the other party and its
                    Affiliates (
                  </span>
                  “Receiving Party”
                  <span className="parragraph">
                    ) in connection with this Agreement. The Receiving Party will use the same
                    degree of care that it uses to protect the confidentiality of its own
                    Confidential Information of a similar nature but not less than reasonable care.
                    The Receiving Party agrees to (i) not use any Confidential Information for any
                    purpose other than to perform its obligations and exercise its rights under this
                    Agreement, and (ii) restrict disclosure of Confidential Information only to
                    individuals or third parties with a “need to know” such information in
                    furtherance of this Agreement and who are under a substantially similar duty of
                    confidentiality as provided in this Section 4. The Receiving Party may disclose
                    the Disclosing Party’s Confidential Information in any legal proceeding or as
                    required by applicable law or regulation; provided, however, that to the extent
                    permitted by applicable law, the Receiving Party will (1) promptly notify the
                    Disclosing Party before disclosing the Disclosing Party’s Confidential
                    Information; (2) reasonably cooperate with and assist the Disclosing Party, at
                    the Disclosing Party’s expense, in any efforts by the Disclosing Party to
                    contest the disclosure; and (3) disclose only that portion of the Disclosing
                    Party’s Confidential Information that is legally required to be disclosed.
                  </span>
                </h2>
              </li>
              <li className="listItem" data-list-text="4.2">
                <h2
                  className="header2"
                  style={{
                    paddingTop: "2pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark12">Confidentiality Exclusions. </a>
                  <span className="parragraph">
                    Notwithstanding the foregoing, a Disclosing Party’s Confidential Information
                    will not include information that: (i) is or becomes a part of the public domain
                    through no act or omission of the Receiving Party; (ii) was in the Receiving
                    Party’s lawful possession prior to the disclosure by the Disclosing Party and
                    had not been obtained by the Receiving Party either directly or indirectly from
                    the Disclosing Party; (iii) is lawfully disclosed to the Receiving Party by a
                    third party without restriction on the disclosure; or (iv) is independently
                    developed by the Receiving Party without use of or reference to the Disclosing
                    Party’s Confidential Information.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="4.3">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark13">Usage Data</a>
                  <span className="parragraph">
                    . In the course of Customer’s use of the Product, RapidCanvas may collect,
                    access, use, process, transmit and/or store Usage Data in order to provide
                    services to its customers, and for product improvement, research and development
                    and other business purposes. RapidCanvas retains all intellectual property and
                    proprietary rights in and to such Usage Data and may use and disclose such data
                    at its sole discretion; provided that any such data that is shared with third
                    parties must be aggregated, anonymized, de-identified, or otherwise disclosed in
                    the manner such that it cannot reasonably be linked to an identifiable
                    individual or to Customer or Users.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="5.">
            <h2
              className="header2"
              style={{ paddingLeft: "15pt", textIndent: " -10pt", textAlign: "left" }}>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
              <a name="bookmark14">OWNERSHIP RIGHTS</a>
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l7">
              <li className="listItem" data-list-text="5.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark15">Customer Ownership</a>
                  <span className="parragraph">
                    . Except as expressly provided otherwise in this Agreement, as between
                    RapidCanvas and Customer, Customer retains all right, title, and interest in and
                    to Customer Data, Output and Customer Materials.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="5.2">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark16">RapidCanvas Ownership</a>
                  <span className="parragraph">
                    . As between RapidCanvas and Customer, RapidCanvas retains all right, title, and
                    interest, including all intellectual property rights, in and to the Product and
                    all other RapidCanvas Materials, including all improvements, enhancements,
                    modifications, derivative works, logos, and trademarks. RapidCanvas reserves all
                    rights in and to the Product that are not expressly granted under this
                    Agreement.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="5.3">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark17">Feedback</a>
                  <span className="parragraph">
                    . Customer or Users may provide suggestions, enhancement or feature requests, or
                    other feedback to RapidCanvas with respect to the Product (
                  </span>
                  “Feedback”
                  <span className="parragraph">
                    ). If Customer or Users provide Feedback, Customer agrees that RapidCanvas may
                    freely use the Feedback (including incorporating the Feedback into RapidCanvas’
                    products and technologies) without restriction and without paying any
                    compensation to Customer or Users.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="6.">
            <h2
              className="header2"
              style={{ paddingLeft: "15pt", textIndent: " -10pt", textAlign: "left" }}>
              WARRANTIES; DISCLAIMERS; LIMITATION OF LIABILITY
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l8">
              <li className="listItem" data-list-text="6.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Authority; Compliance
                  <span className="parragraph">
                    . RapidCanvas and Customer each represent and warrant that (a) it has full legal
                    power to enter into and to perform pursuant to this Agreement; and (b)
                    performance by the party under this Agreement will not conflict with, result in
                    a breach of, constitute a default under, or require the consent of any third
                    party under any obligation (including license, sublicense, lease, contract or
                    agreement) or instrument to which the party is bound. Customer warrants that
                    with respect to its Affiliates to whom Customer provides access to the Product,
                    Customer has the right to bind such Affiliates, or they are otherwise bound by
                    written agreement, to the terms and conditions of this Agreement, and Customer
                    acknowledges and agrees that RapidCanvas has the same rights and remedies under
                    this Agreement with respect to any default by Affiliates of Customer of such
                    terms and conditions as if such default were committed by Customer.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="6.2">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Additional RapidCanvas Warranties.{" "}
                  <span className="parragraph">
                    RapidCanvas warrants that, during the Subscription Term, the Software will
                    perform substantially in compliance with the corresponding Documentation. The
                    foregoing warranty shall not be valid if RapidCanvas determines that the defect
                    resulted from (a) modification of the Product without prior written approval of
                    RapidCanvas or made pursuant to Customer’s instructions, requirements or
                    specifications, (b) hardware failure or failure of hardware to conform with
                    applicable standards, (c) misuse of the Product, or (d) damage from accident,
                    acts of nature or other cause outside RapidCanvas’s control. Customer’s sole and
                    exclusive remedy for RapidCanvas’s breach of the foregoing warranty shall be for
                    RapidCanvas to use reasonable efforts to correct such defect in a timely manner.
                    This warranty is conditioned upon Customer providing RapidCanvas prompt written
                    notice of the Product’s non-conformity, using the Product in compliance with
                    this Agreement, and complying with RapidCanvas’s reasonable instruction with
                    respect to the alleged defective Product, which may include return of the
                    Software at RapidCanvas’s request.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="6.3">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark18">Warranty Disclaimer</a>
                  <span className="parragraph">
                    . EXCEPT AS EXPRESSLY STATED IN SECTION 6.1 AND 6.2, TO THE EXTENT ALLOWED BY
                    APPLICABLE LAW, RAPIDCANVAS EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF
                    ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY WARRANTY,
                    CONDITION, OR OTHER IMPLIED TERM AS TO MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, OR NON-INFRINGEMENT OF THE PRODUCT. RAPIDCANVAS MAKES NO WARRANTY OR
                    REPRESENTATION THAT THE SERVICE: (A) WILL BE UNINTERRUPTED, COMPLETELY SECURE,
                    ERROR-FREE, OR FREE OF VIRUSES; OR (B) WILL MEET CUSTOMER’S BUSINESS
                    REQUIREMENTS OR OPERATE WITH CUSTOMER MATERIALS. RAPIDCANVAS IS NOT RESPONSIBLE
                    FOR ANY ISSUES RELATED TO THE PERFORMANCE, OPERATION, OR SECURITY OF THE PRODUCT
                    THAT ARISE FROM CUSTOMER MATERIALS, THIRD PARTY SERVICES, OR ANY OTHER
                    TECHNOLOGIES, SERVICES OR OTHER MATERIALS PROVIDED BY THIRD PARTIES. RAPIDCANVAS
                    DISCLAIMS ANY RESPONSIBILITY OR LIABILITY FOR ANY INTERCEPTION OR INTERRUPTION
                    OF ANY COMMUNICATIONS THROUGH THE INTERNET, NETWORKS, OR SYSTEMS OUTSIDE
                    RAPIDCANVAS’S CONTROL.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="7.">
            <h2
              className="header2"
              style={{ paddingLeft: "15pt", textIndent: " -10pt", textAlign: "justify" }}>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
              <a name="bookmark19">LIMITATION OF LIABILITY</a>
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l9">
              <li className="listItem" data-list-text="7.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "20pt", textIndent: "-15pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark20">Limitation of Liability</a>
                  <span className="parragraph">.</span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="parragraph"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  IN NO EVENT WILL EITHER PARTY BE LIABLE FOR ANY INDIRECT, CONSEQUENTAL,
                  INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY DAMAGES, OR ANY LOSS OF REVENUES,
                  BUSINESS, PROFITS (IN EACH CASE WHETHER DIRECT OR INDIRECT), OR DATA IN CONNECTION
                  WITH THIS AGREEMENT OR THE PRODUCT, EVEN IF THE DAMAGES WERE FORESEEABLE OR A
                  PARTY HAD BEEN ADVISED OF THE POSSIBILITY OF THOSE DAMAGES.
                </p>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="parragraph"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  IN NO EVENT WILL THE AGGREGATE LIABILITY OF RAPIDCANVAS OR ITS AFFILIATES FOR
                  DIRECT DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE PRODUCT EXCEED
                  THE TOTAL AMOUNT PAID OR PAYABLE BY CUSTOMER TO RAPIDCANVAS UNDER THIS AGREEMENT
                  DURING THE APPLICABLE SUBSCRIPTION TERM.
                </p>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="parragraph"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  THE LIMITATIONS AND EXCLUSIONS OF LIABILITY IN THIS SECTION 7.1 APPLY (A) WHETHER
                  SUCH CLAIMS ARISE UNDER CONTRACT, TORT (INCLUDING NEGLIGENCE), EQUITY, STATUTE, OR
                  OTHERWISE, AND (B) NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF ANY
                  REMEDY. NOTHING IN THIS AGREEMENT LIMITS OR EXCLUDES ANY LIABILITY WHICH CANNOT BE
                  LIMITED OR EXCLUDED UNDER APPLICABLE LAW.
                </p>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="7.2">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  High Risk Activities.{" "}
                  <span className="parragraph">
                    The Software is not fault-tolerant and is not designed or intended for use in
                    hazardous environments requiring fail-safe performance, including without
                    limitation, in the operation of nuclear facilities, aircraft navigation or
                    communication systems, air traffic control, weapons systems, life-support
                    equipment, or any other application in which the failure of the Product could
                    lead to death, personal injury, or severe physical or property damage. Customer
                    agrees that it will not use the Product, and that RapidCanvas will not have any
                    liability, in connection with any such activities.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="8.">
            <h2
              className="header2"
              style={{ paddingLeft: "15pt", textIndent: " -10pt", textAlign: "justify" }}>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
              <a name="bookmark21">INDEMNIFICATION</a>
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l10">
              <li className="listItem" data-list-text="8.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "20pt", textIndent: "-15pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark22">Indemnification by RapidCanvas</a>
                  <span className="parragraph">.</span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <ol id="l11">
                  <li className="listItem" data-list-text="(a)">
                    <p
                      className="parragraph"
                      style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                      <a name="bookmark23">
                        RapidCanvas will defend and indemnify Customer from any third party claim,
                        action, suit, or proceeding alleging that Customer’s access and use of the
                        Product in accordance with this Agreement infringes such third party’s
                        patent, trademark, or copyright (“
                      </a>
                      <b>IP Claim</b>”). RapidCanvas will pay Customers’ reasonable attorney’s fees
                      and costs actually and necessarily incurred prior to tendering defense of the
                      IP Claim to RapidCanvas, and will pay any damages finally awarded against
                      Customer by a court of competent jurisdiction or agreed to by RapidCanvas in a
                      settlement. If an IP Claim is made or appears likely to be made, RapidCanvas,
                      in its sole discretion, may: (1) procure the right for Customer to continue
                      accessing or using the Product under the terms of this Agreement; or (2)
                      modify or replace the Product to be non-infringing without material decrease
                      in functionality. If RapidCanvas, in its sole discretion, determines that
                      neither of the foregoing options is reasonably feasible, RapidCanvas may
                      terminate Customer’s subscription to the Product upon written notice, and
                      provide a pro rata refund of the fees paid by Customer to RapidCanvas for the
                      remainder of the applicable Subscription Term. The foregoing shall be
                      RapidCanvas’s entire obligation and liability, and Customer’s exclusive
                      remedy, in connection with any IP Claim.
                    </p>
                    <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                      <br />
                    </p>
                  </li>
                  <li className="listItem" data-list-text="(b)">
                    <p
                      className="parragraph"
                      style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                      RapidCanvas will have no indemnity obligation for any claim to the extent such
                      IP Claim, in whole or in part, is based on: (i) a modification of the Product
                      by Customer or a third party; (ii) access or use of the Product in a manner
                      that violates the terms and conditions of this Agreement; (iii) technology,
                      designs, instructions, or requirements provided by Customer or a third party
                    </p>
                  </li>
                </ol>
                <p
                  className="parragraph"
                  style={{
                    paddingTop: "2pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}>
                  on Customer’s behalf; (iv) combination, operation, or use of the Product with
                  non-RapidCanvas products, software, services, or business processes, if a claim
                  would not have occurred but for such combination, operation, or use; or (v)
                  Customer Materials or Third Party Services.
                </p>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="8.2">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark24">Indemnification Procedures</a>
                  <span className="parragraph">
                    . Customer will: (a) promptly notify RapidCanvas in writing of any indemnifiable
                    claim; (b) give RapidCanvas all reasonable assistance, at RapidCanvas’ expense;
                    and (c) give RapidCanvas sole control of the defense and settlement of the
                    claim. Any settlement of a claim will not include a specific performance
                    obligation other than the obligation to cease using the Service, or an admission
                    of liability by Customer, without Customer’s consent. Customer may join in the
                    defense of an indemnifiable claim with counsel of its choice at its own expense
                    but may not settle or compromise the claim without RapidCanvas’ prior express
                    written consent.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="9.">
            <h2
              className="header2"
              style={{ paddingLeft: "15pt", textIndent: " -10pt", textAlign: "justify" }}>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
              <a name="bookmark25">TERM AND TERMINATION</a>
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l12">
              <li className="listItem" data-list-text="9.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Term
                  <span className="parragraph">
                    . This Agreement is effective as of the Effective Date and will remain in effect
                    until the earlier of (a) expiration of the applicable Subscription Term of all
                    Products under this Agreement, or (b) termination of this Agreement in
                    accordance with its terms.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="9.2">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Termination of Subscription
                  <span className="parragraph">
                    . Either party may terminate this Agreement and any then-current applicable
                    Order Schedule if the other party (i) materially breaches its obligations under
                    this Agreement and does not cure the breach within thirty (30) days after
                    receipt of written notice of the breach; or (ii) files or has filed against it,
                    any proceeding in bankruptcy or for the appointment of a receiver or any other
                    proceedings under any law for the relief of debtors, or ceases to do business in
                    the ordinary course.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="9.3">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark26">Effect of Termination</a>
                  <span className="parragraph">
                    . Upon termination or expiration of this Agreement: (a) all Customer rights
                    under this Agreement relating to the Product will immediately terminate; (b)
                    Customer is no longer authorized to access or use the Product or Customer’s
                    account; and (c) Customer promptly must destroy any and all copies of the
                    Software within Customer’s possession or under its control, and provide
                    RapidCanvas with written certification of such destruction. Upon any termination
                    by Customer for RapidCanvas’s uncured material breach of the Agreement,
                    RapidCanvas will provide or authorize a pro rata refund of the fees paid by
                    Customer to RapidCanvas for the remainder of the applicable Subscription Term.
                    Upon any termination by RapidCanvas for Customer’s uncured material breach of
                    the Agreement, Customer will pay any unpaid fees covering the remainder of the
                    then-current Subscription Term.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="9.4">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  Disposition of Customer Data and Output upon Termination.{" "}
                  <span className="parragraph">
                    After termination or expiration of this Agreement, Customer agrees that
                    RapidCanvas has no obligation to Customer to retain Customer Data or Output,
                    which may thereafter be permanently deleted by RapidCanvas. RapidCanvas will
                    protect the confidentiality of Customer Data and Output residing in the Service
                    for as long as such information resides in the Service.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="10.">
            <h2
              className="header2"
              style={{ paddingLeft: "20pt", textIndent: "-15pt", textAlign: "justify" }}>
              COMPLIANCE WITH LAWS
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l13">
              <li className="listItem" data-list-text="10.1">
                <h2
                  className="header2"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "106%",
                    textAlign: "justify"
                  }}>
                  General
                  <span className="parragraph">
                    . Each party agrees to comply with all laws and regulations applicable to such
                    party’s performance under this Agreement.
                  </span>
                </h2>
              </li>
              <li className="listItem" data-list-text="10.2">
                <h2
                  className="header2"
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "justify"
                  }}>
                  Export Licenses.{" "}
                  <span className="parragraph">
                    The Product and other RapidCanvas Materials that may be provided to Customer are
                    subject to U.S. export control laws and regulations, and Customer is solely
                    responsible for ensuring that its compliance with all such laws and regulations.
                    Customer is responsible for obtaining any licenses or authorizations required to
                    export, reexport, transfer or import the Product and the output created using
                    the Product. In particular, the Product may not, in the absence of authorization
                    by U.S. and local law and regulations, as required, be used by or exported or
                    reexported to (i) any U.S. sanctioned or embargoed country, or to nationals or
                    residents of such countries; or (ii) any person, entity, organization or other
                    party identified on the U.S. Department of Commerce’s Denied Persons or Entity
                    List, the U.S. Department of Treasury’s Specially Designated Nationals or
                    Blocked Persons List, or the Department of State’s Denied Persons List or Entity
                    List, as published and revised from time to time; or (iii) any party engaged in
                    proliferation activities or geospatial imagery analysis. Customer agrees that
                    RapidCanvas has no obligation to provide the Product where RapidCanvas believes
                    the provision of the Product could violate any applicable export control or
                    sanctions laws.
                  </span>
                </h2>
              </li>
            </ol>
          </li>
          <li className="listItem" data-list-text="11.">
            <h2
              className="header2"
              style={{
                paddingTop: "7pt",
                paddingLeft: "18pt",
                textIndent: "-12pt",
                textAlign: "left"
              }}>
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
              <a name="bookmark27">GENERAL</a>
            </h2>
            <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <ol id="l14">
              <li className="listItem" data-list-text="11.1">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark28">Assignment</a>
                  <span className="parragraph">
                    . Neither party may assign or transfer this Agreement without the other party’s
                    prior written consent; provided that, without such consent, each party may
                    assign and transfer this Agreement and its rights or obligations under this
                    Agreement, to an Affiliate or in connection with a transfer of the majority of
                    its stock or all or substantially all of its assets, by merger, acquisition or
                    similar transaction. RapidCanvas may in its sole discretion use third parties as
                    subcontractors under this Agreement and otherwise may delegate to a third party
                    any of its obligations under this Agreement. In that event, RapidCanvas will
                    remain responsible for performance of such obligations under this Agreement.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="11.2">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark29">Notice</a>
                  <span className="parragraph">
                    . RapidCanvas may provide Customer with notice (a) if applicable to the Product,
                    by means of a general notice on the RapidCanvas portal, on the rapidcanvas.ai
                    website, or any other website used as part of the Product, and (b) if specific
                    to the Customer, by electronic mail to the e-mail address in RapidCanvas’s
                    records. All notices to RapidCanvas concerning this Agreement should be
                    addressed to RapidCanvas at{" "}
                  </span>
                  <span className="parragraph">
                    <a
                      href="mailto:legal@rapidcanvas.ai"
                      className="a"
                      target="_blank"
                      rel="noreferrer">
                      legal@rapidcanvas.ai
                    </a>
                    , with a copy to Legal Department at Attn: Legal Department, RapidCanvas Inc.,
                    10900 RESEARCH BLVD STE160C PMB 30, AUSTIN, TX 78759.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="11.3">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                  Waiver.{" "}
                  <span className="parragraph">
                    Failure by either party to enforce any term or condition of this Agreement will
                    not be construed as a waiver of any of its rights under it.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="11.4">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark30">Severability</a>
                  <span className="parragraph">
                    . If any provision of the Agreement is held to be invalid or unenforceable, the
                    remaining provisions of the Agreement will remain in force to the fullest extent
                    permitted by law.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="11.5">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark31">Force Majeure</a>
                  <span className="parragraph">
                    . Neither party will be liable to the other for any delay or failure to perform
                    hereunder, except for Customer’s payment obligations, due to circumstances
                    beyond such party’s reasonable control, including acts of God, acts of
                    government, flood, fire, earthquakes, civil unrest, acts of terror, strikes or
                    other labor problems, or other industrial disturbances, systemic electrical,
                    telecommunications, or other utility failures.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="11.6">
                <h2
                  className="header2"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "justify"
                  }}>
                  Governing Law and Jurisdiction
                  <span className="parragraph">
                    . This Agreement will be governed by and construed in accordance with the laws
                    of the State of Texas, U.S.A, notwithstanding its conflicts of law principles,
                    and all claims arising out of or relating to this Agreement or the Product shall
                    be brought exclusively in the federal or state courts located in Travis County,
                    Texas, U.S.A. The parties agree that the UN Convention on Contracts for the
                    International Sale of Goods does not apply to this Agreement.
                  </span>
                </h2>
              </li>
              <li className="listItem" data-list-text="11.7">
                <h2
                  className="header2"
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify"
                  }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark32">Survival</a>
                  <span className="parragraph">
                    . The following sections, together with any other terms necessary for the
                    interpretation or enforcement of this Agreement, will survive termination or
                    expiration of this Agreement: 1 (
                  </span>
                  Definitions<span className="parragraph">), 2.3 (</span>Restrictions
                  <span className="parragraph">), 3 (</span>
                  Payment<span className="parragraph">), 4 (</span>Confidentiality
                  <span className="parragraph">), 5 (</span>
                  Ownership Rights<span className="parragraph">), 6.3 (</span>Warranty Disclaimer
                  <span className="parragraph">), 7 (</span>Limitation of Liability
                  <span className="parragraph">), 8 (</span>
                  Indemnification<span className="parragraph">), 9.3 (</span>Effect of Termination
                  <span className="parragraph">), 9.4 (</span>Customer Data upon Termination
                  <span className="parragraph">), and 11 (</span>General
                  <span className="parragraph">).</span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="11.8">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark33">Independent Parties</a>
                  <span className="parragraph">
                    . RapidCanvas and Customer are independent contractors, and nothing in this
                    Agreement will create a partnership, joint venture, agency, franchise, sales
                    representative, or employment relationship between the parties.
                  </span>
                </h2>
                <p className="parragraph" style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </li>
              <li className="listItem" data-list-text="11.9">
                <h2
                  className="header2"
                  style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; name: string; }' is not ... Remove this comment to see the full error message */}
                  <a name="bookmark34">Entire Agreement</a>
                  <span className="parragraph">
                    . This Agreement, the Order Schedule(s) and any other documents referenced in
                    this Agreement constitute the entire agreement between the parties with respect
                    to the Product and supersede all prior or contemporaneous oral or written
                    communications, agreements, or representations with respect to the Product.
                    Except as otherwise expressly provided in this Agreement, any modification to
                    the terms and conditions of this Agreement requires a written amendment to this
                    Agreement signed by authorized representatives of both parties.
                  </span>
                </h2>
              </li>
            </ol>
          </li>
        </ol>
      </section>
    </Grid>
  );
};

export default UsageAndPolicy;
