import React, { useMemo } from "react";

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  makeStyles
} from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import {
  validFileTypes,
  maximumNoOfFilesAllowed,
  maximumTotalFilesSizeAllowedInGb,
  DatasetHelperText
} from "../../utils/Dataset.constants";

import { InfoPropsType } from "../../types/Props.type";
import useStoreSelectors from "../../hooks/useStoreSelectors";

const useStyles = makeStyles((theme) => ({
  dataSourcesFilesUploadInfoContainer: {
    fontStyle: "italic",
    "& .infoIcon": {
      marginTop: theme.spacing(0.25)
    },
    "& .infoTextContainer": {
      flex: 1,
      marginLeft: theme.spacing(1)
    },
    "& .infoText": {
      marginBottom: theme.spacing(1)
    }
  },
  list: {
    "& [class^='MuiListItem-root']": {
      alignItems: "baseline",
      paddingLeft: 0,
      "& [class^='MuiListItemText-root']": {
        "& [class^='MuiTypography-subtitle2']": {
          opacity: 0.6
        }
      },
      "& [class^='MuiListItemIcon-root']": {
        minWidth: "auto",
        marginRight: theme.spacing(1),
        "& [class^='MuiSvgIcon-root']": {
          fontSize: "x-small"
        }
      }
    }
  }
}));

const Info = (props: InfoPropsType) => {
  const { isDataSourcesFilesUpload } = props || {};

  const classes: $TSFixMe = useStyles();

  const {
    datasetCriterionStore,

    datasetFilesStore
  } = useStoreSelectors();

  const isDataSourcesFilesUploadInfo = useMemo(
    () => isDataSourcesFilesUpload && !!datasetCriterionStore?.value,
    [isDataSourcesFilesUpload, datasetCriterionStore, datasetFilesStore]
  );

  return (
    <>
      {isDataSourcesFilesUploadInfo ? (
        <Grid container className={classes.dataSourcesFilesUploadInfoContainer}>
          <InfoOutlinedIcon className="infoIcon" fontSize="small" />
          <Grid item className="infoTextContainer">
            <Typography
              id="dataSourcesFilesUploadInfoLine1"
              variant="caption"
              component="div"
              color="textSecondary"
              className="infoText">
              {DatasetHelperText.UploadedDataColumnInfoLine1}
            </Typography>
            <Typography
              id="dataSourcesFilesUploadInfoLine2"
              variant="caption"
              component="div"
              color="textSecondary">
              {DatasetHelperText.UploadedDataColumnInfoLine2}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <List dense className={classes.list}>
          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                id="filesUploadInfoFileTypesSupported"
                variant="subtitle2"
                color="textPrimary">
                {DatasetHelperText.FileTypesSupported}:
              </Typography>
              <Typography
                id="filesUploadInfoValidFileTypes"
                variant="body2"
                color="textPrimary">{` ${validFileTypes.join(", ")}.`}</Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                id="filesUploadInfoMaximumNoOfFilesAllowed"
                variant="subtitle2"
                color="textPrimary">
                {DatasetHelperText.MaximumNoOfFilesAllowed}:
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary">{` ${maximumNoOfFilesAllowed} Nos.`}</Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                id="filesUploadInfoMaximumFileSizeAllowed"
                variant="subtitle2"
                color="textPrimary">
                {DatasetHelperText.MaximumFileSizeAllowed}:
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary">{` ${maximumTotalFilesSizeAllowedInGb} GB`}</Typography>
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <FiberManualRecordIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                id="filesUploadInfoExcelMultiSheetInfo"
                variant="body2"
                color="textPrimary">
                {DatasetHelperText.ExcelMultiSheetInfo}
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      )}
    </>
  );
};

export default Info;
