export const capitalize = (term = "") => {
  if (term === "" || term === null) return term;

  const lowerString = term.toLowerCase();
  return lowerString?.charAt(0)?.toUpperCase() + lowerString?.slice(1);
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return string;
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};
