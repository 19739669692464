/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import { v4 as uuidv4 } from "uuid";
import { createTemplateWithRethrow } from "src/api";
import { postAPIWithRethrow } from "src/utils/apiService";

export const useViewOutputMutation = () => {
  return useMutation({
    mutationFn: async ({
      code,
      recipeId,
      projectId
    }: {
      code: string;
      recipeId: string | undefined;
      projectId?: string;
    }) => {
      const templateName = uuidv4();
      const templateGroupPayload = {
        name: templateName,
        code,
        projectId,
        baseTransforms: [{ condition: null, type: "python", params: {}, runConfigId: null }],
        type: "CODE"
      };

      const templateResponse: $TSFixMe = await createTemplateWithRethrow(templateGroupPayload);
      return await postAPIWithRethrow(`/v2/dfs-run-config-groups/testv2/${recipeId}`, [
        { templateId: templateResponse.id, name: templateName, variables: {} }
      ]);
    },
    cacheTime: 0
  });
};
