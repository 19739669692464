/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Condition } from './condition';
// May contain unused imports in some cases
// @ts-ignore
import type { PipelineStep } from './pipeline-step';

/**
 * 
 * @export
 * @interface CreateTransformDTO
 */
export interface CreateTransformDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateTransformDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransformDTO
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransformDTO
     */
    'displayName'?: string;
    /**
     * 
     * @type {Array<PipelineStep>}
     * @memberof CreateTransformDTO
     */
    'parents'?: Array<PipelineStep>;
    /**
     * 
     * @type {number}
     * @memberof CreateTransformDTO
     */
    'positionX'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransformDTO
     */
    'positionY'?: number;
    /**
     * 
     * @type {Condition}
     * @memberof CreateTransformDTO
     */
    'condition'?: Condition;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateTransformDTO
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreateTransformDTO
     */
    'envId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTransformDTO
     */
    'timeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTransformDTO
     */
    'recipeType'?: CreateTransformDTORecipeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTransformDTO
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTransformDTO
     */
    'icon'?: string;
}

export const CreateTransformDTORecipeTypeEnum = {
    Standard: 'STANDARD',
    Code: 'CODE',
    AutoMl: 'AUTO_ML',
    ApiConnector: 'API_CONNECTOR'
} as const;

export type CreateTransformDTORecipeTypeEnum = typeof CreateTransformDTORecipeTypeEnum[keyof typeof CreateTransformDTORecipeTypeEnum];


