import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import styles from "./entity-modal.module.scss";

const EventModal = ({ data }: $TSFixMe) => {
  return (
    <div className={styles.transformGroupModal}>
      <Divider light />
      <Accordion defaultExpanded elevation={0}>
        <AccordionSummary
          style={{ flexDirection: "row-reverse" }}
          expandIcon={<ExpandMore color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography color="primary">List of transformations: </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!data.transforms.length ? (
            <h5>No segments available </h5>
          ) : (
            data.transforms.map((transform: $TSFixMe, index: number) => (
              <div key={index} className={styles.templateGroup}>
                <div>
                  <div className={styles.templateGroupTitle}>{transform?.templateName}</div>
                  <div className={styles.templateGroupItems}>
                    Methode:{" "}
                    <span className={styles.templateGroupItemsSpan}>
                      {transform?.variables?.JoiningMethod}
                    </span>
                  </div>
                  <div className={styles.templateGroupItems}>
                    Input:{" "}
                    <span className={styles.templateGroupItemsSpan}>
                      {transform?.variables?.LeftDataset ?? "-"}
                    </span>
                  </div>
                  <div className={styles.templateGroupItems}>
                    Output:{" "}
                    <span className={styles.templateGroupItemsSpan}>
                      {transform?.variables?.RightDataset ?? "-"}
                    </span>
                  </div>
                </div>
              </div>
            ))
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EventModal;
