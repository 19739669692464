/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateGlobalVariableDto } from '../Models';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { GlobalVariableDto } from '../Models';
/**
 * GlobalVariableControllerApi - axios parameter creator
 * @export
 */
export const GlobalVariableControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/v2/variables/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} variableId 
         * @param {string} targetId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assign: async (variableId: string, targetId: string, type: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableId' is not null or undefined
            assertParamExists('assign', 'variableId', variableId)
            // verify required parameter 'targetId' is not null or undefined
            assertParamExists('assign', 'targetId', targetId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('assign', 'type', type)
            const localVarPath = `/v2/variables/assign/{variableId}`
                .replace(`{${"variableId"}}`, encodeURIComponent(String(variableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateGlobalVariableDto} createGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createGlobalVariableDto: CreateGlobalVariableDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGlobalVariableDto' is not null or undefined
            assertParamExists('create', 'createGlobalVariableDto', createGlobalVariableDto)
            const localVarPath = `/v2/variables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGlobalVariableDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} variableId 
         * @param {string} targetId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignee: async (variableId: string, targetId: string, type: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableId' is not null or undefined
            assertParamExists('deleteAssignee', 'variableId', variableId)
            // verify required parameter 'targetId' is not null or undefined
            assertParamExists('deleteAssignee', 'targetId', targetId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteAssignee', 'type', type)
            const localVarPath = `/v2/variables/assign/{variableId}`
                .replace(`{${"variableId"}}`, encodeURIComponent(String(variableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByProject: async (projectId?: string, projectRunId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/variables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (projectRunId !== undefined) {
                localVarQueryParameter['projectRunId'] = projectRunId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/v2/variables/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByName: async (name: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getByName', 'name', name)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getByName', 'projectId', projectId)
            const localVarPath = `/v2/variables/by-name/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} variableId 
         * @param {CreateGlobalVariableDto} createGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (variableId: string, createGlobalVariableDto: CreateGlobalVariableDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'variableId' is not null or undefined
            assertParamExists('update', 'variableId', variableId)
            // verify required parameter 'createGlobalVariableDto' is not null or undefined
            assertParamExists('update', 'createGlobalVariableDto', createGlobalVariableDto)
            const localVarPath = `/v2/variables/{variableId}`
                .replace(`{${"variableId"}}`, encodeURIComponent(String(variableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGlobalVariableDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalVariableControllerApi - functional programming interface
 * @export
 */
export const GlobalVariableControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalVariableControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariableControllerApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} variableId 
         * @param {string} targetId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assign(variableId: string, targetId: string, type: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalVariableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assign(variableId, targetId, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariableControllerApi.assign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateGlobalVariableDto} createGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createGlobalVariableDto: CreateGlobalVariableDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalVariableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createGlobalVariableDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariableControllerApi.create']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} variableId 
         * @param {string} targetId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAssignee(variableId: string, targetId: string, type: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalVariableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssignee(variableId, targetId, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariableControllerApi.deleteAssignee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByProject(projectId?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalVariableDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByProject(projectId, projectRunId, projectRunEntryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariableControllerApi.findByProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalVariableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariableControllerApi.get']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByName(name: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalVariableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByName(name, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariableControllerApi.getByName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} variableId 
         * @param {CreateGlobalVariableDto} createGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(variableId: string, createGlobalVariableDto: CreateGlobalVariableDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalVariableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(variableId, createGlobalVariableDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GlobalVariableControllerApi.update']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GlobalVariableControllerApi - factory interface
 * @export
 */
export const GlobalVariableControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalVariableControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} variableId 
         * @param {string} targetId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assign(variableId: string, targetId: string, type: string, options?: any): AxiosPromise<GlobalVariableDto> {
            return localVarFp.assign(variableId, targetId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateGlobalVariableDto} createGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(createGlobalVariableDto: CreateGlobalVariableDto, options?: any): AxiosPromise<GlobalVariableDto> {
            return localVarFp.create(createGlobalVariableDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} variableId 
         * @param {string} targetId 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAssignee(variableId: string, targetId: string, type: string, options?: any): AxiosPromise<GlobalVariableDto> {
            return localVarFp.deleteAssignee(variableId, targetId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByProject(projectId?: string, projectRunId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<Array<GlobalVariableDto>> {
            return localVarFp.findByProject(projectId, projectRunId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): AxiosPromise<GlobalVariableDto> {
            return localVarFp.get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByName(name: string, projectId: string, options?: any): AxiosPromise<GlobalVariableDto> {
            return localVarFp.getByName(name, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} variableId 
         * @param {CreateGlobalVariableDto} createGlobalVariableDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(variableId: string, createGlobalVariableDto: CreateGlobalVariableDto, options?: any): AxiosPromise<GlobalVariableDto> {
            return localVarFp.update(variableId, createGlobalVariableDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GlobalVariableControllerApi - interface
 * @export
 * @interface GlobalVariableControllerApi
 */
export interface GlobalVariableControllerApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApiInterface
     */
    _delete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} variableId 
     * @param {string} targetId 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApiInterface
     */
    assign(variableId: string, targetId: string, type: string, options?: RawAxiosRequestConfig): AxiosPromise<GlobalVariableDto>;

    /**
     * 
     * @param {CreateGlobalVariableDto} createGlobalVariableDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApiInterface
     */
    create(createGlobalVariableDto: CreateGlobalVariableDto, options?: RawAxiosRequestConfig): AxiosPromise<GlobalVariableDto>;

    /**
     * 
     * @param {string} variableId 
     * @param {string} targetId 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApiInterface
     */
    deleteAssignee(variableId: string, targetId: string, type: string, options?: RawAxiosRequestConfig): AxiosPromise<GlobalVariableDto>;

    /**
     * 
     * @param {string} [projectId] 
     * @param {string} [projectRunId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApiInterface
     */
    findByProject(projectId?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GlobalVariableDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApiInterface
     */
    get(id: string, options?: RawAxiosRequestConfig): AxiosPromise<GlobalVariableDto>;

    /**
     * 
     * @param {string} name 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApiInterface
     */
    getByName(name: string, projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<GlobalVariableDto>;

    /**
     * 
     * @param {string} variableId 
     * @param {CreateGlobalVariableDto} createGlobalVariableDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApiInterface
     */
    update(variableId: string, createGlobalVariableDto: CreateGlobalVariableDto, options?: RawAxiosRequestConfig): AxiosPromise<GlobalVariableDto>;

}

/**
 * GlobalVariableControllerApi - object-oriented interface
 * @export
 * @class GlobalVariableControllerApi
 * @extends {BaseAPI}
 */
export class GlobalVariableControllerApi extends BaseAPI implements GlobalVariableControllerApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApi
     */
    public _delete(id: string, options?: RawAxiosRequestConfig) {
        return GlobalVariableControllerApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} variableId 
     * @param {string} targetId 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApi
     */
    public assign(variableId: string, targetId: string, type: string, options?: RawAxiosRequestConfig) {
        return GlobalVariableControllerApiFp(this.configuration).assign(variableId, targetId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateGlobalVariableDto} createGlobalVariableDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApi
     */
    public create(createGlobalVariableDto: CreateGlobalVariableDto, options?: RawAxiosRequestConfig) {
        return GlobalVariableControllerApiFp(this.configuration).create(createGlobalVariableDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} variableId 
     * @param {string} targetId 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApi
     */
    public deleteAssignee(variableId: string, targetId: string, type: string, options?: RawAxiosRequestConfig) {
        return GlobalVariableControllerApiFp(this.configuration).deleteAssignee(variableId, targetId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectId] 
     * @param {string} [projectRunId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApi
     */
    public findByProject(projectId?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return GlobalVariableControllerApiFp(this.configuration).findByProject(projectId, projectRunId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApi
     */
    public get(id: string, options?: RawAxiosRequestConfig) {
        return GlobalVariableControllerApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApi
     */
    public getByName(name: string, projectId: string, options?: RawAxiosRequestConfig) {
        return GlobalVariableControllerApiFp(this.configuration).getByName(name, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} variableId 
     * @param {CreateGlobalVariableDto} createGlobalVariableDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalVariableControllerApi
     */
    public update(variableId: string, createGlobalVariableDto: CreateGlobalVariableDto, options?: RawAxiosRequestConfig) {
        return GlobalVariableControllerApiFp(this.configuration).update(variableId, createGlobalVariableDto, options).then((request) => request(this.axios, this.basePath));
    }
}

