import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { EnvDto } from "openapi/Models/env-dto";

const QUERY_KEY_ENVIRONMENTS = "query-key-environments";

const useGetEnvironments = (options?: UseQueryOptions<EnvDto[]>): UseQueryResult<EnvDto[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_ENVIRONMENTS],
    queryFn: async () => {
      const response = await api.fetchResponse(async () => await api.EnvControllerApi.findEnvs());
      return response;
    },
    ...options
  });
};

export default useGetEnvironments;
export { QUERY_KEY_ENVIRONMENTS };
