import { useQueryClient, useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getJobByIdWithRethrow } from "src/api/projects";

type PrefetchingJobProps = { queryClient: $TSFixMe; jobId: string };

interface JobProps extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  jobId?: string | null;
  isInitialData?: boolean;

  // useQuery options
  cacheTime?: number;
}

export const prefetchJob = (props: PrefetchingJobProps) => {
  const { queryClient, jobId } = props;

  queryClient.prefetchQuery({
    queryKey: ["job", jobId],
    queryFn: () => getJobByIdWithRethrow(jobId)
  });
};

export const useGetJob = (props: JobProps): UseQueryResult<$TSFixMe> => {
  const {
    projectId,
    jobId,
    isInitialData = false,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;

  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["job", jobId],
    queryFn: async () => {
      if (!jobId) {
        return;
      }

      return await getJobByIdWithRethrow(jobId);
    },
    select: (data: $TSFixMe) => (Object.keys(data || {})?.length > 0 ? data : {}),

    cacheTime,
    enabled: !!jobId,
    ...(isInitialData
      ? {
          initialData: () => {
            const data: $TSFixMe = queryClient.getQueryData(["jobs", projectId]);

            if ((data || [])?.length > 0) {
              return data.find((eachJob: $TSFixMe) => eachJob?.dto?.id === jobId)?.dto || {};
            }

            return {};
          }
        }
      : {}),
    ...options
  });
};
