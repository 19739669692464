import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";
import { flatten, isEmpty, map, uniq } from "lodash";

const QUERY_KEY_ENTITIES_FEATURES = "query-key-entities-features";

const useMultipleEntityFeatures = (
  projectId?: string,
  entityIds?: string[],
  projectRunEntryId?: string,
  projectRunEntityName?: string
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [
      QUERY_KEY_ENTITIES_FEATURES,
      projectId,
      entityIds,
      projectRunEntryId,
      projectRunEntityName
    ],
    enabled: !isEmpty(entityIds),
    queryFn: async () => {
      const entityDataFeaturesResponse = await Promise.all(
        map(entityIds, (entityId) => getAPIWithRethrow(`/v2/features?entityId=${entityId}`))
      );

      return uniq(map(flatten(entityDataFeaturesResponse), "name"));
    }
  });
};

export default useMultipleEntityFeatures;
export { QUERY_KEY_ENTITIES_FEATURES };
