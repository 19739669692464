import FileCopyIcon from "@material-ui/icons/FileCopy";
import React from "react";
import { IconButton, Paper, TextField, Tooltip } from "@material-ui/core";

import useCopyClipboard from "src/hooks/useCopyClipboard";
import { PSFields } from "../utils/PredictionService.constants";
import { useStyles } from "./SampleCurlCommand";

interface IProps {
  name: string;
  onInputChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const UniqueEndPoint: React.FC<IProps> = (props) => {
  const { name, onInputChange } = props;
  const classes = useStyles();
  const { handleCopyClick, isCopied } = useCopyClipboard(name);

  return (
    <Paper className={classes.wrapper}>
      <div style={{ marginBottom: "16px" }} className={classes.details}>
        Unique Endpoint
      </div>
      <div className={classes.flex}>
        <TextField
          fullWidth
          id={PSFields.endpoint.id}
          name={PSFields.endpoint.id}
          label={PSFields.endpoint.label}
          value={name}
          disabled
          size="small"
          test-id="prediction-service-endpoint"
          variant="outlined"
          onChange={onInputChange}
        />
        <Tooltip title={isCopied ? "Copied" : "Copy"}>
          <IconButton className={classes.copyBtn} onClick={handleCopyClick}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Paper>
  );
};

export default UniqueEndPoint;
