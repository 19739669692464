import React from "react";

export const PlusIcon = ({ disabled }: { disabled?: boolean }) => {
  return (
    <svg
      width="26"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: disabled ? 0.5 : "default" }}>
      <path
        d="M9.6665 15H20.3332M14.9998 20.3333V9.66666M10.9998 28.3333H18.9998C25.6665 28.3333 28.3332 25.6667 28.3332 19V11C28.3332 4.33333 25.6665 1.66666 18.9998 1.66666H10.9998C4.33317 1.66666 1.6665 4.33333 1.6665 11V19C1.6665 25.6667 4.33317 28.3333 10.9998 28.3333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
