import { get, has } from "lodash";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { NodeChartTheme } from "src/pages/private/ProjectsModule/utils";

type StyleProps = {
  status: string;
};

const mainContainerDimensions = 40;
const beforeElementDimensions = 35;
const reducedDimensions = (mainContainerDimensions - beforeElementDimensions) / 2; // 40px: main-container, 35px: before-element.

const beforeElementStyles = {
  content: '""',
  width: beforeElementDimensions,
  height: beforeElementDimensions,
  top: reducedDimensions,
  left: reducedDimensions,
  position: "absolute",
  display: "block",
  borderRadius: beforeElementDimensions / 3,
  transform: "rotate(45deg)",
  zIndex: -1
};

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: mainContainerDimensions,
    height: mainContainerDimensions,
    "&:before": {
      ...beforeElementStyles,
      backgroundColor: ({ status }: StyleProps) =>
        has(NodeChartTheme, status)
          ? get(NodeChartTheme, [status, "backgroundColor"])
          : NodeChartTheme.backgroundColor,
      border: "1px solid",
      borderColor: ({ status }: StyleProps) =>
        has(NodeChartTheme, status)
          ? get(NodeChartTheme, [status, "borderColor"])
          : NodeChartTheme.borderColor
    },
    "&:hover:before": {
      ...beforeElementStyles,
      cursor: "pointer",
      backgroundColor: ({ status }: StyleProps) =>
        has(NodeChartTheme, status)
          ? get(NodeChartTheme, [status, "onHover", "backgroundColor"])
          : NodeChartTheme.onHover.backgroundColor,
      border: "1px solid",
      borderColor: ({ status }: StyleProps) =>
        has(NodeChartTheme, status)
          ? get(NodeChartTheme, [status, "onHover", "borderColor"])
          : NodeChartTheme.onHover.borderColor
    },
    "&.highlighted": {
      "&:after": {
        content: '""',
        top: 0,
        width: 40,
        height: 40,
        display: "block",
        background: "#bbe6ff",
        position: "absolute",
        filter: "blur(5.550000190734863px)",
        transform: "rotate(45deg)",
        zIndex: -2
      }
    },
    "&.isContextMenuHighlight": {
      "&:after": {
        content: '""',
        top: 0,
        width: 40,
        height: 40,
        display: "block",
        background: "#fff4bc",
        position: "absolute",
        filter: "blur(5.550000190734863px)",
        transform: "rotate(45deg)",
        zIndex: -2
      }
    }
  },
  container: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center"
  },
  status: {
    top: -(20 - reducedDimensions),
    position: "absolute",
    color: ({ status }: StyleProps) =>
      has(NodeChartTheme, status)
        ? get(NodeChartTheme, [status, "statusColor"])
        : NodeChartTheme.statusColor,
    "&:hover": {
      color: ({ status }: StyleProps) =>
        has(NodeChartTheme, status)
          ? get(NodeChartTheme, [status, "onHover", "statusColor"])
          : NodeChartTheme.onHover.statusColor
    }
  },
  icon: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: reducedDimensions,
    "& svg": {
      margin: "0 0 5% -5%"
    }
  },
  label: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 100,
    color: "#000",
    whiteSpace: "nowrap"
  }
});

export default useStyles;
