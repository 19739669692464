import React, { useMemo, useState } from "react";

import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";

import { Spinner } from "src/components";

import PreviewIconNew from "src/icons/NewUX/Dataset/PreviewIconNew";

import OntologySampleData from "./OntologySampleData";

import { DatasetHelperText } from "../../../utils/Dataset.constants";
import OntologyLoaderWrapper from "../../OntologyLoaderWrapper";

import OntologyHeader from "./OntologyHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "100%",
    borderRadius: theme.spacing(1.5),
    "& > [class^='MuiGrid-root']": {
      padding: theme.spacing(2)
    }
  }
}));

const OntologyContainer = (props: $TSFixMe) => {
  const {
    datasetIsOntologyStore,
    dataset,
    isOntologyProcessing,
    ontologySampleData,
    ontologySchemaOptionsData,
    ontologySchemaData,
    isFetchingOntologySchema,
    refetchOntologySchema,
    isFetchingOntologySampleData,
    setIsFetchingOntologySampleData,
    refetchOntologySampleData,
    updateDatasetLite,
    getDatasetCustomColumnsData,
    visibleColumns
  } = props || {};

  const classes: $TSFixMe = useStyles();

  // States - STARTS >>
  const [searchTerm, setSearchTerm] = useState<$TSFixMe>("");
  // << ENDS - States

  const loader = useMemo(() => {
    if (isFetchingOntologySampleData) {
      return (
        <OntologyLoaderWrapper>
          <Typography color="primary">
            <PreviewIconNew />
          </Typography>
          <Typography variant="body2" color="primary">
            Fetching sample data...
          </Typography>
        </OntologyLoaderWrapper>
      );
    } else if (!!datasetIsOntologyStore && isFetchingOntologySchema) {
      return (
        <OntologyLoaderWrapper>
          <Typography color="primary">
            <PreviewIconNew />
          </Typography>
          <Typography variant="body2" color="primary">
            Fetching schema...
          </Typography>
        </OntologyLoaderWrapper>
      );
    } else if (isOntologyProcessing) {
      return <Spinner />;
    } else {
      return null;
    }
  }, [
    datasetIsOntologyStore,
    isOntologyProcessing,
    isFetchingOntologySchema,
    isFetchingOntologySampleData
  ]);

  const noDataFoundMessage = (message: string) => (
    <OntologyLoaderWrapper>
      <Typography variant="body2" color="primary">
        {message}
      </Typography>
    </OntologyLoaderWrapper>
  );

  return (
    <>
      <Paper className={classes.root}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>{DatasetHelperText.SampleData}</Grid>

          <Grid item>
            <OntologyHeader
              datasetIsOntologyStore={datasetIsOntologyStore}
              dataset={dataset}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              refetchOntologySchema={refetchOntologySchema}
              setIsFetchingOntologySampleData={setIsFetchingOntologySampleData}
              refetchOntologySampleData={refetchOntologySampleData}
              updateDatasetLite={updateDatasetLite}
            />
          </Grid>
        </Grid>

        {!!loader ? (
          loader
        ) : (ontologySampleData?.columns || [])?.length === 0 ||
          (ontologySampleData?.rows || [])?.length === 0 ? (
          noDataFoundMessage(DatasetHelperText.NoSampleDataFound)
        ) : (
          <OntologySampleData
            datasetIsOntologyStore={datasetIsOntologyStore}
            ontologySchemaOptionsData={ontologySchemaOptionsData}
            ontologySchemaData={ontologySchemaData}
            ontologySampleData={ontologySampleData}
            searchTerm={searchTerm}
            getDatasetCustomColumnsData={getDatasetCustomColumnsData}
            visibleColumns={visibleColumns}
          />
        )}
      </Paper>
    </>
  );
};

export default OntologyContainer;
