import React, { useState } from "react";
import { Grid, Button, makeStyles, Typography, Menu } from "@material-ui/core";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import { SearchField } from "..";
import clsx from "clsx";
import MenuList from "../MenuList";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "rgba(2, 16, 43, 1)",
    boxShadow: theme.shadows[1],
    "& > div:first-child": {
      padding: "10px 22px",
      background: "rgb(242 242 242 / 50%)"
    },
    "& > div": {
      borderBottom: "1px solid #D3D9DC"
    },
    "& > div:nth-child(2)": {
      paddingRight: 22,
      backgroundColor: "#FFFFFF"
    }
  },
  noPadding: {
    "& > div:first-child": {
      padding: "0"
    }
  },
  statusPill: {
    borderRadius: 2,
    padding: 4,
    backgroundColor: "#6FCF97",
    color: "#FFFFFF",
    fontSize: 10
  },
  title: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  listContainer: {
    maxHeight: 150,
    overflow: "auto"
  },
  styledDiv: {
    padding: "6px 16px"
  },
  checkboxContainer: {
    display: "flex",
    borderBottom: "1px solid #CDCDCD"
  }
}));

type Props = {
  info: {
    name: string;
    value?: $TSFixMe;
  }[];
  size?: string;
  visibleColumns?: $TSFixMe[];
  columnsList?: $TSFixMe[];
  title?: $TSFixMe;
  actions?: $TSFixMe;
  onDownload?: $TSFixMeFunction;
  onCheckboxChange?: $TSFixMeFunction;
  customLeftContent?: $TSFixMe;
  customRightContent?: $TSFixMe;
  noOptions?: boolean;
  showSearchField?: boolean;
  searchProps?: {
    placeholder?: string;
    onChange?: $TSFixMeFunction;
  };
  noPadding?: boolean;
  className?: string;
  hasMenuListSearchBar?: boolean;
  showToggleHistogram?: boolean;
  toggleHistogramChecked?: boolean;
  onHistogramChange?: $TSFixMeFunction;
  isDisabled?: boolean;
};

const TableHeader = ({
  title,
  size,
  // onDownload,
  noOptions,
  showSearchField,
  searchProps = { placeholder: "Search...", onChange: console.error },
  actions,
  visibleColumns = [],
  columnsList = [],
  onCheckboxChange,
  noPadding,
  className,
  customLeftContent,
  customRightContent,
  // @ts-expect-error TS(2339) FIXME: Property 'customRightContentSearch' does not exist... Remove this comment to see the full error message
  customRightContentSearch,
  showToggleHistogram = false,
  toggleHistogramChecked = true,
  onHistogramChange,
  isDisabled
}: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleHiddenColumnsChange = (e: $TSFixMe) => {
    const checked = e.target.checked;
    const targetValue = e.currentTarget.value;
    if (targetValue === "selectAll") {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      if (checked) return onCheckboxChange([...columnsList]);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      else return onCheckboxChange([]);
    }
    if (checked) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onCheckboxChange([...visibleColumns, targetValue]);
    } else {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onCheckboxChange(visibleColumns.filter((column) => column !== targetValue));
    }
  };

  const handleColumnSearch = (value: $TSFixMe) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    if (!value) return onCheckboxChange([...columnsList]);
    const columnsSelected = columnsList.filter((column) => {
      const regex = new RegExp(value, "gi");
      return column.match(regex);
    });
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onCheckboxChange(columnsSelected);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Grid
      item
      xs={12}
      className={clsx(classes.container, className, noPadding ? classes.noPadding : false)}>
      <Grid container alignItems="center">
        {title && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              className={classes.title}
              variant={size == "medium" ? "subtitle1" : "subtitle2"}>
              {title}
            </Typography>
          </div>
        )}
        {customLeftContent && (
          <div style={{ flex: "1 0 0", overflow: "auto" }}>{customLeftContent}</div>
        )}
        {showSearchField && (
          <Grid item xs={2} style={{ marginRight: customLeftContent ? 10 : 0 }}>
            <SearchField
              placeholder={searchProps.placeholder || "Search..."}
              onChange={searchProps.onChange}
              style={{ width: "100%" }}
            />
          </Grid>
        )}
        {customRightContentSearch && (
          <div
            style={{
              marginTop: "8px",
              marginLeft: "12px"
            }}>
            {customRightContentSearch}
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          {customRightContent && (
            <div style={{ flexGrow: 1, display: "flex" }}>{customRightContent}</div>
          )}
          {actions && <div style={{ display: "flex", justifyContent: "flex-end" }}>{actions}</div>}
          {!noOptions && (
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            <Button
              size="small"
              endIcon={<SettingsApplicationsOutlinedIcon />}
              style={{ marginLeft: 24, fontSize: 10 }}
              onClick={isOpen ? null : toggleDropdown}
              ref={setAnchorEl}
              disabled={isDisabled}>
              Table Settings
              <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={toggleDropdown}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
                <MenuList
                  list={columnsList}
                  handleSearch={handleColumnSearch}
                  handleColumnChange={handleHiddenColumnsChange}
                  visibleColumns={visibleColumns}
                  hasToggle={showToggleHistogram}
                  handleToggle={onHistogramChange}
                  isToggleActive={toggleHistogramChecked}
                  toggleLabel
                />
              </Menu>
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default TableHeader;
