import React, { useMemo, useState } from "react";
// // @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useTable, usePagination, useSortBy, useResizeColumns } from "react-table";
import { TableHeader } from "../../../../../components/Table/TableHeader/TableHeader";
import Pagination from "../../../../../components/Table/Pagination";
import { Link } from "react-router-dom";

import Text from "src/components/Widget/Text";
import styles from "../../Environments.module.scss";
import { OverflowTooltip } from "../../../../../components";
import { dateFormat } from "src/utils/dateFormat";

const PAGE_SIZE = 10;

export const EnvironmentProjectsTable = ({ data }: $TSFixMe) => {
  const [orderBy, setOrderBy] = useState();
  const columns = useMemo(
    () => [
      {
        id: "Project Name",
        accessor: "name",
        Header: "Project Name",
        isSortable: true,
        Cell: ({ row, value }: $TSFixMe) => (
          <Link className={styles.nameLinkStyles} to={`/projects/${row?.original?.id}/canvas`}>
            <Text value={value} />
          </Link>
        )
      },
      {
        id: "Description",
        accessor: "description",
        Header: "Description",
        isSortable: true
      },
      {
        id: "Creator",
        accessor: "creator",
        Header: "Creator",
        isSortable: true
      },
      {
        id: "Creation Date",
        accessor: "createAt",
        Header: "Creation Date",
        isSortable: true,
        Cell: (row: $TSFixMe) => <span>{dateFormat(row.value)}</span>
      },
      {
        id: "Job Count",
        accessor: "jobCount",
        Header: "Job Count",
        isSortable: true,
        Cell: ({ row, value }: $TSFixMe) =>
          value ? (
            <Link className={styles.nameLinkStyles} to={`/projects/${row?.original?.id}/jobs`}>
              <Text value={value} />
            </Link>
          ) : (
            0
          )
      },
      {
        id: "Prediction Job Count",
        accessor: "predictionJobCount",
        Header: "Prediction Job Count",
        isSortable: true,
        Cell: ({ row, value }: $TSFixMe) =>
          value ? (
            <Link
              className={styles.nameLinkStyles}
              to={`/projects/${row?.original?.id}/prediction-job`}>
              <Text value={value} />
            </Link>
          ) : (
            0
          )
      }
    ],
    []
  );

  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data,
      initialState: {
        pageSize: PAGE_SIZE
      },
      autoResetPage: false
    },
    useSortBy,
    usePagination,
    useResizeColumns
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    state: { pageIndex }
  } = tableInstance;

  return (
    <section style={{ overflow: "auto", paddingRight: 24 }}>
      <table className={styles.table} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: $TSFixMe) => {
            const { key: groupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr className={styles.theadRow} key={groupKey} {...restHeaderGroupProps}>
                {headerGroup.headers.map((column: $TSFixMe) => {
                  const key = column.id;
                  return (
                    <TableHeader
                      key={key}
                      className={styles.theadCell}
                      column={column}
                      orderBy={orderBy}
                      headerSortActive={setOrderBy}
                    />
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: $TSFixMe) => {
            prepareRow(row);
            const { key: rowKey, ...restRowProps } = row.getRowProps();
            return (
              <tr className={styles.tbodyRow} key={rowKey} {...restRowProps}>
                {row.cells.map((cell: $TSFixMe) => {
                  const { key: cellKey, ...restCellProps } = cell.getCellProps();
                  return (
                    <td className={styles.tbodyCell} key={cellKey} {...restCellProps}>
                      <OverflowTooltip value={cell.render("Cell")} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {rows.length > PAGE_SIZE && (
        <Pagination
          pageOptions={pageOptions}
          pageCount={pageCount}
          actualPage={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
        />
      )}
    </section>
  );
};
