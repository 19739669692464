import React from "react";

import ViewDataSchemaTable from "./ViewDataSchemaTable/ViewDataSchemaTable";

const ViewDataSchema = ({ entityFeatures, isLoading, maxHeight }: $TSFixMe) => {
  return (
    <section style={{ overflow: "auto" }}>
      <ViewDataSchemaTable data={entityFeatures} isLoadingData={isLoading} maxHeight={maxHeight} />
    </section>
  );
};

export default ViewDataSchema;
