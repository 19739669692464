import React from "react";

const DATA_TYPES_ICONS = {
  DOUBLE: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66667 19H8.11111L8.11111 15.8889H5V14.3333H8.11111L8.11111 9.66667H5V8.11111H8.11111L8.11111 5H9.66667V8.11111H14.3333V5H15.8889V8.11111H19V9.66667H15.8889V14.3333H19V15.8889H15.8889V19H14.3333V15.8889H9.66667V19ZM14.3333 14.3333L14.3333 9.66667H9.66667V14.3333H14.3333Z"
        fill="#003656"
      />
    </svg>
  ),
  FLOAT: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke="#003656" fill="none" />
      <path
        d="M8.71582 5V14.9531H7V5H8.71582ZM12.7764 9.35449V10.7148H8.27832V9.35449H12.7764ZM13.3438 5V6.36719H8.27832V5H13.3438Z"
        fill="#003656"
      />
    </svg>
  ),
  LONG: (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke="#003656" fill="none" />
      <path d="M7 15.1818V5H9.1527V13.407H13.5178V15.1818H7Z" fill="#003656" />
    </svg>
  ),
  TIMESTAMP: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.992 4C7.576 4 4 7.584 4 12C4 16.416 7.576 20 11.992 20C16.416 20 20 16.416 20 12C20 7.584 16.416 4 11.992 4ZM12 18.4C8.464 18.4 5.6 15.536 5.6 12C5.6 8.464 8.464 5.6 12 5.6C15.536 5.6 18.4 8.464 18.4 12C18.4 15.536 15.536 18.4 12 18.4ZM11.824 8H11.776C11.456 8 11.2 8.256 11.2 8.576V12.352C11.2 12.632 11.344 12.896 11.592 13.04L14.912 15.032C15.184 15.192 15.536 15.112 15.696 14.84C15.864 14.568 15.776 14.208 15.496 14.048L12.4 12.208V8.576C12.4 8.256 12.144 8 11.824 8Z"
        fill="#003656"
      />
    </svg>
  ),
  BOOLEAN: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7ZM7 15C5.34 15 4 13.66 4 12C4 10.34 5.34 9 7 9C8.66 9 10 10.34 10 12C10 13.66 8.66 15 7 15Z"
        fill="#003656"
      />
    </svg>
  ),
  STRING: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 17V19H19V17H5ZM9.5 12.8H14.5L15.4 15H17.5L12.75 4H11.25L6.5 15H8.6L9.5 12.8ZM12 5.98L13.87 11H10.13L12 5.98Z"
        fill="#003656"
      />
    </svg>
  ),
  SET: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"></svg>
  ),
  LIST: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 13C4.55 13 5 12.55 5 12C5 11.45 4.55 11 4 11C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 17C4.55 17 5 16.55 5 16C5 15.45 4.55 15 4 15C3.45 15 3 15.45 3 16C3 16.55 3.45 17 4 17ZM4 9C4.55 9 5 8.55 5 8C5 7.45 4.55 7 4 7C3.45 7 3 7.45 3 8C3 8.55 3.45 9 4 9ZM8 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13ZM8 17H20C20.55 17 21 16.55 21 16C21 15.45 20.55 15 20 15H8C7.45 15 7 15.45 7 16C7 16.55 7.45 17 8 17ZM7 8C7 8.55 7.45 9 8 9H20C20.55 9 21 8.55 21 8C21 7.45 20.55 7 20 7H8C7.45 7 7 7.45 7 8ZM4 13C4.55 13 5 12.55 5 12C5 11.45 4.55 11 4 11C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM4 17C4.55 17 5 16.55 5 16C5 15.45 4.55 15 4 15C3.45 15 3 15.45 3 16C3 16.55 3.45 17 4 17ZM4 9C4.55 9 5 8.55 5 8C5 7.45 4.55 7 4 7C3.45 7 3 7.45 3 8C3 8.55 3.45 9 4 9ZM8 13H20C20.55 13 21 12.55 21 12C21 11.45 20.55 11 20 11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13ZM8 17H20C20.55 17 21 16.55 21 16C21 15.45 20.55 15 20 15H8C7.45 15 7 15.45 7 16C7 16.55 7.45 17 8 17ZM7 8C7 8.55 7.45 9 8 9H20C20.55 9 21 8.55 21 8C21 7.45 20.55 7 20 7H8C7.45 7 7 7.45 7 8Z"
        fill="#003656"
      />
    </svg>
  ),
  MAP: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 10.5C4 10.2239 4.22386 10 4.5 10H15.5C15.7761 10 16 10.2239 16 10.5V11.5C16 11.7761 15.7761 12 15.5 12H4.5C4.22386 12 4 11.7761 4 11.5V10.5ZM4 6.5C4 6.22386 4.22386 6 4.5 6H15.5C15.7761 6 16 6.22386 16 6.5V7.5C16 7.77614 15.7761 8 15.5 8H4.5C4.22386 8 4 7.77614 4 7.5V6.5ZM4 14.5C4 14.2239 4.22386 14 4.5 14H11.5C11.7761 14 12 14.2239 12 14.5V15.5C12 15.7761 11.7761 16 11.5 16H4.5C4.22386 16 4 15.7761 4 15.5V14.5ZM14.7572 14.4543C14.424 14.2544 14 14.4944 14 14.8831V19.1169C14 19.5056 14.424 19.7456 14.7572 19.5457L18.2854 17.4287C18.6091 17.2345 18.6091 16.7655 18.2854 16.5713L14.7572 14.4543Z"
        fill="#003656"
      />
    </svg>
  ),
  BLOB: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.64 16.464L13.632 16.472L11.712 14.552L12.56 13.704L13.64 14.784L15.632 12.8L16.48 13.648L13.648 16.48L13.64 16.464ZM12 4C7.6 4 4 7.6 4 12C4 16.4 7.6 20 12 20C16.4 20 20 16.4 20 12C20 7.6 16.4 4 12 4ZM8.272 7.424L9.4 8.552L10.528 7.424L11.376 8.272L10.248 9.4L11.376 10.528L10.528 11.376L9.4 10.248L8.272 11.376L7.424 10.528L8.552 9.4L7.424 8.272L8.272 7.424ZM12 18.4C10.24 18.4 8.64 17.68 7.44 16.56L16.56 7.44C17.68 8.64 18.4 10.24 18.4 12C18.4 15.52 15.52 18.4 12 18.4Z"
        fill="#003656"
      />
    </svg>
  ),
  EMPTY: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 6.9C13.92 6.9 14.63 7.44 14.89 8.3C15.02 8.73 15.45 9 15.9 9H15.96C16.66 9 17.18 8.29 16.93 7.64C16.49 6.49 15.52 5.56 14 5.19V4.5C14 3.67 13.33 3 12.5 3C11.67 3 11 3.67 11 4.5V5.16C10.61 5.24 10.25 5.37 9.89998 5.52L11.41 7.03C11.73 6.95 12.1 6.9 12.5 6.9ZM4.76998 4.62C4.37998 5.01 4.37998 5.64 4.76998 6.03L7.49998 8.77C7.49998 10.85 9.05998 11.99 11.41 12.68L14.92 16.19C14.58 16.68 13.87 17.1 12.5 17.1C10.85 17.1 9.99998 16.51 9.66998 15.67C9.51998 15.28 9.17998 15 8.76998 15H8.59998C7.87998 15 7.35998 15.74 7.64998 16.39C8.23998 17.72 9.53998 18.51 11.01 18.83V19.5C11.01 20.33 11.68 21 12.51 21C13.34 21 14.01 20.33 14.01 19.5V18.85C14.97 18.67 15.84 18.3 16.47 17.73L17.98 19.24C18.37 19.63 19 19.63 19.39 19.24C19.78 18.85 19.78 18.22 19.39 17.83L6.17998 4.62C5.78998 4.23 5.15998 4.23 4.76998 4.62Z"
        fill="#003656"
      />
    </svg>
  ),
  ANY: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"></svg>
  ),
  ID: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8333 16H14.3333V13.7143H12.77C12.105 15.0971 10.67 16 9.08333 16C6.83167 16 5 14.2057 5 12C5 9.79429 6.83167 8 9.08333 8C10.67 8 12.0992 8.90286 12.77 10.2857H19V13.7143H17.8333V16ZM15.5 14.8571H16.6667V12.5714H17.8333V11.4286H11.965L11.8308 11.0457C11.4225 9.90857 10.3142 9.14286 9.08333 9.14286C7.47333 9.14286 6.16667 10.4229 6.16667 12C6.16667 13.5771 7.47333 14.8571 9.08333 14.8571C10.3142 14.8571 11.4225 14.0914 11.8308 12.9543L11.965 12.5714H15.5V14.8571ZM9.08333 13.7143C8.12083 13.7143 7.33333 12.9429 7.33333 12C7.33333 11.0571 8.12083 10.2857 9.08333 10.2857C10.0458 10.2857 10.8333 11.0571 10.8333 12C10.8333 12.9429 10.0458 13.7143 9.08333 13.7143ZM9.08333 11.4286C8.7625 11.4286 8.5 11.6857 8.5 12C8.5 12.3143 8.7625 12.5714 9.08333 12.5714C9.40417 12.5714 9.66667 12.3143 9.66667 12C9.66667 11.6857 9.40417 11.4286 9.08333 11.4286Z"
        fill="#003656"
      />
    </svg>
  )
};

export default DATA_TYPES_ICONS;
