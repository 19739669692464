// inputEntities store selectors
export const getInputEntities = (state: $TSFixMe) => state.inputEntities;
export const inputEntitiesSetter = (state: $TSFixMe) => state.setInputEntities;
export const getSelectedInputEntity = (state: $TSFixMe) => state.selectedInputEntity;
export const selectedInputEntitySetter = (state: $TSFixMe) => state.setSelectedInputEntity;
export const crossProjectInputEntitiesSetter = (state: $TSFixMe) =>
  state.setCrossProjectInputEntities;
export const getCrossProjectInputEntities = (state: $TSFixMe) => state.crossProjectInputEntities;
export const getSegments = (state: $TSFixMe) => state.segments;
export const segmentsSetter = (state: $TSFixMe) => state.setSegments;
export const getEntityFeatures = (state: $TSFixMe) => state.entityFeatures;
export const entityFeaturesSetter = (state: $TSFixMe) => state.setEntityFeatures;
export const getSegmentData = (state: $TSFixMe) => state.segmentData;
export const segmentDataSetter = (state: $TSFixMe) => state.setSegmentData;
export const getVisibleColumns = (state: $TSFixMe) => state.visibleColumns;
export const visibleColumnsSetter = (state: $TSFixMe) => state.setVisibleColumns;

// outputEntities store selectors
export const getOutputEntityFeatures = (state: $TSFixMe) => state.outputEntityFeatures;
export const outputEntityFeaturesSetter = (state: $TSFixMe) => state.setOutputEntityFeatures;

export const getSideComponent = (state: $TSFixMe) => state.sideComponent;
export const getSideComponentProps = (state: $TSFixMe) => state.sideComponentProps;
export const sideComponentSetter = (state: $TSFixMe) => state.setSideComponent;

// dfs store selectors
export const getDfsList = (state: $TSFixMe) => state.dfsList;
export const dfsListSetter = (state: $TSFixMe) => state.setDfsList;

// run-config selectors
export const getRunConfigs = (state: $TSFixMe) => state.runConfigs;
export const runConfigsSetter = (state: $TSFixMe) => state.setRunConfigs;
export const getConfigGroup = (state: $TSFixMe) => state.configGroup;
export const configGroupSetter = (state: $TSFixMe) => state.setConfigGroup;
export const previewTabsGetter = (state: $TSFixMe) => state.previewTabs;
export const previewTabsSetter = (state: $TSFixMe) => state.setPreviewTabs;
export const entitiesFeaturesGetter = (state: $TSFixMe) => state.entitiesFeatures;
export const entitiesFeaturesSetter = (state: $TSFixMe) => state.setEntitiesFeatures;

// pipelines store selectors
export const toggleDirty = (state: $TSFixMe) => state.toggleDirty;
export const getIsDirty = (state: $TSFixMe) => state.isDirty;

// projects selectors
export const projectsSetter = (state: $TSFixMe) => state.setProjectList;
export const projectsGetter = (state: $TSFixMe) => state.projectList;
export const shouldRefreshProjectsGetter = (state: $TSFixMe) => state.shouldProjectsRefresh;
export const shouldRefreshProjectsToggler = (state: $TSFixMe) => state.toggleShouldRefresh;
export const shouldRefreshProjectsSetter = (state: $TSFixMe) => state.setShouldProjectsRefresh;

// jobs selectors
export const jobParametersGetter = (state: $TSFixMe) => state.jobParameters;
export const jobParametersSetter = (state: $TSFixMe) => state.setJobParameters;
export const jobParametersResetter = (state: $TSFixMe) => state.resetJobParameters;

export const shouldRefreshJobsGetter = (state: $TSFixMe) => state.shouldJobsRefresh;
export const shouldRefreshJobsToggler = (state: $TSFixMe) => state.toggleShouldRefresh;

export const watchingJobsGetter = (state: $TSFixMe) => state.watchingJobs;
export const watchingJobsSetter = (state: $TSFixMe) => state.setWatchingJobs;

export const watchingJobsIntervalIdGetter = (state: $TSFixMe) => state.watchingJobsIntervalId;
export const watchingJobsIntervalIdSetter = (state: $TSFixMe) => state.setWatchingJobsIntervalId;

// data sources selectors
export const dataSourcesSetter = (state: $TSFixMe) => state.setDataSources;
export const dataSourcesGetter = (state: $TSFixMe) => state.dataSources;
export const connectorsSetter = (state: $TSFixMe) => state.setConnectors;
export const connectorsGetter = (state: $TSFixMe) => state.connectors;
export const dataSourceTypeSetter = (state: $TSFixMe) => state.setDataSourceType;
export const dataSourceTypeGetter = (state: $TSFixMe) => state.dataSourceType;
export const isCreateDataSourceSetter = (state: $TSFixMe) => state.setIsCreateDataSource;
export const isCreateDataSourceGetter = (state: $TSFixMe) => state.isCreateDataSource;

// environments selectors
export const environmentsSetter = (state: $TSFixMe) => state.setEnvironmentList;
export const environmentsGetter = (state: $TSFixMe) => state.environmentList;
export const shouldRefreshEnvironmentsGetter = (state: $TSFixMe) => state.shouldEnvironmentsRefresh;
export const shouldRefreshEnvironmentsToggler = (state: $TSFixMe) => state.toggleShouldRefresh;
export const isEnvironmentsLoadingGetter = (state: $TSFixMe) => state.isEnvironmentsLoading;
export const setIsEnvironmentLoadingSetter = (state: $TSFixMe) => state.setIsEnvironmentLoading;
export const watchingEnvsSetter = (state: $TSFixMe) => state.setWatchingEnvs;
export const watchingEnvsGetter = (state: $TSFixMe) => state.watchingEnvs;
export const watchingEnvsIntervalIdSetter = (state: $TSFixMe) => state.setWatchingEnvsIntervalId;
export const watchingEnvsIntervalIdGetter = (state: $TSFixMe) => state.watchingEnvsIntervalId;

// canvas selectors
export const getNodes = (state: $TSFixMe) => state.nodes;
export const nodesSetter = (state: $TSFixMe) => state.setNodes;

// is environment relaunching selectors
export const isEnvRelaunchNotifiedGetter = (state: $TSFixMe) => state.isNotified;
export const isEnvRelaunchNotifiedSetter = (state: $TSFixMe) => state.setIsNotified;

export const dataAppsGetter = (state: $TSFixMe) => state.dataApps;

// Toasts - STARTS >>
export const toastContentGetter = (state: $TSFixMe) => state.toastContent;
export const toastContentSetter = (state: $TSFixMe) => state.setToastContent;
// << ENDS - Toasts
