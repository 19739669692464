import { useQuery } from "@tanstack/react-query";

import { DataAppType } from "src/pages/DataApps/DataApps.type";
import { getAPIWithRethrow } from "src/utils/apiService";

type Props = {
  dataAppName?: string;
  onSuccess?: (data: DataAppType[]) => void;
};

const QUERY_KEY_DATAAPPS_ACROSS_TENANTS = "query-key-dataapps-across-tenants";

const useGetDataAppAcrossTenants = ({ dataAppName }: Props) => {
  return useQuery<DataAppType[]>({
    queryKey: [QUERY_KEY_DATAAPPS_ACROSS_TENANTS, dataAppName],
    queryFn: async () => {
      if (!dataAppName) {
        throw "dataAppName is absent";
      }

      return await getAPIWithRethrow(`/dataapps/across-tenants`, {
        params: { name: dataAppName }
      });
    },
    refetchOnMount: true
  });
};

export default useGetDataAppAcrossTenants;
export { QUERY_KEY_DATAAPPS_ACROSS_TENANTS };
