/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface SolutionDashboardDto
 */
export interface SolutionDashboardDto {
    /**
     * 
     * @type {string}
     * @memberof SolutionDashboardDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionDashboardDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionDashboardDto
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionDashboardDto
     */
    'updater'?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionDashboardDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolutionDashboardDto
     */
    'industries'?: Array<SolutionDashboardDtoIndustriesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolutionDashboardDto
     */
    'useCases'?: Array<SolutionDashboardDtoUseCasesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolutionDashboardDto
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SolutionDashboardDto
     */
    'image'?: string;
    /**
     * 
     * @type {number}
     * @memberof SolutionDashboardDto
     */
    'recipeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionDashboardDto
     */
    'dataSetCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionDashboardDto
     */
    'scenarioCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionDashboardDto
     */
    'viewCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionDashboardDto
     * @deprecated
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionDashboardDto
     */
    'updated'?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionDashboardDto
     */
    'created'?: number;
    /**
     * 
     * @type {string}
     * @memberof SolutionDashboardDto
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionDashboardDto
     */
    'display_name'?: string;
}

export const SolutionDashboardDtoIndustriesEnum = {
    Default: 'DEFAULT',
    Banking: 'BANKING',
    Ecommerce: 'ECOMMERCE',
    Logistics: 'LOGISTICS',
    Marketplace: 'MARKETPLACE'
} as const;

export type SolutionDashboardDtoIndustriesEnum = typeof SolutionDashboardDtoIndustriesEnum[keyof typeof SolutionDashboardDtoIndustriesEnum];
export const SolutionDashboardDtoUseCasesEnum = {
    Default: 'DEFAULT',
    Banking: 'BANKING',
    Ecommerce: 'ECOMMERCE',
    Logistics: 'LOGISTICS',
    Marketplace: 'MARKETPLACE'
} as const;

export type SolutionDashboardDtoUseCasesEnum = typeof SolutionDashboardDtoUseCasesEnum[keyof typeof SolutionDashboardDtoUseCasesEnum];


