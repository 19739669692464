/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { GraphDto } from '../Models';
// @ts-ignore
import type { GroupRunResultDto } from '../Models';
// @ts-ignore
import type { Metadata } from '../Models';
// @ts-ignore
import type { ModelEntityDto } from '../Models';
// @ts-ignore
import type { PipelineStep } from '../Models';
// @ts-ignore
import type { PositionDto } from '../Models';
// @ts-ignore
import type { ProjectAssociatesDetailDto } from '../Models';
// @ts-ignore
import type { ProjectCanvasDto } from '../Models';
// @ts-ignore
import type { ProjectDashboardDto } from '../Models';
// @ts-ignore
import type { UpdateCollapsedNodeStatusDTO } from '../Models';
// @ts-ignore
import type { UpdateGroupDto } from '../Models';
/**
 * ProjectControllerV2Api - axios parameter creator
 * @export
 */
export const ProjectControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} type 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {boolean} [deleteAssociatedPJs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStep: async (id: string, type: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStep', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteStep', 'type', type)
            const localVarPath = `/v2/project-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (deleteAssociatedPDs !== undefined) {
                localVarQueryParameter['deleteAssociatedPDs'] = deleteAssociatedPDs;
            }

            if (deleteAssociatedPJs !== undefined) {
                localVarQueryParameter['deleteAssociatedPJs'] = deleteAssociatedPJs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsWithInputDataSets: async (projectUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('getAllModelsWithInputDataSets', 'projectUuid', projectUuid)
            const localVarPath = `/v2/project/{projectUuid}/all-models`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} entityId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsWithInputDataSets1: async (projectUuid: string, entityId: string, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('getAllModelsWithInputDataSets1', 'projectUuid', projectUuid)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getAllModelsWithInputDataSets1', 'entityId', entityId)
            const localVarPath = `/v2/project/model-parent-canvas/{projectUuid}/{entityId}`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)))
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard: async (count?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/projects/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stepId 
         * @param {string} stepType 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {boolean} [deleteAssociatedPJs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeleteCandidates: async (stepId: string, stepType: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stepId' is not null or undefined
            assertParamExists('getDeleteCandidates', 'stepId', stepId)
            // verify required parameter 'stepType' is not null or undefined
            assertParamExists('getDeleteCandidates', 'stepType', stepType)
            const localVarPath = `/v2/project-step/delete-candidates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stepId !== undefined) {
                localVarQueryParameter['stepId'] = stepId;
            }

            if (stepType !== undefined) {
                localVarQueryParameter['stepType'] = stepType;
            }

            if (deleteAssociatedPDs !== undefined) {
                localVarQueryParameter['deleteAssociatedPDs'] = deleteAssociatedPDs;
            }

            if (deleteAssociatedPJs !== undefined) {
                localVarQueryParameter['deleteAssociatedPJs'] = deleteAssociatedPJs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAssociates: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectAssociates', 'projectId', projectId)
            const localVarPath = `/v2/project/{projectId}/associates`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {boolean} [chartsEnabled] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectCanvas: async (projectUuid: string, chartsEnabled?: boolean, scenarioId?: string, projectRunId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('getProjectCanvas', 'projectUuid', projectUuid)
            const localVarPath = `/v2/project-canvas/{projectUuid}`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (chartsEnabled !== undefined) {
                localVarQueryParameter['chartsEnabled'] = chartsEnabled;
            }

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }

            if (projectRunId !== undefined) {
                localVarQueryParameter['projectRunId'] = projectRunId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectMetadata: async (projectUuid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('getProjectMetadata', 'projectUuid', projectUuid)
            const localVarPath = `/v2/project-all-metadata/{projectUuid}`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<UpdateCollapsedNodeStatusDTO>} updateCollapsedNodeStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        group: async (projectUuid: string, updateCollapsedNodeStatusDTO: Array<UpdateCollapsedNodeStatusDTO>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('group', 'projectUuid', projectUuid)
            // verify required parameter 'updateCollapsedNodeStatusDTO' is not null or undefined
            assertParamExists('group', 'updateCollapsedNodeStatusDTO', updateCollapsedNodeStatusDTO)
            const localVarPath = `/v2/project-canvas/{projectUuid}/update-collapsed`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCollapsedNodeStatusDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {UpdateGroupDto} updateGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        group1: async (projectUuid: string, updateGroupDto: UpdateGroupDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('group1', 'projectUuid', projectUuid)
            // verify required parameter 'updateGroupDto' is not null or undefined
            assertParamExists('group1', 'updateGroupDto', updateGroupDto)
            const localVarPath = `/v2/project-canvas/{projectUuid}/group`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGroupDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {GraphDto} graphDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rearrangeGraph: async (projectUuid: string, graphDto: GraphDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('rearrangeGraph', 'projectUuid', projectUuid)
            // verify required parameter 'graphDto' is not null or undefined
            assertParamExists('rearrangeGraph', 'graphDto', graphDto)
            const localVarPath = `/v2/rearrange-graph/{projectUuid}`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(graphDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<string>} scenarioIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runProject: async (projectUuid: string, scenarioIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('runProject', 'projectUuid', projectUuid)
            // verify required parameter 'scenarioIds' is not null or undefined
            assertParamExists('runProject', 'scenarioIds', scenarioIds)
            const localVarPath = `/v2/projects/runs/{projectUuid}`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioIds) {
                localVarQueryParameter['scenarioIds'] = scenarioIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runProject1: async (projectUuid: string, scenarioId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('runProject1', 'projectUuid', projectUuid)
            const localVarPath = `/v2/projects/run/{projectUuid}`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scenarioId !== undefined) {
                localVarQueryParameter['scenarioId'] = scenarioId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ungroup: async (projectUuid: string, body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('ungroup', 'projectUuid', projectUuid)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('ungroup', 'body', body)
            const localVarPath = `/v2/project-canvas/{projectUuid}/group`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<PositionDto>} positionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositions: async (projectUuid: string, positionDto: Array<PositionDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUuid' is not null or undefined
            assertParamExists('updatePositions', 'projectUuid', projectUuid)
            // verify required parameter 'positionDto' is not null or undefined
            assertParamExists('updatePositions', 'positionDto', positionDto)
            const localVarPath = `/v2/project-canvas/{projectUuid}`
                .replace(`{${"projectUuid"}}`, encodeURIComponent(String(projectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(positionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectControllerV2Api - functional programming interface
 * @export
 */
export const ProjectControllerV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectControllerV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} type 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {boolean} [deleteAssociatedPJs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStep(id: string, type: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStep(id, type, deleteAssociatedPDs, deleteAssociatedPJs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.deleteStep']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllModelsWithInputDataSets(projectUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModelEntityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllModelsWithInputDataSets(projectUuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.getAllModelsWithInputDataSets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} entityId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllModelsWithInputDataSets1(projectUuid: string, entityId: string, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectCanvasDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllModelsWithInputDataSets1(projectUuid, entityId, scenarioId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.getAllModelsWithInputDataSets1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboard(count?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDashboardDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboard(count, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.getDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} stepId 
         * @param {string} stepType 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {boolean} [deleteAssociatedPJs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeleteCandidates(stepId: string, stepType: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineStep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeleteCandidates(stepId, stepType, deleteAssociatedPDs, deleteAssociatedPJs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.getDeleteCandidates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectAssociates(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAssociatesDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectAssociates(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.getProjectAssociates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {boolean} [chartsEnabled] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectCanvas(projectUuid: string, chartsEnabled?: boolean, scenarioId?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectCanvasDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectCanvas(projectUuid, chartsEnabled, scenarioId, projectRunId, projectRunEntryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.getProjectCanvas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectMetadata(projectUuid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Metadata>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectMetadata(projectUuid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.getProjectMetadata']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<UpdateCollapsedNodeStatusDTO>} updateCollapsedNodeStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async group(projectUuid: string, updateCollapsedNodeStatusDTO: Array<UpdateCollapsedNodeStatusDTO>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.group(projectUuid, updateCollapsedNodeStatusDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.group']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {UpdateGroupDto} updateGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async group1(projectUuid: string, updateGroupDto: UpdateGroupDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.group1(projectUuid, updateGroupDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.group1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {GraphDto} graphDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rearrangeGraph(projectUuid: string, graphDto: GraphDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GraphDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rearrangeGraph(projectUuid, graphDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.rearrangeGraph']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<string>} scenarioIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runProject(projectUuid: string, scenarioIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runProject(projectUuid, scenarioIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.runProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runProject1(projectUuid: string, scenarioId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupRunResultDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runProject1(projectUuid, scenarioId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.runProject1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ungroup(projectUuid: string, body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ungroup(projectUuid, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.ungroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<PositionDto>} positionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePositions(projectUuid: string, positionDto: Array<PositionDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePositions(projectUuid, positionDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerV2Api.updatePositions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectControllerV2Api - factory interface
 * @export
 */
export const ProjectControllerV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectControllerV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {string} type 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {boolean} [deleteAssociatedPJs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStep(id: string, type: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStep(id, type, deleteAssociatedPDs, deleteAssociatedPJs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsWithInputDataSets(projectUuid: string, options?: any): AxiosPromise<Array<ModelEntityDto>> {
            return localVarFp.getAllModelsWithInputDataSets(projectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} entityId 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllModelsWithInputDataSets1(projectUuid: string, entityId: string, scenarioId?: string, options?: any): AxiosPromise<ProjectCanvasDto> {
            return localVarFp.getAllModelsWithInputDataSets1(projectUuid, entityId, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard(count?: number, options?: any): AxiosPromise<Array<ProjectDashboardDto>> {
            return localVarFp.getDashboard(count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stepId 
         * @param {string} stepType 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {boolean} [deleteAssociatedPJs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeleteCandidates(stepId: string, stepType: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options?: any): AxiosPromise<Array<PipelineStep>> {
            return localVarFp.getDeleteCandidates(stepId, stepType, deleteAssociatedPDs, deleteAssociatedPJs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAssociates(projectId: string, options?: any): AxiosPromise<ProjectAssociatesDetailDto> {
            return localVarFp.getProjectAssociates(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {boolean} [chartsEnabled] 
         * @param {string} [scenarioId] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectCanvas(projectUuid: string, chartsEnabled?: boolean, scenarioId?: string, projectRunId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<ProjectCanvasDto> {
            return localVarFp.getProjectCanvas(projectUuid, chartsEnabled, scenarioId, projectRunId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectMetadata(projectUuid: string, options?: any): AxiosPromise<Array<Metadata>> {
            return localVarFp.getProjectMetadata(projectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<UpdateCollapsedNodeStatusDTO>} updateCollapsedNodeStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        group(projectUuid: string, updateCollapsedNodeStatusDTO: Array<UpdateCollapsedNodeStatusDTO>, options?: any): AxiosPromise<void> {
            return localVarFp.group(projectUuid, updateCollapsedNodeStatusDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {UpdateGroupDto} updateGroupDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        group1(projectUuid: string, updateGroupDto: UpdateGroupDto, options?: any): AxiosPromise<void> {
            return localVarFp.group1(projectUuid, updateGroupDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {GraphDto} graphDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rearrangeGraph(projectUuid: string, graphDto: GraphDto, options?: any): AxiosPromise<GraphDto> {
            return localVarFp.rearrangeGraph(projectUuid, graphDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<string>} scenarioIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runProject(projectUuid: string, scenarioIds: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.runProject(projectUuid, scenarioIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} [scenarioId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runProject1(projectUuid: string, scenarioId?: string, options?: any): AxiosPromise<Array<GroupRunResultDto>> {
            return localVarFp.runProject1(projectUuid, scenarioId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ungroup(projectUuid: string, body: string, options?: any): AxiosPromise<void> {
            return localVarFp.ungroup(projectUuid, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectUuid 
         * @param {Array<PositionDto>} positionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePositions(projectUuid: string, positionDto: Array<PositionDto>, options?: any): AxiosPromise<void> {
            return localVarFp.updatePositions(projectUuid, positionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectControllerV2Api - interface
 * @export
 * @interface ProjectControllerV2Api
 */
export interface ProjectControllerV2ApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {string} type 
     * @param {boolean} [deleteAssociatedPDs] 
     * @param {boolean} [deleteAssociatedPJs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    deleteStep(id: string, type: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    getAllModelsWithInputDataSets(projectUuid: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ModelEntityDto>>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {string} entityId 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    getAllModelsWithInputDataSets1(projectUuid: string, entityId: string, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectCanvasDto>;

    /**
     * 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    getDashboard(count?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectDashboardDto>>;

    /**
     * 
     * @param {string} stepId 
     * @param {string} stepType 
     * @param {boolean} [deleteAssociatedPDs] 
     * @param {boolean} [deleteAssociatedPJs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    getDeleteCandidates(stepId: string, stepType: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<PipelineStep>>;

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    getProjectAssociates(projectId: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAssociatesDetailDto>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {boolean} [chartsEnabled] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    getProjectCanvas(projectUuid: string, chartsEnabled?: boolean, scenarioId?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<ProjectCanvasDto>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    getProjectMetadata(projectUuid: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Metadata>>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {Array<UpdateCollapsedNodeStatusDTO>} updateCollapsedNodeStatusDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    group(projectUuid: string, updateCollapsedNodeStatusDTO: Array<UpdateCollapsedNodeStatusDTO>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {UpdateGroupDto} updateGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    group1(projectUuid: string, updateGroupDto: UpdateGroupDto, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {GraphDto} graphDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    rearrangeGraph(projectUuid: string, graphDto: GraphDto, options?: RawAxiosRequestConfig): AxiosPromise<GraphDto>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {Array<string>} scenarioIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    runProject(projectUuid: string, scenarioIds: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    runProject1(projectUuid: string, scenarioId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<GroupRunResultDto>>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    ungroup(projectUuid: string, body: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} projectUuid 
     * @param {Array<PositionDto>} positionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2ApiInterface
     */
    updatePositions(projectUuid: string, positionDto: Array<PositionDto>, options?: RawAxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProjectControllerV2Api - object-oriented interface
 * @export
 * @class ProjectControllerV2Api
 * @extends {BaseAPI}
 */
export class ProjectControllerV2Api extends BaseAPI implements ProjectControllerV2ApiInterface {
    /**
     * 
     * @param {string} id 
     * @param {string} type 
     * @param {boolean} [deleteAssociatedPDs] 
     * @param {boolean} [deleteAssociatedPJs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public deleteStep(id: string, type: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).deleteStep(id, type, deleteAssociatedPDs, deleteAssociatedPJs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public getAllModelsWithInputDataSets(projectUuid: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).getAllModelsWithInputDataSets(projectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {string} entityId 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public getAllModelsWithInputDataSets1(projectUuid: string, entityId: string, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).getAllModelsWithInputDataSets1(projectUuid, entityId, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public getDashboard(count?: number, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).getDashboard(count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} stepId 
     * @param {string} stepType 
     * @param {boolean} [deleteAssociatedPDs] 
     * @param {boolean} [deleteAssociatedPJs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public getDeleteCandidates(stepId: string, stepType: string, deleteAssociatedPDs?: boolean, deleteAssociatedPJs?: boolean, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).getDeleteCandidates(stepId, stepType, deleteAssociatedPDs, deleteAssociatedPJs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public getProjectAssociates(projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).getProjectAssociates(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {boolean} [chartsEnabled] 
     * @param {string} [scenarioId] 
     * @param {string} [projectRunId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public getProjectCanvas(projectUuid: string, chartsEnabled?: boolean, scenarioId?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).getProjectCanvas(projectUuid, chartsEnabled, scenarioId, projectRunId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public getProjectMetadata(projectUuid: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).getProjectMetadata(projectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {Array<UpdateCollapsedNodeStatusDTO>} updateCollapsedNodeStatusDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public group(projectUuid: string, updateCollapsedNodeStatusDTO: Array<UpdateCollapsedNodeStatusDTO>, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).group(projectUuid, updateCollapsedNodeStatusDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {UpdateGroupDto} updateGroupDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public group1(projectUuid: string, updateGroupDto: UpdateGroupDto, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).group1(projectUuid, updateGroupDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {GraphDto} graphDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public rearrangeGraph(projectUuid: string, graphDto: GraphDto, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).rearrangeGraph(projectUuid, graphDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {Array<string>} scenarioIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public runProject(projectUuid: string, scenarioIds: Array<string>, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).runProject(projectUuid, scenarioIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {string} [scenarioId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public runProject1(projectUuid: string, scenarioId?: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).runProject1(projectUuid, scenarioId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public ungroup(projectUuid: string, body: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).ungroup(projectUuid, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectUuid 
     * @param {Array<PositionDto>} positionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerV2Api
     */
    public updatePositions(projectUuid: string, positionDto: Array<PositionDto>, options?: RawAxiosRequestConfig) {
        return ProjectControllerV2ApiFp(this.configuration).updatePositions(projectUuid, positionDto, options).then((request) => request(this.axios, this.basePath));
    }
}

