import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { PS_TEST_SUCCESSFUL_MESSAGE } from "../pages/private/ProjectsModule/pages/PredictionService/utils/PredictionService.constants";
import { handleResponse, postAPIWithRethrow } from "src/utils/apiService";

interface IVariables {
  name: string;
  json: Record<string, any>;
}

const usePredictionResults = (): UseMutationResult<string, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ name, json }) => await postAPIWithRethrow(`/v2/predict/${name}`, json),
    onSuccess: () => {
      handleResponse({ successMessage: PS_TEST_SUCCESSFUL_MESSAGE });
    }
  });
};

export default usePredictionResults;
