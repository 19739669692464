import React from "react";

export const CanvasIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 2} ${height + 2}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3333 4V8H4.66666V4H11.3333ZM12.6667 0H11.3333V2H12.6667V0ZM4.66666 0H3.33332V2H4.66666V0ZM15.3333 2.66667H13.3333V4H15.3333V2.66667ZM12.6667 2.66667H3.33332V9.33333H12.6667V2.66667ZM2.66666 2.66667H0.666656V4H2.66666V2.66667ZM15.3333 8H13.3333V9.33333H15.3333V8ZM2.66666 8H0.666656V9.33333H2.66666V8ZM12.6667 10H11.3333V12H12.6667V10ZM4.66666 10H3.33332V12H4.66666V10Z"
      fill={color}
    />
  </svg>
);
