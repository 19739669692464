/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CreateDataSourceDto
 */
export interface CreateDataSourceDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDataSourceDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataSourceDto
     */
    'type': CreateDataSourceDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateDataSourceDto
     */
    'tpConnectorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDataSourceDto
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateDataSourceDto
     */
    'options'?: object;
}

export const CreateDataSourceDtoTypeEnum = {
    GcpStorage: 'GCP_STORAGE',
    S3Storage: 'S3_STORAGE',
    AzureBlob: 'AZURE_BLOB',
    Mysql: 'MYSQL',
    Mongo: 'MONGO',
    Snowflake: 'SNOWFLAKE',
    Redshift: 'REDSHIFT',
    RedisStorage: 'REDIS_STORAGE',
    ThirdParty: 'THIRD_PARTY'
} as const;

export type CreateDataSourceDtoTypeEnum = typeof CreateDataSourceDtoTypeEnum[keyof typeof CreateDataSourceDtoTypeEnum];


