import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { TextField, Chip, Box, Grid, Checkbox, ListItemText } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 120,
    margin: 0,
    background: "#fff"
  },
  selectedItems: {
    gap: "8px",
    marginTop: "8px"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1)
  },
  autoCompleteBox: {
    gap: "12px"
  },
  option: {},
  listbox: {
    maxHeight: "250px",
    overflow: "auto"
  },
  paper: {
    padding: 0
  },
  inputRoot: {
    paddingTop: "2px !important",
    paddingBottom: "2px !important"
  },
  input: {
    padding: "12px 0px !important"
  },
  helperText: {
    margin: "4px 0px"
  }
}));

interface Option {
  label: string | number;
  value: string;
}

interface MultiSelectAutocompleteProps
  extends Omit<
    AutocompleteProps<Option, true, false, false>,
    "renderInput" | "options" | "value" | "onChange"
  > {
  options: Option[];
  value: Option[];
  onChange: (value: Option[]) => void;
  placeholder?: string;
  label?: string;
  InputProps?: any;
  textFieldProps?: any;
}

const AutocompleteWithChips: React.FC<MultiSelectAutocompleteProps> = ({
  options,
  value,
  onChange,
  placeholder,
  label,
  InputProps,
  textFieldProps = {},
  ...restProps
}) => {
  const classes = useStyles();

  const handleDelete = (item: string) => {
    onChange(value.filter((i) => i.value !== item));
  };
  return (
    <Grid container direction="column" className={classes.autoCompleteBox}>
      <Autocomplete
        multiple
        options={options}
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        classes={{
          listbox: classes.listbox,
          inputRoot: classes.inputRoot,
          paper: classes.paper,
          input: classes.input
        }}
        getOptionLabel={(option) => option.label.toString()}
        renderTags={() => <React.Fragment />}
        renderOption={(option, { selected }) => (
          <React.Fragment key={option.value}>
            <Checkbox checked={selected} size="small" />
            <ListItemText primary={option.label} />
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            FormHelperTextProps={{
              className: classes.helperText
            }}
            InputProps={{ ...params.InputProps, ...InputProps }}
            variant="outlined"
            label={label}
            placeholder={placeholder}
          />
        )}
        {...restProps}
      />
      <Box className={classes.chips}>
        {value.map((option) => (
          <Chip
            variant="outlined"
            color="primary"
            key={option.value}
            label={option.label}
            onDelete={() => handleDelete(option.value)}
            deleteIcon={<ClearIcon fontSize="small" />}
          />
        ))}
      </Box>
    </Grid>
  );
};

export { AutocompleteWithChips };
