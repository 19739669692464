import React, { useCallback, useMemo } from "react";
import shallow from "zustand/shallow";
import { Modal } from "src/components/custom";
import useAuthStore from "../../../stores/auth.store";
import TenantActionButtons from "./TenantActionButtons";
import styles from "./ModalTenantSwitch.module.scss";
import TenantItem from "./TenantItem";
import Typography from "@material-ui/core/Typography";

export default function ModalTenantSwitch({
  visible,
  tenants,
  onClose,
  handleSwitchAndDelete
}: $TSFixMe) {
  const [selectedTenantId] = useAuthStore(
    useCallback((state) => [state.tenantId], []),
    shallow
  );
  const tenantsFiltered = useMemo(() => {
    return tenants.filter(
      (tenant: $TSFixMe) => tenant.id !== selectedTenantId && tenant.invitationStatus === "ACTIVE"
    );
  }, [selectedTenantId, tenants]);

  const handleClick = async (id: $TSFixMe) => {
    await handleSwitchAndDelete(id, "/");
    onClose(false);
  };

  return visible ? (
    <Modal
      open={visible}
      size="md"
      title={
        <>
          <Typography style={{ fontWeight: 400 }} variant="h6">
            Switch Tenant
          </Typography>
          <Typography variant="subtitle2">Before deleting choose a tenant to switch</Typography>
        </>
      }
      onClose={() => onClose(false)}
      hideCancelAction
      hideSubmitAction>
      <div className={styles.modalContainer}>
        {tenantsFiltered.map((tenant: $TSFixMe) => (
          <TenantItem
            key={tenant.id}
            oneTenant={tenants.length === 1}
            tenantData={tenant}
            ActionButtons={
              <TenantActionButtons
                tenantId={tenant.id}
                handleClickSwitch={handleClick}
                enableButtonLeave={false}
              />
            }
          />
        ))}
      </div>
    </Modal>
  ) : (
    <></>
  );
}
