import React, { Dispatch, SetStateAction } from "react";

import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ViewWeekRoundedIcon from "@material-ui/icons/ViewWeekRounded";
import IconButton from "@material-ui/core/IconButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

type Props = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  onValueChange: (newValue: string) => void;
};

const useStyles = makeStyles((theme) => ({
  search: {
    alignItems: "center",
    columnGap: theme.spacing(1),
    fontSize: "small",
    "&:before, &:hover:before": {
      borderBottom: "none !important"
    }
  }
}));

const SearchColumns = (props: Props) => {
  const { value, setValue, onValueChange } = props;

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onValueChange(event.target.value);
  };

  return (
    <TextField
      data-testid="searchColumns"
      size="small"
      fullWidth
      placeholder="Search columns..."
      InputProps={{
        className: classes.search,
        startAdornment: <ViewWeekRoundedIcon fontSize="small" color="action" />,
        ...(!!value
          ? {
              endAdornment: (
                <IconButton size="small">
                  <CloseRoundedIcon
                    fontSize="small"
                    style={{ opacity: 0.5, fontSize: "1rem" }}
                    onClick={() => {
                      setValue("");
                      onValueChange("");
                    }}
                  />
                </IconButton>
              )
            }
          : {})
      }}
      value={value}
      onChange={handleChange}
    />
  );
};

export default SearchColumns;
