/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { DataSourceDetail } from './data-source-detail';

/**
 * 
 * @export
 * @interface ProcessFileRequest
 */
export interface ProcessFileRequest {
    /**
     * 
     * @type {string}
     * @memberof ProcessFileRequest
     */
    'datasetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessFileRequest
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessFileRequest
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessFileRequest
     */
    'processType'?: ProcessFileRequestProcessTypeEnum;
    /**
     * 
     * @type {DataSourceDetail}
     * @memberof ProcessFileRequest
     */
    'dataSourceDetail'?: DataSourceDetail;
}

export const ProcessFileRequestProcessTypeEnum = {
    File: 'FILE',
    DataSource: 'DATA_SOURCE'
} as const;

export type ProcessFileRequestProcessTypeEnum = typeof ProcessFileRequestProcessTypeEnum[keyof typeof ProcessFileRequestProcessTypeEnum];


