import { useMutation, UseMutationResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";

interface IPayload {
  projectId?: string;
  scenarioId?: string;
  id?: string;
}

const useRemoveRecipeFromQueue = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ projectId, scenarioId, id }: IPayload) => {
      if (!projectId || !scenarioId || !id) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () =>
          await api.DfsRunConfigGroupControllerV2Api.removeRecipeFromQueue(
            projectId,
            id,
            scenarioId
          )
      );
    }
  });

export default useRemoveRecipeFromQueue;
