import React from "react";

export const CardView = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <rect
      x="0.421061"
      y="0.421061"
      width="6.43074"
      height="6.43074"
      rx="1.26318"
      stroke="black"
      strokeWidth="0.842121"
    />
    <rect
      x="0.421061"
      y="9.14811"
      width="6.43074"
      height="6.43074"
      rx="1.26318"
      stroke="black"
      strokeWidth="0.842121"
    />
    <rect
      x="9.87577"
      y="0.421061"
      width="6.43074"
      height="6.43074"
      rx="1.26318"
      stroke="black"
      strokeWidth="0.842121"
    />
    <rect
      x="9.87602"
      y="9.14811"
      width="6.43074"
      height="6.43074"
      rx="1.26318"
      stroke="black"
      strokeWidth="0.842121"
    />
  </svg>
);
