import { Grid } from "@material-ui/core";
import _ from "lodash";
import { get, map, orderBy } from "lodash";
import React, { useMemo } from "react";
import { Spinner, Table } from "src/components";
import useEntityAuditHistory from "src/hooks/api/entities/useEntityAuditHistory";
import { dateFormat } from "src/utils/dateFormat";
import NoAuditHistory from "./NoAuditHistory";
import { EntityAuditHistoryDtoActionEnum } from "openapi/Models/entity-audit-history-dto";

interface IProps {
  datasetId: string;
  dataSourceId?: string;
  connectorsMap: { [key: string]: any };
}
export const updationMessage = {
  [EntityAuditHistoryDtoActionEnum.Edit]: "Dataset Updated",
  [EntityAuditHistoryDtoActionEnum.Create]: "Dataset Created",
  [EntityAuditHistoryDtoActionEnum.Reload]: "Dataset Reloaded",
  [EntityAuditHistoryDtoActionEnum.Append]: "File Appended to Dataset"
};

const AuditHistory: React.FC<IProps> = ({ datasetId, connectorsMap, dataSourceId }) => {
  const auditHistoryResult = useEntityAuditHistory(datasetId, { refetchOnMount: true });

  const columns = useMemo(
    () => [
      {
        id: "User Action",
        accessor: "dataSourceOptions",
        Header: "User Action",
        Cell: ({ row }: any) => {
          const { dataSourceOptions, action } = row.original || {};
          return (
            <Grid container direction="column">
              <Grid item>{get(updationMessage, action)}</Grid>
              {(!dataSourceId ||
                (dataSourceId && !!connectorsMap[dataSourceId]?.dataSourceType?.isSql)) &&
                dataSourceOptions &&
                map(dataSourceOptions, (value, key) => (
                  <Grid key={key} item>
                    <strong>{key}:</strong> {JSON.stringify(value)}
                  </Grid>
                ))}
            </Grid>
          );
        }
      },
      {
        id: "Changed by",
        accessor: "creator",
        Header: "Changed by"
      },
      {
        id: "Updated at",
        accessor: "created",
        Header: "Updated at",
        Cell: ({ row }: any) => {
          const { created } = row.original || {};

          return <span>{dateFormat(created) ?? ""}</span>;
        }
      }
    ],
    []
  );

  if (auditHistoryResult.isLoading) {
    return <Spinner />;
  }

  return _.isEmpty(auditHistoryResult.data) ? (
    <NoAuditHistory />
  ) : (
    <Table
      data={orderBy(auditHistoryResult.data, "created")}
      maxHeight="calc(100vh - 370px)"
      columns={columns}
    />
  );
};

export default AuditHistory;
