import React, { useMemo } from "react";

import { Button, Grid, Paper, Tooltip, Typography, makeStyles } from "@material-ui/core";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { OntologyDatasetIcon } from "src/icons/Dataset/OntologyDatasetIcon";
import { FileConfigurationIcon } from "src/icons/Dataset/FileConfigurationIcon";

import { ellipses } from "src/utils/formatText";

import { DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(2),
    "& .fileDatasetContainer": {
      columnGap: theme.spacing(2)
    },
    "& .fileIconContainer": {
      display: "flex",
      "& svg": {
        margin: "auto",
        width: 43,
        height: 45,
        color: "#003656"
      }
    },
    "& .datasetIconContainer": {
      display: "flex",
      border: "2px solid",
      borderRadius: 10,
      padding: 7
    },
    "& .attributesWrapper": {
      height: "inherit",
      flexGrow: 1
    },
    "& .attributesContainer": {
      height: "inherit"
    },
    "& .fileConfigurationAction": {
      marginLeft: "auto"
    }
  }
}));

const ViewUploadedContainer = (props: $TSFixMe) => {
  const {
    ontologyDatasetIndex,
    isConfigContainerExpanded,
    setIsConfigContainerExpanded,
    isSchemaOptionsSupportedFileType,
    children
  } = props || {};

  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const { datasetFilesStore, datasetDatasetsStore } = useStoreSelectors();
  // << ENDS - Stores

  const filesNames = useMemo(
    () =>
      datasetDatasetsStore?.length === 1
        ? datasetFilesStore?.map((eachFile: $TSFixMe) => eachFile?.name)?.join(", ")
        : datasetFilesStore[ontologyDatasetIndex]?.name,
    [ontologyDatasetIndex, datasetFilesStore]
  );

  const datasetsNames = useMemo(
    () => datasetDatasetsStore[ontologyDatasetIndex]?.name,
    [ontologyDatasetIndex, datasetDatasetsStore]
  );

  return (
    <>
      <Paper className={classes.root}>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center" className="fileDatasetContainer">
                  <Grid item className="fileIconContainer">
                    {<DescriptionOutlinedIcon />}
                  </Grid>
                  <Grid item className="attributesWrapper">
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      className="attributesContainer">
                      <Typography
                        id="viewUploadedFileLabel"
                        variant="subtitle2"
                        color="textPrimary">
                        {DatasetHelperText.UploadedFile}
                      </Typography>
                      {filesNames ? (
                        <Typography variant="body2" color="textPrimary">
                          <Tooltip
                            id="viewUploadedFileNames"
                            title={filesNames?.length > 40 ? filesNames : ""}>
                            <span>{ellipses({ text: filesNames, length: 40 })}</span>
                          </Tooltip>
                        </Typography>
                      ) : (
                        <Typography
                          id="viewUploadedFileUnknown"
                          variant="body2"
                          color="textPrimary">
                          {DatasetHelperText.Unknown}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center" className="fileDatasetContainer">
                  <Grid item className="datasetIconContainer">
                    <OntologyDatasetIcon />
                  </Grid>
                  <Grid item className="attributesWrapper">
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      className="attributesContainer">
                      <Typography
                        id="viewUploadedDatasetLabel"
                        variant="subtitle2"
                        color="textPrimary">
                        {DatasetHelperText.Dataset}
                      </Typography>
                      {datasetsNames ? (
                        <Typography variant="body2" color="textPrimary">
                          <Tooltip
                            id="viewUploadedDatasetNames"
                            title={datasetsNames?.length > 40 ? datasetsNames : ""}>
                            <span>{ellipses({ text: datasetsNames, length: 40 })}</span>
                          </Tooltip>
                        </Typography>
                      ) : (
                        <Typography
                          id="viewUploadedDatasetUnknown"
                          variant="body2"
                          color="textPrimary">
                          {DatasetHelperText.Unknown}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!!isSchemaOptionsSupportedFileType && (
            <Grid item xs={4} style={{ textAlign: "right" }} className="fileConfigurationAction">
              <Typography color="textPrimary">
                <Button
                  variant="outlined"
                  color="inherit"
                  startIcon={<FileConfigurationIcon fill="#003656" />}
                  onClick={() => setIsConfigContainerExpanded(() => !isConfigContainerExpanded)}
                  endIcon={isConfigContainerExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
                  File Configuration
                </Button>
              </Typography>
            </Grid>
          )}
        </Grid>
        {children}
      </Paper>
    </>
  );
};

export default ViewUploadedContainer;
