import React from "react";

type Props = {
  fill?: string;
  width?: string;
  height?: string;
  color?: string;
};

export const LogsNewIconV2 = ({ width, height, fill = "none", color = "#7c7c7c" }: Props) => {
  return (
    <svg
      width={width || "20"}
      height={height || "20"}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7348 7.15401H17.2545V4.83648C17.2545 4.82204 17.2523 4.80748 17.2503 4.79286C17.2496 4.70082 17.2202 4.61047 17.1572 4.53887L13.3012 0.134126C13.3 0.132995 13.2989 0.132594 13.2981 0.131463C13.2751 0.105674 13.2484 0.0843348 13.22 0.0659869C13.2116 0.06026 13.2031 0.0555544 13.1943 0.0506301C13.1698 0.0372795 13.1438 0.026154 13.117 0.0180926C13.1097 0.0160863 13.1032 0.0131317 13.0959 0.0112349C13.0668 0.00430428 13.0365 0 13.0059 0H3.52904C3.09631 0 2.74475 0.352039 2.74475 0.784328V7.15368H2.26457C1.64551 7.15368 1.14355 7.65546 1.14355 8.27473V14.1042C1.14355 14.7231 1.64551 15.2252 2.26457 15.2252H2.74475V19.2157C2.74475 19.6481 3.09631 20 3.52904 20H16.4702C16.9026 20 17.2545 19.6481 17.2545 19.2157V15.2252H17.7347C18.3536 15.2252 18.8556 14.7231 18.8556 14.1042V8.27495C18.8557 7.6555 18.3536 7.15401 17.7348 7.15401ZM3.52904 0.784328H12.6138V4.79712C12.6138 5.01376 12.7895 5.18925 13.0059 5.18925H16.4702V7.15394H3.52904V0.784328ZM11.5926 11.0681C11.5926 12.8106 10.5358 13.8676 8.98238 13.8676C7.40534 13.8676 6.48258 12.6769 6.48258 11.1627C6.48258 9.56944 7.49981 8.37884 9.06902 8.37884C10.7016 8.37884 11.5926 9.60122 11.5926 11.0681ZM2.99633 13.7806V8.46576H4.20273V12.7715H6.31636V13.7804H2.99633V13.7806ZM16.4702 19.0031H3.52904V15.2252H16.4702V19.0031H16.4702ZM16.8219 13.5363C16.4513 13.6623 15.7494 13.8358 15.0477 13.8358C14.0778 13.8358 13.3758 13.5914 12.8868 13.1185C12.3981 12.6612 12.1299 11.967 12.1379 11.1864C12.1457 9.4196 13.431 8.41021 15.1739 8.41021C15.8602 8.41021 16.3883 8.54473 16.6487 8.67062L16.3963 9.63251C16.1045 9.50641 15.7418 9.40388 15.1582 9.40388C14.1569 9.40388 13.3996 9.97171 13.3996 11.1232C13.3996 12.2193 14.0857 12.8657 15.0714 12.8657C15.3473 12.8657 15.5683 12.8346 15.6627 12.7872V11.6751H14.8428V10.7368H16.8219V13.5363Z"
        fill={color}
      />
      <path
        d="M7.75195 11.139C7.75195 12.1799 8.241 12.9133 9.04513 12.9133C9.8574 12.9133 10.3226 12.1405 10.3226 11.1077C10.3226 10.1537 9.86521 9.33289 9.03726 9.33289C8.2251 9.33285 7.75195 10.1059 7.75195 11.139Z"
        fill={color}
      />
    </svg>
  );
};
