import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  recipeRunningImgContainer: {
    flexWrap: "nowrap",
    gap: "24px",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "64px"
  },
  dataCleaningImg: {
    width: "110px",
    height: "90px",
    flex: 0
  },
  textWrap: {
    flexWrap: "nowrap",
    maxWidth: "650px",
    alignItems: "flex-start",
    textAlign: "left",
    gap: "8px"
  },
  subTitle: {
    color: "#7C7C7C",
    fontSize: "16px",
    fontWeight: 400
  }
});

export const AutoMLRecipeRunningImage = ({
  imgSrc,
  title,
  description
}: {
  imgSrc: string;
  title: string;
  description: string;
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" className={classes.recipeRunningImgContainer}>
      <Grid component="img" src={imgSrc} className={classes.dataCleaningImg} />
      <Grid container direction="column" className={classes.textWrap}>
        <Typography color="primary">{title}</Typography>
        <Typography component="span" className={classes.subTitle}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
