import React, { useRef, useEffect, useState } from "react";
import { Tooltip, withStyles } from "@material-ui/core";

const StyledTooltip = withStyles({
  tooltip: {
    "& *": {
      color: "#FFF !important"
    }
  }
})(Tooltip);

type Props = {
  value?: string | any;
  lineHeight?: string;
  tooltipProps?: any;
  title?: string | null | undefined;
  style?: React.CSSProperties;
  testId?: string;
  className?: string;
};

const OverflowTooltip = ({ value = "", title, lineHeight, tooltipProps, style, testId }: Props) => {
  const textElementRef = useRef<HTMLDivElement | null>(null);
  const [hover, setHover] = useState(false);

  const compareSize = () => {
    if (textElementRef?.current) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("mouseover", compareSize);

    () => {
      window.removeEventListener("mouseover", compareSize);
    };
  }, []);

  return (
    <StyledTooltip
      title={title || value}
      interactive
      disableHoverListener={!hover}
      {...tooltipProps}>
      <div
        ref={textElementRef}
        data-testid={testId}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...(lineHeight && { lineHeight }),
          ...style
        }}>
        {value}
      </div>
    </StyledTooltip>
  );
};

export default OverflowTooltip;
