import { getDataSourceWithRethrow } from "src/api/dataSources";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

const QUERY_KEY_DATA_CONNECTOR = "query-key-data-connector";

const useGetDataConnector = (id?: string, options?: UseQueryOptions): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_DATA_CONNECTOR, id],
    queryFn: async () => {
      return await getDataSourceWithRethrow({ id });
    },
    enabled: !!id,
    ...options
  });
};

export default useGetDataConnector;
export { QUERY_KEY_DATA_CONNECTOR };
