/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DFSInputMetadata
 */
export interface DFSInputMetadata {
    /**
     * 
     * @type {string}
     * @memberof DFSInputMetadata
     */
    'datatype'?: DFSInputMetadataDatatypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DFSInputMetadata
     */
    'datatypes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DFSInputMetadata
     */
    'tooltip'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'multiple'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'output'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DFSInputMetadata
     */
    'options'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DFSInputMetadata
     */
    'dataset'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DFSInputMetadata
     */
    'datasets'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DFSInputMetadata
     */
    'input_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'is_required'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DFSInputMetadata
     */
    'default_value'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DFSInputMetadata
     */
    'constant_options'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'input_dataset'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'output_dataset'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'output_chart'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'input_column'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'chart_app'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'is_global_chart_app'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DFSInputMetadata
     */
    'input_data_source'?: boolean;
}

export const DFSInputMetadataDatatypeEnum = {
    String: 'STRING',
    Integer: 'INTEGER',
    Float: 'FLOAT',
    List: 'LIST',
    Map: 'MAP'
} as const;

export type DFSInputMetadataDatatypeEnum = typeof DFSInputMetadataDatatypeEnum[keyof typeof DFSInputMetadataDatatypeEnum];


