import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const StopCircleOutlined = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "#DA1D27",
  color = "currentColor"
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99992 0.333328C6.12444 0.333328 5.25753 0.505767 4.4487 0.840798C3.63986 1.17583 2.90493 1.66689 2.28587 2.28595C1.03563 3.53619 0.333252 5.23188 0.333252 6.99999C0.333252 8.7681 1.03563 10.4638 2.28587 11.714C2.90493 12.3331 3.63986 12.8242 4.4487 13.1592C5.25753 13.4942 6.12444 13.6667 6.99992 13.6667C8.76803 13.6667 10.4637 12.9643 11.714 11.714C12.9642 10.4638 13.6666 8.7681 13.6666 6.99999C13.6666 6.12452 13.4941 5.25761 13.1591 4.44877C12.8241 3.63993 12.333 2.90501 11.714 2.28595C11.0949 1.66689 10.36 1.17583 9.55114 0.840798C8.7423 0.505767 7.8754 0.333328 6.99992 0.333328ZM6.99992 1.66666C9.93992 1.66666 12.3333 4.05999 12.3333 6.99999C12.3333 9.93999 9.93992 12.3333 6.99992 12.3333C4.05992 12.3333 1.66659 9.93999 1.66659 6.99999C1.66659 4.05999 4.05992 1.66666 6.99992 1.66666ZM4.99992 4.99999V8.99999H8.99992V4.99999"
        color={color}
      />
    </svg>
  );
};

export default StopCircleOutlined;
