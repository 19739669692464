import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

export const RunIcon: React.FC<IProps> = (props) => {
  const {
    width = 16,
    height = 16,
    viewBox = "0 0 16 16",
    fill = "none",
    color = "currentColor"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.5 9.25C3.5 8.99112 3.29012 8.78125 3.03125 8.78125H0.46875C0.209875 8.78125 0 8.99112 0 9.25C0 9.50888 0.209875 9.71875 0.46875 9.71875H3.03125C3.29012 9.71875 3.5 9.50888 3.5 9.25Z"
        fill={color}
      />
      <path
        d="M0.46875 7.84375H1.46875C1.72763 7.84375 1.9375 7.63388 1.9375 7.375C1.9375 7.11612 1.72763 6.90625 1.46875 6.90625H0.46875C0.209875 6.90625 0 7.11612 0 7.375C0 7.63388 0.209875 7.84375 0.46875 7.84375Z"
        fill={color}
      />
      <path
        d="M12.0864 2.96516C12.7575 2.29404 12.7575 1.20596 12.0864 0.534844C11.4153 -0.136269 10.3272 -0.136269 9.65607 0.534844C8.98496 1.20596 8.98496 2.29404 9.65607 2.96516C10.3272 3.63627 11.4153 3.63627 12.0864 2.96516Z"
        fill={color}
      />
      <path
        d="M15.3828 2.0607C14.8963 1.88361 14.3583 2.13448 14.1812 2.62101L13.3796 4.82342L11.7877 4.24401C11.5023 4.34908 11.194 4.40655 10.8726 4.40655C9.88938 4.40655 9.02988 3.86917 8.57063 3.07308L8.18732 2.93358C7.71513 2.76173 7.20982 2.70061 6.71029 2.75492L4.92066 2.94958C4.388 3.00751 3.93454 3.36311 3.75125 3.86661L3.02025 5.87498C2.84316 6.36151 3.09404 6.89951 3.58057 7.07658C4.0671 7.25367 4.6051 7.0028 4.78216 6.51626L5.38494 4.86011L7.28025 4.67805L5.00244 10.9362H2.64307C2.039 10.9362 1.54935 11.4259 1.54932 12.03C1.54932 12.634 2.039 13.1237 2.64307 13.1237H5.5495C6.14069 13.1237 6.66875 12.754 6.87094 12.1985L7.384 10.7888L9.14594 11.4301L8.02819 14.5011C7.76853 15.2145 8.29682 15.9689 9.05597 15.9689C9.51579 15.9689 9.9265 15.6813 10.0838 15.2493L11.4687 11.4441C11.7343 10.7143 11.358 9.90736 10.6282 9.64173L9.89407 9.37455L11.1232 5.99751L13.1788 6.7457C13.9086 7.01133 14.7156 6.63505 14.9812 5.9052L15.9431 3.26233C16.1202 2.77576 15.8694 2.2378 15.3828 2.0607Z"
        fill={color}
      />
    </svg>
  );
};
