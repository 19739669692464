import { useRef } from "react";
import { delay, filter, includes, isEmpty, isEqual, isNil, size, toUpper } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";
import { RecipeRunData, RecipeRunDataStatusEnum } from "openapi/Models/recipe-run-data";
import { RecipeQueueResponse } from "openapi/Models/recipe-queue-response";

import { QUERY_KEY_NOTIFICATIONS } from "../notifications";

export enum RecipeRunsConfig {
  PollIntervalInProjectContext = 2 * 1000, // 2 seconds.
  PollIntervalOutOfProjectContext = 10 * 1000, // 10 seconds.
  MaxPollDurationOutOfProjectContext = 30 * 60 * 1000 // 30 minutes.
}

export const enum UseGetRecipeRunsQueueQueryKeys {
  RecipeRunsQueue = "recipe-runs-queue",
  RecipeRunsQueueAcrossProjects = "recipe-runs-queue-across-projects"
}

interface IRecipeRunsQueueProps extends UseQueryOptions<unknown, unknown, RecipeQueueResponse> {
  projectId?: string;
}

const useGetRecipeRunsQueue = (
  props: IRecipeRunsQueueProps
): UseQueryResult<RecipeQueueResponse> => {
  const { projectId, ...options } = props;

  return useQuery({
    queryKey: [UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is null!";
      }

      return await api.fetchResponse(
        async () => await api.DfsRunConfigGroupControllerV2Api.getRecipeQueue(projectId)
      );
    },
    enabled: !!projectId,
    ...options
  });
};

export const useGetRecipeRunsQueueAcrossProjects = (
  props: IRecipeRunsQueueProps
): UseQueryResult<RecipeQueueResponse> => {
  const { projectId, ...options } = props;

  const queryClient = useQueryClient();

  const fetchingRef = useRef<boolean>(false);
  const prevPendingRecipeRunsInQueueRef = useRef<RecipeRunData[]>([]);

  return useQuery({
    // They key & projectId combination executes only once.
    // So, using uuid in queryKey so that it executes each time.
    queryKey: [UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueueAcrossProjects, projectId, uuidv4()],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is null!";
      }

      return await new Promise(() => {
        const intervalId = setInterval(async () => {
          if (!fetchingRef.current) {
            try {
              fetchingRef.current = true;
              const data = await api.fetchResponse(
                async () => await api.DfsRunConfigGroupControllerV2Api.getRecipeQueue(projectId)
              );
              fetchingRef.current = false;

              let pendingRecipeRunsInQueue: RecipeRunData[] = [];

              if (isNil(data?.queue)) {
                clearInterval(intervalId);
              } else {
                if (isEmpty(data?.queue)) {
                  clearInterval(intervalId);
                } else {
                  pendingRecipeRunsInQueue = filter(data?.queue, (recipe) =>
                    includes(
                      [
                        toUpper(RecipeRunDataStatusEnum.InQueue),
                        toUpper(RecipeRunDataStatusEnum.Running)
                      ],
                      toUpper(recipe?.status)
                    )
                  );

                  if (size(pendingRecipeRunsInQueue) === 0) {
                    clearInterval(intervalId);
                  }
                }
              }

              if (!isEqual(pendingRecipeRunsInQueue, prevPendingRecipeRunsInQueueRef.current)) {
                queryClient.refetchQueries([QUERY_KEY_NOTIFICATIONS]);
              }

              prevPendingRecipeRunsInQueueRef.current = pendingRecipeRunsInQueue;
            } catch {
              clearInterval(intervalId);
            }
          }
        }, RecipeRunsConfig.PollIntervalOutOfProjectContext);

        delay(() => {
          clearInterval(intervalId);
        }, RecipeRunsConfig.MaxPollDurationOutOfProjectContext);
      });
    },
    enabled: false,
    ...options
  });
};

export default useGetRecipeRunsQueue;
