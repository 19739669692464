import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Grid, IconButton, InputProps, Typography, makeStyles } from "@material-ui/core";

import { SearchField, Spinner } from "src/components";
import { filter, includes, isEmpty, orderBy, some, toLower, trim, upperCase } from "lodash";
import { useGetProjectCanvas } from "src/hooks/api";
import { useCodeRecipeContext } from "../CodeRecipeContext/useCodeRecipeContext";
import { NodeTypes, RecipeTypesPathMapping } from "src/pages/private/ProjectsModule/utils";

import { generatePath } from "react-router-dom";
import { WebPaths } from "src/routing/routes";
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import _ from "lodash";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CanvasEntity from "./CanvasEntity";

const useStyles = makeStyles({
  codeRecipeHeader: {
    padding: "0px 16px",
    height: "44px",
    flexDirection: "row",
    flexWrap: "nowrap",
    background: "#d9d9d933",
    borderRadius: "11px 11px 0px 0px"
  },

  inputWrap: {
    height: "calc(100% - 77px)",
    flexWrap: "nowrap",
    overflow: "auto",
    borderRadius: "0px 0px 11px 11px",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },

  btnContainer: {
    flexWrap: "nowrap",
    gap: "8px",
    flex: 0
  }
});

const CodeRecipeCanvasEntitiesContainer = ({
  projectId,
  scenarioId,
  setValue,
  jobId,
  jobRunId,
  isJobpath
}: {
  projectId: string | undefined;
  scenarioId: string | undefined;
  isJobpath: boolean;
  jobId: string | undefined;
  jobRunId: string | undefined;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [expanded, setExpanded] = useState(false);
  const { data: projectCanvasData, isLoading } = useGetProjectCanvas({
    projectId: projectId,
    scenarioId: scenarioId!,
    refetchOnMount: true
  });

  const onCollapse = () => {
    setExpanded((prev) => !prev);
  };

  const { recipe, selectedCodeRecipe, setSelectedCodeRecipe, setCurrentSelectedCodeRecipe } =
    useCodeRecipeContext();

  const classes = useStyles();
  const handleSearch: InputProps["onChange"] = (event) => {
    setSearchValue(event?.target.value);
  };

  const handlePreview = (item: any) => {
    if (!some(selectedCodeRecipe, (recipe) => item.id === recipe.id)) {
      setSelectedCodeRecipe([...selectedCodeRecipe, item]);
    }
    setCurrentSelectedCodeRecipe(item.id);
    setValue("codeEditor");
  };

  const filteredEntities = useMemo(() => {
    if (projectCanvasData) {
      return filter(
        filter(projectCanvasData?.nodes, (item) =>
          !!searchValue ? includes(toLower(item.displayName), toLower(searchValue)) : true
        ),
        (item) => item.id !== recipe?.id
      );
    } else {
      return [];
    }
  }, [projectCanvasData?.nodes, searchValue]);

  const getNavigatePath = (node: any) => {
    if (isJobpath) {
      const jobUrl = _.replace(
        WebPaths.JobStandardRecipe,
        RecipeTypesPathMapping.STANDARD,
        _.get(RecipeTypesPathMapping, node?.recipeType || RecipeTypesPathMapping.STANDARD)
      ) as typeof WebPaths.JobStandardRecipe;

      return generatePath(`${WebPaths.JobRoutes}${jobUrl}`, {
        projectId: projectId ?? null,
        jobId: jobId ?? null,
        scenarioId: scenarioId ?? null,
        jobRunId: jobRunId ?? null,
        groupId: node.id
      });
    } else {
      const url = _.replace(
        WebPaths.StandardRecipeDataContainer,
        RecipeTypesPathMapping.STANDARD,
        _.get(RecipeTypesPathMapping, node.recipeType || RecipeTypesPathMapping.STANDARD)
      );

      return generatePath(url, {
        projectId,
        scenarioId,
        groupId: node.id
      });
    }
  };

  const navigateToDataset = (id: string) => {
    if (!id) {
      return;
    }
    if (!areAllKeysPresentAndNotNil({ projectId, scenarioId }, ["projectId", "scenarioId"])) {
      return;
    }

    let path = generatePath(WebPaths.ViewData, {
      projectId: projectId ?? null,
      scenarioId: scenarioId ?? null,
      entityId: id,
      section: "data"
    });

    if (isJobpath) {
      if (!areAllKeysPresentAndNotNil({ jobId, jobRunId }, ["jobId", "jobRunId"])) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: projectId ?? null,
        jobId: jobId ?? null,
        scenarioId: scenarioId ?? null,
        jobRunId: jobRunId ?? null,
        entityId: id,
        section: "data"
      });
    }

    !!path && window.open(path, "_blank");
  };

  const navigateToArtifact = (name: string) => {
    if (!name) {
      return;
    }

    let path = generatePath(WebPaths.ArtifactsDetails, { artifactName: name });

    if (isJobpath) {
      if (
        !areAllKeysPresentAndNotNil({ projectId, scenarioId, jobRunId, jobId }, [
          "projectId",
          "scenarioId",
          "jobId",
          "jobRunId"
        ])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobArtifacts}`, {
        projectId: projectId ?? null,
        jobId: jobId ?? null,
        scenarioId: scenarioId ?? null,
        jobRunId: jobRunId ?? null,
        artifactName: name
      });
    }

    !!path && window.open(path, "_blank");
  };

  const navigateToCharts = (id: string) => {
    if (!id) {
      return;
    }
    if (!areAllKeysPresentAndNotNil({ projectId, scenarioId }, ["projectId", "scenarioId"])) {
      return;
    }

    let path = generatePath(WebPaths.Charts, {
      projectId: projectId ?? null,
      scenarioId: scenarioId ?? null,
      chartId: id
    });

    if (isJobpath) {
      if (!areAllKeysPresentAndNotNil({ jobId, jobRunId }, ["jobId", "jobRunId"])) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobChart}`, {
        projectId: projectId ?? null,
        jobId: jobId ?? null,
        scenarioId: scenarioId ?? null,
        jobRunId: jobRunId ?? null,
        chartId: id
      });
    }

    !!path && window.open(path, "_blank");
  };

  const navigateToModel = (name: string) => {
    if (!name) {
      return;
    }

    let path = generatePath(WebPaths.ModelDetails, {
      modelName: name
    });

    if (isJobpath) {
      if (
        !areAllKeysPresentAndNotNil({ projectId, scenarioId, jobId, jobRunId }, [
          "projectId",
          "scenarioId",
          "jobId",
          "jobRunId"
        ])
      ) {
        return;
      }

      path = generatePath(`${WebPaths.JobRoutes}${WebPaths.JobModels}`, {
        projectId: projectId ?? null,
        jobId: jobId ?? null,
        scenarioId: scenarioId ?? null,
        jobRunId: jobRunId ?? null,
        modelName: name
      });
    }

    !!path && window.open(path, "_blank");
  };
  const navigateToRecipe = (node: any) => {
    const path = getNavigatePath(node);
    !!path && window.open(path, "_blank");
  };

  const navigateToDetils = (node: any) => {
    switch (upperCase(trim(node?.type))) {
      case NodeTypes.Artifact:
        navigateToArtifact(node?.name);
        break;
      case NodeTypes.Chart:
        navigateToCharts(node?.id);
        break;
      case NodeTypes.Dataset:
        navigateToDataset(node.id);
        break;
      case NodeTypes.Model:
        navigateToModel(node?.name);
        break;
      case NodeTypes.Recipe:
        navigateToRecipe(node);
        break;
    }
  };

  return (
    <div
      style={{
        height: "100%",
        border: "1px solid #BDBDBD",
        borderRadius: "11px",
        background: "#FFFFFF"
      }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={onCollapse}
        className={classes.codeRecipeHeader}>
        <Typography variant="subtitle1">Canvas Nodes</Typography>
        <Grid container direction="row" className={classes.btnContainer}>
          <IconButton data-testid="entities-on-canvas-collapse" size="small">
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Grid>
      </Grid>
      {expanded && (
        <>
          <SearchField
            test-id="entities-on-canvas-search-field"
            onChange={handleSearch}
            style={{ borderRadius: "0px", width: "100%" }}
            value={searchValue}
            placeholder="Search "
          />
          {isLoading && <Spinner size={20} />}
          <Grid container direction="column" className={classes.inputWrap}>
            {!isLoading &&
              orderBy(filteredEntities, (item) => toLower(item.displayName))?.map((entity) => (
                <CanvasEntity
                  entity={entity}
                  navigateToDetils={navigateToDetils}
                  handlePreview={handlePreview}
                />
              ))}
            {!isLoading && !!searchValue && isEmpty(filteredEntities) && (
              <span style={{ width: "100%", padding: "5px 30px" }}>No search result</span>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

export default React.memo(CodeRecipeCanvasEntitiesContainer);
