import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const FileImportIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H7.67157C8.0694 0.5 8.45093 0.658035 8.73223 0.93934L10.0459 2.25305C10.0961 2.30324 10.1427 2.35692 10.1853 2.41371L11.2 3.76667C11.3947 4.02631 11.5 4.34211 11.5 4.66667V14C11.5 14.8284 10.8284 15.5 10 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2Z"
        stroke={color}
      />
      <path
        d="M6.00001 8L6.00001 12L6.00001 8ZM6.00001 12L8 10.3793L6.00001 12ZM6.00001 12L4 10.3793L6.00001 12Z"
        fill="white"
      />
      <path
        d="M6.00001 8L6.00001 12M6.00001 12L8 10.3793M6.00001 12L4 10.3793"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileImportIcon;
