import React from "react";

type Props = {
  backgroundColor?: string;
  iconColor?: string;
};

export const DataAppDefaultImage = ({
  backgroundColor = "#003656",
  iconColor = "#ffffff"
}: Props) => {
  return (
    <svg width="160" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.2">
        <rect width="160" height="100%" rx="4" fill={backgroundColor} />
        <rect
          x="0.5"
          y="0.5"
          width="159"
          height="182"
          rx="3.5"
          stroke="black"
          strokeOpacity="0.12"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.9168 96.9166V92.8541H88.6252V96.9166H92.6877V99.6249H88.6252V103.687H85.9168V99.6249H81.8543V96.9166H85.9168ZM69.6668 92.8541H76.4377C77.9334 92.8541 79.146 94.0666 79.146 95.5624V102.333C79.146 103.829 77.9334 105.042 76.4377 105.042H69.6668C68.1711 105.042 66.9585 103.829 66.9585 102.333V95.5624C66.9585 94.0666 68.1711 92.8541 69.6668 92.8541ZM69.6668 95.5624V102.333H76.4377V95.5624H69.6668ZM69.6668 77.9583H76.4377C77.9334 77.9583 79.146 79.1708 79.146 80.6666V87.4374C79.146 88.9332 77.9334 90.1458 76.4377 90.1458H69.6668C68.1711 90.1458 66.9585 88.9332 66.9585 87.4374V80.6666C66.9585 79.1708 68.1711 77.9583 69.6668 77.9583ZM69.6668 80.6666V87.4374H76.4377V80.6666H69.6668ZM84.5627 77.9583H91.3335C92.8293 77.9583 94.0418 79.1708 94.0418 80.6666V87.4374C94.0418 88.9332 92.8293 90.1458 91.3335 90.1458H84.5627C83.0669 90.1458 81.8543 88.9332 81.8543 87.4374V80.6666C81.8543 79.1708 83.0669 77.9583 84.5627 77.9583ZM84.5627 80.6666V87.4374H91.3335V80.6666H84.5627Z"
        fill={iconColor}
      />
    </svg>
  );
};

export const defaultImages = [
  <DataAppDefaultImage key="image1" backgroundColor="#003656" iconColor="#ffffff" />,
  <DataAppDefaultImage key="image2" backgroundColor="#C7C7C7" iconColor="#f1957d" />,
  <DataAppDefaultImage key="image3" backgroundColor="#f1957d" iconColor="#f1957d" />,
  <DataAppDefaultImage key="image4" backgroundColor="#003656" iconColor="#003656" />
];
