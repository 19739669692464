import React, { useMemo } from "react";
import _, { first, get } from "lodash";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";

import AutoMLRecipeBaseInputs from "./AutoMLRecipeBaseInputs";
import AutoMLTemplateInputs from "./AutoMLTemplateInputs";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import { AutoMLAdvancedInputs } from "./AutoMLAdvancedInputs";
import { Entities } from "src/types";
import { useAutoMLRecipeContext } from "../../AutoMLRecipeContext/useAutoMLRecipeContext";
import { useFetchTransformInputs } from "src/pages/Projects/AddStandardRecipe/Transformations/TransformationsList/TransformItems/useFetchTransformInputs";
import { AutoMlRecipeCode } from "./AutoMlRecipeCode";
import { MachineLearningTask } from "../../hooks/useGetAutoMLProblemTypes";
import NumberInput from "./NumberInput";
import { CUSTOM_PARAMETERS } from "../../utils/AddAutoMLRecipe.constants";

type Props = {
  allEntities: Entities | undefined;
  isDefaultScenario: boolean;
};

const useStyles = makeStyles((theme) => ({
  inputWrap: {
    flexWrap: "nowrap",
    overflow: "auto",
    maxHeight: "100%",
    border: "1px solid #BDBDBD",
    borderRadius: "12px",
    background: "#FFFFFF",
    height: "fit-content",
    gap: "18px",
    boxShadow: theme.shadows[3]
  },
  inputContainer: {
    padding: "16px",
    flexWrap: "nowrap",
    overflow: "auto",
    maxHeight: "100%",
    overflowX: "hidden"
  },
  header: {
    padding: "0px 16px",
    height: "44px",
    display: "flex",
    alignItems: "center",
    background: "#d9d9d933"
  },
  leftBar: {
    gap: "16px"
  }
}));
export const AutoMLRecipeInputContainer = ({ allEntities, isDefaultScenario }: Props) => {
  const classes = useStyles();
  const {
    inputDatasets,
    setSelectedTransform,
    templateData,
    values,
    handleInputChange,
    errors,
    problemType,
    recipe
  } = useAutoMLRecipeContext();

  const { inputs } = useFetchTransformInputs({ selectedTransform: templateData });
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  React.useEffect(() => {
    templateData &&
      setSelectedTransform((transform: any) => ({
        ...transform,
        variables: _.omitBy(values, _.isUndefined)
      }));
  }, [values]);

  const input = useMemo(
    () => _.find((inputs as any).parameters, { name: CUSTOM_PARAMETERS.timeSpan }),
    [(inputs as any).parameters]
  );

  const inputInfo = input?.metadata ? "metadata" : "display";
  const currentInput = _.get(input, inputInfo);

  return (
    <NewThemeWrapper>
      <Grid container direction="column" wrap="nowrap" className={classes.leftBar}>
        <Grid container direction="column" className={classes.inputWrap}>
          <Box className={classes.header}>
            <Typography variant="subtitle1">Inputs</Typography>
          </Box>
          <Grid container direction="column" className={classes.inputContainer}>
            <AutoMLRecipeBaseInputs
              allEntities={allEntities}
              isDefaultScenario={isDefaultScenario}
              targetCol={values?.["targetCol"] || ""}
            />
            {templateData && (
              <AutoMLTemplateInputs
                inputs={inputs}
                selectedEntities={inputDatasets}
                values={values}
                handleInputChange={handleInputChange}
                errors={errors}
              />
            )}
          </Grid>
        </Grid>
        {!!problemType && (
          <Grid container direction="column" className={classes.inputWrap}>
            <AutoMLAdvancedInputs
              freezeCol={values?.["freeze_col"] || ""}
              handleInputChange={handleInputChange}>
              {problemType === MachineLearningTask.TIMESERIES_FORECASTING && !!currentInput ? (
                <NumberInput
                  isDefaultScenario={isDefaultScenario}
                  input={input}
                  errors={errors}
                  currentInput={currentInput}
                  values={values}
                  isJobPath={isJobPath}
                  onInputChange={handleInputChange}
                />
              ) : undefined}
            </AutoMLAdvancedInputs>
          </Grid>
        )}
        {recipe?.id && !isJobPath && !!get(first(recipe?.runConfigs), "id") && (
          <Grid container direction="column" className={classes.inputWrap}>
            <AutoMlRecipeCode />
          </Grid>
        )}
      </Grid>
    </NewThemeWrapper>
  );
};
