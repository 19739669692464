import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const EyeIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49999 3.71635C8.2596 3.47474 9.09199 3.33334 9.99999 3.33334C13.4849 3.33334 15.8567 5.4163 17.2709 7.25361C17.9792 8.17378 18.3333 8.63384 18.3333 10C18.3333 11.3662 17.9792 11.8263 17.2709 12.7464C15.8567 14.5837 13.4849 16.6667 9.99999 16.6667C6.51508 16.6667 4.14329 14.5837 2.72906 12.7464C2.02079 11.8263 1.66666 11.3662 1.66666 10C1.66666 8.63384 2.02079 8.17378 2.72906 7.25361C3.13009 6.73262 3.6081 6.19188 4.16666 5.68448"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61925 12.5 7.5 11.3807 7.5 10C7.5 8.61925 8.61925 7.5 10 7.5C11.3807 7.5 12.5 8.61925 12.5 10Z"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default EyeIcon;
