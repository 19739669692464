import React from "react";
import Editor from "@monaco-editor/react";
import { Grid, Button } from "@material-ui/core";

const fileExtensions = {
  JS: "js",
  PYTHON: "py",
  SYSTEM: "sys",
  JAVA: "java",
  JSON: "json"
};

const languages = {
  JS: "javascript",
  PYTHON: "python",
  SYSTEM: "system",
  JAVA: "java",
  JSON: "json"
};

type Props = {
  hideTestCode?: boolean;
  disableTestCode?: boolean;
  defaultValue?: string;
  defaultScriptType?: string;
  value?: string;
  height?: string;
  onChange: $TSFixMeFunction;
  name: string;
  scriptType?: string;
  options?: $TSFixMe;
  readOnly?: boolean;
  theme?: string;
};

const CodeEditor = ({
  hideTestCode,
  disableTestCode = false,
  defaultValue = "",
  defaultScriptType,
  value = "",
  height = "250px",
  scriptType = "JS",
  onChange,
  name,
  options,
  readOnly,
  theme = "vs-dark",
  ...rest
}: Props) => {
  return (
    <>
      <Editor
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        defaultLanguage={languages[defaultScriptType]}
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        language={languages[scriptType]}
        defaultValue={defaultValue}
        value={value}
        height={height}
        theme={theme}
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        path={`script.${fileExtensions[scriptType]}`}
        onChange={(value) => onChange(name, value)}
        options={{ padding: { top: 12, bottom: 12 }, readOnly, ...options }}
        {...rest}
      />
      {!hideTestCode && (
        <Grid item xs={12} style={{ lineHeight: 0, padding: "10px 10px" }}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            style={{ padding: "0 10px" }}
            disabled={disableTestCode}>
            Test code
          </Button>
        </Grid>
      )}
    </>
  );
};

export default CodeEditor;
