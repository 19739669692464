import React from "react";
import { IDestination } from "../JobDestinations/JobDestinations";
import { DataConnectorNames } from "pages/DataSources/utils/DataSources.constants";
import FileBasedConnectors from "./FileBasedConnectors";
import SQLRedshiftConnectorDetails from "../../../OutputDataset/components/SQLRedshiftConnectorDetails";
import MongoConnectorDetails from "../../../OutputDataset/components/MongoConnectorDetails";
import SnowflakeConnectorDetails from "../../../OutputDataset/components/SnowflakeConnectorDetails";
import _, { includes, replace } from "lodash";
import { CONNECTOR_KEYS } from "../../../OutputDataset/utils/OutputDataset.constants";
import { DEFAULT_FILENAME } from "../../utils/Jobs.constants";

interface IProps {
  index: number;
  destination: IDestination;
  onOptionsChange: (options: Record<string, string>) => void;
}

const EditJobConnector: React.FC<IProps> = (props) => {
  const { index, destination, onOptionsChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;

    onOptionsChange({
      ...destination.enteredOptions,
      [name]: value
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const filename =
      _.get(destination.enteredOptions, CONNECTOR_KEYS.fileCategory.destinationFileName.key) || "";
    const newFileName = checked
      ? includes(filename, DEFAULT_FILENAME)
        ? filename
        : `${filename}${DEFAULT_FILENAME}`
      : replace(filename, DEFAULT_FILENAME, "");

    onOptionsChange({
      ...destination.enteredOptions,
      [CONNECTOR_KEYS.fileCategory.destinationFileName.key]: newFileName
    });
  };

  switch (destination.selectedDataSourceType) {
    case DataConnectorNames.AZURE_BLOB:
    case DataConnectorNames.GCP_STORAGE:
    case DataConnectorNames.S3_STORAGE:
      return (
        <FileBasedConnectors
          index={index}
          destination={destination}
          onCheckboxChange={handleCheckboxChange}
          onChange={handleChange}
        />
      );

    case DataConnectorNames.MYSQL:
    case DataConnectorNames.REDSHIFT:
      return (
        <SQLRedshiftConnectorDetails values={destination.enteredOptions} onChange={handleChange} />
      );

    case DataConnectorNames.MONGO:
      return <MongoConnectorDetails values={destination.enteredOptions} onChange={handleChange} />;

    case DataConnectorNames.SNOWFLAKE:
      return (
        <SnowflakeConnectorDetails values={destination.enteredOptions} onChange={handleChange} />
      );

    default:
      return null;
  }
};

export default EditJobConnector;
