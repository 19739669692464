import React, { useState } from "react";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

// Icons
import { PlusIcon } from "src/icons/NewUX/PlusIcon";

// Hooks
import useRecipesStatus from "./hooks/useRecipesStatus";
import useActions from "./hooks/useActions";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./components/SubTopNavBarBreadcrumbs";
import { Spinner } from "src/components";
import Search from "src/components/custom/Search/Search";
import ScenariosTable from "./components/ScenariosTable";
import DeleteScenario from "./components/DeleteScenario";

// Contexts
import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";

const Scenarios = () => {
  const { project } = useProjectContext() || {};

  // States - STARTS >>
  const [searchValue, setSearchValue] = useState("");
  const [deletingScenarioId, setDeletingScenarioId] = useState<undefined | null | string>("");
  const [showConfirmScreen, setShowConfirmScreen] = useState(false);
  // << ENDS - States

  const {
    isLoading,
    isRefetchingProjectCanvas,

    isRecipeInProject,
    isRecipesRunningAcrossScenariosStore,

    scenariosData,
    refetchScenarios
  } = useRecipesStatus();
  const { onAdd, onEdit, onDisplayOnDag, onRun } = useActions();

  // Delete scenario - STARTS >>
  const promptConfirmDeleteScenario = (scenarioId?: string) => {
    setDeletingScenarioId(() => scenarioId);
    setShowConfirmScreen(() => true);
  };

  const resetDeleteScenario = () => {
    setDeletingScenarioId(() => "");
    setShowConfirmScreen(() => false);
  };
  // << ENDS - Delete scenario

  return (
    <>
      {showConfirmScreen && !!deletingScenarioId && (
        <DeleteScenario
          deletingScenarioId={deletingScenarioId}
          resetDeleteScenario={resetDeleteScenario}
          refetch={refetchScenarios}
        />
      )}

      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: <SubTopNavBarBreadcrumbs project={project} />
        }}
        {...(!isLoading
          ? {
              subTopNavBarRightSection: {
                component: (
                  <>
                    <Search
                      onSearch={(event) => setSearchValue(() => event?.target?.value)}
                      placeholder="Search scenario"
                      data-testid="segmentsSearch"
                    />

                    <IconButton
                      color="primary"
                      size="small"
                      onClick={onAdd}
                      data-testid="segmentsAddSegmentAction">
                      <PlusIcon width={28} height={28} />
                    </IconButton>
                  </>
                )
              }
            }
          : {})}
      />

      <Box ml="50px" p={2} height="calc(100% - 44px)">
        <Grid container>
          {isLoading ? (
            <Spinner />
          ) : (
            <ScenariosTable
              data={scenariosData}
              isRefetchingProjectCanvas={isRefetchingProjectCanvas}
              isRecipeInProject={isRecipeInProject}
              isRecipesRunningAcrossScenariosStore={isRecipesRunningAcrossScenariosStore}
              onEdit={onEdit}
              onDelete={promptConfirmDeleteScenario}
              onRun={onRun}
              onDisplayOnDag={onDisplayOnDag}
              searchValue={searchValue}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Scenarios;
