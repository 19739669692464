/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DataSourceUsageDto
 */
export interface DataSourceUsageDto {
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'entityName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataSourceUsageDto
     */
    'entityCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataSourceUsageDto
     */
    'entityUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'entityCreator'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'entityUpdater'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'projectName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataSourceUsageDto
     */
    'projectCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataSourceUsageDto
     */
    'projectUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'projectCreator'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'projectUpdater'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'jobId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'jobName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataSourceUsageDto
     */
    'jobCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataSourceUsageDto
     */
    'jobUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'jobCreator'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'jobUpdater'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'pdId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'pdName'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataSourceUsageDto
     */
    'pdCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof DataSourceUsageDto
     */
    'pdUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'pdCreator'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'pdUpdater'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'type'?: DataSourceUsageDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DataSourceUsageDto
     */
    'defaultScenarioId'?: string;
}

export const DataSourceUsageDtoTypeEnum = {
    Source: 'SOURCE',
    Dest: 'DEST',
    JobDest: 'JOB_DEST',
    PredictionService: 'PREDICTION_SERVICE',
    JobEntity: 'JOB_ENTITY'
} as const;

export type DataSourceUsageDtoTypeEnum = typeof DataSourceUsageDtoTypeEnum[keyof typeof DataSourceUsageDtoTypeEnum];


