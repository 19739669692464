/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateProjectRunDto
 */
export interface UpdateProjectRunDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRunDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRunDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRunDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRunDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRunDto
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRunDto
     */
    'status'?: UpdateProjectRunDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRunDto
     */
    'jobType'?: UpdateProjectRunDtoJobTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof UpdateProjectRunDto
     */
    'scheduleInfo'?: object;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRunDto
     */
    'timeoutInHours'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateProjectRunDto
     */
    'variables'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRunDto
     */
    'modelEntityId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRunDto
     */
    'deleted'?: boolean;
}

export const UpdateProjectRunDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type UpdateProjectRunDtoStatusEnum = typeof UpdateProjectRunDtoStatusEnum[keyof typeof UpdateProjectRunDtoStatusEnum];
export const UpdateProjectRunDtoJobTypeEnum = {
    ProjectJob: 'PROJECT_JOB',
    PredictionJob: 'PREDICTION_JOB'
} as const;

export type UpdateProjectRunDtoJobTypeEnum = typeof UpdateProjectRunDtoJobTypeEnum[keyof typeof UpdateProjectRunDtoJobTypeEnum];


