import React from "react";

export const CheckmarkSquare = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M10.9626 1.58398H7.73301C5.49279 1.58398 4.37269 1.58398 3.51705 2.01996C2.76439 2.40345 2.15247 3.01537 1.76898 3.76802C1.33301 4.62367 1.33301 5.74378 1.33301 7.98398V8.51732C1.33301 10.7575 1.33301 11.8777 1.76898 12.7333C2.15247 13.4859 2.76439 14.0979 3.51705 14.4813C4.37269 14.9173 5.4928 14.9173 7.73301 14.9173H8.26634C10.5065 14.9173 11.6267 14.9173 12.4823 14.4813C13.2349 14.0979 13.8469 13.4859 14.2303 12.7333C14.6663 11.8777 14.6663 10.7575 14.6663 8.51732V7.50992M5.99967 7.58398L7.99967 9.58398L14.6663 2.91732"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
