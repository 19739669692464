import React, { Dispatch, SetStateAction, useMemo } from "react";

// Packages
import { Handle, Position } from "react-flow-renderer";
import clsx from "clsx";

// MUI
import Tooltip from "@material-ui/core/Tooltip";

// Contexts
import { useDagFlowContext } from "../DagFlow/context/useDagFlowContext";

// Styles
import useStyles from "./NodesWrapper.styles";

type Props = {
  nodeId: string;
  isSourceNode?: boolean;
  isTargetNode?: boolean;
  backgroundColor?: string;
  disabledMessage?: string;
  isHovered?: boolean;
  setIsHovered?: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
};

const NodesWrapper = (props: Props) => {
  const {
    nodeId = "",
    isSourceNode = true,
    isTargetNode = true,
    backgroundColor,
    disabledMessage,
    isHovered,
    setIsHovered,
    children
  } = props || {};

  const { nodesExpanded, updateNodesExpanded } = useDagFlowContext();

  const nodeExpanded = useMemo(() => nodesExpanded[nodeId] ?? true, [nodesExpanded[nodeId]]);

  const classes = useStyles({ backgroundColor, nodeExpanded });

  return (
    <>
      {/* Note: Handles below must always be rendered! */}
      {/* The edges render depending upon these Handles. */}
      {/* Hence, hiding these Handles is managed by applying necessary styles. */}
      <Handle
        type="target"
        position={Position.Left}
        className={clsx(!!isTargetNode && !!backgroundColor ? classes.connectable : classes.base)}
      />
      <Tooltip title={!!disabledMessage ? disabledMessage : ""}>
        <span>{children}</span>
      </Tooltip>

      {!!isSourceNode ? (
        <Handle
          type="source"
          position={Position.Right}
          onMouseEnter={() => setIsHovered?.(() => true)}
          onMouseLeave={() => setIsHovered?.(() => false)}
          className={clsx(
            !!backgroundColor ? classes.connectable : classes.base,
            !nodeExpanded || !!isHovered
              ? {
                  expandableHandle: true,
                  expanded: nodeExpanded,
                  collapsed: !nodeExpanded
                }
              : {}
          )}
          onClick={() => updateNodesExpanded(nodeId)}>
          {(!nodeExpanded || !!isHovered) && (
            <span className="text">{nodeExpanded ? "-" : "+"}</span>
          )}
        </Handle>
      ) : (
        <Handle type="source" position={Position.Right} className={classes.base} />
      )}
    </>
  );
};

export default NodesWrapper;
