import React from "react";
import { Button, DialogActions, makeStyles, SvgIcon, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import NewThemeWrapper from "src/styles/NewThemeWrapper";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    zIndex: 9999999,
    backgroundColor: "rgba(255,255,255,0.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    alignContent: "center",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0
  },
  title: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: 5,
    fontSize: "1rem",
    "& svg": {
      marginRight: 10
    }
  },
  subtitle: {
    fontSize: "1rem",
    color: theme.palette.primary.light
  },
  icon: {
    color: theme.palette.primary.main
  }
}));

type Props = {
  onCancel: $TSFixMeFunction;
  onConfirm: $TSFixMeFunction;
  confirmLabel?: $TSFixMe;
  isCancelDisabled?: boolean;
  cancelLabel?: string;
  isConfirmDisabled?: boolean;
  title: string;
  subtitleLines?: string[];
};

/**
 * Component that allows to overlay a message a container with relative position
 * @param {object} props
 * @param {func} props.onCancel - handle cancel
 * @param {func} props.onConfirm - handle confirm
 * @param {string} props.confirmLabel - confirm label button
 * @param {string} props.cancelLabel - cancel label button
 * @param {string} props.title
 */
const ConfirmScreen = ({
  onCancel,
  onConfirm,
  confirmLabel = "Confirm",
  isCancelDisabled = false,
  cancelLabel = "Cancel",
  isConfirmDisabled = false,
  title,
  subtitleLines
}: Props) => {
  const classes = useStyles();
  return (
    <NewThemeWrapper>
      <div className={classes.container}>
        <div>
          <div className={classes.title}>
            <SvgIcon className={classes.icon}>
              <InfoOutlined />
            </SvgIcon>
            {title}
          </div>
          <Typography variant="body1" className={classes.subtitle}>
            {/* @ts-expect-error TS(2322) FIXME: Type 'Element' is not assignable to type 'string'. */}
            {subtitleLines?.reduce((prev, next) => (
              <>
                {prev}
                <br />
                {next}
              </>
            ))}
          </Typography>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
              disabled={isCancelDisabled}>
              {cancelLabel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={isConfirmDisabled}>
              {confirmLabel}
            </Button>
          </DialogActions>
        </div>
      </div>
    </NewThemeWrapper>
  );
};

export default ConfirmScreen;
