import React from "react";

export const TextIconV2 = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6.4" y="4.8" width="19.2" height="19.2" rx="9.6" fill="white" />
      <g filter="url(#filter0_d_20123_8389)">
        <rect x="3.2" y="1.6" width="25.6" height="25.6" rx="12.8" fill="white" />
        <g opacity="0.5">
          <path
            d="M16 20.8C19.5346 20.8 22.4 17.9346 22.4 14.4C22.4 10.8654 19.5346 8 16 8C12.4654 8 9.6 10.8654 9.6 14.4C9.6 17.9346 12.4654 20.8 16 20.8Z"
            stroke="black"
            strokeWidth="0.577778"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 13.6889V17.9556"
            stroke="black"
            strokeWidth="0.577778"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.8218 11.5557V10.8446H16.1775V11.5557H15.8218Z"
            stroke="black"
            strokeWidth="0.577778"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <g filter="url(#filter1_d_20123_8389)">
        <rect x="3.2" y="1.6" width="25.6" height="25.6" rx="12.8" fill="white" />
        <path
          d="M18.8805 10.9844L18.9625 12.7234H18.7547C18.7145 12.4172 18.6599 12.1984 18.5907 12.0672C18.4776 11.8558 18.3263 11.7008 18.1367 11.6024C17.9508 11.5015 17.7047 11.4492 17.3984 11.4492H16.3539V17.1149C16.3539 17.5706 16.4031 17.8549 16.5016 17.968C16.64 18.1211 16.8533 18.1976 17.1414 18.1976H17.3984V18.4H14.2539V18.1976H14.5164C14.8299 18.1976 15.0523 18.1028 15.1836 17.9234C15.2639 17.7966 15.3031 17.5305 15.3031 17.1149V11.4492H14.4125C14.0662 11.4492 13.82 11.4747 13.6742 11.5266C13.4844 11.6024 13.3224 11.7281 13.1883 11.9039C13.0542 12.0797 12.9724 12.388 12.9406 12.7234H12.739L12.8266 10.9844H18.8805Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_20123_8389"
          x="-1.6"
          y="-1.6"
          width="35.2"
          height="35.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.6" />
          <feGaussianBlur stdDeviation="1.6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.451725 0 0 0 0 0.451725 0 0 0 0 0.451725 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20123_8389" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20123_8389"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_20123_8389"
          x="-1.6"
          y="-1.6"
          width="35.2"
          height="35.2"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.6" />
          <feGaussianBlur stdDeviation="1.6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.451725 0 0 0 0 0.451725 0 0 0 0 0.451725 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20123_8389" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20123_8389"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
