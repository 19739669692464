import React from "react";

import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";

const LoaderWrapper = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ rowGap: theme.spacing(2) }}>
      {children}
    </Grid>
  );
};

export default LoaderWrapper;
