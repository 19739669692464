import React from "react";

// Packages
import { Controller, useFormContext } from "react-hook-form";
import { includes } from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

// Contexts
import { useSegmentContext } from "../../context/useSegmentContext";

// Constants
import {
  SegmentFormFields,
  segmentFormFieldsNameMapping,
  SegmentHelperText
} from "../../utils/Segment.constants";

// Styles
import useStyles from "./SegmentDetails.styles";

const SegmentDetails = () => {
  const classes = useStyles();

  const { isReadOnly } = useSegmentContext();

  const { control } = useFormContext();

  const inputLabelProps = {
    classes: { root: classes.inputLabel, shrink: classes.inputLabelShrink }
  };

  return (
    <Paper className={classes.root}>
      <Box p={2}>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          data-testid="segmentDetailsCreateSegment">
          {SegmentHelperText.CreateSegment}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <Grid container className={classes.formContainer}>
          <Grid item>
            <Grid container direction="column" style={{ rowGap: 16 }}>
              <Controller
                control={control}
                name={SegmentFormFields.RowLimit}
                rules={{
                  min: {
                    value: 1,
                    message: `${segmentFormFieldsNameMapping[SegmentFormFields.RowLimit]} should be min 1.`
                  }
                }}
                render={({ field, fieldState }) => {
                  const { error } = fieldState;

                  return (
                    <TextField
                      {...field}
                      type="number"
                      variant="outlined"
                      size="small"
                      fullWidth
                      inputProps={{ min: 1 }}
                      label={segmentFormFieldsNameMapping[SegmentFormFields.RowLimit]}
                      InputLabelProps={inputLabelProps}
                      onKeyDown={(event) => {
                        if (includes(["e", "E", "+"], event.key)) {
                          event?.preventDefault();
                        }
                      }}
                      disabled={!!isReadOnly}
                      error={!!error}
                      {...(!!error?.message ? { helperText: error?.message } : {})}
                      data-testid="segmentDetailsRowLimit"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name={SegmentFormFields.Description}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={4}
                  label={segmentFormFieldsNameMapping[SegmentFormFields.Description]}
                  InputLabelProps={inputLabelProps}
                  disabled={!!isReadOnly}
                  data-testid="segmentDetailsDescription"
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SegmentDetails;
