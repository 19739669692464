import api from "utils/AxiosClient";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { AI_GUIDE_MESSAGES_KEY } from "../aiguide/useGetAIGuideMessages";
import { AIChatResponseDto } from "openapi/Models";

interface IVariable {
  threadId: string;
  messageId: string;
  askAIMessageId: string;
  parentThreadId: string;
}

const useSaveChart = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ threadId, messageId, askAIMessageId, parentThreadId }) => {
      const data = await api.fetchResponse(() =>
        api.AiControllerApi.updateChart(threadId, messageId)
      );

      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, parentThreadId],
        (prevData: AIChatResponseDto[] | undefined) => {
          const updatedData = (prevData || []).map((response) =>
            response.askAIMessageId === askAIMessageId
              ? {
                  ...response,
                  outputEntityList: data.outputEntityList,
                  outputEntityResponseList: undefined
                }
              : response
          );

          return updatedData;
        }
      );
    }
  });
};

export default useSaveChart;
