import React from "react";

export const QueryInputIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.27137 8.2136C8.30506 8.13237 8.28614 8.03776 8.22383 7.97545L6.16306 5.66037C6.07814 5.57545 5.93968 5.57545 5.85476 5.66037L3.77599 7.97545C3.73399 8.01699 3.71183 8.07283 3.71183 8.1296C3.71183 8.15776 3.71737 8.18637 3.72845 8.2136C3.7626 8.29483 3.84199 8.34329 3.9306 8.34329H5.07199C5.07199 10.2753 4.74014 11.3258 2.53906 11.9645C6.22491 11.9645 6.94722 9.83268 6.94722 8.34329H8.0706C8.15829 8.34329 8.23768 8.29483 8.27137 8.2136Z"
        fill="#030104"
      />
      <path
        d="M10.6154 0.0351562H1.38462C0.620308 0.0351562 0 0.655002 0 1.41977V8.80439C0 9.5687 0.620308 10.189 1.38462 10.189H3.69231V9.26593H1.38462C1.13031 9.26593 0.923077 9.0587 0.923077 8.80439V3.26593H11.0769V8.80439C11.0769 9.0587 10.8697 9.26593 10.6154 9.26593H7.84615V10.189H10.6154C11.3797 10.189 12 9.5687 12 8.80439V1.41977C12 0.655002 11.3797 0.0351562 10.6154 0.0351562ZM1.88215 2.047C1.88215 2.23023 1.734 2.37885 1.55031 2.37885H1.21892C1.03569 2.37885 0.887077 2.23069 0.887077 2.047V1.71562C0.887077 1.53239 1.03523 1.38377 1.21892 1.38377H1.55077C1.734 1.38377 1.88262 1.53193 1.88262 1.71562L1.88215 2.047ZM3.26677 2.047C3.26677 2.23023 3.11862 2.37885 2.93492 2.37885H2.60354C2.42031 2.37885 2.27169 2.23069 2.27169 2.047V1.71562C2.27169 1.53239 2.41985 1.38377 2.60354 1.38377H2.93538C3.11862 1.38377 3.26723 1.53193 3.26723 1.71562L3.26677 2.047ZM11.0769 2.34285H3.69231V1.41977H11.0769V2.34285Z"
        fill="#030104"
      />
    </svg>
  );
};
