import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  withStyles
} from "@material-ui/core";

import { ExpandMore } from "@material-ui/icons";
import { useStyles } from "./styling";

const AccordionSummary = withStyles({
  root: {
    minHeight: 24,
    "&$expanded": {
      minHeight: 24
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0px 8px 0px"
    }
  },
  expanded: {},
  expandIcon: {
    padding: "8px 12px"
  }
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: { paddingTop: 0 }
})(MuiAccordionDetails);

const DrawerAccordion = ({
  title,
  label,
  content,
  expanded,
  onChange
}: {
  title: string | JSX.Element;
  content: any;
  expanded?: boolean;
  label?: string;
  onChange?: () => void;
}) => {
  const areaLabel = typeof title === "string" ? title : label ?? "accordian";
  const classes = useStyles();

  return (
    <Accordion
      elevation={0}
      {...(expanded !== undefined && onChange !== undefined
        ? { expanded, onChange }
        : { defaultExpanded: true })}>
      <AccordionSummary
        aria-label="Expand"
        expandIcon={<ExpandMore color="primary" />}
        aria-controls={`panel-${areaLabel}-content`}
        id={`panel-${areaLabel}-header`}>
        <Typography className={classes.accordionTitle} variant="caption" color="textPrimary">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionContainer}>{content}</AccordionDetails>
    </Accordion>
  );
};

export default DrawerAccordion;
