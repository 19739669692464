/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ScenarioRunStepDto
 */
export interface ScenarioRunStepDto {
    /**
     * 
     * @type {string}
     * @memberof ScenarioRunStepDto
     */
    'status'?: ScenarioRunStepDtoStatusEnum;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof ScenarioRunStepDto
     */
    'viewData'?: Array<{ [key: string]: string; }>;
}

export const ScenarioRunStepDtoStatusEnum = {
    Pending: 'PENDING',
    Built: 'BUILT',
    Empty: 'EMPTY',
    Running: 'RUNNING',
    Stopped: 'STOPPED',
    Success: 'SUCCESS',
    Error: 'ERROR',
    Unbuilt: 'UNBUILT',
    Skipped: 'SKIPPED',
    Building: 'BUILDING'
} as const;

export type ScenarioRunStepDtoStatusEnum = typeof ScenarioRunStepDtoStatusEnum[keyof typeof ScenarioRunStepDtoStatusEnum];


