/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { Data } from './data';
// May contain unused imports in some cases
// @ts-ignore
import type { PipelineTestResultPerTransformMsgsInner } from './pipeline-test-result-per-transform-msgs-inner';
// May contain unused imports in some cases
// @ts-ignore
import type { PipelineTestResultPerTransformStatusesInner } from './pipeline-test-result-per-transform-statuses-inner';
// May contain unused imports in some cases
// @ts-ignore
import type { RunErrDetails } from './run-err-details';

/**
 * 
 * @export
 * @interface PipelineTestResult
 */
export interface PipelineTestResult {
    /**
     * 
     * @type {boolean}
     * @memberof PipelineTestResult
     */
    'noOutput'?: boolean;
    /**
     * 
     * @type {{ [key: string]: Data; }}
     * @memberof PipelineTestResult
     */
    'dataMap'?: { [key: string]: Data; };
    /**
     * 
     * @type {Array<PipelineTestResultPerTransformMsgsInner>}
     * @memberof PipelineTestResult
     */
    'perTransformMsgs'?: Array<PipelineTestResultPerTransformMsgsInner>;
    /**
     * 
     * @type {Array<PipelineTestResultPerTransformStatusesInner>}
     * @memberof PipelineTestResult
     */
    'perTransformStatuses'?: Array<PipelineTestResultPerTransformStatusesInner>;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof PipelineTestResult
     */
    'perTransformCache'?: { [key: string]: { [key: string]: string; }; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PipelineTestResult
     */
    'transformRunTimes'?: { [key: string]: number; };
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof PipelineTestResult
     */
    'entityViewData'?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof PipelineTestResult
     */
    'entityArtifactData'?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof PipelineTestResult
     */
    'entityModelData'?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {string}
     * @memberof PipelineTestResult
     */
    'executedCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineTestResult
     */
    'outputType'?: PipelineTestResultOutputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineTestResult
     */
    'answer'?: string;
    /**
     * 
     * @type {RunErrDetails}
     * @memberof PipelineTestResult
     */
    'runErrDetails'?: RunErrDetails;
    /**
     * 
     * @type {number}
     * @memberof PipelineTestResult
     */
    'recipeRunTime'?: number;
}

export const PipelineTestResultOutputTypeEnum = {
    Entity: 'ENTITY',
    RcMlModel: 'RC_ML_MODEL',
    Artifact: 'ARTIFACT',
    Metadata: 'METADATA',
    Chart: 'CHART',
    Dashboard: 'DASHBOARD',
    Answer: 'ANSWER',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type PipelineTestResultOutputTypeEnum = typeof PipelineTestResultOutputTypeEnum[keyof typeof PipelineTestResultOutputTypeEnum];


