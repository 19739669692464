import React from "react";

import JobContextProvider from "./context/JobContextProvider";
import Job from "./Job";

const JobWrapper = () => {
  return (
    <JobContextProvider>
      <Job />
    </JobContextProvider>
  );
};

export default JobWrapper;
