import React from "react";

import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";

import { TabPanel } from "src/components";

import Datasets from "./Datasets";

import { DataSourcesHelperText } from "../utils/DataSources.constants";

const TabPanelWrapper = (props: $TSFixMe) => {
  const {
    tab,
    value,
    index,
    isDataSourceUsageLoading,
    usageSourceDatasets,
    usageDestinationDatasets,
    emptyDataMessage,
    ...rest
  } = props || {};

  return (
    <TabPanel
      value={value}
      index={index}
      boxProps={{
        sx: { py: 3, display: "flex", flexDirection: "column", rowGap: 25 }
      }}
      {...rest}>
      {(usageSourceDatasets || [])?.length === 0 &&
      (usageDestinationDatasets || [])?.length === 0 ? (
        <Box style={{ padding: "0 16px" }}>
          <Alert severity="info">{emptyDataMessage}</Alert>
        </Box>
      ) : (
        <>
          {(usageSourceDatasets || [])?.length > 0 && (
            <Datasets
              title={DataSourcesHelperText.SourceDatasetsCardTitle}
              isDataSourceUsageLoading={isDataSourceUsageLoading}
              usageDatasets={usageSourceDatasets}
              tab={tab}
            />
          )}
          {(usageDestinationDatasets || [])?.length > 0 && (
            <Datasets
              title={DataSourcesHelperText.DestinationDatasetsCardTitle}
              isDataSourceUsageLoading={isDataSourceUsageLoading}
              usageDatasets={usageDestinationDatasets}
              tab={tab}
            />
          )}
        </>
      )}
    </TabPanel>
  );
};

export default TabPanelWrapper;
