import React, { useEffect } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { isEmpty, isNil, size } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// Utils
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { payloadConditions } from "../utils/Segment.helpers";

// Open API
import { TrySegmentDto } from "openapi/Models";

// Components
import { Spinner } from "src/components";
import { Modal } from "src/components/custom";
import { useTestSegment } from "src/hooks/api/segments";
import ViewDataDataTable from "src/pages/ViewData/ViewDataData/ViewDataDataTable/ViewDataDataTable";

// Contexts
import { useSegmentContext } from "../context/useSegmentContext";

// Types
import { Segment } from "../Segment.type";

// Constants
import { SegmentFormFields, SegmentHelperText, TestSegmentModal } from "../utils/Segment.constants";

const useStyles = makeStyles((theme) => ({
  root: {
    rowGap: theme.spacing(2)
  },
  info: {
    marginLeft: "auto",
    fontStyle: "italic",
    opacity: 0.5
  }
}));

type Props = {
  onClose: () => void;
};

const TestModal = (props: Props) => {
  const { onClose } = props || {};

  const classes = useStyles();

  const { entityId: datasetId } = useParams() || {};

  const { datasetFeaturesDataTypeMapping } = useSegmentContext();

  const { getValues } = useFormContext();

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading,
    data,
    mutateAsync: testSegmentMutation,
    reset: resetTestSegmentMutation
  } = useTestSegment();
  // << ENDS - Query hooks

  useEffect(() => {
    // @ts-ignore
    const values: Segment = getValues();

    const _ = async () => {
      await resetTestSegmentMutation();

      const payload: TrySegmentDto = {
        entityId: datasetId,
        rowLimit: values?.[SegmentFormFields.RowLimit],
        condition: { expression: payloadConditions(values, datasetFeaturesDataTypeMapping) }
      };

      await testSegmentMutation(payload);
    };

    if (!!datasetId && areAllKeysPresentAndNotNil(values, [SegmentFormFields.Groups])) {
      _();
    }
  }, []);

  return (
    <Modal
      open
      size={!isNil(data) ? "xl" : "xs"}
      title={TestSegmentModal.title}
      onClose={onClose}
      hideCancelAction
      hideSubmitAction
      content={
        isLoading ? (
          <Spinner />
        ) : isNil(data) ? (
          <Alert severity="info" data-testid="segmentTestModalNoDataFoundInfo">
            {SegmentHelperText.NoDataFound}
          </Alert>
        ) : (
          <Grid container direction="column" className={classes.root}>
            {!isEmpty(data?.rows) && (
              <Typography
                variant="body2"
                className={classes.info}
                data-testid="segmentTestModalInfo">
                {`* showing first ${size(data?.rows)} record(s) from the segment data`}
              </Typography>
            )}
            <Box width="100%" overflow="auto">
              <ViewDataDataTable
                responseData={data}
                isLoadingData={!data}
                hiddenColumns={[]}
                entityFeatures={[]}
                noGraphics
              />
            </Box>
          </Grid>
        )
      }
    />
  );
};

export default TestModal;
