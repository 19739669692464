import React from "react";

const NotFoundIcon: React.FC = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.18787 14.5669C10.1394 14.5669 10.1426 11.6372 8.18787 11.6372C6.2363 11.6372 6.23318 14.5669 8.18787 14.5669Z"
        fill="#7C7C7C"
      />
      <path
        d="M13.4287 14.5669C15.3803 14.5669 15.3834 11.6372 13.4287 11.6372C11.4771 11.6372 11.474 14.5669 13.4287 14.5669Z"
        fill="#7C7C7C"
      />
      <path
        d="M18.6698 14.5669C20.6213 14.5669 20.6244 11.6372 18.6698 11.6372C16.7182 11.6372 16.7149 14.5669 18.6698 14.5669Z"
        fill="#7C7C7C"
      />
      <path
        d="M98.5352 6.05469H35.9359C34.042 6.05469 34.042 8.98438 35.9359 8.98438H97.0703V17.2201H2.92969V8.98438H28.1516C30.0455 8.98438 30.0455 6.05469 28.1516 6.05469H1.46484C0.655664 6.05469 0 6.71055 0 7.51953V92.4805C0 93.2895 0.655664 93.9453 1.46484 93.9453H98.5352C99.3443 93.9453 100 93.2895 100 92.4805V7.51953C100 6.71055 99.3443 6.05469 98.5352 6.05469ZM2.92969 91.0156V20.1498H97.0703V91.0156H2.92969Z"
        fill="#7C7C7C"
      />
      <path
        d="M20.1856 53.3652H29.6557C29.6434 55.1097 29.6299 56.8103 29.6166 58.347C29.601 60.1857 32.5684 60.3031 32.5854 58.3722C32.5902 57.7875 32.5676 55.8422 32.5854 53.3652H33.9287C35.8227 53.3652 35.8227 50.4355 33.9287 50.4355H32.6049C32.6387 44.9863 32.6621 38.6623 32.5959 37.8697C32.3637 35.0791 29.3389 34.0892 27.5274 36.3123C26.2791 37.8437 19.6541 49.831 18.9029 51.1928C18.5037 52.4051 18.9313 53.1291 20.1856 53.3652ZM29.6861 38.3121C29.7262 39.5248 29.7094 44.8594 29.675 50.4355H22.6719C25.3227 45.6717 28.7682 39.6002 29.6861 38.3121Z"
        fill="#7C7C7C"
      />
      <path
        d="M66.0713 53.3656H75.5416C75.5293 55.1102 75.5549 56.8108 75.5416 58.3475C75.526 60.1861 78.4152 60.3035 78.4322 58.3727C78.4371 57.7879 78.4535 55.8426 78.4713 53.3656H79.8145C81.7084 53.3656 81.7084 50.4359 79.8145 50.4359H78.4908C78.5246 44.9867 78.5481 38.6627 78.4818 37.8701C78.2496 35.0791 75.225 34.09 73.4133 36.3127C72.1651 37.8441 65.54 49.8315 64.7887 51.1932C64.3895 52.4055 64.817 53.1295 66.0713 53.3656ZM75.5723 38.3125C75.6123 39.5252 75.5955 44.8598 75.5611 50.4359H68.5578C71.2084 45.6725 74.6539 39.6012 75.5723 38.3125Z"
        fill="#7C7C7C"
      />
      <path
        d="M50 60.1495C51.7614 60.1495 53.4705 59.794 55.0803 59.0926C56.8168 58.3362 55.6465 55.6501 53.9104 56.4067C47.5666 59.2585 40.1172 54.3776 40.1975 47.4171C40.1975 42.012 44.5948 37.6145 50 37.6145C57.1803 37.5266 62.0643 45.4299 58.766 51.8038C57.9168 53.4967 60.5356 54.8098 61.3848 53.1171C65.6698 44.837 59.3278 34.5706 49.9998 34.685C33.1096 35.3839 33.1131 59.4524 50 60.1495Z"
        fill="#7C7C7C"
      />
      <path
        d="M54.024 43.3931C53.4518 42.8211 52.5244 42.8211 51.9523 43.3931L50 45.3453L48.0478 43.3931C46.7084 42.0541 44.6367 44.1254 45.9762 45.4646L47.9285 47.417L45.9762 49.3693C44.6369 50.7086 46.7084 52.7798 48.0478 51.4408L50 49.4886L51.9521 51.4408C53.2916 52.7798 55.3633 50.7086 54.0238 49.3693L52.0715 47.417L54.0238 45.4646C54.5959 44.8927 54.5959 43.9652 54.024 43.3931Z"
        fill="#7C7C7C"
      />
      <path
        d="M31.1186 67.2598C29.2246 67.2598 29.2246 70.1895 31.1186 70.1895H68.8815C70.7754 70.1895 70.7754 67.2598 68.8815 67.2598H31.1186Z"
        fill="#7C7C7C"
      />
      <path
        d="M82.9652 74.3418H17.0348C15.1408 74.3418 15.1408 77.2715 17.0348 77.2715H82.9652C84.8594 77.2715 84.8594 74.3418 82.9652 74.3418Z"
        fill="#7C7C7C"
      />
    </svg>
  );
};

export default NotFoundIcon;
