import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const PinnedIcon: React.FC<IProps> = (props) => {
  const {
    width = 13,
    height = 13,
    viewBox = "0 0 11 11",
    fill = "none",
    color = "#4646B5"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.07248 0.439441C7.39023 -0.242833 6.25053 -0.112799 5.73948 0.705631L4.31689 2.98391C4.31689 2.98391 4.31615 2.98516 4.31348 2.98707C4.31022 2.98941 4.30326 2.99344 4.29159 2.99673C4.26608 3.00391 4.23275 3.00335 4.20299 2.99232C3.71745 2.81232 2.884 2.61459 2.01853 2.94058C1.55224 3.11622 1.31956 3.52044 1.29421 3.93054C1.27002 4.32184 1.42805 4.72408 1.71871 5.01473L3.48646 6.78248L0.146446 10.1225C-0.0488155 10.3178 -0.0488155 10.6343 0.146446 10.8296C0.341707 11.0249 0.658292 11.0249 0.853552 10.8296L4.19357 7.48958L5.96133 9.25738C6.25198 9.54803 6.65423 9.70608 7.04553 9.68188C7.45563 9.65653 7.85988 9.42383 8.03548 8.95758C8.36148 8.09208 8.16373 7.25863 7.98378 6.77308C7.97273 6.74333 7.97218 6.71003 7.97933 6.68448C7.98263 6.67283 7.98668 6.66588 7.98903 6.66258C7.99093 6.65993 7.99213 6.65923 7.99213 6.65923L10.2704 5.23658C11.0889 4.72553 11.2189 3.58586 10.5366 2.90359L8.07248 0.439441ZM6.58773 1.23528C6.75808 0.962471 7.13798 0.919126 7.36538 1.14655L9.82953 3.6107C10.0569 3.83812 10.0136 4.21801 9.74078 4.38836L7.46253 5.81098C6.99018 6.10593 6.88503 6.68618 7.04613 7.12068C7.18903 7.50618 7.30303 8.06518 7.09968 8.60508C7.08578 8.64193 7.07033 8.65523 7.06083 8.66178C7.04873 8.67013 7.02488 8.68123 6.98383 8.68378C6.89508 8.68928 6.76833 8.65013 6.66843 8.55028L2.42581 4.30763C2.32594 4.20776 2.28681 4.08099 2.2923 3.99224C2.29484 3.95118 2.30597 3.92735 2.31432 3.91523C2.32086 3.90575 2.33416 3.89028 2.37101 3.8764C2.91093 3.67303 3.46989 3.78705 3.85539 3.92997C4.28992 4.09105 4.87013 3.98592 5.16513 3.51355L6.58773 1.23528Z"
        fill={color}
      />
      <path
        d="M7 9L1.5 4L2.6955 3.52941H4.3564L6.88235 0L10 3.5L10.4464 4.70588L7.6782 7.05882L7 9Z"
        fill={color}
      />
    </svg>
  );
};

export default PinnedIcon;
