import React from "react";

export const ClockIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00001 12.334C9.93334 12.334 12.3333 9.93398 12.3333 7.00065C12.3333 4.06732 9.93334 1.66732 7.00001 1.66732C4.06668 1.66732 1.66668 4.06732 1.66668 7.00065C1.66668 9.93398 4.06668 12.334 7.00001 12.334ZM7.00001 0.333984C10.6667 0.333984 13.6667 3.33398 13.6667 7.00065C13.6667 10.6673 10.6667 13.6673 7.00001 13.6673C3.33334 13.6673 0.333344 10.6673 0.333344 7.00065C0.333344 3.33398 3.33334 0.333984 7.00001 0.333984ZM7.33334 7.53398L4.13334 9.40065L3.66668 8.46732L6.33334 6.93398V3.66732H7.33334V7.53398Z"
      fill={color}
    />
  </svg>
);
