import React from "react";

export const SettingsIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 6} ${height + 6}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66675 10.7329V9.26621C1.66675 8.39954 2.37508 7.68287 3.25008 7.68287C4.75841 7.68287 5.37508 6.61621 4.61675 5.30787C4.18341 4.55787 4.44175 3.58287 5.20008 3.14954L6.64175 2.32454C7.30008 1.93287 8.15008 2.16621 8.54175 2.82454L8.63341 2.98287C9.38341 4.29121 10.6167 4.29121 11.3751 2.98287L11.4667 2.82454C11.8584 2.16621 12.7084 1.93287 13.3667 2.32454L14.8084 3.14954C15.5667 3.58287 15.8251 4.55787 15.3917 5.30787C14.6334 6.61621 15.2501 7.68287 16.7584 7.68287C17.6251 7.68287 18.3417 8.39121 18.3417 9.26621V10.7329C18.3417 11.5995 17.6334 12.3162 16.7584 12.3162C15.2501 12.3162 14.6334 13.3829 15.3917 14.6912C15.8251 15.4495 15.5667 16.4162 14.8084 16.8495L13.3667 17.6745C12.7084 18.0662 11.8584 17.8329 11.4667 17.1745L11.3751 17.0162C10.6251 15.7079 9.39175 15.7079 8.63341 17.0162L8.54175 17.1745C8.15008 17.8329 7.30008 18.0662 6.64175 17.6745L5.20008 16.8495C4.83683 16.6404 4.5714 16.2957 4.46204 15.891C4.35267 15.4864 4.40831 15.0549 4.61675 14.6912C5.37508 13.3829 4.75841 12.3162 3.25008 12.3162C2.37508 12.3162 1.66675 11.5995 1.66675 10.7329Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
