import React, { useCallback } from "react";
import { TableCell, TextField } from "@material-ui/core";
import Select from "src/components/Select/Select";

export const DefaultTableCell = ({
  cell,
  rowToBeEdited,
  children,
  size,
  updateMyData,
  secondaryData,
  ...restProps
}: any) => {
  const { column, row } = cell;
  if (column.isEditable && row.original.id === rowToBeEdited) {
    return (
      <TableCell size={size} {...restProps}>
        <EditableTableCell
          updateMyData={updateMyData}
          size={size}
          cell={cell}
          secondaryData={secondaryData}
          {...restProps}>
          {children}
        </EditableTableCell>
      </TableCell>
    );
  }
  return <TableCell {...restProps}>{children}</TableCell>;
};

const EditableTableCell = ({ cell, secondaryData, children, ...restProps }: any) => {
  const type = cell.column.type || "string";

  switch (type) {
    case "select":
      return (
        <EditableSelectField cell={cell} secondaryData={secondaryData} {...restProps}>
          {children}
        </EditableSelectField>
      );
    case "string":
    default:
      return <EditableTextField cell={cell} {...restProps} />;
  }
};

const EditableSelectField = ({ cell, updateMyData, secondaryData, children }: any) => {
  const {
    value: initialValue,
    row: {
      index: rowIndex,
      original: { id: rowId },
      original
    },
    column: {
      id: columnId,
      values,
      Header,
      hideSelectInputLabel = false,
      selectVariant = "filled",
      selectStyles = {}
    }
  } = cell;

  const [value, setValue] = React.useState(initialValue);

  let secondaryField: $TSFixMe = {};

  if ((secondaryData || [])?.length > 0) {
    secondaryField = secondaryData?.[rowIndex]?.[columnId] || {};
  }

  const onChange = (event: any) => {
    const updatedData = event.target.value;
    setValue(updatedData);
    updateMyData({ rowId: rowId || secondaryField?.id, columnId, value: updatedData, original });
  };

  if (secondaryData !== undefined) {
    if (!secondaryField?.isEditable) {
      return children;
    }
  }

  const renderSelectedValue = useCallback(
    (selectedValue: $TSFixMe) =>
      (values || secondaryField?.selectValues || [])?.find(
        (thisEntry: $TSFixMe) => thisEntry?.value === selectedValue
      )?.label || "",
    [values || secondaryField?.selectValues]
  );

  return (
    <Select
      variant={selectVariant}
      size="small"
      hideInputLabel={hideSelectInputLabel}
      required
      fullWidth
      values={values || secondaryField?.selectValues || []}
      label={Header}
      value={value}
      onChange={onChange}
      fixedMenuHeight={true}
      renderValue={renderSelectedValue}
      className={selectStyles}
    />
  );
};

const EditableTextField = ({ cell, size, updateMyData }: any) => {
  const {
    value: initialValue,
    row: {
      original: { id: rowId },
      original
    },
    column: { id: columnId, Header }
  } = cell;
  const [value, setValue] = React.useState(initialValue);
  const onChange = (event: any) => {
    const updatedData = event.target.value;
    setValue(updatedData);
    updateMyData({ rowId, columnId, value: updatedData, original });
  };

  return (
    <TextField
      id={columnId}
      variant="outlined"
      label={Header}
      value={value}
      onChange={onChange}
      size={size}
    />
  );
};
