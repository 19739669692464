import React, { Dispatch, SetStateAction } from "react";

import { UseFormReturn } from "react-hook-form";

import { GlobalVariableDto, ProjectDashboardDto } from "openapi/Models";

import { Scenario } from "../Scenario.type";

type ContextTypes = {
  isLoading?: boolean;
  isPendingSegments?: boolean;
  isPendingVariables?: boolean;
  isReadOnly?: boolean;

  project: ProjectDashboardDto;
  isRecipeInProject?: boolean;

  globalVariablesData?: GlobalVariableDto[];

  formMethods: UseFormReturn<Scenario, unknown, undefined>;

  isSavingScenario?: boolean;
  isRunning?: boolean;
  saveScenario: () => void;
  updateScenario: () => void;
  onRun: () => void;
  onDisplayOnDag: () => void;

  isAttemptedSubmit?: boolean;
  setIsAttemptedSubmit: Dispatch<SetStateAction<boolean>>;
};

// @ts-ignore
export const ScenarioContext = React.createContext<ContextTypes>();
