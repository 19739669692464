import React, { useEffect, useState, useMemo, useRef } from "react";

import { Grid, Divider, makeStyles } from "@material-ui/core";

import { getSmoothStepPath } from "react-flow-renderer";

import {
  // schemaOptionsSupportedFileTypes,
  FileUploadStatuses
} from "../../../utils/Dataset.constants";

import FileMetaData from "./FileMetaData";
import FileUploadContainer from "./FileUploadContainer";

import { FileControlPropsType } from "../../../types/Props.type";

enum Colors {
  Orange = "#f1957d",
  Blue = "#4646b5",
  Ash1 = "#f2f2ff",
  Ash2 = "#c7c7c7",
  LightGreen = "#f6fff7",
  Red = "#da1d27",
  LightRed = "#ffeeee"
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: (styleProps: $TSFixMe) => styleProps.rootMarginBottom,
    padding: 10,
    border: "1px solid",
    borderColor: (styleProps: $TSFixMe) => styleProps.rootBorderColor,
    borderRadius: 4,
    backgroundColor: (styleProps: $TSFixMe) => styleProps.rootBackgroundColor,
    "& .fileMetaDataContainer": { width: "67%" },
    "& .dividerContainer": {
      width: "3%",
      "& [class^='MuiDivider-root']": { marginLeft: theme.spacing(1) }
    },
    "& .fileUploadContainer": { width: "30%" }
  },
  connectorLine: {
    position: "absolute",
    left: "100%",
    width: "auto",
    overflow: "visible",
    "& path": {
      stroke: "#f1957d !important"
    }
  }
}));

const FileControl = (props: FileControlPropsType) => {
  const {
    index,
    fileControlContainerRef,
    datasetControlContainerRef,
    datasetFormControlRef,
    datasetFieldRef,
    connectorLineRerenderTimer,
    file,
    isDataSourcesFilesUpload, // optional
    reUpload,
    deleteFile,
    isLastFileControlContainer // optional
  } = props || {};

  const [styleProps, setStyleProps] = useState({
    // Doing strict boolean equality comparison as isLastFileControlContainer is an optional type.
    rootMarginBottom: 16,
    rootBorderColor: Colors.Blue,
    rootBackgroundColor: Colors.Ash1
  });

  const classes: $TSFixMe = useStyles(styleProps);

  // Refs - STARTS >>
  const fileControlRef = useRef<$TSFixMe>(null);
  // << ENDS - Refs

  // Styling - STARTS >>
  useEffect(() => {
    const thisStyleProps = styleProps;

    if (
      [FileUploadStatuses.Success, FileUploadStatuses.Failed, FileUploadStatuses.Deleting].includes(
        file?.status
      )
    ) {
      thisStyleProps.rootBorderColor = Colors.Ash2;

      if (file?.status === FileUploadStatuses.Success) {
        thisStyleProps.rootBackgroundColor = Colors.LightGreen;
      } else {
        thisStyleProps.rootBorderColor = Colors.Red;
        thisStyleProps.rootBackgroundColor = Colors.LightRed;
      }
    } else {
      thisStyleProps.rootBorderColor = Colors.Blue;
      thisStyleProps.rootBackgroundColor = Colors.Ash1;

      if (
        // schemaOptionsSupportedFileTypes.includes(file?.type?.toLowerCase()) &&
        [FileUploadStatuses.SignedUrl].includes(file?.status)
      ) {
        thisStyleProps.rootBorderColor = Colors.Ash2;
        thisStyleProps.rootBackgroundColor = Colors.LightGreen;
      }
    }

    setStyleProps({ ...thisStyleProps });
  }, [isLastFileControlContainer, file]);
  // << ENDS - Styling

  const connectorLine = useMemo(() => {
    // Circle >>
    const circle = { cx: 3, radius: 2.5 };
    // << Circle

    const sourceX = circle.cx + circle.radius;
    const sourceY =
      (fileControlRef?.current?.offsetTop || 0) + fileControlRef?.current?.offsetHeight / 2;

    const targetX =
      (datasetControlContainerRef?.offsetLeft || 0) -
      ((fileControlContainerRef?.offsetLeft || 0) + (fileControlContainerRef?.offsetWidth || 0));

    const targetY = (datasetFormControlRef?.offsetTop || 0) + datasetFieldRef?.offsetHeight / 2;

    if (!sourceY || !targetX || !targetY) {
      return <></>;
    }

    const edgePath = getSmoothStepPath({
      // @ts-ignore
      sourcePosition: "right",
      // @ts-ignore
      targetPosition: "left",

      sourceX,
      sourceY,

      targetX: targetX - 1,
      targetY,

      borderRadius: 12
    });

    return (
      <svg
        id={`connectorLine_${index}`}
        className={classes.connectorLine}
        width="100%"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <circle
            cx={circle.cx}
            cy={sourceY || 0}
            r={circle.radius}
            fill="white"
            stroke={Colors.Orange}></circle>
          <path className="react-flow__edge-path" d={edgePath} stroke={Colors.Orange} />
        </g>
      </svg>
    );
  }, [
    fileControlRef?.current,

    datasetFormControlRef,
    datasetFieldRef,

    styleProps,

    connectorLineRerenderTimer
  ]);

  return (
    <>
      <Grid container className={classes.root} ref={fileControlRef}>
        <Grid item className="fileMetaDataContainer">
          <FileMetaData
            index={index}
            file={file}
            isDataSourcesFilesUpload={isDataSourcesFilesUpload}
          />
        </Grid>
        <Grid item className="dividerContainer">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item className="fileUploadContainer">
          <FileUploadContainer
            index={index}
            file={file}
            reUpload={reUpload}
            deleteFile={deleteFile}
            isDataSourcesFilesUpload={isDataSourcesFilesUpload}
          />
        </Grid>
      </Grid>
      {connectorLine}
    </>
  );
};

export default FileControl;
