import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

import NotFoundIcon from "src/icons/NotFoundIcon";
import Result from "./Result";
import { WebPaths } from "src/routing/routes";

interface IProps {
  setNoRecord: Dispatch<SetStateAction<boolean>>;
}

const RecordNotFound: React.FC<IProps> = ({ setNoRecord }) => {
  const navigate = useNavigate();
  const [canGoBack, setCanGoBack] = useState(true);

  const location = useLocation();

  useEffect(() => {
    setCanGoBack(history.state && history.state.idx !== 0);
  }, [location]);

  const handleGoBack = () => {
    setNoRecord(false);
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate(WebPaths.Dashboard);
    }
  };

  return (
    <Result
      icon={<NotFoundIcon />}
      heading="Record not found"
      subtitle1="Oops! It looks like the entity you are trying to access does not exist or has been moved."
      extra={
        <Button variant="contained" onClick={handleGoBack}>
          {canGoBack ? "Go Back" : "Go to Dashboard"}
        </Button>
      }
    />
  );
};

export default RecordNotFound;
