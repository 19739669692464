/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AutoMlProblemTypesResponse
 */
export interface AutoMlProblemTypesResponse {
    /**
     * 
     * @type {string}
     * @memberof AutoMlProblemTypesResponse
     */
    'name'?: AutoMlProblemTypesResponseNameEnum;
    /**
     * 
     * @type {string}
     * @memberof AutoMlProblemTypesResponse
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof AutoMlProblemTypesResponse
     */
    'templateName'?: string;
}

export const AutoMlProblemTypesResponseNameEnum = {
    BinaryClassification: 'BINARY_CLASSIFICATION',
    BinaryClassificationGpt: 'BINARY_CLASSIFICATION_GPT',
    Regression: 'REGRESSION',
    MulticlassClassification: 'MULTICLASS_CLASSIFICATION',
    TimeseriesForecasting: 'TIMESERIES_FORECASTING',
    AnomalyDetection: 'ANOMALY_DETECTION',
    Clustering: 'CLUSTERING'
} as const;

export type AutoMlProblemTypesResponseNameEnum = typeof AutoMlProblemTypesResponseNameEnum[keyof typeof AutoMlProblemTypesResponseNameEnum];


