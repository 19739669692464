import React, { useState } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

import GenerateEdaChartsIcon from "src/icons/GenerateEdaChartsIcon";
import MagicGenerateIcon from "src/icons/MagicGenerateIcon";
import GradientButton from "components/Buttons/GradientButton";

import useAuthStore from "src/stores/auth.store";

import { AskAiA11yBackdrop } from "src/components/custom";
import { EDA_CHART_LIMIT } from "./EdaCharts";

const useStyles = makeStyles({
  flexContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    boxShadow: "0px 4px 4px 0px #00000040",
    background: "#fff",
    padding: "20px",
    alignItems: "center",

    "& button": {
      color: "#fff !important"
    }
  },
  subText: {
    fontSize: 12,
    fontWeight: 400,
    color: "#7c7c7c",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
});

interface IProps {
  isLoading: boolean;
  onGenerate: (limit: number) => void;
}

const EdaChartsDefault: React.FC<IProps> = (props) => {
  const { flexContainer, subText } = useStyles();

  const askAiEnabled = useAuthStore((state) => state.askAiEnabled);

  const [askAiA11yChecked, setAskAiA11yChecked] = useState(false);

  return (
    <div className={flexContainer}>
      {askAiA11yChecked && !askAiEnabled && <AskAiA11yBackdrop />}

      <GenerateEdaChartsIcon />
      <div className={subText}>
        {props.isLoading ? (
          <>
            <div>Data Analysis Charts are getting Generated</div>
            <div>
              Please be patient while the charts are being prepared for display. Feel free to
              navigate to other pages and we shall update you once charts are ready
            </div>
          </>
        ) : (
          "Generate visual representations based on the dataset to enhance data analysis insights"
        )}
      </div>
      <GradientButton
        variant="contained"
        disabled={props.isLoading}
        startIcon={
          props.isLoading ? (
            <CircularProgress size={16} style={{ color: "#fff" }} />
          ) : (
            <MagicGenerateIcon />
          )
        }
        onClick={() => {
          setAskAiA11yChecked(() => true);
          !!askAiEnabled && props.onGenerate(EDA_CHART_LIMIT);
        }}>
        {props.isLoading ? "Generating Charts" : "Generate Charts"}
      </GradientButton>
    </div>
  );
};

export default EdaChartsDefault;
