import React from "react";
import ViewDataDataTable from "./ViewDataDataTable/ViewDataDataTable";

const ViewDataData = ({
  responseData,
  isLoadingData,
  entityFeatures,
  showPagination,
  hideCount,
  columnOptionsCustomRender,
  onColumnChange,
  maxHeight,
  globalFilter,
  showSample
}: $TSFixMe) => {
  return (
    <section style={{ overflow: "auto" }}>
      <ViewDataDataTable
        responseData={responseData}
        isLoadingData={isLoadingData}
        entityFeatures={entityFeatures}
        hideCount={hideCount}
        showPagination={showPagination}
        columnOptionsCustomRender={columnOptionsCustomRender}
        onColumnChange={onColumnChange}
        maxHeight={maxHeight}
        globalFilter={globalFilter}
        showSample={showSample}
      />
    </section>
  );
};

export default React.memo(ViewDataData);
