export enum ScenarioConfig {
  ScenarioNameFontSize = 14,
  VariableValueMaxLength = 255
}

export enum ScenarioFormFields {
  Name = "name",
  Datasets = "datasets",
  Variables = "variables"
}

export const defaultDatasets = [];

export const defaultVariables = [
  {
    key: "",
    value: ""
  }
];

export const defaultValues = {
  [ScenarioFormFields.Name]: "",
  [ScenarioFormFields.Datasets]: defaultDatasets,
  [ScenarioFormFields.Variables]: []
};

export enum ScenarioLeavePagePromptDetails {
  title = "Confirm",

  messageLine1 = "If you leave you will lose any unsaved changes.",
  messageLine2 = "Do you still want to navigate away?"
}

export enum ScenarioUpdatePromptDetails {
  title = "Update Scenario",

  messageLine1 = "If you make any changes to the segment(s) associated with this custom scenario, it will invalidate the existing canvas connections for this scenario. Re-run this scenario for changes to take effect.",
  messageLine2 = "Are you sure you want to update?",

  submitLabel = "Yes, Update"
}

export enum ScenarioDeletePromptDetails {
  title = "Delete Scenario",

  messageLine1 = "Do you really want to delete this scenario?",
  messageLine2 = "If you confirm you will delete the scenario completely!"
}

export enum ScenarioHelperText {
  AddSegments = "Add Segments",

  CreateScenario = "Create Scenario",

  NoSegmentsInfo = "No Segments present in this project!",

  AddVariable = "Add Variable",
  AddVariableInfo = "Set values of Global Variables which will be used during scenario execution",
  NoGlobalVariablesInfo = "No Global Variables present in this project!",

  Key = "Key",
  Value = "Value",

  Run = "Run",
  CanvasView = "Canvas View",

  Cancel = "Cancel",
  Save = "Save",
  Update = "Update",
  Delete = "Delete",

  UntitledScenario = "Untitled Scenario",

  NoSearchedSegmentsFoundInfo = "No matching search results found!",

  ScenarioCreated = "Scenario created successfully!",
  ScenarioUpdated = "Scenario updated successfully!",
  ScenarioNameUpdated = "Scenario name updated successfully!",
  ScenarioDeleted = "Scenario deleted successfully!",

  Project = "Project",
  Scenarios = "Scenarios",
  Scenario = "Scenario"
}
