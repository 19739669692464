import React, { useRef, useState, useEffect, ReactNode } from "react";
import { Box, Button, Typography, withStyles } from "@material-ui/core";

import { useToastsStore } from "src/store/store";
import { toastContentSetter } from "src/store/store.selectors";

const StyledButton = withStyles(() => ({
  root: {
    background: "white",
    fontSize: "14px",
    textTransform: "none",
    fontWeight: 400,
    color: "#008FE4",
    padding: "3px 12px"
  }
}))(Button);

interface IProps {
  isError: boolean;
  children: React.ReactNode;
}

export const TOAST_TIMEOUT = 10000;

const StyledBox = withStyles<string, any, IProps>(() => ({
  root: (props) =>
    props.isError
      ? {
          maxHeight: "38px",
          display: "-webkit-box",
          "-webkit-line-clamp": 2,
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      : {
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
}))((props: IProps) => {
  const { isError, children, ...rest } = props;
  return <Box {...rest}>{children}</Box>;
});

const ActionsBox = withStyles(() => ({
  root: {
    marginTop: "16px",
    display: "flex",
    gap: "16px"
  }
}))(Box);

export type Action = {
  label: string;
  action: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

type ContentProps = {
  title?: string | ReactNode;
  content: string | ReactNode;
  actions?: Action[];
  type: "info" | "warn" | "error" | "success";
};

const ToastContent = ({ title, content, actions, type }: ContentProps) => {
  // Stores - STARTS >>
  const setToastContentStore = useToastsStore(toastContentSetter);
  // << ENDS - Stores

  const contentRef = useRef<HTMLParagraphElement | null>(null);
  const [isContentClamped, setIsContentClamped] = useState<boolean>(false);

  useEffect(() => {
    if (contentRef?.current) {
      setIsContentClamped(
        contentRef.current.scrollHeight > contentRef.current.clientHeight ||
          contentRef.current.clientHeight > 40 // In firefox, scrollheight and clientheight is same, little hacky but that seems to be the only way
      );
    }
  }, []);

  return (
    <div>
      {title && <Typography>{title}</Typography>}
      <StyledBox isError={type === "error"}>
        <Typography innerRef={contentRef} variant="body2">
          {content}
        </Typography>
      </StyledBox>
      {(actions || isContentClamped) && (
        <ActionsBox>
          {actions &&
            actions?.map((item, index) => (
              <StyledButton key={index} onClick={item?.action}>
                {item?.label}
              </StyledButton>
            ))}
          {type === "error" && isContentClamped && (
            <StyledButton
              test-id="toast-view-more-button"
              onClick={() => setToastContentStore(content)}>
              View More
            </StyledButton>
          )}
        </ActionsBox>
      )}
    </div>
  );
};

export default ToastContent;
