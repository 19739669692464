import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { TextField, Chip, Box, Grid, Checkbox, ListItemText, Popper } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { FixedSizeList } from "react-window";
import useOutsideClick from "src/hooks/useOutsideClick";
import OverflowTooltip from "../OverflowTooltip";
import { toString } from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 120,
    margin: 0,
    background: "#fff"
  },
  selectedItems: {
    gap: "8px",
    marginTop: "8px"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1)
  },
  autoCompleteBox: {
    gap: "12px"
  },
  option: {},
  listbox: {
    maxHeight: "250px",
    overflow: "auto"
  },
  paper: {
    padding: 0
  },
  inputRoot: {
    paddingTop: "2px !important",
    paddingBottom: "2px !important"
  },
  input: {
    padding: "12px 0px !important"
  },
  helperText: {
    margin: "4px 0px"
  },
  popper: {
    top: "75px !important"
  }
}));

interface Option {
  label: string | number;
  value: string;
}

interface MultiSelectAutocompleteProps
  extends Omit<
    AutocompleteProps<Option, true, false, false>,
    "renderInput" | "options" | "value" | "onChange"
  > {
  options: Option[];
  value: Option[];
  onChange: (value: Option[]) => void;
  placeholder?: string;
  label?: string;
  InputProps?: any;
  textFieldProps?: any;
}

const ITEM_HEIGHT = 54;
const MAX_VISIBLE_ITEMS = 5;

const VirtualizedAutocompleteWithChips: React.FC<MultiSelectAutocompleteProps> = ({
  options,
  value,
  onChange,
  placeholder,
  label,
  InputProps,
  textFieldProps = {},
  ...restProps
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDelete = (item: string) => {
    onChange(value.filter((i) => i.value !== item));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClickOutside = () => {
    handleClose();
  };

  const ref = useOutsideClick(handleClickOutside);

  const renderVirtualizedList = (listboxProps: any) => {
    const itemCount = options.length;

    const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const option = options[index];
      const selected = value.some((val) => val.value === option.value);

      const onRowClick = () => {
        onChange(
          selected ? value.filter((currOption) => currOption !== option) : [...value, option]
        );
      };
      return (
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          style={style}
          key={option.value}
          onClick={onRowClick}
          {...listboxProps}>
          <Checkbox checked={selected} size="small" />
          <ListItemText
            primary={
              <OverflowTooltip title={toString(option.label)} value={toString(option.label)} />
            }
            style={{ padding: 0 }}
          />
        </Grid>
      );
    };

    return (
      <FixedSizeList
        height={Math.min(itemCount, MAX_VISIBLE_ITEMS) * ITEM_HEIGHT}
        width="100%"
        itemSize={ITEM_HEIGHT}
        itemCount={itemCount}>
        {Row}
      </FixedSizeList>
    );
  };

  const renderPopperComponent = (props: any) => {
    return (
      <Popper
        {...props}
        disablePortal
        ref={ref}
        placement="bottom-start"
        className={classes.popper}
      />
    );
  };

  return (
    <Grid container direction="column" className={classes.autoCompleteBox} innerRef={ref}>
      <Autocomplete
        multiple
        PopperComponent={renderPopperComponent}
        options={options}
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        classes={{
          listbox: classes.listbox,
          inputRoot: classes.inputRoot,
          paper: classes.paper,
          input: classes.input,
          popper: classes.popper
        }}
        getOptionLabel={(option) => option.label.toString()}
        renderTags={() => <React.Fragment />}
        renderOption={(option, { selected }) => (
          <Grid container direction="row" wrap="nowrap">
            <Checkbox checked={selected} size="small" />
            <ListItemText primary={option.label} />
          </Grid>
        )}
        ListboxComponent={renderVirtualizedList}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            FormHelperTextProps={{
              className: classes.helperText
            }}
            InputProps={{ ...params.InputProps, ...InputProps }}
            variant="outlined"
            label={label}
            placeholder={placeholder}
          />
        )}
        {...restProps}
      />
      <Box className={classes.chips}>
        {value.map((option) => (
          <Chip
            variant="outlined"
            color="primary"
            key={option.value}
            label={option.label}
            onDelete={() => handleDelete(option.value)}
            deleteIcon={<ClearIcon fontSize="small" />}
          />
        ))}
      </Box>
    </Grid>
  );
};

export { VirtualizedAutocompleteWithChips };
