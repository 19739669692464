import React from "react";

export const RedshiftImage = ({ width = "100%", height = "100%" }: $TSFixMe) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 263 290"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4077_108888)">
      <path d="M131.392 221.731L241.761 247.988V41.4263L131.392 67.6835V221.731Z" fill="#205B97" />
      <path
        d="M241.761 41.4263L262.783 51.9292V237.486L241.761 247.988V41.4263ZM131.392 221.731L21.0226 247.988V41.4263L131.392 67.6835V221.731Z"
        fill="#5193CE"
      />
      <path d="M21.0227 41.4263L0 51.9292V237.486L21.0227 247.988V41.4263Z" fill="#205B97" />
      <path
        d="M159.427 290L201.473 268.994V21.0058L159.427 0L146.573 137.708L159.427 290Z"
        fill="#5193CE"
      />
      <path
        d="M103.356 290L61.3107 268.994V21.0058L103.356 0L116.21 137.708L103.356 290Z"
        fill="#205B97"
      />
      <path d="M103.356 0H159.997V289.415H103.356V0Z" fill="#2E73B7" />
    </g>
    <defs>
      <clipPath id="clip0_4077_108888">
        <rect width="262.783" height="290" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
