import React, { useMemo } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { includes } from "lodash";

// Utils
import { ToastTypes, toastWrapper } from "src/utils/toastWrapper";
import { areAllKeysPresentAndNotNil } from "src/utils/helpers";
import { payloadConditions } from "../utils/Segment.helpers";

// Open API
import { UpdateSegmentDto } from "openapi/Models";

// Components
import { Modal } from "src/components/custom";
import { useUpdateSegment } from "src/hooks/api/segments";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Types
import { Segment } from "../Segment.type";

// Contexts
import { useSegmentContext } from "../context/useSegmentContext";

// Constants
import {
  UpdateSegmentPromptDetails,
  SegmentFormFields,
  SegmentHelperText
} from "../utils/Segment.constants";

type Props = {
  onClose: () => void;
};

const UpdateModal = (props: Props) => {
  const { onClose } = props || {};

  const { segmentId } = useParams() || {};

  const { resetForm, datasetFeaturesDataTypeMapping } = useSegmentContext();

  const { getValues } = useFormContext();

  // @ts-ignore
  const values: Segment = useMemo(() => getValues(), [getValues()]);

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading,
    mutateAsync: updateSegmentMutation,
    reset: resetUpdateSegmentMutation
  } = useUpdateSegment();
  // << ENDS - Query hooks

  const updateSegment = async () => {
    if (
      !segmentId ||
      !areAllKeysPresentAndNotNil(values, [SegmentFormFields.Name, SegmentFormFields.Groups])
    ) {
      return;
    }

    await resetUpdateSegmentMutation();

    const payload: UpdateSegmentDto = {
      id: segmentId,
      name: values?.[SegmentFormFields.Name],
      description: values?.[SegmentFormFields.Description],
      rowLimit: values?.[SegmentFormFields.RowLimit],
      condition: { expression: payloadConditions(values, datasetFeaturesDataTypeMapping) }
    };

    await updateSegmentMutation(payload, {
      onSuccess: (data) => {
        toastWrapper({ type: ToastTypes.Success, content: SegmentHelperText.SegmentUpdated });
        resetForm(data);
      },
      onSettled: () => onClose()
    });
  };

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={UpdateSegmentPromptDetails.title}
      content={[UpdateSegmentPromptDetails.messageLine1, UpdateSegmentPromptDetails.messageLine2]}
      submitLabel={UpdateSegmentPromptDetails.submitLabel}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          onClose();
        }
      }}
      onSubmit={updateSegment}
      isCancelDisabled={isLoading}
      isSubmitDisabled={isLoading}
      isSubmitting={isLoading}
      hideCloseIcon
    />
  );
};

export default UpdateModal;
