import shallow from "zustand/shallow";
import { useMutation } from "@tanstack/react-query";

import { patchAPIWithRethrow } from "src/utils/apiService";
import useAuthStore from "src/stores/auth.store";

type Props = {
  name: string;
  cpuGuarantee?: number;
  memGuarantee?: number;
  dataappsTtlInHrs?: number;
  isForNotebookSizeUpdate?: boolean;
};

type Response = {
  name: string;
  cpuGuarantee: number;
  memGuarantee: number;
  dataappsTtlInHrs: number;
};

export const usePatchTenantMutation = () => {
  const [
    cpuGuarantee,
    memGuarantee,
    dataappsTtlInHrs,
    setMemGuarantee,
    setCpuGuarantee,
    setDataappsTtlInHrs
  ] = useAuthStore(
    (state) => [
      state.cpuGuarantee,
      state.memGuarantee,
      state.dataappsTtlInHrs,
      state.setMemGuarantee,
      state.setCpuGuarantee,
      state.setDataappsTtlInHrs
    ],
    shallow
  );
  return useMutation({
    mutationFn: async (props: Props) => {
      if (props.isForNotebookSizeUpdate) {
        return await patchAPIWithRethrow("/tenants/current/notebook", {
          cpuGuarantee: props.cpuGuarantee,
          memGuarantee: props.memGuarantee
        });
      }

      return await patchAPIWithRethrow(`/tenants/current`, {
        cpuGuarantee,
        memGuarantee,
        dataappsTtlInHrs,
        ...props
      });
    },
    onSuccess: (data: Response) => {
      setMemGuarantee(data.memGuarantee);
      setCpuGuarantee(data.cpuGuarantee);
      setDataappsTtlInHrs(data.dataappsTtlInHrs);
    }
  });
};
