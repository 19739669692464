import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: $TSFixMe) => ({
  container: {
    display: "flex",
    alignItems: "center",
    columnGap: 15,
    padding: 20,
    "& div[class^='MuiFormControl-root']": {
      width: "100%"
    },
    "& div[class^='MuiSelect-root']": {
      paddingTop: 9.5,
      paddingBottom: 9.5
    }
  },
  textField: {
    "& .MuiInputBase-input": {
      paddingTop: 9.5,
      paddingBottom: 9.5
    },
    "& .MuiFilledInput-root": {
      borderRadius: 4,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent"
      },
      "&.Mui-focused>div": {
        backgroundColor: "transparent"
      }
    }
  },
  selectDropdownContainer: {
    maxHeight: 250
  }
}));

export { useStyles };
