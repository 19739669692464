import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import axios from "axios";
import _ from "lodash";

import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_DOWNLOAD_SIGNED_URL = "query-key-download-signed-url";

const useDownloadSignedUrl = (name?: string, options?: UseQueryOptions): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_DOWNLOAD_SIGNED_URL, name],
    queryFn: async () => {
      const { signedUrl } = await getAPIWithRethrow(
        `/v2/prediction-services/download-signed-url?path=${name}/${name}.py&folder=predictions`
      );

      const results = await axios.get(signedUrl);

      return _.isString(results.data) ? results.data : null;
    },
    ...options
  });
};

export default useDownloadSignedUrl;
export { QUERY_KEY_DOWNLOAD_SIGNED_URL };
