import React, { useState } from "react";
import _ from "lodash";
import { Grid, makeStyles } from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import NewThemeWrapper from "src/styles/NewThemeWrapper";
import SampleSyntaxIcon from "src/icons/NewUX/SampleSyntaxIcon";
import SyntaxList from "./SyntaxList";
import { CodeRecipeCodeEditor } from "../../AddCodeRecipe/CodeRecipeTabContainer/CodeRecipeCodeEditor/CodeRecipeCodeEditor";
import { SNIPPETS } from "../utils/ApiConnectorRecipe.constants";

interface IProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  gridItem: {
    width: "300px",
    maxHeight: "calc(100vh - 200px)",
    overflow: "auto",
    paddingRight: "8px",
    borderRight: "1px solid #dedada",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",

    "&::-webkit-scrollbar": {
      display: "none"
    }
  }
});

interface ISelected {
  title: string;
  key: string;
  code: string;
  tooltip: string;
}

const SyntaxModal: React.FC<IProps> = (props) => {
  const { open, onClose } = props;
  const [selected, setSelected] = useState<ISelected>({
    ..._.get(SNIPPETS, "Generating Chart and Dataset")[0],
    key: "Generating Chart and Dataset"
  });
  const [isCopied, setIsCopied] = useState(false);

  const classes = useStyles();

  const handleCopy = () => {
    navigator.clipboard.writeText(selected.code);
    setIsCopied(true);

    setTimeout(() => setIsCopied(false), 5000);
  };

  const handleChange = (newVal: ISelected) => {
    setSelected(newVal);
    setIsCopied(false);
  };

  return (
    <NewThemeWrapper>
      <NewModal
        header={
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <SampleSyntaxIcon color="black" width={17} height={17} /> Sample Syntax
          </div>
        }
        open={open}
        data-testid="api-connectorsample-syntax"
        width={980}
        background="#fff"
        keepMounted={false}
        cancelButtonLabel="Close"
        submitDisabled={isCopied}
        submitButtonLabel={isCopied ? "Copied" : "Copy"}
        onClose={onClose}
        onFinish={handleCopy}>
        <Grid container>
          <Grid item xs="auto" className={classes.gridItem}>
            <SyntaxList value={selected.title} onChange={handleChange} />
          </Grid>
          <Grid
            item
            xs
            style={{ height: "calc(100vh - 200px)", overflowX: "hidden", overflowY: "auto" }}>
            <CodeRecipeCodeEditor
              editorValue={selected.code}
              setEditorValue={(val: string) => setSelected((prev) => ({ ...prev, code: val }))}
            />
          </Grid>
        </Grid>
      </NewModal>
    </NewThemeWrapper>
  );
};

export default SyntaxModal;
