import { getAPI } from "../../utils/apiService";

export const getDataLabelsByConfigId = async (decisionConfigId: $TSFixMe) =>
  await getAPI("/data-labels?decisionConfigId=" + decisionConfigId);

export const getDecisionConfigsById = async (decisionConfigId: $TSFixMe) =>
  await getAPI("/decision-configs/" + decisionConfigId);

export const getStrategiesByConfigId = async (decisionConfigId: $TSFixMe) =>
  await getAPI("/strategies?decisionConfigId=" + decisionConfigId);

export const getDecisionPointByConfigId = async (decisionConfigId: $TSFixMe) =>
  await getAPI("/decision-points?decisionConfigId=" + decisionConfigId);

export const getActionsByConfigId = async (decisionConfigId: $TSFixMe) =>
  await getAPI("/actions?decisionConfigId=" + decisionConfigId);

export const getDecisionConfigs = async (live: $TSFixMe) =>
  await getAPI("/decision-configs" + (live ? "?live=true" : ""));
