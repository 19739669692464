import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { join, map, size } from "lodash";

import useAppendFileName from "src/hooks/api/entities/useAppendFileName";

interface IProps {
  datasetId?: string;
}

const AppendFileName: React.FC<IProps> = ({ datasetId }) => {
  const appendFileNameResult = useAppendFileName(datasetId, {
    enabled: !!datasetId,
    refetchOnMount: true
  });

  const { fileName, count } = useMemo(() => {
    if (appendFileNameResult.isSuccess) {
      const files = map(appendFileNameResult.data, "name");
      const count = size(files);
      const fileName = join(files, ", ");
      return { fileName, count };
    }
    return { fileName: undefined, count: 0 };
  }, [appendFileNameResult.data, appendFileNameResult.isSuccess]);

  if (fileName)
    return (
      <Grid xs={6} style={{ textAlign: "center", wordBreak: "break-all" }}>
        {`Existing File Name${count > 1 ? "s" : ""}: ${fileName}`}
      </Grid>
    );
  return <></>;
};

export default AppendFileName;
