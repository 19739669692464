import { Checkbox, Tooltip } from "@material-ui/core";
import _, { includes } from "lodash";
import React, { useMemo } from "react";

import { CONNECTOR_KEYS } from "../../../OutputDataset/utils/OutputDataset.constants";
import { DEFAULT_FILENAME } from "../../utils/Jobs.constants";
import { Field } from "src/components";
import { IDestination } from "../JobDestinations/JobDestinations";
import { useStyles } from "./JobDestination.styles";

interface IProps {
  destination: IDestination;
  index: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FileBasedConnectors: React.FC<IProps> = (props) => {
  const { destination, onCheckboxChange, onChange } = props;
  const classes = useStyles();

  const checked = useMemo(
    () => includes(destination?.enteredOptions?.outputFileName, DEFAULT_FILENAME),
    [destination.enteredOptions]
  );

  return (
    <>
      <Field
        label={`* ${CONNECTOR_KEYS.fileCategory.destinationFolder.label}`}
        name={CONNECTOR_KEYS.fileCategory.destinationFolder.key}
        value={_.get(
          destination?.enteredOptions,
          CONNECTOR_KEYS.fileCategory.destinationFolder.key
        )}
        variant="outlined"
        size="small"
        error={
          destination?.isSubmitted &&
          !_.get(destination?.enteredOptions, CONNECTOR_KEYS.fileCategory.destinationFolder.key)
        }
        onChange={onChange}
      />
      <Field
        id="destination-filename"
        required
        data-testid="destination-filemane"
        label={`* ${CONNECTOR_KEYS.fileCategory.destinationFileName.label}`}
        name={CONNECTOR_KEYS.fileCategory.destinationFileName.key}
        value={_.get(
          destination?.enteredOptions,
          CONNECTOR_KEYS.fileCategory.destinationFileName.key
        )}
        variant="outlined"
        size="small"
        error={
          destination?.isSubmitted &&
          !_.get(destination?.enteredOptions, CONNECTOR_KEYS.fileCategory.destinationFileName.key)
        }
        onChange={onChange}
      />
      <div className={classes.checkbox}>
        <Tooltip
          title="Please note that the destination file will be overwritten after each run. To create
    new file for every run, select this checkbox. This will append the run id for every
    file output.">
          <Checkbox
            checked={checked}
            style={{ padding: 0, marginTop: "1px" }}
            inputProps={{ "aria-label": "controlled" }}
            onChange={onCheckboxChange}
          />
        </Tooltip>
        <span>To create new file for every run, select this checkbox.</span>
      </div>
    </>
  );
};

export default FileBasedConnectors;
