import { RefObject, UIEvent, useCallback, useEffect } from "react";

import { debounce } from "lodash";

import { ServerSideRenderingProps } from "../Table.types";

type Props = {
  tableContainerRef: RefObject<HTMLDivElement> | null;
  serverSideRenderingProps?: ServerSideRenderingProps;
};

const useHook = (props: Props) => {
  const { tableContainerRef, serverSideRenderingProps } = props || {};
  const {
    isLoading,
    fetchNextPage,
    totalNoOfRowsAtServerSide = 0,
    totalNoOfRowsFetched = 0
  } = serverSideRenderingProps || {};

  // Called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    // Debouncing to not to call this method not more than once in 300 milliseconds.
    debounce((containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // Once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 500 &&
          !isLoading &&
          totalNoOfRowsFetched < totalNoOfRowsAtServerSide
        ) {
          fetchNextPage?.();
        }
      }
    }, 300),
    [fetchNextPage, isLoading, totalNoOfRowsFetched, totalNoOfRowsAtServerSide]
  );

  const onTableContainerScrolled = (event: UIEvent<HTMLDivElement>) =>
    fetchMoreOnBottomReached(event?.target as HTMLDivElement);

  // A check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef?.current);
  }, [fetchMoreOnBottomReached]);

  return { onTableContainerScrolled };
};

export default useHook;
