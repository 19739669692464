import React, { useMemo } from "react";

// Packages
import { useLocation, useNavigate } from "react-router-dom";

// MUI
import Grid from "@material-ui/core/Grid";
import MuiLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// Icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Utils
import { isDrawerVariant } from "./SubTopNavBar.helpers";

// Types
import { StyleProps, SubTopNavBarLeftSectionProps } from "./SubTopNavBar.types";
import { SubTopNavBarHelperText } from "./SubTopNavBar.constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: StyleProps) => (isDrawerVariant(props?.variant) ? "100%" : "auto"),
    columnGap: 10,
    paddingLeft: (props: StyleProps) =>
      isDrawerVariant(props?.variant) ? theme.spacing(2) : theme.spacing(3)
  }
}));

const SubTopNavBarLeftSection = (props: SubTopNavBarLeftSectionProps) => {
  const { variant, hideBackNav = false, backNavAction, component, styleProps } = props || {};

  const classes = useStyles({ variant });

  const navigate = useNavigate();
  const location = useLocation();

  const backNavComponent = useMemo(() => {
    const disabledBackNavActionMessage =
      history?.state?.idx === 0 ? SubTopNavBarHelperText.DisabledBackNavActionMessage : "";

    return (
      <Tooltip title={disabledBackNavActionMessage}>
        <span>
          <MuiLink
            component="button"
            variant="body2"
            disabled={!!disabledBackNavActionMessage}
            onClick={() => {
              !!backNavAction?.();
              navigate(-1);
            }}
            data-testid="subTopNavBarBackNavAction">
            <span>
              <Typography
                gutterBottom
                variant="body2"
                color="textPrimary"
                {...(!!disabledBackNavActionMessage ? { style: { opacity: 0.5 } } : {})}>
                <ArrowBackIosIcon fontSize="inherit" />
              </Typography>
            </span>
          </MuiLink>
        </span>
      </Tooltip>
    );
  }, [location, backNavAction]);

  const hideBackNavComponent = useMemo(
    () => isDrawerVariant(variant) || !!hideBackNav,
    [variant, hideBackNav]
  );

  return (
    <Grid container alignItems="center" className={classes.root} style={{ ...styleProps }}>
      {!hideBackNavComponent && backNavComponent}
      {component}
    </Grid>
  );
};

export default SubTopNavBarLeftSection;
