/* eslint-disable no-unused-vars */
import create from "zustand";
import { persist } from "zustand/middleware";

type NotificationType = "Dashboard";

type Store = {
  notifications: any;
  setNotification: (arg0: { type: NotificationType; message: string }) => number;
  clearNotification: (arg0: number) => void;
  clearDashboardNotifications: () => void;
};

const useNotificationStore = create<Store>(
  persist(
    (set) => ({
      notifications: [],
      setNotification: ({ type, message }) => {
        const id = new Date().getTime();
        set((state) => ({
          notifications: [...state.notifications, { type, message, id }]
        }));
        return id;
      },
      clearNotification: (id) => {
        set((state) => ({
          notifications: state.notifications.filter(
            (notification: $TSFixMe) => notification.id !== id
          )
        }));
      },
      clearDashboardNotifications: () => {
        set((state) => ({
          notifications: state.notifications.filter(
            (notification: $TSFixMe) => notification.type !== "Dashboard"
          )
        }));
      }
    }),
    { name: "localStorage" }
  )
);

export default useNotificationStore;
