import React from "react";

import data from "./NotebookLoder/notebookLoader.json";

import { Grid, Typography } from "@material-ui/core";
import Lottie from "react-lottie-player";

const defaultOptions = {
  loop: true,
  play: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  },
  style: { width: "50%" }
};

const NotebookLoading: React.FC = () => {
  return (
    <Grid container direction="column" alignItems="center" wrap="nowrap">
      <Lottie {...defaultOptions} animationData={data || defaultOptions.animationData} />
      <Typography style={{ fontSize: "20px" }} variant="subtitle1">
        Loading Code in Notebook...
      </Typography>
    </Grid>
  );
};

export default NotebookLoading;
