import React from "react";
import {
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton
} from "@material-ui/core";

import { filter, includes, isEmpty, isNil, toLower } from "lodash";

import { AccountBalance, Clear, EmojiEvents, Storage } from "@material-ui/icons";

import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";
import { AiAssistedIcon } from "src/icons/AiAssistedIcon";
import { ChartIcon } from "src/icons/ChartIcon";
import { RapidModelIcon } from "src/icons/RapidModelIcon";
import { RecipeIcon } from "src/icons/RecipeIcon";
import { RecipeTypes } from "pages/private/ProjectsModule/utils/Projects.constants";
import { SearchField } from "../../../components";
import { NodeData } from "src/types";

const useStyles = makeStyles({
  searchField: {
    minWidth: "195px",
    maxWidth: "195px",
    // New UX change >>
    backgroundColor: "white",
    borderRadius: 32,
    // <<
    "& button": {
      marginRight: "2px !important",
      "& svg": {
        fontSize: 12
      }
    },
    // New UX change
    "& input": {
      padding: "9px 12px 9px 12px",
      fontSize: 12,
      maxHeight: 24
    }
  },
  searchResults: {
    fontStyle: "italic",
    color: "#7C7C7C",
    padding: "0px 16px"
  },
  iconContainer: {
    marginRight: 6,
    display: "flex",
    "& svg": {
      height: 14,
      maxWidth: 20,
      fill: "#003656",
      color: "#003656"
    }
  },
  highlight: {
    border: "1px solid #008FE4 !important",
    backgroundColor: "#F2F9FF"
  },
  highlightItem: {
    color: "#008FE4",
    backgroundColor: "#F2F9FF",
    "& svg": {
      fill: "#008FE4"
    }
  }
});

type Props = {
  setNodeToFocus: $TSFixMeFunction;
  nodes: $TSFixMe;
};
type MenuNodesProps = {
  id: string;
  displayName: string;
  type: string;
  recipeType?: string | null;
};

export const getIcon = (type: string) => {
  let defaultIcon = <RecipeIcon />;

  switch (type) {
    case "CHART": {
      defaultIcon = <ChartIcon />;
      break;
    }
    case "ARTIFACT": {
      defaultIcon = <AccountBalance />;
      break;
    }
    case "MODEL": {
      defaultIcon = <EmojiEvents />;
      break;
    }
    case "ENTITY": {
      defaultIcon = <Storage />;
      break;
    }
    case "CODE": {
      defaultIcon = <AiAssistedIcon />;
      break;
    }
    case "AUTO_ML": {
      defaultIcon = <RapidModelIcon />;
      break;
    }

    case RecipeTypes.ApiConnector: {
      defaultIcon = <ApiConnectorIcon />;
      break;
    }
  }
  return defaultIcon;
};

const CanvasSearch = ({ setNodeToFocus, nodes }: Props) => {
  const { searchResults, iconContainer, searchField, highlight, highlightItem } = useStyles();

  const [value, setValue] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedNode, setSelectedNode] = React.useState<string | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSearch = (e: $TSFixMe) => {
    setValue(e?.target?.value);
    setNodeToFocus(e?.target?.value);
    setSelectedNode(null);
    setAnchorEl(e.currentTarget);
  };

  const clearInput = (e: $TSFixMe) => {
    e?.preventDefault();
    e?.stopPropagation();
    setSelectedNode(null);
    setValue("");
    setNodeToFocus("");
  };

  const nodesSorted = React.useMemo(() => {
    if (!isNil(value) && !isEmpty(value)) {
      return filter(nodes, (node: NodeData) =>
        includes(toLower(node?.displayName), toLower(value))
      );
    }

    return nodes;
  }, [value, nodes]);

  React.useEffect(() => () => setNodeToFocus(""), []);

  return (
    <>
      <SearchField
        size="small"
        placeholder="Search entities"
        test-id="canvas-search-field"
        onChange={onSearch}
        onClick={(e: $TSFixMe) => {
          selectedNode && value?.length > 0 && setAnchorEl(e.currentTarget);
        }}
        value={value}
        autoComplete="off"
        className={`${searchField}${selectedNode ? ` ${highlight}` : ""}`}
        InputProps={{
          style: { backgroundColor: "initial" },
          ...(value?.length > 0
            ? {
                type: undefined,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="clear search content"
                      onClick={clearInput}
                      edge="end">
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }
            : {})
        }}
      />
      <Menu
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: -2,
          horizontal: selectedNode ? "left" : 45
        }}
        test-id="canvas-search-field-options"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocus
        disableAutoFocusItem
        disableEnforceFocus>
        <div className={searchResults}>
          <Typography variant="body2">
            Found {nodesSorted?.length} result{nodesSorted?.length !== 1 ? "s" : ""}
          </Typography>
        </div>
        {nodesSorted?.length > 0 &&
          nodesSorted?.map(({ id, displayName, recipeType, type }: MenuNodesProps) => {
            const onMenuItemClick = () => {
              onSearch({ target: { value: displayName } });
              setSelectedNode(displayName);
              handleClose();
            };

            return (
              <MenuItem
                key={id}
                test-id={`canvas-search-field-option-${displayName}`}
                id={id}
                value={displayName}
                onClick={onMenuItemClick}
                className={selectedNode === displayName ? highlightItem : ""}>
                <div className={iconContainer}>{getIcon(recipeType || type)}</div>
                <Typography variant="body2">{displayName}</Typography>
              </MenuItem>
            );
          })}
      </Menu>
    </>
  );
};

export default CanvasSearch;
