import React from "react";

const HighCpuUsageIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14552_7937)">
        <path
          d="M7 10C7 8.58579 7 7.87868 7.43934 7.43934C7.87868 7 8.58579 7 10 7H14C15.4142 7 16.1213 7 16.5607 7.43934C17 7.87868 17 8.58579 17 10V14C17 15.4142 17 16.1213 16.5607 16.5607C16.1213 17 15.4142 17 14 17H10C8.58579 17 7.87868 17 7.43934 16.5607C7 16.1213 7 15.4142 7 14V10Z"
          stroke="#003656"
          strokeWidth="1.5"
        />
        <path
          d="M12.4286 10L11 12H13L11.5714 14"
          stroke="#003656"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 12C4 8.22876 4 6.34315 5.17157 5.17157C6.34315 4 8.22876 4 12 4C15.7712 4 17.6569 4 18.8284 5.17157C20 6.34315 20 8.22876 20 12C20 15.7712 20 17.6569 18.8284 18.8284C17.6569 20 15.7712 20 12 20C8.22876 20 6.34315 20 5.17157 18.8284C4 17.6569 4 15.7712 4 12Z"
          stroke="#003656"
          strokeWidth="1.5"
        />
        <path d="M4 12H2" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M22 12H20" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M4 9H2" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M22 9H20" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M4 15H2" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M22 15H20" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M12 20V22" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M12 2V4" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M9 20V22" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M9 2V4" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M15 20V22" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M15 2V4" stroke="#003656" strokeWidth="1.5" strokeLinecap="round" />
        <path
          d="M13 25.5001H13.5V25.0001V21.7072L14.3965 22.6036L14.75 22.9572L15.1036 22.6036L16.3136 21.3936L16.6671 21.0401L16.3136 20.6865L12.3536 16.7265L12 16.373L11.6465 16.7265L7.68649 20.6865L7.33293 21.0401L7.68649 21.3936L8.89649 22.6036L9.25004 22.9572L9.60359 22.6036L10.5 21.7072V25.0001V25.5001H11H13Z"
          fill="#FF0000"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14552_7937">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HighCpuUsageIcon;
