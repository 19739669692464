import React, { useMemo, useState } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import DataAppModelControls from "../DataAppsDashboard/DataApps/CreateDataApp/DataAppModelControls";
import Drawer from "components/Drawer/CustomDrawer";
import EnableResponseCache from "../DataAppsDashboard/DataApps/CreateDataApp/EnableResponseCache";
import PreviewImageSelector from "pages/Projects/ProjectSettings/PreviewImageSelector";
import SelectAskAIInputType from "../DataAppsDashboard/DataApps/CreateDataApp/SelectAskAIInputType";
import SelectAskAILLMModel from "../DataAppsDashboard/DataApps/CreateDataApp/SelectAskAILLMModel";
import SelectDataAppSecurity from "../DataAppsDashboard/DataApps/CreateDataApp/SelectDataAppSecurity";
import SelectEnvironment from "src/pages/private/ProjectsModule/pages/PredictionService/components/SelectEnvironment";
import { CreateDataAppRequestDtoDataAppTypeEnum } from "openapi/Models/create-data-app-request-dto";
import { DataAppAppTypeEnum, DataAppType } from "../DataApps.type";
import { DataAppDtoDataAppTypeEnum } from "openapi/Models/data-app-dto";
import { DataappAskAIConfigInputTypeEnum } from "openapi/Models/dataapp-ask-aiconfig";
import { Spinner } from "src/components";
import { UpdateDataAppRequestDto } from "openapi/Models/update-data-app-request-dto";
import { dataAppConfigFields } from "../DataAppsDashboard/DataApps/CreateDataApp/CreateDataAppForm";
import { dataAppThumbNailImages } from "src/pages/DataApps/common/DataAppImages";
import { handleResponse } from "src/utils/apiService";
import { imageToBase64 } from "pages/Projects/helpers/projects.helpers";
import { useUpdateDataAppMutation } from "src/hooks/api";
import { validateNameField } from "src/utils/formFieldUtils";

const useStyles = makeStyles({
  drawerPaper: {
    width: "40%",
    overflowX: "hidden"
  },
  btnList: {
    height: "70px",
    backgroundColor: "#F5F5F5",
    padding: "20px",
    alignItems: "center",
    gap: "20px"
  },

  outlinedBtn: {
    color: "#008FE4",
    borderColor: "#008FE4"
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: "14px"
  },
  titleString: {
    fontSize: "18px"
  },
  previewImage: {
    width: "auto"
  },
  rightInputs: {
    flexWrap: "nowrap",
    gap: "30px",
    width: "100%"
  },
  inputsWrap: {
    flexWrap: "nowrap",
    gap: "16px",
    padding: "16px"
  }
});

export const EditDataAppDrawer = ({
  dataApp,
  onClose
}: {
  dataApp: DataAppType;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const [image, setImage] = useState(dataApp.iconUrl);
  const { askAIConfig, dataAppType, appTemplateType } = dataApp;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      name: dataApp.displayName || dataApp.name,
      description: dataApp.description,
      [dataAppConfigFields.customEnvId]: dataApp.askAIConfig?.customEnvId
    }
  });
  const dataAppName = watch("name");
  const dataAppDescription = watch("description");
  const customEnvId = watch(dataAppConfigFields.customEnvId);
  const updateDataAppMutation = useUpdateDataAppMutation();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    const { error } = validateNameField({
      fieldName: value,
      fieldNameLabel: `dataApp name`
    });
    if (error) {
      setError("name", {
        type: "manual",
        message: error
      });
    } else {
      clearErrors("name");
    }
  };

  const onSubmit = (data: { name: string; description: string; customEnvId?: string }) => {
    const { error } = validateNameField({
      fieldName: data.name.trim(),
      fieldNameLabel: `dataApp name`
    });
    if (error) {
      handleResponse({ errorMessage: error });
      return;
    }
    updateDataAppMutation.mutate(
      {
        id: dataApp.id,
        payload: {
          ...(dataApp as UpdateDataAppRequestDto),
          displayName: data.name,
          description: data.description,
          iconUrl: image,
          askAIConfig: { ...dataApp.askAIConfig, customEnvId: data.customEnvId }
        }
      },
      {
        onSuccess: () => {
          handleResponse({ successMessage: "DataApp updated successfully." });
          onClose();
        }
      }
    );
  };

  const customEnvDisability = useMemo(
    () =>
      dataAppType !== CreateDataAppRequestDtoDataAppTypeEnum.Custom &&
      appTemplateType === DataAppAppTypeEnum.REACTJS &&
      !customEnvId,
    [dataAppType, appTemplateType, customEnvId]
  );

  const footer = (
    <Grid container direction="row-reverse" className={classes.btnList}>
      <Tooltip
        title={
          customEnvDisability
            ? "Please wait until the environment is loaded for this DataApp's AskAI"
            : ""
        }>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={
              !dataAppName ||
              !!errors.description ||
              !!errors.name ||
              customEnvDisability ||
              updateDataAppMutation.isLoading ||
              (dataAppName === (dataApp.displayName || dataApp.name) &&
                dataAppDescription === dataApp.description &&
                customEnvId === dataApp.askAIConfig?.customEnvId &&
                dataApp.iconUrl === image)
            }
            data-testid="dataapp-edit-save-btn">
            {updateDataAppMutation.isLoading ? <Spinner size={24} noPadding /> : "Save"}
          </Button>
        </span>
      </Tooltip>
      <Button
        color="primary"
        variant="outlined"
        onClick={onClose}
        data-testid="dataapp-edit-cancel-btn">
        Cancel
      </Button>
    </Grid>
  );

  const title = (
    <div className={classes.title}>
      <div className={classes.titleString}>Edit DataApp</div>
      <IconButton onClick={onClose} color="primary">
        <Close />
      </IconButton>
    </div>
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      data-testid="dataapp-config-drawer"
      hideCloseButton
      open
      width="40%"
      title={title}
      classes={{
        paper: classes.drawerPaper
      }}
      footer={footer}
      onClose={onClose}>
      <div style={{ maxHeight: "calc(100vh - 138px)", overflow: "auto" }}>
        <Grid container direction="row" className={classes.inputsWrap}>
          <Grid item className={classes.previewImage}>
            <PreviewImageSelector
              images={dataAppThumbNailImages}
              noText
              onChange={(img: $TSFixMe) => imageToBase64(img, setImage)}
              defaultImage={image}
            />
          </Grid>
          <Grid container item direction="column" className={classes.rightInputs}>
            <TextField
              required
              fullWidth
              size="small"
              data-testid="dataAppName"
              {...register("name", {
                required: "The dataApp name cannot be blank.",
                setValueAs: (val: string) => val?.trim(),
                onChange: handleNameChange
              })}
              error={!!errors.name}
              helperText={errors.name && <>{errors.name?.message}</>}
              label="DataApp Name"
              variant="outlined"
            />
            <Tooltip title={dataAppDescription ?? ""}>
              <TextField
                fullWidth
                multiline
                maxRows={6}
                minRows={6}
                data-testid="dataAppDescription"
                size="small"
                {...register("description", {
                  setValueAs: (val: string) => val?.trim()
                })}
                error={!!errors.description}
                helperText={errors.description && <>{errors.description?.message}</>}
                label="DataApp Description"
                variant="outlined"
              />
            </Tooltip>
          </Grid>
        </Grid>
        {dataAppType !== CreateDataAppRequestDtoDataAppTypeEnum.Custom &&
          appTemplateType === DataAppAppTypeEnum.REACTJS && (
            <Grid style={{ padding: "16px" }}>
              <SelectEnvironment
                tooltipInfoMsg="The chosen environment will be used for queries in AskAI within this DataApp"
                value={customEnvId ?? ""}
                name={dataAppConfigFields.customEnvId}
                label="Environment"
                projectId={dataApp.projectId}
                shouldSetDefaultValue
                {...(register(dataAppConfigFields.customEnvId),
                {
                  onChange: (event) => {
                    const selectedValue = event.target.value;
                    setValue(dataAppConfigFields.customEnvId, selectedValue as string);
                  }
                })}
              />
            </Grid>
          )}
        {dataAppType === DataAppDtoDataAppTypeEnum.Askai && askAIConfig && (
          <Grid style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "10px" }}>
            <SelectAskAIInputType readOnly value={askAIConfig.inputType!} />
            {askAIConfig.inputType !== DataappAskAIConfigInputTypeEnum.PredictionService && (
              <>
                <SelectAskAILLMModel
                  readOnly
                  value={askAIConfig.llmType!}
                  inputType={askAIConfig.inputType}
                />
                <SelectDataAppSecurity readOnly value={askAIConfig.dataSharingStrategy!} />
                <EnableResponseCache readOnly value={!!askAIConfig.enableCache!} />
                <DataAppModelControls
                  readOnly
                  values={{
                    [dataAppConfigFields.systemMessage]: askAIConfig.systemMessage!
                  }}
                />
              </>
            )}
          </Grid>
        )}
        {dataAppType === DataAppDtoDataAppTypeEnum.RapidModel && (
          <Grid
            style={{
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "10px"
            }}>
            <FormControl component="fieldset" disabled={true}>
              <FormLabel component="legend">Inputs</FormLabel>
              <RadioGroup
                row={true}
                aria-label={dataAppConfigFields.inputType}
                name={dataAppConfigFields.inputType}
                value={"Rapid Model"}>
                <FormControlLabel value={"Rapid Model"} control={<Radio />} label="Rapid Model" />
              </RadioGroup>
            </FormControl>
            <DataAppModelControls
              readOnly
              values={{
                [dataAppConfigFields.systemMessage]: askAIConfig?.systemMessage!
              }}
            />
          </Grid>
        )}
      </div>
    </Drawer>
  );
};
