import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: theme.spacing(1.5)
  },
  datasetWrapper: {
    overflow: "auto",
    maxHeight: 400,
    flexGrow: 1
  },
  datasetSubWrapper: {
    minWidth: 375,
    maxWidth: 375,
    borderRight: "1px dotted #ccc"
  },
  datasetContainer: {
    margin: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
  segmentTypeContainer: {
    rowGap: theme.spacing(1),
    width: "90%"
  },
  segmentTypeFormControlLabelRoot: {
    margin: 0,
    columnGap: theme.spacing(1)
  },
  segmentTypeFormControlLabelLabel: {
    fontSize: "0.875rem"
  },
  segmentTypeRadioRoot: {
    padding: 0
  },
  segmentsContainer: {
    marginLeft: theme.spacing(3),
    rowGap: theme.spacing(1),
    width: "89%",
    maxHeight: 110,
    overflowY: "auto",
    "& .error": {
      color: theme.palette.error.main
    }
  },
  segmentsFormControlLabelRoot: {
    margin: 0,
    columnGap: theme.spacing(1),
    width: "75%"
  },
  segmentsFormControlLabelLabel: {
    width: "100%",
    fontSize: "0.875rem"
  },
  segmentsCheckboxRoot: {
    padding: 0
  }
}));

export default useStyles;
