/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { BaseTransform } from './base-transform';
// May contain unused imports in some cases
// @ts-ignore
import type { DFSInput } from './dfsinput';
// May contain unused imports in some cases
// @ts-ignore
import type { PipelineEntityFeature } from './pipeline-entity-feature';

/**
 * 
 * @export
 * @interface UpdateDFSTemplateDto
 */
export interface UpdateDFSTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'type'?: UpdateDFSTemplateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'conditionExpression'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'requirements'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'status'?: UpdateDFSTemplateDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'category'?: UpdateDFSTemplateDtoCategoryEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateDFSTemplateDto
     */
    'variableExprMap'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: Array<PipelineEntityFeature>; }}
     * @memberof UpdateDFSTemplateDto
     */
    'fieldsTemplate'?: { [key: string]: Array<PipelineEntityFeature>; };
    /**
     * 
     * @type {Array<BaseTransform>}
     * @memberof UpdateDFSTemplateDto
     */
    'baseTransforms'?: Array<BaseTransform>;
    /**
     * 
     * @type {Array<DFSInput>}
     * @memberof UpdateDFSTemplateDto
     */
    'inputs'?: Array<DFSInput>;
    /**
     * 
     * @type {Set<string>}
     * @memberof UpdateDFSTemplateDto
     */
    'tags'?: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'descriptionHtml'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDFSTemplateDto
     */
    'display_name'?: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof UpdateDFSTemplateDto
     */
    'secondary_tags'?: Set<string>;
}

export const UpdateDFSTemplateDtoTypeEnum = {
    Notebook: 'NOTEBOOK',
    PythonFunc: 'PYTHON_FUNC',
    Code: 'CODE'
} as const;

export type UpdateDFSTemplateDtoTypeEnum = typeof UpdateDFSTemplateDtoTypeEnum[keyof typeof UpdateDFSTemplateDtoTypeEnum];
export const UpdateDFSTemplateDtoStatusEnum = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type UpdateDFSTemplateDtoStatusEnum = typeof UpdateDFSTemplateDtoStatusEnum[keyof typeof UpdateDFSTemplateDtoStatusEnum];
export const UpdateDFSTemplateDtoCategoryEnum = {
    Basic: 'BASIC',
    Advanced: 'ADVANCED'
} as const;

export type UpdateDFSTemplateDtoCategoryEnum = typeof UpdateDFSTemplateDtoCategoryEnum[keyof typeof UpdateDFSTemplateDtoCategoryEnum];


