import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "src/utils/AxiosClient";
import { getRedirectUrl } from "src/utils/fivetran";
import { handleErrorWithRedirectToLogs } from "src/utils/apiService";
import { postAPIWithRethrow } from "src/utils/apiService";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";

interface IVariables {
  id: string;
  name: string;
  iconUrl?: string;
}

interface IData {
  connectCardUrl: string;
  connectorId: string;
  groupId: string;
  responseEntity: string;
  status: string;
}

const useCreateConnector = (): UseMutationResult<IData, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ id, name, iconUrl }) => {
      try {
        await api.client(`/v2/data-source/by-name/${name}`);
        handleErrorWithRedirectToLogs(`${name} data connector already exists.`);
        return null;
      } catch {
        const data = await postAPIWithRethrow(`/v2/data-source/create-connector/${id}`, {
          redirectUrl: getRedirectUrl(name)
        });

        await postAPIWithRethrow("/v2/data-source", {
          name,
          type: thirdPartyTypeName,
          tpConnectorType: id,
          iconUrl,
          options: {
            groupId: data.groupId,
            connectorId: data.connectorId,
            connectCardUrl: data.connectCardUrl
          }
        });

        return data;
      }
    },
    onSuccess: (result) => {
      window.open(result.connectCardUrl, "_self");
    }
  });
};

export default useCreateConnector;
