import React from "react";

export const MemoryIcon = ({ width = 20, height = 20, color = "#008FE4" }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9809_12763)">
        <path
          d="M19.5652 4.34778H0.434783C0.194319 4.34778 0 4.5421 0 4.78256V12.6086C0 12.8491 0.194319 13.0434 0.434783 13.0434H0.869565V15.2173C0.869565 15.4578 1.06388 15.6521 1.30435 15.6521H18.6957C18.9361 15.6521 19.1304 15.4578 19.1304 15.2173V13.0434H19.5652C19.8057 13.0434 20 12.8491 20 12.6086V4.78256C20 4.5421 19.8057 4.34778 19.5652 4.34778ZM18.2609 14.7826H16.9565H15.2174H13.4783H11.7391H10H8.26087H6.52174H4.78261H3.04348H1.73913V13.0434H3.04348H4.78261H6.52174H8.26087H10H11.7391H13.4783H15.2174H16.9565H18.2609V14.7826ZM19.1304 12.1739H18.6957H1.30435H0.869565V5.21734H19.1304V12.1739Z"
          fill={color}
        />
        <path
          d="M2.17392 11.3043H4.78261C5.02308 11.3043 5.2174 11.11 5.2174 10.8695V6.5217C5.2174 6.28123 5.02308 6.08691 4.78261 6.08691H2.17392C1.93345 6.08691 1.73914 6.28123 1.73914 6.5217V10.8695C1.73914 11.11 1.93345 11.3043 2.17392 11.3043ZM2.6087 6.95648H4.34783V10.4347H2.6087V6.95648Z"
          fill={color}
        />
        <path
          d="M6.5217 11.3043H9.13039C9.37086 11.3043 9.56518 11.11 9.56518 10.8695V6.5217C9.56518 6.28123 9.37086 6.08691 9.13039 6.08691H6.5217C6.28123 6.08691 6.08691 6.28123 6.08691 6.5217V10.8695C6.08691 11.11 6.28123 11.3043 6.5217 11.3043ZM6.95648 6.95648H8.69561V10.4347H6.95648V6.95648Z"
          fill={color}
        />
        <path
          d="M10.8696 11.3043H13.4783C13.7188 11.3043 13.9131 11.11 13.9131 10.8695V6.5217C13.9131 6.28123 13.7188 6.08691 13.4783 6.08691H10.8696C10.6291 6.08691 10.4348 6.28123 10.4348 6.5217V10.8695C10.4348 11.11 10.6291 11.3043 10.8696 11.3043ZM11.3044 6.95648H13.0435V10.4347H11.3044V6.95648Z"
          fill={color}
        />
        <path
          d="M15.2174 11.3043H17.8261C18.0665 11.3043 18.2609 11.11 18.2609 10.8695V6.5217C18.2609 6.28123 18.0665 6.08691 17.8261 6.08691H15.2174C14.9769 6.08691 14.7826 6.28123 14.7826 6.5217V10.8695C14.7826 11.11 14.9769 11.3043 15.2174 11.3043ZM15.6522 6.95648H17.3913V10.4347H15.6522V6.95648Z"
          fill={color}
        />
        <path
          d="M3.47821 14.3478V13.4782C3.47821 13.2378 3.28389 13.0435 3.04343 13.0435C2.80296 13.0435 2.60864 13.2378 2.60864 13.4782V14.3478C2.60864 14.5883 2.80296 14.7826 3.04343 14.7826C3.28389 14.7826 3.47821 14.5883 3.47821 14.3478Z"
          fill={color}
        />
        <path
          d="M5.21734 14.3478V13.4782C5.21734 13.2378 5.02303 13.0435 4.78256 13.0435C4.5421 13.0435 4.34778 13.2378 4.34778 13.4782V14.3478C4.34778 14.5883 4.5421 14.7826 4.78256 14.7826C5.02303 14.7826 5.21734 14.5883 5.21734 14.3478Z"
          fill={color}
        />
        <path
          d="M6.95648 14.3478V13.4782C6.95648 13.2378 6.76216 13.0435 6.5217 13.0435C6.28123 13.0435 6.08691 13.2378 6.08691 13.4782V14.3478C6.08691 14.5883 6.28123 14.7826 6.5217 14.7826C6.76216 14.7826 6.95648 14.5883 6.95648 14.3478Z"
          fill={color}
        />
        <path
          d="M8.69561 14.3478V13.4782C8.69561 13.2378 8.5013 13.0435 8.26083 13.0435C8.02037 13.0435 7.82605 13.2378 7.82605 13.4782V14.3478C7.82605 14.5883 8.02037 14.7826 8.26083 14.7826C8.5013 14.7826 8.69561 14.5883 8.69561 14.3478Z"
          fill={color}
        />
        <path
          d="M10.4348 14.3478V13.4782C10.4348 13.2378 10.2404 13.0435 9.99997 13.0435C9.7595 13.0435 9.56519 13.2378 9.56519 13.4782V14.3478C9.56519 14.5883 9.7595 14.7826 9.99997 14.7826C10.2404 14.7826 10.4348 14.5883 10.4348 14.3478Z"
          fill={color}
        />
        <path
          d="M12.1739 14.3478V13.4782C12.1739 13.2378 11.9796 13.0435 11.7391 13.0435C11.4986 13.0435 11.3043 13.2378 11.3043 13.4782V14.3478C11.3043 14.5883 11.4986 14.7826 11.7391 14.7826C11.9796 14.7826 12.1739 14.5883 12.1739 14.3478Z"
          fill={color}
        />
        <path
          d="M13.913 14.3478V13.4782C13.913 13.2378 13.7187 13.0435 13.4782 13.0435C13.2378 13.0435 13.0435 13.2378 13.0435 13.4782V14.3478C13.0435 14.5883 13.2378 14.7826 13.4782 14.7826C13.7187 14.7826 13.913 14.5883 13.913 14.3478Z"
          fill={color}
        />
        <path
          d="M15.6522 14.3478V13.4782C15.6522 13.2378 15.4578 13.0435 15.2174 13.0435C14.9769 13.0435 14.7826 13.2378 14.7826 13.4782V14.3478C14.7826 14.5883 14.9769 14.7826 15.2174 14.7826C15.4578 14.7826 15.6522 14.5883 15.6522 14.3478Z"
          fill={color}
        />
        <path
          d="M17.3913 14.3478V13.4782C17.3913 13.2378 17.197 13.0435 16.9565 13.0435C16.716 13.0435 16.5217 13.2378 16.5217 13.4782V14.3478C16.5217 14.5883 16.716 14.7826 16.9565 14.7826C17.197 14.7826 17.3913 14.5883 17.3913 14.3478Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9809_12763">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);
