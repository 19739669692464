/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { Button, Grid, IconButton, Typography, makeStyles, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useLocation } from "react-router";

import { ArtifactMini, Entities, EntityFeaturesResponse, ModelMini } from "src/types";
import { InputDatasetsView } from "../../common/InputDatasetsView";
import { ArtifactIcon } from "src/icons/ArtifactIcon";
import { ModelsIcon } from "src/icons/ModelsIcon";
import { CollapseIcon } from "src/icons/CollapseIcon";
import { READONLY_ENTITY } from "src/constants";
import { orderBy, toLower } from "lodash";

const useStyles = makeStyles({
  codeRecipeHeader: {
    padding: "16px"
  },
  inputWrap: {
    height: "100%",
    maxHeight: "100%",
    flexWrap: "nowrap",
    overflow: "auto"
  },
  entityLeftContent: {
    flexWrap: "nowrap",
    gap: "8px",
    alignItems: "center",
    minHeight: "48px",
    padding: "0px 16px",
    border: "0.5px #D1D1D1",
    borderStyle: "solid none;"
  },
  entityText: {
    fontWeight: 400
  },
  entityTitle: {
    color: "#000000",
    opacity: "0.5",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "8px 16px"
  },
  btnContainer: {
    flexWrap: "nowrap",
    gap: "16px",
    flex: 0
  }
});

type Props = {
  inputDatasets: Entities;
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  onDeleteDataset: (datasetId: string) => void;
  openAddEntitiesDialog: () => void;
  selectedArtifacts: Array<ArtifactMini>;
  selectedModels: Array<ModelMini>;
  recipeId: string | undefined;
  onCollapse: () => void;
  isDefaultScenario: boolean;
};

export const StandardRecipeInputsView = ({
  inputDatasets,
  entityFeaturesMap,
  openAddEntitiesDialog,
  onDeleteDataset,
  selectedArtifacts,
  selectedModels,
  recipeId,
  onCollapse,
  isDefaultScenario
}: Props) => {
  const classes = useStyles();

  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  return (
    <Grid container direction="column" className={classes.inputWrap}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.codeRecipeHeader}>
        <Typography variant="subtitle1">Inputs</Typography>
        <Grid container direction="row" className={classes.btnContainer}>
          {!isJobPath && (
            <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
              <div>
                <Button
                  color="primary"
                  test-id="standardRecipeHeaderAddEntitiesBtn"
                  variant="contained"
                  startIcon={<AddIcon htmlColor="#ffffff" />}
                  onClick={openAddEntitiesDialog}
                  disabled={!isDefaultScenario}
                  size="small">
                  Add
                </Button>
              </div>
            </Tooltip>
          )}
          <IconButton onClick={onCollapse} size="small">
            <CollapseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <InputDatasetsView
        isDefaultScenario={isDefaultScenario}
        recipeId={recipeId}
        inputDatasets={inputDatasets}
        onDeleteDataset={onDeleteDataset}
        entityFeaturesMap={entityFeaturesMap}
      />
      {selectedArtifacts?.length > 0 && (
        <Grid container direction="column">
          <Grid>
            <Typography variant="subtitle2" className={classes.entityTitle}>
              ARTIFACTS ({selectedArtifacts?.length || 0})
            </Typography>
          </Grid>
          {orderBy(selectedArtifacts, (item) => toLower(item.name)).map((artifact) => (
            <Grid
              container
              key={artifact.name}
              direction="row"
              className={classes.entityLeftContent}>
              <ArtifactIcon />
              <Typography
                variant="subtitle2"
                className={classes.entityText}
                test-id="code-recipe-input-dataset-name">
                {artifact.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
      {selectedModels?.length > 0 && (
        <Grid container direction="column">
          <Grid>
            <Typography variant="subtitle2" className={classes.entityTitle}>
              MODELS ({selectedModels?.length || 0})
            </Typography>
          </Grid>
          {orderBy(selectedModels, (item) => toLower(item.name)).map((model) => (
            <Grid container key={model.name} direction="row" className={classes.entityLeftContent}>
              <ModelsIcon />
              <Typography
                variant="subtitle2"
                className={classes.entityText}
                test-id="code-recipe-input-dataset-name">
                {model.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
