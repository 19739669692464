import { useQuery } from "@tanstack/react-query";
import { getTemplatesWithRethrow } from "src/api";

import { DfsTemplate } from "src/types";

export const useGetDfsTemplates = ({
  projectId,
  enabled = true
}: {
  projectId: string;
  enabled?: boolean;
}) => {
  return useQuery<Array<DfsTemplate>>({
    queryKey: ["project", projectId, "dfs-templates"],
    queryFn: () => {
      return getTemplatesWithRethrow({ projectId });
    },
    enabled: enabled && !!projectId,
    cacheTime: 0
  });
};
