import React, { useMemo } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { startCase } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

// Hooks
import { UseGetRecipeRunsQueueQueryKeys, useClearRecipeRunsQueue } from "src/hooks/api/recipes";

// Open API
import { ScenarioDto } from "openapi/Models/scenario-dto";

// Constants
import { RecipeRunsHelperText } from "./RecipeRunsPopover.constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px}`
  }
}));

type Props = {
  scenario?: ScenarioDto;
  isEmptyRecipeRunsQueue: boolean;
  isRecipesRunning: boolean;
};

const Header = (props: Props) => {
  const { scenario, isEmptyRecipeRunsQueue, isRecipesRunning } = props || {};

  const { projectId } = useParams() || {};
  const queryClient = useQueryClient();

  const classes = useStyles();

  // Query hooks - STARTS >>
  // Mutations
  const {
    isLoading: isClearingRecipeRunsQueue,
    mutateAsync: clearRecipeRunsQueueMutation,
    reset: resetClearRecipeRunsQueueMutation
  } = useClearRecipeRunsQueue();
  // << ENDS - Query hooks

  const clearRecipeRunsQueue = async () => {
    if (!projectId) {
      return;
    }

    await resetClearRecipeRunsQueueMutation();
    await clearRecipeRunsQueueMutation(
      { projectId },
      {
        onSuccess: async () =>
          await queryClient.invalidateQueries([UseGetRecipeRunsQueueQueryKeys.RecipeRunsQueue])
      }
    );
  };

  const disabledClearAllActionMessage = useMemo(() => {
    if (isRecipesRunning) {
      return "The queue cannot be cleared while a recipe is currently running.";
    }

    if (isEmptyRecipeRunsQueue) {
      return "No recipes in queue to clear.";
    }

    return;
  }, [isRecipesRunning, isEmptyRecipeRunsQueue]);

  return (
    <Grid container justifyContent="space-between" alignItems="flex-start" className={classes.root}>
      <Box>
        <Box fontWeight={500}>{RecipeRunsHelperText.RecipeQueue}</Box>
        <Typography variant="caption" color="textSecondary">
          {RecipeRunsHelperText.CurrentScenario}: {startCase(scenario?.name) || "Unknown"}
        </Typography>
      </Box>

      {isClearingRecipeRunsQueue ? (
        <CircularProgress size={16} />
      ) : (
        <Tooltip title={disabledClearAllActionMessage || "Clears the recipe queue"}>
          <span>
            <Button
              size="small"
              onClick={clearRecipeRunsQueue}
              disabled={!!disabledClearAllActionMessage}>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ opacity: !!disabledClearAllActionMessage ? 0.5 : 1 }}>
                {RecipeRunsHelperText.ClearAll}
              </Typography>
            </Button>
          </span>
        </Tooltip>
      )}
    </Grid>
  );
};

export default Header;
