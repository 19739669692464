import React from "react";

export const PersonIconV3 = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#FFF8F3" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#FF5C00" />
      <path
        d="M15.9999 17.2004C18.0749 17.2004 19.7499 15.5254 19.7499 13.4504C19.7499 11.3754 18.0749 9.70044 15.9999 9.70044C13.9249 9.70044 12.2499 11.3754 12.2499 13.4504C12.2499 15.5254 13.9249 17.2004 15.9999 17.2004ZM15.9999 10.8254C17.4499 10.8254 18.6249 12.0004 18.6249 13.4504C18.6249 14.9004 17.4499 16.0754 15.9999 16.0754C14.5499 16.0754 13.3749 14.9004 13.3749 13.4504C13.3749 12.0004 14.5499 10.8254 15.9999 10.8254Z"
        fill="#FF5C00"
      />
      <path
        d="M23.3747 21.3C21.3247 19.575 18.7247 18.625 15.9997 18.625C13.2747 18.625 10.6747 19.575 8.62473 21.3C8.39973 21.5 8.34973 21.85 8.54973 22.1C8.74973 22.325 9.09973 22.375 9.34973 22.175C11.1997 20.6 13.5497 19.75 15.9997 19.75C18.4497 19.75 20.7997 20.6 22.6247 22.15C22.7247 22.25 22.8497 22.275 22.9997 22.275C23.1497 22.275 23.3247 22.2 23.4247 22.075C23.6247 21.85 23.5997 21.5 23.3747 21.3Z"
        fill="#FF5C00"
      />
    </svg>
  );
};
