import React from "react";

export const PreviewIconNew = ({ width = 12, height = 13, fill = "none", stroke = "black" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.79094 6.50094C7.79094 7.49094 6.99094 8.29094 6.00094 8.29094C5.01094 8.29094 4.21094 7.49094 4.21094 6.50094C4.21094 5.51094 5.01094 4.71094 6.00094 4.71094C6.99094 4.71094 7.79094 5.51094 7.79094 6.50094Z"
        stroke={stroke}
        strokeWidth="0.78125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99992 10.6344C7.76492 10.6344 9.40992 9.59437 10.5549 7.79437C11.0049 7.08937 11.0049 5.90437 10.5549 5.19937C9.40992 3.39937 7.76492 2.35938 5.99992 2.35938C4.23492 2.35938 2.58992 3.39937 1.44492 5.19937C0.994922 5.90437 0.994922 7.08937 1.44492 7.79437C2.58992 9.59437 4.23492 10.6344 5.99992 10.6344Z"
        stroke={stroke}
        strokeWidth="0.78125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
