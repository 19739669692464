import React from "react";
import { Box, Grid, Typography, makeStyles } from "@material-ui/core";

import rapidModalRecipeSrc from "src/assets/images/rapid-modal-recipe.svg";

const useStyles = makeStyles({
  noDatasetsContainerWrap: {
    width: "100%",
    height: "100%",
    flexWrap: "nowrap",
    paddingTop: "10%",
    alignItems: "center"
  },
  noDatasetsSvgWrap: {
    textAlign: "center",
    alignItems: "center",
    maxWidth: "400px"
  },
  addDatasetsText: {
    marginTop: "8px",
    fontSize: "16px",
    color: "#000000",
    opacity: 0.5
  },
  transformsLink: {
    color: "#008FE4"
  },
  noDatasetsImg: {
    width: "300px",
    height: "200px"
  }
});
export const NoInputAutoMLContainer = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={`${classes.noDatasetsContainerWrap} container-height`}>
      <Grid container direction="column" className={classes.noDatasetsSvgWrap}>
        <Grid
          component="img"
          test-id="empty-rapid-modal-recipe-image"
          src={rapidModalRecipeSrc}
          className={classes.noDatasetsImg}
        />
        <Box pt="24px">
          <Typography test-id="empty-recipe-page-title">What is Rapid Model Recipe?</Typography>
        </Box>
        <Typography className={classes.addDatasetsText} test-id="empty-recipe-page-text">
          Rapid Model Recipe applies various algorithms, or estimators, to analyze, clean, and
          prepare your raw data for machine learning. It automatically detects and categorizes
          features based on data type, such as categorical or numerical.
        </Typography>
      </Grid>
    </Grid>
  );
};
