export const imageToBase64 = (
  imgPath: string,
  setImageBase64: Function,
  height = 204,
  width = 164
) => {
  let canvas = document.createElement("canvas");
  let img = document.createElement("img");
  img.src = imgPath;
  img.crossOrigin = "anonymous"; // This is to enable CORS requests for the image reading from CDN.
  img.onload = function () {
    let context = canvas.getContext("2d");
    canvas.height = height;
    canvas.width = width;
    let hRatio = canvas.width / img.width;
    let vRatio = canvas.height / img.height;
    let ratio = Math.max(hRatio, vRatio);
    let centerShift_x = (canvas.width - img.width * ratio) / 2;
    let centerShift_y = (canvas.height - img.height * ratio) / 2;
    context?.clearRect(0, 0, canvas.width, canvas.height);
    context?.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      centerShift_x,
      centerShift_y,
      img.width * ratio,
      img.height * ratio
    );

    let dataURL = canvas.toDataURL("image/jpeg");
    setImageBase64(dataURL?.split(";base64,")[1]);
  };
};
