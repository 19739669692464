import React from "react";
import {
  AppBar,
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Tooltip
} from "@material-ui/core";

import { AiGuideMiniIcon } from "src/icons/NewUX";
import { MinimizeIcon } from "src/icons/NewUX/MinimizeIcon";
import { ClearIcon } from "src/icons/NewUX/ClearIcon";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    marginTop: "49px",
    backgroundColor: "#fff",
    color: "#4646b5",
    paddingLeft: "16px",
    paddingRight: "3px"
  },
  leftWrap: {
    gap: "20px"
  },
  btnWrap: {
    flex: 0,
    alignItems: "center",
    padding: "0px 12px",
    gap: "6px"
  },
  clearAllBtn: {
    whiteSpace: "nowrap",
    "&:disabled": {
      opacity: 0.5
    }
  },
  iconWrap: {
    padding: "6px",
    "&:disabled": {
      opacity: 0.5
    }
  },
  iconBtn: {
    padding: "6px"
  },
  divider: {
    color: "#7C7C7C",
    height: "28px",
    margin: "0px 8px"
  }
});

export const AIGuideHeader = ({
  onMinimize,
  onClearAll,
  isClearAllDisabled
}: {
  onMinimize?: () => void;
  onClearAll: () => void;
  isClearAllDisabled: boolean;
}) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} elevation={1}>
      <Toolbar variant="dense" disableGutters>
        <Grid container direction="row" wrap="nowrap">
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            alignItems="center"
            data-test-id="ai-guide-header"
            className={classes.leftWrap}>
            <AiGuideMiniIcon
              width={32}
              height={20}
              viewBox="10 -2 38 38"
              data-test-id="ai-guide-header-icon"
            />
            AI Guide
          </Grid>
          <Grid container item direction="row" wrap="nowrap" className={classes.btnWrap}>
            <Grid container item direction="column" wrap="nowrap" alignItems="center">
              <Divider orientation="vertical" className={classes.divider} />
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  isClearAllDisabled
                    ? "No messages available for this chat to clear"
                    : "Clear chat history"
                }>
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={onClearAll}
                    data-testid="ai-guide-clear-chat-history-btn"
                    className={classes.clearAllBtn}
                    disabled={isClearAllDisabled}
                    startIcon={<ClearIcon />}>
                    Clear Chat History
                  </Button>
                </div>
              </Tooltip>
            </Grid>
            {!!onMinimize && (
              <Tooltip title="Minimize">
                <Grid item>
                  <IconButton
                    onClick={() => onMinimize()}
                    className={classes.iconBtn}
                    data-testid="ai-guide-minimize-btn">
                    <MinimizeIcon />
                  </IconButton>
                </Grid>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
