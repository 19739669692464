import React from "react";

export const EntityIconV3 = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="2" width="32" height="32" rx="16" fill="white" />
      <path
        d="M20.6149 10C16.5321 10 13.3333 11.7495 13.3333 13.9834V22.0166C13.3333 24.2505 16.5321 26 20.6149 26C24.6977 26 27.8965 24.2505 27.8965 22.0166V13.9834C27.8944 11.7495 24.6977 10 20.6149 10ZM14.5756 17.942C14.5756 17.6211 14.7164 17.3002 14.9938 16.9855C15.0828 16.8861 15.1304 16.764 15.1449 16.6418C16.47 17.4617 18.412 17.971 20.6149 17.971C22.7971 17.971 24.7267 17.47 26.0497 16.6646C26.0745 16.766 26.1201 16.8613 26.1946 16.9441C26.499 17.2733 26.6522 17.6087 26.6522 17.9441C26.6522 19.2402 24.1719 20.6853 20.6128 20.6853C17.0538 20.6853 14.5756 19.2381 14.5756 17.942ZM20.6149 11.2422C24.1739 11.2422 26.6543 12.6874 26.6543 13.9834C26.6543 15.2795 24.1739 16.7267 20.6149 16.7267C17.0559 16.7267 14.5756 15.2816 14.5756 13.9834C14.5756 12.6853 17.0559 11.2422 20.6149 11.2422ZM20.6149 24.7598C17.0559 24.7598 14.5756 23.3147 14.5756 22.0166C14.5756 21.6957 14.7164 21.3747 14.9938 21.06C15.1097 20.9275 15.1594 20.7598 15.147 20.5983C16.4721 21.4161 18.4141 21.9255 20.6149 21.9255C22.7888 21.9255 24.7102 21.4286 26.0331 20.6294C26.0414 20.7681 26.0932 20.9068 26.1946 21.0166C26.499 21.3458 26.6522 21.6812 26.6522 22.0166C26.6522 23.3147 24.1739 24.7598 20.6149 24.7598Z"
        fill="#7C7C7C"
      />
      <rect x="4" y="2" width="32" height="32" rx="16" fill="white" />
      <path
        d="M20 12C16.9379 12 14.5388 13.3121 14.5388 14.9876V21.0124C14.5388 22.6879 16.9379 24 20 24C23.0621 24 25.4612 22.6879 25.4612 21.0124V14.9876C25.4596 13.3121 23.0621 12 20 12ZM15.4705 17.9565C15.4705 17.7158 15.5761 17.4752 15.7842 17.2391C15.8509 17.1646 15.8866 17.073 15.8975 16.9814C16.8913 17.5963 18.3478 17.9783 20 17.9783C21.6366 17.9783 23.0838 17.6025 24.0761 16.9984C24.0947 17.0745 24.1289 17.146 24.1848 17.2081C24.413 17.455 24.5279 17.7065 24.5279 17.9581C24.5279 18.9301 22.6677 20.014 19.9984 20.014C17.3292 20.014 15.4705 18.9286 15.4705 17.9565ZM20 12.9317C22.6693 12.9317 24.5295 14.0155 24.5295 14.9876C24.5295 15.9596 22.6693 17.045 20 17.045C17.3307 17.045 15.4705 15.9612 15.4705 14.9876C15.4705 14.014 17.3307 12.9317 20 12.9317ZM20 23.0699C17.3307 23.0699 15.4705 21.986 15.4705 21.0124C15.4705 20.7717 15.5761 20.5311 15.7842 20.295C15.8711 20.1957 15.9084 20.0699 15.8991 19.9488C16.8929 20.5621 18.3494 20.9441 20 20.9441C21.6304 20.9441 23.0714 20.5714 24.0637 19.972C24.0699 20.0761 24.1087 20.1801 24.1848 20.2624C24.413 20.5093 24.5279 20.7609 24.5279 21.0124C24.5279 21.986 22.6693 23.0699 20 23.0699Z"
        fill="#7C7C7C"
      />
      <g filter="url(#filter0_d_20123_8206)">
        <rect x="4" y="2" width="32" height="32" rx="16" fill="white" />
        <path
          d="M20 12C16.9379 12 14.5388 13.3121 14.5388 14.9876V21.0124C14.5388 22.6879 16.9379 24 20 24C23.0621 24 25.4612 22.6879 25.4612 21.0124V14.9876C25.4596 13.3121 23.0621 12 20 12ZM15.4705 17.9565C15.4705 17.7158 15.5761 17.4752 15.7842 17.2391C15.8509 17.1646 15.8866 17.073 15.8975 16.9814C16.8913 17.5963 18.3478 17.9783 20 17.9783C21.6366 17.9783 23.0838 17.6025 24.0761 16.9984C24.0947 17.0745 24.1289 17.146 24.1848 17.2081C24.413 17.455 24.5279 17.7065 24.5279 17.9581C24.5279 18.9301 22.6677 20.014 19.9984 20.014C17.3292 20.014 15.4705 18.9286 15.4705 17.9565ZM20 12.9317C22.6693 12.9317 24.5295 14.0155 24.5295 14.9876C24.5295 15.9596 22.6693 17.045 20 17.045C17.3307 17.045 15.4705 15.9612 15.4705 14.9876C15.4705 14.014 17.3307 12.9317 20 12.9317ZM20 23.0699C17.3307 23.0699 15.4705 21.986 15.4705 21.0124C15.4705 20.7717 15.5761 20.5311 15.7842 20.295C15.8711 20.1957 15.9084 20.0699 15.8991 19.9488C16.8929 20.5621 18.3494 20.9441 20 20.9441C21.6304 20.9441 23.0714 20.5714 24.0637 19.972C24.0699 20.0761 24.1087 20.1801 24.1848 20.2624C24.413 20.5093 24.5279 20.7609 24.5279 21.0124C24.5279 21.986 22.6693 23.0699 20 23.0699Z"
          fill="#7C7C7C"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_20123_8206"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.451725 0 0 0 0 0.451725 0 0 0 0 0.451725 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_20123_8206" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_20123_8206"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
