import _ from "lodash";

/**
 * App Events
 * @class
 */
class AppEvents {
  /**
   * All Subscriptions
   * @member subscribers
   */
  subscribers: Record<string, any>;
  constructor() {
    this.subscribers = {};
  }

  /**
   * publish an event
   * @function publish
   * @param {string} event event
   * @param {...any} rest rest
   */
  publish = (event: string, ...rest: any) => {
    console.log(`[EventBus] event: ${event}`, rest);
    if (_.has(this.subscribers, event)) {
      _.forOwn(_.get(this.subscribers, event), (callback: any) => {
        try {
          if (_.isFunction(callback)) {
            callback?.(...rest);
          } else {
            throw new Error(`${event} Not a Function`);
          }
        } catch (ex) {
          console.log(`[Eventbus] ${ex}`);
        }
      });
    }
  };

  /**
   * Subscribes an event
   * @function subscribe
   * @param {event} event event
   * @param {Function} callback callback
   */
  subscribe = (event: string, callback: any) => {
    if (!_.isFunction(callback)) {
      console.log(`[Eventbus] ${callback} is not a function`);
      return;
    }

    let listeners = _.get(this.subscribers, event);
    listeners = _.isArray(listeners) ? [...listeners, callback] : [callback];
    _.setWith(this.subscribers, event, listeners, Object);
  };

  /**
   * Un-subscribes an event
   * @param {string} event event
   */
  unsubscribe = (event: string) => {
    _.unset(this.subscribers, event);
  };
}

export default new AppEvents();
