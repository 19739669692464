import React from "react";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

type Props = {
  isDeleting: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
};

export const UpdateDataAppEnvironmentWarningDialog = ({
  isDeleting,
  handleClose,
  handleSubmit
}: Props) => {
  return (
    <Modal
      open={true}
      variant={ModalVariants.Delete}
      title="Update Environment"
      content={[
        "Environment changes trigger DataApp shutdown (if already running) and subsequent relaunch for updates to take into effect.",
        "Do you still want to continue?"
      ]}
      cancelLabel="Cancel"
      submitLabel="Update"
      isSubmitting={isDeleting}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};
