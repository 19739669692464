import React, { useState } from "react";
import { Grid, FormControlLabel, Checkbox, Link, Typography, makeStyles } from "@material-ui/core";

import Modal from "src/components/custom/Modal/Modal";
import { Notes } from "@material-ui/icons";
import { postAPIWithRethrow } from "../../utils/apiService";

const useStyles = makeStyles(() => ({
  container: {
    "& > div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "6px 16px",
      gap: "12px",
      width: "100%",
      height: "48px",
      background: "#EAF4FC",
      borderRadius: "4px",
      margin: "16px 0 24px 0",
      "& svg": {
        background: "#123553",
        fill: "#EAF4FC",
        borderRadius: "4px",
        padding: "0 1px",
        width: "24px"
      },
      "& a": {
        color: "#123553",
        textDecoration: "underline",
        fontSize: 14
      },
      "& + .MuiFormControlLabel-root": {
        marginLeft: 0
      },
      "& .MuiFormControlLabel-label": {
        color: "rgba(0, 0, 0, 0.54)"
      }
    },
    "& > .MuiGrid-item": {
      padding: "8px 8px 20px 8px"
    }
  }
}));

type Props = {
  onClose?: $TSFixMeFunction;
};

const ModalAgreement = ({ onClose }: Props) => {
  const classes = useStyles();

  const [agreement, setAgreement] = useState(false);

  const handleSubmit = async (e: $TSFixMe) => {
    e.preventDefault();
    try {
      // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 1.
      await postAPIWithRethrow("/v2/user-eula/");
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClose(true);
    } catch {}
  };

  const handleCloseAttempt = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onClose(false);
  };

  const handleAgreementChange = (e: $TSFixMe) => {
    setAgreement(e.target.checked);
  };
  return (
    <Modal
      open
      title="Service Agreement Update"
      onClose={handleCloseAttempt}
      cancelLabel="Close"
      submitLabel="Proceed to Log In"
      isSubmitDisabled={!agreement}
      onSubmit={handleSubmit}>
      <Grid container className={classes.container}>
        <Typography color="textPrimary">
          We’ve updated our Platform Service Agreement. By continuing to use our services, you
          acknowledge these updates.
        </Typography>
        <Typography color="textPrimary">
          Please review our Service Agreement for more details.
        </Typography>
        <div>
          <Notes />
          <Link
            href="https://rapidcanvas.ai/services-agreement/"
            color="primary"
            target="_blank"
            rel="noreferrer">
            RapidCanvas Service Agreement
          </Link>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreement}
              onChange={handleAgreementChange}
              color="default"
              size="small"
            />
          }
          label={<Typography>I’ve read and accept RapidCanvas Service Agreement</Typography>}
        />
      </Grid>
    </Modal>
  );
};

export default ModalAgreement;
