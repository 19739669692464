import React from "react";
import { isEmpty } from "lodash";
import { Badge, Box, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { AiGuideMiniIcon } from "src/icons/NewUX";
import { useThreadsStore } from "src/store/store";

type StyleProps = { badgeStyleProps?: any; badgeDotStyleProps?: any };

const useStyles = makeStyles({
  aiGuideIcon: {
    height: "28px",
    scale: 1.4
  },
  aiGuideBtn: {
    height: "28px",
    width: "28px"
  },

  badgeRoot: (props: StyleProps) => ({
    marginTop: 2,
    marginRight: 2,
    ...(!isEmpty(props?.badgeStyleProps) ? props?.badgeStyleProps : {})
  }),

  badge: (props: StyleProps) => ({
    backgroundColor: "#FF0000",
    border: "1px solid #fff",
    ...(!isEmpty(props?.badgeDotStyleProps) ? props?.badgeDotStyleProps : {})
  })
});

export const AIGuideMiniButton = ({
  onClick,
  projectId,
  targetId,
  chartId,
  style,
  width = 24,
  height = 16,
  viewBox = "4 -2 48 48",
  disabled,
  tooltip,
  badgeStyleProps = {},
  badgeDotStyleProps = {}
}: {
  onClick: (event: any) => void;
  projectId: string;
  targetId: string;
  style?: any;
  width?: number;
  height?: number;
  viewBox?: string;
  disabled?: boolean;
  tooltip?: string;
  chartId?: string;
  badgeStyleProps?: { [key: string]: any };
  badgeDotStyleProps?: { [key: string]: any };
}) => {
  const { aiGuideIcon, aiGuideBtn, badgeRoot, badge } = useStyles({
    badgeStyleProps,
    badgeDotStyleProps
  });

  const { projectTargetMap, chartIdMessagesCountMap } = useThreadsStore();
  const messageCount = chartId
    ? chartIdMessagesCountMap.get(projectId)?.get(targetId)?.get(chartId)
    : projectTargetMap.get(projectId)?.get(targetId);

  return (
    <Tooltip title={tooltip || "AI Guide"}>
      <Badge
        variant="dot"
        color="error"
        classes={{ root: badgeRoot, colorError: badge }}
        invisible={!messageCount || messageCount === 0}
        overlap="circular">
        <IconButton
          size="small"
          style={style}
          onClick={onClick}
          disabled={disabled}
          data-testid="sidebar-entity-ai-guide-icon"
          className={aiGuideBtn}>
          <Box className={aiGuideIcon}>
            <AiGuideMiniIcon viewBox={viewBox} width={width} height={height} />
          </Box>
        </IconButton>
      </Badge>
    </Tooltip>
  );
};
