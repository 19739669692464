import { useMutation } from "@tanstack/react-query";

import { putAPIWithRethrow } from "src/utils/apiService";

export const useLicenseUpdateMutation = () => {
  return useMutation({
    mutationFn: ({ licenseKey }: { licenseKey: string }) => {
      return putAPIWithRethrow(`/license/attach-license`, { licenseKey });
    }
  });
};
