import React from "react";
import { makeStyles, TextField, TextFieldProps, StandardTextFieldProps } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiFilledInput-root": {
      borderRadius: 4,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent"
      },
      "&.Mui-focused>div": {
        backgroundColor: "transparent"
      }
    }
  }
}));

const Field = ({ id, variant = "filled", className, InputProps, ...rest }: TextFieldProps) => {
  const classes = useStyles();

  return (
    <TextField
      id={id}
      name={id}
      variant={variant as StandardTextFieldProps["variant"]}
      className={clsx(classes.textField, className)}
      InputProps={{ disableUnderline: true, ...InputProps }}
      {...rest}
    />
  );
};

export default Field;
