import { getAPIWithRethrow } from "src/utils/apiService";
import { useQuery } from "@tanstack/react-query";

export const useGetAIGuideRecipeCharts = () => {
  return useQuery<string[]>({
    queryKey: ["ai-guide-rapid-recipe-charts"],
    queryFn: async () => {
      return await getAPIWithRethrow("/v2/ai-guide/rapid-model-charts");
    },
    cacheTime: Infinity
  });
};
