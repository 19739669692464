import React, { useState } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import AIGuideOutputWrap from "./AIGuideOutputWrap";
import Chart from "./Chart";
import { ChartDto, ExtractedChartDto } from "./GroupedCharts";

const useStyles = makeStyles({
  chartsWrap: {
    overflow: "auto",
    visibility: "hidden",
    opacity: 0,
    position: "absolute",
    zIndex: 1
  },
  selectedChart: {
    display: "block",
    visibility: "visible",
    opacity: 1,
    position: "relative",
    zIndex: 2
  },
  icon: {
    height: "32px",
    width: "24px !important"
  },
  chartWrap: {
    margin: "5px 12px 5px 12px",
    overflowY: "auto",
    borderRadius: "2px"
  }
});

interface IProps {
  chartList: (ChartDto | ExtractedChartDto)[];
  askAIMessageId: string | undefined;
  isEditChart?: boolean;
  subThreadId: string | undefined;
  onClose?: () => void;
  parentAskAIMessageID?: string;
  parentThreadId?: string;
  isSaveDisabled?: boolean;
  isAddedtoRecipe: boolean | undefined;
  isAiGuide: boolean;
}

const Charts: React.FC<IProps> = ({
  chartList,
  askAIMessageId,
  isEditChart = false,
  subThreadId,
  onClose,
  parentAskAIMessageID,
  parentThreadId,
  isSaveDisabled = false,
  isAddedtoRecipe,
  isAiGuide
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);

  if (chartList?.length === 1) {
    const chart: any = chartList?.[0];
    return (
      <Box className={classes.chartWrap}>
        <Chart
          askAIMessageId={askAIMessageId}
          parentAskAIMessageID={parentAskAIMessageID}
          isAddedtoRecipe={isAddedtoRecipe}
          plotlyJson={chart.plotlyJson}
          name={chart.name}
          dataFilePath={chart.dataFilePath}
          chartType={chart.chartType}
          isEditChart={isEditChart}
          subThreadId={subThreadId}
          codeName={chart.codeName}
          onClose={onClose}
          parentThreadId={parentThreadId}
          isSaveDisabled={isSaveDisabled}
          isAIGuide={isAiGuide}
        />
      </Box>
    );
  }
  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    event.stopPropagation();
  };

  return (
    <Grid onScroll={handleScroll}>
      <AIGuideOutputWrap tabNames={chartList} value={value} handleChange={setValue}>
        {chartList.map((chart: any, index) => (
          <Box
            height="100%"
            key={index}
            className={clsx(classes.chartsWrap, {
              [classes.selectedChart]: value === index
            })}>
            <Chart
              askAIMessageId={askAIMessageId}
              parentAskAIMessageID={parentAskAIMessageID}
              isAddedtoRecipe={isAddedtoRecipe}
              plotlyJson={chart.plotlyJson}
              name={chart.name}
              dataFilePath={chart.dataFilePath}
              chartType={chart.chartType}
              isEditChart={isEditChart}
              subThreadId={subThreadId}
              codeName={chart.codeName}
              onClose={onClose}
              parentThreadId={parentThreadId}
              isSaveDisabled={isSaveDisabled}
              isAIGuide={isAiGuide}
            />
          </Box>
        ))}
      </AIGuideOutputWrap>
    </Grid>
  );
};

export default React.memo(Charts);
