import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Table } from "src/components";

export interface IData {
  key: string;
  value: string | JSX.Element | number;
}

interface IProps {
  title?: string;
  data: IData[];
}

const useStyles = makeStyles({
  table: {
    '& [class*="MuiPaper-elevation1"]': {
      boxShadow: "none"
    },
    '& [class*="MuiTypography-subtitle1"]': {
      marginLeft: "10px",
      fontSize: "18px",
      borderBottom: "1px solid rgba(203, 210, 214, 0.6)",
      paddingLeft: "16px",
      fontWeight: 500
    },

    "& tr td:first-child": {
      fontWeight: 600
    }
  }
});

const columns = [
  {
    id: "key",
    accessor: "key"
  },
  {
    id: "value",
    accessor: "value"
  }
];

const Description: React.FC<IProps> = (props) => {
  const { title, data } = props;
  const { table } = useStyles();

  return (
    <div className={table}>
      {title && (
        <Typography variant="subtitle1" component="div">
          {title}
        </Typography>
      )}
      <Table data={data} size="small" hideCount columns={columns} noHeaders unsorted />
    </div>
  );
};

export default Description;
