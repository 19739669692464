import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  notificationsWrapperContainer: {
    border: "1px solid #AFAFAF",
    boxShadow:
      "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    borderRadius: 4,
    width: "500px",
    backgroundColor: "#F5F5F5"
  },
  notificationsSubContainer: {
    rowGap: 5,
    padding: 15
  },
  showingCountInfoText: {
    opacity: 0.5
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    maxHeight: "450px",
    overflowY: "auto",
    overflowX: "hidden",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",

    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "10px",
    borderRadius: "6px",
    backgroundColor: "#FFFAE5",

    "&.read": {
      backgroundColor: "#FFF"
    },

    "&.hovered": {
      boxShadow: "0px 0px 0px 1px #4646B5 inset",
      backgroundColor: "#FAFAFF",

      "& a": {
        textDecoration: "none",
        color: "#4646B5"
      }
    }
  },
  created: {
    color: "#7c7c7c",
    fontWeight: 400,
    fontSize: "12px"
  },
  textLink: {
    color: "black",
    fontSize: "14px",
    cursor: "pointer",
    "& a": {
      textDecoration: "none"
    }
  },
  closeIcon: {
    fontSize: "small",
    color: "#515151"
  },
  badgeTop: {
    background: "grey",
    color: "white"
  },
  contentWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  margin: {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  btn: {
    width: "19px",
    height: "19px"
  },
  label: {
    width: "unset"
  },

  noAlert: {
    alignItems: "center",
    justifyContent: "center",
    minHeight: "350px",
    display: "flex"
  },
  text: {
    color: "#515151",
    fontSize: "12px"
  },
  contentLabel: {
    maxWidth: "280px",
    textTransform: "capitalize",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  tooltip: {
    "& *": {
      color: "#fff !important",
      fontSize: "10px !important"
    }
  },
  buttons: {
    display: "flex",
    gap: "16px",

    "& button": {
      padding: "4px 12px",
      borderRadius: "20px"
    }
  }
}));

export { useStyles };
