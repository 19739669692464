import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";

import { CodeIcon } from "src/icons/CodeIcon";
import Lottie from "react-lottie-player";

import data from "./askai-loader.json";

interface IProps {
  testId?: string;
  title: string;
  transformX?: string;
  data?: any;
}

type StyleProps = {
  transformX?: string;
};

const defaultOptions = {
  loop: true,
  play: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  },
  style: { width: "128px" }
};

const useStyles = makeStyles<undefined, StyleProps>({
  loader: {
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: "12px 32px 16px 16px",
    gap: "20px"
  },
  iconWrap: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    background: "#fff",
    borderRadius: "50%"
  },
  loaderItem: {
    width: "calc(100% - 156px)"
  },
  codeBox: {
    background: "#fff",
    height: "300px",
    border: "1px solid #fff",
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "4px"
  },
  loadingTextBox: {
    zIndex: 1,
    marginTop: "64px",
    color: "#fff"
  },
  codeIcon: {
    left: "12px",
    height: "48px",
    position: "absolute"
  },
  title: {
    color: "#000"
  }
});

const GenerateLoader: React.FC<IProps> = (props) => {
  const { testId, title, transformX, data } = props;
  const classes = useStyles({ transformX });

  return (
    <Grid test-id={testId} container direction="column" className={classes.loader}>
      <Grid className={classes.iconWrap}>
        <CodeIcon fill="#7C7C7C" />
      </Grid>

      <Grid container direction="column" justifyContent="center" className={classes.loaderItem}>
        <Grid container className={classes.codeBox}>
          <Grid
            container
            direction="column"
            alignItems="center"
            wrap="nowrap"
            className={classes.loadingTextBox}>
            <Grid container direction="column" alignItems="center" wrap="nowrap">
              <Lottie {...defaultOptions} animationData={data || defaultOptions.animationData} />
            </Grid>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { GenerateLoader };
export default GenerateLoader;
