import { deleteAPI, getAPI, postAPI, putAPI } from "../../utils/apiService";

export const getUDFS = async (params: $TSFixMe) =>
  await getAPI(`/udfs${params ? `?${params}` : ""}`);

export const createUDFS = async (payload: $TSFixMe) => await postAPI("/udfs", payload);

export const updateUDFS = async (payload: $TSFixMe) => await putAPI("/udfs", payload);

export const getTransformsTemplates = async (templateName: $TSFixMe) =>
  await getAPI("/dfs-templates" + (templateName ? `?name=${templateName}` : ""));

export const postTransformTemplate = async (payload: $TSFixMe) =>
  await postAPI("/dfs-templates", payload);

export const putTransformTemplate = async (payload: $TSFixMe) =>
  await putAPI("/dfs-templates?id=" + payload.id, payload);

export const applyTransformsTemplate = async (pipelineId: $TSFixMe, name: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await postAPI("/apply-all-dfs-templates?" + "pipelineId=" + pipelineId + "&name=" + name);

export const getUDFsTransforms = async (pipelineId: $TSFixMe) =>
  await getAPI("/simplified-transform2s" + (pipelineId ? `?pipelineId=${pipelineId}` : ""));

export const applyUDFsTransforms = async (payload: $TSFixMe) =>
  await postAPI("/simplified-transform2s", payload);

export const updateAppliedTransform = async (payload: $TSFixMe) =>
  await putAPI("/simplified-transform2s", payload);

export const deleteAppliedTransform = async (id: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await deleteAPI(`/simplified-transform2s?id=${id}`);

export const testUDFsTransforms = async (payload: $TSFixMe, entityId: $TSFixMe) =>
  await postAPI(`/test-pipeline-scalar/${entityId}`, payload);

export const testDfsTransforms = async (payload: $TSFixMe) =>
  await postAPI(`/test-pipeline-scalar-run-config`, payload);

export const getDfsTransforms = async () => await getAPI(`/dfs-templates?status=ACTIVE`);
