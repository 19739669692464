/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'created'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'updater'?: string;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'updated'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'notebookType'?: TenantNotebookTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    'aiModelType'?: TenantAiModelTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'cpuGuarantee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'memGuarantee'?: number;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'dataappsTtlInHrs'?: number;
    /**
     * 
     * @type {number}
     * @memberof Tenant
     */
    'notebookSharedDiskSizeInMbs'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    'notebookSharedDiskExists'?: boolean;
}

export const TenantNotebookTypeEnum = {
    Tenant: 'TENANT',
    User: 'USER',
    Both: 'BOTH'
} as const;

export type TenantNotebookTypeEnum = typeof TenantNotebookTypeEnum[keyof typeof TenantNotebookTypeEnum];
export const TenantAiModelTypeEnum = {
    Auto: 'AUTO',
    Gpt35: 'GPT_3_5',
    Gpt4: 'GPT_4',
    Gpt4O: 'GPT_4_O',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type TenantAiModelTypeEnum = typeof TenantAiModelTypeEnum[keyof typeof TenantAiModelTypeEnum];


