import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { OverflowTooltip, Switch } from "src/components";
import { capitalizeFirstLetter } from "src/utils/capitalize";

const useStyles = makeStyles({
  permissions: {
    backgroundColor: "#ffffff",
    padding: "16px",
    gap: "16px",
    borderRadius: "4px"
  },
  permissionKey: {
    opacity: "50%",
    color: "#000000",
    fontSize: "14px"
  }
});
export const Permissions = ({ metadata }: { metadata: $TSFixMe }) => {
  const classes = useStyles();
  const booleanPermissions = metadata
    ? Object.keys(metadata).reduce(
        (acc: Array<{ metadataKey: string; value: boolean }>, metadataKey) => {
          const metadataValue: boolean = metadata[metadataKey];
          return typeof metadataValue === "boolean"
            ? [
                ...acc,
                {
                  metadataKey: capitalizeFirstLetter(
                    metadataKey?.split(/(?=[A-Z])/)?.join(" ") || ""
                  ),
                  value: metadataValue
                }
              ]
            : acc;
        },
        []
      )
    : [];
  return booleanPermissions?.length && booleanPermissions?.length !== 0 ? (
    <Grid container direction="column" className={classes.permissions}>
      <Grid item>
        <Typography variant="h6" color="textPrimary">
          Permissions
        </Typography>
      </Grid>
      {booleanPermissions.map(({ metadataKey, value }) => (
        <Grid container key={metadataKey}>
          <Grid item xs={3}>
            <OverflowTooltip
              value={<Typography className={classes.permissionKey}>{metadataKey}</Typography>}
            />
          </Grid>
          <Grid item>
            <Switch checked={value} disabled />
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : (
    <React.Fragment />
  );
};
