import React, { useEffect, useMemo, useState } from "react";

import clsx from "clsx";

import { Grid, Typography, makeStyles } from "@material-ui/core";
import Pagination, { PaginationRenderItemParams } from "@material-ui/lab/Pagination";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DescriptionIcon from "@material-ui/icons/Description";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

import { DatasetKeys, OntologyDatasetStatuses } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import useHelpers from "../../hooks/useHelpers";

const useStyles = makeStyles(() => ({
  stepperContainer: {
    display: "flex",
    width: "40%",
    justifyContent: "center"
  }
}));

const useDatasetStepsPaginationStyles = makeStyles({
  root: {
    "& [class^='MuiPagination-ul']": {
      columnGap: 8,
      "& .stepIconContainer": {
        display: "flex",
        alignItems: "center",
        columnGap: 8,
        "& .stepIcon": {
          "&.active": {
            color: "#4646b5"
          },
          "&.error": {
            color: "#ff0000"
          },
          "&.visited": {
            color: "#4646b5"
          },
          "&.unvisited": {
            opacity: 0.5
          }
        },
        "& .stepCount": {
          "&.active": {
            fontWeight: "bold"
          },
          "&.unvisited": {
            opacity: 0.5
          },
          "&.error": {
            color: "#ff0000"
          }
        }
      }
    }
  }
});

const OntologySectionStepper = (props: $TSFixMe) => {
  const { ontologyDatasetIndex } = props || {};

  // Stores - STARTS >>
  const { datasetDatasetsStore } = useStoreSelectors();
  // << ENDS - Stores

  const { isOntologyProcessing } = useHelpers({ ontologyDatasetIndex });

  const [styleProps, setStyleProps] = useState({
    badgeContentContainerOpacity: 1,
    badgeContentContainerCursor: "pointer"
  });

  const classes: $TSFixMe = useStyles(styleProps);
  const datasetStepsPaginationClasses = useDatasetStepsPaginationStyles();

  useEffect(() => {
    const thisStyleProps = styleProps;
    thisStyleProps.badgeContentContainerOpacity = isOntologyProcessing ? 0.5 : 1;
    thisStyleProps.badgeContentContainerCursor = isOntologyProcessing ? "not-allowed" : "pointer";

    setStyleProps({ ...thisStyleProps });
  }, [isOntologyProcessing]);

  const renderStepItem = (item: PaginationRenderItemParams) => {
    const stepArrow =
      (datasetDatasetsStore || [])?.length !== item?.page ? <ChevronRightRoundedIcon /> : <></>;

    const isError = useMemo(() => {
      return (
        datasetDatasetsStore[item?.page - 1]?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
        OntologyDatasetStatuses.Failed
      );
    }, [item?.page, datasetDatasetsStore]);

    const stepCount = useMemo(
      () => (
        <Typography
          variant="subtitle2"
          className={clsx("stepCount", {
            active: item?.page === ontologyDatasetIndex + 1,
            unvisited: item?.page > ontologyDatasetIndex + 1,
            error: isError
          })}>
          {item?.page}
        </Typography>
      ),
      [item?.page, ontologyDatasetIndex, isError]
    );

    return (
      <>
        <div className="stepIconContainer">
          {["start-ellipsis", "end-ellipsis"]?.includes(item?.type) ? (
            <>
              <div>...</div>
              <ChevronRightRoundedIcon />
            </>
          ) : item?.page < ontologyDatasetIndex + 1 ? (
            <>
              <DescriptionIcon
                className={clsx("stepIcon", {
                  visited: !isError,
                  error: isError
                })}
              />
              {stepCount}
              {stepArrow}
            </>
          ) : (
            <>
              <DescriptionOutlinedIcon
                className={clsx("stepIcon", {
                  active: item?.selected,
                  unvisited: !item?.selected,
                  error: isError
                })}
              />
              {stepCount}
              {stepArrow}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Grid xs={6} className={classes.stepperContainer}>
      <Pagination
        className={datasetStepsPaginationClasses.root}
        count={(datasetDatasetsStore || [])?.length}
        page={ontologyDatasetIndex + 1}
        siblingCount={0}
        renderItem={renderStepItem}
        hidePrevButton
        hideNextButton
        disabled
      />
    </Grid>
  );
};

export default OntologySectionStepper;
