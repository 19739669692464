import { Dispatch, SetStateAction, useCallback } from "react";

// Packages
import useTheme from "@material-ui/core/styles/useTheme";
import { getConnectedEdges, getIncomers, isNode } from "react-flow-renderer";

// Import React Flow types
import { Node as ReactFlowNode, Edge as ReactFlowEdge } from "react-flow-renderer";

type Props = {
  setNodes: Dispatch<SetStateAction<ReactFlowNode[]>>;
  setEdges: Dispatch<SetStateAction<ReactFlowEdge[]>>;
};

const useHighlightPath = (props: Props) => {
  const { setNodes, setEdges } = props || {};

  const theme = useTheme();

  // Define a recursive function to get all incomer nodes and edges
  const getAllIncomers = (
    node: ReactFlowNode,
    nodes: ReactFlowNode[],
    edges: ReactFlowEdge[],
    prevIncomers: ReactFlowNode[] = []
  ): (ReactFlowNode | ReactFlowEdge)[] => {
    const incomerNodes = getIncomers(node, nodes, edges);
    const incomerEdges = getConnectedEdges(incomerNodes, edges).filter((item) => {
      const incomerIds = incomerNodes.map((i) => i.id);
      return incomerIds.includes(item.target) || item.target === node.id;
    });

    const result = [node, ...incomerNodes, ...incomerEdges].reduce(
      (memo, incomer) => {
        memo.push(incomer);

        if (prevIncomers.findIndex((n) => n.id === incomer.id) === -1) {
          prevIncomers.push(incomer as ReactFlowNode);

          getAllIncomers(incomer as ReactFlowNode, nodes, edges, prevIncomers)?.forEach(
            (foundNode: ReactFlowNode | ReactFlowEdge) => {
              memo.push(foundNode);
              if (prevIncomers.findIndex((n) => n.id === foundNode.id) === -1) {
                prevIncomers.push(foundNode as ReactFlowNode);
              }
            }
          );
        }
        return memo;
      },
      [] as (ReactFlowNode | ReactFlowEdge)[]
    );

    return result;
  };

  // The highlightPath function to set selected nodes/edges and style them
  const highlightPath = useCallback(
    (node: ReactFlowNode, nodes: ReactFlowNode[], edges: ReactFlowEdge[]) => {
      node.selected = true;

      if (node && [...nodes, ...edges]) {
        const allIncomers = getAllIncomers(node, nodes, edges);

        // Update node styles
        setNodes((prevElements: ReactFlowNode[]) => {
          return prevElements?.map((elem: ReactFlowNode) => {
            const incomerIds = allIncomers.map((i) => (i as ReactFlowNode).id);

            if (isNode(elem) && allIncomers.length > 0) {
              const highlight = elem.id === node.id || incomerIds.includes(elem.id);

              elem.style = {
                ...elem.style,
                opacity: highlight ? 1 : 0.25
              };
            }
            return elem;
          });
        });

        // Update edge styles
        setEdges((prevElements: ReactFlowEdge[]) => {
          return prevElements?.map((elem: ReactFlowEdge) => {
            const highlight = allIncomers.find(
              (item) =>
                (item as ReactFlowEdge).target === elem.target &&
                (item as ReactFlowEdge).source === elem.source
            );

            if (highlight) {
              elem.animated = true;
              elem.selected = true;

              elem.markerEnd = {
                // @ts-ignore
                ...elem.markerEnd,
                color: theme.palette.info.main
              };
            }

            elem.style = {
              ...elem.style,
              stroke: highlight ? theme.palette.info.main : "#c7c7c7",
              opacity: highlight ? 1 : 0.25
            };

            return elem;
          });
        });
      }
    },
    [setNodes, setEdges, theme]
  );

  return { highlightPath };
};

export default useHighlightPath;
