import React from "react";

const MagicGenerateIcon = ({
  fill = "white",
  opacity = 1
}: {
  fill?: string;
  opacity?: number;
}) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.00004 3.98334L3.33337 4.91668L4.26671 3.25001L3.33337 1.58334L5.00004 2.51668L6.66671 1.58334L5.73337 3.25001L6.66671 4.91668L5.00004 3.98334ZM13 10.5167L14.6667 9.58334L13.7334 11.25L14.6667 12.9167L13 11.9833L11.3334 12.9167L12.2667 11.25L11.3334 9.58334L13 10.5167ZM14.6667 1.58334L13.7334 3.25001L14.6667 4.91668L13 3.98334L11.3334 4.91668L12.2667 3.25001L11.3334 1.58334L13 2.51668L14.6667 1.58334ZM8.89337 8.77001L10.52 7.14334L9.10671 5.73001L7.48004 7.35668L8.89337 8.77001ZM9.58004 5.11001L11.14 6.67001C11.4 6.91668 11.4 7.35001 11.14 7.61001L3.36004 15.39C3.10004 15.65 2.66671 15.65 2.42004 15.39L0.860039 13.83C0.600039 13.5833 0.600039 13.15 0.860039 12.89L8.64004 5.11001C8.90004 4.85001 9.33337 4.85001 9.58004 5.11001Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

export default MagicGenerateIcon;
