import React from "react";

// Packages
import { isEmpty } from "lodash";

// MUI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

// Components
import SubTopNavBarLeftSection from "./SubTopNavBarLeftSection";
import SubTopNavBarRightSection from "./SubTopNavBarRightSection";

// Utils
import { isDrawerVariant } from "./SubTopNavBar.helpers";

// Constants
import { SubTopNavBarVariants } from "./SubTopNavBar.constants";

// Types
import { StyleProps, SubTopNavBarProps } from "./SubTopNavBar.types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: (props: StyleProps) => (isDrawerVariant(props?.variant) ? "absolute" : "fixed"),
    marginTop: (props: StyleProps) => (isDrawerVariant(props?.variant) ? 0 : theme.spacing(6)),
    backgroundColor: (props: StyleProps) =>
      isDrawerVariant(props.variant)
        ? theme.palette.common.white
        : theme.palette.background.default,
    color: theme.palette.common.black
  }
}));

const SubTopNavBar = (props: SubTopNavBarProps) => {
  const {
    variant = SubTopNavBarVariants.Page,
    onDrawerClose,
    subTopNavBarLeftSection,
    subTopNavBarRightSection
  } = props || {};

  const classes = useStyles({ variant });

  return (
    <AppBar elevation={1} className={classes.root}>
      <Toolbar variant="dense" disableGutters>
        {!isEmpty(subTopNavBarLeftSection) && (
          <SubTopNavBarLeftSection variant={variant} {...subTopNavBarLeftSection} />
        )}
        {(!isEmpty(subTopNavBarRightSection) || isDrawerVariant(variant)) && (
          <SubTopNavBarRightSection
            variant={variant}
            onDrawerClose={onDrawerClose}
            {...subTopNavBarRightSection}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default SubTopNavBar;
