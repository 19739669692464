import React, { useMemo } from "react";
import _ from "lodash";

import { ALERTS, TAGS } from "../utils/Eda.constants";
import { Table } from "src/components";

interface IProps {
  alerts: string[];
}

const getTagAndRecommendation = (text: string) => {
  for (const tag of TAGS) {
    const alert = _.get(ALERTS, tag);
    if (
      alert?.match &&
      _.some(alert?.match, (word) => _.includes(_.toLower(text), _.toLower(word)))
    ) {
      return {
        tag: (
          <span
            style={{
              padding: "3px 6px",
              color: "white",
              fontWeight: 500,
              borderRadius: "3px",
              fontSize: "12px",
              backgroundColor: alert.backgroundColor
            }}>
            {alert.tagging}
          </span>
        ),
        recommendation: alert.recommendation
      };
    }
  }

  return { tag: "Unknown", recommendation: null };
};

const getAlertDetails = (text: string) => {
  const regex = /(\[[^\]]+])/g;

  const parts = text.split(regex);
  const alert = _.map(parts, (part, index) => {
    const match = part.match(regex);

    if (match) {
      const variable = match[0].replace(/\[|\]/g, "");

      return (
        <span
          key={index}
          style={{
            color: "#c7254e",
            backgroundColor: "#f9f2f4",
            borderRadius: "3px",
            padding: "3px 6px"
          }}>
          {variable}
        </span>
      );
    } else {
      return part;
    }
  });

  return alert;
};

const columns = [
  { id: "alert", accessor: "alert", Header: "Alert", isTooltip: false },
  { id: "tag", accessor: "tag", Header: "Tagging", width: "50px", isTooltip: false },
  { id: "recommendation", accessor: "recommendation", Header: "Recommendation" }
];

const EdaAlerts: React.FC<IProps> = (props) => {
  const { alerts } = props;

  const data = useMemo(() => {
    return _.map(alerts, (ale) => {
      const alert = getAlertDetails(ale);

      return {
        alert,
        ...getTagAndRecommendation(ale)
      };
    });
  }, [alerts]);

  return (
    <Table data={data} size="small" hideCount columns={columns} maxHeight="calc(100vh - 350px)" />
  );
};

export default EdaAlerts;
