import React from "react";
import { Typography } from "@material-ui/core";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import { handleResponse } from "src/utils/apiService";
import { useDeleteTenantUserMutation } from "src/hooks/api";

const DeleteUserModal = (props: any) => {
  const { visible, onClose, user, onDeleteSuccess } = props;

  const deleteTenantUserMutation = useDeleteTenantUserMutation();

  const handleDelete = async () => {
    deleteTenantUserMutation.mutate(
      {
        userId: user.id,
        userType: user.userType
      },
      {
        onSuccess: () => {
          onDeleteSuccess(user.id);
          onClose();
          handleResponse({ successMessage: "Selected User removed successfully." });
        }
      }
    );
  };

  const isDeleting = deleteTenantUserMutation.isLoading;

  return (
    visible && (
      <Modal
        open={true}
        variant={ModalVariants.Delete}
        title="Remove User"
        content={[
          "Selected user will no longer have access to this tenant.",
          "Are you sure you want to remove this user?",
          <>
            <Typography variant="body2" style={{ fontStyle: "italic" }}>
              Note: Deleting the user will not delete the projects created by this user. It might
              also impact associated DataApps (if any)
            </Typography>
          </>
        ]}
        cancelLabel="Cancel"
        submitLabel="Remove"
        isSubmitting={isDeleting}
        isSubmitDisabled={isDeleting}
        onSubmit={handleDelete}
        onClose={onClose}
      />
    )
  );
};

export default DeleteUserModal;
