import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const VideoLibraryIcon: React.FC<IProps> = (props) => {
  const {
    width = 18,
    height = 18,
    viewBox = "0 0 16 16",
    fill = "none",
    color = "#7C7C7C"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2183 5.02808H5.78151C3.53164 5.02808 2.4067 5.02808 1.7748 5.68596C1.1429 6.34385 1.29157 7.36028 1.58893 9.39308L1.87091 11.3208C2.10409 12.9149 2.22069 13.712 2.81881 14.1893C3.41694 14.6667 4.29912 14.6667 6.06348 14.6667H9.93632C11.7007 14.6667 12.5829 14.6667 13.1811 14.1893C13.7792 13.712 13.8957 12.9149 14.1289 11.3208L14.4109 9.39308C14.7083 7.36028 14.8569 6.34384 14.2251 5.68596C13.5931 5.02808 12.4682 5.02808 10.2183 5.02808ZM9.72072 10.5296C10.093 10.2987 10.093 9.70135 9.72072 9.47055L7.47299 8.07715C7.11119 7.85288 6.66658 8.14482 6.66658 8.60662V11.3935C6.66658 11.8553 7.11119 12.1472 7.47299 11.9229L9.72072 10.5296Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M5.67312 1.33326H10.3265C10.4815 1.33322 10.6003 1.33319 10.7042 1.34335C11.4427 1.4156 12.0473 1.85964 12.3036 2.45777H3.69604C3.95238 1.85964 4.55688 1.4156 5.29543 1.34335C5.3993 1.33319 5.51813 1.33322 5.67312 1.33326Z"
        fill={color}
      />
      <path
        opacity="0.7"
        d="M4.20688 3.14844C3.2798 3.14844 2.51962 3.70827 2.26593 4.45096C2.26065 4.46644 2.25558 4.48201 2.25073 4.49764C2.51617 4.41726 2.79241 4.36475 3.07205 4.3289C3.7923 4.23656 4.70253 4.23661 5.75988 4.23667H10.3546C11.4119 4.23661 12.3222 4.23656 13.0424 4.3289C13.3221 4.36475 13.5983 4.41726 13.8637 4.49764C13.8589 4.48201 13.8538 4.46644 13.8485 4.45096C13.5949 3.70827 12.8347 3.14844 11.9076 3.14844H4.20688Z"
        fill={color}
      />
    </svg>
  );
};

export default VideoLibraryIcon;
