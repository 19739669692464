import { useQuery, useMutation, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import {
  getJobRunHistory,
  getJobRunHistoryDetails,
  getJobRunHistoryDetailsWithRethrow,
  getJobRunHistoryLogsWithRethrow,
  runJobWithRethrow,
  deleteJobRunWithRethrow
} from "src/api/projects";

interface JobRunsProps extends UseQueryOptions<$TSFixMe> {
  jobId: string;

  // useQuery options
  cacheTime?: number;
}

interface JobRunProps extends UseQueryOptions<$TSFixMe> {
  jobRunId?: string;
  isApiWithRethrow?: boolean;

  // useQuery options
  cacheTime?: number;
}

interface JobRunLogsProps extends UseQueryOptions<$TSFixMe> {
  jobRunId: string;

  // useQuery options
  cacheTime?: number;
}

type DeleteJobRunPayloadTypes = { jobRunId: string };

export const useGetJobRuns = (props: JobRunsProps): UseQueryResult<$TSFixMe> => {
  const {
    jobId,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;

  return useQuery({
    queryKey: ["job-runs", jobId],
    queryFn: () => getJobRunHistory(jobId),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled: !!jobId,
    ...options
  });
};

export const useGetJobRun = (props: JobRunProps): UseQueryResult<$TSFixMe> => {
  const {
    jobRunId,
    isApiWithRethrow = true,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;

  return useQuery({
    queryKey: ["job-run", jobRunId],
    queryFn: () =>
      isApiWithRethrow
        ? getJobRunHistoryDetailsWithRethrow(jobRunId)
        : getJobRunHistoryDetails(jobRunId),
    select: (data: $TSFixMe) => (Object.keys(data || {})?.length > 0 ? data : {}),
    cacheTime,
    enabled: !!jobRunId,
    ...options
  });
};

export const useGetJobRunLogs = (props: JobRunLogsProps): UseQueryResult<$TSFixMe> => {
  const {
    jobRunId,

    // useQuery options
    cacheTime = 0,
    ...options
  } = props;

  return useQuery({
    queryKey: ["job-run-logs", jobRunId],
    queryFn: () => getJobRunHistoryLogsWithRethrow(jobRunId),
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled: !!jobRunId,
    ...options
  });
};

export const useManualJobRun = () => {
  return useMutation({
    mutationFn: async (payload: $TSFixMe) => await runJobWithRethrow(payload)
  });
};

export const useDeleteJobRun = () => {
  return useMutation({
    mutationFn: async ({ jobRunId }: DeleteJobRunPayloadTypes) =>
      await deleteJobRunWithRethrow(jobRunId)
  });
};
