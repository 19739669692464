import React, { useEffect, useState, useMemo } from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  makeStyles
} from "@material-ui/core";

import { validateNameField } from "src/utils/formFieldUtils";

import {
  DatasetSessionConfig,
  DatasetKeys,
  DatasetHelperText
} from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import useHelpers from "../../hooks/useHelpers";

const useStyles = makeStyles(() => ({
  root: {
    width: 300
  },
  errorMessageContainer: {
    marginRight: 0,
    marginLeft: 0
  }
}));

const DatasetControl = (props: $TSFixMe) => {
  const {
    getDatasetSession,
    datasetIsFetchingOntologyDataStore,
    isFetchingSqlConfig,
    isDatasetCreated
  } = props || {};

  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const { datasetExistingDatasetsStore, datasetDatasetsStore } = useStoreSelectors();
  // << ENDS - Stores

  const { updateDatasetsSession } = useHelpers();

  // States - STARTS >>
  const [value, setValue] = useState<$TSFixMe>("");
  const [error, setError] = useState<$TSFixMe>("");
  // << ENDS - States

  const datasetSession = useMemo(() => getDatasetSession(), [getDatasetSession]);

  const datasetExistingDatasetsNames = useMemo(
    () =>
      (datasetExistingDatasetsStore || [])?.map((eachDataset: $TSFixMe) => eachDataset?.name) || [],
    [datasetExistingDatasetsStore]
  );

  const validateDatasetName = (value: $TSFixMe) => {
    const { isValid, error: errorMessage } = validateNameField({
      fieldName: value,
      fieldNameLabel: `dataset name`,
      maxFieldLength: 64
    });

    setError(() => (isValid ? "" : errorMessage));

    const fields: $TSFixMe = {};
    fields[DatasetKeys.IsValid] = isValid;

    if (isValid) {
      if (datasetExistingDatasetsNames.includes(value)) {
        setError(() => DatasetHelperText.DatasetNameErrorMessage);
        fields[DatasetKeys.IsValid] = false;
      } else {
        let prevDatasetName: $TSFixMe = "";
        const sqlConfigurationSession = sessionStorage.getItem(
          DatasetSessionConfig.SqlConfigurationSessionKey
        );

        if (sqlConfigurationSession) {
          prevDatasetName = JSON.parse(sqlConfigurationSession)?.datasetName || "";
        }

        fields[DatasetKeys.IsDirty] = prevDatasetName !== value;
      }
    }

    updateDatasetsSession({ index: 0, fields });
  };

  const onDatasetNameChange = (event: $TSFixMe) => {
    const thisValue = event?.target?.value;

    setValue(thisValue);
    validateDatasetName(thisValue);
  };

  const save = () => {
    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.Name] = value;
    fields[DatasetKeys.IsExisting] = datasetExistingDatasetsNames.includes(value);

    updateDatasetsSession({ index: 0, fields });
    // << Update datasets in session
  };

  useEffect(() => {
    const datasetName = datasetDatasetsStore[0]?.name || datasetSession?.name || "";
    setValue(() => datasetName);
    validateDatasetName(datasetName);
  }, []);

  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        color="primary"
        error={!(datasetDatasetsStore[0]?.isValid || datasetSession?.isValid)}
        className={classes.root}>
        <InputLabel id="sqlConfigurationDatasetLabel" htmlFor="sqlConfigurationDataset">
          {DatasetHelperText.Dataset}
        </InputLabel>
        <OutlinedInput
          id="sqlConfigurationDataset"
          type="text"
          value={value}
          readOnly={isDatasetCreated || datasetIsFetchingOntologyDataStore || isFetchingSqlConfig}
          onChange={onDatasetNameChange}
          onBlur={save}
          labelWidth={55}
        />
        {!(datasetDatasetsStore[0]?.isValid || datasetSession?.isValid) && (
          <FormHelperText className={classes.errorMessageContainer}>{error}</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default DatasetControl;
