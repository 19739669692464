/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EntityEDADto
 */
export interface EntityEDADto {
    /**
     * 
     * @type {string}
     * @memberof EntityEDADto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityEDADto
     */
    'maxRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityEDADto
     */
    'maxCols'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityEDADto
     */
    'cols'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EntityEDADto
     */
    'strategy'?: EntityEDADtoStrategyEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityEDADto
     */
    'projectRunEntryId'?: string;
}

export const EntityEDADtoStrategyEnum = {
    Auto: 'auto',
    Sample: 'sample',
    Full: 'full'
} as const;

export type EntityEDADtoStrategyEnum = typeof EntityEDADtoStrategyEnum[keyof typeof EntityEDADtoStrategyEnum];


