import { getEnvironmentUsage } from "src/api/environments";
import { EnvironmentsSessionConfig } from "src/constants/environments.constants";

export type ITypes = ("running job(s)" | "prediction job(s)" | "active recipe(s)")[];

interface EnvUsage {
  pdCreated: number;
  pdUpdated: number;
  projectCreated: number;
  projectCreator: string;
  projectId: string;
  projectName: string;
  projectRunList: any[];
  projectUpdated: number;
  projectUpdater: string;
  recipeCreated: number;
  recipeUpdated: number;
  runningJobCount: number;
  runningPredictionJobCount: number;
  runningRecipeCount: number;
  type: string;
}

export const environmentDetails = ({ environmentsTypes, env }: $TSFixMe = {}) => {
  if ((environmentsTypes || [])?.length === 0) {
    return {};
  }

  let output = environmentsTypes.find((type: $TSFixMe) => type?.name === env?.envType);

  if (Object.keys(output || {})?.length > 0) {
    return output;
  }

  return environmentsTypes.find(
    (type: $TSFixMe) => type?.cores === env?.cores && type?.memInMbs === env?.memInMbs
  );
};

export const getEnvironmentConfigSession = () => {
  let environmentConfigSession: $TSFixMe = [];

  if (!sessionStorage.getItem(EnvironmentsSessionConfig.EnvironmentConfigSessionKey)) {
    return environmentConfigSession;
  }

  try {
    environmentConfigSession =
      // @ts-ignore
      JSON.parse(sessionStorage.getItem(EnvironmentsSessionConfig.EnvironmentConfigSessionKey)) ||
      {};
  } catch (error: $TSFixMe) {
    console.error(error);
  }

  return environmentConfigSession;
};

export const setEnvironmentConfigSession = (environmentConfigSession: $TSFixMe = {}) => {
  sessionStorage.setItem(
    EnvironmentsSessionConfig.EnvironmentConfigSessionKey,
    JSON.stringify(environmentConfigSession)
  );
};

export const removeEnvironmentConfigSession = () => {
  sessionStorage.removeItem(EnvironmentsSessionConfig.EnvironmentConfigSessionKey);
};

export const getEnvironmentUsageDetails = async (id?: string): Promise<ITypes> => {
  if (!id) {
    return [];
  }

  const usageResponse: EnvUsage[] = await getEnvironmentUsage(id);

  const projectType = (usageResponse || [])?.filter(
    (eachEntry: EnvUsage) => eachEntry?.type === "PROJECT"
  );
  const recipeType = (usageResponse || [])?.filter(
    (eachEntry: EnvUsage) => eachEntry?.type === "RECIPE"
  );

  const areRunningJobs = projectType?.some((eachEntry: EnvUsage) => eachEntry?.runningJobCount > 0);
  const areRunningPredictionJobs = projectType?.some(
    (eachEntry: EnvUsage) => eachEntry?.runningPredictionJobCount > 0
  );
  const areRunningRecipes = recipeType?.some(
    (eachEntry: EnvUsage) => eachEntry?.runningRecipeCount > 0
  );

  const types: ITypes = [];

  if (areRunningJobs) {
    types.push("running job(s)");
  }
  if (areRunningPredictionJobs) {
    types.push("prediction job(s)");
  }
  if (areRunningRecipes) {
    types.push("active recipe(s)");
  }

  return types;
};
