import { map, has } from "lodash";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_ALL_PROJECT_MODELS = "query-key-all-project-models";

export interface Model {
  id: string;
  name: string;
  displayName: string;
  targetCol: string;
  isTimeSeriesParentRecipe: boolean;
}

const useGetAllProjectModels = (
  projectId?: string,
  options?: UseQueryOptions<Model[]>
): UseQueryResult<Model[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_ALL_PROJECT_MODELS, projectId],
    queryFn: async () => {
      const modelDetails = await getAPIWithRethrow(`/v2/project/${projectId}/all-models`);
      return map(modelDetails, ({ id, name, displayName, recipeVariables }) => ({
        id,
        name,
        displayName,
        targetCol: recipeVariables?.targetCol,
        isTimeSeriesParentRecipe:
          has(recipeVariables, "predictFrequency") && has(recipeVariables, "predictPeriod")
      }));
    },
    ...options,
    refetchOnMount: true
  });
};

export default useGetAllProjectModels;
export { QUERY_KEY_ALL_PROJECT_MODELS };
