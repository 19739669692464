import { postAPI } from "src/utils/apiService";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  tenantId: string;
}

const useJoinNewTenant = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ tenantId }) => {
      const data = await postAPI("/invitation/accept", { invitationDetails: "", tenantId });
      return data;
    }
  });
};

export default useJoinNewTenant;
