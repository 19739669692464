import _ from "lodash";
import { generatePath, useNavigate } from "react-router-dom";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { SYNC_ID } from "src/pages/DataSources/DataSources";
import { getDataSources, syncDataSource } from "src/api/dataSources";
import { useDataSourcesStore } from "src/store/store";
import { WebPaths } from "src/routing/routes";

const QUERY_KEY_SYNC_CONNECTOR = "query-key-sync-connector";

const useSyncConnector = (): UseQueryResult<null> => {
  const navigate = useNavigate();

  const setNewConnectorToSync = useDataSourcesStore((state) => state.setNewConnectorToSync);

  return useQuery({
    queryKey: [QUERY_KEY_SYNC_CONNECTOR],
    queryFn: async () => {
      const decodedUrl = decodeURIComponent(window.location.href);
      const searchParams = new URL(decodedUrl).searchParams;
      const name = searchParams.get(SYNC_ID);
      if (name) {
        const data = await getDataSources();

        const connectorId = _.find(data, { name })?.id;
        if (connectorId) {
          setNewConnectorToSync({ id: connectorId, created: Date.now() });
          navigate(generatePath(WebPaths.DataConnector, { id: connectorId }));
          await syncDataSource(connectorId);
        }
      }
      return null;
    }
  });
};

export default useSyncConnector;
export { QUERY_KEY_SYNC_CONNECTOR };
