import React from "react";

import { Grid, Button, makeStyles, CircularProgress } from "@material-ui/core";

import useHelpers from "../../../hooks/useHelpers";

import { SearchField } from "src/components";
import {
  DatasetHelperText,
  DatasetKeys,
  OntologyDatasetStatuses
} from "../../../utils/Dataset.constants";

const useStyles = makeStyles((theme) => ({
  root: {
    columnGap: theme.spacing(2),
    "& [class^='MuiButton-label']": {
      columnGap: 5
    }
  },

  // $FixMe: Scope to be refactored.
  // The below styles are added only to support New UX. It shall be removed soon.
  searchField: {
    "& [class^='MuiInputBase-root']": {
      background: "none",
      border: "1px solid #ccc",
      borderRadius: 4
    },
    "& input": {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: "small"
    }
  }
}));

const OntologyHeader = (props: $TSFixMe) => {
  const {
    datasetDefaultDatasetStore,
    ontologyDatasetIndex,
    dataset,
    searchTerm,
    setSearchTerm,
    updateDatasetLite
  } = props || {};

  const classes: $TSFixMe = useStyles();

  const { isOntologyProcessing } = useHelpers({ ontologyDatasetIndex });

  const onSearchChange = (event: $TSFixMe) => {
    setSearchTerm(event?.target?.value);
  };

  return (
    <>
      <Grid container className={classes.root}>
        {!datasetDefaultDatasetStore?.id &&
          dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.IsSchemaOrOntologyDirty] && (
            <Button
              id="datasetDataTypeAction"
              variant="contained"
              color="primary"
              disabled={
                dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
                OntologyDatasetStatuses.Updating
              }
              onClick={() => updateDatasetLite()}>
              {dataset?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] ===
              OntologyDatasetStatuses.Updating ? (
                <CircularProgress size={23} color="inherit" />
              ) : (
                DatasetHelperText.Apply
              )}
            </Button>
          )}
        <SearchField
          id="ontologyContainerSearch"
          placeholder="Search"
          className={classes.searchField}
          value={searchTerm}
          onChange={onSearchChange}
          disabled={isOntologyProcessing}
        />
      </Grid>
    </>
  );
};

export default OntologyHeader;
