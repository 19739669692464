import { Box, withStyles } from "@material-ui/core";

const StyledTooltip = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    fontSize: 12,
    borderRadius: 4,
    padding: "4px 8px",
    lineHeight: "16px",
    fontWeight: 400,
    "& p": {
      marginBottom: 0
    }
  }
}))(Box);

export default StyledTooltip;
