import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormGroup,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { DropResult } from "react-beautiful-dnd";
import { filter, forEach, get, has, map, omit, reduce, reverse, size, sortBy } from "lodash";

import ColumnFilterDragDropMenu from "./ColumnFilterDragDropMenu";
import { DataSourcesHelperText } from "src/pages/DataSources/utils/DataSources.constants";
import { IColumn } from "../TableSettingsMenu";
import { MAX_COLUMN_AMOUNT } from "../constants";

const useStyles = makeStyles({
  formControl: {
    width: "100%",
    minWidth: "300px",
    "& span[class*='MuiCheckbox-root']": {
      padding: "7px"
    }
  },
  header: {
    width: "100%",
    backgroundColor: "#fff"
  },
  legend: {
    padding: "8px 14px",
    color: "#7c7c7c"
  },
  list: {
    maxHeight: "50vh",
    maxWidth: "300px",
    overflowY: "auto",
    overflowX: "hidden"
  },
  listItem: {
    padding: "0 14px",
    "&:not(:first-child)": {
      borderTop: "1px solid #e1e1e1"
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& label[class*='MuiFormControlLabel-root']": {
      marginBottom: "0",
      width: "100%",
      overflow: "hidden",
      "& > span": {
        overflow: "hidden",
        whiteSpace: "nowrap"
      }
    }
  },
  search: {
    color: "#7c7c7c",
    minHeight: "34px",
    height: "100%",
    margin: "0px",
    width: "100%",
    borderTop: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "& fieldset": {
      border: "none"
    },
    "& div[class*='MuiInputBase-root']": {
      height: "34px",
      width: "100%",
      color: "#7c7c7c"
    },
    "& input[class*='MuiInputBase-input']": {
      height: "34px",
      fontSize: "14px",
      width: "100%"
    }
  },
  selectAllContainer: {
    padding: "0 4px",
    borderBottom: "1px solid #e1e1e1"
  }
});

interface ColumnFilterMenuProps {
  columns: IColumn[];
  isColumnLimitReached: boolean;

  setColumns: Dispatch<SetStateAction<IColumn[]>>;
  onColumnsChange: (columns: IColumn[]) => void;
  onFormControlChange: (e: React.ChangeEvent<{ checked: boolean; value: string }>) => void;
}

const ColumnFilterMenu = ({
  columns,
  isColumnLimitReached,
  setColumns,
  onFormControlChange,
  onColumnsChange
}: ColumnFilterMenuProps) => {
  const classes = useStyles();
  const [filterValue, setFilterValue] = useState("");

  const handleSearch = (value: string) => {
    setFilterValue(value);
  };

  const reorder = (list: IColumn[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result?.splice(startIndex, 1);
    result?.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result?.destination) {
      return;
    }
    const items = reorder(columns, result?.source?.index, result?.destination?.index);
    onColumnsChange(items);
  };

  const filteredColumnList = useMemo(() => {
    if (!filterValue) {
      return columns || [];
    }
    return (
      filter(columns, ({ name }) => name?.toLowerCase().includes(filterValue.toLowerCase())) || []
    );
  }, [filterValue, columns]);

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    const keyValueFiltered = reduce(
      filteredColumnList,
      (acc: Record<string, number>, column, index) => {
        acc[column.name] = index;
        return acc;
      },
      {}
    );

    if (isChecked) {
      if (filteredColumnList.length > MAX_COLUMN_AMOUNT) {
        setColumns(
          map(columns, (col) => {
            return {
              ...col,
              checked:
                has(keyValueFiltered, col.name) &&
                get(keyValueFiltered, col.name) < MAX_COLUMN_AMOUNT
            };
          })
        );
      } else {
        let count = 0;
        const returnCols: (IColumn & { index: number })[] = [];
        const differenceBy = reverse(filter(columns, (col) => !has(keyValueFiltered, col.name)));
        const keyValueColumns = reduce(
          columns,
          (acc: Record<string, number>, column, index) => {
            acc[column.name] = index;
            return acc;
          },
          {}
        );
        forEach([...filteredColumnList, ...differenceBy], (col) => {
          returnCols.push({
            ...col,
            checked: count < MAX_COLUMN_AMOUNT && (has(keyValueFiltered, col.name) || col.checked),
            index: get(keyValueColumns, col.name)
          });
          if (has(keyValueFiltered, col.name) || col.checked) {
            count = count + 1;
          }
        });
        setColumns(map(sortBy(returnCols, "index"), (col) => omit(col, "index")));
      }
    } else {
      setColumns(
        map(columns, (col) => {
          return {
            ...col,
            checked: has(keyValueFiltered, col.name) ? false : col.checked
          };
        })
      );
    }
  };

  const isCheckedAll = useMemo(
    () =>
      size(filter(filteredColumnList, (item: IColumn) => item.checked)) >=
      Math.min(MAX_COLUMN_AMOUNT, size(filteredColumnList)),
    [filteredColumnList]
  );

  return (
    <div>
      <FormControl className={classes.formControl} component="fieldset">
        <div className={classes.header}>
          <Typography variant="body2" className={classes.legend} component="legend">
            Columns
          </Typography>
          <TextField
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              onKeyDown: (e) => e.stopPropagation(),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              type: "search"
            }}
            data-testid="tableSettingsSearch"
            placeholder="Search column..."
            className={classes.search}
            id="column-search-input"
            name="column-search-input"
            variant="outlined"
          />
        </div>
        <FormGroup>
          <div className={classes.selectAllContainer}>
            <Grid container alignItems="center">
              <Checkbox
                data-testid="tableFilterSelectAll"
                size={"small"}
                color="primary"
                checked={isCheckedAll}
                onChange={handleCheckAll}
              />
              <Typography variant="body2">{DataSourcesHelperText.SelectAll}</Typography>
            </Grid>
          </div>
          <ColumnFilterDragDropMenu
            onDragEnd={handleDragEnd}
            filteredColumnList={filteredColumnList}
            isColumnLimitReached={isColumnLimitReached}
            onChange={(e) =>
              onFormControlChange(
                e as unknown as React.ChangeEvent<{
                  checked: boolean;
                  value: string;
                }>
              )
            }
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default ColumnFilterMenu;
