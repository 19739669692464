import { map } from "lodash";
import { monaco } from "react-monaco-editor";

const helperFunctionList = [
  "initH2o",
  "get_system_feature_store_redis_host",
  "addParam",
  "getAllParams",
  "getParam",
  "get_global_var",
  "get_secret",
  "getOrCreateArtifactsDir",
  "uploadArtifacts",
  "downloadArtifacts",
  "get_artifact",
  "getTenantId",
  "getOrCreateContext",
  "getAllEntities",
  "getEntityData",
  "get_data_from_source",
  "write_data_to_source",
  "get_datasource",
  "getEntityFilePath",
  "getChildDir",
  "getOutputCollection",
  "generate_warning",
  "save",
  "validate_artifact_added",
  "get_rc_ml_model",
  "getValueFromCacheMap",
  "createTemplateOutput",
  "create_template_output_rc_ml_model",
  "create_template_output_answer",
  "create_template_output_metadata",
  "create_template_output_artifact",
  "createTemplateOutputDataset",
  "create_template_output_chart",
  "createTemplateOutputEChart",
  "createTemplateOutputPlotlibChart",
  "createTemplateOutputPlotlyChart",
  "createTemplateOutputPlotlyChartAsJson",
  "createOutputCollection",
  "get_or_create_input_var",
  "get_or_create_input_column_var",
  "get_or_create_input_data_source_var",
  "get_or_create_chart_app_var",
  "get_or_create_input_dataset",
  "get_or_create_output_dataset",
  "get_or_create_output_chart",
  "save_all_vars",
  "get_metadata_value",
  "get_all_metadata"
];
export const helperSuggestions = map(helperFunctionList, (item) => ({
  label: item,
  kind: monaco.languages.CompletionItemKind.Function,
  insertText: `Helpers.${item}`,
  documentation: `${item} function`,
  command: {
    id: "auto-import",
    title: "Add Helper import",
    arguments: ["from utils.notebookhelpers.helpers import Helpers"]
  }
}));
export const addImportStatement = (code: string, importStatement: string) => {
  const lines = code.split("\n");

  // Check if the same import statement already exists
  if (lines.some((line) => line.trim() === importStatement.trim())) {
    return { updatedCode: code, linesAdded: 0 }; // Import already exists, no changes
  }

  let linesAdded = 0; // Initialize counter for lines added

  // If it's a "from ... import ..." statement, check for partial imports
  if (importStatement.startsWith("from")) {
    const [module, imported] = importStatement.replace("from ", "").split(" import ");

    // Find any existing "from <module> import ..." statements
    const existingFromImportIndex = lines.findIndex((line) =>
      line.startsWith(`from ${module} import`)
    );

    if (existingFromImportIndex >= 0) {
      // Merge the new import with the existing "from ... import ..." statement
      const existingImports = lines[existingFromImportIndex]
        .replace(`from ${module} import `, "")
        .split(",")
        .map((imp) => imp.trim());

      if (!existingImports.includes(imported)) {
        // Add the new imported item if it's not already imported
        existingImports.push(imported);
        lines[existingFromImportIndex] = `from ${module} import ${existingImports.join(", ")}`;
      }

      return { updatedCode: lines.join("\n"), linesAdded }; // No additional lines were added
    }
  }

  // Otherwise, add the import statement at the beginning
  lines.unshift(importStatement);
  linesAdded = 1; // One line was added at the top

  return { updatedCode: lines.join("\n"), linesAdded };
};
