import React from "react";

import { Menu, MenuItem } from "@material-ui/core";
import { OUTPUT_TYPE } from "../../../CodeRecipeContext/CodeRecipeContextProvider";

interface IProps {
  anchorEl: HTMLTextAreaElement | null;
  onClose: () => void;
  onClick: (type: OUTPUT_TYPE) => void;
  onModelClick: () => void;
}

const SelectOutputType: React.FC<IProps> = (props) => {
  const { anchorEl, onClose, onModelClick, onClick } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      PaperProps={{
        style: {
          width: 225,
          borderRadius: 12
        }
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}>
      <MenuItem onClick={() => onClick(OUTPUT_TYPE.DATASET)}>Dataset</MenuItem>
      <MenuItem onClick={() => onClick(OUTPUT_TYPE.CHART)}>Chart</MenuItem>
      <MenuItem onClick={() => onClick(OUTPUT_TYPE.TEXT)}>Text</MenuItem>
      <MenuItem onClick={onModelClick}>Model</MenuItem>
      <MenuItem onClick={() => onClick(OUTPUT_TYPE.PROMPT_SUGGESTIONS)}>
        Prompt Suggestions
      </MenuItem>
    </Menu>
  );
};

export default React.memo(SelectOutputType);
