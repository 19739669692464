import React, { ReactElement, useCallback, useMemo, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { useLocation } from "react-router";
import { Add, KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

import { ArtifactMini, Entities, EntityFeaturesResponse } from "src/types";
import InputDatasetsView from "../../common/InputDatasetsView";
import { READONLY_ENTITY } from "src/constants";
import InputArtifact from "./InputArtifact";
import { filter, orderBy, toLower } from "lodash";

const useStyles = makeStyles({
  codeRecipeHeader: {
    padding: "0px 16px",
    height: "44px",
    flexDirection: "row",
    flexWrap: "nowrap",
    background: "#d9d9d933",
    borderRadius: "11px 11px 0px 0px"
  },
  inputContainerWrap: {
    height: "100%",
    maxHeight: "100%",
    flexWrap: "nowrap",
    border: "1px solid #BDBDBD",
    borderRadius: "12px",
    background: "#FFFFFF"
  },
  inputWrap: {
    height: "calc(100% - 44px)",
    overflow: "auto",
    flexWrap: "nowrap",

    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#003656",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  entityLeftContent: {
    flexWrap: "nowrap",
    gap: "8px",
    alignItems: "center",
    minHeight: "48px",
    padding: "0px 16px",
    border: "0.5px #D1D1D1",
    borderStyle: "solid none;"
  },
  entityText: {
    fontWeight: 400
  },
  entityTitle: {
    color: "#000000",
    opacity: "0.5",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "8px 16px"
  },
  btnContainer: {
    flexWrap: "nowrap",
    gap: "8px",
    flex: 0
  },
  iconBtn: {
    padding: "4px"
  }
});

const CodeRecipeInputContainer = ({
  openAddEntitiesDialog,
  handleInputDatasetsChange,
  entityFeaturesMap,
  inputDatasets,
  selectedArtifacts,
  isDefaultScenario,
  isSelectedEntitiesUpdateInProgess,
  isApiConnetorRcipe = false
}: {
  isApiConnetorRcipe?: boolean;
  openAddEntitiesDialog: () => void;
  inputDatasets: Entities;
  selectedArtifacts?: Array<ArtifactMini>;
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  isDefaultScenario: boolean;
  handleInputDatasetsChange: (entities: Entities, artifacts: any, models: any) => void;
  isSelectedEntitiesUpdateInProgess: boolean;
  canvasEntities?: ReactElement<any, any>;
}) => {
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);
  const [expanded, setExpanded] = useState(true);

  const classes = useStyles();

  const onCollapse = () => {
    setExpanded((prev) => !prev);
  };

  const onDeleteDataset = useCallback(
    (datasetId: string) => {
      handleInputDatasetsChange(
        inputDatasets.filter((dataset) => dataset.id !== datasetId),
        selectedArtifacts,
        undefined
      );
    },
    [handleInputDatasetsChange, inputDatasets, selectedArtifacts]
  );
  const onDeleteArtifact = useCallback(
    (artifactName: string) => {
      handleInputDatasetsChange(
        inputDatasets,
        filter(selectedArtifacts, (item) => item.name !== artifactName),
        undefined
      );
    },
    [handleInputDatasetsChange, inputDatasets, selectedArtifacts]
  );

  return (
    <Grid
      container
      direction="column"
      style={{
        width: "100%",
        maxHeight: isApiConnetorRcipe ? "100%" : "50%",
        border: "1px solid #BDBDBD",
        borderRadius: "12px",
        background: "#FFFFFF"
      }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={onCollapse}
        className={classes.codeRecipeHeader}>
        <Typography variant="subtitle1">Inputs</Typography>
        <Grid container direction="row" className={classes.btnContainer}>
          {!isJobPath && (
            <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
              <div>
                <IconButton
                  className={classes.iconBtn}
                  test-id="codeRecipeHeaderAddEntitiesBtn"
                  disabled={!isDefaultScenario}
                  onClick={openAddEntitiesDialog}>
                  {isSelectedEntitiesUpdateInProgess ? <CircularProgress size={20} /> : <Add />}
                </IconButton>
              </div>
            </Tooltip>
          )}
          <IconButton size="small" data-testid="collapseSidebarbtn">
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Grid>
      </Grid>
      {expanded && (
        <Grid container direction="column" className={classes.inputWrap}>
          <InputDatasetsView
            isDefaultScenario={isDefaultScenario}
            inputDatasets={inputDatasets}
            onDeleteDataset={onDeleteDataset}
            entityFeaturesMap={entityFeaturesMap}
          />
          {selectedArtifacts && selectedArtifacts?.length > 0 && (
            <Grid container direction="column">
              <Grid style={{ borderBottom: "0.5px solid #D1D1D1" }}>
                <Typography variant="subtitle2" className={classes.entityTitle}>
                  ARTIFACTS ({selectedArtifacts?.length || 0})
                </Typography>
              </Grid>
              {orderBy(selectedArtifacts, (item) => toLower(item.name)).map((artifact) => (
                <InputArtifact
                  artifact={artifact}
                  isDefaultScenario={isDefaultScenario}
                  isJobPath={isJobPath}
                  onDeleteArtifact={onDeleteArtifact}
                />
              ))}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CodeRecipeInputContainer);
