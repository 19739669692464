import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";
import { DeploymentPropertyDto } from "openapi/Models";

// Constants
import { ReleaseNotesConfig } from "src/constants";

const enum UseGetReleaseNotesByNameQueryKeys {
  ReleaseNotesByName = "release-notes-by-name"
}

interface IReleaseNotesByNameProps
  extends UseQueryOptions<unknown, unknown, DeploymentPropertyDto> {
  name?: string;
}

const useGetReleaseNotesByName = (
  props?: IReleaseNotesByNameProps
): UseQueryResult<DeploymentPropertyDto> => {
  const { name = ReleaseNotesConfig.CurrentReleaseName, ...options } = props || {};

  return useQuery({
    queryKey: [UseGetReleaseNotesByNameQueryKeys.ReleaseNotesByName],
    queryFn: async () => {
      if (!name) {
        throw "Name is null!";
      }

      return await api.fetchResponse(
        async () => await api.DeploymentPropertyControllerApi.getByName1(name)
      );
    },
    ...options
  });
};

export default useGetReleaseNotesByName;
