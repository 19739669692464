import React, { useEffect, useState, useMemo } from "react";

import { Tooltip, makeStyles } from "@material-ui/core";

import { Table } from "src/components";

import { DataSourcesHelperText } from "../../utils/DataSources.constants";

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: 400,
    overflow: "auto"
  },
  cell: {
    display: "inline-block",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle"
  }
}));

const SampleData = (props: $TSFixMe) => {
  const { sampleData, unsorted } = props || {};

  const classes: $TSFixMe = useStyles();

  const [data, setData] = useState<$TSFixMe>([]);

  const getElementWithTooltip = ({ value = "" }: $TSFixMe) => (
    <Tooltip title={value.length >= 55 ? value : ""}>
      <span className={classes.cell}>{value}</span>
    </Tooltip>
  );

  const columns = useMemo(
    () =>
      (sampleData?.columns || [])?.map((column: $TSFixMe, index: number) => {
        return {
          id: column || `column_${index + 1}`,
          name: column,
          Header: column || DataSourcesHelperText.Unknown,
          accessor: column,
          Cell: ({ cell }: $TSFixMe) => getElementWithTooltip({ value: cell.value }),
          sortType: "alphanumeric"
        };
      }),
    [sampleData]
  );

  useEffect(() => {
    const columns = sampleData?.columns || [];
    const rows = sampleData?.rows || [];

    if (columns?.length === 0 || rows?.length === 0) {
      setData(() => []);
    } else {
      const outputRows = [
        ...rows?.reduce((rowAcc: $TSFixMe, row: $TSFixMe) => {
          rowAcc.push(
            (row?.cells || [])?.reduce((cellAcc: $TSFixMe, cell: $TSFixMe, cellIndex: $TSFixMe) => {
              cellAcc[columns[cellIndex]] =
                !!cell && typeof cell === "string" && ["nan"].includes(cell?.trim()?.toLowerCase())
                  ? ""
                  : cell;
              return cellAcc;
            }, {})
          );

          return rowAcc;
        }, [])
      ];

      setData(() => outputRows);
    }
  }, [sampleData]);

  return (
    <Table
      size="small"
      columns={columns}
      data={data}
      orderByDefault={unsorted ? undefined : columns[0]?.name || "Name"}
      hideSettings={false}
      isTheadSticky={true}
      inheritHeight={true}
      maxHeight="25vh"
      unsorted={unsorted}
    />
  );
};

export default SampleData;
