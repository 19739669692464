import React, { useState } from "react";
import _ from "lodash";
import { Button, Tooltip } from "@material-ui/core";

import SampleSyntaxIcon from "src/icons/NewUX/SampleSyntaxIcon";
import SampleSyntaxModal from "./SyntaxModal";

const SyntaxButton: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip title="Refer to this Syntax for manually adding code to the Recipe">
        <Button
          size="small"
          startIcon={<SampleSyntaxIcon />}
          style={{ background: "#fff", color: "#4646B5", textTransform: "capitalize" }}
          variant="contained"
          color="primary"
          onClick={handleClick}>
          Syntax
        </Button>
      </Tooltip>
      {open && <SampleSyntaxModal open={!!open} onClose={handleClose} />}
    </>
  );
};

export default SyntaxButton;
