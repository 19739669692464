import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { getEntityDataByPost } from "src/api";

interface EntitiesDataProps extends UseQueryOptions<$TSFixMe> {
  requests: {
    entityId?: string | null;
    scenarioId?: string;
    jobRunId?: string;
  }[];
}

export const useGetEntitiesData = (props: EntitiesDataProps): UseQueryResult<$TSFixMe> => {
  const { requests, ...options } = props;

  return useQuery({
    queryKey: ["entitiesData", requests.map((request) => request.entityId)],
    queryFn: async () => {
      const responses = await Promise.all(
        requests.map(async (request) => {
          const { entityId, scenarioId, jobRunId } = request;
          const data = await getEntityDataByPost(entityId, scenarioId, jobRunId);
          return { ...data, entityId };
        })
      );
      const results = _.map(responses, (response: any) => ({
        ...response?.data,
        numCols: response?.entityDetails?.columns,
        numRows: response?.entityDetails?.rows,
        entityId: response?.entityId
      }));

      return results;
    },
    enabled: requests?.length > 0,
    ...options
  });
};
