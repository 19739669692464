import React from "react";
import { Grid, GridProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface ChatBoxProps extends GridProps {
  children: React.ReactNode;
  color?: string;
  noPadding?: boolean;
  border?: string;
  width?: string;
  childWidth?: string;
}

const useStyles = makeStyles<undefined, Partial<ChatBoxProps>>({
  container: (props) => ({
    padding: "0px",
    position: "relative",
    border: props.border ? `1px solid ${props.border}` : "none",
    borderRadius: "4px",
    width: props.width
  }),
  borderedArrow: (props) => ({
    top: "-1px",
    left: "-11px",
    transform: "rotate(180deg)",
    width: 0,
    height: 0,
    position: "absolute",
    borderWidth: "10px",
    borderStyle: "solid",
    borderColor: `transparent transparent  ${props.border}`,
    "&::before": {
      top: "-13px",
      left: "-14px",
      width: 0,
      height: 0,
      position: "absolute",
      borderStyle: "solid",
      borderWidth: "11px",
      borderColor: `transparent transparent  ${props.color ? props.color : "#f2f9ff"}`,
      content: '""'
    }
  }),
  arrow: (props) => ({
    content: '""',
    position: "absolute",
    width: 0,
    height: 0,
    left: "-12px",
    top: "12px",
    transform: "translateY(-50%)",
    borderStyle: "solid",
    borderWidth: "12px",
    borderColor: `${props.color} transparent transparent`
  }),
  content: (props) => ({
    background: props.color,
    width: props.childWidth ?? "auto",
    padding: props.noPadding ? "0px" : "4px 12px",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px 0px #82828240"
  })
});

export const ChatBox = (props: ChatBoxProps) => {
  const { children, border, color = "#fff", childWidth, noPadding, ...restProps } = props;
  const classes = useStyles({ childWidth, noPadding, ...restProps, color, border });

  return (
    <Grid container className={classes.container} {...restProps}>
      {border ? <Grid className={classes.borderedArrow} /> : <Grid className={classes.arrow} />}
      <Grid className={classes.content}>{children}</Grid>
    </Grid>
  );
};

export default ChatBox;
