import { useMutation, UseMutationResult } from "@tanstack/react-query";

import useTenantsStore from "src/stores/tenant-management.store";
import { deleteAPIWithRethrow } from "src/utils/apiService";

interface IVariables {
  userId: string;
  userType: string;
}

export const useDeleteTenantUserMutation = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  const [removeTenantProject] = useTenantsStore((state) => [state.removeTenantProject]);
  return useMutation({
    mutationFn: async (body) => {
      const response = await deleteAPIWithRethrow("/tenants/current/user", body);
      removeTenantProject(response.id);
      return response;
    }
  });
};
