import React from "react";

export const ModelsIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2222 1.77778H12.4444V0H3.55556V1.77778H1.77778C0.8 1.77778 0 2.57778 0 3.55556V4.44444C0 6.71111 1.70667 8.56 3.90222 8.83556C4.46222 10.1689 5.66222 11.1733 7.11111 11.4667V14.2222H3.55556V16H12.4444V14.2222H8.88889V11.4667C10.3378 11.1733 11.5378 10.1689 12.0978 8.83556C14.2933 8.56 16 6.71111 16 4.44444V3.55556C16 2.57778 15.2 1.77778 14.2222 1.77778ZM3.55556 6.95111C2.52444 6.57778 1.77778 5.6 1.77778 4.44444V3.55556H3.55556V6.95111ZM14.2222 4.44444C14.2222 5.6 13.4756 6.57778 12.4444 6.95111V3.55556H14.2222V4.44444Z"
        fill="#003656"
      />
    </svg>
  );
};
