import React from "react";

export const Warning = ({ width = 30, height = 26 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0002 0.666016L0.333496 25.9993H29.6668M15.0002 5.99935L25.0402 23.3327H4.96016M13.6668 11.3327V16.666H16.3335V11.3327M13.6668 19.3327V21.9993H16.3335V19.3327"
        fill="black"
      />
    </svg>
  );
};
