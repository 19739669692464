import React from "react";

export const OntologyDatasetIcon = ({ width = 20, height = 20, fill = "white" }) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 1.5C3.79 1.5 2 2.395 2 3.5C2 4.605 3.79 5.5 6 5.5C8.21 5.5 10 4.605 10 3.5C10 2.395 8.21 1.5 6 1.5ZM2 4.5V6C2 7.105 3.79 8 6 8C8.21 8 10 7.105 10 6V4.5C10 5.605 8.21 6.5 6 6.5C3.79 6.5 2 5.605 2 4.5ZM2 7V8.5C2 9.605 3.79 10.5 6 10.5C8.21 10.5 10 9.605 10 8.5V7C10 8.105 8.21 9 6 9C3.79 9 2 8.105 2 7Z"
        fill="#003656"
      />
    </svg>
  </>
);
