import { Grid } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { DropImageBox } from "./DropImageBox";
import { CustomImageCrop } from "./CustomImageCrop";

interface IProps {
  setCompletedCrop: React.Dispatch<React.SetStateAction<PixelCrop | undefined>>;
  imgRef: React.RefObject<HTMLImageElement>;
  imgSrc?: string;
}
export const CustomImage = ({ setCompletedCrop, imgRef, imgSrc: initialImgSrc }: IProps) => {
  const boxRef = useRef<HTMLImageElement>(null);
  const [boxWidth, setBoxWidth] = useState(0);
  const [imgSrc, setImgSrc] = useState<string>(initialImgSrc || "");
  const onFilesUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      if (boxRef.current) {
        setBoxWidth(boxRef.current.offsetWidth);
      }
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <Grid container ref={boxRef}>
      {!imgSrc ? (
        <DropImageBox onFilesUpload={onFilesUpload} />
      ) : (
        <CustomImageCrop
          imgRef={imgRef}
          setCompletedCrop={setCompletedCrop}
          boxWidth={boxWidth}
          imgSrc={imgSrc}
        />
      )}
    </Grid>
  );
};
