import React from "react";

// MUI
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

// Icons
import { CurvedLinesIcon, SquareLinesIcon } from "src/icons/NewUX";
import { makeStyles } from "@material-ui/core";

type Props = {
  isCurvedLine: boolean;
  onClick: () => void;
};

const useStyles = makeStyles(() => ({
  button: {
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    padding: "6px 8px"
  }
}));

const ToggleLineViewButton = (props: Props) => {
  const { isCurvedLine, onClick } = props;
  const classes = useStyles();

  return (
    <Tooltip title={isCurvedLine ? "Square lines" : "Curved lines"}>
      <Button variant="text" className={classes.button} onClick={onClick}>
        <Typography variant="body2" color="textPrimary">
          {isCurvedLine ? <SquareLinesIcon /> : <CurvedLinesIcon viewBox="0 0 18 18" />}
        </Typography>
      </Button>
    </Tooltip>
  );
};

export default ToggleLineViewButton;
