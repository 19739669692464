import { SetStateAction, useEffect, useMemo, useState } from "react";

import { delay, now } from "lodash";

import { useDagFlowContext } from "../DagFlow/context/useDagFlowContext";

import { NodeData } from "src/types";

type Props = {
  data: NodeData;
  disabledMessage?: string;
};

const useContextMenu = (props: Props) => {
  const { data, disabledMessage } = props || {};

  const { contextMenuNodeId, setContextMenuNodeId, setResetNodesAndEdgesTimer } =
    useDagFlowContext();

  const [contextMenuAnchorEl, setContextMenuAnchorEl] =
    useState<SetStateAction<Element | null>>(null);

  // onContextMenu of the node.
  const actionsOnContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    if (!Boolean(contextMenuAnchorEl)) {
      setContextMenuNodeId(() => data?.id);
      setContextMenuAnchorEl(event.currentTarget);
    }
  };

  const actionsOnMouseEnter = (event: React.MouseEvent) => {
    if (!Boolean(contextMenuAnchorEl)) {
      delay(
        (eventCurrentTarget) => {
          setContextMenuNodeId(() => data?.id);
          setContextMenuAnchorEl(eventCurrentTarget);
        },
        800,
        event.currentTarget
      );
    }
  };

  const closeContextMenu = () => {
    setContextMenuAnchorEl(null);
    setResetNodesAndEdgesTimer(() => now());
  };

  const contextMenuProp = useMemo(() => {
    if (!!disabledMessage) {
      return {};
    }

    if (!!data?.isJobCanvas) {
      if (!data?.isJobCanvasPath && !data?.isSourceNode) {
        return {};
      }
    }

    return {
      onContextMenu: actionsOnContextMenu
      // Mouse-leave action is not fully supported. Hence, deferring mouse-enter action.
      // onMouseEnter: actionsOnMouseEnter
    };
  }, [disabledMessage, data?.isJobCanvas, data?.isJobCanvasPath]);

  // Closing this context-menu, if it is not the newest.
  useEffect(() => {
    if (contextMenuNodeId !== data?.id) {
      setContextMenuAnchorEl(null);
    }
  }, [contextMenuNodeId, data?.id]);

  return {
    contextMenuAnchorEl,
    actionsOnMouseEnter,
    closeContextMenu,
    contextMenuProp
  };
};

export default useContextMenu;
