import React from "react";

// Packages
import { includes } from "lodash";

// Utils
import { toastWrapper } from "src/utils/toastWrapper";

// Hooks
import { useDeleteScenario } from "src/hooks/api";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { ScenarioDeletePromptDetails, ScenariosHelperText } from "../utils/Scenarios.constants";

type Props = {
  deletingScenarioId: string;
  resetDeleteScenario: () => void;
  refetch: () => void;
};

const DeleteScenario = (props: Props) => {
  const { deletingScenarioId, resetDeleteScenario, refetch } = props || {};

  const {
    isLoading: isScenarioDeleting,
    mutateAsync: deleteScenarioMutation,
    reset: resetDeleteScenarioMutation
  } = useDeleteScenario();

  const deleteScenario = async () => {
    resetDeleteScenarioMutation();

    await deleteScenarioMutation(
      { scenarioId: deletingScenarioId },
      {
        onSuccess: async () => {
          toastWrapper({ type: "success", content: ScenariosHelperText.ScenarioDeleted });
          await refetch();
        },
        onError: () => {
          toastWrapper({ type: "error", content: ScenariosHelperText.ScenarioDeleteFailed });
        },
        onSettled: () => {
          resetDeleteScenario();
        }
      }
    );
  };

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={ScenarioDeletePromptDetails.title}
      content={[ScenarioDeletePromptDetails.messageLine1, ScenarioDeletePromptDetails.messageLine2]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          resetDeleteScenario();
        }
      }}
      onSubmit={deleteScenario}
      isCancelDisabled={isScenarioDeleting}
      isSubmitDisabled={isScenarioDeleting}
      isSubmitting={isScenarioDeleting}
      hideCloseIcon
    />
  );
};

export default DeleteScenario;
