import create from "zustand";
import { devtools } from "zustand/middleware";
import { toastWrapper } from "src/utils/toastWrapper";

const authStore = (set: $TSFixMe) => ({
  isUserLoggedIn: false,
  token: null,
  rcClientVersion: null,
  rcReleaseVersion: null,
  isTokenPermanent: false,
  expiresAt: null,
  logoutTimer: null,
  tenantName: null,
  tenantId: null,
  notebookType: "",
  isNotebookEnabled: false,
  userName: "",
  firstName: "",
  lastName: null,
  userEmail: null,
  userId: null,
  resetPasswordEmail: null,
  previousLocationPath: null,
  cpuGuarantee: null,
  memGuarantee: null,
  hasPrivileges: false,
  licenseEnabled: false,
  roleName: null,
  isSSOEnabled: false,
  isNonSSOFlow: false,
  dataappsTtlInHrs: null,
  isLoggingNotebooksTypeUserOut: false,
  isLoggingNotebooksTypeTenantOut: false,
  askAiEnabled: false,
  setIsUserLoggedIn: (value: $TSFixMe) => (set as $TSFixMe)({ isUserLoggedIn: value }),
  setResetPasswordEmail: (resetPasswordEmail: $TSFixMe) =>
    (set as $TSFixMe)({ resetPasswordEmail }),
  logout: (logoutTimer: $TSFixMe) => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    localStorage.removeItem("token");
    localStorage.removeItem("projectConfig");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("configGroupId");
    sessionStorage.removeItem("datasetRemovalWarning");
    localStorage.removeItem("askAIModalGenerationWarning");
    set({
      logoutTimer: null,
      isUserLoggedIn: false,
      token: null,
      expiresAt: null,
      userId: null,
      tenantId: null,
      notebookType: "",
      isNotebookEnabled: false,
      licenseEnabled: false,
      cpuGuarantee: null,
      memGuarantee: null,
      roleName: null,
      rcReleaseVersion: null,
      rcClientVersion: null
    });
  },
  setRoleName: (roleName: string) => (set as $TSFixMe)({ roleName }),
  setCpuGuarantee: (cpuGuarantee: number) => (set as $TSFixMe)({ cpuGuarantee }),
  setMemGuarantee: (memGuarantee: number) => (set as $TSFixMe)({ memGuarantee }),
  setDataappsTtlInHrs: (dataappsTtlInHrs: number) => (set as $TSFixMe)({ dataappsTtlInHrs }),
  setToken: (token: $TSFixMe) => {
    sessionStorage.setItem("token", token);
    set({ token });
  },
  setTokenPermanent: (token: $TSFixMe) => {
    localStorage.setItem("token", token);
  },
  setExpiresAt: (expiresAt: $TSFixMe, logoutTimer: $TSFixMe) => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    set({
      logoutTimer: setTimeout(
        () => {
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
          set({
            isUserLoggedIn: false,
            token: null,
            expiresAt: null,
            userId: null,
            tenantId: null
          });
          toastWrapper({
            type: "error",
            content: "Session expired"
          });
        },
        expiresAt * 1000 - new Date().getTime()
      )
    });
    set({ expiresAt });
  },
  setRcClientVersion: (rcClientVersion: string) => set({ rcClientVersion }),
  setRcReleaseVersion: (rcReleaseVersion: string) => set({ rcReleaseVersion }),
  setTenantId: (tenantId: $TSFixMe) => (set as $TSFixMe)({ tenantId }),
  setTenantIdPermanent: (tenantId: $TSFixMe) => {
    localStorage.setItem("tenantId", tenantId);
  },
  setTenantName: (tenantName: $TSFixMe) => (set as $TSFixMe)({ tenantName }),
  setNotebookType: (notebookType: $TSFixMe) => (set as $TSFixMe)({ notebookType }),
  setIsNotebookEnabled: (isNotebookEnabled: $TSFixMe) => (set as $TSFixMe)({ isNotebookEnabled }),
  setUserId: (userId: $TSFixMe) => (set as $TSFixMe)({ userId }),
  setPreviousLocationPath: (path: $TSFixMe) => (set as $TSFixMe)({ previousLocationPath: path }),
  setUserData: (userName: $TSFixMe, firstName: $TSFixMe, lastName: $TSFixMe) =>
    (set as $TSFixMe)({ userName, firstName, lastName }),
  setTenantNameUserEmail: (tenantName: $TSFixMe, userEmail: $TSFixMe) =>
    (set as $TSFixMe)({ tenantName, userEmail }),
  setIsTokenPermanent: (isTokenPermanent: $TSFixMe) => (set as $TSFixMe)({ isTokenPermanent }),
  setPrivileges: (hasPrivileges: $TSFixMe) => (set as $TSFixMe)({ hasPrivileges }),
  setLicenseEnabled: (licenseEnabled: boolean) => (set as $TSFixMe)({ licenseEnabled }),
  setIsLoggingNotebooksTypeUserOut: (isLoggingNotebooksTypeUserOut: $TSFixMe) =>
    (set as $TSFixMe)({ isLoggingNotebooksTypeUserOut }),
  setIsLoggingNotebooksTypeTenantOut: (isLoggingNotebooksTypeTenantOut: $TSFixMe) =>
    (set as $TSFixMe)({ isLoggingNotebooksTypeTenantOut }),
  setAskAiEnabled: (askAiEnabled: boolean | null | undefined) =>
    (set as $TSFixMe)({ askAiEnabled: !!askAiEnabled }),
  setIsSsoEnabled: (enabled: boolean | null | undefined) =>
    (set as $TSFixMe)({ isSSOEnabled: !!enabled }),
  setIsNonSSOFlow: (enabled: boolean | null | undefined) =>
    (set as $TSFixMe)({ isNonSSOFlow: !!enabled })
});

const useAuthStore = create(devtools(authStore, { name: "auth_store" }));

export default useAuthStore;
