import React from "react";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import styles from "./TenantItem.module.scss";
import { OverflowTooltip } from "../../../components";

const TenantItem = ({ tenantData, oneTenant, ActionButtons }: $TSFixMe) => {
  const { name, numberOfProjects } = tenantData;

  return (
    <div className={styles.tenantItemContainer}>
      <div className={styles.tenantInfoContainer}>
        <HomeWorkIcon className={styles.homeWorkIcon} />
        <div className={styles.tenantNameAndProjectsContainer}>
          <p className={styles.tenantName}>
            <OverflowTooltip value={name} />
          </p>
          <p className={styles.tenantNumberProjects}>Number of projects: {numberOfProjects}</p>
        </div>
      </div>
      {!oneTenant && ActionButtons}
    </div>
  );
};

export default TenantItem;
