/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EntityMetaDto
 */
export interface EntityMetaDto {
    /**
     * 
     * @type {string}
     * @memberof EntityMetaDto
     */
    'entityType'?: EntityMetaDtoEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityMetaDto
     */
    'entitySubType'?: EntityMetaDtoEntitySubTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityMetaDto
     */
    'entityOntology'?: EntityMetaDtoEntityOntologyEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityMetaDto
     */
    'entityViewType'?: EntityMetaDtoEntityViewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityMetaDto
     */
    'encoding'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityMetaDto
     */
    'separator'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityMetaDto
     */
    'header'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EntityMetaDto
     */
    'dataTypeMap'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EntityMetaDto
     */
    'ontologyMap'?: { [key: string]: string; };
}

export const EntityMetaDtoEntityTypeEnum = {
    None: 'NONE',
    Base: 'BASE',
    Event: 'EVENT',
    Dimension: 'DIMENSION',
    Relation: 'RELATION',
    Signal: 'SIGNAL',
    Rollupevent: 'ROLLUPEVENT',
    Timetick: 'TIMETICK'
} as const;

export type EntityMetaDtoEntityTypeEnum = typeof EntityMetaDtoEntityTypeEnum[keyof typeof EntityMetaDtoEntityTypeEnum];
export const EntityMetaDtoEntitySubTypeEnum = {
    None: 'NONE',
    DimensionLow: 'DIMENSION_LOW',
    DimensionHigh: 'DIMENSION_HIGH'
} as const;

export type EntityMetaDtoEntitySubTypeEnum = typeof EntityMetaDtoEntitySubTypeEnum[keyof typeof EntityMetaDtoEntitySubTypeEnum];
export const EntityMetaDtoEntityOntologyEnum = {
    None: 'NONE',
    User: 'USER',
    Email: 'EMAIL',
    Ip: 'IP',
    Phone: 'PHONE',
    Device: 'DEVICE',
    Product: 'PRODUCT',
    DeviceEvents: 'DEVICE_EVENTS',
    UserSignup: 'USER_SIGNUP',
    UserLogin: 'USER_LOGIN',
    Transaction: 'TRANSACTION',
    Payment: 'PAYMENT',
    Order: 'ORDER',
    Cart: 'CART',
    BlsMsa: 'BLS_MSA',
    City: 'CITY',
    Department: 'DEPARTMENT'
} as const;

export type EntityMetaDtoEntityOntologyEnum = typeof EntityMetaDtoEntityOntologyEnum[keyof typeof EntityMetaDtoEntityOntologyEnum];
export const EntityMetaDtoEntityViewTypeEnum = {
    Chart: 'CHART',
    Model: 'MODEL',
    Artifact: 'ARTIFACT',
    None: 'NONE'
} as const;

export type EntityMetaDtoEntityViewTypeEnum = typeof EntityMetaDtoEntityViewTypeEnum[keyof typeof EntityMetaDtoEntityViewTypeEnum];
export const EntityMetaDtoDataTypeMapEnum = {
    Long: 'LONG',
    Float: 'FLOAT',
    String: 'STRING',
    Timestamp: 'TIMESTAMP',
    Boolean: 'BOOLEAN',
    Any: 'ANY',
    Double: 'DOUBLE',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type EntityMetaDtoDataTypeMapEnum = typeof EntityMetaDtoDataTypeMapEnum[keyof typeof EntityMetaDtoDataTypeMapEnum];
export const EntityMetaDtoOntologyMapEnum = {
    Unknown: 'UNKNOWN',
    Address: 'ADDRESS',
    Ban: 'BAN',
    CreditCard: 'CREDIT_CARD',
    EmailAddress: 'EMAIL_ADDRESS',
    Uuid: 'UUID',
    HashOrKey: 'HASH_OR_KEY',
    Ipv4: 'IPV4',
    Ipv6: 'IPV6',
    MacAddress: 'MAC_ADDRESS',
    Person: 'PERSON',
    PhoneNumber: 'PHONE_NUMBER',
    Ssn: 'SSN',
    Url: 'URL',
    UsState: 'US_STATE',
    DriversLicense: 'DRIVERS_LICENSE',
    Date: 'DATE',
    Time: 'TIME',
    Datetime: 'DATETIME',
    Integer: 'INTEGER',
    Decimal: 'DECIMAL',
    Quantity: 'QUANTITY',
    Ordinal: 'ORDINAL',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type EntityMetaDtoOntologyMapEnum = typeof EntityMetaDtoOntologyMapEnum[keyof typeof EntityMetaDtoOntologyMapEnum];


