import React, { useEffect, useMemo } from "react";

import { filter, includes, last } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { toastWrapper } from "src/utils/toastWrapper";

import useRelaunchEnvironment from "src/hooks/api/environments/useRelaunchEnvironment";
import { useGetDataSources, useGetProject } from "src/hooks/api";
import useEntities, { QUERY_KEY_ENTITIES } from "src/hooks/api/entities/useEntities";

import { DatasetContext } from "./DatasetContext";
import { useCanvasStore, useDataSourcesStore } from "src/store/store";
import { dataSourcesSetter } from "src/store/store.selectors";
import useFilesSession from "../../hooks/useFilesSession";
import useHelpers from "../../hooks/useHelpers";
import useStoreSelectors from "../../hooks/useStoreSelectors";

import { DatasetKeys, OntologyDatasetStatuses } from "../../utils/Dataset.constants";

type Props = {
  cancelApi: $TSFixMeFunction;
  children: React.ReactNode;
};

const DatasetContextProvider = (props: Props) => {
  const { cancelApi, children } = props || {};

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { projectId, scenarioId, datasetId } = useParams();

  const { removeDatasetFilesSession } = useFilesSession();
  const {
    source,
    deleteEntityDataSourcesFilesUploadSql,
    deleteEntities,
    removeDatasetOntologySchemaSession
  } = useHelpers();

  const { setNodeToFocus } = useCanvasStore();
  const { datasetDatasetsStore, resetDatasetModalStore, setDatasetIsResettingStore } =
    useStoreSelectors();

  // Connectors
  const setDataSourcesStore = useDataSourcesStore(dataSourcesSetter);
  const setIsLoading = useDataSourcesStore((state) => state.setIsLoading);

  const { isFetching } = useGetDataSources({
    cacheTime: Infinity,
    refetchOnMount: true,
    onSuccess: (dataSourcesData) => {
      return setDataSourcesStore(dataSourcesData);
    }
  });

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  // Project
  const { isLoading: isLoadingProject, data: project } = useGetProject({
    projectId,
    cacheTime: Infinity,
    refetchOnMount: true
  });

  // Dataset
  const { data: dataset, isLoading: isLoadingDataset } = useEntities({
    id: datasetId!,
    projectId,
    scenarioId,
    options: {
      onError: () => {
        toastWrapper({
          type: "error",
          content: "No entity was found"
        });

        navigateBack();
      }
    }
  });

  // Relaunch environment
  const relaunchMutation = useRelaunchEnvironment();

  useEffect(() => {
    !!project?.envId && relaunchMutation.mutate({ envId: project?.envId });
  }, [project]);

  const reset = async () => {
    if (source.isSql) {
      await deleteEntityDataSourcesFilesUploadSql();
    } else {
      cancelApi();
      await deleteEntities();
    }

    removeDatasetFilesSession();
    removeDatasetOntologySchemaSession();

    resetDatasetModalStore();
  };

  const navigateBackWithoutRedirect = async () => {
    try {
      setDatasetIsResettingStore(true);
      await reset();
    } finally {
      setDatasetIsResettingStore(false);
    }

    if (projectId) {
      if (!!datasetId) {
        if (scenarioId) {
          await queryClient.invalidateQueries([QUERY_KEY_ENTITIES]);
        }
      } else {
        // To focus on created dataset in the DAG post navigation back.
        // @ts-ignore
        setNodeToFocus(
          last(
            filter(datasetDatasetsStore, (eachDataset: $TSFixMe) =>
              includes(
                [OntologyDatasetStatuses.Active, OntologyDatasetStatuses.Updated],
                eachDataset[DatasetKeys.OntologyConfig][DatasetKeys.Status]
              )
            )
          )?.name || ""
        );
      }
    }
  };

  const navigateBack = async () => {
    try {
      setDatasetIsResettingStore(true);
      await reset();
    } finally {
      setDatasetIsResettingStore(false);
    }

    if (projectId) {
      if (!!datasetId) {
        if (scenarioId) {
          navigate(
            -1
            // {
            //   pathname: generatePath(WebPaths.ViewData, {
            //     projectId,
            //     entityId: datasetId,
            //     scenarioId,
            //     section: "data"
            //   })
            // }
            // Avoiding node-focus with append functionality.
            // { state: location }
          );

          await queryClient.invalidateQueries([QUERY_KEY_ENTITIES]);
        }
      } else {
        // To focus on created dataset in the DAG post navigation back.
        // @ts-ignore
        setNodeToFocus(
          last(
            filter(datasetDatasetsStore, (eachDataset: $TSFixMe) =>
              includes(
                [OntologyDatasetStatuses.Active, OntologyDatasetStatuses.Updated],
                eachDataset[DatasetKeys.OntologyConfig][DatasetKeys.Status]
              )
            )
          )?.name || ""
        );

        navigate(-1);
      }
    }
  };

  useEffect(() => {
    const popstateListner = () => {
      (event: PopStateEvent) => {
        event.preventDefault();
        navigateBack();
      };
      window.addEventListener("popstate", popstateListner);
      return () => {
        window.removeEventListener("popstate", popstateListner);
      };
    };
  }, []);

  useEffect(
    () => () => {
      const _ = async () => await reset();

      _();
    },
    []
  );

  const value = useMemo(
    () => ({
      scenarioId,
      isLoadingProject,
      project,
      isLoadingDataset,
      dataset,
      reset,
      navigateBack,
      navigateBackWithoutRedirect
    }),
    [
      scenarioId,
      isLoadingProject,
      project,
      isLoadingDataset,
      dataset,
      reset,
      navigateBack,
      navigateBackWithoutRedirect
    ]
  );

  return <DatasetContext.Provider value={value}>{children}</DatasetContext.Provider>;
};

export default DatasetContextProvider;
