import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, Theme } from "@material-ui/core/styles";

import TopNavBarLeftSection from "./TopNavBarLeftSection";
import TopNavBarRightSection from "./TopNavBarRightSection";

type StyleProps = {
  background?: string;
};
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    backgroundColor: ({ background }) => background || theme?.palette?.common?.white,
    borderBottom: "1px solid #dddddd",
    // Overriding default value of zIndex which was 1100, as the same zIndex value is assigned for Sub-TopNavBar.
    zIndex: 1110
  }
}));

const TopNavBar = ({ background }: { background?: string }) => {
  const classes = useStyles({ background });

  return (
    <AppBar className={classes.root} elevation={0} data-testid="topNavBar">
      <Toolbar variant="dense" disableGutters>
        <TopNavBarLeftSection />
        <TopNavBarRightSection />
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
