import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { AskAIPromptSuggestions } from "../AskAIPromptSuggestions/AskAIPromptSuggestions";
import clsx from "clsx";
import AskAIResponseContainer from "../AskAIResponseContainer/AskAIResponseContainer";
import { AskAIResponse } from "src/types";

const useStyles = makeStyles({
  responsesWrap: {
    gap: "8px",
    flexWrap: "nowrap",
    overflowY: "auto"
  },
  promptSuggestions: {
    flexWrap: "nowrap",
    width: "calc(100% - 64px)"
  },
  askAiHoveredResponse: {
    position: "relative"
  }
});

const AskAIResponses = ({
  responses,
  hoverId,
  setHoverId
}: {
  responses: AskAIResponse[];
  hoverId: any;
  setHoverId: (hoverId: any) => void;
  isGenerating: boolean;
}) => {
  const classes = useStyles();
  return responses?.length !== 0 ? (
    <Grid container direction="column" className={classes.responsesWrap}>
      {responses.map((response: any, index) => {
        const responseName = response?.queryOutputs?.[0]?.name;
        const isHovered = hoverId === response.historyId;
        if (response.promptSuggestions) {
          return (
            <Grid
              key={response.historyId}
              container
              direction="column"
              className={classes.promptSuggestions}>
              <AskAIPromptSuggestions
                response={response}
                suggestedPrompts={response.promptSuggestions}
                queryInputs={response.queryInputs}
                isExpanded={response.isExpanded || false}
              />
            </Grid>
          );
        }
        return (
          <Grid
            container
            key={index}
            className={clsx(`inputDataset-${responseName}`, {
              [classes.askAiHoveredResponse]: isHovered,
              askAIhoveredResponse: isHovered
            })}
            onMouseEnter={() => setHoverId(response.historyId)}
            onMouseLeave={() => setHoverId(null)}>
            <AskAIResponseContainer responseIndex={index} response={response} />
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <React.Fragment />
  );
};

export default React.memo(AskAIResponses);
