const getHostedNotebookPath = (type: string, token: string) => {
  if (token) {
    const domain = window.location.origin;
    const typeParam = type ? `&notebookType=${type}` : "";

    return domain ? `${domain}/notebooks/hub/login?token=${token}${typeParam}` : "";
  }

  return "";
};

export { getHostedNotebookPath };
