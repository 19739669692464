import React from "react";

export const ArrowLeft = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 6L10.2627 9.73726C9.47071 10.5293 9.07469 10.9253 8.92631 11.382C8.7958 11.7837 8.7958 12.2163 8.92631 12.618C9.07469 13.0747 9.47071 13.4707 10.2627 14.2627L14 18"
      stroke="#00102D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
