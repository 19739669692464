import _ from "lodash";

import { MAX_LENGTH, MIN_LENGTH } from "./OutputDataset.constants";

export const isInvalidConnectionInputs = (
  text: string,
  regex?: RegExp,
  max?: number,
  isOptional?: boolean
) => {
  const trimmed = _.trim(text);
  if (isOptional && _.isEmpty(trimmed)) {
    return false;
  }

  return (
    _.isEmpty(trimmed) ||
    _.size(trimmed) > (max ?? MAX_LENGTH) ||
    _.size(trimmed) < MIN_LENGTH ||
    (regex ? !regex.test(text) : false)
  );
};
