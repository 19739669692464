import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getDataSources } from "src/api/dataSources";

export const enum UseGetDataSourcesQueryKeys {
  DataSources = "data-sources"
}

type PrefetchingDataSourcesProps = { queryClient: $TSFixMe };

interface EnvironmentsProps extends UseQueryOptions<$TSFixMe> {
  // useQuery options
  cacheTime?: number;
}

export const prefetchDataSources = (props: PrefetchingDataSourcesProps) => {
  const { queryClient } = props;

  queryClient.prefetchQuery({
    queryKey: [UseGetDataSourcesQueryKeys.DataSources],
    queryFn: () => getDataSources()
  });
};

export const useGetDataSources = (props?: EnvironmentsProps): UseQueryResult<$TSFixMe> => {
  const {
    // useQuery options
    cacheTime = 0,
    enabled = true,
    ...options
  } = props || {};

  return useQuery({
    queryKey: [UseGetDataSourcesQueryKeys.DataSources],
    queryFn: async () => {
      return await getDataSources();
    },
    select: (data: $TSFixMe) => (Array.isArray(data) ? data : []),
    cacheTime,
    enabled,
    ...options
  });
};
