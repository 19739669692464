import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "40%",
    flexShrink: 0,
    "& .MuiAppBar-root": {
      width: "40%",
      backgroundColor: "white",
      boxShadow: "0px 1px 2px 0px #00000040"
    }
  },
  drawerPaper: {
    width: "40%",
    backgroundColor: "#f5f5f5"
  },
  container: {
    height: "80%",
    overflowY: "auto",
    rowGap: 20,
    padding: 15
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& div[class^='MuiFormControl-root']": {
      width: "100%"
    },
    "& div[class^='MuiSelect-root']": {
      paddingTop: 9.5,
      paddingBottom: 9.5
    }
  },
  inputRow: {
    display: "flex",
    width: "100%",
    gap: 12,
    padding: "12px 14px",
    "& div[class^='MuiFormControl-root']": {
      minWidth: "calc(40% - 12px)",
      background: "white",
      borderRadius: 4,
      "& .MuiFormLabel-root": {
        width: "100% !important"
      }
    },
    "& .deleteButton": {
      opacity: "0",
      transition: "all ease 0.25s",
      pointerEvents: "none"
    },
    "&:hover": {
      background: "#e0e7eb",
      "& .deleteButton": {
        pointerEvents: "all",
        opacity: 1
      }
    }
  },
  inputField: {
    backgroundColor: theme.palette.common.white
  },
  inputLabel: {
    opacity: 0.625
  },
  inputLabelShrink: {
    shrink: true,
    opacity: 1
  },
  footerContainer: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    justifyContent: "flex-end",
    width: "100%",
    columnGap: 15,
    background: "#f5f5f5",
    padding: "10px 20px",
    zIndex: 1,
    backgroundColor: "#d9d9d9"
  }
}));

export { useStyles };
