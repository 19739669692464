import api from "utils/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  runConfigId: string;
}

const usePublishTemplate = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ runConfigId }) => {
      return await api.fetchResponse(() =>
        api.DfsRunConfigControllerV2Api.publishTemplate(runConfigId)
      );
    }
  });
};

export default usePublishTemplate;
