import React from "react";

import { RecipeRunData } from "openapi/Models/recipe-run-data";

type ContextTypes = {
  project: $TSFixMe;
  recipeRunsQueue: RecipeRunData[];
};

// @ts-ignore
export const ProjectContext = React.createContext<ContextTypes>();
