import React from "react";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

import DefaultButton from "src/components/Buttons/DefaultButton";
import NoConnectorIcon from "src/icons/NoConnectorIcon";
import { isCreateDataSourceSetter } from "src/store/store.selectors";
import { useDataSourcesStore } from "src/store/store";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "10px",
    margin: "auto",
    color: "#7C7C7C",
    width: "200px"
  }
});
const NoAvailableConnector: React.FC = () => {
  const { flexContainer } = useStyles();
  const navigate = useNavigate();
  const setIsCreateDataSourceStore = useDataSourcesStore(isCreateDataSourceSetter);

  const handleClick = () => {
    setIsCreateDataSourceStore(true);
    navigate(WebPaths.DataConnectors);
  };

  return (
    <div className={flexContainer}>
      <NoConnectorIcon />
      <div>No Data Connector Available</div>
      <DefaultButton variant="outlined" onClick={handleClick}>
        + Data Connector
      </DefaultButton>
    </div>
  );
};

export default NoAvailableConnector;
