import React from "react";
import { Grid, Button, Typography, CircularProgress, Tooltip } from "@material-ui/core";

import { InputColumn } from "src/components/Table/TableUtils";
import { TenantIcon } from "src/icons/NewUX";

export const getTenantColumns = ({
  currentTenantId,
  joinNewTenant,
  switchingTenantId,
  switchTenant,
  showLeaveAndSwitchTenantModal,
  canLeaveAndSwitchTenant
}: any): InputColumn[] => [
  {
    id: "Tenant Name",
    accessor: "name",
    isSortable: true,
    Header: "Tenant Name",
    color: "textPrimary",
    Cell: (cell: any) => {
      return (
        <Grid container direction="row" style={{ gap: "12px" }}>
          <TenantIcon opacity={0.3} />
          <Typography
            style={{ maxWidth: "85%", overflow: "hidden", textOverflow: "ellipsis" }}
            color="textPrimary"
            variant="body2">
            {cell.value}
          </Typography>
        </Grid>
      );
    },
    width: 250
  },
  {
    id: "Projects",
    accessor: "numberOfProjects",
    isSortable: true,
    Header: "Projects",
    color: "textPrimary",
    type: "number",
    width: 110
  },
  {
    id: "Users",
    accessor: "numberOfUsers",
    isSortable: true,
    Header: "Users",
    color: "textPrimary",
    type: "string",
    width: 110
  },
  {
    id: "Role",
    accessor: "userRole",
    isSortable: true,
    Header: "Role",
    width: 110,
    Cell: (cell: $TSFixMe) => cell?.row?.original?.userRole || "Unknown"
  },
  {
    id: "Actions",
    accessor: "invitationStatus",
    isSortable: false,
    Header: "Actions",
    width: 110,
    Cell: (cell: any) => {
      const tenantId = cell.row.original.id;
      const isCurrentTenant = tenantId === currentTenantId;
      const isInvitePending = cell.value === "PENDING";
      const hasValidLicense = cell.row.original.hasValidLicense;
      const disabled =
        !hasValidLicense ||
        (isCurrentTenant && !canLeaveAndSwitchTenant) ||
        (!!switchingTenantId && switchingTenantId === tenantId);

      const btnText = isCurrentTenant ? (
        "Leave"
      ) : isInvitePending ? (
        "Accept"
      ) : !!switchingTenantId && switchingTenantId === tenantId ? (
        <CircularProgress size={24} />
      ) : (
        "Switch"
      );
      const buttonProps: any = isCurrentTenant
        ? {
            color: "secondary",
            variant: "outlined",
            disabled,
            onClick: () => showLeaveAndSwitchTenantModal(tenantId)
          }
        : isInvitePending
          ? {
              color: "primary",
              variant: "contained",
              disabled,
              onClick: () => joinNewTenant(tenantId)
            }
          : {
              color: "primary",
              variant: "outlined",
              disabled,
              onClick: () => switchTenant(tenantId, "/")
            };

      return (
        <Tooltip
          title={!hasValidLicense ? "License key is invalid for the destination tenant" : ""}>
          <div>
            <Button size="small" {...buttonProps} style={{ fontSize: 12, lineHeight: "17px" }}>
              {btnText}
            </Button>
          </div>
        </Tooltip>
      );
    }
  }
];
