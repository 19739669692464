import CancelIcon from "@material-ui/icons/Cancel";
import Papa from "papaparse";
import React, { useMemo, useState } from "react";
import _, {
  filter,
  find,
  forEach,
  initial,
  join,
  map,
  now,
  replace,
  setWith,
  sortBy,
  split,
  toLower,
  truncate
} from "lodash";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  makeStyles
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DefaultButton from "components/Buttons/DefaultButton";
import EditJson from "./EditJson";
import PredictPeriod from "../../PredictionJob/components/PredictPeriod";
import PredictionResults from "./PredictionResults";
import useActions from "../hooks/useActions";
import useGetDatasets from "src/hooks/api/entities/useGetDatasets";
import usePredictionResults from "../../../../../../hooks/usePredictionResults";
import { EntityDto } from "openapi/Models";
import { Environment } from "src/pages/private/Environments/Environments";
import { InfoOutlined } from "@material-ui/icons";
import { NodeTypes } from "../../../utils";
import { OverflowTooltip, Spinner } from "src/components";
import { PredictionServiceHelperText } from "../utils/PredictionService.constants";
import { checkEnvRelaunchByEnvId } from "src/utils/envRelaunchNotification";
import { handleResponse } from "src/utils/apiService";
import { leadingTextInPath } from "utils/formatText";
import { useGetDatasetDataPost } from "src/hooks/api/entities/useGetEntityData";

interface IFile {
  name: string;
  fileSize: number;
  fileType: string;
  file: File;
  id: string;
}
interface IProps {
  projectId?: string;
  file: IFile;
  isTimeSeriesRecipe: boolean;
  environment: Environment | null;
  name: string;
  onInputChange: (e: any) => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    minHeight: "calc(100vh - 165px)"
  },
  input: {
    display: "none"
  },
  details: {
    fontSize: theme.spacing(2),
    fontWeight: 500
  },
  uploadText: {
    flexGrow: 1,
    "& label": {
      marginBottom: 0,
      "&.MuiInputLabel-root.MuiInputLabel-outlined": {
        width: "calc(100% - 32px) !important"
      }
    },

    " & .MuiFormHelperText-root": {
      color: "rgba(76, 175, 80, 1)"
    }
  },
  flex: {
    display: "flex",
    gap: theme.spacing(2)
  },
  inputContainer: {
    alignItems: "center",
    columnGap: theme.spacing(2)
  },
  inputLabel: {
    opacity: 0.625
  },
  inputLabelShrink: {
    opacity: 1
  }
}));

const uploadType = {
  json: {
    name: "json",
    label: "JSON"
  },
  csv: {
    name: "csv",
    label: "CSV File"
  },
  canvasDataset: {
    name: "canvasDataset",
    label: "Canvas Dataset"
  }
};

const FILE_SIZE_IN_MB = 50;
const MAX_FILE_SIZE = FILE_SIZE_IN_MB * 1024 * 1024;

const TestPredictionService: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const inputLabelProps = {
    classes: {
      root: classes.inputLabel,
      shrink: classes.inputLabelShrink
    }
  };

  const [value, setValue] = useState<string>(uploadType.csv.name);
  const [jsonString, setJsonString] = useState("");
  const [prediction, setPrediction] = useState<string>();
  const [predictPeriod, setPredictPeriod] = useState(0);
  const [datasets, setDatasets] = useState<EntityDto[]>([]);
  const [datasetId, setDatasetId] = useState("");
  const [isAddingToCanvas, setIsAddingToCanvas] = useState(false);
  const [isAddedToCanvas, setIsAddedToCanvas] = useState(false);
  const [isUpdateDatasetNameModalOpen, setIsUpdateDatasetNameModalOpen] = useState(false);

  const fileName = props.file?.name ?? "";
  const predictionResults = usePredictionResults();

  const { isLoading: isLoadingDatasets } = useGetDatasets({
    projectId: props?.projectId,
    onSuccess: (data) => {
      setDatasets(() =>
        sortBy(
          filter(
            data,
            (dataset: EntityDto) => dataset?.entityMeta?.entityViewType !== NodeTypes.Chart
          ),
          [
            (dataset: EntityDto) => toLower(dataset.displayName),
            (dataset: EntityDto) => toLower(dataset.name)
          ]
        )
      );
    },
    refetchOnMount: true,
    // Disabling canvas-dataset provision for the Release-September-11th.
    enabled: false
  });

  const {
    isLoading: isFetchingDataset,
    mutateAsync: getDatasetsMutation,
    reset: resetGetDatasetsMutation
  } = useGetDatasetDataPost();

  const getDatasetName = (id?: string) => {
    const foundDatasetName = find(datasets, { id });
    return foundDatasetName?.displayName || foundDatasetName?.name || "";
  };

  const getProposedDatasetName = () => {
    // Dataset creation
    let datasetName =
      value === uploadType.canvasDataset.name
        ? replace(getDatasetName(datasetId) || "", / /g, "-")
        : join(initial(split(fileName, ".")), ".");

    return `${datasetName ? `${datasetName}-` : ""}output-${now().toString().slice(-6)}`;
  };

  const { onDownload, onAddToCanvas } = useActions({
    projectId: props?.projectId,
    uploadType,
    value,
    fileName,
    datasetId,
    prediction,
    getDatasetName,
    setIsAddingToCanvas,
    setIsAddedToCanvas,
    setIsUpdateDatasetNameModalOpen
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    handleClick();
    setJsonString(() => "");
    setDatasetId(() => "");
  };

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Object.values(e.target.files as FileList).map((file: File) => ({
      name: file.name,
      fileSize: file.size,
      fileType: leadingTextInPath({ text: file.name }),
      file,
      id: `${file.name}.${file.size}`
    }));
    if (_.some(newFiles, (file) => file.fileSize > MAX_FILE_SIZE)) {
      handleResponse({ errorMessage: `File size exceeds the limit of ${FILE_SIZE_IN_MB} MB.` });
      return;
    }
    props.onInputChange({
      target: { value: newFiles, name: "files" }
    });
    e.target.value = "";
    setJsonString("");
  };

  const handleClick = () => {
    props.onInputChange({
      target: { value: [], name: "files" }
    });
  };

  const handleJsonChange = (newJsonString: string) => {
    setJsonString(newJsonString);
    if (fileName) {
      handleClick();
    }
  };

  const csvtoJson = (file: File): Promise<Record<string, any>> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results: any) {
          resolve(results.data);
        },
        error(err) {
          reject(err);
        }
      });
    });
  };

  const getPrediction = (json: Record<string, any>) => {
    checkEnvRelaunchByEnvId(props.environment?.id);

    predictionResults.mutate(
      {
        name: props.name,
        json
      },
      {
        onSuccess: (results) => {
          try {
            const resultObject = typeof results === "string" ? JSON.parse(results) : results;
            const predictionString = JSON.stringify(resultObject, null, 2);
            if (predictionString) {
              setIsAddedToCanvas(false);
              setPrediction(predictionString);
            }
          } catch {
            handleResponse({ errorMessage: "Unable to parse JSON/CSV input" });
          }
        }
      }
    );
  };

  const handlePredict = async () => {
    if (value === uploadType.canvasDataset.name) {
      await resetGetDatasetsMutation();

      await getDatasetsMutation(
        { datasetId },
        {
          onSuccess: (data) => {
            const formattedJson = map(data?.data?.rows, ({ cells }) => {
              const output = {};
              forEach(cells, (cell, index) =>
                // @ts-ignore
                setWith(output, [data?.data?.columns?.[index]], cell, Object)
              );
              return output;
            });

            getPrediction(formattedJson);
          }
        }
      );

      return;
    }

    try {
      if (props.isTimeSeriesRecipe && predictPeriod) {
        getPrediction({ key: { "0": predictPeriod } });
      } else if (jsonString) {
        const val = JSON.parse(jsonString);
        getPrediction(val);
      } else if (props.file && value === uploadType.csv.name) {
        getPrediction(await csvtoJson(props.file.file));
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          getPrediction(JSON.parse(e.target?.result as string));
        };
        reader.readAsText(props.file.file);
      }
    } catch {
      handleResponse({ errorMessage: "Unable to parse JSON/CSV input" });
    }
  };

  const isTestButtonDisabled = useMemo(() => {
    let isDisabled = false;

    if (value === uploadType.canvasDataset.name) {
      isDisabled = isDisabled || !!isLoadingDatasets || !datasetId || !!isFetchingDataset;
    } else {
      isDisabled =
        isDisabled || (!props.isTimeSeriesRecipe && _.isEmpty(fileName) && _.isEmpty(jsonString));
    }

    isDisabled =
      isDisabled || predictionResults.isLoading || (props.isTimeSeriesRecipe && !predictPeriod);

    isDisabled = isDisabled || !!isAddingToCanvas;

    return isDisabled;
  }, [
    props.isTimeSeriesRecipe,
    fileName,
    jsonString,
    predictionResults.isLoading,
    predictPeriod,
    value,
    isLoadingDatasets,
    datasetId,
    isFetchingDataset,
    isAddingToCanvas
  ]);

  const testBtn = (
    <Box style={{ alignSelf: props.isTimeSeriesRecipe ? "auto" : "flex-start" }}>
      <Tooltip title={!!isAddingToCanvas ? "Please wait. Adding dataset to canvas." : ""}>
        <div>
          <Button
            variant="contained"
            disabled={!!isTestButtonDisabled}
            color="primary"
            onClick={handlePredict}>
            {predictionResults.isLoading || !!isFetchingDataset ? (
              <CircularProgress style={{ color: "white" }} size={24} />
            ) : (
              "Test"
            )}
          </Button>
        </div>
      </Tooltip>
    </Box>
  );

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.details}>Test Prediction Service</div>
      {props.isTimeSeriesRecipe ? (
        <div className={classes.flex} style={{ alignItems: "center" }}>
          <PredictPeriod
            disabled={predictionResults.isLoading}
            value={predictPeriod}
            onChange={setPredictPeriod}
          />
          <Tooltip title="The prediction frequency for the selected period will be automatically determined based on the corresponding time series recipe">
            <InfoOutlined fontSize="small" />
          </Tooltip>
          {testBtn}
        </div>
      ) : (
        <>
          <FormControl>
            <RadioGroup
              value={value}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleChange}>
              <FormControlLabel
                value={uploadType.csv.name}
                control={<Radio />}
                label={uploadType.csv.label}
              />
              <FormControlLabel
                value={uploadType.json.name}
                control={<Radio />}
                label={uploadType.json.label}
              />
              {/* Hiding canvas-dataset provision for the Release-September-11th. */}
              {/* <FormControlLabel
                value={uploadType.canvasDataset.name}
                control={<Radio />}
                label={uploadType.canvasDataset.label}
              /> */}
            </RadioGroup>
          </FormControl>
          <Grid container className={classes.inputContainer}>
            {value === uploadType.canvasDataset.name ? (
              isLoadingDatasets ? (
                <Spinner size={24} />
              ) : (
                <>
                  <Autocomplete
                    size="small"
                    style={{ flexGrow: 1 }}
                    disableClearable
                    loading={!!isLoadingDatasets}
                    getOptionLabel={(option: any) =>
                      truncate(getDatasetName(option?.value), { length: 100 })
                    }
                    renderOption={(option: any) => (
                      <OverflowTooltip
                        style={{ width: "100%", whiteSpace: "nowrap" }}
                        value={getDatasetName(option?.value)}
                      />
                    )}
                    value={{ value: datasetId, label: getDatasetName(datasetId) }}
                    onChange={(_, option) => setDatasetId(() => option?.value || "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label={PredictionServiceHelperText.CanvasDatasetFieldLabel}
                        InputLabelProps={inputLabelProps}
                        variant="outlined"
                        data-testid="predictionServiceInputDatasetId"
                      />
                    )}
                    options={
                      map(datasets, (dataset: EntityDto) => ({
                        value: dataset?.id,
                        label: dataset?.displayName || dataset?.name || ""
                      })) ?? []
                    }
                    noOptionsText="No matching search results found!"
                    data-testid="predictionServiceDatasetId"
                  />
                  {testBtn}
                </>
              )
            ) : (
              <>
                <input
                  accept={value === uploadType.csv.name ? "text/csv" : "application/json"}
                  className={classes.input}
                  disabled={!_.isEmpty(jsonString)}
                  id="prediction-service-file-upload"
                  type="file"
                  onChange={handleFilesChange}
                />
                <TextField
                  id="upload"
                  name="upload"
                  label={`Upload File (Maximum ${FILE_SIZE_IN_MB} MB)`}
                  value={fileName}
                  className={classes.uploadText}
                  disabled
                  size="small"
                  helperText={fileName ? "Upload Successful" : ""}
                  data-testid="prediction-service-upload-file-input"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: !!fileName
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {fileName && (
                          <IconButton size="small" onClick={handleClick}>
                            <CancelIcon />
                          </IconButton>
                        )}
                        <label htmlFor="prediction-service-file-upload">
                          <DefaultButton
                            disabled={!_.isEmpty(jsonString)}
                            variant="outlined"
                            color="primary"
                            component="span"
                            size="small"
                            // New UX change
                            // Can be removed soon.
                            isCustomTheming={false}>
                            Browse
                          </DefaultButton>
                        </label>
                      </>
                    )
                  }}
                />
                {testBtn}
              </>
            )}
          </Grid>
          {value === uploadType.json.name && (
            <EditJson jsonString={jsonString} onChange={handleJsonChange} />
          )}
        </>
      )}
      {prediction && (
        <PredictionResults
          jsonString={prediction}
          getProposedDatasetName={getProposedDatasetName}
          onDownload={onDownload}
          onAddToCanvas={onAddToCanvas}
          isAddingToCanvas={isAddingToCanvas}
          isAddedToCanvas={isAddedToCanvas}
          isTesting={predictionResults.isLoading || !!isFetchingDataset}
          isUpdateDatasetNameModalOpen={isUpdateDatasetNameModalOpen}
          setIsUpdateDatasetNameModalOpen={setIsUpdateDatasetNameModalOpen}
        />
      )}
    </Paper>
  );
};

export default TestPredictionService;
