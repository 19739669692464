import React from "react";
import { Card, CardContent, CardHeader, Link } from "@material-ui/core";

import DataSourceEntitySourceDetails from "components/Canvas/CanvasDrawerItems/components/DataSourceEntitySourceDetails";
import { useStyles } from "components/Canvas/CanvasDrawerItems/components/styling";
import { generatePath, useNavigate } from "react-router-dom";
import { WebPaths } from "src/routing/routes";

interface IProps {
  data: $TSFixMe;
  setSideComponent: any;
}

const SourceDetails: React.FC<IProps> = (props) => {
  const { data, setSideComponent } = props;
  const navigate = useNavigate();

  const getNavigatePath = (goToSource?: boolean) => {
    if (!!data?.jobProps) {
      return generatePath(`${WebPaths.JobRoutes}${WebPaths.JobDataRoutes}`, {
        projectId: data.projectId,
        jobId: data?.jobProps?.jobId,
        scenarioId: data?.scenarioId,
        jobRunId: data?.jobProps?.jobRunId,
        entityId: data.itemId,
        section: goToSource ? "source" : "data"
      });
    } else {
      return generatePath(WebPaths.ViewData, {
        projectId: data.projectId,
        scenarioId: data?.scenarioId,
        entityId: data.itemId,
        section: goToSource ? "source" : "data"
      });
    }
  };

  const goToViewData = (goToSource?: boolean) => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });

    navigate(getNavigatePath(goToSource));
  };

  const { cardMargin } = useStyles();

  return (
    <Card className={cardMargin}>
      <CardHeader
        subheader={
          <Link
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => goToViewData(true)}>
            Source Details
          </Link>
        }
        subheaderTypographyProps={{ color: "initial" }}
      />
      <CardContent>
        <DataSourceEntitySourceDetails data={data} />
      </CardContent>
    </Card>
  );
};

export default SourceDetails;
