import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  blockContainer: {
    position: "relative",
    color: "#FFFFFF",
    display: "flex",
    width: "40px",
    height: "40px",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "4px"
  },
  labelContainer: {
    position: "relative",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  label: {
    display: "flex",
    position: "relative",
    top: 2,
    maxWidth: 100,
    lineHeight: "166%",
    flexBasis: "100%",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 12,
    color: "#000000"
  },
  labelActive: {
    fontWeight: "bold"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  highlighted: {
    "&::before": {
      content: "''",
      display: "block",
      height: "calc(100% + 10px)",
      width: "calc(100% + 10px)",
      background: "#BBE6FF",
      position: "absolute",
      filter: "blur(5.550000190734863px)",
      zIndex: -1
    }
  }
}));
