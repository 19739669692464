import React, { Dispatch, SetStateAction } from "react";

// Packages
import { Link, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

// MUI
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

// Hooks
import useUpdateSegmentName from "../hooks/useUpdateSegmentName";

// Components
import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";

// Contexts
import { useSegmentContext } from "../context/useSegmentContext";

// Constants
import { SegmentConfig, SegmentFormFields, SegmentHelperText } from "../utils/Segment.constants";

const SubTopNavBarBreadcrumbs = ({
  isSegmentNameDirty,
  setIsSegmentNameDirty
}: {
  isSegmentNameDirty: boolean;
  setIsSegmentNameDirty: Dispatch<SetStateAction<boolean>>;
}) => {
  const { scenarioId, jobRunId, segmentId } = useParams() || {};

  const {
    isJobPath,
    jobData,
    jobRunData,
    project,
    datasetData: dataset,
    isReadOnly
  } = useSegmentContext();

  const { watch, setValue } = useFormContext();
  const segmentName = watch(SegmentFormFields.Name);

  const { updateSegmentName } = useUpdateSegmentName();

  const onSegmentNameChange = async (newName: string) => {
    setValue(SegmentFormFields.Name, newName);

    if (!!segmentId) {
      await updateSegmentName();
      setIsSegmentNameDirty(() => false);
    }
  };

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link
        key="projects"
        to={`/projects/${project?.id}/canvas`}
        data-testid="segmentBreadcrumbProjectName">
        <RenderText>{project?.name || SegmentHelperText.Project}</RenderText>
      </Link>
    ) : (
      <RenderText key="projects" color="textSecondary" data-testid="segmentBreadcrumbProjectName">
        {project?.name || SegmentHelperText.Project}
      </RenderText>
    )
  );

  if (isJobPath && !!project?.id) {
    breadcrumbs.push(
      <Link key="jobs" to={`/projects/${project?.id}/jobs`} data-testid="segmentBreadcrumbJobs">
        <RenderText>{SegmentHelperText.Jobs}</RenderText>
      </Link>
    );

    breadcrumbs.push(
      !!jobData?.id ? (
        <Link
          key="job-name"
          to={`/projects/${project?.id}/jobs/${jobData?.id}`}
          data-testid="segmentBreadcrumbJobName">
          <RenderText>{jobData?.name || SegmentHelperText.Job}</RenderText>
        </Link>
      ) : (
        <RenderText key="job-name" color="textSecondary" data-testid="segmentBreadcrumbJobName">
          {jobData?.name || SegmentHelperText.Job}
        </RenderText>
      )
    );

    breadcrumbs.push(
      !!jobData?.id && !!jobRunId ? (
        <Link
          key="job-run-name"
          to={`/projects/${project?.id}/jobs/${jobData?.id}/job-runs/${jobRunId}/job-canvas`}
          data-testid="segmentBreadcrumbJobCanvas">
          <RenderText>{`Run Name ${jobRunData?.runId || jobRunId || SegmentHelperText.Unknown}`}</RenderText>
        </Link>
      ) : (
        <RenderText
          key="job-run-name"
          color="textSecondary"
          data-testid="segmentBreadcrumbJobCanvas">
          {`Run Name ${jobRunData?.runId || jobRunId || SegmentHelperText.Unknown}`}
        </RenderText>
      )
    );

    breadcrumbs.push(
      !!jobData?.id && !!scenarioId && !!dataset?.id && !!jobRunId ? (
        <Link
          key="dataset"
          to={`/projects/${project?.id}/jobs/${jobData?.id}/scenario/${scenarioId}/job-runs/${jobRunId}/entity/${dataset?.id}/data`}
          data-testid="segmentBreadcrumbDatasetName">
          <RenderText>{dataset?.name || SegmentHelperText.Dataset}</RenderText>
        </Link>
      ) : (
        <RenderText key="dataset" color="textSecondary" data-testid="segmentBreadcrumbDatasetName">
          {dataset?.name || SegmentHelperText.Dataset}
        </RenderText>
      )
    );
  } else {
    breadcrumbs.push(
      !!project?.id && !!dataset?.id && !!scenarioId ? (
        <Link
          key="dataset"
          to={`/view-data/projects/${project?.id}/entity/${dataset?.id}/scenario/${scenarioId}/data`}
          data-testid="segmentBreadcrumbDatasetName">
          <RenderText>{dataset?.name || SegmentHelperText.Dataset}</RenderText>
        </Link>
      ) : (
        <RenderText key="dataset" color="textSecondary" data-testid="segmentBreadcrumbDatasetName">
          {dataset?.name || SegmentHelperText.Dataset}
        </RenderText>
      )
    );
  }

  breadcrumbs.push(
    <EditName
      key="editSegmentName"
      fieldNameLabel="segment name"
      inputValue={segmentName}
      onSave={onSegmentNameChange}
      inputProps={{
        onChange: () => {
          !isSegmentNameDirty && setIsSegmentNameDirty(() => true);
        }
      }}
      isLoading={!!isReadOnly} // isLoading prop here serves for disabled prop of TextField.
      containerProps={{
        style: {
          width: ((segmentName || "")?.length + 1) * (SegmentConfig.SegmentNameFontSize - 4) + 16
        }
      }}
      data-testid="segmentBreadcrumbSegmentName"
    />
  );

  return (
    <Breadcrumbs data-testid="segmentBreadcrumbsContainer">
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
