import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const ArrowCollapseIcon: React.FC<IProps> = (props) => {
  const { width = 24, height = 24, viewBox = "", fill = "none", color = "black" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 3.08984L15 7.58984V3.99984H13V10.9998H20V8.99984H16.41L20.91 4.49984L19.5 3.08984ZM4 12.9998V14.9998H7.59L3.09 19.4998L4.5 20.9098L9 16.4098V19.9998H11V12.9998H4Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowCollapseIcon;
