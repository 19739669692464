import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import React, { PropsWithChildren, JSX } from "react";
import { Accordion, AccordionActions, AccordionDetails, Divider } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { IEdaCardProps } from "./EdaOverview";

interface IProps extends Omit<IEdaCardProps, "edaJson" | "url"> {
  title: string | JSX.Element;
  defaultExpanded?: boolean;
  label: string;
  actions?: JSX.Element;
  extra?: JSX.Element;
}

const useStyles = makeStyles({
  root: {
    boxShadow: "0px 4px 4px 0px #00000040",
    marginBottom: "20px"
  },
  wrapper: {
    width: "100%",
    display: "flex",
    gap: "10px",
    fontSize: 20
  }
});

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    minHeight: 46,

    "&$expanded": {
      minHeight: 46
    }
  },
  content: {
    margin: "8px 0",
    alignItems: "center",

    "&$expanded": {
      margin: "0px",
      alignItems: "center"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const CollapsibleCard: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { title, actions, label, children, extra, defaultExpanded } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion expanded={defaultExpanded ? true : undefined} defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={defaultExpanded ? undefined : <ExpandMoreIcon />}
          aria-controls={`panel-${label}-content`}
          id={`panel-${label}-header`}>
          <div className={classes.wrapper}>{title}</div>
          {extra}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
        {actions && (
          <>
            <Divider />
            <AccordionActions>{actions}</AccordionActions>
          </>
        )}
      </Accordion>
    </div>
  );
};

export default CollapsibleCard;
