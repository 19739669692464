import React, { useEffect, useState } from "react";
import { useQueries } from "@tanstack/react-query";

import { generatePath, useNavigate, useParams } from "react-router-dom";

import { Grid, Paper, Button, Typography, makeStyles } from "@material-ui/core";

import { toastWrapper } from "src/utils/toastWrapper";
import { capitalize } from "src/utils/capitalize";

import { getProjectCanvas } from "src/api/projects";
import {
  useGetScenarios,
  useGetVariables,
  useGetJob,
  useUpdateJob,
  usePublishJob
} from "src/hooks/api";

import { useProjectsStore } from "src/store/store";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import { Spinner } from "src/components";
import { DagFlow } from "src/pages/private/ProjectsModule/pages/Dag/components";

import { PublishJobModal } from "../PublishJobModal";

import { useProjectContext } from "src/pages/private/ProjectsModule/context/useProjectContext";
import { WebPaths } from "src/routing/routes";

const enum UseJobCanvasCompareCanvasDataQueryKeys {
  ProjectCanvas = "jobCanvasCompare-projectCanvas",
  JobCanvas = "jobCanvasCompare-jobCanvas"
}

export const useStyles = makeStyles({
  root: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: 16
  },
  canvasContainer: {
    // The value 24px is the padding of root-container (left 8px + right 8px + center 8px).
    width: "calc(50vw - 24px)"
  }
});

const JobCanvasCompare = () => {
  const { projectId, scenarioId, jobId }: $TSFixMe = useParams() || {};

  const navigate = useNavigate();

  const classes = useStyles();

  // Project context
  const { project } = useProjectContext() || {};

  // States - STARTS >>
  const [scenario, setScenario] = useState<$TSFixMe>({});
  const [showPublishJobModal, setShowPublishJobModal] = useState<$TSFixMe>(false);
  // << ENDS - States

  // Stores - STARTS >>
  const reloadTrigger = useProjectsStore((state) => state.reloadTrigger);
  // << ENDS - Stores

  // Mutations
  const {
    isLoading: isJobUpdating,
    isSuccess: isJobUpdated,
    mutateAsync: updateJobMutation,
    reset: resetUpdateJobMutation
  } = useUpdateJob();

  const {
    isLoading: isJobPublishing,
    mutateAsync: publishJobMutation,
    reset: resetPublishJobMutation
  } = usePublishJob();

  // Queries
  const { isFetched: isJobFetched, data: jobData } = useGetJob({ projectId, jobId });

  const {
    isFetching: isFetchingScenarios,
    isFetched: isScenariosFetched,
    data: scenariosData
  } = useGetScenarios({ projectId });

  const { data: variablesData, refetch: refetchVariables } = useGetVariables({
    projectId
  });

  useEffect(() => {
    !!reloadTrigger && refetchVariables();
  }, [reloadTrigger]);

  const [
    { isFetching: isFetchingProjectCanvas, data: projectCanvasData },
    { isFetching: isFetchingJobCanvas, data: jobCanvasData }
  ] = useQueries({
    queries: [
      {
        queryKey: [UseJobCanvasCompareCanvasDataQueryKeys.ProjectCanvas],
        queryFn: () =>
          getProjectCanvas({
            projectId,
            scenarioId
          }),
        cacheTime: 0
      },
      {
        queryKey: [UseJobCanvasCompareCanvasDataQueryKeys.JobCanvas],
        queryFn: () =>
          getProjectCanvas({
            projectId,
            scenarioId,
            jobId
          }),
        cacheTime: 0
      }
    ]
  });
  // << ENDS - Query hooks

  useEffect(() => {
    if (isScenariosFetched) {
      if ((scenariosData || [])?.length > 0) {
        if (isJobFetched) {
          if (Object.keys(jobData || {})?.length > 0) {
            const thisScenario = scenariosData?.find(
              (scenario: $TSFixMe) => scenario?.id === jobData?.scenarioId
            );

            Object.keys(thisScenario || {})?.length > 0 && setScenario(() => thisScenario);
          }
        }
      }
    }
  }, [isJobFetched, isScenariosFetched, jobData, scenariosData]);

  useEffect(() => {
    if (isJobUpdated) {
      toastWrapper({ type: "success", content: "Project Canvas is republished to the Job!" });
      setShowPublishJobModal(() => false);

      navigate(
        generatePath(`${WebPaths.JobRoutes}${WebPaths.JobId}`, {
          projectId,
          jobId
        })
      );
    }
  }, [isJobUpdated]);

  const updateJobParameters = async () => {
    resetUpdateJobMutation();

    const jobParametersForPayload: $TSFixMe = {};

    let isValidJobParameters = true;
    if ((variablesData || [])?.length > 0) {
      variablesData?.forEach((eachVariable: $TSFixMe) => {
        if (!!eachVariable?.name && !!eachVariable?.value) {
          jobParametersForPayload[eachVariable.name] = eachVariable.value;
        } else {
          isValidJobParameters = false;
        }
      });
    }

    if (isValidJobParameters) {
      const payload = { id: jobId, variables: jobParametersForPayload };
      await updateJobMutation(payload);
    } else {
      toastWrapper({ type: "error", content: "Something went wrong!" });
      setShowPublishJobModal(() => false);
    }
  };

  const publishJob = async () => {
    resetPublishJobMutation();

    await publishJobMutation(jobId, {
      onSuccess: () => updateJobParameters()
    });
  };

  return (
    <>
      {showPublishJobModal && (
        <PublishJobModal
          handleClose={() => setShowPublishJobModal(() => false)}
          handleSubmit={() => publishJob()}
          isSubmitLoading={isJobPublishing || isJobUpdating}
        />
      )}

      {/* @ts-ignore */}
      {isFetchingProjectCanvas || isFetchingJobCanvas ? (
        <Spinner />
      ) : (
        <>
          <SubTopNavBarWrapper
            subTopNavBarLeftSection={{
              component: <SubTopNavBarBreadcrumbs project={project} jobData={jobData} />
            }}
            subTopNavBarRightSection={{
              component: isFetchingScenarios ? (
                <Spinner size={18} />
              ) : (
                <Typography variant="body2" style={{ width: "max-content" }}>
                  Scenario:{" "}
                  <Typography variant="body2" component="span" color="textPrimary">
                    {capitalize(scenario?.name)}
                  </Typography>
                </Typography>
              )
            }}
          />

          <Grid container justifyContent="space-between" className={classes.root}>
            <Paper className={classes.canvasContainer}>
              <DagFlow
                jobProps={{
                  isCanvasCompareView: true,
                  jobId,
                  // $FixMe: Scope to be refactored.
                  // Sending project's canvas-data to compare with this job's canvas-data.
                  canvasData: jobCanvasData,
                  canvasBackgroundColor: "#fffee3",
                  renderContent: (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => setShowPublishJobModal(() => true)}>
                        Republish
                      </Button>
                    </>
                  )
                }}
              />
            </Paper>
            <Paper className={classes.canvasContainer}>
              <DagFlow
                jobProps={{
                  isCanvasCompareView: true,
                  // $FixMe: Scope to be refactored.
                  // Sending project's canvas-data to render right canvas.
                  // This is to not to depend upon canvas-data in common-store.
                  canvasData: projectCanvasData
                }}
              />
            </Paper>
          </Grid>
        </>
      )}
    </>
  );
};

export default JobCanvasCompare;
