import React, { useRef, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { PixelCrop } from "react-image-crop";
import Img1 from "src/assets/images/dashaboardCards/preview_image1.png";
import Img2 from "src/assets/images/dashaboardCards/preview_image2.png";
import Img3 from "src/assets/images/dashaboardCards/preview_image3.png";
import Img4 from "src/assets/images/dashaboardCards/preview_image4.png";
import Img5 from "src/assets/images/dashaboardCards/preview_image5.png";
import Img6 from "src/assets/images/dashaboardCards/preview_image6.png";
import Img7 from "src/assets/images/dashaboardCards/preview_image7.png";
import Img8 from "src/assets/images/dashaboardCards/preview_image8.png";
import Img9 from "src/assets/images/dashaboardCards/preview_image9.png";
import Img10 from "src/assets/images/dashaboardCards/preview_image10.png";
import Img11 from "src/assets/images/dashaboardCards/preview_image11.png";
import Img12 from "src/assets/images/dashaboardCards/preview_image12.png";
import Img13 from "src/assets/images/dashaboardCards/preview_image13.png";
import Img14 from "src/assets/images/dashaboardCards/preview_image14.png";
import Img15 from "src/assets/images/dashaboardCards/preview_image15.png";
import Img16 from "src/assets/images/dashaboardCards/preview_image16.png";
import Img17 from "src/assets/images/dashaboardCards/preview_image17.png";
import Img18 from "src/assets/images/dashaboardCards/preview_image18.png";
import Img19 from "src/assets/images/dashaboardCards/preview_image19.png";
import Img20 from "src/assets/images/dashaboardCards/preview_image20.png";
import Img21 from "src/assets/images/dashaboardCards/preview_image21.png";
import Img22 from "src/assets/images/dashaboardCards/preview_image22.png";
import Img23 from "src/assets/images/dashaboardCards/preview_image23.png";
import Img24 from "src/assets/images/dashaboardCards/preview_image24.png";
import Img25 from "src/assets/images/dashaboardCards/preview_image25.png";
import Img26 from "src/assets/images/dashaboardCards/preview_image26.png";
import Img27 from "src/assets/images/dashaboardCards/preview_image27.png";
import Img28 from "src/assets/images/dashaboardCards/preview_image28.png";
import Img29 from "src/assets/images/dashaboardCards/preview_image29.png";
import imageSelectionIcon from "src/assets/images/checkmark-square.png";
import Modal from "src/components/custom/Modal/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "src/components";
import { CustomImage } from "../CustomImage/CustomImage";
import { imageToBase64 } from "../../utils/ProjectSettings.helpers";
import { DropImageBox } from "../CustomImage/DropImageBox";

const useStyles = makeStyles({
  imageCount: {
    color: "#7C7C7C",
    fontSize: "14px",
    paddingBottom: "8px",
    lineHeight: "32px"
  },
  customImageBox: {
    height: "100%"
  },
  tabContainer: {
    height: "400px"
  },
  imagesWrap: {
    background: "#EEEDEB",
    gap: "8px"
  },
  title: {
    color: "#123553",
    marginBottom: "12px"
  },
  imageBox: {
    width: ({ previewType }: any) =>
      previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
    height: ({ previewType }: any) =>
      previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundClip: "padding-box",
    "&:hover::after": {
      content: '"CHOOSE"',
      position: "absolute",
      width: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
      height: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
      left: "0",
      top: "0",
      backgroundColor: "rgba(0, 54, 86, 0.64)",
      borderRadius: "4px",
      paddingTop: "22.5%",
      color: "white"
    }
  },
  selectedImage: {
    borderColor: "#f1957d",
    pointerEvents: "none",
    "&::after": {
      content: `url(${imageSelectionIcon})`,
      paddingTop: "20%",
      position: "absolute",
      backgroundColor: "rgba(0, 54, 86, 0.32)",
      width: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "200px" : "96px",
      height: ({ previewType }: any) =>
        previewType === "project" || previewType === "mini-project" ? "100px" : "120px",
      borderRadius: "4px",
      border: "1px solid #f1957d"
    }
  },
  customImgGrid: {
    height: "138px",
    width: "244px",
    borderRadius: "12px",
    "& img": {
      borderRadius: "12px"
    }
  },
  imagesBox: {
    flexWrap: "nowrap",
    gap: "16px",
    padding: "16px 0px"
  }
});

export const defaultImages = [
  Img1,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img7,
  Img8,
  Img9,
  Img10,
  Img11,
  Img12,
  Img13,
  Img14,
  Img15,
  Img16,
  Img17,
  Img18,
  Img19,
  Img20,
  Img21,
  Img22,
  Img23,
  Img24,
  Img25,
  Img26,
  Img27,
  Img28,
  Img29
];

type Props = {
  images?: any[];
  previewType?: "project" | "mini-project" | "default";
  onSubmit: (imageSubmitted: string | undefined) => void;
  isSubmitting?: boolean;
  handleClose: any;
  currentImgSrc?: string;
};

const PreviewImageModal = ({
  images = defaultImages,
  previewType = "default",
  isSubmitting = false,
  onSubmit,
  handleClose,
  currentImgSrc
}: Props) => {
  const styles = useStyles({ previewType });
  const [selectedImage, setSelectedImage] = useState();
  const [value, setValue] = useState("default");
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const handleSubmit = () => {
    if (value === "default") {
      selectedImage && imageToBase64(selectedImage, onSubmit, 543, 1084);
    } else {
      const base64Image = getCroppedImage();
      onSubmit(base64Image);
    }
  };

  const getCroppedImage = () => {
    if (!imgRef.current || !completedCrop) {
      throw new Error("Image or crop not defined");
    }
    const image = imgRef.current;
    const crop = completedCrop;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Unable to get canvas context");
    }
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg");
  };
  const [imgSrc, setImgSrc] = useState<string>("");
  const onFilesUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Modal
      open
      size="md"
      isSubmitting={isSubmitting}
      title="Choose Preview Image"
      submitLabel="Choose"
      isSubmitDisabled={value === "default" ? !selectedImage : !imgRef.current || !completedCrop}
      onClose={handleClose}
      onSubmit={handleSubmit}
      background="#EEEDEB"
      PaperProps={{
        style: {
          background: "#EEEDEB"
        }
      }}
      isNewTheme>
      <Grid container direction="column" className={styles.imagesWrap}>
        <Grid item>
          <Tabs value={value} onChange={setValue}>
            <Tab label="Default" value={"default"} />
            <Tab label="Custom" value={"custom"} />
          </Tabs>
        </Grid>
        <Grid item className={styles.tabContainer}>
          {value === "default" && (
            <Grid item>
              <Typography className={styles.imageCount}>Showing {images.length} images</Typography>
              <Grid container spacing={2}>
                {images.map((image: $TSFixMe, index: number) => (
                  <Grid key={index} item xs style={{ flexGrow: 0 }}>
                    <Button
                      onClick={() => setSelectedImage(selectedImage === image ? undefined : image)}
                      className={`${styles.imageBox} ${
                        selectedImage === image ? styles.selectedImage : ""
                      }`}
                      style={{ backgroundImage: `url(${image})` }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          {value === "custom" && (
            <Grid container className={styles.customImageBox}>
              {currentImgSrc && !imgSrc ? (
                <Grid container direction="column">
                  <Grid container direction="row" className={styles.imagesBox}>
                    <Grid item className={styles.customImgGrid}>
                      <DropImageBox onFilesUpload={onFilesUpload} />
                    </Grid>
                    <Grid item className={styles.customImgGrid}>
                      <img src={currentImgSrc} height="100%" width="100%" alt="project" />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <CustomImage setCompletedCrop={setCompletedCrop} imgRef={imgRef} imgSrc={imgSrc} />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default PreviewImageModal;
