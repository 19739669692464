import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import _, { includes } from "lodash";

import api from "src/utils/AxiosClient";
import { DFSRunConfigGroupDto } from "openapi/Models/dfsrun-config-group-dto";
import { RecipeTypes } from "src/pages/private/ProjectsModule/utils/Projects.constants";

const QUERY_KEY_PROJECT_DATA_APPS_DETAILS = "query-key-project-data-apps-details";

const useProjectDataAppsDetails = (
  projectId?: string,
  options?: UseQueryOptions<DFSRunConfigGroupDto[]>
): UseQueryResult<DFSRunConfigGroupDto[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_PROJECT_DATA_APPS_DETAILS, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "project Id is absent";
      }

      const response = await api.fetchResponse(
        async () => await api.ScenarioControllerApi.findScenarios(projectId),
        false
      );
      const defaultScenarioId = _.find(response, { default: true })?.id;
      if (!defaultScenarioId) {
        throw "Scenario Id couldnt be fetched";
      }

      const canvas = await api.fetchResponse(
        async () =>
          await api.ProjectsControllerV2Api.getProjectCanvas(projectId, true, defaultScenarioId)
      );

      const rapidModelRecipeNodeIds = _.map(
        _.filter(
          canvas.nodes,
          (node: any) => node.recipeType === RecipeTypes.RapidModel && node.status === "SUCCESS"
        ),
        "id"
      );

      const recipeResponses = await Promise.all(
        _.map(rapidModelRecipeNodeIds, (recipeId) =>
          api.fetchResponse(
            async () => await api.DfsRunConfigGroupControllerV2Api.findGroups(recipeId)
          )
        )
      );

      const recipes = recipeResponses?.map((response) =>
        Array.isArray(response) ? response[0] : response
      );

      return _.filter(recipes, (recipe: DFSRunConfigGroupDto) =>
        includes(
          [
            "binary_classification",
            "regression",
            "binary_classification_gpt",
            "multiclass_classification"
          ],
          recipe.metadata?.problemType
        )
      );
    },
    refetchOnMount: true,
    enabled: !!projectId,
    ...options
  });
};

export default useProjectDataAppsDetails;
export { QUERY_KEY_PROJECT_DATA_APPS_DETAILS };
