import { RefObject } from "react";

import { Row } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import { TData } from "../Table.types";

type Props = {
  rows: Row<TData | any>[];
  tableContainerRef: RefObject<HTMLDivElement>;
};

const useHook = (props: Props) => {
  const { rows, tableContainerRef } = props || {};

  return useVirtualizer({
    count: rows?.length,
    estimateSize: () => 32, // estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef?.current,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" && navigator?.userAgent?.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect()?.height
        : undefined,
    overscan: 5
  });
};

export default useHook;
