import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: $TSFixMe) => ({
  multiSelectionError: {
    "& .MuiFormControl-root": {
      borderColor: "red"
    }
  },
  destination: {
    width: "auto",
    marginTop: 20,
    padding: 10,
    border: "1px dashed #A6A6A6",
    borderRadius: 8,
    "& .destinationTitle": {
      display: "inline-block",
      margin: 0,
      width: "auto",
      padding: "0 5px",
      fontSize: 12,
      fontWeight: 100,
      color: "#003656",
      opacity: 0.5
    },
    "& .destinationTitleReadOnlyMode": {
      marginBottom: 5,
      fontSize: 12,
      fontWeight: 100,
      opacity: 0.6
    },
    "& .MuiAvatar-root": {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginLeft: 10,
      backgroundColor: "#F7F7F7",
      "& svg": {
        color: "initial"
      }
    },
    "& .MuiAvatar-root:not(.disabled):not(.loading):hover": {
      backgroundColor: "#00000080",
      cursor: "pointer",
      "& svg": {
        color: "#fff"
      }
    },
    "& .MuiAvatar-root.disabled": {
      opacity: 0.5,
      cursor: "not-allowed"
    },
    "& .MuiAvatar-root.loading": {
      cursor: "progress"
    }
  },
  selectDropdownContainer: {
    maxHeight: 250
  },
  checkbox: {
    display: "flex",
    gap: "3px",
    alignItems: "center",
    fontSize: "13px"
  },
  ellipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "flex"
  }
}));

export { useStyles };
