import React from "react";
import _ from "lodash";
import { Box, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { CopyTextIcon } from "../../../CopyButton/CopyTextIcon";
import AIGuideTextRow from "src/pages/Projects/AIGuide/common/AIGuideTextRow";
import ChatBox from "src/pages/Projects/AIGuide/common/ChatBox";
import { TextIconV2 } from "src/icons/NewUX/TextIconV2";

const useStyles = makeStyles({
  promptsRow: {
    padding: "12px 32px 0px 16px",
    width: "inherit",
    gap: "12px",
    flexWrap: "nowrap"
  },
  textIconWrap: {
    flexWrap: "nowrap",
    background: "#fff",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    minWidth: "20px"
  },
  promptsWrap: {
    width: ({ isExpanded }: { isExpanded: boolean }) =>
      isExpanded ? "calc(100% - 134px)" : "150px",
    flexWrap: "nowrap",
    position: "relative",
    margin: "6px 0px 0px 12px",
    background: "#fff"
  },
  arrow: {
    content: " ",
    position: "absolute",
    width: 0,
    height: 0,
    left: "-12px",
    top: "12px",
    transform: "translateY(-50%)",
    border: "12px solid",
    borderColor: "#fff transparent transparent transparent"
  },
  promptsList: {
    flexWrap: "nowrap",
    padding: ({ isExpanded }: { isExpanded: boolean }) => (isExpanded ? "12px" : "4px 8px"),
    gap: "8px"
  },
  promptsListWrap: {
    flexWrap: "nowrap",
    padding: "0px 18px",
    gap: "8px",
    listStyle: "decimal"
  },
  promptListHeader: {
    color: "#4646B5",
    fontWeight: 400,
    fontSize: "14px"
  },
  promptTitle: {
    color: "#4646B5",
    fontWeight: 400
  },
  promptDescription: {
    fontStyle: "italic",
    color: "#515151",
    fontWeight: 400
  },
  promptWrap: {
    flexWrap: "nowrap"
  },
  promptWithCopy: {
    flexWrap: "nowrap",
    gap: "16px"
  },
  prompt: {
    flexWrap: "nowrap"
  },
  textIcon: {
    height: "32px",
    width: "32px",
    marginRight: "4px"
  }
});

export const PromptSuggestions = ({
  isExpanded,
  inputNames,
  promts,
  handleToggle,
  setShowDeleteModal,
  userInput = "Prompt Suggestions",
  tags
}: {
  inputNames: string[];
  isExpanded: boolean;
  promts: Array<{ title: string; description: string }>;
  handleToggle: () => void;
  setShowDeleteModal?: (show: boolean) => void;
  userInput?: string;
  tags?: string[];
}) => {
  const classes = useStyles({ isExpanded });
  return (
    <Grid container direction="column">
      <AIGuideTextRow
        userInput={userInput}
        queryInputs={_.map(inputNames, (name) => ({ name }))}
        setShowDeleteModal={setShowDeleteModal}
        canDelete={true}
        canCopy={true}
        maxHeight="132px"
        color="#fff"
        width="750px"
        hoverBgColor="#fff"
        tags={tags}
      />
      <Grid container direction="row" className={classes.promptsRow}>
        <Box className={classes.textIcon}>
          <TextIconV2 />
        </Box>
        <ChatBox
          noPadding
          color={"#FFF"}
          border={isExpanded ? "#4646B5" : "#fff"}
          width={isExpanded ? "calc(100% - 160px)" : "auto"}
          childWidth="100%">
          <Grid container direction="column" className={classes.promptsList}>
            <Grid
              container
              direction="row"
              onClick={() => handleToggle()}
              alignItems="center"
              justifyContent="space-between">
              <Typography
                variant="subtitle2"
                className={classes.promptListHeader}
                data-testid="ai-prompts-header">
                List of Prompts
              </Typography>
              <IconButton size="small" data-testid="ai-query-expand-toggle-icon">
                {isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
              </IconButton>
            </Grid>
            {isExpanded && (
              <Grid container direction="column" className={classes.promptsListWrap} component="ol">
                {promts?.map((prompt, index) => {
                  const { title, description } = prompt;
                  return (
                    <Grid item className={classes.promptWrap} key={index} component="li">
                      <Grid container direction="row" className={classes.promptWithCopy}>
                        <Grid container direction="column" className={classes.prompt}>
                          <Typography
                            variant="subtitle2"
                            className={classes.promptTitle}
                            data-testid="ai-prompt-title">
                            {title}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className={classes.promptDescription}
                            data-testid="ai-prompt-description">
                            {description}
                          </Typography>
                        </Grid>
                        <Box>
                          <CopyTextIcon text={title} data-testid="ai-prompt-copy-text-icon" />
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        </ChatBox>
      </Grid>
    </Grid>
  );
};
