import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

const QUERY_KEY_ENV_USAGE = "query-env-usage";

const useGetEnvironmentUsage = (
  envId: string,
  options?: UseQueryOptions<any>
): UseQueryResult<any> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_ENV_USAGE, envId],
    queryFn: async () => {
      return await getAPIWithRethrow(`/v2/envs/${envId}/usage`);
    },
    cacheTime: 0,
    ...options
  });
};

export default useGetEnvironmentUsage;
export { QUERY_KEY_ENV_USAGE };
