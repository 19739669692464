import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Box,
  FormGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Typography
} from "@material-ui/core";

import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";

import Modal from "src/components/custom/Modal/Modal";

import NewThemeWrapper from "src/styles/NewThemeWrapper";

type Props = {
  handleClose: () => void;
  handleSubmit: () => void;
  publishJobsProps: $TSFixMe;
};

export const PublishJobsModal = (props: Props) => {
  const { handleClose, handleSubmit, publishJobsProps } = props || {};
  const { jobsData, isPublishingJobs, publishingJobIds, setPublishingJobIds } =
    publishJobsProps || {};

  const onJobSelection = (event: $TSFixMe) => {
    if (event?.target?.checked) {
      setPublishingJobIds(() => [...new Set([...(publishingJobIds || []), event?.target?.name])]);
    } else {
      setPublishingJobIds(() =>
        publishingJobIds?.filter((eachJobId: string) => eachJobId !== event?.target?.name)
      );
    }
  };

  useEffect(() => () => setPublishingJobIds(() => []), []);

  return (
    <NewThemeWrapper>
      <Modal
        open={true}
        title="Republish Canvas to Jobs"
        cancelLabel="No"
        submitLabel="Yes, Republish"
        isSubmitting={isPublishingJobs}
        isSubmitDisabled={(publishingJobIds || [])?.length === 0}
        onClose={() => !isPublishingJobs && handleClose()}
        onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" gridGap={20}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Jobs</FormLabel>
            <FormGroup>
              {jobsData?.map((eachJob: $TSFixMe) => {
                return (
                  <FormControlLabel
                    key={uuidv4()}
                    control={
                      <Checkbox
                        checked={publishingJobIds?.includes(eachJob?.dto?.id)}
                        onChange={onJobSelection}
                        name={eachJob?.dto?.id}
                      />
                    }
                    label={eachJob?.dto?.name}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <Box display="flex" gridGap={16}>
            <ReportProblemOutlined />
            <Box display="flex" flexDirection="column" gridGap={16}>
              <Typography variant="body2">
                This will replace the existing job canvas with the latest project canvas. Are you
                sure?
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </NewThemeWrapper>
  );
};
