/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface StatsDto
 */
export interface StatsDto {
    /**
     * 
     * @type {string}
     * @memberof StatsDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof StatsDto
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatsDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatsDto
     */
    'updated'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatsDto
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatsDto
     */
    'parentType'?: StatsDtoParentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StatsDto
     */
    'status'?: StatsDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StatsDto
     */
    'statsType'?: StatsDtoStatsTypeEnum;
}

export const StatsDtoParentTypeEnum = {
    File: 'FILE',
    Entity: 'ENTITY',
    ScenarioStep: 'SCENARIO_STEP'
} as const;

export type StatsDtoParentTypeEnum = typeof StatsDtoParentTypeEnum[keyof typeof StatsDtoParentTypeEnum];
export const StatsDtoStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Completed: 'COMPLETED',
    Error: 'ERROR',
    NotAvailable: 'NOT_AVAILABLE'
} as const;

export type StatsDtoStatusEnum = typeof StatsDtoStatusEnum[keyof typeof StatsDtoStatusEnum];
export const StatsDtoStatsTypeEnum = {
    TenK: 'TEN_K',
    Full: 'FULL',
    Pprofiler: 'PPROFILER'
} as const;

export type StatsDtoStatsTypeEnum = typeof StatsDtoStatsTypeEnum[keyof typeof StatsDtoStatsTypeEnum];


