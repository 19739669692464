import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import OverflowTooltip from "../OverflowTooltip";

type InfoPairDisplayProps = {
  label: string;
  value: string;
};

const useStyles = makeStyles({
  infoPairDisplay: {
    display: "flex",
    paddingTop: "16px",
    whiteSpace: "pre"
  }
});

export const CardInfoPairDisplay = ({ label, value }: InfoPairDisplayProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.infoPairDisplay}>
      <Typography component="span" color="textSecondary" variant="body2">
        {label}:{" "}
      </Typography>
      <Typography component="span" variant="body2">
        <Box display="inline-flex" test-id="dataAppDescription">
          <OverflowTooltip value={value || "-"} />
        </Box>
      </Typography>
    </Grid>
  );
};
