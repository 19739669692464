import React, { useMemo } from "react";

import { CircularProgress } from "@material-ui/core";

import useStoreSelectors from "../hooks/useStoreSelectors";

import { FileMetaDataKeys, FileUploadStatuses } from "../utils/Dataset.constants";

const FilesUploadProgress = () => {
  // Stores - STARTS >>
  const { datasetFilesStore } = useStoreSelectors();
  // << ENDS - Stores

  const filesUploadProgress = useMemo(() => {
    if ((datasetFilesStore || [])?.length === 0) {
      return false;
    }

    let filesCounter: number = 0;
    let thisFilesUploadProgress: number = 0;
    let isFilesUploading: boolean = false;

    datasetFilesStore?.forEach((eachFile: $TSFixMe) => {
      isFilesUploading = [FileUploadStatuses.Uploading, FileUploadStatuses.EntityCreated].includes(
        eachFile?.status
      );

      if (isFilesUploading || [FileUploadStatuses.Success].includes(eachFile?.status)) {
        filesCounter++;
        thisFilesUploadProgress += eachFile?.[FileMetaDataKeys.UploadProgress];
      }
    });

    if (!isFilesUploading) {
      return false;
    }

    return thisFilesUploadProgress / filesCounter;
  }, [datasetFilesStore]);

  return (
    <>
      {filesUploadProgress !== false && (
        <CircularProgress
          variant="determinate"
          size={23}
          color="inherit"
          value={filesUploadProgress}
        />
      )}
    </>
  );
};

export default FilesUploadProgress;
