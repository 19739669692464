/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EDAInfo
 */
export interface EDAInfo {
    /**
     * 
     * @type {string}
     * @memberof EDAInfo
     */
    'status'?: EDAInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EDAInfo
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof EDAInfo
     */
    'startTime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EDAInfo
     */
    'truncatedResults'?: boolean;
}

export const EDAInfoStatusEnum = {
    NotStarted: 'NOT_STARTED',
    Started: 'STARTED',
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;

export type EDAInfoStatusEnum = typeof EDAInfoStatusEnum[keyof typeof EDAInfoStatusEnum];


