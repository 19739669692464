import React from "react";
import { Handle, Position } from "react-flow-renderer";

import BallotIcon from "@material-ui/icons/Ballot";

import { EntityModal } from "../CanvasDrawerItems";

import { useDrawerStore } from "../../../store/store";
import { sideComponentSetter } from "../../../store/store.selectors";

import styles from "./EntityBlock.module.scss";

const EntityBlock = ({ data }: $TSFixMe) => {
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const handleClick = () => {
    setSideComponent({
      sideComponent: EntityModal,
      sideComponentProps: { title: data.label, data }
    });
  };

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <div
        role="button"
        tabIndex={0}
        className={styles.entityBlockContainer}
        onClick={handleClick}
        onKeyPress={handleClick}>
        <div className={styles.relativeContainer}>
          <div className={styles.iconContainer}>
            <BallotIcon />
          </div>
          <div className={styles.label}>{data.label}</div>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default EntityBlock;
