import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Header from "../ContentSectionHeader/Header";
import useHelpers from "../../hooks/useHelpers";
import useStoreSelectors from "../../hooks/useStoreSelectors";
import UploadSection from "../UploadSection";
import OntologySection from "../OntologySection/OntologySection";
import SqlInterface from "../SqlInterface/SqlInterface";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "inherit",
    rowGap: theme.spacing(2)
  },
  container: {
    flex: 1,
    width: "100%",
    overflow: "auto",
    borderRadius: theme.spacing(1.5)
  },
  ontologyContainer: {
    flex: 1,
    rowGap: theme.spacing(2),
    overflow: "auto"
  }
}));

const ContentSection = () => {
  const classes = useStyles();

  const { source } = useHelpers();
  const { datasetIsOntologyStore } = useStoreSelectors();

  return (
    <Grid container direction="column" className={classes.root}>
      <Header />

      {source?.isSql ? (
        <Paper className={classes.container}>
          <SqlInterface />
        </Paper>
      ) : datasetIsOntologyStore ? (
        <Grid container direction="column" className={classes.ontologyContainer}>
          <OntologySection />
        </Grid>
      ) : (
        <Paper className={classes.container}>
          <UploadSection />
        </Paper>
      )}
    </Grid>
  );
};

export default ContentSection;
