/* eslint-disable no-unused-vars */
import React from "react";
import clsx from "clsx";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { IdeaIcon } from "src/icons/IdeaIcon";
import { TrafficLightIcon } from "src/icons/TrafficLightIcon";

const ideasList = [
  "Remove all the null data from a dataset",
  "Change timestamp to YYYY-MM-DD",
  "Replace space with underscore for a given column values"
];

const limitationsList = [
  "May occasionally generate incorrect code",
  "Validate your Outputs",
  "May occasionally take longer to respond"
];

const useStyles = makeStyles({
  modalBody: {
    paddingTop: "18px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    overflow: "auto"
  },
  modalHeader: {
    color: "#000000",
    opacity: 0.4,
    maxWidth: "35%",
    fontSize: "20px",
    lineHeight: "24px",
    paddingBottom: "24px",
    textAlign: "center",
    paddingTop: "42px"
  },
  modalContent: {
    paddingLeft: "10%",
    paddingRight: "10%",
    textAlign: "center",
    paddingTop: "16px",
    color: "#003656",
    gap: "12px"
  },
  textCard: {
    background: "#ededed",
    borderRadius: "12px",
    color: "#003656",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "56px",
    padding: "12px",
    textAlign: "left"
  },
  ideaCard: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E7E7FF"
    }
  },
  textCardWrap: {
    gap: "12px"
  },
  modalContentHeader: {
    gap: "8px",
    paddingBottom: "12px",
    alignItems: "center"
  }
});

export const AskAIInitialView = ({ setUserInput }: { setUserInput: (input: string) => void }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" alignItems="center">
      <Typography className={classes.modalHeader}>
        Now experience natural way of creating your code
      </Typography>
      <Grid container direction="row" className={classes.modalContent}>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justifyContent="center"
            className={classes.modalContentHeader}>
            <IdeaIcon />
            <Typography>Recommendations</Typography>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.textCardWrap}
            test-id="ask-ai-modal-ideas">
            {ideasList.map((idea, index) => (
              <Grid
                key={index}
                container
                direction="row"
                test-id="ask-ai-modal-idea"
                className={clsx([classes.textCard, classes.ideaCard])}
                onClick={() => setUserInput(idea)}>
                <Typography variant="body2">“{idea}”</Typography>
                <ArrowForwardIcon fontSize="small" />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justifyContent="center"
            className={classes.modalContentHeader}>
            <TrafficLightIcon />
            <Typography>Limitation</Typography>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.textCardWrap}
            test-id="ask-ai-modal-limitations">
            {limitationsList.map((limitation, index) => (
              <Typography
                key={index}
                variant="body2"
                className={classes.textCard}
                test-id="ask-ai-modal-limitation">
                {limitation}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
