/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface GroupTransformLogDto
 */
export interface GroupTransformLogDto {
    /**
     * 
     * @type {string}
     * @memberof GroupTransformLogDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupTransformLogDto
     */
    'logReqStatus'?: GroupTransformLogDtoLogReqStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupTransformLogDto
     */
    'logs'?: Array<string>;
}

export const GroupTransformLogDtoLogReqStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type GroupTransformLogDtoLogReqStatusEnum = typeof GroupTransformLogDtoLogReqStatusEnum[keyof typeof GroupTransformLogDtoLogReqStatusEnum];


