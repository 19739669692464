import { useMutation } from "@tanstack/react-query";

import api from "utils/AxiosClient";

import { RemoveInputsFromThreadsRequestDto } from "openapi/Models";

export const useRemoveAIThreadInputsMutation = () => {
  return useMutation({
    mutationFn: async ({
      threadId,
      request
    }: {
      threadId: string;
      request: RemoveInputsFromThreadsRequestDto;
    }) => {
      return await api.fetchResponse(() =>
        api.AiControllerApi.removeInputsFromThread(threadId, request)
      );
    }
  });
};
