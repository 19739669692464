import React from "react";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { ScenarioLeavePagePromptDetails } from "../utils/Scenario.constants";

type Props = {
  onLeavePage: () => void;
  resetLeavePage: () => void;
};

const ConfirmLeavePage = (props: Props) => {
  const { onLeavePage, resetLeavePage } = props || {};

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={ScenarioLeavePagePromptDetails.title}
      content={[
        ScenarioLeavePagePromptDetails.messageLine1,
        ScenarioLeavePagePromptDetails.messageLine2
      ]}
      onClose={resetLeavePage}
      onSubmit={onLeavePage}
    />
  );
};

export default ConfirmLeavePage;
