import { useProjectsStore } from "src/store/store";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { postAPIWithRethrow, handleResponse } from "src/utils/apiService";
import { QUERY_KEY_EDA_CHARTS } from "./useGetEdaCharts";

interface IVariables {
  noOfCharts?: number;
  itemId?: string;
  async?: boolean;
  scenarioId?: string;
  projectRunEntryId?: string;
  triggerOnError?: boolean;
  readOnly?: boolean;
  generateAdditionalCharts?: boolean;
}

export interface IEdaChartsData {
  status: string;
  message: string;
  startTime: number;
  chartJson: string[];
}

const POLLING_INTERVAL = 5;
const ABORT_TIMEOUT = 5 * 60;

const fetchResponse = async (
  itemId?: string,
  async?: boolean,
  triggerOnError?: boolean,
  scenarioId?: string,
  noOfCharts?: number,
  projectRunEntryId?: string,
  readOnly?: boolean,
  generateAdditionalCharts?: boolean,
  showToast?: boolean
) => {
  return await postAPIWithRethrow(
    `/v2/entities/${itemId}/eda-charts${async ? `?async=${async}` : ""}${
      triggerOnError ? `&triggerOnError=${triggerOnError}` : ""
    }${generateAdditionalCharts ? `&generateAdditionalCharts=${generateAdditionalCharts}` : ""}${
      readOnly ? `&readOnly=${readOnly}` : ""
    }`,
    {
      scenarioId,
      noOfCharts,
      projectRunEntryId
    },
    {},
    showToast
  );
};
const useGenerateEdaCharts = (): UseMutationResult<
  IEdaChartsData,
  unknown,
  IVariables,
  unknown
> => {
  const queryClient = useQueryClient();
  const [generatingEdaChartsEntities, setGeneratingEdaChartsEntities] = useProjectsStore(
    (state) => [state.generatingEdaChartsEntities, state.setGeneratingEdaChartsEntities]
  );

  return useMutation({
    mutationFn: async ({
      noOfCharts,
      itemId,
      async,
      projectRunEntryId,
      scenarioId,
      triggerOnError,
      readOnly,
      generateAdditionalCharts
    }) => {
      try {
        const results = await fetchResponse(
          itemId,
          async,
          triggerOnError,
          scenarioId,
          noOfCharts,
          projectRunEntryId,
          readOnly,
          generateAdditionalCharts,
          true
        );

        if (results.edaChartsInfo.status === "STARTED") {
          return new Promise((resolve, reject) => {
            let count = 1;
            const interval = setInterval(async () => {
              try {
                const subResults = await fetchResponse(
                  itemId,
                  async,
                  false,
                  scenarioId,
                  noOfCharts,
                  projectRunEntryId,
                  undefined,
                  undefined,
                  false
                );
                count = count + 1;

                if (subResults.edaChartsInfo.status === "SUCCESS") {
                  clearInterval(interval);
                  resolve(subResults.edaChartsInfo);
                }

                if (count > ABORT_TIMEOUT / POLLING_INTERVAL) {
                  handleResponse({
                    errorMessage:
                      "The chart generation process was unable to finish within a dedicated timeframe. Kindly attempt the operation again."
                  });
                  clearInterval(interval);
                  reject("Error in generating EDA charts");
                }
              } catch (e: any) {
                if (e?.response?.status !== 404) {
                  handleResponse({
                    errorMessage:
                      e?.response?.data?.msg ??
                      "An error occurred during the generation of charts. Please try again."
                  });
                }

                clearInterval(interval);
                reject("Error in generating EDA charts");
              }
            }, POLLING_INTERVAL * 1000);
          });
        } else {
          return results.edaChartsInfo;
        }
      } catch {
        handleResponse({
          errorMessage: "An error occurred during the generation of charts. Please try again."
        });
        throw "Error in generating EDA charts";
      }
    },
    onSuccess: (result, { itemId, generateAdditionalCharts, scenarioId, projectRunEntryId }) => {
      queryClient.setQueryData(
        [QUERY_KEY_EDA_CHARTS, itemId, scenarioId, projectRunEntryId],
        result?.chartJson
      );
      if (itemId && _.includes(generatingEdaChartsEntities, itemId)) {
        setGeneratingEdaChartsEntities(_.without(generatingEdaChartsEntities, itemId));
      }

      handleResponse({
        successMessage: generateAdditionalCharts
          ? "Additional Data Analysis charts are generated successfully."
          : "The Data Analysis Charts have been generated successfully and are now ready for display."
      });
    },
    onError: (__, { itemId }) => {
      if (itemId && _.includes(generatingEdaChartsEntities, itemId)) {
        setGeneratingEdaChartsEntities(_.without(generatingEdaChartsEntities, itemId));
      }
    }
  });
};

export default useGenerateEdaCharts;
