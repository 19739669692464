import React from "react";
import _ from "lodash";

import { Typography } from "@material-ui/core";
import { INACTIVE_DATA_SOURCE_MSG } from "../../utils/DataSources.constants";

import { FivetranStatus } from "src/pages/private/ProjectsModule/pages/Dataset/utils/Dataset.constants";

import ThirdPartySynchedData from "./ThirdPartySynchedData";

const ThirdPartyData = ({
  id,
  details,
  status
}: {
  id: string;
  details: $TSFixMe;
  status: FivetranStatus | null;
}) => {
  const renderChildComponent = () => {
    switch (status) {
      case FivetranStatus.active:
      case FivetranStatus.syncing:
      case FivetranStatus.syncIssues:
      case FivetranStatus.syncPaused:
        return <ThirdPartySynchedData id={id} details={details} status={status} />;
      case FivetranStatus.inActive:
        return <Typography>{INACTIVE_DATA_SOURCE_MSG}</Typography>;

      default:
        return null;
    }
  };

  return renderChildComponent();
};

export default ThirdPartyData;
