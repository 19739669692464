/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { NameChangeDto } from './name-change-dto';

/**
 * 
 * @export
 * @interface AddOutputRequestDto
 */
export interface AddOutputRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AddOutputRequestDto
     */
    'flowType'?: AddOutputRequestDtoFlowTypeEnum;
    /**
     * 
     * @type {Array<NameChangeDto>}
     * @memberof AddOutputRequestDto
     */
    'nameChanges'?: Array<NameChangeDto>;
}

export const AddOutputRequestDtoFlowTypeEnum = {
    AiGuide: 'AI_GUIDE',
    Recipe: 'RECIPE',
    Dataapp: 'DATAAPP',
    PredictionService: 'PREDICTION_SERVICE'
} as const;

export type AddOutputRequestDtoFlowTypeEnum = typeof AddOutputRequestDtoFlowTypeEnum[keyof typeof AddOutputRequestDtoFlowTypeEnum];


