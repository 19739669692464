/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface MessageFeedbackRequestDto
 */
export interface MessageFeedbackRequestDto {
    /**
     * 
     * @type {string}
     * @memberof MessageFeedbackRequestDto
     */
    'feedback'?: MessageFeedbackRequestDtoFeedbackEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageFeedbackRequestDto
     */
    'comment'?: string;
}

export const MessageFeedbackRequestDtoFeedbackEnum = {
    Liked: 'LIKED',
    Disliked: 'DISLIKED',
    NotProvided: 'NOT_PROVIDED'
} as const;

export type MessageFeedbackRequestDtoFeedbackEnum = typeof MessageFeedbackRequestDtoFeedbackEnum[keyof typeof MessageFeedbackRequestDtoFeedbackEnum];


