import React from "react";

import { UseTableRowProps } from "react-table";
import { get, lowerCase } from "lodash";

import { Typography } from "@material-ui/core";

import { InputColumn } from "src/components/Table/TableUtils";

export enum Status {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  EXPIRED = "EXPIRED",
  CANCELLED = "CANCELLED"
}
const statusColorMapping = {
  [Status.ACTIVE]: "#00BC4B",
  [Status.PENDING]: "#FF9900",
  [Status.EXPIRED]: "#DB2D02",
  [Status.CANCELLED]: "#DB2D02"
};

const statusCellValueMapping = {
  [Status.ACTIVE]: "Active",
  [Status.PENDING]: "Pending",
  [Status.EXPIRED]: "Link Expired",
  [Status.CANCELLED]: "Cancelled"
};

const roleSortType = (rowA: UseTableRowProps<any>, rowB: UseTableRowProps<any>) => {
  const valA = get(rowA.original, "role");
  const valB = get(rowB.original, "role");

  if (lowerCase(valA) > lowerCase(valB)) {
    return 1;
  }
  if (lowerCase(valA) < lowerCase(valB)) {
    return -1;
  }

  return 0;
};

export const getUserColumns = (roles: any): InputColumn[] => [
  {
    id: "Username",
    accessor: "email",
    isSortable: true,
    Header: "Username",
    color: "textPrimary",
    type: "string",
    width: 336
  },
  {
    id: "Status",
    accessor: "status",
    Header: "Status",
    isSortable: true,
    type: "string",
    Cell: (cell: $TSFixMe) => {
      const status = cell.row.original.status as Status;
      return (
        <Typography style={{ color: statusColorMapping[status], fontSize: "14px" }}>
          {statusCellValueMapping[status]}
        </Typography>
      );
    },
    width: 144
  },
  {
    id: "Role",
    accessor: "roleId",
    sortType: roleSortType,
    isSortable: true,
    Header: "Role",
    Cell: (cell: $TSFixMe) => cell?.row?.original?.role || "Unknown",
    values: roles,
    isEditable: true,
    hideSelectInputLabel: true,
    selectVariant: "standard",
    type: "select"
  },
  {
    id: "Invited On",
    accessor: "invitedOn",
    Header: "Invited On",
    isSortable: true,
    type: "datetime"
  },
  {
    id: "Created On",
    accessor: "createdOn",
    Header: "Created On",
    isSortable: true,
    type: "datetime"
  },
  {
    id: "Last Login",
    accessor: "lastLoginTs",
    Header: "Last Login",
    isSortable: true,
    type: "datetime"
  }
];
