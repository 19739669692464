import React, { useMemo } from "react";

import { DATA_TYPES_ICONS } from "src/icons";
import { ALL_COLUMNS } from "../../constants";

import styles from "./ViewDataSchemaTable.module.scss";
import { Table } from "src/components";

const BooleanCell = ({ cell }: $TSFixMe) => {
  return typeof cell.value === "boolean" ? (
    <span>{cell.value.toString().toUpperCase()}</span>
  ) : (
    <span>{cell.value}</span>
  );
};

const PossibleNullCell = ({ cell }: $TSFixMe) => {
  return cell.value === null ? <span>NULL</span> : <span>{cell.value}</span>;
};

const ViewDataSchemaTable = ({
  data,
  isLoadingData,
  maxHeight = "calc(100vh - 179px)"
}: $TSFixMe) => {
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        id: "Name",
        accessor: "name",
        Cell: ({ cell, row }: $TSFixMe) => {
          const { fieldSchema = {} } = row.original;
          return (
            <>
              <span className={styles.dataTypeIcon}>
                {
                  /* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */
                  DATA_TYPES_ICONS[
                    fieldSchema?.fieldProperties?.idLikeColumn ? "ID" : fieldSchema?.rcDataType
                  ]
                }
              </span>
              <span>{cell.value}</span>
            </>
          );
        }
      },
      {
        Header: "Type",
        id: "Type",
        accessor: "fieldSchema.rcDataType",
        Cell: PossibleNullCell
      },
      {
        Header: "Meaning",
        id: "Meaning",
        accessor: "featureMeta.ontology",
        Cell: PossibleNullCell
      },
      {
        Header: "Collection",
        id: "Collection",
        accessor: "featureMeta.collectionType",
        Cell: PossibleNullCell
      },
      {
        Header: "Concept",
        id: "Concept",
        accessor: "featureMeta.concept",
        Cell: PossibleNullCell
      },
      {
        Header: "Sparse",
        id: "Sparse",
        accessor: "featureMeta.sparseType",
        Cell: PossibleNullCell
      },
      {
        Header: "RefEntity",
        id: "RefEntity",
        accessor: "refEntity",
        Cell: PossibleNullCell
      },
      {
        Header: "Lineage",
        id: "Lineage",
        accessor: "featureMeta.lineageType",
        Cell: PossibleNullCell
      },
      {
        Header: "Source",
        id: "Source",
        accessor: "featureMeta.sourceLineage",
        Cell: PossibleNullCell
      },
      {
        Header: "ID?",
        id: "ID?",
        accessor: "featureMeta.idFlag",
        Cell: BooleanCell
      },
      {
        Header: "Secure?",
        id: "Secure?",
        accessor: "featureMeta.secureFlag",
        Cell: BooleanCell
      },
      {
        Header: "Target?",
        id: "Target?",
        accessor: "featureMeta.targetFlag",
        Cell: BooleanCell
      },
      {
        Header: "Timestamp?",
        id: "Timestamp?",
        accessor: "featureMeta.tsFlag",
        Cell: BooleanCell
      },
      {
        Header: "Format",
        id: "Format",
        accessor: "featureMeta.tsFormat",
        Cell: PossibleNullCell
      }
    ]?.filter((column: $TSFixMe) => [...ALL_COLUMNS?.entityFeatures].includes(column?.Header));
  }, []);

  return (
    <Table
      size="small"
      columns={columns}
      data={data ?? []}
      unsorted
      isLoading={isLoadingData}
      emptyTableMessage="No records"
      maxHeight={maxHeight}
    />
  );
};

export default ViewDataSchemaTable;
