/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Handle, Position } from "react-flow-renderer";

import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import { useDrawerStore } from "../../../store/store";
import { sideComponentSetter } from "../../../store/store.selectors";
import { TransformGroupModalPlain } from "../CanvasDrawerItems";

import styles from "./TransformBlock.module.scss";

const TransformBlock = ({ data }: $TSFixMe) => {
  const setSideComponent = useDrawerStore(sideComponentSetter);

  const handleClick = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
    setSideComponent({
      sideComponent: TransformGroupModalPlain,
      sideComponentProps: { title: data.label, data: data }
    });
  };

  return (
    <>
      <Handle type="target" position={Position.Left} />
      <div className={styles.transformBlockContainer} onClick={handleClick}>
        <SubscriptionsIcon />
      </div>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default TransformBlock;
