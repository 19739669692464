import React from "react";
import shallow from "zustand/shallow";

import { useQueryClient } from "@tanstack/react-query";

import { postAPI } from "./apiService";

import { useProjectsStore } from "../store/store";
import { shouldRefreshProjectsSetter } from "../store/store.selectors";
import { logNotebooksOut } from "src/utils/operateNotebookService";

import useAuthStore from "../stores/auth.store";

import { onLogout } from "./helpers";

const useLogout = () => {
  const queryClient = useQueryClient();

  const [isLogoutInProgress, setIsLogoutInProgress] = React.useState(false);
  const [isUserLoggedIn, logout, logoutTimer] = useAuthStore(
    (state: $TSFixMe) => [state.isUserLoggedIn, state.logout, state.logoutTimer],
    shallow
  );

  const setShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsSetter);

  const thisLogout = async () => {
    try {
      setIsLogoutInProgress(true);
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      await postAPI("/logout");
      logout(logoutTimer);
      setShouldProjectsRefresh(false);

      // Clearing or deleting all react-query queries across the platform.
      queryClient.clear();
      onLogout();

      logNotebooksOut();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLogoutInProgress(false);
    }
  };

  window.addEventListener("storage", (event) => {
    if (event.key === "token") {
      const token = event.newValue;
      try {
        if (
          !isLogoutInProgress &&
          isUserLoggedIn &&
          (!token || token === "undefined" || token === "null")
        ) {
          thisLogout();
        }
      } catch (e) {
        console.error(e);
      }
    }
  });

  return { logout: thisLogout };
};

export default useLogout;
