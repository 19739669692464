import React from "react";

export const DataAppIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6" clipPath="url(#clip0_14855_123591)">
        <path
          d="M15.5312 0.953125H0.46875C0.209875 0.953125 0 1.163 0 1.42188V11.7969C0 12.0557 0.209875 12.2656 0.46875 12.2656H7.53125V13.2441L2.34775 14.1158C2.09244 14.1588 1.92028 14.4006 1.96322 14.6558C2.00172 14.8848 2.20019 15.0469 2.42491 15.0469C2.49944 15.0469 2.1465 15.098 8.00034 14.1159L13.5495 15.0405C13.8048 15.0831 14.0463 14.9105 14.0889 14.6551C14.1314 14.3997 13.9589 14.1582 13.7036 14.1157L8.46875 13.2435V12.2656H15.5312C15.7901 12.2656 16 12.0557 16 11.7968V1.42184C16 1.16297 15.7901 0.953125 15.5312 0.953125ZM15.0625 11.3281H0.9375V1.89062H15.0625V11.3281Z"
          fill="#003656"
        />
        <path
          d="M3.375 6.14063H5.25C5.50887 6.14063 5.71875 5.93075 5.71875 5.67188V3.79688C5.71875 3.538 5.50887 3.32812 5.25 3.32812H3.375C3.11612 3.32812 2.90625 3.538 2.90625 3.79688V5.67188C2.90625 5.93075 3.11612 6.14063 3.375 6.14063ZM3.84375 4.26563H4.78125V5.20313H3.84375V4.26563Z"
          fill="#003656"
        />
        <path
          d="M3.375 9.89062H5.25C5.50887 9.89062 5.71875 9.68075 5.71875 9.42188V7.54688C5.71875 7.288 5.50887 7.07812 5.25 7.07812H3.375C3.11612 7.07812 2.90625 7.288 2.90625 7.54688V9.42188C2.90625 9.68075 3.11612 9.89062 3.375 9.89062ZM3.84375 8.01562H4.78125V8.95312H3.84375V8.01562Z"
          fill="#003656"
        />
        <path
          d="M7.0625 6.14063H8.9375C9.19637 6.14063 9.40625 5.93075 9.40625 5.67188V3.79688C9.40625 3.538 9.19637 3.32812 8.9375 3.32812H7.0625C6.80362 3.32812 6.59375 3.538 6.59375 3.79688V5.67188C6.59375 5.93075 6.80362 6.14063 7.0625 6.14063ZM7.53125 4.26563H8.46875V5.20313H7.53125V4.26563Z"
          fill="#003656"
        />
        <path
          d="M7.0625 9.89062H8.9375C9.19637 9.89062 9.40625 9.68075 9.40625 9.42188V7.54688C9.40625 7.288 9.19637 7.07812 8.9375 7.07812H7.0625C6.80362 7.07812 6.59375 7.288 6.59375 7.54688V9.42188C6.59375 9.68075 6.80362 9.89062 7.0625 9.89062ZM7.53125 8.01562H8.46875V8.95312H7.53125V8.01562Z"
          fill="#003656"
        />
        <path
          d="M10.75 6.14063H12.625C12.8839 6.14063 13.0938 5.93075 13.0938 5.67188V3.79688C13.0938 3.538 12.8839 3.32812 12.625 3.32812H10.75C10.4911 3.32812 10.2812 3.538 10.2812 3.79688V5.67188C10.2812 5.93075 10.4911 6.14063 10.75 6.14063ZM11.2188 4.26563H12.1562V5.20313H11.2188V4.26563Z"
          fill="#003656"
        />
        <path
          d="M10.75 9.89062H12.625C12.8839 9.89062 13.0938 9.68075 13.0938 9.42188V7.54688C13.0938 7.288 12.8839 7.07812 12.625 7.07812H10.75C10.4911 7.07812 10.2812 7.288 10.2812 7.54688V9.42188C10.2812 9.68075 10.4911 9.89062 10.75 9.89062ZM11.2188 8.01562H12.1562V8.95312H11.2188V8.01562Z"
          fill="#003656"
        />
      </g>
      <defs>
        <clipPath id="clip0_14855_123591">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
