import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import { UseGetProjectQueryKeys } from "src/hooks/api/projects/useGetProject";
import { postAPIWithRethrow, handleResponse, getAPI } from "src/utils/apiService";
import { useProjectsStore } from "src/store/store";

interface IVariables {
  projectId?: string;
  projectName?: string;
  type: "PROJECT" | "OBJECTS" | "ENTITY" | "RECIPE" | "MODEL" | "ARTIFACT";
  outputElementId?: string;
  isUpdated: boolean;
  invalidateAboutPage?: boolean;
  isForSnippets?: boolean;
  isAsync?: boolean;
}

const CONTENT_GENERATION_POLLING_INTERVAL = 30;
const POLLING_TIMEOUT = 15 * 60;

const useGenerateAboutContent = (): UseMutationResult<null, unknown, IVariables, unknown> => {
  const [
    generatingSnippetsProjects,
    generatingAboutProjects,
    setGeneratingSnippetsProjects,
    setGeneratingAboutProjects
  ] = useProjectsStore((state) => [
    state.generatingSnippetsProjects,
    state.generatingAboutProjects,
    state.setGeneratingSnippetsProjects,
    state.setGeneratingAboutProjects
  ]);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ projectId, type, outputElementId, isAsync }) => {
      const eventId = await postAPIWithRethrow(
        `/v2/markdown/${projectId}/generate/${type}${
          outputElementId ? `?outputElementId=${outputElementId}` : ""
        }${isAsync ? `?async=${isAsync}` : ""}`,
        {}
      );

      if (!isAsync || !eventId) {
        return eventId;
      }
      let count = 1;
      return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
          const eventData = await getAPI(`/v2/env-alerts?id=${eventId}`);
          const status = _.get(eventData, [0, "status"]);
          count = count + 1;
          if (status === "HANDLED") {
            clearInterval(interval);
            resolve(eventData);
          }
          if (status === "ERROR" || count * CONTENT_GENERATION_POLLING_INTERVAL > POLLING_TIMEOUT) {
            clearInterval(interval);
            reject(eventId);
          }
        }, CONTENT_GENERATION_POLLING_INTERVAL * 1000);
      });
    },
    onSuccess: (
      data,
      { projectId, isUpdated, projectName, invalidateAboutPage, isForSnippets }
    ) => {
      if (invalidateAboutPage) {
        if (_.includes(generatingAboutProjects, projectId)) {
          setGeneratingAboutProjects(
            !!projectId ? _.without(generatingAboutProjects, projectId) : []
          );
        }
        queryClient.invalidateQueries([UseGetProjectQueryKeys.Project]);
        handleResponse({
          successMessage: `About Page content for the project ${projectName} has been ${
            isUpdated ? "updated" : "generated"
          } successfully `
        });
      }

      if (isForSnippets && _.includes(generatingSnippetsProjects, projectId)) {
        setGeneratingSnippetsProjects(
          !!projectId ? _.without(generatingSnippetsProjects, projectId) : []
        );
        const nonGenerated = _.get(data, ["0", "extraInfo", "non-generated"]);
        let successMessage = `Snippets for the project ${projectName} has been generated successfully`;
        if (nonGenerated) {
          successMessage = `Snippets for the project ${projectName} has been generated successfully except for ${nonGenerated}. Please try again`;
        }
        handleResponse({
          successMessage
        });
      }
    },
    onError: (__, { invalidateAboutPage, projectId, isForSnippets }) => {
      if (invalidateAboutPage) {
        if (_.includes(generatingAboutProjects, projectId)) {
          setGeneratingAboutProjects(
            !!projectId ? _.without(generatingAboutProjects, projectId) : []
          );
        }
        queryClient.invalidateQueries([UseGetProjectQueryKeys.Project]);
      }

      if (isForSnippets && _.includes(generatingSnippetsProjects, projectId)) {
        setGeneratingSnippetsProjects(
          !!projectId ? _.without(generatingSnippetsProjects, projectId) : []
        );
      }
    }
  });
};

export default useGenerateAboutContent;
