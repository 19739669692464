import React, { useMemo, useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import Drawer from "components/Drawer/CustomDrawer";
import useGetRecipeRunLogs from "../../RecipeRunLogs/useGetRecipeRunLogs";
import { LogsContent } from "./LogsContent";
import { RecipeStatuses } from "src/constants";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { getStatusEquivalence, ShowLogsDrawerTitle } from "./ShowLogsDrawerTitle";
import { isEmpty } from "lodash";

export const TEMP_MSG = ["Fetching logs. Please wait..."];
export const UNBUILT_MSG = "Logs are not available because the recipe hasn't been executed yet.";

export const useStyles = makeStyles({
  autoMlLogs: {
    flexWrap: "nowrap",
    width: "600px"
  },
  logStatuses: {
    gap: "16px",
    background: "#1e1e1e",
    padding: "8px 8px 0px 8px"
  },
  logStatusWithoutBckg: {
    gap: "16px",
    padding: "8px 8px 0px 8px"
  },
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600
  }
});

type Props = {
  type: any;
  message: string;
};

export const renderInfo = ({ message, type }: Props) => {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: 16,
        display: "inline-block"
      }}>
      <ToastifyAlert type={type} message={message} />
    </Grid>
  );
};

interface IProps {
  projectId?: string;
  scenarioId?: string;
  name: string;
  open: boolean;
  groupId?: string;
  isJobPath: boolean;
  jobRunId?: string;
  onClose: () => void;
}

const RecipeLogsDrawer: React.FC<IProps> = (props) => {
  const { projectId, scenarioId, groupId, name, isJobPath, jobRunId, open, onClose } = props;
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  const { recipeRunInfo, isFetching, recipeLogs, recipeStatus } = useGetRecipeRunLogs({
    projectId,
    scenarioId,
    groupId,
    showAllLogs: true,
    ...(isJobPath ? { jobRunId } : {})
  });

  const url = `/recipe-run-logs/${projectId}/${scenarioId}/${groupId}`;

  useEffect(() => {
    if (projectId) {
      checkEnvRelaunch(projectId);
    }
  }, [projectId]);

  const handleSearchValueChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const filteredLogs = useMemo(() => {
    return (recipeLogs || [])?.filter((eachField: string) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, recipeLogs]);

  const title = (
    <ShowLogsDrawerTitle
      title={name}
      url={!isJobPath && url}
      onClose={onClose}
      {...(recipeStatus && {
        status: { color: getStatusEquivalence(recipeStatus), text: recipeStatus }
      })}
      searchValue={searchValue}
      onSearch={handleSearchValueChange}
    />
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      titleStyle={{ padding: 0, borderBottom: "1px solid #E0E0E0", lineHeight: 1 }}
      hideCloseButton
      classes={{
        paper: classes.drawerPaper
      }}
      className={classes.drawer}
      title={title}
      onClose={onClose}>
      <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
        {!isFetching ? (
          <LogsContent logs={TEMP_MSG} />
        ) : !isEmpty(recipeLogs) ? (
          <LogsContent
            logs={filteredLogs}
            {...(RecipeStatuses.UnBuilt === recipeStatus ? { info: UNBUILT_MSG } : {})}
          />
        ) : (
          renderInfo(recipeRunInfo)
        )}
      </Grid>
    </Drawer>
  );
};

export default RecipeLogsDrawer;
