import React, { useEffect, useRef } from "react";

// Packages
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { find, includes, map, size } from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Autocomplete as MuiAutocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

// Icons
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import {
  defaultGroups,
  defaultRules,
  operatorsByDatatype,
  SegmentFormFields,
  SegmentHelperText
} from "../../utils/Segment.constants";

// Open API
import { FeatureDto } from "openapi/Models";

// Contexts
import { useSegmentContext } from "../../context/useSegmentContext";

// Types
import { RuleArrayProps, RuleProps } from "../../Segment.type";

// Styles
import useStyles from "./SegmentConditions.styles";
import FieldController from "./FieldController";

const SegmentConditions = () => {
  const { isReadOnly } = useSegmentContext() || {};

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: SegmentFormFields.Groups
  });

  const classes = useStyles();

  const handleAddGroup = () => {
    append(defaultGroups[0]);
  };

  return (
    <Paper className={classes.root}>
      <Box p={2}>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          data-testid="segmentConditionAddConditions">
          {SegmentHelperText.AddConditions}
        </Typography>
      </Box>
      <Divider />
      <Box p={2} className={classes.wrapper}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ opacity: 0.75 }}
          data-testid="segmentConditionAddConditionsInfo">
          {SegmentHelperText.AddConditionsInfo}
        </Typography>

        {map(fields, (group, groupIndex) => (
          <Box
            key={group.id}
            style={{ marginTop: 20, marginLeft: groupIndex === 0 ? 0 : 50 * groupIndex }}>
            <Box style={{ position: "relative" }}>
              {groupIndex > 0 && <div className={classes.groupIndentation} />}

              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Controller
                    control={control}
                    name={`groups.${groupIndex}.groupType`}
                    render={({ field }) => (
                      <ToggleButtonGroup
                        {...field}
                        size="small"
                        exclusive
                        style={{ width: 100 }}
                        onChange={(_, option) => field.onChange(option)}
                        data-testid={`segmentConditionGroup${groupIndex}GroupType`}>
                        <ToggleButton
                          size="small"
                          disableRipple
                          disabled={!!isReadOnly}
                          value="AND"
                          style={{ flex: 1 }}
                          data-testid={`segmentConditionGroup${groupIndex}GroupTypeAnd`}>
                          {SegmentHelperText.And}
                        </ToggleButton>
                        <ToggleButton
                          size="small"
                          disableRipple
                          disabled={!!isReadOnly}
                          value="OR"
                          style={{ flex: 1 }}
                          data-testid={`segmentConditionGroup${groupIndex}GroupTypeOr`}>
                          {SegmentHelperText.Or}
                        </ToggleButton>
                      </ToggleButtonGroup>
                    )}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={!!isReadOnly || groupIndex < size(fields) - 1}
                    onClick={handleAddGroup}
                    data-testid={`segmentConditionGroup${groupIndex}AddGroupAction`}>
                    {SegmentHelperText.AddGroup}
                  </Button>
                </Grid>
                <Grid item>
                  {size(fields) > 1 && (
                    <IconButton
                      size="small"
                      disabled={!!isReadOnly}
                      onClick={() => remove(groupIndex)}
                      data-testid={`segmentConditionGroup${groupIndex}RemoveGroupAction`}>
                      <TrashIcon viewBox="0 0 20 20" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Box>
            <RuleArray groupIndex={groupIndex} />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

const RuleArray = (props: RuleArrayProps) => {
  const { groupIndex } = props || {};

  const { isReadOnly } = useSegmentContext() || {};

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `groups.${groupIndex}.groupElements`
  });

  return (
    <>
      {map(fields, (rule, ruleIndex) => (
        <Rule
          key={`rule-${ruleIndex}`}
          groupIndex={groupIndex}
          rule={rule}
          ruleIndex={ruleIndex}
          remove={remove}
          hideRemoveAction={size(fields) <= 1}
        />
      ))}

      <Box mt={2} ml="50px">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          disabled={!!isReadOnly}
          onClick={() => append(defaultRules[0])}
          data-testid={`segmentConditionGroup${groupIndex}AddRuleAction`}>
          {SegmentHelperText.AddRule}
        </Button>
      </Box>
    </>
  );
};

const Rule = (props: RuleProps) => {
  const { groupIndex, rule, ruleIndex, remove, hideRemoveAction } = props || {};

  const classes = useStyles();

  const inputLabelProps = {
    classes: { root: classes.inputLabel, shrink: classes.inputLabelShrink }
  };

  const { isReadOnly } = useSegmentContext() || {};

  const {
    control,
    setValue,
    watch,
    formState: { dirtyFields }
  } = useFormContext();

  const { datasetFeaturesData, datasetFeaturesDataTypeMapping, isDatasetFeaturesLoading } =
    useSegmentContext();

  const isFirstRender = useRef(true);

  const watchFieldValue = watch(`groups.${groupIndex}.groupElements.${ruleIndex}.field.name`);
  const watchOperatorValue = watch(`groups.${groupIndex}.groupElements.${ruleIndex}.operator`);

  useEffect(() => {
    if (
      !!isFirstRender.current &&
      !!dirtyFields?.groups?.[groupIndex]?.groupElements?.[ruleIndex]?.field?.name
    ) {
      const operatorValue =
        // @ts-ignore
        operatorsByDatatype?.[datasetFeaturesDataTypeMapping?.[watchFieldValue]]?.[0];
      setValue(
        `groups.${groupIndex}.groupElements.${ruleIndex}.operator`,
        operatorValue?.replace(/\s/g, "")
      );

      isFirstRender.current = false;
    }
  }, [watchFieldValue]);

  useEffect(() => {
    if (includes(["IsNull", "IsNotNull"], watchOperatorValue)) {
      setValue(`groups.${groupIndex}.groupElements.${ruleIndex}.value.value`, "");
    }
  }, [watchOperatorValue]);

  return (
    <Grid key={rule.id} container alignItems="center" className={classes.ruleContainer}>
      <div
        className={classes.ruleIndentation}
        style={{ top: ruleIndex === 0 ? -20 : -40, height: ruleIndex === 0 ? "100%" : "150%" }}
      />
      <FieldController
        control={control}
        disabled={!!isReadOnly}
        loading={isDatasetFeaturesLoading}
        name={`groups.${groupIndex}.groupElements.${ruleIndex}.field.name`}
        options={
          map(datasetFeaturesData, (datasetFeature: FeatureDto) => ({
            value: datasetFeature?.name!,
            label: datasetFeature?.name!
          })) ?? []
        }
        testId={`segmentConditionGroup${groupIndex}Rule${ruleIndex}Field`}
        groupIndex={groupIndex}
        ruleIndex={ruleIndex}
      />
      <Controller
        control={control}
        name={`groups.${groupIndex}.groupElements.${ruleIndex}.operator`}
        rules={{ required: true }}
        render={({ field, fieldState }) => {
          const { error } = fieldState;

          return (
            <MuiAutocomplete
              {...field}
              size="small"
              className={classes.textField}
              classes={{ listbox: classes.listBox }}
              disableClearable
              getOptionLabel={(option: any) =>
                find(
                  operatorsByDatatype[datasetFeaturesDataTypeMapping?.[watchFieldValue] || "ANY"],
                  (thisEntry: string) => thisEntry?.replace(/\s/g, "") === option?.value
                ) || ""
              }
              value={{ value: field?.value?.replace(/\s/g, ""), label: field?.value }}
              disabled={!!isReadOnly}
              onChange={(_, option) => field.onChange(option?.value?.replace(/\s/g, ""))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${SegmentHelperText.Operator} *`}
                  InputLabelProps={inputLabelProps}
                  variant="outlined"
                  error={!!error}
                  data-testid={`segmentConditionGroup${groupIndex}Rule${ruleIndex}InputOperator`}
                />
              )}
              options={
                map(
                  operatorsByDatatype[datasetFeaturesDataTypeMapping?.[watchFieldValue] || "ANY"],
                  (option: string) => ({
                    value: option.replace(/\s/g, ""),
                    label: option
                  })
                ) ?? []
              }
              data-testid={`segmentConditionGroup${groupIndex}Rule${ruleIndex}Operator`}
            />
          );
        }}
      />
      {!includes(["IsNull", "IsNotNull"], watchOperatorValue) && (
        <Controller
          control={control}
          name={`groups.${groupIndex}.groupElements.${ruleIndex}.value.value`}
          rules={{ required: true }}
          render={({ field, fieldState }) => {
            const { error } = fieldState;

            return (
              <TextField
                {...field}
                id={`value-${groupIndex}-${ruleIndex}`}
                type="text"
                size="small"
                label={`${SegmentHelperText.Value} *`}
                InputLabelProps={inputLabelProps}
                variant="outlined"
                className={classes.textField}
                fullWidth
                disabled={!!isReadOnly}
                error={!!error}
                data-testid={`segmentConditionGroup${groupIndex}Rule${ruleIndex}Value`}
              />
            );
          }}
        />
      )}

      {!hideRemoveAction && (
        <IconButton
          size="small"
          disabled={!!isReadOnly}
          onClick={() => remove(ruleIndex)}
          data-testid={`segmentConditionGroup${groupIndex}Rule${ruleIndex}RemoveRuleAction`}>
          <TrashIcon viewBox="0 0 20 20" />
        </IconButton>
      )}
    </Grid>
  );
};

export default SegmentConditions;
