import React from "react";

import { Accordion, AccordionSummary, Typography, makeStyles } from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { DatasetHelperText } from "../../utils/Dataset.constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const EditQueryContainer = (props: $TSFixMe) => {
  const { onEditQuery, isOntologyProcessing } = props || {};

  const classes: $TSFixMe = useStyles();

  return (
    <>
      <Accordion className={classes.root}>
        {/* @ts-ignore */}
        <AccordionSummary
          id="sqlConfigurationEditQuery"
          expandIcon={<ExpandMoreIcon />}
          disabled={isOntologyProcessing}
          onClick={() => onEditQuery()}>
          <Typography className={classes.heading}>{DatasetHelperText.EditQuery}</Typography>
        </AccordionSummary>
      </Accordion>
    </>
  );
};

export default EditQueryContainer;
