import clsx from "clsx";
import keyBy from "lodash/keyBy";
import React from "react";
import {
  Grid,
  InputAdornment,
  Typography,
  TextField,
  makeStyles,
  MenuItem,
  ListItem
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles({
  columnTransforms: {
    flexWrap: "nowrap",
    maxHeight: "270px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  titleWrap: {
    background: "rgba(197,197,197,0.2)"
  },
  mainTitle: {
    fontSize: "14px",
    padding: "12px",
    fontWeight: 500
  },
  transformsTitle: {
    fontWeight: 400,
    minHeight: "45px"
  },
  inputRoot: {
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(0,0,0,0.1)",
      borderWidth: "1px 0px"
    }
  },
  notchedOutline: {
    borderRadius: "0px",
    borderColor: "rgba(0,0,0,0.1)",
    borderWidth: "1px 0px"
  }
});

const TRANSFORMS_LIST = [
  {
    name: "Divide column"
  },
  {
    name: "Rename Column"
  },
  {
    name: "Split Column"
  },
  {
    name: "Square Column"
  },
  {
    name: "Cube Root Column"
  },
  {
    name: "Multiply Column"
  },
  {
    name: "New Constant Column"
  },
  {
    name: "Pop Column"
  },
  {
    name: "Encode Column"
  },
  {
    name: "Subtract Value Column"
  },
  {
    name: "Add Value Column"
  },
  {
    name: "Cube Column"
  },
  {
    name: "Cut Column"
  },
  {
    name: "Power N Column"
  },
  {
    name: "Square Root Column"
  },
  {
    name: "Group by column max"
  },
  {
    name: "Group by column min"
  },
  {
    name: "Group by column mean"
  }
];

export const AddColumnTransforms = ({ onAddTransform, onClose, dfsTemplates }: $TSFixMe) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const dfsTemplateByName = keyBy(dfsTemplates, "name");
  const filteredTransforms = TRANSFORMS_LIST.filter(
    (currTransform) =>
      currTransform.name.toLowerCase().includes(searchTerm) &&
      !!dfsTemplateByName[currTransform.name]
  );
  return (
    <Grid container direction="column">
      <Grid item className={classes.titleWrap}>
        <Typography className={classes.mainTitle}>Transforms</Typography>
      </Grid>
      <TextField
        size="small"
        hiddenLabel
        value={searchTerm}
        onChange={(event: $TSFixMe) => setSearchTerm(event.target.value.toLowerCase())}
        placeholder="Search..."
        variant="outlined"
        className={classes.inputRoot}
        InputProps={{
          startAdornment: (
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            <InputAdornment>
              <Search />
            </InputAdornment>
          ),
          classes: {
            root: classes.inputRoot,
            notchedOutline: classes.notchedOutline
          }
        }}
      />
      <Grid container direction="column" className={classes.columnTransforms}>
        {filteredTransforms?.length === 0 ? (
          <ListItem className={clsx([classes.mainTitle, classes.transformsTitle])}>
            No Transforms found!
          </ListItem>
        ) : (
          filteredTransforms.map((transform) => {
            return (
              <MenuItem
                className={clsx([classes.mainTitle, classes.transformsTitle])}
                key={transform.name}
                onClick={() => {
                  onAddTransform(dfsTemplateByName?.[transform.name].id);
                  onClose();
                }}>
                {transform.name}
              </MenuItem>
            );
          })
        )}
      </Grid>
    </Grid>
  );
};
