import React, { useMemo, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import Drawer from "components/Drawer/CustomDrawer";
import useGetJobRunLogs from "../../../Jobs/components/JobRunLogs/useGetJobRunLogs";
import useRecipeLogs from "hooks/api/transforms/useRecipeLogs";
import { LogsContent } from "pages/Projects/common/ShowLogsModal/LogsContent";
import {
  ShowLogsDrawerTitle,
  getStatusEquivalence
} from "pages/Projects/common/ShowLogsModal/ShowLogsDrawerTitle";
import { TEMP_MSG, useStyles } from "pages/Projects/common/ShowLogsModal/RecipeLogsDrawer";
import { ToastifyAlert } from "components/ToastifyAlert/ToastifyAlert";
import { JobRunStatuses, jobRunStatusesNames } from "../../../Jobs/utils/Jobs.constants";

interface IProps {
  open: boolean;
  projectId: string;
  logRunDetails: {
    jobName: string;
    runId: string;
    runEntryId: string;
    recipeId?: string;
    recipeName?: string;
    errorMsg?: string;
  };
  onClose: () => void;
}

const useDrawerStyles = makeStyles({
  drawer: {
    width: 600,
    flexShrink: 0
  },
  drawerPaper: {
    width: 600
  }
});

const PredictionJobLogsDrawer: React.FC<IProps> = (props) => {
  const { open, projectId, logRunDetails, onClose } = props;

  const [searchValue, setSearchValue] = useState("");
  const { drawer, drawerPaper } = useDrawerStyles();
  const classes = useStyles();

  const { jobRunInfo, isFetching, jobRunLogs, jobRunStatus } = useGetJobRunLogs({
    jobRunId: logRunDetails.runEntryId
  });

  const { data, isLoading } = useRecipeLogs(logRunDetails.runEntryId, logRunDetails.recipeId, 1000);

  const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredLogs = useMemo(() => {
    return (
      [
        ...jobRunLogs,
        ...(data
          ? [
              "-\n",
              `##########     Recipe Logs${
                logRunDetails.recipeName ? `: (${logRunDetails.recipeName})` : ""
              }    ##########\n`,
              ...data
            ]
          : logRunDetails.errorMsg
            ? [logRunDetails.errorMsg]
            : [])
      ] || []
    )?.filter((eachField: string) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, jobRunLogs, data, logRunDetails.errorMsg, logRunDetails.recipeName]);

  const url = `/projects/${projectId}/prediction-job/${logRunDetails.runId}/prediction-runs/${logRunDetails.runEntryId}/logs`;

  const title = (
    <ShowLogsDrawerTitle
      title={logRunDetails.jobName ?? ""}
      url={url}
      searchValue={searchValue}
      onSearch={handleSearchValueChange}
      {...(jobRunStatus
        ? {
            status: {
              color: getStatusEquivalence(jobRunStatus as JobRunStatuses),
              text: jobRunStatusesNames[jobRunStatus]
            }
          }
        : {})}
      onClose={onClose}
    />
  );

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      titleStyle={{ padding: 0, borderBottom: "1px solid #E0E0E0", lineHeight: 1 }}
      hideCloseButton
      classes={{
        paper: drawerPaper
      }}
      className={drawer}
      title={title}
      onClose={onClose}>
      <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
        {!isFetching || (!!logRunDetails?.recipeId && isLoading) ? (
          <LogsContent logs={TEMP_MSG} />
        ) : (filteredLogs && filteredLogs?.length !== 0) || !jobRunInfo.message ? (
          <LogsContent logs={filteredLogs} />
        ) : (
          <Grid
            container
            item
            xs={12}
            style={{
              padding: 16
            }}>
            <ToastifyAlert type={jobRunInfo.type as any} message={jobRunInfo.message as any} />
          </Grid>
        )}
      </Grid>
    </Drawer>
  );
};

export default PredictionJobLogsDrawer;
