import React from "react";
import { FormControlLabel, Checkbox, makeStyles, Tooltip } from "@material-ui/core";
import { DraggableProvided } from "react-beautiful-dnd";
import { ColumnDefResolved } from "@tanstack/react-table";

import OverflowTooltip from "src/components/OverflowTooltip";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

// Types
import { TData } from "../../Table.types";

interface IProps {
  item: ColumnDefResolved<TData, any>;
  isColumnLimitReached: boolean;
  provided: DraggableProvided;
  style?: React.CSSProperties;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const useStyles = makeStyles({
  listItem: {
    padding: "0 18px",
    borderTop: "1px solid #e1e1e1",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    "& label[class*='MuiFormControlLabel-root']": {
      marginBottom: "0",
      width: "100%",
      overflow: "hidden",
      "& > span": {
        overflow: "hidden",
        whiteSpace: "nowrap"
      }
    }
  },
  columnListItemText: {
    fontSize: "14px"
  }
});

const DragItem: React.FC<IProps> = ({
  style,
  provided,
  item: column,
  isColumnLimitReached,
  onChange
}) => {
  const classes = useStyles();
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...style,
        ...provided.draggableProps.style
      }}
      className={classes.listItem}>
      <FormControlLabel
        value={column.header}
        control={
          <Tooltip
            title={
              !(column as any)?.isSelected && isColumnLimitReached
                ? "Selecting more columns than the maximum supported limit(300) will automatically deselect the previously chosen columns."
                : ""
            }>
            <Checkbox
              size="small"
              color="primary"
              className="columnCheckbox"
              disableRipple
              value={column?.accessorKey}
              // @ts-ignore
              checked={!!column?.isSelected}
            />
          </Tooltip>
        }
        label={
          <ListItemText
            primary={
              <OverflowTooltip
                style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                value={column?.header}
              />
            }
          />
        }
        labelPlacement="end"
        onChange={onChange as any}
      />
      <ListItemSecondaryAction {...provided?.dragHandleProps}>
        <DragIndicatorIcon fontSize="small" color="disabled" />
      </ListItemSecondaryAction>
    </div>
  );
};

export default DragItem;
