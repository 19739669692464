import api from "utils/AxiosClient";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface IVariable {
  threadId: string;
  messageId: string;
}

const useExplainCode = (): UseMutationResult<any, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ threadId, messageId }) => {
      return await api.fetchResponse(() => api.AiControllerApi.explainCode(threadId, messageId));
    }
  });
};

export default useExplainCode;
