import React from "react";

export const RapidModelIcon = ({ width = 20, height = 20, fill = "none" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M13 9H19L8 22L11 12H5L10 2H17L13 9Z" fill="currentColor" />
    </svg>
  );
};
