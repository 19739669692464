import React from "react";

import { Grid, Typography, Tooltip, makeStyles } from "@material-ui/core";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { ellipses } from "src/utils/formatText";
import { formatBytes } from "src/utils/format-bytes";

import { fileMimeTypesValues, DatasetHelperText } from "../../../utils/Dataset.constants";

import { FileMetaDataPropsType } from "../../../types/Props.type";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    flexWrap: "nowrap",
    gap: 10,
    "& .iconContainer": {
      display: "flex",
      "& svg": {
        margin: "auto",
        width: 43,
        height: 45,
        color: "#4646b5"
      }
    },
    "& .fileAttributesWrapper": {
      height: "inherit",
      flexGrow: 1
    },
    "& .fileAttributesContainer": {
      height: "inherit"
    }
  },
  fileAttributesSmall: {
    fontSize: 12
  }
}));

const FileMetaData = (props: FileMetaDataPropsType) => {
  const { index, file, isDataSourcesFilesUpload } = props || {};

  const classes: $TSFixMe = useStyles();

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Grid item className="iconContainer">
          {<DescriptionOutlinedIcon />}
        </Grid>
        <Grid item className="fileAttributesWrapper">
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            className="fileAttributesContainer">
            {file?.name ? (
              <Typography variant="subtitle2">
                <Tooltip id={`fileName_${index}`} title={file?.name?.length > 25 ? file?.name : ""}>
                  <span>{ellipses({ text: file?.name, length: 25 })}</span>
                </Tooltip>
              </Typography>
            ) : (
              <Typography id={`fileNameUnknown_${index}`} variant="subtitle2">
                {DatasetHelperText.Unknown}
              </Typography>
            )}

            <Grid container className={classes.fileAttributesSmall}>
              {!isDataSourcesFilesUpload && (
                <Grid id={`fileSizeLabel_${index}`} item xs={6}>
                  {`${DatasetHelperText.FileSize}: `}
                  <span id={`fileSize_${index}`}>
                    {file?.size ? formatBytes(file.size) : "0 KB"}
                  </span>
                </Grid>
              )}
              <Grid xs={1}></Grid>
              {file?.type && (
                <Grid id={`fileTypeLabel_${index}`} item xs={5}>
                  {`${DatasetHelperText.FileType}: `}
                  {fileMimeTypesValues[file?.type]?.displayName || (
                      <Tooltip
                        id={`fileTypeTooltip_${index}`}
                        title={file?.type?.length > 7 ? file?.type : ""}>
                        <span id={`fileType_${index}`}>
                          {ellipses({ text: file?.type, length: 7 })}
                        </span>
                      </Tooltip>
                    ) ||
                    DatasetHelperText.Unknown}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FileMetaData;
