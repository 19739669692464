import React, { useEffect, useMemo, useState } from "react";

import ReactException from "src/components/Errors/ReactException";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import useGetDataApps from "../../../../hooks/api/dataapps/useGetDataApps";
import { Spinner } from "src/components";
import { DataAppsView } from "../DataAppsView";
import { filter, get, map, uniq } from "lodash";
import { listViewPages, PagesViewConfiguration } from "src/constants/common.constants";
import { DataAppType } from "../../DataApps.type";
import { DeleteDataAppModal } from "../DeleteDataAppModal";

const PlatformDataApps = () => {
  const pagesViewConfiguration = JSON.parse(localStorage.getItem(PagesViewConfiguration) || "{}");
  const isTilesView = get(pagesViewConfiguration, listViewPages.DATAAPPS, true);

  const [tilesView, setTilesView] = useState(isTilesView);
  const [dataAppToDelete, setDataAppToDelete] = useState<DataAppType | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const { data, isLoading, isError, refetch } = useGetDataApps();

  useEffect(() => {
    setSelectedProjects(uniq(map(data, (dataApp) => dataApp.projectId)));
  }, [data]);

  const searchedDataApps = useMemo(
    () =>
      filter(data, (dataApp: DataAppType) =>
        (dataApp.displayName || dataApp.name)?.toLowerCase?.().includes?.(searchValue.toLowerCase())
      ),
    [data, searchValue]
  );

  const filteredDataApps = React.useMemo(
    () =>
      selectedProjects.length !== 0
        ? searchedDataApps
            ?.sort((a: DataAppType, b: DataAppType) => b?.updatedOn - a?.updatedOn)
            ?.filter((dataApp: DataAppType) => selectedProjects?.includes(dataApp.projectId))
        : [],
    [searchedDataApps, selectedProjects]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleDelete = (dataApp: DataAppType) => {
    setDataAppToDelete(dataApp);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <ReactException onClick={refetch} />;
  }

  return (
    <>
      <SubTopNavBarWrapper />
      <DataAppsView
        allDataApps={data}
        searchValue={searchValue}
        dataApps={filteredDataApps}
        onDelete={handleDelete}
        setIsTilesView={setTilesView}
        isTilesView={tilesView}
        onSearch={handleSearch}
        isProjectDataApps={false}
        searchedDataApps={searchedDataApps}
        selectedProjects={selectedProjects}
        onClearSearch={() => setSearchValue("")}
        setSelectedProjects={setSelectedProjects}
      />
      {dataAppToDelete && (
        <DeleteDataAppModal
          dataAppId={dataAppToDelete?.id}
          dataAppName={dataAppToDelete?.displayName || dataAppToDelete?.name}
          open={!!dataAppToDelete?.id}
          onClose={() => setDataAppToDelete(null)}
        />
      )}
    </>
  );
};

export default PlatformDataApps;
