// const addParentNodeData = (nodes, groupNamesAndColors) => {
//   const groupNames = groupNamesAndColors.map(({ name }) => name);
//   const formatGroupNames = groupNames.reduce(
//     (formattedGroupNames, currentGroupName) => ({ ...formattedGroupNames, [currentGroupName]: [] }),
//     {}
//   );

//   nodes?.forEach((node) => {
//     const currentNodeGroupName = node.groupName?.split("-")[0];
//     if (currentNodeGroupName) {
//       formatGroupNames[currentNodeGroupName]?.push(node.id);
//     }
//   });

//   const parsedNodes = nodes.map((node) => {
//     const currentNodeGroupName = node.groupName?.split("-")[0];
//     if (!currentNodeGroupName) return node;

//     return {
//       ...node,
//       parentNode: currentNodeGroupName,
//       groupName: `${currentNodeGroupName}-child`,
//       extent: "parent",
//       position: { x: node.column * 180, y: node.row * 180 },
//       draggable: false
//     };
//   });

//   return parsedNodes;
// };

export const parseNodesForGrouping = ({ nodes, groupNamesAndColors }: $TSFixMe) => {
  groupNamesAndColors?.forEach(({ name, color }: $TSFixMe) => {
    const groupNameIndex = nodes.findIndex((node: $TSFixMe) => {
      return node?.groupName?.split("-")[0] === name;
    });
    let groupRows: $TSFixMe = [];
    let groupColumns: $TSFixMe = [];

    nodes?.forEach((node: $TSFixMe) => {
      if (node?.groupName === name) {
        groupRows.push(node.row);
        groupColumns.push(node.column);
      }
    });

    const presetGroupWidth = 110;
    const presetGroupHeight = 150;

    if (!nodes.some((node: $TSFixMe) => node.id === name)) {
      const groupColumn = groupColumns.sort()[0];
      const groupRow = groupRows.sort()[0];

      nodes.splice(groupNameIndex, 0, {
        id: name,
        type: "collapsedGroup",
        column: groupColumn,
        row: groupRow,
        draggable: false,
        position: {
          x: groupColumn * 180 - 34,
          y: groupRow * 180 - 54
        },
        style: {
          width:
            presetGroupWidth * [...new Set(groupColumns)].length +
            66 * ([...new Set(groupColumns)].length - 1),
          height:
            presetGroupHeight * [...new Set(groupRows)].length +
            25 * ([...new Set(groupRows)].length - 1)
        },
        originalStyles: null,
        groupColor: color,
        groupParent: true
      });
    }
  });
};

export const setNodesToHide = ({ nodes, hideGroupedNodes, groupName }: $TSFixMe) => {
  return nodes.map((node: $TSFixMe) => ({
    ...node,
    isHidden: node?.groupName === groupName ? hideGroupedNodes : Boolean(node.isHidden),

    hideGroupedNodes:
      node.groupParent && node.id === groupName ? hideGroupedNodes : Boolean(node.hideGroupedNodes),

    style:
      (hideGroupedNodes || Boolean(node.hideGroupedNodes)) && node.id === groupName
        ? {}
        : node.originalStyles || node.style,

    originalStyles: node.originalStyles || node.style,

    ...(node.id === groupName && {
      position: hideGroupedNodes
        ? { x: node.column * 180, y: node.row * 180 }
        : {
            x: node.column * 180 - 34,
            y: node.row * 180 - 54
          }
    })
  }));
};

export const setEdgesToHide = ({ edges, nodes, hideGroupedNodes, groupName }: $TSFixMe) => {
  const selectedGroupEdges = edges.find((item: $TSFixMe) => item.groupName === groupName);
  const visibleEdges = [
    ...selectedGroupEdges?.in?.map((item: $TSFixMe) => item.id),
    ...selectedGroupEdges?.out?.map((item: $TSFixMe) => item.id)
  ];
  return edges.map((edge: $TSFixMe) => {
    const groupNodeWithEdge = nodes.find(
      (node: $TSFixMe) =>
        node.groupName === groupName && (node.id === edge.target || node.id === edge.originalTarget)
    );
    return groupNodeWithEdge
      ? {
          ...edge,
          originalTarget: edge.originalTarget || edge.target,
          originalSource: edge.originalSource || edge.source,
          target:
            hideGroupedNodes && selectedGroupEdges?.in.find((item: $TSFixMe) => item.id === edge.id)
              ? groupName
              : hideGroupedNodes && !visibleEdges?.includes(edge.id)
              ? null
              : edge?.originalTarget || edge?.target,
          source:
            hideGroupedNodes &&
            selectedGroupEdges?.out.find((item: $TSFixMe) => item.id === edge.id)
              ? groupName
              : hideGroupedNodes && !visibleEdges?.includes(edge.id)
              ? null
              : edge?.originalSource || edge?.source
        }
      : edge;
  });
};

export const shouldHighlight = (blockName: string, highlightString: string) =>
  highlightString.length > 0 && new RegExp(`^${highlightString}$`, "i")?.test(blockName);
