import React from "react";
import useStoreSelectors from "../hooks/useStoreSelectors";
import ConnectorFilesSelection from "./ConnectorFilesSelection/ConnectorFilesSelection";
import LocalFilesSelection from "./LocalFilesSelection/LocalFilesSelection";
import useHelpers from "../hooks/useHelpers";
import UploadControl from "./UploadControl/UploadControl";

const UploadSection = () => {
  // Stores - STARTS >>
  const { datasetFilesStore } = useStoreSelectors();
  // << ENDS - Stores
  const { isDataSourcesFilesUpload } = useHelpers();
  return (
    <>
      {(datasetFilesStore || [])?.length === 0 ? (
        isDataSourcesFilesUpload ? (
          <ConnectorFilesSelection />
        ) : (
          <LocalFilesSelection />
        )
      ) : (
        <UploadControl />
      )}
    </>
  );
};

export default UploadSection;
