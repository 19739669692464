import React from "react";

const SquareLinesIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <rect x="11.5" y="10.5" width="3" height="3" rx="1" stroke={color} />
    <path d="M11 12H10C8.89543 12 8 11.1046 8 10V4" stroke={color} />
    <rect x="1.5" y="2.5" width="3" height="3" rx="1" stroke={color} />
    <rect x="11.5" y="2.5" width="3" height="3" rx="1" stroke={color} />
    <path d="M5 4H11" stroke={color} />
  </svg>
);

export default SquareLinesIcon;
