import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { IProjectReturn } from "./useRunDetails";

export interface IProjectRunData {
  name: string;
  projectId: string;
  modelEntityId: string;
}

const useCreatePredictionJobRun = (): UseMutationResult<
  IProjectReturn,
  unknown,
  IProjectRunData,
  unknown
> => {
  return useMutation({
    mutationFn: async (body) => {
      const projectRuns = await api.fetchResponse(
        async () =>
          await api.ProjectRunControllerApi.createProjectRun({
            ...body,
            schedule: "0 0 * * *",
            jobType: "PREDICTION_JOB"
          })
      );

      return projectRuns as IProjectReturn;
    }
  });
};

export default useCreatePredictionJobRun;
