/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OutEntityInfo
 */
export interface OutEntityInfo {
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'viewType'?: OutEntityInfoViewTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OutEntityInfo
     */
    'rootEntity'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'syncId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'syncDSId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'syncDSName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'syncTpConnectorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'syncDSType'?: OutEntityInfoSyncDSTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'inputDSId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'inputDSName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'inputDSType'?: OutEntityInfoInputDSTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'inputDSTpConnectorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutEntityInfo
     */
    'description'?: string;
}

export const OutEntityInfoViewTypeEnum = {
    Chart: 'CHART',
    Model: 'MODEL',
    Artifact: 'ARTIFACT',
    None: 'NONE'
} as const;

export type OutEntityInfoViewTypeEnum = typeof OutEntityInfoViewTypeEnum[keyof typeof OutEntityInfoViewTypeEnum];
export const OutEntityInfoSyncDSTypeEnum = {
    GcpStorage: 'GCP_STORAGE',
    S3Storage: 'S3_STORAGE',
    AzureBlob: 'AZURE_BLOB',
    Mysql: 'MYSQL',
    Mongo: 'MONGO',
    Snowflake: 'SNOWFLAKE',
    Redshift: 'REDSHIFT',
    RedisStorage: 'REDIS_STORAGE',
    ThirdParty: 'THIRD_PARTY'
} as const;

export type OutEntityInfoSyncDSTypeEnum = typeof OutEntityInfoSyncDSTypeEnum[keyof typeof OutEntityInfoSyncDSTypeEnum];
export const OutEntityInfoInputDSTypeEnum = {
    GcpStorage: 'GCP_STORAGE',
    S3Storage: 'S3_STORAGE',
    AzureBlob: 'AZURE_BLOB',
    Mysql: 'MYSQL',
    Mongo: 'MONGO',
    Snowflake: 'SNOWFLAKE',
    Redshift: 'REDSHIFT',
    RedisStorage: 'REDIS_STORAGE',
    ThirdParty: 'THIRD_PARTY'
} as const;

export type OutEntityInfoInputDSTypeEnum = typeof OutEntityInfoInputDSTypeEnum[keyof typeof OutEntityInfoInputDSTypeEnum];


