import React, { useMemo, useState } from "react";
import ArrowCollapseIcon from "src/icons/NewUX/ArrowCollapseIcon";
import ArrowExpandIcon from "src/icons/NewUX/ArrowExpandIcon";
import { Create } from "@material-ui/icons";
import _ from "lodash";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@material-ui/core";

import NewModal from "src/components/Modal/NewModal";
import PlotlyCharts from "src/components/PlotlyCharts";
import useFetchChartData from "../../Charts/useFetchChartData";
import { Spinner } from "src/components";
import EditChartModal from "./EditChartModal";
import NotFoundIcon from "src/icons/NotFoundIcon";
import Result from "src/components/Errors/Result";
import useSaveChart from "src/hooks/api/codeRecipe/useSaveChart";

const useStyles = makeStyles({
  editIcon: {
    marginRight: "4px"
  },
  chart: {
    minWidth: "calc(60vw)",
    width: "auto",
    borderRadius: "0px 0px 8px 8px",
    background: "#fff",

    "& .js-plotly-plot .plotly .modebar": {
      right: "12px"
    }
  },
  chartOutput: {
    background: "#fff",
    borderRadius: "2px",
    border: "1px solid #D1D1D1"
  },
  chartWrap: {
    maxWidth: "80vw",
    maxHeight: "80vh",
    overflow: "auto"
  },
  chartName: {
    width: "100%",
    padding: "4px 12px 2px !important",
    color: "#133553",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #D1D1D1"
  }
});

const Chart = ({
  name,
  dataFilePath,
  chartType,
  plotlyJson = null,
  askAIMessageId,
  isEditChart = false,
  isParentChart = false,
  subThreadId,
  codeName,
  onClose,
  parentAskAIMessageID,
  parentThreadId,
  isAIGuide = false,
  isSaveDisabled = false,
  isAddedtoRecipe
}: {
  name: string;
  dataFilePath?: string;
  chartType?: string;
  plotlyJson?: null | string;
  askAIMessageId?: string;
  isEditChart?: boolean;
  isParentChart?: boolean;
  subThreadId?: string;
  codeName?: string;
  onClose?: () => void;
  parentAskAIMessageID?: string;
  parentThreadId?: string;
  isAIGuide?: boolean;
  isSaveDisabled?: boolean;
  isAddedtoRecipe?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });
  const [editChart, setEditChart] = useState(false);
  const {
    data: initialChartData,
    isLoading,
    isError,
    error
  } = useFetchChartData(
    {
      name,
      dataFilePath,
      chartType: chartType || ""
    },
    {
      enabled: !plotlyJson
    }
  );
  const saveChart = useSaveChart();

  const chartData = plotlyJson || initialChartData;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isXl = useMediaQuery(theme.breakpoints.down("xl"));
  const isXXL = useMediaQuery(theme.breakpoints.up("xl"));

  const zoomFactor = useMemo(
    () => (isXs ? 1.025 : isSm ? 1.05 : isMd ? 1.1 : isLg ? 1.15 : isXl || isXXL ? 1.2 : 1),
    [isXs, isSm, isMd, isLg, isXl, isXXL]
  );

  const { chartJson, cleanedChartJson } = useMemo(() => {
    const cleanedJson = chartData
      ? {
          ...chartData,
          layout: {
            ...chartData.layout,
            title: null
          }
        }
      : {};

    const expandedJson = chartData
      ? {
          ...chartData,
          layout: {
            ...chartData.layout,
            title: null,
            width: chartData.layout.width * zoomFactor,
            height: chartData.layout.height * zoomFactor
          }
        }
      : {};

    return { chartJson: chartData, cleanedChartJson: cleanedJson, expandedJson };
  }, [chartData]);

  const expandedJson = useMemo(() => {
    if (!open) {
      return null;
    }
    return chartJson
      ? {
          ...chartJson,
          layout: {
            ...chartJson.layout,
            title: null,
            width: chartJson.layout.width * zoomFactor,
            height: chartJson.layout.height * zoomFactor
          }
        }
      : {};
  }, [chartJson, zoomFactor, open]);

  const handleSave = () => {
    if (subThreadId && askAIMessageId && parentAskAIMessageID && parentThreadId) {
      saveChart.mutate(
        {
          threadId: subThreadId,
          messageId: askAIMessageId,
          askAIMessageId: parentAskAIMessageID,
          parentThreadId
        },
        {
          onSuccess: () => {
            onClose?.();
          }
        }
      );
    }
  };

  const handleEdit = () => {
    setEditChart(true);
  };

  const handleEditChartClose = () => {
    setEditChart(false);
  };

  const handleExpand = () => {
    setOpen(!open);
  };

  const children = (
    <Grid container direction="column" className={classes.chartOutput}>
      {isError ? (
        <Result
          style={{ padding: "20px" }}
          icon={<NotFoundIcon />}
          heading="Unable to load the chart"
          subtitle1={_.isString(error) ? error : "Please try again later"}
        />
      ) : (isLoading && !plotlyJson) || !chartJson ? (
        <Spinner />
      ) : (
        <Grid container direction="column" style={{ width: "100%", gap: "4px" }}>
          {!isParentChart && (
            <Grid item className={classes.chartName} data-testid="ai-chart-name">
              <span>{open && name}</span>
              {!isEditChart ? (
                <Grid>
                  {!isAIGuide && !open && (
                    <Tooltip
                      title={
                        isAddedtoRecipe
                          ? "The query output should be removed from the recipe to allow for editing."
                          : ""
                      }>
                      <span>
                        <Button
                          size="small"
                          onClick={handleEdit}
                          disabled={isAddedtoRecipe}
                          style={{ marginRight: "2px" }}
                          classes={{ startIcon: classes.editIcon }}
                          startIcon={<Create style={{ fontSize: "16px" }} />}
                          data-testid="ai-chart-edit-toggle-btn">
                          <span style={{ fontSize: "14px" }}>Edit</span>
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  <Tooltip title={open ? "Collapse" : "Expand"}>
                    <IconButton
                      size="small"
                      onClick={handleExpand}
                      data-testid="ai-chart-toggle-btn">
                      {open ? <ArrowCollapseIcon /> : <ArrowExpandIcon />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                !isParentChart && (
                  <Tooltip
                    title={
                      isSaveDisabled ? "Please wait until existing query run is in progress" : ""
                    }>
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={isSaveDisabled}
                        size="small">
                        {saveChart.isLoading ? (
                          <CircularProgress style={{ color: "#FFFFFF" }} size={20} />
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                )
              )}
            </Grid>
          )}

          <Grid container className={classes.chartWrap}>
            <Grid container className={classes.chart} data-testid="ai-plotly-chart-grid">
              <PlotlyCharts {...(open ? expandedJson : cleanedChartJson)} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {children}
      <NewModal
        width="auto"
        contentWidth="auto"
        open={open}
        hideCloseButton
        hideFooter
        background="#fff"
        keepMounted={false}
        onClose={handleClose}>
        {children}
      </NewModal>
      {editChart && (
        <EditChartModal
          askAIMessageId={askAIMessageId}
          open={editChart}
          name={name}
          onClose={handleEditChartClose}
          chartJson={cleanedChartJson}
          codeName={codeName}
          parentThreadId={parentThreadId}
        />
      )}
    </>
  );
};

export default React.memo(Chart);
