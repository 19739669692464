import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";

interface IPayload {
  projectId?: string;
}

const useDeleteProject = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ projectId }: IPayload) => {
      if (!projectId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.ProjectControllerApi.deleteProjects(projectId)
      );
    }
  });

export default useDeleteProject;
