import { patchAPI, patchAPIWithRethrow, postAPIWithRethrow } from "../utils/apiService";

export const updateTenantDetails = async (payload: $TSFixMe) => {
  return await patchAPI(`/tenants/current`, payload);
};
export const updateTenantDetailsWithRethrow = async (payload: $TSFixMe) => {
  return await patchAPIWithRethrow(`/tenants/current`, payload, {}, false);
};

export const createTenant = async () => {
  return await postAPIWithRethrow(`/tenants`, {});
};
