/* eslint-disable no-undef */
import React from "react";
import clsx from "clsx";
import { Close, KeyboardArrowLeft } from "@material-ui/icons";
import { Drawer as MuiDrawer, IconButton, Typography, DrawerProps, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

interface IStyleProps {
  width: number | string;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  paper: {
    zIndex: 10 ** 1,
    display: "flex",
    flexDirection: "column"
  },
  drawerOpen: {
    width: ({ width }) => width,
    transition: (theme as $TSFixMe).transitions.create("width", {
      easing: (theme as $TSFixMe).transitions.easing.sharp,
      duration: (theme as $TSFixMe).transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: (theme as $TSFixMe).transitions.create("width", {
      easing: (theme as $TSFixMe).transitions.easing.sharp,
      duration: (theme as $TSFixMe).transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 0
  },
  title: {
    fontWeight: 500,
    lineHeight: 1,
    fontSize: 10
  },
  centeredTitle: {
    backgroundColor: "#F2F2F2",
    height: 46,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.875rem"
  },
  resizeBorder: {
    position: "absolute",
    width: 2,
    height: "100%",
    left: 0,
    top: 0,
    cursor: "ew-resize"
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  close: {
    position: "absolute",
    right: 0,
    top: 0
  }
}));

interface IProps extends Omit<DrawerProps, "title"> {
  width?: number | string;
  titleStyle?: React.CSSProperties;
  hideCloseButton?: boolean;
  title?: string | JSX.Element;
  subtitle?: JSX.Element | string;
  centeredTitle?: string;
  showBackButton?: boolean;
  footer?: JSX.Element | string;
  footerComponent?: JSX.Element | string;
  onBack?: () => void;
  onStartResizing?: $TSFixMeFunction;
}

const Drawer = ({
  children,
  anchor = "right",
  variant,
  width = 309,
  open,
  hideCloseButton,
  title,
  subtitle,
  footer,
  footerComponent,
  centeredTitle,
  showBackButton,
  titleStyle,
  className,
  onClose,
  onBack,
  onStartResizing,
  ...rest
}: IProps) => {
  const classes = useStyles({ width });

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClose?.(e, "backdropClick");
  };

  return (
    <MuiDrawer
      anchor={anchor}
      variant={variant}
      className={clsx(className, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      {...rest}
      onClose={onClose}
      open={open}>
      {onStartResizing && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={classes.resizeBorder} onMouseDown={onStartResizing}></div>
      )}
      {!hideCloseButton && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="primary"
          className={classes.close}>
          <Close style={{ height: 21 }} />
        </IconButton>
      )}
      {showBackButton && (
        <IconButton
          aria-label="close"
          onClick={onBack}
          color="primary"
          style={{
            position: "absolute",
            left: 0,
            top: 0
          }}>
          <KeyboardArrowLeft />
        </IconButton>
      )}
      {title && (
        <div
          style={
            titleStyle
              ? titleStyle
              : {
                  padding: "10px 14px",
                  borderBottom: "1px solid #E0E0E0",
                  lineHeight: 1
                }
          }>
          <Typography variant="overline" classes={{ root: classes.title }}>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {subtitle}
          </Typography>
        </div>
      )}
      {centeredTitle && (
        <div>
          <Typography
            variant="subtitle2"
            classes={{ root: clsx(classes.title, classes.centeredTitle) }}>
            {centeredTitle}
          </Typography>
        </div>
      )}
      {children}
      {footer && <div className={classes.footer}>{footer}</div>}
      {footerComponent}
    </MuiDrawer>
  );
};

export default Drawer;
