import React from "react";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`
  },
  loader: {
    position: "relative",
    left: "50%"
  }
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <Box position="absolute" width="100%" bottom={0} p={1} className={classes.root}>
      <CircularProgress size={24} color="secondary" className={classes.loader} />
    </Box>
  );
};

export default Loader;
