import React from "react";
import { Typography } from "@material-ui/core";

type Props = {
  lines: $TSFixMe[];
  type?: boolean;
};

const Lines = ({ lines, type }: Props) => {
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Typography variant="body1" style={type === "small" && { fontSize: 14 }}>
      {lines?.reduce((prev, next) => (
        <>
          {prev}
          <br />
          {next}
        </>
      ))}
    </Typography>
  );
};

export default Lines;
