import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import _ from "lodash";
import { DFSRunConfigGroupDto } from "openapi/Models/dfsrun-config-group-dto";

import api from "src/utils/AxiosClient";

const QUERY_KEY_RECIPE_BY_ID = "query-key-recipe-by-id";

const useRecipeById = (
  recipeId?: string,
  options?: UseQueryOptions<DFSRunConfigGroupDto>
): UseQueryResult<DFSRunConfigGroupDto> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_RECIPE_BY_ID, recipeId],
    queryFn: async () => {
      if (!recipeId) {
        throw "recipe Id is absent";
      }

      const response = await api.fetchResponse(
        async () => await api.DfsRunConfigGroupControllerV2Api.findGroups(recipeId)
      );

      return _.head(response);
    },
    enabled: !!recipeId,
    ...options
  });
};

export default useRecipeById;
export { QUERY_KEY_RECIPE_BY_ID };
