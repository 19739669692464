/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { DataSourceDetail } from './data-source-detail';

/**
 * 
 * @export
 * @interface GenerateCodeRequest
 */
export interface GenerateCodeRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    'mode'?: GenerateCodeRequestModeEnum;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    'conversationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    'conversationName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    'userInput'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateCodeRequest
     */
    'entityIds'?: Array<string>;
    /**
     * 
     * @type {Array<DataSourceDetail>}
     * @memberof GenerateCodeRequest
     */
    'dataSourcesDetail'?: Array<DataSourceDetail>;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateCodeRequest
     */
    'askAi': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateCodeRequest
     */
    'withSignature': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateCodeRequest
     */
    'outputs'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateCodeRequest
     */
    'charts'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    'outputType'?: GenerateCodeRequestOutputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GenerateCodeRequest
     */
    'parentHistoryId'?: string;
    /**
     * 
     * @type {number}
     * @memberof GenerateCodeRequest
     */
    'sampleRows'?: number;
}

export const GenerateCodeRequestModeEnum = {
    Recipe: 'RECIPE',
    Dataapp: 'DATAAPP'
} as const;

export type GenerateCodeRequestModeEnum = typeof GenerateCodeRequestModeEnum[keyof typeof GenerateCodeRequestModeEnum];
export const GenerateCodeRequestOutputTypeEnum = {
    Dataset: 'DATASET',
    Chart: 'CHART',
    Text: 'TEXT',
    Model: 'MODEL',
    AutoInfer: 'AUTO_INFER',
    PromptSuggestions: 'PROMPT_SUGGESTIONS'
} as const;

export type GenerateCodeRequestOutputTypeEnum = typeof GenerateCodeRequestOutputTypeEnum[keyof typeof GenerateCodeRequestOutputTypeEnum];


