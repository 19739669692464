/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { ShutdownStrategy } from './shutdown-strategy';

/**
 * 
 * @export
 * @interface CreateEnvDto
 */
export interface CreateEnvDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEnvDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEnvDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEnvDto
     */
    'cores'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEnvDto
     */
    'gpus'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEnvDto
     */
    'memInMbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEnvDto
     */
    'diskInGbs'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEnvDto
     */
    'requirements'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEnvDto
     */
    'envType'?: CreateEnvDtoEnvTypeEnum;
    /**
     * 
     * @type {ShutdownStrategy}
     * @memberof CreateEnvDto
     */
    'shutdownStrategy'?: ShutdownStrategy;
}

export const CreateEnvDtoEnvTypeEnum = {
    ExtraSmall: 'EXTRA_SMALL',
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE',
    CpuLarge: 'CPU_LARGE',
    MaxLarge: 'MAX_LARGE',
    ExtraMaxLarge: 'EXTRA_MAX_LARGE',
    Custom: 'CUSTOM'
} as const;

export type CreateEnvDtoEnvTypeEnum = typeof CreateEnvDtoEnvTypeEnum[keyof typeof CreateEnvDtoEnvTypeEnum];


