import React from "react";
import { Grid, Paper, makeStyles, Box } from "@material-ui/core";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";

type StyleProps = { height?: string; width?: string };
const useStyles = makeStyles({
  gridWrap: {
    flexWrap: "nowrap",
    gap: "18px",
    width: ({ width }: StyleProps) => width || "600px"
  },
  paper: {
    width: "100%",
    padding: "20px 25px",
    backgroundColor: "#1e1e1e",
    fontFamily: `Menlo, Monaco, "Courier New", monospace`,
    color: "#d4d4d4",
    fontSize: 12,
    height: ({ height }: StyleProps) => height || "calc(100vh - 45px)",
    maxHeight: ({ height }: StyleProps) => height || "calc(100vh - 45px)",
    overflow: "auto",
    borderRadius: "0px"
  }
});

export const LogsContent = ({
  logs,
  info,
  height,
  width
}: {
  logs: string[];
  info?: string;
  height?: string;
  width?: string;
}) => {
  const classes = useStyles({ height, width });
  return (
    <Grid container className={classes.gridWrap}>
      {logs?.length > 0 ? (
        <Paper className={classes.paper}>
          <ul style={{ listStyle: "none" }} data-testid="dataAppLogsList">
            {logs.map((log, index) => {
              return <li key={`log_${index}`}>{log}</li>;
            })}
          </ul>
        </Paper>
      ) : (
        <Box p={"20px 25px"} width="100%" data-testid="dataAppNoLogsText">
          <ToastifyAlert type="info" message={info ? info : "No logs found!"} />
        </Box>
      )}
    </Grid>
  );
};
