import React, { useState } from "react";

import AskAIInputDataset from "./AskAIInputDataset";
import { EntityFeaturesResponse } from "src/hooks/api/datasets/useGetEntityFeaturesV2";

interface IProps {
  name: string;
  datasetId: string;
  entityData?: EntityFeaturesResponse;
}

const AIGuideInputDataset: React.FC<IProps> = (props) => {
  const { name, datasetId, entityData } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <AskAIInputDataset
      datasetName={name}
      datasetId={datasetId}
      entityFeatures={entityData?.schema}
      onExpandToggle={() => setIsExpanded((expanded) => !expanded)}
      isExpanded={isExpanded}
    />
  );
};

export default React.memo(AIGuideInputDataset);
