/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { v4 as uuidv4 } from "uuid";
import { ChevronLeft, ChevronRight, Info } from "@material-ui/icons";
import {
  Grid,
  makeStyles,
  Popper,
  Typography,
  InputAdornment,
  TextField,
  Paper,
  Chip
} from "@material-ui/core";

import { Search } from "src/icons/Search";
import { DfsTemplate } from "src/types";
import { TagOutline } from "src/icons/TagOutline";

const useStyles = makeStyles({
  autoCompleteOption: {
    paddingLeft: "8px",
    padding: "0px",
    minHeight: "32px"
  },
  renderOption: {
    padding: "6px 16px",
    width: "100%"
  },
  nestedPopupWrap: {
    flexWrap: "nowrap",
    gap: "8px"
  },
  templatePopup: {
    zIndex: 15000,
    width: "300px",
    background: "#fff",
    border: "1px solid #4646b5",
    padding: "12px",
    filter: "drop-shadow(0px 4px 4px rgba(86, 86, 86, 0.35))"
  },
  infoIcon: {
    color: "#4646b5"
  },
  templateWrap: {
    flexDirection: "column",
    flexWrap: "nowrap",
    gap: "8px"
  },
  smallTitle: {
    fontSize: "12px",
    lineHeight: "14px"
  },
  boldTitle: {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 600
  },
  title: {
    fontSize: "14px",
    lineHeight: "16.67px"
  },
  subTitle: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "rgba(0,0,0,0.5)",
    wordBreak: "break-word"
  },
  miniTitle: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "rgba(0,0,0,0.5)"
  },
  headerOption: {
    padding: "6px 16px"
  },
  autoCompleteListBox: {
    padding: "0px",
    maxHeight: "386px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "6px",
      height: "6px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "6px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  inputRoot: {
    "& input::placeholder": {
      fontSize: "14px",
      lineHeight: "16.67px"
    }
  },
  transformGroupHeader: {
    flexWrap: "nowrap",
    padding: "6px 16px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0000000a"
    }
  },
  transformNameWrap: {
    flexWrap: "nowrap",
    gap: "6px",
    alignItems: "center"
  },
  optionsWrap: {
    maxHeight: "350px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "4px",
      height: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "#7C7C7C",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)"
    }
  },
  templateTags: {
    gap: "8px",
    marginLeft: "0px",
    paddingTop: "8px",
    paddingBottom: "8px"
  },
  autoCompletePaper: {
    border: "1px solid #C7C7C7"
  }
});

type Props = {
  dfsTemplates: Array<DfsTemplate>;
  setSelectedTransform: $TSFixMe;
  textFieldRef: $TSFixMe;
  autocompleteRef: $TSFixMe;
  isDefaultScenario: boolean;
};

export const TransformationsList = ({
  autocompleteRef,
  textFieldRef,
  dfsTemplates,
  setSelectedTransform,
  isDefaultScenario
}: Props) => {
  const classes = useStyles();
  const [currentTag, setCurrentTag] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const customFilter = (option: DfsTemplate, inputValue: any) => {
    const searchValue = inputValue.toLowerCase();
    const transformName = (option.display_name || option.name || "")?.toLowerCase();
    const transformDescription = (option?.description || "")?.toLowerCase();

    const hasMatchingSecondaryTag =
      option?.secondaryTags?.some((tag) => tag.includes(searchValue)) || false;
    return (
      transformName.includes(searchValue) ||
      transformDescription.includes(searchValue) ||
      hasMatchingSecondaryTag
    );
  };

  return (
    <Autocomplete
      value={selectedValue}
      inputValue={searchTerm}
      ref={autocompleteRef}
      open={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      clearOnBlur={true}
      clearOnEscape={true}
      disabled={!isDefaultScenario}
      classes={{
        option: classes.autoCompleteOption,
        listbox: classes.autoCompleteListBox
      }}
      PaperComponent={(props) => (
        <Paper {...props} elevation={8} className={classes.autoCompletePaper} />
      )}
      onChange={(__: $TSFixMe, value, reason) => {
        if (reason === "select-option") {
          setSelectedTransform(value);
          setSearchTerm("");
          if (autocompleteRef.current) {
            // Remove focus from the input field to clear value on select
            //@ts-expect-error
            autocompleteRef.current?.value = null;
            autocompleteRef.current?.blur?.();
          }
        }
      }}
      filterOptions={(options, { inputValue }) =>
        options.filter((option) => customFilter(option, inputValue))
      }
      onInputChange={(__, newSearchTerm, reason) => {
        if (reason === "input") {
          setSearchTerm(newSearchTerm);
        }
      }}
      getOptionLabel={(option) => option.display_name || option.name}
      options={dfsTemplates.sort((a, b) => -b.tags[0].localeCompare(a.tags[0]))}
      renderGroup={(group) => {
        if (currentTag && group.group !== currentTag) {
          return <React.Fragment />;
        }
        if (currentTag && group.group === currentTag) {
          return [
            <Grid
              container
              direction="row"
              key={group.key}
              onClick={() => {
                setCurrentTag(null);
              }}
              component="li"
              className={classes.transformGroupHeader}>
              <Grid item container direction="row" className={classes.transformNameWrap}>
                <ChevronLeft fontSize="small" test-id="templateNestedMenuBackIcon" />
                <TagOutline />
                <Typography test-id="templateMenuNestedTag">{group.group}</Typography>
              </Grid>
            </Grid>,
            <Grid key={uuidv4()} className={classes.optionsWrap}>
              {group.children}
            </Grid>
          ];
        }

        return [
          <Grid
            container
            direction="row"
            key={group.key}
            component="li"
            justifyContent="space-between"
            onClick={() => {
              setCurrentTag(group.group);
            }}
            className={classes.transformGroupHeader}>
            <Grid item container direction="row" className={classes.transformNameWrap}>
              <TagOutline />
              <Typography className={classes.title} test-id="templateMenuTag">
                {group.group}
              </Typography>
              {(group.children as $TSFixMe)?.length > 0 && (
                <Typography className={classes.miniTitle} test-id="templateMenuTagTemplatesCount">
                  {(group.children as $TSFixMe).length || 0}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <ChevronRight fontSize="small" test-id="templateNestedMenuExpandIcon" />
            </Grid>
          </Grid>,
          !searchTerm ? <React.Fragment /> : <Grid key={uuidv4()}>{group.children}</Grid>
        ];
      }}
      renderOption={(option) => {
        return <CustomOption option={option} />;
      }}
      groupBy={(option) => {
        return option.tags[0];
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          inputRef={textFieldRef}
          variant="outlined"
          size="small"
          placeholder="Search to Add Transformation"
          inputProps={{
            ...props.inputProps,
            "test-id": "standardRecipeSearchTemplates"
          }}
          InputProps={{
            ...props.InputProps,
            classes: {
              root: classes.inputRoot
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
};

const CustomOption = ({ option }: { option: DfsTemplate }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handlePopoverMouseDown = (event: $TSFixMe) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid
        className={classes.renderOption}
        onMouseEnter={(event) => handleMenuOpen(event)}
        onMouseLeave={handleClose}>
        <Typography noWrap className={classes.title} test-id="standardRecipeTemplateName">
          {option.display_name || option.name}
        </Typography>
      </Grid>
      {option && (
        <Popper
          id="dropdown-id"
          open={open}
          anchorEl={anchorEl}
          placement="left-start"
          disablePortal={false}
          onMouseDown={handlePopoverMouseDown}
          className={classes.templatePopup}>
          <Grid container direction="row" className={classes.nestedPopupWrap}>
            <Grid item>
              <Info className={classes.infoIcon} test-id="templatePopupInfoIcon" />
            </Grid>
            <Grid item container direction="column" className={classes.templateWrap}>
              <Typography className={classes.boldTitle} test-id="templatePopupTitle">
                {option.name}
              </Typography>
              <Typography className={classes.subTitle} test-id="templatePopupDescription">
                {option.description}
              </Typography>
              <Grid container direction="row" spacing={2} className={classes.templateTags}>
                {option?.secondaryTags?.map((secondaryTag: string, index: number) => (
                  <Chip
                    size="small"
                    key={index}
                    label={<Typography className={classes.smallTitle}>{secondaryTag}</Typography>}
                    clickable
                    color="default"
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Popper>
      )}
    </>
  );
};
