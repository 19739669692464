import React, { PropsWithChildren } from "react";

import { makeStyles, Box } from "@material-ui/core";

import NewThemeWrapper from "src/styles/NewThemeWrapper";
import ProjectNavBar from "src/layout/NavBars/components/ProjectNavBar/ProjectNavBar";
import ProjectContextProvider from "src/pages/private/ProjectsModule/context/ProjectContextProvider";

const useStyles = makeStyles({
  root: {
    position: "relative",

    "& .MuiDrawer-paperAnchorLeft": {
      left: "44px",
      top: "44px"
    }
  },
  child: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)"
  }
});
const ProjectRoutesBox: React.FC<PropsWithChildren<{}>> = (props) => {
  const { root, child } = useStyles();

  return (
    // This ProjectContextProvider should be exposed within ProjectModule.
    // Keeping it here till ProjectsModule refactor is completed.
    <ProjectContextProvider>
      <Box className={root}>
        <NewThemeWrapper>
          <ProjectNavBar />
        </NewThemeWrapper>
        <div className={child}>{props.children}</div>
      </Box>
    </ProjectContextProvider>
  );
};

export default ProjectRoutesBox;
