import React from "react";

// MUI
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { ProjectSettingsHelperText } from "../utils/ProjectSettings.constants";

type Props = { width?: number; height?: number; simulateFormControl?: boolean; label?: string };

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: theme.spacing(0.5)
  },
  inputLabel: {
    position: "absolute",
    top: -theme.spacing(1),
    opacity: 0.25
  }
}));

const PreviewPlaceholder = (props: Props) => {
  const {
    width = "100%",
    height = 40,
    simulateFormControl = true,
    label = ProjectSettingsHelperText.Unknown,
    ...rest
  } = props || {};

  const classes = useStyles();

  return !simulateFormControl ? (
    <Skeleton variant="rect" width={width} height={height} className={classes.root} {...rest} />
  ) : (
    <FormControl variant="outlined" fullWidth>
      <InputLabel className={classes.inputLabel} data-testid="projectSettingsPreviewInputLabel">
        {label}
      </InputLabel>
      <Skeleton variant="rect" width={width} height={height} className={classes.root} {...rest} />
    </FormControl>
  );
};

export default PreviewPlaceholder;
