import React from "react";

export const MinimizeIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0832 23.8328C18.4762 23.8249 20.7766 23.7171 22.2467 22.2469C23.8332 20.6604 23.8332 18.1069 23.8332 13.0001C23.8332 7.89319 23.8332 5.33976 22.2467 3.75326C20.6602 2.16675 18.1068 2.16675 12.9999 2.16675C7.893 2.16675 5.33955 2.16675 3.75306 3.75326C2.28293 5.22337 2.17508 7.52376 2.16718 11.9167"
        stroke="#7C7C7C"
        strokeWidth="1.625"
        strokeLinecap="round"
      />
      <path
        d="M18.4167 7.58325L13 12.9999M13 12.9999H17.0625M13 12.9999V8.93742"
        stroke="#7C7C7C"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.16669 19.5001C2.16669 17.4573 2.16669 16.436 2.80129 15.8014C3.43589 15.1667 4.45727 15.1667 6.50002 15.1667C8.54278 15.1667 9.56415 15.1667 10.1987 15.8014C10.8334 16.436 10.8334 17.4573 10.8334 19.5001C10.8334 21.5428 10.8334 22.5642 10.1987 23.1988C9.56415 23.8334 8.54278 23.8334 6.50002 23.8334C4.45727 23.8334 3.43589 23.8334 2.80129 23.1988C2.16669 22.5642 2.16669 21.5428 2.16669 19.5001Z"
        stroke="#7C7C7C"
        strokeWidth="1.625"
      />
    </svg>
  );
};
