/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateProjectDto
 */
export interface UpdateProjectDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'icon'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectDto
     */
    'industries'?: Array<UpdateProjectDtoIndustriesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectDto
     */
    'useCases'?: Array<UpdateProjectDtoUseCasesEnum>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UpdateProjectDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'envId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'canvasEdgeStyle'?: UpdateProjectDtoCanvasEdgeStyleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDto
     */
    'askAIContext'?: string;
}

export const UpdateProjectDtoIndustriesEnum = {
    Default: 'DEFAULT',
    Banking: 'BANKING',
    Ecommerce: 'ECOMMERCE',
    Logistics: 'LOGISTICS',
    Marketplace: 'MARKETPLACE'
} as const;

export type UpdateProjectDtoIndustriesEnum = typeof UpdateProjectDtoIndustriesEnum[keyof typeof UpdateProjectDtoIndustriesEnum];
export const UpdateProjectDtoUseCasesEnum = {
    Default: 'DEFAULT',
    Banking: 'BANKING',
    Ecommerce: 'ECOMMERCE',
    Logistics: 'LOGISTICS',
    Marketplace: 'MARKETPLACE'
} as const;

export type UpdateProjectDtoUseCasesEnum = typeof UpdateProjectDtoUseCasesEnum[keyof typeof UpdateProjectDtoUseCasesEnum];
export const UpdateProjectDtoCanvasEdgeStyleEnum = {
    Curved: 'CURVED',
    Squared: 'SQUARED'
} as const;

export type UpdateProjectDtoCanvasEdgeStyleEnum = typeof UpdateProjectDtoCanvasEdgeStyleEnum[keyof typeof UpdateProjectDtoCanvasEdgeStyleEnum];


