import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

export const QUERY_KEY_DATA_SOURCE_SAMPLE_DATA = "query-key-data-source-sample-data";

const useGetSampleData = (
  connectorId: string,
  tableName?: string,
  options?: UseQueryOptions<any, any>
): UseQueryResult<any> => {
  return useQuery({
    queryKey: [QUERY_KEY_DATA_SOURCE_SAMPLE_DATA, connectorId, tableName],
    queryFn: async () => {
      const secretResponse = await getAPIWithRethrow(
        `/v2/data-source/${connectorId}/connector/sample-data?connectorId=${connectorId}&tableName=${tableName}`
      );

      return secretResponse;
    },
    ...options
  });
};

export default useGetSampleData;
