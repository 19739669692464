import React, { useMemo, useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import useGetRecipeRunLogs from "../../RecipeRunLogs/useGetRecipeRunLogs";
import { LogsContent } from "./LogsContent";
import { RecipeStatuses } from "src/constants";
import { ToastifyAlert } from "src/components/ToastifyAlert/ToastifyAlert";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";
import { getStatusEquivalence, ShowLogsDrawerTitle } from "./ShowLogsDrawerTitle";
import { sideComponentSetter } from "src/store/store.selectors";
import { useDrawerStore } from "src/store/store";

export const TEMP_MSG = ["Fetching logs. Please wait..."];
export const UNBUILT_MSG = "Logs are not available because the recipe hasn't been executed yet.";

export const useStyles = makeStyles({
  autoMlLogs: {
    flexWrap: "nowrap",
    width: "600px"
  },
  logStatuses: {
    gap: "16px",
    background: "#1e1e1e",
    padding: "8px 8px 0px 8px"
  },
  logStatusWithoutBckg: {
    gap: "16px",
    padding: "8px 8px 0px 8px"
  }
});

export const renderInfo = ({ message, type }: $TSFixMe) => {
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: 16,
        display: "inline-block"
      }}>
      <ToastifyAlert type={type} message={message} />
    </Grid>
  );
};

// NOTE: Use RecipeLogsDrawer instead of this component
export const RecipeLogs = ({ projectId, scenarioId, groupId, isJobPath, jobRunId }: $TSFixMe) => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  const {
    recipeRunInfo,
    isFetching,
    recipeLogs,
    isFetchingFullLogs,
    isViewFullLogsClicked,
    recipeStatus,
    setIsViewFullLogsClicked,
    getRecipeLogs
  } = useGetRecipeRunLogs({
    projectId,
    scenarioId,
    groupId,
    ...(isJobPath ? { jobRunId } : {})
  });

  const setSideComponent = useDrawerStore(sideComponentSetter);

  const url = `/recipe-run-logs/${projectId}/${scenarioId}/${groupId}`;

  useEffect(() => {
    if (projectId) {
      checkEnvRelaunch(projectId);
    }
  }, [projectId]);

  const closeLogs = () => {
    setSideComponent({
      sideComponent: null,
      sideComponentProps: null
    });
  };

  const onFullLogsClick = () => {
    getRecipeLogs({ shouldShowFullLogs: true });
    setIsViewFullLogsClicked(true);
  };

  const onSearchValueChange = (event: $TSFixMe) => {
    setSearchValue(event.target.value);
  };

  const filteredLogs = useMemo(() => {
    return (recipeLogs || [])?.filter((eachField: $TSFixMe) => {
      return searchValue ? eachField?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true;
    });
  }, [searchValue, recipeLogs]);

  return (
    <Grid container direction="column" justifyContent="center" className={classes.autoMlLogs}>
      <ShowLogsDrawerTitle
        url={!isJobPath && url}
        onClose={closeLogs}
        {...(recipeStatus && {
          status: { color: getStatusEquivalence(recipeStatus), text: recipeStatus }
        })}
        {...(!isViewFullLogsClicked &&
          ![RecipeStatuses.Running, RecipeStatuses.UnBuilt].includes(recipeStatus) && {
            onFullLogsClick: onFullLogsClick
          })}
        searchValue={searchValue}
        onSearch={onSearchValueChange}
      />
      {!isFetching || isFetchingFullLogs ? (
        <LogsContent logs={TEMP_MSG} />
      ) : filteredLogs && filteredLogs?.length !== 0 ? (
        isFetchingFullLogs ? (
          <LogsContent logs={TEMP_MSG} />
        ) : (
          <LogsContent
            logs={filteredLogs}
            {...(RecipeStatuses.UnBuilt === recipeStatus ? { info: UNBUILT_MSG } : {})}
          />
        )
      ) : (
        renderInfo(recipeRunInfo)
      )}
    </Grid>
  );
};
