import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ChatBox from "./ChatBox";
import { RecordSelector } from "../../common/RecordSelector";
import { EntityFeaturesResponse } from "src/hooks/api";
import ViewDataData from "src/pages/ViewData/ViewDataData/ViewDataData";
import { Spinner } from "src/components";
import { EntityIconV3 } from "src/icons/NewUX/EntityIconV3";
import { AskAICodeEditor } from "../../common/AskAICodeEditor";

type StyleProps = {
  isExpandDisabled: boolean;
  isExpanded: boolean;
  isAddedToRecipe?: boolean;
};

const useStyles = makeStyles<undefined, StyleProps>(() => ({
  root: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "nowrap"
  },
  title: {
    color: "#4646B5",
    fontWeight: 400
  },
  collapsedTitle: {
    color: "#4646B5",
    fontWeight: 400
  },
  titleBox: {
    color: "#4646B5",
    cursor: "pointer",
    padding: "6px 12px",
    borderTopRightRadius: "4px",
    gap: "8px"
  },
  dataContainer: {
    background: "#fff",
    borderRadius: "4px",
    maxWidth: "100%"
  },
  dataGrid: {
    maxHeight: "260px",
    overflowY: "auto",
    margin: "0px 6px 0px 6px",
    borderRadius: "2px"
  },
  bottomGrid: {
    padding: "0px 12px 12px 12px",
    gap: "16px",
    alignItems: "center"
  },
  sizeText: {
    fontSize: "14px",
    color: "#515151"
  },
  boxWrap: {
    width: "100%",
    boxShadow: "0px 4px 4px 0px #82828240"
  },
  collapsedBox: {
    cursor: ({ isExpandDisabled }) => (isExpandDisabled ? "default" : "pointer"),
    padding: ({ isExpanded }) => (isExpanded ? "4px 12px" : "0px"),
    gap: "16px"
  },
  icon: {
    height: "32px",
    width: "24px !important",
    marginRight: "24px"
  },
  rightContainer: {
    gap: "8px"
  }
}));

interface Props {
  isExpandDisabled: boolean;
  isGenerating?: boolean;
  entityData: EntityFeaturesResponse | undefined;
  title: string;
  hasMessages: boolean;
  isExpanded?: boolean;
  onToggle?: ({ onSuccess }: { onSuccess: () => void }) => void;
  isLoading?: boolean;
  rightContainer?: React.ReactNode;
  shouldShowCode?: boolean;
  code?: string;
  isAddedToRecipe?: boolean;
  errDetails?: any;
}

const InputDataset = ({
  entityData,
  title,
  isExpandDisabled,
  isGenerating,
  hasMessages,
  isExpanded: initialExpanded = true,
  onToggle,
  isLoading = false,
  rightContainer,
  shouldShowCode,
  code,
  isAddedToRecipe = false,
  errDetails
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(initialExpanded);
  const classes = useStyles({ isExpandDisabled, isExpanded, isAddedToRecipe });

  const toggleExpanded = () => {
    const onSuccess = () => {
      setIsExpanded((expanded) => !expanded);
    };
    onToggle ? onToggle({ onSuccess }) : onSuccess();
  };

  useEffect(() => {
    setIsExpanded(initialExpanded);
  }, [initialExpanded]);

  useEffect(() => {
    if (isGenerating || hasMessages) {
      setIsExpanded(false);
    }
  }, [isGenerating, hasMessages]);

  const showCode = shouldShowCode && code;
  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        data-testid="ai-dataset-icon"
        className={classes.icon}>
        <EntityIconV3 />
      </Grid>
      {isExpanded ? (
        <ChatBox
          noPadding
          color={isAddedToRecipe ? "#E9FFDF" : "#e7e7ff"}
          border="#4646B5"
          width="calc(100% - 134px)"
          childWidth="100%">
          <Grid container direction="column" className={classes.boxWrap}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
              className={classes.titleBox}
              onClick={toggleExpanded}>
              <Grid item xs>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  data-testid="ai-dataset-title">
                  {title}
                </Typography>
              </Grid>
              <Grid
                item
                direction="row"
                wrap="nowrap"
                container
                xs={3}
                className={classes.rightContainer}
                spacing={1}
                alignItems="center"
                justifyContent="flex-end">
                {rightContainer}
                <IconButton
                  size="small"
                  style={{ padding: "0px" }}
                  data-testid="ai-dataset-toggle-btn">
                  <ExpandLessIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            {isExpanded && showCode ? (
              <AskAICodeEditor editorValue={code} errDetails={errDetails} />
            ) : (
              <MemoizedInputDatasetTable entityData={entityData} />
            )}
          </Grid>
        </ChatBox>
      ) : (
        <ChatBox border={"#FFF"} color={isAddedToRecipe ? "#E9FFDF" : "#FFF"} width="auto">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => !isExpandDisabled && toggleExpanded()}
            className={classes.collapsedBox}>
            <Typography
              variant="subtitle2"
              className={classes.collapsedTitle}
              data-testid="ai-dataset-title">
              {title}
            </Typography>
            <IconButton
              size="small"
              style={{ padding: "0px" }}
              disabled={isExpandDisabled}
              data-testid="ai-dataset-toggle-btn">
              {isLoading ? <Spinner size={18} noPadding /> : <ExpandMoreIcon fontSize="small" />}
            </IconButton>
          </Grid>
        </ChatBox>
      )}
    </Grid>
  );
};

const InputDatasetTable = ({ entityData }: { entityData: any }) => {
  const [value, setValue] = useState(100);
  const classes = useStyles({ isExpandDisabled: false, isExpanded: true });

  const numRows = entityData?.numRows || entityData?.data?.rows?.length;
  const numColumns = entityData?.numColumns || entityData?.data?.columns?.length;
  const handleCountChange = (event: any) => {
    event.stopPropagation();
    setValue(event.target.value);
  };

  const slicedRows = (entityData?.data as any)?.rows?.slice(0, value) || [];
  const slicedColumns = (entityData?.data as any)?.columns || [];
  return (
    <Grid item className={classes.dataContainer}>
      <Grid id="viewDataContainer" className={classes.dataGrid} data-testid="ai-dataset-grid">
        <ViewDataData
          isLoadingData={false}
          responseData={
            entityData?.data
              ? {
                  rows: slicedRows,
                  columns: slicedColumns
                }
              : { columns: [], rows: [] }
          }
          entityFeatures={entityData.schema}
          maxHeight="236px"
        />
      </Grid>
      {numRows && numColumns ? (
        <Grid container className={classes.bottomGrid}>
          <RecordSelector value={value} handleCountChange={handleCountChange} />
          <Typography variant="body2" className={classes.sizeText} data-testid="ai-dataset-size">
            {`Size: ${numRows} Rows * ${numColumns} Columns`}
          </Typography>
        </Grid>
      ) : (
        <React.Fragment />
      )}
    </Grid>
  );
};
const MemoizedInputDatasetTable = React.memo(InputDatasetTable);
export default React.memo(InputDataset);
export { MemoizedInputDatasetTable as InputDatasetTable };
