import React, { useMemo } from "react";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import { PlotParams } from "react-plotly.js";

import EdaChartCard from "./EdaChartCard";
import EdaAdditionalDefault from "./EdaAdditionalDefault";
import PlotlyCharts from "src/components/PlotlyCharts";

interface IProps {
  data: string[];
  isLoading: boolean;
  onGenerate: (limit: number) => void;
}

export const EDA_CHART_LIMIT = 4;

const EdaCharts: React.FC<IProps> = (props) => {
  const { data, isLoading, onGenerate } = props;

  const parsedJsons = useMemo(() => {
    const jsons: PlotParams[] = [];

    _.forEach(data, (eachJson) => {
      const parsed = JSON.parse(eachJson);
      if (parsed) {
        jsons.push(parsed);
      }
    });

    return jsons;
  }, [data]);

  return (
    <div>
      <Grid container spacing={3} style={{ width: "100%" }}>
        {_.map(parsedJsons, (chartJson, index) => (
          <Grid key={index} item xs={12} sm={12} md={6}>
            <EdaChartCard>{<PlotlyCharts {...chartJson} />}</EdaChartCard>
          </Grid>
        ))}
      </Grid>
      {data.length < EDA_CHART_LIMIT + 1 && (
        <EdaAdditionalDefault
          isLoading={isLoading}
          prevLength={data.length}
          onGenerate={onGenerate}
        />
      )}
    </div>
  );
};

export default EdaCharts;
