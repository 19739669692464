import React, { useMemo } from "react";

// Packages
import { ColumnDefResolved } from "@tanstack/react-table";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { find, findIndex, map, size } from "lodash";

// Constants
import { TableConfig } from "../../utils/Table.constants";

// Types
import { TData } from "../../Table.types";

import { FixedSizeList } from "react-window";
import DragItem from "./DragItem";
import ReactWindowRow from "./ReactWindowRow";

type Props = {
  maxColumnsCount?: number;
  columns: ColumnDefResolved<TData, any>[];
  setColumns: $TSFixMeFunction;
  filteredColumns: ColumnDefResolved<TData, any>[];
  setFilteredColumns: $TSFixMeFunction;
  selectedColumns: ColumnDefResolved<TData, any>[];
  onColumnsReorder: $TSFixMeFunction;
};
const ITEM_SIZE = 34;
const Columns = (props: Props) => {
  const {
    maxColumnsCount,
    columns,
    setColumns,
    filteredColumns,
    setFilteredColumns,
    selectedColumns,
    onColumnsReorder
  } = props || {};

  const onDragEnd = (result: DropResult) => {
    if (!result?.destination) return;

    const reorderedColumns = [...filteredColumns];
    const [removed] = reorderedColumns?.splice(result?.source?.index, 1);
    reorderedColumns?.splice(result?.destination?.index, 0, removed);

    setFilteredColumns(() => reorderedColumns);
    onColumnsReorder(reorderedColumns);
  };

  const isColumnLimitReached = useMemo(
    () => size(selectedColumns) > (maxColumnsCount ?? TableConfig.MaxColumnsCount) - 1,
    [selectedColumns]
  );

  const selectColumn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = event?.target?.checked;
    const targetValue = event?.currentTarget?.value;
    const targetIndex = findIndex(columns, { accessorKey: targetValue });

    if (
      size(selectedColumns) > (maxColumnsCount ?? TableConfig.MaxColumnsCount) - 1 &&
      isSelected
    ) {
      // @ts-ignore
      const firstSelectedColumn: ColumnDefResolved<any, any> = find(
        columns,
        { isSelected: true },
        targetIndex > (maxColumnsCount ?? TableConfig.MaxColumnsCount) - 1 ? undefined : targetIndex
      );

      setColumns(
        map(columns, (column: ColumnDefResolved<TData, string>) => {
          if (column?.accessorKey === targetValue) {
            return { ...column, isSelected: true };
          }

          if (firstSelectedColumn?.accessorKey === column?.accessorKey) {
            return { ...column, isSelected: false };
          }

          return column;
        })
      );

      setFilteredColumns(
        map(filteredColumns, (column: ColumnDefResolved<TData, any>) => {
          if (column?.accessorKey === targetValue) {
            return { ...column, isSelected: true };
          }

          if (firstSelectedColumn?.accessorKey === column?.accessorKey) {
            return { ...column, isSelected: false };
          }

          return column;
        })
      );
    } else {
      setColumns(
        map(columns, (column: ColumnDefResolved<TData, any>) => {
          if (column?.accessorKey === targetValue) {
            return { ...column, isSelected };
          } else {
            return column;
          }
        })
      );

      setFilteredColumns(
        map(filteredColumns, (column: ColumnDefResolved<TData, any>) => {
          if (column?.accessorKey === targetValue) {
            return { ...column, isSelected };
          } else {
            return column;
          }
        })
      );
    }
  };

  const itemCount = filteredColumns.length;

  return (
    <>
      {/* Check all */}
      {/* <List className={classes.columnsListContainer} disablePadding>
        <ListItem button dense>
          <Checkbox
            id="toggleColumns"
            size="small"
            color="primary"
            className="columnCheckbox"
            disableRipple
            checked={table?.getIsAllColumnsVisible()}
            onChange={table?.getToggleAllColumnsVisibilityHandler()}
          />
          <ListItemText className="columnListItemText" primary="Select all" />
        </ListItem>
      </List> */}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable-grid"
          mode="virtual"
          direction="vertical"
          renderClone={(provided, __, rubric) => (
            <DragItem
              provided={provided}
              item={filteredColumns[rubric.source.index] as any}
              isColumnLimitReached={isColumnLimitReached}
              onChange={selectColumn as any}
            />
          )}>
          {(provided) => (
            <FixedSizeList
              height={Math.min(300, ITEM_SIZE * itemCount + 1)}
              itemCount={itemCount}
              itemSize={ITEM_SIZE}
              outerRef={provided.innerRef}
              itemData={{
                items: filteredColumns as any,
                isColumnLimitReached,
                onChange: selectColumn as any
              }}
              width="100%">
              {ReactWindowRow}
            </FixedSizeList>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default Columns;
