import React from "react";

const useAutoSave = (
  callback: () => void,
  delay: number = 1000,
  deps: React.DependencyList = []
) => {
  const savedCallback = React.useRef<() => void>();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function runCallback() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (typeof delay === "number") {
      let interval = setInterval(runCallback, delay);
      return () => clearInterval(interval);
    }
  }, [delay, ...deps]);
};

export default useAutoSave;
