import React from "react";

export const useCtrlKeyPress = () => {
  const [isCtrlPressed, setIsCtrlPressed] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Meta" || e.key === "Control") {
        setIsCtrlPressed(true);
      }
    });

    document.addEventListener("keyup", (e) => {
      if (e.key === "Meta" || e.key === "Control") {
        setIsCtrlPressed(false);
      }
    });
  }, []);

  return { isCtrlPressed };
};
