import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";
import { AppTemplate } from "src/pages/DataApps/DataApps.type";

const QUERY_KEY_APP_TEMPLATES = "query-key-app-templates";
const useGetAppTemplates = (
  options?: UseQueryOptions<AppTemplate[]>
): UseQueryResult<AppTemplate[]> => {
  return useQuery<AppTemplate[]>({
    queryKey: [QUERY_KEY_APP_TEMPLATES],
    queryFn: async () => {
      return await getAPIWithRethrow("/app-templates");
    },
    ...options
  });
};

export default useGetAppTemplates;
