import React from "react";
import _ from "lodash";
import { InfoOutlined } from "@material-ui/icons";
import { InputLabel, TextField, Tooltip } from "@material-ui/core";

import { dataAppConfigFields } from "./CreateDataAppForm";

interface IProps {
  values: Record<string, string | undefined | number>;
  readOnly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DataAppModelControls: React.FC<IProps> = (props) => {
  const { values, readOnly, onChange } = props;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <InputLabel disabled={readOnly}>
        Model Controls{" "}
        <Tooltip title="Set of instructions given at the beginning of a conversation to provide context and guide the AI's behavior and tone, ensuring responses are aligned with the desired purpose">
          <InfoOutlined style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }} />
        </Tooltip>
      </InputLabel>
      <TextField
        disabled={readOnly}
        data-testid="systemMessage"
        label="System Message"
        name={dataAppConfigFields.systemMessage}
        value={_.get(values, dataAppConfigFields.systemMessage) ?? ""}
        multiline
        maxRows={10}
        minRows={10}
        variant="outlined"
        onChange={onChange}
      />
    </div>
  );
};

export default DataAppModelControls;
