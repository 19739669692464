enum EVENTBUS_EVENTS {
  RecordNotFound = "RECORD-NOT-FOUND",
  AccessDenied = "ACCESS-DENIED",
  OpenFilterDropdownDataApp = "OPEN-FILTER-DROWN-DATAAPP",
  ShowLogDetails = "SHOW-LOG-DETAILS",
  TableColumnFilterChanged = "TABLE_COLUMN_FILTER_CHANGED",
  TableSearchChanged = "TABLE_SEARCH_CHANGED",
  ClearTableSearch = "CLEAR_TABLE_SEARECH",
  DownloadFilterdTable = "DOWNLOAD_FILTERED_TABLE",
  DownloadFilterdTableComplete = "DOWNLOAD_FILTERED_TABLE_COMPLETE"
}

export { EVENTBUS_EVENTS };
