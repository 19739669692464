class Environment {
  static LOCALHOST = "localhost";

  isLocalhost = () => {
    const hostname = window.location.hostname;
    return hostname === Environment.LOCALHOST;
  };

  isProduction = () => {
    return !this.isLocalhost();
  };

  getEnvironment = () => {
    const hostname = window.location.hostname;
    return this.isLocalhost() ? Environment.LOCALHOST : hostname.split(".")[0];
  };
}

const env = new Environment();
export default env;
