/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { EDAInfo } from './edainfo';
// May contain unused imports in some cases
// @ts-ignore
import type { PipelineTestResultPerTransformMsgsInnerValueInner } from './pipeline-test-result-per-transform-msgs-inner-value-inner';

/**
 * 
 * @export
 * @interface EntityEDAResult
 */
export interface EntityEDAResult {
    /**
     * 
     * @type {string}
     * @memberof EntityEDAResult
     */
    'status'?: EntityEDAResultStatusEnum;
    /**
     * 
     * @type {Array<PipelineTestResultPerTransformMsgsInnerValueInner>}
     * @memberof EntityEDAResult
     */
    'messages'?: Array<PipelineTestResultPerTransformMsgsInnerValueInner>;
    /**
     * 
     * @type {string}
     * @memberof EntityEDAResult
     */
    'responseEntity'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityEDAResult
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityEDAResult
     */
    'jsonUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityEDAResult
     */
    'html'?: string;
    /**
     * 
     * @type {EDAInfo}
     * @memberof EntityEDAResult
     */
    'edaInfo'?: EDAInfo;
}

export const EntityEDAResultStatusEnum = {
    Skipped: 'SKIPPED',
    Success: 'SUCCESS',
    Failure: 'FAILURE'
} as const;

export type EntityEDAResultStatusEnum = typeof EntityEDAResultStatusEnum[keyof typeof EntityEDAResultStatusEnum];


