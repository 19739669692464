import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { ScenarioDto, UpdateScenarioDto } from "openapi/Models";

const useUpdateScenario = (): UseMutationResult<ScenarioDto, unknown, UpdateScenarioDto, unknown> =>
  useMutation({
    mutationFn: async (payload: UpdateScenarioDto) =>
      await api.fetchResponse(async () => await api.ScenarioControllerApi.update2({ ...payload }))
  });

export default useUpdateScenario;
