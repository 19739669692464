import React from "react";

import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import SubTopNavBarBreadcrumbs from "./SubTopNavBarBreadcrumbs";
import { useDatasetContext } from "../../contexts/Dataset/useDatasetContext";

type Props = {
  datasetIsResettingStore: boolean;
};

const Header = (props: Props) => {
  const { datasetIsResettingStore } = props || {};

  const { scenarioId, project, dataset, navigateBackWithoutRedirect } = useDatasetContext();

  return (
    <SubTopNavBarWrapper
      subTopNavBarLeftSection={{
        ...(project?.id
          ? {
              backNavAction: navigateBackWithoutRedirect,
              isBackNavActionDisabled: datasetIsResettingStore
            }
          : {}),
        component: (
          <SubTopNavBarBreadcrumbs
            scenarioId={scenarioId}
            project={project}
            dataset={dataset}
            navigateBack={navigateBackWithoutRedirect}
          />
        )
      }}
    />
  );
};

export default Header;
