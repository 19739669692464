import React from "react";

import { Button, makeStyles } from "@material-ui/core";

// import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

import { DatasetHelperText } from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import useFilesObserver from "../../hooks/useFilesObserver";
import FilesUploadProgress from "../FilesUploadProgress";
import { useUploadContext } from "../../contexts/Upload/useUploadContext";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 150
  },
  buttonMarginLeft: {
    extend: "button",
    marginLeft: theme.spacing(1)
  }
}));

const LocalFilesActions = () => {
  const { upload } = useUploadContext();

  const classes = useStyles();

  // Stores - STARTS >>
  const {
    // setDatasetIsOntologyStore,
    datasetFooterActionsStore
  } = useStoreSelectors();
  // << ENDS - Stores

  const { isFilesUploading } = useFilesObserver();

  return (
    <>
      {!datasetFooterActionsStore.upload.isHidden && (
        <Button
          id="datasetActionUpload"
          variant="contained"
          color="primary"
          className={classes.buttonMarginLeft}
          disabled={datasetFooterActionsStore.upload.isDisabled}
          onClick={() => upload()}
          startIcon={<FilesUploadProgress />}>
          {isFilesUploading ? DatasetHelperText.Uploading : DatasetHelperText.Upload}
        </Button>
      )}
    </>
  );
};

export default LocalFilesActions;
