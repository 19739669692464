import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPI } from "src/utils/apiService";

export const CACHE_QUERY_KEY_FETCH_DATASET_APPEND_FILE = "fetch-dataset-append-file-name";

const useAppendFileName = (
  parentId?: string,
  options?: UseQueryOptions<any, Error>
): UseQueryResult<any, Error> => {
  return useQuery<any, Error>({
    queryKey: [CACHE_QUERY_KEY_FETCH_DATASET_APPEND_FILE, parentId],
    queryFn: async () => {
      if (!parentId) {
        throw "Parent id is Required!";
      }
      const response = await getAPI(
        `/v2/data-files?parentType=ENTITY&subType=USER&parentId=${parentId}`
      );
      return response;
    },
    ...options
  });
};
export default useAppendFileName;
