/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EDAChartsInfo
 */
export interface EDAChartsInfo {
    /**
     * 
     * @type {string}
     * @memberof EDAChartsInfo
     */
    'status'?: EDAChartsInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EDAChartsInfo
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof EDAChartsInfo
     */
    'startTime'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EDAChartsInfo
     */
    'chartJson'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EDAChartsInfo
     */
    'threadId'?: string;
    /**
     * 
     * @type {number}
     * @memberof EDAChartsInfo
     */
    'noOfCharts'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EDAChartsInfo
     */
    'executedCodes'?: Array<string>;
}

export const EDAChartsInfoStatusEnum = {
    NotStarted: 'NOT_STARTED',
    Started: 'STARTED',
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;

export type EDAChartsInfoStatusEnum = typeof EDAChartsInfoStatusEnum[keyof typeof EDAChartsInfoStatusEnum];


