import React, { useMemo } from "react";

import { Grid, LinearProgress, Typography, makeStyles, withStyles } from "@material-ui/core";

interface IProps {
  percentage: string;
  title: string;
  icon: React.ReactNode;
  disabled?: boolean;
  isNewTheme?: boolean;
}

const borderLinearProgressFn = ({ isNewTheme }: { isNewTheme: boolean }) =>
  withStyles(() => ({
    root: {
      height: 15,
      borderRadius: 7.5
    },
    colorPrimary: {
      backgroundColor: isNewTheme ? "#d5d5f2" : "#baddf2"
    },
    bar: {
      borderRadius: 5,
      backgroundColor: isNewTheme ? "#4646b5" : "#1a90ff"
    }
  }))(LinearProgress);

const useStyles = makeStyles(() => ({
  root: {
    rowGap: 10
  },
  titleContainer: ({ disabled, isNewTheme }: any) => ({
    marginBottom: 10,
    flexWrap: "nowrap",
    columnGap: 5,
    color: isNewTheme ? "#4646b5" : "#008FE4",
    alignItems: "center",

    "& .MuiTypography-root": {
      color: disabled ? "#cac8c8" : ""
    }
  }),
  progressBarDetailsContainer: ({ disabled }: any) => ({
    marginTop: 5,
    "& .MuiTypography-body2": {
      fontWeight: "400"
    },
    "& .MuiTypography-root": {
      color: disabled ? "#cac8c8" : ""
    }
  }),
  wrap: {
    cursor: ({ disabled }: any) => (disabled ? "not-allowed" : "default")
  }
}));

export const CustomLinearProgress = ({
  percentage,
  disabled,
  title,
  icon,
  isNewTheme = false
}: IProps) => {
  const classes: $TSFixMe = useStyles({ disabled, isNewTheme });

  const formattedPercentage = useMemo(
    () => Number(parseFloat(percentage).toFixed(2)),
    [percentage]
  );

  const BorderLinearProgress = borderLinearProgressFn({ isNewTheme });

  return (
    <div className={classes.wrap}>
      <Grid container direction="row" className={classes.titleContainer}>
        {icon}
        <Typography variant="body2" display="inline">
          {title}
        </Typography>
      </Grid>
      <BorderLinearProgress variant="determinate" value={formattedPercentage} />
      <Grid
        container
        justifyContent="space-between"
        className={classes.progressBarDetailsContainer}>
        <div>
          <Typography variant="caption" color="primary" display="inline">
            Used
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            display="inline">{` ${formattedPercentage}% of 100%`}</Typography>
        </div>
        <div>
          <Typography variant="caption" color="primary" display="inline">
            Remaining
          </Typography>
          <Typography variant="body2" color="primary" display="inline">{` ${(
            100 - formattedPercentage
          )?.toFixed(2)}%`}</Typography>
        </div>
      </Grid>
    </div>
  );
};

export default CustomLinearProgress;
