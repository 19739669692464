import { useState } from "react";

import { switchTenantUserWithRethrow } from "../api-requests/user.api-requests";

import { postAPIWithRethrow } from "src/utils/apiService";
import { logNotebooksOut } from "src/utils/operateNotebookService";

import { useProjectsStore } from "src/store/store";
import { shouldRefreshProjectsToggler } from "src/store/store.selectors";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";

export const useFetchTenantActions = () => {
  const [switchingTenantId, setSwitchingTenantId] = useState("");

  const { setAuthResponse } = useAuthSetAuthResponse();

  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);

  const updateAuthStore = (authObj: $TSFixMe, rememberMe: boolean) => {
    setAuthResponse({ data: authObj, rememberMe });
    toggleShouldProjectsRefresh();
  };

  const switchTenant = async (tenantId: $TSFixMe, redirectUrl?: string) => {
    setSwitchingTenantId(tenantId);
    try {
      const { token: switchToken } = await switchTenantUserWithRethrow({ tenantId });

      const tokenFromLocalStorage = localStorage.getItem("token");
      const tokenFromSessionStorage = sessionStorage.getItem("token");

      const tokenFromStorage = tokenFromSessionStorage || tokenFromLocalStorage;

      const response = await postAPIWithRethrow("/token/validation", {
        token: switchToken
      });

      updateAuthStore(response, !!tokenFromStorage);

      // $FixMe: This needs to be centralized.
      await logNotebooksOut();
      setSwitchingTenantId("");

      if (redirectUrl) {
        window.location.href = redirectUrl;
        window.history.pushState(null, "", redirectUrl);
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error({ error });
      setSwitchingTenantId("");
    }
  };

  const leaveTenantAndSwitch = async (id: $TSFixMe, redirectUrl?: string) => {
    try {
      const response =
        // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
        await postAPIWithRethrow(`/tenants/leave-current/switch/${id}`);

      const tokenFromLocalStorage = localStorage.getItem("token");
      const tokenFromSessionStorage = sessionStorage.getItem("token");

      const tokenFromStorage = tokenFromSessionStorage || tokenFromLocalStorage;

      setAuthResponse({ data: response, rememberMe: !!tokenFromStorage });
      await logNotebooksOut();
      if (redirectUrl) {
        window.location.href = redirectUrl;
        window.history.pushState(null, "", redirectUrl);
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error({ error });
    }
  };

  return { switchingTenantId, switchTenant, leaveTenantAndSwitch };
};
