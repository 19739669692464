import React from "react";

export const UserGuideIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 2} ${height + 2}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.92578 16.875H16.0742C17.3666 16.875 18.418 15.8236 18.418 14.5312V2.34375C18.418 1.05141 17.3666 0 16.0742 0H3.92578C2.63344 0 1.58203 1.05141 1.58203 2.34375V17.6562C1.58203 18.9486 2.63344 20 3.92578 20H15.293H16.0742C17.3666 20 18.418 18.9486 18.418 17.6562V17.6543C17.7646 18.1458 16.9529 18.4375 16.0742 18.4375H15.293H3.92578C3.495 18.4375 3.14453 18.087 3.14453 17.6562C3.14453 17.2255 3.495 16.875 3.92578 16.875ZM3.14453 2.34375C3.14453 1.91297 3.495 1.5625 3.92578 1.5625H16.0742C16.505 1.5625 16.8555 1.91297 16.8555 2.34375V14.5312C16.8555 14.962 16.505 15.3125 16.0742 15.3125H3.92578C3.65199 15.3125 3.38898 15.3597 3.14453 15.4464V2.34375ZM8.69141 6.58516H7.12891C7.12891 5.78367 7.47242 5.01652 8.07141 4.48031C8.67824 3.93703 9.49457 3.67734 10.3112 3.76805C11.6196 3.91324 12.6731 4.96402 12.8163 6.26664C12.9469 7.45469 12.2502 8.30719 11.7147 8.84508C11.5769 8.98352 11.4497 9.1048 11.3375 9.21187C10.827 9.69875 10.762 9.78063 10.762 10.117V10.1562H9.19945V10.117C9.19945 9.09172 9.69055 8.6234 10.2592 8.08109C10.3696 7.97574 10.4839 7.8668 10.6073 7.74277C11.1119 7.23586 11.308 6.84547 11.2632 6.43734C11.1992 5.8557 10.7264 5.38621 10.1389 5.32102C9.75824 5.27891 9.39387 5.39359 9.11359 5.64453C8.84133 5.8882 8.69141 6.22227 8.69141 6.58516ZM9.19922 11.3281H10.7617V12.8906H9.19922V11.3281Z"
      fill={color}
    />
  </svg>
);
