import { LinkProps, Typography, TypographyProps, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import OverflowTooltip from "../OverflowTooltip";
import Text from "components/Widget/Text";
import { useCardContext } from "./CardContext";

const useStyles = makeStyles({
  titleText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
    overflow: "hidden",
    color: ({ isHovered }: any) => (isHovered ? "#4646b5" : "#181520")
  }
});

interface CardTitleProps extends TypographyProps {
  title: string;
  link?: string;
  linkProps?: LinkProps;
  dataTestId?: string;
  highlightOnHover?: boolean;
}

export const CardTitle = ({
  title,
  link,
  highlightOnHover,
  linkProps,
  dataTestId,
  ...props
}: CardTitleProps) => {
  const { isHovered } = useCardContext();
  const classes = useStyles({ isHovered: highlightOnHover && isHovered });
  const titleComponent = (
    <Typography component="span" variant="h6" className={classes.titleText} {...props}>
      <OverflowTooltip
        title={title}
        value={<Text dataTestId={dataTestId} style={{ whiteSpace: "nowrap" }} value={title} />}
      />
    </Typography>
  );
  return link ? (
    <Link onClick={(event) => event.stopPropagation()} to={link} {...linkProps}>
      {titleComponent}
    </Link>
  ) : (
    titleComponent
  );
};
