import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  canvasContainer: {
    height: "100%",
    backgroundColor: "#fbfcfc"
  },
  fadeIn: {
    webkitAnimation: "fade-in 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both",
    animation: "fade-in 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both"
  },
  fadeOut: {
    webkitAnimation: "fade-out 1s ease-out both",
    animation: "fade-out 1s ease-out both"
  },

  flowContainer: {
    "& div[class~='react-flow__handle']": {
      height: 8,
      width: 8
    },
    "& div[class~='react-flow__handle-right']": {
      right: -5
    },
    "& div[class~='react-flow__handle-left']": {
      left: -5
    }
  },
  actionsContainer: {
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 5,
    display: "flex",
    alignItems: "center",
    gap: 12,
    fontSize: 12
  },
  minimap: {
    left: 20,
    bottom: "55px !important",
    width: 120,
    height: 92,
    border: "4px solid #a7b4bf",
    borderRadius: 8,
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)"
  },
  controlsFlow: {
    display: "flex",
    flexDirection: "row-reverse",
    boxShadow: "none !important",
    "& button": {
      background: "transparent",
      border: "none",
      "& svg": {
        stroke: theme.palette.text.primary
      },
      "&.react-flow__controls-fitview": {
        "& svg": {
          stroke: theme.palette.info.main
        }
      }
    }
  },
  footerContainer: {
    position: "absolute",
    right: 20,
    bottom: 20,
    zIndex: 5
  }
}));

export default useStyles;
