import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const CollapseAllIcon: React.FC<IProps> = (props) => {
  const {
    width = 16,
    height = 16,
    viewBox = "0 0 16 16",
    fill = "none",
    color = "currentColor"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.76427 0C1.18052 0 0.705478 0.475044 0.705478 1.05879V10.9409C0.705478 11.5253 1.18052 11.9997 1.76427 11.9997H9.17582V0H1.76427ZM4.75813 3.63125L5.25726 4.13038L3.74212 5.64552H7.83125V6.3514H3.74212L5.25725 7.86654L4.75812 8.36568L2.3909 5.99846L4.75813 3.63125ZM10.5879 12H9.88203V0.00035H10.5879V12Z"
        fill={color}
      />
    </svg>
  );
};

export default CollapseAllIcon;
