/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreatePredictionServiceDto } from '../Models';
// @ts-ignore
import type { EntityDataDto } from '../Models';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { ModelDto } from '../Models';
// @ts-ignore
import type { ModelListDto } from '../Models';
// @ts-ignore
import type { PredictLogDto } from '../Models';
// @ts-ignore
import type { PredictionServiceDto } from '../Models';
// @ts-ignore
import type { SignedUrlResult } from '../Models';
// @ts-ignore
import type { UpdatePredictionServiceDto } from '../Models';
/**
 * PredictionServiceControllerApi - axios parameter creator
 * @export
 */
export const PredictionServiceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreatePredictionServiceDto} createPredictionServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPredictionService: async (createPredictionServiceDto: CreatePredictionServiceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPredictionServiceDto' is not null or undefined
            assertParamExists('createPredictionService', 'createPredictionServiceDto', createPredictionServiceDto)
            const localVarPath = `/v2/prediction-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPredictionServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel: async (name: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteModel', 'name', name)
            const localVarPath = `/v2/prediction-services/model/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelFile: async (name: string, path: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('deleteModelFile', 'name', name)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('deleteModelFile', 'path', path)
            const localVarPath = `/v2/prediction-services/contents/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePredictionServices: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePredictionServices', 'id', id)
            const localVarPath = `/v2/prediction-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expandLogs: async (id: string, runId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('expandLogs', 'id', id)
            // verify required parameter 'runId' is not null or undefined
            assertParamExists('expandLogs', 'runId', runId)
            const localVarPath = `/v2/prediction-services/{id}/expand-logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (runId !== undefined) {
                localVarQueryParameter['runId'] = runId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPredictionServiceById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPredictionServiceById', 'id', id)
            const localVarPath = `/v2/prediction-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPredictionServices: async (name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/prediction-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {string} [folder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadSignedUrl: async (path: string, folder?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getDownloadSignedUrl', 'path', path)
            const localVarPath = `/v2/prediction-services/download-signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (folder !== undefined) {
                localVarQueryParameter['folder'] = folder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLogs1', 'id', id)
            const localVarPath = `/v2/prediction-services/{id}/logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDetails: async (name: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getModelDetails', 'name', name)
            const localVarPath = `/v2/prediction-services/model-details/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDownloadSignedUrl: async (name: string, path: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getModelDownloadSignedUrl', 'name', name)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getModelDownloadSignedUrl', 'path', path)
            const localVarPath = `/v2/prediction-services/models/download-signed-url/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModels: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/prediction-services/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelsUploadSignedUrl: async (name: string, path: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getModelsUploadSignedUrl', 'name', name)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getModelsUploadSignedUrl', 'path', path)
            const localVarPath = `/v2/prediction-services/models/signed-url/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrl1: async (id: string, path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUploadSignedUrl1', 'id', id)
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getUploadSignedUrl1', 'path', path)
            const localVarPath = `/v2/prediction-services/{id}/signed-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrl2: async (path: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getUploadSignedUrl2', 'path', path)
            const localVarPath = `/v2/prediction-services/signed-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePredictionServiceDto} updatePredictionServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePredictionService: async (id: string, updatePredictionServiceDto: UpdatePredictionServiceDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePredictionService', 'id', id)
            // verify required parameter 'updatePredictionServiceDto' is not null or undefined
            assertParamExists('updatePredictionService', 'updatePredictionServiceDto', updatePredictionServiceDto)
            const localVarPath = `/v2/prediction-services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePredictionServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredictionServiceControllerApi - functional programming interface
 * @export
 */
export const PredictionServiceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PredictionServiceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreatePredictionServiceDto} createPredictionServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPredictionService(createPredictionServiceDto: CreatePredictionServiceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictionServiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPredictionService(createPredictionServiceDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.createPredictionService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModel(name: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModel(name, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.deleteModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModelFile(name: string, path: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModelFile(name, path, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.deleteModelFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePredictionServices(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePredictionServices(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.deletePredictionServices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expandLogs(id: string, runId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictLogDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expandLogs(id, runId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.expandLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPredictionServiceById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PredictionServiceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPredictionServiceById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.findPredictionServiceById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPredictionServices(name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PredictionServiceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPredictionServices(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.findPredictionServices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {string} [folder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadSignedUrl(path: string, folder?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadSignedUrl(path, folder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.getDownloadSignedUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogs1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogs1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.getLogs1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelDetails(name: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelDetails(name, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.getModelDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelDownloadSignedUrl(name: string, path: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelDownloadSignedUrl(name, path, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.getModelDownloadSignedUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModels(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModels(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.getModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelsUploadSignedUrl(name: string, path: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelsUploadSignedUrl(name, path, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.getModelsUploadSignedUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadSignedUrl1(id: string, path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadSignedUrl1(id, path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.getUploadSignedUrl1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadSignedUrl2(path: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadSignedUrl2(path, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.getUploadSignedUrl2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePredictionServiceDto} updatePredictionServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePredictionService(id: string, updatePredictionServiceDto: UpdatePredictionServiceDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PredictionServiceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePredictionService(id, updatePredictionServiceDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictionServiceControllerApi.updatePredictionService']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PredictionServiceControllerApi - factory interface
 * @export
 */
export const PredictionServiceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PredictionServiceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreatePredictionServiceDto} createPredictionServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPredictionService(createPredictionServiceDto: CreatePredictionServiceDto, options?: any): AxiosPromise<PredictionServiceDto> {
            return localVarFp.createPredictionService(createPredictionServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModel(name: string, version?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteModel(name, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelFile(name: string, path: string, version?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteModelFile(name, path, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePredictionServices(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePredictionServices(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} runId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expandLogs(id: string, runId: string, options?: any): AxiosPromise<PredictLogDto> {
            return localVarFp.expandLogs(id, runId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPredictionServiceById(id: string, options?: any): AxiosPromise<Array<PredictionServiceDto>> {
            return localVarFp.findPredictionServiceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPredictionServices(name?: string, options?: any): AxiosPromise<Array<PredictionServiceDto>> {
            return localVarFp.findPredictionServices(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} path 
         * @param {string} [folder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadSignedUrl(path: string, folder?: string, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.getDownloadSignedUrl(path, folder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs1(id: string, options?: any): AxiosPromise<EntityDataDto> {
            return localVarFp.getLogs1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDetails(name: string, version?: string, options?: any): AxiosPromise<ModelDto> {
            return localVarFp.getModelDetails(name, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelDownloadSignedUrl(name: string, path: string, version?: string, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.getModelDownloadSignedUrl(name, path, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModels(options?: any): AxiosPromise<ModelListDto> {
            return localVarFp.getModels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} path 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelsUploadSignedUrl(name: string, path: string, version?: string, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.getModelsUploadSignedUrl(name, path, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrl1(id: string, path: string, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.getUploadSignedUrl1(id, path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrl2(path: string, options?: any): AxiosPromise<SignedUrlResult> {
            return localVarFp.getUploadSignedUrl2(path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdatePredictionServiceDto} updatePredictionServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePredictionService(id: string, updatePredictionServiceDto: UpdatePredictionServiceDto, options?: any): AxiosPromise<PredictionServiceDto> {
            return localVarFp.updatePredictionService(id, updatePredictionServiceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PredictionServiceControllerApi - interface
 * @export
 * @interface PredictionServiceControllerApi
 */
export interface PredictionServiceControllerApiInterface {
    /**
     * 
     * @param {CreatePredictionServiceDto} createPredictionServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    createPredictionService(createPredictionServiceDto: CreatePredictionServiceDto, options?: RawAxiosRequestConfig): AxiosPromise<PredictionServiceDto>;

    /**
     * 
     * @param {string} name 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    deleteModel(name: string, version?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} name 
     * @param {string} path 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    deleteModelFile(name: string, path: string, version?: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    deletePredictionServices(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {string} runId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    expandLogs(id: string, runId: string, options?: RawAxiosRequestConfig): AxiosPromise<PredictLogDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    findPredictionServiceById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PredictionServiceDto>>;

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    findPredictionServices(name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PredictionServiceDto>>;

    /**
     * 
     * @param {string} path 
     * @param {string} [folder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    getDownloadSignedUrl(path: string, folder?: string, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    getLogs1(id: string, options?: RawAxiosRequestConfig): AxiosPromise<EntityDataDto>;

    /**
     * 
     * @param {string} name 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    getModelDetails(name: string, version?: string, options?: RawAxiosRequestConfig): AxiosPromise<ModelDto>;

    /**
     * 
     * @param {string} name 
     * @param {string} path 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    getModelDownloadSignedUrl(name: string, path: string, version?: string, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    getModels(options?: RawAxiosRequestConfig): AxiosPromise<ModelListDto>;

    /**
     * 
     * @param {string} name 
     * @param {string} path 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    getModelsUploadSignedUrl(name: string, path: string, version?: string, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

    /**
     * 
     * @param {string} id 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    getUploadSignedUrl1(id: string, path: string, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

    /**
     * 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    getUploadSignedUrl2(path: string, options?: RawAxiosRequestConfig): AxiosPromise<SignedUrlResult>;

    /**
     * 
     * @param {string} id 
     * @param {UpdatePredictionServiceDto} updatePredictionServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApiInterface
     */
    updatePredictionService(id: string, updatePredictionServiceDto: UpdatePredictionServiceDto, options?: RawAxiosRequestConfig): AxiosPromise<PredictionServiceDto>;

}

/**
 * PredictionServiceControllerApi - object-oriented interface
 * @export
 * @class PredictionServiceControllerApi
 * @extends {BaseAPI}
 */
export class PredictionServiceControllerApi extends BaseAPI implements PredictionServiceControllerApiInterface {
    /**
     * 
     * @param {CreatePredictionServiceDto} createPredictionServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public createPredictionService(createPredictionServiceDto: CreatePredictionServiceDto, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).createPredictionService(createPredictionServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public deleteModel(name: string, version?: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).deleteModel(name, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} path 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public deleteModelFile(name: string, path: string, version?: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).deleteModelFile(name, path, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public deletePredictionServices(id: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).deletePredictionServices(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} runId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public expandLogs(id: string, runId: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).expandLogs(id, runId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public findPredictionServiceById(id: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).findPredictionServiceById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public findPredictionServices(name?: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).findPredictionServices(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} path 
     * @param {string} [folder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public getDownloadSignedUrl(path: string, folder?: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).getDownloadSignedUrl(path, folder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public getLogs1(id: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).getLogs1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public getModelDetails(name: string, version?: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).getModelDetails(name, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} path 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public getModelDownloadSignedUrl(name: string, path: string, version?: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).getModelDownloadSignedUrl(name, path, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public getModels(options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).getModels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} path 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public getModelsUploadSignedUrl(name: string, path: string, version?: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).getModelsUploadSignedUrl(name, path, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public getUploadSignedUrl1(id: string, path: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).getUploadSignedUrl1(id, path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public getUploadSignedUrl2(path: string, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).getUploadSignedUrl2(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdatePredictionServiceDto} updatePredictionServiceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionServiceControllerApi
     */
    public updatePredictionService(id: string, updatePredictionServiceDto: UpdatePredictionServiceDto, options?: RawAxiosRequestConfig) {
        return PredictionServiceControllerApiFp(this.configuration).updatePredictionService(id, updatePredictionServiceDto, options).then((request) => request(this.axios, this.basePath));
    }
}

