import React, { useEffect, useMemo } from "react";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import _ from "lodash";

import {
  DatasetKeys,
  separators,
  encodings,
  DatasetHelperText,
  Criteria
} from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import useHelpers from "../../hooks/useHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    columnGap: theme.spacing(2),
    "& div[class^='MuiFormControl-root']": {
      minWidth: 150
    },
    "& div[class^='MuiSelect-root']": {
      paddingTop: 9.5,
      paddingBottom: 9.5
    },
    "& .separatorEncodingInfo": {
      cursor: "pointer"
    }
  },
  encodingDropdownContainer: {
    maxHeight: "30%"
  }
}));

const ConfigContainer = (props: $TSFixMe) => {
  const { datasetDefaultDatasetStore, ontologyDatasetIndex, applyConfig } = props || {};

  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const { datasetCriterionStore, datasetDatasetsStore } = useStoreSelectors();
  // << ENDS - Stores

  const { updateDatasetsSession, isOntologyProcessing } = useHelpers({ ontologyDatasetIndex });

  const ontologyConfig = useMemo(
    () => datasetDatasetsStore[ontologyDatasetIndex]?.[DatasetKeys.OntologyConfig],
    [datasetDatasetsStore, ontologyDatasetIndex]
  );

  const onSeparatorChange = (event: $TSFixMe) => {
    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.IsDirty] = true;
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] = event?.target?.value;

    updateDatasetsSession({ index: ontologyDatasetIndex, fields });
    // << Update datasets in session
  };

  const onEncodingChange = (event: $TSFixMe) => {
    // Update datasets in session >>
    const fields: $TSFixMe = {};
    fields[DatasetKeys.IsDirty] = true;
    fields[DatasetKeys.OntologyConfig] = {};
    fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] = event?.target?.value;

    updateDatasetsSession({ index: ontologyDatasetIndex, fields });
    // << Update datasets in session
  };

  useEffect(() => {
    const selectedSeparator = ontologyConfig?.separator;

    const isFound = (separators || [])?.find(
      (eachSeparator: $TSFixMe) => eachSeparator?.value === selectedSeparator
    );

    if (!isFound) {
      // Update datasets in session >>
      const fields: $TSFixMe = {};
      fields[DatasetKeys.OntologyConfig] = {};
      fields[DatasetKeys.OntologyConfig][DatasetKeys.CustomSeparator] = selectedSeparator;

      updateDatasetsSession({ index: ontologyDatasetIndex, fields });
      // << Update datasets in session
    }
  }, [ontologyConfig?.separator]);

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item>
        <FormControl
          fullWidth
          variant="outlined"
          disabled={datasetDefaultDatasetStore?.id || isOntologyProcessing}>
          <InputLabel id="ontologyConfigSeparatorLabel">{DatasetHelperText.Separator}</InputLabel>
          <Select
            labelId="ontologyConfigSeparatorLabel"
            id="ontologyConfigSeparator"
            value={ontologyConfig?.separator}
            onChange={onSeparatorChange}
            label={DatasetHelperText.Separator}>
            {(separators || [])?.map((eachSeparator: $TSFixMe) => {
              return (
                <MenuItem
                  id={`ontologyConfigSeparatorOption_${eachSeparator?.id}`}
                  key={`ontologyConfigSeparatorOption_${eachSeparator?.id}`}
                  value={eachSeparator?.value}>
                  <Typography
                    id={`ontologyConfigSeparatorOptionName_${eachSeparator?.id}`}
                    color="primary">
                    {eachSeparator?.name}
                  </Typography>
                </MenuItem>
              );
            })}
            {!!ontologyConfig?.[DatasetKeys.CustomSeparator] && (
              <MenuItem
                id="ontologyConfigSeparatorOption_Custom"
                key="ontologyConfigSeparatorOption_Custom"
                value={ontologyConfig?.[DatasetKeys.CustomSeparator]}>
                <Typography id="ontologyConfigSeparatorOptionName_Custom" color="primary">
                  Custom{` (${ontologyConfig?.[DatasetKeys.CustomSeparator]})`}
                </Typography>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          variant="outlined"
          disabled={datasetDefaultDatasetStore?.id || isOntologyProcessing}>
          <InputLabel id="ontologyConfigEncodingLabel">{DatasetHelperText.Encoding}</InputLabel>
          <Select
            labelId="ontologyConfigEncodingLabel"
            id="ontologyConfigEncoding"
            value={ontologyConfig?.encoding}
            onChange={onEncodingChange}
            label={DatasetHelperText.Encoding}
            MenuProps={{ classes: { paper: classes.encodingDropdownContainer } }}>
            {_.sortBy(encodings || [], "name")?.map((eachEncoding: $TSFixMe) => {
              return (
                <MenuItem
                  id={`ontologyConfigEncodingOption_${eachEncoding?.id}`}
                  key={`ontologyConfigEncodingOption_${eachEncoding?.id}`}
                  value={eachEncoding?.value}>
                  <Typography
                    id={`ontologyConfigEncodingOptionName_${eachEncoding?.id}`}
                    color="primary">
                    {eachEncoding?.name}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          id="ontologyConfigApply"
          variant="outlined"
          color="primary"
          disabled={datasetDefaultDatasetStore?.id || isOntologyProcessing}
          onClick={applyConfig}>
          {DatasetHelperText.Apply}
        </Button>
      </Grid>
      {datasetDefaultDatasetStore?.id && (
        <Grid item>
          <Tooltip
            id="ontologyConfigAddFileAppendInfoTooltip"
            title={DatasetHelperText.AddFileAppendInfo || ""}>
            <InfoOutlinedIcon className="separatorEncodingInfo" fontSize="small" />
          </Tooltip>
        </Grid>
      )}
      {datasetCriterionStore?.value === Criteria.Merge && (
        <Grid item>
          <Tooltip
            id="ontologyConfigCriterionMergeInfoTooltip"
            title={DatasetHelperText.CriterionMergeOntologyInfo || ""}>
            <InfoOutlinedIcon className="separatorEncodingInfo" fontSize="small" />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfigContainer;
