import React from "react";

// Packages
import _ from "lodash";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";
import { RecipeTypesPathMapping } from "src/pages/private/ProjectsModule/utils/Projects.constants";

type Props = {
  project: $TSFixMe;
  scenarioId?: string;
  recipe?: $TSFixMe;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, scenarioId, recipe } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    !!project?.id && !!scenarioId && !!recipe?.id && !!recipe?.recipeType ? (
      <Link
        key="recipe"
        to={`/projects/${project?.id}/scenario/${scenarioId}/${_.get(
          RecipeTypesPathMapping,
          recipe?.recipeType
        )}/${recipe?.id}`}>
        <RenderText>{recipe?.displayName || recipe?.name || "Recipe"}</RenderText>
      </Link>
    ) : (
      <RenderText key="recipe" color="textSecondary">
        {recipe?.name || "Recipe"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    <RenderText key="logs" color="textSecondary">
      Logs
    </RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
