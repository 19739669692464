import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Grid, makeStyles } from "@material-ui/core";

import { useGetCanvas } from "src/hooks/api";
import { useAIGuideStore, useCanvasStore } from "src/store/store";
import { getNodes } from "src/store/store.selectors";
import AIGuideChatBox from "../common/AIGuideChatBox";
import AIGuideContainer from "../common/AIGuideContainer";
import Chart from "../common/Chart";
import ComponentNotFound from "src/components/Errors/ComponentNotFound";
import { useAIGuideContext } from "../common/useAIGuideContext";
import { ChartIconV3 } from "src/icons/NewUX/ChartIconV3";

import { ThreadResponseDto } from "openapi/Models/thread-response-dto";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";
import { Spinner } from "src/components";

const useStyles = makeStyles({
  icon: {
    height: "32px",
    width: "24px !important"
  }
});

type IProps = {
  suggestions: string[];
  thread: ThreadResponseDto;
  messages: AIChatResponseDto[];
};

const AIGuide = ({ suggestions, messages, thread }: IProps) => {
  const { projectId, scenarioId } = useAIGuideContext();

  const { threadId, entityId, targetInputs } = thread;
  const chartId = entityId!;
  const chartName = targetInputs?.[0]?.chartName!;
  const classes = useStyles();

  const nodes = useCanvasStore(getNodes);
  let selectedNode = nodes?.find(({ id }: { id: string }) => id === chartId);
  let chartList = eval(selectedNode?.viewData) || [];

  const [query, setQuery] = useState("");
  const [diamondName, setDiamondName] = useState(selectedNode?.displayName);
  const [chart, setChart] = useState(chartList?.find((chart: any) => chart.name === chartName));
  const [generatingState] = useAIGuideStore((state) => [state.generatingState]);
  const generateState = _.get(generatingState, threadId!);

  const { data: canvas, isFetching } = useGetCanvas({
    projectId,
    scenarioId,
    enabled: !chart
  });

  useEffect(() => {
    if (!isFetching && canvas) {
      const selectedNode = canvas?.nodes?.find(({ id }: { id: string }) => id === chartId);
      const chartList = eval(selectedNode?.viewData) || [];
      setDiamondName(selectedNode?.displayName);
      setChart(chartList?.find((chart: any) => chart.name === chartName));
    }
  }, [canvas, isFetching]);

  if (!scenarioId || !projectId) {
    return <ComponentNotFound />;
  }
  const chartTitle = chartName || chart?.name || "Chart";

  return (
    <AIGuideContainer
      query={query}
      projectId={projectId!}
      isLoading={isFetching || !chart}
      setQuery={setQuery}
      suggestions={suggestions}
      thread={thread}
      messages={messages}
      generateState={generateState}
      threadId={threadId!}>
      {isFetching || !chart ? (
        <Spinner />
      ) : (
        <AIGuideChatBox
          isExpandDisabled={false}
          isGenerating={generateState?.isGenerating || false}
          icon={
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              justifyContent="center"
              wrap="nowrap"
              className={classes.icon}>
              <ChartIconV3 />
            </Grid>
          }
          title={`${chartTitle} - ${diamondName}`}>
          <Chart
            name={chartTitle}
            dataFilePath={chart.dataFilePath}
            chartType={chart.chartType}
            isAIGuide
          />
        </AIGuideChatBox>
      )}
    </AIGuideContainer>
  );
};

export default React.memo(AIGuide);
