import React, { useEffect, useMemo, useState } from "react";

// Packages
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  filter,
  find,
  groupBy,
  isArray,
  isEmpty,
  isNil,
  map,
  mapValues,
  reverse,
  some,
  sortBy,
  uniq
} from "lodash";

// Open API
import { EntityDto, ProjectDashboardDto, ScenarioDto } from "openapi/Models";

// Utils
import { NodeTypes } from "../../../utils";
import { composeScenarioName } from "../utils/Scenario.helpers";

// Hooks
import {
  useGetDfsTemplates,
  useGetGlobalVariables,
  useGetProjectCanvas,
  useGetTransformGroups
} from "src/hooks/api";
import useGetScenarios from "src/hooks/api/scenarios/useGetScenarios";
import useGetDatasets from "src/hooks/api/entities/useGetDatasets";
import useActions from "../hooks/useActions";

// Stores
import { useCanvasStore, useProjectsStore, useScenariosStore } from "src/store/store";
import { projectsGetter } from "src/store/store.selectors";

// Contexts
import { ScenarioContext } from "./ScenarioContext";

// Types
import { Scenario as ScenarioType, Variable } from "../Scenario.type";

// Constants
import { defaultValues, ScenarioFormFields } from "../utils/Scenario.constants";

type Props = {
  children: React.ReactNode;
};

const ScenarioContextProvider = (props: Props) => {
  const { children } = props || {};

  const { projectId, scenarioId } = useParams() || {};

  // Stores - STARTS >>
  const projectsStore = useProjectsStore(projectsGetter);
  const nodesStore = useCanvasStore((state: $TSFixMe) => state?.nodes);
  const scenariosStore = useScenariosStore((state) => state.scenarios);
  // << ENDS - Stores

  // States - STARTS >>
  const [isAttemptedSubmit, setIsAttemptedSubmit] = useState(false);
  const [datasets, setDatasetsData] = useState<EntityDto[]>([]);
  // << ENDS - States

  const project = useMemo(
    () => find(projectsStore, (eachProject: ProjectDashboardDto) => eachProject?.id === projectId),
    [projectsStore, projectId]
  );

  // Query hooks - STARTS >>
  // Queries
  const { isLoading: isLoadingRecipes, data: recipesData } = useGetTransformGroups({
    projectId: projectId!,
    select: (data) => filter(data, (recipe) => !isEmpty(recipe.runConfigs)),
    enabled: !!projectId && !scenarioId
  });

  const { isLoading: isLoadingRecipeTemplates, data: recipeTemplatesData } = useGetDfsTemplates({
    projectId: projectId!,
    enabled: !!projectId && !scenarioId
  });

  const {
    isRefetching: isRefetchingScenarios,
    data: scenariosDataResponse,
    refetch: refetchScenarios
  } = useGetScenarios({
    projectId: projectId!,
    select: (data) => (!isEmpty(data) && isArray(data) ? data : []),
    enabled: false
  });

  const scenariosData = useMemo(
    () =>
      !isEmpty(scenariosStore)
        ? scenariosStore
        : !isEmpty(scenariosDataResponse)
          ? scenariosDataResponse
          : [],
    [scenariosStore, scenariosDataResponse]
  );

  const scenarioData = useMemo(
    () => find(scenariosData, { id: scenarioId }) || {},
    [scenariosData]
  );

  const {
    isRefetching: isRefetchingProjectCanvas,
    data: projectCanvasData,
    refetch: refetchProjectCanvas
  } = useGetProjectCanvas({
    projectId,
    scenarioId: scenarioId!,
    enabled: false
  });

  const isRecipeInProject = useMemo(() => {
    if (!isEmpty(nodesStore)) {
      return some(nodesStore, { type: NodeTypes.Recipe });
    }

    if (!isEmpty(projectCanvasData?.nodes)) {
      return some(projectCanvasData?.nodes, { type: NodeTypes.Recipe });
    }

    return false;
  }, [nodesStore, projectCanvasData]);

  const { isLoading: isLoadingDatasets, data: datasetsData } = useGetDatasets({
    projectId: projectId!
  });

  useEffect(() => {
    if (!isEmpty(datasetsData)) {
      setDatasetsData(() =>
        filter(
          datasetsData,
          (dataset: EntityDto) =>
            dataset?.entityMeta?.entityViewType !== NodeTypes.Chart && !isEmpty(dataset?.segments)
        )
      );
    }
  }, [datasetsData]);

  const { isLoading: isLoadingGlobalVariables, data: globalVariablesData } = useGetGlobalVariables({
    projectId: projectId!
  });
  // << ENDS - Query hooks

  useEffect(() => {
    if (isEmpty(scenariosStore)) {
      refetchScenarios();
    }
  }, [scenariosStore]);

  useEffect(() => {
    if (isEmpty(nodesStore)) {
      refetchProjectCanvas();
    }
  }, [nodesStore]);

  // Form - STARTS >>
  const formMethods = useForm<ScenarioType>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues
  });

  const { reset, getValues, setValue } = formMethods || {};

  const resetForm = (data: ScenarioDto) => {
    let variables: Variable[] = [];
    let groupedSegmentsByDataset: { [key: string]: string[] } = {};

    if (!!scenarioId) {
      if (!isNil(data?.sharedVariables)) {
        if (!isEmpty(data?.sharedVariables)) {
          variables = reverse(
            map(data?.sharedVariables, (value, key) => ({
              key,
              value
            }))
          );
        }
      }

      if (!isNil(data?.segments)) {
        if (!isEmpty(data?.segments)) {
          groupedSegmentsByDataset = mapValues(
            groupBy([...new Set(data?.segments)], "entityId"),
            (segments) => segments?.map((segment) => segment.id).filter((id): id is string => !!id)
          );
        }
      }
    }

    reset({
      ...getValues(),
      ...(!isEmpty(variables) ? { [ScenarioFormFields.Variables]: variables } : {}),
      [ScenarioFormFields.Name]: !!scenarioId ? data?.name : composeScenarioName(scenariosData),
      [ScenarioFormFields.Datasets]: map(
        sortBy(datasets, [(dataset) => dataset?.displayName || dataset?.name]),
        (dataset: EntityDto) => ({
          ...dataset,
          segments: sortBy(dataset?.segments, "name"),
          fullSegment: !!dataset?.id ? isEmpty(groupedSegmentsByDataset[dataset?.id]) : false,
          selectedSegments: !!dataset?.id ? uniq(groupedSegmentsByDataset[dataset?.id]) : [],
          searchQuery: ""
        })
      )
    });
  };

  useEffect(() => resetForm(scenarioData), [scenarioId, scenariosData, scenarioData, datasets]);
  // << ENDS - Form

  const { isSavingScenario, isRunning, saveScenario, updateScenario, onRun, onDisplayOnDag } =
    useActions({
      recipesData,
      recipeTemplatesData,
      resetForm,
      getValues,
      setValue,
      setIsAttemptedSubmit
    });

  const isReadOnly = useMemo(
    () => !!isSavingScenario || !!isRunning,
    [isSavingScenario, isRunning]
  );

  const isLoading = useMemo(() => {
    if (!!scenarioId) {
      return !!isRefetchingScenarios || !!isRefetchingProjectCanvas;
    } else {
      return !!isLoadingRecipes || !!isLoadingRecipeTemplates;
    }
  }, [
    scenarioId,
    isRefetchingScenarios,
    isRefetchingProjectCanvas,
    isLoadingRecipes,
    isLoadingRecipeTemplates
  ]);

  const isPendingSegments = useMemo(() => !!isLoadingDatasets, [isLoadingDatasets]);

  const isPendingVariables = useMemo(
    () => !!isLoadingDatasets || !!isLoadingGlobalVariables,
    [isLoadingDatasets, isLoadingGlobalVariables]
  );

  const value = useMemo(
    () => ({
      isLoading,
      isPendingSegments,
      isPendingVariables,
      isReadOnly,

      project,
      isRecipeInProject,

      globalVariablesData,

      formMethods,

      isSavingScenario,
      isRunning,
      saveScenario,
      updateScenario,
      onRun,
      onDisplayOnDag,

      isAttemptedSubmit,
      setIsAttemptedSubmit
    }),
    [
      isLoading,
      isPendingSegments,
      isPendingVariables,
      isReadOnly,

      project,
      isRecipeInProject,
      globalVariablesData,

      formMethods,

      isSavingScenario,
      isRunning,
      saveScenario,
      updateScenario,
      onRun,
      onDisplayOnDag,

      isAttemptedSubmit,
      setIsAttemptedSubmit
    ]
  );

  return <ScenarioContext.Provider value={value}>{children}</ScenarioContext.Provider>;
};

export default ScenarioContextProvider;
