import React from "react";

export const DashboardIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 2} ${height + 2}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.9">
      <path
        d="M1 3.66666C1 3.04668 1 2.7367 1.06815 2.48236C1.25308 1.79218 1.79218 1.25308 2.48236 1.06815C2.7367 1 3.04669 1 3.66667 1V1C4.28665 1 4.59664 1 4.85097 1.06815C5.54116 1.25308 6.08025 1.79218 6.26519 2.48236C6.33333 2.7367 6.33333 3.04669 6.33333 3.66667V5C6.33333 5.61998 6.33333 5.92997 6.26519 6.1843C6.08025 6.87449 5.54116 7.41358 4.85097 7.59852C4.59664 7.66667 4.28665 7.66667 3.66667 7.66667V7.66667C3.04669 7.66667 2.7367 7.66667 2.48236 7.59852C1.79218 7.41358 1.25308 6.87449 1.06815 6.1843C1 5.92997 1 5.61998 1 5V3.66666Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 12.3333C1 11.2288 1.89543 10.3333 3 10.3333H4.33333C5.4379 10.3333 6.33333 11.2288 6.33333 12.3333V12.3333C6.33333 13.4379 5.4379 14.3333 4.33333 14.3333H3C1.89543 14.3333 1 13.4379 1 12.3333V12.3333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3C9 1.89543 9.89543 1 11 1H12.3333C13.4379 1 14.3333 1.89543 14.3333 3V3C14.3333 4.10457 13.4379 5 12.3333 5H11C9.89543 5 9 4.10457 9 3V3Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 10.3333C9 9.71335 9 9.40336 9.06815 9.14903C9.25308 8.45884 9.79218 7.91975 10.4824 7.73482C10.7367 7.66667 11.0467 7.66667 11.6667 7.66667V7.66667C12.2866 7.66667 12.5966 7.66667 12.851 7.73482C13.5412 7.91975 14.0803 8.45884 14.2652 9.14903C14.3333 9.40336 14.3333 9.71335 14.3333 10.3333V11.6667C14.3333 12.2866 14.3333 12.5966 14.2652 12.851C14.0803 13.5412 13.5412 14.0803 12.851 14.2652C12.5966 14.3333 12.2866 14.3333 11.6667 14.3333V14.3333C11.0467 14.3333 10.7367 14.3333 10.4824 14.2652C9.79218 14.0803 9.25308 13.5412 9.06815 12.851C9 12.5966 9 12.2866 9 11.6667V10.3333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
