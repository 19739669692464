import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const SaveIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.66667 1H2C1.44772 1 1 1.44771 1 2V11C1 11.5523 1.44771 12 2 12H11C11.5523 12 12 11.5523 12 11V4.5C12 4.18524 11.8518 3.88885 11.6 3.7L8.26667 1.2C8.09357 1.07018 7.88304 1 7.66667 1Z"
      stroke={color}
      strokeWidth="0.7"
      strokeLinecap="round"
    />
    <path
      d="M2.5 1.5V1.5C2.5 2.32843 3.17157 3 4 3H5.5C6.32843 3 7 2.32843 7 1.5V1.5"
      stroke={color}
      strokeWidth="0.7"
      strokeLinecap="round"
    />
    <circle cx="6.5" cy="8.5" r="2" stroke={color} strokeWidth="0.7" />
  </svg>
);

export default SaveIcon;
