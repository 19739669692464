export const envMetadataFind = (environmentsTypes: $TSFixMe, env: $TSFixMe) => {
  if ((environmentsTypes || [])?.length === 0) {
    return {};
  }

  let output = environmentsTypes.find((type: $TSFixMe) => type?.name === env?.envType);

  if (Object.keys(output || {})?.length > 0) {
    return output;
  }

  return environmentsTypes.find(
    (type: $TSFixMe) => type?.cores === env?.cores && type?.memInMbs === env?.memInMbs
  );
};

export const imageToBase64 = (
  imgPath: string,
  setImageBase64: Function,
  height = 204,
  width = 164
) => {
  let canvas = document.createElement("canvas");
  let img = document.createElement("img");
  img.src = imgPath;
  img.crossOrigin = "anonymous"; // This is to enable CORS requests for the image reading from CDN.
  img.onload = function () {
    let context = canvas.getContext("2d");
    canvas.height = height;
    canvas.width = width;
    let hRatio = canvas.width / img.width;
    let vRatio = canvas.height / img.height;
    let ratio = Math.max(hRatio, vRatio);
    let centerShift_x = (canvas.width - img.width * ratio) / 2;
    let centerShift_y = (canvas.height - img.height * ratio) / 2;
    context?.clearRect(0, 0, canvas.width, canvas.height);
    context?.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      centerShift_x,
      centerShift_y,
      img.width * ratio,
      img.height * ratio
    );

    let dataURL = canvas.toDataURL("image/jpeg");
    setImageBase64(dataURL?.split(";base64,")[1]);
  };
};

export const exactImageToBase64 = (
  imgUrl: string,
  setImageBase64: Function,
  height = 204,
  width = 164
) => {
  var canvas: any = document.createElement("canvas");
  var img = new Image();
  img.src = imgUrl;
  img.onload = function () {
    let context = canvas.getContext("2d");
    canvas.height = height;
    canvas.width = width;
    let hRatio = canvas.width / img.width;
    let vRatio = canvas.height / img.height;
    let ratio = Math.max(hRatio, vRatio);
    let centerShift_x = (canvas.width - img.width * ratio) / 2;
    let centerShift_y = (canvas.height - img.height * ratio) / 2;
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      centerShift_x,
      centerShift_y,
      img.width * ratio,
      img.height * ratio
    );

    let dataURL = canvas.toDataURL("image/jpeg");
    canvas = null;
    setImageBase64(dataURL?.split(";base64,")[1]);
  };
};

export const getCoreSet = ({ initSet = [1], stepper = 2, maxValue = 12 } = {}) => {
  const outputAr: number[] = [...initSet];

  let counter = 0;
  while (counter < maxValue) {
    counter += stepper;

    outputAr.push(counter);
  }

  return outputAr;
};

export const getMemorySet = ({ initSet = [1], stepper = 2, maxValue = 48 } = {}) => {
  const outputAr: number[] = [...initSet];

  let counter = 0;
  while (counter < maxValue) {
    counter += stepper;

    outputAr.push(counter);
  }

  return outputAr;
};

export const getDiskSpaceSet = ({ initSet = [], stepper = 5, maxValue = 100 } = {}) => {
  const outputAr: number[] = [...initSet];

  let counter = 0;
  while (counter < maxValue) {
    counter += stepper;

    outputAr.push(counter);
  }

  return outputAr;
};
