import React, { useState } from "react";
import { Button, DialogActions, makeStyles } from "@material-ui/core";

export function useForm(initialFValues = {}, validateOnChange = false, validate = () => true) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e: $TSFixMe) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setValues({
        ...values,
        [name]: files[0]
      });
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "100%",
      paddingBottom: theme.spacing(1)
    }
  },
  actions: {
    padding: 0,
    marginTop: 20,
    position: "absolute",
    bottom: 0,
    right: 0
  }
}));

type OwnProps = {
  children?: React.ReactNode;
  autoComplete?: "off" | "on";
  cancelButtonLabel?: string;
  onCancel: $TSFixMeFunction;
  onSubmit: $TSFixMeFunction;
  handleCustomButton?: $TSFixMeFunction;
  hideActions?: boolean;
  hasCustomButton?: boolean;
  isSubmitDisabled?: boolean;
  submitButtonLabel?: string;
  customButtonLabel?: string;
};

// @ts-expect-error TS(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof Form.defaultProps;

export function Form({
  children,
  autoComplete = "off",
  cancelButtonLabel = "Cancel",
  onCancel,
  hideActions,
  submitButtonLabel = "Create",
  customButtonLabel,
  hasCustomButton,
  handleCustomButton,
  isSubmitDisabled,
  // @ts-expect-error TS(2339) FIXME: Property 'submitButtonDisabled' does not exist on ... Remove this comment to see the full error message
  submitButtonDisabled,
  ...rest
}: Props) {
  const classes = useStyles();
  return (
    <form className={classes.root} autoComplete={autoComplete} {...rest}>
      {children}
      {!hideActions && (
        <DialogActions className={classes.actions}>
          <Button onClick={onCancel} color="secondary" variant="outlined">
            {cancelButtonLabel}
          </Button>
          {hasCustomButton && (
            <Button
              onClick={handleCustomButton}
              color="secondary"
              variant="outlined"
              name="form-custom"
              type="submit">
              {customButtonLabel}
            </Button>
          )}
          {!submitButtonDisabled && (
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              name="form-submit"
              disabled={isSubmitDisabled}>
              {submitButtonLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </form>
  );
}

Form.defaultProps = {
  isSubmitDisabled: false,
  hasCustomButton: false,
  customButtonLabel: "",
  handleCustomButton: () => {}
};
