import React from "react";
import { makeStyles } from "@material-ui/core";
import { Lines } from "../";

const useStyles = makeStyles(() => ({
  primary: {
    color: "#113554",
    fontWeight: 500
  },
  secundary: {
    color: "#425e7f",
    fontSize: "0.9em"
  }
}));

type Props = {
  name?: string | React.ReactNode;
  description?: string | React.ReactNode | string[];
};

const FieldName = ({ name, description, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <div {...rest}>
      <span className={classes.primary}>{name}</span>
      <br />
      <span className={classes.secundary}>
        {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'boolean |... Remove this comment to see the full error message */}
        {description instanceof Array ? <Lines lines={description} type="small" /> : description}
      </span>
    </div>
  );
};

export default FieldName;
