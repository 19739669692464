import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const AiGuideFaceIcon = ({
  width = 128,
  height = 128,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10887_6645)">
        <path
          d="M106.875 70.834H86.4583C82.8135 70.6987 82.8163 65.468 86.4583 65.334H106.875C110.52 65.4692 110.517 70.7 106.875 70.834Z"
          fill="black"
        />
        <path
          d="M11.2873 62.651C11.1163 62.651 10.9433 62.635 10.7695 62.602C9.27751 62.3178 8.29851 60.878 8.58276 59.386C9.57601 54.1735 12.389 49.4473 16.5043 46.0778C24.719 39.1328 37.9483 39.133 46.1628 46.0778C50.2778 49.4475 53.091 54.1738 54.084 59.386C54.3683 60.878 53.3893 62.3178 51.8973 62.602C50.407 62.887 48.9655 61.9075 48.6813 60.4153C44.913 41.7745 17.7485 41.7858 13.986 60.4153C13.7345 61.7333 12.5813 62.651 11.2873 62.651Z"
          fill="black"
        />
        <path
          d="M2.7495 42.25C2.395 42.25 2.03775 42.1817 1.6975 42.0407C0.67 41.615 0 40.6123 0 39.5V10.9167C0 8.00075 1.134 5.25925 3.19325 3.1975C5.2255 1.165 8.04025 0 10.9168 0H47.6668C50.5433 0 53.358 1.165 55.3893 3.19625C57.4493 5.25925 58.5833 8.0005 58.5833 10.9167V19.0833C58.7138 24.9603 53.5258 30.1 47.6668 30H16.1393L4.69475 41.4445C4.1685 41.9707 3.465 42.25 2.7495 42.25ZM10.9168 5.5C9.48825 5.5 8.091 6.078 7.0835 7.0855C6.06275 8.1075 5.5 9.4685 5.5 10.9167V32.8607L13.0555 25.3053C13.5713 24.7898 14.2708 24.5 15 24.5H47.6668C50.5748 24.549 53.148 22.0018 53.0833 19.0833V10.9167C53.0833 9.46825 52.5205 8.10725 51.4988 7.08425C50.4925 6.078 49.0953 5.5 47.6668 5.5H10.9168Z"
          fill="black"
        />
        <path
          d="M125.251 42.25C124.535 42.25 123.832 41.9707 123.306 41.4445L111.861 30H80.3333C74.474 30.0998 69.2865 24.96 69.4168 19.0833V10.9167C69.4168 8.0005 70.5508 5.25925 72.6098 3.1975C74.642 1.165 77.4568 0 80.3333 0H117.083C119.96 0 122.775 1.165 124.806 3.19625C126.866 5.25925 128 8.00075 128 10.9167V39.5C128 40.6123 127.33 41.615 126.303 42.0407C125.962 42.1817 125.605 42.25 125.251 42.25ZM80.3333 5.5C78.9048 5.5 77.5075 6.078 76.5 7.0855C75.4795 8.10725 74.9168 9.46825 74.9168 10.9167V19.0833C74.8518 22.0018 77.4255 24.549 80.3333 24.5H113C113.729 24.5 114.429 24.7897 114.945 25.3055L122.5 32.8607V10.9167C122.5 9.4685 121.937 8.1075 120.915 7.08425C119.909 6.078 118.512 5.5 117.083 5.5H80.3333Z"
          fill="black"
        />
        <path
          d="M108.917 106.166H107.583V95.2144H110.958C114.421 95.2144 117.678 93.8676 120.139 91.4116C122.54 88.9896 123.917 85.6526 123.917 82.2561V62.0741C123.917 58.6764 122.54 55.3396 120.129 52.9101C117.676 50.4634 114.42 49.1159 110.958 49.1159H99.4167V41.5411C99.2815 37.8964 94.0507 37.8991 93.9167 41.5411V49.1159H82.375C78.9137 49.1159 75.6573 50.4634 73.1955 52.9196C70.7943 55.3394 69.4167 58.6759 69.4167 62.0741V82.2561C69.4167 85.6529 70.7938 88.9899 73.205 91.4221C75.6558 93.8676 78.9123 95.2144 82.375 95.2144H85.75V106.166H84.4167C73.894 106.166 65.3333 114.727 65.3333 125.25C65.3333 126.768 66.5645 128 68.0833 128H125.25C126.769 128 128 126.768 128 125.25C128 114.727 119.439 106.166 108.917 106.166ZM77.1003 87.5396C75.7125 86.1396 74.9167 84.2139 74.9167 82.2561V62.0741C74.9167 60.1161 75.7123 58.1916 77.0898 56.8034C78.5035 55.3926 80.3805 54.6159 82.375 54.6159H110.958C112.953 54.6159 114.83 55.3926 116.234 56.7939C117.621 58.1919 118.416 60.1164 118.416 62.0741V82.2561C118.416 84.2136 117.62 86.1394 116.243 87.5289C114.831 88.9384 112.954 89.7144 110.958 89.7144H82.375C80.3792 89.7144 78.5023 88.9384 77.1003 87.5396ZM91.25 95.3329H102.083V106.166H91.25V95.3329ZM71.113 122.5C72.3877 116.323 77.8685 111.666 84.4167 111.666H88.5H104.833H108.917C115.465 111.666 120.946 116.323 122.22 122.5H71.113Z"
          fill={color}
        />
        <path
          d="M61.1465 112.581L42.25 103.133V96.7461C43.6353 96.0233 44.949 95.1646 46.1625 94.1708C50.2775 90.8013 53.0908 86.0748 54.0838 80.8626C54.368 79.3706 53.389 77.9306 51.897 77.6466C50.407 77.3631 48.9652 78.3408 48.681 79.8333C47.6077 85.4663 43.7983 90.0318 38.747 92.3201C38.518 92.3853 38.3018 92.4796 38.1015 92.5983C33.9543 94.3426 28.713 94.3428 24.5658 92.5983C24.3652 92.4793 24.149 92.3851 23.9198 92.3198C18.8685 90.0316 15.059 85.4658 13.9858 79.8331C13.1693 76.2781 8.033 77.2601 8.583 80.8623C9.57625 86.0748 12.3892 90.8011 16.5045 94.1706C17.718 95.1643 19.032 96.0231 20.417 96.7458V103.133L1.52025 112.581C0.58825 113.047 0 114 0 115.041V125.249C0 126.768 1.23125 127.999 2.75 127.999H66.0417C67.5605 127.999 68.7917 126.768 68.7917 125.249C68.7917 119.708 65.9333 114.972 61.1465 112.581ZM5.5 122.499V116.741L24.3965 107.292C25.3282 106.827 25.9167 105.874 25.9167 104.833V98.7851C29.4312 99.6228 33.2355 99.6228 36.75 98.7851V104.833C36.75 105.874 37.3385 106.827 38.2702 107.292L58.688 117.501C60.7555 118.534 62.2253 120.316 62.8913 122.499H5.5Z"
          fill="black"
        />
        <path
          d="M55.9848 59.7676C54.3135 58.0901 52.0843 57.1661 49.708 57.1661H34.083V41.5411C33.9478 37.8964 28.717 37.8991 28.583 41.5411V57.1661H12.958C10.582 57.1661 8.35301 58.0899 6.68001 59.7691C5.02951 61.4284 4.08301 63.7144 4.08301 66.0411V74.2076C4.08301 76.5361 5.03026 78.8219 6.67976 80.4771C8.35076 82.1574 10.5805 83.0826 12.958 83.0826H49.708C52.0863 83.0826 54.316 82.1574 55.9843 80.4794C57.6358 78.8224 58.583 76.5366 58.583 74.2076V66.0411C58.5833 63.7139 57.6368 61.4281 55.9848 59.7676ZM53.0833 74.2076C53.0833 75.0919 52.721 75.9626 52.0865 76.5989C51.4558 77.2331 50.6115 77.5826 49.7083 77.5826H12.9583C12.0555 77.5826 11.2108 77.2331 10.5778 76.5969C9.94601 75.9626 9.58326 75.0919 9.58326 74.2076V66.0411C9.58326 65.1569 9.94626 64.2846 10.578 63.6496C11.21 63.0156 12.0553 62.6661 12.9583 62.6661H49.7083C50.6113 62.6661 51.4568 63.0151 52.0873 63.6481C52.7203 64.2846 53.0833 65.1566 53.0833 66.0411V74.2076Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_10887_6645">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AiGuideFaceIcon;
