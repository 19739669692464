import Img1 from "src/assets/images/dataAppImages/dataApp-thumbnail1.jpg";
import Img2 from "src/assets/images/dataAppImages/dataApp-thumbnail2.jpg";
import Img3 from "src/assets/images/dataAppImages/dataApp-thumbnail3.jpg";
import Img4 from "src/assets/images/dataAppImages/dataApp-thumbnail4.jpg";
import Img5 from "src/assets/images/dataAppImages/dataApp-thumbnail5.jpg";
import Img6 from "src/assets/images/dataAppImages/dataApp-thumbnail6.jpg";
import Img7 from "src/assets/images/dataAppImages/dataApp-thumbnail7.jpg";
import Img8 from "src/assets/images/dataAppImages/dataApp-thumbnail8.jpg";
import Img9 from "src/assets/images/dataAppImages/dataApp-thumbnail9.jpg";
import Img10 from "src/assets/images/dataAppImages/dataApp-thumbnail10.jpg";

export const dataAppThumbNailImages = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9, Img10];
