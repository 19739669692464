import React, { useMemo } from "react";
import { map } from "lodash";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";

import Spinner from "components/Spinner";
import useEnvironmentLogs from "hooks/api/environments/useEnvironmentLogs";
import useGetEnvironmentById from "hooks/api/environments/useGetEnvironmentById";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import { RenderText } from "src/utils";

export const useStyles = makeStyles({
  logsContainer: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: 16
  }
});

const EnvironmentLogs: React.FC = () => {
  const { envId } = useParams();

  const classes = useStyles();

  const {
    data: environment,
    isLoading: isEnvLoading,
    isFetching
  } = useGetEnvironmentById(envId, {
    enabled: !!envId
  });

  const isLaunchingOrSucceed = useMemo(
    () =>
      environment?.launchStatus
        ? [EnvironmentStatuses.Launching, EnvironmentStatuses.Active].includes(
            environment?.launchStatus as EnvironmentStatuses
          )
        : false,
    [environment?.launchStatus]
  );

  const { data, isLoading } = useEnvironmentLogs(
    envId!,
    isLaunchingOrSucceed ? "live" : "offline",
    {
      refetchInterval: isLaunchingOrSucceed ? 2000 : false,
      enabled: !!environment?.launchStatus
    }
  );

  // const launchStatus = useMemo(() => {
  //   const updatedEnv =
  //     environments?.find((env: $TSFixMe) => env?.id === environment?.id) || environment;
  //   return updatedEnv?.launchStatus;
  // }, [environments]);

  return isLoading || isEnvLoading || isFetching || !data ? (
    <Spinner size={24} />
  ) : (
    <>
      <SubTopNavBarWrapper
        subTopNavBarLeftSection={{
          component: <RenderText color="textSecondary">{environment?.name} Logs</RenderText>
        }}
      />

      <Grid container className={classes.logsContainer}>
        <Grid container item xs={12}>
          <Paper
            style={{
              width: "100%",
              padding: "20px 25px",
              backgroundColor: "#1e1e1e",
              fontFamily: `Menlo, Monaco, "Courier New", monospace`,
              color: "#d4d4d4",
              fontSize: 12,
              overflow: "auto"
            }}>
            <ul style={{ listStyle: "none", wordBreak: "break-word" }}>
              {map(data, (log: $TSFixMe, index: $TSFixMe) => {
                return <li key={`log_${index}`}>{log}</li>;
              })}
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EnvironmentLogs;
