import React, { useState, useEffect, useMemo } from "react";
import _, { isEmpty, find, lowerCase, trim, every, some, size } from "lodash";
import shallow from "zustand/shallow";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Typography,
  Paper,
  InputBase,
  FormHelperText,
  Chip,
  Tooltip,
  useTheme
} from "@material-ui/core";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import {
  FilterNone as FilterNoneIcon,
  LibraryAddCheckOutlined as LibraryAddCheckOutlinedIcon
} from "@material-ui/icons";

import useAuthStore from "src/stores/auth.store";
import useCopyClipboard from "src/hooks/useCopyClipboard";
import useTenantsStore from "src/stores/tenant-management.store";
import { DeleteNew } from "src/icons/DeleteNew";
import { Modal } from "src/components/custom";
import { OverflowTooltip, RadioButtons } from "src/components";
import { PlusIcon } from "src/icons/PlusIcon";
import { Roles } from "src/types";
import { handleResponse } from "src/utils/apiService";
import { useInviteUserMutation } from "src/hooks/api";

type FormValues = {
  role: string;
  inviteType: string;
  emails: {
    email: string | undefined;
  }[];
  urls: {
    email: string;
    authLink?: string;
  }[];
};

const useStyles = makeStyles((theme) => ({
  loaderContainer: { height: 275 },
  email: {
    width: "380px"
  },
  arrayField: {
    columnGap: theme.spacing(1)
  },
  chipContainer: {
    minHeight: 70,
    display: "flex",
    flexWrap: "wrap",
    padding: `10.5px ${theme.spacing(5)}px 10.5px 14px`,
    borderColor: (props: any) =>
      props.hasError ? theme.palette.error.main : theme.palette.grey[400],
    "&:hover": {
      borderColor: (props: any) =>
        props.hasError ? theme.palette.error.main : theme.palette.text.primary
    }
  },
  chip: {
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px  ${theme.spacing(0.5)}px`
  },
  errorChip: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    "& svg": {
      color: theme.palette.error.main
    }
  },
  inputBase: {
    flexGrow: 1,
    minWidth: 120,
    alignItems: "flex-start"
  },
  textFieldRoot: {
    marginTop: theme.spacing(2),
    width: "60%"
  },
  label: {
    position: "absolute",
    top: -theme.spacing(1),
    left: theme.spacing(1.5),
    background: "#fff",
    padding: `0 ${theme.spacing(0.5)}px`,
    color: (props: any) => (props.hasError ? theme.palette.error.main : theme.palette.text.primary)
  },
  errorText: {
    color: theme.palette.error.main
  },
  helperText: {
    marginLeft: theme.spacing(1)
  }
}));

const emailPattern =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const InviteUsersModal = ({ onClose }: any) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState("");
  const [emails, setEmails] = useState<{ value: string; isValid: boolean }[]>([]);
  const [isDirty, setIsDirty] = useState(false);

  const [tenantName, askAiEnabled] = useAuthStore(
    (state) => [state.tenantName, state.askAiEnabled],
    shallow
  );
  const [roles, toggleTenantsRefresh] = useTenantsStore(
    (state) => [state.roles, state.toggleTenantsRefresh],
    shallow
  );

  const inviteTypes = useMemo(() => {
    const types = [
      {
        label: "Using Link (URL)",
        value: "url"
      }
    ];

    if (!!askAiEnabled) {
      types.unshift({
        label: "Email Invite",
        value: "email"
      });
    }

    return types;
  }, [askAiEnabled]);

  const { menuItems, roleAdminId } = useMemo(() => {
    return {
      menuItems: _.map(_.sortBy(roles, "name"), ({ name, id }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      )),
      roleAdminId: _.get(_.find(roles, { name: Roles.Admin.name }), "id")
    };
  }, [roles]);

  const {
    control,
    trigger,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitted }
  } = useForm<FormValues>({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues: {
      role: roleAdminId,
      inviteType: !!askAiEnabled ? "email" : "url",
      // emails: [{ email: "" }],
      urls: [{ email: "", authLink: "" }]
    }
  });

  useEffect(() => {
    if (roleAdminId) {
      setValue("role", roleAdminId);
    }
  }, [roleAdminId]);

  // const { fields, prepend, remove } = useFieldArray<FormValues, "emails", "email">({
  //   control,
  //   name: "emails"
  // });

  const {
    fields: urlFields,
    prepend: prependURL,
    remove: removeURL,
    replace: replaceURLs
  } = useFieldArray<FormValues, "urls", "url">({
    control,
    name: "urls"
  });

  const inviteType = watch("inviteType");
  // These watch() are required to avoid only one item in array displaying error instead of all of them.
  // watch("emails");
  const watchUrls = watch("urls");

  const isEmailInvite = inviteType === "email";

  const inviteUserMutation = useInviteUserMutation();

  const [invalidUrlsMessage, setInvalidUrlsMessage] = useState("");
  useEffect(() => {
    const validateUrls = () =>
      !every(watchUrls, (url: any) => !!url.email)
        ? "Please enter emails to enable this action."
        : !every(watchUrls, (url: any) => !!emailPattern.test(url.email))
          ? "Please correct or remove any emails with an invalid format to enable this action."
          : "";

    if (inviteType !== "email") {
      setInvalidUrlsMessage(validateUrls());
    }
  }, [inviteType, JSON.stringify(watchUrls)]);

  const disabledSubmitActionMessage = useMemo(() => {
    if (isEmpty(menuItems)) {
      return "Please select User Role to enable this action.";
    }

    if (inviteType === "email") {
      if (!inputValue && isEmpty(emails)) {
        return "Please enter emails to enable this action.";
      }

      return (!!inputValue && !emailPattern.test(inputValue)) ||
        some(emails, (email: any) => !email.isValid)
        ? "Please correct or remove any emails with an invalid format to enable this action."
        : "";
    } else {
      return invalidUrlsMessage;
    }
  }, [menuItems, inviteType, inputValue, emails, invalidUrlsMessage]);

  const onSubmit = async (data: FormValues) => {
    isEmailInvite
      ? // ? handleSendEmail({ emails: data.emails, roleId: data.role })
        handleSendEmail({ roleId: data.role })
      : handleGetInvitations({ urls: data.urls, roleId: data.role });
  };

  const handleGetInvitations = ({ urls, roleId }: any) => {
    const requests = urls.map((url: any) => ({
      roleId,
      recipientEmail: url.email
    }));
    inviteUserMutation.mutate(
      { requests, isEmail: false },
      {
        onSuccess: (responses: any) => {
          const errorMessages = urls.reduce((acc: Array<string>, __: any, index: number) => {
            const [{ errorMessage }] = responses[index];
            return errorMessage ? [...acc, errorMessage] : acc;
          }, []);
          if (errorMessages?.length !== 0) {
            handleResponse({
              errorMessage: errorMessages
                .map((msg: string) => (msg.endsWith(".") ? msg : `${msg}.`))
                .join(" ")
            });
          }

          const updatedURLs = urls.map((url: any, index: number) => {
            const [{ link }] = responses[index];
            return { email: url.email, authLink: link };
          });
          replaceURLs(updatedURLs);
        }
      }
    );
  };

  // const handleSendEmail = ({ emails, roleId }: any) => {
  const handleSendEmail = ({ roleId }: any) => {
    const requests = emails
      ?.filter((email: any) => email.isValid)
      .map((email: any) => ({
        roleId,
        // recipientEmail: email.email
        recipientEmail: email.value
      }));

    if (requests.length === 0) {
      return;
    }

    inviteUserMutation.mutate(
      { requests },
      {
        onSuccess: (responses: any) => {
          const errorMessages = responses.reduce(
            (acc: Array<string>, response: any) =>
              response.errorMessage ? [...acc, response.errorMessage] : acc,
            []
          );
          if (errorMessages?.length !== 0) {
            handleResponse({
              errorMessage: errorMessages
                .map((msg: string) => (msg.endsWith(".") ? msg : `${msg}.`))
                .join(" ")
            });
            onModalClose();
            return;
          }
          const successMessage = `${
            emails.length === 1 ? "Email Invitation" : emails.length + " Email Invitations"
          } sent successfully`;
          handleResponse({
            successMessage
          });
          onModalClose();
        },
        onError: () => {
          onClose();
        }
      }
    );
  };

  // const submitLabel = React.useMemo(
  //   () =>
  //     inviteType === "email"
  //       ? `Send ${fields.length === 1 ? "Invite" : fields.length + " Invites"}`
  //       : `Generate Auth ${urlFields.length === 1 ? "Link" : "Links"}`,
  //   [fields.length, inviteType, urlFields.length]
  // );

  const submitLabel = React.useMemo(() => {
    const filteredEmails = emails?.filter((email: any) => email?.isValid);

    return inviteType === "email"
      ? `Send ${filteredEmails.length <= 1 ? "Invite" : filteredEmails.length + " Invites"}`
      : `Generate Auth ${urlFields.length === 1 ? "Link" : "Links"}`;
  }, [emails.length, inviteType, urlFields.length]);

  const onModalClose = () => {
    toggleTenantsRefresh();
    onClose();
  };

  const getDefaultUserRole = useMemo(
    () =>
      find(
        roles,
        (eachMenu: $TSFixMe) =>
          lowerCase(trim(eachMenu?.name)) === lowerCase(trim(Roles.Admin.name))
      ),
    [roles]
  );

  // const isEmailUnique = (value: string, index: number) => {
  //   const currentEmails = watch("emails");
  //   const otherEmails = currentEmails.filter((_, i) => i !== index);
  //   return !otherEmails.some((email) => email.email === value);
  // };

  const isUrlEmailUnique = (value: string, index: number) => {
    const currentEmails = watch("urls");
    const otherEmails = currentEmails.filter((_, i) => i !== index);
    return !otherEmails.some((email) => email.email === value);
  };

  const hasError = useMemo(() => {
    if (isDirty || isSubmitted) {
      if (emails.length === 0 && inputValue.trim() === "") {
        return true;
      }
    }

    return false;
  }, [isSubmitted, isDirty, emails, inputValue]);

  const classes = useStyles({ hasError });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (!isDirty) setIsDirty(true);
  };

  const addEmailsFromInput = (input: string) => {
    const emailList = input
      .split(/[,\s]+/)
      .filter(Boolean)
      .map((email) => email.trim());

    const newEmails = emailList
      .map((email) => ({
        value: email,
        isValid: emailPattern.test(email)
      }))
      // Filter out emails that already exist in the list
      .filter(
        (newEmail) => !emails.some((existingEmail) => existingEmail.value === newEmail.value)
      );

    setEmails((prevEmails) => [...prevEmails, ...newEmails]);
    setInputValue(""); // Clear input field
  };

  const handleOnBlur = () => {
    if (inputValue.trim()) {
      addEmailsFromInput(inputValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === " " || event.key === ",") {
      event.preventDefault();
      if (inputValue.trim()) {
        addEmailsFromInput(inputValue);
      }
    }
  };

  const handleDelete = (emailToDelete: string) => () => {
    setEmails((prevEmails) => prevEmails.filter((email) => email.value !== emailToDelete));
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text");
    addEmailsFromInput(paste);
  };

  return (
    <Modal
      open={true}
      size="md"
      title={`Invite users to ${tenantName}`}
      submitLabel={submitLabel}
      isSubmitting={inviteUserMutation.isLoading}
      isSubmitDisabled={!!disabledSubmitActionMessage}
      submitActionInfo={disabledSubmitActionMessage}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onModalClose}>
      {isEmpty(menuItems) ? (
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          className={classes.loaderContainer}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Box py="8px">
            <Grid>
              <Controller
                name="role"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    size="small"
                    variant="outlined"
                    style={{ width: "42%", marginBottom: "15px" }}
                    component="fieldset">
                    <FormLabel component="legend">
                      <Typography style={{ fontWeight: 500 }}>User Role</Typography>
                    </FormLabel>
                    <InputLabel id="demo-simple-select-label" />
                    <Select
                      labelId="demo-simple-select-label"
                      id="role-id"
                      defaultValue={getDefaultUserRole?.id}
                      value={value}
                      onChange={onChange}>
                      {menuItems}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid>
              <Controller
                name="inviteType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioButtons
                    row
                    size="small"
                    label="Invite Type"
                    labelStyleProps={{
                      color: "#515151"
                    }}
                    values={inviteTypes}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Grid>
            <Grid
              {...(size(urlFields) > 5
                ? {
                    style: { maxHeight: 270, overflowY: "auto" }
                  }
                : {})}>
              {/* <Typography style={{ fontWeight: 500, marginBottom: "8px", color: "#515151" }}>
                User Email
              </Typography> */}
              {inviteType === "email" ? (
                <FormControl size="small" error={hasError} className={classes.textFieldRoot}>
                  <InputLabel shrink className={classes.label}>
                    User Emails
                  </InputLabel>
                  <Paper
                    elevation={0}
                    className={classes.chipContainer}
                    // onBlur={handleBlur}
                    variant="outlined">
                    {emails.map((email, index) => (
                      <Tooltip
                        key={index}
                        title={email.isValid ? "" : "Invalid email id"}
                        placement="top">
                        <Chip
                          label={
                            <OverflowTooltip
                              style={{ maxWidth: 200, whiteSpace: "nowrap" }}
                              value={email.value}
                            />
                          }
                          size="small"
                          onDelete={handleDelete(email.value)}
                          className={`${classes.chip} ${!email.isValid ? classes.errorChip : ""}`}
                          variant="outlined"
                          color={email.isValid ? "primary" : "secondary"}
                        />
                      </Tooltip>
                    ))}
                    {(!isEmpty(emails) || !!inputValue) && (
                      <IconButton
                        size="small"
                        style={{ position: "absolute", right: theme.spacing(1) }}>
                        <CloseRoundedIcon
                          fontSize="small"
                          style={{ opacity: 0.5 }}
                          onClick={() => {
                            setEmails(() => []);
                            setInputValue("");
                          }}
                        />
                      </IconButton>
                    )}
                    <InputBase
                      className={classes.inputBase}
                      value={inputValue}
                      onChange={handleInputChange}
                      onBlur={handleOnBlur}
                      onKeyDown={handleKeyDown}
                      onPaste={handlePaste}
                      placeholder={emails?.length === 0 ? "User Emails" : ""}
                      inputProps={{
                        style: {
                          padding: 0
                        }
                      }}
                    />
                  </Paper>
                  <FormHelperText error={false}>
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{ marginRight: 4, color: "rgba(0, 0, 0, 0.54) !important" }}
                    />
                    Enter emails or paste bulk emails separated by comma or space.
                  </FormHelperText>
                </FormControl>
              ) : (
                // fields.map((field: any, index: number) => {
                //     return (
                //       <Grid
                //         key={field.id}
                //         container
                //         alignItems="center"
                //         className={classes.arrayField}>
                //         <Controller
                //           name={`emails.${index}.email` as const}
                //           control={control}
                //           rules={{
                //             required: "This field is required.",
                //             validate: {
                //               isValidPassword: (value) => {
                //                 if (value && !emailPattern.test(value)) {
                //                   return "Email format invalid";
                //                 }

                //                 return true;
                //               },
                //               isUnique: (value?: string) => {
                //                 if (!value || isEmailUnique(value, index)) {
                //                   return true;
                //                 }

                //                 return "Email must be unique";
                //               }
                //             }
                //           }}
                //           render={({ field, fieldState }) => {
                //             const { error } = fieldState;
                //             return (
                //               <TextField
                //                 {...field}
                //                 label="User Email"
                //                 variant="outlined"
                //                 size="small"
                //                 required
                //                 error={!!error}
                //                 helperText={error?.message}
                //                 className={classes.email}
                //                 onBlur={() => trigger()}
                //               />
                //             );
                //           }}
                //         />
                //         <IconButton
                //           id={`delete-row-button-${index}`}
                //           color="primary"
                //           onClick={() => {
                //             remove(index);
                //             trigger();
                //           }}
                //           disabled={fields.length === 1}>
                //           <DeleteNew disabled={fields.length === 1} />
                //         </IconButton>
                //         {index == 0 && (
                //           <IconButton
                //             id={`add-row-button-${index}`}
                //             color="primary"
                //             onClick={() =>
                //               prepend({
                //                 email: ""
                //               })
                //             }>
                //             <PlusIcon />
                //           </IconButton>
                //         )}
                //       </Grid>
                //     );
                //   })
                urlFields.map((field: any, index: number) => {
                  return (
                    <Grid
                      key={field.id}
                      container
                      alignItems="flex-start"
                      className={classes.arrayField}
                      style={{ marginTop: index > 0 ? 16 : 0 }}>
                      <Controller
                        name={`urls.${index}.email` as const}
                        control={control}
                        rules={{
                          required: "This field is required.",
                          validate: {
                            isValidPassword: (value) => {
                              if (value && !emailPattern.test(value)) {
                                return "Email format invalid";
                              }

                              return true;
                            },
                            isUnique: (value?: string) => {
                              if (!value || isUrlEmailUnique(value, index)) {
                                return true;
                              }

                              return "Email must be unique";
                            }
                          }
                        }}
                        render={({ field, fieldState }) => {
                          const { error } = fieldState;
                          return (
                            <TextField
                              {...field}
                              label="Email"
                              variant="outlined"
                              size="small"
                              required
                              error={!!error}
                              helperText={error?.message}
                              onBlur={() => trigger()}
                              style={{ flexGrow: 1 }}
                            />
                          );
                        }}
                      />
                      <Controller
                        name={`urls.${index}.authLink` as const}
                        control={control}
                        render={({ field: { onChange, value = "" }, fieldState }) => {
                          const { error } = fieldState;
                          const { handleCopyClick, isCopied } = useCopyClipboard(value);
                          return (
                            <TextField
                              label="Auth Link"
                              variant="outlined"
                              size="small"
                              onChange={onChange}
                              value={value}
                              disabled={!value}
                              error={!!error}
                              helperText={error?.message}
                              style={{ flexGrow: 1 }}
                              // @ts-expect-error
                              readOnly={value !== ""}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      id={`copy-invite-link-button-${index}`}
                                      aria-label="copy invite link"
                                      onClick={handleCopyClick}
                                      onMouseDown={handleCopyClick}
                                      edge="end">
                                      {isCopied ? (
                                        <LibraryAddCheckOutlinedIcon fontSize="small" />
                                      ) : (
                                        <FilterNoneIcon fontSize="small" />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          );
                        }}
                      />
                      <Grid
                        container
                        style={{
                          marginTop: theme.spacing(0.5),
                          width: 65,
                          columnGap: theme.spacing(1)
                        }}>
                        <IconButton
                          id={`delete-row-button-${index}`}
                          size="small"
                          color="primary"
                          disabled={urlFields.length === 1}
                          onClick={() => removeURL(index)}>
                          <DeleteNew disabled={urlFields.length === 1} />
                        </IconButton>
                        {index == 0 && (
                          <IconButton
                            id={`add-row-button-${index}`}
                            size="small"
                            color="primary"
                            onClick={() =>
                              prependURL({
                                email: ""
                              })
                            }>
                            <PlusIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Box>
        </>
      )}
    </Modal>
  );
};

export default InviteUsersModal;
