import { Button, withStyles } from "@material-ui/core";

const AnchorButton = withStyles(() => ({
  root: {
    color: "#003656",
    textTransform: "none",
    padding: 0,
    fontWeight: 400,
    fontSize: "16px",
    textAlign: "left",
    justifyContent: "left",
    "&:hover": {
      color: "#0056b3",
      textDecoration: "underline",
      background: "none"
    }
  }
}))(Button);

export default AnchorButton;
