import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  synchDataContainer: {
    height: "calc(100vh - 214px)"
  },
  message: {
    color: "#7C7C7C"
  }
}));

const SynchingDatasource = () => {
  const classes = useStyles();
  return (
    <Grid
      justifyContent="center"
      className={classes.synchDataContainer}
      container
      alignItems="center"
      direction="column">
      <svg
        width="92"
        height="92"
        viewBox="0 0 92 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path
            d="M72.8992 33.1434C68.7844 22.2812 58.2996 14.5547 46.018 14.5547C33.7363 14.5547 23.2516 22.2723 19.1367 33.1344C11.4012 35.1738 5.70508 42.2445 5.75 50.6359C5.80391 60.0156 13.0813 67.6434 22.2453 68.4609C22.6676 68.4969 23.027 68.1645 23.027 67.7422V62.3156C23.027 61.9562 22.7574 61.6508 22.3981 61.6059C19.9723 61.3004 17.6813 60.2402 15.9203 58.5063C13.7641 56.3949 12.5781 53.5559 12.5781 50.5461C12.5781 48.0305 13.3957 45.6586 14.932 43.682C16.4324 41.7594 18.5438 40.3668 20.8707 39.7559L24.2758 38.8574L25.5246 35.5602C26.2973 33.5117 27.3754 31.5891 28.732 29.8551C30.0707 28.1301 31.6609 26.6207 33.4398 25.3629C37.1324 22.7664 41.4809 21.3918 46.018 21.3918C50.5551 21.3918 54.9035 22.7664 58.5961 25.3719C60.384 26.6297 61.9652 28.1391 63.3039 29.8641C64.6605 31.598 65.7387 33.5207 66.5113 35.5691L67.7512 38.8574L71.1473 39.7559C76.0168 41.0496 79.4219 45.4789 79.4219 50.5371C79.4219 53.5559 78.2359 56.3859 76.0797 58.4973C74.3277 60.2223 72.0457 61.2914 69.6199 61.5969C69.2606 61.6418 69 61.9473 69 62.3066V67.7422C69 68.1645 69.3684 68.4969 69.7906 68.4609C78.9277 67.6344 86.1871 60.0156 86.25 50.6539C86.3039 42.2625 80.6168 35.1918 72.8992 33.1434Z"
            fill="black"
          />
          <path
            d="M38.3296 64.9835C38.4517 62.7217 39.3949 60.6152 41.0098 59.0015C42.7333 57.2798 45.081 56.3143 47.5237 56.3143C49.98 56.3143 52.2939 57.2663 54.0377 59.0015C54.1327 59.096 54.2209 59.1905 54.3159 59.2918L52.6195 60.6152C52.5387 60.6774 52.4772 60.761 52.442 60.8565C52.4069 60.9519 52.3995 61.0553 52.4207 61.1548C52.442 61.2542 52.4911 61.3457 52.5622 61.4186C52.6334 61.4916 52.7238 61.5431 52.8231 61.5671L59.4863 63.1875C59.8256 63.2686 60.1581 63.012 60.1581 62.6677L60.192 55.8282C60.192 55.3758 59.6763 55.1193 59.3167 55.4029L57.7425 56.6317C55.2591 53.7959 51.6085 52 47.5305 52C40.2431 52 34.2991 57.7457 34.0005 64.943C33.987 65.2468 34.2312 65.5034 34.5434 65.5034H37.7936C38.0785 65.4966 38.316 65.2738 38.3296 64.9835ZM60.4566 65.4966H57.2064C56.9215 65.4966 56.684 65.7194 56.6636 66.0098C56.5415 68.2716 55.5983 70.3781 53.9834 71.9917C52.2599 73.7134 49.9122 74.6789 47.4695 74.6789C45.0132 74.6789 42.6994 73.7269 40.9555 71.9917C40.8605 71.8972 40.7723 71.8027 40.6773 71.7014L42.3737 70.3781C42.4545 70.3159 42.516 70.2322 42.5512 70.1368C42.5864 70.0413 42.5937 69.9379 42.5725 69.8385C42.5512 69.739 42.5022 69.6476 42.431 69.5746C42.3598 69.5017 42.2694 69.4502 42.1701 69.4261L35.5069 67.8057C35.1676 67.7247 34.8351 67.9812 34.8351 68.3256L34.808 75.1718C34.808 75.6242 35.3237 75.8807 35.6833 75.5971L37.2575 74.3683C39.7409 77.2041 43.3915 79 47.4695 79C54.7569 79 60.7009 73.2543 60.9995 66.057C61.013 65.7532 60.7688 65.4966 60.4566 65.4966Z"
            fill="black"
          />
        </g>
      </svg>
      <Typography className={classes.message}>Data Sync has started...</Typography>
      <Typography className={classes.message}>Please wait for sometime</Typography>
    </Grid>
  );
};

export default SynchingDatasource;
