import React from "react";
import { Chip, Grid, Tooltip, Typography } from "@material-ui/core";

import ReadOnlyConnectorDetails from "./ReadOnlyConnectorDetails";
import { IDestination } from "../JobDestinations/JobDestinations";
import { ellipses } from "src/utils/formatText";

interface IProps {
  destination: IDestination;
  datasetName: string;
}

const ViewJobDataConnector: React.FC<IProps> = (props) => {
  const { destination, datasetName } = props;

  return (
    <Grid container>
      <Grid item style={{ width: "50%" }}>
        <Typography className="destinationTitleReadOnlyMode">Datasets</Typography>
        <Typography variant="body2">
          <Tooltip title={datasetName.length > 20 ? datasetName : ""}>
            <Chip
              variant="outlined"
              label={ellipses({
                text: datasetName || "Unknown",
                length: 20
              })}
              size="small"
              style={{ marginRight: 10 }}
            />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item style={{ width: "50%" }}>
        <ReadOnlyConnectorDetails destination={destination} />
      </Grid>
    </Grid>
  );
};

export default ViewJobDataConnector;
