import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroupProps,
  FormControlLabelProps,
  Typography,
  RadioProps,
  TypographyProps
} from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";
import { StyledTooltip } from "src/components";

interface RadioButtonsProps extends RadioGroupProps {
  label: string;
  info?: string;
  size?: RadioProps["size"];
  color?: RadioProps["color"] & TypographyProps["color"];
  values: {
    label: string | number;
    value: string;
    disabled?: boolean;
    labelPlacement?: FormControlLabelProps["labelPlacement"];
  }[];
  labelStyleProps: $TSFixMe;
}

const RadioButtons = ({
  label,
  info,
  values,
  color = "primary",
  value,
  size,
  onChange,
  labelStyleProps = {},
  ...restProps
}: RadioButtonsProps) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography color={color} style={{ fontWeight: 500, ...labelStyleProps }}>
          {label}
          {info && (
            <StyledTooltip title={info}>
              <InfoIcon fontSize="small" />
            </StyledTooltip>
          )}
        </Typography>
      </FormLabel>
      <RadioGroup value={value} onChange={onChange} {...restProps}>
        {values.map(({ value, label, disabled, labelPlacement }) => (
          <FormControlLabel
            value={value}
            key={value}
            control={
              <Radio
                id={`${`${label}`?.replace(" ", "")}-radio-button`}
                size={size}
                color={color}
              />
            }
            label={label}
            labelPlacement={labelPlacement}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;
