import React from "react";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    // @ts-expect-error TS(2339) FIXME: Property 'noPadding' does not exist on type '{}'.
    padding: ({ noPadding, padding }) => (padding ? padding : noPadding ? 0 : 40)
  },
  absoluteCentered: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  }
}));

type OwnProps = {
  isAbsolute?: boolean;
  className?: string;
  size?: number;
  noPadding?: boolean;
  padding?: string;
};

// @ts-expect-error TS(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Spinner.defaultProps;

// @ts-expect-error TS(7022) FIXME: 'Spinner' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const Spinner = ({ isAbsolute, className, noPadding, size, padding }: Props) => {
  const classes = useStyles({ noPadding, padding });

  return (
    <Grid
      container
      justifyContent="center"
      className={clsx(classes.root, className, {
        [classes.absoluteCentered]: isAbsolute
      })}>
      <CircularProgress color="secondary" size={size} />
    </Grid>
  );
};

Spinner.defaultProps = {
  isAbsolute: false
};

export default Spinner;
