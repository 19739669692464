import React from "react";

const PredictionJobDefaultIcon: React.FC = () => {
  return (
    <svg
      width="216"
      height="214"
      viewBox="0 0 216 214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 178V214H38V178H0Z" fill="#D9D9D9" />
      <path d="M0 178V214H11V178H0Z" fill="#BEBEBE" />
      <path d="M38 165V214H76V165H38Z" fill="#D9D9D9" />
      <path d="M38 165V214H49V165H38Z" fill="#BEBEBE" />
      <path d="M76 152.396V214H114V152.396H76Z" fill="#D9D9D9" />
      <path d="M76 152.396V214H87V152.396H76Z" fill="#BEBEBE" />
      <path d="M113 112V214H151V112H113Z" fill="#D9D9D9" />
      <path d="M113 112V214H124V112H113Z" fill="#BEBEBE" />
      <circle cx="23.5" cy="23.5" r="7.5" fill="#D9D9D9" />
      <path d="M31 15.5L34 12.5" stroke="#C7C7C7" strokeWidth="2" strokeLinecap="round" />
      <path d="M12.5 34.5L15 32" stroke="#C7C7C7" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M11.9999 12.2308L14.7201 14.4893"
        stroke="#C7C7C7"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M6 23H11.5" stroke="#C7C7C7" strokeWidth="2" strokeLinecap="round" />
      <path d="M23 40.5L23 35" stroke="#C7C7C7" strokeWidth="2" strokeLinecap="round" />
      <circle cx="205.5" cy="10.5" r="10.5" fill="#2B88FF" />
      <path
        d="M113.979 163V162.838C113.982 162.836 113.985 162.836 113.989 162.835V162.998C113.985 162.998 113.982 162.999 113.979 163ZM116.778 111.2H116.62V111.102H116.778V111.2Z"
        fill="#BDBCBA"
      />
      <path d="M124.227 111.2H124.387V111.101H124.227V111.2Z" fill="#BDBCBA" />
      <path
        d="M113.989 140.89C113.985 140.89 113.982 140.89 113.979 140.89V115.285C113.982 115.284 113.985 115.283 113.989 115.283V140.89Z"
        fill="#C1C0BE"
      />
      <path
        d="M103.383 33.0513C101.606 35.198 99.7777 37.3024 97.9465 39.4029L96.5627 40.9693L95.3219 42.3537C94.8111 42.9568 94.4748 43.7122 94.3553 44.5365L93.9388 48.6175C93.7984 50.0012 93.6644 51.3856 93.502 52.7687C93.196 55.5355 92.8787 58.3017 92.507 61.0654C92.3768 62.0338 91.4861 62.7134 90.517 62.5831C89.6624 62.4687 89.0334 61.7607 88.9867 60.9307C88.8261 58.1494 88.7199 55.3712 88.6257 52.593C88.5688 51.2035 88.541 49.8154 88.5062 48.4272L88.412 44.1907C88.438 42.2418 89.0815 40.2822 90.1959 38.6368L91.5721 36.7865L92.8199 35.1285C94.4963 32.9293 96.1752 30.7345 97.9066 28.5821C99.1304 27.0619 101.355 26.8204 102.876 28.0442C104.397 29.268 104.638 31.4931 103.414 33.0134L103.383 33.0513Z"
        fill="#4870C0"
      />
      <path
        d="M88.548 61.2502L86.2375 65.1726L91.5563 65.7124L92.4918 61.73C92.6031 61.2578 92.2996 60.7818 91.8224 60.7002C90.9406 60.5492 89.5613 60.4866 88.548 61.2502Z"
        fill="#E46554"
      />
      <path
        d="M86.2375 65.1726L86.4803 66.5602C86.5997 67.246 87.1958 67.7454 87.8924 67.7441L89.6245 67.7391C90.1523 67.7378 90.6359 67.4458 90.8837 66.9799L91.5563 65.7125L86.2375 65.1726Z"
        fill="#E46554"
      />
      <path
        d="M88.1718 58.3095L93.2915 58.4846L92.9615 61.5435L88.6371 61.2091L88.1718 58.3095Z"
        fill="#4870C0"
      />
      <path
        d="M92.9615 61.5435L92.5146 61.5087L88.6371 61.2091L88.6339 61.1876L88.1718 58.3095L93.2915 58.4846L92.9615 61.5435Z"
        fill="#799BD7"
      />
      <path
        d="M93.3351 90.4193L92.4937 91.6836L91.8502 90.3776L93.3831 70.53C93.6265 67.375 92.562 64.782 88.6371 64.6866C84.9657 64.5981 82.7374 67.1058 82.4814 70.2557L80.8739 90.0679L79.9946 91.3283L79.3947 90.0262L81.0123 70.219C81.3113 66.5571 82.9365 63.6189 88.7104 63.758C94.4944 63.8977 95.1392 66.8971 94.8579 70.5673L93.3351 90.4193Z"
        fill="#1D2A30"
      />
      <path
        d="M77.0659 77.0325L76.0508 77.0274C73.9369 77.0179 72.1455 78.5825 71.8711 80.6786L70 94.9818L76.5855 95.2163L77.0659 77.0325Z"
        fill="#1D2A30"
      />
      <path
        d="M73.3686 95.1021L70 94.9819L71.8711 80.6788C72.1448 78.5889 73.9255 77.0276 76.0312 77.0276C76.0375 77.0276 76.0444 77.0276 76.0508 77.0276L76.9206 77.032C75.1285 77.0623 73.6784 78.5244 73.6512 80.3658L73.4167 91.8308C73.4148 91.9332 73.4179 92.0343 73.4255 92.1348L73.3686 95.1021Z"
        fill="#45555C"
      />
      <path
        d="M95.66 90.5879L94.1726 90.5455L94.3174 70.6986C94.3402 67.543 93.0949 64.9506 89.163 64.8552C85.4853 64.766 83.4321 67.2743 83.3967 70.4242L83.1748 90.2358L81.6925 90.1941L81.9251 70.3869C81.9681 66.7256 83.3879 63.7869 89.1712 63.9266C94.9653 64.0663 95.8193 67.0657 95.7947 70.7359L95.66 90.5879Z"
        fill="#1D2A30"
      />
      <path
        d="M102.639 95.9897L73.3687 95.1212L73.6512 80.366C73.679 78.4753 75.2069 76.9841 77.066 77.0328L98.377 77.5903C100.784 77.6536 102.728 79.6821 102.718 82.1252L102.639 95.9897Z"
        fill="#1D2A30"
      />
      <path
        d="M73.4255 92.1348C73.4179 92.0343 73.4147 91.9331 73.4166 91.8307L73.6512 80.3658L73.4255 92.1348Z"
        fill="#929C9E"
      />
      <path
        d="M97.7057 95.8428L76.5856 95.2163C74.9085 95.1664 73.5513 93.813 73.4255 92.1347L73.6512 80.3657C73.6784 78.5047 75.1588 77.0312 76.9775 77.0312C77.0072 77.0312 77.0363 77.0312 77.066 77.0325L81.8455 77.1576L81.6925 90.1941L82.3834 91.4969L83.1749 90.2358L83.3209 77.1962L94.2675 77.4826L94.1727 90.5455L94.9072 91.8521L95.6601 90.5879L95.7486 77.5211L96.6051 77.5439C96.6481 83.6559 97.4338 90.1732 97.7057 95.8428Z"
        fill="#758288"
      />
      <path
        d="M95.7485 77.521L94.2675 77.4824L94.3174 70.6984C94.3174 70.6674 94.3174 70.6371 94.3174 70.6061C94.3155 67.9556 93.4027 65.7179 90.7288 65.0529C90.2617 64.9372 89.7414 64.8689 89.163 64.855C89.0998 64.8538 89.0366 64.8525 88.974 64.8525C88.1522 64.8525 87.4146 64.9846 86.7654 65.2261C86.5985 65.288 86.4379 65.3569 86.283 65.4328C84.4277 66.3418 83.4214 68.2268 83.3967 70.4241L83.3209 77.1961L81.8455 77.1575L81.9251 70.3868C81.9669 66.8089 83.3234 63.9214 88.7818 63.9214C88.9095 63.9214 89.0391 63.9233 89.1712 63.9264C94.8863 64.0642 95.7953 66.984 95.7947 70.5853C95.7947 70.6352 95.7947 70.6852 95.7947 70.7357L95.7485 77.521Z"
        fill="#5D6C72"
      />
      <path
        d="M94.9072 91.8521L94.1726 90.5454L94.2675 77.4825L95.7485 77.521L95.66 90.5878L94.9072 91.8521ZM82.3834 91.4968L81.6925 90.194L81.8455 77.1576L83.3209 77.1961L83.1748 90.2357L82.3834 91.4968Z"
        fill="#A1A8AA"
      />
      <path
        d="M83.6774 82.5796L81.238 82.5138L81.2488 81.5954L83.6875 81.6605L83.6774 82.5796Z"
        fill="#1D2A30"
      />
      <path
        d="M83.6117 88.5356L81.1666 88.4674L81.178 87.5445L83.6218 87.6127L83.6117 88.5356Z"
        fill="#1D2A30"
      />
      <path
        d="M96.2138 82.9175L93.7643 82.8517L93.7712 81.9314L96.2195 81.9971L96.2138 82.9175Z"
        fill="#1D2A30"
      />
      <path
        d="M96.1746 88.8862L93.72 88.8173L93.727 87.8932L96.1803 87.9614L96.1746 88.8862Z"
        fill="#1D2A30"
      />
      <path
        d="M91.5461 83.3164L86.3089 83.1742L86.3285 81.2095L91.5626 81.3492L91.5461 83.3164Z"
        fill="white"
      />
      <path
        d="M114.144 10.8705C114.345 10.8319 114.56 11.0418 114.625 11.3465C114.691 11.6511 114.585 11.9369 114.384 11.9754C114.182 12.014 113.968 11.8041 113.903 11.4994C113.821 11.1846 113.943 10.9084 114.144 10.8705Z"
        fill="#1D2A30"
      />
      <path
        d="M114.18 11.1245C114.386 11.0284 114.575 10.8135 114.657 10.6017C114.482 10.7667 114.239 10.8584 113.998 10.8527C114.09 10.9052 114.175 11.0183 114.18 11.1245Z"
        fill="#1D2A30"
      />
      <path
        d="M114.584 14.2182C115.115 14.2403 115.628 14.026 115.986 13.6341C115.211 12.9615 114.533 12.1865 113.968 11.3312L114.584 14.2182Z"
        fill="#D33B32"
      />
      <path
        d="M112.451 10.3714C112.744 10.0042 113.196 9.80001 113.666 9.82466L113.726 9.83352C113.829 9.83162 113.911 9.74692 113.909 9.64388C113.906 9.54147 113.822 9.45929 113.719 9.46118C113.133 9.41314 112.559 9.65271 112.181 10.1034L112.136 10.2242C112.136 10.3272 112.219 10.4106 112.323 10.4106L112.451 10.3714Z"
        fill="#1D2A30"
      />
      <path
        d="M110.526 28.6398C105.268 28.6961 104.462 25.8332 104.462 25.8332C106.024 24.2996 106.102 21.4936 105.77 18.9916C106.173 19.5567 106.675 20.0517 107.332 20.4898C108.59 21.3172 111.319 19.9879 111.319 19.9879C111.325 19.9879 111.319 20.4544 111.353 21.1263C111.439 22.7661 111.799 25.6429 113.291 26.0879C113.291 26.0879 116.323 28.576 110.526 28.6398Z"
        fill="#E46554"
      />
      <path
        d="M111.396 20.4146C111.34 20.6567 111.284 20.8982 111.25 21.1548L111.185 21.1769C109.953 21.4829 108.592 21.3147 107.334 20.4879C106.672 20.0498 106.169 19.5581 105.768 18.9923C105.688 18.357 105.572 17.7375 105.456 17.1686L111.183 20.3002L111.396 20.4146Z"
        fill="#E46554"
      />
      <path
        d="M115.164 16.1212C115.126 18.0656 114.063 19.7066 112.575 20.5992L112.567 20.5998L112.545 20.6068C112.146 20.8596 111.709 21.0398 111.25 21.1548L111.185 21.1769C109.953 21.4829 108.592 21.3147 107.334 20.4879C106.672 20.0498 106.169 19.5581 105.768 18.9923C104.737 17.5782 104.308 15.6907 103.57 13.0383C103.343 12.2589 103.297 11.4339 103.442 10.6355C103.959 7.78021 106.694 5.88699 109.542 6.4047C113.539 7.11395 115.243 12.5692 115.164 16.1212Z"
        fill="#E46554"
      />
      <path
        d="M111.413 21.1113C111.508 21.0835 111.603 21.0532 111.697 21.0197C111.611 21.0525 111.52 21.0816 111.413 21.1113Z"
        fill="#CECDCC"
      />
      <path
        d="M111.353 21.1265C111.319 20.4545 111.325 19.988 111.319 19.988C111.353 19.4014 111.396 18.8148 111.396 18.8148C111.396 18.8363 112.011 20.7023 112.286 20.7023C112.047 20.854 111.886 20.9463 111.697 21.019C111.603 21.0525 111.508 21.0828 111.413 21.1107C111.394 21.1157 111.374 21.1208 111.353 21.1265Z"
        fill="#B55144"
      />
      <path
        d="M113.783 8.9492C113.355 9.62937 112.007 10.2046 112.052 10.0466C112.284 9.48209 112.241 8.39861 111.542 8.6521C111.735 9.08384 112.315 12.3298 112.052 12.7237C111.909 12.9386 111.772 15.5354 110.667 17.8249C109.785 19.6518 109.785 20.2182 109.785 20.2182L106.195 20.9116C104.851 20.7435 103.6 19.026 103.489 18.8363C103.02 18.0291 101.586 16.9697 100.84 16.4083C100.517 16.1656 100.265 15.8078 100.129 15.4254C99.8827 14.7364 100.385 12.2533 100.495 11.0858C100.653 9.40623 101.534 7.85246 103.97 6.85496C105.476 6.238 106.744 4.88462 108.281 4.3492C109.818 3.81316 111.647 3.83781 112.944 4.8195C113.583 5.30245 114.855 7.24371 113.783 8.9492Z"
        fill="#1D2A30"
      />
      <path
        d="M111.907 11.3127C113.013 11.6377 113.59 13.2673 113.637 14.3021C113.692 15.4456 112.928 16.1681 111.93 15.4026C111.18 14.8128 110.643 13.993 110.404 13.0682C110.133 11.948 110.801 10.9885 111.907 11.3127Z"
        fill="#E46554"
      />
      <path
        d="M113.236 15.1222C113.236 15.3889 113.021 15.6045 112.754 15.6045C112.487 15.6045 112.271 15.3889 112.271 15.1222C112.271 14.8554 112.487 14.6392 112.754 14.6392C113.021 14.6392 113.236 14.8554 113.236 15.1222Z"
        fill="#4870C0"
      />
      <path
        d="M112.754 15.6045C112.487 15.6045 112.271 15.3889 112.271 15.1222C112.271 14.8554 112.487 14.6392 112.754 14.6392C113.021 14.6392 113.236 14.8554 113.236 15.1222C113.236 15.3889 113.021 15.6045 112.754 15.6045Z"
        fill="#799BD7"
      />
      <path
        d="M102.952 27.729C102.952 27.729 103.769 25.2074 105.002 24.411C106.234 23.6145 111.645 24.4407 112.493 24.7725C113.341 25.1044 114.196 27.0583 114.196 27.0583L102.952 27.729Z"
        fill="#4870C0"
      />
      <path
        d="M103.437 26.5308C103.797 25.7653 104.339 24.8392 105.002 24.4106C105.38 24.1666 106.152 24.0749 107.064 24.0749C109.125 24.0749 111.905 24.5421 112.493 24.7722C113.008 24.9738 113.525 25.7735 113.853 26.3708C113.688 26.3468 113.499 26.3253 113.269 26.307C112.109 26.2533 110.949 26.2267 109.789 26.2267C109.627 26.2267 109.466 26.2273 109.305 26.228C107.721 26.2368 106.138 26.2956 104.558 26.4043C104.18 26.4315 103.806 26.4751 103.437 26.5308Z"
        fill="#799BD7"
      />
      <path
        d="M115.307 26.652C114.465 26.5142 114.233 26.3865 113.269 26.3107C110.366 26.1741 107.458 26.2057 104.558 26.4049C103.5 26.4807 102.481 26.6849 101.47 26.9283C99.3934 27.4264 97.9939 29.3613 98.1475 31.4764C98.5786 37.3938 100.328 40.8667 100.586 44.7625C101.1 52.5238 99.8618 57.9658 99.6507 59.7775L103.631 60.3818C110.738 60.2137 115.457 58.6858 119.369 57.0214C119.369 57.0214 118.343 47.6375 119.335 44.2568C122.043 35.0189 120.285 33.5574 119.751 30.9119C119.305 28.714 117.537 27.018 115.307 26.652Z"
        fill="#4870C0"
      />
      <path
        d="M99.6507 59.7764L99.6488 59.7757C99.8017 58.4691 100.489 55.2693 100.668 50.6705C100.491 55.2674 99.8036 58.4672 99.6507 59.7764ZM119.369 57.0197C119.357 56.909 118.901 52.6889 118.903 48.9183C118.903 48.9227 118.903 48.9278 118.903 48.9328C118.903 52.7496 119.369 57.019 119.369 57.019C119.369 57.0197 119.369 57.0197 119.369 57.0197ZM114.691 26.5346C114.41 26.4745 114.177 26.4195 113.854 26.3734C113.853 26.3727 113.853 26.3721 113.853 26.3715C114.176 26.4183 114.41 26.4739 114.691 26.5346Z"
        fill="white"
      />
      <path
        d="M113.854 26.3735C113.688 26.3501 113.5 26.3293 113.269 26.311C112.09 26.2553 110.909 26.2275 109.729 26.2275C109.587 26.2275 109.446 26.2281 109.305 26.2288C109.466 26.2281 109.627 26.2275 109.789 26.2275C110.949 26.2275 112.109 26.2541 113.269 26.3078C113.499 26.3261 113.688 26.3476 113.853 26.3716C113.853 26.3723 113.853 26.3729 113.854 26.3735Z"
        fill="#89A8DD"
      />
      <path
        d="M99.7341 59.7886L99.6507 59.7759C99.8036 58.4668 100.491 55.2669 100.668 50.6701C100.737 48.8989 100.73 46.9203 100.589 44.7622C100.328 40.8677 98.5811 37.3973 98.15 31.4742C97.9951 29.361 99.394 27.4235 101.472 26.9292C102.483 26.6814 103.501 26.4836 104.561 26.4058C106.14 26.2983 107.722 26.2383 109.305 26.2282C109.446 26.2275 109.587 26.2269 109.729 26.2269C110.909 26.2269 112.09 26.2547 113.269 26.3104C113.5 26.3287 113.688 26.3495 113.854 26.3729C114.177 26.4191 114.41 26.4741 114.691 26.5341C114.71 26.5386 114.729 26.5423 114.748 26.5468C113.605 26.9652 112.707 27.9621 112.478 29.246C112.441 29.4565 112.423 29.6657 112.423 29.8718C112.423 31.552 113.626 33.0406 115.338 33.346C116.022 33.4673 116.707 33.5697 117.394 33.6671L120.479 39.4896C120.253 40.8045 119.885 42.3664 119.334 44.2534C119.01 45.3526 118.903 47.0853 118.903 48.9178C118.901 52.6885 119.357 56.9086 119.369 57.0192C119.367 57.0199 119.365 57.0205 119.364 57.0211C119.364 57.0205 119.363 57.0205 119.363 57.0199L103.314 59.1994L99.7898 59.6539C99.7898 59.6539 99.7701 59.7001 99.7341 59.7886Z"
        fill="#537BC6"
      />
      <path
        d="M116.451 26.3657C117.904 26.5705 119.343 26.8606 120.79 27.1046C122.231 27.3828 123.671 27.6685 125.107 27.977L124.586 27.9365C125.228 27.9643 125.792 28.0143 126.148 28.037C126.319 28.0497 126.44 28.0459 126.395 28.0724C126.386 28.087 126.302 28.1135 126.204 28.1767C126.081 28.2425 125.933 28.3493 125.772 28.513C125.442 28.8449 125.309 29.2893 125.318 29.4214C125.315 29.5649 125.361 29.5314 125.368 29.4195C125.393 29.1919 125.344 28.7551 125.25 28.307L125.316 28.4928C124.587 26.3891 123.895 24.2708 123.261 22.1298C122.646 19.9812 122.055 17.8231 121.635 15.598C121.474 14.744 122.036 13.9203 122.89 13.7591C123.543 13.6365 124.18 13.9393 124.515 14.469C125.719 16.3869 126.752 18.3718 127.76 20.3668C128.75 22.3681 129.681 24.3928 130.576 26.4321L130.641 26.6185C130.885 27.2633 131.125 27.9416 131.269 28.8537C131.334 29.3114 131.381 29.8563 131.276 30.5637C131.179 31.2558 130.8 32.2476 130.052 32.9765C129.687 33.3425 129.292 33.5998 128.916 33.797C128.557 33.9772 128.203 34.1049 127.915 34.1832C127.313 34.3495 126.861 34.3975 126.462 34.4393C125.672 34.5138 125.091 34.517 124.586 34.5473L124.506 34.5518L124.066 34.5056C122.605 34.3526 121.148 34.1769 119.692 33.9923C118.24 33.7749 116.783 33.6029 115.338 33.3463C113.416 33.0037 112.136 31.168 112.479 29.2463C112.812 27.3689 114.578 26.1046 116.451 26.3657Z"
        fill="#4870C0"
      />
      <path
        d="M124.506 34.5518L124.385 34.5391L124.227 34.5227L124.066 34.5056C122.914 34.3855 121.765 34.2509 120.617 34.1086C120.592 33.9759 120.564 33.8469 120.536 33.7218L116.775 32.4992L117.394 33.6674C116.707 33.5701 116.022 33.4677 115.338 33.3463C113.626 33.041 112.424 31.5523 112.423 29.8721C112.423 29.666 112.441 29.4568 112.479 29.2463C112.783 27.5339 114.279 26.3316 115.961 26.3316C116.123 26.3316 116.286 26.3429 116.451 26.3657C117.904 26.5705 119.343 26.8606 120.79 27.1046C122.202 27.3771 123.613 27.6571 125.021 27.9593C125.059 27.9612 125.096 27.9637 125.134 27.9656C124.88 27.2235 124.629 26.4795 124.385 25.7329C124.332 25.5711 124.28 25.4093 124.227 25.2474C123.893 24.2126 123.57 23.1741 123.261 22.1298C122.933 20.9869 122.613 19.8415 122.322 18.6847L126.178 17.3288C126.725 18.3338 127.246 19.3484 127.76 20.3668C128.75 22.3681 129.681 24.3928 130.576 26.4321L130.641 26.6185C130.885 27.2633 131.125 27.9416 131.269 28.8537C131.307 29.1186 131.338 29.4119 131.337 29.7495C131.337 29.9954 131.32 30.2653 131.276 30.5637C131.179 31.2558 130.8 32.2476 130.052 32.9765C129.687 33.3425 129.292 33.5998 128.916 33.797C128.557 33.9772 128.203 34.1049 127.915 34.1832C127.313 34.3495 126.861 34.3975 126.462 34.4393C125.672 34.5138 125.091 34.517 124.586 34.5473L124.506 34.5518ZM125.318 29.4334C125.318 29.4916 125.327 29.5169 125.337 29.5169C125.349 29.5169 125.364 29.4796 125.368 29.4195C125.373 29.3803 125.375 29.3348 125.375 29.2842C125.375 29.2463 125.373 29.2046 125.371 29.161C125.334 29.2672 125.317 29.3557 125.317 29.4081C125.317 29.4126 125.318 29.417 125.318 29.4214C125.318 29.4252 125.318 29.4296 125.318 29.4334ZM121.653 15.6928C121.647 15.6612 121.641 15.6296 121.635 15.598C121.624 15.5405 121.617 15.4836 121.612 15.4267L121.657 15.598C121.666 15.6271 121.677 15.6549 121.689 15.6821L121.653 15.6928Z"
        fill="#537BC6"
      />
      <path
        d="M142.591 12.7568L161.543 12.1759L161.252 6.23894L142.338 7.48423L142.591 12.7568Z"
        fill="#4870C0"
      />
      <path
        d="M128.267 12.9854L143.592 12.4917L143.359 7.62362L128.061 8.61038L128.267 12.9854Z"
        fill="#4870C0"
      />
      <path
        d="M141.568 12.6163L142.573 12.7231L142.324 7.52135L141.334 7.72426L141.568 12.6163Z"
        fill="#1D2A30"
      />
      <path
        d="M142.573 12.7231L141.568 12.6163L141.566 12.5569L141.336 7.7546L141.334 7.72426L142.323 7.52135L142.415 9.43544L142.573 12.7231Z"
        fill="#182124"
      />
      <path
        d="M142.575 12.7573L143.603 12.7257L143.349 7.41773L142.322 7.48537L142.575 12.7573Z"
        fill="#1D2A30"
      />
      <path
        d="M125.887 12.2657L127.408 12.9712L127.207 8.70621L125.76 9.55516L125.887 12.2657Z"
        fill="#1D2A30"
      />
      <path
        d="M118.099 12.2129L122.606 11.9784L122.512 9.9821L118.008 10.2514L118.099 12.2129Z"
        fill="#1D2A30"
      />
      <path
        d="M118.099 12.2129L118.008 10.2514L121.197 10.0605L120.803 11.8602C120.787 11.9303 120.779 12.0018 120.777 12.0732L118.099 12.2129Z"
        fill="#182124"
      />
      <path
        d="M121.99 12.3809L127.004 12.1881L126.872 9.39405L121.864 9.69053L121.99 12.3809Z"
        fill="#1D2A30"
      />
      <path
        d="M127.408 12.9712L125.887 12.2657L125.885 12.231L127.004 12.188L126.872 9.39396L125.941 9.44897L127.207 8.70621L127.408 12.9712Z"
        fill="#182124"
      />
      <path
        d="M125.885 12.231L125.76 9.55515L125.941 9.44895L126.872 9.39395L127.004 12.188L125.885 12.231Z"
        fill="#182124"
      />
      <path
        d="M127.435 13.0117L128.267 12.9852L128.06 8.61021L127.23 8.66394L127.435 13.0117Z"
        fill="#1D2A30"
      />
      <path
        d="M160.898 12.1963L163.871 12.0901L163.579 6.12974L160.608 6.28145L160.898 12.1963Z"
        fill="#1D2A30"
      />
      <path
        d="M162.216 12.6177L166.009 12.5115L166.104 12.2492C166.914 10.0007 166.753 7.5164 165.659 5.39181L161.875 5.65287L161.958 5.85136C162.856 8.00249 162.948 10.4046 162.216 12.6177Z"
        fill="#4870C0"
      />
      <path
        d="M158.183 7.54736C158.145 7.33434 158.159 7.11499 158.176 6.89816L159.711 6.80335L159.742 7.45761L158.183 7.54736Z"
        fill="#8DAEE0"
      />
      <path
        d="M143.952 8.36914L143.92 7.77811L157.248 6.97024C157.231 7.18643 157.222 7.40325 157.255 7.61628L143.952 8.36914Z"
        fill="#8DAEE0"
      />
      <path
        d="M128.413 9.48633L128.385 8.95535L140.885 8.16328L140.912 8.74358L128.413 9.48633Z"
        fill="#8DAEE0"
      />
      <path
        d="M162.247 6.521L162.21 5.85789L165.179 5.64929L165.211 6.32505L162.247 6.521Z"
        fill="#8DAEE0"
      />
      <path
        d="M162.562 12.418L162.525 11.7549L165.627 11.6354C165.607 11.8617 165.596 12.0893 165.632 12.3124L162.562 12.418Z"
        fill="#2E53AC"
      />
      <path
        d="M143.863 12.5034L143.83 11.913L160.128 11.2663C160.111 11.4851 160.101 11.7057 160.135 11.9218L143.863 12.5034Z"
        fill="#2E53AC"
      />
      <path
        d="M128.679 12.8428L128.651 12.3112L140.762 11.8377C140.749 12.0311 140.742 12.2258 140.77 12.4174L128.679 12.8428Z"
        fill="#2E53AC"
      />
      <path
        d="M117.086 10.3267L117.125 12.2364C117.132 12.5607 117.426 12.8186 117.783 12.8129L119.333 12.7864L119.268 9.65857L117.719 9.71546C117.362 9.72873 117.079 10.0024 117.086 10.3267Z"
        fill="#4870C0"
      />
      <path
        d="M125.095 15.6227L125.684 12.0651C125.708 11.9172 125.713 11.7674 125.7 11.6175L125.531 9.73253C125.504 9.48853 125.332 9.24895 125.067 9.16741L122.225 9.1339C121.85 9.0157 121.438 9.19585 121.313 9.52962L120.803 11.8603C120.764 12.0354 120.768 12.2155 120.814 12.3887L121.657 15.5981C121.739 15.8655 121.986 16.0747 122.292 16.1335L124.149 16.1505C124.586 16.234 125.006 16.0014 125.095 15.6227Z"
        fill="#E76454"
      />
      <path
        d="M126.766 17.121L121.934 18.8208L121.129 15.8523L125.278 14.5893L126.766 17.121Z"
        fill="#4870C0"
      />
      <path
        d="M121.934 18.8208L121.129 15.8523L125.278 14.5893L126.766 17.121L126.178 17.3283L122.322 18.6843L121.934 18.8208Z"
        fill="#799BD7"
      />
      <path
        d="M120.479 39.4902L117.394 33.6677C118.16 33.7764 118.927 33.8782 119.692 33.9926C120 34.0324 120.309 34.071 120.617 34.1089C120.73 34.6949 120.805 35.3656 120.805 36.1791C120.806 37.0812 120.714 38.1583 120.479 39.4902Z"
        fill="#4067BA"
      />
      <path
        d="M120.617 34.1079C120.309 34.07 120 34.0314 119.692 33.9916C118.927 33.8772 118.16 33.7754 117.394 33.6667L116.775 32.4985L120.536 33.7211C120.564 33.8462 120.592 33.9752 120.617 34.1079Z"
        fill="#4067BA"
      />
      <path
        d="M139.572 97.5248L138.807 107.019L143.128 107.102L144.314 97.32L139.572 97.5248Z"
        fill="#E76454"
      />
      <path
        d="M143.813 105.804L138.883 105.904C138.697 105.908 138.537 106.049 138.498 106.244L137.649 110.568C137.572 110.951 137.797 111.327 138.153 111.407L138.292 111.422C140.069 111.353 141.648 111.217 143.861 111.175C146.472 111.123 145.813 111.472 148.883 111.457C150.732 111.446 151.049 109.182 150.263 109.087C146.669 108.674 146.285 107.518 144.887 106.167C144.583 105.908 144.201 105.779 143.813 105.804Z"
        fill="#1D2A30"
      />
      <path
        d="M92.3786 138.215L90.6308 146.936L95.2776 148.355L97.516 139.448L92.3786 138.215Z"
        fill="#E76454"
      />
      <path
        d="M117.241 57.402C117.241 57.402 110.627 96.0023 107.034 107.362C104.476 115.451 97.6152 141.915 97.6152 141.915L91.3919 140.535C91.3919 140.535 90.696 122.521 94.1575 113.223C95.3383 110.051 96.8788 107.19 97.2966 105.223C99.0786 96.8209 95.4053 81.579 97.0293 70.2108C97.9098 64.0488 99.7898 59.6542 99.7898 59.6542L117.241 57.402Z"
        fill="#1D2A30"
      />
      <path
        d="M107.034 107.362C104.476 115.451 97.6152 141.915 97.6152 141.915L91.3919 140.535C91.3919 140.535 90.696 122.521 94.1575 113.223C95.3383 110.051 96.8788 107.19 97.2966 105.223C99.0786 96.8209 95.4053 81.579 97.0293 70.2108C97.9098 64.0488 99.7898 59.6542 99.7898 59.6542"
        fill="#1D2A30"
      />
      <path
        d="M112.461 82.4077L109.67 71.4491C111.954 72.4997 113.522 72.6856 114.348 72.7159L112.461 82.4077Z"
        fill="#182124"
      />
      <path
        d="M144.617 102.079L138.536 102.274C138.536 102.274 138.984 94.3159 138.984 82.7106C138.984 80.9211 139.262 79.1574 139.654 77.5771C140.521 74.0315 141.924 71.3885 141.924 71.3885C141.924 71.3885 141.273 71.4265 140.161 71.4777C136.721 71.6541 115.405 72.7552 114.987 72.7236C114.947 72.7198 114.879 72.7205 114.784 72.7211C114.146 72.7255 112.286 72.7306 109.262 71.2558C102.809 68.1103 102.744 59.277 102.744 59.277L119.363 57.0203C119.363 57.0329 119.635 57.0714 123.637 57.1403C135.767 57.349 150.674 57.8186 153.291 61.9837C156.971 67.8417 144.617 102.079 144.617 102.079Z"
        fill="#1D2A30"
      />
      <path
        d="M95.5102 146.097L90.4109 146.166C90.2187 146.169 90.0613 146.31 90.0322 146.508L89.3976 150.866C89.3388 151.253 89.5935 151.633 89.9665 151.717L90.1112 151.733C91.9476 151.675 93.5753 151.548 95.8642 151.52C98.5647 151.484 97.9022 151.832 101.081 151.836C102.995 151.837 103.195 149.553 102.376 149.453C98.6311 149.013 98.1677 147.844 96.6437 146.47C96.315 146.207 95.9117 146.075 95.5102 146.097Z"
        fill="#1D2A30"
      />
      <path
        d="M139.654 77.5767C140.521 74.031 141.924 71.3881 141.924 71.3881C141.924 71.3881 141.273 71.426 140.161 71.4772C140.11 71.4576 140.06 71.445 139.996 71.445L144.429 71.072L139.654 77.5767Z"
        fill="#182124"
      />
      <path d="M202.148 5.6123H203.926V10.8368" stroke="white" strokeWidth="0.533333" />
      <path d="M209.852 5.6123H208.074V10.8368" stroke="white" strokeWidth="0.533333" />
      <path d="M206.296 3.97949V10.8367" stroke="white" strokeWidth="0.533333" />
      <ellipse cx="206.296" cy="3.65306" rx="0.592593" ry="0.653062" fill="white" />
      <ellipse cx="209.852" cy="5.61205" rx="0.592593" ry="0.653062" fill="white" />
      <ellipse cx="202.148" cy="5.61205" rx="0.592593" ry="0.653062" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198 10.1836V11.217C198 11.4541 198.146 11.6609 198.355 11.7179L200.191 12.2153C200.302 12.6043 200.447 12.9821 200.622 13.3448L199.804 14.8482C199.693 15.0515 199.722 15.3118 199.874 15.4792L201.2 16.9402C201.351 17.1072 201.588 17.139 201.773 17.0179C201.773 17.0179 202.69 16.4115 203.14 16.1149C203.465 16.3061 203.806 16.464 204.157 16.5866L204.608 18.6086C204.66 18.8387 204.847 19.0001 205.062 19.0001H206.938C207.153 19.0001 207.34 18.8387 207.392 18.6087L207.843 16.5866C208.204 16.4605 208.552 16.2975 208.883 16.0998C209.345 16.405 210.272 17.0179 210.272 17.0179C210.456 17.1395 210.693 17.1082 210.844 16.9402L212.17 15.4792C212.322 15.3118 212.351 15.0515 212.241 14.8482L211.398 13.3009C211.564 12.9533 211.702 12.5896 211.809 12.2153L213.645 11.7179C213.854 11.6604 214 11.4541 214 11.217V10.1836H209.281C209.281 10.1837 209.281 10.1837 209.281 10.1838C209.281 12.1775 207.809 13.7999 206 13.7999C204.191 13.7999 202.719 12.1775 202.719 10.1838C202.719 10.1837 202.719 10.1837 202.719 10.1836H198Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.35 10.1831C203.497 11.7676 204.627 13.0001 206 13.0001C207.373 13.0001 208.503 11.7676 208.65 10.1831H203.35Z"
        fill="white"
      />
    </svg>
  );
};

export default PredictionJobDefaultIcon;
