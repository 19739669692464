export const DEFAULT_VISIBLE_COLUMNS = {
  // entityFeatures: ["Name", "Type", "Collection", "Source", "Lineage", "ID?", "Timestamp?"]
  // Hiding a few "columns" as part of RC-1096.
  entityFeatures: ["Name", "Type"]
};

export const ALL_COLUMNS = {
  // Hiding a few "columns" as part of RC-1096.
  entityFeatures: [
    ...DEFAULT_VISIBLE_COLUMNS.entityFeatures
    // "Sparse",
    // "Ontology",
    // "Concept",
    // "RefEntity",
    // "Secure?",
    // "Target?",
    // "Format",
    // "Meaning"
  ]
};

const collator = new Intl.Collator("en");
const sortColumnsAtoZ = (tableColumns: $TSFixMe) =>
  tableColumns.sort((a: $TSFixMe, b: $TSFixMe) => collator.compare(a.id, b.id));
const sortColumnsZtoA = (tableColumns: $TSFixMe) =>
  tableColumns.sort((a: $TSFixMe, b: $TSFixMe) => collator.compare(b.id, a.id));

export const COLUMN_ORDERS = {
  ascendant: {
    key: "ascendant",
    sortingFunction: (tableColumns: $TSFixMe) => sortColumnsAtoZ(tableColumns)
  },
  descendant: {
    key: "descendant",
    sortingFunction: (tableColumns: $TSFixMe) => sortColumnsZtoA(tableColumns)
  },
  default: { key: "default", sortingFunction: null }
};
