import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
  opacity?: number;
};

const SplashIcon = ({ width = 242, height = 216, viewBox = "" }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill="none">
    <path d="M186.908 166.123H43.2046V58.4688H186.908V166.123Z" fill="white" />
    <path
      d="M186.908 166.123H43.2046V58.4688H186.908V166.123ZM44.6861 59.9502V164.642H185.427V59.9502H44.6861Z"
      fill="#E6E6E6"
    />
    <path
      d="M115.012 139.862C130.124 139.862 142.374 127.611 142.374 112.499C142.374 97.3873 130.124 85.1367 115.012 85.1367C99.8995 85.1367 87.6489 97.3873 87.6489 112.499C87.6489 127.611 99.8995 139.862 115.012 139.862Z"
      fill="#C7C7C7"
    />
    <path
      d="M126.374 110.203H117.071V100.9C117.071 100.319 116.84 99.7626 116.43 99.3521C116.019 98.9415 115.462 98.7109 114.882 98.7109C114.301 98.7109 113.744 98.9415 113.334 99.3521C112.923 99.7626 112.693 100.319 112.693 100.9V110.203H103.389C102.809 110.203 102.252 110.434 101.842 110.845C101.431 111.255 101.2 111.812 101.2 112.392C101.2 112.973 101.431 113.53 101.842 113.94C102.252 114.351 102.809 114.582 103.389 114.582H112.693V123.885C112.693 124.465 112.923 125.022 113.334 125.433C113.744 125.843 114.301 126.074 114.882 126.074C115.462 126.074 116.019 125.843 116.43 125.433C116.84 125.022 117.071 124.465 117.071 123.885V114.582H126.374C126.955 114.582 127.512 114.351 127.922 113.94C128.333 113.53 128.563 112.973 128.563 112.392C128.563 111.812 128.333 111.255 127.922 110.845C127.512 110.434 126.955 110.203 126.374 110.203Z"
      fill="white"
    />
    <path d="M55.0582 58.0206H1.35986V17.5H55.0582V58.0206Z" fill="white" />
    <path
      d="M55.0582 58.0206H1.35986V17.5H55.0582V58.0206ZM2.34751 57.033H54.0705V18.4877H2.34751V57.033Z"
      fill="#E6E6E6"
    />
    <path d="M47.9753 28.5352H8.44238V31.1707H47.9753V28.5352Z" fill="#E4E4E4" />
    <path d="M47.9753 36.4414H8.44238V39.0769H47.9753V36.4414Z" fill="#E4E4E4" />
    <path d="M47.9753 44.3477H8.44238V46.9833H47.9753V44.3477Z" fill="#E4E4E4" />
    <path d="M85.4616 81.7233H25.5571V36.5195H85.4616V81.7233Z" fill="white" />
    <path
      d="M85.4616 81.7233H25.5571V36.5195H85.4616V81.7233ZM26.6589 80.6215H84.3598V37.6213H26.6589V80.6215Z"
      fill="#E6E6E6"
    />
    <path d="M77.5604 48.832H33.4585V51.7721H77.5604V48.832Z" fill="#4646B5" />
    <path d="M77.5604 57.6523H33.4585V60.5925H77.5604V57.6523Z" fill="#4646B5" />
    <path d="M77.5604 66.4727H33.4585V69.4128H77.5604V66.4727Z" fill="#4646B5" />
    <path d="M240.924 200.06H149.508V131.078H240.924V200.06Z" fill="white" />
    <path
      d="M240.924 200.06H149.508V131.078H240.924V200.06ZM151.19 198.379H239.242V132.76H151.19V198.379Z"
      fill="#E6E6E6"
    />
    <path d="M228.866 149.867H161.565V154.354H228.866V149.867Z" fill="#4646B5" />
    <path d="M228.866 163.328H161.565V167.814H228.866V163.328Z" fill="#4646B5" />
    <path d="M228.866 176.789H161.565V181.275H228.866V176.789Z" fill="#4646B5" />
  </svg>
);

export default SplashIcon;
