import React from "react";

export const LimitsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.50033 18.3334H12.5003C16.667 18.3334 18.3337 16.6667 18.3337 12.5001V7.50008C18.3337 3.33341 16.667 1.66675 12.5003 1.66675H7.50033C3.33366 1.66675 1.66699 3.33341 1.66699 7.50008V12.5001C1.66699 16.6667 3.33366 18.3334 7.50033 18.3334Z"
        stroke="#515151"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9756 15.4167V12.1667"
        stroke="#515151"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9756 6.20825V4.58325"
        stroke="#515151"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9753 10.5416C14.1718 10.5416 15.1419 9.57154 15.1419 8.37496C15.1419 7.1783 14.1718 6.20825 12.9753 6.20825C11.7786 6.20825 10.8086 7.1783 10.8086 8.37496C10.8086 9.57154 11.7786 10.5416 12.9753 10.5416Z"
        stroke="#515151"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02441 15.4167V13.7917"
        stroke="#515151"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02441 7.83325V4.58325"
        stroke="#515151"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.02507 13.7918C8.22168 13.7918 9.19176 12.8217 9.19176 11.6252C9.19176 10.4286 8.22168 9.4585 7.02507 9.4585C5.82845 9.4585 4.8584 10.4286 4.8584 11.6252C4.8584 12.8217 5.82845 13.7918 7.02507 13.7918Z"
        stroke="#515151"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
