import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { areEqual, ListChildComponentProps } from "react-window";

import DragItem from "./DragItem";
import { IColumn } from "../TableSettingsMenu";

export interface IItemData {
  items: IColumn[];
  isColumnLimitReached: boolean;
  onChange: (event: React.ChangeEvent<{}>, checked: boolean) => void;
}

const ReactWindowRow: React.FC<ListChildComponentProps<IItemData>> = (props) => {
  const { data, index, style } = props;
  const { items, isColumnLimitReached, onChange } = data;
  const item = items[index];

  return (
    <Draggable draggableId={item.name} index={index} key={item.name}>
      {(provided) => (
        <DragItem
          provided={provided}
          item={item}
          style={style}
          isColumnLimitReached={isColumnLimitReached}
          onChange={onChange}
        />
      )}
    </Draggable>
  );
};

export default React.memo(ReactWindowRow, areEqual);
