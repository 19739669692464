import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import ReactFlow, {
  Controls,
  useNodesState,
  useEdgesState,
  useReactFlow,
  useNodes,
  BezierEdge,
  Background,
  MiniMap,
  getSmoothStepPath,
  isNode,
  getIncomers,
  getConnectedEdges
} from "react-flow-renderer";
import { keyBy } from "lodash";

import { Grid, Tooltip, IconButton } from "@material-ui/core";
import { AccountTreeOutlined, Save } from "@material-ui/icons";
import { BezierIcon } from "../../icons/BezierIcon";
import { AutoLayoutIcon } from "src/icons/NewUX";

import { getSmartEdge } from "@tisoap/react-flow-smart-edge";

import {
  EntityBlock,
  EntityBlockV2,
  TransformBlock,
  TransformBlockV2,
  ChartBlock,
  EventBlockV2,
  ArtifactBlockV2,
  ModelBlockV2
} from "./Blocks";
import styles from "./CanvasFlowChart.module.scss";
import {
  getAPIWithRethrow,
  handleResponse,
  postAPIWithRethrow,
  putAPIWithRethrow
} from "../../utils/apiService";
import Spinner from "../Spinner";
import clsx from "clsx";
import { EntityTypeEnum } from "src/types";
import { GroupBlock } from "./Blocks/GroupBlock";
import { RecipeStatuses } from "src/constants";
import { toastWrapper } from "src/utils/toastWrapper";
import { useCanvasStore } from "../../store/store";
import SnippetGenerator from "src/pages/private/ProjectsModule/pages/Dag/components/SnippetGenerator";

const foreignObjectSize = 100;

const smartEdgeOptions = {
  nodePadding: 40,
  gridRatio: 10
};

const FixedSmoothStepEdge = (props: $TSFixMe) => {
  const edgePath = getSmoothStepPath({
    ...props,
    centerX: props?.sourceX + 65,
    borderRadius: 12
  });
  return (
    <path
      id={props?.id}
      style={props?.style}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={props?.markerEnd}
    />
  );
};

const SmartEdgeWithButtonLabel = (props: $TSFixMe) => {
  const {
    // id,
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    style,
    markerStart,
    markerEnd
  } = props;

  // const { projectId } = useParams<$TSFixMe>();

  const nodes = useNodes();
  // const edges = useEdges();
  // const setCanvasEdges = useCanvasStore((state) => state.setEdges);
  // const setReloadTrigger = useCanvasStore((state) => state.setReloadTrigger);

  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
    options: smartEdgeOptions
  });

  // If the value returned is null, it means "getSmartEdge" was unable to find
  // a valid path, and you should do something else instead
  if (getSmartEdgeResponse === null) {
    return <BezierEdge {...props} />;
  }

  const { edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse;

  // TODO: Uncomment if disconnecting nodes is enabled
  // const handleRemoveEdge = useCallback(
  //   async (event) => {
  //     event.stopPropagation();

  //     const edge = edges.find((edge) => edge.id === id);

  //     const [edgeTargetNode] = nodes.filter((node) => edge.target === node.id);
  //     const [edgeSourceNode] = nodes.filter((node) => edge.source === node.id);

  //     edgeTargetNode.isSource = false;
  //     edgeTargetNode.isTarget = true;
  //     edgeSourceNode.isSource = true;
  //     edgeSourceNode.isTarget = false;

  //     const edgesWithSameTargetNode = edges.filter((edge) => edge.target === edgeTargetNode.id);

  //     const sourceIdsForEdgesWithSameTargetNode = edgesWithSameTargetNode.map(
  //       (edge) => edge.source
  //     );

  //     const sourceIdsForEdgesWithSameTargetNodeWithoutDuplicates = Object.values(
  //       sourceIdsForEdgesWithSameTargetNode.reduce((acc, curr) => {
  //         acc[curr] = curr;
  //         return acc;
  //       }, {})
  //     );

  //     if (sourceIdsForEdgesWithSameTargetNodeWithoutDuplicates.length <= 0) {
  //      toastWrapper({
  //        type: "error",
  //        content: "Target of to be removed edge must have a parent"
  //      });
  //       return;
  //     }

  //     const edgeNodes = [edgeTargetNode, edgeSourceNode];

  //     const datasetNode = edgeNodes.find((node) => node.type === "entityV2");
  //     const dfsRunConfigGroupNode = edgeNodes.find((node) => node.type === "dfsgroupV2");

  //     try {
  //       const [[dfsGroupFromBE], [datasetFromBE]] = await Promise.all([
  //         getAPIWithRethrow(`/v2/dfs-run-config-groups?id=${dfsRunConfigGroupNode.id}`),
  //         getAPIWithRethrow(`/v2/entities?id=${datasetNode.id}`)
  //       ]);

  //       let parents = [...dfsGroupFromBE.parents];
  //       let children = [...dfsGroupFromBE.children];

  //       const objectToBeRemoved = {
  //         id: datasetFromBE.id
  //       };

  //       if (datasetNode.isSource) {
  //         parents = parents.filter((parent) => parent.id !== objectToBeRemoved.id);
  //       }

  //       if (datasetNode.isTarget) {
  //         children = children.filter((child) => child.id !== objectToBeRemoved.id);
  //       }

  //       await putAPIWithRethrow(`/v2/dfs-run-config-groups/${dfsGroupFromBE.id}`, {
  //         id: dfsGroupFromBE.id,
  //         displayName: dfsGroupFromBE.displayName,
  //         runConfigs: dfsGroupFromBE.runConfigs.map((runConfig) => runConfig.id),
  //         parents,
  //         children
  //       });
  //       // setCanvasEdges(edges.filter((edge) => edge.id !== id));
  //       setReloadTrigger();
  //     } catch (e) {
  //      toastWrapper({
  //        type: "error",
  //        content: "Edge removal failed"
  //      });
  //     }
  //   },
  //   [setCanvasEdges, nodes, edges, id, projectId, setReloadTrigger]
  // );

  return (
    <>
      <path
        style={style}
        className="react-flow__edge-path"
        d={svgPathString}
        markerEnd={markerEnd}
        markerStart={markerStart}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        className={styles.foreignObject}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml">
        {/* <div className={styles.smartEdgeButtonHoverArea}>
          <button className={styles.smartEdgeButton} onClick={handleRemoveEdge}>
            <ClearIcon />
          </button>
        </div> */}
      </foreignObject>
    </>
  );
};

const nodeTypes = {
  entity: EntityBlock,
  dfsgroup: TransformBlock,
  entityV2: EntityBlockV2,
  eventV2: EventBlockV2,
  baseV2: EntityBlockV2,
  dfsgroupV2: TransformBlockV2,
  chartV2: ChartBlock,
  collapsedgroupV2: GroupBlock,
  artifactV2: ArtifactBlockV2,
  modelV2: ModelBlockV2
};
const edgeTypes = {
  smoothstep: FixedSmoothStepEdge,
  smart: SmartEdgeWithButtonLabel
};

const CanvasFlowChart = ({
  projectId,
  projectName,
  nodes: initialNodes,
  edges: initialEdges,
  jobProps,
  nodesDraggable = true,
  disableItemsUpdate = false
}: $TSFixMe) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [showMinimap, setShowMinimap] = useState(true);
  const projectEdgeType =
    JSON.parse(localStorage.getItem("projectConfig") || "{}")?.[projectId]?.edgeType ||
    localStorage.getItem("edgeType") ||
    "smoothstep";
  const [edgeType, setEdgeType] = useState(projectEdgeType);
  const timeoutMap = useRef(null);
  const [isSaveNodesInProgress, setIsSaveNodesInProgress] = useState(false);

  const [beforeDragPositions, setBeforeDragPositions] = useState(null);

  const proOptions = {
    account: "paid-pro",
    hideAttribution: true
  };

  const { zoomTo, setCenter } = useReactFlow();

  // // every time our nodes change, we want to center the graph again
  // useEffect(() => {
  //   fitView({ duration: 400 });
  // }, [nodes, fitView]);

  useEffect(() => {
    setNodes(initialNodes);
  }, [initialNodes, setNodes]);

  useEffect(() => {
    setEdges(() => {
      return initialEdges?.map((elem: $TSFixMe) => {
        elem.type = edgeType;

        return elem;
      });
    });
  }, [setEdges, initialEdges, edgeType]);

  useEffect(() => {
    setTimeout(() => {
      zoomTo(1);
    }, 10);
  }, [zoomTo]);

  useEffect(() => {
    sessionStorage.setItem("selectedEntities", "");
    sessionStorage.setItem("selectedArtifacts", "");
    sessionStorage.setItem("selectedModels", "");
  }, []);

  const setShouldBlockClickHandlerTrigger = useCanvasStore(
    (state) => state.setShouldBlockClickHandlerTrigger
  );
  const setReloadTrigger = useCanvasStore((state) => state.setReloadTrigger);

  const canvasNodes = useCanvasStore((state) => state.nodes);
  const setCanvasNodes = useCanvasStore((state) => state.setNodes);
  // const setCanvasEdges = useCanvasStore((state) => state.setEdges);
  const nodeToFocus = useCanvasStore((state) => state.nodeToFocus);

  useEffect(() => {
    let focusNode;
    if (nodeToFocus?.length > 0) {
      focusNode = nodes?.find((node) => (node as $TSFixMe)?.data?.displayName === nodeToFocus);
    }
    if (focusNode) {
      setCenter((focusNode as $TSFixMe)?.position?.x, (focusNode as $TSFixMe)?.position?.y, {
        zoom: 1,
        duration: 500
      });
    }
  }, [nodeToFocus]);

  const formatNodesToCanvas = (nodes: $TSFixMe) => {
    const nodeTypes = {
      entityV2: "entity",
      dfsgroupV2: "dfsgroup",
      chartV2: "chart",
      collapsedgroupV2: "collapsedGroup",
      artifactV2: "artifact",
      modelV2: "model"
    };
    const canvasNodeIdNodeMap = canvasNodes.reduce(
      (acc: $TSFixMe, node: $TSFixMe) => ({ ...acc, [node.id]: node }),
      {}
    );
    return nodes.map((node: $TSFixMe) => ({
      ...canvasNodeIdNodeMap[node.id],
      ...node,
      id: node.id,
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      type: nodeTypes[node.type],
      column: node.position.x / 180,
      row: node.position.y / 180,
      displayName: node.data.displayName,
      name: node.data.name,
      status: node.data.status,
      projectId: node.projectId
    }));
  };

  const handleNodeDragStop = useCallback(
    async (__: $TSFixMe, node: $TSFixMe) => {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      if (node.position.x === beforeDragPositions.x && node.position.y === beforeDragPositions.y) {
        setShouldBlockClickHandlerTrigger(false);
      } else {
        setShouldBlockClickHandlerTrigger(true);
      }

      const collidingBlocks = nodes.filter(
        (nd) =>
          nd.position.x === node.position.x &&
          nd.position.y === node.position.y &&
          nd.id !== node.id
      );

      if (collidingBlocks.length) {
        let nodesToSet = null;

        setNodes((nds) => {
          nodesToSet = nds.map((nd) => {
            if (nd.id === node.id) {
              // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'XYPosition'... Remove this comment to see the full error message
              nd.position = beforeDragPositions;
            }
            return nd;
          });
          return nodesToSet;
        });

        setCanvasNodes(formatNodesToCanvas(nodesToSet));

        toastWrapper({ type: "error", content: "Blocks cannot collide" });

        return;
      }

      const parentEdges = edges.filter((edge) => edge.target === node.id);
      const parentBlocks = parentEdges.map((edge) => nodes.find((nd) => nd.id === edge.source));
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const parentBlocksMaxXPosition = Math.max(...parentBlocks.map((block) => block.position.x));

      const childrenEdges = edges.filter((edge) => edge.source === node.id);
      const childrenBlocks = childrenEdges.map((edge) => nodes.find((nd) => nd.id === edge.target));
      const childrenBlocksMinXPosition = Math.min(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        ...childrenBlocks.map((block) => block.position.x)
      );

      if (node.position.x <= parentBlocksMaxXPosition) {
        let nodesToSet = null;

        setNodes((nds) => {
          nodesToSet = nds.map((nd) => {
            if (nd.id === node.id) {
              // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'XYPosition'... Remove this comment to see the full error message
              nd.position = beforeDragPositions;
            }
            return nd;
          });
          return nodesToSet;
        });

        setCanvasNodes(formatNodesToCanvas(nodesToSet));

        toastWrapper({
          type: "error",
          content: "Block cannot be moved on the left side of the parent block"
        });

        return;
      }

      if (node.position.x >= childrenBlocksMinXPosition) {
        let nodesToSet = null;

        setNodes((nds) => {
          nodesToSet = nds.map((nd) => {
            if (nd.id === node.id) {
              // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'XYPosition'... Remove this comment to see the full error message
              nd.position = beforeDragPositions;
            }
            return nd;
          });
          return nodesToSet;
        });

        setCanvasNodes(formatNodesToCanvas(nodesToSet));

        toastWrapper({
          type: "error",
          content: "Block cannot be moved on the right side of the children block"
        });

        return;
      }

      try {
        await putAPIWithRethrow(
          `/v2/project-canvas/${projectId}`,
          nodes.map((node) => ({
            id: node.id,
            positionX: node.position.x / 180,
            positionY: node.position.y / 180
          }))
        );

        let nodesToSet = null;

        setNodes((nds) => {
          nodesToSet = nds.map((nd) => {
            return nd;
          });
          return nodesToSet;
        });

        setCanvasNodes(formatNodesToCanvas(nodesToSet));
      } catch (e) {
        let nodesToSet = null;

        setNodes((nds) => {
          nodesToSet = nds.map((nd) => {
            if (nd.id === node.id) {
              // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'XYPosition'... Remove this comment to see the full error message
              nd.position = beforeDragPositions;
            }
            return nd;
          });
          return nodesToSet;
        });

        setCanvasNodes(formatNodesToCanvas(nodesToSet));

        toastWrapper({
          type: "error",
          content: "Canvas element position update failed"
        });

        return;
      }
    },
    [
      edges,
      nodes,
      setCanvasNodes,
      beforeDragPositions,
      setNodes,
      setShouldBlockClickHandlerTrigger,
      projectId
    ]
  );

  const handleConnect = useCallback(
    async ({ source, target }: $TSFixMe) => {
      const sourceNode = nodes.find((node) => node.id === source);
      const targetNode = nodes.find((node) => node.id === target);

      (sourceNode as $TSFixMe).isSource = true;
      (sourceNode as $TSFixMe).isTarget = false;
      (targetNode as $TSFixMe).isSource = false;
      (targetNode as $TSFixMe).isTarget = true;

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const sourceNodeType = sourceNode.type;
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const targetNodeType = targetNode.type;

      if (sourceNodeType === "chartV2" || targetNodeType === "chartV2") {
        toastWrapper({
          type: "error",
          content: "Chart blocks cannot be connected with other types of blocks"
        });
        return;
      }

      if (sourceNodeType === "entityV2" && targetNodeType === "entityV2") {
        toastWrapper({
          type: "error",
          content: "Two datasets cannot be connected"
        });
        return;
      }

      if (sourceNodeType === "dfsgroupV2" && targetNodeType === "dfsgroupV2") {
        toastWrapper({
          type: "error",
          content: "Two recipes cannot be connected"
        });
        return;
      }

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const dfsGroup = [sourceNode, targetNode].find((node) => node.type === "dfsgroupV2");
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const dataset = [sourceNode, targetNode].find((node) => node.type === "entityV2");

      try {
        const [[dfsGroupFromBE], [datasetFromBE]] = await Promise.all([
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          getAPIWithRethrow(`/v2/dfs-run-config-groups?id=${dfsGroup.id}`),
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          getAPIWithRethrow(`/v2/entities?id=${dataset.id}`)
        ]);

        let parents = [...dfsGroupFromBE.parents];
        let children = [...dfsGroupFromBE.children];

        const objectToPush = {
          id: datasetFromBE.id,
          name: datasetFromBE.name,
          type: "ENTITY",
          displayName: datasetFromBE.displayName,
          viewData: (dataset as $TSFixMe).viewData
        };

        if ((dataset as $TSFixMe).isSource) {
          parents.push(objectToPush);
        }

        if ((dataset as $TSFixMe).isTarget) {
          children.push(objectToPush);
        }

        await putAPIWithRethrow(`/v2/dfs-run-config-groups/${dfsGroupFromBE.id}`, {
          id: dfsGroupFromBE.id,
          displayName: dfsGroupFromBE.displayName,
          runConfigs: dfsGroupFromBE.runConfigs.map((runConfig: $TSFixMe) => runConfig.id),
          parents,
          children
        });

        // const edgeType = "smart";
        // const markerEnd = { type: "arrowclosed" };
        // let edgesToSet = null;

        // setEdges((edges) => {
        //   edgesToSet = addEdge(
        //     { id: Date.now(), source, target, type: edgeType, markerEnd },
        //     edges
        //   );
        //   return edgesToSet;
        // });

        // setCanvasEdges(edgesToSet);
        setReloadTrigger();
      } catch (e) {
        toastWrapper({
          type: "error",
          content: "Edge creation failed"
        });
      }
    },
    [nodes, setReloadTrigger]
  );

  const handleNodeDragStart = useCallback(
    (__: $TSFixMe, node: $TSFixMe) => {
      setShouldBlockClickHandlerTrigger(false);
      setBeforeDragPositions(node.position);
    },
    [setShouldBlockClickHandlerTrigger, setBeforeDragPositions]
  );

  const handleMinimapAppear = () => {
    if (timeoutMap.current) clearTimeout(timeoutMap.current);
    setShowMinimap(true);
    // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'null'.
    timeoutMap.current = setTimeout(() => {
      setShowMinimap(false);
      timeoutMap.current = null;
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number[]' is not assignable to p... Remove this comment to see the full error message
    }, [2500]);
  };

  const minimapStyles = clsx([styles.miniMap, showMinimap ? styles.fadeIn : styles.fadeOut]);

  const nodeColorSelection = (node: $TSFixMe) => {
    const { data } = node;
    const colorStatus = {
      UNBUILT: "#00000042",
      EMPTY: "#00000042",
      RUNNING: "#2196f3",
      PENDING: "#ff9800",
      SUCCESS: "#4caf50",
      BUILT: "#4caf50",
      ERROR: "#f44336"
    };
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return colorStatus[data.status];
  };

  const getAllIncomers = (node: $TSFixMe, nodes: $TSFixMe, edges: $TSFixMe, prevIncomers = []) => {
    const incomerNodes = getIncomers(node, nodes, edges);
    const incomerEdges = getConnectedEdges(incomerNodes, edges).filter((item) => {
      const incomerIds = incomerNodes.map((i) => i.id);
      return incomerIds.includes(item.target) || item.target === node.id;
    });
    const result = [node, ...incomerNodes, ...incomerEdges].reduce((memo, incomer) => {
      memo.push(incomer);

      if (prevIncomers.findIndex((n) => (n as $TSFixMe).id == incomer.id) == -1) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        prevIncomers.push(incomer);

        getAllIncomers(incomer, nodes, edges, prevIncomers)?.forEach((foundNode: $TSFixMe) => {
          memo.push(foundNode);
          if (prevIncomers.findIndex((n) => (n as $TSFixMe).id == foundNode.id) == -1) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            prevIncomers.push(incomer);
          }
        });
      }
      return memo;
    }, []);
    return result;
  };

  const highlightPath = (node: $TSFixMe, nodes: $TSFixMe, edges: $TSFixMe) => {
    node.selected = true;
    if (node.type === "collapsedgroupV2") return;
    if (node && [...nodes, ...edges]) {
      const allIncomers = getAllIncomers(node, nodes, edges);

      setNodes((prevElements) => {
        return prevElements?.map((elem) => {
          const incomerIds = allIncomers.map((i: $TSFixMe) => i.id);

          if (isNode(elem) && allIncomers.length > 0) {
            const highlight = elem.id === node.id || incomerIds.includes(elem.id);

            elem.style = {
              ...elem.style,
              opacity: highlight ? 1 : 0.25
            };
          }
          return elem;
        });
      });
      setEdges((prevElements) => {
        return prevElements?.map((elem) => {
          const highlight = allIncomers.find(
            (item: $TSFixMe) => item.target === elem.target && item.source === elem.source
          );

          if (highlight) {
            elem.animated = true;
            elem.selected = true;
          }

          elem.style = {
            ...elem.style,
            opacity: highlight ? 1 : 0.25
          };

          return elem;
        });
      });
    }
  };

  const resetNodeStyles = () => {
    setNodes((prevElements) => {
      return prevElements?.map((elem) => {
        elem.style = {
          ...elem.style,
          opacity: 1
        };
        return elem;
      });
    });
    setEdges((prevElements) => {
      return prevElements?.map((elem) => {
        elem.animated = false;
        elem.selected = false;

        elem.style = {
          ...elem.style,
          opacity: 1
        };

        return elem;
      });
    });
  };

  const changeEdgeType = () => {
    const newEdgeType = edgeType === "smoothstep" ? "bezier" : "smoothstep";

    setEdgeType(newEdgeType);
    const currentConfigs = JSON.parse(localStorage.getItem("projectConfig") || "{}");
    const updatedConfigs = { ...currentConfigs, [projectId]: { edgeType: newEdgeType } };
    localStorage.setItem("edgeType", newEdgeType);
    localStorage.setItem("projectConfig", JSON.stringify(updatedConfigs));
  };

  const saveCanvasNodes = useCallback(
    async ({
      payloadNodes,
      onSettled,
      onSuccess
    }: {
      payloadNodes: $TSFixMe[];
      onSettled?: () => void;
      onSuccess?: () => void;
    }) => {
      try {
        await putAPIWithRethrow(
          `/v2/project-canvas/${projectId}`,
          payloadNodes.map((node) => ({
            id: node.id,
            positionX: node.position.x / 180,
            positionY: node.position.y / 180
          }))
        );

        // set the React Flow nodes with the positions from the layout
        setNodes(payloadNodes);
        onSuccess?.();
      } catch (e) {
        setNodes(nodes);

        toastWrapper({
          type: "error",
          content: "Canvas element position update failed"
        });
      } finally {
        onSettled?.();
      }
    },
    [nodes, projectId, setNodes]
  );

  const autoLayoutNew = useCallback(async () => {
    const formattedNodes = formatNodesToCanvas(nodes);

    try {
      const minimalNodes = formattedNodes?.map((node: $TSFixMe) => ({
        id: node.id,
        column: node.column,
        row: node.row
      }));
      const minimalEdges = edges?.map((edge: $TSFixMe) => ({
        id: edge.id,
        source: edge.source,
        target: edge.target
      }));
      const updatedLayout = await postAPIWithRethrow(`/v2/rearrange-graph/${projectId}`, {
        nodes: minimalNodes,
        edges: minimalEdges
      });
      const updatedNodeIdNodeMap = keyBy(updatedLayout.nodes, "id");
      const updatedEdgeIdEdgeMap = keyBy(updatedLayout.edges, "id");
      const updatedNodes = nodes?.map((node: $TSFixMe) => {
        const updatedNode = updatedNodeIdNodeMap[node.id];
        const updatedPosition = {
          x: updatedNode.column * 180,
          y: updatedNode.row * 180
        };
        return { ...node, position: updatedPosition };
      });
      const updatedEdges = edges.map((edge: $TSFixMe) => ({
        ...edge,
        ...updatedEdgeIdEdgeMap[edge.id]
      }));
      setNodes(updatedNodes);
      setEdges(updatedEdges);
      setCanvasNodes(formatNodesToCanvas(updatedNodes));
      saveCanvasNodes({ payloadNodes: updatedNodes });
      return updatedNodes;
    } catch (e) {
      setNodes(nodes);

      toastWrapper({
        type: "error",
        content: "Canvas element position update failed"
      });
    }
  }, [
    edges,
    formatNodesToCanvas,
    nodes,
    projectId,
    saveCanvasNodes,
    setCanvasNodes,
    setEdges,
    setNodes
  ]);

  const isAnyRecipeRunning = useMemo(
    () =>
      !!canvasNodes?.find(
        (node: any) =>
          node.type === EntityTypeEnum.DFSGROUP && node.status === RecipeStatuses.Running
      ),
    [canvasNodes]
  );

  const isJobCanvasOld = () =>
    !!jobProps?.jobId &&
    !!jobProps?.canvasData &&
    !_.isEqual(
      {
        nodes: jobProps?.canvasData?.nodes || [],
        edges: jobProps?.canvasData?.edges || []
      },
      { nodes: nodes || [], edges: edges || [] }
    );

  return (
    <Grid container className={styles.canvasContainer}>
      <Grid item xs={12}>
        <ReactFlow
          id={uuidv4()}
          className={styles.flowContainer}
          proOptions={proOptions}
          edgeTypes={edgeTypes}
          deleteKeyCode={null}
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          snapToGrid
          snapGrid={[180, 180]}
          onNodeMouseEnter={(_event, node) => highlightPath(node, nodes, edges)}
          onNodeMouseLeave={() => resetNodeStyles()}
          onNodeDragStop={handleNodeDragStop}
          onNodeDragStart={handleNodeDragStart}
          onMoveStart={handleMinimapAppear}
          onConnect={handleConnect}
          // @ts-expect-error TS(2322) FIXME: Type 'false | OnChange<NodeChange>' is not assigna... Remove this comment to see the full error message
          onNodesChange={!disableItemsUpdate && onNodesChange}
          // @ts-expect-error TS(2322) FIXME: Type 'false | OnChange<EdgeChange>' is not assigna... Remove this comment to see the full error message
          onEdgesChange={!disableItemsUpdate && onEdgesChange}
          nodesDraggable={nodesDraggable}
          fitView
          fitViewOptions={{ minZoom: 0.5, maxZoom: 2 }}
          zoomTo={1}
          style={{
            ...(isJobCanvasOld() && !!jobProps?.canvasBackgroundColor
              ? { background: jobProps?.canvasBackgroundColor }
              : {})
          }}>
          {jobProps?.canvasInfo}
          <div className={styles.actionsContainer}>
            {isJobCanvasOld() && jobProps?.renderContent}
            <Tooltip title={edgeType === "smoothstep" ? "Curved lines" : "Square lines"}>
              <IconButton size="small" color="primary" onClick={changeEdgeType}>
                {edgeType === "smoothstep" ? (
                  <BezierIcon width={20} height={20} fillColor="#396083" />
                ) : (
                  <AccountTreeOutlined style={{ fontSize: 20 }} />
                )}
              </IconButton>
            </Tooltip>

            {!jobProps && (
              <>
                <Tooltip
                  title={`Auto arrange canvas nodes${
                    isAnyRecipeRunning
                      ? ". This action is disabled currently as there is a running recipe in canvas."
                      : ""
                  }`}>
                  <span>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={autoLayoutNew}
                      disabled={isAnyRecipeRunning}>
                      <AutoLayoutIcon
                        width={20}
                        height={20}
                        color="#003656"
                        {...(isAnyRecipeRunning ? { opacity: 0.5 } : {})}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title={`Save canvas nodes orientation${
                    isAnyRecipeRunning
                      ? ". This action is disabled currently as there is a running recipe in canvas."
                      : ""
                  }`}>
                  <span>
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={isSaveNodesInProgress || isAnyRecipeRunning}
                      onClick={() => {
                        setIsSaveNodesInProgress(true);
                        saveCanvasNodes({
                          payloadNodes: nodes,
                          onSuccess: () => {
                            handleResponse({
                              successMessage: `Canvas nodes saved successfully.`
                            });
                          },
                          onSettled: () => {
                            setIsSaveNodesInProgress(false);
                          }
                        });
                      }}>
                      {isSaveNodesInProgress ? (
                        <Spinner size={12} noPadding />
                      ) : (
                        <Save style={{ fontSize: 20 }} />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
                <SnippetGenerator projectId={projectId} projectName={projectName} />
              </>
            )}
          </div>
          {/* @ts-expect-error TS(2322) FIXME: Type '"lines"' is not assignable to type 'Backgrou... Remove this comment to see the full error message */}
          <Background variant="lines" gap={75} color="#39608326" />
          <Controls className={styles.controlsFlow} showInteractive={false} />
          <MiniMap
            nodeColor={nodeColorSelection}
            className={minimapStyles}
            nodeStrokeColor={nodeColorSelection}
            maskColor="#3232327a"
          />
        </ReactFlow>
      </Grid>
    </Grid>
  );
};

export default CanvasFlowChart;
