import React from "react";
import clsx from "clsx";
import { Box, Button, Grid, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { CalendarToday, Person } from "@material-ui/icons";
import { generatePath, useNavigate } from "react-router-dom";

import defaultImage from "../../assets/images/dashaboardCards/5Gpredictive_med.jpg";
import styles from "./InfoCards.module.scss";
import { Card, CardTitle, TitleText } from "src/components";
import { DataAppIcon } from "src/icons/NewUX/DataAppIcon";
import { JobIcon } from "src/icons/NewUX/JobIcon";
import { WebPaths } from "src/routing/routes";
import { dateFormat } from "src/utils/dateFormat";

interface IData {
  id: string;
  scenarioId: string;
  img: string;
  description: string;
  title: string;
  defaultScenarioId: string;
  link: string;
  imgSrc: string;
  dataSetCount: number;
  singleEntityId: string;
  dataAppCount: number;
  jobCount: number;
  updated: string;
  updater: string;
}

interface IOptions {
  label: string;
  click: () => void;
  icon: React.JSX.Element;
}

interface IProps {
  data: IData;
  options: IOptions[];
}

const useStyles = makeStyles({
  cardImg: {
    "& img": {
      width: "100%",
      borderRadius: "8px",
      height: "164px",
      minHeight: "164px",
      objectFit: "cover"
    }
  },
  actionBtn: {
    borderRadius: "4px",
    borderColor: "#4646b5",
    width: "max-content",
    marginBottom: "74px"
  },
  countText: {
    color: "#00365699",
    fontSize: "12px",
    fontWeight: 400
  },
  gap: {
    gap: "6px"
  },
  overflowText: {
    maxWidth: "calc(100% - 40px)"
  },
  detailsContainer: {
    overflow: "auto",
    height: "calc(100% - 164px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  }
});

const InfoCard: React.FC<IProps> = ({ data, options }) => {
  const classes = useStyles();
  const {
    id,
    scenarioId,
    img,
    description,
    title,
    defaultScenarioId,
    link,
    imgSrc,
    dataSetCount,
    singleEntityId,
    dataAppCount,
    jobCount,
    updated,
    updater
  } = data;
  const navigate = useNavigate();
  const renderImage = () => {
    if (imgSrc) {
      return <img src={imgSrc} alt={title} />;
    }
    // Rendering default image if img is null.
    if (!img) {
      return <img src={defaultImage} alt={title} />;
    }
    // Checking if image is of type http / https.
    else if (/(http|https):\/\//.test(img)) {
      return <img src={img} alt={title} />;
    }
    // Else rendering image as of type base64.
    else {
      const imgSrc = img?.split(";base64,")?.pop() || img;
      return <img src={imgSrc ? `data:image/jpeg;base64,${imgSrc}` : defaultImage} alt={title} />;
    }
  };
  const handleCardBtnClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (dataSetCount === 0) {
      navigate(
        generatePath(WebPaths.Dataset, {
          projectId: id,
          scenarioId: defaultScenarioId ?? scenarioId
        })
      );
    } else {
      navigate(
        `/projects/${id}?openAskAI=true${
          dataSetCount === 1 && !!singleEntityId ? `&entity=${singleEntityId}` : ""
        }`
      );
    }
  };

  const handleDataAppClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleJobsClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Card moreOptions={options} link={`${link}/${id}`}>
      <Grid item xs="auto" className={classes.cardImg} data-testid="projectImage">
        {renderImage()}
      </Grid>
      <Grid container direction="column" item xs className={classes.detailsContainer}>
        <Grid item>
          <Box pt="8px">
            <CardTitle
              dataTestId="projectName"
              link={`${link}/${id}`}
              title={title}
              style={{ fontWeight: 400 }}
            />
          </Box>
        </Grid>
        {description && (
          <Grid item>
            <TitleText
              dataTestId="projectDescription"
              title={description}
              variant="body2"
              color="textSecondary"
              className={styles.description}
            />
          </Grid>
        )}
        <Grid
          container
          direction="column"
          wrap="nowrap"
          justifyContent="flex-end"
          item
          style={{ flexGrow: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            data-testid="projectData"
            className={classes.actionBtn}
            onClick={handleCardBtnClick}>
            {dataSetCount === 0 ? "Connect Your Data" : "Ask AI on your data"}
          </Button>
        </Grid>
        <Grid item container direction="row" wrap="nowrap">
          <Grid
            item
            container
            xs={6}
            direction="row"
            alignItems="center"
            className={classes.gap}
            wrap="nowrap"
            style={{ paddingBottom: "6px" }}>
            <Person style={{ fontSize: "16px", color: "#00365699" }} />
            <Grid className={classes.overflowText}>
              <TitleText
                dataTestId="projectUpdater"
                title={updater?.split("@")?.[0] || "-"}
                className={clsx(classes.countText)}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container direction="row" className={classes.gap} wrap="nowrap">
            <Tooltip title="Updated On">
              <>
                <CalendarToday style={{ fontSize: "16px", color: "#00365699" }} />
                <Typography data-testid="projectUpdatedOn" className={classes.countText}>
                  {dateFormat(updated, true)}
                </Typography>
              </>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item container direction="row" wrap="nowrap">
          <Grid
            item
            xs={6}
            container
            direction="row"
            alignItems="center"
            className={classes.gap}
            onClick={handleDataAppClick}>
            <DataAppIcon />
            <TitleText
              dataTestId="createDataAppForProject"
              title={
                dataAppCount === 0
                  ? "Create DataApp"
                  : `${dataAppCount} DataApp${dataAppCount !== 1 ? "s" : ""}`
              }
              className={clsx(classes.countText)}
              link={`/projects/${id}/project-dataapps`}
            />
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="row"
            className={classes.gap}
            onClick={handleJobsClick}>
            <JobIcon />
            <TitleText
              dataTestId="scheduleJobForProject"
              title={
                jobCount === 0
                  ? "Schedule Job"
                  : `${jobCount} Job${jobCount !== 1 ? "s" : ""} scheduled`
              }
              className={classes.countText}
              link={jobCount === 0 ? `/projects/${id}/jobs/create-job` : `/projects/${id}/jobs`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default InfoCard;
