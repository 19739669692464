import React from "react";

export const ArtifactIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.52381 6.85714V12.1905H3.80952V6.85714H1.52381ZM6.09524 6.85714V12.1905H8.38095V6.85714H6.09524ZM0 16H14.4762V13.7143H0V16ZM10.6667 6.85714V12.1905H12.9524V6.85714H10.6667ZM7.2381 0L0 3.80952V5.33333H14.4762V3.80952L7.2381 0Z"
      fill="#10354E"
    />
  </svg>
);
