/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface EnvDetailsDto
 */
export interface EnvDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof EnvDetailsDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvDetailsDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvDetailsDto
     */
    'cores'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvDetailsDto
     */
    'gpus'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvDetailsDto
     */
    'memInMbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvDetailsDto
     */
    'diskInGbs'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvDetailsDto
     */
    'launchStatus'?: EnvDetailsDtoLaunchStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvDetailsDto
     */
    'envType'?: EnvDetailsDtoEnvTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EnvDetailsDto
     */
    'defaultFlag'?: boolean;
}

export const EnvDetailsDtoLaunchStatusEnum = {
    Success: 'SUCCESS',
    Launching: 'LAUNCHING',
    Shutdown: 'SHUTDOWN',
    Failed: 'FAILED',
    Unknown: 'UNKNOWN'
} as const;

export type EnvDetailsDtoLaunchStatusEnum = typeof EnvDetailsDtoLaunchStatusEnum[keyof typeof EnvDetailsDtoLaunchStatusEnum];
export const EnvDetailsDtoEnvTypeEnum = {
    ExtraSmall: 'EXTRA_SMALL',
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE',
    CpuLarge: 'CPU_LARGE',
    MaxLarge: 'MAX_LARGE',
    ExtraMaxLarge: 'EXTRA_MAX_LARGE',
    Custom: 'CUSTOM'
} as const;

export type EnvDetailsDtoEnvTypeEnum = typeof EnvDetailsDtoEnvTypeEnum[keyof typeof EnvDetailsDtoEnvTypeEnum];


