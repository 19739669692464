import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const PredictRecipeIcon: React.FC<IProps> = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : "0 0 16 16"}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M4.14795 2.6123H5.92573V7.8368" stroke={color} strokeWidth="0.533333" />
      <path d="M11.8521 2.6123H10.0743V7.8368" stroke={color} strokeWidth="0.533333" />
      <path d="M8.29614 0.979492V7.83674" stroke={color} strokeWidth="0.533333" />
      <ellipse cx="8.29621" cy="0.653062" rx="0.592593" ry="0.653062" fill={color} />
      <ellipse cx="11.8516" cy="2.61205" rx="0.592593" ry="0.653062" fill={color} />
      <ellipse cx="4.14826" cy="2.61205" rx="0.592593" ry="0.653062" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.18359V8.21696C0 8.45408 0.1465 8.66092 0.355219 8.71791L2.19097 9.21531C2.30222 9.60426 2.44684 9.98213 2.62219 10.3448L1.80372 11.8482C1.69294 12.0515 1.72225 12.3118 1.87422 12.4792L3.19991 13.9402C3.35144 14.1072 3.58763 14.139 3.77256 14.0179C3.77256 14.0179 4.69038 13.4115 5.13991 13.1149C5.46537 13.3061 5.80594 13.464 6.15706 13.5866L6.60797 15.6086C6.65966 15.8387 6.84734 16.0001 7.0625 16.0001H8.9375C9.15266 16.0001 9.33987 15.8387 9.39206 15.6087L9.84297 13.5866C10.2042 13.4605 10.552 13.2975 10.883 13.0998C11.3449 13.405 12.2718 14.0179 12.2718 14.0179C12.4563 14.1395 12.6925 14.1082 12.8445 13.9402L14.1702 12.4792C14.3222 12.3118 14.3515 12.0515 14.2407 11.8482L13.3979 10.3009C13.5641 9.95333 13.7019 9.58963 13.8095 9.21531L15.6447 8.71791C15.8535 8.6604 16 8.45408 16 8.21696V7.18359H11.2812C11.2812 7.18366 11.2812 7.18373 11.2812 7.1838C11.2812 9.1775 9.80909 10.7999 8 10.7999C6.19091 10.7999 4.71875 9.1775 4.71875 7.1838C4.71875 7.18373 4.71875 7.18366 4.71875 7.18359H0Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.34961 7.18311C5.49698 8.76762 6.62738 10.0001 8.00001 10.0001C9.37263 10.0001 10.503 8.76762 10.6504 7.18311H5.34961Z"
        fill={color}
      />
    </svg>
  );
};

export default PredictRecipeIcon;
