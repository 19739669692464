import React, { useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { isEmpty, merge, now } from "lodash";

import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";

import { UploadContext } from "./UploadContext";
import {
  Criteria,
  criteria,
  schemaOptionsSupportedFileTypes,
  FileMetaDataKeys,
  FileUploadStatuses,
  DatasetKeys,
  OntologyDatasetStatuses,
  separators,
  encodings,
  DatasetHelperText,
  OntologyNavigationTypes
} from "../../utils/Dataset.constants";
import useHelpers from "../../hooks/useHelpers";
import useFilesSession from "../../hooks/useFilesSession";

import {
  createEntityWithRethrow,
  updateEntityWithRethrow,
  getSignedUrlWithRethrow,
  executeSignedUrlWithRethrow
} from "src/api/projects";
import useStoreSelectors from "../../hooks/useStoreSelectors";
import useDatasetsSession from "../../hooks/useDatasetsSession";
import useFilesObserver from "../../hooks/useFilesObserver";
import { useDatasetContext } from "../Dataset/useDatasetContext";

import { getEntitiesByProjectId } from "src/api/projects";

import { capitalize } from "src/utils/capitalize";
import { trimLeadingSlash } from "src/utils/formatText";

import { getFormattedFileName } from "../../utils/Dataset.utils";
import useDatasetsObserver from "../../hooks/useDatasetsObserver";
import { useUpdateEntity } from "src/hooks/api/entities/useUpdateEntities";
import { toastWrapper } from "src/utils/toastWrapper";

type Props = {
  cancelApiTokenRef: $TSFixMe;
  children: React.ReactNode;
};

const UploadContextProvider = (props: Props) => {
  const { cancelApiTokenRef, children } = props || {};

  const { projectId } = useParams();

  const { dataset, navigateBack } = useDatasetContext();

  const {
    source,
    isUploadControlProcessing,
    updateFilesSession,
    updateDatasetsSession,
    deleteEntities,
    deleteEntityDataSourcesFilesUploadSql,
    getDatasetOntologySchemaSession,
    removeDatasetOntologySchemaSession
  } = useHelpers();

  const envRelaunchToastIdRef = useRef<$TSFixMe>(null);

  const [previousDatasetName, setPreviousDatasetName] = useState<$TSFixMe>("");
  const [ontologyDatasetIndex, setOntologyDatasetIndex] = useState<$TSFixMe>(0);

  // Files in session - STARTS >>
  const [filesTimer, setFilesTimer] = useState<$TSFixMe>(new Date().getTime());

  const { getDatasetFilesSession, setDatasetFilesSession, removeDatasetFilesSession } =
    useFilesSession({ timer: filesTimer });

  useEffect(() => {
    removeDatasetFilesSession();
    return () => removeDatasetFilesSession();
  }, []);
  // << ENDS - Files in session

  // Datasets in session - STARTS >>
  const [datasetsTimer, setDatasetsTimer] = useState<$TSFixMe>(new Date().getTime());
  const { getDatasetDatasetsSession, setDatasetDatasetsSession, removeDatasetDatasetsSession } =
    useDatasetsSession({
      timer: datasetsTimer
    });

  useEffect(() => () => removeDatasetDatasetsSession(), []);
  // << ENDS - Datasets in session

  // Stores - STARTS >>
  const {
    setDatasetDefaultDatasetStore,
    setDatasetCriteriaStore,

    datasetProjectStore,
    setDatasetProjectStore,

    datasetSourceStore,
    setDatasetSourceStore,

    datasetCriterionStore,
    setDatasetCriterionStore,

    datasetFilesStore,
    setDatasetFilesStore,

    datasetDatasetsStore,
    setShouldResetDataset,

    datasetSelectedDataSourceFilesStore,
    setDatasetSelectedDataSourceFilesStore,

    datasetExistingDatasetsStore,
    setDatasetExistingDatasetsStore,

    datasetIsOntologyStore,
    datasetIsFetchingOntologyDataStore,
    shouldResetDataset,

    setDatasetWatchOntologySchemaSetStore,

    resetDatasetStore
  } = useStoreSelectors();
  // << ENDS - Stores

  // Query hooks - STARTS >>
  // Mutations
  const { mutateAsync: updateDatasetMutation, reset: resetUpdateDatasetMutation } = useUpdateEntity(
    {
      onSuccess: () => {
        // Update datasets in session >>
        const fields: $TSFixMe = {};
        fields[DatasetKeys.OntologyConfig] = {};
        fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = false;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Updated;
        updateDatasetsSession({
          index: ontologyDatasetIndex,
          fields
        });
        // << Update datasets in session

        toastWrapper({
          type: "success",
          content: "Datatype changed successfully!"
        });
      },
      onError: () => {
        // Update datasets in session >>
        const fields: $TSFixMe = {};
        fields[DatasetKeys.OntologyConfig] = {};
        fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = false;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Failed;
        updateDatasetsSession({
          index: ontologyDatasetIndex,
          fields
        });
        // << Update datasets in session
      },
      onSettled: () => {
        setDatasetWatchOntologySchemaSetStore(now());
      }
    }
  );
  // << ENDS - Query hooks

  // Sync datasetProjectStore - STARTS >>
  useEffect(() => {
    const thisProjectStore = datasetProjectStore;
    thisProjectStore.value = projectId;

    setDatasetProjectStore({ ...thisProjectStore });
  }, [projectId]);
  // << ENDS - Sync datasetProjectStore

  // Disable upload? - STARTS >>
  const { isExistingDatasetNames, isInvalidDatasetNames, isEmptyDatasetNames } =
    useDatasetsObserver();

  const { isProcessFilesWithSchemaOptions } = useFilesObserver();

  const isUploadDisabled = useMemo(
    () =>
      datasetFilesStore?.length === 0 ||
      isExistingDatasetNames ||
      isEmptyDatasetNames ||
      isInvalidDatasetNames ||
      isUploadControlProcessing,
    [
      datasetFilesStore,
      isExistingDatasetNames,
      isEmptyDatasetNames,
      isInvalidDatasetNames,
      isUploadControlProcessing
    ]
  );
  // << ENDS - Disable upload?

  // Upload - STARTS >>
  const updateProgress = (index: $TSFixMe) => (progress: $TSFixMe) => {
    let progressValue = Math.floor((progress.loaded * 100) / progress.total);

    // if (progressValue >= 95) {
    //   progressValue = 95;
    // }

    const fields: $TSFixMe = {};
    fields[FileMetaDataKeys.UploadProgress] = progressValue;

    updateFilesSession({ index, fields });
  };

  const failFilesInSession = ({ index = -1 }: $TSFixMe = {}) => {
    // Update files in session >>
    const fields: $TSFixMe = {};
    fields[FileMetaDataKeys.Status] = FileUploadStatuses.Failed;
    fields[FileMetaDataKeys.UploadProgress] = 0;

    updateFilesSession({ index, fields });
    // << Update files in session
  };

  const existingDatasetNames = useMemo(
    () =>
      (datasetExistingDatasetsStore || [])?.map((eachDataset: $TSFixMe) => eachDataset?.name) || [],
    [datasetExistingDatasetsStore]
  );

  const setDataSourceFilesInStore = () => {
    // Files >>
    const uploadingFiles = (datasetSelectedDataSourceFilesStore || [])?.map(
      (eachFile: $TSFixMe) => {
        let uploadingFile: $TSFixMe = {
          name: DatasetHelperText.Unknown,
          payloadPath: DatasetHelperText.Unknown,
          type: DatasetHelperText.Unknown,
          size: "",
          file: eachFile?.path || "",
          status: FileUploadStatuses.Stage,
          entityId: "",
          uploadProgress: 0
        };

        if (eachFile?.type === "file") {
          const fileSplit = trimLeadingSlash({ text: eachFile?.path }).split(".");
          const fileSplitPayload = trimLeadingSlash({ text: eachFile?.payloadPath }).split(".");

          uploadingFile = {
            ...uploadingFile,
            name: fileSplit.slice(0, -1).join("."),
            payloadPath: isEmpty(eachFile?.payloadPath)
              ? undefined
              : fileSplitPayload.slice(0, -1).join("."),
            type: fileSplit.at(-1)
          };
        } else {
          uploadingFile = {
            ...uploadingFile,
            name: trimLeadingSlash({ text: eachFile?.path }),
            payloadPath: isEmpty(eachFile?.payloadPath)
              ? undefined
              : trimLeadingSlash({ text: eachFile?.payloadPath }),
            type: capitalize(eachFile?.type)
          };
        }

        return uploadingFile;
      }
    );

    const thisDatasetFilesStore = [...datasetFilesStore, ...uploadingFiles];
    setDatasetFilesStore(thisDatasetFilesStore);

    const thisDatasetFilesSession =
      (thisDatasetFilesStore || [])?.map((eachFile: $TSFixMe) => ({
        name: eachFile?.name,
        status: eachFile?.status,
        entityId: eachFile?.entityId,
        uploadProgress: eachFile?.uploadProgress
      })) || [];

    setDatasetFilesSession(thisDatasetFilesSession);
    setFilesTimer(() => new Date().getTime());
    // << Files

    // Datasets >>
    if (datasetCriterionStore?.value === Criteria.Segregate) {
      const thisDatasets = (uploadingFiles || [])?.map((eachFile: $TSFixMe, index: number) => {
        const thisFileName = trimLeadingSlash({ text: eachFile?.name })?.split("/")?.at(-1);
        const formattedFileName = getFormattedFileName(thisFileName);

        return {
          name: formattedFileName,
          isValid: true,
          isEditing: false,
          isExisting: existingDatasetNames.includes(formattedFileName),
          ontologyConfig: {
            status: index === 0 ? OntologyDatasetStatuses.Active : OntologyDatasetStatuses.Stage,
            ...(schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase()) && {
              separator: separators[0].value,
              encoding: encodings[0].value
            })
          }
        };
      });

      setDatasetDatasetsSession([...thisDatasets]);
    } else {
      const thisFileName = trimLeadingSlash({ text: uploadingFiles?.[0]?.name })
        ?.split("/")
        ?.at(-1);
      const formattedFileName = getFormattedFileName(thisFileName);

      setDatasetDatasetsSession([
        {
          name: formattedFileName,
          isValid: true,
          isEditing: false,
          isExisting: existingDatasetNames.includes(formattedFileName),
          ontologyConfig: {
            ...(schemaOptionsSupportedFileTypes.includes(
              uploadingFiles?.[0]?.type?.toLowerCase()
            ) && {
              separator: separators[0].value,
              encoding: encodings[0].value
            })
          }
        }
      ]);
    }

    setDatasetsTimer(() => new Date().getTime());
    // << Datasets
  };

  useEffect(() => {
    const _ = async () => {
      const datasetsResponse = await getEntitiesByProjectId(projectId);

      if ((datasetsResponse || [])?.length > 0) {
        setDatasetExistingDatasetsStore(
          datasetsResponse.map((eachDataset: $TSFixMe) => ({
            id: eachDataset?.id,
            name: eachDataset?.name,
            ontologyConfig: {
              status: OntologyDatasetStatuses.Stage,
              separator: eachDataset?.entityMeta?.separator,
              encoding: eachDataset?.entityMeta?.encoding
            }
          }))
        );
      } else {
        setDatasetExistingDatasetsStore([]);
      }
    };

    _();
  }, []);

  useEffect(() => {
    removeDatasetDatasetsSession();

    if (dataset?.id) {
      setDatasetDefaultDatasetStore(dataset);

      // Setting criteria >>
      const thisDatasetCriteriaStore = criteria;
      setDatasetCriteriaStore([...thisDatasetCriteriaStore]);
      // << Setting criteria

      // Setting source >>
      const thisDatasetSourceStore = datasetSourceStore;
      thisDatasetSourceStore.isDisabled = true;

      setDatasetSourceStore({ ...thisDatasetSourceStore });
      // << Setting source

      // Setting criterion >>
      const thisDatasetCriterionStore = datasetCriterionStore;
      thisDatasetCriterionStore.value = Criteria.Append;
      thisDatasetCriterionStore.isDisabled = true;

      setDatasetCriterionStore({ ...thisDatasetCriterionStore });
      // << Setting criterion

      // Update datasets in session >>
      const fields: $TSFixMe = {};
      fields[DatasetKeys.Id] = dataset?.id;
      fields[DatasetKeys.Name] = dataset?.name;
      fields[DatasetKeys.OntologyConfig] = {};
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] = dataset?.entityMeta?.separator;
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] = dataset?.entityMeta?.encoding;

      setDatasetDatasetsSession([fields]);
      setDatasetsTimer(() => new Date().getTime());
      // << Update datasets in session
    } else {
      // Setting criteria >>
      const thisDatasetCriteriaStore = criteria.filter(
        (eachCriterion: $TSFixMe) => eachCriterion.id !== Criteria.Append
      );
      setDatasetCriteriaStore([...thisDatasetCriteriaStore]);
      // << Setting criteria
    }
  }, [dataset]);

  const resetOnCriterionChange = () => {
    if (shouldResetDataset) {
      setDatasetFilesSession([]);
      setFilesTimer(() => new Date().getTime());

      setDatasetDatasetsSession([]);
      setDatasetsTimer(() => new Date().getTime());

      setDatasetSelectedDataSourceFilesStore([]);

      resetDatasetStore();
    }
    if (!shouldResetDataset) {
      setShouldResetDataset(true);
    }
  };

  useEffect(() => {
    if (!dataset?.id) {
      resetOnCriterionChange();
    }
  }, [dataset, datasetCriterionStore?.value]);

  useEffect(() => {
    const _ = async () => {
      resetOnCriterionChange();

      await deleteEntityDataSourcesFilesUploadSql();

      if (source?.isSql) {
        // Update datasets in session >>
        const fields: $TSFixMe = {};
        fields[DatasetKeys.Id] = "";
        fields[DatasetKeys.Name] = "";
        fields[DatasetKeys.OntologyConfig] = {};
        fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlFieldDirty] = false;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSqlConfigValid] = true;

        setDatasetDatasetsSession([fields]);
        setDatasetsTimer(() => new Date().getTime());
        // << Update datasets in session
      }
    };

    !dataset?.id && _();
  }, [dataset, source?.isSql]);

  useEffect(() => {
    if (!dataset?.id) {
      const thisDatasetSourceStore = datasetSourceStore;

      if (source?.isSql) {
        const thisDataset: $TSFixMe = datasetDatasetsStore?.[0];
        const datasetOntologyConfig = thisDataset?.[DatasetKeys.OntologyConfig];

        // Setting source >>
        thisDatasetSourceStore.isDisabled =
          datasetIsOntologyStore ||
          datasetIsFetchingOntologyDataStore ||
          datasetOntologyConfig?.[DatasetKeys.Status] === OntologyDatasetStatuses.Deleting;
        // << Setting source
      } else {
        const isFilesSelected = (datasetFilesStore || [])?.length > 0;

        // Setting source >>
        thisDatasetSourceStore.isDisabled = isFilesSelected;
        // << Setting source

        // Setting criterion >>
        const thisDatasetCriterionStore = datasetCriterionStore;
        thisDatasetCriterionStore.isDisabled = isFilesSelected;

        setDatasetCriterionStore({ ...thisDatasetCriterionStore });
        // << Setting criterion
      }

      setDatasetSourceStore({ ...thisDatasetSourceStore });
    }
  }, [
    dataset,
    source?.isSql,
    datasetFilesStore,
    datasetDatasetsStore,
    datasetIsOntologyStore,
    datasetIsFetchingOntologyDataStore
  ]);

  const createDataset = async (requestJson: $TSFixMe) => {
    const thisRequestJson: $TSFixMe = {
      entityMeta: {
        entityOntology: "USER",
        entityType: "EVENT"
      },
      projectId
    };

    if (!envRelaunchToastIdRef.current) {
      envRelaunchToastIdRef.current = checkEnvRelaunch(projectId);
    }

    return await createEntityWithRethrow(merge(thisRequestJson, requestJson), {
      cancelToken: cancelApiTokenRef.current
    });
  };

  const fileUpload = async ({ entityId, targetIndex = -1 }: $TSFixMe) => {
    let thisDatasetFilesSession: $TSFixMe = [];

    let isFailedFiles: boolean = false;

    const targetIndices: number[] = [];
    if (targetIndex !== -1) {
      targetIndices.push(targetIndex);
    } else {
      (getDatasetFilesSession() || [])?.forEach((eachFile: $TSFixMe, index: number) => {
        // if (schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase())) {
        eachFile?.status !== FileUploadStatuses.SignedUrl && targetIndices.push(index);
        // } else {
        //   eachFile?.status !== FileUploadStatuses.Success && targetIndices.push(index);
        // }
      });
    }

    try {
      const signedUrlData = await getSignedUrlWithRethrow({
        params: { entityId },
        options: {
          params: {
            fileName: `${(datasetFilesStore || [])
              ?.filter((_: $TSFixMe, index: number) => targetIndices.includes(index))
              ?.map((eachFile: $TSFixMe) => eachFile?.name)}`
          },
          cancelToken: cancelApiTokenRef.current
        }
      });

      if (signedUrlData?.length > 0) {
        await Promise.all(
          (datasetFilesStore || [])
            ?.filter((_: $TSFixMe, index: number) => targetIndices.includes(index))
            ?.map(async (eachFile: $TSFixMe, index: $TSFixMe) => {
              const thisTargetIndex = targetIndex !== -1 ? targetIndex : index;
              const thisSignedUrlData = signedUrlData[index];

              // const formData = new FormData();

              // if (isCriterionAppend) {
              //   formData.append("files", eachFile?.file);
              // } else {
              //   formData.append("eachFile", eachFile?.file);
              //   formData.append("fileName", eachFile?.name);
              //   formData.append("name", datasetDatasetsStore[0]?.name);
              // }

              let apiOptions: $TSFixMe = {
                signedUrl: true,
                onUploadProgress: updateProgress(thisTargetIndex),
                cancelToken: cancelApiTokenRef.current
              };

              if (typeof thisSignedUrlData?.headers === "object") {
                if (Object.keys(thisSignedUrlData?.headers || {})?.length > 0) {
                  apiOptions = {
                    ...apiOptions,
                    headers: thisSignedUrlData?.headers
                  };
                }
              }

              return await executeSignedUrlWithRethrow({
                url: thisSignedUrlData?.signedUrl,
                data: eachFile?.file,
                options: apiOptions
              })
                .then(() => {
                  // Update files in session >>
                  const fields: $TSFixMe = {};
                  fields[FileMetaDataKeys.Status] = FileUploadStatuses.SignedUrl;
                  // if (schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase())) {
                  fields[FileMetaDataKeys.UploadProgress] = 100;
                  // }
                  updateFilesSession({ index: thisTargetIndex, fields });
                  // << Update files in session
                })
                .catch(() => {
                  failFilesInSession({ index: thisTargetIndex });
                });
            })
        ).catch(async () => {
          // Update files in session >>
          thisDatasetFilesSession = getDatasetFilesSession() || [];

          thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map(
            (eachFile: $TSFixMe, index: number) => {
              const fields: $TSFixMe = {};

              if (targetIndices.includes(index)) {
                fields[FileMetaDataKeys.Status] = FileUploadStatuses.Failed;
                fields[FileMetaDataKeys.UploadProgress] = 0;
              }

              return {
                ...eachFile,
                ...fields
              };
            }
          );

          setDatasetFilesSession(thisDatasetFilesSession);
          setFilesTimer(() => new Date().getTime());
          // << Update files in session
        });
      } else {
        isFailedFiles = true;
      }
    } catch {
      isFailedFiles = true;
    }

    if (isFailedFiles) {
      // Update files in session >>
      thisDatasetFilesSession = getDatasetFilesSession();

      thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map(
        (eachFile: $TSFixMe, index: number) => {
          const fields: $TSFixMe = {};

          if (targetIndices.includes(index)) {
            fields[FileMetaDataKeys.Status] = FileUploadStatuses.Failed;
            fields[FileMetaDataKeys.UploadProgress] = 0;
          }

          return {
            ...eachFile,
            ...fields
          };
        }
      );

      setDatasetFilesSession(thisDatasetFilesSession);
      setFilesTimer(() => new Date().getTime());
      // << Update files in session
    }
  };

  const segregateFileUpload = async ({ entityBody }: $TSFixMe) => {
    let index = -1;
    for (let eachFile of datasetFilesStore) {
      index++;

      if (eachFile?.status === FileUploadStatuses.SignedUrl) {
        continue;
      }

      try {
        let entityResponse: $TSFixMe = {
          id: eachFile?.entityId
        };

        if (!entityResponse?.id) {
          entityResponse = await createEntityWithRethrow(
            {
              ...entityBody,
              name: datasetDatasetsStore[index]?.name
            },
            {
              cancelToken: cancelApiTokenRef.current
            }
          );
        }

        // Update files in session >>
        let fileFields: $TSFixMe = {};
        fileFields[FileMetaDataKeys.Status] = FileUploadStatuses.EntityCreated;
        fileFields[FileMetaDataKeys.EntityId] = entityResponse?.id;

        updateFilesSession({ index, fields: fileFields });
        // Update files in session >>

        // Update datasets in session >>
        const datasetFields: $TSFixMe = {};
        datasetFields[DatasetKeys.Id] = entityResponse?.id;

        if (schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase())) {
          datasetFields[DatasetKeys.OntologyConfig] = {};
          datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] =
            entityResponse?.entityMeta?.separator || separators[0].value;
          datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] =
            entityResponse?.entityMeta?.encoding || encodings[0].value;
        }

        updateDatasetsSession({ index, fields: datasetFields });
        // << Update datasets in session

        let signedUrlData: $TSFixMe;
        try {
          signedUrlData = await getSignedUrlWithRethrow({
            params: { entityId: entityResponse?.id },
            options: {
              params: { fileName: eachFile?.name },
              cancelToken: cancelApiTokenRef.current
            }
          });

          if ((signedUrlData || [])?.[0]?.signedUrl) {
            // const formData = new FormData();
            // formData.append("file", eachFile?.file);
            // formData.append("fileName", eachFile?.name);
            // formData.append("name", datasetDatasetsStore[index]?.name);

            try {
              let apiOptions: $TSFixMe = {
                signedUrl: true,
                onUploadProgress: updateProgress(index),
                cancelToken: cancelApiTokenRef.current
              };

              if (typeof signedUrlData[0]?.headers === "object") {
                if (Object.keys(signedUrlData[0]?.headers || {})?.length > 0) {
                  apiOptions = {
                    ...apiOptions,
                    headers: signedUrlData[0]?.headers
                  };
                }
              }

              await executeSignedUrlWithRethrow({
                url: signedUrlData[0]?.signedUrl,
                data: eachFile?.file,
                options: apiOptions
              })
                .then(async () => {
                  fileFields = {};
                  fileFields[FileMetaDataKeys.Status] = FileUploadStatuses.SignedUrl;
                  // if (schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase())) {
                  fileFields[FileMetaDataKeys.UploadProgress] = 100;
                  // }

                  updateFilesSession({ index, fields: fileFields });
                })
                .catch(() => {
                  failFilesInSession({ index });
                });
            } catch {
              failFilesInSession({ index });
              continue;
            }
          } else {
            failFilesInSession({ index });
            continue;
          }
        } catch {
          failFilesInSession({ index });
          continue;
        }
      } catch {
        failFilesInSession({ index });
        continue;
      }
    }

    // setReloadTrigger();
  };

  const upload = async () => {
    // Update files in session >>
    let thisDatasetFilesSession = getDatasetFilesSession();

    thisDatasetFilesSession = (thisDatasetFilesSession || [])?.map((eachFile: $TSFixMe) => {
      const fileFields: $TSFixMe = {};

      if ([FileUploadStatuses.Stage, FileUploadStatuses.Failed].includes(eachFile?.status)) {
        fileFields[FileMetaDataKeys.Status] = FileUploadStatuses.Uploading;
        fileFields[FileMetaDataKeys.UploadProgress] = 0;
      }

      return {
        ...eachFile,
        ...fileFields
      };
    });

    setDatasetFilesSession(thisDatasetFilesSession);
    setFilesTimer(() => new Date().getTime());
    // << Update files in session

    let intervalId: $TSFixMe = null;

    const entityBody = {
      entityMeta: {
        entityOntology: "USER",
        entityType: "EVENT"
      },
      projectId
    };

    const criterion = datasetCriterionStore?.value;

    if (criterion === Criteria.Segregate) {
      segregateFileUpload({ entityBody });
    } else if (criterion === Criteria.Append) {
      datasetDatasetsStore[0]?.id && fileUpload({ entityId: datasetDatasetsStore[0]?.id });
    } else {
      const entityId = (datasetFilesStore || [])?.filter(
        (eachFile: $TSFixMe) =>
          eachFile?.status === FileUploadStatuses.Failed && !!eachFile?.entityId
      )?.[0]?.entityId;

      let entityResponse: $TSFixMe = { id: entityId };

      if (!entityResponse?.id || previousDatasetName !== datasetDatasetsStore[0]?.name) {
        setPreviousDatasetName(datasetDatasetsStore[0]?.name);

        try {
          entityResponse = await createEntityWithRethrow(
            {
              ...entityBody,
              name: datasetDatasetsStore[0]?.name
            },
            {
              cancelToken: cancelApiTokenRef.current
            }
          );
          // Update files in session >>
          const fileFields: $TSFixMe = {};
          fileFields[FileMetaDataKeys.Status] = FileUploadStatuses.EntityCreated;
          fileFields[FileMetaDataKeys.EntityId] = entityResponse?.id;

          updateFilesSession({ fields: fileFields });
          // << Update files in session
        } catch {
          failFilesInSession();

          clearInterval(intervalId);
        }
      }

      if (entityResponse?.id) {
        // Update datasets in session >>
        const datasetFields: $TSFixMe = {};
        datasetFields[DatasetKeys.Id] = entityResponse?.id;

        if (isProcessFilesWithSchemaOptions) {
          datasetFields[DatasetKeys.OntologyConfig] = {};
          datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] =
            entityResponse?.entityMeta?.separator || separators[0].value;
          datasetFields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] =
            entityResponse?.entityMeta?.encoding || encodings[0].value;
        }

        updateDatasetsSession({ fields: datasetFields });
        // << Update datasets in session

        fileUpload({ entityId: entityResponse.id });
      }
    }
  };

  const reUpload = async (file: $TSFixMe, index: $TSFixMe) => {
    if (!file?.entityId) {
      return;
    }

    const fields: $TSFixMe = {};
    fields[FileMetaDataKeys.Status] = FileUploadStatuses.Uploading;
    fields[FileMetaDataKeys.UploadProgress] = 0;

    updateFilesSession({ index, fields });

    fileUpload({ entityId: file.entityId, targetIndex: index });
  };
  // << ENDS - Upload

  // Delete - STARTS >>
  const deleteFile = async (file: $TSFixMe, index: $TSFixMe) => {
    const criterion = datasetCriterionStore?.value;

    const fields: $TSFixMe = {};
    fields[FileMetaDataKeys.Status] = FileUploadStatuses.Deleting;

    updateFilesSession({ index, fields });

    let isDeleteEntities: boolean = false;
    try {
      const entityId = file?.entityId;
      if (entityId) {
        isDeleteEntities =
          isDeleteEntities || [Criteria.SingleFileUpload, Criteria.Segregate].includes(criterion);

        isDeleteEntities =
          isDeleteEntities ||
          (criterion === Criteria.Merge && (datasetFilesStore || [])?.length === 1);

        isDeleteEntities && (await deleteEntities(entityId));
      }
    } finally {
      // Update files in session >>
      const thisDatasetFilesSession = (getDatasetFilesSession() || [])?.filter(
        (eachFile: $TSFixMe) => eachFile?.name !== file?.name
      );

      setDatasetFilesSession(thisDatasetFilesSession);
      setFilesTimer(() => new Date().getTime());
      // << Update files in session

      if (criterion === Criteria.Segregate) {
        const thisDatasetDatasetsSession = (getDatasetDatasetsSession() || [])?.filter(
          (_: $TSFixMe, thisIndex: number) => thisIndex !== index
        );

        setDatasetDatasetsSession([...thisDatasetDatasetsSession]);
        setDatasetsTimer(() => new Date().getTime());
      } else {
        if (!dataset?.id) {
          if ((thisDatasetFilesSession || [])?.length === 0) {
            setDatasetDatasetsSession([]);
            setDatasetsTimer(() => new Date().getTime());
          }
        }
      }
    }
  };
  // << ENDS - Delete

  // Update dataset - STARTS >>
  const updateDatasetLite = async () => {
    resetUpdateDatasetMutation();

    const entityId = datasetDatasetsStore[ontologyDatasetIndex]?.id;
    const thisOntologySchema = getDatasetOntologySchemaSession();

    const dataTypeMap: $TSFixMe = {};
    const ontologyMap: $TSFixMe = {};

    (thisOntologySchema || [])?.forEach((eachField: $TSFixMe) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!eachField?.name) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!eachField?.newDataType) {
          dataTypeMap[eachField?.name] = eachField["New Data Type"] || eachField?.newDataType;
        }

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!eachField?.newOntology) {
          ontologyMap[eachField?.name] = eachField["New Ontology"] || eachField?.newOntology;
        }
      }
    });

    if (Object.keys(dataTypeMap)?.length > 0 || Object.keys(ontologyMap)?.length > 0) {
      const payload = {
        id: entityId,
        entityMeta: { dataTypeMap, ontologyMap }
      };

      // Update datasets in session >>
      const fields: $TSFixMe = {};
      fields[DatasetKeys.OntologyConfig] = {};

      fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Updating;
      updateDatasetsSession({ index: ontologyDatasetIndex, fields });
      // << Update datasets in session

      await updateDatasetMutation(payload);
    }
  };

  const ontologyNavigation = async (navigationType?: string) => {
    let index = ontologyDatasetIndex;

    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};

    let newIndex = -1;

    if (navigationType === OntologyNavigationTypes.Previous) {
      newIndex = index - 1;

      if (newIndex >= 0) {
        fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = false;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Stage;
        updateDatasetsSession({ index, fields });

        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;
        updateDatasetsSession({ index: newIndex, fields });

        setOntologyDatasetIndex(() => newIndex);
      }

      return;
    }

    if (
      datasetDatasetsStore?.[index]?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] !==
      OntologyDatasetStatuses.Failed
    ) {
      fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = false;
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Updated;
      updateDatasetsSession({ index, fields });
    }

    newIndex = index + 1;

    if (datasetDatasetsStore[newIndex]) {
      fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = false;
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] = separators[0].value;
      fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] = encodings[0].value;

      updateDatasetsSession({ index: newIndex, fields });
      setOntologyDatasetIndex(() => newIndex);
    } else {
      navigateBack({
        action: "done"
      });
    }
  };

  const updateDataset = async (navigationType: string) => {
    let index = ontologyDatasetIndex;

    const entityId = datasetDatasetsStore[index]?.id;

    const fields: $TSFixMe = {};
    fields[DatasetKeys.OntologyConfig] = {};

    let newIndex = -1;

    if (navigationType === OntologyNavigationTypes.Previous) {
      newIndex = index - 1;

      if (newIndex >= 0) {
        fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = false;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Stage;
        updateDatasetsSession({ index, fields });

        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;
        updateDatasetsSession({ index: newIndex, fields });

        setOntologyDatasetIndex(() => newIndex);
      }

      return;
    }

    if (
      !datasetDatasetsStore?.[index]?.[DatasetKeys.OntologyConfig]?.[
        DatasetKeys.IsSchemaOrOntologyDirty
      ]
    ) {
      if (
        datasetDatasetsStore?.[index]?.[DatasetKeys.OntologyConfig]?.[DatasetKeys.Status] !==
        OntologyDatasetStatuses.Failed
      ) {
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Updated;
        updateDatasetsSession({ index, fields });
      }

      newIndex = index + 1;

      if (datasetDatasetsStore[newIndex]) {
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] = separators[0].value;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] = encodings[0].value;
        fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = false;

        updateDatasetsSession({ index: newIndex, fields });
        setOntologyDatasetIndex(() => newIndex);
      } else {
        navigateBack();
      }

      return;
    }

    const thisOntologySchema = getDatasetOntologySchemaSession();

    if (!entityId || (thisOntologySchema || [])?.length === 0) {
      return;
    }

    const dataTypeMap: $TSFixMe = {};
    const ontologyMap: $TSFixMe = {};

    (thisOntologySchema || [])?.forEach((eachField: $TSFixMe) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!eachField?.name) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!eachField?.newDataType) {
          dataTypeMap[eachField?.name] = eachField["New Data Type"] || eachField?.newDataType;
        }

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!eachField?.newOntology) {
          ontologyMap[eachField?.name] = eachField["New Ontology"] || eachField?.newOntology;
        }
      }
    });

    if (Object.keys(dataTypeMap)?.length > 0 || Object.keys(ontologyMap)?.length > 0) {
      const requestJson = {
        id: entityId,
        entityMeta: { dataTypeMap, ontologyMap }
      };

      fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Updating;
      updateDatasetsSession({ index, fields });

      try {
        const entityResponse = await updateEntityWithRethrow(requestJson, {
          cancelToken: cancelApiTokenRef.current
        });

        if (entityResponse?.id) {
          fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Updated;
          updateDatasetsSession({ index, fields: { ...fields, [DatasetKeys.IsDirty]: false } });

          newIndex = index + 1;

          if (datasetDatasetsStore[newIndex]) {
            fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Active;
            fields[DatasetKeys.OntologyConfig][DatasetKeys.Separator] = separators[0].value;
            fields[DatasetKeys.OntologyConfig][DatasetKeys.Encoding] = encodings[0].value;
            fields[DatasetKeys.OntologyConfig][DatasetKeys.IsSchemaOrOntologyDirty] = false;

            updateDatasetsSession({ index: newIndex, fields });
            setOntologyDatasetIndex(() => newIndex);
          } else {
            navigateBack();
          }
        } else {
          fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Failed;
          updateDatasetsSession({ index, fields });
        }
      } catch {
        fields[DatasetKeys.OntologyConfig][DatasetKeys.Status] = OntologyDatasetStatuses.Failed;
        updateDatasetsSession({ index, fields });
      }
    }
  };

  useEffect(() => {
    removeDatasetOntologySchemaSession();
    return () => removeDatasetOntologySchemaSession();
  }, []);
  // << ENDS - Update dataset

  const value = useMemo(
    () => ({
      cancelApiTokenRef,
      setDataSourceFilesInStore,
      setFilesTimer,
      isUploadDisabled,
      createDataset,
      upload,
      reUpload,
      deleteFile,
      ontologyNavigation,
      updateDatasetLite,
      updateDataset,
      failFilesInSession,
      ontologyDatasetIndex,
      envRelaunchToastIdRef
    }),
    [
      cancelApiTokenRef,
      setDataSourceFilesInStore,
      setFilesTimer,
      isUploadDisabled,
      createDataset,
      upload,
      reUpload,
      deleteFile,
      ontologyNavigation,
      updateDatasetLite,
      updateDataset,
      failFilesInSession,
      ontologyDatasetIndex,
      envRelaunchToastIdRef
    ]
  );
  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

export default UploadContextProvider;
