import { deleteAPIWithRethrow } from "src/utils/apiService";
import { useMutation } from "@tanstack/react-query";

type DeleteRecipePayloadTypes = { projectId: string; recipeId: string };

export const useDeleteRecipe = () => {
  return useMutation({
    mutationFn: ({ recipeId }: DeleteRecipePayloadTypes) => {
      return deleteAPIWithRethrow(
        `/v2/project-step?type=DFSGROUP&id=${recipeId}&deleteAssociatedPDs=true&deleteAssociatedPJs=true`,
        {}
      );
    }
  });
};
