import { getAPI, getAPIWithRethrow, postAPI, postAPIWithRethrow } from "../utils/apiService";

export const postInvitation = async (payload: $TSFixMe) => await postAPI("/invitation", payload);

export const postInvitationWithRethrow = async (payload: $TSFixMe) =>
  // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  await postAPIWithRethrow("/invitation", payload);

export const postInvitationThroughEmailWithRethrow = async (payload: $TSFixMe) =>
  // // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
  await postAPIWithRethrow("/invitation/email", payload);

export const getInvitationConsent = async (token: $TSFixMe) =>
  await getAPI(`api/invitation/consent?details=${token}`);

export const getInvitationConsentWithRethrow = async (token: $TSFixMe) =>
  await getAPIWithRethrow(`api/invitation/consent?details=${token}`);
