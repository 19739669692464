import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles({
  chartsOutput: {
    flexWrap: "nowrap",
    gap: "8px",
    padding: "8px"
  },
  infoItem: {
    color: "rgba(0,0,0,0.6)",
    fontSize: "12px",
    lineHeight: 1.54,
    fontStyle: "italic"
  },
  infoIcon: {
    color: "rgba(0,0,0,0.6)",
    paddingRight: "4px"
  },
  helpText: {
    padding: "0px 8px 8px 8px",
    background: "#fff",
    borderRadius: "4px"
  }
});

export const SampleRows = ({ sampleRows }: { sampleRows: number | undefined }) => {
  const classes = useStyles();
  return (
    <>
      {sampleRows && (
        <Grid container direction="row" alignItems="center" className={classes.helpText}>
          <InfoIcon fontSize="small" className={classes.infoIcon} />
          <Typography className={classes.infoItem} data-testid="sampleRowsHelpText">
            Query is run on a sample of{" "}
            {sampleRows === 100000 ? "100k" : sampleRows === 1000000 ? "1 Million" : sampleRows}{" "}
            records
          </Typography>
        </Grid>
      )}
    </>
  );
};
