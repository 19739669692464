import { LinkProps, Typography, TypographyProps, makeStyles } from "@material-ui/core";

import React from "react";
import { Link } from "react-router-dom";
import OverflowTooltip from "../OverflowTooltip";
import Text from "components/Widget/Text";

const useStyles = makeStyles({
  titleText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
    overflow: "hidden",
    color: ({ colorCode }: any) => colorCode || "#787878"
  }
});

interface CardTitleProps extends TypographyProps {
  title: string;
  link?: string;
  linkProps?: LinkProps;
  colorCode?: string;
  dataTestId?: string;
  whiteSpace?: any;
}

export const TitleText = ({
  title,
  link,
  className,
  colorCode = "#787878",
  linkProps,
  dataTestId,
  whiteSpace,
  ...props
}: CardTitleProps) => {
  const classes = useStyles({ colorCode });
  const titleComponent = (
    <Typography component="span" variant="h6" className={className || classes.titleText} {...props}>
      <OverflowTooltip
        title={title}
        value={
          <Text
            dataTestId={dataTestId}
            style={{ whiteSpace: whiteSpace || "nowrap" }}
            value={title}
          />
        }
      />
    </Typography>
  );
  return link ? (
    <Link {...linkProps} to={link}>
      {titleComponent}
    </Link>
  ) : (
    titleComponent
  );
};
