import React from "react";

interface IProps {
  fill?: string;
}

export const CodeIcon: React.FC<IProps> = (props) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.59273 0L8.8994 0.266667L6.40607 12L5.0994 11.7333L7.59273 0ZM12.0594 6L9.66607 3.60667V1.72L13.9461 6L9.66607 10.2733V8.38667L12.0594 6ZM0.0527344 6L4.33273 1.72V3.60667L1.9394 6L4.33273 8.38667V10.2733L0.0527344 6Z"
        fill={props.fill || "currentColor"}
      />
    </svg>
  );
};
