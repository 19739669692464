import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const ReleaseNotesIcon = ({
  width = 18,
  height = 18,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.35526 3.86842V5.44737H3.82895V15.0947H13.3026V9.39474H14.8816V14.9211C14.8816 15.7895 14.1711 16.5 13.3026 16.5H3.82895C2.96053 16.5 2.25 15.7895 2.25 14.9211V5.44737C2.25 4.57895 2.96053 3.86842 3.82895 3.86842H9.35526ZM9.35526 11.7632V13.3421H5.40789V11.7632H9.35526ZM11.7237 9.39474V10.9737H5.40789V9.39474H11.7237ZM11.7237 7.02632V8.60526H5.40789V7.02632H11.7237ZM14.0921 3.07895L14.8322 4.70724L16.4605 5.44737L14.8322 6.1875L14.0921 7.81579L13.352 6.1875L11.7237 5.44737L13.352 4.70724L14.0921 3.07895ZM11.7237 1.5L12.2171 2.58553L13.3026 3.07895L12.2171 3.57237L11.7237 4.65789L11.2303 3.57237L10.1447 3.07895L11.2303 2.58553L11.7237 1.5Z"
        fill={color}
      />
    </svg>
  );
};

export default ReleaseNotesIcon;
