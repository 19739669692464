import React, { useMemo } from "react";

// Packages
import { map, values, isFunction, get } from "lodash";

// Icons
import { AiAssistedIcon } from "src/icons/NewUX/AiAssistedRecipeIcon";
import { ArtifactIcon } from "src/icons/NewUX/ArtifactIcon";
import { DatasetIcon } from "src/icons/NewUX/DatasetIcon";
import { ModelIcon } from "src/icons/NewUX/ModelIcon";
import { PlusIcon } from "src/icons/NewUX/PlusIcon";
import { RapidModelRecipeIcon } from "src/icons/NewUX/RapidModelRecipeIcon";
import { TemplateRecipeIcon } from "src/icons/NewUX/TemplateRecipeIcon";

// Components
import { IItem } from "src/components/custom/Menu/Menu";
import { Menu } from "src/components/custom";

// Constants
import { NodeTypeNames, RecipeTypeNames } from "src/pages/private/ProjectsModule/utils";
import ApiConnectorIcon from "src/icons/NewUX/ApiConnectorIcon";

enum DatasetMenuKeys {
  DatasetModal = "DATASET_MODAL",
  Dataset = "DATASET",
  Artifact = "ARTIFACT",
  Model = "MODEL"
}

enum RecipeMenuKeys {
  TemplateRecipe = "TEMPLATE_RECIPE",
  AiAssistedRecipe = "AI_ASSISTED_RECIPE",
  RapidModelRecipe = "RAPID_MODEL_RECIPE",
  ApiConnectorRecipe = "API_CONNECTOR_RECIPE"
}

interface IProps {
  isAddTemplateRecipeDisabled: boolean;
  isAddAiAssistedRecipeDisabled: boolean;
  isAddRapidModelRecipeDisabled: boolean;
  canAddStandardRecipe: boolean;
  canAddArtifacts: boolean;
  canAddModels: boolean;
  addArtifacts: () => void;
  addModel: () => void;
  addDataset: () => void;
  addTemplateRecipe: () => void;
  addAiAssistedRecipe: () => void;
  addRapidModelRecipe: () => void;
  onAddApiConnectorRecipe?: () => void;
}

const AddMenu: React.FC<IProps> = (props) => {
  const {
    // Datasets props
    addDataset,
    addArtifacts,
    addModel,
    canAddArtifacts,
    canAddModels,

    // Recipes props
    isAddTemplateRecipeDisabled,
    isAddAiAssistedRecipeDisabled,
    isAddRapidModelRecipeDisabled,
    addTemplateRecipe,
    addAiAssistedRecipe,
    canAddStandardRecipe,
    addRapidModelRecipe,
    onAddApiConnectorRecipe
  } = props || {};

  // Meta-data of menu-items - STARTS >>
  const datasetMenuDetails = useMemo(
    () => ({
      [DatasetMenuKeys.Dataset]: {
        key: DatasetMenuKeys.Dataset,
        hide: false,
        label: NodeTypeNames.Dataset,
        icon: <DatasetIcon width={20} height={16} viewBox="2 0 20 20" />,
        action: addDataset
      },
      [DatasetMenuKeys.Artifact]: {
        key: DatasetMenuKeys.Artifact,
        label: NodeTypeNames.Artifact,
        hide: !canAddArtifacts,
        icon: <ArtifactIcon width={20} height={20} />,
        action: addArtifacts
      },
      [DatasetMenuKeys.Model]: {
        key: DatasetMenuKeys.Model,
        hide: !canAddModels,
        label: NodeTypeNames.Model,
        icon: <ModelIcon width={20} height={20} />,
        action: addModel
      }
    }),
    []
  );

  const recipeMenuDetails = useMemo(
    () => ({
      [RecipeMenuKeys.AiAssistedRecipe]: {
        key: RecipeMenuKeys.AiAssistedRecipe,
        label: RecipeTypeNames.AiAssisted,
        icon: <AiAssistedIcon width={20} height={20} />,
        action: addAiAssistedRecipe,
        isDisabled: isAddAiAssistedRecipeDisabled,
        hide: false,
        tooltip: isAddTemplateRecipeDisabled ? "No datasets to add recipe" : ""
      },
      [RecipeMenuKeys.RapidModelRecipe]: {
        key: RecipeMenuKeys.RapidModelRecipe,
        label: RecipeTypeNames.RapidModel,
        icon: <RapidModelRecipeIcon width={20} height={20} />,
        action: addRapidModelRecipe,
        isDisabled: isAddRapidModelRecipeDisabled,
        hide: false,
        tooltip: isAddTemplateRecipeDisabled ? "No datasets to add recipe" : ""
      },
      [RecipeMenuKeys.TemplateRecipe]: {
        key: RecipeMenuKeys.TemplateRecipe,
        label: RecipeTypeNames.Template,
        icon: <TemplateRecipeIcon width={20} height={20} />,
        action: addTemplateRecipe,
        hide: !canAddStandardRecipe,
        isDisabled: isAddTemplateRecipeDisabled,
        tooltip: isAddTemplateRecipeDisabled ? "No datasets to add recipe" : ""
      },
      [RecipeMenuKeys.ApiConnectorRecipe]: {
        key: RecipeMenuKeys.ApiConnectorRecipe,
        label: "API Connector",
        icon: <ApiConnectorIcon />,
        action: onAddApiConnectorRecipe,
        isDisabled: false,
        hide: !isFunction(onAddApiConnectorRecipe),
        tooltip: ""
      }
    }),
    [
      isAddTemplateRecipeDisabled,
      isAddAiAssistedRecipeDisabled,
      isAddRapidModelRecipeDisabled,
      onAddApiConnectorRecipe
    ]
  );
  // ENDS - Meta-data of menu-items

  // Formatting to support MUI menu - STARTS >>
  const visibleDatasetMenuItems = values(datasetMenuDetails).filter((menuItem) => !menuItem.hide);
  const datasetMenuItems = useMemo(
    () =>
      map(visibleDatasetMenuItems, (menuItem) => ({
        key: menuItem?.key,
        label: menuItem?.label,
        icon: menuItem?.icon
      })),
    [datasetMenuDetails]
  );

  const visibleRecipeMenuItems = values(recipeMenuDetails).filter((recipe) => !recipe.hide);
  const recipeMenuItems = useMemo(
    () =>
      map(visibleRecipeMenuItems, (menuItem) => ({
        key: menuItem?.key,
        label: menuItem?.label,
        icon: menuItem?.icon,
        isDisabled: menuItem?.isDisabled,
        tooltip: menuItem?.tooltip
      })),
    [recipeMenuDetails]
  );
  // ENDS - Formatting to support MUI menu

  const menuItems = useMemo(
    () => [
      {
        label: "Datasets",
        key: "datasets",
        menuItems: datasetMenuItems
      },
      {
        label: "Recipes",
        key: "recipesx",
        menuItems: recipeMenuItems
      }
    ],
    [datasetMenuItems, recipeMenuItems]
  );

  return (
    <Menu
      buttonProps={{
        IconButtonProps: {
          color: "primary",
          size: "small"
        },
        icon: <PlusIcon width={28} height={28} />
      }}
      menuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      }}
      menuItems={menuItems}
      onClick={(menuItem: IItem) =>
        (get(datasetMenuDetails, menuItem.key) || get(recipeMenuDetails, menuItem.key))?.action(
          menuItem
        )
      }
    />
  );
};

export default AddMenu;
