import { useCallback, useState } from "react";

// Packages
import { useNavigate, useLocation } from "react-router-dom";
import shallow from "zustand/shallow";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";

// Utils
import api from "src/utils/AxiosClient";
// Stores
import useAuthStore from "src/stores/auth.store";
import useNotificationStore from "src/stores/notification.store";
import { shouldRefreshProjectsToggler } from "src/store/store.selectors";
import { useProjectsStore } from "src/store/store";

// Hooks
import { useAuthAwaitStreamlit } from "src/hooks/useAuthAwaitStreamlit";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";

// API Hooks
import useQuery from "src/hooks/useQuery";
import { UseGetDataConnectorsQueryKeys } from "src/hooks/api";
import { useGetRole } from "src/hooks/useGetRole";
import { fetchRedirectUrlPostSignIn, cleanLocationParams } from "src/utils/urlUtils";

// Constants
import { PagesViewConfiguration } from "src/constants";

export const useSignInActions = ({
  setErrorMessage,
  setIsEmailPasswordErrorVisible,
  setIsError500Visible
}: {
  setErrorMessage: (msg: string | null) => void;
  setIsEmailPasswordErrorVisible: (val: boolean) => void;
  setIsError500Visible: (val: boolean) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();

  const { setAuthResponse } = useAuthSetAuthResponse();

  const [setToken, setTokenPermanent] = useAuthStore(
    useCallback((state) => [state.setToken, state.setTokenPermanent], []),
    shallow
  );
  const setNotification = useNotificationStore(
    useCallback((state) => state.setNotification, []),
    shallow
  );

  const { checkIsRoleYieldsDataAppView } = useGetRole();

  const queryParameters = useQuery();

  const [isSigningIn, setIsSigningIn] = useState(false);
  const [displayAgreement, setDisplayAgreement] = useState(false);

  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);
  const { shouldFetchStreamlitToken, fetchAllTokens } = useAuthAwaitStreamlit({
    fetchOnLoad: false
  });

  const invitationDetails = queryParameters.get("invitation_details");

  const isSignUpFlow = !!invitationDetails;

  const handleEmailPasswordSubmit = async (data: $TSFixMe) => {
    const invitationDetails = queryParameters.get("invitation_details");

    try {
      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("password", data.password);

      if (invitationDetails) {
        formData.append("invitation_details", invitationDetails);
      }
      const response = await axios.post("/api/login/authenticate", formData);

      if (response.status === 200) {
        handleAuthSuccessSteps(response, invitationDetails);
      }
    } catch (error: $TSFixMe) {
      handleSignInError(error);
    }
  };

  const handleSignInError = (error: any) => {
    if (error?.response?.status >= 500) {
      setIsError500Visible(true);
    } else if (error?.response?.status === 401) {
      setIsEmailPasswordErrorVisible(true);
    } else {
      setErrorMessage(error?.response?.data?.msg || error.message);
    }

    setIsSigningIn(false);
  };

  const handleAuthSuccessSteps = async (response: $TSFixMe, invitationDetails?: $TSFixMe) => {
    const { token, eulaAccepted, tenantName, roleName } = response.data;
    localStorage.setItem(PagesViewConfiguration, "{}");
    setToken(token);
    setTokenPermanent(token);
    api.init(token);
    if (shouldFetchStreamlitToken) {
      try {
        await fetchAllTokens();
      } catch {
        return;
      }
    }
    setAuthResponse({
      data: response.data,
      rememberMe: true
    });

    const decodedUrl = decodeURIComponent(window.location.href);
    const searchParams = new URL(decodedUrl).searchParams;
    const dataappurl = searchParams.get("dataappurl");
    if (dataappurl) {
      window.location.href = dataappurl;
    }

    setDisplayAgreement(!eulaAccepted);
    setIsSigningIn(false);
    toggleShouldProjectsRefresh();

    if (eulaAccepted) {
      // In case of streamlit token fetch failure, clean url params
      const updatedLocation = cleanLocationParams(location);
      invitationDetails &&
        setNotification({
          type: "Dashboard",
          message: `You are added to "${tenantName}" tenant successfully`
        });
      const { pathname, search } = fetchRedirectUrlPostSignIn(updatedLocation, token);
      // If an unauthenticated request originates from a certain route, Redirect to that route saved in the state variable
      const shouldRedirectToOriginalRoute = location?.state?.from?.pathname?.includes("/apps/");
      const updatePathName = shouldRedirectToOriginalRoute
        ? location?.state?.from?.pathname
        : pathname;
      const searchParams = shouldRedirectToOriginalRoute ? location?.state?.from?.search : search;

      // Get third-party connectors for roles that do not yield data-app view role.
      if (!!roleName && !checkIsRoleYieldsDataAppView(roleName)) {
        const dataConnectorsData = queryClient.getQueryData([
          UseGetDataConnectorsQueryKeys.Connectors
        ]);

        if (!dataConnectorsData) {
          queryClient.prefetchQuery({ queryKey: [UseGetDataConnectorsQueryKeys.Connectors] });
        }
      }

      navigate({ pathname: updatePathName, search: searchParams });
    }
  };

  const handleNavigate = (nav: $TSFixMe) => {
    setDisplayAgreement(false);
    if (nav) {
      navigate({ pathname: "/", search: location.search }, { state: location.state });
    }
  };

  return {
    isSignUpFlow,
    handleNavigate,
    displayAgreement,
    isSigningIn,
    handleSignIn: handleEmailPasswordSubmit
  };
};
