import { useState } from "react";
import { useForm } from "react-hook-form";

export const useSignInForm = ({ email = "" }: { email?: string }) => {
  const [isEmailPasswordErrorVisible, setIsEmailPasswordErrorVisible] = useState(false);
  const [IsError500Visible, setIsError500Visible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { email, password: "" } });

  const clearBEErrors = () => {
    setIsEmailPasswordErrorVisible(false);
    setIsError500Visible(false);
    setErrorMessage(null);
  };

  return {
    register,
    setValue,
    handleSubmit,
    errors,
    clearBEErrors,
    isEmailPasswordErrorVisible,
    IsError500Visible,
    errorMessage,
    setErrorMessage,
    setIsEmailPasswordErrorVisible,
    setIsError500Visible
  };
};
