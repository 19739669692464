import React from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core";

interface IProps {
  id: string;
  classNames: string;
  // eslint-disable-next-line no-undef
  image: JSX.Element;
  displayName?: string;
  onClick: () => void;
}

const useStyles = makeStyles(() => ({
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: 0,
    padding: "10px 0 0 !important",
    "& .data-source-image": {
      marginTop: 10,
      width: 125,
      height: 32,
      display: "flex",
      justifyContent: "center"
    },
    "& .data-source-type": {
      marginTop: 5,
      marginBottom: 10,
      fontSize: 14,
      textAlign: "center",
      fontWeight: 500,
      color: "#123553"
    }
  }
}));

const DataSourceCard: React.FC<IProps> = (props) => {
  const { id, classNames, image, displayName, onClick } = props;

  const classes = useStyles();

  return (
    <Card id={id} className={classNames} onClick={onClick}>
      <CardContent className={classes.cardContent}>
        <div className="data-source-image">{image}</div>
        <div className="data-source-type">{displayName}</div>
      </CardContent>
    </Card>
  );
};

export default DataSourceCard;
