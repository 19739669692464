import React, { useMemo, useState } from "react";

// Packages
import { v4 as uuidv4 } from "uuid";
import { forEach, isBoolean, map } from "lodash";

// MUI
import {
  Menu as MuiMenu,
  IconButtonProps,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Typography,
  makeStyles,
  MenuProps,
  Button
} from "@material-ui/core";

export interface IItem {
  key: string;
  path?: string;
  label?: string | React.JSX.Element;
  icon?: React.JSX.Element;
  isDisabled?: boolean;
  tooltip?: string;
  menuItems?: IItem[];
  testId?: string;
}

interface IIconButtonProps extends IconButtonProps {
  "data-testid"?: string;
}
interface IButtonProps {
  IconButtonProps?: IIconButtonProps;
  icon?: React.JSX.Element;
  label?: string | React.JSX.Element;
  "data-testid"?: string;
}

type Props = {
  buttonProps: IButtonProps;
  menuProps: Pick<MenuProps, "anchorOrigin" | "transformOrigin">;
  menuItems: IItem[];
  renderButton?: boolean;
  onClick: (item: IItem) => void;
};

const useStyles = makeStyles({
  menuItemIcon: {
    minWidth: "auto",
    marginRight: 8
  }
});

const Menu = (props: Props) => {
  const { buttonProps, menuProps, menuItems, renderButton, onClick } = props || {};

  const classes = useStyles();

  // Drop-down code - STARTS >>
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // << ENDS - Drop-down code

  const handleMenuClick = (menuItem: IItem) => {
    onClick(menuItem);
    handleClose();
  };

  const menuItemComponents = useMemo(() => {
    let items: React.ReactNode[] = [];

    forEach(menuItems, (thisEntry: IItem, index: number) => {
      if (!thisEntry?.menuItems) {
        items.push(
          <Tooltip key={uuidv4()} title={!!thisEntry?.tooltip ? thisEntry?.tooltip : ""}>
            <span>
              <MenuItem
                onClick={() => handleMenuClick(thisEntry)}
                disabled={thisEntry?.isDisabled}
                data-testid={thisEntry?.testId}>
                <ListItemIcon className={classes.menuItemIcon}>
                  <Typography color="textSecondary">{thisEntry?.icon}</Typography>
                </ListItemIcon>
                <Typography variant="body2">{thisEntry?.label}</Typography>
              </MenuItem>
            </span>
          </Tooltip>
        );
      } else {
        if (!!thisEntry?.label) {
          items.push(
            <MenuItem
              key={uuidv4()}
              data-testid={thisEntry?.testId}
              disabled={isBoolean(thisEntry?.isDisabled) ? thisEntry?.isDisabled : true}>
              <ListItemText
                primary={thisEntry?.label}
                primaryTypographyProps={{
                  variant: "body2"
                }}
              />
            </MenuItem>
          );
        }

        items = [
          ...items,
          ...map(thisEntry?.menuItems, (menuItem: IItem) => (
            <Tooltip key={uuidv4()} title={!!menuItem?.tooltip ? menuItem?.tooltip : ""}>
              <span>
                <MenuItem
                  onClick={() => handleMenuClick(menuItem)}
                  disabled={menuItem?.isDisabled}
                  data-testid={menuItem?.testId}>
                  <ListItemIcon className={classes.menuItemIcon}>
                    <Typography color="textSecondary">{menuItem?.icon}</Typography>
                  </ListItemIcon>
                  <Typography variant="body2">{menuItem?.label}</Typography>
                </MenuItem>
              </span>
            </Tooltip>
          ))
        ];

        if (index < menuItems?.length - 1) {
          items.push(<Divider key={uuidv4()} component="li" />);
        }
      }
    });

    return items;
  }, [menuItems]);

  return (
    <>
      {renderButton ? (
        <Button {...buttonProps?.IconButtonProps} variant="contained" onClick={handleOpen}>
          {buttonProps.label}
        </Button>
      ) : (
        <IconButton onClick={handleOpen} {...buttonProps?.IconButtonProps}>
          {buttonProps?.icon}
        </IconButton>
      )}
      <MuiMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
          ...menuProps?.anchorOrigin
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
          ...menuProps?.transformOrigin
        }}
        PaperProps={{
          style: {
            minWidth: 225,
            borderRadius: 12
          }
        }}
        getContentAnchorEl={null}
        onClose={handleClose}>
        {menuItemComponents}
      </MuiMenu>
    </>
  );
};

export default Menu;
