import { useEffect } from "react";

import { DatasetSessionConfig } from "../utils/Dataset.constants";

import useStoreSelectors from "./useStoreSelectors";

import { UseFilesSessionPropsType } from "../types/Props.type";

const useFilesSession = (props?: UseFilesSessionPropsType) => {
  const { timer } = props || {};

  // Stores - STARTS >>
  const { datasetFilesStore, setDatasetFilesStore } = useStoreSelectors();
  // << ENDS - Stores

  const getDatasetFilesSession = () => {
    let datasetFilesSession: $TSFixMe = [];

    if (!sessionStorage.getItem(DatasetSessionConfig.UploadingFilesSessionKey)) {
      return datasetFilesSession;
    }

    try {
      datasetFilesSession =
        // @ts-ignore
        JSON.parse(sessionStorage.getItem(DatasetSessionConfig.UploadingFilesSessionKey)) || [];
    } catch (error: $TSFixMe) {
      console.error(error);
    }

    return datasetFilesSession;
  };

  const setDatasetFilesSession = (datasetFilesSession: $TSFixMe = []) => {
    sessionStorage.setItem(
      DatasetSessionConfig.UploadingFilesSessionKey,
      JSON.stringify(datasetFilesSession)
    );
  };

  const removeDatasetFilesSession = () => {
    sessionStorage.removeItem(DatasetSessionConfig.UploadingFilesSessionKey);
  };

  useEffect(() => {
    const thisDatasetFilesSession = getDatasetFilesSession();

    if ((thisDatasetFilesSession || [])?.length > 0) {
      // Getting all file names in session
      const datasetFileNamesSession = (thisDatasetFilesSession || [])?.map(
        (eachFile: $TSFixMe) => eachFile?.name
      );

      let thisDatasetFilesStore = (datasetFilesStore || [])
        // Filtering store out with session's file names
        ?.filter((eachFile: $TSFixMe) => datasetFileNamesSession.includes(eachFile?.name))
        // Mapping store with values of session
        ?.map((eachFileInStore: $TSFixMe) => {
          // The thisDatasetFileSession will have latest values of a file
          const thisDatasetFileSession = thisDatasetFilesSession?.find(
            (eachFileInSession: $TSFixMe) => eachFileInSession?.name === eachFileInStore?.name
          );

          // Updating latest values of a file to corresponding file in store
          if (Object.keys(thisDatasetFileSession)?.length > 0) {
            return { ...eachFileInStore, ...thisDatasetFileSession };
          } else {
            return { ...eachFileInStore };
          }
        });

      setDatasetFilesStore([...thisDatasetFilesStore]);
    } else {
      setDatasetFilesStore([]);
    }
  }, [sessionStorage.getItem(DatasetSessionConfig.UploadingFilesSessionKey), timer]);

  return { getDatasetFilesSession, setDatasetFilesSession, removeDatasetFilesSession };
};

export default useFilesSession;
