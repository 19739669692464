import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5)
  },
  formContainer: {
    columnGap: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: "60%",
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      rowGap: theme.spacing(2)
    },
    "& [class^=MuiGrid-root]": {
      flex: 1
    }
  },
  inputLabel: {
    opacity: 0.625
  },
  inputLabelShrink: {
    shrink: true,
    opacity: 1
  }
}));

export default useStyles;
