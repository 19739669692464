/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { AppTemplateInput } from './app-template-input';

/**
 * 
 * @export
 * @interface AppTemplateDto
 */
export interface AppTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'appType'?: AppTemplateDtoAppTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'source'?: AppTemplateDtoSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'status'?: AppTemplateDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'requirements'?: string;
    /**
     * 
     * @type {Array<AppTemplateInput>}
     * @memberof AppTemplateDto
     */
    'params'?: Array<AppTemplateInput>;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'autoMlProblemType'?: AppTemplateDtoAutoMlProblemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AppTemplateDto
     */
    'buildStatus'?: AppTemplateDtoBuildStatusEnum;
}

export const AppTemplateDtoAppTypeEnum = {
    Streamlit: 'STREAMLIT',
    Reactjs: 'REACTJS'
} as const;

export type AppTemplateDtoAppTypeEnum = typeof AppTemplateDtoAppTypeEnum[keyof typeof AppTemplateDtoAppTypeEnum];
export const AppTemplateDtoSourceEnum = {
    Tenant: 'TENANT',
    System: 'SYSTEM'
} as const;

export type AppTemplateDtoSourceEnum = typeof AppTemplateDtoSourceEnum[keyof typeof AppTemplateDtoSourceEnum];
export const AppTemplateDtoStatusEnum = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type AppTemplateDtoStatusEnum = typeof AppTemplateDtoStatusEnum[keyof typeof AppTemplateDtoStatusEnum];
export const AppTemplateDtoAutoMlProblemTypeEnum = {
    BinaryClassification: 'BINARY_CLASSIFICATION',
    BinaryClassificationGpt: 'BINARY_CLASSIFICATION_GPT',
    Regression: 'REGRESSION',
    MulticlassClassification: 'MULTICLASS_CLASSIFICATION',
    TimeseriesForecasting: 'TIMESERIES_FORECASTING',
    AnomalyDetection: 'ANOMALY_DETECTION',
    Clustering: 'CLUSTERING'
} as const;

export type AppTemplateDtoAutoMlProblemTypeEnum = typeof AppTemplateDtoAutoMlProblemTypeEnum[keyof typeof AppTemplateDtoAutoMlProblemTypeEnum];
export const AppTemplateDtoBuildStatusEnum = {
    Unbuilt: 'UNBUILT',
    Requested: 'REQUESTED',
    Building: 'BUILDING',
    Failure: 'FAILURE',
    Built: 'BUILT'
} as const;

export type AppTemplateDtoBuildStatusEnum = typeof AppTemplateDtoBuildStatusEnum[keyof typeof AppTemplateDtoBuildStatusEnum];


