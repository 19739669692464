import React from "react";

type ContextTypes = {
  cancelApiTokenRef: $TSFixMe;
  setDataSourceFilesInStore: $TSFixMeFunction;
  setFilesTimer: $TSFixMeFunction;
  isUploadDisabled: boolean;
  createDataset: $TSFixMeFunction;
  upload: $TSFixMeFunction;
  reUpload: $TSFixMeFunction;
  deleteFile: $TSFixMeFunction;
  ontologyNavigation: $TSFixMeFunction;
  updateDatasetLite: $TSFixMeFunction;
  updateDataset: $TSFixMeFunction;
  failFilesInSession: $TSFixMeFunction;
  ontologyDatasetIndex: number;
  envRelaunchToastIdRef: $TSFixMe;
};

// @ts-ignore
export const UploadContext = React.createContext<ContextTypes>();
