import React from "react";
import _ from "lodash";
import { FormControl, Grid, InputLabel, makeStyles, OutlinedInput } from "@material-ui/core";

import { useStyles as useTransformInputStyles } from "src/pages/Projects/common/TransformInputs/TransformInputs";

interface IProps {
  isDefaultScenario: boolean;
  errors: any;
  input: any;
  currentInput: any;
  isJobPath: boolean;
  values: any;
  onInputChange: (e: any) => void;
}

const useStyles = makeStyles({
  parametersWrap: {
    width: "100%",
    "& label": {
      whiteSpace: "nowrap"
    },
    gap: "8px"
  },
  flexWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    flexWrap: "nowrap",
    paddingTop: "8px"
  },
  helpTextContainer: {
    width: "25px"
  },
  formItem: {
    flex: 1
  }
});

const NumberInput: React.FC<IProps> = (props) => {
  const { isDefaultScenario, input, errors, currentInput, values, isJobPath, onInputChange } =
    props;

  const transformInputClasses = useTransformInputStyles();
  const classes = useStyles();

  const numberInputChange = (event: $TSFixMe) => {
    const input = event.target.value;
    if (!input || (/^\d+$/.test(input) && input > 0)) {
      onInputChange(event);
    } else {
      onInputChange({
        target: { value: "", name: input.name }
      });
    }
  };

  return (
    <Grid
      key={`transform-parameter-${input.name}`}
      container
      className={transformInputClasses.inputBox}
      test-id="selectedTransformParametersContainer">
      <Grid className={classes.formItem}>
        <FormControl
          disabled={!isDefaultScenario}
          variant="outlined"
          fullWidth
          margin="dense"
          error={errors?.[input.name]}
          required={currentInput?.is_required}>
          <InputLabel
            htmlFor={`parameters-input-${currentInput?.input_name}`}
            test-id="selectedTransformParametersInputLabel">
            {currentInput?.input_name}
          </InputLabel>
          <OutlinedInput
            margin="dense"
            fullWidth
            label={currentInput?.input_name}
            id={`parameters-input-${currentInput?.input_name}`}
            type="number"
            value={values[input.name] || ""}
            onChange={numberInputChange}
            inputProps={{
              type: "number",
              name: input.name,
              min: 1,
              "test-id": "selectedTransformParametersField"
            }}
            disabled={isJobPath || !isDefaultScenario}
          />
        </FormControl>
      </Grid>
      <Grid item className={classes.helpTextContainer} />
    </Grid>
  );
};

export default NumberInput;
