import React from "react";

type Props = {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
};

const PredictionServiceIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.402 9.11079C13.2973 8.92641 13.0614 8.86391 12.8786 8.97016C12.6942 9.07485 12.6317 9.31079 12.7364 9.4936C13.777 11.3045 14.0395 12.8155 13.4208 13.4342C12.238 14.6186 8.55202 12.5827 5.98327 10.0139C5.33483 9.36547 4.75202 8.69047 4.2442 8.01547C4.76608 7.31079 5.35827 6.62172 5.98327 5.99672C8.55045 3.42954 12.2364 1.39516 13.4208 2.57797C13.9036 3.06079 13.8536 4.10297 13.2817 5.43735C12.6551 6.89985 11.4895 8.52641 10.0005 10.0155C9.65202 10.3639 9.29108 10.6998 8.92702 11.0123C8.76608 11.1498 8.74889 11.3936 8.88639 11.553C9.02545 11.7139 9.26764 11.7311 9.42702 11.5936C9.80514 11.2686 10.1801 10.9186 10.5426 10.5577C12.0989 9.00141 13.3223 7.29047 13.9864 5.74047C14.6989 4.07954 14.6895 2.76391 13.963 2.03735C12.5317 0.606099 8.78795 2.10922 5.44108 5.45766C4.81764 6.0811 4.25827 6.7186 3.7692 7.35141C3.33014 6.69985 2.96608 6.05454 2.70202 5.43891C2.13014 4.1061 2.08014 3.06391 2.56295 2.57954C3.1817 1.96079 4.68952 2.22172 6.50045 3.26079C6.68327 3.36547 6.9192 3.30297 7.02389 3.1186C7.12858 2.93422 7.06608 2.69985 6.8817 2.59516C4.6942 1.34047 2.92233 1.13579 2.02077 2.03735C1.29264 2.76547 1.28483 4.07954 1.99733 5.74047C2.30983 6.47172 2.75045 7.23735 3.28795 8.0061C1.52389 10.5342 0.948892 12.9045 2.02077 13.9764C2.39264 14.3483 2.91139 14.5311 3.54577 14.5311C4.46295 14.5311 5.62233 14.1467 6.92858 13.3905C7.11139 13.2842 7.17389 13.0498 7.06764 12.8655C6.96139 12.6827 6.72702 12.6202 6.54264 12.7264C4.71295 13.7889 3.18795 14.0592 2.56295 13.4342C1.7567 12.628 2.44577 10.6592 3.77858 8.67329C4.27233 9.31235 4.83014 9.94672 5.43952 10.5561C6.99577 12.1123 8.7067 13.3342 10.2583 13.9998C11.0786 14.3514 11.8161 14.528 12.4395 14.528C13.077 14.528 13.5942 14.3436 13.963 13.9764C14.8645 13.0748 14.6598 11.3014 13.402 9.11079ZM7.99264 6.72641C7.28639 6.72641 6.71295 7.29985 6.71295 8.0061C6.71295 8.71235 7.28639 9.28579 7.99264 9.28579C8.69889 9.28579 9.27233 8.71235 9.27233 8.0061C9.27077 7.29985 8.69889 6.72641 7.99264 6.72641Z"
        fill={color}
      />
    </svg>
  );
};

export default PredictionServiceIcon;
