import React, { useMemo } from "react";

// Packages
import { isString } from "lodash";

// Icons
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import { Delete } from "src/icons/Delete";
import { PencilUnderlined } from "src/icons/PencilUnderlined";
import { Run } from "src/icons/Run";

// Utils
import { dateFormat } from "src/utils/dateFormat";
import { getScenarioStatusTemplate } from "../utils/Scenarios.helpers";

// Components
import { OverflowTooltip, Table } from "src/components";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";

// Constants
import { ScenariosHelperText } from "../utils/Scenarios.constants";

type Props = {
  data: any;
  isRefetchingProjectCanvas?: boolean;
  isRecipeInProject?: boolean;
  isRecipesRunningAcrossScenariosStore?: boolean;
  onEdit: (segmentId?: string) => void;
  onDelete: (segmentId?: string) => void;
  onRun: (segmentId?: string) => void;
  onDisplayOnDag: (segmentId?: string) => void;
  searchValue: string;
};

const ScenariosTable = (props: Props) => {
  const {
    data,
    isRefetchingProjectCanvas,
    isRecipeInProject,
    isRecipesRunningAcrossScenariosStore,
    onEdit,
    onDelete,
    onRun,
    onDisplayOnDag,
    searchValue
  } = props || {};

  const disabledRunActionMessage = useMemo(() => {
    if (!isRecipeInProject) {
      return "Add recipes to the project to enable this action.";
    }

    if (!!isRecipesRunningAcrossScenariosStore) {
      return "Please wait. One of the recipes is running in the project.";
    }

    return !!isRefetchingProjectCanvas;
  }, [isRefetchingProjectCanvas, isRecipeInProject, isRecipesRunningAcrossScenariosStore]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        id: "Name",
        accessor: "name",
        isTooltip: false,
        Cell: ({ row, value }: any) => (
          <LinkCellWithMenu
            linkText={<OverflowTooltip style={{ whiteSpace: "nowrap" }} value={value} />}
            {...(!row?.original?.default ? { onClick: () => onEdit(row?.original?.id) } : {})}
            menuButtons={[
              {
                icon: <PencilUnderlined />,
                label: ScenariosHelperText.Edit,
                action: () => onEdit(row?.original?.id),
                ...(!!row?.original?.default
                  ? {
                      disabled: true,
                      tooltip: ScenariosHelperText.DefaultScenarioRestrictedEditInfo
                    }
                  : {})
              },
              {
                icon: <Run />,
                label: ScenariosHelperText.Run,
                disabled: !!disabledRunActionMessage,
                action: () => onRun(row?.original?.id),
                ...(!!isString(disabledRunActionMessage)
                  ? { tooltip: disabledRunActionMessage }
                  : {})
              },
              {
                icon: <SettingsOverscanIcon />,
                label: ScenariosHelperText.DisplayOnDag,
                action: () => onDisplayOnDag(row?.original?.id)
              },
              {
                icon: <Delete />,
                label: ScenariosHelperText.Delete,
                disabled: !!row?.original?.default,
                action: () => onDelete(row?.original?.id),
                ...(!!row?.original?.default
                  ? {
                      disabled: true,
                      tooltip: ScenariosHelperText.DefaultScenarioRestrictedDeleteInfo
                    }
                  : {})
              }
            ]}
          />
        )
      },
      {
        Header: "Segments",
        id: "Segments",
        accessor: "segments"
      },
      {
        Header: "Last updated on",
        id: "Last updated on",
        accessor: "updated",
        Cell: ({ value }: any) => <span>{dateFormat(value)}</span>
      },
      {
        Header: "Status",
        id: "Status",
        accessor: "status",
        Cell: ({ value }: any) => getScenarioStatusTemplate(value, true)
      }
    ],
    [disabledRunActionMessage]
  );

  return (
    <Table
      size="small"
      data={data}
      columns={columns}
      globalFilter={searchValue}
      emptyTableMessage="No records"
      orderByDefault="Last updated on"
    />
  );
};

export default ScenariosTable;
