import React from "react";

export const AiAssistedIcon = ({ width = 20, height = 20, fill = "none" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.584 12.9165C14.584 13.8415 13.8423 14.5832 12.9173 14.5832C11.9923 14.5832 11.2507 13.8415 11.2507 12.9165C11.2507 11.9915 12.0007 11.2498 12.9173 11.2498C13.834 11.2498 14.584 11.9998 14.584 12.9165ZM7.08398 11.2498C6.16732 11.2498 5.41732 11.9998 5.41732 12.9165C5.41732 13.8332 6.16732 14.5832 7.08398 14.5832C8.00065 14.5832 8.75065 13.8415 8.75065 12.9165C8.75065 11.9915 8.00898 11.2498 7.08398 11.2498ZM19.1673 12.4998V14.9998C19.1673 15.4582 18.7923 15.8332 18.334 15.8332H17.5007V16.6665C17.5007 17.5915 16.759 18.3332 15.834 18.3332H4.16732C3.25065 18.3332 2.50065 17.5915 2.50065 16.6665V15.8332H1.66732C1.20898 15.8332 0.833984 15.4582 0.833984 14.9998V12.4998C0.833984 12.0415 1.20898 11.6665 1.66732 11.6665H2.50065C2.50065 8.4415 5.10898 5.83317 8.33398 5.83317H9.16732V4.77484C8.66732 4.4915 8.33398 3.94984 8.33398 3.33317C8.33398 2.4165 9.08398 1.6665 10.0007 1.6665C10.9173 1.6665 11.6673 2.4165 11.6673 3.33317C11.6673 3.94984 11.334 4.4915 10.834 4.77484V5.83317H11.6673C14.8923 5.83317 17.5007 8.4415 17.5007 11.6665H18.334C18.7923 11.6665 19.1673 12.0415 19.1673 12.4998ZM17.5007 13.3332H15.834V11.6665C15.834 9.3665 13.9673 7.49984 11.6673 7.49984H8.33398C6.03398 7.49984 4.16732 9.3665 4.16732 11.6665V13.3332H2.50065V14.1665H4.16732V16.6665H15.834V14.1665H17.5007V13.3332Z"
        fill="currentColor"
      />
    </svg>
  );
};
