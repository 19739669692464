/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import { deleteTransformById, getTransformGroupWithRethrow } from "src/api";

export const useDeleteTransformMutation = () => {
  return useMutation({
    mutationFn: async ({ transformId, recipeId }: { transformId: string; recipeId: string }) => {
      await deleteTransformById(transformId);
      const transformGroupResponse = await getTransformGroupWithRethrow(recipeId);
      return {
        transformGroupResponse: transformGroupResponse?.[0]
      };
    },
    cacheTime: 0
  });
};
