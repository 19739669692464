import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

type Props = {
  project: any;
  jobData: any;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, jobData } = props || {};

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id ? (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    ) : (
      <RenderText key="project-name" color="textSecondary">
        {project?.name || "Project"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    !!project?.id && (
      <Link key="jobs" to={`/projects/${project?.id}/prediction-job`}>
        <RenderText>Predictions</RenderText>
      </Link>
    )
  );

  breadcrumbs.push(
    !!project?.id && !!jobData?.id ? (
      <Link key="job-name" to={`/projects/${project?.id}/prediction-job/${jobData?.id}`}>
        <RenderText>{jobData?.name || "Predictions"}</RenderText>
      </Link>
    ) : (
      <RenderText key="job-name" color="textSecondary">
        {jobData?.name || "Prediction"}
      </RenderText>
    )
  );

  breadcrumbs.push(
    <RenderText key="logs" color="textSecondary">
      Logs
    </RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
