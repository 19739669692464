import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

export const useGetDataAppResourceUsage = ({
  dataAppId,
  enabled
}: {
  dataAppId: string | undefined;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: ["resource-usage", dataAppId],
    queryFn: async () => {
      return await getAPIWithRethrow(`/dataapps/${dataAppId}/resource-usage`);
    },
    enabled: enabled && !!dataAppId,
    cacheTime: 0,
    refetchInterval: 5000
  });
};
