import React from "react";

const SplashProject = () => (
  <svg
    width="241"
    height="215"
    viewBox="0 0 241 215"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M78.2563 11.4247C77.6801 11.4257 77.1278 11.655 76.7208 12.0622C76.3137 12.4695 76.0851 13.0213 76.0851 13.5967C76.0851 14.1721 76.3137 14.724 76.7208 15.1312C77.1278 15.5384 77.6801 15.7677 78.2563 15.7688H237.064C237.64 15.7677 238.192 15.5384 238.599 15.1312C239.006 14.724 239.235 14.1721 239.235 13.5967C239.235 13.0213 239.006 12.4695 238.599 12.0622C238.192 11.655 237.64 11.4257 237.064 11.4247H78.2563Z"
        fill="#f1957d"
      />
      <path
        d="M78.2563 24.4569C77.6794 24.4556 77.1257 24.6833 76.7169 25.0898C76.3081 25.4963 76.0778 26.0482 76.0766 26.6243C76.0754 27.2004 76.3034 27.7533 76.7104 28.1615C77.1175 28.5697 77.6702 28.7997 78.2471 28.8009H178.647C178.933 28.8014 179.216 28.7456 179.48 28.6367C179.745 28.5278 179.985 28.3679 180.187 28.1661C180.39 27.9644 180.55 27.7248 180.66 27.461C180.769 27.1972 180.826 26.9145 180.826 26.6289C180.826 26.3433 180.769 26.0606 180.66 25.7968C180.55 25.533 180.39 25.2934 180.187 25.0917C179.985 24.8899 179.745 24.73 179.48 24.6211C179.216 24.5121 178.933 24.4563 178.647 24.4569H78.2563Z"
        fill="#f1957d"
      />
      <path
        d="M78.2563 89.7956C77.6801 89.7966 77.1278 90.0259 76.7208 90.4331C76.3137 90.8404 76.0851 91.3922 76.0851 91.9676C76.0851 92.543 76.3137 93.0949 76.7208 93.5021C77.1278 93.9094 77.6801 94.1386 78.2563 94.1397H237.064C237.64 94.1386 238.192 93.9094 238.599 93.5021C239.006 93.0949 239.235 92.543 239.235 91.9676C239.235 91.3922 239.006 90.8404 238.599 90.4331C238.192 90.0259 237.64 89.7966 237.064 89.7956H78.2563Z"
        fill="#E4E4E4"
      />
      <path
        d="M78.2563 102.828C77.6794 102.827 77.1257 103.054 76.7169 103.461C76.3081 103.867 76.0778 104.419 76.0766 104.995C76.0754 105.571 76.3034 106.124 76.7104 106.532C77.1175 106.941 77.6702 107.171 78.2471 107.172H178.647C178.933 107.172 179.216 107.117 179.48 107.008C179.745 106.899 179.985 106.739 180.187 106.537C180.39 106.335 180.55 106.096 180.66 105.832C180.769 105.568 180.826 105.285 180.826 105C180.826 104.714 180.769 104.432 180.66 104.168C180.55 103.904 180.39 103.664 180.187 103.463C179.985 103.261 179.745 103.101 179.48 102.992C179.216 102.883 178.933 102.827 178.647 102.828H78.2563Z"
        fill="#E4E4E4"
      />
      <path
        d="M78.2563 168.167C77.6801 168.168 77.1278 168.397 76.7208 168.804C76.3137 169.211 76.0851 169.763 76.0851 170.339C76.0851 170.914 76.3137 171.466 76.7208 171.873C77.1278 172.28 77.6801 172.51 78.2563 172.511H237.064C237.64 172.51 238.192 172.28 238.599 171.873C239.006 171.466 239.235 170.914 239.235 170.339C239.235 169.763 239.006 169.211 238.599 168.804C238.192 168.397 237.64 168.168 237.064 168.167H78.2563Z"
        fill="#E4E4E4"
      />
      <path
        d="M78.2563 181.199C77.6794 181.198 77.1257 181.425 76.7169 181.832C76.3081 182.238 76.0778 182.79 76.0766 183.366C76.0754 183.942 76.3034 184.495 76.7104 184.903C77.1175 185.312 77.6702 185.542 78.2471 185.543H178.647C178.933 185.543 179.216 185.488 179.48 185.379C179.745 185.27 179.985 185.11 180.187 184.908C180.39 184.706 180.55 184.467 180.66 184.203C180.769 183.939 180.826 183.656 180.826 183.371C180.826 183.085 180.769 182.803 180.66 182.539C180.55 182.275 180.39 182.035 180.187 181.834C179.985 181.632 179.745 181.472 179.48 181.363C179.216 181.254 178.933 181.198 178.647 181.199H78.2563Z"
        fill="#E4E4E4"
      />
      <path
        d="M19.9872 26.7137H19.9797C19.8002 26.7127 19.6229 26.6734 19.4597 26.5986C19.2966 26.5238 19.1512 26.4152 19.0334 26.2799L12.2967 18.5557C12.1872 18.4307 12.1035 18.2854 12.0504 18.128C11.9973 17.9707 11.9757 17.8044 11.987 17.6387C11.9983 17.473 12.0422 17.3112 12.1162 17.1625C12.1902 17.0138 12.2928 16.8811 12.4182 16.772C12.5436 16.663 12.6893 16.5797 12.847 16.5269C13.0046 16.4741 13.1712 16.4529 13.3371 16.4645C13.503 16.4761 13.6649 16.5202 13.8137 16.5944C13.9625 16.6685 14.0952 16.7713 14.2042 16.8967L19.9983 23.5403L37.3923 4.05352C37.5027 3.92938 37.6365 3.82819 37.7861 3.75574C37.9356 3.68329 38.0981 3.641 38.264 3.63131C38.43 3.62161 38.5962 3.64469 38.7532 3.69923C38.9102 3.75376 39.055 3.83868 39.1791 3.94913C39.3032 4.05957 39.4043 4.19337 39.4766 4.34287C39.5488 4.49237 39.5909 4.65463 39.6003 4.82037C39.6097 4.98611 39.5862 5.15209 39.5313 5.30878C39.4764 5.46548 39.3911 5.60982 39.2803 5.73355L20.9311 26.2911C20.8125 26.424 20.6671 26.5304 20.5044 26.6032C20.3417 26.676 20.1654 26.7137 19.9872 26.7137Z"
        fill="#f1957d"
      />
      <path
        d="M20.1412 40.2258C14.7995 40.2258 9.67647 38.1068 5.89926 34.3349C2.12205 30.563 3.05176e-05 25.4472 3.05176e-05 20.1129C3.05176e-05 14.7786 2.12205 9.66284 5.89926 5.89094C9.67647 2.11904 14.7995 0 20.1412 0C20.3254 0 20.5021 0.0730695 20.6324 0.203135C20.7626 0.333201 20.8358 0.509608 20.8358 0.693548C20.8358 0.877489 20.7626 1.0539 20.6324 1.18396C20.5021 1.31403 20.3254 1.3871 20.1412 1.3871C16.4324 1.38716 12.8069 2.48547 9.72319 4.54313C6.63946 6.60078 4.236 9.52538 2.81675 12.9471C1.39749 16.3688 1.02618 20.1339 1.74977 23.7663C2.47336 27.3988 4.25936 30.7353 6.88191 33.3542C9.50446 35.973 12.8458 37.7564 16.4833 38.4789C20.1209 39.2014 23.8913 38.8306 27.3178 37.4133C30.7443 35.9959 33.673 33.5958 35.7335 30.5164C37.794 27.4369 38.8937 23.8165 38.8937 20.1129C38.8937 19.929 38.9669 19.7526 39.0972 19.6225C39.2274 19.4924 39.4041 19.4194 39.5883 19.4194C39.7725 19.4194 39.9491 19.4924 40.0794 19.6225C40.2096 19.7526 40.2828 19.929 40.2828 20.1129C40.2767 25.4454 38.1527 30.5577 34.3767 34.3283C30.6007 38.0989 25.4812 40.2198 20.1412 40.2258Z"
        fill="#3F3D56"
      />
      <path
        d="M20.6816 104.391H20.6742C20.4946 104.39 20.3174 104.351 20.1542 104.276C19.991 104.201 19.8457 104.092 19.7279 103.957L12.9912 96.233C12.8817 96.108 12.798 95.9626 12.7449 95.8053C12.6917 95.6479 12.6702 95.4816 12.6815 95.316C12.6928 95.1503 12.7367 94.9885 12.8107 94.8397C12.8847 94.691 12.9873 94.5584 13.1127 94.4493C13.2381 94.3402 13.3838 94.2569 13.5414 94.2041C13.6991 94.1514 13.8657 94.1302 14.0316 94.1418C14.1974 94.1533 14.3594 94.1975 14.5082 94.2717C14.657 94.3458 14.7897 94.4485 14.8987 94.574L20.6927 101.218L38.0868 81.7308C38.1973 81.607 38.3311 81.5061 38.4806 81.434C38.6301 81.3618 38.7923 81.3198 38.9581 81.3103C39.1239 81.3007 39.2899 81.3239 39.4467 81.3784C39.6035 81.433 39.748 81.5178 39.872 81.6281C39.996 81.7385 40.0969 81.8721 40.1692 82.0213C40.2414 82.1706 40.2836 82.3327 40.2931 82.4982C40.3026 82.6637 40.2795 82.8295 40.2248 82.9861C40.1702 83.1427 40.0853 83.287 39.9748 83.4108L21.6256 103.968C21.507 104.101 21.3615 104.208 21.1988 104.28C21.0362 104.353 20.8599 104.391 20.6816 104.391Z"
        fill="#f1957d"
      />
      <path
        d="M20.8357 117.903C15.4939 117.903 10.3709 115.784 6.59374 112.012C2.81653 108.24 0.694519 103.125 0.694519 97.7904C0.694519 92.4561 2.81653 87.3403 6.59374 83.5684C10.3709 79.7965 15.4939 77.6774 20.8357 77.6774C21.0199 77.6774 21.1966 77.7505 21.3268 77.8806C21.4571 78.0106 21.5303 78.187 21.5303 78.371C21.5303 78.5549 21.4571 78.7313 21.3268 78.8614C21.1966 78.9915 21.0199 79.0645 20.8357 79.0645C17.1269 79.0645 13.5013 80.1628 10.4176 82.2204C7.33379 84.278 4.93029 87.2026 3.51098 90.6243C2.09167 94.046 1.72033 97.8111 2.44389 101.444C3.16744 105.076 4.95339 108.413 7.57592 111.031C10.1985 113.65 13.5398 115.434 17.1774 116.156C20.8149 116.879 24.5853 116.508 28.0118 115.091C31.4383 113.673 34.367 111.273 36.4276 108.194C38.4881 105.114 39.5879 101.494 39.5879 97.7904C39.5879 97.6064 39.661 97.43 39.7913 97.2999C39.9215 97.1699 40.0982 97.0968 40.2824 97.0968C40.4666 97.0968 40.6433 97.1699 40.7735 97.2999C40.9038 97.43 40.9769 97.6064 40.9769 97.7904C40.9708 103.123 38.8468 108.235 35.0709 112.006C31.2951 115.776 26.1756 117.897 20.8357 117.903Z"
        fill="#3F3D56"
      />
      <path d="M240.417 58.1309H0.805679V59.518H240.417V58.1309Z" fill="#E4E4E4" />
      <path d="M240.417 135.808H0.805679V137.195H240.417V135.808Z" fill="#E4E4E4" />
      <path d="M240.417 213.486H0.805679V214.873H240.417V213.486Z" fill="#E4E4E4" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="241" height="215" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SplashProject;
