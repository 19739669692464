import React, { useMemo, useState } from "react";
import Add from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { Alert } from "@material-ui/lab";
import {
  Grid,
  ListItem,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  OutlinedInput,
  Tooltip,
  Button,
  Typography
} from "@material-ui/core";
import { size, isEmpty, includes, toLower, filter } from "lodash";

import SelectSearch from "components/Inputs/SelectSearch";
import styles from "./ConditionBuilder.module.scss";
import { OverflowTooltip } from "src/components";
import { operators } from "./ConditionBuilder";

interface IProps {
  isJobPath?: boolean;
  condition: $TSFixMe;
  conditionIndex: number;
  ruleIndex: number;
  error: $TSFixMe;
  rule: $TSFixMe;
  globalVariables: $TSFixMe;
  onFieldChange: $TSFixMeFunction;
  onOperatorChange: $TSFixMeFunction;
  onValueChange: $TSFixMeFunction;
  onDeleteCondition: $TSFixMeFunction;
  onAddCondition: $TSFixMeFunction;
  readonly: boolean;
}

export const MAX_FIELD_VALUE_LENGTH = 255;
const MAX_LIMIT_ERROR_MESSAGE = `Maximum allowed characters: ${MAX_FIELD_VALUE_LENGTH}. Your input exceeds this limit.`;

const Rule: React.FC<IProps> = (props) => {
  const {
    isJobPath,
    condition,
    conditionIndex,
    ruleIndex,
    error,
    rule,
    globalVariables,
    onFieldChange,
    onOperatorChange,
    onValueChange,
    onDeleteCondition,
    onAddCondition,
    readonly
  } = props;

  const [value, setValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const filtered = useMemo(() => {
    return filter(globalVariables, ({ name }) => includes(toLower(name), toLower(value)));
  }, [value, globalVariables]);

  const handleValueChange = (ruleId: $TSFixMe, conditionId: $TSFixMe, newValue: $TSFixMe) => {
    onValueChange(ruleId, conditionId, newValue);

    if (size(newValue) > 255) {
      if (!errorMessage) {
        setErrorMessage(MAX_LIMIT_ERROR_MESSAGE);
      }
    } else {
      if (errorMessage) {
        setErrorMessage("");
      }
    }
  };

  return (
    <ListItem className={styles.listItem} style={{ paddingLeft: ruleIndex * 25 + 50 }}>
      <span
        className={styles.line}
        style={{
          top: conditionIndex === 0 ? 0 : -39,
          left: (ruleIndex + 1) * 24,
          height: conditionIndex === 0 ? 35 : 89
        }}
      />
      <Grid container className={styles.conditionBlock}>
        <Grid container xs={12} spacing={2} alignItems="center">
          <Grid item xs={5}>
            <FormControl size="small" className={styles.fullWidth} variant="outlined">
              <Select
                id={`condition-field-${ruleIndex}-${conditionIndex}`}
                className={styles.fullWidth}
                onChange={({ target: { value } }) => onFieldChange(rule.id, condition.id, value)}
                defaultValue={condition?.field?.name || "Field"}
                error={error && condition?.field?.isEmpty}
                // @ts-ignore
                renderValue={(selected) =>
                  selected === "Field" ? (
                    <span className={styles.placeholder}>{selected}</span>
                  ) : (
                    selected
                  )
                }
                inputProps={{
                  "test-id": "condition-group-group-element-select"
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  PaperProps: { style: { maxWidth: 340 } },
                  getContentAnchorEl: null,
                  autoFocus: false
                }}
                onClose={() => setValue("")}
                disabled={!!isJobPath || readonly}>
                <SelectSearch value={value} onChange={setValue} />
                {filtered.map((item: $TSFixMe) => (
                  <MenuItem
                    key={item.id}
                    value={item.name}
                    test-id="condition-group-global-variable">
                    <OverflowTooltip value={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl size="small" className={styles.fullWidth} variant="outlined">
              <Select
                className={styles.fullWidth}
                onChange={({ target: { value } }) => onOperatorChange(rule.id, condition.id, value)}
                inputProps={{
                  "test-id": "condition-group-group-element-operators-select"
                }}
                defaultValue={
                  condition?.operator ||
                  // // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  operators[0]
                }
                error={error && !condition?.operator}
                value={condition?.operator}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                disabled={!!isJobPath || readonly}>
                <MenuItem value="Operator" disabled>
                  Operator
                </MenuItem>
                {/* // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                {operators?.map((item: $TSFixMe, index: $TSFixMe) => (
                  <MenuItem
                    key={index}
                    value={item.replace(/\s/g, "")}
                    test-id="condition-group-operator">
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {["IsNull", "IsNotNull"].includes(condition?.operator) ? null : (
            <Tooltip
              placement="top"
              title={condition?.operator === "IsInList" ? "Example value: A, B, C" : ""}>
              <Grid item xs={2}>
                <FormControl size="small" className={styles.fullWidth} variant="outlined">
                  <OutlinedInput
                    defaultValue={condition?.value?.value}
                    onChange={({ target: { value } }) =>
                      handleValueChange(rule.id, condition.id, value)
                    }
                    inputProps={{
                      "aria-label": "condition-value",
                      "test-id": "condition-group-group-element-value-input"
                    }}
                    labelWidth={0}
                    error={
                      (!isEmpty(error) && condition?.value?.value === "") || !isEmpty(errorMessage)
                    }
                    placeholder="Value"
                    required
                    disabled={!!isJobPath || readonly}
                  />
                </FormControl>
              </Grid>
            </Tooltip>
          )}
          {!isJobPath && (
            <Grid item xs={1}>
              <IconButton
                disabled={readonly}
                color="primary"
                test-id="condition-group-group-element-delete-condition"
                onClick={() => onDeleteCondition(rule.id, condition.id, ruleIndex)}>
                <DeleteOutline />
              </IconButton>
            </Grid>
          )}
        </Grid>
        {errorMessage && (
          <Alert className={styles.resetPasswordField} severity="error">
            {errorMessage}
          </Alert>
        )}
        {!isJobPath && conditionIndex === rule?.groupElements?.length - 1 && (
          <Grid id={`condition-${ruleIndex}-add`} item xs={12} className={styles.listButton}>
            <Button
              disabled={readonly}
              className={styles.subheaderButton}
              size="small"
              variant="text"
              test-id="condition-group-group-element-add-condition"
              onClick={(e) => onAddCondition(e, rule.id)}>
              <Typography variant="overline">
                <Add /> ADD RULE
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

export default Rule;
