/* eslint-disable no-unused-vars */
import React from "react";
import clsx from "clsx";
import { MenuItem, Box, Grid, Typography, makeStyles, CircularProgress } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const options = [
  {
    memGuarantee: 1,
    cpuGuarantee: 1
  },
  {
    memGuarantee: 2,
    cpuGuarantee: 1
  },
  {
    memGuarantee: 2,
    cpuGuarantee: 2
  },
  {
    memGuarantee: 4,
    cpuGuarantee: 4
  }
];

const useStyles = makeStyles({
  subTitle: {
    fontSize: "12px"
  },
  selectedMenuItem: {
    backgroundColor: "#E6F5FF",
    opacity: "1 !important"
  },
  menuItem: {
    borderBottom: "1px solid #cccccc",
    "&:hover": {
      background: "rgba(230, 245, 255, .6)"
    },

    "&:last-of-type": {
      borderBottom: "none !important"
    }
  },
  menuHeader: {
    paddingLeft: "10px",
    paddingRight: "8px"
  }
});
export const NotebookSizesListItems = ({
  memGuarantee,
  cpuGuarantee,
  memGuaranteeUpdating,
  cpuGuaranteeUpdating,
  onClose,
  onUpdateConfig,
  isLoading
}: {
  onClose: () => void;
  onUpdateConfig: (config: { memGuarantee: number; cpuGuarantee: number }) => void;
  memGuarantee: number | null;
  cpuGuarantee: number | null;
  memGuaranteeUpdating: number | undefined;
  cpuGuaranteeUpdating: number | undefined;
  isLoading: boolean;
}) => {
  const classes = useStyles();
  return (
    <Box m="0px 10px">
      <MenuItem
        className={classes.menuHeader}
        onClick={onClose}
        test-id="tenant-menu-notebook-sizes-back-btn">
        <Grid container alignItems="center">
          <Box pr="6px" display="flex">
            <ChevronLeftIcon fontSize="small" />
          </Box>
          <Typography className={classes.subTitle}>MY NOTEBOOK SIZE</Typography>
        </Grid>
      </MenuItem>
      <Box test-id="tenant-menu-notebook-sizes-list">
        {options.map((option, index) => {
          const isCurrentConfigUpating =
            isLoading &&
            cpuGuaranteeUpdating === option.cpuGuarantee &&
            memGuaranteeUpdating === option.memGuarantee;
          return (
            <MenuItem
              key={index}
              test-id="tenant-menu-notebook-sizes"
              className={clsx([
                classes.menuItem,
                {
                  [classes.selectedMenuItem]:
                    option.memGuarantee === memGuarantee && option.cpuGuarantee === cpuGuarantee
                }
              ])}
              disabled={
                isLoading ||
                (option.memGuarantee === memGuarantee && option.cpuGuarantee === cpuGuarantee)
              }
              onClick={() => onUpdateConfig(option)}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography>{`${option.memGuarantee} GB RAM & ${option.cpuGuarantee} GHz CPU`}</Typography>
                {isCurrentConfigUpating && <CircularProgress size={20} />}
              </Grid>
            </MenuItem>
          );
        })}
      </Box>
    </Box>
  );
};
