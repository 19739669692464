/* eslint-disable no-unused-vars */
import React from "react";
import { useForm } from "react-hook-form";
import {
  makeStyles,
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip
} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";

import { Modal } from "src/components/custom";
import { validateNameField } from "src/utils/formFieldUtils";
import { useProjectsStore } from "src/store/store";
import { shouldRefreshProjectsToggler } from "src/store/store.selectors";
import {
  useCreateTenantMutation,
  useLicenseTestMutation,
  useTenantSwitchMutation
} from "src/hooks/api";
import InfoIcon from "@material-ui/icons/Info";
import { createDefaultEnvironment } from "src/api";
import { WebPaths } from "src/routing/routes";

const useStyles = makeStyles({
  createTenantForm: {
    gap: "16px",
    paddingTop: "8px"
  },
  licenseRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "16px"
  },
  successHelperText: {
    color: "#1B9654"
  },
  errorHelperText: {
    color: "#E16546",
    paddingRight: "6px"
  },
  submitBtn: {
    width: "100px"
  }
});

type FormValues = {
  tenantName: string;
  tenantDescription: string;
  licenseKey: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  licenseEnabled: boolean;
};

export const CreateTenantModal = ({ open, licenseEnabled, onClose }: Props) => {
  const classes = useStyles();
  const licenseTestMutation = useLicenseTestMutation();
  const toggleShouldProjectsRefresh = useProjectsStore(shouldRefreshProjectsToggler);
  const tenantSwitchMutation = useTenantSwitchMutation({
    onSuccess: async () => {
      await createDefaultEnvironment();
      toggleShouldProjectsRefresh();
      window.location.href = WebPaths.Dashboard;
      window.history.pushState(null, "", WebPaths.Dashboard);
    }
  });
  const tenantCreateMutation = useCreateTenantMutation({
    onSuccess: async (data: $TSFixMe) => {
      await tenantSwitchMutation.mutate({ tenantId: data.id });
    }
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<FormValues>({});
  const licenseKey = watch("licenseKey");

  const onFormSubmit = async (data: FormValues) => {
    tenantCreateMutation.mutate({
      name: data.tenantName,
      description: data.tenantDescription,
      licenseKey: data.licenseKey
    });
  };

  const onTest = () => {
    licenseTestMutation.mutate({ licenseKey });
  };

  const clearLicenseKey = () => {
    setValue("licenseKey", "");
    licenseTestMutation.reset();
  };
  const errorMsg =
    (licenseTestMutation.error as $TSFixMe)?.response?.data?.msg ||
    (licenseTestMutation.error as $TSFixMe)?.message;

  return open ? (
    <Modal
      open={open}
      size="md"
      title="Create Tenant"
      onSubmit={handleSubmit(onFormSubmit)}
      onClose={onClose}
      isSubmitDisabled={
        (licenseEnabled && (!licenseTestMutation.isSuccess || licenseTestMutation.isLoading)) ||
        !!errors.tenantName
      }
      isSubmitting={tenantCreateMutation.isLoading || tenantSwitchMutation.isLoading}
      submitLabel="Create">
      <Grid container direction="column" className={classes.createTenantForm}>
        <TextField
          fullWidth
          required
          {...register("tenantName", {
            setValueAs: (val) => val?.trim(),
            validate: (value) => {
              const { error } = validateNameField({
                fieldName: value,
                fieldNameLabel: "tenant name"
              });
              return error;
            }
          })}
          size="small"
          label="Tenant Name"
          variant="outlined"
          error={!!errors.tenantName}
          helperText={errors.tenantName && <>{errors.tenantName?.message}</>}
        />
        <TextField
          fullWidth
          size="small"
          {...register("tenantDescription", {
            setValueAs: (val: string) => val?.trim()
          })}
          label="Tenant Description"
          variant="outlined"
        />
        {licenseEnabled && (
          <Grid container direction="column">
            <Grid className={classes.licenseRow}>
              <Box flex={1}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  {...register("licenseKey", {
                    required: "The License Key cannot be blank.",
                    setValueAs: (val: string) => val?.trim(),
                    onChange: () => licenseTestMutation.reset()
                  })}
                  label="License Key"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={clearLicenseKey} disabled={!licenseKey}>
                          <BackspaceIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  disabled={licenseTestMutation.isLoading}
                  error={!!errors.licenseKey || licenseTestMutation.isError}
                  helperText={errors.licenseKey && <>{errors.licenseKey?.message}</>}
                />
              </Box>
              <Box width="120px">
                <Button fullWidth variant="outlined" disabled={!licenseKey} onClick={onTest}>
                  Test
                </Button>
              </Box>
            </Grid>
            {licenseTestMutation.isSuccess && (
              <Typography variant="subtitle2" className={classes.successHelperText}>
                License Key Test Successful
              </Typography>
            )}
            {licenseTestMutation.isError && (
              <Grid container direction="row" alignItems="center">
                <Typography variant="subtitle2" className={classes.errorHelperText}>
                  Invalid License key
                </Typography>
                {(licenseTestMutation.isError as $TSFixMe) && (
                  <Tooltip title={errorMsg}>
                    <InfoIcon style={{ color: "#E16546", fontSize: 16 }} />
                  </Tooltip>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Modal>
  ) : (
    <></>
  );
};
