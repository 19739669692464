import React from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { FixedSizeList as List } from "react-window";

import DragItem from "./DragItem";
import ReactWindowRow from "./ReactWindowRow";
import { IColumn } from "../TableSettingsMenu";

interface IProps {
  isColumnLimitReached: boolean;
  filteredColumnList: IColumn[];
  onDragEnd: (result: DropResult) => void;
  onChange: (event: React.ChangeEvent<{}>, checked: boolean) => void;
}

const ITEM_SIZE = 34;
const ColumnFilterDragDropMenu = ({
  isColumnLimitReached,
  onChange,
  filteredColumnList,
  onDragEnd
}: IProps) => {
  const itemCount = filteredColumnList.length;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        mode="virtual"
        direction="vertical"
        renderClone={(provided, __, rubric) => (
          <DragItem
            provided={provided}
            item={filteredColumnList[rubric.source.index]}
            isColumnLimitReached={isColumnLimitReached}
            onChange={onChange}
          />
        )}>
        {(provided) => (
          <List
            height={Math.min(300, ITEM_SIZE * itemCount + 1)}
            itemCount={itemCount}
            itemSize={ITEM_SIZE}
            outerRef={provided.innerRef}
            itemData={{ items: filteredColumnList, isColumnLimitReached, onChange }}
            width="100%">
            {ReactWindowRow}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ColumnFilterDragDropMenu;
