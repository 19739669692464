import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import _, { isEmpty } from "lodash";

import { Select, Tabs, Tab } from "src/components";
import { FilterIcon } from "src/icons/FilterIcon";
import ChartCard from "./ChartCard";
import ChartFrame from "./ChartFrame";

const useStyles = makeStyles({
  chartList: {
    padding: "16px",
    backgroundColor: "#f5f5f5",
    flex: 1
  },
  chartHeader: {
    paddingBottom: "16px"
  },
  inputAdornment: {
    marginRight: "16px",
    backgroundColor: "#fff"
  },
  groupedChartsHeader: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%"
  },
  chartsRightFilter: {
    flexWrap: "nowrap",
    flex: 0,
    gap: "16px"
  },
  tabs: {
    overflow: "auto"
  }
});

export const ChartList = ({
  charts,
  token,
  hideAIGuide
}: {
  charts: Array<any>;
  token: string;
  hideAIGuide?: boolean;
}) => {
  const classes = useStyles();
  const { hasNewCharts, hasAnyGroups, allGroupedCharts } = useMemo(() => {
    return {
      hasNewCharts: charts?.some((chart) => chart.dataFilePath),
      hasAnyGroups: charts?.some((chart) => chart.group),
      allGroupedCharts: charts.reduce((acc, item) => {
        const groupName = item.group || "Basic";
        acc[groupName] = acc[groupName] || [];
        acc[groupName].push(item);
        return acc;
      }, {})
    };
  }, [charts]);

  const [value, setValue] = useState(Object.keys(allGroupedCharts)?.[0]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [highlightedChart, setHighlightedChart] = useState("all");

  const activeChartNames = useMemo(
    () => _.map(hasAnyGroups ? allGroupedCharts[value] : charts, "name") || [],
    [hasAnyGroups, allGroupedCharts, value, charts]
  );
  const filteredCharts = useMemo(
    () => _.filter(charts, (chart) => activeChartNames.includes(chart.name)),
    [charts, activeChartNames]
  );

  const chartValues = useMemo(() => {
    const defaultCharts = [
      {
        label: "All Charts",
        value: "all"
      }
    ];

    const activeCharts = _.orderBy(
      _.map(activeChartNames, (name) => ({ label: name, value: name })),
      "label"
    );

    return isEmpty(activeCharts) ? defaultCharts : [...defaultCharts, ...activeCharts];
  }, [activeChartNames]);

  const chartsSelect = (
    <Select
      variant="outlined"
      margin="dense"
      startAdornment={
        <Box pr="16px">
          <FilterIcon />
        </Box>
      }
      menuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      }}
      value={highlightedChart}
      values={chartValues || []}
      onChange={(event) => setHighlightedChart(event.target.value as string)}
    />
  );
  const chartsBtn = (
    <Button color="primary" variant="text" onClick={() => setIsExpanded((expanded) => !expanded)}>
      Expand/Collapse All
    </Button>
  );

  return hasNewCharts ? (
    <Grid container direction="column" wrap="nowrap" className={classes.chartList}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        className={classes.chartHeader}>
        {hasAnyGroups ? (
          <Grid container direction="row" className={classes.groupedChartsHeader}>
            <Grid item className={classes.tabs}>
              <Tabs
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                  setHighlightedChart("all");
                  setIsExpanded(true);
                }}>
                {Object.keys(allGroupedCharts)?.map((groupName) => (
                  <Tab value={groupName} key={groupName} label={groupName} />
                ))}
              </Tabs>
            </Grid>
            <Grid item container direction="row" className={classes.chartsRightFilter}>
              {chartsSelect}
              {chartsBtn}
            </Grid>
          </Grid>
        ) : (
          <>
            {chartsSelect}
            {chartsBtn}
          </>
        )}
      </Grid>
      <Grid container spacing={2}>
        {_.map(filteredCharts, (chart: any) => {
          return (
            <Grid
              key={chart.name}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={filteredCharts?.length === 1 ? 12 : 6}>
              <ChartCard
                token={token}
                isHighlighted={highlightedChart === chart.name}
                data={chart}
                isExpanded={isExpanded}
                hideAIGuide={hideAIGuide}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  ) : (
    <Grid container xs={12} spacing={2} style={{ padding: 16 }}>
      {charts?.map((chart, index) => (
        <ChartFrame token={token} data={chart} key={index} size={charts?.length === 1 ? 12 : 6} />
      ))}
    </Grid>
  );
};
