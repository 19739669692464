/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import { updateTransformGroup, addTransformGroup } from "src/api";

export const useUpdateTransformGroupMutation = () => {
  return useMutation({
    mutationFn: ({
      recipeId,
      transformGroup,
      projectId
    }: {
      projectId: string;
      recipeId: string | undefined;
      transformGroup: $TSFixMe;
    }) => {
      const response = recipeId
        ? updateTransformGroup(transformGroup, recipeId)
        : addTransformGroup(transformGroup, projectId);
      const configGroup = Array.isArray(response) ? response[0] : response;
      return configGroup;
    },
    cacheTime: 0
  });
};
