import React from "react";

export const ImageIcon = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5271 0.507812H1.47291C0.676469 0.507812 0 1.15025 0 1.98072V12.0224C0 12.8529 0.676406 13.4953 1.47291 13.4953H14.5271C15.3235 13.4953 16 12.8529 16 12.0224V1.98072C16 1.15022 15.3236 0.507812 14.5271 0.507812ZM0.9375 1.98072C0.9375 1.6815 1.18006 1.44531 1.47291 1.44531H14.5271C14.8197 1.44531 15.0625 1.68131 15.0625 1.98072V7.87822L12.8502 5.66591C12.7623 5.578 12.6431 5.52859 12.5188 5.52859C12.3944 5.52859 12.2752 5.57797 12.1873 5.66591L8.50209 9.35119L6.82519 7.67431C6.64216 7.49128 6.34534 7.49128 6.16228 7.67431L1.30578 12.5308C1.09341 12.461 0.9375 12.2622 0.9375 12.0224V1.98072ZM14.5271 12.5578H2.60456L6.49375 8.66866L8.17066 10.3455C8.35369 10.5286 8.6505 10.5286 8.83356 10.3455L12.5188 6.66028L15.0625 9.20403V12.0224C15.0625 12.3216 14.8199 12.5578 14.5271 12.5578Z"
        fill="#515151"
      />
      <path
        d="M3.9833 6.46559C5.07233 6.46559 5.9583 5.57963 5.9583 4.49063C5.9583 3.40163 5.07233 2.51562 3.9833 2.51562C2.89427 2.51562 2.0083 3.40163 2.0083 4.49063C2.0083 5.57963 2.89427 6.46559 3.9833 6.46559ZM3.9833 3.45312C4.55539 3.45312 5.0208 3.91856 5.0208 4.49063C5.0208 5.06269 4.55539 5.52809 3.9833 5.52809C3.41121 5.52809 2.9458 5.06266 2.9458 4.49063C2.9458 3.91853 3.41121 3.45312 3.9833 3.45312Z"
        fill="#515151"
      />
    </svg>
  );
};
