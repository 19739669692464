import { Button, Grid, Typography, makeStyles } from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import React from "react";

import systemFailureImgSrc from "src/assets/images/system-failure-icon.svg";

const useStyles = makeStyles({
  runFailureImg: {
    width: "60px",
    height: "60px"
  },
  failureView: {
    flexWrap: "nowrap",
    gap: "16px",
    alignItems: "center"
  }
});
export const AutoMLRecipeRunFailureView = ({ onBack }: { onBack: () => void }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.failureView}>
      <Grid component="img" src={systemFailureImgSrc} className={classes.runFailureImg} />
      <Typography color="primary">Oops! Recipe Run Failed</Typography>
      <Button startIcon={<ArrowBackOutlined />} onClick={() => onBack()}>
        Go back to Dataset View
      </Button>
    </Grid>
  );
};
