import { useCallback, useEffect, useRef, useState } from "react";

// Packages
import { find, isEmpty } from "lodash";
import shallow from "zustand/shallow";
import {
  useEdgesState,
  useNodesState,
  useReactFlow,
  Node as ReactFlowNode,
  Edge as ReactFlowEdge
} from "react-flow-renderer";

// Open API
import { Node } from "openapi/Models";

// Stores
import { useCanvasStore } from "src/store/store";

// Types
import { NodeType } from "../../../Dag.types";

const useManageDagFlow = () => {
  const { setCenter } = useReactFlow();

  const [nodes, setNodes, onNodesChange] = useNodesState<ReactFlowNode[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<ReactFlowEdge[]>([]);

  const [nodesStore, nodeToFocusStore] = useCanvasStore(
    (state) => [state.nodes, state.nodeToFocus],
    shallow
  );

  const timeoutMap = useRef<NodeJS.Timeout | null>(null);
  const [isMinimap, setIsMiniMap] = useState(true);

  // Node search & focus on it.
  useEffect(() => {
    if (!isEmpty(nodeToFocusStore)) {
      const focusNode = find(
        nodes || [],
        (node: ReactFlowNode) => node?.data?.displayName === nodeToFocusStore
      );

      if (!!focusNode) {
        setCenter(focusNode?.position?.x, focusNode?.position?.y, {
          zoom: 1,
          duration: 500
        });
      }
    }
  }, [nodeToFocusStore, nodes]);

  const formatNodesToCanvas = (thisNodes: NodeType[]) => {
    const nodeTypes = {
      entity: "entity",
      dfsgroup: "dfsgroup",
      chart: "chart",
      artifact: "artifact",
      model: "model"
    };

    const canvasNodeIdNodeMap = nodesStore.reduce(
      (acc: { [key: string]: Node }, node: Node) => ({
        ...acc,
        [node.id]: node
      }),
      {}
    );

    return thisNodes
      ?.filter((node: NodeType) => !!node?.data?.id)
      ?.map((node: NodeType) => ({
        ...canvasNodeIdNodeMap[node.id],
        ...node.data,
        id: node?.data?.id || "",
        // @ts-ignore
        type: nodeTypes[node?.data?.type],
        // @ts-ignore
        column: node?.position?.x / 180,
        // @ts-ignore
        row: node?.position?.y / 180,
        displayName: node?.data?.displayName,
        name: node?.data?.name,
        status: node?.data?.status,
        projectId: node?.data?.projectId
      }));
  };

  const resetNodesAndEdges = useCallback(() => {
    setNodes((prevElements: ReactFlowNode[]) => {
      return prevElements?.map((elem: ReactFlowNode) => {
        elem.style = {
          ...elem.style,
          opacity: 1
        };
        return elem;
      });
    });

    setEdges((prevElements: ReactFlowEdge[]) => {
      return prevElements?.map((elem: ReactFlowEdge) => {
        elem.animated = false;
        elem.selected = false;

        elem.style = {
          ...elem.style,
          stroke: "#c7c7c7",
          opacity: 1
        };

        elem.markerEnd = {
          // @ts-ignore
          ...elem.markerEnd,
          color: "#c7c7c7"
        };

        return elem;
      });
    });
  }, []);

  const minimap = useCallback(() => {
    if (timeoutMap.current) clearTimeout(timeoutMap.current);
    setIsMiniMap(true);
    timeoutMap.current = setTimeout(() => {
      setIsMiniMap(false);
      timeoutMap.current = null;
    }, 2500);
  }, []);

  const nodeColorSelection = (node: ReactFlowNode) => {
    const { data } = node;
    const colorStatus: { [key: string]: string } = {
      UNBUILT: "#00000042",
      EMPTY: "#00000042",
      RUNNING: "#2196f3",
      PENDING: "#ff9800",
      SUCCESS: "#4caf50",
      BUILT: "#4caf50",
      ERROR: "#f44336"
    };

    return colorStatus[data.status];
  };

  return {
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    resetNodesAndEdges,
    formatNodesToCanvas,
    minimap,
    isMinimap,
    nodeColorSelection
  };
};

export default useManageDagFlow;
