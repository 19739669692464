/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateSegmentDto } from '../Models';
// @ts-ignore
import type { Data } from '../Models';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { SegmentDto } from '../Models';
// @ts-ignore
import type { TrySegmentDto } from '../Models';
// @ts-ignore
import type { UpdateSegmentDto } from '../Models';
/**
 * SegmentControllerApi - axios parameter creator
 * @export
 */
export const SegmentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSegmentDto} createSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (createSegmentDto: CreateSegmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSegmentDto' is not null or undefined
            assertParamExists('create1', 'createSegmentDto', createSegmentDto)
            const localVarPath = `/v2/segments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSegmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete1', 'id', id)
            const localVarPath = `/v2/segments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [name] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSegment: async (entityId: string, name?: string, projectRunId?: string, projectRunEntryId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('findSegment', 'entityId', entityId)
            const localVarPath = `/v2/segments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (projectRunId !== undefined) {
                localVarQueryParameter['projectRunId'] = projectRunId;
            }

            if (projectRunEntryId !== undefined) {
                localVarQueryParameter['projectRunEntryId'] = projectRunEntryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get1', 'id', id)
            const localVarPath = `/v2/segments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData: async (id: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getData', 'id', id)
            const localVarPath = `/v2/segments/{id}/data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TrySegmentDto} trySegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trySegment: async (trySegmentDto: TrySegmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trySegmentDto' is not null or undefined
            assertParamExists('trySegment', 'trySegmentDto', trySegmentDto)
            const localVarPath = `/v2/segments/try`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trySegmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSegmentDto} updateSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (updateSegmentDto: UpdateSegmentDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSegmentDto' is not null or undefined
            assertParamExists('update1', 'updateSegmentDto', updateSegmentDto)
            const localVarPath = `/v2/segments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSegmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SegmentControllerApi - functional programming interface
 * @export
 */
export const SegmentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SegmentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSegmentDto} createSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(createSegmentDto: CreateSegmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create1(createSegmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SegmentControllerApi.create1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SegmentControllerApi.delete1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [name] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSegment(entityId: string, name?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SegmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findSegment(entityId, name, projectRunId, projectRunEntryId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SegmentControllerApi.findSegment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SegmentControllerApi.get1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getData(id: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Data>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getData(id, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SegmentControllerApi.getData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TrySegmentDto} trySegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trySegment(trySegmentDto: TrySegmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Data>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trySegment(trySegmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SegmentControllerApi.trySegment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateSegmentDto} updateSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(updateSegmentDto: UpdateSegmentDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SegmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(updateSegmentDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SegmentControllerApi.update1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SegmentControllerApi - factory interface
 * @export
 */
export const SegmentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SegmentControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSegmentDto} createSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(createSegmentDto: CreateSegmentDto, options?: any): AxiosPromise<SegmentDto> {
            return localVarFp.create1(createSegmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.delete1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} [name] 
         * @param {string} [projectRunId] 
         * @param {string} [projectRunEntryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSegment(entityId: string, name?: string, projectRunId?: string, projectRunEntryId?: string, options?: any): AxiosPromise<Array<SegmentDto>> {
            return localVarFp.findSegment(entityId, name, projectRunId, projectRunEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1(id: string, options?: any): AxiosPromise<SegmentDto> {
            return localVarFp.get1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData(id: string, limit?: number, options?: any): AxiosPromise<Data> {
            return localVarFp.getData(id, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TrySegmentDto} trySegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trySegment(trySegmentDto: TrySegmentDto, options?: any): AxiosPromise<Data> {
            return localVarFp.trySegment(trySegmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSegmentDto} updateSegmentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(updateSegmentDto: UpdateSegmentDto, options?: any): AxiosPromise<SegmentDto> {
            return localVarFp.update1(updateSegmentDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SegmentControllerApi - interface
 * @export
 * @interface SegmentControllerApi
 */
export interface SegmentControllerApiInterface {
    /**
     * 
     * @param {CreateSegmentDto} createSegmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApiInterface
     */
    create1(createSegmentDto: CreateSegmentDto, options?: RawAxiosRequestConfig): AxiosPromise<SegmentDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApiInterface
     */
    delete1(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} entityId 
     * @param {string} [name] 
     * @param {string} [projectRunId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApiInterface
     */
    findSegment(entityId: string, name?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SegmentDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApiInterface
     */
    get1(id: string, options?: RawAxiosRequestConfig): AxiosPromise<SegmentDto>;

    /**
     * 
     * @param {string} id 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApiInterface
     */
    getData(id: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<Data>;

    /**
     * 
     * @param {TrySegmentDto} trySegmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApiInterface
     */
    trySegment(trySegmentDto: TrySegmentDto, options?: RawAxiosRequestConfig): AxiosPromise<Data>;

    /**
     * 
     * @param {UpdateSegmentDto} updateSegmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApiInterface
     */
    update1(updateSegmentDto: UpdateSegmentDto, options?: RawAxiosRequestConfig): AxiosPromise<SegmentDto>;

}

/**
 * SegmentControllerApi - object-oriented interface
 * @export
 * @class SegmentControllerApi
 * @extends {BaseAPI}
 */
export class SegmentControllerApi extends BaseAPI implements SegmentControllerApiInterface {
    /**
     * 
     * @param {CreateSegmentDto} createSegmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApi
     */
    public create1(createSegmentDto: CreateSegmentDto, options?: RawAxiosRequestConfig) {
        return SegmentControllerApiFp(this.configuration).create1(createSegmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApi
     */
    public delete1(id: string, options?: RawAxiosRequestConfig) {
        return SegmentControllerApiFp(this.configuration).delete1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} entityId 
     * @param {string} [name] 
     * @param {string} [projectRunId] 
     * @param {string} [projectRunEntryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApi
     */
    public findSegment(entityId: string, name?: string, projectRunId?: string, projectRunEntryId?: string, options?: RawAxiosRequestConfig) {
        return SegmentControllerApiFp(this.configuration).findSegment(entityId, name, projectRunId, projectRunEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApi
     */
    public get1(id: string, options?: RawAxiosRequestConfig) {
        return SegmentControllerApiFp(this.configuration).get1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApi
     */
    public getData(id: string, limit?: number, options?: RawAxiosRequestConfig) {
        return SegmentControllerApiFp(this.configuration).getData(id, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TrySegmentDto} trySegmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApi
     */
    public trySegment(trySegmentDto: TrySegmentDto, options?: RawAxiosRequestConfig) {
        return SegmentControllerApiFp(this.configuration).trySegment(trySegmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSegmentDto} updateSegmentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentControllerApi
     */
    public update1(updateSegmentDto: UpdateSegmentDto, options?: RawAxiosRequestConfig) {
        return SegmentControllerApiFp(this.configuration).update1(updateSegmentDto, options).then((request) => request(this.axios, this.basePath));
    }
}

