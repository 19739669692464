import { useMutation, UseMutationResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { CreateSegmentDto, SegmentDto } from "openapi/Models";

import { areAllKeysPresentAndNotNil } from "src/utils/helpers";

const useSaveSegment = (): UseMutationResult<SegmentDto, unknown, CreateSegmentDto, unknown> =>
  useMutation({
    mutationFn: async (payload: CreateSegmentDto) => {
      if (!areAllKeysPresentAndNotNil(payload, ["entityId", "name", "condition"])) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.SegmentControllerApi.create1({ ...payload })
      );
    }
  });

export default useSaveSegment;
