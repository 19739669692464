import { useQuery } from "@tanstack/react-query";

import { getTransformGroupWithRethrow } from "src/api";

export const useGetRecipes = (
  requests: {
    recipeId?: string | null;
    scenarioId?: string;
    jobRunId?: string;
  }[]
) => {
  return useQuery({
    queryKey: ["entitiesData", requests.map((request) => request.recipeId)],
    queryFn: async () => {
      const responses = await Promise.all(
        requests.map((request) => {
          const { recipeId, scenarioId, jobRunId } = request;
          return getTransformGroupWithRethrow(recipeId, scenarioId, jobRunId);
        })
      );

      const recipes = responses?.map((response) =>
        Array.isArray(response) ? response[0] : response
      );
      return recipes;
    },
    enabled: requests?.length > 0
  });
};
