/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ProjectRunDto
 */
export interface ProjectRunDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'schedule'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'status'?: ProjectRunDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'jobType'?: ProjectRunDtoJobTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof ProjectRunDto
     */
    'scheduleInfo'?: object;
    /**
     * 
     * @type {number}
     * @memberof ProjectRunDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectRunDto
     */
    'updated'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'updater'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectRunDto
     */
    'variables'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectRunDto
     */
    'computedVariables'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof ProjectRunDto
     */
    'timeoutInHours'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectRunDto
     */
    'modelEntityId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRunDto
     */
    'deleted'?: boolean;
}

export const ProjectRunDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type ProjectRunDtoStatusEnum = typeof ProjectRunDtoStatusEnum[keyof typeof ProjectRunDtoStatusEnum];
export const ProjectRunDtoJobTypeEnum = {
    ProjectJob: 'PROJECT_JOB',
    PredictionJob: 'PREDICTION_JOB'
} as const;

export type ProjectRunDtoJobTypeEnum = typeof ProjectRunDtoJobTypeEnum[keyof typeof ProjectRunDtoJobTypeEnum];


