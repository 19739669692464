import React, { Dispatch, SetStateAction } from "react";

import { ProjectDto, ProjectRunDto } from "openapi/Models";

type ContextTypes = {
  isFromDatasetPage: boolean;
  isFromArtifactsModelModal: boolean;
  setIsFromArtifactsModelModal: Dispatch<SetStateAction<boolean>>;

  scenarioId: string;
  project: ProjectDto;

  isFetchingProjectScenarios: boolean;
  jobsData: ProjectRunDto;
};

// @ts-ignore
export const DagContext = React.createContext<ContextTypes>();
