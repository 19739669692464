/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { GroupRunResultDto } from './group-run-result-dto';

/**
 * 
 * @export
 * @interface RecipeRunData
 */
export interface RecipeRunData {
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'groupId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {GroupRunResultDto}
     * @memberof RecipeRunData
     */
    'groupRunResult'?: GroupRunResultDto;
    /**
     * 
     * @type {number}
     * @memberof RecipeRunData
     */
    'index'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'tenantId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'hostname'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'recipeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'recipeDisplayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'recipeType'?: RecipeRunDataRecipeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'projectName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'status'?: RecipeRunDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {number}
     * @memberof RecipeRunData
     */
    'timeout'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'username'?: string;
    /**
     * 
     * @type {number}
     * @memberof RecipeRunData
     */
    'lastRunTimestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipeRunData
     */
    'currentExecutorId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RecipeRunData
     */
    'stopped'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecipeRunData
     */
    'running'?: boolean;
}

export const RecipeRunDataRecipeTypeEnum = {
    Standard: 'STANDARD',
    Code: 'CODE',
    AutoMl: 'AUTO_ML',
    ApiConnector: 'API_CONNECTOR'
} as const;

export type RecipeRunDataRecipeTypeEnum = typeof RecipeRunDataRecipeTypeEnum[keyof typeof RecipeRunDataRecipeTypeEnum];
export const RecipeRunDataStatusEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR',
    InQueue: 'IN_QUEUE',
    Running: 'RUNNING',
    Unbuilt: 'UNBUILT'
} as const;

export type RecipeRunDataStatusEnum = typeof RecipeRunDataStatusEnum[keyof typeof RecipeRunDataStatusEnum];


