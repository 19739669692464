import React from "react";

export const TrashIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4451 5.09764C17.4265 5.09764 17.3986 5.09764 17.3707 5.09764C12.4498 4.60462 7.53821 4.41858 2.67308 4.9116L0.775418 5.09764C0.384722 5.13485 0.0405372 4.85578 0.00332806 4.46509C-0.0338811 4.07439 0.245188 3.73951 0.626581 3.7023L2.52425 3.51626C7.47309 3.01393 12.487 3.20928 17.5103 3.7023C17.8916 3.73951 18.1707 4.08369 18.1335 4.46509C18.1056 4.82788 17.7986 5.09764 17.4451 5.09764Z"
      fill={color}
    />
    <path
      d="M5.81823 4.15812C5.78103 4.15812 5.74382 4.15812 5.69731 4.14882C5.32521 4.0837 5.06475 3.72091 5.12987 3.34882L5.33452 2.13022C5.48335 1.2372 5.688 0 7.8554 0H10.2926C12.4693 0 12.674 1.28372 12.8135 2.13953L13.0182 3.34882C13.0833 3.73022 12.8228 4.093 12.4507 4.14882C12.0693 4.21393 11.7065 3.95347 11.6507 3.58138L11.4461 2.37208C11.3158 1.56278 11.2879 1.40464 10.3019 1.40464H7.8647C6.8787 1.40464 6.86009 1.53488 6.72056 2.36278L6.5066 3.57208C6.45079 3.91626 6.15312 4.15812 5.81823 4.15812Z"
      fill={color}
    />
    <path
      d="M12.0598 19.9996H6.08771C2.84122 19.9996 2.71098 18.2042 2.60866 16.7531L2.00401 7.38565C1.9761 7.00426 2.27378 6.66938 2.65517 6.64148C3.04587 6.62287 3.37145 6.91124 3.39935 7.29264L4.004 16.6601C4.10633 18.074 4.14354 18.6042 6.08771 18.6042H12.0598C14.0133 18.6042 14.0505 18.074 14.1435 16.6601L14.7481 7.29264C14.7761 6.91124 15.1109 6.62287 15.4923 6.64148C15.8737 6.66938 16.1714 6.99495 16.1435 7.38565L15.5388 16.7531C15.4365 18.2042 15.3063 19.9996 12.0598 19.9996Z"
      fill={color}
    />
    <path
      d="M10.6176 14.8836H7.51996C7.13854 14.8836 6.82227 14.5673 6.82227 14.186C6.82227 13.8046 7.13854 13.4883 7.51996 13.4883H10.6176C10.999 13.4883 11.3153 13.8046 11.3153 14.186C11.3153 14.5673 10.999 14.8836 10.6176 14.8836Z"
      fill={color}
    />
    <path
      d="M11.399 11.161H6.74784C6.36645 11.161 6.05017 10.8447 6.05017 10.4633C6.05017 10.0819 6.36645 9.76562 6.74784 9.76562H11.399C11.7804 9.76562 12.0967 10.0819 12.0967 10.4633C12.0967 10.8447 11.7804 11.161 11.399 11.161Z"
      fill={color}
    />
  </svg>
);
