import React from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import tenant_name_wrap from "src/assets/images/tenant_name_wrap.svg";

const useStyles = makeStyles({
  basicTenantDetailsWrap: {
    gap: "20px"
  },
  cardWrap: {
    padding: "16px",
    background: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0px 2px 2px rgba(152, 152, 152, 0.25)"
  },
  tokenWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%"
  },
  gridGap: {
    gap: "8px"
  },
  subHeading: {
    fontSize: "14px",
    color: "#000000",
    opacity: 0.5
  },
  flexWidth: {
    flex: 1
  },
  title: {},
  description: { fontSize: "14px" },
  singleStat: {
    display: "flex",
    gap: "6px",
    flexDirection: "column",
    alignItems: "center"
  },
  nameColumn: {
    display: "flex",
    gap: "20px",
    alignItems: "flex-start"
  },
  imageWrap: {
    position: "relative",
    display: "inline-block"
  },
  nameChar: {
    position: "absolute",
    top: "6px",
    left: "11px",
    color: "white"
  },
  imageGridWrap: {
    paddingRight: "30px"
  },
  basicTenantDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap"
  }
});

const BasicTenantDetails = ({
  name,
  description,
  onEdit,
  canEdit
}: {
  onEdit: () => void;
  name: string;
  description: string;
  canEdit: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.basicTenantDetails}>
      <Grid item className={classes.imageGridWrap}>
        <Grid className={classes.imageWrap}>
          <img src={tenant_name_wrap} alt="Tenant Name Wrap" />
          <Typography className={classes.nameChar}>{name.charAt(0).toUpperCase()}</Typography>
        </Grid>
      </Grid>
      <Grid container item direction="column" className={classes.gridGap}>
        <Typography className={classes.subHeading}>Tenant Name</Typography>
        <Grid container direction="row" className={classes.nameColumn}>
          <Typography color="textPrimary">{name}</Typography>
          {canEdit && (
            <IconButton aria-label="edit-icon" size="small" onClick={onEdit}>
              <EditIcon style={{ fontSize: 16 }} />
            </IconButton>
          )}
        </Grid>
        <Typography className={classes.description}>{description}</Typography>
      </Grid>
    </Grid>
  );
};
export default BasicTenantDetails;
