import React from "react";

export const Download = ({ disabled }: { disabled?: boolean }) => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ opacity: disabled ? 0.5 : "default" }}>
      <path
        d="M0 10H2V15H18V10H20V15C20 16.11 19.11 17 18 17H2C0.9 17 0 16.11 0 15V10ZM10 13L15.55 7.54L14.13 6.13L11 9.25V0H9V9.25L5.88 6.13L4.46 7.55L10 13Z"
        fill="#4646B5"
      />
    </svg>
  );
};
