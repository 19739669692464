import { useMemo } from "react";

type Props = {
  classes: any;
  status: string;
};

const useNodeStyle = (props: Props) => {
  const { classes, status } = props || {};

  // Define a type for the valid status keys
  type StatusKey = keyof typeof classes;

  // Memoize the status key to avoid recalculating on every render
  const statusKey: StatusKey = useMemo(() => status as StatusKey, [status]);

  return { classes, statusKey };
};

export default useNodeStyle;
