import { head, includes } from "lodash";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { Environment } from "pages/private/Environments/Environments";
import { EnvironmentStatuses } from "src/constants/environments.constants";
import { getAPIWithRethrow } from "src/utils/apiService";
import { relaunchEnvironment } from "src/api/environments";

interface IVariable {
  envId: string;
}

const useRelaunchEnvironment = (): UseMutationResult<unknown, unknown, IVariable, unknown> => {
  return useMutation({
    mutationFn: async ({ envId }) => {
      const response = await getAPIWithRethrow(`/v2/envs/${envId}`);
      const envDetails: Environment | undefined = head(response);
      if (
        !includes(
          [EnvironmentStatuses.Active, EnvironmentStatuses.Launching],
          envDetails?.launchStatus
        )
      ) {
        await relaunchEnvironment(envId, false, envDetails, false);
      }

      return null;
    }
  });
};

export default useRelaunchEnvironment;
