import * as React from "react";
import { dateFormat } from "src/utils/dateFormat";

import { IEntityDetails } from "hooks/api/entities/useEntityDetails";
import { useStyles } from "./styling";
import MuiLink from "@material-ui/core/Link";

interface IProps {
  entityDetails: IEntityDetails;
  isSourceFileImport: boolean;
  navigateToSource: () => void;
}
export default function EntityDetailItem({
  entityDetails,
  isSourceFileImport,
  navigateToSource
}: IProps) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.detailsWrapper}>
        {entityDetails?.parentTransform && (
          <div className={classes.detailItem}>
            <p className={classes.detailName} data-testid="entity-detail-build-label">
              Build:
            </p>
            <p data-testid="entity-detail-build-value">{dateFormat(entityDetails?.built)}</p>
          </div>
        )}
        <div className={classes.detailItem}>
          <p data-testid="entity-detail-created-label" className={classes.detailName}>
            Created:
          </p>
          <p data-testid="entity-detail-created-value">{dateFormat(entityDetails?.created)}</p>
        </div>
        <div className={classes.detailItem}>
          <p data-testid="entity-detail-updated-label" className={classes.detailName}>
            Updated:
          </p>
          <p data-testid="entity-detail-updated-value">{dateFormat(entityDetails?.updated)}</p>
        </div>
        {entityDetails?.parentTransform && (
          <div className={classes.detailItem}>
            <p data-testid="entity-detail-parent-transform-label" className={classes.detailName}>
              Parent Transform:
            </p>
            <p data-testid="entity-detail-parent-transform-value">
              {entityDetails?.parentTransform ?? "-"}
            </p>
          </div>
        )}
        <div className={classes.detailItem}>
          <p data-testid="entity-detail-size-label" className={classes.detailName}>
            Total size:
          </p>
          <p data-testid="entity-detail-size-value">
            {entityDetails?.totalSize?.size === 0 && entityDetails?.totalSize?.unit === "KB"
              ? "1 KB"
              : `${entityDetails?.totalSize?.size ?? "-"} ${entityDetails?.totalSize?.unit ?? "-"}`}
          </p>
        </div>
        <div className={classes.detailItem}>
          <p data-testid="entity-detail-rows-label" className={classes.detailName}>
            Rows:
          </p>
          <p data-testid="entity-detail-rows-value">{entityDetails?.rows ?? "-"}</p>
        </div>
        <div className={classes.detailItem}>
          <p data-testid="entity-detail-columns-label" className={classes.detailName}>
            Columns:
          </p>
          <p data-testid="entity-detail-columns-value">{entityDetails?.columns ?? "-"}</p>
        </div>
        {isSourceFileImport && (
          <div className={classes.detailItem}>
            <p data-testid="entity-detail-source-label" className={classes.detailName}>
              Source:
            </p>
            <MuiLink style={{ cursor: "pointer" }} onClick={navigateToSource}>
              <p data-testid="entity-detail-source-value">File Import</p>
            </MuiLink>
          </div>
        )}
      </div>
    </>
  );
}
