/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import {
  createTemplateWithRethrow,
  saveTransformByIdWithRethrow,
  getTransformGroupWithRethrow
} from "src/api";
import { Recipe } from "src/types";

export const useSaveCodeRecipeMutationV2 = () => {
  return useMutation({
    mutationFn: async ({
      projectId,
      code,
      recipe,
      codeHistoryId
    }: {
      code: string;
      projectId: string;
      recipe: Recipe | null;
      codeHistoryId: string | null;
    }) => {
      const templateGroupPayload = {
        name: uuidv4(),
        code,
        projectId,
        baseTransforms: [{ condition: null, type: "python", params: {}, runConfigId: null }],
        type: "CODE"
      };

      const templateResponse: $TSFixMe = await createTemplateWithRethrow(templateGroupPayload);

      const payload = {
        name: uuidv4(),
        groupId: recipe?.id,
        templateId: templateResponse.id,
        codeHistoryId
      };
      const saveTransformResponse = await saveTransformByIdWithRethrow(payload);

      const transformGroupResponse = await getTransformGroupWithRethrow(recipe?.id);

      return {
        templateResponse,
        saveTransformResponse,
        transformGroupResponse: transformGroupResponse?.[0]
      };
    },
    cacheTime: 0
  });
};
