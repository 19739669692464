import React from "react";
import _ from "lodash";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip
} from "@material-ui/core";

import { DataappAskAIConfigInputTypeEnum } from "openapi/Models/dataapp-ask-aiconfig";
import { askAITypesInfoList } from "src/pages/DataApps/utils/DataApps.constants";
import { dataAppConfigFields } from "./CreateDataAppForm";

interface IProps {
  readOnly?: boolean;
  value: DataappAskAIConfigInputTypeEnum;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectAskAIInputType: React.FC<IProps> = (props) => {
  return (
    <FormControl component="fieldset" disabled={props.readOnly}>
      <FormLabel component="legend">Inputs</FormLabel>
      <RadioGroup
        row={props.readOnly}
        aria-label={dataAppConfigFields.inputType}
        name={dataAppConfigFields.inputType}
        value={props.value}
        data-testid="dataAppaskAITypesInfoList"
        onChange={props.onChange}>
        {_.map(askAITypesInfoList, ({ label, tooltip, value }, index) => {
          return (
            <FormControlLabel
              key={index}
              value={value}
              data-testid="dataAppaskAITypesInfoItem"
              control={<Radio />}
              label={
                <>
                  <span>{label}</span>{" "}
                  <Tooltip title={tooltip}>
                    <InfoOutlinedIcon
                      data-testid="dataAppaskAITypesInfoIcon"
                      style={{ fontSize: "1rem", marginBottom: "2px", color: "#8a8989" }}
                    />
                  </Tooltip>
                </>
              }
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default SelectAskAIInputType;
