import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton,
  Tooltip
} from "@material-ui/core";
import clsx from "clsx";
import { Edit, SettingsBackupRestore } from "@material-ui/icons";
import EventBus from "src/utils/EventBus";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiFilledInput-root": {
      borderRadius: 4,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "transparent"
      },
      "&.Mui-focused>div": {
        backgroundColor: "transparent"
      }
    }
  }
}));

type Props = TextFieldProps & {
  InputProps?: $TSFixMe;
  savedValue: string;
};

const EditSecretField = ({
  id,
  variant = "filled",
  savedValue,
  className,
  InputProps,
  ...rest
}: Props) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  //   const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEdit = () => {
    // setShowPassword(false);
    if (isEditing) {
      rest?.onChange?.({ target: { value: savedValue } } as any);
    } else {
      rest?.onChange?.({ target: { value: "" } } as any);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
    setIsEditing((prev) => !prev);
  };

  //   const handleClickShowPassword = () => {
  //     if (inputRef.current) {
  //       const cursorPosition = inputRef.current.selectionStart || 0;
  //       setShowPassword((prev) => !prev);
  //       setTimeout(() => {
  //         if (inputRef.current) {
  //           inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
  //         }
  //       }, 0);
  //     }
  //   };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    EventBus.subscribe("RESET-SECRETS", () => {
      setIsEditing(false);
      //   setShowPassword(false);
    });
    return () => {
      EventBus.unsubscribe("RESET-SECRETS");
    };
  }, []);

  return (
    <TextField
      id={id}
      name={id}
      variant={"outlined"}
      type={"password"}
      className={clsx(classes.textField, className)}
      disabled={!isEditing}
      inputRef={inputRef}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end">
            {/* {isEditing && (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            )} */}
            <Tooltip title={isEditing ? "Reset Changes" : "Edit"}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleEdit}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {isEditing ? <SettingsBackupRestore /> : <Edit />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        ...InputProps
      }}
      {...rest}
    />
  );
};

export default EditSecretField;
