import { Grid } from "@material-ui/core";
import React from "react";

// Styles
import { useStyles } from "./SignIn.styles";

// Icons
import rapidCanvasLogo from "src/assets/images/rc-logo-dark-text.svg";

export const RCLogo = () => {
  const classes = useStyles();
  return (
    <Grid item className={classes.rapidCanvasLogoContainer}>
      <img
        src={rapidCanvasLogo}
        width={250}
        alt="RapidCanvas logo"
        data-testid="signInRapidCanvasLogo"
      />
    </Grid>
  );
};
