import React from "react";
import { IconButton } from "@material-ui/core";
import PaginationItem from "@material-ui/lab/PaginationItem";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core";
import { usePagination } from "@material-ui/lab";

const useStyles = (absolute: boolean) =>
  makeStyles((theme) => ({
    paginationContainer: {
      left: 0,
      transfrom: absolute ? "translateX(50%)" : "none",
      display: "flex",
      justifyContent: "center",
      position: !absolute ? "sticky" : "absolute",
      bottom: absolute ? 0 : "unset",
      paddingTop: 10,
      paddingBottom: 10
    },
    paginationNav: {
      display: "block"
    },
    paginationList: {
      margin: 0,
      display: "flex",
      padding: 0,
      flexWrap: "wrap",
      listStyle: "none",
      alignItems: "center"
    },
    paginationItem: {
      color: theme.palette.primary.main,

      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          cursor: "default"
        }
      }
    }
  }));

type OwnPaginationProps = {
  pageCount?: $TSFixMe;
  pageOptions?: $TSFixMe; // TODO: instanceOf(Array)
  actualPage?: number;
  canPreviousPage?: boolean;
  canNextPage?: boolean;
  nextPage?: $TSFixMeFunction;
  previousPage?: $TSFixMeFunction;
  gotoPage?: $TSFixMe;
  absolutePosition?: boolean;
};

// @ts-expect-error TS(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type PaginationProps = OwnPaginationProps & typeof Pagination.defaultProps;

function Pagination({
  pageCount,
  actualPage,
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  gotoPage,
  absolutePosition = false
}: PaginationProps) {
  const classes = useStyles(absolutePosition)();
  const { items } = usePagination({ count: pageCount, page: actualPage + 1 });

  return (
    <div className={classes.paginationContainer}>
      <nav className={classes.paginationNav}>
        <ul className={classes.paginationList}>
          <li>
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <IconButton
              size="small"
              disabled={!canPreviousPage}
              onClick={canPreviousPage ? previousPage : null}>
              <ChevronLeftIcon />
            </IconButton>
          </li>
          {items.map(({ page, type, selected }, index) => {
            let children = null;
            if (type === "page" || type === "first" || type === "last") {
              children = (
                <PaginationItem
                  className={classes.paginationItem}
                  selected={selected}
                  page={page}
                  onClick={() => !selected && gotoPage(page - 1)}
                />
              );
            } else if (type === "start-ellipsis" || type === "end-ellipsis") {
              children = "...";
            }
            return <li key={index}>{children}</li>;
          })}

          <li>
            {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
            <IconButton
              size="small"
              disabled={!canNextPage}
              onClick={canNextPage ? nextPage : null}>
              <ChevronRightIcon />
            </IconButton>
          </li>
        </ul>
      </nav>
    </div>
  );
}

Pagination.defaultProps = {
  pageOptions: [],
  actualPage: 0,
  nextPage() {},
  previousPage() {},
  gotoPage() {}
};

export default Pagination;
