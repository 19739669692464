import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";
import { Environment } from "pages/private/Environments/Environments";

const QUERY_KEY_ENV_BY_ID = "query-env-by-id";

const useGetEnvironmentById = (
  envId?: string,
  options?: UseQueryOptions<Environment>
): UseQueryResult<Environment> => {
  return useQuery<Environment>({
    queryKey: [QUERY_KEY_ENV_BY_ID, envId],
    queryFn: async () => {
      const response = await getAPIWithRethrow(`/v2/envs/${envId}`);

      return response?.[0];
    },
    ...options
  });
};

export default useGetEnvironmentById;
export { QUERY_KEY_ENV_BY_ID };
