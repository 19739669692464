import React, { useCallback, useMemo, useState } from "react";
import { isEmpty, map, size } from "lodash";
import { useTheme, Grid, Paper, Box, Tooltip, Typography, makeStyles } from "@material-ui/core";

import { CodeEditor, Spinner, Tabs, Tab, TabPanel } from "../../../../../components";
import { useFetchWithZustand } from "../../../../../utils/useFetchWithZustand";
import { getProjectsWithRethrow } from "../../../../../api";
import { useProjectsStore } from "../../../../../store/store";
import { projectsGetter, projectsSetter } from "../../../../../store/store.selectors";
import { EnvironmentProjectsTable } from "./EnvironmentProjectsTable";
import EnvironmentPredictionServiceTable from "./EnvironmentPredictionServiceTable";
import {
  getEnvironmentConfigSession,
  setEnvironmentConfigSession
} from "../../utils/environments.helpers";
import { IPredictionService } from "../Environment";
import Space from "src/pages/common/Space";
import { InfoOutlined } from "@material-ui/icons";
import { PackagesInfoLines } from "../../utils/Environments.constants";

type Props = {
  envProjects: Record<string, { jobsCount: number; predictionJobsCount: number }>;
  isLoadingPredictionServices: boolean;
  predictionServices: IPredictionService[];
  isDefault: boolean;
};

const useStyles = makeStyles((theme) => ({
  tabPanelContainer: {
    // 94 (SubTopNavBar height)
    // + 24 (Page padding)
    // + 48 (Tabs height)
    // + 24 (Data section margin-top)
    // + 8 (Data section padding-top)
    // + n (buffer)
    height: "calc(100vh - 214px)",
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    overflowY: "auto"
  },
  tabPanel: {
    height: "100%"
  }
}));

export const EnvironmentTabs = ({
  envProjects = {},
  isLoadingPredictionServices,
  predictionServices,
  isDefault
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [value, setValue] = useState(1);

  const projects = useProjectsStore(projectsGetter);
  const setProjects = useProjectsStore(projectsSetter);

  const { isLoading: isLoadingProjects } = useFetchWithZustand({
    fetchCallback: getProjectsWithRethrow,
    setApiData: setProjects,
    shouldFetchData: !projects
  });

  const [values, setValues] = useState<$TSFixMe>({ code: getEnvironmentConfigSession()?.code });

  const handleChangeCodeEditor = (name: string, value: string) => {
    setValues({
      [name]: value
    });

    setEnvironmentConfigSession({ ...getEnvironmentConfigSession(), [name]: value });
  };

  const handleChange = useCallback(
    (newValue: $TSFixMe) => {
      if (value === newValue) return;
      setValue(newValue);
    },
    [value]
  );

  const filteredProjects = useMemo(() => {
    const projectsList = [...projects];
    const projectNames = Object.keys(envProjects);

    return projectsList
      .filter((project: $TSFixMe) => projectNames.includes(project.name))
      .map((project: $TSFixMe) => ({
        ...project,
        jobCount: envProjects[project.name]?.jobsCount,
        predictionJobCount: envProjects[project.name]?.predictionJobsCount
      }));
  }, [projects, envProjects]);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable">
        <Tab
          value={1}
          label={
            <Space justifyContent="center">
              <Typography>Packages</Typography>
              <Tooltip
                arrow
                title={map(PackagesInfoLines, (line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    {index < size(PackagesInfoLines) - 1 && <br />}
                  </React.Fragment>
                ))}>
                <InfoOutlined style={{ color: "#9e9e9e", fontSize: "16px" }} />
              </Tooltip>
            </Space>
          }
        />
        <Tab value={2} label="Projects" />
        <Tab value={3} label="Prediction Service" />
      </Tabs>
      <div className={classes.tabPanelContainer}>
        <TabPanel value={value} index={1} className={classes.tabPanel}>
          <Paper
            elevation={0}
            style={{
              width: "100%",
              height: "100%"
            }}>
            <Tooltip
              title={!!isDefault ? "Cannot update requirements of the DEFAULT env." : ""}
              placement="top"
              arrow>
              <div style={{ height: "100%" }}>
                <CodeEditor
                  name="code"
                  height="100%"
                  value={values.code}
                  // @ts-expect-error
                  width="100%"
                  scriptType="SYSTEM"
                  onChange={handleChangeCodeEditor}
                  readOnly={!!isDefault}
                  hideTestCode
                />
              </div>
            </Tooltip>
          </Paper>
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabPanel}>
          <Grid
            container
            style={{
              height: isEmpty(filteredProjects) ? "inherit" : "initial",
              rowGap: theme.spacing(2)
            }}>
            {isLoadingProjects ? (
              <Spinner />
            ) : isEmpty(filteredProjects) ? (
              <>
                <Box m="auto">
                  <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                    The environment is not used in any project yet.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" align="center">
                    After you use it for any project, all of them will be listed here.
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="body2" color="textSecondary">
                  Once you create the environment, RapidCanvas will pull automatically the projects
                  in which the environment is used. This will help to keep track of the projects
                  related to this environment in case you need to change any setting.
                </Typography>
                <EnvironmentProjectsTable data={filteredProjects} />
              </>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.tabPanel}>
          <Grid
            container
            style={{
              height: isEmpty(predictionServices) ? "inherit" : "initial",
              rowGap: theme.spacing(2)
            }}>
            {isLoadingPredictionServices ? (
              <Spinner />
            ) : isEmpty(predictionServices) ? (
              <>
                <Box m="auto">
                  <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                    The environment is not used in any prediction service yet.
                  </Typography>
                  <Typography variant="body2" color="textSecondary" align="center">
                    After you use it for any prediction service, all of them will be listed here.
                  </Typography>
                </Box>
              </>
            ) : (
              <EnvironmentPredictionServiceTable data={predictionServices} />
            )}
          </Grid>
        </TabPanel>
      </div>
    </>
  );
};
