import React, { useMemo } from "react";

// Packages
import { generatePath, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// MUI
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

// Utils
import { capitalizeFirstLetter } from "src/utils/capitalize";

// Components
import OverflowTooltip from "src/components/OverflowTooltip";
import { WebPaths } from "src/routing/routes";

const ScenariosMenu = (props: $TSFixMe) => {
  const { projectId, scenarios, onClose } = props || {};

  const navigate = useNavigate();

  // $FixMe: Scope to be refactored.
  const options = useMemo(() => {
    return scenarios.map((scenario: $TSFixMe) => ({
      name: (scenario as $TSFixMe).default ? "Default Scenario" : (scenario as $TSFixMe).name,
      id: (scenario as $TSFixMe).id,
      default: (scenario as $TSFixMe).default
    }));
  }, [scenarios]);

  // $FixMe: Scope to be refactored.
  const optionSorted = useMemo(() => {
    const list = options.sort((a: $TSFixMe, b: $TSFixMe) => a.name.localeCompare(b.name));
    if (list.length <= 1) return list;
    const defaultScenarioIndex = list.findIndex((scenario: $TSFixMe) => scenario?.default);
    list.splice(0, 0, list.splice(defaultScenarioIndex, 1)[0]);
    return list;
  }, [options]);

  const onMenuClick = (menuItem: $TSFixMe) => {
    navigate(
      generatePath(`${WebPaths.Dag}${WebPaths.Canvas}`, { projectId, scenarioId: menuItem.id }),
      { replace: true }
    );
    onClose();
  };

  return optionSorted?.map((menuItem: $TSFixMe) => (
    <MenuItem key={uuidv4()} onClick={() => onMenuClick(menuItem)}>
      <OverflowTooltip
        value={
          <Typography variant="body2" component="span">
            {capitalizeFirstLetter(menuItem?.name)}
          </Typography>
        }
      />
    </MenuItem>
  ));
};

export default ScenariosMenu;
