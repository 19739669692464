import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";
import { IProjectReturn } from "./useRunDetails";

const QUERY_KEY_ALL_PROJECT_RUNS = "query-key-all-project-runs";

export interface IProjectRun {
  dto: IProjectReturn;
  lastRunEnties: Record<string, any>[];
  lastRunEntry: Record<string, any>;
}

const useGetAllProjectRuns = (
  projectId?: string,
  options?: UseQueryOptions<IProjectRun[]>
): UseQueryResult<IProjectRun[]> => {
  return useQuery({
    queryKey: [QUERY_KEY_ALL_PROJECT_RUNS, projectId],
    queryFn: async () => {
      if (!projectId) {
        throw "projectId is absent";
      }

      const projectRuns = await api.fetchResponse(
        async () => await api.ProjectRunControllerApi.findProjectRuns(projectId, "PREDICTION_JOB")
      );

      return projectRuns as IProjectRun[];
    },
    ...options,
    refetchOnMount: true
  });
};

export default useGetAllProjectRuns;
export { QUERY_KEY_ALL_PROJECT_RUNS };
