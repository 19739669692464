/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DownloadEntityDto
 */
export interface DownloadEntityDto {
    /**
     * 
     * @type {string}
     * @memberof DownloadEntityDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadEntityDto
     */
    'dataFileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadEntityDto
     */
    'projectRunEntryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadEntityDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadEntityDto
     */
    'fileType'?: DownloadEntityDtoFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DownloadEntityDto
     */
    'separator'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadEntityDto
     */
    'encoding'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DownloadEntityDto
     */
    'downloadRaw'?: boolean;
}

export const DownloadEntityDtoFileTypeEnum = {
    Csv: 'CSV',
    Parquet: 'PARQUET',
    Xls: 'XLS',
    Xlsx: 'XLSX',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type DownloadEntityDtoFileTypeEnum = typeof DownloadEntityDtoFileTypeEnum[keyof typeof DownloadEntityDtoFileTypeEnum];


