import React, { useMemo } from "react";

// MUI
import Link from "@material-ui/core/Link";

// Icons
import { Delete } from "src/icons/Delete";
import { PencilUnderlined } from "src/icons/PencilUnderlined";

// Utils
import { dateFormat } from "src/utils/dateFormat";

// Components
import { Table } from "src/components";
import { LinkCellWithMenu } from "src/components/Table/Cells/LinkCellWithMenu/LinkCellWithMenu.component";

type Props = {
  data: any;
  isReadOnly: boolean;
  onEdit: (segmentId?: string) => void;
  onDelete: (segmentId?: string) => void;
};

const SegmentsTable = (props: Props) => {
  const { data, isReadOnly, onEdit, onDelete } = props || {};

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        id: "Name",
        accessor: "name",
        isTooltip: false,
        Cell: ({ row, value }: any) =>
          isReadOnly ? (
            <Link
              onClick={() => onEdit(row?.original?.id)}
              data-testid={`segments${row?.original?.id}EditAction`}>
              {value}
            </Link>
          ) : (
            <LinkCellWithMenu
              linkText={value}
              onClick={() => onEdit(row?.original?.id)}
              data-testid={`segments${row?.original?.id}MoreActions`}
              menuButtons={[
                {
                  icon: <PencilUnderlined />,
                  label: "Edit",
                  action: () => onEdit(row?.original?.id)
                },
                {
                  icon: <Delete />,
                  label: "Delete",
                  action: () => onDelete(row?.original?.id)
                }
              ]}
            />
          )
      },
      {
        Header: "Description",
        id: "Description",
        accessor: "description"
      },
      {
        Header: "Last updated on",
        id: "Last updated on",
        accessor: "updated",
        Cell: ({ row, value }: any) => (
          <span data-testid={`segments${row?.original?.id}LastUpdatedDate`}>
            {dateFormat(value)}
          </span>
        )
      },
      {
        Header: "Rows",
        id: "Rows",
        accessor: "rowLimit"
      }
    ],
    [isReadOnly]
  );

  return (
    <Table
      size="small"
      data={data}
      columns={columns}
      emptyTableMessage="No records"
      orderByDefault="Last updated on"
    />
  );
};

export default SegmentsTable;
