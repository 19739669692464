/* eslint-disable no-unused-vars */
import { useMutation } from "@tanstack/react-query";

import {
  getErrorMessage,
  handleErrorWithRedirectToLogs,
  postAPIWithRethrow
} from "src/utils/apiService";
import { checkEnvRelaunch } from "src/utils/envRelaunchNotification";

export const useAutoGenerateCodeRecipeMutationV2 = () => {
  return useMutation({
    mutationFn: ({
      entityIds,
      userInput,
      outputType,
      recipeId,
      projectId,
      intermediateDatasets,
      sampleRows
    }: {
      projectId?: string;
      userInput?: string;
      entityIds: Array<string>;
      outputType: string;
      recipeId: string | undefined;
      intermediateDatasets?: { string: string };
      inputNames?: string[];
      shouldThrowError: boolean;
      sampleRows?: number | undefined;
    }) => {
      projectId && checkEnvRelaunch(projectId);
      return postAPIWithRethrow(
        `/v2/dfs-run-config-groups/generate-output`,
        {
          userInput,
          entityIds,
          outputType,
          groupId: recipeId,
          intermediateEntity: intermediateDatasets,
          sampleRows
        },
        {},
        false
      );
    },
    onError: (error, variables) => {
      const errorMsg = getErrorMessage(error);
      variables.shouldThrowError && handleErrorWithRedirectToLogs(errorMsg);
    },
    cacheTime: 0
  });
};
