import React from "react";

const ChartIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.49789 29.1668H31.556C34.9941 29.1668 36.8036 27.3572 36.8036 23.9191V2.02393H4.23218V23.9191C4.25028 27.3572 6.0598 29.1668 9.49789 29.1668Z"
        stroke={color}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.44019 2.02393H38.6306"
        stroke={color}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2971 38.2141L20.5352 34.5951V29.1666"
        stroke={color}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.774 38.2141L20.5359 34.595"
        stroke={color}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3926 18.3098L18.0926 13.5508C18.545 13.1708 19.1421 13.2793 19.4498 13.7859L21.6211 17.405C21.9288 17.9116 22.5259 18.0021 22.9783 17.6403L28.6783 12.8811"
        stroke={color}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChartIcon;
