import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const StackIcon: React.FC<IProps> = (props) => {
  const {
    width = 16,
    height = 16,
    viewBox = "0 0 16 16",
    fill = "none",
    color = "#7C7C7C"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16662_34991)">
        <path
          d="M14 14H2C1.73487 13.9997 1.48068 13.8943 1.2932 13.7068C1.10572 13.5193 1.00028 13.2651 1 13V10.5H2V13H14V10.5H15V13C14.9997 13.2651 14.8943 13.5193 14.7068 13.7068C14.5193 13.8943 14.2651 13.9997 14 14Z"
          fill={color}
        />
        <path d="M12.5 10.5H3.5V11.5H12.5V10.5Z" fill={color} />
        <path d="M12.5 8H3.5V9H12.5V8Z" fill={color} />
        <path d="M12.5 5.5H3.5V6.5H12.5V5.5Z" fill={color} />
        <path d="M12.5 3H3.5V4H12.5V3Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_16662_34991">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StackIcon;
