import React, { useCallback, useMemo } from "react";

import cloneDeep from "lodash/cloneDeep";

import { useForm } from "src/utils/useForm";
import { hasDuplicateItems, hasInvalidValueLength } from "src/utils/arrayUtils";
import { supportedOperatorValues } from "./ConditionBuilder";

export const getRulesFromExpression = (expression: $TSFixMe) => {
  let rules: $TSFixMe = [];
  const checkIfNested = (expressionGroup: $TSFixMe) => {
    const { groupElements = [] } = expressionGroup;
    rules.push({
      ...expressionGroup,
      groupElements: groupElements.filter((el: $TSFixMe) => el.type === "item")
    });
    if (expressionGroup.groupElements) {
      expressionGroup.groupElements?.forEach((item: $TSFixMe) => {
        if (item.type === "group") {
          checkIfNested(item);
        }
      });
    }
  };
  checkIfNested(expression);
  return rules;
};

const getInitialFormValues = (conditions: $TSFixMe) => {
  if (conditions) {
    const initialRules = getRulesFromExpression(conditions);
    const filteredRules = initialRules?.map((rule: $TSFixMe) => ({
      ...rule,
      groupElements: rule.groupElements.map((element: $TSFixMe) => ({
        ...element,
        operator: supportedOperatorValues.includes(element.operator) ? element.operator : null
      }))
    }));
    return {
      rules: filteredRules
    };
  } else {
    return {
      rules: []
    };
  }
};

type Props = {
  onSubmitSuccess: () => void;
  conditions: $TSFixMe;
  setConditions: $TSFixMeFunction;
};
export const useBuildRecipeConditions = ({ onSubmitSuccess, conditions, setConditions }: Props) => {
  const { values, setValues, setErrors, errors } = useForm(getInitialFormValues(conditions), true);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const rulesFormat = React.useMemo(() => {
    let expression: $TSFixMe;
    //@ts-expect-error
    const rules = cloneDeep(values.rules);
    rules.reverse()?.forEach((ruleGroup: $TSFixMe, index: $TSFixMe) => {
      if (index > 0) {
        const nestedGroup = expression;
        expression = ruleGroup;
        expression.groupElements.push(nestedGroup);
      } else {
        expression = ruleGroup;
      }
    });
    return expression;
  }, [(values as $TSFixMe).rules]);

  const onChangeRules = (rules: $TSFixMe) => {
    setErrors((previousErrors: $TSFixMe) => ({ ...previousErrors, rules: false }));
    const nonEmptyRules = rules?.filter((rule: $TSFixMe) => rule?.groupElements?.length !== 0);
    setValues({
      ...values,
      rules: nonEmptyRules
    });
  };

  const removeIds = useCallback((object: any): Record<string, any> => {
    if (Array.isArray(object)) {
      return object.map(removeIds);
    } else if (typeof object === "object" && object !== null) {
      const newObj: any = {};
      for (const key in object) {
        if (key !== "id") {
          newObj[key] = removeIds(object[key]);
        }
      }
      return newObj;
    }
    return object;
  }, []);

  const checkDuplicateConditions = (rules: any) => {
    const nestedConditions = rules?.groupElements?.filter?.(
      (element: any) => element.type === "group"
    );
    const hasNestedDuplicates = nestedConditions?.some((condition: any) =>
      checkDuplicateConditions(condition)
    );
    const hasDuplicates = hasDuplicateItems(rules?.groupElements);
    return hasDuplicates || hasNestedDuplicates;
  };

  const { hasInvalidValues, hasDuplicatedConditions } = useMemo(() => {
    const rulesWithoutIds = removeIds(rulesFormat);
    const hasDuplicatedConditions = checkDuplicateConditions(rulesWithoutIds);

    return {
      hasInvalidValues: hasInvalidValueLength(rulesWithoutIds?.groupElements),
      hasDuplicatedConditions
    };
  }, [rulesFormat]);

  const handleSubmit = async (e: $TSFixMe) => {
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitting(true);
    //@ts-expect-error
    const emptyValues = values?.rules?.reduce((acc: $TSFixMe, rule: $TSFixMe) => {
      return (
        acc ||
        rule?.groupElements.reduce(
          (acc: $TSFixMe, element: $TSFixMe) =>
            acc ||
            element?.value?.value?.trim() == "" ||
            element?.field?.isEmpty ||
            !element?.operator,
          false
        )
      );
    }, false);
    const currentErrors = {
      rules: emptyValues
    };

    setErrors((previousErrors: $TSFixMe) => ({
      ...previousErrors,
      ...currentErrors
    }));

    const hasErrors = Object.values({ ...errors, ...currentErrors }).some((error: $TSFixMe) =>
      Boolean(error)
    );

    if (hasErrors) {
      setIsSubmitting(false);
      return;
    }
    try {
      await setConditions(removeIds(rulesFormat));
      onSubmitSuccess();
      setIsSubmitting(false);
    } catch (error) {
      console.error({ error });
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    rules: (values as $TSFixMe).rules,
    errors: (errors as $TSFixMe)["rules"],
    onChangeRules,
    handleSubmit,
    rulesFormat: removeIds(rulesFormat),
    hasDuplicatedConditions,
    hasInvalidValues
  };
};
