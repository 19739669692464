import { useMutation, useQueryClient } from "@tanstack/react-query";

import { DataAppType } from "src/pages/DataApps/DataApps.type";
import { putAPIWithRethrow } from "src/utils/apiService";

type Props = { dataAppId: string; dataApp: DataAppType };

const useSaveDataAppMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dataAppId, dataApp }: Props) => {
      return await putAPIWithRethrow(`/dataapps/${dataAppId}`, dataApp);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["dataApps"] });
    }
  });
};

export default useSaveDataAppMutation;
