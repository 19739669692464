import React from "react";

// Packages
import { generatePath } from "react-router-dom";

// MUI
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

// Icons
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// Utils
import { RenderText } from "src/utils";
import { getDocsUrl } from "src/helpers/helpers";
import { WebPaths } from "src/routing/routes";

// Hooks
import { useGetReleaseNotesByName } from "src/hooks/api/releaseNotes";

// Components
import SubTopNavBarWrapper from "src/layout/NavBars/components/SubTopNavBar/SubTopNavBarWrapper";
import ReleaseNotesContent from "./ReleaseNotesContent";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "40%",
    flexShrink: 0,
    "& .MuiAppBar-root": {
      width: "40%",
      backgroundColor: "white",
      boxShadow: "0px 1px 2px 0px #00000040"
    }
  },
  drawerPaper: {
    width: "40%",
    backgroundColor: "#f5f5f5"
  },
  container: {
    height: "calc(100vh - 44px)",
    marginTop: 44,
    padding: theme.spacing(2)
  }
}));

type Props = {
  onClose: () => void;
};

const ReleaseNotesDrawer = (props: Props) => {
  const { onClose } = props || {};

  const classes = useStyles();

  // Query hooks - STARTS >>
  // Queries
  const { isLoading, data } = useGetReleaseNotesByName();
  // << ENDS - Query hooks

  const openReleaseNotesInNewTab = () => {
    window.open(generatePath(WebPaths.ReleaseNotes, { name: "" }), "_blank");
  };

  const previousReleaseNotes = () => {
    window.open(`${getDocsUrl()}/Releasenotesindex.html`, "_blank");
  };

  return (
    <Drawer
      open
      anchor="right"
      variant="temporary"
      onClose={onClose}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}>
      <SubTopNavBarWrapper
        variant="drawer"
        onDrawerClose={onClose}
        subTopNavBarLeftSection={{
          component: (
            <Grid container alignItems="center">
              <RenderText color="textSecondary" isOverflowTooltip>
                {`Latest Release Notes${!!data?.description ? ` (${data?.description})` : ""}`}
              </RenderText>
              <Tooltip title="View in New Tab">
                <span>
                  <IconButton size="small" color="primary" onClick={openReleaseNotesInNewTab}>
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )
        }}
        subTopNavBarRightSection={{
          component: (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={previousReleaseNotes}
              style={{ textWrap: "nowrap" }}>
              Previous Release Notes
            </Button>
          )
        }}
      />

      <Grid container direction="column" wrap="nowrap" className={classes.container}>
        <ReleaseNotesContent isLoading={isLoading} data={data} />
      </Grid>
    </Drawer>
  );
};

export default ReleaseNotesDrawer;
