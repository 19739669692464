import React, { useMemo } from "react";

// Packages
import { useParams } from "react-router-dom";
import { FormProvider } from "react-hook-form";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

// Components
import { Spinner } from "src/components";
import Header from "./components/Header";
import SegmentDetails from "./components/SegmentDetails/SegmentDetails";
import SegmentConditions from "./components/SegmentConditions/SegmentConditions";

// Contexts
import SegmentContextProvider from "./context/SegmentContextProvider";
import { useSegmentContext } from "./context/useSegmentContext";

const Segment = () => {
  const { segmentId } = useParams() || {};

  const { isDatasetLoading, isDatasetFeaturesLoading, isSegmentLoading, formMethods } =
    useSegmentContext();

  const isLoading = useMemo(
    () => isDatasetLoading || isDatasetFeaturesLoading || (!!segmentId && isSegmentLoading),
    [isDatasetLoading, isDatasetFeaturesLoading, segmentId, isSegmentLoading]
  );

  return isLoading ? (
    <Spinner />
  ) : (
    <FormProvider {...formMethods}>
      <Header />

      <Box p={2}>
        <Grid container direction="column" style={{ rowGap: 16 }}>
          <SegmentDetails />
          <SegmentConditions />
        </Grid>
      </Box>
    </FormProvider>
  );
};

const SegmentWrapper = () => (
  <SegmentContextProvider>
    <Segment />
  </SegmentContextProvider>
);

export default SegmentWrapper;
