import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import React from "react";
import { Link } from "react-router-dom";
import { isFunction } from "lodash";

import EditName from "src/layout/NavBars/components/SubTopNavBar/EditName";
import { RenderText } from "src/utils";

interface IProps {
  project: $TSFixMe;
  predictionJobName?: string;
  onUpdate?: (name: string) => void;
}

const FONT_SIZE = 14;

const SubTopNavBarBreadcrumbs: React.FC<IProps> = (props) => {
  const { project, predictionJobName, onUpdate } = props;

  const breadcrumbs: React.ReactNode[] = [];

  breadcrumbs.push(
    !!project?.id && (
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    )
  );

  breadcrumbs.push(
    <Link key="prediction-name" to={`/projects/${project?.id}/prediction-job`}>
      <RenderText>Prediction</RenderText>
    </Link>
  );

  if (isFunction(onUpdate) && predictionJobName) {
    breadcrumbs.push(
      <EditName
        fieldNameLabel="prediction-job name"
        key="edit-name"
        inputValue={predictionJobName}
        onSave={onUpdate}
        containerProps={{
          style: {
            width: ((predictionJobName || "")?.length + 1) * (FONT_SIZE - 4) + 16
          }
        }}
      />
    );
  }

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
