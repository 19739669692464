import { Grid, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";

import { Table } from "src/components";

const useStyles = makeStyles({
  chartName: {
    margin: "4px 12px 0px 12px",
    color: "#133553",
    borderBottom: "1px solid #D1D1D1"
  },
  tableWrap: {
    width: "inherit",
    maxWidth: "100%",
    gap: "4px"
  },
  table: {
    width: "inherit",
    maxWidth: "100%",
    padding: "8px"
  }
});
export const TableTypeChart = ({ data }: { data: any }) => {
  const classes = useStyles();
  const columns = useMemo(
    () =>
      data[0]
        ? Object.keys(data[0]).map((key) => ({
            Header: key,
            accessor: key,
            id: key
          }))
        : [],
    [data]
  );

  return (
    <Grid container direction="column" className={classes.tableWrap}>
      <Grid item className={classes.table}>
        <Table
          data={data}
          columns={columns}
          isCellSortEnabled
          size="small"
          maxHeight="100%"
          inheritHeight
        />
      </Grid>
    </Grid>
  );
};
