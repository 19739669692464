import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";

const useStyles = makeStyles(() => ({
  submitButton: {
    width: "125px"
  },
  deleteNote: {
    fontStyle: "italic",
    color: "#000000",
    opacity: 0.5,
    fontSize: "14px"
  }
}));

type Props = {
  entityLabel: string;
  entityName: string;
  fileName: string;
  onConfirm: () => void;
  onClose: () => void;
  isDeleteInProgress: boolean;
};

export const DeleteEntityFileConfirmationModal = ({
  entityLabel,
  entityName,
  fileName,
  onConfirm,
  onClose,
  isDeleteInProgress
}: Props) => {
  const classes = useStyles();
  return (
    <Modal
      open={true}
      variant={ModalVariants.Delete}
      title="Delete File"
      isSubmitting={isDeleteInProgress}
      onClose={onClose}
      onSubmit={onConfirm}
      content={[
        <>
          File <b>&ldquo;{fileName}&rdquo;</b> is currently used by the {entityLabel}{" "}
          <b>&ldquo;{entityName}</b>
          &rdquo;
        </>,
        "Are you sure you want to remove?",
        <>
          <Grid className={classes.deleteNote}>
            Note: Deleting this might impact associated dataapps(if any).
          </Grid>
        </>
      ]}
    />
  );
};
