import { ReactNode } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getProjectCanvas } from "src/api/projects";

export const enum UseGetProjectCanvasQueryKeys {
  ProjectCanvas = "query-key-project-canvas"
}

type ProjectJobProps = {
  scenarioId?: string;
  jobId?: string;
  jobRunId?: string;
  renderContent?: ReactNode;
  skipJobCanvas?: boolean;
};
interface ProjectProps extends UseQueryOptions<$TSFixMe> {
  projectId?: string;
  scenarioId?: string;
  jobProps?: ProjectJobProps;

  // useQuery options
  cacheTime?: number;
}

export const useGetProjectCanvas = (props: ProjectProps): UseQueryResult<$TSFixMe> => {
  const {
    projectId,
    scenarioId,
    jobProps,

    // useQuery options
    cacheTime = 0,
    enabled,
    ...options
  } = props;

  return useQuery({
    queryKey: [
      UseGetProjectCanvasQueryKeys.ProjectCanvas,
      projectId,
      scenarioId || jobProps?.scenarioId
    ],
    // queryKey: ["projectCanvas", projectId, scenarioId],
    queryFn: () => {
      return getProjectCanvas({
        projectId,
        scenarioId: scenarioId || jobProps?.scenarioId,
        ...(!!jobProps?.jobId ? { jobId: jobProps?.jobId } : {}),
        ...(!!jobProps?.jobRunId ? { jobRunId: jobProps?.jobRunId } : {})
      });
    },
    select: (data: $TSFixMe) => (typeof data === "object" ? data : {}),
    cacheTime,
    enabled: enabled ?? (!!projectId && (!!scenarioId || !!jobProps?.scenarioId)),
    ...options
  });
};
