import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { getEntityData } from "src/api";

export const enum UseGetDatasetDataQueryKeys {
  DatasetData = "dataset-data"
}
interface DatasetDataProps extends UseQueryOptions<$TSFixMe> {
  entityId: string | null;
  scenarioId?: string;
  jobRunId?: string;
  limit?: number;
}

export const useGetEntityData = (props: DatasetDataProps): UseQueryResult<$TSFixMe> => {
  const {
    entityId,
    scenarioId,
    jobRunId,
    limit,

    // useQuery options
    cacheTime = 0,
    enabled,
    ...options
  } = props;

  return useQuery({
    queryKey: [UseGetDatasetDataQueryKeys.DatasetData, entityId],
    queryFn: () => {
      return getEntityData(entityId, scenarioId, jobRunId, limit);
    },
    cacheTime,
    enabled: enabled ?? !!entityId,
    ...options
  });
};
