import React from "react";

export const TenantIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor",
  opacity = 1
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <path
          d="M15.9996 8.87218C15.9964 8.71808 15.9079 8.53698 15.7278 8.45648L12.2362 6.84559V3.02693C12.2357 2.86752 12.1455 2.68133 11.9608 2.60017L8.19176 0.895145C8.07101 0.840532 7.93287 0.839627 7.81137 0.892585L4.04754 2.53494C3.89853 2.60186 3.76501 2.75792 3.76648 2.96423V6.842L0.278303 8.39103C0.131794 8.45776 -0.00200685 8.61429 2.27976e-05 8.8191V13.0002C2.27976e-05 13.1853 0.108968 13.353 0.278084 13.4282L4.04192 15.1019C4.1182 15.1127 4.14649 15.1925 4.42234 15.102L7.99988 13.5133L11.5723 15.1019C11.6486 15.1127 11.6768 15.1925 11.9527 15.102L15.7217 13.4283C15.891 13.3531 16 13.1854 16 13.0002V8.88177C16 8.87855 15.9997 8.87539 15.9996 8.87218ZM4.23602 7.65845L6.89157 8.83936L4.2323 10.1406L1.57578 8.8398L4.23602 7.65845ZM7.53032 5.09938V8.09801L4.70324 6.83994V3.7151L7.53032 5.09938ZM11.2994 6.84085L9.71961 7.54239L8.46708 8.0986V5.10397L11.2994 3.76616V6.84085ZM4.70062 10.9543L7.53032 9.56972V12.6969L4.70062 13.9535V10.9543ZM8.46754 9.57016L11.2942 10.9542V13.9531L8.46951 12.6969L8.46754 9.57016ZM11.7662 10.1423L9.1061 8.8398L11.7641 7.65945L14.4244 8.88679L11.7662 10.1423ZM7.99529 1.8344L10.6517 3.03611L8.00235 4.28746L5.34988 2.98868L7.99529 1.8344ZM0.936782 9.56994L3.76386 10.9542V13.9531L0.936782 12.6959V9.56994ZM12.2309 13.9535V10.9588L15.0632 9.62099V12.6957L12.2309 13.9535Z"
          fill={color}
        />
      </g>
    </svg>
  </>
);
