import React, { useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { Grid, Button, Typography, makeStyles } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { CloudUploadIcon } from "src/icons/Dataset/CloudUploadIcon";

import { toastWrapper } from "src/utils/toastWrapper";

import {
  DatasetSessionConfig,
  Criteria,
  DatasetHelperText,
  DatasetConfig,
  FileUploadStatuses,
  OntologyDatasetStatuses,
  separators,
  encodings
} from "../../utils/Dataset.constants";

import useStoreSelectors from "../../hooks/useStoreSelectors";
import useDatasetsSession from "../../hooks/useDatasetsSession";
import useFilesObserver from "../../hooks/useFilesObserver";

import FileInputField from "./FileInputField";

import { invalidFilesUploadingMessage } from "../../utils/Dataset.utils";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginBottom: 0,
    border: "1px dashed #7c7c7c",
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(2),
    "& [class^='MuiTypography-root']": {
      fontWeight: "lighter"
    },
    "& .dropFieldContainer": {
      margin: "auto",
      width: "auto",
      rowGap: 10,
      "& svg": {
        margin: "0 auto"
      }
    },
    "& .dropInfoContainer": {
      columnGap: theme.spacing(2),
      fontStyle: "italic",
      "& svg": {
        opacity: 0.5
      }
    }
  },
  textAlign: {
    textAlign: "center"
  }
}));

const LocalFilesSelection = () => {
  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const {
    datasetDefaultDatasetStore,

    setDatasetIsSelectionOverlayOpenStore,

    datasetCriterionStore,
    setDatasetCriterionStore,
    setShouldResetDataset,

    datasetFilesStore,
    setDatasetFilesStore,

    datasetExistingDatasetsStore
  } = useStoreSelectors();

  const { isFilesUploading } = useFilesObserver();

  // << ENDS - Stores

  const [datasetsTimer, setDatasetsTimer] = useState<$TSFixMe>(new Date().getTime());
  const { setDatasetDatasetsSession, getDatasetDatasetsSession } = useDatasetsSession({
    timer: datasetsTimer
  });

  const getFileNameWithoutExtension = (fileNameWithExtension: $TSFixMe) => {
    if (!fileNameWithExtension) {
      return DatasetHelperText.Unknown;
    }

    const fileName = fileNameWithExtension?.split(".")?.slice(0, -1)?.join(".");
    const suffix = `_${uuidv4()?.substring(0, 5)}`;
    const updatedFileName = `${fileName}${suffix}`;

    if (updatedFileName?.length > DatasetConfig.acceptedDatasetNameLength) {
      return `${fileName.slice(
        0,
        DatasetConfig.acceptedDatasetNameLength - suffix?.length
      )}${suffix}`;
    }

    return fileName;
  };

  const onFilesUpload = (event: $TSFixMe) => {
    const files = event?.target?.files || event?.dataTransfer?.files;
    const message = invalidFilesUploadingMessage(files || [], datasetCriterionStore?.value);

    if (message === "Multiple files are not allowed!") {
      setShouldResetDataset(false);
      const thisDatasetCriterionStore = datasetCriterionStore;
      thisDatasetCriterionStore.value = Criteria.Segregate;
      setDatasetCriterionStore({ ...thisDatasetCriterionStore });
    }

    if (!!message && !(message === "Multiple files are not allowed!")) {
      toastWrapper({
        type: "error",
        content: message
      });

      return;
    }

    // Files >>
    const uploadingFiles =
      (Object.values(files) || [])?.map((eachFile: $TSFixMe) => ({
        name: eachFile?.name,
        type: eachFile?.type,
        size: eachFile?.size,
        file: eachFile,
        status: FileUploadStatuses.Stage,
        entityId: "",
        uploadProgress: 0
      })) || [];

    const thisDatasetFilesStore = [...datasetFilesStore, ...uploadingFiles];
    setDatasetFilesStore(thisDatasetFilesStore);

    const thisDatasetFilesSession =
      (thisDatasetFilesStore || [])?.map((eachFile: $TSFixMe) => ({
        name: eachFile?.name,
        status: eachFile?.status,
        entityId: eachFile?.entityId,
        uploadProgress: eachFile?.uploadProgress
      })) || [];

    sessionStorage.setItem(
      DatasetSessionConfig.UploadingFilesSessionKey,
      JSON.stringify(thisDatasetFilesSession)
    );
    // << Files

    // Datasets >>
    if (!datasetDefaultDatasetStore?.id) {
      const existingDatasetNames =
        (datasetExistingDatasetsStore || [])?.map((eachDataset: $TSFixMe) => eachDataset?.name) ||
        [];

      const thisDatasets = (uploadingFiles || [])?.map((eachFile: $TSFixMe, index: number) => {
        const fileNameWithoutExtension = getFileNameWithoutExtension(eachFile?.name);

        return {
          name: fileNameWithoutExtension,
          isValid: true,
          isEditing: false,
          isExisting: existingDatasetNames.includes(fileNameWithoutExtension),
          ontologyConfig: {
            status: index === 0 ? OntologyDatasetStatuses.Active : OntologyDatasetStatuses.Stage,
            separator: separators[0].value,
            encoding: encodings[0].value
          }
        };
      });

      if (datasetCriterionStore?.value === Criteria.Segregate) {
        const existingDatasetSessions = getDatasetDatasetsSession();
        setDatasetDatasetsSession([...existingDatasetSessions, ...thisDatasets]);
      } else {
        setDatasetDatasetsSession([...[thisDatasets[0]]]);
      }

      setDatasetsTimer(() => new Date().getTime());
    }
    // << Datasets

    setDatasetIsSelectionOverlayOpenStore(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    onFilesUpload(e);
  };

  const allowDrop = (e: any) => {
    e.preventDefault();
  };

  return (
    <Grid container style={{ height: "100%" }} onDrop={handleDrop} onDragOver={allowDrop}>
      <FileInputField disabled={isFilesUploading} onChange={onFilesUpload}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.root}
          component="label"
          style={{ opacity: isFilesUploading ? ".5" : "1" }}
          htmlFor="fileInput">
          <Grid container direction="column" className="dropFieldContainer">
            <CloudUploadIcon />
            <Typography id="selectionPlaceholderDragDropTitle" variant="h5" gutterBottom>
              {DatasetHelperText.SelectionPlaceholderDragDropTitle}
            </Typography>
            <Typography
              id="selectionPlaceholderOr"
              variant="h5"
              gutterBottom
              className={classes.textAlign}>
              {DatasetHelperText.Or}
            </Typography>
            <Typography color="textPrimary" className={classes.textAlign}>
              <Button
                id="uploadFilesFromLocal"
                variant="outlined"
                disabled={isFilesUploading}
                color="inherit"
                component="span"
                style={{ borderRadius: 20 }}>
                {DatasetHelperText.UploadFilesFromLocal}
              </Button>
            </Typography>
          </Grid>
          <Grid container alignItems="center" justifyContent="center" className="dropInfoContainer">
            <InfoOutlinedIcon fontSize="small" />
            <Grid item>
              <Typography
                id="dropInfoContentLine1"
                display="block"
                variant="caption"
                color="textSecondary"
                gutterBottom>
                {DatasetHelperText.UploadedDataColumnInfoLine1}
              </Typography>
              <Typography
                id="dropInfoContentLine2"
                display="block"
                variant="caption"
                color="textSecondary"
                gutterBottom>
                {DatasetHelperText.UploadedDataColumnInfoLine2}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </FileInputField>
    </Grid>
  );
};

export default LocalFilesSelection;
