import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { PropsWithChildren } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  makeStyles,
  withStyles
} from "@material-ui/core";

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #cccccc",
    minHeight: 46,
    height: 46,
    paddingLeft: "0px",

    "&$expanded": {
      minHeight: 46
    }
  },
  content: {
    alignItems: "center",

    "&$expanded": {
      margin: "0px",
      alignItems: "center"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const useStyles = makeStyles({
  accordian: {
    border: "1px solid #cccccc",
    boxShadow: "none",
    borderRadius: "4px",
    margin: "0px !important"
  }
});

interface IProps {
  label: string;
  title: React.JSX.Element;
}

const AccordianCard: React.FC<PropsWithChildren<IProps>> = (props) => {
  const { children, label, title } = props;
  const { accordian } = useStyles();

  return (
    <Accordion className={accordian} defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${label}-content`}
        aria-label="Expand"
        id={`panel-${label}-header`}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordianCard;
