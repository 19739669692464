import React from "react";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";

import { useCodeRecipeContext } from "../../../CodeRecipeContext/useCodeRecipeContext";
import { QueryInputIcon } from "src/icons/NewUX/QueryInputIcon";
import { isEmpty } from "lodash";

const useStyles = makeStyles({
  queryBtn: {
    borderRadius: "4px",
    background: "#fff",
    padding: "6px"
  }
});

interface IProps {
  name: string;
}

const AutoSelectDataset: React.FC<IProps> = (props) => {
  const { name } = props;

  const classes = useStyles();
  const { pinnedNames, setInputNames } = useCodeRecipeContext();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (isEmpty(pinnedNames)) {
      setInputNames([name]);
    }
  };

  return (
    <Tooltip title="Auto-select this dataset as input to run queries.">
      <div>
        <IconButton
          className={classes.queryBtn}
          test-id="ask-ai-output-query"
          onClick={handleClick}>
          <QueryInputIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default AutoSelectDataset;
