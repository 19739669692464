import React from "react";
import { ListChildComponentProps } from "react-window";
import { MenuItem } from "@material-ui/core";

import OverflowTooltip from "src/components/OverflowTooltip";

export interface IColumnRowProps {
  columns: string[];
  highlightedIndex: number | null;
  menuItemRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
  onSuggestionClick: (val: string) => void;
}

const ColumnRow: React.FC<ListChildComponentProps<IColumnRowProps>> = (props) => {
  const { index, style, data } = props;
  const { columns, highlightedIndex, menuItemRefs, onSuggestionClick } = data;

  const item = columns[index];

  return (
    <MenuItem
      style={style}
      key={`suggestion-${index}`}
      selected={index === highlightedIndex}
      onClick={() => onSuggestionClick(item)}
      ref={(el) => (menuItemRefs.current[index] = el)}
      data-testid="columnSuggestion">
      <OverflowTooltip
        value={item}
        title={item}
        tooltipProps={{
          placement: "right",
          PopperProps: {
            style: { zIndex: 100005 }
          }
        }}
      />
    </MenuItem>
  );
};

export default ColumnRow;
