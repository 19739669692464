import React from "react";

const NoAboutContentIcon = () => {
  return (
    <svg
      width="100"
      height="128"
      viewBox="0 0 100 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M90.5372 43.4333C89.5015 43.4333 88.6622 44.2728 88.6622 45.3083V123.4C88.6622 123.869 88.281 124.25 87.8125 124.25H14.5225C14.054 124.25 13.6727 123.869 13.6727 123.4V102.389C13.6727 101.353 12.8332 100.514 11.7977 100.514C10.7622 100.514 9.92273 101.353 9.92273 102.389V123.4C9.92273 125.937 11.9862 128 14.5225 128H87.8125C90.349 128 92.4122 125.937 92.4122 123.4V45.3083C92.4122 44.2725 91.573 43.4333 90.5372 43.4333Z"
        fill="#7C7C7C"
      />
      <path
        d="M87.8125 16.7405H74.2045C74.2657 16.3085 74.2992 15.868 74.2992 15.4192C74.2992 10.2442 70.089 6.03425 64.9142 6.03425C59.7395 6.03425 55.5295 10.2442 55.5295 15.4192C55.5295 15.8677 55.5632 16.3085 55.6242 16.7405H35.1712C34.6775 16.7442 34.195 16.9405 33.8455 17.2897L10.4717 40.6635C10.1237 41.0133 9.92398 41.496 9.92273 41.9893V93.132C9.92273 94.1675 10.7622 95.007 11.7977 95.007C12.8332 95.007 13.6727 94.1675 13.6727 93.132V43.8643H32.4465C34.983 43.8643 37.0462 41.8008 37.0462 39.2645V20.4905H57.0237C58.6957 23.083 61.6067 24.8042 64.9142 24.8042C68.2217 24.8042 71.133 23.0827 72.805 20.4905H87.8125C88.281 20.4905 88.6622 20.8717 88.6622 21.3402V36.0515C88.6622 37.087 89.5015 37.9265 90.5372 37.9265C91.573 37.9265 92.4122 37.087 92.4122 36.0515V21.3402C92.4122 18.804 90.3487 16.7405 87.8125 16.7405ZM32.4465 40.1143H16.3245L33.2965 23.1422V39.2645C33.2962 39.733 32.9152 40.1143 32.4465 40.1143ZM64.9142 21.0542C61.8072 21.0542 59.2795 18.5265 59.2795 15.4192C59.2795 12.312 61.8072 9.78425 64.9142 9.78425C68.0215 9.78425 70.5492 12.312 70.5492 15.4192C70.5492 18.5265 68.0215 21.0542 64.9142 21.0542Z"
        fill="#7C7C7C"
      />
      <path
        d="M44.9786 89.0745C45.6461 89.8662 46.8291 89.967 47.6206 89.299C48.4624 88.589 49.6579 88.1827 50.9031 88.1827H50.9204C52.1719 88.1865 53.3724 88.6005 54.2136 89.3187C54.5669 89.6202 54.9996 89.7675 55.4299 89.7675C55.9596 89.7675 56.4861 89.5442 56.8569 89.1097C57.5291 88.3222 57.4356 87.1387 56.6481 86.4662C55.1194 85.1612 53.0891 84.439 50.9316 84.4325C50.9219 84.4325 50.9121 84.4325 50.9021 84.4325C48.7559 84.4325 46.7329 85.142 45.2031 86.432C44.4116 87.1 44.3111 88.2827 44.9786 89.0745Z"
        fill="#7C7C7C"
      />
      <path
        d="M61.1804 81.2345C61.2199 81.2387 61.2602 81.2395 61.3004 81.2412C61.3239 81.2422 61.3472 81.245 61.3709 81.2452H61.3814C62.4122 81.2452 63.2507 80.4125 63.2562 79.3805C63.2612 78.4705 62.6169 77.7087 61.7577 77.5335C61.7514 77.5322 61.7449 77.5317 61.7384 77.5305C61.6844 77.52 61.6297 77.511 61.5742 77.5055C61.5517 77.5032 61.5287 77.5032 61.5059 77.5017C61.4679 77.4992 61.4299 77.4955 61.3914 77.4952H61.3692C61.3657 77.4952 61.3624 77.4952 61.3587 77.4952C61.2942 77.4952 61.2304 77.4985 61.1674 77.505C60.2219 77.6 59.4927 78.3987 59.4927 79.3705C59.4927 80.3315 60.2254 81.1225 61.1584 81.2315C61.1659 81.232 61.1732 81.2337 61.1804 81.2345Z"
        fill="#7C7C7C"
      />
      <path
        d="M40.9545 77.4952C39.919 77.4952 39.0902 78.3347 39.0902 79.3702C39.0902 80.4057 39.9407 81.2452 40.9762 81.2452C42.0117 81.2452 42.8512 80.4057 42.8512 79.3702C42.8512 78.3347 42.0117 77.4952 40.9762 77.4952H40.9545Z"
        fill="#7C7C7C"
      />
      <path
        d="M33.0675 66.803C29.6997 70.941 27.8452 76.1635 27.8452 81.508C27.8452 94.368 38.3077 104.83 51.168 104.83C64.0282 104.83 74.4905 94.368 74.4905 81.508C74.4905 76.1623 72.6352 70.9393 69.2665 66.8008C65.9472 62.723 61.3055 59.8585 56.1967 58.735C55.1845 58.5118 54.1852 59.1523 53.9625 60.1635C53.74 61.1748 54.3797 62.175 55.391 62.3975C59.6765 63.34 63.5715 65.7445 66.3582 69.1683C69.1842 72.6398 70.7405 77.0223 70.7405 81.508C70.7405 92.3003 61.9602 101.08 51.168 101.08C40.3757 101.08 31.5952 92.3003 31.5952 81.508C31.5952 77.023 33.151 72.6413 35.976 69.17C38.762 65.7467 42.6557 63.3418 46.94 62.3985C47.9512 62.1758 48.5907 61.1755 48.368 60.164C48.1452 59.1528 47.145 58.514 46.1335 58.736C41.026 59.861 36.3857 62.7258 33.0675 66.803Z"
        fill="#7C7C7C"
      />
      <path
        d="M51.1602 51.8787C52.1929 51.8787 53.0317 51.043 53.0349 50.0092L53.0532 43.9302C53.0564 42.8947 52.2194 42.0527 51.1837 42.0497C51.1817 42.0497 51.1799 42.0497 51.1779 42.0497C50.1452 42.0497 49.3064 42.8855 49.3032 43.9192L49.2849 49.9982C49.2819 51.0337 50.1187 51.8757 51.1544 51.8787H51.1602Z"
        fill="#7C7C7C"
      />
      <path
        d="M42.096 54.4697C42.4237 54.4697 42.756 54.3837 43.0575 54.203C43.946 53.671 44.2347 52.5195 43.7027 51.631L40.5442 46.3577C40.0122 45.4692 38.8607 45.1802 37.9722 45.7125C37.0837 46.2445 36.795 47.396 37.327 48.2845L40.4855 53.5577C40.837 54.1445 41.4585 54.4697 42.096 54.4697Z"
        fill="#7C7C7C"
      />
      <path
        d="M60.2082 54.5528C60.8417 54.5528 61.46 54.2315 61.8127 53.6505L65.003 48.3948C65.5402 47.5095 65.2582 46.3563 64.373 45.819C63.4875 45.282 62.3347 45.5638 61.7972 46.449L58.607 51.7048C58.0697 52.59 58.3517 53.7433 59.237 54.2805C59.5412 54.465 59.8767 54.5528 60.2082 54.5528Z"
        fill="#7C7C7C"
      />
      <path
        d="M14.391 17.1705C14.757 17.5367 15.237 17.7197 15.7167 17.7197C16.1965 17.7197 16.6765 17.5367 17.0425 17.1705L18.621 15.592L20.1995 17.1705C20.5655 17.5365 21.0455 17.7197 21.5252 17.7197C22.005 17.7197 22.485 17.5367 22.851 17.1705C23.5832 16.4382 23.5832 15.251 22.851 14.519L21.2725 12.9405L22.851 11.362C23.5832 10.6297 23.5832 9.4425 22.851 8.7105C22.1187 7.97825 20.9315 7.97825 20.1995 8.7105L18.621 10.289L17.0425 8.7105C16.3102 7.97825 15.123 7.97825 14.391 8.7105C13.6587 9.44275 13.6587 10.63 14.391 11.362L15.9695 12.9405L14.391 14.519C13.6587 15.251 13.6587 16.4382 14.391 17.1705Z"
        fill="#7C7C7C"
      />
      <path
        d="M45.7384 8.26275C46.1044 8.629 46.5844 8.812 47.0642 8.812C47.5439 8.812 48.0239 8.629 48.3899 8.26275L49.5952 7.05775L50.8002 8.26275C51.1664 8.62875 51.6464 8.812 52.1262 8.812C52.6059 8.812 53.0859 8.629 53.4522 8.26275C54.1844 7.5305 54.1844 6.34325 53.4522 5.61125L52.2469 4.406L53.4522 3.20075C54.1844 2.4685 54.1844 1.28125 53.4522 0.549249C52.7197 -0.183001 51.5327 -0.183001 50.8004 0.549249L49.5954 1.75425L48.3902 0.549249C47.6579 -0.183001 46.4707 -0.183001 45.7387 0.549249C45.0064 1.2815 45.0064 2.46875 45.7387 3.20075L46.9439 4.406L45.7387 5.61125C45.0059 6.34325 45.0059 7.5305 45.7384 8.26275Z"
        fill="#7C7C7C"
      />
      <path
        d="M15.0394 27.8753C15.0394 23.8645 11.7764 20.6015 7.76569 20.6015C3.75494 20.6015 0.491943 23.8645 0.491943 27.8753C0.491943 31.886 3.75494 35.149 7.76569 35.149C11.7764 35.149 15.0394 31.886 15.0394 27.8753ZM7.76569 31.399C5.82269 31.399 4.24194 29.8183 4.24194 27.8753C4.24194 25.9323 5.82269 24.3515 7.76569 24.3515C9.70869 24.3515 11.2894 25.9323 11.2894 27.8753C11.2894 29.8183 9.70869 31.399 7.76569 31.399Z"
        fill="#7C7C7C"
      />
      <path
        d="M97.7537 7.90925L98.959 6.704C99.6912 5.97175 99.6912 4.7845 98.959 4.0525C98.2265 3.32025 97.0395 3.32025 96.3072 4.0525L95.1022 5.2575L93.8972 4.0525C93.1647 3.32025 91.9777 3.32025 91.2455 4.0525C90.5132 4.78475 90.5132 5.972 91.2455 6.704L92.4507 7.90925L91.2455 9.1145C90.5132 9.84675 90.5132 11.034 91.2455 11.766C91.6117 12.132 92.0917 12.3152 92.5715 12.3152C93.0512 12.3152 93.5312 12.1322 93.8975 11.766L95.1025 10.561L96.3075 11.766C96.6737 12.132 97.1537 12.3152 97.6335 12.3152C98.1132 12.3152 98.5932 12.1322 98.9595 11.766C99.6917 11.0337 99.6917 9.8465 98.9595 9.1145L97.7537 7.90925Z"
        fill="#7C7C7C"
      />
    </svg>
  );
};

export default NoAboutContentIcon;
