import React from "react";
import _ from "lodash";
import { Grid, Typography } from "@material-ui/core";

import { CONNECTOR_KEYS } from "../../../OutputDataset/utils/OutputDataset.constants";
import { DataConnectorNames } from "pages/DataSources/utils/DataSources.constants";
import { IDestination } from "../JobDestinations/JobDestinations";

interface IProps {
  destination: IDestination;
}

const ReadOnlyConnectorDetails: React.FC<IProps> = (props) => {
  const { destination } = props;

  const getChild = () => {
    switch (destination.dataSourceType) {
      case DataConnectorNames.AZURE_BLOB:
      case DataConnectorNames.GCP_STORAGE:
      case DataConnectorNames.S3_STORAGE:
        return (
          <>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">Destination</Typography>
              <Typography variant="body2">{destination?.dataSourceName}</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.fileCategory.destinationFolder.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.fileCategory.destinationFolder.key)}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.fileCategory.destinationFileName.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.fileCategory.destinationFileName.key)}
              </Typography>
            </Grid>
          </>
        );

      case DataConnectorNames.MYSQL:
      case DataConnectorNames.REDSHIFT:
        return (
          <>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">Destination</Typography>
              <Typography variant="body2">{destination?.dataSourceName}</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.sqlRedshiftCategory.tableName.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.sqlRedshiftCategory.tableName.key)}
              </Typography>
            </Grid>
          </>
        );

      case DataConnectorNames.MONGO:
        return (
          <>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">Destination</Typography>
              <Typography variant="body2">{destination?.dataSourceName}</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.mongoCateogry.collection.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.mongoCateogry.collection.key)}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.mongoCateogry.database.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.mongoCateogry.database.key)}
              </Typography>
            </Grid>
          </>
        );

      case DataConnectorNames.SNOWFLAKE:
        return (
          <>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">Destination</Typography>
              <Typography variant="body2">{destination?.dataSourceName}</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.snowFlakeCategory.databaseName.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.snowFlakeCategory.databaseName.key)}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.snowFlakeCategory.schema.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.snowFlakeCategory.schema.key)}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.snowFlakeCategory.tableName.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.snowFlakeCategory.tableName.key)}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.snowFlakeCategory.warehouse.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.snowFlakeCategory.warehouse.key)}
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 }}>
              <Typography className="destinationTitleReadOnlyMode">
                {CONNECTOR_KEYS.snowFlakeCategory.role.label}
              </Typography>
              <Typography variant="body2">
                {_.get(destination?.options, CONNECTOR_KEYS.snowFlakeCategory.role.key)}
              </Typography>
            </Grid>
          </>
        );

      default:
        return null;
    }
  };
  return (
    <Grid container direction="column">
      {getChild()}
    </Grid>
  );
};

export default ReadOnlyConnectorDetails;
