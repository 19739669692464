import React from "react";

export const ProjectsIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 2} ${height + 2}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9885 0.639273C15.9784 0.403367 15.7944 0.211805 15.559 0.192242L15.5444 0.191023C13.95 0.0595547 12.3686 0.236367 10.8443 0.716492C9.28648 1.20724 7.86317 1.98959 6.61395 3.04184C6.39723 3.2244 6.1882 3.41402 5.98592 3.60952C5.48952 3.3109 4.90998 3.16905 4.32542 3.20827C3.63736 3.25443 2.98958 3.54865 2.50148 4.03674L0.137297 6.40096C-0.0457656 6.58402 -0.0457656 6.8808 0.137297 7.06387L0.415203 7.34177C1.17792 8.10449 2.17955 8.53115 3.23555 8.55927L3.05867 9.71477C3.03608 9.86224 3.08508 10.0116 3.19058 10.1171L5.73423 12.6608C5.8228 12.7493 5.9423 12.7981 6.06564 12.7981C6.0892 12.7981 6.11295 12.7963 6.13658 12.7927L7.29242 12.6157C7.32064 13.6717 7.74705 14.6736 8.50955 15.4361L8.78749 15.7141C8.87902 15.8056 8.99898 15.8514 9.11892 15.8514C9.23886 15.8514 9.35886 15.8056 9.45036 15.7141L11.8145 13.3499C12.7243 12.4401 12.9056 11.0598 12.2963 9.9594C12.5345 9.7264 12.7645 9.48365 12.9848 9.23021C14.0155 8.04427 14.8001 6.69062 15.3169 5.2069C15.8295 3.73527 16.0554 2.19849 15.9885 0.639273ZM15.0622 1.09912C15.069 2.1418 14.933 3.17134 14.6573 4.1729L11.8848 1.40037C12.925 1.1498 13.9894 1.04862 15.0622 1.09912ZM1.13223 6.7318L3.16436 4.69968C3.73552 4.12852 4.60183 3.98912 5.31308 4.31299C4.62552 5.0924 4.04023 5.95655 3.56508 6.89684L3.49108 7.04327C3.46877 7.08743 3.45358 7.13487 3.44611 7.18377L3.37892 7.62274C2.54317 7.63021 1.74427 7.31646 1.13223 6.7318ZM4.0213 9.62206L4.21817 8.3359C4.23383 8.31443 4.24798 8.29187 4.25986 8.26799L7.58627 11.5944C7.56408 11.6052 7.54302 11.618 7.52286 11.6321L6.22933 11.8301L4.0213 9.62206ZM11.1517 12.687L9.11958 14.7191C8.53508 14.1073 8.2212 13.3081 8.22864 12.4724L8.66761 12.4052C8.71652 12.3978 8.76392 12.3826 8.80808 12.3603L9.25245 12.1357C10.0872 11.714 10.8624 11.2042 11.5723 10.6131C11.8588 11.3124 11.708 12.1306 11.1517 12.687ZM8.82964 11.299L8.68814 11.3705L4.48252 7.16487C5.82427 4.61015 8.12833 2.63549 10.8576 1.69899L14.3319 5.17318C13.3264 7.84105 11.382 10.0092 8.82964 11.299Z"
      fill={color}
    />
    <path
      d="M8.31691 5.07862C7.58837 5.80716 7.58837 6.99259 8.31691 7.72116C8.68116 8.08541 9.15969 8.26753 9.63816 8.26753C10.1166 8.26753 10.5952 8.08541 10.9594 7.72113C11.6879 6.99259 11.6879 5.80716 10.9594 5.07862C10.2309 4.35009 9.04541 4.35009 8.31691 5.07862ZM10.2965 7.05822C9.9335 7.42122 9.34281 7.42122 8.97981 7.05822C8.61681 6.69519 8.61681 6.10453 8.97981 5.7415C9.16134 5.56 9.39972 5.46925 9.63816 5.46925C9.87659 5.46925 10.115 5.56 10.2965 5.7415C10.6595 6.10456 10.6595 6.69522 10.2965 7.05822Z"
      fill={color}
    />
    <path
      d="M3.84755 12.1334C3.66452 11.9503 3.3677 11.9503 3.18464 12.1334L1.67636 13.6417C1.4933 13.8248 1.4933 14.1215 1.67636 14.3046C1.76789 14.3961 1.88786 14.4419 2.0078 14.4419C2.12773 14.4419 2.24773 14.3961 2.33923 14.3046L3.84752 12.7963C4.03064 12.6133 4.03064 12.3164 3.84755 12.1334Z"
      fill={color}
    />
    <path
      d="M2.5048 10.7906C2.32177 10.6076 2.02496 10.6076 1.8419 10.7906L0.333586 12.2989C0.150523 12.482 0.150523 12.7788 0.333586 12.9618C0.425117 13.0534 0.545086 13.0991 0.665023 13.0991C0.784961 13.0991 0.904961 13.0534 0.996461 12.9618L2.50477 11.4535C2.68787 11.2705 2.68787 10.9737 2.5048 10.7906Z"
      fill={color}
    />
    <path
      d="M4.52696 13.4762L3.01864 14.9845C2.83558 15.1676 2.83558 15.4643 3.01864 15.6474C3.11018 15.7389 3.23014 15.7847 3.35008 15.7847C3.47002 15.7847 3.59002 15.739 3.68152 15.6474L5.18983 14.1391C5.37289 13.956 5.37289 13.6592 5.18983 13.4762C5.0068 13.2931 4.71002 13.2931 4.52696 13.4762Z"
      fill={color}
    />
  </svg>
);
