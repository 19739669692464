/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { CreateProjectRunSyncDto } from './create-project-run-sync-dto';

/**
 * 
 * @export
 * @interface CreateProjectRunDto
 */
export interface CreateProjectRunDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRunDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRunDto
     */
    'projectId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRunDto
     */
    'scenarioId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRunDto
     */
    'schedule': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRunDto
     */
    'status'?: CreateProjectRunDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRunDto
     */
    'jobType'?: CreateProjectRunDtoJobTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof CreateProjectRunDto
     */
    'scheduleInfo'?: object;
    /**
     * 
     * @type {Array<CreateProjectRunSyncDto>}
     * @memberof CreateProjectRunDto
     */
    'syncDtos'?: Array<CreateProjectRunSyncDto>;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectRunDto
     */
    'timeoutInHours'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateProjectRunDto
     */
    'variables'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRunDto
     */
    'modelEntityId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectRunDto
     */
    'deleted'?: boolean;
}

export const CreateProjectRunDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type CreateProjectRunDtoStatusEnum = typeof CreateProjectRunDtoStatusEnum[keyof typeof CreateProjectRunDtoStatusEnum];
export const CreateProjectRunDtoJobTypeEnum = {
    ProjectJob: 'PROJECT_JOB',
    PredictionJob: 'PREDICTION_JOB'
} as const;

export type CreateProjectRunDtoJobTypeEnum = typeof CreateProjectRunDtoJobTypeEnum[keyof typeof CreateProjectRunDtoJobTypeEnum];


