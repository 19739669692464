import React from "react";

import { Box, makeStyles } from "@material-ui/core";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const useStyles = makeStyles({
  markdownStyles: {
    fontSize: "14px",
    background: "#FFF",
    overflow: "auto",
    "& > *:first-child": {
      marginBottom: 0
    },
    "& ul": {
      display: "block",
      marginBlockStart: "1em",
      marginBlockEnd: "1em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
      paddingInlineStart: "40px",
      marginTop: 0,
      marginBottom: "10px"
    },
    "& ol": {
      display: "block",
      listStyleType: "decimal",
      marginBlockStart: "1em",
      marginBlockEnd: "1em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
      paddingInlineStart: "40px",
      marginTop: "0",
      marginBottom: " 10px"
    },
    "& ul li": {
      listStylePosition: "inside",

      "& p": {
        display: "inline",
        margin: 0
      }
    },
    "& ol li": {
      listStylePosition: "inside",

      "& p": {
        display: "inline",
        margin: 0
      }
    },
    "& code": {
      padding: "2px 4px",
      fontSize: "90%",
      color: "#c7254e",
      backgroundColor: "#f9f2f4",
      borderRadius: "4px"
    },
    "& pre": {
      display: "block",
      padding: "9.5px",
      margin: "0 0 10px",
      fontSize: "13px",
      lineHeight: "1.42857143",
      color: "#333",
      wordBreak: "break-word",
      wordWrap: "break-word",
      backgroundColor: "#f5f5f5",
      border: "1px solid #ccc",
      borderRadius: "4px"
    },
    "& pre code": {
      padding: "0",
      fontSize: "inherit",
      color: "inherit",
      whiteSpace: "pre-wrap",
      backgroundColor: "transparent",
      borderRadius: "0"
    },
    "& table > thead > tr > th": {
      verticalAlign: "bottom",
      borderBottom: "2px solid #ddd",
      padding: "8px"
    },
    "& table > tbody > tr:nth-child(odd) > td": {
      backgroundColor: "#f9f9f9"
    },
    "& table > caption + thead > tr:first-child > th": {
      borderTop: 0
    },
    "& table > colgroup + thead > tr:first-child > th": {
      borderTop: 0
    },
    "& table > thead:first-child > tr:first-child > th": {
      borderTop: 0
    },
    "& table > caption + thead > tr:first-child > td": {
      borderTop: 0
    },
    "& table > colgroup + thead > tr:first-child > td": {
      borderTop: 0
    },
    "& table > thead:first-child > tr:first-child > td": {
      borderTop: 0
    },
    "& table > thead > tr > td": {
      padding: "8px",
      verticalAlign: "top",
      borderTop: "1px solid #ddd"
    },
    "& table > tbody > tr > td": {
      padding: "8px",
      verticalAlign: "top",
      borderTop: "1px solid #ddd"
    },
    "& table > tfoot > tr > td": {
      padding: "8px",
      verticalAlign: "top",
      borderTop: "1px solid #ddd"
    },
    "& img": {
      maxWidth: "35%"
    }
  }
});

const MarkdownDisplay = ({ string }: { string: string }) => {
  const classes = useStyles();

  return (
    <Box className={classes.markdownStyles}>
      <Markdown remarkPlugins={[remarkGfm]}>{string}</Markdown>
    </Box>
  );
};

export default MarkdownDisplay;
