import React, { useRef, useEffect, useState, PropsWithChildren } from "react";
import { Tooltip } from "@material-ui/core";

import { useStyles } from "./TopNavBarNotifications.style";

const NotificationLabel: React.FC<PropsWithChildren<{}>> = (props) => {
  const textElementRef = useRef<HTMLDivElement | null>(null);
  const [hover, setHover] = useState(false);
  const classes = useStyles();

  const compareSize = () => {
    if (textElementRef?.current) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("mouseover", compareSize);

    () => {
      window.removeEventListener("mouseover", compareSize);
    };
  }, []);

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={props.children ?? ""}
      interactive
      placement="top"
      disableHoverListener={!hover}>
      <div className={classes.contentLabel} ref={textElementRef}>
        {props.children}
      </div>
    </Tooltip>
  );
};

export default NotificationLabel;
