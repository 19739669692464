import React, { useEffect, useState, useMemo, useRef } from "react";
import { castArray, compact, filter, get } from "lodash";
import { Typography, Chip } from "@material-ui/core";

import { getEnvironmentById, runRecipeLogsWithRethrow } from "../../../api";

import { EnvironmentStatuses } from "src/constants/environments.constants";
import envRelaunchNotification, {
  envRelaunchMessage
} from "../../../utils/envRelaunchNotification";
import { RecipeStatuses } from "src/constants";

const useGetRecipeRunLogs = ({ scenarioId, groupId, jobRunId, showAllLogs = false }: $TSFixMe) => {
  const interval = 2000;

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingFullLogs, setIsFetchingFullLogs] = useState(false);
  const [isEnvRelaunched, setIsEnvRelaunched] = useState(false);

  const [recipeName, setRecipeName] = useState("");
  const [envStatus, setEnvStatus] = useState("");
  const [recipeStatus, setRecipeStatus] = useState<$TSFixMe>("");
  const [recipeLogs, setRecipeLogs] = useState([]);
  const [isViewFullLogsClicked, setIsViewFullLogsClicked] = useState(false);
  const recipeRunLogsInterval = useRef<$TSFixMe>(null);

  const stopWatching = (timeoutId: $TSFixMe) => {
    timeoutId && clearTimeout(timeoutId);
    recipeRunLogsInterval.current && clearTimeout(recipeRunLogsInterval.current);
  };

  const getRecipeLogs = async ({ shouldShowFullLogs = true, timeoutId }: $TSFixMe) => {
    if (groupId && scenarioId) {
      shouldShowFullLogs && setIsFetchingFullLogs(true);

      try {
        const response = await runRecipeLogsWithRethrow(
          groupId,
          scenarioId,
          shouldShowFullLogs || showAllLogs ? 10000 : 50,
          jobRunId
        );

        // Environment Status code starts >>
        let thisIsEnvRelaunched: boolean = false;
        const thisEnvStatus = response?.envStatus;

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!thisEnvStatus) {
          if (
            thisEnvStatus?.trim()?.toLowerCase() === EnvironmentStatuses.Launching.toLowerCase()
          ) {
            thisIsEnvRelaunched = true;
            setIsEnvRelaunched(true);

            if (thisEnvStatus !== envStatus) {
              const envResponse = await getEnvironmentById(response?.envId);
              const shutdownTimeInHrs = envResponse?.[0].shutdownStrategy?.inactivityInHours;
              envRelaunchNotification(shutdownTimeInHrs);
            }
          }
        }

        // Must be after the above if condition, as it's previous value being used in the if condition.
        setEnvStatus(response?.envStatus);
        // << Environment Status code ends

        // Recipe Log code starts >>
        !isFetching && setIsFetching(true);
        shouldShowFullLogs && setIsFetchingFullLogs(false);

        !recipeName && setRecipeName(response?.displayName);

        if (
          recipeLogs?.length === 0 ||
          (recipeLogs?.length > 0 && response?.status === RecipeStatuses.Running) ||
          // The below condition is to set logs of first succeed status preceded by (after) a running status.
          (recipeLogs?.length > 0 &&
            recipeStatus === RecipeStatuses.Running &&
            response?.status !== RecipeStatuses.Running) ||
          shouldShowFullLogs
        ) {
          const dbValidationLogs = compact(
            castArray(
              get(
                filter(
                  response?.results,
                  ({ status, type }) => status === "FAILURE" && type === "RECIPE"
                ),
                ["0", "msgList", "0", "msg"]
              ) ?? ""
            )
          );
          const thisRecipeLogs: $TSFixMe = [
            ...(!shouldShowFullLogs && (thisIsEnvRelaunched || isEnvRelaunched)
              ? [envRelaunchMessage]
              : []),
            ...(response?.status === RecipeStatuses.Running
              ? response?.transformLogs?.[0]?.logs
              : response?.transformLogs?.reduce(
                  (acc: string[], item: $TSFixMe) =>
                    item?.logs?.length > 0
                      ? [...acc, `>>>>> ${item?.name} <<<<<`, ...item?.logs]
                      : acc,
                  []
                ) ?? []),
            ...dbValidationLogs
          ];

          setRecipeLogs(thisRecipeLogs);

          if (
            recipeLogs?.length > 0 &&
            recipeStatus === RecipeStatuses.Running &&
            response?.status === RecipeStatuses.Success
          ) {
            setIsViewFullLogsClicked(false);
          }
        }

        // Must be after the above if condition, as it's previous value being used in the if condition.
        setRecipeStatus(() => response?.status);
        // << Recipe Log code ends
      } catch (e) {
        stopWatching(timeoutId);
      }
    }
  };
  const isMounted = useRef(true);
  useEffect(() => {
    let timeout = 0;
    let timeoutId: $TSFixMe = null;

    recipeRunLogsInterval.current && clearTimeout(recipeRunLogsInterval.current);

    const _ = () => {
      // Call getRecipeLogs every 2 secs.
      timeoutId = setTimeout(async () => {
        if (!isMounted.current) {
          return;
        }
        recipeRunLogsInterval.current = timeoutId;

        const beforeTimeInMillis = Date.now();
        await getRecipeLogs({ timeoutId });
        const afterTimeInMillis = Date.now();

        if (recipeStatus === RecipeStatuses.Running) {
          const timeoutLeft = interval - (afterTimeInMillis - beforeTimeInMillis);
          timeout = timeoutLeft <= 0 ? 0 : timeoutLeft;

          _();
        } else {
          stopWatching(timeoutId);
        }
      }, timeout);
    };

    groupId && scenarioId && ["", RecipeStatuses.Running].includes(recipeStatus) && _();
  }, [groupId, scenarioId, recipeStatus, jobRunId]);

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
      recipeRunLogsInterval.current && clearTimeout(recipeRunLogsInterval.current);
    };
  }, []);

  const getStatus = useMemo(() => {
    // @FIXME: The color codes below should be constants & centralized.
    let style = {
      marginLeft: 10,
      backgroundColor: "#ecf5ff",
      borderColor: "#0844cf",
      color: "#0844cf"
    };

    switch (recipeStatus) {
      case RecipeStatuses.Success:
        style = {
          ...style,
          backgroundColor: "#ddfbe5",
          borderColor: "#1fa045",
          color: "#1fa045"
        };
        break;
      case RecipeStatuses.Error:
        style = {
          ...style,
          backgroundColor: "#fff2f1",
          borderColor: "#f74c54",
          color: "#f74c54"
        };
        break;
    }

    return (
      <Chip
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        variant="soft"
        style={style}
        label={<Typography variant="caption">{recipeStatus}</Typography>}
      />
    );
  }, [recipeStatus]);

  const recipeRunInfo =
    recipeStatus === RecipeStatuses.Success
      ? { type: "success", message: "Recipe run successful" }
      : recipeStatus === RecipeStatuses.Running
        ? { type: "info", message: "Recipe run in progress..." }
        : recipeStatus === RecipeStatuses.UnBuilt
          ? {
              type: "info",
              message: "Logs are not available because the recipe hasn't been executed yet."
            }
          : { type: "error", message: "No logs found!" };

  return {
    recipeRunInfo,
    getStatus,
    isFetching,
    recipeLogs,
    getRecipeLogs,
    isFetchingFullLogs,
    recipeName,
    recipeStatus,
    isViewFullLogsClicked,
    setIsViewFullLogsClicked
  };
};

export default useGetRecipeRunLogs;
