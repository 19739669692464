import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const VersionIcon: React.FC<IProps> = (props) => {
  const {
    width = 18,
    height = 18,
    viewBox = "0 0 64 64",
    fill = "none",
    color = "currentColor"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g fill={color}>
        <path d="m16 2c-4.4183 0-8 3.58172-8 8v7c0 1.1046.89543 2 2 2 1.1046 0 2-.8954 2-2v-7c0-2.20914 1.7909-4 4-4h32c2.2091 0 4 1.79086 4 4v44c0 2.2091-1.7909 4-4 4h-4c-1.1046 0-2 .8954-2 2s.8954 2 2 2h4c4.4183 0 8-3.5817 8-8v-44c0-4.41828-3.5817-8-8-8z" />
        <path d="m34 62c1.1046 0 2-.8954 2-2s-.8954-2-2-2h-18c-2.2091 0-4-1.7909-4-4v-27c0-1.1046-.8954-2-2-2-1.10457 0-2 .8954-2 2v27c0 4.4183 3.5817 8 8 8z" />
        <path d="m40.5787 17.2279c.6781-.8719.5211-2.1285-.3508-2.8066s-2.1285-.5211-2.8066.3508l-7.8168 10.0502-3.075-3.6495c-.7118-.8447-1.9735-.9524-2.8182-.2407s-.9525 1.9735-.2408 2.8181l4.6667 5.5385c.3888.4615.9653.7227 1.5686.7109s1.1691-.2954 1.5396-.7717z" />
        <path d="m18 41c0-1.1046.8954-2 2-2h24c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-24c-1.1046 0-2-.8954-2-2z" />
        <path d="m18 49c0-1.1046.8954-2 2-2h24c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-24c-1.1046 0-2-.8954-2-2z" />
      </g>
    </svg>
  );
};

export default VersionIcon;
