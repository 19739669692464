import React from "react";

// Packages
import { Controller, useFieldArray, UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { every, filter, isEmpty, map, pick, size, toLower, trim } from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";

// Icons
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import { getReactHookFormTextFieldRules } from "src/utils/helpers";

// Components
import PreviewPlaceholder from "../PreviewPlaceholder";

// Constants
import {
  defaultVariables,
  ProjectSettingsFormFields,
  ProjectSettingsFormFieldsNameMapping,
  ProjectSettingsHelperText
} from "../../utils/ProjectSettings.constants";

// Styles
import useStyles from "./Variables.styles";

type VariableRowProps = {
  variable: { [id: string]: string };
  variableIndex: number;
  isSingleVariable: boolean;
  removeVariable: UseFieldArrayRemove;
  isReadOnly: boolean;
};

type Props = {
  isFetchingVariables: boolean;
  isReadOnly: boolean;
};

const Variables = (props: Props) => {
  const { isFetchingVariables, isReadOnly } = props || {};

  const { control } = useFormContext();

  const classes = useStyles();

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: ProjectSettingsFormFields.Variables
  });

  const addVariable = () => prepend(defaultVariables[0]);
  const removeVariable = (indices?: number | number[] | undefined) => {
    remove(indices);
    size(fields) <= 1 && addVariable();
  };

  return (
    <Paper className={classes.root}>
      <Box px={2} py={1}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography
            variant="subtitle2"
            color="textSecondary"
            data-testid="projectSettingsVariables">
            {ProjectSettingsHelperText.Variables}
          </Typography>
          {!isFetchingVariables && (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              disabled={!!isReadOnly}
              onClick={addVariable}
              data-testid="projectSettingsAddVariableAction">
              {ProjectSettingsHelperText.AddVariable}
            </Button>
          )}
        </Grid>
      </Box>
      <Divider />

      {!!isFetchingVariables ? (
        <PreviewPlaceholder
          height={80}
          simulateFormControl={false}
          label={ProjectSettingsFormFieldsNameMapping[ProjectSettingsFormFields.Variables]}
          data-testid="projectSettingsVariablesPreview"
        />
      ) : (
        <Box p={2}>
          {!isEmpty(fields) && (
            <Box pt={1} className={classes.wrapper}>
              {map(fields, (variable, variableIndex) => (
                <Box key={`variable${variableIndex}`} {...(variableIndex > 0 ? { mt: 2 } : {})}>
                  <VariableRow
                    variable={variable}
                    variableIndex={variableIndex}
                    isSingleVariable={size(fields) <= 1}
                    removeVariable={removeVariable}
                    isReadOnly={!!isReadOnly}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

const VariableRow = (props: VariableRowProps) => {
  const { variable, variableIndex, isSingleVariable, removeVariable, isReadOnly } = props || {};

  const theme = useTheme();
  const classes = useStyles();

  const inputLabelProps = {
    classes: {
      root: classes.inputLabel,
      shrink: classes.inputLabelShrink
    }
  };

  const { control, watch, trigger } = useFormContext();

  const watchVariables = watch(ProjectSettingsFormFields.Variables);

  const getVariableKeyRules = () => ({
    validate: (value: string) => {
      const fieldName = ProjectSettingsHelperText.VariableFieldName;

      if (!value || trim(value) === "") {
        if (!!watchVariables?.[variableIndex]?.value) {
          // Allow empty value, no validation required
          return `The ${toLower(fieldName)} cannot be blank.`;
        } else {
          return true;
        }
      }

      // Apply other rules only when the field is not empty
      const { noInvalidPatterns } = getReactHookFormTextFieldRules({ fieldName }).validate;

      // Check for pattern validity
      const isPatternValid = noInvalidPatterns(value);
      if (isPatternValid !== true) {
        return isPatternValid; // return the error message from the pattern check
      }

      // Check for duplicate values
      const duplicateCount = size(
        filter(map(watchVariables, "key"), (val: string) => val === value)
      );
      if (duplicateCount > 1) {
        return `This ${toLower(ProjectSettingsHelperText.Key)} is duplicate!`;
      }

      return true;
    }
  });

  const getVariableValueRules = () => ({
    validate: (value: string) => {
      if (!!watchVariables?.[variableIndex]?.key) {
        if (!value || trim(value) === "") {
          // Allow empty value, no validation required
          return `The ${toLower(ProjectSettingsHelperText.Value)} cannot be blank.`;
        }
      }

      return true;
    }
  });

  const isRemoveActionDisabled = () => {
    const singleVariable = isSingleVariable
      ? watch(ProjectSettingsFormFields.Variables)?.[0]
      : null;

    const isEmptySingleVariable =
      !!singleVariable && every(pick(singleVariable, ["key", "value"]), (value) => !trim(value));

    return !!isReadOnly || !!isEmptySingleVariable;
  };

  return (
    <Grid
      key={variable?.id}
      container
      alignItems="flex-start"
      wrap="nowrap"
      className={classes.variablesContainer}>
      <Grid item xs={6} style={{ flexGrow: 1 }}>
        <Controller
          control={control}
          name={`${ProjectSettingsFormFields.Variables}.${variableIndex}.key`}
          rules={getVariableKeyRules()}
          render={({ field, fieldState }) => {
            const { error } = fieldState;

            return (
              <TextField
                {...field}
                type="text"
                size="small"
                label={ProjectSettingsHelperText.Key}
                InputLabelProps={inputLabelProps}
                variant="outlined"
                fullWidth
                disabled={!!isReadOnly}
                error={!!error}
                onChange={(event) => {
                  field.onChange(event?.target?.value);

                  // Revalidate all the fields to reflect the duplicate error on all fields
                  trigger(ProjectSettingsFormFields.Variables);
                }}
                {...(!!error?.message ? { helperText: error?.message } : {})}
                data-testid={`projectSettingsVariable${variableIndex}KeyInput`}
              />
            );
          }}
          data-testid={`projectSettingsVariable${variableIndex}Key`}
        />
      </Grid>
      <Grid item xs={6} style={{ flexGrow: 1 }}>
        <Controller
          control={control}
          name={`${ProjectSettingsFormFields.Variables}.${variableIndex}.value`}
          rules={getVariableValueRules()}
          render={({ field, fieldState }) => {
            const { error } = fieldState;

            return (
              <TextField
                {...field}
                type="text"
                size="small"
                label={ProjectSettingsHelperText.Value}
                InputLabelProps={inputLabelProps}
                variant="outlined"
                fullWidth
                disabled={!!isReadOnly}
                error={!!error}
                {...(!!error?.message ? { helperText: error?.message } : {})}
                data-testid={`projectSettingsVariable${variableIndex}ValueInput`}
              />
            );
          }}
          data-testid={`projectSettingsVariable${variableIndex}Value`}
        />
      </Grid>

      <IconButton
        size="small"
        onClick={() => removeVariable(variableIndex)}
        style={{ width: 40, marginTop: theme.spacing(1) }}
        disabled={isRemoveActionDisabled()}
        data-testid={`projectSettingsVariable${variableIndex}RemoveAction`}>
        <TrashIcon viewBox="0 0 20 20" />
      </IconButton>
    </Grid>
  );
};

export default Variables;
