import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

import NewModal from "components/Modal/NewModal";
import UploadFileContainer from "./UploadFileContainer";
import UploadedFileWithData from "./UploadedFileWithData";

interface IProps {
  open: boolean;
  entityId: string;
  projectRunId: string;
  onClose: () => void;
  onFileUpload: (fileName: string) => void;
}

const useStyles = makeStyles({
  wrapper: {
    height: "calc(100vh - 165px)",
    overflow: "auto"
  }
});

const UploadFileModal: React.FC<IProps> = (props) => {
  const { open, entityId, projectRunId, onClose, onFileUpload } = props;
  const [file, setFile] = useState<File | null>(null);
  const { wrapper } = useStyles();

  const handleUpload = (newFile: File) => {
    setFile(newFile);
  };

  const handleCancel = () => {
    setFile(null);
  };

  const handleClose = () => {
    setFile(null);
    onClose();
  };

  return (
    <NewModal
      header="File Upload"
      open={open}
      data-testid="predict-job-dataset-upload-modal"
      keepMounted={false}
      width="95%"
      background="#f5f5f5"
      hideSubmit
      cancelButtonLabel="Close"
      onClose={handleClose}>
      <div className={wrapper}>
        {file ? (
          <UploadedFileWithData
            entityId={entityId}
            projectRunId={projectRunId}
            file={file}
            onCancel={handleCancel}
            onFileUpload={onFileUpload}
          />
        ) : (
          <UploadFileContainer onUpload={handleUpload} />
        )}
      </div>
    </NewModal>
  );
};

export default UploadFileModal;
