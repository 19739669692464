import React from "react";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
  color?: string;
}

const AddRunIcon: React.FC<IProps> = (props) => {
  const {
    width = 22,
    height = 22,
    viewBox = "0 0 24 24",
    fill = "none",
    color = "currentColor"
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5074 6.69243C20.2148 5.98499 20.2148 4.83802 19.5074 4.13058C18.7999 3.42314 17.6529 3.42314 16.9455 4.13058C16.2381 4.83802 16.2381 5.98499 16.9455 6.69243C17.6529 7.39987 18.7999 7.39987 19.5074 6.69243Z"
        fill={color}
      />
      <path
        d="M22.9822 5.73902C22.4694 5.55235 21.9023 5.8168 21.7156 6.32966L20.8706 8.65127L19.1926 8.0405C18.8917 8.15126 18.5667 8.21184 18.2279 8.21184C17.1915 8.21184 16.2855 7.64537 15.8014 6.8062L15.3973 6.65915C14.8996 6.47799 14.3669 6.41357 13.8403 6.47082L11.9538 6.67601C11.3923 6.73708 10.9143 7.11192 10.7211 7.64268L9.95057 9.75975C9.76389 10.2726 10.0284 10.8397 10.5412 11.0264C11.0541 11.2131 11.6212 10.9486 11.8078 10.4357L12.4432 8.68995L14.4411 8.49803L12.04 15.0949H9.55297C8.91621 15.0949 8.40006 15.6111 8.40002 16.2479C8.40002 16.8846 8.91621 17.4008 9.55297 17.4008H12.6167C13.2399 17.4008 13.7965 17.0111 14.0097 16.4255L14.5505 14.9395L16.4078 15.6155L15.2296 18.8528C14.9558 19.6048 15.5127 20.4 16.313 20.4C16.7977 20.4 17.2306 20.0968 17.3964 19.6414L18.8563 15.6303C19.1363 14.861 18.7396 14.0104 17.9703 13.7304L17.1964 13.4487L18.4921 9.88891L20.6589 10.6776C21.4282 10.9576 22.2789 10.561 22.5589 9.7916L23.5729 7.00569C23.7595 6.49278 23.4952 5.92571 22.9822 5.73902Z"
        fill={color}
      />
      <path
        d="M7.35547 11.6611V12.8506H0.533203V11.6611H7.35547ZM4.58008 8.75586V16.002H3.31543V8.75586H4.58008Z"
        fill={color}
      />
    </svg>
  );
};

export default AddRunIcon;
