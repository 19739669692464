import React from "react";
import { Grid, Link, Typography, makeStyles, Tooltip } from "@material-ui/core";

import noDatasetsSvg from "src/assets/images/recipe-with-no-datasets.svg";
import { READONLY_ENTITY } from "src/constants";

const useStyles = makeStyles({
  noDatasetsContainerWrap: {
    width: "100%",
    height: "100%",
    flexWrap: "nowrap",
    paddingTop: "15%",
    alignItems: "center",
    backgroundColor: "#ffffff"
  },
  noDatasetsSvgWrap: {
    textAlign: "center"
  },
  addDatasetsText: {
    marginTop: "20px"
  },
  transformsLink: {
    color: "#008FE4"
  }
});

export const RecipeWithNoDatasetsView = ({
  onTransformsClick,
  isDefaultScenario
}: {
  onTransformsClick: () => void;
  isDefaultScenario: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={`${classes.noDatasetsContainerWrap} container-height`}>
      <Grid container direction="column" className={classes.noDatasetsSvgWrap}>
        <Grid component="img" test-id="emptyRecipePageImage" src={noDatasetsSvg} />
        <Typography className={classes.addDatasetsText} test-id="emptyRecipePageText">
          This recipe has no associated datasets. <br />
          Please click on{" "}
          <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
            <Link
              className={classes.transformsLink}
              {...(isDefaultScenario && { onClick: onTransformsClick })}>
              Transformations
            </Link>
          </Tooltip>{" "}
          to understand the recipe usage
        </Typography>
      </Grid>
    </Grid>
  );
};
