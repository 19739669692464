import React, { useMemo } from "react";
import _ from "lodash";

import TextField from "./TextFieldWithValidation";
import { CONNECTOR_KEYS, LENGTH_ERROR_MESSAGE } from "../utils/OutputDataset.constants";
import { IConnectorDetailsProps } from "./SnowflakeConnectorDetails";
import { hasLengthError } from "./SQLRedshiftConnectorDetails";
import { Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const MongoConnectorDetails: React.FC<IConnectorDetailsProps> = (props) => {
  const { values, onChange } = props;

  const databaseValue = _.get(values, CONNECTOR_KEYS.mongoCateogry.database.key, "");
  const { error, helperText } = useMemo(() => {
    const trimmed = _.trim(databaseValue);
    if (_.size(trimmed) === 0) {
      return { error: false, helperText: "" };
    }

    const regex = CONNECTOR_KEYS.mongoCateogry.database.regex;
    const lengthError = hasLengthError(databaseValue);
    const isValid = regex.test(databaseValue);
    return {
      error: lengthError || !isValid,
      helperText: lengthError
        ? LENGTH_ERROR_MESSAGE
        : isValid
          ? ""
          : CONNECTOR_KEYS.mongoCateogry.database.errorMessage
    };
  }, [databaseValue]);

  const collectionValue = _.get(values, CONNECTOR_KEYS.mongoCateogry.collection.key, "");
  const { cError, cHelperText } = useMemo(() => {
    const trimmed = _.trim(collectionValue);
    if (_.size(trimmed) === 0) {
      return { error: false, helperText: "" };
    }
    const regex = CONNECTOR_KEYS.mongoCateogry.collection.regex;
    const lengthError = hasLengthError(collectionValue);
    const isValid = regex.test(collectionValue);
    return {
      cError: lengthError || !isValid,
      cHelperText: lengthError
        ? LENGTH_ERROR_MESSAGE
        : isValid
          ? ""
          : CONNECTOR_KEYS.mongoCateogry.collection.errorMessage
    };
  }, [collectionValue]);

  return (
    <>
      <TextField
        fullWidth
        id="database"
        label={CONNECTOR_KEYS.mongoCateogry.database.label}
        name={CONNECTOR_KEYS.mongoCateogry.database.key}
        required
        size="small"
        data-testid="database"
        value={databaseValue}
        error={error}
        helperText={helperText}
        variant="outlined"
        onChange={onChange}
      />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <TextField
          fullWidth
          id="collection"
          label={CONNECTOR_KEYS.mongoCateogry.collection.label}
          name={CONNECTOR_KEYS.mongoCateogry.collection.key}
          required
          size="small"
          data-testid="collection"
          value={collectionValue}
          variant="outlined"
          error={cError}
          helperText={cHelperText}
          onChange={onChange}
        />
        <Tooltip title="Please note that the data will always be appended in case of existing collection">
          <InfoOutlined />
        </Tooltip>
      </div>
    </>
  );
};

export default MongoConnectorDetails;
