/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DFSProblemTypeDto
 */
export interface DFSProblemTypeDto {
    /**
     * 
     * @type {string}
     * @memberof DFSProblemTypeDto
     */
    'problemType'?: DFSProblemTypeDtoProblemTypeEnum;
}

export const DFSProblemTypeDtoProblemTypeEnum = {
    Regression: 'REGRESSION',
    BinaryClassification: 'BINARY_CLASSIFICATION',
    MulticlassClassification: 'MULTICLASS_CLASSIFICATION',
    Unknown: 'UNKNOWN',
    Unrecognized: 'UNRECOGNIZED'
} as const;

export type DFSProblemTypeDtoProblemTypeEnum = typeof DFSProblemTypeDtoProblemTypeEnum[keyof typeof DFSProblemTypeDtoProblemTypeEnum];


