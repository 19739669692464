import React from "react";
import { Button, Grid, Tooltip, Typography, makeStyles } from "@material-ui/core";

import { Spinner } from "src/components";

type StyleProps = { background?: string; isExpanded: boolean };
const useStyles = makeStyles({
  outputHeader: {
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px 16px",
    background: ({ background }: StyleProps) => background || "#f2f9ff",
    cursor: "pointer",
    borderRadius: "4px",
    position: "relative"
  },
  arrow: {
    top: "-1px",
    left: "-15px",
    transform: "rotate(180deg)",
    width: 0,
    height: 0,
    position: "absolute",
    border: "15px solid transparent",
    borderBottomColor: "#4646B5",
    "&::before": {
      top: "-12px",
      left: "-15px",
      width: 0,
      height: 0,
      position: "absolute",
      border: "13px solid transparent",
      borderBottomColor: ({ background }: StyleProps) => background || "#f2f9ff",
      content: '""'
    }
  },
  rightAligned: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    flexWrap: "nowrap",
    alignItems: "center"
  },
  leftAligned: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px"
  },
  outputDatasetContainer: {
    backgroundColor: "#EFF3F5",
    flexWrap: "nowrap",
    borderRadius: "4px",
    border: "1px solid #4646B5"
  },
  outputContainer: {
    borderRadius: "0px 0px 4px 4px",
    background: "#fff"
  },
  btn: {
    textTransform: "none",
    fontWeight: 400
  }
});

export const AskAIOutputWrapContainer = ({
  name,
  buttons,
  children,
  onClick,
  background,
  rightComponent
}: {
  name: string | React.JSX.Element;
  children?: React.ReactNode;
  buttons: $TSFixMe[];
  onClick: () => void;
  background?: string;
  rightComponent?: React.ReactNode;
}) => {
  const isExpanded = !!children;
  const classes = useStyles({ isExpanded, background });

  return (
    <Grid container direction="column" className={classes.outputDatasetContainer}>
      <Grid
        container
        direction="row"
        className={classes.outputHeader}
        alignItems="center"
        onClick={() => onClick()}>
        <Grid className={classes.arrow} />
        <Grid item className={classes.leftAligned}>
          <Typography variant="subtitle2" style={{ fontWeight: 500 }} data-testid="aiOutputName">
            {name}
          </Typography>
        </Grid>
        <Grid item className={classes.rightAligned}>
          {rightComponent}
          {buttons?.map((button, index) => {
            const { component, text, helpText, isLoading, ...restProps } = button;
            if (component) {
              return <React.Fragment key={index}>{component}</React.Fragment>;
            }
            return (
              <Tooltip key={text} title={helpText || ""}>
                <div>
                  <Button
                    className={classes.btn}
                    variant="text"
                    size="small"
                    color="primary"
                    data-testid="ai-output-btn"
                    {...restProps}>
                    {isLoading ? <Spinner size={18} noPadding /> : text}
                  </Button>
                </div>
              </Tooltip>
            );
          })}
        </Grid>
      </Grid>
      {children && (
        <Grid className={classes.outputContainer} test-id="ask-ai-modal-output-container">
          {children}
        </Grid>
      )}
    </Grid>
  );
};
