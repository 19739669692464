import React from "react";
import FileInputField from "src/pages/private/ProjectsModule/pages/Dataset/components/LocalFilesSelection/FileInputField";
import { Button, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    height: "100%",
    borderRadius: 12,
    background: "#FFFFFF",
    border: "1px dashed #415F80",
    alignItems: "center",
    justifyContent: "center",
    display: "flex"
  },
  uploadBtn: {
    color: "#4646B5",
    borderRadius: "20px",
    borderColor: "#4646B5",
    "&:hover": {
      color: "#4646B5",
      borderColor: "#4646B5"
    }
  }
});

type IProps = {
  onFilesUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const DropImageBox = ({ onFilesUpload }: IProps) => {
  const classes = useStyles();
  return (
    <FileInputField onChange={onFilesUpload} accept="image/*">
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.root}
        component="label"
        htmlFor="fileInput">
        <Button
          id="uploadFilesFromLocal"
          variant="outlined"
          color="primary"
          component="span"
          className={classes.uploadBtn}>
          Upload from Computer
        </Button>
      </Grid>
    </FileInputField>
  );
};
