import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  makeStyles
} from "@material-ui/core";
import { map, isEmpty, size, get, orderBy, toLower } from "lodash";
import { generatePath, useNavigate } from "react-router-dom";

import NewModal from "components/Modal/NewModal";
import Text from "components/Widget/Text";
import useCreateProjectRun, { IProjectRunData } from "hooks/api/projects/useCreatePredictionJobRun";
import { MODELS } from "./SelectModel";
import { Model } from "hooks/api/projects/useGetAllModels";
import { WebPaths } from "src/routing/routes";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY_PROJECT_DETAILS } from "src/hooks/api/projects/useProjectDetails";

interface IProps extends Omit<IProjectRunData, "modelEntityId"> {
  models?: Model[];
}

const useStyles = makeStyles({
  selectMenu: {
    maxHeight: 200
  },
  info: {
    fontSize: 12,
    fontStyle: "italic",
    marginBottom: "12px",
    color: "grey"
  }
});

const PredictionButton: React.FC<IProps> = (props) => {
  const { models, projectId, name } = props;
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [modelId, setModelId] = useState("");
  const createProjectRun = useCreateProjectRun();
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    if (size(models) === 1) {
      setModelId(get(models, ["0", "id"]));
    }
  }, [models, open]);

  const handleClick = () => {
    setOpen(true);
  };

  const options = useMemo(() => {
    return map(
      orderBy(models, (item) => toLower(item.displayName)),
      ({ id, displayName, parentRecipeDisplayName }) => {
        const name = (
          <span>
            <span>{displayName}</span>{" "}
            <i style={{ fontSize: 14 }}>
              (<b>Source Recipe</b> -{" "}
              <Tooltip title={parentRecipeDisplayName}>
                <span>{parentRecipeDisplayName ?? "N/A"}</span>
              </Tooltip>
              )
            </i>
          </span>
        );

        return (
          <MenuItem id={id} key={id} value={id}>
            <Text value={name} />
          </MenuItem>
        );
      }
    );
  }, [models]);

  const handleCreatePrediction = () => {
    createProjectRun.mutate(
      { name, projectId, modelEntityId: modelId },
      {
        onSuccess: (result) => {
          if (result?.id) {
            queryClient.invalidateQueries([QUERY_KEY_PROJECT_DETAILS]);
            navigate(
              generatePath(WebPaths.EditPredictionJob, {
                projectId: props.projectId,
                projectRunId: result?.id
              }),
              {
                state: { [MODELS]: models }
              }
            );
          }
        }
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
    setModelId("");
  };

  return (
    <>
      <Tooltip
        title={
          isEmpty(models)
            ? "To create a Prediction Job, at least one model from rapid model recipe must be present in the canvas"
            : ""
        }>
        <div>
          <Button
            variant="contained"
            data-testid="predict-job-output"
            size="small"
            color="primary"
            disabled={isEmpty(models)}
            onClick={handleClick}>
            + Prediction
          </Button>
        </div>
      </Tooltip>
      <NewModal
        open={open}
        header={
          <span>
            <EmojiEventsIcon /> <span>{"Select model to generate predictions "}</span>
          </span>
        }
        loading={createProjectRun.isLoading}
        width="465px"
        height={350}
        background="#fff"
        isDefaultButtonCustomTheming
        submitButtonLabel="Proceed"
        submitDisabled={isEmpty(models) || createProjectRun.isLoading || !modelId}
        onFinish={handleCreatePrediction}
        onClose={handleClose}>
        <div>
          <div className={classes.info}>
            <b>Note:</b> Applicable only for models successfully built by Rapid Model Recipes.
          </div>
          <FormControl fullWidth variant="outlined">
            <InputLabel test-id="model-label">Model *</InputLabel>
            <Select
              id="model-id"
              label="Model *"
              test-id="model-id"
              name="Model"
              value={modelId}
              MenuProps={{
                classes: { paper: classes.selectMenu },
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "center",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                }
              }}
              required
              onChange={(e) => setModelId(e.target.value as string)}>
              {options}
            </Select>
          </FormControl>
        </div>
      </NewModal>
    </>
  );
};

export default PredictionButton;
