import React from "react";
import clsx from "clsx";
import { Button, ButtonProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  button: {
    textTransform: "capitalize",
    background: "linear-gradient(90deg, #008FE4 7.56%, #FA8072 98.01%)",
    color: "white",

    "&:hover": {
      backgroundColor: "rgba(0, 143, 228, 1)",
      color: "white"
    }
  }
});

const GradientButton: React.FC<ButtonProps> = (props) => {
  const { className, ...restProps } = props;
  const styles = useStyles();

  return <Button className={clsx(className, styles.button)} {...restProps} />;
};

export default GradientButton;
