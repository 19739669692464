/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CopyProjectDto } from '../Models';
// @ts-ignore
import type { CopyProjectResponseDto } from '../Models';
// @ts-ignore
import type { CreateProjectDto } from '../Models';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { ProjectDto } from '../Models';
// @ts-ignore
import type { Schema } from '../Models';
// @ts-ignore
import type { UpdateProjectDto } from '../Models';
/**
 * ProjectControllerApi - axios parameter creator
 * @export
 */
export const ProjectControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupAllDeletedProjects: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/projects/cleanup-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupDeletedProjects: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cleanupDeletedProjects', 'id', id)
            const localVarPath = `/v2/projects/cleanup/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CopyProjectDto} copyProjectDto 
         * @param {boolean} [async] 
         * @param {boolean} [_export] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProject: async (copyProjectDto: CopyProjectDto, async?: boolean, _export?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyProjectDto' is not null or undefined
            assertParamExists('copyProject', 'copyProjectDto', copyProjectDto)
            const localVarPath = `/v2/projects/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (async !== undefined) {
                localVarQueryParameter['async'] = async;
            }

            if (_export !== undefined) {
                localVarQueryParameter['export'] = _export;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyProjectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProjectDto} createProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (createProjectDto: CreateProjectDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectDto' is not null or undefined
            assertParamExists('createProject', 'createProjectDto', createProjectDto)
            const localVarPath = `/v2/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjects: async (id: string, deleteAssociatedPDs?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjects', 'id', id)
            const localVarPath = `/v2/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deleteAssociatedPDs !== undefined) {
                localVarQueryParameter['deleteAssociatedPDs'] = deleteAssociatedPDs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [includeJobCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectById: async (id: string, includeJobCount?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findProjectById', 'id', id)
            const localVarPath = `/v2/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeJobCount !== undefined) {
                localVarQueryParameter['includeJobCount'] = includeJobCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjects: async (name?: string, count?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSchema', 'id', id)
            const localVarPath = `/v2/projects/{id}/schema`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectDto} updateProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (updateProjectDto: UpdateProjectDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectDto' is not null or undefined
            assertParamExists('updateProject', 'updateProjectDto', updateProjectDto)
            const localVarPath = `/v2/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectControllerApi - functional programming interface
 * @export
 */
export const ProjectControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanupAllDeletedProjects(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanupAllDeletedProjects(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.cleanupAllDeletedProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanupDeletedProjects(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanupDeletedProjects(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.cleanupDeletedProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CopyProjectDto} copyProjectDto 
         * @param {boolean} [async] 
         * @param {boolean} [_export] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyProject(copyProjectDto: CopyProjectDto, async?: boolean, _export?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CopyProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyProject(copyProjectDto, async, _export, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.copyProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateProjectDto} createProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(createProjectDto: CreateProjectDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(createProjectDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.createProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjects(id: string, deleteAssociatedPDs?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjects(id, deleteAssociatedPDs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.deleteProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [includeJobCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjectById(id: string, includeJobCount?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjectById(id, includeJobCount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.findProjectById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProjects(name?: string, count?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findProjects(name, count, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.findProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchema(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchema(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.getSchema']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectDto} updateProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(updateProjectDto: UpdateProjectDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(updateProjectDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectControllerApi.updateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectControllerApi - factory interface
 * @export
 */
export const ProjectControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupAllDeletedProjects(options?: any): AxiosPromise<void> {
            return localVarFp.cleanupAllDeletedProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupDeletedProjects(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cleanupDeletedProjects(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CopyProjectDto} copyProjectDto 
         * @param {boolean} [async] 
         * @param {boolean} [_export] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProject(copyProjectDto: CopyProjectDto, async?: boolean, _export?: boolean, options?: any): AxiosPromise<CopyProjectResponseDto> {
            return localVarFp.copyProject(copyProjectDto, async, _export, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProjectDto} createProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(createProjectDto: CreateProjectDto, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.createProject(createProjectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [deleteAssociatedPDs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjects(id: string, deleteAssociatedPDs?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjects(id, deleteAssociatedPDs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [includeJobCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjectById(id: string, includeJobCount?: boolean, options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.findProjectById(id, includeJobCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProjects(name?: string, count?: number, options?: any): AxiosPromise<Array<ProjectDto>> {
            return localVarFp.findProjects(name, count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema(id: string, options?: any): AxiosPromise<Schema> {
            return localVarFp.getSchema(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectDto} updateProjectDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(updateProjectDto: UpdateProjectDto, options?: any): AxiosPromise<ProjectDto> {
            return localVarFp.updateProject(updateProjectDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectControllerApi - interface
 * @export
 * @interface ProjectControllerApi
 */
export interface ProjectControllerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    cleanupAllDeletedProjects(options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    cleanupDeletedProjects(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {CopyProjectDto} copyProjectDto 
     * @param {boolean} [async] 
     * @param {boolean} [_export] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    copyProject(copyProjectDto: CopyProjectDto, async?: boolean, _export?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<CopyProjectResponseDto>;

    /**
     * 
     * @param {CreateProjectDto} createProjectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    createProject(createProjectDto: CreateProjectDto, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDto>;

    /**
     * 
     * @param {string} id 
     * @param {boolean} [deleteAssociatedPDs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    deleteProjects(id: string, deleteAssociatedPDs?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {boolean} [includeJobCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    findProjectById(id: string, includeJobCount?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectDto>>;

    /**
     * 
     * @param {string} [name] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    findProjects(name?: string, count?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<ProjectDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    getSchema(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Schema>;

    /**
     * 
     * @param {UpdateProjectDto} updateProjectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    updateProject(updateProjectDto: UpdateProjectDto, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDto>;

}

/**
 * ProjectControllerApi - object-oriented interface
 * @export
 * @class ProjectControllerApi
 * @extends {BaseAPI}
 */
export class ProjectControllerApi extends BaseAPI implements ProjectControllerApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public cleanupAllDeletedProjects(options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).cleanupAllDeletedProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public cleanupDeletedProjects(id: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).cleanupDeletedProjects(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CopyProjectDto} copyProjectDto 
     * @param {boolean} [async] 
     * @param {boolean} [_export] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public copyProject(copyProjectDto: CopyProjectDto, async?: boolean, _export?: boolean, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).copyProject(copyProjectDto, async, _export, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProjectDto} createProjectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public createProject(createProjectDto: CreateProjectDto, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).createProject(createProjectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} [deleteAssociatedPDs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public deleteProjects(id: string, deleteAssociatedPDs?: boolean, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).deleteProjects(id, deleteAssociatedPDs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} [includeJobCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public findProjectById(id: string, includeJobCount?: boolean, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).findProjectById(id, includeJobCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public findProjects(name?: string, count?: number, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).findProjects(name, count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public getSchema(id: string, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).getSchema(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectDto} updateProjectDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public updateProject(updateProjectDto: UpdateProjectDto, options?: RawAxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration).updateProject(updateProjectDto, options).then((request) => request(this.axios, this.basePath));
    }
}

