import { useEffect } from "react";

import { isString, throttle } from "lodash";

import { useRefreshToken } from "src/hooks/api";
import useCheckTokenValidation from "src/hooks/api/auth/useCheckTokenValidation";
import { useAuthSetAuthResponse } from "src/hooks/useAuthSetAuthResponse";

import {
  getLocalStorageItem,
  setLocalStorageItem
} from "src/services/LocalStorage/LocalStorage.service";
import { setSessionStorageItem } from "src/services/SessionStorage/SessionStorage.service";

import { getTokenFromStorage } from "src/utils/helpers";

import { AuthSessionEnum, AuthSessionConfig } from "src/constants";

type Props = {
  isUserLoggedIn?: boolean;
  logout?: () => void;
};

const useRefreshTokenHook = (props: Props) => {
  const { isUserLoggedIn, logout } = props || {};

  const { setAuthResponse } = useAuthSetAuthResponse();
  const { mutateAsync: refreshTokenMutation, reset: resetRefreshTokenMutation } = useRefreshToken();

  const { refetch } = useCheckTokenValidation({
    onSuccess: (data) => {
      if (data) {
        setAuthResponse({
          data,
          rememberMe: !!getLocalStorageItem({
            key: AuthSessionEnum.TokenSessionKey,
            shouldJsonParse: false
          })
        });
      }
    },
    enabled: false
  });

  // Create a throttled function to trigger the mutation
  const throttledMutation = throttle(async (tokenFromStorage) => {
    resetRefreshTokenMutation();
    await refreshTokenMutation(
      { token: tokenFromStorage },
      {
        onSuccess: async (data) => {
          if (isString(data)) {
            setLocalStorageItem({
              key: AuthSessionEnum.TokenSessionKey,
              data,
              shouldJsonStringify: false
            });

            setSessionStorageItem({
              key: AuthSessionEnum.TokenSessionKey,
              data,
              shouldJsonStringify: false
            });

            await refetch();
          }
        },
        onError: (error) => {
          console.error(error);
          logout?.();
        }
      }
    );
  }, AuthSessionConfig.refreshTokenInterval);

  const stopPolling = (intervalId: NodeJS.Timeout | null) => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    throttledMutation?.cancel();
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (!!isUserLoggedIn) {
      intervalId = setInterval(() => {
        const tokenFromStorage = getTokenFromStorage();
        if (!!tokenFromStorage) {
          throttledMutation(tokenFromStorage);
        }
      }, AuthSessionConfig.refreshTokenInterval);
    } else {
      stopPolling(intervalId);
    }

    return () => stopPolling(intervalId);
  }, [isUserLoggedIn, throttledMutation]);
};

export default useRefreshTokenHook;
