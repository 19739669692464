import { useEffect, useMemo } from "react";

// Packages
import { useParams } from "react-router-dom";
import { isArray, isEmpty, join, map, some, sortBy } from "lodash";

// Hooks
import { useGetProjectCanvas } from "src/hooks/api";
import useGetScenarios from "src/hooks/api/scenarios/useGetScenarios";

// Open API
import { SegmentDto } from "openapi/Models";

// Stores
import { useCanvasStore } from "src/store/store";

// Constants
import { NodeTypes } from "../../../utils";

const useRecipesStatus = () => {
  const { projectId, scenarioId } = useParams() || {};

  // Stores - STARTS >>
  const nodesStore = useCanvasStore((state) => state?.nodes);
  const isRecipesRunningAcrossScenariosStore = useCanvasStore(
    (state) => state?.isRecipesRunningAcrossScenarios
  );
  // << ENDS - Stores

  // Query hooks - STARTS >>
  // Queries
  const {
    isLoading: isLoadingScenarios,
    data: scenariosDataResponse,
    refetch: refetchScenarios
  } = useGetScenarios({
    projectId: projectId!,
    refetchOnMount: true
  });

  const scenariosData = useMemo(
    () =>
      isArray(scenariosDataResponse)
        ? map(scenariosDataResponse, (scenario) => ({
            ...scenario,
            segments: isEmpty(scenario?.segments)
              ? ""
              : join(
                  map(
                    sortBy(scenario?.segments, "name"),
                    (segment) => (segment as SegmentDto)?.name
                  ),
                  ", "
                )
          }))
        : [],
    [scenariosDataResponse]
  );

  const {
    isRefetching: isRefetchingProjectCanvas,
    data: projectCanvasData,
    refetch: refetchProjectCanvas
  } = useGetProjectCanvas({
    projectId,
    scenarioId: scenarioId!,
    enabled: false
  });

  const isRecipeInProject = useMemo(() => {
    if (!isEmpty(nodesStore)) {
      return some(nodesStore, { type: NodeTypes.Recipe });
    }

    if (!isEmpty(projectCanvasData?.nodes)) {
      return some(projectCanvasData?.nodes, { type: NodeTypes.Recipe });
    }

    return false;
  }, [nodesStore, projectCanvasData]);
  // << ENDS - Query hooks

  useEffect(() => {
    if (isEmpty(nodesStore)) {
      refetchProjectCanvas();
    }
  }, [nodesStore]);

  const isLoading = useMemo(() => !!isLoadingScenarios, [isLoadingScenarios]);

  return {
    isLoading,
    isRefetchingProjectCanvas,

    isRecipeInProject,
    isRecipesRunningAcrossScenariosStore,

    scenariosData,
    refetchScenarios
  };
};

export default useRecipesStatus;
