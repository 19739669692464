import React from "react";
import clsx from "clsx";

import rcDarkLogo from "../../assets/images/rc-logo-dark-text.svg";
import rcLogoBigGray from "../../assets/images/rc-icon-black.svg";
import Spinner from "../Spinner";

import styles from "./AppLoadingScreen.module.scss";

const AppLoadingScreen = ({ requestPending }: $TSFixMe) => {
  return (
    <div
      className={clsx(styles.loadingScreenContainer, {
        [styles.loadingScreenContainerOut]: !requestPending
      })}>
      <img className={styles.rcLogoBigGray} src={rcLogoBigGray} alt="rapidcanvas logo big gray" />
      <img
        className={styles.rcLogoDarkText}
        src={rcDarkLogo}
        alt="rapidcanvas logo with blue text"
      />
      <Spinner />
    </div>
  );
};

export default AppLoadingScreen;
