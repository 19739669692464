import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { filter, get, has, orderBy, size, isEmpty, some, find } from "lodash";
import { toast } from "react-toastify";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import { useEnvironmentsStore } from "../../../../store/store";
import {
  environmentsGetter,
  environmentsSetter,
  shouldRefreshEnvironmentsToggler,
  watchingEnvsSetter,
  watchingEnvsGetter,
  watchingEnvsIntervalIdSetter,
  watchingEnvsIntervalIdGetter,
  setIsEnvironmentLoadingSetter,
  isEnvironmentsLoadingGetter
} from "../../../../store/store.selectors";
import {
  deleteEnvironment,
  relaunchEnvironment,
  stopEnvironment,
  updateEnvironment,
  getEnvironmentById
} from "../../../../api";
import { Environment as EnvironmentType } from "../Environments";
import envRelaunchNotification from "../../../../utils/envRelaunchNotification";
import {
  EnvironmentsTypes,
  EnvironmentStatuses,
  DEFAULT_NAME
} from "../../../../constants/environments.constants";
import {
  getEnvironmentConfigSession,
  setEnvironmentConfigSession,
  removeEnvironmentConfigSession,
  ITypes,
  getEnvironmentUsageDetails
} from "../utils/environments.helpers";
import Spinner from "src/components/Spinner";
import Modal, { ModalVariants } from "src/components/custom/Modal/Modal";
import EnvironmentConfig from "./EnvironmentConfig/EnvironmentConfigDrawer";
import EnvironmentLogsDrawer from "./EnvironmentLogs/EnvironmentLogsDrawer";
import { EnvironmentInputs } from "./EnvironmentInputs/EnvironmentInputs";
import { EnvironmentTabs } from "./EnvironmentTabs/EnvironmentTabs";
import { toastWrapper } from "src/utils/toastWrapper";
import { validateNameField } from "src/utils/formFieldUtils";
import EnvironmentHeader from "./EnvironmentHeader";
import { createString } from "src/helpers/helpers";
import { EnvironmentDeleteMessage } from "../utils/Environments.constants";
import { openLogsModal } from "src/layout/NavBars/components/TopNavBar/TopNavBarNotifications/TopNavBarNotifications.constants";
import { WebPaths } from "src/routing/routes";
import { useGetEnvironments, useGetEnvironmentTypes } from "src/hooks/api";
import useGetEnvironmentUsage from "src/hooks/api/environments/useGetEnvironmentUsage";
import _ from "lodash";

export type Values = {
  name: string;
  description: string;
  envType: string;
  code: string;
};

export interface IPredictionService {
  id: string;
  pdCreated: number;
  pdCreator: string;
  pdId: string;
  pdName: string;
  pdUpdated: number;
  pdUpdater: string;
  pdModelName: string;
}

export interface EnvAction {
  types: ITypes;
  action: "stopping" | "modifying" | "changing";
}

const initialFormValues = {
  name: "",
  description: "",
  envType: "",
  code: "",
  cores: "",
  memory: "",
  diskSpace: ""
};

const errors = {
  nameExist: "The environment name already exists",
  typeMissing: "A type must be selected"
};

const useStyles = makeStyles(() => ({
  root: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    minHeight: "calc(100vh - 94px)"
  },
  container: { padding: 24, width: "calc(100vw)" },
  tab: {
    padding: "0 !important",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    "&.Mui-selected": {
      backgroundColor: "rgba(63, 81, 181, 0.08)"
    }
  }
}));

const Environment = () => {
  const { envId } = useParams<$TSFixMe>();
  const navigate = useNavigate();

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPredictionServices, setIsLoadingPredictionServices] = useState(false);
  const [showDeleteScreen, setShowDeleteScreen] = useState(false);
  const [confirmEnvAction, setConfirmEnvAction] = useState<EnvAction | null>(null);
  const [isStopping, setIsStopping] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // @ts-ignore
  const [showNameError, setShowNameError] = useState("");
  const [toggleRefreshStatus, setToggleRefreshStatus] = useState(false);
  const [environment, setEnvironment] = useState<$TSFixMe>({});
  const [projects, setProjects] = useState<
    Record<string, { jobsCount: number; predictionJobsCount: number }>
  >({});
  const [predictionServices, setPredictionServices] = useState<IPredictionService[]>([]);
  const [customConfigErrors, setCustomConfigErrors] = useState(["", "", ""]);

  const [isEnvUsageOpen, setIsEnvUsageOpen] = useState<$TSFixMe>(false);

  const [timer, setTimer] = useState<$TSFixMe>(new Date().getTime());

  const toggleShouldEnvironmentsRefresh = useEnvironmentsStore(shouldRefreshEnvironmentsToggler);
  const setEnvironments = useEnvironmentsStore(environmentsSetter);
  const environments = useEnvironmentsStore(environmentsGetter);
  const setIsEnvironmentLoading = useEnvironmentsStore(setIsEnvironmentLoadingSetter);
  const isEnvironmentLoading = useEnvironmentsStore(isEnvironmentsLoadingGetter);
  const watchingEnvsStore = useEnvironmentsStore(watchingEnvsGetter);
  const setWatchingEnvsStore = useEnvironmentsStore(watchingEnvsSetter);
  const watchingEnvsIntervalIdStore = useEnvironmentsStore(watchingEnvsIntervalIdGetter);
  const setWatchingEnvsIntervalIdStore = useEnvironmentsStore(watchingEnvsIntervalIdSetter);

  const { data: environmentsTypes, isLoading: isEnvironmentsTypesFetching } =
    useGetEnvironmentTypes();

  const [searchParams, setSearchParams] = useSearchParams();
  const isOpenLogs = searchParams.get(openLogsModal);

  const openLogs = () => {
    setLogsOpen(true);
  };

  useEffect(() => {
    if (isOpenLogs && !isEmpty(environment)) {
      openLogs();
    }
  }, [isOpenLogs, environment]);

  // $FixMe: Using session for time-being. This should be replaced with local-state.
  const initEnvironmentConfig = () => {
    setEnvironmentConfigSession(initialFormValues);
  };

  useEffect(() => {
    initEnvironmentConfig();

    () => () => removeEnvironmentConfigSession();
  }, []);

  const {
    isFetching: isLoadingEnvironments,
    isFetched: areEnvFetched,
    data: environmentsData
  } = useGetEnvironments({
    refetchOnMount: true,
    enabled: !environments?.length
  });

  useEffect(() => {
    if (areEnvFetched && environmentsData) {
      setEnvironments(environmentsData);
    }
  }, [areEnvFetched, environmentsData]);

  const checkIsDuplicate = useCallback(
    (name: string) => some(environments, (env) => env.name === name),
    [environment]
  );

  const watchEnvironment = (environmentParam: $TSFixMe) => {
    if (environmentParam?.id) {
      setWatchingEnvsStore([...watchingEnvsStore, environmentParam]);
    }
  };

  const getFilteredWatchingEnvs = ({ watchingEnvsStore, thisEnv = false }: $TSFixMe) =>
    watchingEnvsStore?.filter((env: $TSFixMe) => (thisEnv ? env.id === envId : env.id !== envId));

  const getFilteredWatchingEnvsLaunching = (environments: $TSFixMe) =>
    environments?.filter(
      (env: $TSFixMe) =>
        env?.launchStatus?.trim()?.toLowerCase() === EnvironmentStatuses.Launching.toLowerCase()
    );

  const updateSucceedWatchingEnvsStore = () => {
    clearInterval(watchingEnvsIntervalIdStore);
    setWatchingEnvsStore(getFilteredWatchingEnvs({ watchingEnvsStore }) || []);
  };

  const getEnvironment = async () => {
    const [thisEnvironment] = await getEnvironmentById(envId);
    setEnvironment(thisEnvironment);
    thisEnvironment?.launchStatus?.trim()?.toLowerCase() ===
      EnvironmentStatuses.Active.toLowerCase() && updateSucceedWatchingEnvsStore();
  };

  const customEnvLimit = useMemo(
    () => find(environmentsTypes, (item: any) => item.name === EnvironmentsTypes.Custom),
    [environmentsTypes]
  );

  useEffect(() => {
    let intervalId: $TSFixMe = null;
    if (watchingEnvsIntervalIdStore !== null || isDeleting) {
      clearInterval(watchingEnvsIntervalIdStore);
    }

    if (watchingEnvsStore?.length > 0 && !isDeleting) {
      const filteredWatchingEnvs =
        getFilteredWatchingEnvs({ watchingEnvsStore, thisEnv: true }) || [];

      if (filteredWatchingEnvs?.length > 0) {
        const filteredWatchingEnvsUnSucceed =
          getFilteredWatchingEnvsLaunching(filteredWatchingEnvs) || [];

        if (filteredWatchingEnvsUnSucceed?.length === 0) {
          updateSucceedWatchingEnvsStore();
        } else {
          // $FixMe: Below API can be refactored with better polling approach.
          // Watching for environment changes
          intervalId = setInterval(() => {
            getEnvironment();
          }, 2000);

          setWatchingEnvsIntervalIdStore(intervalId);
        }

        return () => {
          if (intervalId !== null) {
            clearInterval(intervalId);
          }

          if (watchingEnvsIntervalIdStore !== null) {
            clearInterval(watchingEnvsIntervalIdStore);
          }
        };
      }
    }
  }, [watchingEnvsStore, isDeleting]);

  useEffect(() => {
    if (environments?.length > 0) {
      const filteredWatchingEnvsLaunching = getFilteredWatchingEnvsLaunching(environments);

      if (filteredWatchingEnvsLaunching?.length === 0) {
        updateSucceedWatchingEnvsStore();
      } else {
        setWatchingEnvsStore(filteredWatchingEnvsLaunching);
      }
    }
  }, [environments]);

  const handleCloseModal = async () => {
    // @ts-ignore
    if (environment?.projects?.length > 0) {
      toastWrapper({
        type: "error",
        content: `Cannot delete this environment because it is currently used in other project(s) [${environment?.projects?.join(
          ", "
        )}]`
      });

      setShowDeleteScreen(false);

      return;
    }

    await onDeleteEnvironment();

    initEnvironmentConfig();

    navigate(WebPaths.Environments);
  };

  const onDeleteEnvironment = async () => {
    try {
      setIsDeleting(true);
      setIsEnvironmentLoading(true);
      await deleteEnvironment(envId);
      setIsDeleting(false);

      toastWrapper({
        type: "success",
        content: "Environment deleted successfully!"
      });

      setIsLoading(false);
      toggleShouldEnvironmentsRefresh();
    } finally {
      setIsEnvironmentLoading(false);
      setIsDeleting(false);
      setShowDeleteScreen(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteScreen(false);
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteScreen(true);
  };

  const handleRelaunch = async (body: EnvironmentType) => {
    const toastId = envRelaunchNotification(body?.shutdownStrategy?.inactivityInHours);
    setIsEnvironmentLoading(true);

    const thisEnvironment = environment;
    const prevLaunchStatus = thisEnvironment?.launchStatus;
    thisEnvironment.launchStatus = EnvironmentStatuses.Launching;

    setEnvironment(() => thisEnvironment);

    try {
      const thisEnvironment = await relaunchEnvironment(body?.id, true, body);

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!thisEnvironment) {
        if (thisEnvironment?.id) {
          watchEnvironment(thisEnvironment);
        }
      }
    } catch (error) {
      toast.dismiss(toastId);

      console.error({ error });

      thisEnvironment.launchStatus = prevLaunchStatus;
      setEnvironment(() => thisEnvironment);
    } finally {
      setIsEnvironmentLoading(false);
    }
  };

  const stopEnv = async () => {
    try {
      setIsEnvironmentLoading(true);
      await stopEnvironment(environment?.id);
      setToggleRefreshStatus(!toggleRefreshStatus);
    } catch (error) {
      console.error({ error });
    } finally {
      setIsStopping(false);
      setIsEnvironmentLoading(false);
    }
  };
  const nameExist = () => {
    const environmentConfigSession: $TSFixMe = getEnvironmentConfigSession();

    return environments.some((env: EnvironmentType) => {
      return (
        (env as EnvironmentType)?.name?.toLowerCase() ===
          environmentConfigSession?.name?.toLowerCase() && (env as EnvironmentType)?.id !== envId
      );
    });
  };

  const onConfirmStopEnvClose = () => {
    setIsStopping(false);
    setConfirmEnvAction(null);
  };

  const onConfirmStopEnv = async () => {
    setConfirmEnvAction(null);

    await stopEnv();
  };

  const onStopEnv = async () => {
    setIsStopping(true);

    const types = await getEnvironmentUsageDetails(environment?.id);
    if (isEmpty(types)) {
      await stopEnv();
    } else {
      setConfirmEnvAction({ types, action: "stopping" });
    }
  };

  const validateCustomConfig = (
    fieldIndex: number,
    fieldName: string,
    value: string,
    limit: number
  ) => {
    let isValid = true;
    let errorMessage = "";

    if (value === "") {
      errorMessage = `The ${fieldName} cannot be blank.`;
      isValid = false;
    } else {
      const numericValue = _.toNumber(value);

      if (!Number.isInteger(numericValue)) {
        errorMessage = `The ${fieldName} must be an integer.`;
        isValid = false;
      } else if (numericValue <= 0 || numericValue > limit) {
        errorMessage = `The  ${fieldName} needs to be between 1 and ${limit}.`;
        isValid = false;
      }
    }

    setCustomConfigErrors((prev) => {
      prev[fieldIndex] = errorMessage;
      return [...prev];
    });

    return isValid;
  };

  const validFields = () => {
    let isValid = true;

    const environmentConfigSession: $TSFixMe = getEnvironmentConfigSession();

    const { isValid: isNameValid, error } = validateNameField({
      fieldName: environmentConfigSession?.name,
      fieldNameLabel: `environment name`
    });

    if (!isNameValid && error) {
      setShowNameError(error);
      isValid = false;
    } else if (nameExist()) {
      setShowNameError(errors.nameExist);
      isValid = false;
    } else {
      setShowNameError("");
    }

    if (environmentConfigSession?.envType === EnvironmentsTypes.Custom) {
      if (
        !environmentConfigSession?.cores ||
        !environmentConfigSession?.memory ||
        !environmentConfigSession?.diskSpace
      ) {
        isValid = false;
      }
    }

    if (environmentConfigSession?.envType === EnvironmentsTypes.Custom) {
      const validCores = validateCustomConfig(
        0,
        "no of Cores",
        environmentConfigSession?.cores,
        get(customEnvLimit, "cores")
      );

      const validMemory = validateCustomConfig(
        1,
        "Memory value",
        environmentConfigSession?.memory,
        get(customEnvLimit, "memInGbs")
      );
      const validDiskSpace = validateCustomConfig(
        2,
        "Disk Space value",
        environmentConfigSession?.diskSpace,
        get(customEnvLimit, "diskInGbs")
      );

      isValid = validCores && validMemory && validDiskSpace;
    }

    return isValid;
  };

  const handleSave = async () => {
    if ((environmentsTypes || [])?.length === 0) {
      return;
    }

    setIsEnvironmentLoading(true);
    try {
      const environmentConfigSession: $TSFixMe = getEnvironmentConfigSession();

      let valuesToSend: $TSFixMe;
      const typeSelected = (environmentsTypes || []).find(
        (type: $TSFixMe) => type.name === environmentConfigSession?.envType
      );

      if (validFields()) {
        valuesToSend = {
          name: environmentConfigSession?.name?.trim(),
          description: environmentConfigSession?.description?.trim(),
          requirements: environmentConfigSession?.code,
          envType: environmentConfigSession?.envType
          // diskInGbs is unchangeable. Hence its omitted in the payload.
        };

        if (environmentConfigSession?.envType === EnvironmentsTypes.Custom) {
          valuesToSend.cores = environmentConfigSession?.cores;
          valuesToSend.memInMbs = environmentConfigSession?.memory * 1024;
        } else {
          valuesToSend.cores = typeSelected?.cores;
          valuesToSend.memInMbs = typeSelected?.memInMbs;
        }

        setIsLoading(true);
        const environmentResponse = await updateEnvironment(valuesToSend, envId);
        toastWrapper({
          type: "success",
          content: "Environment updated successfully!"
        });

        environmentResponse?.launchStatus === EnvironmentStatuses.Launching &&
          setWatchingEnvsStore([...watchingEnvsStore, environmentResponse]);
      }
    } catch (error) {
      console.error({ error });
    } finally {
      fetchEnvironmentData();
      setIsLoading(false);
      setIsEnvironmentLoading(false);
      setIsSaving(false);
    }
  };

  const onConfirmSaveEnvClose = () => {
    setIsSaving(false);
    setConfirmEnvAction(null);
  };

  const onConfirmSaveEnv = async () => {
    setConfirmEnvAction(null);

    await handleSave();
  };

  const onSaveEnv = async () => {
    setIsSaving(true);
    const types = await getEnvironmentUsageDetails(environment?.id);
    if (isEmpty(types)) {
      await handleSave();
    } else {
      setConfirmEnvAction({ types, action: "modifying" });
    }
  };

  const isSaveDisabled = useMemo(() => {
    let isDisabled = false;

    isDisabled = isDisabled || !!isLoading;
    isDisabled = isDisabled || !!isSaving;
    isDisabled = isDisabled || !!isEnvironmentsTypesFetching;
    isDisabled = isDisabled || !validFields();

    return isDisabled;
  }, [isLoading, isSaving, isEnvironmentsTypesFetching, timer]);

  const fetchEnvironmentData = async () => {
    try {
      setIsLoading(true);
      setIsEnvironmentLoading(true);
      const [environment] = await getEnvironmentById(envId);
      setEnvironment(environment);

      const thisValues: $TSFixMe = {
        ...getEnvironmentConfigSession(),
        name: environment.name,
        description: environment.description,
        code: environment.requirements,
        envType: environment.envType || environment.customEnvType
      };

      thisValues.cores = _.toNumber(environment.cores);
      thisValues.memory = (environment.memInMbs / 1024).toFixed();
      thisValues.diskSpace = _.toNumber(environment.diskInGbs);

      setEnvironmentConfigSession(thisValues);

      setIsLoading(false);

      setTimer(() => new Date().getTime());
    } catch (e: any) {
      console.error({ error: e?.error });
      setIsLoading(false);
    } finally {
      setIsEnvironmentLoading(false);
    }
  };

  useEffect(() => {
    fetchEnvironmentData();
  }, [envId, toggleRefreshStatus]);

  const {
    data: environmentUsageData,
    isLoading: isLoadingEnvUsage,
    isFetched
  } = useGetEnvironmentUsage(envId!);

  useEffect(() => {
    if (isFetched && (environmentUsageData || [])?.length > 0) {
      setIsLoadingPredictionServices(() => true);
      const thisProjects: Record<string, { jobsCount: number; predictionJobsCount: number }> = {};
      const allPredictionServices: IPredictionService[] = [];

      environmentUsageData?.forEach((thisEnv: $TSFixMe) => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!thisEnv?.projectName) {
          const predictionJobsCount = size(
            filter(thisEnv?.projectRunList, ({ jobType }: any) => jobType === "PREDICTION_JOB")
          );
          const jobsCount = (thisEnv?.projectRunList?.length ?? 0) - predictionJobsCount;

          if (has(thisProjects, thisEnv?.projectName)) {
            const old = get(thisProjects, [thisEnv?.projectName, "jobsCount"]) as number;
            thisProjects[thisEnv?.projectName] = {
              jobsCount: Math.max(old, jobsCount),
              predictionJobsCount: Math.max(
                get(thisProjects, [thisEnv?.projectName, "predictionJobsCount"]) as number,
                predictionJobsCount
              )
            };
          } else {
            thisProjects[thisEnv?.projectName] = { jobsCount, predictionJobsCount };
          }
        }
        if (thisEnv.pdId) {
          const { pdCreated, pdCreator, pdId, pdName, pdUpdated, pdUpdater, pdModelName } = thisEnv;
          allPredictionServices.push({
            pdModelName,
            id: pdId,
            pdCreated,
            pdCreator,
            pdId,
            pdName,
            pdUpdated,
            pdUpdater
          });
        }
      });

      setProjects(() => thisProjects);
      setPredictionServices(orderBy(allPredictionServices, "pdCreated", "desc"));
      setIsLoadingPredictionServices(() => false);
    }
  }, [isFetched, environmentUsageData]);

  const isLaunching = useMemo(
    () => environment?.launchStatus === EnvironmentStatuses.Launching,
    [environment?.launchStatus]
  );

  const isUsageDisabled = useMemo(
    () => environment?.launchStatus !== "SUCCESS" || isStopping,
    [environment?.launchStatus, isStopping]
  );

  const handleClose = () => {
    if (confirmEnvAction?.action === "stopping") {
      onConfirmStopEnvClose();
    } else {
      onConfirmSaveEnvClose();
    }
  };

  const handleSubmit = () => {
    if (confirmEnvAction?.action === "stopping") {
      onConfirmStopEnv();
    } else {
      onConfirmSaveEnv();
    }
  };

  return (
    <>
      <EnvironmentConfig
        isOpen={isEnvUsageOpen}
        environment={environment}
        stoppingEnvironments={isStopping ? [environment?.id] : []}
        onUpdate={getEnvironment}
        onClose={() => setIsEnvUsageOpen(() => false)}
      />

      <EnvironmentLogsDrawer
        open={logsOpen}
        environment={environment}
        onClose={() => {
          setLogsOpen(false);
          setSearchParams({});
        }}
      />

      {showDeleteScreen && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Delete Environment"
          content={[EnvironmentDeleteMessage.messageLine1, EnvironmentDeleteMessage.messageLine2]}
          isSubmitting={isDeleting}
          onClose={handleCancelDelete}
          onSubmit={handleCloseModal}
        />
      )}

      {confirmEnvAction && (
        <Modal
          open={true}
          variant={ModalVariants.Delete}
          title="Are you sure?"
          content={[
            `Please note that there are active processes within this environment, such as ${
              !!confirmEnvAction ? createString(confirmEnvAction.types) : ""
            }. Continuing with ${
              !!confirmEnvAction ? confirmEnvAction?.action : ""
            } the environment may affect these ongoing operations`
          ]}
          isSubmitting={isDeleting}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}

      <Grid container direction="column" alignItems="stretch" className={classes.root}>
        {isLoading || isLoadingEnvironments ? (
          <Spinner />
        ) : (
          <>
            <EnvironmentHeader
              isLoading={isLoading}
              checkIsDuplicate={checkIsDuplicate}
              environment={environment}
              isLaunching={isLaunching}
              isEnvironmentsTypesFetching={isEnvironmentsTypesFetching}
              isStopping={isStopping}
              isSaving={isSaving}
              isSaveActionDisabled={isSaveDisabled}
              isUsageDisabled={isUsageDisabled}
              onRelaunch={handleRelaunch}
              onStopEnvironmentAction={onStopEnv}
              onSaveAction={onSaveEnv}
              onConfigAction={() => setIsEnvUsageOpen(() => true)}
              onLogsAction={openLogs}
              onDelete={handleOpenDeleteModal}
              readOnly={environment?.defaultFlag && environment?.name === DEFAULT_NAME}
              watchEnvironment={watchEnvironment}
              fetchEnvironmentData={fetchEnvironmentData}
            />

            <Grid item xs={12} className={`${classes.container} container-height`}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <EnvironmentInputs
                    isEnvironmentLoading={isEnvironmentLoading}
                    isEnvironmentsTypesFetching={isEnvironmentsTypesFetching}
                    environmentsTypes={environmentsTypes || []}
                    setTimer={setTimer}
                    customConfigErrors={customConfigErrors}
                  />
                </Grid>
                <Grid item xs={9}>
                  <EnvironmentTabs
                    envProjects={projects}
                    isLoadingPredictionServices={isLoadingPredictionServices || isLoadingEnvUsage}
                    predictionServices={predictionServices}
                    isDefault={environment?.defaultFlag && environment?.name === DEFAULT_NAME}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

Environment.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default Environment;
