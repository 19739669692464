import { useMutation } from "@tanstack/react-query";

import { postAPIWithRethrow } from "src/utils/apiService";

export const useLicenseTestMutation = () => {
  return useMutation({
    mutationFn: ({ licenseKey }: { licenseKey: string }) => {
      return postAPIWithRethrow(`/license/test`, { licenseKey }, {}, false);
    }
  });
};
