export enum CUSTOM_PARAMETERS {
  predictPeriod = "predictPeriod",
  predictFrequency = "predictFrequency",
  timeSpan = "timeSpan"
}

export const FREQUENCY_OPTS = [
  { value: "D", label: "D (calendar day frequency)" },
  { value: "W", label: "W (weekly frequency)" },
  { value: "M", label: "M (month end frequency)" },
  { value: "Q", label: "Q (quarter end frequency)" },
  { value: "A", label: "A (year end frequency)" },
  { value: "H", label: "H (hourly frequency)" },
  { value: "T", label: "T (minutely frequency)" },
  { value: "S", label: "S (secondly frequency)" }
  // { value: "D", label: "D (Day)" },
  // { value: "B", label: "B (Business day)" },
  // { value: "H", label: "H (Hour)" },
  // { value: "T", label: "T or min (Minute)" },
  // { value: "S", label: "S (Second)" },
  // { value: "L", label: "L or ms (Millisecond)" },
  // { value: "U", label: "U (Microsecond)" },
  // { value: "N", label: "N (Nanosecond)" },
  // { value: "Q", label: "Q (Quarter end)" },
  // { value: "A", label: "A or Y (Year end)" },
  // { value: "BA", label: "BA (Business year end)" },
  // { value: "AS", label: "AS or BYS (Year start)" },
  // { value: "BAS", label: "BAS (Business year start)" },
  // { value: "BM", label: "BM (Business month end)" },
  // { value: "BMS", label: "BMS (Business month start)" },
  // { value: "SM", label: "SM (Semi-month start (on the 1st and 15th))" },
  // { value: "SMS", label: "SMS (Semi-month start (on the 1st and 15th))" },
  // { value: "BH", label: "BH (Business hour)" }
];
