import { map } from "lodash";
import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import api from "utils/AxiosClient";

const QUERY_KEY_ALL_MODELS = "query-key-all-models";

export interface Model {
  id: string;
  name: string;
  displayName: string;
  parentRecipeId: string;
  parentRecipeName: string;
  parentRecipeDisplayName: string;
  targetCol: string;
}

const useGetAllModels = (
  projectId?: string,
  options?: UseQueryOptions<Model[]>
): UseQueryResult<Model[]> => {
  return useQuery<any>({
    queryKey: [QUERY_KEY_ALL_MODELS, projectId],
    queryFn: async () => {
      if (!projectId) {
        return "projectId is absent";
      }

      const modelDetails = await api.fetchResponse(
        async () => await api.ProjectsControllerV2Api.getAllModelsWithInputDataSets(projectId)
      );

      return map(
        modelDetails,
        ({
          id,
          name,
          displayName,
          recipeVariables,
          parentRecipeName,
          parentRecipeId,
          parentRecipeDisplayName
        }) => ({
          id,
          name,
          displayName,
          parentRecipeName,
          parentRecipeDisplayName,
          parentRecipeId,
          targetCol: recipeVariables?.targetCol
        })
      );
    },
    ...options,
    refetchOnMount: true
  });
};

export default useGetAllModels;
export { QUERY_KEY_ALL_MODELS };
