import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AddOutputRequestDto } from "openapi/Models/add-output-request-dto";
import api from "utils/AxiosClient";

import { AI_GUIDE_MESSAGES_KEY } from "../aiguide";
import { AIChatResponseDto } from "openapi/Models/aichat-response-dto";

export const useAddMessageToOutputMutation = (threadId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      request,
      messageId
    }: {
      request: AddOutputRequestDto;
      messageId: string;
    }) => {
      const response = await api.fetchResponse(() =>
        api.AiControllerApi.addOutput(threadId, messageId, request)
      );
      queryClient.setQueryData(
        [AI_GUIDE_MESSAGES_KEY, threadId!],
        (prevData: AIChatResponseDto[] | undefined) => [
          ...(prevData || []).map((message) =>
            message.askAIMessageId === messageId ? response : message
          )
        ]
      );
      return response;
    }
  });
};
