import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    // New UX change
    // The value 94px is the height of both the NavBars (TopNavBar 50px + SubTopNavBar 44px).
    height: "calc(100vh - 94px)",
    padding: theme.spacing(2)
  },
  container: {
    height: "100%",
    "& .leftContainer": {
      height: "inherit",
      paddingRight: theme.spacing(1),
      "&.closed": {
        width: theme.spacing(8)
      }
      // transition: "ease-in-out width 1s"
    },
    "& .rightContainer": {
      height: "inherit",
      paddingLeft: theme.spacing(1),
      "&.closed": {
        // 2 (page padding on left-side)
        // + 8 (right and left padding of the left and right containers respectively)
        // + 2 (page padding on right-side)
        // Total = 12
        width: `calc(100vw - ${theme.spacing(12)}px)`
      }
    }
  }
}));

export default useStyles;
