import React, { useMemo } from "react";
import clsx from "clsx";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useLocation } from "react-router";

import { Entities, EntityFeaturesResponse } from "src/types";
import { RecipeWithNoDatasetsIcon } from "src/icons/RecipeWithNoDatasetsIcon";
import InputDataset from "./InputDataset";
import { orderBy, toLower } from "lodash";

type Props = {
  inputDatasets: Entities;
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  onDeleteDataset: (datasetId: string) => void;
  recipeId?: string | null | undefined;
  isDefaultScenario: boolean;
};

export const useStyles = makeStyles({
  inputWrap: {
    flexWrap: "nowrap"
  },
  datasetsTitle: {
    color: "#000000",
    opacity: "0.5",
    fontSize: "12px",
    lineHeight: "16px",
    padding: "8px 16px"
  },

  accordionWrap: {
    maxHeight: "100%",
    borderTop: ({ hideBorder }: { hideBorder: boolean }) =>
      hideBorder ? "none" : "0.5px solid #D1D1D1",
    borderBottom: "none",
    cursor: "pointer",
    borderStyle: "solid none"
  },
  accordion: {
    "&:last-child": {
      borderRadius: "0px 0px 12px 12px"
    }
  },
  entityText: {
    fontWeight: 400,
    maxWidth: "80%",
    wordWrap: "break-word"
  },
  entityLeftContent: {
    gap: "8px",
    alignItems: "center"
  },
  noInputDatasets: {
    minHeight: "48px",
    padding: "0px 16px",
    background: "#f5f5f5",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px"
  }
});

export const InputDatasetsView = ({
  recipeId,
  inputDatasets,
  entityFeaturesMap,
  onDeleteDataset,
  isDefaultScenario
}: Props) => {
  const location = useLocation();
  const isJobPath = useMemo(() => /jobs/.test(location.pathname), [location.pathname]);

  const classes = useStyles({ hideBorder: inputDatasets?.length === 0 });

  return (
    <Grid container direction="column" className={classes.inputWrap}>
      <Typography
        variant="subtitle2"
        className={classes.datasetsTitle}
        data-testid="datasetCountText">
        DATASETS ({inputDatasets?.length || 0})
      </Typography>
      {inputDatasets?.length === 0 && recipeId ? (
        <Grid
          container
          direction="row"
          className={clsx([classes.entityLeftContent, classes.noInputDatasets])}>
          <RecipeWithNoDatasetsIcon />
          <Typography
            variant="subtitle2"
            className={classes.entityText}
            data-testid="code-recipe-no-input-dataset-text">
            No Input Dataset
          </Typography>
        </Grid>
      ) : (
        <Grid className={classes.accordionWrap}>
          {orderBy(inputDatasets, (item) => toLower(item.displayName))?.map((dataset: any) => {
            return (
              <InputDataset
                key={dataset.id}
                isDefaultScenario={isDefaultScenario}
                isJobPath={isJobPath}
                dataset={dataset}
                entityFeaturesMap={entityFeaturesMap}
                onDelete={onDeleteDataset}
              />
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(InputDatasetsView);
