import { useMutation, UseMutationResult } from "@tanstack/react-query";

import { EXPORT_SUCCESS_MESG } from "pages/private/ProjectsModule/pages/OutputDataset/utils/OutputDataset.constants";
import { handleResponse, postAPIWithRethrow } from "src/utils/apiService";

interface IVariables {
  id: string;
  scenarioId: string;
}

const useExportEntityConnectors = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  return useMutation({
    mutationFn: async ({ id, scenarioId }) => {
      const results = await postAPIWithRethrow(`/v2/entities/${id}/sync`, {
        id,
        scenarioId
      });

      return results;
    },
    onSuccess: () => {
      handleResponse({ successMessage: EXPORT_SUCCESS_MESG });
    }
  });
};

export default useExportEntityConnectors;
