import React from "react";

export const EntityIcon = ({ width = "16px", height = "16px", fill = "#003656" } = {}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        version="1.1">
        <path
          fill={fill}
          d="M 8 2 C 5.054688 2 2.667969 3.191406 2.667969 4.667969 C 2.667969 6.140625 5.054688 7.332031 8 7.332031 C 10.945312 7.332031 13.332031 6.140625 13.332031 4.667969 C 13.332031 3.191406 10.945312 2 8 2 Z M 2.667969 6 L 2.667969 8 C 2.667969 9.472656 5.054688 10.667969 8 10.667969 C 10.945312 10.667969 13.332031 9.472656 13.332031 8 L 13.332031 6 C 13.332031 7.472656 10.945312 8.667969 8 8.667969 C 5.054688 8.667969 2.667969 7.472656 2.667969 6 Z M 2.667969 9.332031 L 2.667969 11.332031 C 2.667969 12.808594 5.054688 14 8 14 C 10.945312 14 13.332031 12.808594 13.332031 11.332031 L 13.332031 9.332031 C 13.332031 10.808594 10.945312 12 8 12 C 5.054688 12 2.667969 10.808594 2.667969 9.332031 Z M 2.667969 9.332031 "
        />
      </svg>
    </>
  );
};
