import React from "react";

export const UserIcon = ({
  width = 22,
  height = 22,
  viewBox = "",
  fill = "none",
  color = "currentColor",
  opacity = 1
}) => (
  <>
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : `0 0 ${width - 2} ${height - 2}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={opacity}>
        <rect x="0.77002" y="0.5" width="19" height="19" rx="9.5" fill={fill} />
        <rect x="0.77002" y="0.5" width="19" height="19" rx="9.5" stroke={color} />
        <g clipPath="url(#clip0_14337_7867)">
          <path
            d="M10.28 10.9004C11.8363 10.9004 13.0925 9.64414 13.0925 8.08789C13.0925 6.53164 11.8363 5.27539 10.28 5.27539C8.72378 5.27539 7.46753 6.53164 7.46753 8.08789C7.46753 9.64414 8.72378 10.9004 10.28 10.9004ZM10.28 6.11914C11.3675 6.11914 12.2488 7.00039 12.2488 8.08789C12.2488 9.17539 11.3675 10.0566 10.28 10.0566C9.19253 10.0566 8.31128 9.17539 8.31128 8.08789C8.31128 7.00039 9.19253 6.11914 10.28 6.11914Z"
            fill={color}
          />
          <path
            d="M15.8114 13.975C14.2739 12.6813 12.3239 11.9688 10.2801 11.9688C8.23635 11.9688 6.28635 12.6813 4.74885 13.975C4.5801 14.125 4.5426 14.3875 4.6926 14.575C4.8426 14.7438 5.1051 14.7813 5.2926 14.6313C6.6801 13.45 8.4426 12.8125 10.2801 12.8125C12.1176 12.8125 13.8801 13.45 15.2489 14.6125C15.3239 14.6875 15.4176 14.7062 15.5301 14.7062C15.6426 14.7062 15.7739 14.65 15.8489 14.5562C15.9989 14.3875 15.9801 14.125 15.8114 13.975Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  </>
);
