import _ from "lodash";
import { UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";

import { putAPIWithRethrow } from "src/utils/apiService";
import { QUERY_KEY_NOTIFICATIONS } from "./useGetNotifications";

const useReadAllNotifications = (): UseMutationResult<unknown, unknown, {}, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const data = await putAPIWithRethrow(`/v2/env-alerts/read/all`, {});
      return data;
    },
    onSuccess: () => {
      queryClient.setQueryData([QUERY_KEY_NOTIFICATIONS], (prevData: any) =>
        _.map(prevData, (notification) => ({ ...notification, read: true }))
      );
    }
  });
};

export default useReadAllNotifications;
