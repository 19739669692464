import React from "react";
import isEqual from "lodash/isEqual";
import { Alert } from "@material-ui/lab";
import { Grid, Button, makeStyles, Box, Tooltip } from "@material-ui/core";

import { Spinner } from "src/components";
import ConditionBuilder from "./ConditionBuilder";
import { useBuildRecipeConditions } from "./useBuildRecipeConditions";
import { Recipe } from "src/types";
import { useGetGlobalVariables } from "src/hooks/api";
import { READONLY_ENTITY } from "src/constants";
import NewThemeWrapper from "src/styles/NewThemeWrapper";

const useStyles = makeStyles({
  conditions: {
    flexWrap: "nowrap",
    width: "700px",
    height: "100%"
  },
  btnList: {
    height: "70px",
    backgroundColor: "#F5F5F5",
    padding: "20px",
    alignItems: "center",
    gap: "20px"
  }
});

type Props = {
  isJobPath?: boolean;
  jobId?: string;
  scenarioId?: string;
  jobRunId?: string;
  recipe: Recipe;
  projectId: string;
  onClose: () => void;
  conditions: $TSFixMe;
  setConditions: $TSFixMeFunction;
  onSubmitSuccess: () => void;
  isDefaultScenario: boolean;
};

export const RecipeConditions = ({
  isJobPath,
  jobId,
  scenarioId,
  jobRunId,
  recipe,
  onClose,
  onSubmitSuccess,
  conditions,
  projectId,
  setConditions,
  isDefaultScenario
}: Props) => {
  const classes = useStyles();
  const {
    isSubmitting,
    rules,
    errors,
    onChangeRules,
    handleSubmit,
    rulesFormat,
    hasDuplicatedConditions,
    hasInvalidValues
  } = useBuildRecipeConditions({
    onSubmitSuccess,
    conditions,
    setConditions
  });

  const {
    isLoading,
    isFetching,
    data: globalVariables
  } = useGetGlobalVariables({
    projectId,
    ...(!!isJobPath ? { scenarioId, jobId, jobRunId } : {})
  });

  return (
    <NewThemeWrapper>
      <Grid container direction="column" className={classes.conditions}>
        {isLoading || isFetching ? (
          <Spinner />
        ) : isJobPath ? (
          <ConditionBuilder
            isJobPath={isJobPath}
            globalVariables={globalVariables}
            onChangeRules={onChangeRules}
            initialRules={rules}
            error={errors}
            readonly={!isDefaultScenario}
          />
        ) : (
          <>
            <ConditionBuilder
              readonly={!isDefaultScenario}
              globalVariables={globalVariables}
              onChangeRules={onChangeRules}
              initialRules={rules}
              error={errors}
            />
            {hasDuplicatedConditions && (
              <Box p="24px 16px">
                <Alert severity="error">
                  Duplicate Conditions Found. Update Conditions to enable Add.
                </Alert>
              </Box>
            )}
            <Grid container direction="row-reverse" className={classes.btnList}>
              {globalVariables?.length !== 0 && (
                <Tooltip title={!isDefaultScenario ? READONLY_ENTITY : ""}>
                  <div>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={
                        !isDefaultScenario ||
                        isSubmitting ||
                        isEqual(recipe?.condition?.expression, rulesFormat) ||
                        hasDuplicatedConditions ||
                        hasInvalidValues
                      }
                      test-id="code-recipe-save-recipe-conditions-btn">
                      {isSubmitting ? <Spinner size={24} noPadding /> : "Save"}
                    </Button>
                  </div>
                </Tooltip>
              )}
              <Button
                variant="outlined"
                color="primary"
                onClick={onClose}
                test-id="code-recipe-close-conditions-btn">
                Cancel
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </NewThemeWrapper>
  );
};
