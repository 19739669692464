/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { FieldInfo } from './field-info';

/**
 * 
 * @export
 * @interface DataSourceSchemaDto
 */
export interface DataSourceSchemaDto {
    /**
     * 
     * @type {string}
     * @memberof DataSourceSchemaDto
     */
    'type'?: DataSourceSchemaDtoTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DataSourceSchemaDto
     */
    'fileBased'?: boolean;
    /**
     * 
     * @type {Array<FieldInfo>}
     * @memberof DataSourceSchemaDto
     */
    'dataSourceFields'?: Array<FieldInfo>;
    /**
     * 
     * @type {Array<FieldInfo>}
     * @memberof DataSourceSchemaDto
     */
    'datasetFields'?: Array<FieldInfo>;
    /**
     * 
     * @type {Array<FieldInfo>}
     * @memberof DataSourceSchemaDto
     */
    'syncFields'?: Array<FieldInfo>;
}

export const DataSourceSchemaDtoTypeEnum = {
    GcpStorage: 'GCP_STORAGE',
    S3Storage: 'S3_STORAGE',
    AzureBlob: 'AZURE_BLOB',
    Mysql: 'MYSQL',
    Mongo: 'MONGO',
    Snowflake: 'SNOWFLAKE',
    Redshift: 'REDSHIFT',
    RedisStorage: 'REDIS_STORAGE',
    ThirdParty: 'THIRD_PARTY'
} as const;

export type DataSourceSchemaDtoTypeEnum = typeof DataSourceSchemaDtoTypeEnum[keyof typeof DataSourceSchemaDtoTypeEnum];


