import React from "react";
import { Grid, Button, Tooltip, PropTypes } from "@material-ui/core";
import { TitleSection } from "../";

interface IProps {
  gridContainerStyle?: string;
  gridItemStyle?: string;
  gridIconStyle?: string;
  button?: {
    label?: string | React.JSX.Element;
    color?: PropTypes.Color;
    tooltip?: string;
    disabled?: boolean;
    dataTestId?: string;
    size?: "small" | "large" | "medium" | undefined;
    onClick: () => void;
  };
  titleSection: {
    subtitleLines?: $TSFixMe[];
    title?: string;
  };
  icon?: React.ReactNode | React.ReactNode[];
  buttonComponent?: React.JSX.Element;
  titleSectionStyle?: React.CSSProperties;
}

const SplashSection: React.FC<IProps> = (props) => {
  const {
    gridContainerStyle,
    gridItemStyle,
    gridIconStyle,
    titleSectionStyle,
    button,
    buttonComponent,
    titleSection: { title, subtitleLines, ...restTitleSection },
    icon
  } = props;

  return (
    <Grid container className={gridContainerStyle} data-testid={"splashGridWrap"}>
      {icon && (
        <Grid item className={gridIconStyle} data-testid={"splashGridIcon"}>
          {icon}
        </Grid>
      )}
      <Grid item className={gridItemStyle}>
        <TitleSection
          title={title}
          subtitleLines={subtitleLines}
          style={titleSectionStyle}
          {...restTitleSection}
          shouldUseGutterBottom={false}
        />
        {buttonComponent
          ? buttonComponent
          : button && (
              <Tooltip title={button?.tooltip || ""}>
                <span>
                  <Button
                    color={button.color || "secondary"}
                    variant="contained"
                    data-testid={button.dataTestId}
                    size={button.size || "large"}
                    disabled={button?.disabled}
                    onClick={button.onClick}>
                    {button.label}
                  </Button>
                </span>
              </Tooltip>
            )}
      </Grid>
    </Grid>
  );
};

SplashSection.defaultProps = {
  gridContainerStyle: "",
  gridItemStyle: "",
  gridIconStyle: "",
  titleSectionStyle: { marginBottom: 25 },
  icon: null
};

export default SplashSection;
