import { QUERY_KEY_MODELS } from "src/hooks/api";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";

import { handleResponse, postAPIWithRethrow } from "src/utils/apiService";

interface IVariables {
  name: string;
}

const useInvalidateCache = (): UseMutationResult<unknown, unknown, IVariables, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ name }) => {
      return await postAPIWithRethrow(`/v2/predict/${name}/invalidate-cache`, {});
    },
    onSuccess: async (__, { name }) => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEY_MODELS], refetchType: "all" });
      handleResponse({ successMessage: `Prediction Service ${name} refreshed successfully` });
    }
  });
};

export default useInvalidateCache;
