export enum AuthSessionEnum {
  TokenSessionKey = "token"
}

export const AuthSessionConfig = {
  refreshTokenInterval: 25 * 60 * 1000 // 25 mins.
};

export const PathRexExps = {
  auth: /auth/,
  tenant: /tenant-choice/,
  projects: /\/projects/,
  canvas: /canvas/,
  dataAppDeepLink: /\/apps/
};

export enum MenuItemTypes {
  Notebook = "NOTEBOOK",
  Support = "SUPPORT"
}

export enum NotebookTypes {
  Both = "BOTH",
  User = "USER",
  Tenant = "TENANT",
  LaunchNotebook = "Notebook",
  LaunchNotebookUser = "Notebook (User)",
  LaunchNotebookTenant = "Notebook (Tenant)"
}

export enum MuiBreakpoints {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl"
}

export enum PublicScreenLabels {
  SignIn = "Sign In",
  SignUp = "Sign Up",
  Email = "Email",
  Password = "Password",
  ConfirmPassword = "Confirm Password",
  JoinTenant = "Join Tenant",
  SignInLoading = '"Signing in...'
}

export enum ResetPasswordPathStatuses {
  Send,
  Sent
}

export enum ReleaseNotesConfig {
  CurrentReleaseName = "current_release_notes"
}

export enum listViewPages {
  PROJECTS = "projects",
  CONNECTORS = "connectors",
  DATAAPPS = "dataapps",
  ENVIRONMENT = "environments"
}

export const PagesViewConfiguration = "pagesViewConfiguration";
