/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { EntityData } from './entity-data';
// May contain unused imports in some cases
// @ts-ignore
import type { EntityMetaDto } from './entity-meta-dto';
// May contain unused imports in some cases
// @ts-ignore
import type { PipelineSegmentDto } from './pipeline-segment-dto';

/**
 * 
 * @export
 * @interface PipelineEntityDto
 */
export interface PipelineEntityDto {
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'markdown'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'mergedDataFile'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PipelineEntityDto
     */
    'metadata'?: { [key: string]: string; };
    /**
     * 
     * @type {EntityMetaDto}
     * @memberof PipelineEntityDto
     */
    'entityMeta'?: EntityMetaDto;
    /**
     * 
     * @type {boolean}
     * @memberof PipelineEntityDto
     */
    'rootEntity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PipelineEntityDto
     */
    'orphanEntity'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'type'?: PipelineEntityDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PipelineEntityDto
     */
    'positionX'?: number;
    /**
     * 
     * @type {number}
     * @memberof PipelineEntityDto
     */
    'positionY'?: number;
    /**
     * 
     * @type {EntityData}
     * @memberof PipelineEntityDto
     */
    'entityData'?: EntityData;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'dataSourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'dataSourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'dataSourceType'?: PipelineEntityDtoDataSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'tpConnectorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'entityMetaString'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'executedCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'dataSourceOptions'?: string;
    /**
     * 
     * @type {Array<PipelineSegmentDto>}
     * @memberof PipelineEntityDto
     */
    'segmentDtos'?: Array<PipelineSegmentDto>;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'image'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PipelineEntityDto
     */
    'customSeparatorOrEncoding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PipelineEntityDto
     */
    'modelVersion'?: string;
}

export const PipelineEntityDtoTypeEnum = {
    Entity: 'ENTITY',
    IntermediateEntity: 'INTERMEDIATE_ENTITY',
    Dfsgroup: 'DFSGROUP',
    Chart: 'CHART',
    Model: 'MODEL',
    Artifact: 'ARTIFACT'
} as const;

export type PipelineEntityDtoTypeEnum = typeof PipelineEntityDtoTypeEnum[keyof typeof PipelineEntityDtoTypeEnum];
export const PipelineEntityDtoDataSourceTypeEnum = {
    GcpStorage: 'GCP_STORAGE',
    S3Storage: 'S3_STORAGE',
    AzureBlob: 'AZURE_BLOB',
    Mysql: 'MYSQL',
    Mongo: 'MONGO',
    Snowflake: 'SNOWFLAKE',
    Redshift: 'REDSHIFT',
    RedisStorage: 'REDIS_STORAGE',
    ThirdParty: 'THIRD_PARTY'
} as const;

export type PipelineEntityDtoDataSourceTypeEnum = typeof PipelineEntityDtoDataSourceTypeEnum[keyof typeof PipelineEntityDtoDataSourceTypeEnum];


