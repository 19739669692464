import React from "react";

const LowDiskIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14552_7962)">
        <path
          d="M6.75 16.5C6.94891 16.5 7.13968 16.421 7.28033 16.2803C7.42098 16.1397 7.5 15.9489 7.5 15.75C7.5 15.5511 7.42098 15.3603 7.28033 15.2197C7.13968 15.079 6.94891 15 6.75 15C6.55109 15 6.36032 15.079 6.21967 15.2197C6.07902 15.3603 6 15.5511 6 15.75C6 15.9489 6.07902 16.1397 6.21967 16.2803C6.36032 16.421 6.55109 16.5 6.75 16.5ZM4.5 15.75C4.5 15.9489 4.42098 16.1397 4.28033 16.2803C4.13968 16.421 3.94891 16.5 3.75 16.5C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75C3 15.5511 3.07902 15.3603 3.21967 15.2197C3.36032 15.079 3.55109 15 3.75 15C3.94891 15 4.13968 15.079 4.28033 15.2197C4.42098 15.3603 4.5 15.5511 4.5 15.75Z"
          fill="#003656"
        />
        <path
          d="M24 16.5C24 17.2956 23.6839 18.0587 23.1213 18.6213C22.5587 19.1839 21.7956 19.5 21 19.5H3C2.20435 19.5 1.44129 19.1839 0.87868 18.6213C0.316071 18.0587 0 17.2956 0 16.5V14.265C0 13.638 0.1575 13.02 0.4575 12.4695L4.1655 5.673C4.35902 5.31803 4.64461 5.02176 4.99225 4.81535C5.33988 4.60893 5.7367 4.5 6.141 4.5H17.859C18.2633 4.5 18.6601 4.60893 19.0078 4.81535C19.3554 5.02176 19.641 5.31803 19.8345 5.673L23.5425 12.468C23.8425 13.02 24 13.638 24 14.265V16.5ZM5.4825 6.39L2.388 12.0645C2.586 12.021 2.79 12 3 12H21C21.21 12 21.414 12.021 21.612 12.063L18.5175 6.39C18.4529 6.27186 18.3576 6.17331 18.2417 6.10469C18.1259 6.03606 17.9937 5.9999 17.859 6H6.141C6.00608 5.99963 5.87356 6.03566 5.75741 6.1043C5.64125 6.17294 5.54726 6.27164 5.4825 6.39ZM1.5 15V16.5C1.5 16.8978 1.65804 17.2794 1.93934 17.5607C2.22064 17.842 2.60218 18 3 18H21C21.3978 18 21.7794 17.842 22.0607 17.5607C22.342 17.2794 22.5 16.8978 22.5 16.5V15C22.5 14.6022 22.342 14.2206 22.0607 13.9393C21.7794 13.658 21.3978 13.5 21 13.5H3C2.60218 13.5 2.22064 13.658 1.93934 13.9393C1.65804 14.2206 1.5 14.6022 1.5 15Z"
          fill="#003656"
        />
        <g clipPath="url(#clip1_14552_7962)">
          <path
            d="M11 14.4999L10.5 14.4999L10.5 14.9999L10.5 18.2928L9.60351 17.3964L9.24996 17.0428L8.89641 17.3964L7.68641 18.6064L7.33285 18.9599L7.68641 19.3135L11.6464 23.2735L12 23.627L12.3535 23.2735L16.3135 19.3135L16.6671 18.9599L16.3135 18.6064L15.1035 17.3964L14.75 17.0428L14.3964 17.3964L13.5 18.2928L13.5 14.9999L13.5 14.4999L13 14.4999L11 14.4999Z"
            fill="#FF0000"
            stroke="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_14552_7962">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_14552_7962">
          <rect width="12" height="12" fill="white" transform="translate(18 25) rotate(180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LowDiskIcon;
