import React from "react";

export const Run = (props: $TSFixMe) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 17.2942V6.70578C6 4.84059 6 3.90799 6.39081 3.37846C6.73153 2.91681 7.25412 2.62372 7.82571 2.57372C8.48133 2.51637 9.2771 3.00267 10.8686 3.97528L19.5319 9.2695C21.0092 10.1723 21.7478 10.6237 21.9997 11.2048C22.2195 11.7121 22.2195 12.2879 21.9997 12.7952C21.7478 13.3763 21.0092 13.8277 19.5319 14.7305L10.8686 20.0247C9.2771 20.9973 8.48133 21.4836 7.82571 21.4263C7.25412 21.3763 6.73153 21.0832 6.39081 20.6215C6 20.092 6 19.1594 6 17.2942Z"
      stroke="#00102D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    />
  </svg>
);
