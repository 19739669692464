import React from "react";

export const RcLogoIcon = ({ width = 24, height = 24, color = "#ff6550" }) => (
  <>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      height={height}
      // @ts-ignore
      style={{ enableBackground: "new 0 0 300 300" }}
      xmlSpace="preserve"
      viewBox="44 44 212 212">
      <path
        style={{ fill: color }}
        d="M198.5,69.8c-32.2,3.2-49.7,21.3-62.3,40.3l-7.6,11.5L94.7,90.4l15.9-17.3l14,12.9 		c14.7-18.6,36.5-36.1,71.5-39.6L198.5,69.8z"></path>{" "}
      <path
        style={{ fill: color }}
        d="M241,127.5c-25-20.5-50.2-20.1-72.5-15.5l-13.5,2.8l-1.9-46l23.5-1l0.8,19c23.6-2.7,51.3,0.3,78.6,22.5 		L241,127.5z"></path>{" "}
      <path
        style={{ fill: color }}
        d="M69.8,101.5c3.2,32.2,21.3,49.7,40.3,62.3l11.5,7.6l-31.2,33.9l-17.3-15.9l12.9-14 		c-18.6-14.7-36.1-36.5-39.6-71.5L69.8,101.5z"></path>{" "}
      <path
        style={{ fill: color }}
        d="M127.5,59c-20.5,25-20.1,50.2-15.5,72.5l2.8,13.5l-46,1.9l-1-23.5l19-0.8c-2.7-23.6,0.3-51.3,22.5-78.6 		L127.5,59z"></path>{" "}
      <path
        style={{ fill: color }}
        d="M101.5,230.2c32.2-3.2,49.7-21.3,62.3-40.3l7.6-11.5l33.9,31.2l-15.9,17.3l-14-12.9 		c-14.7,18.6-36.5,36.1-71.5,39.6L101.5,230.2z"></path>{" "}
      <path
        style={{ fill: color }}
        d="M59,172.5c25,20.5,50.2,20.1,72.5,15.5l13.5-2.8l1.9,46l-23.5,1l-0.8-19c-23.6,2.7-51.3-0.3-78.6-22.5 		L59,172.5z"></path>{" "}
      <path
        style={{ fill: color }}
        d="M230.2,198.5c-3.2-32.2-21.3-49.7-40.3-62.3l-11.5-7.6l31.2-33.9l17.3,15.9l-12.9,14 		c18.6,14.7,36.1,36.5,39.6,71.5L230.2,198.5z"></path>{" "}
      <path
        style={{ fill: color }}
        d="M172.5,241c20.5-25,20.1-50.2,15.5-72.5l-2.8-13.5l46-1.9l1,23.5l-19,0.8c2.7,23.6-0.3,51.3-22.5,78.6 		L172.5,241z"></path>{" "}
    </svg>
  </>
);
