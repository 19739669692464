import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "3%",
    display: "flex",
    flexDirection: "column",
    background: "transparent",
    border: "none"
  },
  // Using !important as the corresponding styles are aut-calculated by MUI and overriding these styles.
  mainMenuItemWrapper: { height: "auto !important" },
  menuItemWrapper: { height: "85px !important" },
  baseMenuItem: {
    display: "flex",
    margin: 10,
    alignItems: "center",
    background: "transparent",
    cursor: "pointer",
    borderRadius: 8,
    "&:hover, &.active": {
      backgroundColor: theme.palette.common.white,
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
    },
    "&.disabled": {
      opacity: 0.5
    }
  },
  mainMenuItem: {
    flexDirection: "row",
    columnGap: 10,
    padding: 10
  },
  menuItem: {
    height: "80%",
    flexDirection: "column",
    justifyContent: "space-evenly"
  }
}));
