import React from "react";

export const ViewOutputIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15426_4168)">
        <path
          d="M15 9V12.5C15 13.8807 13.8807 15 12.5 15H3.5C2.11928 15 1 13.8807 1 12.5V3.5C1 2.11928 2.11928 1 3.5 1H6.5V0H3.5C1.567 0 0 1.567 0 3.5V12.5C0 14.433 1.567 16 3.5 16H12.5C14.433 16 16 14.433 16 12.5V9H15Z"
          fill="black"
        />
        <path
          d="M5.5 12.9995H6.5V8.99953C6.5 5.06953 9.86 4.99953 10 4.99953H13.295L10.65 7.64453L11.355 8.34953L14.855 4.84953C15.0489 4.6545 15.0489 4.33956 14.855 4.14453L11.355 0.644531L10.645 1.35453L13.295 3.99953H10C9.955 3.99953 5.5 4.05453 5.5 8.99953V12.9995Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_15426_4168">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
