import React from "react";
import { AiGuideIcon } from ".";

interface IProps {
  width?: number;
  height?: number;
  viewBox?: string;
  fill?: string;
}

const AiGuideMiniIcon: React.FC<IProps> = (props) => {
  const { width = 56, height = 40, viewBox = "0 0 58 32", fill = "none" } = props;

  return (
    <AiGuideIcon
      data-testid="ai-guide-mini-icon"
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
    />
  );
};

export default AiGuideMiniIcon;
