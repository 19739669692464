import React from "react";

import styles from "./GroupBlock.module.scss";
import { Launch, DynamicFeed, UnfoldLess } from "@material-ui/icons";

import { IconButton } from "@material-ui/core";
import { Handle, Position } from "react-flow-renderer";

export const GroupBlock = ({ data }: $TSFixMe) => {
  const {
    handleCollapseClick,
    handleRedirectClick,
    hideGroupedNodes,
    itemId: groupName,
    groupColor
  } = data;

  return (
    <>
      {hideGroupedNodes && (
        <Handle type="target" position={Position.Left} isConnectable={hideGroupedNodes} />
      )}
      <div
        style={{ border: `2px solid ${groupColor}` }}
        className={`${styles.groupBlockContainer} ${
          hideGroupedNodes ? styles.collapsedGroupBlock : ""
        }`}>
        {hideGroupedNodes ? (
          <div
            role="button"
            tabIndex={0}
            onClick={() => handleCollapseClick(!hideGroupedNodes)}
            onKeyPress={(e) => e.key === "Enter" && handleCollapseClick(!hideGroupedNodes)}>
            <div className={styles.relativeContainer}>
              <div className={styles.blockStatus}>Group</div>
              <div className={styles.iconContainer}>
                <DynamicFeed />
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.labelOptions}>
            <span>{groupName}</span>
            <div>
              <IconButton
                size="small"
                onClick={() => handleCollapseClick(!hideGroupedNodes)}
                style={{ backgroundColor: "transparent", transform: "rotate(45deg)" }}>
                <UnfoldLess />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleRedirectClick()}
                style={{ backgroundColor: "transparent" }}>
                <Launch />
              </IconButton>
            </div>
          </div>
        )}
      </div>
      {hideGroupedNodes && (
        <Handle type="source" position={Position.Right} isConnectable={hideGroupedNodes} />
      )}
    </>
  );
};
