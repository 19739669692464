/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AIChatRequestDto } from '../Models';
// @ts-ignore
import type { AIChatResponseDto } from '../Models';
// @ts-ignore
import type { AddInputsToThreadRequestDto } from '../Models';
// @ts-ignore
import type { AddOutputRequestDto } from '../Models';
// @ts-ignore
import type { CreateAIThreadRequestDto } from '../Models';
// @ts-ignore
import type { ErrorMessage } from '../Models';
// @ts-ignore
import type { MessageFeedbackRequestDto } from '../Models';
// @ts-ignore
import type { ProcessAdhocInputRequestDto } from '../Models';
// @ts-ignore
import type { ProcessAdhocInputResponseDto } from '../Models';
// @ts-ignore
import type { RemoveInputsFromThreadsRequestDto } from '../Models';
// @ts-ignore
import type { ThreadResponseDto } from '../Models';
// @ts-ignore
import type { UpdateThreadRequestDto } from '../Models';
/**
 * AiControllerApi - axios parameter creator
 * @export
 */
export const AiControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeedback: async (threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('addFeedback', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('addFeedback', 'messageId', messageId)
            // verify required parameter 'messageFeedbackRequestDto' is not null or undefined
            assertParamExists('addFeedback', 'messageFeedbackRequestDto', messageFeedbackRequestDto)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}/feedback`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageFeedbackRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInputsToThread: async (threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('addInputsToThread', 'threadId', threadId)
            // verify required parameter 'addInputsToThreadRequestDto' is not null or undefined
            assertParamExists('addInputsToThread', 'addInputsToThreadRequestDto', addInputsToThreadRequestDto)
            const localVarPath = `/ai/threads/{threadId}/inputs`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addInputsToThreadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {AddOutputRequestDto} addOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOutput: async (threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('addOutput', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('addOutput', 'messageId', messageId)
            // verify required parameter 'addOutputRequestDto' is not null or undefined
            assertParamExists('addOutput', 'addOutputRequestDto', addOutputRequestDto)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}/add`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOutputRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AIChatRequestDto} aIChatRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chat1: async (threadId: string, aIChatRequestDto: AIChatRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('chat1', 'threadId', threadId)
            // verify required parameter 'aIChatRequestDto' is not null or undefined
            assertParamExists('chat1', 'aIChatRequestDto', aIChatRequestDto)
            const localVarPath = `/ai/threads/{threadId}/chat`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aIChatRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread: async (createAIThreadRequestDto: CreateAIThreadRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAIThreadRequestDto' is not null or undefined
            assertParamExists('createThread', 'createAIThreadRequestDto', createAIThreadRequestDto)
            const localVarPath = `/ai/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAIThreadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage2: async (threadId: string, messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('deleteMessage2', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('deleteMessage2', 'messageId', messageId)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessages: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('deleteMessages', 'threadId', threadId)
            const localVarPath = `/ai/threads/{threadId}/messages`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThread1: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('deleteThread1', 'threadId', threadId)
            const localVarPath = `/ai/threads/{threadId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        explainCode: async (threadId: string, messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('explainCode', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('explainCode', 'messageId', messageId)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}/explain-code`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages2: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getMessages2', 'threadId', threadId)
            const localVarPath = `/ai/threads/{threadId}/messages`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThread: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('getThread', 'threadId', threadId)
            const localVarPath = `/ai/threads/{threadId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreads1: async (projectId: string, targetId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getThreads1', 'projectId', projectId)
            const localVarPath = `/ai/threads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {ProcessAdhocInputRequestDto} processAdhocInputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdhocInput: async (threadId: string, processAdhocInputRequestDto: ProcessAdhocInputRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('processAdhocInput', 'threadId', threadId)
            // verify required parameter 'processAdhocInputRequestDto' is not null or undefined
            assertParamExists('processAdhocInput', 'processAdhocInputRequestDto', processAdhocInputRequestDto)
            const localVarPath = `/ai/threads/{threadId}/process-adhoc-input`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processAdhocInputRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processRagFiles: async (threadId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('processRagFiles', 'threadId', threadId)
            const localVarPath = `/ai/threads/{threadId}/process`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInputsFromThread: async (threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('removeInputsFromThread', 'threadId', threadId)
            // verify required parameter 'removeInputsFromThreadsRequestDto' is not null or undefined
            assertParamExists('removeInputsFromThread', 'removeInputsFromThreadsRequestDto', removeInputsFromThreadsRequestDto)
            const localVarPath = `/ai/threads/{threadId}/inputs`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeInputsFromThreadsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOutput: async (threadId: string, messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('removeOutput', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('removeOutput', 'messageId', messageId)
            const localVarPath = `/ai/threads/{threadId}/messages/{messageId}/remove`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChart: async (threadId: string, messageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('updateChart', 'threadId', threadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('updateChart', 'messageId', messageId)
            const localVarPath = `/ai/threads/{threadId}/{messageId}/chart`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)))
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} threadId 
         * @param {UpdateThreadRequestDto} updateThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThread: async (threadId: string, updateThreadRequestDto: UpdateThreadRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threadId' is not null or undefined
            assertParamExists('updateThread', 'threadId', threadId)
            // verify required parameter 'updateThreadRequestDto' is not null or undefined
            assertParamExists('updateThread', 'updateThreadRequestDto', updateThreadRequestDto)
            const localVarPath = `/ai/threads/{threadId}`
                .replace(`{${"threadId"}}`, encodeURIComponent(String(threadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateThreadRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AiControllerApi - functional programming interface
 * @export
 */
export const AiControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AiControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFeedback(threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFeedback(threadId, messageId, messageFeedbackRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.addFeedback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addInputsToThread(threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addInputsToThread(threadId, addInputsToThreadRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.addInputsToThread']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {AddOutputRequestDto} addOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOutput(threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOutput(threadId, messageId, addOutputRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.addOutput']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AIChatRequestDto} aIChatRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chat1(threadId: string, aIChatRequestDto: AIChatRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chat1(threadId, aIChatRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.chat1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createThread(createAIThreadRequestDto: CreateAIThreadRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createThread(createAIThreadRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.createThread']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessage2(threadId: string, messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessage2(threadId, messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.deleteMessage2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessages(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessages(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.deleteMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteThread1(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteThread1(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.deleteThread1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async explainCode(threadId: string, messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.explainCode(threadId, messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.explainCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages2(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AIChatResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages2(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.getMessages2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThread(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThread(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.getThread']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreads1(projectId: string, targetId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThreadResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreads1(projectId, targetId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.getThreads1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {ProcessAdhocInputRequestDto} processAdhocInputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAdhocInput(threadId: string, processAdhocInputRequestDto: ProcessAdhocInputRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessAdhocInputResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processAdhocInput(threadId, processAdhocInputRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.processAdhocInput']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processRagFiles(threadId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processRagFiles(threadId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.processRagFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeInputsFromThread(threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeInputsFromThread(threadId, removeInputsFromThreadsRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.removeInputsFromThread']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOutput(threadId: string, messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOutput(threadId, messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.removeOutput']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChart(threadId: string, messageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AIChatResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChart(threadId, messageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.updateChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} threadId 
         * @param {UpdateThreadRequestDto} updateThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateThread(threadId: string, updateThreadRequestDto: UpdateThreadRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreadResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateThread(threadId, updateThreadRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AiControllerApi.updateThread']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AiControllerApi - factory interface
 * @export
 */
export const AiControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AiControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFeedback(threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.addFeedback(threadId, messageId, messageFeedbackRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addInputsToThread(threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.addInputsToThread(threadId, addInputsToThreadRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {AddOutputRequestDto} addOutputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOutput(threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.addOutput(threadId, messageId, addOutputRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {AIChatRequestDto} aIChatRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chat1(threadId: string, aIChatRequestDto: AIChatRequestDto, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.chat1(threadId, aIChatRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThread(createAIThreadRequestDto: CreateAIThreadRequestDto, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.createThread(createAIThreadRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage2(threadId: string, messageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessage2(threadId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessages(threadId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessages(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThread1(threadId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteThread1(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        explainCode(threadId: string, messageId: string, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.explainCode(threadId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages2(threadId: string, options?: any): AxiosPromise<Array<AIChatResponseDto>> {
            return localVarFp.getMessages2(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThread(threadId: string, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.getThread(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId 
         * @param {string} [targetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreads1(projectId: string, targetId?: string, options?: any): AxiosPromise<Array<ThreadResponseDto>> {
            return localVarFp.getThreads1(projectId, targetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {ProcessAdhocInputRequestDto} processAdhocInputRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdhocInput(threadId: string, processAdhocInputRequestDto: ProcessAdhocInputRequestDto, options?: any): AxiosPromise<ProcessAdhocInputResponseDto> {
            return localVarFp.processAdhocInput(threadId, processAdhocInputRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processRagFiles(threadId: string, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.processRagFiles(threadId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInputsFromThread(threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.removeInputsFromThread(threadId, removeInputsFromThreadsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOutput(threadId: string, messageId: string, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.removeOutput(threadId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChart(threadId: string, messageId: string, options?: any): AxiosPromise<AIChatResponseDto> {
            return localVarFp.updateChart(threadId, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} threadId 
         * @param {UpdateThreadRequestDto} updateThreadRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThread(threadId: string, updateThreadRequestDto: UpdateThreadRequestDto, options?: any): AxiosPromise<ThreadResponseDto> {
            return localVarFp.updateThread(threadId, updateThreadRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AiControllerApi - interface
 * @export
 * @interface AiControllerApi
 */
export interface AiControllerApiInterface {
    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    addFeedback(threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    addInputsToThread(threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {AddOutputRequestDto} addOutputRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    addOutput(threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {AIChatRequestDto} aIChatRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    chat1(threadId: string, aIChatRequestDto: AIChatRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    createThread(createAIThreadRequestDto: CreateAIThreadRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    deleteMessage2(threadId: string, messageId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    deleteMessages(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    deleteThread1(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    explainCode(threadId: string, messageId: string, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    getMessages2(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AIChatResponseDto>>;

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    getThread(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

    /**
     * 
     * @param {string} projectId 
     * @param {string} [targetId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    getThreads1(projectId: string, targetId?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ThreadResponseDto>>;

    /**
     * 
     * @param {string} threadId 
     * @param {ProcessAdhocInputRequestDto} processAdhocInputRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    processAdhocInput(threadId: string, processAdhocInputRequestDto: ProcessAdhocInputRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ProcessAdhocInputResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    processRagFiles(threadId: string, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    removeInputsFromThread(threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    removeOutput(threadId: string, messageId: string, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    updateChart(threadId: string, messageId: string, options?: RawAxiosRequestConfig): AxiosPromise<AIChatResponseDto>;

    /**
     * 
     * @param {string} threadId 
     * @param {UpdateThreadRequestDto} updateThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApiInterface
     */
    updateThread(threadId: string, updateThreadRequestDto: UpdateThreadRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ThreadResponseDto>;

}

/**
 * AiControllerApi - object-oriented interface
 * @export
 * @class AiControllerApi
 * @extends {BaseAPI}
 */
export class AiControllerApi extends BaseAPI implements AiControllerApiInterface {
    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {MessageFeedbackRequestDto} messageFeedbackRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public addFeedback(threadId: string, messageId: string, messageFeedbackRequestDto: MessageFeedbackRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).addFeedback(threadId, messageId, messageFeedbackRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {AddInputsToThreadRequestDto} addInputsToThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public addInputsToThread(threadId: string, addInputsToThreadRequestDto: AddInputsToThreadRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).addInputsToThread(threadId, addInputsToThreadRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {AddOutputRequestDto} addOutputRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public addOutput(threadId: string, messageId: string, addOutputRequestDto: AddOutputRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).addOutput(threadId, messageId, addOutputRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {AIChatRequestDto} aIChatRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public chat1(threadId: string, aIChatRequestDto: AIChatRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).chat1(threadId, aIChatRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateAIThreadRequestDto} createAIThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public createThread(createAIThreadRequestDto: CreateAIThreadRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).createThread(createAIThreadRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public deleteMessage2(threadId: string, messageId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).deleteMessage2(threadId, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public deleteMessages(threadId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).deleteMessages(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public deleteThread1(threadId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).deleteThread1(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public explainCode(threadId: string, messageId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).explainCode(threadId, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public getMessages2(threadId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).getMessages2(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public getThread(threadId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).getThread(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} projectId 
     * @param {string} [targetId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public getThreads1(projectId: string, targetId?: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).getThreads1(projectId, targetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {ProcessAdhocInputRequestDto} processAdhocInputRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public processAdhocInput(threadId: string, processAdhocInputRequestDto: ProcessAdhocInputRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).processAdhocInput(threadId, processAdhocInputRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public processRagFiles(threadId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).processRagFiles(threadId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {RemoveInputsFromThreadsRequestDto} removeInputsFromThreadsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public removeInputsFromThread(threadId: string, removeInputsFromThreadsRequestDto: RemoveInputsFromThreadsRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).removeInputsFromThread(threadId, removeInputsFromThreadsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public removeOutput(threadId: string, messageId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).removeOutput(threadId, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public updateChart(threadId: string, messageId: string, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).updateChart(threadId, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} threadId 
     * @param {UpdateThreadRequestDto} updateThreadRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AiControllerApi
     */
    public updateThread(threadId: string, updateThreadRequestDto: UpdateThreadRequestDto, options?: RawAxiosRequestConfig) {
        return AiControllerApiFp(this.configuration).updateThread(threadId, updateThreadRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}

