import CancelIcon from "@material-ui/icons/Cancel";
import React, { useState } from "react";
import { Button, IconButton, TextField, makeStyles } from "@material-ui/core";

import DefaultButton from "components/Buttons/DefaultButton";
import UploadFileModal from "./UploadFileModal";
import SampleDataModal from "./SampleDataModal";

const useStyles = makeStyles({
  wrapper: {
    padding: "16px",
    minHeight: "calc(100vh - 165px)"
  },
  input: {
    display: "none"
  },
  details: {
    fontSize: "16px",
    fontWeight: 500
  },
  uploadText: {
    "& label": {
      marginBottom: 0,
      "&.MuiInputLabel-root.MuiInputLabel-outlined": {
        width: "calc(100% - 32px) !important"
      }
    },

    " & .MuiFormHelperText-root": {
      color: "rgba(76, 175, 80, 1)"
    }
  },
  flex: {
    display: "flex",
    gap: "10px",

    "& button": {
      height: "40px"
    }
  }
});

interface IProps {
  entityId: string;
  name: string;
  disabled: boolean;
  targetCol: string;
  projectRunId: string;
  projectId: string;
  onCountChange: (number: 1 | -1) => void;
}

const UploadDataset: React.FC<IProps> = (props) => {
  const { name, entityId, targetCol, projectRunId, projectId, disabled, onCountChange } = props;
  const classes = useStyles();
  const [fileName, setFileName] = useState("");
  const [open, setOpen] = useState(false);
  const [openSample, setOpenSample] = useState(false);

  const handleClick = () => {
    setFileName("");
    onCountChange(-1);
  };

  const handleShowSample = () => {
    setOpenSample(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCloseSample = () => {
    setOpenSample(false);
  };

  const handleFileUpload = (name: string) => {
    setFileName(name);
    onCountChange(1);
  };

  return (
    <>
      <div>
        <TextField
          fullWidth
          data-testid="predict-job-dataset"
          id="upload"
          name="upload"
          disabled={disabled}
          label="Upload File *"
          value={fileName}
          className={classes.uploadText}
          size="small"
          test-id="prediction-service-upload-file-input"
          variant="outlined"
          InputLabelProps={{
            shrink: !!fileName
          }}
          InputProps={{
            endAdornment: fileName ? (
              <IconButton disabled={disabled} size="small" onClick={handleClick}>
                <CancelIcon />
              </IconButton>
            ) : (
              <DefaultButton
                data-testid="predict-job-dataset-browse-btn"
                variant="outlined"
                color="primary"
                disabled={disabled}
                component="span"
                size="small"
                onClick={handleOpen}>
                Browse
              </DefaultButton>
            )
          }}
        />
      </div>
      <Button color="primary" onClick={handleShowSample}>
        {`${name} - sample data`}
      </Button>
      <UploadFileModal
        entityId={entityId}
        projectRunId={projectRunId}
        open={open}
        onClose={handleClose}
        onFileUpload={handleFileUpload}
      />
      <SampleDataModal
        open={openSample}
        targetCol={targetCol}
        projectId={projectId}
        entityId={entityId}
        entityName={name}
        onClose={handleCloseSample}
      />
    </>
  );
};

export default UploadDataset;
