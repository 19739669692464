import { useMutation, UseMutationResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";

interface IPayload {
  projectId?: string;
}

const useClearRecipeRunsQueue = (): UseMutationResult<unknown, unknown, IPayload, unknown> =>
  useMutation({
    mutationFn: async ({ projectId }: IPayload) => {
      if (!projectId) {
        throw "Invalid payload!";
      }

      return await api.fetchResponse(
        async () => await api.DfsRunConfigGroupControllerV2Api.clearRecipeQueue(projectId)
      );
    }
  });

export default useClearRecipeRunsQueue;
