import { makeStyles } from "@material-ui/core/styles";

export const useSettingsIconsStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: theme.spacing(4),
    top: theme.spacing(1),
    zIndex: 10,
    backgroundColor: "#6b6bc301"
  }
}));

export const useSettingsStyles = makeStyles((theme) => ({
  container: {
    width: 300,
    borderRadius: theme.spacing(1.5),
    "& .borderTop": {
      borderTop: `${theme.spacing(1)}px solid #ededed`
    },
    "& .borderRight": {
      borderRight: `${theme.spacing(1)}px solid #ededed`
    },
    "& .borderBottom": {
      borderBottom: `${theme.spacing(1)}px solid #ededed`
    },
    "& .borderLeft": {
      borderLeft: `${theme.spacing(1)}px solid #ededed`
    },

    "& .bgColor": {
      background: "#ededed"
    }
  },
  list: {
    paddingBottom: 0
  },
  menuItemSelection: {
    flexDirection: "column"
  },
  padding: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`
  }
}));
