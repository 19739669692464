import React from "react";

// Packages
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

// Utils
import { RenderText } from "src/utils";

type Props = {
  project: $TSFixMe;
  dataAppName?: string;
};

const SubTopNavBarBreadcrumbs = (props: Props) => {
  const { project, dataAppName } = props || {};

  const breadcrumbs: React.ReactNode[] = [];
  // projectid
  // < project name > dataapps > dataapp > log

  if (!!project?.id) {
    breadcrumbs.push(
      <Link key="projects" to={`/projects/${project?.id}/canvas`}>
        <RenderText>{project?.name || "Project"}</RenderText>
      </Link>
    );

    breadcrumbs.push(
      <Link key="project-dataapp" to={`/projects/${project?.id}/project-dataapps`}>
        <RenderText>DataApps</RenderText>
      </Link>
    );

    breadcrumbs.push(
      !!dataAppName ? (
        <Link key="dataapp-name" to={`/projects/${project?.id}/project-dataapps/${dataAppName}`}>
          <RenderText>{dataAppName}</RenderText>
        </Link>
      ) : (
        <RenderText key="dataapp-name">DataApp</RenderText>
      )
    );
  } else {
    breadcrumbs.push(
      !!dataAppName ? (
        <Link key="dataapp-name" to={`/dataapps-ui/${dataAppName}`}>
          <RenderText>{dataAppName}</RenderText>
        </Link>
      ) : (
        <RenderText>DataApp</RenderText>
      )
    );
  }

  breadcrumbs.push(
    <RenderText key="logs" color="textSecondary">
      Logs
    </RenderText>
  );

  return (
    <Breadcrumbs>
      {breadcrumbs?.map((eachBreadcrumb: React.ReactNode) => eachBreadcrumb)}
    </Breadcrumbs>
  );
};

export default SubTopNavBarBreadcrumbs;
