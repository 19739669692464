import React from "react";

export const TemplateRecipeIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor",
  opacity = 1
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <path
        d="M3.33325 5.20703C3.33325 4.1715 4.17272 3.33203 5.20825 3.33203H12.2916C13.3271 3.33203 14.1666 4.1715 14.1666 5.20703V11.6654H17.0833V14.3737C17.0833 15.8694 15.8707 17.082 14.3749 17.082H5.20825C4.17272 17.082 3.33325 16.2425 3.33325 15.207V5.20703ZM14.1666 15.832H14.3749C15.1803 15.832 15.8333 15.1791 15.8333 14.3737V12.9154H14.1666V15.832ZM6.45825 6.66536C6.11308 6.66536 5.83325 6.94519 5.83325 7.29036C5.83325 7.63554 6.11308 7.91536 6.45825 7.91536H11.0416C11.3868 7.91536 11.6666 7.63554 11.6666 7.29036C11.6666 6.94519 11.3868 6.66536 11.0416 6.66536H6.45825ZM6.45825 9.58203C6.11308 9.58203 5.83325 9.86186 5.83325 10.207C5.83325 10.5522 6.11308 10.832 6.45825 10.832H11.0416C11.3868 10.832 11.6666 10.5522 11.6666 10.207C11.6666 9.86186 11.3868 9.58203 11.0416 9.58203H6.45825ZM6.45825 12.4987C6.11308 12.4987 5.83325 12.7785 5.83325 13.1237C5.83325 13.4689 6.11308 13.7487 6.45825 13.7487H8.54158C8.88675 13.7487 9.16658 13.4689 9.16658 13.1237C9.16658 12.7785 8.88675 12.4987 8.54158 12.4987H6.45825Z"
        fill={color}
      />
    </g>
  </svg>
);
