/* eslint-disable no-unused-vars */
import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

type Props = {
  dataAppId: string | undefined;
  enabled: boolean;
  mode: "live" | "offline";
};

export const useGetDataAppLogs = ({ dataAppId, enabled, mode }: Props) => {
  return useQuery<string[]>({
    queryKey: ["dataApp", dataAppId, "logs", mode],
    queryFn: async () => {
      const response = await getAPIWithRethrow(`/dataapps/${dataAppId}/logs?mode=${mode}`);
      return response?.logs ? response?.logs?.split("\n") : [];
    },
    enabled: !!dataAppId && enabled,
    cacheTime: 0,
    refetchInterval: mode === "live" ? 15000 : false
  });
};
