import React from "react";
import { Recipe, Entities, EntityFeaturesResponse } from "src/types";

type MyContextData = {
  jobData?: $TSFixMe;
  jobRunData?: $TSFixMe;
  recipe: Recipe | null;
  isSelectedEntitiesUpdateInProgess: boolean;
  inputDatasets: Entities;
  handleUpdateRecipe: (props: {
    payload: $TSFixMe;
    onError?: () => void;
    onSuccess?: (recipe: Recipe) => void;
  }) => void;
  entityFeaturesMap: {
    [id: string]: EntityFeaturesResponse;
  } | null;
  setEntityFeaturesMap: (features: { [id: string]: EntityFeaturesResponse } | null) => void;
  handleInputDatasetsChange: (entities: Entities) => void;
  recipeId: string | undefined;
  isSaveDisabled: boolean;
  isRunDisabled: boolean;
  isTestDisabled: boolean;
  saveToolTip: string | undefined;
  runTooltip: string | undefined;
  testTooltip: string | undefined;
  isRunInProgress: boolean;
  isTestInProgress: boolean;
  isSaveInProgress: boolean;
  handleSave: () => void;
  handleRun: () => void;
  handleTest: (sampleRows?: number) => void;
  onRemoveCodeFromRecipe: (payload: { transformId: string; onSuccess: () => void }) => void;
  onUpdateRecipe: (recipe: Recipe) => void;
  isAskAiRecipeUpdateInProgress: boolean;
  previewTabs: $TSFixMe;
  setPreviewTabs: $TSFixMeFunction;
  editorValue: string;
  setEditorValue: (editorValue: string) => void;
  setInputDatasets: (inputDatasets: Entities) => void;
  codeErrorDetails: {
    lineNo: number;
    line: string;
  } | null;
  isQueryAssociatedWithRecipe: (identifier: string) => boolean;
  isDeletableRecipe: boolean;
  allEntities: Entities;
  isAutoSaving: boolean;
};

//@ts-expect-error
export const ApiConnectorRecipeContext = React.createContext<MyContextData>();
