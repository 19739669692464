import React from "react";

import { DialogTitle, Grid } from "@material-ui/core";

import styles from "./SimpleModalTitle.module.scss";

const SimpleModalTitle = ({ children }: $TSFixMe) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <DialogTitle className={styles.dialogTitle}>{children}</DialogTitle>
      </Grid>
    </Grid>
  );
};

export default SimpleModalTitle;
