import React from "react";
import { Button, makeStyles } from "@material-ui/core";

import MagicGenerateIcon from "src/icons/MagicGenerateIcon";
import NoAboutContentIcon from "src/icons/NoAboutContentIcon";

interface IProps {
  onGenerate: () => void;
}

export const useStyles = makeStyles({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "calc(100vh - 160px)",

    "& div": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
      color: "#515151",

      "& button": {
        textTransform: "capitalize"
      },

      "& div": {
        fontSize: 20
      },

      "& span": {
        fontSize: 14
      }
    }
  }
});

const NoAboutContent: React.FC<IProps> = (props) => {
  const { flexContainer } = useStyles();

  return (
    <div className={flexContainer}>
      <div>
        <NoAboutContentIcon />
        <div>Oops! No About Page Content Found</div>
        <span>Use Generative AI to auto generate an About page for this project</span>
        <Button
          variant="contained"
          color="primary"
          startIcon={<MagicGenerateIcon />}
          onClick={props.onGenerate}>
          Generate Content
        </Button>
      </div>
    </div>
  );
};

export default NoAboutContent;
