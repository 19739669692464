import React from "react";
import { Typography } from "@material-ui/core";
import PreviewIconNew from "src/icons/NewUX/Dataset/PreviewIconNew";
import OntologyLoaderWrapper from "./OntologyLoaderWrapper";

const DataFetchingContainer: React.FC = () => {
  return (
    <OntologyLoaderWrapper height={200}>
      <Typography color="primary">
        <PreviewIconNew />
      </Typography>
      <Typography variant="body2" color="primary">
        Fetching data...
      </Typography>
    </OntologyLoaderWrapper>
  );
};

export default DataFetchingContainer;
