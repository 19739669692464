import { postInvitationThroughEmailWithRethrow, postInvitationWithRethrow } from "src/api";
import { useMutation, UseMutationResult } from "@tanstack/react-query";

interface EmailRequest {
  roleId: string;
  recipientEmail: string;
}

interface IVariables {
  requests: EmailRequest[];
  isEmail?: boolean;
}

export const useInviteUserMutation = (): UseMutationResult<
  unknown,
  unknown,
  IVariables,
  unknown
> => {
  return useMutation({
    mutationFn: async ({ requests, isEmail = true }) => {
      if (isEmail) {
        return await postInvitationThroughEmailWithRethrow(requests);
      }
      return Promise.all(
        requests.map((request) => {
          const invitation = postInvitationWithRethrow([request]);
          return invitation;
        })
      );
    }
  });
};
