/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorMessage } from '../Models';
/**
 * PredictControllerApi - axios parameter creator
 * @export
 */
export const PredictControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} serviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateCache: async (serviceName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('invalidateCache', 'serviceName', serviceName)
            const localVarPath = `/v2/predict/{serviceName}/invalidate-cache`
                .replace(`{${"serviceName"}}`, encodeURIComponent(String(serviceName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serviceName 
         * @param {string} body 
         * @param {boolean} [log] 
         * @param {boolean} [mock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelPredict: async (serviceName: string, body: string, log?: boolean, mock?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceName' is not null or undefined
            assertParamExists('modelPredict', 'serviceName', serviceName)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('modelPredict', 'body', body)
            const localVarPath = `/v2/predict/{serviceName}`
                .replace(`{${"serviceName"}}`, encodeURIComponent(String(serviceName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (log !== undefined) {
                localVarQueryParameter['log'] = log;
            }

            if (mock !== undefined) {
                localVarQueryParameter['mock'] = mock;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredictControllerApi - functional programming interface
 * @export
 */
export const PredictControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PredictControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} serviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invalidateCache(serviceName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invalidateCache(serviceName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictControllerApi.invalidateCache']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} serviceName 
         * @param {string} body 
         * @param {boolean} [log] 
         * @param {boolean} [mock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelPredict(serviceName: string, body: string, log?: boolean, mock?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelPredict(serviceName, body, log, mock, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PredictControllerApi.modelPredict']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PredictControllerApi - factory interface
 * @export
 */
export const PredictControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PredictControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} serviceName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invalidateCache(serviceName: string, options?: any): AxiosPromise<void> {
            return localVarFp.invalidateCache(serviceName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} serviceName 
         * @param {string} body 
         * @param {boolean} [log] 
         * @param {boolean} [mock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelPredict(serviceName: string, body: string, log?: boolean, mock?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.modelPredict(serviceName, body, log, mock, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PredictControllerApi - interface
 * @export
 * @interface PredictControllerApi
 */
export interface PredictControllerApiInterface {
    /**
     * 
     * @param {string} serviceName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictControllerApiInterface
     */
    invalidateCache(serviceName: string, options?: RawAxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} serviceName 
     * @param {string} body 
     * @param {boolean} [log] 
     * @param {boolean} [mock] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictControllerApiInterface
     */
    modelPredict(serviceName: string, body: string, log?: boolean, mock?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<string>;

}

/**
 * PredictControllerApi - object-oriented interface
 * @export
 * @class PredictControllerApi
 * @extends {BaseAPI}
 */
export class PredictControllerApi extends BaseAPI implements PredictControllerApiInterface {
    /**
     * 
     * @param {string} serviceName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictControllerApi
     */
    public invalidateCache(serviceName: string, options?: RawAxiosRequestConfig) {
        return PredictControllerApiFp(this.configuration).invalidateCache(serviceName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} serviceName 
     * @param {string} body 
     * @param {boolean} [log] 
     * @param {boolean} [mock] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictControllerApi
     */
    public modelPredict(serviceName: string, body: string, log?: boolean, mock?: boolean, options?: RawAxiosRequestConfig) {
        return PredictControllerApiFp(this.configuration).modelPredict(serviceName, body, log, mock, options).then((request) => request(this.axios, this.basePath));
    }
}

