import { useEffect, useState } from "react";

export const useFetchTransformInputs = ({ selectedTransform }: $TSFixMe) => {
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    const input_datasets: $TSFixMe = [];
    const parameters: $TSFixMe = [];
    const output_datasets: $TSFixMe = [];
    const output_charts: $TSFixMe = [];

    selectedTransform?.inputs?.forEach((input: $TSFixMe) => {
      if (input.display?.input_dataset || input.metadata?.input_dataset) {
        input_datasets.push(input);
      } else if (input.display?.output_dataset || input.metadata?.output_dataset) {
        output_datasets.push(input);
      } else if (input.display?.output_chart || input.metadata?.output_chart) {
        output_charts.push(input);
      } else {
        parameters.push(input);
      }
    });
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ input_datasets: any; parameter... Remove this comment to see the full error message
    setInputs({ input_datasets, parameters, output_datasets, output_charts });
  }, [selectedTransform?.inputs]);
  return { inputs };
};
