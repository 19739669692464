import { getAPI, postAPI, putAPI } from "../../utils/apiService";

const idToQS = (ids: $TSFixMe) => (ids ? "?" + ids.map((i: $TSFixMe) => "id=" + i).join("&") : "");

const segmentToQS = (pipelineIds: $TSFixMe) =>
  pipelineIds
    ? pipelineIds.reduce((acc: $TSFixMe, curr: $TSFixMe, idx: $TSFixMe) => {
        return acc + `${idx === 0 ? "?" : "&"}inputEntityId=` + curr;
      }, "")
    : "";

export const getPipelines = async () => await getAPI("/pipelines");

export const getPipelineById = async (id: $TSFixMe) => await getAPI("/pipelines/" + id);

export const getPipelineDags = async () => await getAPI("/pipeline-dag");

export const getPipelineDagById = async (pipelineId: $TSFixMe) =>
  await getAPI("/pipeline-dag?pipelineId=" + pipelineId);

export const getEntitiesByPipelineId = async (pipelineId: $TSFixMe) =>
  await getAPI("/pipeline-entities?pipelineId=" + pipelineId);

export const getEntityFeaturesByEntityId = async (entityId: $TSFixMe) =>
  await getAPI("/pipeline-entity-features?entityId=" + entityId);

export const getEntityFeaturesByPipelineId = async (pipelineId: $TSFixMe) =>
  await getAPI(`/pipeline-entity-features?pipelineId=${pipelineId}&ref=true`);

export const updatePipelineEntityFeaturesById = async (payload: $TSFixMe) =>
  await putAPI("/pipeline-entity-features", payload);

export const getPipelineTransformsById = async (pipelineId: $TSFixMe) =>
  await getAPI("/pipeline-transforms?pipelineId=" + pipelineId);

export const getInputEntitesByIds = async (inputEntityIds: $TSFixMe) => {
  if (inputEntityIds === null || !inputEntityIds.length) {
    return [];
  }
  return await getAPI("/input-entities" + idToQS(inputEntityIds));
};

export const createFilesV1 = async (fileData: $TSFixMe) => await postAPI("/files", fileData);

export const createInputEntity = async (inputEntityData: $TSFixMe) =>
  await postAPI("/input-entities", inputEntityData);

export const getInputEntityById = async (inputEntityId: $TSFixMe) =>
  await getAPI("/input-entities/" + inputEntityId);

export const getInputEntitySegmentsByIds = async (inputEntityIds: $TSFixMe) => {
  if (inputEntityIds === null) {
    return [];
  }
  return await getAPI("/input-entity-segments" + segmentToQS(inputEntityIds));
};

export const getInputEntitySegmentsById = async (inputEntityId: $TSFixMe) =>
  await getAPI("/input-entity-segments?inputEntityId=" + inputEntityId);

export const getInputEntityFeaturesById = async (inputEntityId: $TSFixMe) =>
  await getAPI("/input-entity-features?inputEntityId=" + inputEntityId);

export const updateInputEntityFeaturesById = async (payload: $TSFixMe) =>
  await putAPI("/input-entity-feature", payload);

export const getSegmentFeaturesById = async (segmentId: $TSFixMe) =>
  await getAPI("/input-entity-segment-features?inputEntitySegmentId=" + segmentId);

export const updateSegmentFeaturesById = async (payload: $TSFixMe) =>
  await putAPI("/input-entity-segment-features", payload);

export const getOutputEntitiesByPipelineId = async (pipelineId: $TSFixMe) =>
  await getAPI("/output-entities?pipelineId=" + pipelineId);

export const getOutputEntitiesByPipelineIdPrcId = async (pipelineId: $TSFixMe, prcId: $TSFixMe) =>
  await getAPI("/output-entities?pipelineId=" + pipelineId + "&prcId=" + prcId);

export const getOutputEntityFeaturesById = async (pipelineId: $TSFixMe, outputEntityId: $TSFixMe) =>
  await getAPI(
    "/output-entity-features?pipelineId=" + pipelineId + "&outputEntityId=" + outputEntityId
  );

export const getML = async () => await getAPI("/ml-models");

export const postModelBuild = async (requestData: $TSFixMe) =>
  await postAPI("/ml-model-build-jobs", requestData);

export const getModelBuild = async (pipelineId: $TSFixMe) =>
  await getAPI("/ml-model-build-jobs?pipelineId=" + pipelineId);

export const applyAllDFSTemplates = async (pipelineId: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await postAPI("/apply-all-dfs-templates/" + pipelineId);

export const buildPipeline = async (pipelineId: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await postAPI("/build-pipeline/" + pipelineId);

export const postPipelineRunConfigs = async (body: $TSFixMe) =>
  await postAPI("/pipeline-run-configs", body);

export const getPipelineConfigs = async (pipelineId: $TSFixMe) =>
  await getAPI("/pipeline-run-configs?pipelineId=" + pipelineId);

export const runPipeline = async (pipelineId: $TSFixMe, rcId: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await postAPI("/run-pipeline/" + pipelineId + "/" + rcId);

export const runDefaultPipeline = async (pipelineId: $TSFixMe) =>
  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  await postAPI("/run-pipeline/" + pipelineId);

export const updatePipelineEntities = async (pipelineId: $TSFixMe, inputEntityIds: $TSFixMe) =>
  await postAPI("/pipeline-input-entities/" + pipelineId, inputEntityIds);

export const getData = async (inputEntityId: $TSFixMe) =>
  await getAPI("/data?limit=200&type=INPUT_ENTITY&id=" + inputEntityId);

export const getSegmentData = async (segmentId: $TSFixMe) =>
  await getAPI("/data?limit=200&type=INPUT_ENTITY_SEGMENT&id=" + segmentId);

export const postSegmentData = async (body: $TSFixMe) =>
  await postAPI("/input-entity-segments", body);

export const getOutputEntityData = async (outputEntityId: $TSFixMe) =>
  await getAPI("/data?limit=200&type=OUTPUT_ENTITY&id=" + outputEntityId);

export const getSourcesById = async (entityId: $TSFixMe) =>
  await getAPI("/files?entityId=" + entityId);

export const getOutputEntityDiff = async (
  entityId1: $TSFixMe,
  entityType1: $TSFixMe,
  entityId2: $TSFixMe,
  entityType2: $TSFixMe
) => await getAPI(`/diffs?element=${entityId1}-${entityType1}&element=${entityId2}-${entityType2}`);

export const getTransformId = async (transformName: $TSFixMe) =>
  await getAPI(`/dfs-templates?name=${transformName}`);

export const testTransformById = async (body: $TSFixMe) =>
  await postAPI("/test-pipeline-scalar-run-config", body);

export const getTransformsByPipelineId = async (pipelineId: $TSFixMe) =>
  await getAPI(`/dfs-run-configs?pipelineId=${pipelineId}`);

export const getAlertsByEntityIdV1 = async (entityId: $TSFixMe) =>
  await getAPI(`/entities/${entityId}/alerts`);

export const deleteProjectPipeline = async (pipelineId: $TSFixMe) =>
  await getAPI("/cleanup-pipeline?pipelineId=" + pipelineId);
