import { useRef } from "react";
import _ from "lodash";
import { QueryClient, UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate, generatePath } from "react-router-dom";

import { DataAppType } from "pages/DataApps/DataApps.type";
import {
  getAPIWithRethrow,
  handleErrorWithRedirectToLogs,
  handleSuccessWithRedirectToLogs,
  postAPIWithRethrow
} from "src/utils/apiService";
import { DATAAPP_INTERVAL_IN_SEC, isInProgress } from "./useDataAppStatusById";
import { DataAppDtoLaunchStatusEnum } from "openapi/Models/data-app-dto";
import { QUERY_KEY_DATAAPPS_ACROSS_TENANTS } from "./useGetDataAppAcrossTenants";
import { QUERY_KEY_DATA_APPS } from "./useGetDataApps";
import { WebPaths } from "src/routing/routes";
import { useShowLogsModal } from "pages/Projects/common/ShowLogsModal/useShowLogsModal";
import { useTransformErrorUtils } from "pages/Projects/common/hooks/useTransformErrorUtils";

type Props = { id: string; hideLogs?: boolean };

export interface ILaunchResponse {
  logs: string;
  name: string;
  launchStatus: DataAppDtoLaunchStatusEnum;
}

export const invalidateDataAppQueries = (
  queryClient: QueryClient,
  id: string,
  launchStatus: DataAppDtoLaunchStatusEnum,
  dataAppName?: string
) => {
  if (dataAppName) {
    queryClient.setQueryData([QUERY_KEY_DATAAPPS_ACROSS_TENANTS, dataAppName], (prevData: any) =>
      _.map(prevData, (app) => ({
        ...app,
        launchStatus: app.id === id ? launchStatus : app.launchStatus
      }))
    );
  } else {
    queryClient.setQueryData([QUERY_KEY_DATA_APPS], (prevData: any) =>
      _.map(prevData, (app) => ({
        ...app,
        launchStatus: app.id === id ? launchStatus : app.launchStatus
      }))
    );
  }
};

export const useLaunchDataAppMutation = (): UseMutationResult<
  ILaunchResponse,
  unknown,
  Props,
  unknown
> => {
  const { dataAppName, projectId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { openLogsSideBar } = useShowLogsModal();
  const { fetchAndRenderFullErrorLogs } = useTransformErrorUtils();

  const fetchingRef = useRef<boolean>(false);

  return useMutation({
    mutationFn: async ({ id }) => {
      const response = await postAPIWithRethrow(`/dataapps/${id}/launch`, {}, {}, false);
      const launchStatus = response?.launchStatus;

      if (isInProgress(launchStatus)) {
        return await new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            if (!fetchingRef.current) {
              fetchingRef.current = true;

              try {
                const newResponse: DataAppType = await getAPIWithRethrow(
                  `/dataapps/by-id/${id}`,
                  undefined,
                  false
                );
                fetchingRef.current = false;
                const newStatus = newResponse.launchStatus;
                if (!isInProgress(newStatus)) {
                  clearInterval(interval);
                  const logsResponse = await getAPIWithRethrow(
                    `/dataapps/${id}/logs?mode=offline`,
                    undefined,
                    false
                  );
                  resolve({
                    launchStatus: newStatus,
                    logs: logsResponse?.logs,
                    name: response?.name
                  });
                }
              } catch (e: any) {
                clearInterval(interval);
                reject(e);
              }
            }
          }, DATAAPP_INTERVAL_IN_SEC * 1000);
        });
      } else {
        return { launchStatus, logs: "", name: response?.name };
      }
    },
    onSuccess: (response: ILaunchResponse, { id, hideLogs }) => {
      invalidateDataAppQueries(queryClient, id, response.launchStatus, dataAppName);
      if (!hideLogs) {
        const actions = [
          {
            label: "View Log",
            action: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event?.preventDefault();
              event?.stopPropagation();
              const logLines = response?.logs ? response?.logs?.split("\n") : [];
              openLogsSideBar(logLines, "success");
            }
          },
          {
            label: "View DataApp",
            action: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              event?.preventDefault();
              event?.stopPropagation();
              const path = projectId
                ? generatePath(WebPaths.ProjectDataApp, { dataAppName: response.name, projectId })
                : generatePath(WebPaths.DataAppDashboard, { dataAppName: response.name });
              navigate(path);
            }
          }
        ];
        if (response.launchStatus === DataAppDtoLaunchStatusEnum.Running) {
          handleSuccessWithRedirectToLogs(`DataApp launched successfully.`, actions);
        } else {
          handleErrorWithRedirectToLogs(`Error in launching DataApp.`, (event) => {
            event?.preventDefault();
            event?.stopPropagation();
            const logLines = response?.logs ? response?.logs?.split("\n") : [];
            openLogsSideBar(logLines, "error");
          });
        }
      }
    },
    onError: (error: any, { id, hideLogs }) => {
      invalidateDataAppQueries(queryClient, id, DataAppDtoLaunchStatusEnum.Failure, dataAppName);
      if (!hideLogs && error?.response?.status !== 404) {
        fetchAndRenderFullErrorLogs(error);
      }
    }
  });
};
