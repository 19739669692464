import React, { createContext, useContext } from "react";

type CardProps = {
  isHovered: boolean;
};
//@ts-expect-error
const CardContext = createContext<CardProps>({});

export const useCardContext = () => useContext(CardContext);

type CardProviderProps = {
  children: React.ReactNode;
  isHovered: boolean;
};

export const CardProvider = ({ children, ...props }: CardProviderProps) => {
  return <CardContext.Provider value={{ ...props }}>{children}</CardContext.Provider>;
};
