import React from "react";

import { Switch as MuiSwitch, withStyles, SwitchProps } from "@material-ui/core";

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex"
  },
  switchBase: {
    padding: 3.5,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 9,
    height: 9,
    boxShadow: "none"
  },
  track: {
    border: `2px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  disabled: {
    color: `${theme.palette.primary.main} !important`,
    opacity: "0.2 !important",
    "& + $track": {
      backgroundColor: `${theme.palette.common.white} !important`,
      border: `2px solid ${theme.palette.primary.main}`,
      opacity: "0.2 !important"
    }
  },
  checked: {
    padding: 3
  }
}))(MuiSwitch);

export const Switch = (props: SwitchProps) => {
  return <StyledSwitch {...props} />;
};
