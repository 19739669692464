import React, { useMemo } from "react";

import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  makeStyles,
  withStyles
} from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import {
  // schemaOptionsSupportedFileTypes,
  FileUploadStatuses,
  DatasetHelperText
} from "../../../utils/Dataset.constants";

import useStoreSelectors from "../../../hooks/useStoreSelectors";

import { FileUploadContainerPropsType } from "../../../types/Props.type";

const useStyles = makeStyles((theme) => ({
  root: { height: "100%" },
  progressValue: { marginLeft: theme.spacing(1) },
  progressCancelIconContainer: {
    "& [class^='MuiSvgIcon-root']:not(.disabled)": { fontSize: 12, cursor: "pointer" },
    "& .disabled": { fontSize: 12, cursor: "not-allowed", opacity: 0.5 }
  },
  uploadSuccessContainer: {
    display: "flex",
    gap: 10,
    color: "#1c9929",
    "& [class^='MuiSvgIcon-root']": { fontSize: 16 }
  },
  uploadFailureContainer: {
    display: "flex",
    gap: 10,
    color: "#ff0000",
    "& [class^='MuiSvgIcon-root']": { fontSize: 16 }
  },
  refreshDeleteIconsContainer: {
    "& .iconButton": {
      padding: 0,
      color: "initial"
    },
    "& .iconButton:disabled": {
      opacity: 0.5,
      cursor: "not-allowed"
    },
    "& .iconButton:last-child": {
      marginLeft: theme.spacing(0.5)
    },
    "& [class^='MuiCircularProgress-root']": {
      color: "initial",
      marginTop: theme.spacing(0.25),
      marginLeft: theme.spacing(0.5)
    },
    "& .disabled": { cursor: "not-allowed", opacity: 0.5 }
  }
}));

export const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 12,
    borderRadius: 5,
    border: "0.5px solid #C0C0C0"
  },
  colorPrimary: {
    backgroundColor: "white"
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#4646b5"
  }
}))(LinearProgress);

const FileUploadContainer = (props: FileUploadContainerPropsType) => {
  const { index, file, reUpload, deleteFile, isDataSourcesFilesUpload } = props || {};

  const classes: $TSFixMe = useStyles();

  // Stores - STARTS >>
  const { datasetFilesStore } = useStoreSelectors();
  // << ENDS - Stores

  const isFileUploading = useMemo(() => {
    let thisIsFileUploading: boolean = true;

    // if (schemaOptionsSupportedFileTypes.includes(file?.type?.toLowerCase())) {
    thisIsFileUploading = [FileUploadStatuses.Uploading, FileUploadStatuses.EntityCreated].includes(
      file?.status
    );
    // } else {
    //   thisIsFileUploading = [
    //     FileUploadStatuses.Uploading,
    //     FileUploadStatuses.EntityCreated,
    //     FileUploadStatuses.SignedUrl
    //   ].includes(file?.status);
    // }

    return thisIsFileUploading;
  }, [file]);

  // $FixMe Remove
  // const isFileUploading = useMemo(
  //   () =>
  //     [
  //       FileUploadStatuses.Uploading,
  //       FileUploadStatuses.EntityCreated,
  //       FileUploadStatuses.SignedUrl,
  //       FileUploadStatuses.Processing
  //     ].includes(file?.status),
  //   [file]
  // );

  const isFileSucceed = useMemo(() => {
    let thisIsFileSucceed: boolean = false;

    if (
      !isDataSourcesFilesUpload
      // && schemaOptionsSupportedFileTypes.includes(file?.type?.toLowerCase())
    ) {
      thisIsFileSucceed = file?.status === FileUploadStatuses.SignedUrl;
    } else {
      thisIsFileSucceed = file?.status === FileUploadStatuses.Success;
    }

    return thisIsFileSucceed;
  }, [isDataSourcesFilesUpload, file]);

  // $FixMe Remove
  // const isFileSucceed = useMemo(() => file?.status === FileUploadStatuses.Success, [file]);

  const isFileFailed = useMemo(() => file?.status === FileUploadStatuses.Failed, [file]);

  const isFilesUploadFulfilled = useMemo(() => {
    if (datasetFilesStore?.length > 0) {
      return (datasetFilesStore || [])
        ?.filter((eachFile: $TSFixMe) => file?.name !== eachFile?.name)
        ?.every((eachFile: $TSFixMe) => {
          let thisIsFilesUploadFulfilled: boolean = false;

          if (
            !isDataSourcesFilesUpload
            // && schemaOptionsSupportedFileTypes.includes(eachFile?.type?.toLowerCase())
          ) {
            thisIsFilesUploadFulfilled = [
              FileUploadStatuses.Stage,
              FileUploadStatuses.SignedUrl,
              FileUploadStatuses.Failed
            ].includes(eachFile?.status);
          } else {
            thisIsFilesUploadFulfilled = [
              FileUploadStatuses.Stage,
              FileUploadStatuses.Success,
              FileUploadStatuses.Failed
            ].includes(eachFile?.status);
          }

          return thisIsFilesUploadFulfilled;
        });
    }

    return false;
  }, [isDataSourcesFilesUpload, datasetFilesStore]);

  // $FixMe Remove
  // const isFilesUploadFulfilled = useMemo(() => {
  //   if (datasetFilesStore?.length > 0) {
  //     return (datasetFilesStore || [])
  //       ?.filter((eachFile: $TSFixMe) => file?.name !== eachFile?.name)
  //       ?.every((eachFile: $TSFixMe) =>
  //         [
  //           FileUploadStatuses.Stage,
  //           FileUploadStatuses.Success,
  //           FileUploadStatuses.Failed
  //         ].includes(eachFile?.status)
  //       );
  //   }

  //   return false;
  // }, [datasetFilesStore]);

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        {isFileUploading ? (
          <Grid container direction="column">
            <Grid item>
              <Typography id={`fileUploading_${index}`} variant="caption">
                {DatasetHelperText.Uploading}
                <span id={`fileUploadingProgress_${index}`} className={classes.progressValue}>
                  {file?.uploadProgress ? `${file?.uploadProgress}%` : `0%`}
                </span>
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={10}>
                  <BorderLinearProgress
                    id={`fileUploadingProgressBar_${index}`}
                    variant="determinate"
                    value={file?.uploadProgress}
                  />
                </Grid>
                <Grid item xs={1} className={classes.progressCancelIconContainer}>
                  {isFilesUploadFulfilled ? (
                    <CancelIcon id={`fileUploadingCancel_${index}`} onClick={deleteFile} />
                  ) : (
                    <Tooltip
                      id={`fileUploadingCancelDisabled_${index}`}
                      title={DatasetHelperText?.UploadingFileActionDisabledText || ""}>
                      <CancelIcon className="disabled" />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            alignItems="center"
            justifyContent={`${isFileSucceed || isFileFailed ? "space-between" : "flex-end"}`}>
            {isFileSucceed && (
              <>
                <Grid item xs={9} alignItems="center" className={classes.uploadSuccessContainer}>
                  <CheckCircleIcon />
                  <Typography id={`fileUploadSucceed_${index}`} variant="caption">
                    {DatasetHelperText.FileUploadSucceed}
                  </Typography>
                </Grid>
              </>
            )}
            {isFileFailed && (
              <>
                <Grid item xs={9} alignItems="center" className={classes.uploadFailureContainer}>
                  <CancelIcon />
                  <Typography id={`fileUploadFailed_${index}`} variant="caption">
                    {DatasetHelperText.FileUploadFailed}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid container item xs={3} className={classes.refreshDeleteIconsContainer}>
              {file?.entityId && isFileFailed && (
                <IconButton
                  id={`fileReUploadAction_${index}`}
                  className="iconButton"
                  onClick={reUpload}>
                  <RefreshIcon fontSize="small" />
                </IconButton>
              )}
              {[
                FileUploadStatuses.Stage,
                FileUploadStatuses.Failed,
                FileUploadStatuses.Deleting
              ].includes(file?.status) && (
                <>
                  {file?.status === FileUploadStatuses.Deleting ? (
                    <CircularProgress id={`fileUploadDeletingProgress_${index}`} size={15} />
                  ) : (
                    <>
                      {isFilesUploadFulfilled ? (
                        <IconButton
                          id={`fileUploadedDeleteAction_${index}`}
                          className="iconButton"
                          onClick={deleteFile}
                          disabled={!isFilesUploadFulfilled}>
                          <DeleteOutlineIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <Tooltip
                          id={`fileUploadedDeleteActionDisabled_${index}`}
                          title={DatasetHelperText?.UploadingFileActionDisabledText || ""}>
                          <DeleteOutlineIcon fontSize="small" className="disabled" />
                        </Tooltip>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FileUploadContainer;
