import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";

export const enum UseGetUserDetailsQueryKeys {
  UserDetails = "user-details"
}

export interface IUserTenant {
  id: string;
  name: string;
  numberOfProjects: number;
  numberOfUsers: number;
  cpuGuarantee: number;
  memGuarantee: number;
  dataappsTtlInHrs: number;
  userRole: string;
  invitationStatus: string;
  hasValidLicense: boolean;
  canCreateTenant: boolean;
}
export interface IUserDetails {
  userId: string;
  tenants: IUserTenant[];
  noteBooksEnabled: boolean;
  licenseEnabled: boolean;
  accessAllowList: string;
  gaId: string;
  userRole: string;
  userAdmin: boolean;
}

export const useGetUserDetails = (options?: UseQueryOptions): UseQueryResult<IUserDetails | any> =>
  useQuery({
    queryKey: [UseGetUserDetailsQueryKeys.UserDetails],
    queryFn: async () => await getAPIWithRethrow("/user"),
    select: (data: $TSFixMe) => (Object.keys(data || {})?.length > 0 ? data : {}),
    enabled: false,
    ...options
  });
