/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import type { ProjectDto } from './project-dto';
// May contain unused imports in some cases
// @ts-ignore
import type { ShutdownStrategy } from './shutdown-strategy';

/**
 * 
 * @export
 * @interface EnvDto
 */
export interface EnvDto {
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvDto
     * @deprecated
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvDto
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvDto
     */
    'updated'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'updater'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvDto
     */
    'cores'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvDto
     */
    'gpus'?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvDto
     */
    'memInMbs'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'requirements'?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvDto
     */
    'diskInGbs'?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'launchStatus'?: EnvDtoLaunchStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'envType'?: EnvDtoEnvTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnvDto
     */
    'projects'?: Array<string>;
    /**
     * 
     * @type {Array<ProjectDto>}
     * @memberof EnvDto
     */
    'projectDtos'?: Array<ProjectDto>;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'logs'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnvDto
     */
    'defaultFlag'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'podStatus'?: string;
    /**
     * 
     * @type {ShutdownStrategy}
     * @memberof EnvDto
     */
    'shutdownStrategy'?: ShutdownStrategy;
    /**
     * 
     * @type {boolean}
     * @memberof EnvDto
     */
    'defaultShutdownStrategy'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EnvDto
     */
    'tenantId'?: string;
}

export const EnvDtoLaunchStatusEnum = {
    Success: 'SUCCESS',
    Launching: 'LAUNCHING',
    Shutdown: 'SHUTDOWN',
    Failed: 'FAILED',
    Unknown: 'UNKNOWN'
} as const;

export type EnvDtoLaunchStatusEnum = typeof EnvDtoLaunchStatusEnum[keyof typeof EnvDtoLaunchStatusEnum];
export const EnvDtoEnvTypeEnum = {
    ExtraSmall: 'EXTRA_SMALL',
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE',
    CpuLarge: 'CPU_LARGE',
    MaxLarge: 'MAX_LARGE',
    ExtraMaxLarge: 'EXTRA_MAX_LARGE',
    Custom: 'CUSTOM'
} as const;

export type EnvDtoEnvTypeEnum = typeof EnvDtoEnvTypeEnum[keyof typeof EnvDtoEnvTypeEnum];


