import { useQuery } from "@tanstack/react-query";

import { getAPIWithRethrow } from "src/utils/apiService";
import { LicensesType } from "src/types/License.type";

export const useGetLicenses = () => {
  return useQuery<LicensesType>({
    queryKey: ["license"],
    queryFn: () => {
      return getAPIWithRethrow(`/license`);
    }
  });
};
