import { FormControl, MenuItem, Select, makeStyles } from "@material-ui/core";

import React from "react";

const useStyles = makeStyles({
  selectRoot: {
    fontSize: "14px",
    "&::before": {
      "border-bottom": "0px !important"
    }
  },
  menuItem: {
    fontSize: "14px"
  },
  select: {
    color: "#515151"
  }
});

export const RecordSelector = ({
  value,
  handleCountChange
}: {
  value: number;
  handleCountChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => void;
}) => {
  const classes = useStyles();
  return (
    <FormControl>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          MenuListProps: { disablePadding: true },
          getContentAnchorEl: null
        }}
        className={classes.selectRoot}
        classes={{
          select: classes.select
        }}
        data-testid="ai-record-selector"
        value={value}
        onChange={handleCountChange}
        displayEmpty>
        <MenuItem value="" disabled className={classes.menuItem}>
          Show Records
        </MenuItem>
        {[5, 10, 20, 100].map((count) => (
          <MenuItem
            value={count}
            key={count}
            className={classes.menuItem}
            data-testid="ai-record-selector-option">
            Show {count} records
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
