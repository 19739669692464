/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateRoleDto
 */
export interface UpdateRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleDto
     */
    'permissions'?: Array<UpdateRoleDtoPermissionsEnum>;
}

export const UpdateRoleDtoPermissionsEnum = {
    UserInvite: 'USER_INVITE',
    UserUpdate: 'USER_UPDATE',
    TenantCreate: 'TENANT_CREATE',
    TenantUpdate: 'TENANT_UPDATE',
    TenantDelete: 'TENANT_DELETE',
    RoleView: 'ROLE_VIEW',
    RoleCreate: 'ROLE_CREATE',
    RoleUpdate: 'ROLE_UPDATE',
    RoleDelete: 'ROLE_DELETE'
} as const;

export type UpdateRoleDtoPermissionsEnum = typeof UpdateRoleDtoPermissionsEnum[keyof typeof UpdateRoleDtoPermissionsEnum];


