import React, { useRef, useState } from "react";
import { Accordion, AccordionSummary, Paper, Typography, makeStyles } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import Chart from "./Chart";
import { useParams } from "react-router";
import AIGuideDialog from "../AIGuide/common/AIGuideDialog";
import { ThreadResponseDtoTargetTypeEnum } from "openapi/Models/thread-response-dto";
import { AIGuideMiniButton } from "src/components/Buttons/AIGuideMiniButton";

type StyleProps = { expanded: boolean; isHighlighted: boolean; isAiGuideBtnDisabled: boolean };
const useStyles = makeStyles({
  paper: {
    height: ({ expanded }: { expanded: boolean }) => (expanded ? "100%" : "inherit"),
    boxShadow: "0px 2.832793951034546px 2.832793951034546px 0px #9C9C9C40",
    border: "0.71px solid #A9A9A9",
    borderRadius: "8.5px"
  },
  accordion: {
    borderRadius: "8.5px",
    height: ({ expanded }: StyleProps) => (expanded ? "100%" : "inherit")
  },
  accordionHeader: {
    background: ({ isHighlighted }: StyleProps) => (isHighlighted ? "#DFF0FF" : "#E6E6E6"),
    minHeight: "48px !important",
    height: "48px !important",
    borderRadius: "8.5px"
  },
  accordionHeaderExpanded: {
    borderRadius: "8.5px 8.5px 0px 0px"
  },
  accordionContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  btn: {
    opacity: ({ isAiGuideBtnDisabled }) => (isAiGuideBtnDisabled ? 0.5 : 1),
    zIndex: 1
  }
});
const ChartCard = ({ data = {}, token, isExpanded, isHighlighted, hideAIGuide }: $TSFixMe) => {
  const chartDivRef = useRef<HTMLDivElement>(null);

  const { projectId, chartId, scenarioId } = useParams<$TSFixMe>();
  const [expanded, setExpanded] = React.useState(isExpanded);
  const [showAIGuideDialog, setShowAIGuideDialog] = useState(false);
  const classes = useStyles({
    expanded,
    isHighlighted,
    isAiGuideBtnDisabled: data.chartType !== "JSON"
  });

  React.useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  React.useEffect(() => {
    if (isHighlighted && chartDivRef.current) {
      const topPos = chartDivRef.current.offsetTop;

      // $FixMe: Scope to be refactored.
      // This tag-name DOM method will be replaced with "window" object.
      // It'll be taken up in a next sprint as part of (RC-3041: UX-Upgrade: Main-container styling, Fix for scrollbar issue)
      document.getElementsByTagName("main")[0].scrollTo({
        top: topPos,
        behavior: "smooth"
      });
    }
  }, [isHighlighted]);
  const navigateToAIGuide = (event: any) => {
    event.stopPropagation();
    setShowAIGuideDialog(true);
  };

  return (
    <Paper className={classes.paper}>
      <AIGuideDialog
        open={showAIGuideDialog}
        onClose={() => {
          setShowAIGuideDialog(false);
        }}
        projectId={projectId!}
        scenarioId={scenarioId!}
        diamondId={chartId}
        chartName={data.name}
        chartId={data.chartId}
        targetType={ThreadResponseDtoTargetTypeEnum.Chart}
      />
      <Accordion
        ref={chartDivRef}
        square
        expanded={expanded}
        onChange={(_, newExpanded) => setExpanded(newExpanded)}
        className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className={classes.accordionHeader}
          classes={{
            expanded: classes.accordionHeaderExpanded,
            content: classes.accordionContent
          }}>
          <Typography color="primary">{data.name}</Typography>
          {!hideAIGuide && (
            <AIGuideMiniButton
              width={28}
              height={26}
              viewBox="4 -6 48 48"
              badgeDotStyleProps={{ marginRight: -8 }}
              tooltip={
                data.chartType !== "JSON" ? "AI guide is not available for this chart" : "AI Guide"
              }
              onClick={navigateToAIGuide}
              targetId={chartId!}
              chartId={data.chartId}
              projectId={projectId!}
              disabled={data.chartType !== "JSON"}
            />
          )}
        </AccordionSummary>
        <Chart data={data} token={token} expanded={isExpanded} />
      </Accordion>
    </Paper>
  );
};

export default React.memo(ChartCard);
