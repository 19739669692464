import React from "react";
import { Tab, styled } from "@material-ui/core";

export const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  textTransform: "none",
  minWidth: 100,
  maxWidth: 200,
  minHeight: 44,
  color: "rgba(0, 0, 0, 0.85)",
  "& > span": {
    display: "flex",
    flexDirection: "row-reverse",
    padding: "0px 6px",
    "& > button:first-child": {
      marginBottom: "0px !important"
    }
  },
  "& div": {
    flexGrow: 1,
    textAlign: "left",
    display: "inline-block",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginRight: 10
  },
  "&.preview-tab": {
    background: "#f1957d",
    position: "relative",
    color: "#FFF",
    borderRadius: "4px 4px 0 0",
    "&::after": {
      display: "block",
      content: "''",
      height: 4,
      background: "#BB6651",
      position: "absolute",
      bottom: 0,
      width: "100%",
      zIndex: 1
    },
    "& div": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center"
    }
  }
}));
