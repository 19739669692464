import React from "react";

import { Grid, Typography, makeStyles } from "@material-ui/core";

import { ConfigIcon } from "src/icons/ConfigIcon";

import { capitalize } from "src/utils/capitalize";
import { validateNameField } from "src/utils/formFieldUtils";

import { Field, PasswordInput } from "src/components";

import { DataSourcesHelperText } from "../utils/DataSources.constants";
import { thirdPartyTypeName } from "src/pages/DataSources/utils/DataSources.constants";
import { omit } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 142px)",
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 12
  },
  titleContainerWrapper: {
    marginBottom: 10,
    fontWeight: 500
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    "& .title": { marginLeft: 5 }
  },
  imageContainer: { margin: "25px 0", "& .image": { width: 42, height: 42 } },
  title: {
    fontWeight: 500
  },
  formField: {
    marginBottom: 16,
    width: "100%"
  }
}));

const DataSourceConfiguration = (props: $TSFixMe) => {
  const {
    dataSourceTypeMetaData,
    values,
    setValues,
    setIsNameDirty,
    setIsDataSourceConfigDirty,
    nameError,
    setNameError,
    checkIsDuplicate,
    setIsTestSucceed,
    isSaving,
    setIsSaved
  } = props || {};

  const classes = useStyles();

  const validateDatasetName = (value: $TSFixMe) => {
    const { isValid, error: errorMessage } = validateNameField({
      fieldName: value,
      fieldNameLabel: `data-connector name`
    });

    setNameError(() => (isValid ? "" : errorMessage));

    if (isValid) {
      if (checkIsDuplicate(value)) {
        setNameError(() => DataSourcesHelperText.DataSourceNameExists);
      }
    }
  };

  const handleInputChange = (event: $TSFixMe, id?: string) => {
    const { name, value } = event.target;

    if (id === "name") {
      setIsNameDirty(() => true);
      validateDatasetName(value);
    } else {
      setIsDataSourceConfigDirty(() => true);
      setIsTestSucceed(() => false);
    }

    setIsSaved(() => false);

    (values as $TSFixMe)[name].value = value;

    setValues(() => ({
      ...values
    }));
  };

  const getErrorText = (field: $TSFixMe) => {
    if (field === "name") {
      return nameError || "";
    }

    return "";
  };

  const isThirdParty = dataSourceTypeMetaData?.name === thirdPartyTypeName;

  return (
    <>
      <Grid container alignContent="flex-start" className={classes.root}>
        <Typography
          component={"span"}
          variant="subtitle1"
          color="primary"
          data-testid="connectorConfuguration"
          className={classes.titleContainerWrapper}>
          <div className={classes.titleContainer}>
            <ConfigIcon />
            <Typography component={"span"} className="title">
              {DataSourcesHelperText.ConfigurationTitle}
            </Typography>
          </div>
        </Typography>
        <Typography data-testid="connectorConfugurationInfo" component={"span"} variant="body2">
          <div>{DataSourcesHelperText.ConfigurationInfo}</div>
        </Typography>
        <Grid container alignItems="center" spacing={2} className={classes.imageContainer}>
          <Grid item>
            {typeof dataSourceTypeMetaData?.image === "string" ? (
              <img
                data-testid="connectorImg"
                src={dataSourceTypeMetaData?.image}
                alt="data-connector"
                width={90}
                height={90}
              />
            ) : (
              <div data-testid="connectorImg" className="image">
                {dataSourceTypeMetaData?.image}
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2} alignContent="space-between">
              <Grid item xs>
                <Typography component={"span"} variant="caption">
                  <div data-testid="connectorSource">{DataSourcesHelperText.Source}</div>
                </Typography>
                <Typography
                  component={"span"}
                  variant="subtitle1"
                  color="primary"
                  data-testid="connectorName"
                  className={classes.title}>
                  <div>{dataSourceTypeMetaData?.displayName}</div>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <form name="dataSourceConfigurationForm" autoComplete="dataSourceConfigurationForm-off">
          {Object.keys(omit(values, "name"))?.length > 0 &&
            Object.keys(omit(values, "name")).map((field: $TSFixMe, index: number) => {
              if (isThirdParty && field !== "name") return;
              return (values as $TSFixMe)[field]?.encrypted ? (
                <PasswordInput
                  key={`index_${index}`}
                  // @ts-ignore
                  id={(values as $TSFixMe)[field]?.name || `dataSourceFieldId_${index}`}
                  name={(values as $TSFixMe)[field]?.name || `dataSourceFieldId_${index}`}
                  label={capitalize((values as $TSFixMe)[field]?.name || "")}
                  variant="outlined"
                  data-testid="connectorInput"
                  // @ts-ignore
                  size="small"
                  // @ts-ignore
                  value={(values as $TSFixMe)[field]?.value || ""}
                  required={(values as $TSFixMe)[field]?.required || false}
                  error={
                    isSaving &&
                    (values as $TSFixMe)[field]?.required &&
                    !(values as $TSFixMe)[field]?.value
                  }
                  helperText={
                    isSaving &&
                    (values as $TSFixMe)[field]?.required &&
                    !(values as $TSFixMe)[field]?.value &&
                    "Required!"
                  }
                  onChange={(event: $TSFixMe) => {
                    handleInputChange(event);
                  }}
                  autoComplete="new-password"
                  className={classes.formField}
                />
              ) : (
                <Field
                  key={`index_${index}`}
                  id={(values as $TSFixMe)[field]?.name || `dataSourceFieldId_${index}`}
                  label={capitalize((values as $TSFixMe)[field]?.name || "")}
                  variant="outlined"
                  // @ts-ignore
                  size="small"
                  // @ts-ignore
                  value={(values as $TSFixMe)[field]?.value || ""}
                  required={(values as $TSFixMe)[field]?.required || false}
                  error={!!getErrorText(field)}
                  helperText={getErrorText(field)}
                  onChange={(event: $TSFixMe) => {
                    handleInputChange(event, field);
                  }}
                  autoComplete={`dataSourceFieldId_${index}-off`}
                  className={classes.formField}
                />
              );
            })}
        </form>
      </Grid>
    </>
  );
};

export default DataSourceConfiguration;
