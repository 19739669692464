import React, { useEffect, useMemo } from "react";

// Packages
import { Controller, useFieldArray, UseFieldArrayRemove, useFormContext } from "react-hook-form";
import { filter, find, includes, isEmpty, map, size, truncate } from "lodash";

// MUI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Autocomplete } from "@material-ui/lab";

// Icons
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { TrashIcon } from "src/icons/NewUX/TrashIcon";

// Utils
import {
  defaultVariables,
  ScenarioConfig,
  ScenarioFormFields,
  ScenarioHelperText
} from "../../utils/Scenario.constants";

// Open API
import { GlobalVariableDto } from "openapi/Models";

// Components
import { OverflowTooltip, Spinner } from "src/components";

// Contexts
import { useScenarioContext } from "../../context/useScenarioContext";

// Styles
import useStyles from "./ScenarioVariables.styles";

type VariableRowProps = {
  variable: { [id: string]: string };
  variableIndex: number;
  remove: UseFieldArrayRemove;
  isFirstRow: boolean;
};

const ScenarioVariables = () => {
  const { isPendingVariables, isReadOnly, globalVariablesData } = useScenarioContext() || {};

  const { control } = useFormContext();

  const classes = useStyles();

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: ScenarioFormFields.Variables
  });

  return (
    <Paper className={classes.root}>
      <Box p={2}>
        <Typography variant="subtitle2" color="textSecondary" data-testid="segmentAddVariables">
          {`${ScenarioHelperText.AddVariable} (Optional)`}
        </Typography>
      </Box>
      <Divider />
      {isPendingVariables ? (
        <Spinner />
      ) : isEmpty(globalVariablesData) ? (
        <Box p={2} fontStyle="italic" style={{ opacity: 0.75 }}>
          <InfoOutlinedIcon />{" "}
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
            data-testid="segmentNoVariablesInfo">
            {ScenarioHelperText.NoGlobalVariablesInfo}
          </Typography>
        </Box>
      ) : (
        <Box p={2}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginBottom: 20, opacity: 0.75 }}
            data-testid="segmentAddVariablesInfo">
            {ScenarioHelperText.AddVariableInfo}
          </Typography>

          {size(fields) < size(globalVariablesData) && (
            <Box mb={1}>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={!!isReadOnly}
                onClick={() => prepend(defaultVariables[0])}
                data-testid="scenarioAddVariableAction">
                {ScenarioHelperText.AddVariable}
              </Button>
            </Box>
          )}

          {!isEmpty(fields) && (
            <Box pt={1} className={classes.wrapper}>
              {map(fields, (variable, variableIndex) => (
                <Box key={`variable-${variableIndex}`} {...(variableIndex > 0 ? { mt: 2 } : {})}>
                  <VariableRow
                    variable={variable}
                    variableIndex={variableIndex}
                    remove={remove}
                    isFirstRow={variableIndex === 0}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
};

const VariableRow = (props: VariableRowProps) => {
  const { variable, variableIndex, isFirstRow, remove } = props || {};

  const classes = useStyles();

  const inputLabelProps = {
    classes: {
      root: classes.inputLabel,
      shrink: classes.inputLabelShrink
    }
  };

  const { isReadOnly, globalVariablesData, isAttemptedSubmit } = useScenarioContext() || {};

  const { control, watch, setValue } = useFormContext();

  // Watch selected variables
  const selectedVariables = watch("variables").map((variable: { key: string }) => variable?.key);
  const currentVariable = watch(`variables.0.key`);

  // Filter out already selected options
  const availableOptions = useMemo(
    () =>
      filter(
        globalVariablesData,
        (globalVariable: GlobalVariableDto) =>
          !includes(selectedVariables, globalVariable?.name) ||
          globalVariable?.name === currentVariable
      ),
    [globalVariablesData, selectedVariables, currentVariable]
  );

  const disableVariableField = useMemo(
    () => !isFirstRow || size(availableOptions) === 1,
    [isFirstRow, availableOptions]
  );

  useEffect(() => {
    if (!currentVariable) {
      setValue(`variables.0.key`, availableOptions?.[0]?.name);
    }
  }, []);

  return (
    <Grid key={variable?.id} container alignItems="center" className={classes.variableContainer}>
      <Controller
        control={control}
        name={`variables.${variableIndex}.key`}
        render={({ field, fieldState }) => {
          const { error } = fieldState;

          return (
            <Autocomplete
              {...field}
              disabled={!!isReadOnly || !!disableVariableField}
              size="small"
              className={classes.textField}
              getOptionLabel={(option: any) =>
                truncate(
                  find(
                    globalVariablesData,
                    (globalVariable: GlobalVariableDto) => globalVariable?.name === option?.value
                  )?.name || "",
                  { length: 20 }
                )
              }
              renderOption={(option: any) => (
                <OverflowTooltip
                  style={{ width: "100%", whiteSpace: "nowrap" }}
                  value={
                    find(
                      globalVariablesData,
                      (globalVariable: GlobalVariableDto) => globalVariable?.name === option?.value
                    )?.name || ""
                  }
                />
              )}
              value={{ value: field?.value, label: field?.value }}
              onChange={(_, option) => field.onChange(option?.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${ScenarioHelperText.Key} *`}
                  InputLabelProps={inputLabelProps}
                  variant="outlined"
                  error={!!isAttemptedSubmit && !!error}
                  data-testid={`scenarioVariable${variableIndex}InputVariable`}
                />
              )}
              options={
                map(availableOptions, (globalVariable: GlobalVariableDto) => ({
                  value: globalVariable?.name,
                  label: globalVariable?.name
                })) ?? []
              }
              data-testid={`scenarioVariable${variableIndex}Variable`}
            />
          );
        }}
      />
      <Controller
        control={control}
        name={`variables.${variableIndex}.value`}
        rules={{
          required: !!watch(`variables.${variableIndex}.key`),
          maxLength: {
            value: ScenarioConfig.VariableValueMaxLength,
            message: `Maximum allowed characters: ${ScenarioConfig.VariableValueMaxLength}`
          }
        }}
        render={({ field, fieldState }) => {
          const { error } = fieldState;

          return (
            <TextField
              {...field}
              id={`value-${variableIndex}`}
              type="text"
              size="small"
              label={`${ScenarioHelperText.Value} *`}
              InputLabelProps={inputLabelProps}
              variant="outlined"
              className={classes.textField}
              disabled={!!isReadOnly}
              fullWidth
              error={!!isAttemptedSubmit && !!error}
              {...(!!isAttemptedSubmit && error?.type === "maxLength"
                ? {
                    helperText: error?.message
                  }
                : {})}
              data-testid={`scenarioVariable${variableIndex}InputValue`}
            />
          );
        }}
        data-testid={`scenarioVariable${variableIndex}Value`}
      />

      <IconButton
        size="small"
        disabled={!!isReadOnly}
        onClick={() => remove(variableIndex)}
        data-testid={`scenarioVariable${variableIndex}RemoveVariableAction`}>
        <TrashIcon viewBox="0 0 20 20" />
      </IconButton>
    </Grid>
  );
};

export default ScenarioVariables;
