import React from "react";

export const ConnectorsIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 2} ${height + 2}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 4.6875C6.70766 4.6875 5.65625 3.63609 5.65625 2.34375C5.65625 1.05141 6.70766 0 8 0C9.29234 0 10.3438 1.05141 10.3438 2.34375C10.3438 3.63609 9.29234 4.6875 8 4.6875ZM8 0.9375C7.22459 0.9375 6.59375 1.56834 6.59375 2.34375C6.59375 3.11916 7.22459 3.75 8 3.75C8.77541 3.75 9.40625 3.11916 9.40625 2.34375C9.40625 1.56834 8.77541 0.9375 8 0.9375Z"
      fill={color}
    />
    <path
      d="M0.313552 13.3906C-0.332636 12.2714 0.0522082 10.8352 1.17143 10.189C2.29071 9.54286 3.7269 9.92771 4.37305 11.0469C5.02074 12.1687 4.63711 13.6008 3.51518 14.2485C2.38886 14.8988 0.955614 14.5027 0.313552 13.3906ZM1.64018 11.0009C0.968646 11.3886 0.737771 12.2504 1.12546 12.9219C1.5113 13.5902 2.3719 13.8261 3.04643 13.4366C3.71949 13.048 3.9498 12.1888 3.56118 11.5156C3.17346 10.8441 2.31174 10.6132 1.64018 11.0009Z"
      fill={color}
    />
    <path
      d="M12.4847 14.2485C11.3629 13.6009 10.9791 12.1688 11.6268 11.0469C12.273 9.92772 13.7093 9.54285 14.8284 10.189C15.9476 10.8352 16.3325 12.2714 15.6863 13.3907C15.043 14.5048 13.6085 14.8974 12.4847 14.2485ZM12.4387 11.5157C12.0501 12.1887 12.2803 13.048 12.9535 13.4366C13.6249 13.8243 14.4867 13.5934 14.8744 12.9219C15.2621 12.2504 15.0313 11.3886 14.3597 11.0009C13.6847 10.6112 12.8244 10.8477 12.4387 11.5157Z"
      fill={color}
    />
    <path
      d="M1.91559 8.59646L0.979492 8.54493C1.04068 7.43368 1.37012 6.34018 1.93221 5.38265C2.47874 4.45159 3.24518 3.64624 4.14868 3.05371L4.6628 3.83765C3.04837 4.89646 2.02137 6.67546 1.91559 8.59646Z"
      fill={color}
    />
    <path
      d="M14.0841 8.59646C13.9784 6.67546 12.9514 4.89646 11.3369 3.83765L11.851 3.05371C12.7545 3.64624 13.521 4.45155 14.0675 5.38265C14.6296 6.34018 14.959 7.43368 15.0202 8.54493L14.0841 8.59646Z"
      fill={color}
    />
    <path
      d="M8.0001 16.0001C6.88522 16.0001 5.81885 15.7466 4.83057 15.2468L5.25372 14.4102C6.10957 14.8431 7.0336 15.0626 8.0001 15.0626C8.9666 15.0626 9.89063 14.8431 10.7465 14.4102L11.1696 15.2468C10.1813 15.7466 9.11497 16.0001 8.0001 16.0001Z"
      fill={color}
    />
  </svg>
);
