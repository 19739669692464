/* eslint-disable no-unused-vars */
import React, { ReactNode } from "react";
import { Button, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles({
  listItem: {
    "& svg": {
      verticalAlign: "baseline",
      marginRight: "6px"
    }
  }
});

type Value = {
  label: string | number;
  value: string;
  disabled?: boolean;
  onClick?: (event: any) => void;
  icon?: ReactNode;
  endIcon?: ReactNode;
};
type Props = {
  label: string;
  values: Value[];
  labelStyles?: any;
  buttonProps?: any;
  className?: string;
};

const SimpleMenu = ({ label, values, buttonProps, labelStyles, ...restProps }: Props) => {
  const { listItem } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        {...buttonProps}
        onClick={handleClick}
        endIcon={
          open ? <ArrowDropUpIcon onClick={() => {}} /> : <ArrowDropDownIcon onClick={() => {}} />
        }>
        {label}
      </Button>
      <Menu
        {...restProps}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {values.map(({ label, value, disabled, icon, onClick, endIcon, ...restProps }) => {
          const onMenuItemClick = (event: any) => {
            onClick?.(event);
            handleClose();
          };

          return (
            <MenuItem
              key={value}
              id={value}
              className={listItem}
              value={value}
              disabled={disabled}
              onClick={onClick && onMenuItemClick}
              {...restProps}>
              <Typography {...labelStyles}>
                {icon ? icon : null}
                {label}
                {endIcon ? endIcon : null}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export { SimpleMenu };
export default SimpleMenu;
