import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

// Open API
import api from "utils/AxiosClient";
import { FeatureDto } from "openapi/Models";

export const enum UseGetEntityFeaturesV2QueryKeys {
  EntityFeaturesV2 = "entity-features-v2"
}

interface IEntityFeaturesV2Props extends UseQueryOptions<unknown, unknown, FeatureDto[]> {
  entityId?: string;
  scenarioId?: string;
  jobRunId?: string;
}

const useGetEntityFeaturesV3 = (props: IEntityFeaturesV2Props): UseQueryResult<FeatureDto[]> => {
  const { entityId, scenarioId, jobRunId, ...options } = props;

  return useQuery({
    queryKey: [UseGetEntityFeaturesV2QueryKeys.EntityFeaturesV2],
    queryFn: async () => {
      if (!entityId || !scenarioId) {
        throw "Invalid params!";
      }

      return await api.fetchResponse(
        async () => await api.FeatureControllerApi.findFeatures(entityId, scenarioId, jobRunId)
      );
    },
    enabled: !!entityId && !!scenarioId,
    ...options
  });
};

export default useGetEntityFeaturesV3;
