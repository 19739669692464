import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles, MenuItem, TextField, Typography } from "@material-ui/core";
import { Spinner } from "../";

const useStyles = makeStyles((theme) => ({
  // @ts-expect-error TS(2339) FIXME: Property 'noSelectStyles' does not exist on type '... Remove this comment to see the full error message
  textField: ({ noSelectStyles }) => ({
    "& .MuiFilledInput-root": {
      borderRadius: 4,
      backgroundColor: "transparent",
      border: noSelectStyles ? "none" : "1px solid #123553",
      color: "#123553",
      "&:hover": {
        backgroundColor: "transparent"
      },
      "&.Mui-focused>div": {
        backgroundColor: "transparent"
      }
    }
  }),
  // @ts-expect-error TS(2339) FIXME: Property 'noSelectStyles' does not exist on type '... Remove this comment to see the full error message
  selectNoLabel: ({ noSelectStyles }) => ({
    fontWeight: "normal",
    paddingTop: 12,
    paddingBottom: 12,
    ...(noSelectStyles
      ? {
          padding: "5px 12px 0 0 !important"
        }
      : {})
  }),
  selectMenu: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.light}`,
    boxSizing: "border-box",
    boxShadow: "0px 0.8px 1.5px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    color: theme.palette.primary.main,
    marginTop: 25,
    marginLeft: 5,
    "& .Mui-selected": {
      background: theme.palette.common.white,
      color: theme.palette.secondary.main
    }
  },
  label: {
    padding: "8px 16px",
    lineHeight: 1,
    display: "block",
    color: theme.palette.grey[500]
  },
  titleGroup: {
    fontWeight: 500,
    color: theme.palette.primary.light
  },
  groupSeparator: {
    width: "calc(100% - 32px)",
    backgroundColor: "#E0E0E0",
    height: 1,
    margin: "6px 16px"
  },
  loader: {
    padding: "0 !important"
  },
  minimal: {
    fontSize: 14,
    padding: "5px 0",
    "& .MuiFilledInput-root": {
      border: "none"
    }
  }
}));

type Props = {
  id: string;
  onChange: $TSFixMeFunction;
  variant?: string;
  label?: string;
  options?: OptionPropTypes;
  optionGroups?: $TSFixMe[]; // TODO: { title: PropTypes.string, options: OptionPropTypes }
  labelKey?: string;
  valueKey?: string;
  type?: string;
  isLoading?: boolean;
  noSelectStyles?: boolean;
  onSelect?: $TSFixMeFunction;
};

const Dropdown = ({
  id,
  label,
  onChange,
  options: optionList,
  optionGroups,
  labelKey = "label",
  valueKey = "value",
  type,
  isLoading,
  onSelect,
  // @ts-expect-error TS(2339) FIXME: Property 'noOptionAvailableMessage' does not exist... Remove this comment to see the full error message
  noOptionAvailableMessage = "No option available",
  noSelectStyles,
  ...rest
}: Props) => {
  const classes = useStyles({ noSelectStyles });

  const optionsGroupList = useMemo(() => {
    const test: $TSFixMe = [];
    optionGroups?.forEach(({ title, options }, groupIndex) => {
      test.push(
        ...[
          <Typography
            key={`${title}-${groupIndex}`}
            variant="overline"
            component="label"
            onClick={(e: $TSFixMe) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            classes={{ root: clsx(classes.titleGroup, classes.label) }}>
            {title}
          </Typography>,
          ...(options.length
            ? options.map((option: $TSFixMe, optionIndex: $TSFixMe) => (
                <MenuItem
                  key={`${option[labelKey]}-${groupIndex}-${optionIndex}`}
                  value={option[valueKey]}>
                  {option[labelKey]}
                </MenuItem>
              ))
            : [
                <Typography
                  variant="caption"
                  key="no-label-available"
                  color="primary"
                  classes={{ root: classes.label }}>
                  {noOptionAvailableMessage}
                </Typography>
              ])
        ],
        <div
          className={clsx({
            [classes.groupSeparator]: groupIndex !== optionGroups?.length - 1
          })}></div>
      );
    });
    return test;
  }, [optionGroups]); // eslint-disable-line

  const handleChange = (evt: $TSFixMe) => {
    onChange && onChange(evt);
    if (onSelect) {
      // // @ts-expect-error TS(2550) FIXME: Property 'flat' does not exist on type 'any[]'. Do... Remove this comment to see the full error message
      const list = optionGroups ? optionGroups.map(({ options }) => options).flat() : optionList;
      const item = list?.find((option: $TSFixMe) => option.value === evt.target.value);
      onSelect(item);
    }
  };

  return (
    <TextField
      id={id}
      name={id}
      label={label}
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"filled" ... Remove this comment to see the full error message
      variant="filled"
      select
      onChange={handleChange}
      required
      // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
      className={`${classes.textField} ${classes[type]}`}
      InputProps={{ disableUnderline: true }}
      SelectProps={{
        // @ts-expect-error TS(2322) FIXME: Type 'string | false' is not assignable to type 's... Remove this comment to see the full error message
        classes: { root: !label && `${classes.selectNoLabel} ${classes[type]}` },
        MenuProps: { classes: { paper: `${classes.selectMenu}` } }
      }}
      {...rest}>
      {isLoading ? (
        <Spinner className={classes.loader} size={20} />
      ) : optionGroups ? (
        optionsGroupList
      ) : (
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        optionList.map((option, index) => (
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          <MenuItem key={`${option}-${index}`} value={option[valueKey]}>
            {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {option[labelKey]}
          </MenuItem>
        ))
      )}
    </TextField>
  );
};

type OptionPropTypes = {
  label?: string;
  value?: number | string | boolean;
}[];

export default Dropdown;
