import React from "react";

export const DataappsIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 2} ${height + 2}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5312 0.952637H0.46875C0.209875 0.952637 0 1.16251 0 1.42139V11.7964C0 12.0553 0.209875 12.2651 0.46875 12.2651H7.53125V13.2436L2.34775 14.1154C2.09244 14.1583 1.92028 14.4001 1.96322 14.6554C2.00172 14.8844 2.20019 15.0464 2.42491 15.0464C2.49944 15.0464 2.1465 15.0975 8.00034 14.1154L13.5495 15.04C13.8048 15.0826 14.0463 14.91 14.0889 14.6546C14.1314 14.3993 13.9589 14.1578 13.7036 14.1152L8.46875 13.243V12.2651H15.5312C15.7901 12.2651 16 12.0552 16 11.7964V1.42136C16 1.16248 15.7901 0.952637 15.5312 0.952637ZM15.0625 11.3276H0.9375V1.89014H15.0625V11.3276Z"
      fill={color}
    />
    <path
      d="M3.375 6.14014H5.25C5.50887 6.14014 5.71875 5.93026 5.71875 5.67139V3.79639C5.71875 3.53751 5.50887 3.32764 5.25 3.32764H3.375C3.11612 3.32764 2.90625 3.53751 2.90625 3.79639V5.67139C2.90625 5.93026 3.11612 6.14014 3.375 6.14014ZM3.84375 4.26514H4.78125V5.20264H3.84375V4.26514Z"
      fill={color}
    />
    <path
      d="M3.375 9.89014H5.25C5.50887 9.89014 5.71875 9.68026 5.71875 9.42139V7.54639C5.71875 7.28751 5.50887 7.07764 5.25 7.07764H3.375C3.11612 7.07764 2.90625 7.28751 2.90625 7.54639V9.42139C2.90625 9.68026 3.11612 9.89014 3.375 9.89014ZM3.84375 8.01514H4.78125V8.95264H3.84375V8.01514Z"
      fill={color}
    />
    <path
      d="M7.0625 6.14014H8.9375C9.19637 6.14014 9.40625 5.93026 9.40625 5.67139V3.79639C9.40625 3.53751 9.19637 3.32764 8.9375 3.32764H7.0625C6.80362 3.32764 6.59375 3.53751 6.59375 3.79639V5.67139C6.59375 5.93026 6.80362 6.14014 7.0625 6.14014ZM7.53125 4.26514H8.46875V5.20264H7.53125V4.26514Z"
      fill={color}
    />
    <path
      d="M7.0625 9.89014H8.9375C9.19637 9.89014 9.40625 9.68026 9.40625 9.42139V7.54639C9.40625 7.28751 9.19637 7.07764 8.9375 7.07764H7.0625C6.80362 7.07764 6.59375 7.28751 6.59375 7.54639V9.42139C6.59375 9.68026 6.80362 9.89014 7.0625 9.89014ZM7.53125 8.01514H8.46875V8.95264H7.53125V8.01514Z"
      fill={color}
    />
    <path
      d="M10.75 6.14014H12.625C12.8839 6.14014 13.0938 5.93026 13.0938 5.67139V3.79639C13.0938 3.53751 12.8839 3.32764 12.625 3.32764H10.75C10.4911 3.32764 10.2812 3.53751 10.2812 3.79639V5.67139C10.2812 5.93026 10.4911 6.14014 10.75 6.14014ZM11.2188 4.26514H12.1562V5.20264H11.2188V4.26514Z"
      fill={color}
    />
    <path
      d="M10.75 9.89014H12.625C12.8839 9.89014 13.0938 9.68026 13.0938 9.42139V7.54639C13.0938 7.28751 12.8839 7.07764 12.625 7.07764H10.75C10.4911 7.07764 10.2812 7.28751 10.2812 7.54639V9.42139C10.2812 9.68026 10.4911 9.89014 10.75 9.89014ZM11.2188 8.01514H12.1562V8.95264H11.2188V8.01514Z"
      fill={color}
    />
  </svg>
);
