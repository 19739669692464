import React from "react";

export const ChartIcon = ({
  width = 18,
  height = 18,
  viewBox = "",
  fill = "none",
  stroke = "#396083"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={!!viewBox ? viewBox : "0 0 32 32"}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.59831 23.3334H25.2448C27.9953 23.3334 29.4429 21.8858 29.4429 19.1353V1.61914H3.38574V19.1353C3.40022 21.8858 4.84784 23.3334 7.59831 23.3334Z"
        stroke={stroke}
        strokeWidth="2.17143"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.95215 1.61914H30.9045"
        stroke={stroke}
        strokeWidth="2.17143"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6377 30.5713L16.4282 27.6761V23.3333"
        stroke={stroke}
        strokeWidth="2.17143"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2192 30.5713L16.4287 27.676"
        stroke={stroke}
        strokeWidth="2.17143"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91406 14.6478L14.4741 10.8406C14.836 10.5366 15.3137 10.6234 15.5598 11.0287L17.2969 13.924C17.543 14.3293 18.0207 14.4017 18.3826 14.1122L22.9426 10.3049"
        stroke={stroke}
        strokeWidth="2.17143"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
