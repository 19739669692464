import { makeStyles } from "@material-ui/core/styles";

type StyleProps = {
  maxHeight?: string;
};

const useStyles = makeStyles({
  wrapper: {
    position: "relative"
  },
  root: {
    overflowX: "auto",
    maxHeight: (props: StyleProps) => props?.maxHeight || "100%"
  },
  table: {
    width: "auto",
    borderCollapse: "collapse" // Collapse border spacing
  }
});

export default useStyles;
