import React, { ReactNode, useMemo } from "react";
import { Select, MenuItem, SelectProps } from "@material-ui/core";
import { isEmpty, isUndefined, map, orderBy, toLower } from "lodash";
import Spinner from "../Spinner";

interface IProps extends SelectProps {
  options: { label: string; value: string }[];
  render?: (option: { label: string; value: string }) => ReactNode;
  isLoading: boolean;
  isSorted?: boolean;
}

const SelectWithLoading: React.FC<IProps> = (props) => {
  const { options, render, isLoading, isSorted, ...rest } = props;

  const content = useMemo(() => {
    if (isSorted) {
      return map(
        orderBy(options, (item) => toLower(item.label)),
        (option) => (
          <MenuItem key={option.value as string} value={option.value as string}>
            {render?.(option)}
          </MenuItem>
        )
      );
    }
    return map(options, (option) => (
      <MenuItem key={option.value as string} value={option.value as string}>
        {!isUndefined(render) ? render(option) : option.label}
      </MenuItem>
    ));
  }, [options, isSorted]);

  return (
    <Select labelId="select-label" label="Options" displayEmpty {...rest}>
      {isLoading ? (
        <Spinner padding={5} size={24} />
      ) : isEmpty(options) ? (
        <MenuItem disabled>No Data</MenuItem>
      ) : (
        content
      )}
    </Select>
  );
};

export default SelectWithLoading;
