import { useEffect } from "react";

import { DatasetSessionConfig } from "../utils/Dataset.constants";

import useStoreSelectors from "./useStoreSelectors";

import { UseDatasetsSessionPropsType } from "../types/Props.type";

const useDatasetsSession = (props?: UseDatasetsSessionPropsType) => {
  const { timer } = props || {};

  // Stores - STARTS >>
  const { setDatasetDatasetsStore } = useStoreSelectors();
  // << ENDS - Stores

  const getDatasetDatasetsSession = () => {
    let datasetDatasetsSession: $TSFixMe = [];

    if (!sessionStorage.getItem(DatasetSessionConfig.DatasetsSessionKey)) {
      return datasetDatasetsSession;
    }

    try {
      datasetDatasetsSession =
        // @ts-ignore
        JSON.parse(sessionStorage.getItem(DatasetSessionConfig.DatasetsSessionKey)) || [];
    } catch (error: $TSFixMe) {
      console.error(error);
    }

    return datasetDatasetsSession;
  };

  const setDatasetDatasetsSession = (datasetDatasetsSession: $TSFixMe = []) => {
    sessionStorage.setItem(
      DatasetSessionConfig.DatasetsSessionKey,
      JSON.stringify(datasetDatasetsSession)
    );
  };

  const removeDatasetDatasetsSession = () => {
    sessionStorage.removeItem(DatasetSessionConfig.DatasetsSessionKey);
  };

  useEffect(() => {
    const thisDatasetDatasetsSession = getDatasetDatasetsSession();

    if ((thisDatasetDatasetsSession || [])?.length > 0) {
      setDatasetDatasetsStore([...thisDatasetDatasetsSession]);
    } else {
      setDatasetDatasetsStore([]);
    }
  }, [sessionStorage.getItem(DatasetSessionConfig.DatasetsSessionKey), timer]);

  return {
    getDatasetDatasetsSession,
    setDatasetDatasetsSession,
    removeDatasetDatasetsSession
  };
};

export default useDatasetsSession;
