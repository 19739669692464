import React from "react";

export const ViewCodeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15061_9822)">
        <path
          d="M9.06727 4.15734C8.65535 4.04292 8.22861 4.2841 8.11414 4.69608L6.39367 10.8896C6.27925 11.3016 6.52043 11.7283 6.93241 11.8428C7.00172 11.862 7.07145 11.8712 7.14005 11.8712C7.47914 11.8712 7.79037 11.6467 7.88554 11.304L9.60601 5.11048C9.72043 4.69856 9.47925 4.27177 9.06727 4.15734Z"
          fill="black"
        />
        <path
          d="M3.84986 4.30789C3.52108 4.03445 3.03293 4.07935 2.75959 4.40813L0.178942 7.51151C-0.0600254 7.79889 -0.0596125 8.21592 0.179975 8.50278L2.76062 11.593C2.91376 11.7763 3.13373 11.8709 3.35525 11.8709C3.53027 11.8709 3.70627 11.8119 3.8511 11.691C4.1793 11.4169 4.22317 10.9286 3.94911 10.6005L1.78204 8.00555L3.95014 5.39822C4.22348 5.06939 4.17858 4.58124 3.84986 4.30789Z"
          fill="black"
        />
        <path
          d="M15.8202 7.49698L13.2395 4.40686C12.9655 4.07865 12.4772 4.03483 12.149 4.3089C11.8208 4.58296 11.777 5.07122 12.0511 5.39938L14.2182 7.99432L12.0501 10.6016C11.7767 10.9304 11.8216 11.4186 12.1503 11.6919C12.2949 11.8122 12.4704 11.8709 12.6449 11.8709C12.867 11.8709 13.0875 11.7758 13.2406 11.5916L15.8212 8.48825C16.0602 8.20082 16.0598 7.78379 15.8202 7.49698Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_15061_9822">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
