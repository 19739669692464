import React from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import UserData from "./UserData/UserData";
import TenantsData from "./TenantsData/TenantsData";
import { GithubProfile } from "./GithubProfile/GithubProfile";

const useStyles = makeStyles((theme) => ({
  profileWrap: {
    // New UX change
    marginTop: -44,
    position: "fixed",

    height: "100%",
    flexWrap: "nowrap",
    gap: "20px",
    padding: theme.spacing(2)
  },
  cardWrap: {
    padding: "16px",
    background: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0px 2px 2px rgba(152, 152, 152, 0.25)"
  },
  userData: {
    height: "max-content"
  },
  leftWrap: {
    flexWrap: "nowrap",
    gap: "20px"
  }
}));

const UserDetails = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      className={`${classes.profileWrap} container-height-no-subtopbar`}>
      <Grid container item xs={4} direction="column" className={classes.leftWrap}>
        <Grid className={clsx(classes.cardWrap, classes.userData)}>
          <UserData />
        </Grid>
        <Grid className={classes.cardWrap}>
          <GithubProfile />
        </Grid>
      </Grid>
      <Grid item xs={8} className={classes.cardWrap}>
        <TenantsData />
      </Grid>
    </Grid>
  );
};

export default UserDetails;
