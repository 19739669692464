import React from "react";

import ReactApexChart, { Props } from "react-apexcharts";

const options: Props["options"] = {
  chart: {
    type: "heatmap" as const
  },
  dataLabels: {
    enabled: false
  },
  plotOptions: {
    heatmap: {
      colorScale: {
        ranges: [
          {
            from: -1,
            to: -0.75,
            color: "#69011E",
            name: "-1 to -0.75"
          },
          {
            from: -0.75,
            to: -0.5,
            color: "#BA2832",
            name: "-0.75 to -0.5"
          },
          {
            from: -0.5,
            to: -0.25,
            color: "#E68569",
            name: "-0.5 to -0.25"
          },
          {
            from: -0.25,
            to: 0,
            color: "#FCD4BE",
            name: "-0.25 to 0"
          },
          {
            from: 0,
            to: 0.25,
            color: "#F3F5F6",
            name: "0 to 0.25"
          },
          {
            from: 0.25,
            to: 0.5,
            color: "#BDDBEA",
            name: "0.25 to 0.5"
          },
          {
            from: 0.5,
            to: 0.75,
            color: "#5AA2CB",
            name: "0.5 to 0.75"
          },
          {
            from: 0.75,
            to: 1,
            color: "#09366A",
            name: "0.75 to 1"
          }
        ]
      }
    }
  }
};

interface IProps extends Props {
  height?: number;
}

const HeatMap: React.FC<IProps> = (props) => {
  return <ReactApexChart options={options} type="heatmap" {...props} />;
};

export default HeatMap;
