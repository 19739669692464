import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";

// Packages
import { useForm, Controller } from "react-hook-form";
import { includes, isArray, isEmpty, isNil, isPlainObject, isString, values } from "lodash";

// MUI
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Utils
import { getReactHookFormTextFieldRules } from "src/utils/helpers";

// Components
import JsonEditor from "components/editor/JsonEditor";
import { Modal } from "src/components/custom";
import { PredictionServiceHelperText } from "../utils/PredictionService.constants";

enum FormFields {
  DatasetName = "datasetName"
}

interface IProps {
  jsonString: string;
  getProposedDatasetName: () => string;
  onDownload: () => void;
  onAddToCanvas: (datasetName: string) => void;
  isAddingToCanvas: boolean;
  isAddedToCanvas: boolean;
  isTesting: boolean;
  isUpdateDatasetNameModalOpen: boolean;
  setIsUpdateDatasetNameModalOpen: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  editor: {
    width: "100% !important",
    height: "400px !important",
    background: theme.palette.common.black,
    color: theme.palette.common.white,

    "& .ace_cursor": {
      color: "white !important"
    },

    "& .ace_gutter": {
      background: "black",
      color: "white"
    },

    "& .ace_print-margin": {
      visibility: "hidden !important"
    },

    "& .ace_indent-guide": {
      background: "none !important"
    }
  }
}));

const PredictionResults: React.FC<IProps> = (props) => {
  const {
    jsonString,
    getProposedDatasetName,
    onDownload,
    onAddToCanvas,
    isAddingToCanvas,
    isAddedToCanvas,
    isTesting,
    isUpdateDatasetNameModalOpen,
    setIsUpdateDatasetNameModalOpen
  } = props || {};

  const theme = useTheme();
  const classes = useStyles();

  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    mode: "onChange", // Validate onChange
    reValidateMode: "onChange", // Re-validate onChange
    defaultValues: {
      [FormFields.DatasetName]: ""
    }
  });

  useEffect(() => {
    if (!!isUpdateDatasetNameModalOpen) {
      setValue(FormFields.DatasetName, getProposedDatasetName());
    }
  }, [isUpdateDatasetNameModalOpen]);

  const disabledDownloadActionMessage = useMemo(() => {
    if (!!isTesting) {
      return "Please wait. Testing prediction service.";
    }

    return "";
  }, [isTesting]);

  const disabledAddToCanvasActionMessage = useMemo(() => {
    if (!!isTesting) {
      return "Please wait. Testing prediction service.";
    }

    if (!!isAddedToCanvas) {
      return "Dataset already added to canvas.";
    }

    return "";
  }, [isTesting, isAddedToCanvas]);

  const disabledAddToCanvasActionInModalMessage = useMemo(() => {
    if (!isValid) {
      return `Invalid ${PredictionServiceHelperText.PredictionResultsOutputDatasetName}.`;
    }

    if (!!isAddingToCanvas) {
      return "Please wait. Adding dataset to canvas.";
    }

    return "";
  }, [isValid, isAddingToCanvas]);

  const isParsable = (): boolean => {
    try {
      const parsed = JSON.parse(jsonString);
      return isPlainObject(parsed);
    } catch (e) {
      return false;
    }
  };

  const isValidResults = useMemo(() => {
    if (!isParsable()) {
      return false;
    }

    const data = JSON.parse(jsonString);

    if (isNil(values(data)) || isEmpty(values(data))) {
      return false;
    }

    if (
      !isPlainObject(values(data)?.[0]) &&
      !isArray(values(data)?.[0]) &&
      !isString(values(data)?.[0])
    ) {
      return false;
    }

    return true;
  }, [jsonString]);

  return (
    <>
      {!!isUpdateDatasetNameModalOpen && (
        <Modal
          open
          size="sm"
          title="Update Name"
          onClose={(_, reason: string) => {
            if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
              setIsUpdateDatasetNameModalOpen(() => false);
            }
          }}
          onSubmit={() => onAddToCanvas(getValues(FormFields.DatasetName))}
          cancelLabel="Cancel"
          submitLabel={PredictionServiceHelperText.AddToCanvas}
          isCancelDisabled={!!isAddingToCanvas}
          isSubmitDisabled={!!disabledAddToCanvasActionInModalMessage}
          isSubmitting={!!isAddingToCanvas}
          cancelActionInfo={!!isAddingToCanvas ? "Please wait. Adding dataset to canvas." : ""}
          submitActionInfo={disabledAddToCanvasActionInModalMessage || ""}
          hideCloseIcon>
          <Controller
            name={FormFields.DatasetName}
            control={control}
            rules={getReactHookFormTextFieldRules({
              fieldName: PredictionServiceHelperText.PredictionResultsOutputDatasetName
            })}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label={PredictionServiceHelperText.PredictionResultsOutputDatasetName}
                fullWidth
                disabled={!!isAddingToCanvas}
                error={!!errors.datasetName}
                helperText={errors.datasetName ? <>{errors.datasetName.message}</> : ""}
              />
            )}
          />
        </Modal>
      )}

      <Grid
        container
        direction="column"
        style={{ marginTop: theme.spacing(2), rowGap: theme.spacing(2) }}>
        <Typography variant="subtitle2">{PredictionServiceHelperText.PredictionResults}</Typography>
        <JsonEditor className={classes.editor} readOnly jsonString={jsonString} />
        {!!isValidResults && (
          <Grid container style={{ columnGap: theme.spacing(2) }}>
            <Tooltip title={disabledDownloadActionMessage || ""}>
              <span>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={!!disabledDownloadActionMessage}
                  onClick={onDownload}>
                  {PredictionServiceHelperText.DownloadAsCSV}
                </Button>
              </span>
            </Tooltip>

            <Tooltip title={disabledAddToCanvasActionMessage || ""}>
              <span>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={!!disabledAddToCanvasActionMessage}
                  onClick={() => setIsUpdateDatasetNameModalOpen(() => true)}>
                  {PredictionServiceHelperText.AddToCanvas}
                </Button>
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PredictionResults;
