import React from "react";

export const TableViewIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width} ${height}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 7V3C1 1.89543 1.89543 1 3 1H15C16.1046 1 17 1.89543 17 3V7M1 7V12M1 7H6M17 7V12M17 7H12M1 12V15C1 16.1046 1.89543 17 3 17H6M1 12H6M6 7V12M6 7H12M17 12V15C17 16.1046 16.1046 17 15 17H12M17 12H12M12 7V12M6 17V12M6 17H12M6 12H12M12 17V12M7 4H11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
