import { Spinner } from "src/components";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  makeStyles
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React, { useCallback, useState } from "react";

const useStyles = makeStyles({
  menuPaper: {
    border: "1px solid #C7C7C7",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    width: "320px"
  },
  menuList: {
    padding: "8px"
  },
  menuItemTitle: {
    color: "#4646B5",
    fontSize: "14px"
  },
  menuItemDesc: {
    color: "#000",
    opacity: 0.5,
    fontSize: "12px",
    fontStyle: "italic",
    whiteSpace: "normal"
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    alignItems: "flex-start",
    "&:hover": {
      background: "#F2F9FF",
      borderRadius: "4px"
    }
  },
  btn: {
    border: "1px solid #4646B5 !important",
    borderRight: ({ showBorder }: { showBorder?: boolean }) =>
      showBorder ? undefined : "0px !important",
    color: "#4646B5 !important",
    borderRadius: ({ showBorder }: { showBorder?: boolean }) =>
      showBorder ? undefined : "4px 0px 0px 4px",
    height: "100%",
    "&:disabled": {
      opacity: 0.5
    },
    background: "#fff"
  },
  iconBtn: {
    background: "#fff !important",
    border: "1px solid #4646B5",
    borderRadius: "0px 4px 4px 0px",
    "&:disabled": {
      opacity: 0.5
    }
  },
  arrowIcon: {
    color: "#4646B5"
  }
});

export const TestRecipeDropdown = ({
  onTest,
  isTestInProgress,
  isTestDisabled,
  hideMenu
}: {
  onTest: (sampleRows?: number) => void;
  isTestInProgress: boolean;
  isTestDisabled: boolean;
  hideMenu?: boolean;
}) => {
  const classes = useStyles({ showBorder: hideMenu });
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback((e: $TSFixMe) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      label: "Test",
      testId: "test",
      value: undefined,
      helpText: "This runs by default on the full data. Response time can be higher"
    },
    {
      label: "Test with 100k rows",
      testId: "test100kRows",
      value: 100000,
      helpText: "This runs on sample data of 100k rows"
    },
    {
      label: "Test with 1Million rows",
      testId: "test1MillionRows",
      value: 1000000,
      helpText: "This runs on sample data of 1 million rows of data"
    }
  ];

  return (
    <Grid container direction="row" wrap="nowrap">
      <Tooltip title={isTestInProgress ? "Test Recipe is in progress" : ""}>
        <div>
          <Button
            className={classes.btn}
            size="small"
            variant="outlined"
            disabled={isTestDisabled}
            data-testid="testRecipeBtn"
            onClick={() => onTest(undefined)}>
            Test
            {isTestInProgress && <Spinner size={16} padding="0px 8px" />}
          </Button>
        </div>
      </Tooltip>
      {!hideMenu && (
        <>
          <Tooltip title={isTestInProgress ? "Test Recipe is in progress" : ""}>
            <div>
              <IconButton
                onClick={handleClick}
                className={classes.iconBtn}
                disabled={isTestDisabled}
                data-testid="testRecipeOptionsBtn"
                size="small">
                <ArrowDropDown className={classes.arrowIcon} />
              </IconButton>
            </div>
          </Tooltip>
          <Menu
            classes={{
              paper: classes.menuPaper,
              list: classes.menuList
            }}
            transitionDuration={{ exit: 0 }}
            onClose={handleClose}
            keepMounted
            getContentAnchorEl={null}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}>
            <MenuList disablePadding>
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  disabled={isTestInProgress}
                  className={classes.menuItem}
                  test-id="test-recipe-sample-rows"
                  onClick={() => {
                    onTest(option.value);
                    handleClose();
                  }}>
                  <Typography className={classes.menuItemTitle} data-testid={option.testId}>
                    {option.label}
                  </Typography>
                  <Typography className={classes.menuItemDesc}>{option.helpText}</Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </>
      )}
    </Grid>
  );
};
