import React from "react";

export const InfoIcon = ({
  width = 16,
  height = 16,
  viewBox = "",
  fill = "none",
  color = "currentColor"
} = {}) => (
  <svg
    width={width}
    height={height}
    viewBox={!!viewBox ? viewBox : `0 0 ${width + 4} ${height + 4}`}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2501 3.33398C10.9185 3.33398 10.6006 3.46568 10.3662 3.7001C10.1318 3.93452 10.0001 4.25246 10.0001 4.58398C10.0001 4.91551 10.1318 5.23345 10.3662 5.46787C10.6006 5.70229 10.9185 5.83398 11.2501 5.83398C11.5816 5.83398 11.8995 5.70229 12.1339 5.46787C12.3684 5.23345 12.5001 4.91551 12.5001 4.58398C12.5001 4.25246 12.3684 3.93452 12.1339 3.7001C11.8995 3.46568 11.5816 3.33398 11.2501 3.33398ZM10.9501 7.30898C9.9584 7.39232 7.25006 9.55065 7.25006 9.55065C7.0834 9.67565 7.1334 9.66732 7.26673 9.90065C7.40006 10.1257 7.3834 10.1423 7.54173 10.034C7.7084 9.92565 7.9834 9.75065 8.44173 9.46732C10.2084 8.33398 8.72507 10.9507 7.96673 15.359C7.66673 17.5423 9.6334 16.4173 10.1417 16.084C10.6417 15.759 11.9834 14.834 12.1167 14.7423C12.3001 14.6173 12.1667 14.5173 12.0251 14.309C11.9251 14.1673 11.8251 14.2673 11.8251 14.2673C11.2834 14.6257 10.2917 15.3757 10.1584 14.9007C10.0001 14.4257 11.0167 11.1673 11.5751 8.92565C11.6667 8.39232 11.9167 7.22565 10.9501 7.30898Z"
      fill={color}
    />
  </svg>
);
