import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

import TextField from "./TextFieldWithValidation";
import {
  CONNECTOR_KEYS,
  LENGTH_ERROR_MESSAGE,
  MAX_LENGTH,
  MIN_LENGTH
} from "../utils/OutputDataset.constants";
import { IConnectorDetailsProps } from "./SnowflakeConnectorDetails";

export const hasLengthError = (value: string, max?: number) => {
  const trimmed = _.trim(value);
  const size = _.size(trimmed);

  return size > (max ?? MAX_LENGTH) || size < MIN_LENGTH;
};

const SQLRedshiftConnectorDetails: React.FC<IConnectorDetailsProps> = (props) => {
  const { values, onChange } = props;

  const tableValue = _.get(values, CONNECTOR_KEYS.sqlRedshiftCategory.tableName.key, "");

  const { error, helperText } = useMemo(() => {
    const trimmed = _.trim(tableValue);
    if (_.size(trimmed) === 0) {
      return { error: false, helperText: "" };
    }
    const regex = CONNECTOR_KEYS.sqlRedshiftCategory.tableName.regex;
    const lengthError = hasLengthError(tableValue);
    const isValid = regex.test(tableValue);
    return {
      error: lengthError || !isValid,
      helperText: lengthError
        ? LENGTH_ERROR_MESSAGE
        : isValid
          ? ""
          : CONNECTOR_KEYS.sqlRedshiftCategory.tableName.errorMessage
    };
  }, [tableValue]);

  useEffect(() => {
    if (!_.has(values, CONNECTOR_KEYS.sqlRedshiftCategory.type.key)) {
      const e = {
        target: {
          name: CONNECTOR_KEYS.sqlRedshiftCategory.type.key,
          value: CONNECTOR_KEYS.sqlRedshiftCategory.type.values.append
        }
      };
      onChange(e);
    }
  }, []);

  return (
    <>
      <TextField
        fullWidth
        size="small"
        id="table-name"
        label={CONNECTOR_KEYS.sqlRedshiftCategory.tableName.label}
        required
        data-testid="table-name"
        value={tableValue}
        name={CONNECTOR_KEYS.sqlRedshiftCategory.tableName.key}
        variant="outlined"
        error={error}
        helperText={helperText}
        onChange={onChange}
      />
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name={CONNECTOR_KEYS.sqlRedshiftCategory.type.key}
          value={_.get(
            values,
            CONNECTOR_KEYS.sqlRedshiftCategory.type.key,
            CONNECTOR_KEYS.sqlRedshiftCategory.type.values.append
          )}
          onChange={onChange}>
          <FormControlLabel
            value={CONNECTOR_KEYS.sqlRedshiftCategory.type.values.append}
            control={<Radio />}
            label={_.capitalize(CONNECTOR_KEYS.sqlRedshiftCategory.type.values.append)}
          />
          <FormControlLabel
            value={CONNECTOR_KEYS.sqlRedshiftCategory.type.values.replace}
            control={<Radio />}
            label={_.capitalize(CONNECTOR_KEYS.sqlRedshiftCategory.type.values.replace)}
          />
          <Tooltip
            title={
              <>
                <div>
                  Append action is applicable only for same schema. It will fail in case of schema
                  mismatch.
                </div>
                <div>
                  Replace will completely overwrite your existing data and schema with the new one.
                </div>
              </>
            }>
            <InfoOutlined style={{ marginTop: "9px" }} />
          </Tooltip>
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default SQLRedshiftConnectorDetails;
