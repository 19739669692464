import React from "react";

export const UsageAndPolicyIcon = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33325 2.66667V10.6667M1.33325 2.66667V1.33334M1.33325 2.66667C6.66659 2.66667 5.99992 5.33334 11.3333 5.33334C9.99992 10.6667 3.33325 7.33334 1.33325 10.6667M1.33325 14.6667V10.6667"
      stroke="#003656"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
