import React from "react";

// MUI
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { ProjectSettingsHelperText } from "../utils/ProjectSettings.constants";

// Styles
import { useStyles as useSubtopBarStyles } from "src/components/SubtopBar/styling";

type Props = {
  disabledCancelActionMessage: string;
  disabledSaveActionMessage: string;
  isSaving: boolean;
  saveProject: () => void;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    columnGap: theme.spacing(2),
    padding: "10px 20px",
    zIndex: 1,
    backgroundColor: "#d9d9d9"
  }
}));

const Footer = (props: Props) => {
  const { disabledCancelActionMessage, disabledSaveActionMessage, isSaving, saveProject, onClose } =
    props || {};

  const { barMainButton, actionButton } = useSubtopBarStyles();
  const classes = useStyles();

  return (
    <Grid container component="footer" justifyContent="flex-end" className={classes.root}>
      <Tooltip title={disabledCancelActionMessage} data-testid="projectSettingsCancelActionInfo">
        <span>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={`${barMainButton} ${actionButton}`}
            disabled={!!disabledCancelActionMessage}
            onClick={() => onClose()}
            data-testid="projectSettingsCancelAction">
            {ProjectSettingsHelperText.Cancel}
          </Button>
        </span>
      </Tooltip>
      <Tooltip title={disabledSaveActionMessage} data-testid="projectSettingsSaveActionInfo">
        <span>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!!disabledSaveActionMessage}
            onClick={() => saveProject()}
            data-testid="projectSettingsSaveAction">
            {!!isSaving ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              ProjectSettingsHelperText.Save
            )}
          </Button>
        </span>
      </Tooltip>
    </Grid>
  );
};

export default Footer;
