import React from "react";

// Packages
import { includes } from "lodash";

// Components
import { Modal } from "src/components/custom";
import { ModalVariants } from "src/components/custom/Modal/Modal";

// Constants
import { ProjectSettingsCloseWindowPromptDetails } from "../utils/ProjectSettings.constants";

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmCloseWindow = (props: Props) => {
  const { onConfirm, onCancel } = props || {};

  return (
    <Modal
      open
      variant={ModalVariants.Delete}
      title={ProjectSettingsCloseWindowPromptDetails.title}
      content={[
        ProjectSettingsCloseWindowPromptDetails.messageLine1,
        ProjectSettingsCloseWindowPromptDetails.messageLine2
      ]}
      onClose={(_, reason: string) => {
        if (!includes(["escapeKeyDown", "backdropClick"], reason)) {
          onCancel();
        }
      }}
      cancelLabel={ProjectSettingsCloseWindowPromptDetails.cancelLabel}
      submitLabel={ProjectSettingsCloseWindowPromptDetails.submitLabel}
      onSubmit={onConfirm}
      hideCloseIcon
    />
  );
};

export default ConfirmCloseWindow;
