import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";

import { putAPI } from "src/utils/apiService";

const useDataAppResourceUsage = (
  dataappId?: string,
  options?: UseQueryOptions<null>
): UseQueryResult<null> => {
  return useQuery({
    queryFn: async () => {
      return await putAPI(`/dataapps/${dataappId}/record-usage`, { dataappId }, true);
    },
    refetchInterval: 60000,
    refetchIntervalInBackground: true,
    ...options
  });
};

export default useDataAppResourceUsage;
